export var OPEN_SIDEBAR = "OPEN_SIDEBAR";
export var CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export var SHOW_SNACKBAR = "SHOW_SNACKBAR";
export var HIDE_SNACKBAR = "HIDE_SNACKBAR";
export var SHOW_LOADER = "SHOW_LOADER";
export var SHOW_CONFIRM_MODAL = "SHOW_CONFIRM_MODAL";
export var SHOW_APP_MODAL = "SHOW_APP_MODAL";
export var openSidebar = function () { return ({
    type: OPEN_SIDEBAR,
}); };
export var closeSidebar = function () { return ({
    type: CLOSE_SIDEBAR,
}); };
export var showSnackbar = function () { return ({
    type: SHOW_SNACKBAR,
}); };
export var hideSnackbar = function () { return ({
    type: HIDE_SNACKBAR,
}); };
export var showLoader = function () { return ({
    type: SHOW_LOADER,
}); };
export var showConfirmModal = function () { return ({
    type: SHOW_CONFIRM_MODAL,
}); };
export var showAppModal = function () { return ({
    type: SHOW_APP_MODAL,
}); };
