import { IApplication } from "@rentiohq/shared-frontend/dist/types/application.types";
import { createContext } from "react";
import { IDropArea } from "../PropertyDetailApplications";

interface IDragAndDropContext {
  selectedItems: IApplication[];
  allDropAreas: IDropArea[];
  onSelectItem: (item: IApplication) => void;
  onUnselectItem: (item: IApplication) => void;
  setAllDropAreas: (dropAreas: IDropArea[]) => void;
  replaceSelectedItems: (items: IApplication[]) => void;
}

export const DragAndDropContext = createContext<IDragAndDropContext>({
  selectedItems: [],
  allDropAreas: [],
  setAllDropAreas: () => {},
  onSelectItem: () => {},
  onUnselectItem: () => {},
  replaceSelectedItems: () => {},
});
