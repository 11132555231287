var _a;
import { EDocumentCategory } from "../../types/document.types";
export var ENTITY = "document";
export var REDUCER_KEY = "DOCUMENTS_V2";
export var BASE_PATH_ENTITY = "documents";
export var GENERATOR_PARAMS = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
};
export var DOCUMENT_CATEGORY_LABELS = (_a = {},
    _a[EDocumentCategory.Other] = "other",
    _a[EDocumentCategory.Contract] = "contract",
    _a[EDocumentCategory.Certificate] = "certificate",
    _a[EDocumentCategory.Invoice] = "invoice",
    _a[EDocumentCategory.Application] = "application",
    _a[EDocumentCategory.LocationDescription] = "location_description",
    _a[EDocumentCategory.Image] = "image",
    _a[EDocumentCategory.Attachment] = "attachment",
    _a[EDocumentCategory.RentDeposit] = "rent_deposit",
    _a[EDocumentCategory.RentalAssignment] = "rental_assignment",
    _a[EDocumentCategory.RegisteredMail] = "registered_mail",
    _a[EDocumentCategory.RentDepositRelease] = "rent_deposit_release",
    _a[EDocumentCategory.IdCardBack] = "id_card_back",
    _a[EDocumentCategory.IdCardFront] = "id_card_front",
    _a[EDocumentCategory.Epc] = "epc",
    _a[EDocumentCategory.Electricity] = "electricity",
    _a[EDocumentCategory.Examination] = "examination",
    _a[EDocumentCategory.HeaterMaintenance] = "heater_maintenance",
    _a[EDocumentCategory.RegisteredLetter] = "registered_letter",
    _a[EDocumentCategory.Quotation] = "quotation",
    _a[EDocumentCategory.CancelledContract] = "cancelled_contract",
    _a[EDocumentCategory.InsuranceProof] = "insurance_proof",
    _a[EDocumentCategory.KeyHandover] = "key_handover",
    _a[EDocumentCategory.ContractAddendum] = "contract_addendum",
    _a[EDocumentCategory.RegistrationProofContract] = "registration_proof_contract",
    _a[EDocumentCategory.RegistrationProofLocationDescription] = "registration_proof_location_description",
    _a[EDocumentCategory.RegistrationProofAddendum] = "registration_proof_addendum",
    _a[EDocumentCategory.BeneficiaryReport] = "beneficiary_report",
    _a[EDocumentCategory.LegalCase] = "legal_case",
    _a);
export var ALL_DOCUMENT_CATEGORIES = Object.values(EDocumentCategory).filter(function (x) { return !isNaN(+x); });
export var NON_USER_SELECTABLE_DOCUMENT_CATEGORIES = [
    EDocumentCategory.BeneficiaryReport,
    EDocumentCategory.LegalCase,
];
// export const DOCUMENT_CATEGORY_VISUAL_ORDER = [
//   EDocumentCategory.RentalAssignment,
//   EDocumentCategory.KeyHandover,
//   EDocumentCategory.Contract,
//   EDocumentCategory.LocationDescription,
//   EDocumentCategory.ContractAddendum,
//   EDocumentCategory.CancelledContract,
//   EDocumentCategory.IdCardBack,
//   EDocumentCategory.IdCardFront,
//   EDocumentCategory.Electricity,
//   EDocumentCategory.Epc,
//   EDocumentCategory.HeaterMaintenance,
//   EDocumentCategory.Invoice,
//   EDocumentCategory.Certificate,
//   EDocumentCategory.Examination,
//   EDocumentCategory.Quotation,
//   EDocumentCategory.InsuranceProof,
//   EDocumentCategory.Image,
//   EDocumentCategory.Attachment,
//   EDocumentCategory.Other,
//   // Rentio generate items at the bottom
//   EDocumentCategory.RentDeposit,
//   EDocumentCategory.RentDepositRelease,
//   EDocumentCategory.RegisteredMail,
//   EDocumentCategory.RegisteredLetter,
//   EDocumentCategory.RegistrationProofContract,
//   EDocumentCategory.RegistrationProofLocationDescription,
//   EDocumentCategory.RegistrationProofAddendum,
//   EDocumentCategory.Application,
//   EDocumentCategory.BeneficiaryReport,
//   EDocumentCategory.LegalCase,
// ];
// // Throw error if category is missing in DOCUMENT_CATEGORY_VISUAL_ORDER
// if (CONFIG.ENVIRONMENT !== EEnvironment.Prod) {
//   const ALL_DOCUMENT_CATEGORIES = Object.values(EDocumentCategory).filter(
//     x => !isNaN(+x),
//   ) as EDocumentCategory[];
//   const missingVisualCategory = ALL_DOCUMENT_CATEGORIES.find(
//     category => !DOCUMENT_CATEGORY_VISUAL_ORDER.includes(category),
//   );
//   if (missingVisualCategory) {
//     throw new Error(
//       `DOCUMENT_CATEGORY_VISUAL_ORDER is missing category with ID: ${missingVisualCategory}`,
//     );
//   }
// }
