import { REDUCER_KEY_BANK_ACCOUNT, } from "../../reduxV2/bankAccount";
import { REDUCER_KEY_CHATS as REDUCER_KEY_CHATS_2, } from "../../reduxV2/chats";
import { REDUCER_KEY_COST_CENTER, } from "../../reduxV2/costCenter";
import { REDUCER_KEY_DOCUMENTS as REDUCER_KEY_DOCUMENTS_2, } from "../../reduxV2/documents";
import { REDUCER_KEY_EXPORT_FILE, } from "../../reduxV2/exportFile";
import { REDUCER_KEY_NOTE as REDUCER_KEY_NOTE_2, } from "../../reduxV2/note";
import { REDUCER_KEY_PAYMENT_ORDERS, } from "../../reduxV2/paymentOrder";
import { REDUCER_KEY_PAYMENT_REQUESTS, } from "../../reduxV2/paymentRequest";
import { REDUCER_KEY_PROPERTY as REDUCER_KEY_PROPERTY_2, } from "../../reduxV2/property";
import { REDUCER_KEY_TASK as REDUCER_KEY_TASK_2, } from "../../reduxV2/task";
import { REDUCER_KEY_AUTH } from "../auth/auth.reducer";
import { REDUCER_KEY_BROKER } from "../broker/broker.reducer";
import { REDUCER_KEY_CARD } from "../card/card.reducer";
import { REDUCER_KEY_COMMENT } from "../comment/comment.reducer";
import { REDUCER_KEY_CONTACT } from "../contact/contact.reducer";
import { REDUCER_KEY_COUNT } from "../count/count.reducer";
import { REDUCER_KEY_DOCUMENT_PACKAGE, } from "../documentPackage/documentPackage.reducer";
import { REDUCER_KEY_FEATURE_FLAG, } from "../featureFlag/featureFlag.reducer";
import { REDUCER_KEY_FORM } from "../form/form.reducer";
import { REDUCER_KEY_PAYMENT } from "../payment/payment.reducer";
import { REDUCER_KEY_PAYMENTDISPUTE, } from "../paymentDispute/paymentDispute.reducer";
import { REDUCER_KEY_PRICING } from "../pricing/pricing.reducer";
import { REDUCER_KEY_PROPERTY, } from "../property/property.reducer";
import { REDUCER_KEY_RENT_DEPOSIT, } from "../rentDeposit/rentDeposit.reducer";
import { REDUCER_KEY_REQUIREMENTS, } from "../requirements/requirements.reducer";
import { REDUCER_KEY_SOCKET } from "../socket/socket.reducer";
export var EDashboardType;
(function (EDashboardType) {
    EDashboardType["TenantDashboard"] = "TENANT_DASHBOARD";
    EDashboardType["ProDashboard"] = "PRO_DASHBOARD";
})(EDashboardType || (EDashboardType = {}));
