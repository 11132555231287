import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import { REDUCER_KEY_DOCUMENT_PACKAGE, } from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.reducer";
import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import { REDUCER_KEY_DOCUMENT, } from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.reducer";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../Loading";
export var DocumentFetchListItem = function (props) {
    var document = props.document, documentId = props.documentId, renderDocument = props.renderDocument, renderLoading = props.renderLoading, renderError = props.renderError, _a = props.fetchDocumentPackage, fetchDocumentPackage = _a === void 0 ? false : _a, extraQueryParams = props.extraQueryParams;
    // Redux
    var dispatch = useDispatch();
    var _b = documentHooks.useDetail({ id: documentId }), fetchedDocument = _b.detail, isFetching = _b.isFetching, fetchError = _b.fetchError;
    var anyDocument = fetchedDocument || document;
    var documentPackage = useSelector(function (state) {
        return (anyDocument === null || anyDocument === void 0 ? void 0 : anyDocument.documentPackageId)
            ? state.documentPackage.documentPackages[anyDocument === null || anyDocument === void 0 ? void 0 : anyDocument.documentPackageId]
            : undefined;
    });
    // Lifecycle
    React.useEffect(function () {
        if (document || !documentId) {
            return;
        }
        dispatch(documentActions.getDetailStart.getAction({
            id: documentId,
            extraQueryParams: extraQueryParams,
        }));
    }, [documentId]);
    React.useEffect(function () {
        var anyDocument = fetchedDocument || document;
        if (!(anyDocument === null || anyDocument === void 0 ? void 0 : anyDocument.documentPackageId) || !fetchDocumentPackage) {
            return;
        }
        if (documentPackage) {
            return;
        }
        dispatch(documentPackageActions.getDocumentPackage.actions.start({
            documentPackageId: anyDocument.documentPackageId,
        }));
    }, [anyDocument]);
    // Render
    if (document) {
        return _jsx(_Fragment, { children: renderDocument(document, documentPackage) });
    }
    if (fetchedDocument) {
        return _jsx(_Fragment, { children: renderDocument(fetchedDocument, documentPackage) });
    }
    if (isFetching) {
        return (renderLoading === null || renderLoading === void 0 ? void 0 : renderLoading()) || _jsx(Loading, { asDots: true });
    }
    if (fetchError && renderError) {
        return renderError(fetchError);
    }
    return null;
};
