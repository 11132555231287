import { EField } from "./schema.updateVariable.types";
export var getInitialValues = function (extraData) {
    var _a;
    var _b;
    var variable = extraData.variable, group = extraData.group;
    var _c = variable.isRequired, isRequired = _c === void 0 ? true : _c, _d = variable.isMultilineText, isMultilineText = _d === void 0 ? false : _d;
    return _a = {},
        _a[EField.Name] = variable.name,
        _a[EField.Group] = group.id,
        _a[EField.Type] = variable.type,
        _a[EField.EditableByTenant] = variable.editableByTenant ? "yes" : "no",
        _a[EField.Required] = isRequired ? "yes" : "no",
        _a[EField.SortWeight] = variable.sortWeight,
        _a[EField.DefaultText] = variable.defaultText,
        _a[EField.IsMultilineText] = isMultilineText ? "yes" : "no",
        _a[EField.DefaultDate] = variable.defaultDate,
        _a[EField.DefaultDateFormatting] = variable.defaultDateFormatting,
        _a[EField.MinDate] = variable.minDate,
        _a[EField.MaxDate] = variable.maxDate,
        _a[EField.DefaultNumber] = variable.defaultNumber,
        _a[EField.MinNumber] = variable.minNumber,
        _a[EField.MaxNumber] = variable.maxNumber,
        _a[EField.DefaultToggle] = variable.defaultToggle ? "yes" : "no",
        _a[EField.DropdownValues] = (_b = variable.dropdownValues) === null || _b === void 0 ? void 0 : _b.join(","),
        _a[EField.Persisted] = variable.persisted ? "yes" : "no",
        _a;
};
