import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import * as documentPackageSelectors from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.selectors";
import * as expertInquirySelectors from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.selectors";
import * as rentDepositActions from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.actions";
import * as rentDepositSelectors from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.selectors";
import * as templateSelectors from "@rentiohq/shared-frontend/dist/redux/template/template.selectors";
import * as templateUtils from "@rentiohq/shared-frontend/dist/redux/template/template.utils";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import * as templateDocumentSelectors from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.selectors";
import * as templateDocumentUtils from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.utils";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  EDocumentPackageStatus,
  EDocumentPackageTypes,
} from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { EPaymentOrderType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { ERentDepositStatus } from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";
import { appendWhere } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { compact } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import {
  getDocumentPackageMessage,
  getIndexationMessage,
  getInsuranceMessage,
  getMoveServiceMessage,
  getPaymentOrderMessage,
  getPropertyInspectionReportMessage,
  getRegistrationMessage,
  getRentDepositMessage,
  getTemplateDocumentContractMessage,
} from "./../../ContractCard.utils";

export const useGetChecklistItems = (params: {
  contract: IContract;
  property: IProperty;

  // Callbacks
  onIndexContract: () => void;
  onCreateContractFromTemplateDocument: () => void;
  onShowDocument: (documentId: string) => void;
  onShowTemplateDocument: (templateDocumentId: string) => void;
  onShowDocumentPackage: (documentPackageId: string) => void;
  onShowRentDeposit: (rentDepositId: number) => void;
  onOrderPropertyInspectionReport: () => void;
  onRequestInsurance: () => void;
  onShowInsurance: (insuranceContractId: string) => void;
  onCreateMoveRequest: () => void;
  onShowMoveRequest: (moveRequestId: number) => void;
  onShowRentRegistration: (registrationId: number) => void;

  // Config
  shouldFetchData: boolean;
}) => {
  const {
    contract,
    property,

    onIndexContract,
    onCreateContractFromTemplateDocument,
    onShowDocument,
    onShowTemplateDocument,
    onShowDocumentPackage,
    onShowRentDeposit,
    onOrderPropertyInspectionReport,
    onRequestInsurance,
    onShowInsurance,
    onCreateMoveRequest,
    onShowMoveRequest,
    onShowRentRegistration,

    shouldFetchData,
  } = params;

  const dispatch = useDispatch();

  const { internalModeEnabled } = useInternalMode();
  const { isBroker } = authHooks.useSelf();

  const { update: updateContract } = contractHooks.useUpdate({
    id: contract.id,
  });

  // Templates
  const templates = useSelector((state: IRootStore) =>
    templateSelectors.paged.dataForPage(state, {
      id: templateUtils.getPagedId({}),
      page: 0,
    }),
  );
  const isFetchingTemplates = useSelector((state: IRootStore) =>
    templateSelectors.paged.isFetchingPage(state, {
      id: templateUtils.getPagedId({}),
      page: 0,
    }),
  );

  // Template documents
  const contractTemplateDocumentsFilter = {
    where: {
      contractId: contract.id,
      type: { like: "%contract%", options: "i" },
    },
  };
  const contractTemplateDocuments = useSelector((state: IRootStore) =>
    templateDocumentSelectors.paged.dataForPage(state, {
      id: templateDocumentUtils.getPagedId({
        filter: contractTemplateDocumentsFilter,
      }),
      page: 0,
    }),
  );
  const isFetchingContractTemplateDocuments = useSelector((state: IRootStore) =>
    templateDocumentSelectors.paged.isFetchingPage(state, {
      id: templateDocumentUtils.getPagedId({
        filter: contractTemplateDocumentsFilter,
      }),
      page: 0,
    }),
  );

  // Document packages
  const documentPackagesIdentifier = `document-packages-contract-${contract.id}`;
  const isFetchingDocumentPackage = useSelector((state: IRootStore) =>
    documentPackageSelectors.isFetchingDocumentPackage(
      state,
      documentPackagesIdentifier,
    ),
  );
  const documentPackages = useSelector((state: IRootStore) =>
    documentPackageSelectors.getDocumentPackages(
      state,
      documentPackagesIdentifier,
    ),
  );

  // Rent deposits
  const rentDepositsIdentifier = `rent-deposits-contract-${contract.id}`;
  const isFetchingRentDeposits = useSelector((state: IRootStore) =>
    rentDepositSelectors.isFetchingRentDeposits(state, rentDepositsIdentifier),
  );
  const rentDeposits = useSelector((state: IRootStore) =>
    rentDepositSelectors.getRentDeposits(state, rentDepositsIdentifier),
  );

  // Payment orders
  const contractsPaymentOrdersCountIdentifier = `contracts-payment-orders-count-${contract.id}`;
  const isFetchingPaymentOrdersCount =
    useSelector((state: IRootStore) =>
      countSelectors.getIsFetchingCount(
        state,
        contractsPaymentOrdersCountIdentifier,
      ),
    ) || 0;
  const paymentOrdersCount =
    useSelector((state: IRootStore) =>
      countSelectors.getCount(state, contractsPaymentOrdersCountIdentifier),
    ) || 0;

  // Property inspection report
  const partnerships = useSelector((state: IRootStore) =>
    expertInquirySelectors.partnerships(state),
  );

  // Insurance
  const hasInsuranceForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Insurance,
  );

  // Move service
  const hasMoveServiceForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.MoveService,
  );

  // Actions
  // Template documents
  useEffect(() => {
    if (!shouldFetchData) {
      return;
    }

    if (isFetchingContractTemplateDocuments) {
      return;
    }

    dispatch(
      templateDocumentActions.getPaged.actions.start({
        page: 0,
        // limit: 100,
        filter: contractTemplateDocumentsFilter,
      }),
    );
  }, []);

  // Document packages
  useEffect(() => {
    if (!shouldFetchData) {
      return;
    }

    if (isFetchingDocumentPackage) {
      return;
    }

    dispatch(
      documentPackageActions.getDocumentPackages.actions.start({
        identifier: documentPackagesIdentifier,
        refetch: true,
        filterData: {
          where: {
            contractId: contract.id,
            type: EDocumentPackageTypes.Contract,
            status: {
              nin: [
                EDocumentPackageStatus.Revoked,
                EDocumentPackageStatus.Expired,
                EDocumentPackageStatus.Failed,
                EDocumentPackageStatus.Rejected,
                EDocumentPackageStatus.WorkerFailed,
              ],
            },
          },
          order: "createdAt DESC",
        },
        limit: 1,
      }),
    );
  }, []);

  // Rent deposits
  useEffect(() => {
    if (!shouldFetchData) {
      return;
    }

    if (isFetchingRentDeposits) {
      return;
    }

    dispatch(
      rentDepositActions.getRentDeposits.actions.start({
        identifier: rentDepositsIdentifier,
        refetch: true,
        extraFilterData: {
          where: {
            basicContractId: contract.id,
            status: { neq: ERentDepositStatus.Cancelled },
          },
          order: "createdAt DESC",
        },
        limit: 1,
      }),
    );
  }, []);

  // Payment orders
  useEffect(() => {
    if (!shouldFetchData) {
      return;
    }

    if (isFetchingPaymentOrdersCount) {
      return;
    }

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: contractsPaymentOrdersCountIdentifier,
        countBase: appendWhere("/payment-orders/count", {
          and: [{ contractId: contract.id }, { type: EPaymentOrderType.Rent }],
        }),
      }),
    );
  }, []);

  // Build checklist items
  const checklistItems = [
    getIndexationMessage({
      property,
      contract,

      // Callbacks
      onIndexContract,

      // Config
      internalModeEnabled,
    }),
    getTemplateDocumentContractMessage({
      property,
      contract,

      // Callbacks
      onCreateContractFromTemplateDocument,
      onShowDocument,
      onShowTemplateDocument,

      // Update
      updateContract,

      // Config
      isBroker,
      templates,
      isFetchingTemplates: isFetchingTemplates || false,
      contractTemplateDocuments,
      isFetchingContractTemplateDocuments:
        isFetchingContractTemplateDocuments || false,
    }),
    getDocumentPackageMessage({
      property,
      contract,

      // Callbacks
      onShowDocumentPackage,

      // Actions
      updateContract,

      // Config
      documentPackage: documentPackages?.[0],
      isFetchingDocumentPackage: isFetchingDocumentPackage || false,
    }),
    getRentDepositMessage({
      property,
      contract,

      // Callbacks
      onShowRentDeposit,

      // Actions
      updateContract,

      // Config
      rentDeposit: rentDeposits?.[0],
      isFetchingRentDeposit: isFetchingRentDeposits || false,
    }),
    getPaymentOrderMessage({
      property,
      contract,

      // Actions
      updateContract,

      // Config
      isBroker,
      paymentOrdersCount,
      isFetchingPaymentOrdersCount: isFetchingPaymentOrdersCount || false,
    }),
    getPropertyInspectionReportMessage({
      property,
      contract,

      // Callbacks
      onOrderPropertyInspectionReport,

      // Actions
      updateContract,

      // Config
      partnerships,
    }),
    getInsuranceMessage({
      property,
      contract,

      // Callbacks
      onShowInsurance,
      onRequestInsurance,

      // Actions
      updateContract,

      // Config
      isBroker,
      hasInsuranceForBroker,
      internalModeEnabled,
    }),
    getMoveServiceMessage({
      property,
      contract,

      // Callbacks
      onCreateMoveRequest,
      onShowMoveRequest,

      // Actions
      updateContract,

      // Config
      isBroker,
      hasMoveServiceForBroker,
      internalModeEnabled,
    }),
    getRegistrationMessage({
      property,
      contract,

      // Callbacks
      onShowRentRegistration,

      // Actions
      updateContract,
    }),
  ];

  return compact(checklistItems);
};
