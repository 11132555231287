var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Platform } from "react-native";
import { getFormattedChatDate, getTitle, } from "../../../reduxV2/chats/chat.utils";
import images from "../../assets";
import * as S from "./ChatListItem.styles";
var ChatListItem = function (_a) {
    var onPress = _a.onPress, chat = _a.chat, mostRecentMessage = _a.mostRecentMessage, _b = _a.isActiveChat, isActiveChat = _b === void 0 ? false : _b;
    var members = chat.members, lastMessage = chat.lastMessage, unreadCounter = chat.unreadCounter;
    var isGroupChat = members.length > 2;
    var message = mostRecentMessage || lastMessage;
    var hasUnreadMessages = unreadCounter > 0;
    var isWeb = Platform.OS === "web";
    var handlePressChat = function () { return onPress(chat); };
    var renderUnreadIndicator = function () {
        var hasUnreadMessages = unreadCounter > 0;
        if (!hasUnreadMessages) {
            return null;
        }
        return (_jsx(S.UnreadIndicatorWrap, { children: _jsx(S.UnreadIndicator, {}) }));
    };
    return (_jsx(S.Wrap, { children: _jsx(S.ChatListItem, __assign({ title: getTitle({ chat: chat }), titleNumberOfLines: 1, subtitle: message === null || message === void 0 ? void 0 : message.text, subtitleNumberOfLines: 2, onPress: handlePressChat, showDisclosureIcon: true, iconLeft: isGroupChat ? images.ICO_CHAT_MULTIPLE : images.ICO_CHAT_SINGLE, iconLeftIsRounded: false, renderComponentRight: renderUnreadIndicator, disclosureInfo: message && getFormattedChatDate(message), suppressTouchableStyle: true, forceTouchableStyle: hasUnreadMessages }, (isWeb
            ? {
                isArchived: !!chat.archivedAt,
                isActive: isActiveChat,
                isActiveLink: isActiveChat,
            }
            : { isArchived: !!chat.archivedAt, isActive: isActiveChat }))) }));
};
// eslint-disable-next-line import/no-default-export
export default ChatListItem;
