import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useEffect, useState } from "react";
export function useWindowDimension() {
    var _a = useState([
        window.innerWidth,
        window.innerHeight,
    ]), dimension = _a[0], setDimension = _a[1];
    var dimensionDebounced = useDebounce(dimension)[0];
    useEffect(function () {
        var debouncedResizeHandler = function () {
            setDimension([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener("resize", debouncedResizeHandler);
        return function () { return window.removeEventListener("resize", debouncedResizeHandler); };
    }, []);
    return dimensionDebounced;
}
