import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as paymentActions from "../../../redux/payment/payment.actions";
import * as paymentSelectors from "../../../redux/payment/payment.selectors";
import * as paymentRequestActions from "../../../reduxV2/paymentRequest/paymentRequest.actions";
import * as paymentRequestSelectors from "../../../reduxV2/paymentRequest/paymentRequest.selectors";
export var PaymentRequestFetch = function (props) {
    var renderLoading = props.renderLoading, renderError = props.renderError, renderNotLoaded = props.renderNotLoaded, renderPaymentRequest = props.renderPaymentRequest, id = props.id, publicId = props.publicId, paymentRequestV2Enabled = props.paymentRequestV2Enabled;
    var dispatch = useDispatch();
    var paymentRequest = useSelector(function (state) {
        if (paymentRequestV2Enabled) {
            if (publicId) {
                return paymentRequestSelectors.getGrantAccessPaymentRequest(state, id);
            }
            return paymentRequestSelectors.getDetail(state, id);
        }
        return paymentSelectors.getPaymentRequest(state, id);
    });
    var isFetchingPaymentRequest = useSelector(function (state) {
        if (paymentRequestV2Enabled) {
            if (publicId) {
                return paymentRequestSelectors.isFetchingGrantAccessPaymentRequest(state, id);
            }
            return paymentRequestSelectors.isFetchingDetail(state, id);
        }
        return paymentSelectors.getIsFetchingPaymentRequest(state, id);
    });
    var fetchErrorPaymentRequest = useSelector(function (state) {
        if (paymentRequestV2Enabled) {
            if (publicId) {
                return paymentRequestSelectors.getGrantAccessPaymentRequestError(state, id);
            }
            return paymentRequestSelectors.fetchDetailError(state, id);
        }
        return paymentSelectors.getFetchErrorPaymentRequest(state, id);
    });
    // Data fetch effect
    useEffect(function () {
        fetchData();
    }, [id, publicId]); // Dependency array to refetch if id or publicId changes
    var fetchData = function () {
        if (paymentRequestV2Enabled) {
            if (publicId) {
                dispatch(paymentRequestActions.grantAccessPaymentRequestStart.getAction({
                    id: id,
                    data: { publicId: publicId },
                }));
                return;
            }
            dispatch(paymentRequestActions.getDetailStart.getAction({
                id: id,
                customPath: "payment-requests/".concat(id, "/external-detail"),
            }));
            return;
        }
        dispatch(paymentActions.getPaymentRequest.actions.start({ id: id, publicId: publicId }));
    };
    // Render
    if (paymentRequest) {
        return renderPaymentRequest ? renderPaymentRequest(paymentRequest) : null;
    }
    if (isFetchingPaymentRequest) {
        return renderLoading ? renderLoading() : null;
    }
    if (fetchErrorPaymentRequest) {
        return renderError
            ? renderError(fetchData, fetchErrorPaymentRequest)
            : null;
    }
    return renderNotLoaded ? renderNotLoaded() : null;
};
