import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import {
  Divider,
  IMenuAction,
  Stack,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { ECaptionVariant } from "../../types";
import { AccountListItem } from "../AccountListItem";

export interface IProps {
  accounts: IAccount[];
  getActions?: (params: { accountIds: number[] }) => IMenuAction[];
  getSubtitle?: (
    accountIds: number[],
    contact?: IContact,
  ) =>
    | {
        subTitle: React.ReactNode | undefined;
        subTitleColor?: ECaptionVariant;
      }
    | undefined;
}

export const AccountList = ({
  accounts = [],
  getSubtitle,
  getActions,
}: IProps) => {
  return (
    <Stack
      direction="column"
      gap={2}
      divider={<Divider orientation="horizontal" />}
    >
      {accounts.map(account => (
        <AccountListItem
          account={account}
          getSubtitle={getSubtitle}
          getActions={getActions}
        />
      ))}
    </Stack>
  );
};
