import * as address from "./address";
import * as array from "./array";
import * as components from "./components";
import * as contact from "./contact";
import * as contract from "./contract";
import * as date from "./date";
import * as debug from "./debug";
import * as document from "./document";
import * as file from "./file";
import * as general from "./general";
import * as i18n from "./i18n";
import * as iban from "./iban.utils";
import * as members from "./members";
import * as object from "./object";
import * as paymentRequest from "./paymentRequest";
import * as phone from "./phone";
import * as properties from "./properties";
import * as query from "./query";
import * as registration from "./registration";
import * as rentDeposit from "./rentDeposit";
import * as text from "./text";
import * as user from "./user";
export { address, array, components, contact, contract, date, debug, document, file, general, i18n, iban, members, object, paymentRequest, phone, properties, query, registration, rentDeposit, text, user, };
// eslint-disable-next-line import/no-default-export
export default {
    address: address,
    array: array,
    components: components,
    file: file,
    contact: contact,
    contract: contract,
    date: date,
    debug: debug,
    document: document,
    general: general,
    i18n: i18n,
    iban: iban,
    members: members,
    object: object,
    paymentRequest: paymentRequest,
    phone: phone,
    properties: properties,
    query: query,
    rentDeposit: rentDeposit,
    registration: registration,
    text: text,
    user: user,
};
