var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { appendFilter } from "../../utils/api.utils";
import api from "../../utils/api/api.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "../../utils/date.utils";
export var FETCH_LIMIT = 20;
export var getPricing = function (pricingId) {
    return api.get("/pricings/".concat(pricingId));
};
export var createPricing = function (data) {
    return api.post("/pricings", {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
    });
};
export var updatePricing = function (pricingId, data) {
    return api.patch("/pricings/".concat(pricingId), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
    });
};
export var deletePricing = function (pricingId) {
    return api.delete("/pricings/".concat(pricingId));
};
export var getPricings = function (skip, filterData, limit) {
    if (filterData === void 0) { filterData = {}; }
    if (limit === void 0) { limit = FETCH_LIMIT; }
    var filter = __assign({ order: "createdAt DESC", limit: limit, skip: skip }, filterData);
    return api.get(appendFilter("/pricings", filter));
};
