import { ELocale } from "./i18n.types";
import { EPropertyMemberTypes } from "./property.types";
export var EGroupActionRoleType;
(function (EGroupActionRoleType) {
    EGroupActionRoleType["executor"] = "EXECUTOR";
    EGroupActionRoleType["follower"] = "FOLLOWER";
    EGroupActionRoleType["viewer"] = "VIEWER";
    EGroupActionRoleType["participant"] = "PARTICIPANT";
    EGroupActionRoleType["recipient"] = "RECIPIENT";
})(EGroupActionRoleType || (EGroupActionRoleType = {}));
export var EGroupActionAccount;
(function (EGroupActionAccount) {
    EGroupActionAccount[EGroupActionAccount["Tenant"] = 9999999] = "Tenant";
    EGroupActionAccount[EGroupActionAccount["Owner"] = 9999998] = "Owner";
    EGroupActionAccount[EGroupActionAccount["Myself"] = 9999997] = "Myself";
})(EGroupActionAccount || (EGroupActionAccount = {}));
var getGroupActionAccountKey = function (enumValue) {
    return Object.keys(EGroupActionAccount)[Object.values(EGroupActionAccount).indexOf(enumValue)];
};
// Define mapping between EGroupActionAccount keys and EPropertyMemberTypes values
var groupAccountToMemberTypeMapping = {
    Tenant: EPropertyMemberTypes.Tenant,
    Owner: EPropertyMemberTypes.Owner,
    Myself: "MYSELF",
};
export var getGroupActionTypes = function (groupActionAccounts) {
    return groupActionAccounts.map(function (x) { return groupAccountToMemberTypeMapping[getGroupActionAccountKey(x)]; });
};
export var getRole = function (selectedMembers, roleType) {
    var members = getGroupActionTypes(selectedMembers);
    switch (roleType) {
        case EGroupActionRoleType.recipient:
            return { recipient: members };
        case EGroupActionRoleType.participant:
            return { participant: members };
        case EGroupActionRoleType.viewer:
            return { viewer: members };
        case EGroupActionRoleType.executor:
            return { executor: members };
        case EGroupActionRoleType.follower:
            return { follower: members };
        default:
            return {};
    }
};
export var EGroupActionType;
(function (EGroupActionType) {
    EGroupActionType["Notification"] = "notification";
    EGroupActionType["Sms"] = "sms";
    EGroupActionType["Email"] = "email";
    EGroupActionType["Task"] = "task";
    EGroupActionType["Document"] = "document";
    EGroupActionType["Chat"] = "chat";
})(EGroupActionType || (EGroupActionType = {}));
export var EGroupActionModalStep;
(function (EGroupActionModalStep) {
    EGroupActionModalStep["SelectAction"] = "SELECT_ACTION";
    EGroupActionModalStep["SelectDocument"] = "SELECT_DOCUMENT";
    EGroupActionModalStep["SelectMembers"] = "SELECT_MEMBERS";
    EGroupActionModalStep["AddContent"] = "ADD_CONTENT";
    EGroupActionModalStep["Finished"] = "FINISHED";
})(EGroupActionModalStep || (EGroupActionModalStep = {}));
