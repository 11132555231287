import { IPayIn } from "@rentiohq/shared-frontend/dist/types/payin.types";
import {
  Activities,
  Card,
  DisplayText,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { ts } from "../../../../../../services";
import { EPaymentPayInTab } from "../../PaymentPayIn.types";
import { getActivitiesFilter } from "../../PaymentPayIn.utils";

interface IProps {
  payIn: IPayIn;
  setTab: (tab: EPaymentPayInTab) => void;
}

const PaymentPayInHistory: React.FC<IProps> = props => {
  const { payIn } = props;

  return (
    <Card
      space={ESpacings.base}
      heading={
        <DisplayText size="extraSmall" space="tight">
          {ts.system("history")}
        </DisplayText>
      }
    >
      <Activities
        identifier={`pay_in-${payIn.id}`}
        filter={getActivitiesFilter(payIn)}
        hasFetchMore={true}
        isCompact={true}
      />
    </Card>
  );
};

// eslint-disable-next-line import/no-default-export
export default PaymentPayInHistory;
