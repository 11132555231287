export var ERecipientType;
(function (ERecipientType) {
    ERecipientType["Owner"] = "OWNER";
    ERecipientType["Tenant"] = "TENANT";
    ERecipientType["Broker"] = "BROKER";
})(ERecipientType || (ERecipientType = {}));
export var ESendType;
(function (ESendType) {
    ESendType["Tenant"] = "TENANT";
    ESendType["Signing"] = "SIGNING";
})(ESendType || (ESendType = {}));
