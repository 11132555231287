import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import * as propertySelectorsV2 from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { EPropertyMemberTypes } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  DisplayText,
  ESpacings,
  Icon,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { findManagerOrAssignedEmployee } from "@rentiohq/web-shared/dist/utils/members";
import { spacing } from "@rentiohq/web-theme";
import { memo, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ts } from "../../../../../../services";
import * as S from "./OfficeManagersCard.styled";
import { SelectEmployeeDropdown } from "./SelectEmployeeDropdown";

interface IProps {
  propertyId: number;
}

interface IAccountWithRole {
  account: IAccount | undefined;
  role: EPropertyMemberTypes;
}

const getIdentifier = (role: EPropertyMemberTypes, accountId?: number) => {
  return `${accountId ?? "empty"}-${role}`;
};

const OfficeManagersCard = ({ propertyId }: IProps) => {
  const [accountIdsToShowDropdownFor, setAccountIdsToShowDropdownFor] =
    useState<string[]>([]);

  const { isBroker, broker } = authHooks.useSelf();

  const property = useSelector((state: propertySelectorsV2.IPartialRootState) =>
    propertySelectorsV2.getDetail(state, propertyId),
  );

  // Hooks
  const { updateEmployees } = propertyHooks.useUpdate({ id: propertyId });
  const { employees: employeeAccounts } = brokerHooks.useGetBrokerEmployees();

  const officeManagers: IAccountWithRole[] = useMemo(() => {
    if (!property || !broker) return [];
    const { members } = property;

    const technicalManager = findManagerOrAssignedEmployee(
      members,
      EPropertyMemberTypes.TechnicalManager,
      broker,
    );
    const financialManager = findManagerOrAssignedEmployee(
      members,
      EPropertyMemberTypes.FinancialManager,
      broker,
    );
    const indexationManager = findManagerOrAssignedEmployee(
      members,
      EPropertyMemberTypes.IndexationManager,
      broker,
    );
    const administrativeManager = findManagerOrAssignedEmployee(
      members,
      EPropertyMemberTypes.AdministrativeManager,
      broker,
    );

    const managers: IAccountWithRole[] = [];
    if (financialManager) {
      managers.push({
        account: financialManager ?? undefined,
        role: EPropertyMemberTypes.FinancialManager,
      });
    }
    if (technicalManager) {
      managers.push({
        account: technicalManager ?? undefined,
        role: EPropertyMemberTypes.TechnicalManager,
      });
    }
    if (indexationManager) {
      managers.push({
        account: indexationManager ?? undefined,
        role: EPropertyMemberTypes.IndexationManager,
      });
    }
    managers.push({
      account: administrativeManager ?? undefined,
      role: EPropertyMemberTypes.AdministrativeManager,
    });

    return managers;
  }, [property, employeeAccounts]);

  const chooseEmployeeForPosition = (
    newRole: EPropertyMemberTypes,
    employeeAccountId: number | null,
  ) => {
    updateEmployees({
      employeesToAdd: [
        {
          accountId: broker?.accountId!,
          employeeAccountId,
          roles: [newRole],
        },
      ],
      employeesToRemove: [],
    });
  };

  const showDropdownForAccount = (
    role: EPropertyMemberTypes,
    accountId?: number,
  ) => {
    setAccountIdsToShowDropdownFor([
      ...accountIdsToShowDropdownFor,
      getIdentifier(role, accountId),
    ]);
  };

  const hideDropdownForAccount = (
    role: EPropertyMemberTypes,
    accountId?: number,
  ) => {
    setAccountIdsToShowDropdownFor(
      accountIdsToShowDropdownFor.filter(
        id => getIdentifier(role, accountId) !== id,
      ),
    );
  };

  const shouldShowDropdown = (role: EPropertyMemberTypes, accountId?: number) =>
    accountIdsToShowDropdownFor.includes(getIdentifier(role, accountId));

  const getActions = (officeManager: IAccountWithRole) => {
    const { account, role } = officeManager;
    const actions: IAction[] = [
      {
        media: <Icon source="edit" size="small" />,
        content: getLocalizedText("property.case_manager.cta.select_other"),
        onClick: () => {
          showDropdownForAccount(role, account ? account.id : undefined);
        },
      },
    ];

    if (account?.id && account.id !== broker!.accountId) {
      actions.push({
        content: (
          <TextStyle variation="negative">
            {getLocalizedText("property.case_manager.cta.remove")}
          </TextStyle>
        ),
        media: <Icon source="bin" color="red" />,
        onClick: () => {
          chooseEmployeeForPosition(role, null);
        },
      });
    }
    return actions;
  };

  if (
    !property ||
    !isBroker ||
    !broker ||
    !employeeAccounts ||
    !officeManagers
  ) {
    return null;
  }

  return (
    <Card
      heading={
        <DisplayText size="extraSmall" space="tight">
          {ts.propertyDashboardManagersHeading()}
        </DisplayText>
      }
      space={ESpacings.loose}
    >
      {officeManagers && (
        <ResourceList
          items={officeManagers}
          renderItem={officeManager => {
            const { role, account } = officeManager;
            return (
              <ResourceListItem
                mediaSize="medium"
                media={<Icon source={"profile"} />}
                boxProps={{ py: ESpacings.base }}
                actions={getActions(officeManager)}
              >
                <S.OfficeRole>
                  {account && account.id !== broker.accountId
                    ? getName(account)
                    : getLocalizedText("role.no_appointed_employee")}
                  <S.RoleText>
                    {getLocalizedText(`role.${role.toLowerCase()}`)}
                  </S.RoleText>
                  {role === EPropertyMemberTypes.AdministrativeManager &&
                    !account && (
                      <div style={{ marginTop: spacing("tight") }}>
                        <TextStyle variation="subdued">
                          {getLocalizedText(
                            "property.case_manager.feature_info",
                          )}
                        </TextStyle>
                      </div>
                    )}
                </S.OfficeRole>
                {shouldShowDropdown(role, account?.id) && (
                  <SelectEmployeeDropdown
                    accountId={account?.id}
                    employeeAccounts={employeeAccounts}
                    hideDropdown={() =>
                      hideDropdownForAccount(role, account?.id)
                    }
                    onSelectEmployee={selectedOption => {
                      if (selectedOption.value) {
                        const newEmployeeId = Number(selectedOption.value);
                        chooseEmployeeForPosition(role, newEmployeeId);
                      }

                      hideDropdownForAccount(role, account?.id);
                    }}
                  />
                )}
              </ResourceListItem>
            );
          }}
        />
      )}
    </Card>
  );
};

// eslint-disable-next-line import/no-default-export
export default memo(OfficeManagersCard);
