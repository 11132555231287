var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ThemeConsumer } from "@rentiohq/web-theme";
import { rem, stripUnit, transitions } from "polished";
import ReactSelect, { components as selectComponents } from "react-select";
import * as t from "../../../../services/translationService";
import { Icon } from "../../../Icon";
export var Select = function (_a) {
    var label = _a.label, name = _a.name, noResultsMessage = _a.noResultsMessage, value = _a.value, _b = _a.disabled, disabled = _b === void 0 ? false : _b, createMessage = _a.createMessage, helpText = _a.helpText, id = _a.id, error = _a.error, _c = _a.labelHidden, labelHidden = _c === void 0 ? false : _c, options = _a.options, defaultOptions = _a.defaultOptions, optionRender = _a.optionRender, noOptionRender = _a.noOptionRender, _d = _a.onChange, onChange = _d === void 0 ? null : _d, _e = _a.components, components = _e === void 0 ? {} : _e, allowNewOption = _a.allowNewOption, _f = _a.isDropdownIndicatorHidden, isDropdownIndicatorHidden = _f === void 0 ? false : _f, field = __rest(_a, ["label", "name", "noResultsMessage", "value", "disabled", "createMessage", "helpText", "id", "error", "labelHidden", "options", "defaultOptions", "optionRender", "noOptionRender", "onChange", "components", "allowNewOption", "isDropdownIndicatorHidden"]);
    var DropdownIndicator = function (props) {
        return isDropdownIndicatorHidden
            ? null
            : selectComponents.DropdownIndicator && (_jsx(selectComponents.DropdownIndicator, __assign({}, props, { children: _jsx(Icon, { source: "chevronDown" }) })));
    };
    var Option = function (props) {
        return (_jsx(selectComponents.Option, __assign({}, props, { children: optionRender ? optionRender(props) : props.children })));
    };
    var NoOptionsMessage = function (props) {
        return (_jsx(selectComponents.NoOptionsMessage, __assign({}, props, { children: noOptionRender ? noOptionRender(props) : props.children })));
    };
    if (typeof allowNewOption !== "undefined") {
        field.isValidNewOption = function () { return allowNewOption; };
    }
    else {
        noResultsMessage = t.searchIncentive();
    }
    var handleChange = function (selectedOption) {
        // setFieldValue(name, fieldValue)
        if (onChange) {
            onChange(selectedOption);
        }
    };
    var addNoOptionsMessage = function () {
        return noResultsMessage ? noResultsMessage : t.searchNoResultsDefault();
    };
    var addCreateMessage = function () {
        return createMessage ? createMessage : t.searchCreateCTADefault();
    };
    return (_jsx(ThemeConsumer, { children: function (theme) {
            var getOptionBackgroundColor = function (state) {
                if (state.isSelected) {
                    return theme.getColor("primary");
                }
                else if (state.isFocused) {
                    return theme.getColor("primary", 0);
                }
                else {
                    theme.getColor("white");
                }
            };
            var getControlBorderColor = function (state) {
                if (error) {
                    return theme.inputStyles.borderColor.error;
                }
                if (state.isFocused) {
                    return theme.inputStyles.borderColor.focus;
                }
                else {
                    return theme.inputStyles.borderColor.base;
                }
            };
            var getControlBoxShadow = function (state) {
                if (error && state.isFocused) {
                    return theme.inputStyles.boxShadowColor.error;
                }
                if (state.isFocused) {
                    return theme.inputStyles.boxShadowColor.focus;
                }
                else {
                    return "transparent";
                }
            };
            var initValue = null;
            if (options) {
                initValue = options.find(function (option) { return option.value === value; });
            }
            if (defaultOptions instanceof Array) {
                initValue = defaultOptions.find(function (option) { return option.value === value; });
            }
            // const currentPayer: any = members.find(member => member.id === values.orders[index].payer) || {}
            return (_jsx(ReactSelect, __assign({}, field, { id: id, options: options, defaultOptions: defaultOptions, value: initValue, components: __assign({ DropdownIndicator: DropdownIndicator, Option: Option, NoOptionsMessage: NoOptionsMessage, IndicatorSeparator: null }, components), onChange: handleChange, menuShouldScrollIntoView: true, formatCreateLabel: addCreateMessage, noOptionsMessage: addNoOptionsMessage, isDisabled: disabled, 
                // isValidNewOption={addNewOption}
                styles: {
                    option: function (base, state) {
                        var isNew = state.data.__isNew__;
                        var optionStyles = isNew
                            ? __assign(__assign({}, base), { backgroundColor: "".concat(theme.getColor("primary", 600)), borderRadius: "".concat(theme.borderRadius("small")), color: theme.getColor("white"), lineHeight: 1, textAlign: "center", margin: "".concat(theme.spacing("extraTight")), marginBottom: 0, padding: "".concat(theme.spacing()), width: "auto", "&:hover": {
                                    background: theme.getColor("primary"),
                                } }) : __assign(__assign({}, base), { backgroundColor: getOptionBackgroundColor(state), color: state.isSelected
                                ? theme.getColor("white")
                                : theme.getColor("gray", 1000), "&:hover": {
                                background: theme.getColor("primary", 0),
                                color: theme.getColor("gray", 1000),
                            }, "&:focus": {
                                background: theme.getColor("primary", 0),
                                color: theme.getColor("gray", 1000),
                            } });
                        return optionStyles;
                    },
                    dropdownIndicator: function (base) { return (__assign(__assign({}, base), { padding: "calc(".concat(theme.spacing("tight"), " - 1px)") })); },
                    singleValue: function (base) { return (__assign(__assign({}, base), { marginLeft: 0, marginRight: 0, maxWidth: "calc(100% - ".concat(theme.inputStyles.spacing.horizontal, ")") })); },
                    container: function (base) { return (__assign(__assign({}, base), { zIndex: 2 })); },
                    valueContainer: function (base) { return (__assign(__assign({}, base), { padding: "".concat(theme.inputStyles.spacing.vertical, " 0"), paddingLeft: theme.inputStyles.spacing.horizontal, lineHeight: rem(40 - 2 - Number(stripUnit(theme.spacing("tight"))) * 2, theme.baseFontSize) })); },
                    input: function (base) { return (__assign(__assign({}, base), { margin: 0, padding: 0 })); },
                    placeholder: function (base) { return (__assign(__assign({}, base), { color: theme.getColor("gray", 200) })); },
                    control: function (base, state) { return (__assign(__assign(__assign({}, base), transitions("border-color 250ms ease-in", "box-shadow 150ms ease-in")), { borderRadius: theme.inputStyles.borderRadius, outline: state.isFocused && "none", border: "1px solid ".concat(getControlBorderColor(state)), boxShadow: "0 0 2px 1px ".concat(getControlBoxShadow(state)), backgroundColor: error
                            ? "".concat(theme.inputStyles.backgroundColor.error)
                            : "".concat(theme.inputStyles.backgroundColor.base), "&:hover": {
                            border: !error &&
                                "1px solid ".concat(theme.inputStyles.borderColor.hover),
                        } })); },
                    noOptionsMessage: function (base) { return (__assign(__assign({}, base), { textAlign: "left" })); },
                    menuPortal: function (base) { return (__assign(__assign({}, base), { zIndex: 200 })); },
                } })));
        } }));
};
