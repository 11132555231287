import { getFormattedDate } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import {
  EInsuranceExternalStatus,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import StatusDot, { EStatus } from "../StatusDot";

interface IProps {
  contract: IContract;
  hasFeature: boolean;
}

const StatusDotInsurance: React.FC<IProps> = props => {
  const { contract, hasFeature } = props;

  // Helpers
  const getStatus = () => {
    if (contract.manuallyCompletedInsurance) {
      return EStatus.Success;
    }

    if (!hasFeature) {
      return EStatus.Error;
    }

    switch (contract.insuranceExternalStatus || EInsuranceExternalStatus.None) {
      case EInsuranceExternalStatus.None:
      case EInsuranceExternalStatus.Failed:
        return EStatus.Error;

      case EInsuranceExternalStatus.Created:
      case EInsuranceExternalStatus.Pending:
        return EStatus.Warning;

      case EInsuranceExternalStatus.Completed:
        return EStatus.Success;

      default:
        return EStatus.None;
    }
  };

  // Render
  const renderTooltipContent = () => {
    if (contract.manuallyCompletedInsurance) {
      return getLocalizedText(contract.manuallyCompletedInsurance);
    }

    if (!hasFeature) {
      return getLocalizedText("system.unchecked");
    }

    const {
      insuranceExternalStatus = EInsuranceExternalStatus.None,
      insuranceLeadCompletedDate,
    } = contract;

    return getLocalizedText(
      `insurance.status.${insuranceExternalStatus}`.toLowerCase(),
      {
        date: insuranceLeadCompletedDate
          ? getFormattedDate(insuranceLeadCompletedDate)
          : "",
      },
    );
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={renderTooltipContent()} />
  );
};

// eslint-disable-next-line import/no-default-export
export default StatusDotInsurance;
