import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as chatHooks from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.hooks";
import { IChat } from "@rentiohq/shared-frontend/dist/types/chat.types";
import {
  Button,
  DisplayText,
  Icon,
  Stack,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import * as t from "../../../../../../services/translationService";
import * as S from "./ChatArchivedCard.styled";

interface IProps {
  chat: IChat;
  children?: React.ReactNode;
}

export const ChatArchivedCard: React.FC<IProps> = ({
  chat,
  children,
}: IProps) => {
  const { unarchive } = chatHooks.useUnarchive({ id: chat.id });

  const handleUnarchiveProperty = () => {
    if (!chat) {
      return;
    }

    if (chat.archivedAt) {
      unarchive({});
    }
  };

  if (!chat?.archivedAt) {
    return <>{children}</>;
  }

  return (
    <S.Wrap>
      <S.ChildrenWrap>{children}</S.ChildrenWrap>
      <S.Card>
        <Stack alignment="center">
          <Icon source="archive" size="large" color="red"></Icon>
          <Stack.Item>
            <DisplayText element="h3" size="medium" space="extraTight">
              {t.chatArchivedCardTitle()}
            </DisplayText>

            <TextStyle element="p" size="medium" variation="subdued">
              {t.chatArchivedCardContent()}
            </TextStyle>

            <Spacer weight={ESpacerWeight.W08} />

            <Button onClick={handleUnarchiveProperty} appearance="link">
              {t.chatArchivedCardCta()}
            </Button>
          </Stack.Item>
        </Stack>
      </S.Card>
    </S.Wrap>
  );
};
