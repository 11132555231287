import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import {
  IConfirm,
  registerConfirmTrigger,
} from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  DateFieldInternal,
  Modal,
  TextField,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import React from "react";

interface IConfirmProviderProps {
  children?: React.ReactNode;
}

export const ConfirmProvider: React.FC<IConfirmProviderProps> = ({
  children,
}) => {
  const didInitialize = React.useRef<boolean>(false);
  const [confirm, setConfirm] = React.useState<IConfirm>();
  const [inputValue, setInputValue] = React.useState<string | Date | undefined>(
    undefined,
  );

  // Lifecycle methods
  React.useEffect(() => {
    if (didInitialize.current) {
      return;
    }
    didInitialize.current = true;

    const confirmTrigger = (newConfirm: IConfirm) => {
      setConfirm(newConfirm);
      setInputValue(newConfirm.inputValueProps?.initialValue);
    };
    registerConfirmTrigger(confirmTrigger);
  }, []);

  // Event handlers
  const handleOnClose = () => {
    if (!confirm) {
      return;
    }

    confirm.onClose?.();

    setConfirm(undefined);
    setInputValue(undefined);
  };

  const handleClick = (
    onClick?: (params?: { inputValue?: string | Date }) => void,
  ) => {
    onClick?.({
      inputValue,
    });

    setConfirm(undefined);
    setInputValue(undefined);
  };

  // Helpers
  const modalActions = React.useMemo(() => {
    if (!confirm) {
      return;
    }

    const { primaryActions, secondaryAction } = confirm;
    let actions: IAction[] = [];

    if (secondaryAction) {
      actions.push({
        content: secondaryAction.title,
        onClick: () => {
          handleClick(secondaryAction.onPress);
        },
        appearance: "outline",
        size: "small",
      });
    } else {
      actions.push({
        content: getLocalizedText("system.cancel"),
        onClick: handleOnClose,
        appearance: "outline",
        size: "small",
      });
    }

    primaryActions?.forEach(primaryAction => {
      actions.push({
        content: primaryAction.title,
        onClick: () => {
          handleClick(primaryAction.onPress);
        },
        appearance: "primary",
        size: "small",
      });
    });

    return actions;
  }, [confirm, inputValue]);

  const renderInput = () => {
    switch (confirm?.inputValueProps?.type) {
      case "string": {
        const inputValueMapped = inputValue as string | undefined;

        return (
          <>
            <Spacer weight={ESpacerWeight.W16} />
            <TextField
              name={""}
              label={confirm.inputValueProps.label || ""}
              multiline={confirm.inputValueProps.asMultiline}
              optional={confirm.inputValueProps.optional}
              helpText={confirm.inputValueProps.extraInfoValue || ""}
              placeholder={confirm.inputValueProps.placeholder}
              value={inputValueMapped}
              onChange={(event: any) => {
                setInputValue(event.target.value || "");
              }}
            />
          </>
        );
      }

      case "date": {
        const inputValueMapped = inputValue as Date | undefined;

        return (
          <div style={{ height: 280 }}>
            <Spacer weight={ESpacerWeight.W16} />
            <DateFieldInternal
              placeholder={confirm.inputValueProps.placeholder}
              info={confirm.inputValueProps.extraInfoValue}
              value={inputValueMapped}
              optional={confirm.inputValueProps.optional}
              onChange={(newValue?: Date) => {
                setInputValue(newValue);
              }}
              maxDate={confirm.inputValueProps.maxDate}
            />
          </div>
        );
      }

      default:
        break;
    }
  };

  const renderContent = () => {
    const input = renderInput();

    if (!confirm?.info && !input) {
      return undefined;
    }

    return (
      <>
        {confirm?.info}
        {input}
      </>
    );
  };

  return (
    <>
      {children}
      {confirm && (
        <Modal
          type={confirm.type}
          onClose={handleOnClose}
          actions={modalActions}
          heading={confirm.title}
          hasDismiss={false}
          shouldCloseOnOverlayClick={!confirm.secondaryAction}
          width="medium"
          {...confirm.modalProps}
        >
          {renderContent()}
        </Modal>
      )}
    </>
  );
};
