import { ITask } from "@rentiohq/shared-frontend/dist/types/task.types";
import styled from "@rentiohq/web-theme";

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GrayText = styled.p`
  color: ${({ theme }) => theme.getColor("gray", 400)};
`;

export const TaskActive = styled.div<{ task: ITask }>`
  opacity: ${({ task }) => (task.finishedAt ? 0.5 : 1)};
  display: flex;
  justify-content: start;
`;
