import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ts } from "../../../../services";
import StatusDot from "../StatusDot";
import { EStatus } from "../StatusDot/StatusDot.types";

interface IProps {
  contract: IContract;
}

const StatusDotContractDocumentPackage = (props: IProps) => {
  const { contract } = props;

  // Helpers
  const getStatus = () => {
    const {
      manuallyCompletedLocationDescription,
      locationDescriptionInRequestedAt,
      locationDescriptionOutRequestedAt,
    } = contract;

    if (manuallyCompletedLocationDescription) {
      return EStatus.Success;
    }

    if (locationDescriptionInRequestedAt || locationDescriptionOutRequestedAt) {
      return EStatus.Success;
    }

    return EStatus.Error;
  };

  // Render
  const renderTooltipContent = () => {
    const {
      manuallyCompletedLocationDescription,
      locationDescriptionInRequestedAt,
      locationDescriptionOutRequestedAt,
    } = contract;

    if (manuallyCompletedLocationDescription) {
      return getLocalizedText(manuallyCompletedLocationDescription);
    }

    if (locationDescriptionInRequestedAt && locationDescriptionOutRequestedAt) {
      return ts.contractCardChecklistLocationDescriptionBothContent({
        values: {
          date: formatDate(contract.locationDescriptionOutRequestedAt!),
        },
      });
    }

    if (locationDescriptionInRequestedAt) {
      return ts.contractCardChecklistLocationDescriptionInContent({
        values: {
          date: formatDate(contract.locationDescriptionInRequestedAt!),
        },
      });
    }

    if (locationDescriptionOutRequestedAt) {
      return ts.contractCardChecklistLocationDescriptionOutContent({
        values: {
          date: formatDate(contract.locationDescriptionOutRequestedAt!),
        },
      });
    }

    return getLocalizedText("status.location_description.empty");
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={renderTooltipContent()} />
  );
};

// eslint-disable-next-line import/no-default-export
export default StatusDotContractDocumentPackage;
