import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
export var MultipleStagesWrapper = function (_a) {
    var children = _a.children;
    if (!children)
        return null;
    if (!Array.isArray(children))
        return _jsx(_Fragment, { children: children });
    return (_jsx(_Fragment, { children: children.map(function (c, i) {
            var style = i > 0 ? { paddingTop: "16px" } : undefined;
            return _jsx("div", { style: style, children: c });
        }) }));
};
