var _a;
import { EContractIndexationStatus, } from "../../types/contract.types";
import { EPropertyMemberTypes } from "../../types/property.types";
import { ERegistrationContractStatus, ERegistrationDocumentType, } from "../../types/registration.types";
import { endOfDay } from "../../utils/date-fns.utils";
export var EContractFetchType;
(function (EContractFetchType) {
    EContractFetchType["Active"] = "ACTIVE";
    EContractFetchType["Ended"] = "ENDED";
})(EContractFetchType || (EContractFetchType = {}));
export var EContractCountIdentifier;
(function (EContractCountIdentifier) {
    EContractCountIdentifier["ContractsOverviewPaged"] = "contractsOverviewPaged";
    EContractCountIdentifier["ContractsIndexable"] = "contractsIndexable";
    EContractCountIdentifier["ContractsIndexOwnerAsked"] = "contractsIndexOwnerAsked";
    EContractCountIdentifier["ContractsIndexed"] = "contractsIndexed";
    EContractCountIdentifier["RegistrationsContractsInProgress"] = "registrationsContractsInProgress";
    EContractCountIdentifier["RegistrationsContractsActionRequired"] = "registrationsContractsActionRequired";
    EContractCountIdentifier["RegistrationsContractsRegistered"] = "registrationsContractsRegistered";
    EContractCountIdentifier["RegistrationsAddendaInProgress"] = "registrationsAddendaInProgress";
    EContractCountIdentifier["RegistrationsAddendaActionRequired"] = "registrationsAddendaActionRequired";
    EContractCountIdentifier["RegistrationsAddendaRegistered"] = "registrationsAddendaRegistered";
    EContractCountIdentifier["RegistrationLocationDescriptionInProgress"] = "registrationLocationDescriptionInProgress";
    EContractCountIdentifier["RegistrationLocationDescriptionActionRequired"] = "registrationsLocationDescriptionActionRequired";
    EContractCountIdentifier["RegistrationLocationDescriptionRegistered"] = "registrationLocationDescriptionRegistered";
})(EContractCountIdentifier || (EContractCountIdentifier = {}));
// Contracts
var DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT = [
    ERegistrationContractStatus.Registering,
    ERegistrationContractStatus.Incomplete,
];
var DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT = [
    ERegistrationContractStatus.NeedPayment,
    ERegistrationContractStatus.Rejected,
    ERegistrationContractStatus.Failed,
];
var DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT = [
    ERegistrationContractStatus.Registered,
    ERegistrationContractStatus.RegisteredManually,
    ERegistrationContractStatus.NotApplicable,
];
// Location description
var DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_LOCATION_DESCRIPTION = [
    ERegistrationContractStatus.None,
    ERegistrationContractStatus.Registering,
    ERegistrationContractStatus.Incomplete,
];
var DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_LOCATION_DESCRIPTION = [
    ERegistrationContractStatus.NeedPayment,
    ERegistrationContractStatus.Rejected,
    ERegistrationContractStatus.Failed,
];
var DEFAULT_STATUSES_REGISTRATION_REGISTERED_LOCATION_DESCRIPTION = [
    ERegistrationContractStatus.Registered,
    ERegistrationContractStatus.RegisteredManually,
    ERegistrationContractStatus.NotApplicable,
];
export var ContractFilters = (_a = {},
    _a[EContractCountIdentifier.ContractsIndexable] = {
        filter: {
            and: [
                {
                    stopDate: {
                        gt: endOfDay(new Date()),
                    },
                    indexationStatus: {
                        in: [
                            EContractIndexationStatus.Ready,
                            EContractIndexationStatus.OwnerApproved,
                            EContractIndexationStatus.OwnerDeclined,
                        ],
                    },
                },
            ],
        },
        propertyRolesFilter: [EPropertyMemberTypes.IndexationManager],
    },
    _a[EContractCountIdentifier.ContractsIndexed] = {
        filter: {
            and: [
                {
                    stopDate: {
                        gt: endOfDay(new Date()),
                    },
                    indexationStatus: {
                        in: [EContractIndexationStatus.NotReady],
                    },
                    lastIndexedAt: {
                        isNot: null,
                    },
                },
            ],
        },
        propertyRolesFilter: [EPropertyMemberTypes.IndexationManager],
    },
    _a[EContractCountIdentifier.ContractsIndexOwnerAsked] = {
        filter: {
            and: [
                {
                    stopDate: {
                        gt: endOfDay(new Date()),
                    },
                    indexationStatus: { eq: EContractIndexationStatus.OwnerAsked },
                },
            ],
        },
        propertyRolesFilter: [EPropertyMemberTypes.IndexationManager],
    },
    _a[EContractCountIdentifier.RegistrationsContractsInProgress] = {
        filter: {
            rentContractStatus: {
                in: DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT,
            },
        },
    },
    _a[EContractCountIdentifier.RegistrationsContractsActionRequired] = {
        filter: {
            rentContractStatus: {
                in: DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT,
            },
        },
    },
    _a[EContractCountIdentifier.RegistrationsContractsRegistered] = {
        filter: {
            rentContractStatus: {
                in: DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT,
            },
        },
    },
    _a[EContractCountIdentifier.RegistrationLocationDescriptionInProgress] = {
        filter: {
            locationDescriptionStatus: {
                in: DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_LOCATION_DESCRIPTION,
            },
        },
    },
    _a[EContractCountIdentifier.RegistrationLocationDescriptionActionRequired] = {
        filter: {
            locationDescriptionStatus: {
                in: DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_LOCATION_DESCRIPTION,
            },
        },
    },
    _a[EContractCountIdentifier.RegistrationLocationDescriptionRegistered] = {
        filter: {
            locationDescriptionStatus: {
                in: DEFAULT_STATUSES_REGISTRATION_REGISTERED_LOCATION_DESCRIPTION,
            },
        },
    },
    _a[EContractCountIdentifier.RegistrationsAddendaActionRequired] = {
        filter: {
            and: [
                {
                    status: {
                        in: DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT,
                    },
                },
                { documentType: { eq: ERegistrationDocumentType.Addendum } },
            ],
        },
    },
    _a[EContractCountIdentifier.RegistrationsAddendaInProgress] = {
        filter: {
            and: [
                {
                    status: {
                        in: DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT,
                    },
                },
                { documentType: { eq: ERegistrationDocumentType.Addendum } },
            ],
        },
    },
    _a[EContractCountIdentifier.RegistrationsAddendaRegistered] = {
        filter: {
            and: [
                {
                    status: {
                        in: DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT,
                    },
                },
                { documentType: { eq: ERegistrationDocumentType.Addendum } },
            ],
        },
    },
    _a);
