import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { ETemplateType } from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import { ITemplateDocument } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import { EUsedSigningType } from "@rentiohq/shared-frontend/dist/types/activity.types";
import { EContractMemberTypes } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EDocumentPackageTypes } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { getStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import {
  EDocumentSigningTypes,
  EField,
  IExtraData,
} from "./schema.createDocumentPackage.types";
import {
  customContractSelector,
  customPropertySelector,
} from "./schema.createDocumentPackage.utils.fetchContracts";

export const getInitialValues = (extraData: IExtraData): IValues => {
  const {
    property,
    contract,
    document,
    templateDocument,
    documentPackageType,
  } = extraData;

  const initialValues = {
    [EField.DocumentPackageType]:
      documentPackageType ||
      getDocumentPackageType({
        templateDocument,
      }),

    [EField.Property]:
      property?.id ||
      contract?.propertyId ||
      document?.propertyId ||
      templateDocument?.propertyId,

    [EField.Contract]:
      contract?.id || document?.contractId || templateDocument?.contractId,

    [EField.Documents]: document ? [document.id] : undefined,

    [EField.SigningTypes]: Object.values(EDocumentSigningTypes),
    [EField.Signers]: contract
      ? contract.members
          .filter(member =>
            member.roles.some(role =>
              [
                EContractMemberTypes.Owner,
                EContractMemberTypes.Tenant,
                EContractMemberTypes.Parent,
              ].includes(role),
            ),
          )
          .map(member => member.account.id)
      : undefined,

    [EField.Name]: document
      ? utils.file.removeExtension(document.filename)
      : undefined,
  };

  return initialValues;
};

export const getNavigationTitle = () =>
  utils.i18n.t("document_package.form.navigation.heading");

export const getRelatedContract = (extraData: IExtraData, formData: any) => {
  // const { contract } = extraData;

  // if (contract) {
  //   return contract;
  // }

  const contractId = formData[EField.Contract];
  if (!contractId) {
    return;
  }

  const store = getStore();
  const state = store?.getState();
  if (!state) {
    return;
  }

  return customContractSelector({
    state,
    contractId: formData[EField.Contract],
  });
};

export const getRelatedProperty = (extraData: IExtraData, formData: any) => {
  // const { property } = extraData;
  // if (property) {
  //   return property;
  // }

  const propertyId = formData[EField.Property];
  if (!propertyId) {
    return undefined;
  }

  const store = getStore();
  const state = store?.getState();
  if (!state) {
    return;
  }

  return customPropertySelector({
    state,
    propertyId: +propertyId,
  });
};

export const getSigningTypes = (signingTypeGroups: EDocumentSigningTypes[]) => {
  return signingTypeGroups.reduce(
    (types: EUsedSigningType[], signingTypeGroup: EDocumentSigningTypes) => {
      switch (signingTypeGroup) {
        case EDocumentSigningTypes.ID:
          return [...types, ...[EUsedSigningType.Itsme, EUsedSigningType.BeId]];

        case EDocumentSigningTypes.OTP:
          return [
            ...types,
            ...[EUsedSigningType.MailOtp, EUsedSigningType.SmsOtp],
          ];

        default:
          return [...types, EUsedSigningType.Manual];
      }
    },
    [],
  );
};

const getDocumentPackageType = (params: {
  templateDocument?: ITemplateDocument;
}) => {
  const { templateDocument } = params;

  if (templateDocument) {
    switch (templateDocument.type) {
      case ETemplateType.ContractResidential:
      case ETemplateType.ContractResidentialShort:
      case ETemplateType.ContractResidentialLong:
      case ETemplateType.ContractStudentResidence:
      case ETemplateType.ContractCommercial:
      case ETemplateType.ContractParkingLot:
      case ETemplateType.ContractGarage:
      case ETemplateType.ContractOther:
      case ETemplateType.PropertyInspection:
        return EDocumentPackageTypes.Contract;

      case ETemplateType.KeyHandover:
      case ETemplateType.Indexation:
      case ETemplateType.Other:
        return EDocumentPackageTypes.Document;

      default:
        return;
    }
  }
};
