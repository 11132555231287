import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import { EDocumentCategory } from "@rentiohq/shared-frontend/dist/types/document.types";
import { formatLongDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { FileFieldInternal } from "@rentiohq/web-shared/dist/components";
import { useDispatch } from "react-redux";
import usePreference from "scenes/Settings/hooks/usePreference";
import { SharedSettingsProps } from "../Settings.types";

interface SettingsImageUploadProps extends SharedSettingsProps {
  title: string;
  tags?: string[];
  fileNamePrefix?: string;
}

// eslint-disable-next-line import/no-default-export
export default function SettingsImageUpload({
  title,
  preferenceKey,
  preferencePersistScope,
  fileNamePrefix = "Image",
  tags = [],
}: SettingsImageUploadProps) {
  const dispatch = useDispatch();

  const [image, saveImage, clearImage] = usePreference({
    preferenceKey,
    preferencePersistScope,
  });

  const uploadImage = async (newValue: any) => {
    return new Promise<string>((resolve, reject) => {
      dispatch(
        documentActions.uploadDocumentStart.getAction({
          file: newValue?.[0],
          metaData: {
            filename: `${fileNamePrefix}(${formatLongDate(new Date())})`,
            categoryId: EDocumentCategory.Image,
            tags,
          },
          onSuccess: resolve,
          onFailure: reject,
        }),
      );
    });
  };

  return (
    <FileFieldInternal
      title={title}
      multiple={false}
      disabled={false}
      accept={"image/*"}
      formData={image ? [image] : []}
      hideInput={!!image}
      onChange={async (newValue: any[]) => {
        if (newValue.length === 0) {
          clearImage();
          return;
        }

        if (typeof newValue?.[0] !== "object") {
          return;
        }

        const documentId = await uploadImage(newValue);
        saveImage(documentId ?? null);
      }}
    />
  );
}
