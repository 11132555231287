import { Box, Flex } from "@rebass/grid";
import { IPartnership } from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.types";
import { getAddendumStatusesFromContract } from "@rentiohq/shared-frontend/dist/redux/registration/registration.utils";
import {
  ETemplateTag,
  ETemplateType,
  ITemplate,
} from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import { ITemplateDocument } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import {
  EContractIndexationStatus,
  EContractType,
  EInsuranceExternalStatus,
  EMoveServiceStatus,
  ETemplateDocumentStatus,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  EDocumentPackageStatus,
  EDocumentPackageTypes,
  IDocumentPackage,
} from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { ERegistrationContractStatus } from "@rentiohq/shared-frontend/dist/types/registration.types";
import {
  ERentDepositStatus,
  IRentDeposit,
} from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { isEndedContract } from "@rentiohq/shared-frontend/dist/utils/contract.utils";
import {
  addMonths,
  differenceInYears,
  isAfter,
  isBefore,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { canAddPaymentOrders } from "@rentiohq/shared-frontend/dist/utils/paymentOrder.utils";
import { iHaveRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  capitalizeString,
  stringToSnakeCase,
} from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { appendQueryParams } from "@rentiohq/shared-frontend/dist/utils/url.utils";
import {
  Appearance,
  ESpacings,
  Grid,
  Icon,
  RentioInternalRenderer,
  TextStyle,
  Tooltip,
  renderActions,
} from "@rentiohq/web-shared/dist/components";
import {
  getSignerInfo,
  renderSignersTooltip,
} from "utils/documentPackage.utils";
import { rs, ts } from "../../../../../../services";
import { EActionStatus, IChecklistItem } from "./components/ChecklistItem";

export const STATUS_IS_ENDING_WHEN_IN_MONTHS = 9;

const handleChangeActionStatus = (params: {
  updateContract: ReturnType<typeof contractHooks.useUpdate>["update"];
  key:
    | "manuallyCompletedTemplateDocumentContract"
    | "manuallyCompletedDocumentSigning"
    | "manuallyCompletedRentDeposit"
    | "manuallyCompletedLocationDescription"
    | "manuallyCompletedPaymentRent"
    | "manuallyCompletedMove"
    | "manuallyCompletedInsurance"
    | "manuallyCompletedRegistration";
  entityKey: string;
  shouldCheck: boolean;
}) => {
  const { updateContract, key, entityKey, shouldCheck } = params;

  const entity = getLocalizedText(`system.${entityKey}`).toLowerCase();

  if (shouldCheck) {
    confirm({
      title: capitalizeString(
        getLocalizedText("contract_action.manual.check.confirm.title", {
          entity,
        }),
      ),
      info: capitalizeString(
        getLocalizedText("contract_action.manual.check.confirm.info", {
          entity,
        }),
      ),
      inputValueProps: {
        type: "string",
        initialValue: getLocalizedText("contract_action.manual"),
        extraInfoValue: getLocalizedText(
          `contract_action.manual.check_example.${entityKey}`,
        ),
      },
      primaryActions: [
        {
          title: getLocalizedText("system.check"),
          onPress: (params?: { inputValue?: string | Date }) => {
            const { inputValue = "" } = params || {};

            if (typeof inputValue !== "string") {
              return;
            }

            let reason = inputValue;
            if (
              reason.length === 0 ||
              reason === getLocalizedText("contract_action.manual")
            ) {
              reason = "contract_action.manual";
            }

            updateContract({
              data: {
                [key]: reason,
              },
              customSuccessMessage: capitalizeString(
                getLocalizedText(
                  "contract_action.manual.check.toast.success.title",
                  { entity, reason: getLocalizedText(reason) },
                ),
              ),
              customFailureMessage: capitalizeString(
                getLocalizedText(
                  "contract_action.manual.check.toast.failure.title",
                  { entity },
                ),
              ),
            });
          },
        },
      ],
    });

    return;
  }

  confirm({
    title: capitalizeString(
      getLocalizedText("contract_action.manual.uncheck.confirm.title", {
        entity,
      }),
    ),
    info: capitalizeString(
      getLocalizedText("contract_action.manual.uncheck.confirm.info", {
        entity,
      }),
    ),
    primaryActions: [
      {
        title: getLocalizedText("system.uncheck"),
        onPress: () => {
          updateContract({
            data: {
              [key]: null,
            },
            customSuccessMessage: capitalizeString(
              getLocalizedText(
                "contract_action.manual.uncheck.toast.success.title",
                { entity },
              ),
            ),
            customFailureMessage: capitalizeString(
              getLocalizedText(
                "contract_action.manual.uncheck.toast.failure.title",
                { entity },
              ),
            ),
          });
        },
      },
    ],
  });

  return;
};

export const getIndexationMessage = (params: {
  property: IProperty;
  contract: IContract;

  // Callbacks
  onIndexContract: () => void;

  // Config
  internalModeEnabled: boolean;
}): IChecklistItem | undefined => {
  const {
    property,
    contract,

    // Callbacks
    onIndexContract,

    // Config
    internalModeEnabled,
  } = params;

  if (isEndedContract(contract)) {
    return;
  }

  if (contract.indexationStatus === EContractIndexationStatus.NotReady) {
    return;
  }

  if (!iHaveRole(property, EPropertyMemberTypes.IndexationManager)) {
    if (internalModeEnabled) {
      return {
        actionStatus: EActionStatus.Incomplete,
        title: "",
        content: (
          <RentioInternalRenderer>
            Indexation is possible, but no indexation role
          </RentioInternalRenderer>
        ),
        rightContent: renderActions({
          content: "View indexation",
          onClick: onIndexContract,
          appearance: "link",
          color: "green",
          size: "small",
        }),
      };
    }
    return;
  }

  const common = {
    title: ts.propertyDashboardChecklistIndexContractHeading(),
    onClick: onIndexContract,
  };

  let showActionStatus = false;
  let actionStatus = EActionStatus.Incomplete;
  let buttonAppearance: Appearance | undefined;
  switch (contract.indexationStatus) {
    case EContractIndexationStatus.OwnerAsked:
      showActionStatus = true;
      actionStatus = EActionStatus.Incomplete;
      buttonAppearance = "link";
      break;

    case EContractIndexationStatus.OwnerApproved:
      showActionStatus = true;
      actionStatus = EActionStatus.UrgentActionRentio;
      break;

    case EContractIndexationStatus.OwnerDeclined:
      showActionStatus = true;
      actionStatus = EActionStatus.UrgentActionRentio;
      break;

    case EContractIndexationStatus.Muted:
      showActionStatus = true;
      actionStatus = EActionStatus.Incomplete;
      buttonAppearance = "link";
      break;

    case EContractIndexationStatus.Ready:
    default:
      break;
  }

  return {
    ...common,
    actionStatus,
    actionStatusText: showActionStatus
      ? getLocalizedText(
          `property.dashboard.checklist.index_contract.${contract.indexationStatus}.heading`.toLowerCase(),
        )
      : undefined,
    content: (
      <div>
        {getLocalizedText(
          `property.dashboard.checklist.index_contract.${contract.indexationStatus}.content`.toLowerCase(),
        )}
      </div>
    ),
    rightContent: renderActions({
      content: `${getLocalizedText(
        `property.dashboard.checklist.index_contract.${contract.indexationStatus}.action`.toLowerCase(),
      )} ›`,
      onClick: onIndexContract,
      appearance: buttonAppearance,
      color: "green",
      size: "small",
    }),
  };
};

export const getTemplateDocumentContractMessage = (params: {
  property: IProperty;
  contract: IContract;

  // Callbacks
  onCreateContractFromTemplateDocument: () => void;
  onShowDocument: (documentId: string) => void;
  onShowTemplateDocument: (templateDocumentId: string) => void;

  // Actions
  updateContract: ReturnType<typeof contractHooks.useUpdate>["update"];

  // Config
  isBroker: boolean;
  templates?: ITemplate[];
  isFetchingTemplates: boolean;
  contractTemplateDocuments?: ITemplateDocument[];
  isFetchingContractTemplateDocuments: boolean;
}): IChecklistItem | undefined => {
  const {
    property,
    contract,

    // Callbacks
    onCreateContractFromTemplateDocument,
    onShowDocument,
    onShowTemplateDocument,

    // Actions
    updateContract,

    // Config
    isBroker,
    templates,
    isFetchingTemplates,
    contractTemplateDocuments,
    isFetchingContractTemplateDocuments,
  } = params;
  const { manuallyCompletedTemplateDocumentContract } = contract;

  const contractIsEnded = isEndedContract(contract);

  const availableTemplatesForContract = getAvailableTemplatesForType({
    type: "contract",
    isBroker,
    templates,
    contract,
    property,
  });

  const title = getLocalizedText(
    "property.dashboard.checklist.template_document_contract.heading",
  );
  const entityKey = "template_document_contract";

  if (manuallyCompletedTemplateDocumentContract) {
    return {
      actionStatus: EActionStatus.CompletedManually,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedTemplateDocumentContract",
          entityKey,
          shouldCheck: false,
        });
      },
      actionStatusText: getLocalizedText(
        manuallyCompletedTemplateDocumentContract,
      ),
      title,
      content: (
        <div>
          {getLocalizedText(
            "property.dashboard.checklist.template_document_contract.empty_content",
          )}
        </div>
      ),
      rightContent: undefined,
    };
  }

  // No available templates & no existing template documents
  const templateDocument = contractTemplateDocuments?.[0];
  if (
    availableTemplatesForContract &&
    availableTemplatesForContract.length === 0 &&
    !templateDocument
  ) {
    return;
  }

  const showLoading =
    (!availableTemplatesForContract && isFetchingTemplates) ||
    (!contractTemplateDocuments && isFetchingContractTemplateDocuments);
  if (showLoading) {
    return {
      actionStatus: EActionStatus.Loading,
      title,
      content: null, // <div>&nbsp;</div>,
    };
  }

  // Template document
  if (templateDocument) {
    const { status, documentId } = templateDocument;

    let showActions = true;
    let actionStatus = EActionStatus.Incomplete;
    switch (status) {
      case ETemplateDocumentStatus.Generating:
        actionStatus = EActionStatus.PendingRentio;
        break;

      case ETemplateDocumentStatus.Generated:
        actionStatus = EActionStatus.CompletedInRentio;
        break;

      // TODO: Failed
      case ETemplateDocumentStatus.Failed:
      case ETemplateDocumentStatus.New:
      default:
        break;
    }

    const onClick = () => {
      switch (status) {
        case ETemplateDocumentStatus.Generated:
        case ETemplateDocumentStatus.Generating:
          if (documentId) {
            onShowDocument(documentId);
          }

          break;

        case ETemplateDocumentStatus.Failed:
        case ETemplateDocumentStatus.New:
        default: {
          onShowTemplateDocument(templateDocument.id);

          break;
        }
      }
    };

    return {
      actionStatus,
      title,
      content: (
        <div>
          {getLocalizedText(
            `property.dashboard.checklist.template_document_contract.status.${status}`.toLowerCase(),
          )}
        </div>
      ),
      onClick,
      rightContent:
        showActions &&
        renderActions({
          content: `${getLocalizedText(
            `property.dashboard.checklist.template_document_contract.info_action.${status}`.toLowerCase(),
          )} ›`,
          onClick,
          appearance: "link",
        }),
    };
  }

  // Empty state
  return {
    actionStatus: EActionStatus.Incomplete,
    title,
    content: (
      <div>
        {getLocalizedText(
          "property.dashboard.checklist.template_document_contract.content",
        )}
      </div>
    ),
    onChangeActionStatus: (_: EActionStatus) => {
      handleChangeActionStatus({
        updateContract,
        key: "manuallyCompletedTemplateDocumentContract",
        entityKey,
        shouldCheck: true,
      });
    },
    onClick: contractIsEnded ? undefined : onCreateContractFromTemplateDocument,
    rightContent: contractIsEnded
      ? undefined
      : renderActions({
          content: `${getLocalizedText(
            "property.dashboard.checklist.template_document_contract.empty_action",
          )} ›`,
          onClick: onCreateContractFromTemplateDocument,
          color: "green",
          size: "small",
        }),
  };
};

const renderDocumentPackageInfo = (params: {
  documentPackage: IDocumentPackage;
}) => {
  const { documentPackage } = params;

  if (!documentPackage) {
    return null;
  }

  if (
    [EDocumentPackageStatus.Pending, EDocumentPackageStatus.Finished].includes(
      documentPackage.status!,
    )
  ) {
    const { signerCount = 0, signedCount = 0 } = documentPackage
      ? getSignerInfo(documentPackage)
      : {};

    return (
      <Grid spacing="tight" alignItems="center" justifyContent="flex-end">
        <Grid.Item>
          <Icon source="contentPenWrite" size="small" />
        </Grid.Item>
        <Grid.Item>
          <Tooltip tooltipContent={renderSignersTooltip(documentPackage)}>
            <TextStyle
              variation={[
                "code",
                signedCount >= signerCount ? "positive" : "negative",
              ]}
            >
              {signedCount}/{signerCount}
            </TextStyle>
          </Tooltip>
        </Grid.Item>
      </Grid>
    );
  }

  return ts.documentPackagesStatus(documentPackage.status);
};

export const getDocumentPackageMessage = (params: {
  property: IProperty;
  contract: IContract;

  // Callbacks
  onShowDocumentPackage: (documentPackageId: string) => void;

  // Actions
  updateContract: ReturnType<typeof contractHooks.useUpdate>["update"];

  // Config
  documentPackage?: IDocumentPackage;
  isFetchingDocumentPackage: boolean;
}): IChecklistItem | undefined => {
  const {
    property,
    contract,

    // Callbacks
    onShowDocumentPackage,

    // Actions
    updateContract,

    // Config
    documentPackage,
    isFetchingDocumentPackage,
  } = params;

  const { manuallyCompletedDocumentSigning } = contract;
  const contractIsEnded = isEndedContract(contract);

  if (!contract.contractType || contract.contractType !== EContractType.Basic) {
    return;
  }

  if (manuallyCompletedDocumentSigning) {
    return {
      actionStatus: EActionStatus.CompletedManually,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedDocumentSigning",
          entityKey: "contract_signing",
          shouldCheck: false,
        });
      },
      actionStatusText: getLocalizedText(manuallyCompletedDocumentSigning),
      title: ts.propertyDashboardChecklistSignContractHeading(),
      content: (
        <div>{ts.propertyDashboardChecklistSignContractEmptyContent()}</div>
      ),
      rightContent: undefined,
    };
  }

  if (!documentPackage && isFetchingDocumentPackage) {
    return {
      actionStatus: EActionStatus.Loading,
      title: ts.propertyDashboardChecklistSignContractHeading(),
      content: null, // <div>&nbsp;</div>,
    };
  }

  if (documentPackage) {
    const actionStatus =
      documentPackage.status === EDocumentPackageStatus.Finished
        ? EActionStatus.CompletedInRentio
        : EActionStatus.PendingRentio;

    return {
      actionStatus,
      // onChangeActionStatus:
      //   actionStatus === EActionStatus.PendingRentio
      //     ? (_: EActionStatus) => {
      //         // TODO: Confirm modal where document package will be removed
      //         dispatch(
      //           contractActions.updateContract.actions.start({
      //             contractId: contract.id,
      //             contract: {
      //               manuallyCompletedDocumentSigning: "contract_action.manual",
      //             },
      //           }),
      //         );
      //       }
      //     : undefined,
      title: ts.propertyDashboardChecklistSignContractHeading(),
      content: <div>{ts.propertyDashboardChecklistSignContractContent()}</div>,
      onClick: () => {
        onShowDocumentPackage(documentPackage.id);
      },
      rightContent: (
        <Flex>
          {renderDocumentPackageInfo({ documentPackage })}
          <Box ml={ESpacings.tight}>
            {renderActions({
              content: `${ts.propertyDashboardChecklistViewDocumentPackageAction()} ›`,
              onClick: () => {
                onShowDocumentPackage(documentPackage.id);
              },
              appearance: "link",
            })}
          </Box>
        </Flex>
      ),
    };
  }

  const link = appendQueryParams({
    path: "/forms/document-package",
    queryParams: {
      propertyId: property.id,
      contractId: contract.id,
      documentPackageType: EDocumentPackageTypes.Contract,
    },
  });

  return {
    actionStatus: EActionStatus.Incomplete,
    onChangeActionStatus: (_: EActionStatus) => {
      handleChangeActionStatus({
        updateContract,
        key: "manuallyCompletedDocumentSigning",
        entityKey: "contract_signing",
        shouldCheck: true,
      });
    },
    title: ts.propertyDashboardChecklistSignContractHeading(),
    content: (
      <div>{ts.propertyDashboardChecklistSignContractEmptyContent()}</div>
    ),
    link: contractIsEnded ? undefined : link,
    rightContent: contractIsEnded
      ? undefined
      : renderActions({
          content: `${ts.propertyDashboardChecklistSignContractAction()} ›`,
          url: link,
          // onClick: handlePrepareContract,
          color: "green",
          size: "small",
        }),
  };
};

export const getRentDepositMessage = (params: {
  property: IProperty;
  contract: IContract;

  // Callbacks
  onShowRentDeposit: (rentDepositId: number) => void;

  // Actions
  updateContract: ReturnType<typeof contractHooks.useUpdate>["update"];

  // Config
  rentDeposit?: IRentDeposit;
  isFetchingRentDeposit: boolean;
}): IChecklistItem | undefined => {
  const {
    property,
    contract,

    // Callbacks
    onShowRentDeposit,

    // Actions
    updateContract,

    // Config
    rentDeposit,
    isFetchingRentDeposit,
  } = params;

  const { manuallyCompletedRentDeposit } = contract;
  const contractIsEnded = isEndedContract(contract);

  if (contract.contractType !== EContractType.Basic) {
    return;
  }

  if (manuallyCompletedRentDeposit) {
    return {
      actionStatus: EActionStatus.CompletedManually,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedRentDeposit",
          entityKey: "rent_deposit",
          shouldCheck: false,
        });
      },
      actionStatusText: getLocalizedText(manuallyCompletedRentDeposit),
      title: ts.propertyDashboardChecklistRentDepositHeading(),
      content: (
        <div>{ts.propertyDashboardChecklistRentDepositEmptyContent()}</div>
      ),
      rightContent: undefined,
    };
  }

  if (!rentDeposit && isFetchingRentDeposit) {
    return {
      actionStatus: EActionStatus.Loading,
      title: ts.propertyDashboardChecklistRentDepositHeading(),
      content: null, // <div>&nbsp;</div>,
    };
  }

  if (!rentDeposit || rentDeposit.status === ERentDepositStatus.Cancelled) {
    return {
      actionStatus: EActionStatus.Incomplete,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedRentDeposit",
          entityKey: "rent_deposit",
          shouldCheck: true,
        });
      },
      title: ts.propertyDashboardChecklistRentDepositHeading(),
      content: (
        <div>{ts.propertyDashboardChecklistRentDepositEmptyContent()}</div>
      ),
      link: contractIsEnded
        ? undefined
        : rs.createRentDepositRoute(property.id, contract.id),
      rightContent: contractIsEnded
        ? undefined
        : renderActions({
            content: `${ts.propertyDashboardChecklistRentDepositEmptyAction()} ›`,
            url: rs.createRentDepositRoute(property.id, contract.id),
            color: "green",
            size: "small",
          }),
    };
  }

  let actionStatus = EActionStatus.CompletedInRentio;
  if (
    [
      ERentDepositStatus.New,
      ERentDepositStatus.OpenToSign,
      ERentDepositStatus.OpenSigned,
      ERentDepositStatus.PayingIn,
      ERentDepositStatus.PayingInPartiallySigned,
      ERentDepositStatus.PaidInPartiallySigned,
    ].includes(rentDeposit.status)
  ) {
    actionStatus = EActionStatus.PendingRentio;
  }

  const content =
    rentDeposit.status === ERentDepositStatus.Established
      ? getLocalizedText(
          "property.dashboard.checklist.rent_deposit.content.established",
          {
            value: formatCurrency(rentDeposit.amount),
          },
        )
      : getLocalizedText("property.dashboard.checklist.rent_deposit.content", {
          value: formatCurrency(rentDeposit.amount),
        });

  return {
    actionStatus,
    // onChangeActionStatus: (_: EActionStatus) => {},
    title: ts.propertyDashboardChecklistRentDepositHeading(),
    content,
    onClick: () => {
      onShowRentDeposit(rentDeposit.id);
    },
    rightContent: renderActions({
      content: `${ts.propertyDashboardChecklistRentDepositAction()} ›`,
      appearance: "link",
      onClick: () => {
        onShowRentDeposit(rentDeposit.id);
      },
    }),
  };
};

export const getPaymentOrderMessage = (params: {
  property: IProperty;
  contract: IContract;

  // Actions
  updateContract: ReturnType<typeof contractHooks.useUpdate>["update"];

  // Config
  isBroker: boolean;
  paymentOrdersCount?: number;
  isFetchingPaymentOrdersCount: boolean;
}): IChecklistItem | undefined => {
  const {
    property,
    contract,

    // Actions
    updateContract,

    // Config
    isBroker,
    paymentOrdersCount = 0,
    isFetchingPaymentOrdersCount,
  } = params;

  const { manuallyCompletedPaymentRent } = contract;
  const contractIsEnded = isEndedContract(contract);

  if (contract.contractType !== EContractType.Basic) {
    return;
  }

  if (manuallyCompletedPaymentRent) {
    return {
      actionStatus: EActionStatus.CompletedManually,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedPaymentRent",
          entityKey: "payment_orders",
          shouldCheck: false,
        });
      },
      actionStatusText: getLocalizedText(manuallyCompletedPaymentRent),
      title: ts.propertyDashboardChecklistPaymentOrderHeading(),
      content: (
        <div>{ts.propertyDashboardChecklistPaymentOrderEmptyContent()}</div>
      ),
      rightContent: undefined,
    };
  }

  if (paymentOrdersCount === 0 && isFetchingPaymentOrdersCount) {
    return {
      actionStatus: EActionStatus.Loading,
      title: ts.propertyDashboardChecklistPaymentOrderHeading(),
      content: null, // <div>&nbsp;</div>,
    };
  }

  // Don't show if payments are already added
  if (paymentOrdersCount > 0) {
    return {
      actionStatus: EActionStatus.CompletedInRentio,
      title: ts.propertyDashboardChecklistPaymentOrderHeading(),
      content: (
        <div>{ts.propertyDashboardChecklistPaymentOrderEmptyContent()}</div>
      ),
      link: `/properties/${property.id}/payments`,
      rightContent: renderActions({
        // content: `${getLocalizedText(
        //   "contract_card.checklist.move_service.list_action.detail",
        // )} ›`,
        content: `${getLocalizedText("system.view")} ›`,
        appearance: "link",
        url: `/properties/${property.id}/payments`,
      }),
    };
  }

  if (
    !canAddPaymentOrders({
      property,
      isBroker,
    })
  ) {
    return undefined;
  }

  return {
    actionStatus: EActionStatus.Incomplete,
    onChangeActionStatus: (_: EActionStatus) => {
      handleChangeActionStatus({
        updateContract,
        key: "manuallyCompletedPaymentRent",
        entityKey: "payment_orders",
        shouldCheck: true,
      });
    },
    title: ts.propertyDashboardChecklistPaymentOrderHeading(),
    content: (
      <div>{ts.propertyDashboardChecklistPaymentOrderEmptyContent()}</div>
    ),
    link: contractIsEnded
      ? undefined
      : `/properties/${property.id}/payments/add?contractId=${contract.id}`,
    rightContent: contractIsEnded
      ? undefined
      : renderActions({
          content: `${ts.propertyDashboardChecklistPaymentOrderEmptyAction()} ›`,
          url: `/properties/${property.id}/payments/add?contractId=${contract.id}`,
          color: "green",
          size: "small",
        }),
  };
};

export const getPropertyInspectionReportMessage = (params: {
  property: IProperty;
  contract: IContract;

  // Callbacks
  onOrderPropertyInspectionReport: () => void;

  // Actions
  updateContract: ReturnType<typeof contractHooks.useUpdate>["update"];

  // Config
  partnerships?: IPartnership[];
}): IChecklistItem | undefined => {
  const {
    // property,
    contract,

    // Callbacks
    onOrderPropertyInspectionReport,

    // Actions
    updateContract,

    // Config
    partnerships,
  } = params;

  const { manuallyCompletedLocationDescription } = contract;
  const contractIsEnded = isEndedContract(contract);

  const isContractStarting =
    isBefore(new Date(), addMonths(contract.startDate, 1)) &&
    !contract.locationDescriptionInRequestedAt;
  const isContractEnding =
    isAfter(new Date(), addMonths(contract.startDate, 1)) &&
    !contract.locationDescriptionOutRequestedAt;

  if (manuallyCompletedLocationDescription) {
    return {
      actionStatus: EActionStatus.CompletedManually,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedLocationDescription",
          entityKey: "location_description",
          shouldCheck: false,
        });
      },
      actionStatusText: getLocalizedText(manuallyCompletedLocationDescription),
      title: ts.contractCardChecklistLocationDescriptionHeading(),
      content: (
        <div>
          {getLocalizedText(
            "contract_card.checklist.location_description.manually",
          )}
        </div>
      ),
      rightContent: undefined,
    };
  }

  if (
    contract.locationDescriptionInRequestedAt &&
    contract.locationDescriptionOutRequestedAt
  ) {
    return {
      actionStatus: EActionStatus.CompletedInRentio,
      title: ts.contractCardChecklistLocationDescriptionHeading(),
      content: (
        <div>
          {ts.contractCardChecklistLocationDescriptionBothContent({
            values: {
              date: formatDate(contract.locationDescriptionOutRequestedAt!),
            },
          })}
        </div>
      ),
    };
  }

  if (contract.locationDescriptionInRequestedAt) {
    return {
      actionStatus: EActionStatus.CompletedInRentio,
      title: ts.contractCardChecklistLocationDescriptionHeading(),
      content: (
        <div>
          {ts.contractCardChecklistLocationDescriptionInContent({
            values: {
              date: formatDate(contract.locationDescriptionInRequestedAt!),
            },
          })}
        </div>
      ),
    };
  }

  if (contract.locationDescriptionOutRequestedAt) {
    return {
      actionStatus: EActionStatus.CompletedInRentio,
      title: ts.contractCardChecklistLocationDescriptionHeading(),
      content: (
        <div>
          {ts.contractCardChecklistLocationDescriptionOutContent({
            values: {
              date: formatDate(contract.locationDescriptionOutRequestedAt!),
            },
          })}
        </div>
      ),
    };
  }

  // Only hide if feature enabled & there are no partnerships
  if (!partnerships) {
    return undefined;
  }

  let isOnlyMexp = false;
  if (
    (partnerships || []).length === 1 &&
    (partnerships || [])[0].locationDescriptorKey === "MEXP"
  ) {
    isOnlyMexp = true;
  }

  if (isContractStarting || isContractEnding) {
    return {
      actionStatus: EActionStatus.Incomplete,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedLocationDescription",
          entityKey: "location_description",
          shouldCheck: true,
        });
      },
      title: ts.contractCardChecklistLocationDescriptionHeading(),
      content: (
        <div>
          {isOnlyMexp
            ? getLocalizedText(
                "contract_card.checklist.location_description.empty_content",
              )
            : getLocalizedText(
                "contract_card.checklist.location_description.empty_content_generic",
              )}
        </div>
      ),
      onClick: contractIsEnded ? undefined : onOrderPropertyInspectionReport,
      rightContent: contractIsEnded
        ? undefined
        : renderActions({
            content: `${ts.contractCardChecklistLocationDescriptionEmptyAction()} ›`,
            onClick: onOrderPropertyInspectionReport,
            color: "green",
            size: "small",
          }),
    };
  }
};

export const getInsuranceMessage = (params: {
  property: IProperty;
  contract: IContract;

  // Callbacks
  onRequestInsurance: () => void;
  onShowInsurance: (insuranceContractId: string) => void;

  // Actions
  updateContract: ReturnType<typeof contractHooks.useUpdate>["update"];

  // Config
  isBroker: boolean;
  hasInsuranceForBroker: boolean;
  internalModeEnabled: boolean;
}): IChecklistItem | undefined => {
  const {
    contract,

    // Callbacks
    onRequestInsurance,
    onShowInsurance,

    // Actions
    updateContract,

    // Config
    isBroker,
    hasInsuranceForBroker,
    internalModeEnabled,
  } = params;

  const { manuallyCompletedInsurance } = contract;
  const contractIsEnded = isEndedContract(contract);

  if (isBroker && !hasInsuranceForBroker && !internalModeEnabled) {
    return {
      actionStatus: manuallyCompletedInsurance
        ? EActionStatus.CompletedManually
        : EActionStatus.Incomplete,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedInsurance",
          entityKey: "insurance",
          shouldCheck: !manuallyCompletedInsurance,
        });
      },
      actionStatusText: manuallyCompletedInsurance
        ? getLocalizedText(manuallyCompletedInsurance)
        : undefined,
      title: getLocalizedText(
        "contract_card.checklist.insurance.heading.manual",
      ),
      content: getLocalizedText(
        "contract_card.checklist.insurance.content.manual",
      ),
      rightContent: undefined,
    };
  }

  let title = getLocalizedText("contract_card.checklist.insurance.heading");

  if (manuallyCompletedInsurance) {
    return {
      actionStatus: EActionStatus.CompletedManually,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedInsurance",
          entityKey: "insurance",
          shouldCheck: false,
        });
      },
      actionStatusText: getLocalizedText(manuallyCompletedInsurance),
      title,
      content: getLocalizedText(
        "contract_card.checklist.insurance.content.none",
      ),
      rightContent: undefined,
    };
  }

  const { insuranceExternalStatus, insuranceLeadCompletedDate } = contract;

  let content = getLocalizedText(
    `contract_card.checklist.insurance.content.${insuranceExternalStatus}`.toLowerCase(),
    {
      date: insuranceLeadCompletedDate
        ? formatDate(insuranceLeadCompletedDate)
        : "",
    },
  );

  let actionStatus = EActionStatus.Incomplete;
  let rightContent = null;
  let onClick = undefined;

  switch (insuranceExternalStatus) {
    case EInsuranceExternalStatus.None: {
      actionStatus = EActionStatus.Incomplete;

      onClick = contractIsEnded ? undefined : onRequestInsurance;

      rightContent = contractIsEnded
        ? undefined
        : renderActions({
            content: `${getLocalizedText(
              "contract_card.checklist.insurance.list_action",
            )} ›`,
            onClick,
            color: "green",
            size: "small",
          });

      break;
    }

    case EInsuranceExternalStatus.Created:
    case EInsuranceExternalStatus.Pending:
    case EInsuranceExternalStatus.Failed: {
      actionStatus = EActionStatus.PendingRentio;

      onClick = () => {
        onShowInsurance(contract.id);
      };

      rightContent = renderActions({
        content: `${getLocalizedText(
          "contract_card.checklist.insurance.list_action.detail",
        )} ›`,
        onClick,
        appearance: "link",
      });

      break;
    }

    case EInsuranceExternalStatus.Completed:
      actionStatus = EActionStatus.CompletedInRentio;

      onClick = () => {
        onShowInsurance(contract.id);
      };

      rightContent = renderActions({
        content: `${getLocalizedText(
          "contract_card.checklist.insurance.list_action.detail",
        )} ›`,
        onClick,
        appearance: "link",
      });
      break;

    default:
      break;
  }

  return {
    actionStatus,
    onChangeActionStatus: (_: EActionStatus) => {
      handleChangeActionStatus({
        updateContract,
        key: "manuallyCompletedInsurance",
        entityKey: "insurance",
        shouldCheck: true,
      });
    },
    title,
    content,
    onClick,
    rightContent,
  };
};

export const getMoveServiceMessage = (params: {
  property: IProperty;
  contract: IContract;

  // Callbacks
  onShowMoveRequest: (moveRequestId: number) => void;
  onCreateMoveRequest: () => void;

  // Actions
  updateContract: ReturnType<typeof contractHooks.useUpdate>["update"];

  // Config
  isBroker: boolean;
  hasMoveServiceForBroker: boolean;
  internalModeEnabled: boolean;
}): IChecklistItem | undefined => {
  const {
    contract,

    // Callbacks
    onShowMoveRequest,
    onCreateMoveRequest,

    // Actions
    updateContract,

    // Config
    isBroker,
    hasMoveServiceForBroker,
    internalModeEnabled,
  } = params;

  const { manuallyCompletedMove } = contract;
  const contractIsEnded = isEndedContract(contract);

  if (isBroker && !hasMoveServiceForBroker && !internalModeEnabled) {
    return {
      actionStatus: manuallyCompletedMove
        ? EActionStatus.CompletedManually
        : EActionStatus.Incomplete,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedMove",
          entityKey: "move_service",
          shouldCheck: !manuallyCompletedMove,
        });
      },
      actionStatusText: manuallyCompletedMove
        ? getLocalizedText(manuallyCompletedMove)
        : undefined,
      title: getLocalizedText(
        "contract_card.checklist.move_service.heading.manual",
      ),
      content: getLocalizedText(
        "contract_card.checklist.move_service.content.manual",
      ),
      rightContent: undefined,
    };
  }

  const { moveServiceStatus = EMoveServiceStatus.None } = contract;

  let title = getLocalizedText("contract_card.checklist.move_service.heading");
  let content = getLocalizedText(
    `contract_card.checklist.move_service.content.${moveServiceStatus}`.toLowerCase(),
  );

  if (contract.manuallyCompletedMove) {
    return {
      actionStatus: EActionStatus.CompletedManually,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedMove",
          entityKey: "move_service",
          shouldCheck: false,
        });
      },
      actionStatusText: getLocalizedText(contract.manuallyCompletedMove),
      title,
      content,
      rightContent: undefined,
    };
  }

  if (contract.moveRequestId) {
    return {
      actionStatus: EActionStatus.CompletedInRentio,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedMove",
          entityKey: "move_service",
          shouldCheck: true,
        });
      },
      title,
      content,
      onClick: () => {
        if (contract.moveRequestId) {
          onShowMoveRequest(contract.moveRequestId);
        }
      },
      rightContent: renderActions({
        content: `${getLocalizedText(
          "contract_card.checklist.move_service.list_action.detail",
        )} ›`,
        onClick: () => {
          if (contract.moveRequestId) {
            onShowMoveRequest(contract.moveRequestId);
          }
        },
        appearance: "link",
      }),
    };
  }

  return {
    actionStatus: EActionStatus.Incomplete,
    onChangeActionStatus: (_: EActionStatus) => {
      handleChangeActionStatus({
        updateContract,
        key: "manuallyCompletedMove",
        entityKey: "move_service",
        shouldCheck: true,
      });
    },
    title,
    content,
    onClick: contractIsEnded ? undefined : onCreateMoveRequest,
    rightContent: contractIsEnded
      ? undefined
      : renderActions({
          content: `${getLocalizedText(
            "contract_card.checklist.move_service.list_action.start",
          )} ›`,
          onClick: onCreateMoveRequest,
          color: "green",
          size: "small",
        }),
  };
};

export const getRegistrationMessage = (params: {
  property: IProperty;
  contract: IContract;

  // Callbacks
  onShowRentRegistration: (registrationId: number) => void;

  // Actions
  updateContract: ReturnType<typeof contractHooks.useUpdate>["update"];
}): IChecklistItem | undefined => {
  const {
    property,
    contract,

    // Callbacks
    onShowRentRegistration,

    // Actions
    updateContract,
  } = params;

  const { manuallyCompletedRegistration } = contract;
  const contractIsEnded = isEndedContract(contract);

  const {
    rentRegistrationRentContractStatus = ERegistrationContractStatus.None,
    rentRegistrationLocationDescriptionStatus = ERegistrationContractStatus.None,
    rentRegistrationId,
  } = contract;

  const rentRegistrationAddendumStatuses =
    getAddendumStatusesFromContract(contract);

  const addendumIsRegistered = rentRegistrationAddendumStatuses.some(
    addendumStatus => ERegistrationContractStatus.Registered === addendumStatus,
  );

  let title = getLocalizedText("contract_card.checklist.registration.heading");

  if (manuallyCompletedRegistration) {
    return {
      actionStatus: EActionStatus.CompletedManually,
      onChangeActionStatus: (_: EActionStatus) => {
        handleChangeActionStatus({
          updateContract,
          key: "manuallyCompletedRegistration",
          entityKey: "registration",
          shouldCheck: false,
        });
      },
      actionStatusText: getLocalizedText(manuallyCompletedRegistration),
      title,
      content: getLocalizedText(
        "contract_card.checklist.registration.content.empty",
      ),
      rightContent: undefined,
    };
  }

  let content = getLocalizedText(
    `contract_card.checklist.registration.content.${
      rentRegistrationRentContractStatus === ERegistrationContractStatus.None
        ? "empty"
        : "other"
    }`,
    {
      rentContractStatus: getLocalizedText(
        `registration.status.${stringToSnakeCase(
          rentRegistrationRentContractStatus,
        )}`,
      ).toLowerCase(),
      locationDescriptionStatus: getLocalizedText(
        `registration.status.${stringToSnakeCase(
          rentRegistrationLocationDescriptionStatus,
        )}`,
      ).toLowerCase(),
    },
  );

  let actionStatus = EActionStatus.Incomplete;
  let rightContent = null;
  let link = undefined;
  let onClick = undefined;

  if (
    [
      ERegistrationContractStatus.Registered,
      ERegistrationContractStatus.RegisteredManually,
    ].includes(rentRegistrationRentContractStatus) &&
    [
      ERegistrationContractStatus.Registered,
      ERegistrationContractStatus.RegisteredManually,
    ].includes(rentRegistrationLocationDescriptionStatus) &&
    addendumIsRegistered
  ) {
    actionStatus = EActionStatus.CompletedInRentio;
  }

  if (
    (rentRegistrationRentContractStatus !== ERegistrationContractStatus.None &&
      [
        ERegistrationContractStatus.Failed,
        ERegistrationContractStatus.Rejected,
        ERegistrationContractStatus.Registering,
        ERegistrationContractStatus.NeedPayment,
        ERegistrationContractStatus.None,
      ].includes(rentRegistrationLocationDescriptionStatus)) ||
    [
      ERegistrationContractStatus.Failed,
      ERegistrationContractStatus.Rejected,
    ].includes(rentRegistrationRentContractStatus)
  ) {
    actionStatus = EActionStatus.PendingRentio;
  }

  if (rentRegistrationRentContractStatus === ERegistrationContractStatus.None) {
    link = contractIsEnded
      ? undefined
      : `/properties/${property.id}/contracts/${contract.id}/registration`;
    rightContent = contractIsEnded
      ? undefined
      : renderActions({
          content: `${getLocalizedText(
            "contract_card.checklist.registration.list_action.start",
          )} ›`,
          url: link,
          color: "green",
          size: "small",
        });
  } else {
    onClick = () => {
      if (rentRegistrationId) {
        onShowRentRegistration(rentRegistrationId);
      }
    };
    rightContent = renderActions({
      content: `${getLocalizedText(
        "contract_card.checklist.registration.list_action.detail",
      )} ›`,
      onClick,
      appearance: "link",
    });
  }

  return {
    actionStatus,
    onChangeActionStatus: (_: EActionStatus) => {
      handleChangeActionStatus({
        updateContract,
        key: "manuallyCompletedRegistration",
        entityKey: "registration",
        shouldCheck: true,
      });
    },
    title,
    content,
    link,
    onClick,
    rightContent,
  };
};

export const getAvailableTemplatesForType = (params: {
  type: "general" | "contract";
  isBroker: boolean;
  property: IProperty;
  contract?: IContract;
  templates?: ITemplate[];
}) => {
  const { type, isBroker, property, contract, templates } = params;

  if (!templates) {
    return;
  }

  if (type === "contract") {
    return templates.filter(template => {
      // Country
      if (template.country !== (property?.countryId || "BE")) {
        return false;
      }

      // Broker
      if (isBroker && !template.tags.includes(ETemplateTag.Broker)) {
        return false;
      }

      // Consumer
      if (!isBroker && !template.tags.includes(ETemplateTag.Consumer)) {
        return false;
      }

      // Property type
      switch (property?.typeId || -1) {
        // House
        case 2:
        // Apartment
        case 3:
          if (template.type === ETemplateType.ContractResidential) {
            return true;
          }

          if (
            template.type !== ETemplateType.ContractResidentialShort &&
            template.type !== ETemplateType.ContractResidentialLong
          ) {
            return false;
          }

          // Residential duration
          if (contract?.stopDate) {
            const durationInYears = differenceInYears(
              contract.stopDate,
              contract.startDate,
            );

            if (
              template.type === ETemplateType.ContractResidentialShort &&
              durationInYears > 3
            ) {
              return false;
            }

            if (
              template.type === ETemplateType.ContractResidentialLong &&
              durationInYears <= 3
            ) {
              return false;
            }
          }

          break;

        // Student housing
        case 4:
          if (template.type !== ETemplateType.ContractStudentResidence) {
            return false;
          }

          break;

        // Commercial
        case 5:
          if (template.type !== ETemplateType.ContractCommercial) {
            return false;
          }

          break;

        // Parking lot
        case 6:
          if (template.type !== ETemplateType.ContractParkingLot) {
            return false;
          }

          break;

        // Garage
        case 7:
          if (template.type !== ETemplateType.ContractGarage) {
            return false;
          }

          break;
        // NO (8) "other" here, that's the default

        case 9: // Office
        case 10: // Warehouse
        case 11: // Shed
        case 12: // Storage
          if (template.type !== ETemplateType.ContractOther) {
            return false;
          }

          break;

        default:
          break;
      }

      return true;
    });
  }

  return templates.filter(template => {
    // Broker
    if (isBroker && !template.tags.includes(ETemplateTag.Broker)) {
      return false;
    }

    // Consumer
    if (!isBroker && !template.tags.includes(ETemplateTag.Consumer)) {
      return false;
    }

    return [
      ETemplateType.KeyHandover,
      ETemplateType.Other,
      ETemplateType.PropertyInspection,
    ].includes(template.type);
  });
};
