var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { useNavigate } from "react-router";
import { Grid } from "../../components";
import * as t from "../../services/translationService";
import { Banner } from "../Banner";
import { Button } from "../Button";
import { Error } from "../Error";
import { Loading } from "../Loading";
import { PageHeader } from "../PageHeader";
import * as S from "./Page.styled";
export var Page = function (_a) {
    var children = _a.children, _b = _a.fullWidth, fullWidth = _b === void 0 ? false : _b, _c = _a.loading, loading = _c === void 0 ? false : _c, error = _a.error, banners = _a.banners, _d = _a.variation, variation = _d === void 0 ? "default" : _d, headerProps = __rest(_a, ["children", "fullWidth", "loading", "error", "banners", "variation"]);
    var navigate = useNavigate();
    var bannersMarkup = banners ? (_jsx(S.PageBanners, { children: banners.map(function (banner) { return (_createElement(Banner, __assign({}, banner, { key: banner.title }))); }) })) : null;
    var goBack = function () {
        navigate(-1);
    };
    if (error) {
        return (_jsx(S.Page, { variation: variation, children: _jsx(S.PageContent, { fullWidth: fullWidth, children: _jsxs(S.PageError, { children: [_jsx(Error, { errors: [error] }), _jsx(Button, { appearance: "plain", onClick: goBack, children: t.system("back_to_previous") })] }) }) }));
    }
    return (_jsxs(S.Page, { variation: variation, children: [_jsx(PageHeader, __assign({ fullWidth: fullWidth }, headerProps)), bannersMarkup, loading ? (_jsx(Loading, {})) : (_jsx(S.PageContent, { fullWidth: fullWidth, children: _jsx(Grid, { spacing: "loose", children: _jsx(Grid.Item, { width: 1, children: _jsx(S.PageContentMain, { children: children }) }) }) }))] }));
};
