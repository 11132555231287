import { ERentioFormField } from "../../components/components/Form";
import { contactSelectInlineV2ValueSelector, getValue, } from "../../redux/form/form.utils";
import { EContactInitialType } from "../../types/contact.types";
import { EPropertyMemberTypes } from "../../types/property.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createContract.types";
import { getNavigationTitle } from "./schema.createContract.utils";
export var getSchema = function (initialValues, _) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.Tenants],
            properties: (_a = {},
                _a[EField.Header + "2"] = {
                    default: undefined,
                },
                _a[EField.TenantsInfo] = {
                    default: undefined,
                },
                _a[EField.Tenants] = {
                    default: getValue(EField.Tenants, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("contract_info.tenants.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "2"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contract_info.tenants.heading"),
        },
        _a[EField.TenantsInfo] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("contract_info.tenants_info.heading"),
            subtitle: getLocalizedText("contract_info.tenants_info.content"),
        },
        _a[EField.Tenants] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            title: getLocalizedText("contract_info.tenants.field"),
            selectContactFromListCtaTitle: getLocalizedText("contract_info.tenants.select_other.cta"),
            isMultiSelect: true,
            prefetchDefault: true,
            initialType: EContactInitialType.Tenant,
            prefilledAddress: (function () {
                var property = extraData.property;
                return {
                    street: property.street,
                    number: property.number,
                    box: property.box,
                    zip: property.zip,
                    city: property.city,
                    state: property.state,
                    countryId: property.countryId,
                };
            })(),
            // Custom selectors & actions
            getDisallowedAccountIds: function (formData) { return formData[EField.Owners] || []; },
            valueSelector: function (state, selectedAccountIds) {
                return contactSelectInlineV2ValueSelector(state, selectedAccountIds, extraData.property.members.map(function (member) { return member.account; }));
            },
            customGetSubtitleSelector: function (state, formData, item) {
                var accountId = item.accountId, contact = item.contact;
                var member = extraData.property.members.find(function (member) {
                    if (accountId && accountId === member.account.id) {
                        return true;
                    }
                    if (contact === null || contact === void 0 ? void 0 : contact.accountIds.includes(member.account.id)) {
                        return true;
                    }
                    return false;
                });
                if (!member) {
                    return;
                }
                return member.roles
                    .filter(function (role) {
                    return ![
                        EPropertyMemberTypes.ContractMember,
                        EPropertyMemberTypes.TaskMember,
                    ].includes(role);
                })
                    .map(function (role) { return getLocalizedText("role.".concat(role.toLowerCase())); })
                    .join(", ");
            },
            customExtraAccountsSelector: function () {
                return extraData.property.members
                    .filter(function (_a) {
                    var roles = _a.roles;
                    return roles.includes(EPropertyMemberTypes.Tenant);
                })
                    .map(function (member) { return member.account; });
            },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
        },
        _a);
};
export var getValidate = function (extraData) { return function (formData, errors) {
    return errors;
}; };
