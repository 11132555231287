var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonActions } from "../Button";
import { StyledActionList, StyledActionListHeading, StyledActionListItem, } from "./ActionList.styled";
export var ActionList = function (props) {
    var heading = props.heading, items = props.items, onListItemClick = props.onClick;
    if (items && items.length === 0) {
        return null;
    }
    return (_jsx(StyledActionList, { children: items && (_jsxs(_Fragment, { children: [heading && (_jsx(StyledActionListHeading, { children: heading })), items.map(function (item, index) {
                    var actionItem = __assign({}, item);
                    if (onListItemClick) {
                        var handleClick = function (e) {
                            var onItemClick = item.onClick;
                            onListItemClick(item);
                            if (onItemClick) {
                                onItemClick(e);
                            }
                        };
                        actionItem = __assign(__assign({}, actionItem), { onClick: handleClick });
                    }
                    return (_jsx(StyledActionListItem, { children: _jsx(ButtonActions, { actions: [actionItem] }) }, "".concat(index, "Item")));
                })] })) }));
};
