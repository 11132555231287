var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatBytes } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { useDropzone } from "react-dropzone";
import { ASSETS } from "../../assets";
import { enqueueSnackbar } from "../../utils/snackbar";
import { UploadFieldStyles } from "./UploadField.styles";
export var showDropErrors = function (params) {
    var fileRejections = params.fileRejections, _a = params.maxFileSize, maxFileSize = _a === void 0 ? CONFIG.UPLOAD_MAX_FILE_SIZE : _a;
    fileRejections.forEach(function (_a) {
        var errors = _a.errors, file = _a.file;
        errors.forEach(function (error) {
            switch (error.code) {
                case "file-too-large":
                    enqueueSnackbar("".concat(file.name, " :").concat(getLocalizedText("files.error.size_limit_exceeded", {
                        size: formatBytes(maxFileSize),
                    })), { variant: "error" });
                    break;
                default:
                    break;
            }
        });
    });
};
export var UploadField = function (_a) {
    var id = _a.id, name = _a.name, onChange = _a.onChange, _b = _a.asDropzone, asDropzone = _b === void 0 ? true : _b, _c = _a.multiple, multiple = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.maxFileSize, maxFileSize = _e === void 0 ? CONFIG.UPLOAD_MAX_FILE_SIZE : _e, _f = _a.accept, accept = _f === void 0 ? "*" : _f, required = _a.required, error = _a.error, fileName = _a.fileName;
    var dropzoneProps = {
        onDrop: handleDrop,
        multiple: multiple,
        maxSize: maxFileSize,
        disabled: disabled,
    };
    var _g = useDropzone(dropzoneProps), getRootProps = _g.getRootProps, getInputProps = _g.getInputProps, isDragActive = _g.isDragActive;
    var sx = UploadFieldStyles({
        hasError: Boolean(error),
        isDraggingOver: isDragActive,
    });
    function handleDrop(acceptedFiles, fileRejections, event) {
        showDropErrors({ acceptedFiles: acceptedFiles, fileRejections: fileRejections, event: event, maxFileSize: maxFileSize });
        if (acceptedFiles.length === 0) {
            return;
        }
        onChange(acceptedFiles);
    }
    function handleChange(_a) {
        var acceptedFiles = _a.target.files;
        onChange(acceptedFiles);
    }
    // To accept all, just leave the 'accept' prop out.
    if (accept && accept !== "*")
        dropzoneProps.accept = accept;
    if (asDropzone)
        return (_jsx(_Fragment, { children: _jsxs(Box, __assign({}, getRootProps(), { sx: sx.wrapper, children: [_jsx("input", __assign({ id: id, name: typeof name === "string" ? name : undefined }, getInputProps())), _jsxs(Stack, { direction: "row", gap: 2, alignItems: "center", children: [_jsx("img", { src: ASSETS.uploadFile, alt: "uploadFile" }), _jsxs(Stack, { direction: "column", gap: 1, children: [_jsx(Typography, { variant: "h5", sx: sx.content.placeHolderTitle, children: "Drag & Drop or Select file" }), _jsx(Typography, { variant: "subtitle2", children: isDragActive
                                            ? "Drop files here"
                                            : "Drop files here or click browse thorough your machine" })] })] })] })) }));
    return (_jsx("input", { id: id, name: typeof name === "string" ? name : undefined, required: required, type: "file", onChange: handleChange, defaultValue: "", multiple: multiple, accept: accept, disabled: disabled }));
};
