import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import { Box, Icon, Stack } from "@rentiohq/web-shared-next/dist/ui-components";
import { Link } from "react-router-dom";
import { DataListItemStyles } from "./DataListItem.styles";

export enum EStartIcon {
  Icon = "icon",
  Avatar = "avatar",
}

export type Configuration = {
  link?: string;
  action?: React.ReactElement;
  spacing?: number;
  showDetailsIcon?: boolean;
  onClick?: () => void;
};

export type IProps = {
  mainContent: React.ReactElement;
  configuration?: Configuration;
};

export const DataListItem = ({ configuration, mainContent }: IProps) => {
  const {
    action,
    onClick,
    link = "",
    showDetailsIcon = false,
  } = configuration || {};

  const renderContent = () => {
    return mainContent;
  };

  const renderAction = () => {
    return action;
  };

  const renderLinkIcon = () => {
    return showDetailsIcon ? (
      <Box sx={sx.content.link}>
        <Link to={link}>
          <Icon path={EAssets.ArrowRightIcon} width="16px" height="16px" />
        </Link>
      </Box>
    ) : null;
  };

  const sx = DataListItemStyles(!!onClick);

  return (
    <Stack
      gap={2}
      onClick={() => (onClick ? onClick() : {})}
      direction={{ sm: "column", md: "row" }}
      justifyContent="space-between"
      alignItems={{ sm: "flex-start", md: "center" }}
      sx={sx.wrapper}
    >
      {renderContent()}
      <Stack direction="row" alignItems="center" gap={4}>
        {renderAction()}
        {renderLinkIcon()}
      </Stack>
    </Stack>
  );
};
