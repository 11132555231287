var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b, _c, _d, _e, _f, _g, _h;
import { call } from "redux-saga/effects";
import { getRepetitionDay } from "../../forms/createPayment/schema.createPayment.utils";
import * as documentPackageApi from "../../redux/documentPackage/documentPackage.api";
import * as paymentApi from "../../redux/payment/payment.api";
import * as templateDocumentApi from "../../redux/templateDocument/templateDocument.api";
import { EUsedSigningType } from "../../types/activity.types";
import { ETemplateDocumentStatus } from "../../types/contract.types";
import { EDocumentPackageTypes } from "../../types/documentPackage.types";
import { EPaymentMethod, EPaymentOrderOwnerPaymentMethod, EPaymentOrderType, EPaymentRepetitionType, } from "../../types/payment.types";
import * as documentApi from "../documents/document.api";
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./contract.constants";
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["Index"] = "INDEX";
    EActionCustom["IndexAskOwner"] = "INDEX_ASK_OWNER";
    EActionCustom["IndexSkip"] = "INDEX_SKIP";
    EActionCustom["Renew"] = "RENEW";
    EActionCustom["RequestMoveService"] = "REQUEST_MOVE_SERVICE";
    EActionCustom["ActivateLegal"] = "ACTIVATE_LEGAL";
    EActionCustom["Cancel"] = "CANCEL";
    EActionCustom["DeleteCancellation"] = "DELETE_CANCELLATION";
})(EActionCustom || (EActionCustom = {}));
function awaitTemplateDocumentGeneration(params) {
    var templateDocumentId, templateDocument;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                templateDocumentId = params.templateDocumentId;
                return [4 /*yield*/, new Promise(function (resolve) {
                        setTimeout(function () {
                            resolve(1);
                        }, 1500);
                    })];
            case 1:
                _a.sent();
                return [4 /*yield*/, call(templateDocumentApi.getTemplateDocument, {
                        id: templateDocumentId,
                    })];
            case 2:
                templateDocument = (_a.sent()).data;
                if (!(templateDocument.status === ETemplateDocumentStatus.Generating)) return [3 /*break*/, 4];
                return [4 /*yield*/, awaitTemplateDocumentGeneration(params)];
            case 3:
                _a.sent();
                _a.label = 4;
            case 4: return [2 /*return*/, templateDocument];
        }
    });
}
export function afterContractCreate(creationData, createdContract) {
    var templateDocumentGeneration, paymentOrderGeneration, template, getTemplateDocumentData, withSignature, templateDocument, latestTemplateDocument, data, document_1, payerAccountId, payeeAccountId, payeeBankAccountId, paymentOrdersToCreate, rentPaymentOrder, commonCostsPaymentOrder, nonCommonCostsPaymentOrder;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                templateDocumentGeneration = creationData.templateDocumentGeneration, paymentOrderGeneration = creationData.paymentOrderGeneration;
                if (!templateDocumentGeneration) return [3 /*break*/, 6];
                template = templateDocumentGeneration.template, getTemplateDocumentData = templateDocumentGeneration.getTemplateDocumentData, withSignature = templateDocumentGeneration.withSignature;
                return [4 /*yield*/, call(templateDocumentApi.createTemplateDocument, {
                        data: getTemplateDocumentData({
                            template: template,
                            contract: createdContract,
                        }),
                    })];
            case 1:
                templateDocument = (_a.sent()).data;
                // Create PDF from template document
                return [4 /*yield*/, call(templateDocumentApi.generateTemplateDocumentPdf, {
                        id: templateDocument.id,
                        data: {},
                    })];
            case 2:
                // Create PDF from template document
                _a.sent();
                if (!withSignature) return [3 /*break*/, 6];
                return [4 /*yield*/, call(awaitTemplateDocumentGeneration, {
                        templateDocumentId: templateDocument.id,
                    })];
            case 3:
                latestTemplateDocument = _a.sent();
                // Get document for template document
                if (!latestTemplateDocument.documentId) {
                    throw new Error("No document ID found after generating contract");
                }
                return [4 /*yield*/, call(documentApi.getDetail, {
                        id: latestTemplateDocument.documentId,
                    })];
            case 4:
                data = (_a.sent()).data;
                document_1 = data.data;
                if (!document_1.signerFields) return [3 /*break*/, 6];
                return [4 /*yield*/, call(documentPackageApi.createDocumentPackage, {
                        name: latestTemplateDocument.name,
                        type: EDocumentPackageTypes.Contract,
                        signers: Object.keys(document_1.signerFields).map(function (accountIdString) {
                            var _a;
                            var accountId = +accountIdString;
                            var member = (_a = createdContract.members) === null || _a === void 0 ? void 0 : _a.find(function (member) { return member.account.id === accountId; });
                            return {
                                accountId: accountId,
                                role: (member === null || member === void 0 ? void 0 : member.roles[0]) || "UNKNOWN",
                                allowedSigningTypes: [
                                    EUsedSigningType.Itsme,
                                    EUsedSigningType.BeId,
                                    EUsedSigningType.MailOtp,
                                    EUsedSigningType.SmsOtp,
                                ],
                            };
                        }),
                        documentsUploadedIds: [document_1.id],
                        propertyId: createdContract.propertyId,
                        contractId: createdContract.id,
                    })];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6:
                if (!paymentOrderGeneration) return [3 /*break*/, 8];
                payerAccountId = paymentOrderGeneration.payerAccountId, payeeAccountId = paymentOrderGeneration.payeeAccountId, payeeBankAccountId = paymentOrderGeneration.payeeBankAccountId;
                paymentOrdersToCreate = [];
                rentPaymentOrder = {
                    amount: createdContract.currentPrice,
                    proRataAmount: 0,
                    vat: createdContract.priceVat || 0,
                    isRepetitionDelayed: false,
                    repetitionDay: getRepetitionDay(createdContract.startDate, createdContract),
                    repetitionType: EPaymentRepetitionType.Monthly,
                    type: EPaymentOrderType.Rent,
                    ownerPaymentMethod: EPaymentOrderOwnerPaymentMethod.Direct,
                    startedAt: createdContract.startDate,
                    paydateOffset: 0,
                    firstPayDateAt: new Date(),
                    payerId: payerAccountId,
                    payeeId: payeeAccountId,
                    payeeBankaccountId: payeeBankAccountId,
                    preferredPaymentMethods: [EPaymentMethod.Payconiq, EPaymentMethod.Ideal],
                    propertyId: createdContract.propertyId,
                    contractId: createdContract.id,
                };
                paymentOrdersToCreate.push(rentPaymentOrder);
                if (createdContract.currentCostsCommon &&
                    createdContract.currentCostsCommon > 0) {
                    commonCostsPaymentOrder = {
                        amount: createdContract.currentCostsCommon,
                        proRataAmount: 0,
                        vat: createdContract.costsCommonVat || 0,
                        isRepetitionDelayed: false,
                        repetitionDay: getRepetitionDay(createdContract.startDate, createdContract),
                        repetitionType: EPaymentRepetitionType.Monthly,
                        type: EPaymentOrderType.CommonCosts,
                        ownerPaymentMethod: EPaymentOrderOwnerPaymentMethod.Direct,
                        startedAt: createdContract.startDate,
                        paydateOffset: 0,
                        firstPayDateAt: new Date(),
                        payerId: payerAccountId,
                        payeeId: payeeAccountId,
                        payeeBankaccountId: payeeBankAccountId,
                        preferredPaymentMethods: [
                            EPaymentMethod.Payconiq,
                            EPaymentMethod.Ideal,
                        ],
                        propertyId: createdContract.propertyId,
                        contractId: createdContract.id,
                    };
                    paymentOrdersToCreate.push(commonCostsPaymentOrder);
                }
                if (createdContract.currentCostsNonCommon &&
                    createdContract.currentCostsNonCommon > 0) {
                    nonCommonCostsPaymentOrder = {
                        amount: createdContract.currentCostsNonCommon,
                        proRataAmount: 0,
                        vat: createdContract.costsNonCommonVat || 0,
                        isRepetitionDelayed: false,
                        repetitionDay: getRepetitionDay(createdContract.startDate, createdContract),
                        repetitionType: EPaymentRepetitionType.Monthly,
                        type: EPaymentOrderType.NonCommonCosts,
                        ownerPaymentMethod: EPaymentOrderOwnerPaymentMethod.Direct,
                        startedAt: createdContract.startDate,
                        paydateOffset: 0,
                        firstPayDateAt: new Date(),
                        payerId: payerAccountId,
                        payeeId: payeeAccountId,
                        payeeBankaccountId: payeeBankAccountId,
                        preferredPaymentMethods: [
                            EPaymentMethod.Payconiq,
                            EPaymentMethod.Ideal,
                        ],
                        propertyId: createdContract.propertyId,
                        contractId: createdContract.id,
                    };
                    paymentOrdersToCreate.push(nonCommonCostsPaymentOrder);
                }
                return [4 /*yield*/, call(paymentApi.createPaymentOrder, paymentOrdersToCreate)];
            case 7:
                _a.sent();
                _a.label = 8;
            case 8: return [2 /*return*/];
        }
    });
}
var generateRenewActions = function (params) {
    var entity = params.entity;
    var renewStart = createCustomAction({
        entity: entity,
        custom: EActionCustom.Renew,
        state: EActionState.Start,
    });
    var renewSucceeded = createCustomAction({
        entity: entity,
        custom: EActionCustom.Renew,
        state: EActionState.Success,
    });
    var renewFailed = createCustomAction({
        entity: entity,
        custom: EActionCustom.Renew,
        state: EActionState.Fail,
    });
    return {
        renewStart: renewStart,
        renewSucceeded: renewSucceeded,
        renewFailed: renewFailed,
    };
};
var generateIndexActions = function (params) {
    var entity = params.entity;
    var indexStart = createCustomAction({
        entity: entity,
        custom: EActionCustom.Index,
        state: EActionState.Start,
    });
    var indexSucceeded = createCustomAction({
        entity: entity,
        custom: EActionCustom.Index,
        state: EActionState.Success,
    });
    var indexFailed = createCustomAction({
        entity: entity,
        custom: EActionCustom.Index,
        state: EActionState.Fail,
    });
    return {
        indexStart: indexStart,
        indexSucceeded: indexSucceeded,
        indexFailed: indexFailed,
    };
};
var generateIndexAskOwnerActions = function (params) {
    var entity = params.entity;
    var indexAskOwnerStart = createCustomAction({
        entity: entity,
        custom: EActionCustom.IndexAskOwner,
        state: EActionState.Start,
    });
    var indexAskOwnerSucceeded = createCustomAction({
        entity: entity,
        custom: EActionCustom.IndexAskOwner,
        state: EActionState.Success,
    });
    var indexAskOwnerFailed = createCustomAction({
        entity: entity,
        custom: EActionCustom.IndexAskOwner,
        state: EActionState.Fail,
    });
    return {
        indexAskOwnerStart: indexAskOwnerStart,
        indexAskOwnerSucceeded: indexAskOwnerSucceeded,
        indexAskOwnerFailed: indexAskOwnerFailed,
    };
};
var generateIndexSkipActions = function (params) {
    var entity = params.entity;
    var indexSkipStart = createCustomAction({
        entity: entity,
        custom: EActionCustom.IndexSkip,
        state: EActionState.Start,
    });
    var indexSkipSucceeded = createCustomAction({
        entity: entity,
        custom: EActionCustom.IndexSkip,
        state: EActionState.Success,
    });
    var indexSkipFailed = createCustomAction({
        entity: entity,
        custom: EActionCustom.IndexSkip,
        state: EActionState.Fail,
    });
    return {
        indexSkipStart: indexSkipStart,
        indexSkipSucceeded: indexSkipSucceeded,
        indexSkipFailed: indexSkipFailed,
    };
};
// TODO: uncomment when move-request have been migrated.
/* const generateRequestMoveServiceActions = (params: IGeneratorParams) => {
  const { entity } = params;

  const requestMoveServiceStart = createCustomAction<{
    id: string;
    onSuccess?: () => void;
    onFailure?: (error: any) => void;
  }>({
    entity,
    custom: EActionCustom.RequestMoveService,
    state: EActionState.Start,
  });

  const requestMoveServiceSucceeded = createCustomAction<{
    id: string;
    result: ISuccessResponse<IContract>;
  }>({
    entity,
    custom: EActionCustom.RequestMoveService,
    state: EActionState.Success,
  });

  const requestMoveServiceFailed = createCustomAction<{
    id: string;
    error: Error;
  }>({
    entity,
    custom: EActionCustom.RequestMoveService,
    state: EActionState.Fail,
  });

  return {
    requestMoveServiceStart,
    requestMoveServiceSucceeded,
    requestMoveServiceFailed,
  };
}; */
var generateActivateLegalActions = function (params) {
    var entity = params.entity;
    var activateLegalStart = createCustomAction({
        entity: entity,
        custom: EActionCustom.ActivateLegal,
        state: EActionState.Start,
    });
    var activateLegalSucceeded = createCustomAction({
        entity: entity,
        custom: EActionCustom.ActivateLegal,
        state: EActionState.Success,
    });
    var activateLegalFailed = createCustomAction({
        entity: entity,
        custom: EActionCustom.ActivateLegal,
        state: EActionState.Fail,
    });
    return {
        activateLegalStart: activateLegalStart,
        activateLegalSucceeded: activateLegalSucceeded,
        activateLegalFailed: activateLegalFailed,
    };
};
var generateCancelActions = function (params) {
    var entity = params.entity;
    var cancelStart = createCustomAction({
        entity: entity,
        custom: EActionCustom.Cancel,
        state: EActionState.Start,
    });
    var cancelSucceeded = createCustomAction({
        entity: entity,
        custom: EActionCustom.Cancel,
        state: EActionState.Success,
    });
    var cancelFailed = createCustomAction({
        entity: entity,
        custom: EActionCustom.Cancel,
        state: EActionState.Fail,
    });
    return {
        cancelStart: cancelStart,
        cancelSucceeded: cancelSucceeded,
        cancelFailed: cancelFailed,
    };
};
var generateDeleteCancellationActions = function (params) {
    var entity = params.entity;
    var deleteCancellationStart = createCustomAction({
        entity: entity,
        custom: EActionCustom.DeleteCancellation,
        state: EActionState.Start,
    });
    var deleteCancellationSucceeded = createCustomAction({
        entity: entity,
        custom: EActionCustom.DeleteCancellation,
        state: EActionState.Success,
    });
    var deleteCancellationFailed = createCustomAction({
        entity: entity,
        custom: EActionCustom.DeleteCancellation,
        state: EActionState.Fail,
    });
    return {
        deleteCancellationStart: deleteCancellationStart,
        deleteCancellationSucceeded: deleteCancellationSucceeded,
        deleteCancellationFailed: deleteCancellationFailed,
    };
};
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getAllWhereStart = _a.getAllWhereStart, getAllWhereFailed = _a.getAllWhereFailed, getAllWhereSucceeded = _a.getAllWhereSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed, createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed;
export var indexStart = (_b = generateIndexActions(GENERATOR_PARAMS), _b.indexStart), indexSucceeded = _b.indexSucceeded, indexFailed = _b.indexFailed;
export var indexAskOwnerStart = (_c = generateIndexAskOwnerActions(GENERATOR_PARAMS), _c.indexAskOwnerStart), indexAskOwnerSucceeded = _c.indexAskOwnerSucceeded, indexAskOwnerFailed = _c.indexAskOwnerFailed;
export var indexSkipStart = (_d = generateIndexSkipActions(GENERATOR_PARAMS), _d.indexSkipStart), indexSkipSucceeded = _d.indexSkipSucceeded, indexSkipFailed = _d.indexSkipFailed;
export var renewStart = (_e = generateRenewActions(GENERATOR_PARAMS), _e.renewStart), renewSucceeded = _e.renewSucceeded, renewFailed = _e.renewFailed;
export var activateLegalStart = (_f = generateActivateLegalActions(GENERATOR_PARAMS), _f.activateLegalStart), activateLegalSucceeded = _f.activateLegalSucceeded, activateLegalFailed = _f.activateLegalFailed;
export var cancelStart = (_g = generateCancelActions(GENERATOR_PARAMS), _g.cancelStart), cancelSucceeded = _g.cancelSucceeded, cancelFailed = _g.cancelFailed;
export var deleteCancellationStart = (_h = generateDeleteCancellationActions(GENERATOR_PARAMS), _h.deleteCancellationStart), deleteCancellationSucceeded = _h.deleteCancellationSucceeded, deleteCancellationFailed = _h.deleteCancellationFailed;
// TODO: uncomment when move-request have been migrated.
/* export const {
  requestMoveServiceStart,
  requestMoveServiceSucceeded,
  requestMoveServiceFailed,
} = generateRequestMoveServiceActions(GENERATOR_PARAMS); */
