import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import * as documentPackageSelectors from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";

import { EDocumentPackageStageSimple } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { ts } from "../../../../../services";

import * as propertyActions from "@rentiohq/shared-frontend/dist/reduxV2/property/property.actions";
import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import {
  ETagVariant,
  Icon,
  SpinningLoader,
  Stack,
  TabPanel,
  Tabs,
  Tag,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { useNavigate } from "react-router-dom";
import { documentPackageUtils } from "../../../../../utils";
import { getSignerInfo } from "../../../../../utils/documentPackage.utils";
import { DocumentPackageHistory } from "./components/DocumentPackageHistory";
import { DocumentPackageInfo } from "./components/DocumentPackageInfo";
import { ContractMetaStyles } from "./DocumentPackage.styles";

export enum EDocumentPackageTabs {
  Info,
  History,
}

interface IProps {
  documentPackageId: string;
}

export const DocumentPackage = ({ documentPackageId }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sx = ContractMetaStyles();

  const [activeTab, setActiveTab] = React.useState(EDocumentPackageTabs.Info);

  const documentPackage = useSelector((state: IRootStore) =>
    documentPackageSelectors.getDocumentPackage(state, documentPackageId),
  );

  const property = useSelector((state: IRootStore) =>
    propertySelectors.getDetail(state, documentPackage?.propertyId),
  );

  const isFetchingDocumentPackage = useSelector((state: IRootStore) =>
    documentPackageSelectors.isFetchingDocumentPackage(
      state,
      documentPackageId,
    ),
  );

  React.useEffect(() => {
    dispatch(
      documentPackageActions.getDocumentPackage.actions.start({
        documentPackageId,
      }),
    );
  }, [documentPackageId]);

  React.useEffect(() => {
    dispatch(
      propertyActions.getDetailStart.getAction({
        id: documentPackage?.propertyId,
      }),
    );
  }, [documentPackage?.propertyId]);

  if (isFetchingDocumentPackage) {
    return <SpinningLoader />;
  }

  if (!documentPackage) {
    return null;
  }

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) =>
    setActiveTab(newValue);

  const renderMeta = () => {
    const simpleStage =
      documentPackage?.status &&
      documentPackageUtils.getSimpleStages(documentPackage.status);
    const { signedCount } = getSignerInfo(documentPackage);

    return (
      <Stack direction="column" gap={1} py={1.25}>
        <Tag
          variant={
            [
              EDocumentPackageStageSimple.Revoked,
              EDocumentPackageStageSimple.Finished,
            ].includes(simpleStage as EDocumentPackageStageSimple)
              ? ETagVariant.Success
              : ETagVariant.Warning
          }
        >
          {ts.followUpDocumentPackagesFilterStageLabel({
            extra: { key: simpleStage },
          })}
          :{` ${signedCount}/${documentPackage.signers.length || 0}`}
        </Tag>
        <Typography variant="h3">{documentPackage.name}</Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <Icon path={EAssets.ApartmentIcon} width="16px" height="16px" />
          <Typography
            variant="body1"
            sx={sx.content.propertyTitle}
            onClick={() =>
              navigate(`/properties/${documentPackage.propertyId}`)
            }
          >
            {documentPackage?.property?.name || property?.name}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <Icon path={EAssets.CalendarIcon} width="16px" height="16px" />
          <Typography variant="body1">
            {ts.system("drawn_up_at")}:{" "}
            {utils.date.format(documentPackage.createdAt, "dd/MM/yyyy")}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const renderInfo = () => {
    return (
      <DocumentPackageInfo
        documentPackage={documentPackage}
        setActiveTab={setActiveTab}
      />
    );
  };

  const renderHistory = () => {
    return <DocumentPackageHistory documentPackageId={documentPackage?.id} />;
  };

  const tabs = [
    {
      label: getLocalizedText("payment_request.detail.tabs.info"),
      tabIndex: EDocumentPackageTabs.Info,
    },
    {
      label: getLocalizedText("payment_request.detail.tabs.history"),
      tabIndex: EDocumentPackageTabs.History,
    },
  ];

  return (
    <>
      {renderMeta()}
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
      />
      <TabPanel value={activeTab} index={EDocumentPackageTabs.Info}>
        {renderInfo()}
      </TabPanel>
      <TabPanel value={activeTab} index={EDocumentPackageTabs.History}>
        {renderHistory()}
      </TabPanel>
    </>
  );
};
