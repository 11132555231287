var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
export var Image = function (_a) {
    var sourceSet = _a.sourceSet, source = _a.source, crossOrigin = _a.crossOrigin, rest = __rest(_a, ["sourceSet", "source", "crossOrigin"]);
    var finalSourceSet = sourceSet
        ? sourceSet
            .map(function (_a) {
            var subSource = _a.source, descriptor = _a.descriptor;
            return "".concat(subSource, " ").concat(descriptor);
        })
            .join(",")
        : null;
    return finalSourceSet ? (
    // eslint-disable-next-line jsx-a11y/alt-text
    _jsx("img", __assign({ src: source, srcSet: finalSourceSet, crossOrigin: crossOrigin }, rest))) : (
    // eslint-disable-next-line jsx-a11y/alt-text
    _jsx("img", __assign({ src: source }, rest, { crossOrigin: crossOrigin })));
};
