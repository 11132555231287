import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import React from "react";
import DetailArchivedCard from "../../../../../components/DetailArchivedCard";
import * as t from "../../../../../services/translationService";

interface IProps {
  property: IProperty;
}

export const PropertyArchivedCard: React.FC<IProps> = ({
  property,
}: IProps) => {
  const { unarchive } = propertyHooks.useUnarchive({ id: property.id });

  const handleUnarchiveProperty = () => {
    if (!property) {
      return;
    }

    unarchive({});
  };

  return (
    <DetailArchivedCard
      title={t.propertyDashboardCardArchivedTitle()}
      description={t.propertyDashboardCardArchivedContent()}
      ctaTitle={t.propertyDashboardCardArchivedCta()}
      onClick={handleUnarchiveProperty}
    ></DetailArchivedCard>
  );
};
