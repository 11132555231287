import { jsx as _jsx } from "react/jsx-runtime";
import { Flex } from "@rebass/grid";
import { defaultTheme } from "@rentiohq/web-theme";
import { HalfCircleSpinner, HollowDotsSpinner } from "react-epic-spinners";
import { ESpacings } from "../Grid";
import * as S from "./Loading.styled";
import { ELoadingSize } from "./Loading.types";
export var Loading = function (_a) {
    var _b = _a.asDots, asDots = _b === void 0 ? false : _b, _c = _a.color, color = _c === void 0 ? defaultTheme.colors.neutral60 : _c, size = _a.size;
    var loadingSize = size || asDots ? ELoadingSize.Small : ELoadingSize.Large;
    return asDots ? (_jsx(Flex, { py: ESpacings.base, justifyContent: "center", children: _jsx(HollowDotsSpinner, { size: loadingSize, color: color }) })) : (_jsx(S.Loading, { size: loadingSize, children: _jsx(HalfCircleSpinner, { size: loadingSize, color: defaultTheme.colors.neutral60 }) }));
};
