import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMembersWithOneOfRoles } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import { MuiDialog, Stack } from "@rentiohq/web-shared-next/dist/ui-components";
import { EButtonVariants } from "@rentiohq/web-shared-next/dist/utils/types/button";
import React from "react";
import { ContactCard } from "../../../../components/ContactCard";
import { OpenAppCard } from "../../../../components/OpenAppCard";
import { ContactMethods } from "../components/ContactMethods";
import { ProblemCategories } from "../components/ProblemCategories";
import { EContactMethod, EProblemCategory } from "../types";

interface IProps {
  property: IProperty;
  openDialog: boolean;
  toggleOpenDialog: () => void;
}

const choices = [
  {
    icon: EAssets.ShedIcon,
    label:
      "I have a technical question about my property (devices, keys, fix,...)",
    value: EProblemCategory.Property,
  },
  {
    icon: EAssets.EuroIcon,
    label: "I have a question about a payment (rent, rental deposit, other)",
    value: EProblemCategory.Payment,
  },
  {
    icon: EAssets.ApplicationIcon,
    label: "I want to report a problem in the property (damage, fix, ...)",
    value: EProblemCategory.RentioApp,
  },
];

export const adminRoles = [
  EPropertyMemberTypes.IndexationManager,
  EPropertyMemberTypes.FinancialManager,
  EPropertyMemberTypes.TechnicalManager,
];

export const ContactManager = ({
  property,
  openDialog,
  toggleOpenDialog,
}: IProps) => {
  const [category, setCategory] = React.useState<EProblemCategory>();
  const [method, setMethod] = React.useState<EContactMethod>();
  const [step, setStep] = React.useState(1);

  const handleSelectCategory = (choice: EProblemCategory) =>
    setCategory(choice);

  const handleSelectMethod = (choice: EContactMethod) => setMethod(choice);

  const goToNextStep = () => setStep(prevState => prevState + 1);

  const goToPreviousStep = () => setStep(prevState => prevState - 1);

  const resetStates = () => {
    setCategory(undefined);
    setMethod(undefined);
    setStep(1);
  };

  const handleClose = () => {
    toggleOpenDialog();
    resetStates();
  };

  const getStepActions = () => {
    switch (step) {
      case 2:
        if (category === EProblemCategory.RentioApp) {
          return [
            {
              variant: EButtonVariants.Outlined,
              onClick: goToPreviousStep,
              label: getLocalizedText("system.previous"),
            },
            {
              onClick: handleClose,
              label: getLocalizedText("system.ok"),
            },
          ];
        }
        return [
          {
            variant: EButtonVariants.Outlined,
            onClick: goToPreviousStep,
            label: getLocalizedText("system.previous"),
          },
          { onClick: goToNextStep, label: getLocalizedText("system.next") },
        ];
      case 3:
        return [
          {
            variant: EButtonVariants.Outlined,
            onClick: goToPreviousStep,
            label: getLocalizedText("system.previous"),
          },
          { onClick: handleClose, label: getLocalizedText("system.ok") },
        ];
      default:
        return [
          {
            variant: EButtonVariants.Outlined,
            onClick: handleClose,
            label: getLocalizedText("system.cancel"),
          },
          { onClick: goToNextStep, label: getLocalizedText("system.next") },
        ];
    }
  };

  const getDialogTitle = () => {
    if (step > 1 && category === EProblemCategory.RentioApp) {
      return "nav.report.problem";
    } else {
      return "property.report_problem.contact.manager";
    }
  };

  const getAdminRoles = React.useMemo(() => {
    if (category === EProblemCategory.Payment) {
      return [EPropertyMemberTypes.FinancialManager];
    }

    if (category === EProblemCategory.Property) {
      return [EPropertyMemberTypes.TechnicalManager];
    }

    return [];
  }, [category]);

  const propertyAdmins = getMembersWithOneOfRoles(
    property?.members,
    getAdminRoles,
  );

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <ProblemCategories
            // @ts-ignore
            choices={choices}
            category={category}
            handleSelect={handleSelectCategory}
            title="property.contact_manager.select_reason.title"
          />
        );
      case 2:
        switch (category) {
          case EProblemCategory.Payment:
          case EProblemCategory.Property:
            return (
              <ContactMethods
                method={method}
                handleSelect={handleSelectMethod}
              />
            );
          case EProblemCategory.RentioApp:
          default:
            return <OpenAppCard />;
        }
      case 3:
        return method === EContactMethod.Chat ? (
          <OpenAppCard />
        ) : (
          <Stack flexDirection="row" gap={1} flexWrap="wrap">
            {propertyAdmins.map(admin => (
              <ContactCard
                key={admin?.account?.id}
                account={admin?.account}
                propertyId={property?.id}
              />
            ))}
          </Stack>
        );
    }
  };

  return (
    <MuiDialog
      title={getLocalizedText(getDialogTitle())}
      open={openDialog}
      toggleOpen={handleClose}
      actions={getStepActions()}
      maxWidth={step === 3 ? "unset" : "600px"}
    >
      {renderStepContent()}
    </MuiDialog>
  );
};
