var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import { getDefaultStateAll, registerActions, } from "../generic/all.reducer";
import { getAllEmployees } from "./broker.actions";
import CONFIG from "./broker.config";
export var REDUCER_KEY_BROKER = CONFIG.REDUCER_KEY;
var getDefaultState = function () {
    return merge({}, getDefaultStateAll(), {
        employees: {
            isFetching: false,
            employees: undefined,
            error: undefined,
        },
    });
};
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(getAllEmployees.types.START, function (state) {
    var _a;
    return (__assign(__assign({}, state), { employees: {
            error: undefined,
            isFetching: true,
            employees: (_a = state.employees.employees) !== null && _a !== void 0 ? _a : [],
        } }));
});
reducer.on(getAllEmployees.types.SUCCESS, function (state, _a) {
    var employees = _a.employees;
    return __assign(__assign({}, state), { employees: {
            isFetching: false,
            employees: employees,
            error: undefined,
        } });
});
reducer.on(getAllEmployees.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isFetching: false, fetchError: error }));
});
registerActions({
    entityName: CONFIG.ENTITY_NAME,
    reducer: reducer,
});
// eslint-disable-next-line import/no-default-export
export default reducer;
