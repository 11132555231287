import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import { EPropertyEPCLabel } from "@rentiohq/shared-frontend/dist/types/property.types";
import { isBefore } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { EField } from "./schema.indexContract.types";
export var getInitialValues = function (_a) {
    var _b;
    var indexInfo = _a.indexInfo, contract = _a.contract, defaultGenerateLetter = _a.defaultGenerateLetter, letterTemplates = _a.letterTemplates, property = _a.property;
    return (_b = {},
        _b[EField.CurrentPrice] = contract.currentPrice,
        _b[EField.GenerateLetter] = defaultGenerateLetter,
        _b[EField.LetterType] = letterTemplates === null || letterTemplates === void 0 ? void 0 : letterTemplates[0].id,
        // EPC Fields
        _b[EEpcField.Label] = (property === null || property === void 0 ? void 0 : property.epcLabel) || undefined,
        _b[EEpcField.Value] = (property === null || property === void 0 ? void 0 : property.epcValue) || undefined,
        _b[EEpcField.ExpirationDate] = (property === null || property === void 0 ? void 0 : property.epcExpirationDate) || undefined,
        _b);
};
export var getMaximumPrice = function (params) {
    var indexInfo = params.indexInfo, epcLabel = params.epcLabel, epcExpired = params.epcExpired, isCommercialLease = params.isCommercialLease, forceFullIndexation = params.forceFullIndexation;
    if (forceFullIndexation || isCommercialLease) {
        return indexInfo.newPrice;
    }
    if (epcExpired) {
        return indexInfo.newPriceNoEpc;
    }
    switch (epcLabel || EPropertyEPCLabel.Unknown) {
        case EPropertyEPCLabel.APlus:
        case EPropertyEPCLabel.A:
        case EPropertyEPCLabel.B:
        case EPropertyEPCLabel.C:
            return indexInfo.newPrice;
        case EPropertyEPCLabel.D:
            return indexInfo.newPriceEpcD;
        case EPropertyEPCLabel.E:
            return indexInfo.newPriceEpcE;
        case EPropertyEPCLabel.F:
            return indexInfo.newPriceEpcF;
        case EPropertyEPCLabel.G:
            return indexInfo.newPriceEpcG;
        case EPropertyEPCLabel.Unknown:
        case EPropertyEPCLabel.NoEPCAvailable:
        default:
            return indexInfo.newPriceNoEpc;
    }
};
export var checkIfEpcExpired = function (epcExpiryDate) {
    return epcExpiryDate && isBefore(epcExpiryDate, new Date());
};
