import { SimpleCard } from "@rentiohq/web-shared-next/dist/ui-components";

import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMembersWithOneOfRoles } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import { ts } from "../../../../../services";
import { AccountList } from "../../../../components/AccountList";

interface IProps {
  property: IProperty;
  showActions?: boolean;
}

export const adminRoles = [
  EPropertyMemberTypes.IndexationManager,
  EPropertyMemberTypes.FinancialManager,
  EPropertyMemberTypes.TechnicalManager,
];

export const PropertyManagersCard = (props: IProps) => {
  const { property } = props;

  const propertyAdmins = getMembersWithOneOfRoles(
    property?.members,
    adminRoles,
  );

  const createSubtitle = (accountIds: number[]) => {
    const member = propertyAdmins.find(member =>
      accountIds.includes(member.account.id!),
    );
    if (!member) {
      return undefined;
    }

    return {
      subTitle: member
        .roles!.filter((role: any) => adminRoles.includes(role))
        .map(role => ts.role(role))
        .join(", "),
    };
  };

  return (
    <>
      <SimpleCard title={getLocalizedText("property.property_managers.title")}>
        <AccountList
          accounts={propertyAdmins.map(admin => admin.account)}
          getSubtitle={createSubtitle}
        />
      </SimpleCard>
    </>
  );
};
