import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { logError } from "@rentiohq/shared-frontend/dist/logger/logger";
import {
  Card,
  DisplayText,
  Page,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import {
  useInternalMode,
  useLocaleAsKeys,
} from "@rentiohq/web-shared/dist/redux/system/system.hooks";

export const RentioInternal = () => {
  // Redux
  const { internalModeEnabled, toggleInternalMode } = useInternalMode();
  const { localeAsKeysEnabled, toggleLocaleAsKeys } = useLocaleAsKeys();

  // Render
  return (
    <Page
      title={"Rentio Internal"}
      breadcrumbs={{
        content: "Back to dashboard",
        to: "/",
      }}
    >
      <Spacer weight={ESpacerWeight.W16} />

      <Card>
        <DisplayText>Version</DisplayText>
        <TextStyle>
          {CONFIG.SENTRY_RELEASE || "Release version not set"}
        </TextStyle>
      </Card>

      <Spacer weight={ESpacerWeight.W16} />

      <Card>
        <DisplayText>Deploy date</DisplayText>
        <TextStyle>
          {CONFIG.DEPLOY_DATE ? `${CONFIG.DEPLOY_DATE}` : "Deploy date not set"}
        </TextStyle>
      </Card>

      <Spacer weight={ESpacerWeight.W16} />

      <Card>
        <DisplayText>
          Internal mode enabled: {internalModeEnabled ? "yes" : "no"}
        </DisplayText>

        <ButtonBasic
          title="Toggle internal mode"
          onPress={toggleInternalMode}
        />
      </Card>

      <Card>
        <DisplayText>
          Locale as keys: {localeAsKeysEnabled ? "yes" : "no"}
        </DisplayText>

        <ButtonBasic
          title="Toggle locale as keys"
          onPress={toggleLocaleAsKeys}
        />
      </Card>

      <Card>
        <DisplayText>Crash</DisplayText>

        <ButtonBasic
          title="Simulate crash"
          onPress={() => {
            logError({
              error: new Error("rentio-internal-crash"),
              fatal: true,
            });
          }}
        />
      </Card>
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default RentioInternal;
