var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import { MainCardStyles } from "./MainCard.styles";
export var MainCard = React.forwardRef(function (_a, ref) {
    var _b = _a.border, border = _b === void 0 ? false : _b, boxShadow = _a.boxShadow, children = _a.children, _c = _a.content, content = _c === void 0 ? true : _c, _d = _a.contentClass, contentClass = _d === void 0 ? "" : _d, _e = _a.contentSX, contentSX = _e === void 0 ? {} : _e, darkTitle = _a.darkTitle, shadow = _a.shadow, _f = _a.sx, sx = _f === void 0 ? {} : _f, title = _a.title, startIcon = _a.startIcon, action = _a.action, others = __rest(_a, ["border", "boxShadow", "children", "content", "contentClass", "contentSX", "darkTitle", "shadow", "sx", "title", "startIcon", "action"]);
    var defaultStyles = MainCardStyles(border, boxShadow, shadow);
    var cardSX = defaultStyles.cardSX, headerSX = defaultStyles.headerSX;
    return (_jsxs(Card, __assign({ ref: ref }, others, { sx: __assign({ cardSX: cardSX }, sx), children: [!darkTitle && title && (_jsx(CardHeader, { sx: headerSX, title: _jsxs(Stack, { direction: "row", alignItems: "center", gap: 1, children: [startIcon, _jsx(Typography, { variant: "h6", children: title })] }), action: action })), darkTitle && title && (_jsx(CardHeader, { sx: headerSX, title: _jsxs(Stack, { direction: "row", alignItems: "center", gap: 1, children: [startIcon, _jsx(Typography, { variant: "h6", children: title })] }), action: action })), title && _jsx(Divider, {}), content && (_jsx(CardContent, { sx: contentSX, className: contentClass, children: children })), !content && children] })));
});
