import { ERentioFormField, ERentioFormFormat, } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getMappedLanguages, getMappedNationalities, } from "../../utils/location/location.utils";
import { EIdCardOptions } from "./schema.addContact.1.broker";
import { CONTACT_AGE_RANGE_MAX, CONTACT_AGE_RANGE_MIN, } from "./schema.addContact.constants";
import { EField } from "./schema.addContact.types";
import { getNavigationTitle } from "./schema.addContact.utils";
var companyContactFields = [
    EField.DateOfBirth,
    EField.Email,
    EField.Phone,
    EField.Firstname,
    EField.Lastname,
    EField.Nationality,
    EField.NationalRegisterNumber,
    EField.PlaceOfBirth,
    EField.Language,
];
export var getSchema = function (_a, initialValues) {
    var _b = _a.fields, fields = _b === void 0 ? [] : _b;
    return function (defaultValues) {
        var _a;
        var showField = function (field) { return fields.includes(field); };
        var filteredFields = fields.filter(function (field) {
            return ![EField.NationalRegisterNumber, EField.ForeignIdentityNumber].includes(field);
        });
        return {
            // Mark the required fields except the National Register and Foreign Identity numbers,
            // if they are required, we should mark them required conditional to the nationality
            required: filteredFields,
            properties: (_a = {},
                _a[EField.Header] = {
                    default: undefined,
                },
                _a[EField.Firstname] = {
                    // @ts-ignore
                    showIf: function (_) { return showField(EField.Firstname); },
                    default: getValue(EField.Firstname, initialValues, defaultValues),
                },
                _a[EField.Lastname] = {
                    // @ts-ignore
                    showIf: function (_) { return showField(EField.Lastname); },
                    default: getValue(EField.Lastname, initialValues, defaultValues),
                },
                _a[EField.Email] = {
                    // @ts-ignore
                    showIf: function (_) { return showField(EField.Email); },
                    default: getValue(EField.Email, initialValues, defaultValues),
                },
                _a[EField.Phone] = {
                    // @ts-ignore
                    showIf: function (_) { return showField(EField.Phone); },
                    default: getValue(EField.Phone, initialValues, defaultValues),
                },
                _a[EField.Company] = {
                    // @ts-ignore
                    showIf: function (_) { return showField(EField.Company); },
                    default: getValue(EField.Company, initialValues, defaultValues),
                },
                _a[EField.Address] = {
                    // @ts-ignore
                    showIf: function (_) { return showField(EField.Address); },
                    default: getValue(EField.Address, initialValues, defaultValues),
                },
                _a[EField.VATNumber] = {
                    // @ts-ignore
                    showIf: function (_) { return showField(EField.VATNumber); },
                    default: getValue(EField.VATNumber, initialValues, defaultValues),
                },
                _a[EField.Header2] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function (_) {
                        return !!initialValues[EField.Company] &&
                            fields.some(function (field) { return companyContactFields.includes(field); });
                    },
                },
                _a[EField.Language] = {
                    // @ts-ignore
                    showIf: function (_) { return showField(EField.Language); },
                    default: getValue(EField.Language, initialValues, defaultValues),
                    // @ts-ignore
                    options: getMappedLanguages(),
                },
                _a[EField.DateOfBirth] = {
                    // @ts-ignore
                    showIf: function (_) { return showField(EField.DateOfBirth); },
                    default: getValue(EField.DateOfBirth, initialValues, defaultValues),
                },
                _a[EField.PlaceOfBirth] = {
                    // @ts-ignore
                    showIf: function (_) { return showField(EField.PlaceOfBirth); },
                    default: getValue(EField.PlaceOfBirth, initialValues, defaultValues),
                },
                _a[EField.Nationality] = {
                    // @ts-ignore
                    showIf: function (_) { return showField(EField.Nationality); },
                    default: getValue(EField.Nationality, initialValues, defaultValues),
                    // @ts-ignore
                    options: getMappedNationalities(),
                },
                _a[EField.IdType] = {
                    default: getValue(EField.IdType, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        EIdCardOptions.BelgianIdCardNumber,
                        EIdCardOptions.PassportNumber,
                    ].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("system.national_register_number.".concat(id)),
                    }); }),
                    showIf: function (formData) {
                        return (showField(EField.NationalRegisterNumber) ||
                            showField(EField.ForeignIdentityNumber)) &&
                            formData[EField.Nationality] !== "BE";
                    },
                },
                _a[EField.NationalRegisterNumber] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        return (showField(EField.NationalRegisterNumber) ||
                            showField(EField.ForeignIdentityNumber)) &&
                            (formData[EField.Nationality] === "BE" ||
                                (formData[EField.Nationality] !== "BE" &&
                                    formData[EField.IdType] ===
                                        EIdCardOptions.BelgianIdCardNumber));
                    },
                    // @ts-ignore
                    requiredIf: function (formData) {
                        return (showField(EField.NationalRegisterNumber) ||
                            showField(EField.ForeignIdentityNumber)) &&
                            (formData[EField.Nationality] === "BE" ||
                                (formData[EField.Nationality] !== "BE" &&
                                    formData[EField.IdType] ===
                                        EIdCardOptions.BelgianIdCardNumber));
                    },
                    format: ERentioFormFormat.NationalRegisterNumber,
                    default: getValue(EField.NationalRegisterNumber, initialValues, defaultValues),
                },
                _a[EField.ForeignIdentityNumber] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        return (showField(EField.NationalRegisterNumber) ||
                            showField(EField.ForeignIdentityNumber)) &&
                            formData[EField.Nationality] !== "BE" &&
                            formData[EField.IdType] === EIdCardOptions.PassportNumber;
                    },
                    // @ts-ignore
                    requiredIf: function (formData) {
                        return (showField(EField.NationalRegisterNumber) ||
                            showField(EField.ForeignIdentityNumber)) &&
                            formData[EField.Nationality] !== "BE" &&
                            formData[EField.IdType] === EIdCardOptions.PassportNumber;
                    },
                    default: getValue(EField.ForeignIdentityNumber, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    // const { fields = [] } = extraData;
    // const showField = (field: EField) => fields.includes(field);
    var _a;
    return _a = {
            "rentio:navigationTitle": getNavigationTitle(extraData),
            "rentio:trackingKey": "contact_complete"
        },
        // "ui:field": ERentioFormField.Grid,
        // grid: {
        //   row: [
        //     showField(EField.Header)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.Header],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.Company)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.Company],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.Address)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.Address],
        //           },
        //         }
        //       : undefined,
        //     // Show empty space if address is show
        //     showField(EField.Address)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.VATNumber)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.VATNumber],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.Header2)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.Header2],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.Firstname)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.Firstname],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.Lastname)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.Lastname],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.Email)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.Email],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.Phone)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.Phone],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.Language)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.Language],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.DateOfBirth)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.DateOfBirth],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.PlaceOfBirth)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.PlaceOfBirth],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.Nationality)
        //       ? {
        //           col: {
        //             width: [1, 1 / 2],
        //             children: [EField.Nationality],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.NationalRegisterNumber) ||
        //     showField(EField.ForeignIdentityNumber)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [EField.IdType],
        //           },
        //         }
        //       : undefined,
        //     showField(EField.NationalRegisterNumber) ||
        //     showField(EField.ForeignIdentityNumber)
        //       ? {
        //           col: {
        //             width: 1,
        //             children: [
        //               EField.NationalRegisterNumber,
        //               EField.ForeignIdentityNumber,
        //             ],
        //           },
        //         }
        //       : undefined,
        //   ].filter(item => !!item),
        // },
        _a[EField.Header] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contact.complete"),
        },
        _a[EField.Company] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("contact.company.name.label"),
        },
        _a[EField.Address] = {
            "ui:field": ERentioFormField.Address,
            title: getLocalizedText("system.address"),
        },
        _a[EField.VATNumber] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("contact.company.vat_number.label"),
        },
        _a[EField.Header2] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("contact.add_company.section.contact_info.title"),
        },
        _a[EField.Firstname] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.firstname"),
        },
        _a[EField.Lastname] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.lastname"),
        },
        _a[EField.Email] = {
            "ui:field": ERentioFormField.Email,
            title: getLocalizedText("system.email"),
        },
        _a[EField.Phone] = {
            "ui:field": ERentioFormField.Phone,
            title: getLocalizedText("system.phone"),
        },
        _a[EField.Language] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("system.language"),
        },
        _a[EField.DateOfBirth] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("system.date_of_birth"),
            minDate: CONTACT_AGE_RANGE_MIN,
            maxDate: CONTACT_AGE_RANGE_MAX,
            formatSummary: function (formData) { return (formData ? formatDate(formData) : undefined); },
        },
        _a[EField.PlaceOfBirth] = {
            "ui:field": ERentioFormField.Address,
            title: getLocalizedText("system.place_of_birth"),
            lookupTypes: ["(cities)"],
        },
        _a[EField.Nationality] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("system.nationality"),
        },
        _a[EField.IdType] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("system.identification"),
            //info: getLocalizedText("system.identification.infotext"),
        },
        _a[EField.NationalRegisterNumber] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.national_register_number"),
            iMaskProps: {
                mask: "00.00.00-000.00",
                lazy: false,
                unmask: true,
            },
        },
        _a[EField.ForeignIdentityNumber] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("system.national_register_number.foreigner"),
        },
        _a;
};
