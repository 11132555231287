import { IApplication } from "@rentiohq/shared-frontend/dist/types/application.types";
import {
  Button,
  DisplayText,
  DropdownMenu,
  Dropzone,
  DropzonePlaceholder,
  ESpacings,
  Grid,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { spacing } from "@rentiohq/web-theme";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import {
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";
import * as t from "../../../../../services/translationService";
import { IApplicationDropDownHandler } from "../PropertyDetailApplications";
import * as S from "../PropertyDetailApplications.styled";
import { DragItem, IMultiSelectProps } from "./DragItem/DragItem";

interface IProps extends IMultiSelectProps {
  id: number;
  items: IApplication[];
  allowedSteps: [];
  isDisabled?: boolean;
  actions?: IAction[];
  actionsLabel?: string;
  selectedItemIds: string[];
  handler: IApplicationDropDownHandler;
}

export const PropertyApplicationDropArea: React.FC<IProps> = ({
  id,
  items = [],
  allowedSteps = [],
  isDisabled = false,
  actions,
  actionsLabel,
  selectedItemIds,
  handler,
}) => {
  const isEmptyArea = isEmpty(items);

  return (
    <S.DropArea>
      <Grid
        mb={ESpacings.tight}
        justifyContent={["initial", "initial", "space-between"]}
        flexDirection={["column", "column", "row-reverse"]}
        alignItems={["initial", "initial", "flex-end"]}
      >
        <Grid.Item style={{ marginBottom: spacing("base") }}>
          {actions && (
            <DropdownMenu
              children={
                <Button appearance="primary" iconAfterName="chevronDown">
                  {actionsLabel}
                </Button>
              }
              actions={actions}
            />
          )}
        </Grid.Item>
        <DisplayText element="h3" size="small">
          {t.applicationStateTitle(id)}
          {items && items.length > 0 ? ` (${items.length})` : ""}
        </DisplayText>
      </Grid>
      <Droppable droppableId={`${id}`} isDropDisabled={isDisabled}>
        {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
          <Dropzone
            ref={provided.innerRef}
            {...provided.droppableProps}
            isDraggingOver={snapshot.isDraggingOver}
            isDisabled={isDisabled}
            isEmpty={isEmptyArea}
            state={`${id}`}
          >
            {items?.map((item, index) => (
              <DragItem
                step={id}
                item={item}
                index={index}
                handler={handler}
                allowedSteps={allowedSteps}
                key={`${index}Item`}
                isDisabled={isDisabled}
                isSelected={selectedItemIds.includes(item.id)}
              />
            ))}
            {provided.placeholder}
            {isEmptyArea && (
              <DropzonePlaceholder>
                {t.applicationStatePlaceholder(id)}
              </DropzonePlaceholder>
            )}
          </Dropzone>
        )}
      </Droppable>
    </S.DropArea>
  );
};
