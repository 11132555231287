import * as S from "@rentiohq/shared-frontend/dist/components/components/TaskListItem/TaskListItem.styles";
import { ITask } from "@rentiohq/shared-frontend/dist/types/task.types";
import {
  differenceInDays,
  startOfDay,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { memo } from "react";
import * as TS from "../../Tasks.styled";

interface IDueDateProps extends ITask {}

const getKeyForIndex = (index: number) => {
  return getLocalizedText(
    `task.days_left.to.due_date${index < 0 ? "_negative" : ""}`,
    {
      days: `${Math.abs(index)}`,
    },
    Math.abs(index),
  );
};

const DueDate = ({ dueDate }: IDueDateProps) => {
  if (!dueDate) return <p>{getLocalizedText("task.date.no_selection")}</p>;

  const days = differenceInDays(startOfDay(dueDate), startOfDay(new Date()));

  return (
    <TS.FlexColumn>
      {formatDate(dueDate)}
      <S.DeadlineTitle numberOfLines={1} days={days}>
        {getKeyForIndex(days)}
      </S.DeadlineTitle>
    </TS.FlexColumn>
  );
};

// eslint-disable-next-line import/no-default-export
export default memo(DueDate);
