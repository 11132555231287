import { appendFilter } from "../../utils/api.utils";
import api from "../../utils/api/api.utils";
export var FETCH_LIMIT = 5;
export var getComments = function (baseUrl, skip, limit) {
    if (limit === void 0) { limit = FETCH_LIMIT; }
    var filter = {
        order: "createdAt DESC",
        limit: limit,
        skip: skip,
    };
    return api.get(appendFilter("/".concat(baseUrl, "/comments"), filter));
};
export var createComment = function (baseUrl, content) {
    return api.post("/".concat(baseUrl, "/comments"), {
        data: { content: content },
    });
};
export var updateComment = function (commentId, data) {
    return api.put("/comments/".concat(commentId), { data: data });
};
export var deleteComment = function (commentId) {
    return api.delete("/comments/".concat(commentId));
};
