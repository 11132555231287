import { Box, Flex } from "@rebass/grid";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EPropertyTypeId,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  ESpacings,
  Grid,
  Icon,
  Image,
} from "@rentiohq/web-shared/dist/components";
import createContractCardImage from "../../../../../assets/card-create-contract.svg";
import * as t from "../../../../../services/translationService";

interface IProps {
  property: IProperty;
  propertyId: number;
}

const NoContractCard = (props: IProps) => {
  // Selectors
  const { user } = authHooks.useSelf();

  const hasMoveServiceForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.MoveService,
  );
  // Render
  if (!user) {
    return null;
  }

  // Hide if group
  if (props.property.typeId === EPropertyTypeId.Group) {
    return null;
  }

  return (
    <Card
      heading={t.cardCreateContractHeading()}
      footerActions={[
        {
          content: t.cardCreateContractAction(),
          url: `/properties/${props.propertyId}/contracts/add`,
          appearance: "primary",
        },
      ]}
    >
      <Flex alignItems="center" flexWrap="wrap">
        <Box flex={1} mr={ESpacings.base}>
          <Box mb={ESpacings.loose}>{t.cardCreateContractContent()}</Box>
          <Grid spacing="tight">
            <Grid.Item width={[1, 1, 1 / 2]}>
              <Flex alignItems="center" mb={ESpacings.tight}>
                <Icon source="checkBadge" color="primary" />
                <Box flex="1" ml={ESpacings.tight}>
                  {t.cardCreateContractFeatureIndexation()}
                </Box>
              </Flex>
            </Grid.Item>
            <Grid.Item width={[1, 1, 1 / 2]}>
              <Flex alignItems="center" mb={ESpacings.tight}>
                <Icon source="checkBadge" color="primary" />
                <Box flex="1" ml={ESpacings.tight}>
                  {getLocalizedText("card.create_contract.feature.renewal")}
                </Box>
              </Flex>
            </Grid.Item>
            <Grid.Item width={[1, 1, 1 / 2]}>
              <Flex alignItems="center" mb={ESpacings.tight}>
                <Icon source="checkBadge" color="primary" />
                <Box flex="1" ml={ESpacings.tight}>
                  {t.cardCreateContractFeatureDigitalSigning()}
                </Box>
              </Flex>
            </Grid.Item>
            <Grid.Item width={[1, 1, 1 / 2]}>
              <Flex alignItems="center" mb={ESpacings.tight}>
                <Icon source="checkBadge" color="primary" />
                <Box flex="1" ml={ESpacings.tight}>
                  {t.cardCreateContractFeatureRentDeposit()}
                </Box>
              </Flex>
            </Grid.Item>
            <Grid.Item width={[1, 1, 1 / 2]}>
              <Flex alignItems="center" mb={ESpacings.tight}>
                <Icon source="checkBadge" color="primary" />
                <Box flex="1" ml={ESpacings.tight}>
                  {t.cardCreateContractFeatureDigitalPayments()}
                </Box>
              </Flex>
            </Grid.Item>
            {hasMoveServiceForBroker && (
              <Grid.Item width={[1, 1, 1 / 2]}>
                <Flex alignItems="center" mb={ESpacings.tight}>
                  <Icon source="checkBadge" color="primary" />
                  <Box flex="1" ml={ESpacings.tight}>
                    {t.cardCreateContractFeatureMoveService()}
                  </Box>
                </Flex>
              </Grid.Item>
            )}
          </Grid>
        </Box>
        <Image alt="" source={createContractCardImage} />
      </Flex>
    </Card>
  );
};

// eslint-disable-next-line import/no-default-export
export default NoContractCard;
