import {
  alpha,
  getFontSize,
  useTheme,
} from "@rentiohq/web-shared-next/dist/themes";
import {
  EFontSizes,
  EFontWeights,
} from "@rentiohq/web-shared-next/dist/themes/types";
import { ECaptionVariant } from "../../types";

export const AccountListItemStyles = (
  captionColor: ECaptionVariant = ECaptionVariant.Default,
) => {
  const theme = useTheme();

  const getCaptionColor = () => {
    switch (captionColor) {
      case ECaptionVariant.Success:
        return theme.palette.success.main;
      case ECaptionVariant.Warning:
        return theme.palette.warning.main;
      case ECaptionVariant.Error:
        return theme.palette.error.main;
      default:
        return theme.palette.text.secondary;
    }
  };
  return {
    content: {
      accountItem: {
        svg: {
          color: alpha(theme.palette.grey[700], 0.5),
        },
      },
      subtitle: {
        color: getCaptionColor(),
        fontSize: getFontSize(EFontSizes.ExtraSmall),
        fontWeight: EFontWeights.Regular,
        lineHeight: "16px",
      },
    },
  };
};
