import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import {
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EPayoutType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { EPaymentRequestStatus } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { appendWhere } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { startOfDay } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { Loading } from "@rentiohq/web-shared/dist/components";
import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";

interface ILatePaymentsCountProps {
  contract: IContract;
}

const NOW = new Date();

const LatePaymentsCount = ({ contract }: ILatePaymentsCountProps) => {
  const dispatch = useDispatch();
  const LATE_PAYMENTS_COUNT_ID = `late-payments-${contract.id}-count`;

  const latePaymentsCount = useSelector<
    IRootStore,
    ReturnType<typeof countSelectors.getCount>
  >(state => countSelectors.getCount(state, LATE_PAYMENTS_COUNT_ID));
  const isFetchingLatePaymentsCount = useSelector<
    IRootStore,
    ReturnType<typeof countSelectors.getIsFetchingCount>
  >(state => countSelectors.getIsFetchingCount(state, LATE_PAYMENTS_COUNT_ID));

  useEffect(() => {
    if (
      contract &&
      latePaymentsCount === undefined &&
      !isFetchingLatePaymentsCount
    ) {
      dispatch(
        countActions.getCount.actions.start({
          countIdentifier: LATE_PAYMENTS_COUNT_ID,
          countBase: appendWhere("/payment-requests/count", {
            status: { neq: EPaymentRequestStatus.Paid },
            requestPropertyId: contract.propertyId,
            payoutType: EPayoutType.Charge,
            dueDateAt: {
              lt: startOfDay(NOW).getTime(),
            },
            requestPayerAccountId: {
              inq: contract?.members
                .filter(m => m.roles.includes(EContractMemberTypes.Tenant))
                .map(t => t.account.id),
            },
          }),
        }),
      );
    }
  }, [contract, latePaymentsCount, isFetchingLatePaymentsCount]);

  if (isFetchingLatePaymentsCount) return <Loading asDots={true} />;
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {latePaymentsCount}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default memo(LatePaymentsCount);
