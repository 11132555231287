import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { CONFIG } from "../config/app.config";
import { EProduct } from "../config/app.config.types";
import * as requirementsActions from "../redux/requirements/requirements.actions";
import * as requirementsSelectors from "../redux/requirements/requirements.selectors";
import { isBefore } from "../utils/date-fns.utils";
import { ScreenDisabled } from "./components";
import { EScreenDisabledType } from "./components/ScreenDisabled";
var RequirementsProvider = function (props) {
    var children = props.children;
    var dispatch = useDispatch();
    // State
    var requirements = useSelector(function (state) { return requirementsSelectors.getRequirements(state); });
    // Lifecycle
    React.useEffect(function () {
        dispatch(requirementsActions.getRequirements.actions.start({}));
    }, []);
    // Helpers
    var getMinVersionDate = function () {
        switch (CONFIG.PRODUCT) {
            case EProduct.Dashboard:
                return requirements === null || requirements === void 0 ? void 0 : requirements.dashboardMinVersionDate;
            default:
                return undefined;
        }
    };
    // Render
    if (!requirements) {
        return null;
    }
    var minVersionDate = getMinVersionDate();
    if (minVersionDate && CONFIG.DEPLOY_DATE) {
        var shouldForceUpdate = isBefore(CONFIG.DEPLOY_DATE, minVersionDate);
        if (shouldForceUpdate) {
            // eslint-disable-next-line no-console
            console.info("Showing forced update", {
                minVersionDate: minVersionDate,
                deployDate: CONFIG.DEPLOY_DATE,
                requirements: requirements,
            });
            return _jsx(ScreenDisabled, { type: EScreenDisabledType.ForcedUpdate });
        }
    }
    if (requirements.downtimeMessage) {
        // eslint-disable-next-line no-console
        console.info("Showing downtime", {
            downtimeMessage: requirements.downtimeMessage,
            requirements: requirements,
        });
        return (_jsx(ScreenDisabled, { type: EScreenDisabledType.Downtime, message: requirements.downtimeMessage }));
    }
    return _jsx(_Fragment, { children: children });
};
// eslint-disable-next-line import/no-default-export
export default RequirementsProvider;
