import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  EElectricityMeterType,
  EElectricityType,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { FormValidation } from "react-native-jsonschema-form-test";
import { EAN_MASK } from "./schema.requestMoveService.config";
import { EField, IExtraData } from "./schema.requestMoveService.types";
import { validationErrorsForEan } from "./schema.requestMoveService.utils";

export const getSchema =
  (initialValues: { [key: string]: any }, _: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [EField.ElectricityType],
      properties: {
        [EField.ElectricityType]: {
          default: getValue(
            EField.ElectricityType,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: [
            EElectricityType.Single,
            EElectricityType.Double,
            EElectricityType.Unknown,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `move.option.electricity_type.${id}`.toLowerCase(),
            ),
          })),
        },

        [EField.ElectricityEan]: {
          default: getValue(
            EField.ElectricityEan,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: (formData: any) =>
            formData[EField.ElectricityType] &&
            formData[EField.ElectricityType] !== EElectricityType.Unknown,
        },

        [EField.ElectricityMeterNumberBoth]: {
          default: getValue(
            EField.ElectricityMeterNumberBoth,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          showIf: (formData: any) =>
            formData[EField.ElectricityType] &&
            formData[EField.ElectricityType] !== EElectricityType.Unknown,
        },
        [EField.ElectricityMeterType]: {
          default: getValue(
            EField.ElectricityMeterType,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: [
            EElectricityMeterType.Standard,
            EElectricityMeterType.Opening,
            EElectricityMeterType.Budget,
            EElectricityMeterType.Empty,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `move.option.electricity_meter_type.${id}`.toLowerCase(),
            ),
          })),
          // @ts-ignore
          showIf: (formData: any) =>
            formData[EField.ElectricityType] &&
            formData[EField.ElectricityType] !== EElectricityType.Unknown,
          requiredIf: (formData: any) =>
            formData[EField.ElectricityType] &&
            formData[EField.ElectricityType] !== EElectricityType.Unknown,
        },
      },
    };
  };

export const uiSchema = (_: IExtraData): IUiSchema<EField> => ({
  [EField.ElectricityType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.electricity_type.title"),
  },
  [EField.ElectricityEan]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.electricity_ean.title"),
    optional: true,
    iMaskProps: {
      mask: EAN_MASK,
      lazy: false,
      unmask: true,
      placeholderChar: ". ",
    },
  },
  [EField.ElectricityMeterNumberBoth]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.electricity_meter_number_both.title"),
    optional: true,
  },
  [EField.ElectricityMeterType]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.electricity_meter_type.title"),
  },
});

export const getValidate =
  (_: IExtraData) => (formData: any, errors: FormValidation) => {
    validationErrorsForEan(formData[EField.ElectricityEan]).forEach(error => {
      errors[EField.ElectricityEan].addError(error);
    });

    return errors;
  };
