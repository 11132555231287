import { ERentioFormField } from "../../components/components/Form";
import { getIbans } from "../../redux/contact/contact.actions";
import * as contactSelectors from "../../redux/contact/contact.selectors";
import { getName } from "../../redux/contact/contact.utils";
import { getValue, ibanSelectInlineValueSelector, } from "../../redux/form/form.utils";
import { EContractMemberTypes } from "../../types/contract.types";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { formatCurrency } from "../../utils/number.utils";
import { EField } from "./schema.releaseRentDeposit.types";
import { getNavigationTitle, getOwners, } from "./schema.releaseRentDeposit.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.OwnerBankAccountId],
            properties: (_a = {},
                _a[EField.Header + "2"] = {
                    default: undefined,
                },
                _a[EField.Info] = {
                    default: undefined,
                },
                _a[EField.OwnerBankAccountId] = {
                    default: getValue(EField.OwnerBankAccountId, initialValues, defaultValues),
                },
                _a),
        });
    };
};
var getAccountId = function (formData, extraData) {
    var receiver = formData[EField.OwnerReceiver];
    if (!receiver) {
        var ownerAccounts = getOwners(extraData.relatedContract);
        return ownerAccounts[0].id;
    }
    return receiver[0];
};
export var uiSchema = function (extraData) {
    var _a;
    var tenants = extraData.relatedContract.members.filter(function (member) {
        return member.roles.includes(EContractMemberTypes.Tenant) ||
            member.roles.includes(EContractMemberTypes.Parent);
    });
    var owners = extraData.relatedContract.members.filter(function (member) {
        return member.roles.includes(EContractMemberTypes.Owner);
    });
    return _a = {
            "rentio:title": getLocalizedText("rent_deposit_release.owner_bankaccount.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "2"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rent_deposit_release.owner_bankaccount.heading"),
        },
        _a[EField.Info] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("rent_deposit_release.contract_info.heading"),
            subtitle: (function () {
                return getLocalizedText("rent_deposit_release.contract_info.content", {
                    startDate: formatDate(extraData.relatedContract.startDate),
                    amount: formatCurrency(extraData.relatedContract.amount || 0),
                    tenants: tenants.map(function (member) { return getName(member.account); }).join(", "),
                    owners: owners.map(function (member) { return getName(member.account); }).join(", "),
                });
            })(),
            // TODO: Icon
            // icon: images.ICO_CHAT_SEND
        },
        _a[EField.OwnerBankAccountId] = {
            "ui:field": ERentioFormField.IbanSelectInline,
            title: getLocalizedText("rent_deposit_release.owner_bank_account.heading"),
            addIbanCtaTitle: getLocalizedText("iban.add.cta"),
            valueSelector: function (state, input) {
                return ibanSelectInlineValueSelector(state, input);
            },
            accountIdToAddIban: function (formData) { return getAccountId(formData, extraData); },
            customIsFetchingSelector: function (state, formData) {
                return contactSelectors.isFetchingIbans(state, getAccountId(formData, extraData));
            },
            customIbansSelector: function (state, formData) {
                return contactSelectors.getIbans(state, undefined, getAccountId(formData, extraData));
            },
            customGetIbansActions: function (formData) { return [
                function () {
                    return getIbans.actions.start({
                        accountId: getAccountId(formData, extraData),
                    });
                },
            ]; },
        },
        _a;
};
export var getValidate = function () { return function (formData, errors) {
    if (!formData[EField.OwnerBankAccountId]) {
        var noBankAccountError = getLocalizedText("rent_deposit_release.error.no_bank_account");
        errors[EField.OwnerBankAccountId].addError(noBankAccountError);
    }
    return errors;
}; };
