import * as authActions from "@rentiohq/shared-frontend/dist/redux/auth/auth.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as authSelectors from "@rentiohq/shared-frontend/dist/redux/auth/auth.selectors";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as chatActions from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.actions";
import * as chatUtils from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { setLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { MainLayout } from "@rentiohq/web-shared-next/dist/layout-components/MainLayout";
import { NavigationScroll } from "@rentiohq/web-shared-next/dist/layout-components/NavigationScroll";
import { Notistack } from "@rentiohq/web-shared-next/dist/layout-components/NotiStack";
import ThemeCustomization from "@rentiohq/web-shared-next/dist/themes";
import {
  ConfirmModal,
  Snackbar,
} from "@rentiohq/web-shared-next/dist/ui-components";
import * as systemActions from "@rentiohq/web-shared/dist/redux/system/system.actions";
import { useLocaleAsKeys } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { ChatListener } from "components/ChatListener";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DetailsDrawer } from "./components/DetailsDrawer";
import { InvitesModal } from "./components/InvitesModal";
import { MobileAppModal } from "./components/Modals/MobileAppModal";
import "./index.css";

const AppMemo = React.memo(() => (
  <>
    <ChatListener renderTime={new Date()} />

    {/* <BackgroundChecks /> */}

    <DetailsDrawer />
  </>
));

export const TenantDashboardApp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const hasAccessToken = useSelector(authSelectors.hasAccessToken);
  const dispatch = useDispatch();

  const { user } = authHooks.useSelf();

  const { localeAsKeysEnabled } = useLocaleAsKeys();

  React.useEffect(() => {
    if (hasAccessToken && location?.pathname === "/") {
      navigate("/properties");
    }
  }, [hasAccessToken]);

  React.useEffect(() => {
    if (!hasAccessToken) {
      return;
    }

    dispatch(
      contactActions.getContact.actions.start({
        contactId: EContactCustomId.Me,
      }),
    );

    dispatch(
      contactActions.getContact.actions.start({
        contactId: EContactCustomId.MeMaster,
      }),
    );

    dispatch(authActions.getUser.actions.start({}));

    dispatch(
      chatActions.getInfiniteLoadStart.getAction({
        query: chatUtils.getChatsQuery({}),
        refresh: true,
      }),
    );
  }, [hasAccessToken]);

  React.useEffect(() => {
    const locale = user?.locale || navigator.language;

    setLocale(localeAsKeysEnabled ? ELocale.INTERNAL : locale);
  }, [user, localeAsKeysEnabled]);

  React.useEffect(() => {
    if (!user) {
      return;
    }

    // @ts-ignore
    window.zE?.identify?.({
      name: getName(user),
      email: user.email || user.unverifiedEmail,
    });
  }, [user]);

  React.useEffect(() => {
    const intervalTimeInMinutes = 10;
    const intervalTimeInMilliseconds = 1000 * 60 * intervalTimeInMinutes;

    const interval = setInterval(() => {
      dispatch(
        systemActions.getIndexLastModified.actions.start({
          url: `${window.location.origin}/index.html`,
        }),
      );
    }, intervalTimeInMilliseconds);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <ThemeCustomization>
      <AppMemo />
      <NavigationScroll>
        <Notistack>
          <InvitesModal />
          <MainLayout />
          <Snackbar />
          <ConfirmModal />
          <MobileAppModal />
        </Notistack>
      </NavigationScroll>
    </ThemeCustomization>
  );
};
