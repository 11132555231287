import { jsx as _jsx } from "react/jsx-runtime";
import { getConnectionLabel } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { EContactType } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { Tooltip } from "../Tooltip";
import * as S from "./ContactConnectionIndicator.styled";
export var ContactConnectionIndicator = function (props) {
    var contact = props.contact, _a = props.showTooltip, showTooltip = _a === void 0 ? true : _a, _b = props.forceDisplay, forceDisplay = _b === void 0 ? false : _b;
    if (!(contact === null || contact === void 0 ? void 0 : contact.hasOwnProperty("inviteAcceptedAt")) && !forceDisplay) {
        return null;
    }
    var hasAccepted = false;
    if (contact === null || contact === void 0 ? void 0 : contact.inviteAcceptedAt) {
        hasAccepted = true;
    }
    if ((contact === null || contact === void 0 ? void 0 : contact.type) === EContactType.Personal) {
        hasAccepted = true;
    }
    var variation = hasAccepted ? "success" : "error";
    if (showTooltip && contact) {
        return (_jsx(Tooltip, { tooltipContent: getConnectionLabel({
                contact: contact,
                showConnectionDate: false,
            }), placement: "right", children: _jsx(S.Dot, { variation: variation }) }));
    }
    return _jsx(S.Dot, { variation: variation });
};
