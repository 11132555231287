import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EField, IExtraData } from "./schema.createDocument.types";

export const getInitialValues = ({ document, files }: IExtraData): IValues => {
  if (document) {
    return {
      [EField.Name]: document.filename,
      [EField.Category]: document.categoryId,
    };
  }

  if (files) {
    return {
      [EField.Name]: files[0].name,
    };
  }

  return {};
};
