import {
  getSchema as getSchema1,
  uiSchema as uiSchema1,
} from "./schema.registration.1";
import {
  getSchema as getSchema2,
  uiSchema as uiSchema2,
} from "./schema.registration.2";
import {
  getSchema as getSchema3,
  uiSchema as uiSchema3,
} from "./schema.registration.3";
import {
  getSchema as getSchema4,
  uiSchema as uiSchema4,
} from "./schema.registration.4";
import {
  getSchema as getSchema5,
  uiSchema as uiSchema5,
} from "./schema.registration.5";
import {
  getSchema as getSchema6,
  uiSchema as uiSchema6,
} from "./schema.registration.6";
import { EField, IExtraData } from "./schema.registration.types";
import { getInitialValues } from "./schema.registration.utils";

// eslint-disable-next-line import/no-default-export
export default (extraData: IExtraData) => {
  const initialValues = getInitialValues(extraData);

  return [
    {
      schema: getSchema1(initialValues, extraData),
      uiSchema: uiSchema1(extraData),
    },
    {
      schema: getSchema2(initialValues, extraData),
      uiSchema: uiSchema2(extraData),
    },
    {
      schema: getSchema3(initialValues, extraData),
      uiSchema: uiSchema3(extraData),
    },
    {
      schema: getSchema4(initialValues, extraData),
      uiSchema: uiSchema4(extraData),
    },
    {
      schema: getSchema5(initialValues, extraData),
      uiSchema: uiSchema5(extraData),
    },
    {
      schema: getSchema6(initialValues, extraData),
      uiSchema: uiSchema6(extraData),
      showIf: (formData: any) =>
        formData[EField.LocationDescriptionShouldUpload] === "now",
    },
  ];
};
