import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./system.reducer";
var PERSIST_CONFIG = {
    key: "system",
    storage: storage,
    whitelist: ["preferencesLocalStorage"],
};
// eslint-disable-next-line import/no-default-export
export default persistReducer(PERSIST_CONFIG, reducer);
