var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { generateReducer, } from "../generators/generator.reducer";
import { createWithDocumentsFailed, createWithDocumentsStart, updateWithDocumentsFailed, updateWithDocumentsStart, updateWithDocumentsSucceeded, } from "./legalCase.actions";
import { GENERATOR_PARAMS } from "./legalCase.constants";
var reducer = generateReducer(GENERATOR_PARAMS);
reducer.on(createWithDocumentsStart.type, function (state, action) {
    return __assign(__assign({}, state), { create: {
            pending: true,
            error: undefined,
        } });
});
reducer.on(createWithDocumentsFailed.type, function (state, action) {
    return __assign(__assign({}, state), { create: {
            pending: false,
            error: action.error,
        } });
});
reducer.on(createWithDocumentsFailed.type, function (state, action) {
    return __assign(__assign({}, state), { create: {
            pending: false,
            error: undefined,
        } });
});
reducer.on(updateWithDocumentsStart.type, function (state, action) {
    var _a;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[action.legalCase.id] = __assign(__assign({}, state.detail[action.legalCase.id]), { pending: true, error: undefined }), _a)) });
});
reducer.on(updateWithDocumentsFailed.type, function (state, action) {
    var _a;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[action.id] = __assign(__assign({}, state.detail[action.id]), { pending: false, error: action.error }), _a)) });
});
reducer.on(updateWithDocumentsSucceeded.type, function (state, action) {
    var _a;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[action.id] = __assign(__assign(__assign({}, state.detail[action.id]), { pending: false }), action.result.data), _a)) });
});
// eslint-disable-next-line import/no-default-export
export default reducer;
