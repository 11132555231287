import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { FormValidation } from "react-native-jsonschema-form-test";
import { EField, IExtraData } from "./schema.moveRequestUpdate.types";

export const getSchema =
  (initialValues: { [key: string]: any }, _: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [EField.LeavingContactFirstname, EField.LeavingContactLastname],
      properties: {
        [EField.LeavingContactFirstname]: {
          default: getValue(
            EField.LeavingContactFirstname,
            initialValues,
            defaultValues,
          ),
        },

        [EField.LeavingContactLastname]: {
          default: getValue(
            EField.LeavingContactLastname,
            initialValues,
            defaultValues,
          ),
        },

        [EField.LeavingContactEmail]: {
          default: getValue(
            EField.LeavingContactEmail,
            initialValues,
            defaultValues,
          ),
        },
      },
    };
  };

export const uiSchema = (_: IExtraData): IUiSchema<EField> => ({
  [EField.LeavingContactFirstname]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.leaving_contact_firstname.title"),
  },
  [EField.LeavingContactLastname]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.leaving_contact_lastname.title"),
  },
  [EField.LeavingContactEmail]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.leaving_contact_email.title"),
    optional: true,
  },
});

export const getValidate =
  (_: IExtraData) => (formData: any, errors: FormValidation) => {
    return errors;
  };
