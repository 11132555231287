import component from "./LoopRenderer";
import strategy from "./LoopRenderer.strategy";
export { LOOP_RENDERER_TYPE } from "./LoopRenderer.strategy";
export * from "./LoopRenderer.types";
var decorator = {
    strategy: strategy,
    component: component,
};
// eslint-disable-next-line import/no-default-export
export default decorator;
