import * as actions from "./templateDocument.actions";
import * as api from "./templateDocument.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY, } from "./templateDocument.constants";
import * as hooks from "./templateDocument.hooks";
import * as promises from "./templateDocument.promises";
import { templateDocumentReducer, } from "./templateDocument.reducer";
import { saga } from "./templateDocument.saga";
import * as selectors from "./templateDocument.selectors";
export var REDUCER_KEY_TEMPLATE_DOCUMENTS = REDUCER_KEY;
export var templateDocumentModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: templateDocumentReducer,
    saga: saga,
    selectors: selectors,
    utils: {},
};
