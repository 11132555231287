import * as actions from "./paymentRequest.actions";
import * as api from "./paymentRequest.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY, } from "./paymentRequest.constants";
import * as hooks from "./paymentRequest.hooks";
import * as promises from "./paymentRequest.promises";
import reducer from "./paymentRequest.reducer";
import { saga } from "./paymentRequest.saga";
import * as selectors from "./paymentRequest.selectors";
export var REDUCER_KEY_PAYMENT_REQUESTS = REDUCER_KEY;
export var paymentRequestModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: reducer,
    saga: saga,
    selectors: selectors,
    utils: {},
};
