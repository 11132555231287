import {
  ERecurrenceType,
  ITask,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import { formatMediumDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  ETagVariant,
  Grid,
  Tag,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { TaskCardStyles } from "./TaskCard.styles";

interface IProps {
  task: ITask;
}

export const TaskCard = ({ task }: IProps) => {
  const sx = TaskCardStyles();

  const getRecurrenceType = (recurrenceType: ERecurrenceType) => {
    return recurrenceType.replaceAll("_", " ").toLowerCase();
  };

  const { title, description, recurrenceType = "", dueDate } = task;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={8}>
        <Typography
          variant="h4"
          fontSize="16px"
          sx={sx.content.longText}
          title={title}
        >
          {title}
        </Typography>
        <Typography
          variant="caption"
          sx={sx.content.longText}
          title={description}
          mb={1}
        >
          {description}
        </Typography>
        {recurrenceType && (
          <Tag variant={ETagVariant.Default}>
            {getRecurrenceType(recurrenceType)}
          </Tag>
        )}
      </Grid>
      {/* <Grid item xs={6} md={3} lg={3} textAlign="left">
        <Typography variant="caption">
          {getLocalizedText("task.execute_date.meta")}
        </Typography>
        <Typography variant="subtitle2">
          {executeDate ? formatMediumDate(executeDate) : "-"}
        </Typography>
      </Grid> */}
      <Grid item xs={6} md={3} lg={2} textAlign="left">
        <Typography variant="caption">
          {getLocalizedText("deadline")}
        </Typography>
        <Typography variant="subtitle2">
          {dueDate ? formatMediumDate(dueDate) : "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};
