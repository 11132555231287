import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from "@rentiohq/shared-frontend/dist/components/components/FormInputBase/FormInputBase.styles";
import { endOfMonth, startOfMonth, } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { isDate } from "lodash";
import { useState } from "react";
import { MonthYearPicker } from "./MonthYearPicker";
var transformRange = function (_a) {
    var start = _a.start, end = _a.end;
    var monthStart = start;
    var monthEnd = end;
    if (start)
        monthStart = startOfMonth(start);
    if (end)
        monthEnd = endOfMonth(end);
    return { start: monthStart, end: monthEnd };
};
var validate = function (_a) {
    var start = _a.start, end = _a.end;
    var error;
    if (start && end) {
        if (!isDate(end) || !isDate(start)) {
            error = getLocalizedText("filter.month_range.error.invalid_date_range");
        }
        if (end < start) {
            error = getLocalizedText("filter.month_range.error.to_before_from");
        }
    }
    return error;
};
export var MonthRangeFilter = function (_a) {
    var minStartDate = _a.minStartDate, maxStartDate = _a.maxStartDate, minEndDate = _a.minEndDate, maxEndDate = _a.maxEndDate, range = _a.range, onChange = _a.onChange;
    var _b = useState(), error = _b[0], setError = _b[1];
    var handleClear = function (key) {
        if (!range) {
            return;
        }
        range[key] = undefined;
        onChange === null || onChange === void 0 ? void 0 : onChange(range);
    };
    var handleChange = function (newDate, isStartDate) {
        var dates = {
            start: isStartDate ? newDate : range === null || range === void 0 ? void 0 : range.start,
            end: isStartDate ? range === null || range === void 0 ? void 0 : range.end : newDate,
        };
        var fullRange = transformRange(dates);
        var error = validate(fullRange);
        if (error) {
            setError(error);
            return;
        }
        setError(undefined);
        onChange === null || onChange === void 0 ? void 0 : onChange(fullRange);
    };
    return (_jsxs("div", { children: [_jsxs("div", { style: { display: "flex" }, children: [_jsx(MonthYearPicker, { placeholder: "From", error: error, selected: range === null || range === void 0 ? void 0 : range.start, minDate: minStartDate, maxDate: maxStartDate, onChange: function (newStartDate) {
                            handleChange(newStartDate, true);
                        }, handleClear: function () { return handleClear("start"); }, handleIconClick: function () { } }), _jsx("div", { style: { width: "16px" } }), _jsx(MonthYearPicker, { placeholder: "To", error: error, selected: range === null || range === void 0 ? void 0 : range.end, minDate: minEndDate, maxDate: maxEndDate, onChange: function (newStartDate) {
                            handleChange(newStartDate, false);
                        }, handleClear: function () { return handleClear("end"); }, handleIconClick: function () { } })] }), _jsx("div", { style: { paddingTop: 6 }, children: error && _jsx(S.ErrorText, { children: error }) })] }));
};
