import {
  getSchema as getSchema1,
  uiSchema as uiSchema1,
} from "./schema.duplicateTemplatePropertySelect.1";
import {
  EField,
  IExtraData,
} from "./schema.duplicateTemplatePropertySelect.types";

// eslint-disable-next-line import/no-default-export
export default (extraData: IExtraData) => [
  {
    schema: getSchema1({ [EField.Property]: extraData.propertyId }),
    uiSchema: uiSchema1(),
  },
];
