import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import {
  getMembersWithOneOfRoles,
  getMembersWithRole,
} from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  capitalizeString,
  join,
} from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  DropdownMenu,
  Icon,
  TDropdownActions,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { CancelContractModal } from "components/CancelContractModal";
import { Fragment, memo, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import RenewContractModal from "scenes/FollowUp/EndingContracts/components/RenewContractModal";
import { getContractDuration } from "scenes/Properties/scenes/Contracts/Contracts.utils";
import { ContractStatusChip } from "scenes/Properties/scenes/Contracts/components/ContractCard/components/ContractStatusChip";
import { EContractEndingStatus } from "scenes/Properties/scenes/Contracts/components/ContractCard/components/ContractStatusChip/contractStatusChip.types";
import { getContractEditRoute } from "utils/contract";
import { ts } from "../../../../../services";
import { PropertyListItemItem } from "../../PropertyListItem/PropertyListItem.header";
import * as S from "./ContractRow.styles";
import { FinalNoticeDate, InfoCircle } from "./components";

interface IProps {
  contract?: IContract;
  propertyId: number;
  isGroup?: boolean;
}

const getShowCancellationLink = (contractId: string) =>
  `/properties?cancelledContractId=${contractId}`;

const renderDuration = (contract: IContract) => {
  const duration = getContractDuration(contract);
  const text = getLocalizedText(`contract.duration.${duration}`);

  return (
    <TextStyle
      variation={
        duration === EContractEndingStatus.Cancelled ? "warn" : "default"
      }
    >
      {text}
    </TextStyle>
  );
};

const renderType = (contract: IContract) => {
  const { tacitRenewal } = contract;

  if (!tacitRenewal) {
    return (
      <TextStyle>{getLocalizedText("contract.type.fixed_term")}</TextStyle>
    );
  }

  const renewalInfo = !contract.dateOfCancellation
    ? contractUtils.getRenewalInfo(contract)
    : undefined;

  return (
    <InfoCircle renewalInfo={renewalInfo}>
      <TextStyle>{getLocalizedText("contract.type.tacit_renew")}</TextStyle>
    </InfoCircle>
  );
};

const renderMembers = (roles: EContractMemberTypes[], contract: IContract) => {
  const members = getMembersWithOneOfRoles(contract.members, roles);

  if (members.length === 0) {
    return (
      <TextStyle variation="subdued">
        {getLocalizedText(
          `properties.overview.property.no_${roles[0].toLowerCase()}s`,
        )}
      </TextStyle>
    );
  }

  return (
    <TextStyle>
      {members.map(owner => (
        <Fragment key={`account-${owner.account.id}`}>
          {getName(owner.account)}
          <br />
        </Fragment>
      ))}
    </TextStyle>
  );
};

const ContractRow = ({ contract, propertyId, isGroup = false }: IProps) => {
  const [showCancelContractModal, setShowCancelContractModal] =
    useState<boolean>(false);
  const [showRenewContractModal, setShowRenewContractModal] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { user } = authHooks.useSelf();
  const contractId = contract ? contract.id : undefined;
  const { remove: removeContract } = contractHooks.useRemove({
    id: contractId,
  });
  const { update } = contractHooks.useUpdate({
    id: contractId,
  });

  const { detail: property } = propertyHooks.useDetail({
    id: contract ? contract.propertyId : undefined,
  });

  const hasMoveServiceForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.MoveService,
  );

  // TODO: this and the getActions functions are mostly the same ad the ones used in the ContractMetaData component
  // Maybe we can find a way to extract them.
  const handlePressRequestMoveContractForEmptyProperty = useCallback(() => {
    if (!hasMoveServiceForBroker || !property || !user || !contract) {
      return;
    }

    const tenants = getMembersWithRole(
      contract.members,
      EContractMemberTypes.Tenant,
    );
    const tenantNames = join(tenants.map(tenant => getName(tenant.account)));

    confirm({
      title: capitalizeString(
        getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.confirm.title",
        ),
      ),
      info: capitalizeString(
        getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.confirm.info",
          {
            email: user?.email || "?",
          },
        ),
      ),
      inputValueProps: {
        type: "string",
        asMultiline: true,
        label: getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.remarks.label",
        ),
        initialValue: getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.remarks.initial_value",
          {
            tenantName: tenantNames,
            tenantAddress: formatAddress(property),
            tenantVat: "",
            tenantDateOfBirth: "",
          },
        ),
        optional: true,
      },
      primaryActions: [
        {
          title: getLocalizedText("system.request"),
          onPress: async ({ inputValue } = {}) => {
            try {
              await api.post(
                `/properties/${contract.propertyId}/request-move-request-contract`,
                {
                  data: {
                    extraRemarks: inputValue,
                  },
                },
              );

              showAlert({
                type: "success",
                message: getLocalizedText(
                  "contract.move.request_move_contract_for_empty_property.alert.success",
                ),
              });
            } catch (unknownError) {
              const error = unknownError as any;
              showAlert({
                type: "error",
                error,
                message: getLocalizedText(
                  "contract.move.request_move_contract_for_empty_property.alert.failure",
                ),
              });
            }
          },
        },
      ],
    });
  }, [hasMoveServiceForBroker, user, contract]);

  const getActions = (): TDropdownActions => {
    const toPropertyAction = {
      content: getLocalizedText("actions.to_property"),
      onClick: () => {
        navigate(`/properties/${propertyId}`);
      },
    };

    if (!contract) {
      return [toPropertyAction];
    }

    const isCancelled = !!contract.dateOfCancellation;

    const sectionOneActions: IAction[] = [
      {
        content: ts.contractEdit(),
        url: getContractEditRoute(contract, contract.propertyId),
      },
      ...(!isCancelled && contract.stopDate
        ? [
            {
              content: ts.contractRenew(),
              onClick: () => {
                setShowRenewContractModal(true);
              },
            },
          ]
        : []),
      {
        content: contract.dateOfCancellation
          ? ts.contractShowCancellation()
          : ts.contractCancel(),
        onClick: () => {
          contract.dateOfCancellation
            ? navigate(`/properties?cancelledContractId=${contract.id}`)
            : setShowCancelContractModal(true);
        },
      },
    ];

    const sectionTwoActions: IAction[] = [];
    sectionTwoActions.push({
      content: (
        <TextStyle variation="negative">
          {getLocalizedText("contract.delete.cta")}
        </TextStyle>
      ),
      onClick: () => {
        confirm({
          title: capitalizeString(
            getLocalizedText("contract.delete.confirm.title"),
          ),
          info: capitalizeString(
            getLocalizedText("contract.delete.confirm.info"),
          ),
          primaryActions: [
            {
              title: getLocalizedText("system.delete"),
              onPress: () => {
                removeContract({});
              },
            },
          ],
        });
      },
    });

    if (contract.tacitRenewal && !isCancelled) {
      sectionTwoActions.push({
        content: (
          <TextStyle variation={"warn"}>
            {ts.contractTurnOffTacitRenewal()}
          </TextStyle>
        ),
        onClick: () => {
          update({
            data: {
              tacitRenewal: false,
            },
          });
        },
      });
    }

    const sectionThreeActions: IAction[] = [];
    // Section three actions
    if (hasMoveServiceForBroker && !isCancelled) {
      sectionThreeActions.push({
        content: getLocalizedText(
          "contract.move.request_move_contract_for_empty_property.cta.title",
        ),
        onClick: handlePressRequestMoveContractForEmptyProperty,
      });
    }

    if (!isCancelled) {
      sectionThreeActions.push(toPropertyAction);
    }
    return [sectionOneActions, sectionTwoActions, sectionThreeActions];
  };

  const actions = getActions();

  return (
    <>
      <S.ContractRowWrapper>
        <PropertyListItemItem style={{ flex: 1 }}>
          {(contract &&
            renderMembers([EContractMemberTypes.Tenant], contract)) ||
            "-"}
        </PropertyListItemItem>
        <PropertyListItemItem style={{ flex: 1 }}>
          {contract?.currentPrice ? formatCurrency(contract.currentPrice) : "-"}
        </PropertyListItemItem>
        <PropertyListItemItem style={{ flex: 1 }}>
          {contract?.startDate ? formatDate(contract.startDate) : "-"}
        </PropertyListItemItem>
        <PropertyListItemItem style={{ flex: 1 }}>
          {contract?.stopDate ? formatDate(contract.stopDate) : "-"}
        </PropertyListItemItem>
        <PropertyListItemItem style={{ flex: 1 }}>
          {(contract && renderType(contract)) || "-"}
        </PropertyListItemItem>
        <PropertyListItemItem style={{ flex: 1 }}>
          {(contract && renderDuration(contract)) || "-"}
        </PropertyListItemItem>
        <PropertyListItemItem style={{ flex: 1 }}>
          {contract ? <FinalNoticeDate contract={contract} /> : "-"}
        </PropertyListItemItem>
        {!isGroup && (
          <PropertyListItemItem style={{ flex: 1 }}>
            <ContractStatusChip contract={contract} />
          </PropertyListItemItem>
        )}
        <PropertyListItemItem>
          {actions.length > 0 ? (
            <DropdownMenu
              passDownClickEvents={false}
              children={
                <span>
                  <Icon source="navigationMenuVertical" />
                </span>
              }
              actions={actions}
            />
          ) : (
            <div style={{ width: 24 }}></div>
          )}
        </PropertyListItemItem>
      </S.ContractRowWrapper>
      {showCancelContractModal && contract && (
        <CancelContractModal
          contract={contract}
          onSuccess={() => {
            navigate(getShowCancellationLink(contract.id));
          }}
          onClose={() => {
            setShowCancelContractModal(false);
          }}
        />
      )}
      {showRenewContractModal && contract?.stopDate && (
        <RenewContractModal
          contractId={contract.id}
          onClose={() => setShowRenewContractModal(false)}
          onSuccess={() => {
            setShowRenewContractModal(false);
          }}
        />
      )}
    </>
  );
};

export const MemoizedContractRow = memo(ContractRow);
