var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { keyBy, uniq } from "lodash";
import { createReducer } from "redux-act";
import { createComment, deleteComment, getComments, setShowLimit, updateComment, } from "./comment.actions";
export var REDUCER_KEY_COMMENT = "comment";
var getDefaultState = function () { return ({
    comments: {},
    isCreatingComment: false,
    createCommentError: undefined,
    commentsByIdentifier: {},
    commentsById: {},
    showLimit: 5,
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
// Get comments
reducer.on(getComments.types.START, function (state, _a) {
    var _b;
    var commentIdentifier = _a.commentIdentifier, baseUrl = _a.baseUrl;
    return __assign(__assign({}, state), { commentsByIdentifier: __assign(__assign({}, state.commentsByIdentifier), (_b = {}, _b[commentIdentifier] = __assign(__assign({ commentIds: undefined, 
            // @ts-ignore TODO: Typescript upgrade
            isExhausted: false }, (state.commentsByIdentifier[commentIdentifier] || {})), { baseUrl: baseUrl, isFetching: true, fetchError: undefined }), _b)) });
});
reducer.on(getComments.types.SUCCESS, function (state, _a) {
    var _b;
    var commentIdentifier = _a.commentIdentifier, refetch = _a.refetch, comments = _a.comments, isExhausted = _a.isExhausted;
    return __assign(__assign({}, state), { comments: __assign(__assign({}, state.comments), keyBy(comments, "id")), commentsByIdentifier: __assign(__assign({}, state.commentsByIdentifier), (_b = {}, _b[commentIdentifier] = __assign(__assign({ baseUrl: undefined }, (state.commentsByIdentifier[commentIdentifier] || {})), { isFetching: false, fetchError: undefined, isExhausted: isExhausted, commentIds: refetch
                ? comments.map(function (comment) { return comment.id; })
                : uniq(__spreadArray(__spreadArray([], comments.map(function (comment) { return comment.id; }), true), (state.commentsByIdentifier[commentIdentifier].commentIds ||
                    []), true)) }), _b)) });
});
reducer.on(getComments.types.FAILURE, function (state, _a) {
    var _b;
    var commentIdentifier = _a.commentIdentifier, error = _a.error;
    return (__assign(__assign({}, state), { commentsByIdentifier: __assign(__assign({}, state.commentsByIdentifier), (_b = {}, _b[commentIdentifier] = __assign(__assign({}, (state.commentsByIdentifier[commentIdentifier] || {})), { extraFilterData: undefined, isExhausted: false, isFetching: false, fetchError: error }), _b)) }));
});
// Create comment
reducer.on(createComment.types.START, function (state) { return (__assign(__assign({}, state), { isCreatingComment: true, createCommentError: undefined })); });
reducer.on(createComment.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var commentIdentifier = _a.commentIdentifier, comment = _a.comment;
    return (__assign(__assign({}, state), { comments: __assign(__assign({}, state.comments), (_b = {}, _b[comment.id] = __assign({}, comment), _b)), commentsByIdentifier: __assign(__assign({}, state.commentsByIdentifier), (_c = {}, _c[commentIdentifier] = __assign(__assign({ baseUrl: undefined }, (state.commentsByIdentifier[commentIdentifier] || {})), { isFetching: false, fetchError: undefined, commentIds: uniq(__spreadArray(__spreadArray([], (state.commentsByIdentifier[commentIdentifier].commentIds || []), true), [
                comment.id,
            ], false)) }), _c)), isCreatingComment: false, createCommentError: undefined }));
});
reducer.on(createComment.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isCreatingComment: false, createCommentError: error }));
});
// Update comment
reducer.on(updateComment.types.START, function (state, _a) {
    var _b;
    var commentId = _a.commentId;
    return (__assign(__assign({}, state), { commentsById: (_b = {},
            _b[commentId] = __assign(__assign({}, state.commentsById[commentId]), { isUpdating: true, updateError: undefined }),
            _b) }));
});
reducer.on(updateComment.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var commentId = _a.commentId, comment = _a.comment;
    return __assign(__assign({}, state), { comments: __assign(__assign({}, state.comments), (_b = {}, _b[commentId] = comment, _b)), commentsById: (_c = {},
            _c[commentId] = __assign(__assign({}, state.commentsById[commentId]), { isUpdating: false, updateError: undefined }),
            _c) });
});
reducer.on(updateComment.types.FAILURE, function (state, _a) {
    var _b;
    var commentId = _a.commentId, error = _a.error;
    return (__assign(__assign({}, state), { commentsById: (_b = {},
            _b[commentId] = __assign(__assign({}, state.commentsById[commentId]), { isUpdating: false, updateError: error }),
            _b) }));
});
reducer.on(deleteComment.types.START, function (state, _a) {
    var _b;
    var commentId = _a.commentId;
    return (__assign(__assign({}, state), { commentsById: __assign(__assign({}, state.commentsById), (_b = {}, _b[commentId] = __assign(__assign({}, state.commentsById[commentId]), { isDeleting: true, deleteError: undefined }), _b)) }));
});
reducer.on(deleteComment.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var commentId = _a.commentId, commentIdentifier = _a.commentIdentifier;
    var prevState = state.commentsByIdentifier[commentIdentifier];
    var newIds = prevState &&
        (prevState.commentIds || []).filter(function (randomId) { return randomId !== commentId; });
    var comments = __assign({}, state.comments);
    delete comments[commentId];
    return __assign(__assign({}, state), { comments: __assign(__assign({}, comments), keyBy(comments, "id")), commentsById: __assign(__assign({}, state.commentsById), (_b = {}, _b[commentId] = __assign(__assign({}, state.commentsById[commentId]), { isDeleting: false, deleteError: undefined }), _b)), commentsByIdentifier: __assign(__assign({}, state.commentsByIdentifier), (_c = {}, _c[commentIdentifier] = __assign(__assign({}, (state.commentsByIdentifier[commentIdentifier] || {})), { baseUrl: undefined, isFetching: false, fetchError: undefined, commentIds: newIds }), _c)) });
});
reducer.on(deleteComment.types.FAILURE, function (state, _a) {
    var _b;
    var commentId = _a.commentId, error = _a.error;
    return (__assign(__assign({}, state), { commentsById: (_b = {},
            _b[commentId] = __assign(__assign({}, state.commentsById[commentId]), { isDeleting: false, deleteError: error }),
            _b) }));
});
reducer.on(setShowLimit, function (state, _a) {
    var showLimit = _a.showLimit;
    return (__assign(__assign({}, state), { showLimit: showLimit }));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
