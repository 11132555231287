import { Middleware } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

export const sagaMiddleware = createSagaMiddleware();
const middlewares: Middleware[] = [sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  const logger = createLogger({ level: "info", collapsed: true });
  middlewares.push(logger);
}

// eslint-disable-next-line import/no-default-export
export default middlewares;
