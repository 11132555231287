var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import addContactSchemas from "@rentiohq/shared-frontend/dist/forms/addContact";
import { isPerformingSubmitActionSelector, performingSubmitActionErrorSelector, performingSubmitActionResultSelector, submitActionCreator, } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.actions";
import { getContact } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.actions.utils";
import { EField, } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.types";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactHooks from "@rentiohq/shared-frontend/dist/redux/contact/contact.hooks";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import * as React from "react";
import { useDispatch } from "react-redux";
import * as ts from "../../services/translationService";
import { i18n } from "../../utils";
import { Banner } from "../Banner";
import { ContactOverviewRow } from "../ContactOverviewRow/ContactOverviewRow";
import { Loading } from "../Loading";
import { Modal } from "../Modal";
import { ResourceList } from "../ResourceList";
import { MultiStepForm } from "../SharedForm";
export var AddContactModal = function (props) {
    var fields = props.fields, type = props.type, onClose = props.onClose, onSubmit = props.onSubmit, contact = props.contact, baseAccountId = props.baseAccountId, prefilledAddress = props.prefilledAddress, initialType = props.initialType;
    var dispatch = useDispatch();
    var hasDuplicateContacts = useBrokerFeature(EBrokerFeature.DuplicateContacts);
    var _a = React.useState(), duplicateContactsErrors = _a[0], setDuplicateContactsErrors = _a[1];
    var _b = React.useState(), duplicateContactToCreate = _b[0], setDuplicateContactToCreate = _b[1];
    var duplicateContactsFilterWhere = [];
    if (duplicateContactsErrors === null || duplicateContactsErrors === void 0 ? void 0 : duplicateContactsErrors.email) {
        duplicateContactsFilterWhere.push({ email: duplicateContactsErrors.email });
    }
    if (duplicateContactsErrors === null || duplicateContactsErrors === void 0 ? void 0 : duplicateContactsErrors.phone) {
        duplicateContactsFilterWhere.push({ phone: duplicateContactsErrors.phone });
    }
    var _c = contactHooks.usePaged(duplicateContactsErrors
        ? {
            id: "duplicate-contacts-".concat(JSON.stringify(duplicateContactsErrors)),
            preventDefaultFetching: false,
            page: 1,
            limit: 20,
            filter: {
                where: {
                    and: [
                        { or: duplicateContactsFilterWhere },
                        { duplicateWithoutConnection: false },
                    ],
                },
            },
        }
        : {}), duplicateContacts = _c.data, isFetchingDuplicateContacts = _c.isFetching;
    // Form
    var formId = React.useRef(generateFormId());
    var contactSchemas = addContactSchemas({
        isCompany: type === "company",
        fields: fields,
        // @ts-ignore
        contact: contact,
        initialType: initialType,
        showSendInvite: true,
        prefilledAddress: prefilledAddress,
        baseAccountId: baseAccountId,
    });
    // Redux
    var broker = authHooks.useSelf().broker;
    // Event handlers
    var handleSuccess = function (contact) {
        if (!contact) {
            return;
        }
        onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(contact);
        onClose();
    };
    // Render
    var renderContactItem = function (item) {
        return _jsx(ContactOverviewRow, { contact: item, clickable: false });
    };
    return (_jsxs(_Fragment, { children: [_jsx(MultiStepForm, { formId: "add-contact-".concat(formId.current), schemas: contactSchemas, asModal: true, withAside: false, onSuccess: handleSuccess, onError: function (error, formData) {
                    var _a, _b, _c, _d, _e, _f, _g;
                    if (!hasDuplicateContacts) {
                        return;
                    }
                    var axiosError = error;
                    if (!axiosError.isAxiosError) {
                        return;
                    }
                    if (((_a = axiosError.response) === null || _a === void 0 ? void 0 : _a.status) !== 422) {
                        return;
                    }
                    var errorCodes = ((_e = (_d = (_c = (_b = axiosError.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error) === null || _d === void 0 ? void 0 : _d.details) === null || _e === void 0 ? void 0 : _e.codes) || {};
                    var emailNotUnique = false;
                    if ((_f = errorCodes.email) === null || _f === void 0 ? void 0 : _f.includes("custom.uniqueness")) {
                        emailNotUnique = true;
                    }
                    var phoneNotUnique = false;
                    if ((_g = errorCodes.phone) === null || _g === void 0 ? void 0 : _g.includes("custom.uniqueness")) {
                        phoneNotUnique = true;
                    }
                    if (emailNotUnique || phoneNotUnique) {
                        setDuplicateContactToCreate(__assign(__assign({}, getContact(formData, undefined, baseAccountId, prefilledAddress)), { duplicateWithoutConnection: true }));
                        setDuplicateContactsErrors({
                            email: emailNotUnique ? formData.email : undefined,
                            phone: phoneNotUnique ? formData.phone : undefined,
                        });
                    }
                }, modalProps: { onClose: onClose }, isPerformingSubmitActionSelector: isPerformingSubmitActionSelector(undefined), performingSubmitActionResultSelector: performingSubmitActionResultSelector(undefined), performingSubmitActionErrorSelector: performingSubmitActionErrorSelector(undefined), submitActionCreator: submitActionCreator(undefined, baseAccountId, prefilledAddress), submitLabel: ts.system("save"), getPrecommitConfirm: function (params) {
                    var formData = params.formData, onProceed = params.onProceed;
                    var email = formData[EField.Email];
                    var phone = formData[EField.Phone];
                    if (email !== (broker === null || broker === void 0 ? void 0 : broker.dummyEmail) && phone !== (broker === null || broker === void 0 ? void 0 : broker.dummyPhone)) {
                        return;
                    }
                    var dummyInfo = [];
                    if (email === (broker === null || broker === void 0 ? void 0 : broker.dummyEmail)) {
                        dummyInfo.push(broker === null || broker === void 0 ? void 0 : broker.dummyEmail);
                    }
                    if (phone === (broker === null || broker === void 0 ? void 0 : broker.dummyPhone)) {
                        dummyInfo.push(broker === null || broker === void 0 ? void 0 : broker.dummyPhone);
                    }
                    return {
                        type: "warning",
                        title: getLocalizedText("modal.contact.dummy_data.title"),
                        info: i18n.tm("modal.contact.dummy_data.info", {
                            values: {
                                dummyInfo: dummyInfo.join(" ".concat(getLocalizedText("system.and"), " ").toLowerCase()),
                            },
                            markdownProps: { listAsChecklist: true, checklistCharacter: "✗" },
                        }),
                        primaryActions: [
                            {
                                title: getLocalizedText("system.edit"),
                            },
                        ],
                        secondaryAction: {
                            title: getLocalizedText("modal.contact.dummy_data.cta.create"),
                            onPress: function () {
                                onProceed();
                            },
                        },
                    };
                } }), duplicateContactsErrors && duplicateContactToCreate && (_jsxs(Modal, { onClose: function () {
                    setDuplicateContactToCreate(undefined);
                    setDuplicateContactsErrors(undefined);
                }, heading: getLocalizedText("contact.add.duplicate_contacts.title"), actions: [
                    {
                        content: getLocalizedText("system.back"),
                        appearance: "primary",
                        onClick: function () {
                            setDuplicateContactToCreate(undefined);
                            setDuplicateContactsErrors(undefined);
                        },
                    },
                    {
                        content: getLocalizedText("contact.add.duplicate_contacts.cta.create"),
                        appearance: "warning",
                        onClick: function () {
                            setDuplicateContactToCreate(undefined);
                            setDuplicateContactsErrors(undefined);
                            dispatch(contactActions.createContact.actions.start({
                                contact: duplicateContactToCreate,
                                sendInvite: false,
                            }));
                        },
                    },
                ], children: [_jsx(Banner, { title: getLocalizedText("contact.add.duplicate_contacts.info_banner.title"), icon: "alertDiamond", variation: "warning", hasDismiss: false, children: getLocalizedText("contact.add.duplicate_contacts.info_banner.info") }), isFetchingDuplicateContacts && _jsx(Loading, {}), _jsx(ResourceList, { items: duplicateContacts || [], renderItem: renderContactItem })] }))] }));
};
