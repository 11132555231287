var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { borderRadius, spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
import { Loading } from "../Loading";
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) { return (props.isLoading ? "opacity: .3;" : ""); });
export var LoaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  bottom: 0;\n  width: 100%;\n"])));
export var Loader = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  /* width: ", ";\n  height: ", "; */\n  padding: ", ";\n  border-radius: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: ", ";\n"], ["\n  /* width: ", ";\n  height: ", "; */\n  padding: ", ";\n  border-radius: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: ", ";\n"])), function (props) { return rem(112, props.theme.baseFontSize); }, function (props) { return rem(112, props.theme.baseFontSize); }, spacing(), borderRadius(), function (props) { return props.theme.colors.neutral20; });
export var Dots = styled(function (props) { return _jsx(Loading, __assign({}, props)); }).attrs(function (props) { return ({
    asDots: props.asDots,
    color: props.theme.getColor("primary"),
}); })(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
