import createNoteSchemas from "@rentiohq/shared-frontend/dist/forms/createNote";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/createNote/schema.createNote.actions";
import { EField } from "@rentiohq/shared-frontend/dist/forms/createNote/schema.createNote.types";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { INote } from "@rentiohq/shared-frontend/dist/types/notes.types";
import { MultiStepForm } from "@rentiohq/web-shared/dist/components";
import equals from "ramda/es/equals";
import React from "react";
import * as t from "../../../services/translationService";

interface IProps {
  onClose: () => void;
  onSubmit: (data: any) => void;
  note?: INote;
  customPath: string;
  shouldLiveValidate?: boolean;
  schema?: any;
}

const formId = generateFormId();
export const NoteForm: React.FC<IProps> = React.memo(
  ({ note, onClose, customPath, onSubmit }) => {
    const noteSchemas = createNoteSchemas({
      noteId: note ? note.id : undefined,
      [EField.Content]: note ? note.content : undefined,
    });

    const handleSuccess = () => {
      onSubmit?.(note);
      onClose();
    };

    return (
      <MultiStepForm
        formId={`add-note-${formId}`}
        schemas={noteSchemas}
        preventSubmitThroughEnter={true}
        asModal={false}
        withAside={false}
        onSuccess={handleSuccess}
        modalProps={{ onClose }}
        isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
          note?.id,
        )}
        performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
          note?.id,
        )}
        submitActionCreator={(formData: any) => {
          if (!formData.content && !note) {
            onClose();
            return { type: "NONE" };
          }

          return submitActionCreator(customPath, note?.id)(formData);
        }}
        submitLabel={t.system("save")}
      />
    );
  },
  (
    { onClose: _, onSubmit: __, ...prevProps }: any,
    { onClose: ___, onSubmit: ____, ...nextProps }: any,
  ) => {
    return equals(prevProps, nextProps);
  },
);
