import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import {
  ACTIVITY_SUBTYPES,
  IActivityItem,
} from "@rentiohq/shared-frontend/dist/types/activity.types";
import { EPaymentOrderOwnerPaymentMethod } from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  formatDate,
  formatDateAndTime,
} from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import {
  AddressCell,
  Icon,
  IDataTableItem,
  Lozenge,
  TextStyle,
  TruncateTooltip,
} from "@rentiohq/web-shared/dist/components";
import { createTitle, getPaymentOrders, getPeriod } from "utils/payment";

export const getDirection = (activity: IActivityItem) => {
  switch (activity.subtype) {
    case ACTIVITY_SUBTYPES.PAYMENT.PAYOUT_SUCCEEDED:
    case ACTIVITY_SUBTYPES.PAYMENT.REQUEST_MANUALLY_PAID:
    case ACTIVITY_SUBTYPES.PAYMENT.PAYIN_DISPUTED:
      return "out";

    case ACTIVITY_SUBTYPES.PAYMENT.PAYIN_SUCCEEDED:
    default:
      return "in";
  }
};

const getIsDisputed = (activity: IActivityItem) =>
  activity.subtype === ACTIVITY_SUBTYPES.PAYMENT.PAYIN_DISPUTED;

export const getItems = (params: { propertyId?: number }) => {
  const { propertyId } = params;

  const result: IDataTableItem<IActivityItem>[] = [];

  result.push({
    heading: getLocalizedText("system.date"),
    columnContentType: "text",
    // sortable: true,
    sortable: false, // TODO: Not working in backend
    order: "datetime",
    renderContent: (entity: IActivityItem) => (
      <>{formatDateAndTime(entity.datetime)}</>
    ),
  });

  if (!propertyId) {
    result.push({
      heading: getLocalizedText("system.property"),
      columnContentType: "text",
      renderContent: (entity: IActivityItem) =>
        entity.property ? <AddressCell property={entity.property} /> : null,
    });
  }

  result.push({
    heading: getLocalizedText("payment_history.heading.description"),
    columnContentType: "text",
    renderContent: (entity: IActivityItem) =>
      entity.paymentRequest ? (
        <TruncateTooltip width={140}>
          {createTitle({
            paymentOrders: getPaymentOrders(entity.paymentRequest),
          })}
        </TruncateTooltip>
      ) : null,
  });

  result.push({
    heading: getLocalizedText("payment_history.heading.period"),
    columnContentType: "text",
    renderContent: (entity: IActivityItem) =>
      entity.paymentRequest ? getPeriod(entity.paymentRequest) : null,
  });

  result.push({
    heading: getLocalizedText("payment_history.heading.amount"),
    columnContentType: "numeric",
    renderContent: (entity: IActivityItem) => {
      let amount = 0;

      switch (entity.subtype) {
        case ACTIVITY_SUBTYPES.PAYMENT.REQUEST_MANUALLY_PAID:
          if (entity.amount) {
            amount = entity.amount;
          }

          break;

        case ACTIVITY_SUBTYPES.PAYMENT.PAYIN_DISPUTED:
          if (entity.paymentRequest) {
            amount = entity.paymentRequest.originalAmount;
          }

          break;

        default:
          if (entity.paymentTransaction) {
            amount = entity.paymentTransaction.amount;
            break;
          }

          if (entity.paymentRequest) {
            amount = entity.paymentRequest.originalAmount;
            break;
          }

          break;
      }

      const direction = getDirection(entity);

      return (
        <TextStyle variation="strong">
          <TextStyle variation={direction === "in" ? "positive" : "default"}>
            {formatCurrency(direction === "in" ? amount : -amount)}
          </TextStyle>
        </TextStyle>
      );
    },
  });

  result.push({
    heading: getLocalizedText("payment_history.heading.due_date"),
    columnContentType: "text",
    renderContent: (entity: IActivityItem) =>
      entity.paymentRequest
        ? formatDate(entity.paymentRequest.dueDateAt)
        : null,
  });

  result.push({
    heading: getLocalizedText("payment_history.heading.in_out"),
    columnContentType: "icon",
    renderContent: (entity: IActivityItem) => {
      const direction = getDirection(entity);
      const isDisputed = getIsDisputed(entity);

      if (isDisputed) return <Icon source="alertDiamond" color="red" />;
      return (
        <Icon
          source={direction === "in" ? "download" : "upload"}
          color={direction === "in" ? "green" : "yellow"}
        />
      );
    },
  });

  result.push({
    heading: getLocalizedText("payment_history.heading.contact"),
    columnContentType: "text",
    renderContent: (entity: IActivityItem) => {
      let name: string | undefined;
      let info: string | undefined;

      const isDisputed = getIsDisputed(entity);
      switch (getDirection(entity)) {
        case "in":
          if (entity.payer) {
            name = getName(entity.payer);
          }
          if (entity.paymentRequest?.paymentMethod) {
            info = getLocalizedText(
              `payment_request.method.${entity.paymentRequest?.paymentMethod}`.toLowerCase(),
            );
          }
          break;

        case "out":
          if (isDisputed) {
            if (entity.payer) {
              name = getName(entity.payer);
            }
          } else {
            if (entity.payee) {
              name = getName(entity.payee);
            }
          }
          break;

        default:
          break;
      }

      if (!name) {
        return null;
      }

      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TruncateTooltip width={130}>{name}</TruncateTooltip>
          {info && <TextStyle variation="subdued">{info}</TextStyle>}
        </div>
      );
    },
  });

  result.push({
    heading: getLocalizedText("payment_history.heading.type"),
    columnContentType: "text",
    renderContent: (entity: IActivityItem) => {
      if (!entity.paymentOrder) {
        return null;
      }

      switch (entity.subtype) {
        case ACTIVITY_SUBTYPES.PAYMENT.PAYOUT_SUCCEEDED:
          return (
            <Lozenge>{getLocalizedText("payment_history_type.payout")}</Lozenge>
          );

        case ACTIVITY_SUBTYPES.PAYMENT.PAYIN_SUCCEEDED: {
          if (
            entity.paymentOrder.ownerPaymentMethod ===
            EPaymentOrderOwnerPaymentMethod.FromRent
          ) {
            return (
              <Lozenge>
                {getLocalizedText("payment_history_type.payin_from_rent")}
              </Lozenge>
            );
          }

          return (
            <Lozenge>
              {getLocalizedText("payment_history_type.payin_direct")}
            </Lozenge>
          );
        }

        case ACTIVITY_SUBTYPES.PAYMENT.REQUEST_MANUALLY_PAID:
          return (
            <Lozenge>
              {getLocalizedText("payment_history_type.payin_manual")}
            </Lozenge>
          );

        case ACTIVITY_SUBTYPES.PAYMENT.PAYIN_DISPUTED:
          return (
            <Lozenge appearance="error">
              {getLocalizedText("payment_history_type.payin_disputed")}
            </Lozenge>
          );

        default:
          return null;
      }
    },
  });

  // internalModeEnabled
  //   ? {
  //       heading: getLocalizedText("Activity type/subtype"),
  //       columnContentType: "text",
  //       renderContent: (entity: IActivityItem) => (
  //         <RentioInternalRendererActivity activity={entity} />
  //       ),
  //     }
  //   : undefined,

  return result;
};
