import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import {
  Avatar,
  Box,
  Button,
  Card,
  Icon,
  Stack,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import {
  EButtonColors,
  EButtonVariants,
} from "@rentiohq/web-shared-next/dist/utils/types/button";
import { SupportCardStyles } from "./SupportCard.styles";

interface IProps {
  name: string;
  email?: string;
  phone: string;
}

export const SupportCard = ({ name, email, phone }: IProps) => {
  const sx = SupportCardStyles();
  return (
    <Card sx={sx.wrapper}>
      <Stack direction="column" gap={2} alignItems="flex-start">
        <Avatar alt="logo" sx={sx.content.avatar}>
          <Icon path={EAssets.LogoIcon} width="72px" height="72px" />
        </Avatar>
        <Typography variant="h4" sx={sx.content.value}>
          {name}
        </Typography>
        {email && (
          <Box>
            <Typography variant="subtitle1" sx={sx.content.label}>
              {getLocalizedText("system.email")}
            </Typography>
            <Typography variant="h6" sx={sx.content.value}>
              {email}
            </Typography>
          </Box>
        )}
        <Box>
          <Typography variant="subtitle1" sx={sx.content.label}>
            {getLocalizedText("system.phone")}
          </Typography>
          <Typography variant="h6" sx={sx.content.value}>
            {phone}
          </Typography>
        </Box>
        <Button
          variant={EButtonVariants.Outlined}
          color={EButtonColors.Success}
          size="small"
          startIcon={
            <Icon path={EAssets.RingingPhoneIcon} width="16px" height="16px" />
          }
          onClick={() =>
            (window.location.href = `tel:${getLocalizedText("system.phone")}`)
          }
        >
          {getLocalizedText("system.call.imperative")}
        </Button>
      </Stack>
    </Card>
  );
};
