var _a;
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateHooks } from "../generators/generator.hooks";
import * as paymentOrderActions from "./paymentOrder.actions";
import { GENERATOR_PARAMS } from "./paymentOrder.constants";
import * as paymentOrderSelectors from "./paymentOrder.selectors";
export var useDetail = (_a = generateHooks(GENERATOR_PARAMS), _a.useDetail), usePaged = _a.usePaged, useUpdate = _a.useUpdate, useCount = _a.useCount, useInfiniteLoad = _a.useInfiniteLoad;
export var usePayeeBankAccount = function (paymentOrder) {
    var paymentOrderId = paymentOrder.id, payeeId = paymentOrder.payeeId, payeeBankaccountId = paymentOrder.payeeBankaccountId;
    var dispatch = useDispatch();
    // Selectors
    var bankAccount = useSelector(function (state) {
        return paymentOrderSelectors.getPayeeBankAccount(paymentOrderId, state);
    });
    // Lifecycle
    useEffect(function () {
        if (bankAccount) {
            return;
        }
        if (!payeeId) {
            return;
        }
        if (!payeeBankaccountId) {
            return;
        }
        dispatch(paymentOrderActions.getPayeeBankAccountStart.getAction({
            id: paymentOrderId,
        }));
    }, [paymentOrderId, payeeId]);
    return bankAccount;
};
export var usePayerBankAccount = function (paymentOrder) {
    var paymentOrderId = paymentOrder.id, payerId = paymentOrder.payerId, payerBankaccountId = paymentOrder.payerBankaccountId;
    var dispatch = useDispatch();
    // Selectors
    var bankAccount = useSelector(function (state) {
        return paymentOrderSelectors.getPayerBankAccount(paymentOrderId, state);
    });
    // Lifecycle
    useEffect(function () {
        if (bankAccount) {
            return;
        }
        if (!payerId) {
            return;
        }
        if (!payerBankaccountId) {
            return;
        }
        dispatch(paymentOrderActions.getPayerBankAccountStart.getAction({
            id: paymentOrderId,
        }));
    }, [paymentOrderId, payerId]);
    return bankAccount;
};
export var useMissingKYCs = function (propertyId) {
    var dispatch = useDispatch();
    var isFetching = useSelector(function (state) {
        return paymentOrderSelectors.isFetchingMissingKYCs(state, propertyId) || false;
    });
    var fetchingError = useSelector(function (state) {
        return paymentOrderSelectors.fetchErrorMissingKYCs(state, propertyId);
    });
    var missingKYCs = useSelector(function (state) {
        return paymentOrderSelectors.getMissingKYCs(state, propertyId);
    });
    var dispatchMissingKYCs = function (params) {
        var query = params.query, propertyId = params.propertyId, refetch = params.refetch, onSuccess = params.onSuccess, onFailure = params.onFailure;
        dispatch(paymentOrderActions.getMissingKYCsStart.getAction({
            query: query,
            propertyId: propertyId,
            refetch: refetch,
            onFailure: onFailure,
            onSuccess: onSuccess,
        }));
    };
    return {
        missingKYCs: missingKYCs,
        dispatchMissingKYCs: dispatchMissingKYCs,
        isFetching: isFetching,
        fetchingError: fetchingError,
    };
};
