import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import {
  Avatar,
  Box,
  Button,
  Card,
  Icon,
  SpinningLoader,
  Stack,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import {
  EButtonColors,
  EButtonVariants,
} from "@rentiohq/web-shared-next/dist/utils/types/button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { ContactCardStyles } from "./ContactCard.styles";

interface IProps {
  account: IAccount;
  propertyId: number;
}

export const ContactCard = ({ account }: IProps) => {
  const sx = ContactCardStyles();

  const dispatch = useDispatch();

  const contact = useSelector((state: IRootStore) =>
    contactSelectors.getContact(state, undefined, account?.id),
  );
  const isFetchingContact = useSelector((state: IRootStore) =>
    contactSelectors.getContactIsFetching(state, undefined, account?.id),
  );

  React.useEffect(() => {
    if (!account?.id) {
      return;
    }

    dispatch(
      contactActions.getContactsByAccountIds.actions.start({
        accountIds: [account?.id],
      }),
    );
  }, [account?.id]);

  const getName = () => {
    if (account?.company) {
      return account?.company;
    } else {
      return `${account?.firstname} ${account?.lastname}`;
    }
  };

  return (
    <Card sx={sx.wrapper}>
      {isFetchingContact ? (
        <SpinningLoader />
      ) : (
        <Stack direction="column" gap={2} alignItems="flex-start">
          <Avatar alt="logo" sx={sx.content.avatar}>
            <Icon
              path={
                contact?.isCompany ? EAssets.SuitCaseIcon : EAssets.UserIcon
              }
              width="36px"
              height="36px"
            />
          </Avatar>
          <Typography variant="h4" sx={sx.content.value}>
            {getName()}
          </Typography>
          {contact?.email && (
            <Box>
              <Typography variant="subtitle1" sx={sx.content.label}>
                {getLocalizedText("system.email")}
              </Typography>
              <Typography variant="h6" sx={sx.content.value}>
                {contact?.email}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography variant="subtitle1" sx={sx.content.label}>
              {getLocalizedText("system.phone")}
            </Typography>
            <Typography variant="h6" sx={sx.content.value}>
              {formatPhone(contact?.phone)}
            </Typography>
          </Box>
          <Button
            variant={EButtonVariants.Outlined}
            color={EButtonColors.Success}
            size="small"
            startIcon={
              <Icon
                path={EAssets.RingingPhoneIcon}
                width="16px"
                height="16px"
              />
            }
            onClick={() => (window.location.href = `tel:${contact?.phone}`)}
          >
            {getLocalizedText("system.call.imperative")}
          </Button>
        </Stack>
      )}
    </Card>
  );
};
