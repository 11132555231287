import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EProduct } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import { Route } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import ResetPassword from "./ResetPassword";
import SignIn from "./SignIn";
import SignInToken from "./SignInToken";
import SignUp from "./SignUp";
import VerifyEmail from "./VerifyEmail";
import VerifyPhone from "./VerifyPhone";
export var getAuthScene = function () {
    return (_jsxs(Route, { path: "auth", children: [CONFIG.PRODUCT === EProduct.Dashboard && (_jsxs(_Fragment, { children: [_jsx(Route, { path: "resetPassword", element: _jsx(ResetPassword, {}) }), _jsx(Route, { path: "changePassword", element: _jsx(ChangePassword, {}) }), _jsx(Route, { path: "signup", element: _jsx(SignUp, {}) }), _jsx(Route, { path: "verify/phone", element: _jsx(VerifyPhone, {}) }), _jsx(Route, { path: "verify/email", element: _jsx(VerifyEmail, {}) }), _jsx(Route, { path: "signin-token", element: _jsx(SignInToken, {}) })] })), _jsx(Route, { path: "signin", element: _jsx(SignIn, {}) }), _jsx(Route, { index: true, element: _jsx(SignIn, {}) })] }));
};
