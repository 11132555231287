import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ASSETS } from "../../assets";
import { EMultipleChoiceVariants } from "../../utils/types/radio";
import { Icon } from "../Icon";
import { IconAvatar } from "../IconAvatar";
import { MultipleChoiceStyles } from "./MultipleChoice.styles";
export var MultipleChoice = function (_a) {
    var choices = _a.choices, _b = _a.variant, variant = _b === void 0 ? EMultipleChoiceVariants.List : _b, title = _a.title, selectedChoice = _a.selectedChoice, handleSelect = _a.handleSelect;
    var isList = variant === EMultipleChoiceVariants.List;
    var handleChange = function (event) {
        handleSelect(event.target.value);
    };
    var renderListItem = function (choice) {
        var label = choice.label, value = choice.value, icon = choice.icon;
        var isSelected = value === selectedChoice;
        var sx = MultipleChoiceStyles({ isSelected: isSelected });
        return (_jsx(List, { children: _jsxs(ListItem, { sx: sx.content.listItem, secondaryAction: isSelected && (_jsx("img", { src: ASSETS.greenCheckedCircle, width: "24px", height: "24px", alt: "greenCheckedCircle" })), children: [icon && (_jsx(ListItemAvatar, { sx: sx.content.listItemAvatar, children: _jsx(IconAvatar, { icon: icon }) })), _jsx(ListItemText, { primary: _jsx(Typography, { variant: "h6", children: label }) })] }) }));
    };
    var renderCardItem = function (choice) {
        var label = choice.label, value = choice.value, icon = choice.icon;
        var isSelected = value === selectedChoice;
        var sx = MultipleChoiceStyles({ isSelected: isSelected });
        return (_jsxs(Card, { variant: "outlined", sx: sx.content.card, children: [_jsxs(Box, { sx: sx.content.cardIcon, children: [icon && _jsx(Icon, { path: icon, width: "36px", height: "36px" }), " ", isSelected ? (_jsx("img", { src: ASSETS.greenCheckedCircle, width: "24px", height: "24px", alt: "greenCheckedCircle" })) : null] }), _jsx(Typography, { variant: "h6", children: label })] }));
    };
    var sx = MultipleChoiceStyles({
        fullWidth: isList,
    });
    return (_jsxs(RadioGroup, { "aria-labelledby": "rentio-multiple-choice-label", name: "rentio-multiple-buttons-group", value: selectedChoice, onChange: handleChange, children: [_jsx(Typography, { variant: "body1", sx: sx.content.choicesLabel, mb: 1.5, children: title }), _jsx(Stack, { flexDirection: isList ? "column" : "row", gap: 1, flexWrap: "wrap", children: choices.map(function (choice) {
                    var value = choice.value;
                    return (_jsx(FormControlLabel, { value: value, control: _jsx(Radio, { sx: sx.content.radio }), sx: sx.content.formControlLabel, label: isList ? renderListItem(choice) : renderCardItem(choice) }));
                }) })] }));
};
