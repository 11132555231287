export var CONDITION_RENDERER_TYPE = "CONDITION";
var strategy = function (block, callback, contentState) {
    block.findEntityRanges(function (character) {
        var entityKey = character.getEntity();
        return (entityKey !== null &&
            contentState.getEntity(entityKey).getType() === CONDITION_RENDERER_TYPE);
    }, callback);
};
// eslint-disable-next-line import/no-default-export
export default strategy;
