import * as S from "./StatusDot.styles";
import { EStatus } from "./StatusDot.types";

interface IProps {
  status: EStatus;
  tooltipContent?: React.ReactNode | string;
  isFetching?: boolean;
}

const StatusDot = (props: IProps) => {
  const { status, tooltipContent } = props;

  // Render
  if (!tooltipContent) {
    return (
      <S.Wrap>
        <S.Status status={status} />
      </S.Wrap>
    );
  }

  return (
    <S.Wrap>
      <S.Tooltip
        status={status}
        tooltipContent={tooltipContent}
        placement="right"
      >
        <S.Status status={status} />
      </S.Tooltip>
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default StatusDot;
