import { ERentioFormField } from "../../components/components/Form";
import { getValue, getValueOrExtraValue, } from "../../redux/form/form.utils";
import { EPropertyAdjoined, EPropertyTypeId } from "../../types/property.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { getAmountWithVat } from "../../utils/number.utils";
import { EField } from "./schema.createRentDeposit.types";
import { getNavigationTitle } from "./schema.createRentDeposit.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        var isHouse = extraData.property.typeId === EPropertyTypeId.House;
        var isApartment = extraData.property.typeId === EPropertyTypeId.Apartment;
        return {
            required: [],
            properties: (_a = {},
                _a[EField.Header + "7"] = {
                    default: undefined,
                },
                _a[EField.InsuranceSendProposal] = {
                    default: getValueOrExtraValue(EField.InsuranceSendProposal, defaultValues, "yes"),
                    // @ts-ignore
                    options: ["yes", "no"].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("rent_deposit.insurance.send_proposal.option.".concat(id.toLowerCase())),
                    }); }),
                },
                _a[EField.InsuranceDataSharing] = {
                    type: "boolean",
                    default: getValueOrExtraValue(EField.InsuranceDataSharing, defaultValues, true),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.InsuranceSendProposal] === "yes";
                    },
                },
                _a[EField.InsuranceContractRentPrice] = {
                    default: getAmountWithVat(extraData.contract.currentPrice, (extraData.contract.priceVat || 0) / 100),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.InsuranceSendProposal] === "yes";
                    },
                    requiredIf: function (formData) {
                        return formData[EField.InsuranceSendProposal] === "yes";
                    },
                },
                _a[EField.InsuranceBuildingSurfaceArea] = {
                    default: getValue(EField.InsuranceBuildingSurfaceArea, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.InsuranceSendProposal] === "yes" && isApartment;
                    },
                    requiredIf: function (formData) {
                        return formData[EField.InsuranceSendProposal] === "yes" && isApartment;
                    },
                },
                _a[EField.InsuranceBuildingLayers] = {
                    default: getValue(EField.InsuranceBuildingLayers, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.InsuranceSendProposal] === "yes" && isApartment;
                    },
                },
                _a[EField.InsuranceBuildingAdjoined] = {
                    default: getValue(EField.InsuranceBuildingAdjoined, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.InsuranceSendProposal] === "yes" && isHouse;
                    },
                    // @ts-ignore
                    options: [
                        EPropertyAdjoined.Freestanding,
                        EPropertyAdjoined.OneSide,
                        EPropertyAdjoined.BothSides,
                        // EPropertyAdjoined.Unknown,
                        // EPropertyAdjoined.NotApplicable,
                    ].map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("property.adjoined.option.".concat(id, ".label").toLowerCase()),
                    }); }),
                },
                _a[EField.InsuranceBuildingHasHardMaterials] = {
                    default: getValueOrExtraValue(EField.InsuranceBuildingHasHardMaterials, defaultValues, true),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.InsuranceSendProposal] === "yes";
                    },
                    requiredIf: function (formData) {
                        return formData[EField.InsuranceSendProposal] === "yes";
                    },
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("rent_deposit.insurance.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "7"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rent_deposit.insurance.heading"),
        },
        _a[EField.InsuranceSendProposal] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("rent_deposit.insurance.send_proposal.label"),
            info: getLocalizedText("rent_deposit.insurance.send_proposal.info"),
        },
        _a[EField.InsuranceDataSharing] = {
            "ui:field": ERentioFormField.Switch,
            title: "",
            label: getLocalizedText("request_insurance.modal.intro.checkbox.data_sharing.title"),
            formatSummary: function () { return getLocalizedText("form.yes"); },
            shouldShowOnSummary: function () { return false; },
        },
        _a[EField.InsuranceBuildingSurfaceArea] = {
            "ui:field": ERentioFormField.Number,
            title: getLocalizedText("form.request_insurance.field.building_surface_area"),
            placeholder: "0",
            suffixText: "m²",
            isFloat: true,
        },
        _a[EField.InsuranceContractRentPrice] = {
            "ui:field": ERentioFormField.Number,
            title: getLocalizedText("form.request_insurance.field.contract_rent_price"),
            placeholder: "0",
            suffixText: "EUR",
            isFloat: true,
            readOnly: true,
            shouldShowOnSummary: function () { return false; },
        },
        _a[EField.InsuranceBuildingLayers] = {
            "ui:field": ERentioFormField.Number,
            title: getLocalizedText("form.request_insurance.field.building_layers"),
            placeholder: "0",
            optional: true,
        },
        _a[EField.InsuranceBuildingAdjoined] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("form.request_insurance.field.building_adjoined"),
            optional: true,
        },
        _a[EField.InsuranceBuildingHasHardMaterials] = {
            "ui:field": ERentioFormField.Switch,
            label: getLocalizedText("form.request_insurance.field.building_has_hard_materials"),
            title: "",
            formatSummary: function () { return getLocalizedText("form.yes"); },
            shouldShowOnSummary: function () { return false; },
        },
        _a;
};
export var getValidate = function (_) { return function (formData, errors) {
    if (formData[EField.InsuranceSendProposal] !== "yes") {
        return errors;
    }
    if (errors[EField.InsuranceDataSharing] &&
        !formData[EField.InsuranceDataSharing]) {
        errors[EField.InsuranceDataSharing].addError(getLocalizedText("form.request_insurance.field.data_sharing.error.required"));
    }
    if (errors[EField.InsuranceBuildingHasHardMaterials] &&
        !formData[EField.InsuranceBuildingHasHardMaterials]) {
        errors[EField.InsuranceBuildingHasHardMaterials].addError(getLocalizedText("form.request_insurance.field.building_has_hard_materials.error.required"));
    }
    return errors;
}; };
