import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { getConnectionLabel, getName, } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { DisplayText, Grid, Icon, Lozenge, ResourceListItem, TextStyle, } from "../";
export var ContactOverviewRow = function (_a) {
    var contact = _a.contact, clickable = _a.clickable;
    var iconSource = contact.isCompany ? "briefcase" : "profile";
    var hasAccepted = contact.inviteAcceptedAt;
    return (_jsx(ResourceListItem, { item: contact, link: clickable ? "/contacts/".concat(contact.id) : undefined, media: _jsx(Icon, { source: iconSource }), mediaSize: "large", children: _jsxs(Grid, { alignItems: "center", children: [_jsxs(Grid.Item, { flex: 1, children: [_jsx(Box, { mb: 1, children: _jsx(DisplayText, { size: "small", children: getName(contact) }) }), (contact.email || contact.phone) && (_jsx(TextStyle, { variation: "subdued", children: _jsxs(Grid, { alignItems: "center", children: [contact.phone && (_jsx(Grid.Item, { children: _jsxs(Grid, { spacing: "extraTight", alignItems: "center", children: [_jsx(Grid.Item, { children: _jsx(Icon, { source: "smartphone", size: "small" }) }), _jsx(Grid.Item, { children: formatPhone(contact.phone) })] }) })), contact.email && (_jsx(Grid.Item, { children: _jsxs(Grid, { spacing: "extraTight", alignItems: "center", children: [_jsx(Grid.Item, { children: _jsx(Icon, { source: "email", size: "small" }) }), _jsx(Grid.Item, { children: contact.email })] }) }))] }) }))] }), _jsx(Grid.Item, { children: _jsx(Lozenge, { appearance: hasAccepted ? "success" : "error", children: getConnectionLabel({
                            contact: contact,
                        }) }) })] }) }));
};
