var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { updateContact } from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import isEmpty from "ramda/es/isEmpty";
import mergeDeepLeft from "ramda/es/mergeDeepLeft";
import React from "react";
import { useDispatch } from "react-redux";
import { ts } from "../../services";
import { ContactConnectionIndicator } from "../ContactConnectionIndicator";
import { ContactEditModal } from "../ContactEditModal";
import { Icon } from "../Icon";
import { ResourceList, ResourceListItem, } from "../ResourceList";
import { TextStyle } from "../TextStyle";
var subtractContact = function (person) {
    switch (true) {
        case person.__typename === "Contact" || person.hasOwnProperty("isCompany"):
            return person;
        case person.__typename === "Member" || person.hasOwnProperty("roles"):
            var _a = person.account, accountContact = _a.contact, personAccount = __rest(_a, ["contact"]);
            return !!accountContact
                ? mergeDeepLeft(accountContact, { account: personAccount })
                : null;
        case person.__typename === "Account" ||
            person.hasOwnProperty("activeAccountId"):
            var contact = person.contact, account = __rest(person, ["contact"]);
            return contact ? mergeDeepLeft(contact, { account: account }) : null;
        default:
            return person;
    }
};
var subtractAccount = function (person) {
    switch (true) {
        case person.__typename === "Member" || person.hasOwnProperty("roles"):
            return person.account;
        default:
            return person;
    }
};
export var DefaultContactListItemRender = function (_a) {
    var contactName = _a.contactName, contactMeta = _a.contactMeta, _b = _a.contact, contact = _b === void 0 ? {} : _b;
    return (_jsxs(_Fragment, { children: [_jsx("div", { children: contactName }), _jsxs(TextStyle, { variation: "subdued", size: "small", children: [contact && _jsx(ContactConnectionIndicator, { contact: contact }), contactMeta] })] })
    // <>
    //   <DisplayText size="small" space="extraTight">
    //     {contactName}
    //   </DisplayText>
    //   {contactMeta && <TextStyle variation="subdued">{contactMeta}</TextStyle>}
    // </>
    );
};
export var ContactList = function (_a) {
    var contacts = _a.contacts, actions = _a.actions, onItemClick = _a.onItemClick, _b = _a.hasSeparator, hasSeparator = _b === void 0 ? true : _b, _c = _a.mediaSize, mediaSize = _c === void 0 ? "large" : _c, contactListItemRender = _a.contactListItemRender, _d = _a.shared, shared = _d === void 0 ? false : _d, _e = _a.readOnly, readOnly = _e === void 0 ? false : _e, _f = _a.boxProps, boxProps = _f === void 0 ? { p: 2 } : _f, onItemEdit = _a.onItemEdit;
    var _g = React.useState(), selectedContact = _g[0], setSelectedContact = _g[1];
    var _h = React.useState(false), isModalOpen = _h[0], setModalOpen = _h[1];
    var dispatch = useDispatch();
    React.useEffect(function () {
        setModalOpen(!!selectedContact);
    }, [selectedContact]);
    var renderContactItem = function (member) {
        var contact = subtractContact(member);
        var account = subtractAccount(member);
        var isContact = !!contact;
        var contactOrAccount = contact || account;
        var contactMetaData = [];
        if (contact.phone) {
            var formattedPhone = formatPhone(contact.phone);
            if (formattedPhone) {
                contactMetaData.push(formattedPhone);
            }
        }
        if (contact.email) {
            contactMetaData.push(contact.email);
        }
        // eslint-disable-next-line no-nested-ternary
        var iconSource = isContact
            ? contactOrAccount.isCompany
                ? "briefcase"
                : "profile"
            : "questionMark";
        var editAction = {
            content: ts.contactEditContact(),
            media: _jsx(Icon, { source: "edit", size: "small" }),
            isContactAction: true,
            onClick: function (member) {
                var contact = subtractContact(member);
                setSelectedContact(contact.id);
            },
        };
        var itemActions = readOnly ? [] : __spreadArray([editAction], (actions || []), true);
        var mappedActions = itemActions
            .filter(function (action) {
            var _a = action.isContactAction, isContactAction = _a === void 0 ? true : _a;
            return isContact ? true : !isContactAction;
        })
            .map(function (action) { return (__assign(__assign({}, action), { onClick: function () {
                var _a;
                return (_a = action.onClick) === null || _a === void 0 ? void 0 : _a.call(action, member);
            } })); });
        var handleClick = function () {
            onItemClick === null || onItemClick === void 0 ? void 0 : onItemClick()(member);
        };
        return (_jsx(ResourceListItem, { item: member, boxProps: __assign({}, boxProps), onClick: onItemClick && handleClick, mediaSize: mediaSize, media: _jsx(Icon, { source: iconSource, size: mediaSize }), actions: mappedActions, children: contactListItemRender ? (React.createElement(contactListItemRender, {
                member: member,
                contactOrAccount: contactOrAccount,
                contactMeta: !isEmpty(contactMetaData) && contactMetaData.join(" | "),
            })) : (_jsx(DefaultContactListItemRender, { contactName: getName(contactOrAccount), contactMeta: !isEmpty(contactMetaData) && contactMetaData.join(" | "), contact: contact })) }));
    };
    var handleEditModalClose = function () {
        setSelectedContact(undefined);
    };
    var handleEditSubmit = function (contact) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setSelectedContact(undefined);
            if (shared) {
                dispatch(updateContact.actions.start({ contactId: contact.id, contact: contact }));
            }
            onItemEdit === null || onItemEdit === void 0 ? void 0 : onItemEdit(contact);
            return [2 /*return*/];
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsx(ResourceList, { items: contacts, renderItem: renderContactItem, hasSeparator: hasSeparator }), isModalOpen && (_jsx(ContactEditModal, { contactId: selectedContact, onClose: handleEditModalClose, onSubmit: handleEditSubmit }))] }));
};
