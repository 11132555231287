import cancelContractSchema from "@rentiohq/shared-frontend/dist/forms/cancelContract";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/cancelContract/schema.cancelContract.actions";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { MultiStepForm } from "@rentiohq/web-shared/dist/components";

interface IProps {
  contract: IContract;
  onSuccess: () => void;
  onClose: () => void;
}

const formId = generateFormId();

export const CancelContractModal = ({
  contract,
  onClose,
  onSuccess,
}: IProps) => {
  const ownersAndTenants = contract.members.filter(
    member =>
      member.roles.includes(EContractMemberTypes.Owner) ||
      member.roles.includes(EContractMemberTypes.Tenant),
  );

  return (
    <MultiStepForm
      formId={`cancel-contract-modal-form-${formId}`}
      schemas={cancelContractSchema({
        members: ownersAndTenants,
        contract,
      })}
      submitActionCreator={submitActionCreator(contract.id)}
      isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
        contract.id,
      )}
      performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
        contract.id,
      )}
      onSuccess={() => {
        onSuccess();
        onClose();
      }}
      asModal={true}
      submitLabel={getLocalizedText("contract.cancel")}
      modalProps={{
        onClose,
        shouldCloseOnOverlayClick: true,
        hasDismiss: true,
      }}
      withAside={false}
    />
  );
};
