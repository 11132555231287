import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import {
  getValue,
  getValueOrExtraValue,
} from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  EMoveServiceManager,
  EMoveServiceService,
} from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { JSONSchema6 } from "json-schema";
import { FormValidation } from "react-native-jsonschema-form-test";
import { EField, IExtraData } from "./schema.requestMoveService.types";

export const getSchema =
  (initialValues: { [key: string]: any }, _: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [EField.Services, EField.Manager, EField.IsCorporateAddress],
      properties: {
        [EField.Services]: {
          default: getValue(EField.Services, initialValues, defaultValues),
          // @ts-ignore
          options: [
            EMoveServiceService.Energy,
            EMoveServiceService.Telco,
            EMoveServiceService.Water,
          ].map(id => ({
            id,
            value: getLocalizedText(`move.option.service.${id}`.toLowerCase()),
            enabledOtherOptionsIfSelected:
              id === EMoveServiceService.Water
                ? [EMoveServiceService.Energy]
                : undefined,
          })),
        },
        [EField.Manager]: {
          default: getValue(EField.Manager, initialValues, defaultValues),
          // @ts-ignore
          options: [
            EMoveServiceManager.TenantAndBroker,
            EMoveServiceManager.Tenant,
            EMoveServiceManager.MijnVerhuis,
          ].map(id => ({
            id,
            value: getLocalizedText(
              `move.option.manager.${stringToSnakeCase(id)}`.toLowerCase(),
            ),
          })),
        },
        [EField.IsCorporateAddress]: {
          default: getValueOrExtraValue(
            EField.IsCorporateAddress,
            defaultValues,
            "no",
          ),
          // @ts-ignore
          options: ["no", "yes"].map(id => ({
            id,
            value: getLocalizedText(`system.${id.toLowerCase()}`),
          })),
        },
      },
    };
  };

export const uiSchema = (_: IExtraData): IUiSchema<EField> => ({
  [EField.Services]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.services.title"),
    isMultiSelect: true,
    asOptionList: true,
  },
  [EField.Manager]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("move.field.manager.title"),
  },
  [EField.IsCorporateAddress]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("move.field.corporate_address.title"),
  },
});

export const getValidate =
  (_: IExtraData) => (formData: any, errors: FormValidation) => {
    const services: EMoveServiceService[] = formData[EField.Services] || [];
    if (
      services.includes(EMoveServiceService.Water) &&
      !services.includes(EMoveServiceService.Energy)
    ) {
      errors[EField.Services].addError(
        getLocalizedText(
          "move.field.services.error.energy_required_with_water",
        ),
      );
    }

    return errors;
  };
