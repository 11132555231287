import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { EIntakeStep } from "@rentiohq/shared-frontend/dist/types/application.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getSteps } from "scenes/Properties/scenes/Applications/PropertyDetailApplications.util";
import PropertyListItemHeader, {
  IPropertyListAddressSortProps,
  PropertyListItemItem,
} from "../PropertyListItem/PropertyListItem.header";
import * as S from "./PropertyListItemApplications.styles";

export interface IProps extends IPropertyListAddressSortProps {}

const PropertyListItemApplicationsHeader = ({
  addressOnChangeOrder,
  addressOrder,
  selectable,
}: IProps) => {
  // Redux
  const { broker } = authHooks.useSelf();

  // Helpers
  const intakeType = broker?.intakeType || 2;

  const steps = getSteps(intakeType);

  const renderStepItem = (s: EIntakeStep) => (
    <PropertyListItemItem
      key={s}
      text={getLocalizedText(`property.dashboard.application.${s}.title`)}
      style={{
        flex: 1,
      }}
      contentStyle={{ justifyContent: "center" }}
    />
  );

  return (
    <PropertyListItemHeader
      addressOnChangeOrder={addressOnChangeOrder}
      addressOrder={addressOrder}
      selectable={selectable}
    >
      <S.PropertyListItemItemSpacer />

      {steps.reduce(
        (allSteps, steps) => [...allSteps, ...steps.map(renderStepItem)],
        [] as JSX.Element[],
      )}

      <S.PropertyListItemItemSpacer />
    </PropertyListItemHeader>
  );
};

// eslint-disable-next-line import/no-default-export
export default PropertyListItemApplicationsHeader;
