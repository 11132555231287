var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EPaymentRequestFetchType, } from "../../types/paymentRequest.types";
import { REDUCER_KEY_PAYMENT, } from "./payment.reducer";
import * as paymentUtils from "./payment.utils";
export var getPaymentOrder = function (state, paymentOrderId) {
    return state.payment.paymentOrders[paymentOrderId];
};
export var isFetchingPaymentOrder = function (state, paymentOrderId) { var _a; return ((_a = state.payment.getPaymentOrderById[paymentOrderId]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var fetchPaymentOrderError = function (state, paymentOrderId) { var _a; return (_a = state.payment.getPaymentOrderById[paymentOrderId]) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var getPaymentOrdersForProperty = function (state, propertyId) {
    var paymentOrdersState = state.payment.getPaymentOrdersForProperty[propertyId];
    if (!paymentOrdersState) {
        return undefined;
    }
    return paymentOrdersState.paymentOrders;
};
export var getPaymentOrders = function (state) {
    return Object.values(state.payment.paymentOrders).filter(function (property) { return property !== undefined; });
};
export var getPaymentOrdersForPage = function (state, identifier, page) {
    var _a, _b, _c;
    return (_c = (_b = (_a = state.payment.pagedPaymentOrders[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.paymentOrderIds) === null || _c === void 0 ? void 0 : _c.map(function (paymentOrderId) { return state.payment.paymentOrders[paymentOrderId]; }).filter(Boolean);
};
export var getPayeeIban = function (state, paymentOrderId, payeeId) {
    var ibanId = paymentUtils.getIbanId(paymentOrderId, payeeId);
    if (!ibanId)
        return null;
    return state.payment.ibans[ibanId];
};
export var getPayerIban = function (state, paymentOrderId, payerId) {
    var ibanId = paymentUtils.getIbanId(paymentOrderId, undefined, payerId);
    if (!ibanId)
        return null;
    return state.payment.ibans[ibanId];
};
export var isFetchingPayeeIban = function (state, paymentOrderId) {
    var ibanState = state.payment.ibansById[paymentOrderId];
    if (!ibanState) {
        return false;
    }
    return ibanState.isFetching || false;
};
export var fetchIbanError = function (state, paymentOrderId) {
    var ibanState = state.payment.ibansById[paymentOrderId];
    if (!ibanState) {
        return false;
    }
    return ibanState.fetchError || false;
};
export var isFetchingPaymentOrdersForPage = function (state, identifier, page) {
    var _a, _b;
    return ((_b = (_a = state.payment.pagedPaymentOrders[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.isFetching) ||
        false;
};
export var paymentOrdersForPageFetchError = function (state, identifier, page) { var _a, _b; return (_b = (_a = state.payment.pagedPaymentOrders[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.fetchError; };
export var getPaymentOrdersByIdentifier = function (state, paymentOrdersIdentifier) {
    var _a, _b;
    return (_b = (_a = state.payment.paymentOrdersByIdentifier[paymentOrdersIdentifier]) === null || _a === void 0 ? void 0 : _a.paymentOrderIds) === null || _b === void 0 ? void 0 : _b.map(function (id) { return state.payment.paymentOrders[id]; }).filter(Boolean);
};
export var isFetchingPaymentOrders = function (state) {
    return state.payment.isFetchingPaymentOrders;
};
export var isFetchingPaymentOrdersByIdentifier = function (state, paymentOrdersIdentifier) {
    var _a;
    return ((_a = state.payment.paymentOrdersByIdentifier[paymentOrdersIdentifier]) === null || _a === void 0 ? void 0 : _a.isFetching) || false;
};
export var fetchPaymentOrdersError = function (state) {
    return state.payment.fetchPaymentOrdersError;
};
export var fetchPaymentOrdersByIdentifierError = function (state, paymentOrdersIdentifier) { var _a; return (_a = state.payment.paymentOrdersByIdentifier[paymentOrdersIdentifier]) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var isExhaustedFetchingPaymentOrders = function (state) {
    return state.payment.isExhaustedFetchingPaymentOrders;
};
export var getPaymentRequestState = function (state, fetchType) {
    var fetchState;
    switch (fetchType) {
        case EPaymentRequestFetchType.Open:
            fetchState = state.payment.openPaymentRequests;
            break;
        case EPaymentRequestFetchType.Pending:
            fetchState = state.payment.pendingPaymentRequests;
            break;
        case EPaymentRequestFetchType.Done:
            fetchState = state.payment.donePaymentRequests;
            break;
        default:
            break;
    }
    return fetchState;
};
export var getPaymentRequests = function (state, fetchType) {
    var fetchState = getPaymentRequestState(state, fetchType);
    if (!fetchState) {
        return;
    }
    return fetchState.paymentRequests;
};
export var getPaymentRequestsByIdentifiers = function (state, paymentRequestsIdentifiers) {
    if (state.payment.paymentRequestsByIdentifier) {
        var identifiers = Object.keys(state.payment.paymentRequestsByIdentifier).filter(function (identifier) {
            return paymentRequestsIdentifiers.map(function (i) { return String(i); }).includes(identifier);
        });
        var map_1 = {};
        identifiers.forEach(function (identifier) {
            var paymentRequestIds = state.payment.paymentRequestsByIdentifier[identifier].paymentRequestIds;
            var paymentRequests = paymentRequestIds
                ? paymentRequestIds.map(function (id) { return state.payment.paymentRequests[id]; })
                : [];
            map_1[identifier] = __assign(__assign({}, state.payment.paymentRequestsByIdentifier[identifier]), { paymentRequests: paymentRequests });
        });
        return map_1;
    }
    return {};
};
export var getIsFetchingOrHasErrorPaymentRequestsByIdentifiers = function (state, paymentRequestsIdentifiers) {
    if (state.payment.paymentRequestsByIdentifier) {
        var identifiers = Object.keys(state.payment.paymentRequestsByIdentifier).filter(function (identifier) {
            return paymentRequestsIdentifiers.map(function (i) { return String(i); }).includes(identifier);
        });
        var map_2 = {};
        identifiers.forEach(function (identifier) {
            var pr = state.payment.paymentRequestsByIdentifier[identifier];
            map_2[identifier] = pr.isFetching || !!pr.fetchError;
        });
        return map_2;
    }
    return {};
};
export var getPaymentRequestsByIdentifier = function (state, paymentRequestsIdentifier) {
    var _a, _b;
    return (_b = (_a = state.payment.paymentRequestsByIdentifier[paymentRequestsIdentifier]) === null || _a === void 0 ? void 0 : _a.paymentRequestIds) === null || _b === void 0 ? void 0 : _b.map(function (id) { return state.payment.paymentRequests[id]; }).filter(Boolean);
};
export var isFetchingPaymentRequestsByIdentifier = function (state, paymentRequestsIdentifier) {
    var _a;
    return ((_a = state.payment.paymentRequestsByIdentifier[paymentRequestsIdentifier]) === null || _a === void 0 ? void 0 : _a.isFetching) || false;
};
export var isExhaustedPaymentRequestsByIdentifier = function (state, paymentRequestsIdentifier) {
    var _a;
    return (_a = state.payment.paymentRequestsByIdentifier[paymentRequestsIdentifier]) === null || _a === void 0 ? void 0 : _a.isExhausted;
};
export var paymentRequestsByIdentifierFetchError = function (state, paymentRequestsIdentifier) {
    var _a;
    return (_a = state.payment.paymentRequestsByIdentifier[paymentRequestsIdentifier]) === null || _a === void 0 ? void 0 : _a.fetchError;
};
export var paymentRequestsByIdentifier = function (state, identifier) {
    if (!identifier) {
        return {
            items: undefined,
            isFetching: false,
            isExhausted: false,
            fetchError: undefined,
        };
    }
    return {
        items: getPaymentRequestsByIdentifier(state, identifier),
        isFetching: isFetchingPaymentRequestsByIdentifier(state, identifier),
        isExhausted: isExhaustedPaymentRequestsByIdentifier(state, identifier),
        fetchError: paymentRequestsByIdentifierFetchError(state, identifier),
    };
};
export var getIsFetchingPaymentRequests = function (state, fetchType) {
    var fetchState = getPaymentRequestState(state, fetchType);
    if (!fetchState) {
        return false;
    }
    return fetchState.isFetching;
};
export var getIsExhaustedFetchingPaymentRequests = function (state, fetchType) {
    var fetchState = getPaymentRequestState(state, fetchType);
    if (!fetchState) {
        return false;
    }
    return fetchState.isExhausted;
};
export var getFetchPaymentRequestsError = function (state, fetchType) {
    var fetchState = getPaymentRequestState(state, fetchType);
    if (!fetchState) {
        return;
    }
    return fetchState.fetchError;
};
export var getPaymentRequestsForPage = function (state, identifier, page) {
    var _a, _b, _c;
    return (_c = (_b = (_a = state.payment.pagedPaymentRequests[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.paymentRequestIds) === null || _c === void 0 ? void 0 : _c.map(function (paymentRequestId) { return state.payment.paymentRequests[paymentRequestId]; }).filter(Boolean);
};
export var isFetchingPaymentRequestsForPage = function (state, identifier, page) {
    var _a, _b;
    return ((_b = (_a = state.payment.pagedPaymentRequests[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.isFetching) ||
        false;
};
export var paymentRequestsForPageFetchError = function (state, identifier, page) { var _a, _b; return (_b = (_a = state.payment.pagedPaymentRequests[identifier]) === null || _a === void 0 ? void 0 : _a.pages[page]) === null || _b === void 0 ? void 0 : _b.fetchError; };
export var getPaymentRequest = function (state, id) {
    return state.payment.paymentRequests[id];
};
export var getIsFetchingPaymentRequest = function (state, id) {
    var paymentRequestState = state.payment.paymentRequestsById[id];
    if (!paymentRequestState) {
        return false;
    }
    return paymentRequestState.isFetching || false;
};
export var getFetchErrorPaymentRequest = function (state, id) {
    var paymentRequestState = state.payment.paymentRequestsById[id];
    if (!paymentRequestState) {
        return;
    }
    return paymentRequestState.fetchError;
};
export var isDeletingMandate = function (state, mandateId) {
    return state.payment.deleteMandate[mandateId]
        ? state.payment.deleteMandate[mandateId].isDeletingMandate
        : false;
};
export var deleteMandateError = function (state, mandateId) {
    return state.payment.deleteMandate[mandateId]
        ? state.payment.deleteMandate[mandateId].deleteMandateError
        : undefined;
};
export var isMarkingThirdPartyReceived = function (state, paymentRequestId) {
    return state.payment.markThirdPartyReceived[paymentRequestId]
        ? state.payment.markThirdPartyReceived[paymentRequestId].isMarkingReceived
        : false;
};
export var isMarkingThirdPartyReceivedError = function (state, paymentRequestId) {
    return state.payment.markThirdPartyReceived[paymentRequestId]
        ? state.payment.markThirdPartyReceived[paymentRequestId].markReceivedError
        : undefined;
};
export var isMarkingPaid = function (state, paymentRequestId) {
    return state.payment.markPaid[paymentRequestId]
        ? state.payment.markPaid[paymentRequestId].isMarkingPaid
        : false;
};
export var markPaidError = function (state, paymentRequestId) {
    return state.payment.markPaid[paymentRequestId]
        ? state.payment.markPaid[paymentRequestId].markPaidError
        : undefined;
};
export var isPendingFollowUp = function (state, paymentRequestId, type) {
    var _a;
    return state.payment.followUp[paymentRequestId]
        ? (_a = state.payment.followUp[paymentRequestId][type]) === null || _a === void 0 ? void 0 : _a.isPendingFollowUp
        : false;
};
export var followUpError = function (state, paymentRequestId, type) {
    var _a;
    return state.payment.followUp[paymentRequestId]
        ? (_a = state.payment.followUp[paymentRequestId][type]) === null || _a === void 0 ? void 0 : _a.followUpError
        : undefined;
};
export var getMissingKYCs = function (state, identifier) { var _a; return (_a = state.payment.missingKYCs[identifier]) === null || _a === void 0 ? void 0 : _a.items; };
export var getIsFetchingMissingKYCs = function (state, identifier) { var _a; return ((_a = state.payment.missingKYCs[identifier]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var getFetchErrorMissingKYCs = function (state, identifier) { var _a; return (_a = state.payment.missingKYCs[identifier]) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var getIsExhaustedMissingKYCs = function (state, identifier) { var _a; return ((_a = state.payment.missingKYCs[identifier]) === null || _a === void 0 ? void 0 : _a.isExhausted) || false; };
