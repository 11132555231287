import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { drawerWidth, drawerWidthCollapsed } from "../../utils/constant";
export var SidebarStyles = function (sidebarOpened) {
    var theme = useTheme();
    var downSM = useMediaQuery(function (theme) { return theme.breakpoints.down("sm"); });
    return {
        wrapper: {
            width: sidebarOpened ? drawerWidth : drawerWidthCollapsed,
            transition: "all 0.5s ease-in-out",
            display: downSM ? "none" : "block",
        },
        content: {
            drawer: {
                "& .MuiDrawer-paper": {
                    width: sidebarOpened ? drawerWidth : drawerWidthCollapsed,
                    bgcolor: theme.palette.grey[100],
                    color: "text.primary",
                    borderRight: "1px solid ".concat(theme.palette.grey[200]),
                    transition: "width 425ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                },
            },
            logo: {
                display: "flex",
                padding: "24px 0 40px 24px",
            },
            menu: {
                open: {
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    marginTop: "0px",
                },
                close: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                },
            },
        },
    };
};
