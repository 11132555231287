var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import logger from "../../logger";
import * as socketActions from "../../redux/socket/socket.actions";
import { EChatMemberUpdateStatus } from "../../types/chat.types";
import { ESocketEventMessage, ESocketSource, } from "../../types/socket.types";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralUpdateSuccessText } from "../../utils/i18n/i18n.utils";
import * as documentApi from "../documents/document.api";
import { generateSaga } from "../generators/generator.saga";
import { counterInfoReceivedStart, getMessagesFailed, getMessagesStart, isTypingReceivedStart, messagesReceivedStart, requestForMessagesFulfilledStart, requestForMessagesStart, sendMessageFailed, sendMessageStart, sendReadReceiptStart, sendTypingFailed, sendTypingStart, setChatIsExhaustedStart, statusInfoReceivedStart, stopTypingStart, updateChatStart, updateFailed, updateMembersStart, updateSucceeded, } from "./chat.actions";
import * as chatApi from "./chat.api";
import { GENERATOR_PARAMS } from "./chat.constants";
import { getMessagesCount, getMessagesRequest } from "./chat.selectors";
var REQUEST_MESSAGES_AMOUNT = 50;
// Chat messages start flow
function socketMessageReceivedFlow(_a) {
    var data, jsonData, source, event_1, socketPayload, ref, messagesRequest, _b, messages, _c, message, messages, statusInfo, typingInfo, counterInfo, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 24, , 25]);
                data = payload.data;
                jsonData = JSON.parse(data);
                if (!jsonData) {
                    throw Error("Could not parse socket message in socketMessageReceivedFlow");
                }
                source = jsonData.source, event_1 = jsonData.event, socketPayload = jsonData.message, ref = jsonData.ref;
                if (source !== ESocketSource.Chat) {
                    return [2 /*return*/];
                }
                if (!ref) return [3 /*break*/, 5];
                _b = getMessagesRequest;
                return [4 /*yield*/, select()];
            case 1:
                messagesRequest = _b.apply(void 0, [_d.sent(), ref]);
                messages = socketPayload;
                if (!messages) return [3 /*break*/, 5];
                if (!(messagesRequest &&
                    messagesRequest.amountRequested > messages.length)) return [3 /*break*/, 3];
                return [4 /*yield*/, put(setChatIsExhaustedStart.getAction({
                        chatId: messagesRequest.chatId,
                    }))];
            case 2:
                _d.sent();
                _d.label = 3;
            case 3: return [4 /*yield*/, put(requestForMessagesFulfilledStart.getAction({ ref: ref }))];
            case 4:
                _d.sent();
                _d.label = 5;
            case 5:
                _c = event_1;
                switch (_c) {
                    case ESocketEventMessage.Message: return [3 /*break*/, 6];
                    case ESocketEventMessage.Messages: return [3 /*break*/, 10];
                    case ESocketEventMessage.Status: return [3 /*break*/, 13];
                    case ESocketEventMessage.Typing: return [3 /*break*/, 16];
                    case ESocketEventMessage.Counter: return [3 /*break*/, 19];
                }
                return [3 /*break*/, 22];
            case 6:
                if (!socketPayload) return [3 /*break*/, 9];
                message = socketPayload;
                return [4 /*yield*/, put(socketActions.socketMessageSend({
                        source: "chat",
                        event: "received",
                        message: {
                            chatMessageId: message.id,
                        },
                    }))];
            case 7:
                _d.sent();
                return [4 /*yield*/, put(messagesReceivedStart.getAction([message]))];
            case 8:
                _d.sent();
                _d.label = 9;
            case 9: return [3 /*break*/, 23];
            case 10:
                if (!socketPayload) return [3 /*break*/, 12];
                messages = socketPayload;
                // TODO: Receipt?
                // yield all(
                //   messages.map(message =>
                //     put(
                //       socketMessageSend({
                //         source: "chat",
                //         event: "received",
                //         message: {
                //           chatMessageId: message.id
                //         }
                //       })
                //     )
                //   )
                // );
                return [4 /*yield*/, put(messagesReceivedStart.getAction(messages))];
            case 11:
                // TODO: Receipt?
                // yield all(
                //   messages.map(message =>
                //     put(
                //       socketMessageSend({
                //         source: "chat",
                //         event: "received",
                //         message: {
                //           chatMessageId: message.id
                //         }
                //       })
                //     )
                //   )
                // );
                _d.sent();
                _d.label = 12;
            case 12: return [3 /*break*/, 23];
            case 13:
                if (!socketPayload) return [3 /*break*/, 15];
                statusInfo = socketPayload;
                return [4 /*yield*/, put(statusInfoReceivedStart.getAction(statusInfo))];
            case 14:
                _d.sent();
                _d.label = 15;
            case 15: return [3 /*break*/, 23];
            case 16:
                if (!socketPayload) return [3 /*break*/, 18];
                typingInfo = socketPayload;
                return [4 /*yield*/, put(isTypingReceivedStart.getAction(typingInfo))];
            case 17:
                _d.sent();
                _d.label = 18;
            case 18: return [3 /*break*/, 23];
            case 19:
                if (!socketPayload) return [3 /*break*/, 21];
                counterInfo = socketPayload;
                return [4 /*yield*/, put(counterInfoReceivedStart.getAction(counterInfo))];
            case 20:
                _d.sent();
                _d.label = 21;
            case 21: return [3 /*break*/, 23];
            case 22: return [3 /*break*/, 23];
            case 23: return [3 /*break*/, 25];
            case 24:
                unknownError_1 = _d.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [3 /*break*/, 25];
            case 25: return [2 /*return*/];
        }
    });
}
function isTypingReceivedFlow(_a) {
    var unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                return [4 /*yield*/, new Promise(function (resolve) {
                        setTimeout(function () {
                            resolve(true);
                        }, 2000);
                    })];
            case 1:
                _b.sent();
                return [4 /*yield*/, put(stopTypingStart.getAction(payload))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}
function getMessagesStartFlow(_a) {
    var chatId, _b, shouldReset, ref, skip, _c, _d, unknownError_3, error;
    var payload = _a.payload;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                chatId = payload.chatId, _b = payload.shouldReset, shouldReset = _b === void 0 ? false : _b, ref = payload.ref;
                _e.label = 1;
            case 1:
                _e.trys.push([1, 7, , 9]);
                if (!shouldReset) return [3 /*break*/, 2];
                _c = 0;
                return [3 /*break*/, 4];
            case 2:
                _d = getMessagesCount;
                return [4 /*yield*/, select()];
            case 3:
                _c = _d.apply(void 0, [_e.sent(), chatId]);
                _e.label = 4;
            case 4:
                skip = _c;
                return [4 /*yield*/, put(requestForMessagesStart.getAction({
                        ref: ref,
                        chatId: chatId,
                        amountRequested: REQUEST_MESSAGES_AMOUNT,
                    }))];
            case 5:
                _e.sent();
                return [4 /*yield*/, put(socketActions.socketMessageSend({
                        source: "chat",
                        event: "request",
                        message: {
                            chatId: chatId,
                            skip: skip,
                            limit: REQUEST_MESSAGES_AMOUNT,
                        },
                        ref: ref,
                    }))];
            case 6:
                _e.sent();
                return [3 /*break*/, 9];
            case 7:
                unknownError_3 = _e.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(getMessagesFailed.getAction({ ref: ref, error: error }))];
            case 8:
                _e.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function sendReadReceiptStartFlow(_a) {
    var messageId, ref, unknownError_4, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 2, , 3]);
                messageId = payload.messageId, ref = payload.ref;
                return [4 /*yield*/, put(socketActions.socketMessageSend({
                        source: "chat",
                        event: "read",
                        message: {
                            chatMessageId: messageId,
                        },
                        ref: ref,
                    }))];
            case 1:
                _b.sent();
                return [3 /*break*/, 3];
            case 2:
                unknownError_4 = _b.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}
function sendMessageStartFlow(_a) {
    var message, ref, attachment, presignedUrl, unknownError_5, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                message = payload.message, ref = payload.ref;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 7, , 9]);
                attachment = undefined;
                if (!message.attachment) return [3 /*break*/, 5];
                return [4 /*yield*/, put(socketActions.socketMessageStartPending({
                        ref: ref,
                    }))];
            case 2:
                _b.sent();
                return [4 /*yield*/, call(documentApi.uploadDocumentToS3, message.attachment)];
            case 3:
                presignedUrl = _b.sent();
                attachment = {
                    filename: message.attachment.name || "file",
                    preSignedUrl: presignedUrl,
                };
                return [4 /*yield*/, put(socketActions.socketMessageStopPending({
                        ref: ref,
                    }))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [4 /*yield*/, put(socketActions.socketMessageSend({
                    source: "chat",
                    event: "message",
                    message: __assign(__assign({}, message), { attachment: attachment }),
                    ref: ref,
                }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 9];
            case 7:
                unknownError_5 = _b.sent();
                error = unknownError_5;
                logger.logError({ error: error });
                return [4 /*yield*/, put(sendMessageFailed.getAction({ error: error, ref: ref }))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function sendTypingStartFlow(_a) {
    var chatId, unknownError_6, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                chatId = payload.chatId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 5]);
                return [4 /*yield*/, put(socketActions.socketMessageSend({
                        source: "chat",
                        event: "typing",
                        message: {
                            chatId: chatId,
                        },
                    }))];
            case 2:
                _b.sent();
                return [3 /*break*/, 5];
            case 3:
                unknownError_6 = _b.sent();
                error = unknownError_6;
                logger.logError({ error: error });
                return [4 /*yield*/, put(sendTypingFailed.getAction({ error: error }))];
            case 4:
                _b.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
// Chat start flow
function updateChatStartFlow(action) {
    var _a, id, data, members, onSuccess, onFailure, membersToAdd, membersToRemove, chat, unknownError_7, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, data = _a.data, members = _a.members, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                if (!members) return [3 /*break*/, 3];
                membersToAdd = members
                    .filter(function (member) { return member.status === EChatMemberUpdateStatus.Added; })
                    .map(function (member) { return ({ accountId: member.account.id, roles: member.roles }); });
                membersToRemove = members
                    .filter(function (member) { return member.status === EChatMemberUpdateStatus.Removed; })
                    .map(function (member) { return ({ accountId: member.account.id, roles: member.roles }); });
                return [4 /*yield*/, put(updateMembersStart.getAction({
                        id: id,
                        membersToAdd: membersToAdd,
                        membersToRemove: membersToRemove,
                        suppressSuccessAlert: true,
                    }))];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [4 /*yield*/, call(chatApi.update, {
                    id: id,
                    data: data,
                })];
            case 4:
                chat = _b.sent();
                return [4 /*yield*/, put(updateSucceeded.getAction({
                        id: id,
                        result: chat,
                    }))];
            case 5:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(chat.data);
                showAlert({
                    type: "success",
                    message: getGeneralUpdateSuccessText("chat"),
                });
                return [3 /*break*/, 8];
            case 6:
                unknownError_7 = _b.sent();
                error = unknownError_7;
                logger.logError({ error: error });
                return [4 /*yield*/, put(updateFailed.getAction({ id: id, error: error }))];
            case 7:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(socketActions.socketMessageReceived.getType(), socketMessageReceivedFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(isTypingReceivedStart.type, isTypingReceivedFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(getMessagesStart.type, getMessagesStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(sendReadReceiptStart.type, sendReadReceiptStartFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(sendMessageStart.type, sendMessageStartFlow)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(sendTypingStart.type, sendTypingStartFlow)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeEvery(updateChatStart.type, updateChatStartFlow)];
            case 7:
                _a.sent();
                return [4 /*yield*/, generateSaga(GENERATOR_PARAMS)];
            case 8:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
