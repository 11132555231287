import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { ERevenuesInvoicedStatus } from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import { activateRentioLegalFeature } from "@rentiohq/shared-frontend/dist/reduxV2/legalCase/legalCase.utils";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  getDay,
  getMonth,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { appendQueryParams } from "@rentiohq/shared-frontend/dist/utils/url.utils";
import {
  CallOutCard,
  Card,
  DisplayText,
  ESpacings,
  Grid,
  QuickActionCard,
  QuickActionCardCollection,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  EEndDateWithinMonths,
  ERenewalDateWithinMonths,
} from "scenes/FollowUp/EndingContracts/EndingContracts.types";
import { useIsRentioLegalActivated } from "scenes/RentioLegal/hooks/useIsRentioLegalActivated";
import { EBeneficiaryReportReported } from "scenes/Reports/Beneficiary/BeneficiaryReport.types";
import { EInitialType } from "scenes/Tasks/components/TasksFilter/TasksFilter.utils";
import birthdayIcon from "../../assets/svg/icon-birthday.svg";
import calculatorIcon from "../../assets/svg/icon-calculator.svg";
import invoicesIcon from "../../assets/svg/icon-invoices.svg";
import paymentsIcon from "../../assets/svg/icon-payments.svg";
import * as t from "../../services/translationService";
import * as S from "./Dashboard.styled";
import { IDashboardProps } from "./Dashboard.types";
import { FeatureSpecificQuickActionCard } from "./components/FeatureSpecificQuickActionCard";

export const Dashboard: React.FC<IDashboardProps> = ({ counts }) => {
  const { contactMe, broker } = authHooks.useSelf();
  const navigate = useNavigate();
  const {
    paymentRequestsCount,
    revenuesCount,
    indexableContractsCount,
    signaturesPendingCount,
    registrationLocationDescriptionActionRequiredCount,
    registrationAddendaActionRequiredCount,
    registrationsContractsActionRequiredCount,
    toSignRentDepositsCount,
    tasksToExecuteCount,
    tasksKeyPointRepair,
    deadlineWithin1MonthCount,
    endDateWithin1MonthCount,
    toBeReportedCount,
    ongoingLegalCasesCount,
    paymentsTooLate,
  } = counts;

  const { internalModeEnabled } = useInternalMode();

  const hasPaymentV2ForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.PaymentV2,
  );

  const hasKeypointRepairFeature = brokerHooks.useBrokerFeature(
    EBrokerFeature.KeypointRepair,
  );
  const hasRentioLegalFeature = useIsRentioLegalActivated();

  const beneficiaryReportsRender = (
    <CallOutCard
      heading={
        <DisplayText size="extraSmall" subdued={true}>
          {getLocalizedText("dashboard.card.beneficiary_reports")}
        </DisplayText>
      }
      primaryAction={{
        content: getLocalizedText("dashboard.card.beneficiary_reports"),
        url: `/reports?reported=${EBeneficiaryReportReported.PaymentRequestNotReported}`,
      }}
      customImage={invoicesIcon}
    >
      <DisplayText size="extraLarge" element="div">
        {toBeReportedCount}
      </DisplayText>
    </CallOutCard>
  );

  const now = new Date();
  const isBirthday =
    contactMe?.dateOfBirth &&
    getMonth(contactMe.dateOfBirth) === getMonth(now) &&
    getDay(contactMe.dateOfBirth) === getDay(now);

  const birthdayCard = isBirthday && (
    <CallOutCard
      heading={
        <DisplayText size="extraSmall" subdued={true}>
          {getLocalizedText("birthday_card.subtitle")}
        </DisplayText>
      }
      customImage={birthdayIcon}
    >
      <DisplayText size="extraLarge" element="div">
        {getLocalizedText("birthday_card.title", {
          value: contactMe?.firstname,
        })}
      </DisplayText>
    </CallOutCard>
  );

  const paymentsCardRender =
    paymentRequestsCount > 0 ? (
      <CallOutCard
        heading={
          <DisplayText size="extraSmall" subdued={true}>
            {t.dashboardCardPaymentsTitle()}
          </DisplayText>
        }
        primaryAction={{
          content: t.dashboardCardPaymentsAction(),
          url: "/follow-up/payments",
        }}
        customImage={paymentsIcon}
      >
        <DisplayText size="extraLarge" element="div">
          {paymentRequestsCount}
        </DisplayText>
      </CallOutCard>
    ) : (
      <CallOutCard
        heading={
          <DisplayText size="extraSmall" subdued={true}>
            {t.dashboardCardEmptyPaymentsTitle()}
          </DisplayText>
        }
        secondaryAction={{
          content: t.dashboardCardEmptyPaymentsAction(),
          url: "/follow-up/payments",
        }}
        customImage={paymentsIcon}
      >
        <p>{t.dashboardCardEmptyPaymentsBody()}</p>
      </CallOutCard>
    );

  const revenuesCardRender = (
    <Grid.Item width={[1, 1, 1, 1 / 3]}>
      <CallOutCard
        heading={
          <DisplayText size="extraSmall" subdued={true}>
            {t.dashboardCardRevenuesTitle({ broker })}
          </DisplayText>
        }
        primaryAction={{
          content: t.dashboardCardRevenuesAction({ broker }),
          url: `/revenues?invoiceStatus=${ERevenuesInvoicedStatus.NotInvoiced}`,
        }}
        customImage={calculatorIcon}
      >
        <DisplayText size="extraLarge" element="div">
          {revenuesCount}
        </DisplayText>
      </CallOutCard>
    </Grid.Item>
  );

  const getStageRenewalUrlWithQuery = (
    renewalDate: ERenewalDateWithinMonths,
  ) => {
    return appendQueryParams({
      path: `follow-up/ending-contracts?screenType=renewal_date&renewalDateWithinMonths=${renewalDate}`,
      queryParams: {
        renewalDate,
      },
    });
  };

  const getStageEndDateUrlWithQuery = (endDate: EEndDateWithinMonths) => {
    return appendQueryParams({
      path: `follow-up/ending-contracts?screenType=end_date&endDateWithinMonths=${endDate}`,
      queryParams: {
        endDate,
      },
    });
  };

  const getStageEndDateUrlWithQueryV2 = (endDate: EEndDateWithinMonths) => {
    return appendQueryParams({
      path: `follow-up/ending-contracts-v2?screenType=end_date&endDateWithinMonths=${endDate}`,
      queryParams: {
        endDate,
      },
    });
  };

  const getRegistrationUrlWithQuery = (stage: string) => {
    return appendQueryParams({
      path: "follow-up/registrations",
      queryParams: {
        stage,
      },
    });
  };

  const getRegistrationDocumentsUrlWithQuery = (stage: string) => {
    return appendQueryParams({
      path: "follow-up/registration-documents",
      queryParams: {
        stage,
      },
    });
  };

  return (
    <>
      {!!birthdayCard && (
        <S.Wrapper mb={ESpacings.extraLoose}>
          <Grid.Item width={[1, 1, 1, 1 / 3]}>{birthdayCard}</Grid.Item>
        </S.Wrapper>
      )}

      <S.Wrapper mb={ESpacings.extraLoose}>
        <Grid.Item width={[1, 1, 1, 1 / 3]}>{paymentsCardRender}</Grid.Item>
        {revenuesCardRender}
        <Grid.Item width={[1, 1, 1, 1 / 3]}>
          {beneficiaryReportsRender}
        </Grid.Item>
      </S.Wrapper>

      <Card
        hasBorder={true}
        heading={getLocalizedText("dashboard.group.rent.title")}
      >
        <QuickActionCardCollection>
          <QuickActionCard
            heading={getLocalizedText("dashboard.card.signing")}
            icon="contentPenWrite"
            count={signaturesPendingCount}
            countDescription={getLocalizedText(
              "dashboard.card.incompletely_signed",
            )}
            url="/follow-up/signatures?page=1&stage=pending"
            actions={[
              {
                content: t.documentPackageCreateAction(),
                url: "/forms/document-package",
              },
            ]}
            maxWidthPercentage={24}
          />
          <QuickActionCard
            heading={getLocalizedText("dashboard.card.deposits")}
            icon="safe"
            count={toSignRentDepositsCount}
            url="/follow-up/rent-deposits?page=1&signatureStatus=not_signed"
            // actions={[
            //   {
            //     content: t.rentDepositOpenAction(),
            //     url: "/forms/rent-deposit/open",
            //   },
            // ]}
            maxWidthPercentage={24}
          />
          <QuickActionCard
            heading={getLocalizedText("dashboard.card.registrations")}
            icon="houseNotes"
            count={
              registrationsContractsActionRequiredCount +
              registrationLocationDescriptionActionRequiredCount
            }
            url={getRegistrationUrlWithQuery("action_required")}
            maxWidthPercentage={24}
          />
          <QuickActionCard
            heading={getLocalizedText("dashboard.card.addenda")}
            icon="houseNotes"
            count={registrationAddendaActionRequiredCount}
            url={getRegistrationDocumentsUrlWithQuery("action_required")}
            maxWidthPercentage={24}
          />
        </QuickActionCardCollection>
      </Card>

      <Card
        hasBorder={true}
        heading={getLocalizedText("dashboard.card.property_management")}
      >
        <QuickActionCardCollection>
          <QuickActionCard
            heading={getLocalizedText("dashboard.card.payments")}
            icon="currencyEuroCircle"
            count={paymentsTooLate}
            countDescription={getLocalizedText("dashboard.card.payments.info")}
            url="/follow-up/payments"
          />

          {broker && (
            <FeatureSpecificQuickActionCard
              featureEnabled={hasRentioLegalFeature}
              heading={getLocalizedText("dashboard.card.rentio_legal")}
              subheading={getLocalizedText(
                "dashboard.card.rentio_legal.subheading",
              )}
              icon="legalHammer"
              count={ongoingLegalCasesCount}
              countDescription={getLocalizedText(
                "dashboard.card.rentio_legal.info",
              )}
              emptySubheading={getLocalizedText(
                "dashboard.card.rentio_legal.subheading.empty",
              )}
              emptyDescription={getLocalizedText(
                "dashboard.card.rentio_legal.empty.description",
              )}
              emptyText={
                hasRentioLegalFeature
                  ? undefined
                  : getLocalizedText("dashboard.card.rentio_legal.empty")
              }
              url={"/rentio-legal?caseState=ongoing_cases&page=1"}
              emptyOnClick={
                hasRentioLegalFeature
                  ? () =>
                      navigate("/rentio-legal?caseState=ongoing_cases&page=1")
                  : () => activateRentioLegalFeature(broker)
              }
            />
          )}

          <QuickActionCard
            heading={getLocalizedText("system.entity.tasks")}
            icon="checklist"
            count={tasksToExecuteCount}
            countDescription={getLocalizedText(
              "dashboard.card.tasks.to_be_executed",
            )}
            url={`/tasks?initialType=${EInitialType.ToExecute}`}
            actions={[
              {
                content: getLocalizedText("dashboard.card.tasks.new"),
                url: "/tasks/add",
              },
            ]}
          />
          {hasKeypointRepairFeature ? (
            <FeatureSpecificQuickActionCard
              featureEnabled={hasKeypointRepairFeature}
              heading={getLocalizedText("dashboard.card.repairs")}
              subheading={getLocalizedText("dashboard.card.repairs.subheading")}
              emptySubheading={getLocalizedText(
                "dashboard.card.repairs.no_requests",
              )}
              countDescription={getLocalizedText(
                "dashboard.card.repairs.count_description",
              )}
              emptyText={getLocalizedText("dashboard.card.tasks.repairs.empty")}
              count={tasksKeyPointRepair}
              icon="logoKeypoint"
              iconColor="blue"
              url={`/tasks?initialType=${EInitialType.Keypoint}`}
              emptyStateUrl="/tasks/add?keypoint=1"
              actions={[
                {
                  content: getLocalizedText("dashboard.card.tasks.new_repair"),
                  url: "/tasks/add?keypoint=1",
                },
              ]}
            />
          ) : null}
        </QuickActionCardCollection>
      </Card>

      <Card hasBorder={true} heading={getLocalizedText("system.contracts")}>
        <QuickActionCardCollection>
          <QuickActionCard
            heading={t.followUpIndexableContractsStageLabel({
              extra: { key: "indexable" },
            })}
            icon="currencyEuroIncrease"
            count={indexableContractsCount}
            url="follow-up/indexable-contracts?page=1&stage=indexable"
          />
          <QuickActionCard
            heading={getLocalizedText(
              "dashboard.card.ending_contracts.title.end_date",
            )}
            icon="businessContractBreak"
            count={endDateWithin1MonthCount}
            countDescription={getLocalizedText(
              "dashboard.card.ending_contracts.info",
            )}
            url={getStageEndDateUrlWithQuery(EEndDateWithinMonths.Within1Month)}
          />
          <QuickActionCard
            heading={getLocalizedText("dashboard.card.cancellable_contracts")}
            icon="doorOut"
            count={deadlineWithin1MonthCount}
            countDescription={getLocalizedText(
              "dashboard.card.cancellable_contracts.info",
            )}
            url={getStageRenewalUrlWithQuery(
              ERenewalDateWithinMonths.Within1Month,
            )}
          />
        </QuickActionCardCollection>
      </Card>

      {internalModeEnabled && hasPaymentV2ForBroker && (
        <Card
          hasBorder={true}
          heading={getLocalizedText("system.contracts") + "(V2)"}
        >
          <QuickActionCardCollection>
            <QuickActionCard
              heading={t.followUpIndexableContractsStageLabel({
                extra: { key: "indexable" },
              })}
              icon="currencyEuroIncrease"
              count={indexableContractsCount}
              url="follow-up/indexable-contracts-v2?page=1&stage=indexable"
            />
            <QuickActionCard
              heading={getLocalizedText(
                "dashboard.card.ending_contracts.title.end_date",
              )}
              icon="businessContractBreak"
              count={endDateWithin1MonthCount}
              countDescription={getLocalizedText(
                "dashboard.card.ending_contracts.info",
              )}
              url={getStageEndDateUrlWithQueryV2(
                EEndDateWithinMonths.Within1Month,
              )}
            />
          </QuickActionCardCollection>
        </Card>
      )}
    </>
  );
};
