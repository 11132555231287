var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from "react";
var PaymentOrderFetch = /** @class */ (function (_super) {
    __extends(PaymentOrderFetch, _super);
    // Initialize
    function PaymentOrderFetch(props) {
        var _this = _super.call(this, props) || this;
        // Data
        _this.fetchData = function () {
            _this.props.getPaymentOrder({
                paymentOrderId: _this.props.id,
            });
        };
        _this.fetchData();
        return _this;
    }
    // Render
    PaymentOrderFetch.prototype.render = function () {
        var _a = this.props, isFetchingPaymentOrder = _a.isFetchingPaymentOrder, fetchErrorPaymentOrder = _a.fetchErrorPaymentOrder, paymentOrder = _a.paymentOrder, renderLoading = _a.renderLoading, renderError = _a.renderError, renderNotLoaded = _a.renderNotLoaded, renderPaymentOrder = _a.renderPaymentOrder;
        if (paymentOrder) {
            return renderPaymentOrder ? renderPaymentOrder(paymentOrder) : null;
        }
        if (isFetchingPaymentOrder) {
            return renderLoading ? renderLoading() : null;
        }
        if (fetchErrorPaymentOrder) {
            return renderError
                ? renderError(this.fetchData, fetchErrorPaymentOrder)
                : null;
        }
        return renderNotLoaded ? renderNotLoaded() : null;
    };
    return PaymentOrderFetch;
}(React.Component));
// eslint-disable-next-line import/no-default-export
export default PaymentOrderFetch;
