import merge from "lodash/merge";
import { createReducer } from "redux-act";
import { getPagedDefaultState, registerPagedActions, } from "../generic/paged.reducer";
import CONFIG from "./registrationDocument.config";
export var REDUCER_KEY_REGISTRATION_DOCUMENT = CONFIG.REDUCER_KEY;
var getDefaultState = function () {
    return merge({}, {}, getPagedDefaultState());
};
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
registerPagedActions({
    entityName: CONFIG.ENTITY_NAME,
    reducer: reducer,
});
// eslint-disable-next-line import/no-default-export
export default reducer;
