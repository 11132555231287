import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as expertInquirySelectors from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.selectors";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { EContractFetchType } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  Loading,
  Modal,
  MultiStepForm,
  Select,
  SuccessModal,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import isEmpty from "ramda/es/isEmpty";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import orderLocationDescriptionSchemas from "../../forms/orderLocationDescription";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "../../forms/orderLocationDescription/schema.orderLocationDescription.actions";
import { ts } from "../../services";
import {
  filterRunningContracts,
  filterUpcomingContracts,
  genInitialModal,
} from "./LocationDescriptionModal.utils";

interface ILocationDescriptionModalProps {
  property: IProperty;
  newContract?: IContract;
  oldContract?: IContract;
  onClose: (task?: any) => void;
}

type TModals = "intro" | "partnerPicker" | "form" | "outro";

const formId = generateFormId();
const INLINE_FETCH_LIMIT = 20;

export const LocationDescriptionModal: FC<ILocationDescriptionModalProps> = ({
  property,
  newContract,
  oldContract,
  onClose,
}) => {
  const partnerships = useSelector((state: IRootStore) =>
    expertInquirySelectors.partnerships(state),
  );

  const [selectedPartnershipAccountId, setSelectedPartnershipAccountId] =
    useState<number | undefined>();

  const activeContractsFilter = contractUtils.getFilterForProperty(
    property.id,
    EContractFetchType.Active,
  );
  const { items: activeContracts, isFetching: isFetchingContracts } =
    contractHooks.useGetAll({
      query: {
        filter: { ...activeContractsFilter, limit: INLINE_FETCH_LIMIT },
      },
    });

  const createdTaskId = useSelector(
    (state: IRootStore) =>
      state.expertInquiry.requestedLocationDescriptionTaskId,
  );
  const [currentModal, setCurrentModal] = useState<TModals | undefined>(
    genInitialModal(partnerships),
  );
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (!partnerships) {
      return;
    }

    setSelectedPartnershipAccountId(partnerships[0]?.partnerAccountId);
  }, [partnerships]);

  if (!activeContracts) {
    return null;
  }

  const selectedPartnership =
    partnerships?.find(
      x => x.partnerAccountId === selectedPartnershipAccountId,
    ) || partnerships?.[0];

  // Event handlers
  const handlePriceListClick = () => {
    utils.general.openWindow(ts.locationDescriptionPricesLink());
  };

  const handleSuccess = () => {
    setCurrentModal("outro");
  };

  const getPossibleUpcomingContract = () => {
    if (!newContract) {
      return newContract;
    }

    const upcomingContracts = filterUpcomingContracts(activeContracts);
    if (!isEmpty(upcomingContracts)) {
      return upcomingContracts[0];
    }

    return;
  };
  const getPossibleEndingContract = () => {
    if (!oldContract) {
      return oldContract;
    }

    const runningContracts = filterRunningContracts(activeContracts);
    if (!isEmpty(runningContracts)) {
      return runningContracts[0];
    }

    return;
  };

  // Render functions
  const renderPartnerPickerModal = () => {
    return (
      <Modal
        width="medium"
        onClose={handleClose}
        heading={ts.locationDescriptionModalHeading()}
        actions={[
          {
            content: ts.system("cancel"),
            onClick: onClose,
            appearance: "outline",
          },
          {
            content: ts.system("next"),
            onClick: () => {
              if (selectedPartnership?.locationDescriptorKey === "MEXP") {
                setCurrentModal("intro");
                return;
              }

              setCurrentModal("form");
            },
            appearance: "primary",
          },
        ]}
      >
        <Select
          label={getLocalizedText(
            "location_description.modal.field.select_partner",
          )}
          id="select_partner"
          onChange={(option: { label: string; value: number }) => {
            setSelectedPartnershipAccountId(option.value);
          }}
          value={selectedPartnershipAccountId || -1}
          options={(partnerships || []).map(partnership => ({
            value: partnership.partnerAccountId,
            label: getName(partnership.partnerAccount),
          }))}
        />
      </Modal>
    );
  };

  const renderMexpIntroModal = () => {
    return (
      <Modal
        width="medium"
        onClose={handleClose}
        heading={ts.locationDescriptionModalHeading()}
        actions={[
          {
            content: ts.system("cancel"),
            onClick: onClose,
            appearance: "outline",
          },
          {
            content: ts.system("next"),
            onClick: () => setCurrentModal("form"),
            appearance: "primary",
          },
        ]}
      >
        {isFetchingContracts ? (
          <Loading asDots />
        ) : (
          <>
            {ts.locationDescriptionIntroModalContent()}
            <Button onClick={handlePriceListClick} appearance="link">
              {ts.locationDescriptionIntroModalAction()}
            </Button>
          </>
        )}
      </Modal>
    );
  };

  const renderFormModal = () => {
    if (!activeContracts) {
      onClose();
      return null;
    }

    return (
      <MultiStepForm
        formId={`index-contact-${formId}`}
        schemas={orderLocationDescriptionSchemas({
          partnership: selectedPartnership,
          property,
          newContract: getPossibleUpcomingContract(),
          oldContract: getPossibleEndingContract(),
        })}
        asModal={true}
        withAside={false}
        // onSuccess={handleIndexSuccess}
        modalProps={{
          onClose,
          heading: ts.locationDescriptionModalHeading(),
          width: "medium",
        }}
        modalActions={[
          {
            content: ts.system("previous"),
            appearance: "outline",
            onClick: () => {
              setCurrentModal(genInitialModal(partnerships));
            },
          },
        ]}
        isPerformingSubmitActionSelector={isPerformingSubmitActionSelector()}
        performingSubmitActionResultSelector={performingSubmitActionResultSelector()}
        performingSubmitActionErrorSelector={performingSubmitActionErrorSelector()}
        submitActionCreator={submitActionCreator({
          partnership: selectedPartnership,
          property,
          newContract: getPossibleUpcomingContract(),
          oldContract: getPossibleEndingContract(),
        })}
        onSuccess={handleSuccess}
        submitLabel={ts.locationDescriptionFormModalAction()}
      />
    );
  };

  const renderOutroModal = () => {
    return (
      <SuccessModal
        onClose={handleClose}
        actions={
          [
            createdTaskId && {
              content: ts.locationDescriptionModalViewTaskAction(),
              url: `/tasks/${createdTaskId}`,
              appearance: "outline",
            },
            {
              content: ts.system("close"),
              onClick: onClose,
              appearance: "primary",
            },
          ].filter(Boolean) as any
        }
      >
        {ts.locationDescriptionOutroModalContent({
          partner: selectedPartnership?.partnerAccount
            ? getName(selectedPartnership.partnerAccount)
            : "MEXP",
        })}
      </SuccessModal>
    );
  };

  switch (currentModal) {
    case "partnerPicker":
      return renderPartnerPickerModal();

    case "form":
      return renderFormModal();

    case "outro":
      return renderOutroModal();

    case "intro":
      return renderMexpIntroModal();

    default:
      return null;
  }
};
