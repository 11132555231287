import { EIntakeStep } from "@rentiohq/shared-frontend/dist/types/application.types";
import { EIntakeType } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { getStepsForIntakeType } from "utils/application.utils";

export const getSteps = (intakeType: EIntakeType) => {
  return getStepsForIntakeType(intakeType).reduce(
    (allSteps, steps: EIntakeStep[]) => {
      const eligibleSteps = steps.filter(
        step => step !== EIntakeStep.notEligible,
      );
      return [...allSteps, eligibleSteps];
    },
    [] as EIntakeStep[][],
  );
};
