import addContactSchemas from "@rentiohq/shared-frontend/dist/forms/addContact";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitBrokerActionResultSelector,
  submitBrokerContactActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.actions";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { MultiStepForm } from "@rentiohq/web-shared/dist/components";
import React from "react";

interface IBrokerContact extends IContact {
  ibanId?: number;
}

interface IBrokerContactFormProps {
  brokerId: number;
  type: EContactCustomId.Legal | EContactCustomId.Financial;
  contact?: IBrokerContact;
}

const formId = generateFormId();
export const BrokerContactForm: React.FC<IBrokerContactFormProps> = ({
  brokerId,
  type,
  contact,
}) => {
  const contactSchemas = addContactSchemas({
    isBroker: true,
    isCompany: false,
    contact,
    customContactId: type,
    showSendInvite: false,
  });

  return (
    <MultiStepForm
      formId={`contact-broker-${formId}-${type}`}
      schemas={contactSchemas}
      withSummary={false}
      withAside={false}
      isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
        contact ? contact.id : undefined,
      )}
      performingSubmitActionResultSelector={performingSubmitBrokerActionResultSelector(
        brokerId,
        type,
      )}
      performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
        contact ? contact.id : undefined,
      )}
      submitActionCreator={submitBrokerContactActionCreator(
        brokerId,
        type,
        contact ? contact.id : undefined,
      )}
    />
  );
};
