import { Box } from "@rebass/grid";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as cardActions from "@rentiohq/shared-frontend/dist/redux/card/card.actions";
import {
  ECardButtonActionType,
  ICard,
  ICardButton,
} from "@rentiohq/shared-frontend/dist/redux/card/card.types";
import { Button, ESpacings } from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StringParam } from "serialize-query-params";
import { openDeeplink } from "./deeplink.utils";
interface IProps {
  card: ICard;
  cardButton: ICardButton;
  actionIndex: number;
  onDone: () => void;
}

const Card: React.FC<IProps> = (props: IProps) => {
  const { card, cardButton, actionIndex, onDone } = props;

  const [, setQueryParamValue] = useQueryParams({ documentId: StringParam });

  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch();

  // Event handlers
  const handleClickButton = () => {
    switch (cardButton.action.type) {
      case ECardButtonActionType.Callback:
        dispatch(
          cardActions.cardActionCallback.actions.start({
            cardId: card.id,
            actionIndex,
            action: cardButton.action,
          }),
        );
        break;

      case ECardButtonActionType.Deeplink:
        if (cardButton.action.url) {
          openDeeplink({
            url: cardButton.action.url,
            navigate,
            setQueryParamValue,
          });
          onDone();
        }
        break;

      case ECardButtonActionType.External:
        if (cardButton.action.url) {
          window.open(cardButton.action.url, "_blank");
          onDone();
        }
        break;

      case ECardButtonActionType.Handler:
      case ECardButtonActionType.Ignore:
        break;
    }

    if (
      card.autoclose &&
      cardButton.action.type !== ECardButtonActionType.Callback
    ) {
      dispatch(cardActions.closeCard.actions.start({ cardId: card.id }));
    }
  };

  const handleClickButtonWithTimeout = () => {
    setTimeout(() => {
      handleClickButton();
    }, 0);
  };

  // Render
  return (
    <Box mr={ESpacings.tight}>
      <Button appearance="link" onClick={handleClickButtonWithTimeout}>
        {cardButton.label}
      </Button>
      {/* 
      // TODO: @dries add loading indicator */}
      {/* {isLoading && <Loading asDots={true} />} */}
    </Box>
  );
};

// eslint-disable-next-line import/no-default-export
export default Card;
