import { EIntakeStep } from "@rentiohq/shared-frontend/dist/types/application.types";
import { EIntakeType } from "@rentiohq/shared-frontend/dist/types/broker.types";

export const getStepsForIntakeType = (
  intakeType: EIntakeType,
): EIntakeStep[][] => {
  switch (intakeType) {
    case EIntakeType.TwoPhases:
      return [
        [EIntakeStep.candidateVisitor, EIntakeStep.visitor],
        [EIntakeStep.candidateTenant, EIntakeStep.tenant],
        [EIntakeStep.notEligible],
      ];
    case EIntakeType.TwoPhasesWithVisit:
      return [
        [
          EIntakeStep.candidateVisitor,
          EIntakeStep.waitingList,
          EIntakeStep.planAsVisitor,
          EIntakeStep.visitor,
        ],
        [EIntakeStep.candidateTenant, EIntakeStep.tenant],
        [EIntakeStep.notEligible],
      ];
    default:
      return [
        [EIntakeStep.candidateTenant],
        [EIntakeStep.tenant],
        [EIntakeStep.notEligible],
      ];
  }
};

export const getPhaseForStep = (intakeType: EIntakeType, step: EIntakeStep) => {
  const steps = getStepsForIntakeType(intakeType);
  return steps.findIndex(steps => steps.includes(step));
};

export interface IFlowItem {
  id: EIntakeStep;
  allowedSteps: EIntakeStep[];
}

export const getFlows = (
  intakeType: EIntakeType,
): {
  visitorSteps?: IFlowItem[];
  candidateSteps: IFlowItem[];
  notEligibleSteps: IFlowItem[];
} => {
  const flows = {
    [EIntakeType.OnePhase]: {
      candidateSteps: [
        {
          id: EIntakeStep.candidateTenant,
          allowedSteps: [EIntakeStep.tenant, EIntakeStep.notEligible],
        },
        { id: EIntakeStep.tenant, allowedSteps: [EIntakeStep.notEligible] },
      ],
      notEligibleSteps: [
        {
          id: EIntakeStep.notEligible,
          allowedSteps: [
            EIntakeStep.candidateTenant,
            EIntakeStep.candidateVisitor,
            EIntakeStep.tenant,
          ],
        },
      ],
    },
    [EIntakeType.TwoPhases]: {
      visitorSteps: [
        {
          id: EIntakeStep.candidateVisitor,
          allowedSteps: [
            EIntakeStep.visitor,
            EIntakeStep.candidateTenant,
            EIntakeStep.notEligible,
          ],
        },
        {
          id: EIntakeStep.visitor,
          allowedSteps: [
            EIntakeStep.candidateVisitor,
            EIntakeStep.candidateTenant,
            EIntakeStep.notEligible,
          ],
        },
      ],
      candidateSteps: [
        {
          id: EIntakeStep.candidateTenant,
          allowedSteps: [EIntakeStep.tenant, EIntakeStep.notEligible],
        },
        { id: EIntakeStep.tenant, allowedSteps: [EIntakeStep.notEligible] },
      ],
      notEligibleSteps: [
        {
          id: EIntakeStep.notEligible,
          allowedSteps: [EIntakeStep.candidateVisitor, EIntakeStep.tenant],
        },
      ],
    },
    [EIntakeType.TwoPhasesWithVisit]: {
      visitorSteps: [
        {
          id: EIntakeStep.candidateVisitor,
          allowedSteps: [
            EIntakeStep.visitor,
            EIntakeStep.waitingList,
            EIntakeStep.planAsVisitor,
            EIntakeStep.candidateTenant,
            EIntakeStep.notEligible,
          ],
        },
        {
          id: EIntakeStep.waitingList,
          allowedSteps: [
            EIntakeStep.visitor,
            EIntakeStep.waitingList,
            EIntakeStep.planAsVisitor,
            EIntakeStep.candidateTenant,
            EIntakeStep.notEligible,
          ],
        },
        {
          id: EIntakeStep.planAsVisitor,
          allowedSteps: [
            EIntakeStep.candidateVisitor,
            EIntakeStep.waitingList,
            EIntakeStep.visitor,
            EIntakeStep.candidateTenant,
            EIntakeStep.notEligible,
          ],
        },
        {
          id: EIntakeStep.visitor,
          allowedSteps: [
            EIntakeStep.candidateVisitor,
            EIntakeStep.waitingList,
            EIntakeStep.planAsVisitor,
            EIntakeStep.candidateTenant,
            EIntakeStep.notEligible,
          ],
        },
      ],
      candidateSteps: [
        {
          id: EIntakeStep.candidateTenant,
          allowedSteps: [EIntakeStep.tenant, EIntakeStep.notEligible],
        },
        { id: EIntakeStep.tenant, allowedSteps: [EIntakeStep.notEligible] },
      ],
      notEligibleSteps: [
        {
          id: EIntakeStep.notEligible,
          allowedSteps: [
            EIntakeStep.candidateVisitor,
            EIntakeStep.waitingList,
            EIntakeStep.tenant,
          ],
        },
      ],
    },
  };

  return flows[intakeType];
};
