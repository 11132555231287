import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import "draft-js/dist/Draft.css";
import React from "react";
import { useToggle } from "../../../../../../hooks/useToggle";
import { removeEntity } from "../../../../utils/draftjs.utils";
import VariableModal from "../../components/VariableModal";
import { EditorStateContext } from "../../contexts/EditorState.context";
import { FieldsContext } from "../../contexts/Fields.context";
import { ReadOnlyContext } from "../../contexts/ReadOnly.context";
var FieldRenderer = function (props) {
    var _a, _b;
    var entityKey = props.entityKey, contentState = props.contentState;
    var _c = React.useContext(FieldsContext), fields = _c.fields, removeVariable = _c.removeVariable;
    var _d = React.useContext(EditorStateContext), editorState = _d.editorState, setEditorState = _d.setEditorState;
    var setIsShowingModal = React.useContext(ReadOnlyContext).setIsShowingModal;
    var entity = contentState.getEntity(entityKey);
    var data = entity.getData();
    var variableId = data.id;
    var variable = (_a = fields.variables) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === variableId; });
    var groupId = variable === null || variable === void 0 ? void 0 : variable.groupId;
    var group = (_b = fields.groups) === null || _b === void 0 ? void 0 : _b.find(function (x) { return x.id === groupId; });
    // State
    var _e = useToggle(), showModal = _e[0], toggleModalOn = _e[2], toggleModalOff = _e[3];
    // Event handlers
    var handleShowModal = function () {
        setIsShowingModal(true);
        toggleModalOn();
    };
    var handleCloseModal = function () {
        setIsShowingModal(false);
        toggleModalOff();
    };
    var handleRemoveInstance = function () {
        if (!editorState || !variable) {
            return;
        }
        var newEditorState = removeEntity({ editorState: editorState, entityKey: entityKey });
        if (!newEditorState) {
            return;
        }
        setEditorState(newEditorState);
        removeVariable(variable.id);
        handleCloseModal();
        showAlert({
            type: "info",
            message: getLocalizedText("Variable removed"),
        });
    };
    var handleRemoveInstanceWithConfirm = function () {
        confirm({
            title: getLocalizedText("editor.variable_instance.remove.confirm.title"),
            primaryActions: [
                {
                    title: getLocalizedText("system.remove"),
                    onPress: handleRemoveInstance,
                },
            ],
        });
    };
    // Render
    var canRemove = !fields.loops.find(function (x) { return x.variableId === variableId; });
    return (_jsxs("span", { contentEditable: false, 
        // @ts-ignore
        readOnly: true, children: [_jsx("button", { onClick: handleShowModal, 
                // onMouseEnter={togglePopover}
                // onMouseLeave={togglePopover}
                style: {
                    paddingLeft: 2,
                    paddingRight: 2,
                    backgroundColor: "yellow",
                }, children: variable && group
                    ? "".concat(getLocalizedText(group.name), " - ").concat(getLocalizedText(variable.name))
                    : "Error loading variable" }), showModal && (_jsx(VariableModal, { variableId: variableId, onClose: handleCloseModal, onRemoveInstance: canRemove ? handleRemoveInstanceWithConfirm : undefined }))] }));
};
// eslint-disable-next-line import/no-default-export
export default FieldRenderer;
