import { jsx as _jsx } from "react/jsx-runtime";
import { StyledTag } from "./Tag.styles";
export var ETagVariant;
(function (ETagVariant) {
    ETagVariant["Success"] = "success";
    ETagVariant["Error"] = "error";
    ETagVariant["Warning"] = "warning";
    ETagVariant["Default"] = "default";
})(ETagVariant || (ETagVariant = {}));
export var Tag = function (_a) {
    var children = _a.children, _b = _a.variant, variant = _b === void 0 ? ETagVariant.Success : _b;
    var getBgColor = function () {
        switch (variant) {
            case ETagVariant.Success:
                return "#c2e6dc";
            case ETagVariant.Warning:
                return "#FFC107";
            case ETagVariant.Error:
                return "#c2e6dc";
            default:
                return "#eef2f6";
        }
    };
    var getColor = function () {
        switch (variant) {
            case ETagVariant.Success:
                return "#697586";
            case ETagVariant.Warning:
                return "#fff";
            case ETagVariant.Error:
                return "#697586";
            default:
                return "#697586";
        }
    };
    return (_jsx(StyledTag, { background: getBgColor(), color: getColor(), children: children }));
};
