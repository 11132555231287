import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as documentUtils from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.utils";
import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EFontWeights } from "@rentiohq/web-shared-next/dist/themes/types";
import {
  Box,
  ETagVariant,
  IMenuAction,
  Menu,
  Stack,
  Tag,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import utils from "@rentiohq/web-shared/dist/utils";

interface IProps {
  document: IDocument;
  actions: IMenuAction[];
}

export const DocumentRow = ({ document, actions }: IProps) => {
  const { user } = authHooks.useSelf();

  const { filename, categoryId, members, createdAt } = document;
  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      p={2}
    >
      <Box>
        <Stack flexDirection="row" alignItems="center" gap={1} mb={1}>
          <Typography variant="caption" fontWeight={EFontWeights.Regular}>
            {utils.date.format(createdAt)}
          </Typography>
          <Tag variant={ETagVariant.Default}>
            {documentUtils.getDocumentCategoryName(categoryId)}
          </Tag>
        </Stack>
        <Typography variant="subtitle2" fontWeight={EFontWeights.Bold}>
          {filename}
        </Typography>
        <Typography variant="subtitle2" fontWeight={EFontWeights.Regular}>
          {getLocalizedText("documents.members.header.title")}:{" "}
          {members.length > 1
            ? members
                .map((member: any) => {
                  return (
                    member.account.id !== user?.activeAccountId &&
                    getName(member.account)
                  );
                })
                .join(", ")
            : "-"}
        </Typography>
      </Box>
      {Boolean(actions?.length) && <Menu actions={actions || []} />}
    </Stack>
  );
};
