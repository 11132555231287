import { connect } from "react-redux";
import { FormSummaryItem } from "./FormSummaryItem";
var mapStateToProps = function (state, _a) {
    var valueSelector = _a.valueSelector, rawValue = _a.rawValue, formData = _a.formData;
    return ({
        selectedValue: valueSelector
            ? valueSelector(state, rawValue, formData)
            : undefined,
    });
};
export var FormSummaryItemContainer = connect(mapStateToProps)(FormSummaryItem);
