import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  Loading,
  Pagination,
  ResourceList,
} from "@rentiohq/web-shared/dist/components";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { useParams } from "react-router-dom";
import PropertyListItemGeneral from "scenes/Properties/components/PropertyListItemGeneral";
import PropertyListItemGeneralHeader from "scenes/Properties/components/PropertyListItemGeneral/PropertyListItemGeneral.header";
import { ESortOrder } from "scenes/Properties/components/SortableResourceListHeader";
import usePreference from "scenes/Settings/hooks/usePreference";

const PREFERENCE_KEY_PROPERTIES_IN_GROUP_ADDRESS_SORT_ORDER =
  "PROPERTIES_GROUP_ADDRESS_ORDER";
const PREFERENCE_KEY_PROPERTY_PAGE_NUMBER = "PROPERTY_PAGE_NUMBER";
const PAGE_PROPERTIES_LIMIT = 20;

export const PropertiesInGroup = () => {
  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const [page = 1, setPage] = usePreference<number>({
    preferenceKey: `${PREFERENCE_KEY_PROPERTY_PAGE_NUMBER}-${propertyId}`,
    preferencePersistScope: EPreferencePersistScope.InMemory,
  });

  const [addressOrder = undefined, setAddressOrder] = usePreference<
    ESortOrder | undefined
  >({
    preferenceKey: PREFERENCE_KEY_PROPERTIES_IN_GROUP_ADDRESS_SORT_ORDER,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const {
    items: properties,
    isFetching,
    totalPages = 1,
  } = propertyHooks.usePaged({
    query: {
      page,
      limit: PAGE_PROPERTIES_LIMIT,
      filter: {
        archivedAt: { is: null },
        parentId: { eq: propertyId },
      },
    },
  });

  // Cleanup page
  const renderPaging = () => {
    if (totalPages <= 1) {
      return;
    }

    return (
      <Pagination
        initialPage={page - 1}
        pageCount={totalPages}
        onPageChange={handlePageClick}
      />
    );
  };

  const handlePageClick = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  // Render
  const renderPropertyListItem = (propertyInGroup: IProperty) => {
    return (
      <PropertyListItemGeneral
        property={propertyInGroup}
        selectedParentId={propertyId}
        onSetParentId={() => {}}
      />
    );
  };

  return (
    <Card
      heading={getLocalizedText("property.group_info.title")}
      primaryActions={[
        {
          content: getLocalizedText("property.overview.primary_CTA"),
          url: `/properties/add?parentId=${propertyId}`,
        },
      ]}
    >
      {isFetching && !properties && <Loading />}

      {properties && (
        <>
          {properties.length > 0 && (
            <PropertyListItemGeneralHeader
              addressOnChangeOrder={setAddressOrder}
              addressOrder={addressOrder}
            />
          )}
          <ResourceList
            items={properties}
            renderItem={renderPropertyListItem}
            noResultsHeading={getLocalizedText(
              "property.group_info.empty.title",
            )}
            noResultsContent={getLocalizedText(
              "property.group_info.empty.content",
            )}
          />
        </>
      )}

      {renderPaging()}
    </Card>
  );
};

// eslint-disable-next-line import/no-default-export
export default PropertiesInGroup;
