var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getValue } from "../..//redux/form/form.utils";
import { ERentioFormField } from "../../components/components/Form";
import { ETaskLocation, ETaskType } from "../../types/task.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createTask.types";
import { getMappedCategories } from "./schema.createTask.utils";
var getCategoryTaskTypeKey = function (taskType) {
    return "".concat(EField.Category, "-").concat(taskType);
};
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a, _b;
        var requiredFields = [EField.Title];
        if (extraData.keypoint) {
            requiredFields.push(EField.Type, EField.PropertySelect, EField.Description);
        }
        // Kind of a hack. There's no way to change the options based on another input
        // So we generate all of the options and hide or show the right input
        // based on the selected other input.
        var categoriesForTypeFields = Object.values(ETaskType).reduce(function (all, taskType) {
            var key = getCategoryTaskTypeKey(taskType);
            var mappedCategories = getMappedCategories(taskType);
            var field = {
                default: getValue(EField.Category, initialValues, defaultValues),
                //@ts-ignore
                options: mappedCategories.map(function (category) { return ({
                    value: getLocalizedText("task.category.".concat(category.toLowerCase())),
                    id: category,
                }); }),
                showIf: function (formData) {
                    return formData[EField.Type] === taskType && mappedCategories.length > 0;
                },
            };
            all[key] = field;
            return all;
        }, {});
        return {
            required: requiredFields,
            properties: __assign(__assign((_a = {}, _a[EField.RecurrenceSelection] = {
                default: getValue(EField.RecurrenceSelection, initialValues, defaultValues),
                // @ts-ignore
                options: [
                    {
                        id: "ONCE",
                        value: getLocalizedText("task.recurring_type.once"),
                    },
                    {
                        id: "RECURRING",
                        value: getLocalizedText("task.recurring_type.recurring"),
                    },
                ],
            }, _a[EField.Type] = {
                default: getValue(EField.Type, initialValues, defaultValues),
                //@ts-ignore
                options: Object.values(ETaskType).map(function (type) { return ({
                    value: getLocalizedText("task.type.".concat(type.toLowerCase())),
                    id: type,
                }); }),
            }, _a), categoriesForTypeFields), (_b = {}, _b[EField.Title] = {
                default: getValue(EField.Title, initialValues, defaultValues),
                maxLength: 64,
            }, _b[EField.Description] = {
                default: getValue(EField.Description, initialValues, defaultValues),
            }, _b[EField.PropertySelect] = {
                default: getValue(EField.PropertySelect, initialValues, defaultValues),
                // @ts-ignore
                showIf: function (_) { return !extraData.isGroupActions; },
            }, _b[EField.Location] = {
                default: getValue(EField.Location, initialValues, defaultValues),
                //@ts-ignore
                options: Object.values(ETaskLocation).map(function (location) { return ({
                    value: getLocalizedText("task.location.".concat(location.toLowerCase())),
                    id: location,
                }); }),
            }, _b)),
        };
    };
};
var getRows = function (extraData, categoryFields) {
    var rows = [
        { col: { width: [1, 1], children: [EField.RecurrenceSelection] } },
        {
            col: { width: [1, 1 / 2], children: [EField.Type] },
        },
        {
            col: {
                width: [1, 1 / 2],
                children: Object.keys(categoryFields),
            },
        },
        { col: { width: [1, 1], children: [EField.Title] } },
        { col: { width: [1, 1], children: [EField.Description] } },
    ];
    if (!extraData.isGroupActions) {
        rows.push({ col: { width: [1, 1], children: [EField.PropertySelect] } });
    }
    rows.push({ col: { width: [1, 1], children: [EField.Location] } });
    return rows;
};
export var uiSchema = function (extraData) {
    var _a, _b;
    // Kind of a hack. There's no way to change the options based on another input
    // So we generate all of the options and hide or show the right input
    // based on the selected other input.
    var categoryFields = Object.values(ETaskType).reduce(function (all, taskType) {
        var key = getCategoryTaskTypeKey(taskType);
        all[key] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("task.category"),
            formatSummary: function (input) {
                return input ? getLocalizedText("task.category.".concat(input.toLowerCase())) : "";
            },
            optional: true,
        };
        return all;
    }, {});
    return __assign(__assign((_a = { "rentio:navigationTitle": getLocalizedText(extraData.taskId ? "tasks.edit" : "task.new"), "rentio:title": getLocalizedText("task.information.section_header"), "ui:field": ERentioFormField.Grid, grid: {
                row: getRows(extraData, categoryFields),
            } }, _a[EField.RecurrenceSelection] = {
        title: getLocalizedText("task.frequency"),
        "ui:field": ERentioFormField.SegmentedControl,
        formatSummary: function (formData) {
            return getLocalizedText("task.recurring_type.".concat(formData === null || formData === void 0 ? void 0 : formData.toLowerCase()));
        },
        isDisabled: function (formData) { return !!extraData.taskId || extraData.keypoint; },
    }, _a[EField.Type] = {
        "ui:field": ERentioFormField.DropdownSelect,
        title: getLocalizedText("task.type"),
        formatSummary: function (input) {
            return input ? getLocalizedText("task.type.".concat(input.toLowerCase())) : "";
        },
        optional: !extraData.keypoint,
        isDisabled: function () { return extraData.keypoint; },
    }, _a), categoryFields), (_b = {}, _b[EField.Title] = {
        "ui:field": ERentioFormField.Text,
        title: getLocalizedText("task.subject.label"),
        placeholder: getLocalizedText("task.subject.placeholder"),
        subtitle: getLocalizedText("task.subject.description"),
        autoFocus: true,
    }, _b[EField.Description] = {
        "ui:field": ERentioFormField.Text,
        title: getLocalizedText("system.description"),
        placeholder: getLocalizedText("system.description.placeholder"),
        optional: !extraData.keypoint,
    }, _b[EField.PropertySelect] = {
        "ui:field": ERentioFormField.PropertySelectInline,
        title: getLocalizedText("choose.property"),
        selectPropertyFromListCtaTitle: undefined,
        optional: !extraData.keypoint,
        valueSelector: function (state, propertyId) {
            var _a;
            if (propertyId) {
                var property = (_a = state.PROPERTY_V2.detail[propertyId]) === null || _a === void 0 ? void 0 : _a.entity;
                if (property) {
                    return property.name;
                }
                return getLocalizedText("summary.view.property");
            }
            return getLocalizedText("property.no.summary");
        },
    }, _b[EField.Location] = {
        "ui:field": ERentioFormField.DropdownSelect,
        title: getLocalizedText("task.location"),
        formatSummary: function (input) {
            return input ? getLocalizedText("task.location.".concat(input.toLowerCase())) : "";
        },
        optional: true,
    }, _b));
};
