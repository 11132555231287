import { useTheme } from "@rentiohq/web-shared-next/dist/themes";

export const ContractMetaStyles = () => {
  const theme = useTheme();

  return {
    content: {
      propertyTitle: {
        color: theme.palette.success.main,
      },
    },
  };
};
