import component from "./VariableRenderer";
import strategy from "./VariableRenderer.strategy";
export { VARIABLE_RENDERER_TYPE } from "./VariableRenderer.strategy";
export * from "./VariableRenderer.types";
var decorator = {
    strategy: strategy,
    component: component,
};
// eslint-disable-next-line import/no-default-export
export default decorator;
