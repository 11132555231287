import { createPaymentOrder, updatePaymentOrder, } from "../../redux/payment/payment.actions";
import * as paymentOrderActions from "../../reduxV2/paymentOrder/paymentOrder.actions";
import { getCommissionPaymentOrder, getCommissionPaymentOrderV2, getCommonCostsPaymentOrder, getCommonCostsPaymentOrderV2, getManagementFeePaymentOrder, getManagementFeePaymentOrderV2, getNonCommonCostsPaymentOrder, getNonCommonCostsPaymentOrderV2, getPaymentGroup, getPaymentOrder, getPaymentOrderV2, } from "./schema.createPayment.actions.utils";
import { getRelatedContract } from "./schema.createPayment.utils";
export var isPerformingSubmitActionSelector = function (paymentOrder, internalModeEnabled) {
    return function (state) {
        var _a, _b;
        if (paymentOrder) {
            var updateStateUpdating = internalModeEnabled
                ? (_a = state.PAYMENT_ORDERS_V2.update[paymentOrder.id]) === null || _a === void 0 ? void 0 : _a.pending
                : (_b = state.payment.updatePaymentOrders[paymentOrder.id]) === null || _b === void 0 ? void 0 : _b.isUpdatingPaymentOrder;
            if (updateStateUpdating) {
                return updateStateUpdating;
            }
            return false;
        }
        return internalModeEnabled
            ? state.PAYMENT_ORDERS_V2.createPaymentOrders.pending || false
            : !!state.payment.isCreatingPaymentOrder;
    };
};
export var performingSubmitActionErrorSelector = function (paymentOrder, internalModeEnabled) {
    return function (state) {
        var _a, _b;
        if (paymentOrder) {
            var updateStateError = internalModeEnabled
                ? (_a = state.PAYMENT_ORDERS_V2.update[paymentOrder.id]) === null || _a === void 0 ? void 0 : _a.error
                : (_b = state.payment.updatePaymentOrders[paymentOrder.id]) === null || _b === void 0 ? void 0 : _b.fetchPaymentOrdersError;
            if (updateStateError) {
                return updateStateError;
            }
            return;
        }
        return internalModeEnabled
            ? state.PAYMENT_ORDERS_V2.createPaymentOrders.error
            : state.payment.createPaymentOrderError;
    };
};
export var submitActionCreator = function (extraData, propertyId, paymentOrderId, paymentOrder, internalModeEnabled) {
    return function (formData) {
        var contract = getRelatedContract(extraData, formData);
        var contractId = contract === null || contract === void 0 ? void 0 : contract.id;
        if (paymentOrderId) {
            if (internalModeEnabled) {
                var updatedPaymentOrderV2 = getPaymentOrderV2(formData, extraData);
                return paymentOrderActions.updateStart.getAction({
                    id: paymentOrderId,
                    data: updatedPaymentOrderV2,
                });
            }
            var updatedPaymentOrder = getPaymentOrder(formData, propertyId, extraData);
            return updatePaymentOrder.actions.start({
                paymentOrderId: paymentOrderId,
                paymentOrder: updatedPaymentOrder,
            });
        }
        if (internalModeEnabled) {
            var paymentOrders_1 = [];
            var mainPaymentOrder_1 = getPaymentOrderV2(formData, extraData);
            paymentOrders_1.push(mainPaymentOrder_1);
            var commonCostsPaymentOrder_1 = getCommonCostsPaymentOrderV2(formData, contract);
            if (commonCostsPaymentOrder_1) {
                paymentOrders_1.push(commonCostsPaymentOrder_1);
            }
            var nonCommonCostsPaymentOrder_1 = getNonCommonCostsPaymentOrderV2(formData, contract);
            if (nonCommonCostsPaymentOrder_1) {
                paymentOrders_1.push(nonCommonCostsPaymentOrder_1);
            }
            var commissionPaymentOrder_1 = getCommissionPaymentOrderV2(formData, contract);
            if (commissionPaymentOrder_1) {
                paymentOrders_1.push(commissionPaymentOrder_1);
            }
            var managementFeePaymentOrder_1 = getManagementFeePaymentOrderV2(formData, contract);
            if (managementFeePaymentOrder_1) {
                paymentOrders_1.push(managementFeePaymentOrder_1);
            }
            var paymentGroup = getPaymentGroup(formData, propertyId, extraData);
            return paymentOrderActions.createPaymentOrdersStart.getAction({
                data: { paymentOrders: paymentOrders_1, paymentGroup: paymentGroup },
            });
        }
        var paymentOrders = [];
        var mainPaymentOrder = getPaymentOrder(formData, propertyId, extraData);
        paymentOrders.push(mainPaymentOrder);
        var commonCostsPaymentOrder = getCommonCostsPaymentOrder(formData, propertyId, contractId, contract);
        if (commonCostsPaymentOrder) {
            paymentOrders.push(commonCostsPaymentOrder);
        }
        var nonCommonCostsPaymentOrder = getNonCommonCostsPaymentOrder(formData, propertyId, contractId, contract);
        if (nonCommonCostsPaymentOrder) {
            paymentOrders.push(nonCommonCostsPaymentOrder);
        }
        var commissionPaymentOrder = getCommissionPaymentOrder(formData, propertyId, contractId, contract);
        if (commissionPaymentOrder) {
            paymentOrders.push(commissionPaymentOrder);
        }
        var managementFeePaymentOrder = getManagementFeePaymentOrder(formData, propertyId, contractId, contract);
        if (managementFeePaymentOrder) {
            paymentOrders.push(managementFeePaymentOrder);
        }
        return createPaymentOrder.actions.start(paymentOrders);
    };
};
