import { alpha } from "@mui/material/styles";
import { ThemeMode } from "../utils/types/config";
var createCustomShadow = function (theme, color) {
    var transparent = alpha(color, 0.24);
    return {
        z1: "0 1px 2px 0 ".concat(transparent),
        z8: "0 8px 16px 0 ".concat(transparent),
        z12: "0 12px 24px 0 ".concat(transparent, " 0 10px 20px 0 ").concat(transparent),
        z16: "0 0 3px 0 ".concat(transparent, " 0 14px 28px -5px ").concat(transparent),
        z20: "0 0 3px 0 ".concat(transparent, " 0 18px 36px -5px ").concat(transparent),
        z24: "0 0 6px 0 ".concat(transparent, " 0 21px 44px 0 ").concat(transparent),
        primary: "0px 12px 14px 0px ".concat(alpha(theme.palette.primary.main, 0.3)),
        secondary: "0px 12px 14px 0px ".concat(alpha(theme.palette.secondary.main, 0.3)),
        orange: "0px 12px 14px 0px ".concat(alpha(theme.palette.orange.main, 0.3)),
        success: "0px 12px 14px 0px ".concat(alpha(theme.palette.success.main, 0.3)),
        warning: "0px 12px 14px 0px ".concat(alpha(theme.palette.warning.main, 0.3)),
        error: "0px 12px 14px 0px ".concat(alpha(theme.palette.error.main, 0.3)),
    };
};
// eslint-disable-next-line import/no-default-export
export default function customShadows(mode, theme) {
    return mode === ThemeMode.DARK
        ? createCustomShadow(theme, theme.palette.dark.main)
        : createCustomShadow(theme, theme.palette.grey[900]);
}
