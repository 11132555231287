import { logError } from "@rentiohq/shared-frontend/dist/logger/logger";
import React from "react";
import { parseFields, parseVariablesData, } from "../TemplateEditor/components/Editor/Editor.utils";
export var useParsedTemplateDocument = function (detail) {
    var rawState = React.useMemo(function () {
        if (!(detail === null || detail === void 0 ? void 0 : detail.data)) {
            return undefined;
        }
        try {
            return typeof detail.data === "string"
                ? JSON.parse(detail.data)
                : detail.data;
        }
        catch (unknownError) {
            var error = unknownError;
            // TODO: What to do if parsing failed?
            logError({ error: error });
            return {
                blocks: [],
                entityMap: {},
            };
        }
    }, [detail]);
    var fields = React.useMemo(function () { return parseFields(detail === null || detail === void 0 ? void 0 : detail.fields); }, [detail]);
    var variablesData = React.useMemo(function () {
        if (!(detail === null || detail === void 0 ? void 0 : detail.variablesData)) {
            return {};
        }
        return parseVariablesData(detail.variablesData);
    }, [detail]);
    var documentConfig = React.useMemo(function () {
        if (!(detail === null || detail === void 0 ? void 0 : detail.documentConfig)) {
            return {};
        }
        try {
            return ((typeof detail.documentConfig === "string"
                ? JSON.parse(detail.documentConfig)
                : detail.documentConfig) || {});
        }
        catch (unknownError) {
            var error = unknownError;
            // TODO: What to do if parsing failed?
            logError({ error: error });
            return {};
        }
    }, [detail]);
    return {
        rawState: rawState,
        fields: fields,
        variablesData: variablesData,
        documentConfig: documentConfig,
    };
};
