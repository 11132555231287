import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import chatForm from "@rentiohq/shared-frontend/dist/forms/createChat";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/createChat/schema.createChat.actions";
import { getContactMeMaster } from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as chatHooks from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.hooks";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import {
  EChatMemberTypes,
  EChatMemberUpdateStatus,
  IChat,
  IChatMember,
} from "@rentiohq/shared-frontend/dist/types/chat.types";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { iHaveRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  AccountList,
  Card,
  ContactSearch,
  DisplayText,
  ESpacings,
  Icon,
  Loading,
  MultiStepForm,
  Page,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import * as t from "../../../../services/translationService";

interface IProps {
  chat: IChat;
}

const formId = generateFormId();

export const EditChat: React.FC<IProps> = ({ chat }) => {
  const navigate = useNavigate();

  const contactMeMaster = useSelector((state: IRootStore) =>
    getContactMeMaster(state),
  );

  const [isUpdateMembers, setUpdateMembers] = React.useState(false);

  let members: IChatMember[] = chat.members;

  const { isUpdating: isUpdatingChats } = chatHooks.useUpdate({
    id: chat.id,
  });

  // Check for chat update..
  React.useEffect(() => {
    // Updated or failed updating chat
    if (isUpdatingChats) {
      navigate(`/chats/${chat.id}`, { state: { updateChat: true } });
    }
  }, [chat.id, history, isUpdatingChats]);

  React.useEffect(() => {
    if (chat) {
      if (!iHaveRole(chat, EChatMemberTypes.Admin)) {
        navigate(-1);
      }
    }
  }, [chat, history]);

  const handleRemoveMember = async (accountId: number) => {
    members = members.map(member => {
      if (member.account.id === accountId) {
        member.status = EChatMemberUpdateStatus.Removed;
        return member;
      }

      return member;
    });

    setUpdateMembers(!isUpdateMembers);
  };

  const handleAddMember = async (account: IAccount) => {
    const member: IChatMember = {
      chatId: chat.id,
      account,
      roles: [EChatMemberTypes.Participant],
      status: EChatMemberUpdateStatus.Added,
    };

    members.push(member);

    setUpdateMembers(!isUpdateMembers);
  };

  const handleContactAdd = async (newContact: IContact) => {
    // @ts-ignore
    await handleAddMember(newContact.account);
  };

  const handleContactRemove = async (accountId: number) => {
    // @ts-ignore
    await handleRemoveMember(accountId);
  };

  const actions = [
    {
      content: (
        <TextStyle variation={"negative"}>
          {t.chatRemoveParticipant()}
        </TextStyle>
      ),
      media: <Icon source="bin" color="red" />,
      onClick: (accountId: number) => {
        handleContactRemove(accountId);
      },
      isContactAction: false,
    },
  ];

  if (!contactMeMaster) {
    return <Loading />;
  }

  return (
    <Page
      title={t.chatsEditTitle()}
      breadcrumbs={{
        content: t.chatsDetailBreadcrumbAction(),
        to: `/chats/${chat.id}`,
      }}
      hasBorder={true}
    >
      <Card>
        <Box mb={ESpacings.base}>
          <DisplayText size="medium">{t.chatParticipants()}</DisplayText>
          <Box mb={ESpacings.tight}>
            <AccountList
              accounts={members.map(member => member.account)}
              actions={actions}
              mediaSize="medium"
              boxProps={{ py: 3 }}
              getExtraSubtitle={(accountIds: number[], contact?: IContact) => {
                const member = members.find(member =>
                  accountIds.includes(member.account.id),
                );

                if (!member?.status) {
                  return;
                }

                return (
                  <>
                    <Spacer weight={ESpacerWeight.W02} />
                    <TextStyle
                      size={"small"}
                      variation={
                        member.status === EChatMemberUpdateStatus.Removed
                          ? "negative"
                          : "positive"
                      }
                    >
                      {member.status === EChatMemberUpdateStatus.Removed
                        ? t.chatRemovedParticipant()
                        : t.chatAddedParticipant()}
                    </TextStyle>
                  </>
                );
              }}
            />
          </Box>
          <ContactSearch
            includeSupport={true}
            onContactSelect={handleContactAdd}
            disallowedAccountIds={chat.members.map(member => member.account.id)}
          />
        </Box>
        <MultiStepForm
          formId={`create-chat-${formId}`}
          schemas={chatForm({ contactMeMaster, chat })}
          withSummary={false}
          withAside={false}
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            chat.id,
          )}
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            chat.id,
          )}
          submitActionCreator={submitActionCreator(chat.id, members)}
        />
      </Card>
    </Page>
  );
};
