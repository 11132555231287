var _a;
import { EDocumentPackageStatus } from "../../types/documentPackage.types";
import { EPaymentRequestStatus } from "../../types/paymentRequest.types";
import { ERegistrationDocumentType } from "../../types/registration.types";
import { ERentDepositStatus } from "../../types/rentDeposit.types";
import { appendWhere, appendWhereAndSelection } from "../../utils/api.utils";
import { DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT, DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_LOCATION_DESCRIPTION, DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT, DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_LOCATION_DESCRIPTION, DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT, DEFAULT_STATUSES_REGISTRATION_REGISTERED_LOCATION_DESCRIPTION, } from "./count.config";
export var ECountIdentifier;
(function (ECountIdentifier) {
    ECountIdentifier["Activities"] = "activities";
    ECountIdentifier["Contacts"] = "contacts";
    ECountIdentifier["ContactsOverviewPaged"] = "contactsOverviewPaged";
    ECountIdentifier["TasksUnfinishedExecutor"] = "tasksUnfinishedExecutor";
    ECountIdentifier["PaymentOrders"] = "paymentOrders";
    ECountIdentifier["PaymentRequests"] = "paymentRequests";
    ECountIdentifier["PaymentRequestsNew"] = "paymentRequestsNew";
    ECountIdentifier["PaymentRequestsNotPaid"] = "paymentRequestsNotPaid";
    ECountIdentifier["RentDepositsOverviewPaged"] = "rentDepositsOverviewPaged";
    ECountIdentifier["RentDepositsNew"] = "rentDepositsNew";
    ECountIdentifier["RentDepositsPayingIn"] = "rentDepositsPayingIn";
    ECountIdentifier["RentDepositsEstablished"] = "rentDepositsEstablished";
    ECountIdentifier["RentDepositsSigningOut"] = "rentDepositsSigningOut";
    ECountIdentifier["RentDepositsPayingOut"] = "rentDepositsPayingOut";
    ECountIdentifier["Revenues"] = "revenues";
    ECountIdentifier["DocumentPackages"] = "documentPackages";
    ECountIdentifier["DocumentPackagesPending"] = "documentPackagesPending";
    ECountIdentifier["DocumentPackagesFinished"] = "documentPackagesFinished";
    ECountIdentifier["Pricings"] = "pricings";
    ECountIdentifier["RegistrationsContractsInProgress"] = "registrationsContractsInProgress";
    ECountIdentifier["RegistrationsContractsActionRequired"] = "registrationsContractsActionRequired";
    ECountIdentifier["RegistrationsContractsRegistered"] = "registrationsContractsRegistered";
    ECountIdentifier["RegistrationsAddendaInProgress"] = "registrationsAddendaInProgress";
    ECountIdentifier["RegistrationsAddendaActionRequired"] = "registrationsAddendaActionRequired";
    ECountIdentifier["RegistrationsAddendaRegistered"] = "registrationsAddendaRegistered";
    ECountIdentifier["RegistrationLocationDescriptionInProgress"] = "registrationLocationDescriptionInProgress";
    ECountIdentifier["RegistrationLocationDescriptionActionRequired"] = "registrationsLocationDescriptionActionRequired";
    ECountIdentifier["RegistrationLocationDescriptionRegistered"] = "registrationLocationDescriptionRegistered";
    ECountIdentifier["PaymentDisputes"] = "paymentDisputes";
    //NEW
    ECountIdentifier["RentDepositsToBeSigned"] = "rentDepositsToBeSigned";
})(ECountIdentifier || (ECountIdentifier = {}));
export var COUNT_BASE = (_a = {},
    _a[ECountIdentifier.Activities] = "/activities/count",
    _a[ECountIdentifier.Contacts] = "/contacts/count",
    _a[ECountIdentifier.TasksUnfinishedExecutor] = appendWhereAndSelection("/tasks/count", {
        finishedAt: { eq: null },
    }, ["roleExecutor"]),
    _a[ECountIdentifier.PaymentOrders] = "/payment-orders/count",
    _a[ECountIdentifier.PaymentRequests] = "/payment-requests/count",
    _a[ECountIdentifier.PaymentRequestsNew] = appendWhere("/payment-requests/count", {
        or: [
            { status: EPaymentRequestStatus.New },
            { status: EPaymentRequestStatus.FailedPaidIn },
        ],
    }),
    _a[ECountIdentifier.PaymentRequestsNotPaid] = appendWhere("/payment-requests/count", {
        status: { neq: EPaymentRequestStatus.Paid },
    }),
    // NEW
    _a[ECountIdentifier.RentDepositsToBeSigned] = appendWhere("/rent-deposits/count", {
        status: {
            inq: [
                ERentDepositStatus.New,
                ERentDepositStatus.OpenToSign,
                ERentDepositStatus.OpenSigned,
                ERentDepositStatus.PayingInPartiallySigned,
                ERentDepositStatus.PaidInPartiallySigned,
                ERentDepositStatus.PayingIn,
                ERentDepositStatus.WaitingForValidations,
                ERentDepositStatus.WaitingForIBAN,
                ERentDepositStatus.ReleaseNew,
                ERentDepositStatus.ReleaseToSign,
                ERentDepositStatus.ReleaseSigned,
            ],
        },
    }),
    //OLD
    _a[ECountIdentifier.RentDepositsNew] = appendWhere("/rent-deposits/count", {
        status: {
            inq: [
                ERentDepositStatus.New,
                ERentDepositStatus.OpenToSign,
                ERentDepositStatus.OpenSigned,
                ERentDepositStatus.PayingInPartiallySigned,
                ERentDepositStatus.PaidInPartiallySigned,
            ],
        },
    }),
    _a[ECountIdentifier.RentDepositsPayingIn] = appendWhere("/rent-deposits/count", {
        status: {
            inq: [
                // `${ERentDepositStatus.PayingInPartiallySigned}`,
                ERentDepositStatus.PayingIn,
            ],
        },
    }),
    _a[ECountIdentifier.RentDepositsEstablished] = appendWhere("/rent-deposits/count", { status: "".concat(ERentDepositStatus.Established) }),
    _a[ECountIdentifier.RentDepositsSigningOut] = appendWhere("/rent-deposits/count", {
        status: {
            inq: [
                ERentDepositStatus.WaitingForValidations,
                ERentDepositStatus.WaitingForIBAN,
                ERentDepositStatus.ReleaseNew,
                ERentDepositStatus.ReleaseToSign,
                ERentDepositStatus.ReleaseSigned,
            ],
        },
    }),
    _a[ECountIdentifier.RentDepositsPayingOut] = appendWhere("/rent-deposits/count", {
        status: {
            inq: [
                ERentDepositStatus.ManualVerification,
                ERentDepositStatus.ManualVerificationJudicial,
                ERentDepositStatus.PayingOut,
            ],
        },
    }),
    _a[ECountIdentifier.DocumentPackagesPending] = appendWhere("/document-packages/count", {
        status: {
            inq: [
                EDocumentPackageStatus.New,
                EDocumentPackageStatus.PDFReady,
                EDocumentPackageStatus.Draft,
                EDocumentPackageStatus.Pending,
            ],
        },
    }),
    _a[ECountIdentifier.DocumentPackagesFinished] = appendWhere("/document-packages/count", { status: EDocumentPackageStatus.Finished }),
    _a[ECountIdentifier.Pricings] = "/pricings/count",
    _a[ECountIdentifier.RegistrationsContractsInProgress] = appendWhere("/rent-registrations/count", {
        rentContractStatus: {
            inq: DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT,
        },
    }),
    _a[ECountIdentifier.RegistrationsContractsActionRequired] = appendWhere("/rent-registrations/count", {
        rentContractStatus: {
            inq: DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT,
        },
    }),
    _a[ECountIdentifier.RegistrationsContractsRegistered] = appendWhere("/rent-registrations/count", {
        rentContractStatus: {
            inq: DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT,
        },
    }),
    _a[ECountIdentifier.RegistrationLocationDescriptionInProgress] = appendWhere("/rent-registrations/count", {
        locationDescriptionStatus: {
            inq: DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_LOCATION_DESCRIPTION,
        },
    }),
    _a[ECountIdentifier.RegistrationLocationDescriptionActionRequired] = appendWhere("/rent-registrations/count", {
        locationDescriptionStatus: {
            inq: DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_LOCATION_DESCRIPTION,
        },
    }),
    _a[ECountIdentifier.RegistrationLocationDescriptionRegistered] = appendWhere("/rent-registrations/count", {
        locationDescriptionStatus: {
            inq: DEFAULT_STATUSES_REGISTRATION_REGISTERED_LOCATION_DESCRIPTION,
        },
    }),
    _a[ECountIdentifier.RegistrationsAddendaActionRequired] = appendWhere("/rent-registration-documents/count", {
        status: {
            inq: DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT,
        },
        documentType: ERegistrationDocumentType.Addendum,
    }),
    _a[ECountIdentifier.RegistrationsAddendaInProgress] = appendWhere("/rent-registration-documents/count", {
        status: {
            inq: DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT,
        },
        documentType: ERegistrationDocumentType.Addendum,
    }),
    _a[ECountIdentifier.RegistrationsAddendaRegistered] = appendWhere("/rent-registration-documents/count", {
        status: {
            inq: DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT,
        },
        documentType: ERegistrationDocumentType.Addendum,
    }),
    _a[ECountIdentifier.PaymentDisputes] = "/payment-disputes/count",
    _a);
