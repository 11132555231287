var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import pluralize from "pluralize";
import React from "react";
import * as t from "../../../services/translationService";
import { isEmptyDeep } from "../../../utils/object";
import { Button } from "../../Button";
import { DisplayText } from "../../DisplayText";
import { Icon } from "../../Icon";
import * as S from "../SharedForm.styled";
var contactArrayNames = ["tenant", "owner", "client", "customer"];
var getItemTitle = function (uiSchema, formData) {
    var value = uiSchema["itemTitle"];
    var getValue = uiSchema["getItemTitle"];
    if (getValue) {
        return getValue(formData);
    }
    return value;
};
export var ArrayFieldTemplate = function (_a) {
    var rawErrors = _a.rawErrors, props = __rest(_a, ["rawErrors"]);
    var items = props.items, canAdd = props.canAdd, onAddClick = props.onAddClick, idSchema = props.idSchema, formData = props.formData, rest = __rest(props, ["items", "canAdd", "onAddClick", "idSchema", "formData"]);
    var _b = React.useState(items), initialItems = _b[0], setInitialItems = _b[1];
    var name = pluralize.singular(idSchema.$id.substr(idSchema.$id.lastIndexOf("_") + 1));
    var isContactField = contactArrayNames.includes(name);
    React.useEffect(function () {
        setInitialItems(items);
    }, [items]);
    var options = rest.uiSchema;
    var _c = options.buttonType, buttonType = _c === void 0 ? "plain" : _c, showButtonIcon = options.showButtonIcon;
    return (_jsxs(_Fragment, { children: [items === null || items === void 0 ? void 0 : items.map(function (element, index) {
                var elementData = formData[index];
                var title = getItemTitle(props.uiSchema, elementData);
                var hasEdit = options === null || options === void 0 ? void 0 : options.hasOwnProperty("editable");
                var canEdit = hasEdit ? options.editable : true;
                var isEditable = canEdit ||
                    index > initialItems.length - 1 ||
                    isEmptyDeep(elementData);
                var children = element.children;
                return (_jsxs(S.ArrayFieldWrap, { children: [title && _jsx(DisplayText, { size: "small", children: title }), _jsxs(S.ArrayField, { hasAdd: canAdd, isContact: isContactField, children: [element.hasRemove && (_jsx(S.ArrayFieldRemove, { children: _jsx(Button, { type: "button", appearance: "plain", onClick: element.onDropIndexClick(element.index), ariaLabel: t.arrayItemRemove(name), iconBefore: _jsx(Icon, { source: "removeCircle", color: "red" }) }) })), _jsx(S.ArrayFieldContent, { children: React.cloneElement(children, __assign(__assign(__assign({}, children.props), (options.required ? { required: options.required } : {})), { readonly: !isEditable })) })] }, element.index)] }, element.index));
            }), canAdd && (_jsx(Button, { appearance: buttonType, onClick: onAddClick, iconBeforeName: showButtonIcon ? "addCircle" : undefined, children: t.arrayItemAdd(name) }))] }));
};
