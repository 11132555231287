import { useTheme } from "@mui/material/styles";
import useConfig from "../../../hooks/useConfig";
import { ThemeMode } from "../../../utils/types/config";
export var SimpleCardStyles = function (boxShadow, shadow) {
    var mode = useConfig().mode;
    var theme = useTheme();
    var defaultShadow = mode === ThemeMode.DARK
        ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
        : "0 2px 14px 0 rgb(32 40 45 / 8%)";
    var headerSX = {
        color: theme.palette.grey[100],
        pb: 0,
        textTransform: "uppercase",
        "& .MuiCardHeader-action": { mr: 0 },
    };
    var cardSX = {
        border: "1px solid ".concat(theme.palette.grey[100]),
        ":hover": {
            boxShadow: boxShadow ? shadow || defaultShadow : "inherit",
        },
    };
    return { headerSX: headerSX, cardSX: cardSX };
};
