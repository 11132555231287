var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { append } from "../../utils/api.utils";
import api from "../../utils/api/api.utils";
import { generateApi } from "../generators/generator.api";
import { GENERATOR_PARAMS } from "./bankAccount.constants";
export var getDetail = (_a = generateApi(GENERATOR_PARAMS), _a.getDetail), getInfiniteLoad = _a.getInfiniteLoad;
export var getAllMissingKYCs = function (query) {
    return api.get(append("/".concat(GENERATOR_PARAMS.basePathEntity, "/missing-kycs"), __assign(__assign({}, query), { sort: query.sort })), { isV2: true });
};
