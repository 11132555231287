var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useTheme } from "@mui/material/styles";
import useConfig from "../../../hooks/useConfig";
export var ProfileSectionStyles = function () {
    var theme = useTheme();
    var borderRadius = useConfig().borderRadius;
    return {
        wrapper: {
            chip: {
                display: "flex",
                flexDirection: "row-reverse",
                ml: 2,
                height: "48px",
                alignItems: "center",
                borderRadius: "27px",
                transition: "all .2s ease-in-out",
                borderColor: theme.palette.grey[200],
                bgcolor: theme.palette.background.default,
                '&[aria-controls="menu-list-grow"], &:hover': {
                    borderColor: "primary.main",
                    bgcolor: "".concat(theme.palette.primary.light, " !important"),
                },
                "& .MuiChip-label": {
                    lineHeight: 0,
                    svg: {
                        color: theme.palette.grey[500],
                    },
                },
            },
        },
        content: {
            avatar: __assign(__assign({}, theme.typography.mediumAvatar), { margin: "8px 8px 8px 0px !important", cursor: "pointer" }),
            list: {
                width: "100%",
                maxWidth: 350,
                minWidth: 300,
                borderRadius: "".concat(borderRadius, "px"),
                "& .MuiListItemButton-root": {
                    mt: 0.5,
                    "&:hover": {
                        ".MuiListItemIcon-root": {
                            color: theme.palette.text.primary,
                        },
                    },
                    "&:last-child": {
                        ".MuiListItemIcon-root": {
                            color: theme.palette.error.main,
                        },
                        ".MuiTypography-root": {
                            color: theme.palette.error.main,
                        },
                    },
                },
            },
            chip: { "& .MuiChip-label": { mt: 0.25 } },
            profileBtn: {
                p: 2,
                pb: 0,
                mb: 1,
            },
            listContainer: {
                p: 2,
                pt: 1,
            },
        },
    };
};
