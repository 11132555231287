var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ECreatePaymentMethod } from "../../forms/createPayment/schema.createPayment.types";
import { contactSelectInlineV2ValueSelector, } from "../../redux/form/form.utils";
import * as propertySelectorsV2 from "../../reduxV2/property/property.selectors";
import { EContractIndexationStatus, EContractMemberTypes, EContractPaydate, EContractPaymentRepetitionType, VAT_OPTIONS, VAT_OPTIONS_BOOKKEEPING, } from "../../types/contract.types";
import { EPaymentMethod, EPaymentOrderType, EPaymentRepetitionType, } from "../../types/payment.types";
import { EPropertyMemberTypes } from "../../types/property.types";
import { differenceInDays, endOfDay, endOfMonth, endOfQuarter, endOfYear, getDay, getMonth, isAfter, isBefore, max, startOfDay, subDays, } from "../../utils/date-fns.utils";
import { stripTime } from "../../utils/date.utils";
import { getAllRequiredCompanyFields, getAllRequiredContactFields, } from "../../utils/forms.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField as EContactField } from "../addContact/schema.addContact.types";
import { ETypeSelect } from "./schema.createPayment.0";
import { EField, NON_RECURRING_COSTS, RECURRING_COSTS, } from "./schema.createPayment.types";
var NOW = new Date();
var YESTERDAY = subDays(new Date(), 1);
export var isRentOrCost = function (type) {
    return type &&
        [
            EPaymentOrderType.RentAndCosts,
            EPaymentOrderType.Rent,
            EPaymentOrderType.CommonCosts,
            EPaymentOrderType.NonCommonCosts,
        ].includes(type);
};
export var showStartDateAsDropdown = function (formData) {
    // Don't show if not rent or cost
    if (!isRentOrCost(formData[EField.TypeRecurring])) {
        return false;
    }
    return true;
};
export var checkIfTypeIsOther = function (formData) {
    return formData[EField.TypeRecurring] === EPaymentOrderType.OtherRecurring ||
        formData[EField.TypeOnce] === EPaymentOrderType.OtherOneoff;
};
export var shouldAskOwnerPaymentMethod = function (formData, extraData) {
    var _a;
    // TODO: Can be other account ID
    var payerAccountId = (_a = formData[EField.Payer]) === null || _a === void 0 ? void 0 : _a[0];
    var contract = getRelatedContract(extraData, formData);
    if (contract && payerAccountId) {
        var payerMember = contract.members.find(function (member) { return member.account.id === payerAccountId; });
        return !!(payerMember === null || payerMember === void 0 ? void 0 : payerMember.roles.includes(EContractMemberTypes.Owner));
    }
    return false;
};
export var getStartDate = function (formData) {
    if (showStartDateAsDropdown(formData)) {
        if (formData[EField.StartDateDropdown]) {
            return new Date(formData[EField.StartDateDropdown]);
        }
        return undefined;
    }
    if (formData[EField.StartDate]) {
        return formData[EField.StartDate];
    }
    return undefined;
};
export var getRecurringCosts = function (isCreate) {
    if (!isCreate) {
        return RECURRING_COSTS;
    }
    return __spreadArray([EPaymentOrderType.RentAndCosts], RECURRING_COSTS, true);
};
export var isRecurringCostDisabled = function (cost, isTenant, contract, internalModeEnabled) {
    if (isTenant === void 0) { isTenant = false; }
    if (!contract) {
        return true;
    }
    if (isTenant) {
        return cost !== EPaymentOrderType.OtherRecurring;
    }
    var currentPrice = contract.currentPrice, currentCostsCommon = contract.currentCostsCommon, currentCostsNonCommon = contract.currentCostsNonCommon;
    var hasCommonCosts = currentCostsCommon && currentCostsCommon > 0;
    var hasNonCommonCosts = currentCostsNonCommon && currentCostsNonCommon > 0;
    var hasIndexationOpen = !internalModeEnabled &&
        (contract === null || contract === void 0 ? void 0 : contract.indexationStatus) === EContractIndexationStatus.Ready;
    if (cost === EPaymentOrderType.RentAndCosts) {
        return (!hasCommonCosts && !hasNonCommonCosts) || hasIndexationOpen;
    }
    if (cost === EPaymentOrderType.Rent) {
        return !currentPrice || currentPrice === 0 || hasIndexationOpen;
    }
    if (cost === EPaymentOrderType.CommonCosts) {
        return !hasCommonCosts || hasIndexationOpen;
    }
    if (cost === EPaymentOrderType.NonCommonCosts) {
        return !hasNonCommonCosts;
    }
    return false;
};
export var isOnceCostDisabled = function (cost, isTenant) {
    if (isTenant === void 0) { isTenant = false; }
    if (isTenant) {
        return cost !== EPaymentOrderType.OtherOneoff;
    }
    return false;
};
var PAYMENT_TYPE_ICONS = {
    RENT_AND_COSTS: "realEstateActionBuildingStar",
    RENT: "forRentSign",
    COMMON_COSTS: "houseWrench",
    NON_COMMON_COSTS: "billSplit",
    MANAGEMENT_COSTS: "keys",
    RENTIO: "smartphone",
    OTHER_RECURRING: "billStack",
    RENT_DEPOSIT: "safe",
    LOCATION_DESCRIPTION: "houseNotes",
    BROKER_FEE: "broker",
    OTHER_ONEOFF: "billStack",
};
export var getNavigationTitle = function (_a) {
    var paymentOrder = _a.paymentOrder;
    if (paymentOrder) {
        return getLocalizedText("payments.update.title");
    }
    return getLocalizedText("payments.create.title");
};
export var getInitialValues = function (extraData) {
    var _a, _b;
    var paymentOrder = extraData.paymentOrder, shouldUpdateOpenPaymentRequests = extraData.shouldUpdateOpenPaymentRequests, _c = extraData.activeContracts, activeContracts = _c === void 0 ? [] : _c, _d = extraData.endedContracts, endedContracts = _d === void 0 ? [] : _d;
    if (!paymentOrder) {
        var contracts = __spreadArray(__spreadArray([], activeContracts, true), endedContracts, true);
        var activeContract = void 0;
        var endedContract = void 0;
        // If there is only a single contract it can be prefilled
        if (contracts.length === 1) {
            if (activeContracts.length > 0) {
                activeContract = activeContracts[0];
            }
            else if (endedContracts.length > 0) {
                endedContract = endedContracts[0];
            }
        }
        var contract = activeContract || endedContract;
        var initialValues_1 = (_a = {},
            _a[EField.TypeRecurring] = undefined,
            _a[EField.TypeOnce] = contracts.length === 0 ? undefined : NON_RECURRING_COSTS[0],
            _a[EField.ActiveContract] = activeContract === null || activeContract === void 0 ? void 0 : activeContract.id,
            _a[EField.EndedContract] = endedContract === null || endedContract === void 0 ? void 0 : endedContract.id,
            _a[EField.IncludeCommission] = "0",
            _a[EField.IncludeManagementFee] = "0",
            _a[EField.PaydateOffset] = "0",
            _a[EField.FirstPaymentReceivedDate] = NOW,
            _a[EField.FirstPaymentReceivedDateProRata] = NOW,
            _a[EField.PayoutReferenceType] = "DEFAULT",
            _a[EField.CommissionPayoutReferenceType] = "DEFAULT",
            _a[EField.ManagementFeePayoutReferenceType] = "DEFAULT",
            _a);
        if (contract) {
            if (isAfter(contract.startDate, YESTERDAY)) {
                initialValues_1[EField.StartDate] = stripTime(max([NOW, contract.startDate]));
            }
        }
        return initialValues_1;
    }
    var isRecurring = RECURRING_COSTS.includes(paymentOrder.type);
    var proRataAmount = paymentOrder.proRataAmount;
    // INFO: When repetition is delayed, add amount
    if (paymentOrder.isRepetitionDelayed && isRecurring) {
        proRataAmount = proRataAmount + paymentOrder.amount;
    }
    var priceVat = paymentOrder.vat || 0;
    var priceVatMapped;
    if (extraData.bookkeepingEnabled) {
        priceVatMapped =
            priceVat === 0
                ? paymentOrder.noVatType || VAT_OPTIONS_BOOKKEEPING[0]
                : priceVat;
    }
    else {
        priceVatMapped = priceVat || VAT_OPTIONS[0];
    }
    var initialValues = (_b = {},
        _b[EField.TypeSelect] = isRecurring ? ETypeSelect.Recurring : ETypeSelect.Once,
        _b[EField.TypeRecurring] = isRecurring ? paymentOrder.type : undefined,
        _b[EField.TypeOnce] = isRecurring ? undefined : paymentOrder.type,
        _b[EField.ActiveContract] = paymentOrder.contractId,
        _b[EField.WithoutContract] = paymentOrder.contract ? undefined : "1",
        _b[EField.Price] = paymentOrder.amount,
        _b[EField.PriceVat] = priceVatMapped,
        _b[EField.PriceCommonCosts] = paymentOrder.amount,
        _b[EField.PriceCommonCostsVat] = priceVatMapped,
        _b[EField.PriceNonCommonCosts] = paymentOrder.amount,
        _b[EField.PriceNonCommonCostsVat] = priceVatMapped,
        _b[EField.StartDate] = stripTime(paymentOrder.startedAt),
        _b[EField.StartDateDropdown] = stripTime(paymentOrder.startedAt).toISOString(),
        _b[EField.PaydateOffset] = "".concat(paymentOrder.paydateOffset || 0),
        _b[EField.FirstPaymentReceivedDate] = paymentOrder.firstPayDateAt,
        _b[EField.FirstPaymentReceivedDateProRata] = paymentOrder.firstPayDateAt,
        _b[EField.ProRata] = paymentOrder.isRepetitionDelayed
            ? "EXTRA_MONTH"
            : "NORMAL",
        _b[EField.FirstPrice] = proRataAmount,
        _b[EField.Payer] = [paymentOrder.payerId],
        _b[EField.Payee] = [paymentOrder.payeeId],
        _b[EField.Iban] = paymentOrder.payeeBankaccountId,
        _b[EField.PayoutReferenceType] = paymentOrder.payoutReferenceCustom
            ? "CUSTOM"
            : "DEFAULT",
        _b[EField.PayoutReference] = paymentOrder.payoutReference,
        _b[EField.PayoutReferenceCustom] = paymentOrder.payoutReferenceCustom,
        _b[EField.UpdatePaymentRequests] = shouldUpdateOpenPaymentRequests
            ? "UPDATE_TRUE"
            : "UPDATE_FALSE",
        _b[EField.BookkeepingPeriodStart] = paymentOrder.bookkeepingPeriodStart,
        _b[EField.BookkeepingPeriodEnd] = paymentOrder.bookkeepingPeriodEnd,
        _b[EField.BookkeepingGeneralLedgerAccount] = paymentOrder.bookkeepingGeneralLedgerAccount,
        _b);
    var paymentMethods = [];
    if (paymentOrder.paymentMethods.includes(EPaymentMethod.Sepa)) {
        paymentMethods.push(ECreatePaymentMethod.Sepa);
    }
    if (paymentOrder.paymentMethods.includes(EPaymentMethod.Bancontact) ||
        paymentOrder.paymentMethods.includes(EPaymentMethod.Payconiq) ||
        paymentOrder.paymentMethods.includes(EPaymentMethod.Ideal)) {
        paymentMethods.push(ECreatePaymentMethod.Online);
    }
    if (paymentOrder.paymentMethods.includes(EPaymentMethod.Ibanisation)) {
        paymentMethods.push(ECreatePaymentMethod.Ibanisation);
    }
    initialValues[EField.PaymentMethods] = paymentMethods;
    if (checkIfTypeIsOther(initialValues)) {
        initialValues[EField.OtherDescription] = paymentOrder.otherDescription;
    }
    if (shouldAskOwnerPaymentMethod(initialValues, extraData)) {
        initialValues[EField.OwnerPaymentMethod] = paymentOrder.ownerPaymentMethod;
    }
    return initialValues;
};
export var getSubtitle = function (payload) {
    var item = payload.item, state = payload.state, contract = payload.contract;
    var property = propertySelectorsV2.getDetail(state, contract ? contract.propertyId : -1);
    /*const property = propertySelectors.getProperty(
      state,
      contract ? contract.propertyId : -1,
    );*/
    if (!property) {
        return;
    }
    var accountId = item.accountId, contact = item.contact;
    var member = property.members.find(function (member) {
        if (accountId && accountId === member.account.id) {
            return true;
        }
        if (contact === null || contact === void 0 ? void 0 : contact.accountIds.includes(member.account.id)) {
            return true;
        }
        return false;
    });
    if (!member) {
        return;
    }
    return member.roles
        .filter(function (role) {
        return ![
            EPropertyMemberTypes.Admin,
            EPropertyMemberTypes.Signer,
            EPropertyMemberTypes.ContractMember,
            EPropertyMemberTypes.TaskMember,
        ].includes(role);
    })
        .map(function (role) { return getLocalizedText("role.".concat(role.toLowerCase())); })
        .join(", ");
};
export var customContactsValueSelector = function (payload) {
    var state = payload.state, propertyId = payload.propertyId, selectedAccountIds = payload.selectedAccountIds;
    var property = propertySelectorsV2.getDetail(state, propertyId);
    // const property = propertySelectors.getProperty(state, propertyId);
    if (!property) {
        return getLocalizedText("summary.view.contact");
    }
    return contactSelectInlineV2ValueSelector(state, selectedAccountIds, property.members.map(function (member) { return member.account; }));
};
export var customContactsSelector = function (payload) {
    var type = payload.type, payerType = payload.payerType, state = payload.state, contract = payload.contract;
    var property = propertySelectorsV2.getDetail(state, contract ? contract.propertyId : -1);
    /*const property = propertySelectors.getProperty(
      state,
      contract ? contract.propertyId : -1,
    );*/
    if (!contract || !property) {
        return;
    }
    var members = contract.members.filter(function (contractMember) {
        var roles = contractMember.roles;
        var propertyMember = property.members.find(function (randomPropertyMember) {
            return randomPropertyMember.account.id === contractMember.account.id;
        });
        var isTenant = roles.includes(EContractMemberTypes.Tenant) ||
            roles.includes(EContractMemberTypes.Parent);
        var isOwner = roles.includes(EContractMemberTypes.Owner);
        var isBrokerOrManager = (propertyMember === null || propertyMember === void 0 ? void 0 : propertyMember.roles.includes(EPropertyMemberTypes.Broker)) ||
            (propertyMember === null || propertyMember === void 0 ? void 0 : propertyMember.roles.includes(EPropertyMemberTypes.TechnicalManager)) ||
            (propertyMember === null || propertyMember === void 0 ? void 0 : propertyMember.roles.includes(EPropertyMemberTypes.FinancialManager));
        switch (type) {
            case EPaymentOrderType.RentAndCosts:
            case EPaymentOrderType.Rent:
            case EPaymentOrderType.CommonCosts:
            case EPaymentOrderType.NonCommonCosts:
                if (payerType === "payer") {
                    return isTenant;
                }
                return isOwner;
            case EPaymentOrderType.ManagementCosts:
            case EPaymentOrderType.Rentio:
            case EPaymentOrderType.BrokerFee:
                if (payerType === "payer") {
                    return isOwner;
                }
                return isBrokerOrManager;
            case EPaymentOrderType.RentDeposit:
                if (payerType === "payer") {
                    return isTenant;
                }
                return isBrokerOrManager;
            case EPaymentOrderType.LocationDescription:
                if (payerType === "payer") {
                    return isTenant || isOwner;
                }
                return isBrokerOrManager;
            case EPaymentOrderType.OtherRecurring:
            case EPaymentOrderType.OtherOneoff:
                return isTenant || isOwner || isBrokerOrManager;
            default:
                return false;
        }
    });
    return members.map(function (member) { return member.account; });
};
export var getRepetitionDay = function (startDate, contract) {
    var result = getDay(startDate);
    if (contract && contract.payDateStart === "calendar") {
        result = 1;
    }
    if (contract && contract.payDateStart === "contract") {
        result = getDay(contract.startDate);
    }
    return Math.min(28, result);
};
export var getPaymentTypeIcon = function (type) { return PAYMENT_TYPE_ICONS[type]; };
export var isNewContract = function (contract) {
    return isAfter(startOfDay(contract.startDate), startOfDay(YESTERDAY));
};
export var hasProRata = function (formData, contract) {
    if (!contract) {
        return false;
    }
    if (!isNewContract(contract)) {
        return false;
    }
    var startDate = contract.startDate, payDateStart = contract.payDateStart, _a = contract.repetitionType, repetitionType = _a === void 0 ? EContractPaymentRepetitionType.Monthly : _a;
    var selectedStartDate = getStartDate(formData);
    if (payDateStart === EContractPaydate.Calendar) {
        switch (repetitionType) {
            case EContractPaymentRepetitionType.Monthly: {
                return getDay(selectedStartDate) !== 1;
            }
            case EContractPaymentRepetitionType.Quarterly: {
                return (getDay(selectedStartDate) !== 1 ||
                    ![1, 4, 7, 10].includes(getMonth(selectedStartDate) + 1));
            }
            case EContractPaymentRepetitionType.Yearly: {
                return (getDay(selectedStartDate) !== 1 ||
                    getMonth(selectedStartDate) + 1 !== 1);
            }
            default:
                return false;
        }
    }
    if (payDateStart === EContractPaydate.Contract) {
        return getDay(selectedStartDate) !== getDay(startDate);
    }
    return false;
};
export var proRataForContract = function (contract, baseAmount) {
    if (contract.payDateStart === EContractPaydate.Contract) {
        return 0;
    }
    var periodDays = 0;
    var proRataDays = 0;
    switch (contract.repetitionType) {
        case EContractPaymentRepetitionType.Quarterly:
            periodDays = 30 * 3;
            proRataDays = differenceInDays(endOfQuarter(contract.startDate), contract.startDate);
            break;
        case EContractPaymentRepetitionType.Yearly:
            periodDays = 365;
            proRataDays = differenceInDays(endOfYear(contract.startDate), contract.startDate);
            break;
        case EContractPaymentRepetitionType.Monthly:
        default:
            periodDays = 30;
            proRataDays = differenceInDays(endOfMonth(contract.startDate), contract.startDate);
            break;
    }
    if (proRataDays === 0) {
        return 0;
    }
    return (Math.round(baseAmount * (Math.min(proRataDays, periodDays) / periodDays) * 100) / 100);
};
export var getFirstPayDateAt = function (params) {
    var repetitionType = params.repetitionType, formData = params.formData, contract = params.contract;
    var userDate = formData[EField.FirstPaymentReceivedDate];
    var isRecurring = repetitionType !== EPaymentRepetitionType.Once;
    if (isRecurring && hasProRata(formData, contract)) {
        userDate = formData[EField.FirstPaymentReceivedDateProRata];
    }
    return new Date(userDate || getStartDate(formData));
};
export var isRepetitionDelayed = function (formData, contract) {
    // Only for recurring payments
    return !!formData[EField.TypeRecurring] &&
        // Only if pro-rata
        hasProRata(formData, contract) &&
        formData[EField.ProRata] === "EXTRA_MONTH";
};
export var getDefaultPayerPayeeValue = function (payload) {
    var key = payload.key, initialValues = payload.initialValues, defaultValues = payload.defaultValues, type = payload.type, contract = payload.contract;
    if (key in defaultValues) {
        return defaultValues[key];
    }
    if (key in initialValues) {
        return initialValues[key];
    }
    if (!contract) {
        return;
    }
    var contractMembers = contract.members.filter(function (_a) {
        var roles = _a.roles;
        var isTenant = roles.includes(EContractMemberTypes.Tenant) ||
            roles.includes(EContractMemberTypes.Parent);
        var isOwner = roles.includes(EContractMemberTypes.Owner);
        var isBroker = roles.includes(EContractMemberTypes.Broker);
        switch (type) {
            case EPaymentOrderType.RentAndCosts:
            case EPaymentOrderType.Rent:
            case EPaymentOrderType.CommonCosts:
            case EPaymentOrderType.NonCommonCosts:
                if (key === EField.Payer) {
                    return isTenant;
                }
                return isOwner;
            case EPaymentOrderType.ManagementCosts:
            case EPaymentOrderType.Rentio:
            case EPaymentOrderType.BrokerFee:
                if (key === EField.Payer) {
                    return isOwner;
                }
                return isBroker;
            case EPaymentOrderType.RentDeposit:
                if (key === EField.Payer) {
                    return isTenant;
                }
                return isBroker;
            case EPaymentOrderType.LocationDescription:
                if (key === EField.Payer) {
                    return isTenant || isOwner;
                }
                return isBroker;
            case EPaymentOrderType.OtherRecurring:
            case EPaymentOrderType.OtherOneoff:
                return isTenant || isOwner || isBroker;
            default:
                return false;
        }
    });
    if (contractMembers.length === 1) {
        return [contractMembers[0].account.id];
    }
};
export var getActiveContracts = function (contracts) {
    return contracts.filter(function (contract) {
        return isBefore(endOfDay(new Date()), endOfDay(new Date(contract.stopDate)));
    });
};
export var getEndedContracts = function (contracts) {
    return contracts.filter(function (contract) {
        return isAfter(endOfDay(new Date()), endOfDay(new Date(contract.stopDate)));
    });
};
export var getRelatedContract = function (_a, formData) {
    var activeContracts = _a.activeContracts, endedContracts = _a.endedContracts, paymentOrder = _a.paymentOrder;
    var contracts = __spreadArray(__spreadArray([], (activeContracts || []), true), (endedContracts || []), true);
    var contractId = (paymentOrder === null || paymentOrder === void 0 ? void 0 : paymentOrder.contractId) ||
        formData[EField.ActiveContract] ||
        formData[EField.EndedContract];
    return contracts.find(function (contract) { return contract.id === contractId; });
};
export var getContract = function (contractId, _a) {
    var activeContracts = _a.activeContracts, endedContracts = _a.endedContracts;
    var contracts = __spreadArray(__spreadArray([], (activeContracts || []), true), (endedContracts || []), true);
    return contracts.find(function (contract) { return contract.id === contractId; });
};
export var contractHasRentPaymentOrder = function (extraData, formData) {
    var paymentOrders = extraData.paymentOrders;
    var contract = getRelatedContract(extraData, formData);
    if (!contract) {
        return false;
    }
    var rentPaymentOrder = paymentOrders === null || paymentOrders === void 0 ? void 0 : paymentOrders.find(function (paymentOrder) {
        return paymentOrder.type === EPaymentOrderType.Rent &&
            paymentOrder.contractId === contract.id;
    });
    return !!rentPaymentOrder;
};
export var getCreatePaymentCompanyFieldsSchema = function (contact) {
    return getAllRequiredCompanyFields({
        contact: contact,
        requiredFields: [
            EContactField.Address,
            EContactField.DateOfBirth,
            EContactField.Nationality,
            EContactField.PlaceOfBirth,
            EContactField.VATNumber,
        ],
        appendIdentityNumberToRequiredFields: false,
    });
};
export var getCreatePaymentContactFieldsSchema = function (contact) {
    return getAllRequiredContactFields({
        contact: contact,
        requiredFields: [
            EContactField.Address,
            EContactField.DateOfBirth,
            EContactField.Nationality,
            EContactField.PlaceOfBirth,
        ],
        appendIdentityNumberToRequiredFields: false,
    });
};
