import { Route } from "react-router-dom";
import { PropertyDetails } from "./PropertyDetails";
import { Contracts } from "./scenes/Contracts";
import { Documents } from "./scenes/Documents";
import { History } from "./scenes/History";
import { Overview } from "./scenes/Overview";

export const getPropertyDetailScene = () => {
  return (
    <Route path=":propertyId">
      <Route element={<PropertyDetails />}>
        <Route index element={<Overview />} />
        <Route path="contracts" element={<Contracts />} />
        <Route path="documents" element={<Documents />} />
        <Route path="history" element={<History />} />
      </Route>
    </Route>
  );
};
