export var EOptionsType;
(function (EOptionsType) {
    EOptionsType["FillIn"] = "FILL_IN";
    EOptionsType["Preview"] = "PREVIEW";
})(EOptionsType || (EOptionsType = {}));
export var ETextAlignment;
(function (ETextAlignment) {
    ETextAlignment["Left"] = "left";
    ETextAlignment["Center"] = "center";
    ETextAlignment["Right"] = "right";
    ETextAlignment["Justify"] = "justify";
})(ETextAlignment || (ETextAlignment = {}));
export var EDisplayType;
(function (EDisplayType) {
    EDisplayType["Template"] = "template";
    EDisplayType["TemplateDocument"] = "templateDocument";
    EDisplayType["TemplateDocumentEditor"] = "templateDocumentEditor";
    EDisplayType["TemplateDocumentExternal"] = "TemplateDocumentExternal";
    EDisplayType["Print"] = "print";
})(EDisplayType || (EDisplayType = {}));
export var EVariableType;
(function (EVariableType) {
    EVariableType["String"] = "string";
    EVariableType["Number"] = "number";
    EVariableType["Date"] = "date";
    EVariableType["Boolean"] = "boolean";
    EVariableType["Dropdown"] = "dropdown";
    EVariableType["Image"] = "image";
    EVariableType["Signature"] = "signature";
})(EVariableType || (EVariableType = {}));
export var EConditionType;
(function (EConditionType) {
    EConditionType["IsEmpty"] = "isEmpty";
    EConditionType["IsNotEmpty"] = "isNotEmpty";
    EConditionType["TextContains"] = "textContains";
    EConditionType["TextDoesNotContain"] = "textDoesNotContain";
    EConditionType["TextStartsWith"] = "textStartsWith";
    EConditionType["TextEndsWith"] = "textEndsWith";
    EConditionType["TextIsExactly"] = "textIsExactly";
    EConditionType["GreaterThan"] = "greaterThan";
    EConditionType["GreaterThanOrEqualTo"] = "greaterThanOrEqualTo";
    EConditionType["LessThan"] = "lessThan";
    EConditionType["LessThanOrEqualTo"] = "lessThanOrEqualTo";
    EConditionType["IsEqualTo"] = "isEqualTo";
    EConditionType["IsNotEqualTo"] = "isNotEqualTo";
    // IsBetween = "isBetween",
    // IsNotBetween = "isNotBetween",
    EConditionType["DateIs"] = "dateIs";
    EConditionType["DateIsBefore"] = "dateIsBefore";
    EConditionType["DateIsAfter"] = "dateIsAfter";
    EConditionType["IsTrue"] = "isTrue";
    EConditionType["IsFalse"] = "isFalse";
})(EConditionType || (EConditionType = {}));
