export var EClientType;
(function (EClientType) {
    EClientType["Broker"] = "BROKER";
    // cspell:ignore PATRIMONYCOMPANY
    EClientType["PatrimonyCompany"] = "PATRIMONYCOMPANY";
    EClientType["Partner"] = "PARTNER";
})(EClientType || (EClientType = {}));
export var EIntakeType;
(function (EIntakeType) {
    EIntakeType[EIntakeType["TwoPhases"] = 1] = "TwoPhases";
    EIntakeType[EIntakeType["OnePhase"] = 2] = "OnePhase";
    EIntakeType[EIntakeType["TwoPhasesWithVisit"] = 3] = "TwoPhasesWithVisit";
})(EIntakeType || (EIntakeType = {}));
export var EBrokerFeature;
(function (EBrokerFeature) {
    EBrokerFeature["Bookkeeping"] = "BOOKKEEPING";
    EBrokerFeature["CompleteContractFlow"] = "COMPLETE_CONTRACT_FLOW";
    EBrokerFeature["DuplicateContacts"] = "DUPLICATE_CONTACTS";
    EBrokerFeature["ExportHistory"] = "EXPORT_HISTORY";
    EBrokerFeature["Ibanisation"] = "IBANISATION";
    EBrokerFeature["Insurance"] = "INSURANCE";
    EBrokerFeature["IntakeIncludeIncomeStatement"] = "INTAKE_INCLUDE_INCOME_STATEMENT";
    EBrokerFeature["IntakeIncomeOptional"] = "INTAKE_INCOME_OPTIONAL";
    EBrokerFeature["Invoicing"] = "INVOICING";
    EBrokerFeature["KeypointRepair"] = "KEYPOINT_REPAIR";
    EBrokerFeature["MangoPayWebPayments"] = "MANGO_PAY_WEB_PAYMENTS";
    EBrokerFeature["MoveService"] = "MOVE_SERVICE";
    EBrokerFeature["PaymentV2"] = "PAYMENT_V2";
    EBrokerFeature["RentDiscount"] = "RENT_DISCOUNT";
    EBrokerFeature["RentioLegal"] = "RENTIO_LEGAL";
    EBrokerFeature["TenantEndingNotification"] = "TENANT_ENDING_NOTIFICATION";
})(EBrokerFeature || (EBrokerFeature = {}));
export var EBrokerUsageStatus;
(function (EBrokerUsageStatus) {
    EBrokerUsageStatus["Green"] = "green";
    EBrokerUsageStatus["Orange"] = "orange";
    EBrokerUsageStatus["Red"] = "red";
})(EBrokerUsageStatus || (EBrokerUsageStatus = {}));
export var EBrokerBookkeepingSyncType;
(function (EBrokerBookkeepingSyncType) {
    EBrokerBookkeepingSyncType["Charge"] = "CHARGE";
    EBrokerBookkeepingSyncType["Payout"] = "PAYOUT";
})(EBrokerBookkeepingSyncType || (EBrokerBookkeepingSyncType = {}));
