export var LoaderStyles = function () {
    return {
        wrapper: {
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 1301,
            width: "100%",
        },
    };
};
