var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import keyBy from "lodash/keyBy";
import { createReducer } from "redux-act";
import * as paymentDisputeActions from "./paymentDispute.actions";
export var REDUCER_KEY_PAYMENTDISPUTE = "paymentDispute";
var getDefaultState = function () { return ({
    // All paymentDisputes
    paymentDisputes: {},
    // paymentDisputes by ID
    paymentDisputesById: {},
    // paymentDisputes by identifier
    paymentDisputesByIdentifier: {},
    // Search paymentDisputes
    searchQuery: undefined,
    isSearchingPaymentDisputes: false,
    searchPaymentDisputesError: undefined,
    searchedPaymentDisputeIds: undefined,
    // paymentDisputes paged
    pagedPaymentDisputes: {},
    //checksum
    checksum: {
        externalValue: 0,
        ownTotal: 0,
    },
    checksumError: undefined,
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(paymentDisputeActions.getPaymentDispute.types.START, function (state, _a) {
    var _b;
    var paymentDisputeId = _a.paymentDisputeId;
    return (__assign(__assign({}, state), { paymentDisputesById: __assign(__assign({}, state.paymentDisputesById), (_b = {}, _b[paymentDisputeId] = {
            isFetching: true,
            fetchError: undefined,
        }, _b)) }));
});
reducer.on(paymentDisputeActions.getPaymentDispute.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var paymentDisputeId = _a.paymentDisputeId, paymentDispute = _a.paymentDispute;
    return (__assign(__assign({}, state), { paymentDisputes: __assign(__assign({}, state.paymentDisputes), (_b = {}, _b[paymentDisputeId] = paymentDispute, _b)), paymentDisputesById: __assign(__assign({}, state.paymentDisputesById), (_c = {}, _c[paymentDisputeId] = {
            isFetching: false,
            fetchError: undefined,
        }, _c)) }));
});
reducer.on(paymentDisputeActions.getPaymentDispute.types.FAILURE, function (state, _a) {
    var _b;
    var paymentDisputeId = _a.paymentDisputeId, error = _a.error;
    return (__assign(__assign({}, state), { paymentDisputesById: __assign(__assign({}, state.paymentDisputesById), (_b = {}, _b[paymentDisputeId] = {
            isFetching: false,
            fetchError: error,
        }, _b)) }));
});
reducer.on(paymentDisputeActions.getPaymentDisputesPaged.types.START, function (state, _a) {
    var _b, _c;
    var _d, _e, _f;
    var identifier = _a.identifier, _g = _a.page, page = _g === void 0 ? 0 : _g, filterData = _a.filterData;
    return (__assign(__assign({}, state), { pagedPaymentDisputes: __assign(__assign({}, state.pagedPaymentDisputes), (_b = {}, _b[identifier] = __assign(__assign({}, state.pagedPaymentDisputes[identifier]), { filterData: filterData, pages: __assign(__assign({}, (_d = state.pagedPaymentDisputes[identifier]) === null || _d === void 0 ? void 0 : _d.pages), (_c = {}, _c[page] = __assign(__assign({}, (_f = (_e = state.pagedPaymentDisputes[identifier]) === null || _e === void 0 ? void 0 : _e.pages) === null || _f === void 0 ? void 0 : _f[page]), { isFetching: true, fetchError: undefined }), _c)) }), _b)) }));
});
reducer.on(paymentDisputeActions.getPaymentDisputesPaged.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var _d, _e, _f;
    var identifier = _a.identifier, _g = _a.page, page = _g === void 0 ? 0 : _g, paymentDisputes = _a.paymentDisputes;
    return (__assign(__assign({}, state), { paymentDisputes: __assign(__assign({}, state.paymentDisputes), keyBy(paymentDisputes, "id")), pagedPaymentDisputes: __assign(__assign({}, state.pagedPaymentDisputes), (_b = {}, _b[identifier] = __assign(__assign({}, state.pagedPaymentDisputes[identifier]), { pages: __assign(__assign({}, (_d = state.pagedPaymentDisputes[identifier]) === null || _d === void 0 ? void 0 : _d.pages), (_c = {}, _c[page] = __assign(__assign({}, (_f = (_e = state.pagedPaymentDisputes[identifier]) === null || _e === void 0 ? void 0 : _e.pages) === null || _f === void 0 ? void 0 : _f[page]), { paymentDisputeIds: paymentDisputes.map(function (paymentDispute) { return paymentDispute.id; }), isFetching: false, fetchError: undefined }), _c)) }), _b)) }));
});
reducer.on(paymentDisputeActions.getPaymentDisputesPaged.types.FAILURE, function (state, _a) {
    var _b, _c;
    var _d, _e, _f;
    var identifier = _a.identifier, _g = _a.page, page = _g === void 0 ? 0 : _g, error = _a.error;
    return (__assign(__assign({}, state), { pagedPaymentDisputes: __assign(__assign({}, state.pagedPaymentDisputes), (_b = {}, _b[identifier] = __assign(__assign({}, state.pagedPaymentDisputes[identifier]), { pages: __assign(__assign({}, (_d = state.pagedPaymentDisputes[identifier]) === null || _d === void 0 ? void 0 : _d.pages), (_c = {}, _c[page] = __assign(__assign({}, (_f = (_e = state.pagedPaymentDisputes[identifier]) === null || _e === void 0 ? void 0 : _e.pages) === null || _f === void 0 ? void 0 : _f[page]), { isFetching: false, fetchError: error }), _c)) }), _b)) }));
});
reducer.on(paymentDisputeActions.getPaymentDisputes.types.START, function (state, _a) {
    var _b;
    var paymentDisputeIdentifier = _a.paymentDisputeIdentifier, extraFilterData = _a.extraFilterData;
    var prevStateForIdentifier = state.paymentDisputesByIdentifier[paymentDisputeIdentifier] || {};
    return __assign(__assign({}, state), { paymentDisputesByIdentifier: __assign(__assign({}, state.paymentDisputesByIdentifier), (_b = {}, _b[paymentDisputeIdentifier] = __assign(__assign({ paymentDisputeIds: undefined, isExhausted: false }, prevStateForIdentifier), { extraFilterData: extraFilterData, isFetching: true, fetchError: undefined }), _b)) });
});
reducer.on(paymentDisputeActions.getPaymentDisputes.types.SUCCESS, function (state, _a) {
    var _b;
    var paymentDisputeIdentifier = _a.paymentDisputeIdentifier, refetch = _a.refetch, paymentDisputes = _a.paymentDisputes, isExhausted = _a.isExhausted;
    var prevStateForIdentifier = state.paymentDisputesByIdentifier[paymentDisputeIdentifier] || {};
    var paymentDisputeIds = paymentDisputes.map(function (_a) {
        var id = _a.id;
        return id;
    });
    return __assign(__assign({}, state), { paymentDisputes: __assign(__assign({}, state.paymentDisputes), keyBy(paymentDisputes, "id")), paymentDisputesByIdentifier: __assign(__assign({}, state.paymentDisputesByIdentifier), (_b = {}, _b[paymentDisputeIdentifier] = __assign(__assign({ extraFilterData: undefined }, prevStateForIdentifier), { isFetching: false, fetchError: undefined, isExhausted: isExhausted, paymentDisputeIds: refetch
                ? paymentDisputeIds
                : __spreadArray(__spreadArray([], (prevStateForIdentifier.paymentDisputeIds || []), true), paymentDisputeIds, true) }), _b)) });
});
reducer.on(paymentDisputeActions.getPaymentDisputes.types.FAILURE, function (state, _a) {
    var _b;
    var paymentDisputeIdentifier = _a.paymentDisputeIdentifier, error = _a.error;
    var prevStateForIdentifier = state.paymentDisputesByIdentifier[paymentDisputeIdentifier] || {};
    return __assign(__assign({}, state), { paymentDisputesByIdentifier: __assign(__assign({}, state.paymentDisputesByIdentifier), (_b = {}, _b[paymentDisputeIdentifier] = __assign(__assign({ extraFilterData: undefined, paymentDisputeIds: undefined, isExhausted: false }, prevStateForIdentifier), { isFetching: false, fetchError: error }), _b)) });
});
reducer.on(paymentDisputeActions.searchPaymentDisputes.types.START, function (state, _a) {
    var query = _a.query;
    return (__assign(__assign({}, state), { searchQuery: query, isSearchingPaymentDisputes: true, searchPaymentDisputesError: undefined }));
});
reducer.on(paymentDisputeActions.searchPaymentDisputes.types.SUCCESS, function (state, _a) {
    var query = _a.query, paymentDisputes = _a.paymentDisputes;
    return (__assign(__assign({}, state), { paymentDisputes: __assign(__assign({}, state.paymentDisputes), keyBy(paymentDisputes, "id")), searchQuery: query, isSearchingPaymentDisputes: false, searchPaymentDisputesError: undefined, searchedPaymentDisputeIds: paymentDisputes.map(function (_a) {
            var id = _a.id;
            return id;
        }) }));
});
reducer.on(paymentDisputeActions.searchPaymentDisputes.types.FAILURE, function (state, _a) {
    var query = _a.query, error = _a.error;
    return (__assign(__assign({}, state), { searchQuery: query, isSearchingPaymentDisputes: false, searchPaymentDisputesError: error }));
});
reducer.on(paymentDisputeActions.getCheckSum.types.START, function (state) { return (__assign(__assign({}, state), { checksum: {
        externalValue: 0,
        ownTotal: 0,
    }, checksumError: undefined })); });
reducer.on(paymentDisputeActions.getCheckSum.types.SUCCESS, function (state, _a) {
    var checksum = _a.checksum;
    return (__assign(__assign({}, state), { checksum: {
            externalValue: checksum.externalValue,
            ownTotal: checksum.ownTotal,
        }, checksumError: undefined }));
});
reducer.on(paymentDisputeActions.getCheckSum.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { checksum: {
            externalValue: 0,
            ownTotal: 0,
        }, checksumError: error }));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
