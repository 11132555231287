import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { getYear } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { compact } from "lodash";
import { forwardRef } from "react";
import { default as DatePicker } from "react-datepicker";
import { Button } from "../Button";
import { ESpacings } from "../Grid";
import { Icon } from "../Icon";
import * as S from "../SharedForm/fields/DateField/DateField.styled";
import { defineStartYear, defineYearAmount, } from "../SharedForm/fields/DateField/DateField.util";
import { StyledBackdrop, StyledFloatIcon, StyledTextField, } from "../TextField/TextField.styled";
export var BaseDatePicker = forwardRef(function (props, ref) {
    var id = props.id, hideDayPicker = props.hideDayPicker, hasError = props.hasError, isDisabled = props.isDisabled, placeholderText = props.placeholderText, locale = props.locale, selected = props.selected, minDate = props.minDate, maxDate = props.maxDate, required = props.required, dateFormat = props.dateFormat, showTimeSelect = props.showTimeSelect, _a = props.inverseYears, inverseYears = _a === void 0 ? false : _a, onChange = props.onChange, onMonthChange = props.onMonthChange, onYearChange = props.onYearChange, renderCustomHeader = props.renderCustomHeader, handleClear = props.handleClear, handleIconClick = props.handleIconClick;
    var renderHeader = function (params) {
        var changeYear = params.changeYear, changeMonth = params.changeMonth, decreaseMonth = params.decreaseMonth, increaseMonth = params.increaseMonth, prevMonthButtonDisabled = params.prevMonthButtonDisabled, nextMonthButtonDisabled = params.nextMonthButtonDisabled, date = params.date;
        var yearAmount = defineYearAmount({ minDate: minDate, maxDate: maxDate });
        var yearStart = defineStartYear({ minDate: minDate, maxDate: maxDate, yearAmount: yearAmount });
        var yearsArray = Array(yearAmount)
            .fill(0)
            .map(function (_, index) { return yearStart + index; });
        if (inverseYears) {
            yearsArray.reverse();
        }
        var handleMonthChange = function (_a) {
            var value = _a.target.value;
            changeMonth(value);
        };
        var handleYearChange = function (_a) {
            var value = _a.target.value;
            changeYear(value);
        };
        var monthsToShow = compact([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (month) {
            if (minDate &&
                getYear(minDate) === getYear(date) &&
                month < minDate.getMonth()) {
                return undefined;
            }
            if (maxDate &&
                getYear(maxDate) === getYear(date) &&
                month > maxDate.getMonth()) {
                return undefined;
            }
            return {
                name: formatDate(new Date(2000, month, 1), "MMMM"),
                index: month,
            };
        }));
        return (_jsxs("div", { children: [!prevMonthButtonDisabled && (_jsx(S.PreviousMonth, { appearance: "plain", onClick: decreaseMonth, children: _jsx(Icon, { source: "chevronLeft" }) })), !nextMonthButtonDisabled && (_jsx(S.NextMonth, { appearance: "plain", onClick: increaseMonth, children: _jsx(Icon, { source: "chevronRight" }) })), _jsx(S.CurrentMonth, { children: formatDate(date, "MMMM yyyy") }), _jsxs(S.SelectMonthYear, { children: [_jsx("select", { value: date.getMonth(), onChange: handleMonthChange, children: monthsToShow.map(function (month) { return (_jsx("option", { value: month.index, children: month.name }, month.index)); }) }), _jsx("select", { value: date.getFullYear(), onChange: handleYearChange, children: yearsArray.map(function (year, index) { return (_jsx("option", { value: year, children: year }, index)); }) })] })] }));
    };
    return (_jsx(S.DatePickerContainer, { hideDayPicker: hideDayPicker, children: _jsxs(StyledTextField, { hasError: hasError, children: [_jsx(StyledBackdrop, {}), _jsx(DatePicker, { ref: ref, placeholderText: placeholderText, id: id, locale: locale !== null && locale !== void 0 ? locale : getLocale(), selected: selected, minDate: minDate, maxDate: maxDate, onChange: onChange, onMonthChange: onMonthChange, onYearChange: onYearChange, showMonthDropdown: true, showYearDropdown: true, dateFormat: dateFormat, dropdownMode: "select", showTimeSelect: showTimeSelect, renderCustomHeader: renderCustomHeader !== null && renderCustomHeader !== void 0 ? renderCustomHeader : renderHeader, showMonthYearDropdown: false, autoComplete: "off", disabled: isDisabled, popperPlacement: "top-end", popperModifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [5, 10],
                            },
                        },
                        {
                            name: "preventOverflow",
                            options: {
                                rootBoundary: "viewport",
                                tether: false,
                                altAxis: true,
                            },
                        },
                    ] }), !isDisabled && selected && !required && (_jsx(Box, { pt: ESpacings.extraTight, pr: ESpacings.tight, children: _jsx(Button, { onClick: handleClear, appearance: "plain", children: _jsx(Icon, { source: "removeCircle" }) }) })), _jsx(StyledFloatIcon, { onClick: handleIconClick, children: _jsx(Icon, { source: "calendar" }) })] }) }));
});
