var _a, _b, _c, _d, _e;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./paymentOrder.constants";
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["CreatePaymentOrders"] = "CREATE_PAYMENT_ORDERS";
    EActionCustom["GetPayeeBankAccount"] = "GET_PAYEE_BANK_ACCOUNT";
    EActionCustom["GetPayerBankAccount"] = "GET_PAYER_BANK_ACCOUNT";
    EActionCustom["GetMissingKYCs"] = "GET_MISSING_KYCS";
})(EActionCustom || (EActionCustom = {}));
var generateGetPayeeBankAccountActions = function () { return ({
    getPayeeBankAccountStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GetPayeeBankAccount,
        state: EActionState.Start,
    }),
    getPayeeBankAccountSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GetPayeeBankAccount,
        state: EActionState.Success,
    }),
    getPayeeBankAccountFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GetPayeeBankAccount,
        state: EActionState.Fail,
    }),
}); };
var generateGetPayerBankAccountActions = function () { return ({
    getPayerBankAccountStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GetPayerBankAccount,
        state: EActionState.Start,
    }),
    getPayerBankAccountSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GetPayerBankAccount,
        state: EActionState.Success,
    }),
    getPayerBankAccountFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GetPayerBankAccount,
        state: EActionState.Fail,
    }),
}); };
var generateGetMissingKYCsActions = function () { return ({
    getMissingKYCsStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GetMissingKYCs,
        state: EActionState.Start,
    }),
    getMissingKYCsSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GetMissingKYCs,
        state: EActionState.Success,
    }),
    getMissingKYCsFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GetMissingKYCs,
        state: EActionState.Fail,
    }),
}); };
var generateCreatePaymentOrders = function () {
    var createPaymentOrdersStart = createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.CreatePaymentOrders,
        state: EActionState.Start,
    });
    var createPaymentOrdersSucceeded = createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.CreatePaymentOrders,
        state: EActionState.Success,
    });
    var createPaymentOrdersFailed = createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.CreatePaymentOrders,
        state: EActionState.Fail,
    });
    return {
        createPaymentOrdersStart: createPaymentOrdersStart,
        createPaymentOrdersSucceeded: createPaymentOrdersSucceeded,
        createPaymentOrdersFailed: createPaymentOrdersFailed,
    };
};
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, getInfiniteLoadStart = _a.getInfiniteLoadStart, getInfiniteLoadFailed = _a.getInfiniteLoadFailed, getInfiniteLoadSucceeded = _a.getInfiniteLoadSucceeded, createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed, updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed;
export var getPayeeBankAccountStart = (_b = generateGetPayeeBankAccountActions(), _b.getPayeeBankAccountStart), getPayeeBankAccountSucceeded = _b.getPayeeBankAccountSucceeded, getPayeeBankAccountFailed = _b.getPayeeBankAccountFailed;
export var getPayerBankAccountStart = (_c = generateGetPayerBankAccountActions(), _c.getPayerBankAccountStart), getPayerBankAccountSucceeded = _c.getPayerBankAccountSucceeded, getPayerBankAccountFailed = _c.getPayerBankAccountFailed;
export var getMissingKYCsStart = (_d = generateGetMissingKYCsActions(), _d.getMissingKYCsStart), getMissingKYCsSucceeded = _d.getMissingKYCsSucceeded, getMissingKYCsFailed = _d.getMissingKYCsFailed;
export var createPaymentOrdersStart = (_e = generateCreatePaymentOrders(), _e.createPaymentOrdersStart), createPaymentOrdersSucceeded = _e.createPaymentOrdersSucceeded, createPaymentOrdersFailed = _e.createPaymentOrdersFailed;
