import { getFontSize, useTheme } from "@rentiohq/web-shared-next/dist/themes";
import { EFontSizes } from "@rentiohq/web-shared-next/dist/themes/types";

export const SupportCardStyles = () => {
  const theme = useTheme();

  return {
    wrapper: {
      p: 3,
      borderRadius: "8px",
      border: `1px solid ${theme.palette.grey[200]}`,
      minWidth: "330px",
    },
    content: {
      avatar: {
        width: "72px",
        height: "72px",
        background: theme.palette.grey[50],

        svg: {
          color: theme.palette.success.main,
        },
      },
      label: {
        fontSize: getFontSize(EFontSizes.ExtraSmall),
      },
      value: {
        color: theme.palette.grey[900],
      },
    },
  };
};
