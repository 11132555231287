import { EVariableType } from "../../../Editor/Editor.types";
import { OPTION_NEW } from "../variableForm.utils";
import { EField } from "./schema.insertVariable.types";
export var getInitialValues = function (extraData) {
    var _a;
    var _b = extraData.forceCreateNewVariable, forceCreateNewVariable = _b === void 0 ? false : _b;
    return _a = {},
        // [EField.Name]: OPTION_NEW,
        // [EField.Group]: OPTION_NEW,
        _a[EField.Name] = forceCreateNewVariable ? OPTION_NEW : undefined,
        _a[EField.Group] = undefined,
        _a[EField.Type] = EVariableType.String,
        _a[EField.EditableByTenant] = "no",
        _a[EField.Required] = "yes",
        _a[EField.Persisted] = "no",
        _a;
};
