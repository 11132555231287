import { ERentioFormField } from "../../components/components/Form";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createPayment.types";
import { customContactsSelector, customContactsValueSelector, getCreatePaymentCompanyFieldsSchema, getCreatePaymentContactFieldsSchema, getDefaultPayerPayeeValue, getNavigationTitle, getRelatedContract, getSubtitle, } from "./schema.createPayment.utils";
import { customGetContactsActions, customIsFetchingSelector, } from "./schema.createPayment.utils.fetchContacts";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        var contract = getRelatedContract(extraData, defaultValues);
        return {
            required: [EField.Payer],
            properties: (_a = {},
                _a[EField.Header4] = {
                    default: undefined,
                },
                _a[EField.Payer] = {
                    default: getDefaultPayerPayeeValue({
                        key: EField.Payer,
                        initialValues: initialValues,
                        defaultValues: defaultValues,
                        type: defaultValues[EField.TypeRecurring] ||
                            defaultValues[EField.TypeOnce],
                        contract: contract,
                    }),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("payments.create.payer.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData),
            "rentio:summaryTitle": getLocalizedText("payment.payment_order.payer")
        },
        _a[EField.Header4] = {
            "ui:field": ERentioFormField.SectionHeader,
            getTitle: function (formData) {
                return getLocalizedText("payments.create.payer.heading", {
                    type: formData.otherDescription ||
                        getLocalizedText("payment.payment_order.type.".concat(formData.typeRecurring || formData.typeOnce).toLowerCase()),
                });
            },
        },
        _a[EField.Payer] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            getTitle: function (formData) {
                return getLocalizedText("payment.payment_order.payer.heading", {
                    type: formData.otherDescription ||
                        getLocalizedText("payment.payment_order.type.".concat(formData.typeRecurring || formData.typeOnce).toLowerCase()),
                });
            },
            selectContactFromListCtaTitle: getLocalizedText("payment.payment_order.payer.select_other.cta"),
            addCtaLabel: getLocalizedText("payment.payment_order.payer.other.label"),
            isMultiSelect: false,
            asOptionList: true,
            getRequiredContactFieldsSchema: getCreatePaymentContactFieldsSchema,
            getRequiredCompanyFieldsSchema: getCreatePaymentCompanyFieldsSchema,
            withSummaryValidation: false,
            // Custom selectors & actions
            valueSelector: function (state, selectedAccountIds) {
                return customContactsValueSelector({
                    state: state,
                    propertyId: extraData.property.id,
                    selectedAccountIds: selectedAccountIds,
                });
            },
            customGetSubtitleSelector: function (state, formData, item) {
                return getSubtitle({
                    state: state,
                    contract: getRelatedContract(extraData, formData),
                    item: item,
                });
            },
            customExtraAccountsSelector: function (state, formData) {
                if (formData === void 0) { formData = {}; }
                return customContactsSelector({
                    type: formData[EField.TypeRecurring] || formData[EField.TypeOnce],
                    payerType: "payer",
                    state: state,
                    contract: getRelatedContract(extraData, formData),
                });
            },
            customIsFetchingSelector: function (state) {
                return customIsFetchingSelector(state, extraData);
            },
            customGetContactsActions: customGetContactsActions(extraData),
        },
        _a);
};
