import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  Grid,
  Icon,
  Loading,
  Page,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import { useState } from "react";
import { ETabs, ITab } from "utils/tabs.utils";
import { CancelledContractHistory } from "./components/CancelledContractHistory";
import { CancelledContractInfo } from "./components/CancelledContractInfo/CancelledContractInfo";

interface IProps {
  cancelledContractId: string;
}

const getMetaData = (contract: IContract, property: IProperty) => {
  return (
    <>
      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon
            source={utils.properties.getIcon(property.typeId || 0)}
            size="small"
          />
        </Grid.Item>
        <Grid.Item>
          <Button appearance="link" url={`/properties/${property.id}`}>
            {formatAddress(property)}
          </Button>
        </Grid.Item>
      </Grid>
      {contract.dateOfCancellation && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="calendar" size="small" />
            </Grid.Item>
            <Grid.Item>
              {getLocalizedText("system.created_at", {
                date: formatDate(contract.dateOfCancellation),
              })}
            </Grid.Item>
          </Grid>
        </>
      )}
    </>
  );
};

export const CancelledContract = ({ cancelledContractId }: IProps) => {
  const [tab, setTab] = useState<ETabs>(ETabs.Info);
  // Redux
  const { detail: contract, isFetching } = contractHooks.useDetail({
    id: cancelledContractId,
  });

  const { detail: property, isFetching: isFetchingProperty } =
    propertyHooks.useDetail({
      id: contract?.propertyId,
    });

  const tabs: ITab[] = [
    {
      content: getLocalizedText(`system.tab.${ETabs.Info}`),
      onClick: () => setTab(ETabs.Info),
      isActive: tab === ETabs.Info,
    },
    {
      content: getLocalizedText(`system.tab.${ETabs.History}`),
      onClick: () => setTab(ETabs.History),
      isActive: tab === ETabs.History,
    },
  ];

  if (isFetching || isFetchingProperty) return <Loading />;
  if (!property || !contract) return null;
  return (
    <Page
      metadata={getMetaData(contract, property)}
      tabs={tabs}
      title={getLocalizedText("contract.cancellation.cancelled_contract")}
    >
      {tab === ETabs.Info && (
        <CancelledContractInfo
          contract={contract}
          onMoreHistoryClick={() => {
            setTab(ETabs.History);
          }}
        />
      )}
      {tab === ETabs.History && (
        <CancelledContractHistory contract={contract} />
      )}
    </Page>
  );
};
