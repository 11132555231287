var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getSchema as getSchema1, uiSchema as uiSchema1, } from "./schema.editContact.1";
import { getSchema as getSchema2, uiSchema as uiSchema2, } from "./schema.editContact.2";
import { getSchema as getSchema3, uiSchema as uiSchema3, } from "./schema.editContact.3";
import { getSchema as getSchema4 } from "./schema.editContact.4";
import { getInitialValues } from "./schema.editContact.utils";
import { uiSchema } from "./uiSchema.editContact";
export var editContractList = function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema2(initialValues, extraData),
            uiSchema: __assign(__assign({}, uiSchema1(extraData)), uiSchema(extraData)),
            showIf: function () { return extraData.contact.isCompany; },
        },
        {
            schema: getSchema1(initialValues, extraData),
            uiSchema: __assign(__assign({}, uiSchema2(extraData)), uiSchema(extraData)),
        },
        {
            schema: getSchema3(initialValues, extraData),
            uiSchema: __assign(__assign({}, uiSchema3(extraData)), uiSchema(extraData)),
        },
        // {
        //   schema: getSchema4(initialValues, extraData),
        //   uiSchema: { ...uiSchema4(extraData), ...uiSchema(extraData) },
        // },
    ];
};
export var personSchemas = function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema1(initialValues, extraData),
            uiSchema: uiSchema1(extraData),
        },
    ];
};
export var companySchemas = function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema2(initialValues, extraData),
            uiSchema: uiSchema(extraData),
        },
    ];
};
export var extraSchemas = function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema3(initialValues, extraData),
            uiSchema: uiSchema(extraData),
        },
    ];
};
// export const invoicingConfigSchemas = (extraData: IExtraData) => {
//   const initialValues = getInitialValues(extraData);
//   return [
//     {
//       schema: getSchemaInvoicingConfig(initialValues, extraData),
//       uiSchema: uiSchemaInvoicingConfig(extraData),
//     },
//   ];
// };
export var financialSchemas = function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema4(initialValues, extraData),
            uiSchema: uiSchema(extraData),
        },
    ];
};
