import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { PROPERTY_TYPES } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { Grid, Icon, TAssetSource } from "@rentiohq/web-shared/dist/components";
import { sortBy } from "lodash";
import React from "react";
import { ForRentSwitch } from "scenes/Properties/components/ForRentSwitch";

interface IProps {
  property: IProperty;
}

export const PropertyMeta: React.FC<IProps> = ({ property }) => {
  const propertyType = PROPERTY_TYPES.find(
    (type: any) => type.id === property.typeId,
  );
  const propertyPrice = property?.price
    ? formatCurrency(property.price)
    : undefined;
  const propertyCosts = property?.costs
    ? formatCurrency(property.costs)
    : undefined;

  const propertyOwners = property.members
    ? join(
        sortBy(
          property.members
            .filter(member => member.roles.includes(EPropertyMemberTypes.Owner))
            .map(member => getName(member.account)),
          "account.id",
        ),
      )
    : undefined;

  return (
    <Grid alignItems="center">
      <ForRentSwitch
        propertyId={property.id}
        forRentStatus={property.forRentStatus}
      />

      {propertyType && (
        <Grid.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source={propertyType.icon as TAssetSource} size="small" />
            </Grid.Item>
            <Grid.Item>
              {getLocalizedText(
                `property.type.${propertyType.label}`.toLowerCase(),
              )}
            </Grid.Item>
          </Grid>
        </Grid.Item>
      )}

      {!!propertyOwners && (
        <Grid.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="broker" size="small" />
            </Grid.Item>
            <Grid.Item>{propertyOwners}</Grid.Item>
          </Grid>
        </Grid.Item>
      )}

      {propertyPrice && (
        <Grid.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="currencyEuroCircle" size="small" />
            </Grid.Item>
            <Grid.Item>{propertyPrice}</Grid.Item>
          </Grid>
        </Grid.Item>
      )}

      {propertyCosts && (
        <Grid.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="houseWrench" size="small" />
            </Grid.Item>
            <Grid.Item>{propertyCosts}</Grid.Item>
          </Grid>
        </Grid.Item>
      )}
    </Grid>
  );
};
