var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { compact, flattenDepth, uniq } from "lodash";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Error as ErrorComponent } from "../../Error/Error";
import { Loading } from "../../Loading";
import { Modal } from "../../Modal";
import { MultiStepFormContainer } from "./MultiStepForm.container";
export var PrefetchGuard = forwardRef(function (props, ref) {
    var _a = props.asModal, asModal = _a === void 0 ? false : _a, schemas = props.schemas;
    var _b = useState([]), fetchedIds = _b[0], setFetchedIds = _b[1];
    // Get prefilled account ID's from all steps
    var accountIds = uniq(flattenDepth(schemas === null || schemas === void 0 ? void 0 : schemas.map(function (schema) {
        var result = [];
        var schemaFields = schema.schema({}).properties || {};
        var uiSchemaFields = schema.uiSchema;
        Object.keys(uiSchemaFields).forEach(function (fieldKey) {
            var field = uiSchemaFields[fieldKey];
            if (typeof field === "object") {
                if (field["ui:field"] === ERentioFormField.ContactSelectInlineV2 &&
                    field.prefetchDefault) {
                    // @ts-ignore
                    result.push(schemaFields[fieldKey].default || []);
                }
            }
        });
        return result;
    }), 2));
    // Redux
    var dispatch = useDispatch();
    var contacts = useSelector(function (state) {
        var result = {};
        accountIds.forEach(function (accountId) {
            var contact = contactSelectors.getContact(state, undefined, accountId);
            result[accountId] = contact;
        });
        return result;
    });
    var isFetching = useSelector(function (state) {
        var result = {};
        accountIds.forEach(function (accountId) {
            var isFetching = contactSelectors.getContactIsFetching(state, undefined, accountId);
            result[accountId] = isFetching;
        });
        return result;
    });
    // Hooks
    useEffect(function () {
        var accountIdsToFetch = [];
        accountIds.forEach(function (accountId) {
            var didFetchContact = fetchedIds.includes(accountId);
            var isFetchingContact = !!isFetching[accountId];
            var hasContact = !!contacts[accountId];
            if (!isFetchingContact && !hasContact && !didFetchContact) {
                accountIdsToFetch.push(accountId);
            }
        });
        if (accountIdsToFetch.length > 0) {
            setFetchedIds(__spreadArray(__spreadArray([], fetchedIds, true), accountIdsToFetch, true));
            dispatch(contactActions.getContactsByAccountIds.actions.start({
                accountIds: accountIdsToFetch,
            }));
        }
    }, [accountIds]);
    // Render
    var hasAllContacts = compact(Object.values(contacts)).length === accountIds.length;
    if (Object.values(isFetching).some(function (item) { return item === true; })) {
        if (asModal) {
            return (_jsx(Modal, { onClose: function () { }, children: _jsx(Loading, {}) }));
        }
        return _jsx(Loading, {});
    }
    if (!hasAllContacts) {
        var unknownAccountIds = accountIds.filter(function (accountId) {
            return !Object.values(contacts).find(function (x) { return (x === null || x === void 0 ? void 0 : x.accountId) === accountId || (x === null || x === void 0 ? void 0 : x.accountIds.includes(accountId)); });
        });
        var errorComponent = (_jsx(ErrorComponent, { errorHeading: getLocalizedText("error.unknown_contacts", {
                count: "".concat(unknownAccountIds.length),
            }, unknownAccountIds.length), errors: [
                new Error("Unknown account ID's ".concat(join(unknownAccountIds.map(function (x) { return "".concat(x); })))),
            ] }));
        if (asModal) {
            _jsx(Modal, { onClose: function () { }, children: errorComponent });
        }
        return errorComponent;
    }
    return _jsx(MultiStepFormContainer, __assign({ ref: ref }, props));
});
