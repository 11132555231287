import {
  EContractLengthType,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EContractEndingStatus } from "./components/ContractCard/components/ContractStatusChip/contractStatusChip.types";

export const getContractDuration = (
  contract: IContract,
): EContractEndingStatus => {
  if (contract.dateOfCancellation) return EContractEndingStatus.Cancelled;
  if (contract.tacitRenewal) {
    return contract.contractLengthType === EContractLengthType.Short
      ? EContractEndingStatus.ShortTerm
      : EContractEndingStatus.LongTerm;
  }
  return EContractEndingStatus.AutoEnding;
};
