var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ASSETS } from "@rentiohq/web-assets";
import React from "react";
import { Lozenge } from "..";
import { assetLoader } from "../../utils/svg";
import * as S from "./Icon.styled";
var IconBase = function (props) {
    var _a;
    var source = props.source, color = props.color, realColor = props.realColor, _b = props.size, size = _b === void 0 ? "medium" : _b, accessibilityLabel = props.accessibilityLabel, _c = props.useCurrentColor, useCurrentColor = _c === void 0 ? true : _c, badge = props.badge, rest = __rest(props, ["source", "color", "realColor", "size", "accessibilityLabel", "useCurrentColor", "badge"]);
    var assetSource = undefined;
    if (source) {
        assetSource = assetLoader(ASSETS[source] || ASSETS["questionMark"]);
    }
    return (_jsxs(S.StyledIcon
    // @ts-ignore
    , __assign({ 
        // @ts-ignore
        size: size, color: color, realColor: realColor, "aria-label": accessibilityLabel, useCurrentColor: useCurrentColor }, rest, { children: [(assetSource === null || assetSource === void 0 ? void 0 : assetSource.viewBox) && (assetSource === null || assetSource === void 0 ? void 0 : assetSource.body) && (_jsx(S.IconWrap, { children: _jsx("svg", { viewBox: assetSource.viewBox, dangerouslySetInnerHTML: { __html: assetSource.body }, focusable: "false", "aria-hidden": "true" }) })), (badge === null || badge === void 0 ? void 0 : badge.content) && (_jsx("div", { style: { position: "absolute", top: -8, right: -5 }, children: _jsx(Lozenge, { isBold: true, appearance: badge.appearance, children: (_a = badge.content) !== null && _a !== void 0 ? _a : "" }) }))] })));
};
export var Icon = React.memo(IconBase);
