import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EPaymentOrderType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { ts } from "../../../../services";
import { renderTooltipChecklist } from "../../../../utils/tooltip.utils";
import StatusDot from "../StatusDot";
import { EStatus } from "../StatusDot/StatusDot.types";

interface IProps {
  contract: IContract;
}

const StatusDotContractPaymentOrders = (props: IProps) => {
  const { contract } = props;

  // Helpers
  const paymentOrdersIdentifier = `contract-${contract.id}`;

  // Redux
  const dispatch = useDispatch();
  const paymentOrders = useSelector((state: IRootStore) =>
    paymentSelectors.getPaymentOrdersByIdentifier(
      state,
      paymentOrdersIdentifier,
    ),
  );
  const isFetching = useSelector((state: IRootStore) =>
    paymentSelectors.isFetchingPaymentOrdersByIdentifier(
      state,
      paymentOrdersIdentifier,
    ),
  );

  // Lifecycle
  React.useEffect(() => {
    if (contract.manuallyCompletedPaymentRent) {
      return;
    }

    if (isFetching) {
      return;
    }

    if (getStatus() === EStatus.Success) {
      return;
    }

    dispatch(
      paymentActions.getPaymentOrdersByIdentifier.actions.start({
        paymentOrdersIdentifier,
        refetch: true,
        filterData: {
          where: { contractId: contract.id },
        },
      }),
    );
  }, [contract]);

  // Helpers
  const getStatus = () => {
    if (contract.manuallyCompletedPaymentRent) {
      return EStatus.Success;
    }

    if (!paymentOrders) {
      return EStatus.None;
    }

    if (paymentOrders.length === 0) {
      return EStatus.Error;
    }

    const hasRent = paymentOrders.find(
      paymentOrder => paymentOrder.type === EPaymentOrderType.Rent,
    );
    if (!hasRent) {
      return EStatus.Error;
    }

    const hasCommonCosts = paymentOrders.find(
      paymentOrder => paymentOrder.type === EPaymentOrderType.CommonCosts,
    );
    if (contract.currentCostsCommon && !hasCommonCosts) {
      return EStatus.Warning;
    }

    const hasNonCommonCosts = paymentOrders.find(
      paymentOrder => paymentOrder.type === EPaymentOrderType.NonCommonCosts,
    );
    if (contract.currentCostsNonCommon && !hasNonCommonCosts) {
      return EStatus.Warning;
    }

    return EStatus.Success;
  };

  // Render
  const renderTooltipContent = () => {
    if (contract.manuallyCompletedPaymentRent) {
      return getLocalizedText(contract.manuallyCompletedPaymentRent);
    }

    if (!paymentOrders) {
      return undefined;
    }

    const checklist = [];

    const labelRent = ts.paymentOrderTypeLabel(EPaymentOrderType.Rent);
    const hasRent = paymentOrders.find(
      paymentOrder => paymentOrder.type === EPaymentOrderType.Rent,
    );

    checklist.push({ label: labelRent, success: !!hasRent });

    if (contract.currentCostsCommon) {
      const label = ts.paymentOrderTypeLabel(EPaymentOrderType.CommonCosts);
      const hasCommonCosts = paymentOrders.find(
        paymentOrder => paymentOrder.type === EPaymentOrderType.CommonCosts,
      );

      checklist.push({
        label,
        success: !!hasCommonCosts,
      });
    }

    if (contract.currentCostsNonCommon) {
      const label = ts.paymentOrderTypeLabel(EPaymentOrderType.NonCommonCosts);
      const hasNonCommonCosts = paymentOrders.find(
        paymentOrder => paymentOrder.type === EPaymentOrderType.NonCommonCosts,
      );

      checklist.push({
        label,
        success: !!hasNonCommonCosts,
      });
    }

    return renderTooltipChecklist(checklist);
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={renderTooltipContent()} />
  );
};

// eslint-disable-next-line import/no-default-export
export default StatusDotContractPaymentOrders;
