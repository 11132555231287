export var Themes;
(function (Themes) {
    Themes["default"] = "default";
    Themes["Theme1"] = "theme1";
    Themes["Theme2"] = "theme2";
    Themes["Theme3"] = "theme3";
    Themes["Theme4"] = "theme4";
    Themes["Theme5"] = "theme5";
    Themes["Theme6"] = "theme6";
})(Themes || (Themes = {}));
export var MenuOrientation;
(function (MenuOrientation) {
    MenuOrientation["VERTICAL"] = "vertical";
    MenuOrientation["HORIZONTAL"] = "horizontal";
})(MenuOrientation || (MenuOrientation = {}));
export var ThemeMode;
(function (ThemeMode) {
    ThemeMode["LIGHT"] = "light";
    ThemeMode["DARK"] = "dark";
})(ThemeMode || (ThemeMode = {}));
export var ThemeDirection;
(function (ThemeDirection) {
    ThemeDirection["LTR"] = "ltr";
    ThemeDirection["RTL"] = "rtl";
})(ThemeDirection || (ThemeDirection = {}));
