import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as t from "../../services/translationService";
import { StyledSearchMoreLink, StyledSearchResultLink, } from "./SearchList.styled";
export var SearchItemContact = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, linkHandler = _a.linkHandler, searchValue = _a.searchValue;
    return (_jsxs(_Fragment, { children: [data.slice(0, 10).map(function (item, index) {
                var link = "/contacts/".concat(item.id);
                return (_jsx(StyledSearchResultLink, { to: link, onClick: linkHandler, children: getName(item) }, "contact".concat(index)));
            }), data.length > 10 && (_jsx(StyledSearchMoreLink, { to: "/contacts?query=".concat(encodeURIComponent(searchValue)), onClick: linkHandler, children: t.searchMore() }))] }));
};
