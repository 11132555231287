var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createReducer } from "redux-act";
import { onMultiple } from "../utils/reducer.utils";
import * as invitesActions from "./invites.actions";
export var REDUCER_KEY_INVITES = "invites";
var getDefaultState = function () { return ({
    invites: undefined,
    isFetching: false,
    fetchError: undefined,
    pendingInvites: [],
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(invitesActions.getInvites.types.START, function (state) { return (__assign(__assign({}, state), { isFetching: true, fetchError: undefined })); });
reducer.on(invitesActions.getInvites.types.SUCCESS, function (state, _a) {
    var invites = _a.invites;
    return (__assign(__assign({}, state), { invites: invites, isFetching: false, fetchError: undefined }));
});
reducer.on(invitesActions.getInvites.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isFetching: false, fetchError: error }));
});
reducer.on(invitesActions.acceptAllInvites.types.START, function (state) { return (__assign(__assign({}, state), { pendingInvites: state.invites
        ? state.invites.map(function (invite) { return invite.account.id; })
        : [] })); });
reducer.on(invitesActions.acceptAllInvites.types.SUCCESS, function (state) { return (__assign(__assign({}, state), { invites: state.invites ? [] : undefined, pendingInvites: [] })); });
reducer.on(invitesActions.acceptAllInvites.types.FAILURE, function (state) { return (__assign(__assign({}, state), { pendingInvites: [] })); });
onMultiple(reducer, [
    invitesActions.acceptInvite.types.START,
    invitesActions.declineInvite.types.START,
], function (state, _a) {
    var invite = _a.invite;
    return (__assign(__assign({}, state), { pendingInvites: __spreadArray(__spreadArray([], state.pendingInvites, true), [invite.account.id], false) }));
});
onMultiple(reducer, [
    invitesActions.acceptInvite.types.SUCCESS,
    invitesActions.declineInvite.types.SUCCESS,
], function (state, _a) {
    var invite = _a.invite;
    return (__assign(__assign({}, state), { invites: state.invites
            ? __spreadArray([], state.invites, true).filter(function (randomInvite) { return invite.account.id !== randomInvite.account.id; })
            : undefined, pendingInvites: __spreadArray([], state.pendingInvites, true).filter(function (accountId) { return invite.account.id !== accountId; }) }));
});
onMultiple(reducer, [
    invitesActions.acceptInvite.types.FAILURE,
    invitesActions.declineInvite.types.FAILURE,
], function (state, _a) {
    var invite = _a.invite;
    return (__assign(__assign({}, state), { pendingInvites: __spreadArray([], state.pendingInvites, true).filter(function (accountId) { return invite.account.id !== accountId; }) }));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
