import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as applicationActions from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.actions";
import * as applicationHooks from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.hooks";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { IApplication } from "@rentiohq/shared-frontend/dist/types/application.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { isBrusselsZip } from "@rentiohq/shared-frontend/dist/utils/property.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Checkbox,
  Drawer,
  DropdownMenu,
  Grid,
  Icon,
  MultiStepForm,
  StyledItemActions,
  StyledItemActionsTrigger,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import React, { useContext } from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getPhaseForStep } from "utils/application.utils";
import createDuplicateApplicationForm from "../../../../../../forms/duplicateApplication";
import * as t from "../../../../../../services/translationService";
import { IApplicationDropDownHandler } from "../../PropertyDetailApplications";
import { DragAndDropContext } from "../../context/DragAndDropContext";
import { ApplicationDetail } from "../ApplicationDetail";
import { DragCount } from "./DragCount";
import {
  StyledDragHandle,
  StyledDragItem,
  StyledDragItemMeta,
} from "./DragItem.styled";
import {
  findCommonNumbers,
  getResidentsCount,
  getTotalIncome,
} from "./dragItem.utils";

export interface IMultiSelectProps {}

export interface IProps extends IMultiSelectProps {
  item: IApplication;
  step?: number;
  index?: number;
  allowedSteps?: number[];
  isDisabled?: boolean;
  renderAsDragItem?: boolean;
  isSelected: boolean;
  handler?: IApplicationDropDownHandler;
}

const formId = generateFormId();

const renderMeta = (item: IApplication, step: number) => {
  if (item.archivedAt) {
    return (
      <>
        {t.applicationStepArchivedAt()}{" "}
        <b>{utils.date.format(item.archivedAt, "dd/MM/yyyy HH:mm")}</b>
      </>
    );
  }

  if (item.stepCompletedAt) {
    return (
      <>
        {t.applicationStepCompletedAt(step)}{" "}
        <b>{utils.date.format(item.stepCompletedAt, "dd/MM/yyyy HH:mm")}</b>
      </>
    );
  }

  return (
    <>
      {t.applicationStepProvidedAt(step)}{" "}
      <b>{utils.date.format(item.stepStartedAt, "dd/MM/yyyy HH:mm")}</b>
    </>
  );
};

// This DragItem should be used with a DragAndDropContext
export const DragItem: React.FC<IProps> = React.memo(props => {
  const {
    item,
    step = 0,
    allowedSteps = [],
    handler,
    index,
    isDisabled,
    renderAsDragItem = true,
    isSelected,
  } = props;

  const dispatch = useDispatch();
  const { broker } = authHooks.useSelf();

  const intakeType = broker?.intakeType || 2;

  const {
    onSelectItem,
    onUnselectItem,
    replaceSelectedItems,
    selectedItems,
    allDropAreas,
  } = useContext(DragAndDropContext);

  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const { archive } = applicationHooks.useArchive({});
  const { unarchive } = applicationHooks.useUnarchive({});

  const { detail: property } = propertyHooks.useDetail({
    id: propertyId,
  });

  const selectedItemIds = selectedItems.map(si => si.id) || [];

  const [showDuplicateModal, setShowDuplicateModal] =
    React.useState<boolean>(false);
  const [detailOpen, setDetailOpen] = React.useState<boolean>(false);
  const { contacts, residents } = item;
  const stepsWithoutExtraInfo = [1, 4, 5, 6, 7];

  const shouldDoActionForSelection =
    selectedItems.length > 1 && selectedItemIds.includes(item.id);

  const getStatusActions = () => {
    // if more than 1 item is selected, only allow status changes that are allowed for
    // all selected items
    let allAllowedSteps = allowedSteps;

    if (shouldDoActionForSelection) {
      const dropAreasForItems = (allDropAreas || []).filter(dropArea =>
        dropArea.items.some(item => selectedItems.includes(item)),
      );
      allAllowedSteps = findCommonNumbers(
        ...dropAreasForItems.map(dropArea => dropArea.allowedSteps),
      );
    }

    return allAllowedSteps
      .map(step => ({
        id: step,
        content: t.applicationAssignStatus(step),
        onClick: () => {
          if (!handler) return;
          if (selectedItemIds.includes(item.id)) {
            // Part of selection
            handler(selectedItemIds, step);
          } else {
            // Not part of selection
            handler([item.id], step);
          }
        },
      }))
      .filter((action: any) => action.id !== item.id);
  };

  const contactActions: any[] = [];
  const contactOptions = ["phone", "email"];

  for (const contact of contacts) {
    for (const key in contact) {
      if (contact.hasOwnProperty(key) && contactOptions.includes(key)) {
        let title: string | undefined;
        let link: string | undefined;

        // @ts-ignore
        const value = contact[key];

        switch (key) {
          case "phone":
            if (value) {
              title = `${t.callImperative()} ${
                contact.firstname
              } (${utils.phone.parsePhoneNumber(value)})`;
              link = `tel:${value}`;
            }

            break;

          case "email":
            if (value) {
              title = `${t.mailImperative()} ${contact.firstname} (${value})`;
              link = `mailto:${value}`;
            }
            break;

          default:
            break;
        }

        if (title && link) {
          contactActions.push({
            content: title,
            onClick: () => {
              if (link) {
                window.location.href = link;
              }
            },
          });
        }
      }
    }
  }

  const archiveApplication = (id: string, shouldArchive: boolean) => {
    if (shouldArchive) {
      archive({}, id);
    } else {
      unarchive({}, id);
    }
  };

  const archiveActions = [
    {
      content: item.archivedAt
        ? getLocalizedText("applications.cta.unarchive")
        : getLocalizedText("applications.cta.archive"),
      onClick: () => {
        if (shouldDoActionForSelection) {
          for (const selectedItem of selectedItems) {
            const shouldArchive = selectedItem.archivedAt ? false : true;
            archiveApplication(selectedItem.id, shouldArchive);
          }

          return;
        }

        const shouldArchive = item.archivedAt ? false : true;
        archiveApplication(item.id, shouldArchive);
      },
    },
  ];

  const applicationActionItems = [
    {
      content: t.applicationDuplicateApplicationAction(),
      onClick: () => {
        setShowDuplicateModal(true);
      },
    },
  ];

  const viewActions = () => {
    const result = [
      {
        content: getLocalizedText("view.application"),
        onClick: () => {
          setDetailOpen(true);
        },
      },
    ];

    if (item.pdfUrl) {
      result.push({
        content: t.downloadItem("pdf"),
        onClick: () => (window.location.href = `${item.pdfUrl}`),
      });
    }

    return result;
  };

  const totalIncome = getTotalIncome(contacts);
  const adultCount = getResidentsCount(residents, "INTAKE_RESIDENT_ADULTS");
  const childrenCount = getResidentsCount(
    residents,
    "INTAKE_RESIDENT_CHILDREN",
  );
  const petCount = residents.reduce(
    (currentPetCount, resident) =>
      resident.id.includes("_PETS_")
        ? currentPetCount + resident.value
        : currentPetCount,
    0,
  );

  let totalDocuments = 0;
  let deliveredDocuments = 0;

  contacts.forEach((contact: any) => {
    const propertyInBrussels = property?.zip && isBrusselsZip(property.zip);

    if (!propertyInBrussels) {
      totalDocuments++;
      if (contact.documents.length > 0) deliveredDocuments++;
    }

    contact.income.forEach((income: any) => {
      totalDocuments++;
      if (income.documents && income.documents.length > 0) deliveredDocuments++;
    });
  });

  const handleDuplicate = ({ step, propertyId: newPropertyId }: any) => {
    if (!newPropertyId) {
      return;
    }

    dispatch(
      applicationActions.duplicateApplicationStart.getAction({
        id: item.id,
        propertyId: newPropertyId,
        step,
        onSuccess(result) {
          handler?.([result.id], step, result);
        },
      }),
    );

    setShowDuplicateModal(false);
  };

  const getActions = () => {
    const statusActions = getStatusActions();
    const allActions = [
      statusActions,
      applicationActionItems,
      contactActions,
      viewActions(),
      archiveActions,
    ];
    const multiSelectActions = [statusActions, archiveActions];
    return selectedItemIds.length > 1 && selectedItemIds.includes(item.id)
      ? multiSelectActions
      : allActions;
  };

  const handleCloseModal = () => {
    setShowDuplicateModal(false);
  };

  const handleCloseDetail = () => {
    setDetailOpen(false);
  };

  const missingDocuments = deliveredDocuments < totalDocuments;
  const inSufficientIncome = totalIncome / 3 < (property?.price || 0);

  if (!propertyId) {
    return null;
  }

  const handleSelectNewItem = (item: IApplication) => {
    const isFirstSelection = selectedItems.length === 0;
    if (isFirstSelection) {
      onSelectItem(item);
      return;
    }

    //Check if they belong to the same phase.
    const phaseForOtherItems = getPhaseForStep(
      intakeType,
      selectedItems[0].step,
    );
    const phaseForNewItem = getPhaseForStep(intakeType, step);

    if (phaseForOtherItems === phaseForNewItem) {
      onSelectItem(item);
    } else {
      replaceSelectedItems([item]);
    }
  };

  const renderItem = () => {
    return (
      <Grid spacing="extraLoose" alignItems="center" justifyContent="flex-end">
        <Grid.Item>
          <Checkbox
            onChangeValue={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              if (!isSelected) {
                handleSelectNewItem(item);
              } else {
                onUnselectItem(item);
              }
            }}
            checked={isSelected}
            appearance={isSelected ? "success" : "default"}
            error={false}
          />
        </Grid.Item>

        <Grid.Item
          flex="1"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setDetailOpen(true);
          }}
        >
          <TextStyle variation="default" size="medium">
            {join(item.contacts.map(contact => getName(contact)))}
          </TextStyle>
          <StyledDragItemMeta>{renderMeta(item, step)}</StyledDragItemMeta>
        </Grid.Item>
        <Grid.Item
          style={{ cursor: "pointer" }}
          onClick={() => {
            setDetailOpen(true);
          }}
        >
          <Grid spacing="extraLoose" alignItems="center">
            <Grid.Item>
              <TextStyle
                variation={inSufficientIncome ? "negative" : "default"}
              >
                <TextStyle variation="code">
                  {formatCurrency(totalIncome)} / {t.month().toLowerCase()}
                </TextStyle>
              </TextStyle>
            </Grid.Item>
            <Grid.Item>
              <Grid spacing="tight" alignItems="center">
                <Grid.Item>
                  <Icon source="contact" size="small" />
                </Grid.Item>
                <Grid.Item>{adultCount}</Grid.Item>
              </Grid>
            </Grid.Item>
            <Grid.Item>
              <Grid spacing="tight" alignItems="center">
                <Grid.Item>
                  <Icon source="propellerHat" size="small" />
                </Grid.Item>
                <Grid.Item>{childrenCount}</Grid.Item>
              </Grid>
            </Grid.Item>
            {!stepsWithoutExtraInfo.includes(step) && (
              <Grid.Item>
                <Grid spacing="tight" alignItems="center">
                  <Grid.Item>
                    <Icon source="pets" size="small" />
                  </Grid.Item>
                  <Grid.Item>{petCount}</Grid.Item>
                </Grid>
              </Grid.Item>
            )}
            {!stepsWithoutExtraInfo.includes(step) && (
              <Grid.Item>
                <Grid spacing="tight" alignItems="center">
                  <Grid.Item>
                    <Icon source="documents" size="small" />
                  </Grid.Item>
                  <Grid.Item>
                    <TextStyle
                      variation={missingDocuments ? "negative" : "default"}
                    >
                      {deliveredDocuments}
                    </TextStyle>
                    {" / "}
                    {totalDocuments}
                  </Grid.Item>
                </Grid>
              </Grid.Item>
            )}
          </Grid>
        </Grid.Item>
        <Grid.Item>
          <StyledItemActions>
            <DropdownMenu
              children={
                <StyledItemActionsTrigger>
                  <Icon source="navigationMenuVertical" />
                </StyledItemActionsTrigger>
              }
              actions={getActions()}
            />
          </StyledItemActions>
        </Grid.Item>
      </Grid>
    );
  };

  const renderItemAsDragItem = () => {
    if (index === undefined || index === null) {
      return renderItem();
    }

    return (
      <Draggable draggableId={item.id} index={index} key={item.id}>
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
          <StyledDragItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            isDisabled={isDisabled || false}
          >
            {snapshot.isDragging && selectedItemIds.length > 1 && (
              <DragCount count={selectedItemIds.length} />
            )}
            <StyledDragHandle>
              <Icon source="dragHandle" />
            </StyledDragHandle>
            {renderItem()}
          </StyledDragItem>
        )}
      </Draggable>
    );
  };

  return (
    <>
      {renderAsDragItem ? renderItemAsDragItem() : renderItem()}

      {showDuplicateModal && (
        <MultiStepForm
          formId={`duplicate-application-${formId}`}
          schemas={createDuplicateApplicationForm({
            broker,
            propertyId,
          })}
          asModal={true}
          withAside={false}
          onSuccess={handleDuplicate}
          modalProps={{ onClose: handleCloseModal, width: "small" }}
        />
      )}

      <Drawer
        isOpen={detailOpen}
        onClose={handleCloseDetail}
        width="wide"
        position="right"
        isFullWidth={true}
      >
        <ApplicationDetail applicationId={item.id} />
      </Drawer>
    </>
  );
});
