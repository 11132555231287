var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToCamelCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import "draft-js/dist/Draft.css";
import React from "react";
import { DisplayText, Modal, MultiStepForm, TextStyle, } from "../../../../../../components";
import schemasCondition from "../../../InsertConditionModal/schemas";
import { getCleanFormData as getCleanFormDataSchemasCondition } from "../../../InsertConditionModal/schemas/schema.insertCondition.utils";
import schemas from "../../../InsertVariableModal/schemas/updateVariable";
import { EField } from "../../../InsertVariableModal/schemas/updateVariable/schema.updateVariable.types";
import { DEFAULT_GROUPS } from "../../Editor.config";
import { EVariableType } from "../../Editor.types";
import { DisplayTypeContext } from "../../contexts/DisplayType.context";
import { FieldsContext } from "../../contexts/Fields.context";
import VariableInfo from "../VariableInfo";
import * as S from "./VariableModal.styles";
var VariableModal = function (props) {
    var _a, _b;
    var variableId = props.variableId, onClose = props.onClose, onRemoveInstance = props.onRemoveInstance;
    // Context
    var isTemplate = React.useContext(DisplayTypeContext).isTemplate;
    var _c = React.useContext(FieldsContext), fields = _c.fields, setFields = _c.setFields, removeVariable = _c.removeVariable;
    var variable = (_a = fields.variables) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === variableId; });
    var groupId = variable === null || variable === void 0 ? void 0 : variable.groupId;
    var group = (_b = fields.groups) === null || _b === void 0 ? void 0 : _b.find(function (x) { return x.id === groupId; });
    // Refs
    var editVariableFormRef = React.createRef();
    var editSidebarConditionFormRef = React.createRef();
    // State
    var formId = React.useState(generateFormId())[0];
    var _d = React.useState(0), tabIndex = _d[0], setTabIndex = _d[1];
    var _e = React.useState(false), showSidebarConditionForm = _e[0], setShowSidebarConditionForm = _e[1];
    // Helpers
    var getCleanFormData = function (formData) {
        var name = formData[EField.Name];
        var newGroupId = formData[EField.Group];
        var newGroupName = formData[EField.GroupNew];
        var type = formData[EField.Type];
        var isRequired = formData[EField.Required] === "yes";
        var sortWeight = formData[EField.SortWeight];
        var defaultText = type && [EVariableType.String, EVariableType.Dropdown].includes(type)
            ? formData[EField.DefaultText]
            : undefined;
        var isMultilineText = type === EVariableType.String
            ? formData[EField.IsMultilineText] === "yes"
            : undefined;
        var defaultNumber = type === EVariableType.Number
            ? formData[EField.DefaultNumber]
            : undefined;
        var minNumber = type === EVariableType.Number ? formData[EField.MinNumber] : undefined;
        var maxNumber = type === EVariableType.Number ? formData[EField.MaxNumber] : undefined;
        var defaultDate = type === EVariableType.Date ? formData[EField.DefaultDate] : undefined;
        var defaultDateFormatting = type === EVariableType.Date
            ? formData[EField.DefaultDateFormatting]
            : undefined;
        var minDate = type === EVariableType.Date ? formData[EField.MinDate] : undefined;
        var maxDate = type === EVariableType.Date ? formData[EField.MaxDate] : undefined;
        var defaultToggle = type === EVariableType.Boolean
            ? formData[EField.DefaultToggle] === "yes"
            : undefined;
        var dropdownValues = type === EVariableType.Dropdown
            ? (formData[EField.DropdownValues] || "").split(",")
            : undefined;
        var persisted = formData[EField.Persisted] === "yes";
        var editableByTenant = formData[EField.EditableByTenant] === "yes";
        return {
            name: name,
            newGroupId: newGroupId,
            newGroupName: newGroupName,
            type: type,
            isRequired: isRequired,
            sortWeight: sortWeight,
            defaultText: defaultText,
            isMultilineText: isMultilineText,
            defaultDate: defaultDate,
            defaultDateFormatting: defaultDateFormatting,
            minDate: minDate,
            maxDate: maxDate,
            defaultNumber: defaultNumber,
            minNumber: minNumber,
            maxNumber: maxNumber,
            defaultToggle: defaultToggle,
            dropdownValues: dropdownValues,
            persisted: persisted,
            editableByTenant: editableByTenant,
        };
    };
    var getNewGroup = function (newGroupId, newGroupName) {
        if (!variable || !group) {
            return;
        }
        // Group not changed
        if (newGroupId === group.id) {
            return;
        }
        var existingGroup = fields.groups.find(function (group) { return group.id === newGroupId; });
        var defaultGroup = DEFAULT_GROUPS.find(function (group) { return group.id === newGroupId; });
        if (!existingGroup && defaultGroup) {
            return defaultGroup;
        }
        if (newGroupName) {
            return {
                id: stringToCamelCase(newGroupName),
                name: newGroupName,
            };
        }
    };
    // Event handlers
    var handleRemoveDangerously = function () {
        confirm({
            title: getLocalizedText("editor.variable_all_instances.remove.confirm.title"),
            primaryActions: [
                {
                    title: getLocalizedText("system.remove"),
                    onPress: function () {
                        if (!variable) {
                            return;
                        }
                        removeVariable(variable.id);
                    },
                },
            ],
        });
    };
    var onUpdateVariable = function (formData) {
        if (!variable || !group || !groupId) {
            showAlert({
                type: "error",
                message: getLocalizedText("No group/variable"),
            });
            return;
        }
        var _a = getCleanFormData(formData), name = _a.name, newGroupId = _a.newGroupId, newGroupName = _a.newGroupName, newVariableRest = __rest(_a, ["name", "newGroupId", "newGroupName"]);
        // Insert new or default group if group is changed
        var newGroup = getNewGroup(newGroupId, newGroupName);
        var newVariable = __assign(__assign({ sidebarConditionConfig: variable.sidebarConditionConfig }, newVariableRest), { id: variable.id, name: name, groupId: (newGroup === null || newGroup === void 0 ? void 0 : newGroup.id) || newGroupId || groupId });
        setFields({ group: newGroup, variable: newVariable });
        showAlert({
            type: "info",
            message: getLocalizedText("Variable updated"),
        });
        onClose();
    };
    var onUpdateSidebarCondition = function (formData) {
        if (!variable) {
            return;
        }
        var newSidebarConditionConfig = getCleanFormDataSchemasCondition(formData);
        var newVariable = __assign(__assign({}, variable), { sidebarConditionConfig: __assign({ id: "_" }, newSidebarConditionConfig) });
        setFields({ variable: newVariable });
        showAlert({
            type: "info",
            message: getLocalizedText("Sidebar condition updated"),
        });
    };
    var onRemoveSidebarCondition = function () {
        if (!variable) {
            return;
        }
        setFields({
            variable: __assign(__assign({}, variable), { sidebarConditionConfig: undefined }),
        });
        showAlert({
            type: "info",
            message: getLocalizedText("Sidebar condition removed"),
        });
    };
    // Render
    if (!variable || !group) {
        return (_jsxs(Modal, { onClose: onClose, heading: "Variable", shouldCloseOnOverlayClick: true, hasDismiss: true, width: "medium", children: [_jsx(DisplayText, { size: "small", children: _jsx(TextStyle, { variation: "subdued", children: "Variable/group not found" }) }), isTemplate && variable && (_jsx(S.RemoveButton, { title: getLocalizedText("system.remove_dangerously"), onPress: handleRemoveDangerously }))] }));
    }
    var info = (_jsxs(_Fragment, { children: [" ", _jsx(VariableInfo, { variable: variable, group: group }), isTemplate && onRemoveInstance && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W32 }), _jsx(S.RemoveButton, { title: getLocalizedText("system.remove"), onPress: onRemoveInstance })] }))] }));
    var edit = (_jsx(MultiStepForm, { formId: "edit-variable-".concat(formId), schemas: schemas({ fields: fields, variable: variable, group: group }), withAside: false, onSuccess: onUpdateVariable, showControls: false, submitLabel: getLocalizedText("system.update"), ref: editVariableFormRef }));
    var extra = (_jsxs(_Fragment, { children: [_jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.variable.info_variable.remove_dangerously.info") }), _jsx(Spacer, { weight: ESpacerWeight.W04 }), _jsx(S.RemoveButton, { title: getLocalizedText("system.remove_dangerously"), onPress: handleRemoveDangerously })] }));
    var sidebarCondition = (_jsxs(_Fragment, { children: [showSidebarConditionForm || variable.sidebarConditionConfig ? (_jsx(MultiStepForm, { formId: "edit-sidebar-condition-".concat(formId), schemas: schemasCondition({
                    fields: fields,
                    condition: variable.sidebarConditionConfig,
                }), withAside: false, onSuccess: onUpdateSidebarCondition, showControls: false, submitLabel: getLocalizedText("system.update"), ref: editSidebarConditionFormRef })) : (_jsx(ButtonBasic, { title: getLocalizedText("system.add"), onPress: function () {
                    setShowSidebarConditionForm(true);
                } })), variable.sidebarConditionConfig && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W32 }), _jsx(S.RemoveButton, { title: getLocalizedText("system.remove"), onPress: onRemoveSidebarCondition })] }))] }));
    var actions = [];
    if (tabIndex > 0) {
        actions.push({
            content: getLocalizedText("system.update"),
            onClick: function () {
                var _a, _b;
                if (tabIndex === 1) {
                    (_a = editVariableFormRef.current) === null || _a === void 0 ? void 0 : _a.handleExternalSubmit();
                    return;
                }
                if (tabIndex === 2) {
                    (_b = editSidebarConditionFormRef.current) === null || _b === void 0 ? void 0 : _b.handleExternalSubmit();
                    return;
                }
            },
            appearance: "primary",
        });
    }
    return (_jsx(Modal, { onClose: onClose, heading: "".concat(getLocalizedText(group.name), " - ").concat(getLocalizedText(variable.name)), shouldCloseOnOverlayClick: true, hasDismiss: true, width: "medium", onChangeTab: setTabIndex, tabs: [
            {
                content: getLocalizedText("system.info"),
                component: info,
                isShown: true,
            },
            {
                content: getLocalizedText("system.edit"),
                component: edit,
                isShown: isTemplate,
            },
            {
                content: getLocalizedText("editor.variable.sidebar_condition"),
                component: sidebarCondition,
                isShown: isTemplate,
            },
            {
                content: getLocalizedText("system.extra"),
                component: extra,
                isShown: isTemplate,
            },
        ].filter(function (x) { return x.isShown; }), actions: actions }));
};
// eslint-disable-next-line import/no-default-export
export default VariableModal;
