import { Box } from "@rebass/grid";
import ListItemSeparator from "@rentiohq/shared-frontend/dist/components/components/ListItemSeparator";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import {
  getAddenda,
  getContractDocument,
  getLocationDescription,
} from "@rentiohq/shared-frontend/dist/redux/registration/registration.utils";
import { EContractMemberTypes } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  ERegistrationContractDestination,
  ERegistrationContractStatus,
  ERegistrationDocumentType,
  ERegistrationMemberType,
  IRegistration,
  IRegistrationDocument,
} from "@rentiohq/shared-frontend/dist/types/registration.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { getMembersWithRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  join,
  stringToSnakeCase,
} from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  AccountList,
  Activities,
  Card,
  ContractFetchListItem,
  DisplayText,
  ESpacings,
  Icon,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { getTextStyleVariationTypeForStatus } from "@rentiohq/web-shared/dist/utils/registration";
import { DocumentListItem } from "components/DetailDrawers/Drawers/RentDeposit/components/RentDepositInfo/components/DocumentRow";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ERegistrationTab } from "../../Registration.types";
import {
  getActivitiesFilter,
  getContractActions,
  getLocationDescriptionActions,
} from "../../Registration.utils";

interface IProps {
  registration: IRegistration;
  property: IProperty;
  setTab: (tab: ERegistrationTab) => void;
  onDrawerClose?: () => void;
}

const RegistrationInfo: React.FC<IProps> = props => {
  const { registration, property, setTab, onDrawerClose } = props;

  const {
    id,
    rentContractDestination,
    rentContractPropertyTaxPaidByTenant = true,
    documents,
  } = registration;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Helpers
  const hasExternalPaymentInformation = !!documents.find(
    document => document.paymentInformationSentExternally,
  );

  const getSortedDocuments = () => {
    const result: (IRegistrationDocument | "location_description_filler")[] =
      [];

    const contract = getContractDocument(registration);
    if (contract) {
      result.push(contract);
    }

    const locationDescription = getLocationDescription(registration);
    if (locationDescription) {
      result.push(locationDescription);
    } else {
      result.push("location_description_filler");
    }

    result.push(...getAddenda(registration));

    return result;
  };

  // Render
  const renderRow = (item: {
    title: string;
    value?: string;
    action?: { id: string; content: string; onClick: () => void };
  }) => {
    let formattedValue = item.value;
    if (!item.value || item.value.length === 0) {
      formattedValue = "-";
    }

    return (
      <ResourceListItem
        item={item}
        primaryActions={item.action ? [item.action] : undefined}
      >
        <TextStyle variation="subdued" element="div">
          {item.title}
        </TextStyle>
        <div>{formattedValue}</div>
      </ResourceListItem>
    );
  };

  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  const renderRegistrationDocument = (
    item: IRegistrationDocument | "location_description_filler",
  ) => {
    if (item === "location_description_filler") {
      return (
        <ResourceListItem
          boxProps={{ p: ESpacings.tight }}
          media={<Icon source="leaseContract" />}
          mediaSize="medium"
          actions={getLocationDescriptionActions({
            registration,
            property,
            navigate,
            dispatch,
          })}
        >
          <div>
            {getLocalizedText(
              `registration.document_type.${stringToSnakeCase(
                ERegistrationDocumentType.PropertyInspectionReport,
              )}`,
            )}
          </div>

          <TextStyle variation={["subdued", "negative"]} size="small">
            {getLocalizedText(
              `registration.status.${ERegistrationContractStatus.None}`.toLowerCase(),
            )}
          </TextStyle>
        </ResourceListItem>
      );
    }

    const isContract = item.documentType === ERegistrationDocumentType.Contract;

    let title = getLocalizedText(
      `registration.document_type.${stringToSnakeCase(item.documentType)}`,
    );
    let status = item.status || ERegistrationContractStatus.None;

    const metaComponents = [];
    if (isContract) {
      metaComponents.push(
        getLocalizedText(
          `registration.contract_destination.${stringToSnakeCase(
            rentContractDestination,
          )}`.toLowerCase(),
        ),
      );
    }
    if (isContract) {
      metaComponents.push(
        getLocalizedText(
          `registration.contract_type.${item.type}`.toLowerCase(),
        ),
      );
    }
    if (item.signedAt) {
      metaComponents.push(
        `${getLocalizedText("system.signed_at")} ${formatDate(item.signedAt)}`,
      );
    }
    if (
      isContract &&
      rentContractDestination === ERegistrationContractDestination.Commercial
    ) {
      if (rentContractPropertyTaxPaidByTenant) {
        metaComponents.push(
          getLocalizedText("registration.contract_destination.paid_by_tenant"),
        );
      } else {
        metaComponents.push(
          getLocalizedText("registration.contract_destination.paid_by_owner"),
        );
      }
    }

    let actions: IAction[] = [];

    switch (item.documentType) {
      case ERegistrationDocumentType.Contract: {
        actions = getContractActions({
          registration,
          property,
          navigate,
          dispatch,
          onDrawerClose,
        });

        break;
      }

      case ERegistrationDocumentType.PropertyInspectionReport: {
        actions = getLocationDescriptionActions({
          registration,
          property,
          navigate,
          dispatch,
        });

        break;
      }

      default:
        break;
    }

    const signers =
      item.documentType === ERegistrationDocumentType.Addendum
        ? item.signers
        : undefined;

    return (
      <ResourceListItem
        boxProps={{ p: ESpacings.tight }}
        media={<Icon source="leaseContract" />}
        mediaSize="medium"
        actions={actions}
      >
        <div>{title}</div>

        {signers && signers.length > 0 && (
          <>
            <TextStyle variation={"subdued"} size="small">
              {getLocalizedText("registration.detail.document_signers.info", {
                // TODO: Signer account should be returned from backend
                value: join(
                  signers.map(signer =>
                    signer.account ? getName(signer.account) : "",
                  ),
                ),
              })}
            </TextStyle>

            <br />
          </>
        )}

        {metaComponents.length > 0 && (
          <>
            <TextStyle variation={"subdued"} size="small">
              {metaComponents.join(" | ")}
            </TextStyle>

            <br />
          </>
        )}

        <TextStyle
          variation={["subdued", getTextStyleVariationTypeForStatus(status)]}
          size="small"
        >
          {item.rejectionComment ||
            getLocalizedText(
              `registration.status.${stringToSnakeCase(status)}`.toLowerCase(),
            )}
        </TextStyle>
      </ResourceListItem>
    );
  };

  const renderDocument = (item: IRegistrationDocument) => (
    <React.Fragment key={item.id}>
      <DocumentListItem documentId={item.documentId} />
      {item.certificateDocumentId && (
        <>
          <ListItemSeparator />
          <DocumentListItem documentId={item.certificateDocumentId} />
        </>
      )}
    </React.Fragment>
  );

  const renderExternalPaymentInformation = () => {
    return (
      <Card
        heading={renderCardHeader(
          getLocalizedText(
            "registration.detail.section.payment_external.title",
          ),
        )}
        space={ESpacings.base}
      >
        <TextStyle variation="subdued">
          {getLocalizedText(
            "registration.detail.section.payment_external.body",
          )}
        </TextStyle>
      </Card>
    );
  };

  const renderPaymentInformation = (item: IRegistrationDocument) => {
    if (item.paymentInformationSentExternally) {
      return null;
    }

    if (item.paymentIban) {
      <Card
        heading={renderCardHeader(
          getLocalizedText(
            `registration.detail.section.payment_${stringToSnakeCase(
              item.documentType,
            )}.title`.toLowerCase(),
          ),
        )}
        subHeading={getLocalizedText(
          `registration.detail.section.payment_${stringToSnakeCase(
            item.documentType,
          )}.body`.toLowerCase(),
        )}
        space={ESpacings.base}
      >
        <ResourceList
          items={[
            {
              title: getLocalizedText(
                "registration.detail.section.payment.item.iban",
              ),
              value: item.paymentIban || "-",
            },
            {
              title: getLocalizedText(
                "registration.detail.section.payment.item.bic",
              ),
              value: item.paymentBic || "-",
            },
            {
              title: getLocalizedText(
                "registration.detail.section.payment.item.amount",
              ),
              value: item.paymentAmount
                ? formatCurrency(item.paymentAmount)
                : "-",
            },
            {
              title: getLocalizedText(
                "registration.detail.section.payment.item.reference",
              ),
              value: item.paymentReference || "-",
            },
          ]}
          renderItem={renderRow}
        />
      </Card>;
    }

    return null;
  };

  const renderMembers = () => {
    const registrationMembers = registration.members;
    if (registrationMembers) {
      return Object.values(ERegistrationMemberType).map(role => {
        const members = getMembersWithRole(registrationMembers, role);
        if (members.length === 0) {
          return null;
        }

        return (
          <Card
            key={`member-role-${role}`}
            heading={renderCardHeader(
              getLocalizedText(`system.${role}`.toLowerCase()),
            )}
            space={ESpacings.base}
          >
            <AccountList accounts={members.map(member => member.account)} />
          </Card>
        );
      });
    }

    return [
      EContractMemberTypes.Owner,
      EContractMemberTypes.Tenant,
      EContractMemberTypes.Parent,
    ].map(role => (
      <Card
        key={`registration-role-${role}`}
        heading={renderCardHeader(
          getLocalizedText(`system.${role}s`.toLowerCase()),
        )}
        space={ESpacings.base}
      >
        <ContractFetchListItem
          contract={registration.contract}
          contractId={registration.contractId}
          renderContract={contract => (
            <AccountList
              accounts={getMembersWithRole(contract.members, role).map(
                member => member.account,
              )}
            />
          )}
        />
      </Card>
    ));
  };

  return (
    <>
      <Card
        heading={renderCardHeader(
          getLocalizedText("registration.detail.section.registrations"),
        )}
        space={ESpacings.base}
      >
        <ResourceList
          items={getSortedDocuments()}
          renderItem={renderRegistrationDocument}
        />
      </Card>

      {documents.map(document => renderPaymentInformation(document))}

      {hasExternalPaymentInformation && renderExternalPaymentInformation()}

      <Card
        heading={renderCardHeader(
          getLocalizedText("registration.detail.section.documents"),
        )}
        space={ESpacings.base}
      >
        <ResourceList items={documents} renderItem={renderDocument} />
      </Card>

      {renderMembers()}

      <Card
        heading={renderCardHeader(getLocalizedText("system.history"))}
        space={ESpacings.base}
      >
        <Box mb={ESpacings.base}>
          <Activities
            identifier={`registration-${id}`}
            filter={getActivitiesFilter(registration)}
            limit={3}
            isCompact={true}
            onMoreClick={() => {
              setTab(ERegistrationTab.History);
            }}
          />
        </Box>
      </Card>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default RegistrationInfo;
