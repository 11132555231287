import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { ERegistrationContractDestination } from "@rentiohq/shared-frontend/dist/types/registration.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import sortBy from "lodash/sortBy";
import { EField, IExtraData } from "./schema.registration.types";
import { getDocumentSignedAt } from "./schema.registration.utils";

export const getSchema =
  (
    initialValues: {
      [key: string]: unknown;
    },
    _: IExtraData,
  ) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    const translatedDestination = Object.entries(
      ERegistrationContractDestination,
    ).map(data => {
      return {
        [getLocalizedText(
          `registration.contract_destination.${data[0]}`.toLowerCase(),
        )]: data[1],
      };
    });

    const sortedDestination = sortBy(translatedDestination, [
      destination => {
        return Object.keys(destination)[0];
      },
    ]);

    return {
      required: [EField.RentContractDestination, EField.RentContractSignedAt],
      properties: {
        title1: {
          default: undefined,
        },
        // info1: {
        //   default: undefined,
        // },
        [EField.RentContractDestination]: {
          default: getValue(
            EField.RentContractDestination,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: sortedDestination.map(destination => {
            return {
              id: Object.values(destination)[0],
              value: Object.keys(destination)[0],
            };
          }),
        },
        [EField.RentContractSignedAt]: {
          default: getDocumentSignedAt({
            documentField: EField.RentContractDocumentToUpload,
            signedAtField: EField.RentContractSignedAt,
            initialValues,
            defaultValues,
          }),
        },
        [EField.RentContractPropertyTaxPaidByTenant]: {
          default: getValue(
            EField.RentContractPropertyTaxPaidByTenant,
            initialValues,
            defaultValues,
          ),
          // @ts-ignore
          options: ["yes", "no"].map(id => ({
            id,
            value: getLocalizedText(`system.${id}`),
          })),
          showIf: (formData: any) =>
            formData[EField.RentContractDestination] !==
            ERegistrationContractDestination.Housing,
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData): IUiSchema<EField> => {
  return {
    "rentio:title": utils.i18n.t("registration.create.form.step.contract_meta"),
    title1: {
      "ui:field": ERentioFormField.SectionHeader,
      title: utils.i18n.t("registration.create.form.field.contract_meta_title"),
    },
    // info1: {
    //   "ui:field": ERentioFormField.HelpInfo,
    //   subtitle: getLocalizedText(
    //     "registration.create.form.field.contract_meta_info",
    //   ),
    // },
    [EField.RentContractDestination]: {
      "ui:field": ERentioFormField.DropdownSelect,
      title: getLocalizedText(
        "registration.create.form.field.contract_destination",
      ),
      formatSummary: (formData: ERegistrationContractDestination) =>
        getLocalizedText(
          `registration.contract_destination.${formData}`.toLowerCase(),
        ),
    },

    [EField.RentContractSignedAt]: {
      "ui:field": ERentioFormField.Date,
      title: getLocalizedText(
        "registration.create.form.field.contract_signed_at",
      ),
      maxDate: new Date(),
      formatSummary: formData => (formData ? formatDate(formData) : undefined),
    },
    [EField.RentContractPropertyTaxPaidByTenant]: {
      "ui:field": ERentioFormField.SegmentedControl,
      extraInfoUrl:
        "registration.create.form.field.rent_contract_property_tax_paid_by_tenant.url",
      title: getLocalizedText(
        "registration.create.form.field.rent_contract_property_tax_paid_by_tenant",
      ),
      formatSummary: (value: string) =>
        getLocalizedText(`system.${value}`.toLowerCase()),
    },
  };
};
