import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";

export interface IExtraData {
  property: IProperty;
  contract: IContract;
  contactMeMaster?: IContact;
  legalContact?: IContact;
  isBroker?: boolean;
}

export enum EField {
  Owners = "owners",
  SignAsBroker = "signAsBroker",
  Tenants = "tenants",

  RentContractDocumentToUpload = "rentContractDocumentToUpload",
  RentContractDestination = "rentContractDestination",
  RentContractSignedAt = "rentContractSignedAt",
  RentContractPropertyTaxPaidByTenant = "rentContractPropertyTaxPaidByTenant",
  RentContractLanguage = "preferredContractLanguage",

  LocationDescriptionShouldUpload = "locationDescriptionShouldUpload",
  LocationDescriptionDocumentToUpload = "locationDescriptionDocumentToUpload",
  LocationDescriptionSignedAt = "locationDescriptionSignedAt",

  Title1 = "title1",
  Info1 = "info1",
}
