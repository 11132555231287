import * as authActions from "@rentiohq/shared-frontend/dist/redux/auth/auth.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import {
  EBrokerFeature,
  EClientType,
} from "@rentiohq/shared-frontend/dist/types/broker.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  IBrokerUsageOutletContextType,
  IInvoiceItemsUninvoicedOutletContextType,
  IInvoiceTableOutletContextType,
  Loading,
  Page,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { TabAction } from "@rentiohq/web-shared/dist/types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, matchPath, useLocation } from "react-router-dom";
import { IRootStore } from "redux/reducers";

export const Company = () => {
  const { internalModeEnabled } = useInternalMode();

  let location = useLocation();

  const dispatch = useDispatch();

  const hasBookkeeping = brokerHooks.useBrokerFeature(
    EBrokerFeature.Bookkeeping,
  );

  const { user, broker } = authHooks.useSelf();
  const { type: brokerType = EClientType.Broker } = broker || {};
  // Broker, not a patrimony
  const isBrokerType = brokerType === EClientType.Broker;

  const isFetchingUser = useSelector(
    (state: IRootStore) => state.auth.isFetchingUser,
  );

  const financialContact = useSelector((state: IRootStore) =>
    user?.brokerId
      ? contactSelectors.getContactByCustomId(state, EContactCustomId.Financial)
      : undefined,
  );

  React.useEffect(() => {
    dispatch(authActions.getUser.actions.start({}));
  }, []);

  if (isFetchingUser) {
    return <Loading />;
  }

  if (!user) {
    return null;
  }

  const tabs: TabAction[] = [
    {
      name: "personal",
      content: getLocalizedText("page.company.tab.personal"),
      url: "/company/contact",
      isActive: !!matchPath("/company/contact", location.pathname),
    },
  ];

  if (financialContact) {
    tabs.push({
      name: "financial",
      content: getLocalizedText("page.company.tab.bank_accounts"),
      url: "/company/financial",
      isActive: !!matchPath("/company/financial", location.pathname),
    });
  }

  tabs.push({
    name: "third-party",
    content: getLocalizedText("page.company.tab.account_holder"),
    url: "/company/account-holder",
    isActive: !!matchPath("/company/account-holder", location.pathname),
  });

  if (isBrokerType) {
    tabs.push({
      name: "legal",
      content: getLocalizedText("page.company.tab.legal"),
      url: "/company/legal",
      isActive: !!matchPath("/company/legal", location.pathname),
    });
  }

  tabs.push({
    name: "employees",
    content: getLocalizedText("broker.detail.tabs.employees"),
    url: "/company/employees",
    isActive: !!matchPath("/company/employees", location.pathname),
  });

  tabs.push({
    name: "export-files",
    content: getLocalizedText("user.detail.tabs.export_files"),
    url: "export-files",
    isActive: !!matchPath("/company/export-files", location.pathname),
  });

  if (hasBookkeeping) {
    tabs.push({
      name: "bookkeeping",
      content: getLocalizedText("broker.detail.tabs.bookkeeping"),
      url: "/company/bookkeeping",
      isActive: !!matchPath("/company/bookkeeping", location.pathname),
    });

    tabs.push({
      name: "cost-centers",
      content: getLocalizedText("system.entity.cost_centers"),
      url: "/company/cost-centers",
      isActive: !!matchPath("/company/cost-centers", location.pathname),
    });

    tabs.push({
      name: "general-ledger-accounts",
      content: getLocalizedText("system.entity.general_ledger_accounts"),
      url: "/company/general-ledger-accounts",
      isActive: !!matchPath(
        "/company/general-ledger-accounts",
        location.pathname,
      ),
    });
  }

  if (internalModeEnabled) {
    tabs.push({
      name: "invoice",
      content: getLocalizedText("broker.detail.tabs.invoices"),
      url: "/company/invoices",
      isActive: !!matchPath("/company/invoices", location.pathname),
    });
  }

  tabs.push({
    name: "usage",
    content: getLocalizedText("broker.detail.tabs.usage"),
    url: "/company/usage",
    isActive: !!matchPath("/company/usage", location.pathname),
  });

  const context: IInvoiceTableOutletContextType &
    IInvoiceItemsUninvoicedOutletContextType &
    IBrokerUsageOutletContextType = {
    invoiceAccountId: user.activeAccountId,
    brokerId: broker?.id,
  };

  return (
    <Page title={getLocalizedText("page.company.title")} tabs={tabs}>
      <Card>
        <Outlet context={context} />
      </Card>
    </Page>
  );
};
