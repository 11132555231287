export var _ = function () { };
export var getApplicationsQuery = function (params) {
    var _a = params.limit, limit = _a === void 0 ? 100 : _a, search = params.search, _b = params.sortField, sortField = _b === void 0 ? "providedAt" : _b, _c = params.sortMethod, sortMethod = _c === void 0 ? "DESC" : _c, filter = params.filter;
    return {
        limit: limit,
        sort: [{ field: sortField, method: sortMethod }],
        search: search && search.length > 0 ? search : undefined,
        filter: filter,
    };
};
