export const PaymentRequestCardStyles = () => {
  return {
    content: {
      longText: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "block",
      },
      // cspell:disable-next-line
      hideable: {
        display: { xs: "none", lg: "block" },
        textAlign: "left",
      },
    },
  };
};
