var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer } from "redux-act";
import * as expertInquiryActions from "./expertInquiry.actions";
export var REDUCER_KEY_EXPERT_INQUIRY = "expertInquiry";
var getDefaultState = function () { return ({
    // Partnerships
    isFetchingPartnerships: false,
    fetchPartnershipsError: undefined,
    partnerships: undefined,
    // Location descriptions
    isRequestingLocationDescription: false,
    requestLocationDescriptionError: undefined,
    requestedLocationDescriptionTaskId: undefined,
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(expertInquiryActions.getPartnerships.types.START, function (state) { return (__assign(__assign({}, state), { isFetchingPartnerships: true, fetchPartnershipsError: undefined, partnerships: undefined })); });
reducer.on(expertInquiryActions.getPartnerships.types.SUCCESS, function (state, _a) {
    var partnerships = _a.partnerships;
    return (__assign(__assign({}, state), { isFetchingPartnerships: false, fetchPartnershipsError: undefined, partnerships: partnerships }));
});
reducer.on(expertInquiryActions.getPartnerships.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isFetchingPartnerships: false, fetchPartnershipsError: error }));
});
reducer.on(expertInquiryActions.requestLocationDescription.types.START, function (state) { return (__assign(__assign({}, state), { isRequestingLocationDescription: true, requestLocationDescriptionError: undefined, requestedLocationDescriptionTaskId: undefined })); });
reducer.on(expertInquiryActions.requestLocationDescription.types.SUCCESS, function (state, _a) {
    var task = _a.task;
    return (__assign(__assign({}, state), { isRequestingLocationDescription: false, requestLocationDescriptionError: undefined, requestedLocationDescriptionTaskId: task.id }));
});
reducer.on(expertInquiryActions.requestLocationDescription.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isRequestingLocationDescription: false, requestLocationDescriptionError: error, requestedLocationDescriptionTaskId: undefined }));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
