var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import React from "react";
import { elementChildren, isElementOfType } from "../../utils/components";
import * as S from "./Grid.styled";
export var ESpacings;
(function (ESpacings) {
    ESpacings[ESpacings["none"] = 0] = "none";
    ESpacings[ESpacings["extraTight"] = 1] = "extraTight";
    ESpacings[ESpacings["tight"] = 2] = "tight";
    ESpacings[ESpacings["base"] = 3] = "base";
    ESpacings[ESpacings["loose"] = 4] = "loose";
    ESpacings[ESpacings["extraLoose"] = 5] = "extraLoose";
    ESpacings[ESpacings["superLoose"] = 6] = "superLoose";
    // hyperLoose = 6,
    ESpacings[ESpacings["ultraLoose"] = 7] = "ultraLoose";
})(ESpacings || (ESpacings = {}));
var Grid = /** @class */ (function (_super) {
    __extends(Grid, _super);
    function Grid() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Grid.prototype.render = function () {
        var _a = this.props, children = _a.children, _b = _a.spacing, spacing = _b === void 0 ? "base" : _b, rest = __rest(_a, ["children", "spacing"]);
        var gridMargin;
        switch (ESpacings[spacing]) {
            case ESpacings.extraLoose:
                gridMargin = [-3, -3, -4, -5];
                break;
            case ESpacings.loose:
                gridMargin = [-3, -3, -3, -4];
                break;
            default:
                gridMargin = 0 - ESpacings[spacing];
        }
        var itemMarkup = function (margin) {
            return elementChildren(children).map(function (child, index) {
                var isGridItem = isElementOfType(child, Grid.Item);
                var gridItemPadding;
                gridItemPadding = Array.isArray(margin)
                    ? (gridItemPadding = margin.map(function (value) { return Math.abs(value); }))
                    : (gridItemPadding = Math.abs(margin));
                return isGridItem ? (
                // @ts-ignore
                React.cloneElement(child, __assign({ pt: gridItemPadding, pl: gridItemPadding }, child.props))) : (_jsx(React.Fragment, { children: React.createElement(Grid.Item, __assign({ pt: gridItemPadding, pl: gridItemPadding }, child.props), child) }, index));
            });
        };
        return (_jsx(S.Grid, __assign({ ml: gridMargin, mt: gridMargin, flexWrap: "wrap" }, rest, { children: itemMarkup(gridMargin) })));
    };
    Grid.Item = function (_a) {
        var children = _a.children, rest = __rest(_a, ["children"]);
        return _jsx(Box, __assign({}, rest, { children: children }));
    };
    return Grid;
}(React.PureComponent));
export { Grid };
