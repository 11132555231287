import { useTheme } from "@mui/material/styles";
import { ThemeMode } from "@rentiohq/web-shared-next/dist/utils/types/config";
import styled from "styled-components";

export const ProductCardStyles = () => {
  const theme = useTheme();

  return {
    wrapper: {
      p: 3,
      bgcolor:
        theme.palette.mode === ThemeMode.DARK
          ? "background.default"
          : "grey.50",
      border: "1px solid",
      borderColor: "divider",
      cursor: "pointer",
      "&:hover": {
        borderColor: "primary.main",
      },
    },
    content: {
      avatar: {
        bgcolor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.grey[500]}`,
        svg: {
          width: "38.4px",
          height: "38.4px",
          color: theme.palette.grey[500],
        },
      },
      profileImg: {
        bgcolor: theme.palette.background.paper,
        img: {
          objectFit: "cover",
        },
      },
      longText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
      },
    },
  };
};

export const Image = styled.img`
  height: 125px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  object-fit: cover;
`;
