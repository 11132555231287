import { IPartnership } from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.types";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  addMonths,
  isAfter,
  isBefore,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";

const NOW = new Date();

export const genInitialModal = (partnerships?: IPartnership[]) => {
  if (!partnerships) {
    return "intro";
  }

  if (partnerships.length === 0) {
    return "intro";
  }

  if (partnerships.length === 1) {
    // Only has MEXP
    if (partnerships[0].locationDescriptorKey === "MEXP") {
      return "intro";
    }

    // Single partner
    return "form";
  }

  // Multiple partners
  return "partnerPicker";
};

/**
 * Returns a list of contracts that will start, or are less then 1 month into lease.
 * @param contracts An array of contracts
 */
export const filterUpcomingContracts = (contracts: IContract[] = []) => {
  return contracts.filter(contract =>
    isBefore(NOW, addMonths(contract.startDate, 1)),
  );
};

/**
 * Returns a list of contracts that will start, or are less then 1 month into lease.
 * @param contracts An array of contracts
 */
export const filterRunningContracts = (contracts: IContract[] = []) => {
  return contracts.filter(contract => {
    return (
      isAfter(addMonths(contract.stopDate!, 1), NOW) &&
      !isBefore(NOW, addMonths(contract.startDate, 1))
    );
  });
};
