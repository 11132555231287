import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import {
  EContractType,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EPropertyTypeId } from "@rentiohq/shared-frontend/dist/types/property.types";
import { Loading } from "@rentiohq/web-shared/dist/components";
import { TSelectableItemProps } from "scenes/Properties/Properties.types";
import PropertyListItem, {
  IPropertyListItemProps,
} from "../PropertyListItem/PropertyListItem";
import { PropertyListItemSpacer } from "../PropertyListItem/PropertyListItem.header";
import { ContractRow } from "./ContractRow";

export interface IPropertyListItemContractsProps
  extends IPropertyListItemProps {
  includeFinishedContracts?: boolean;
  selectable?: TSelectableItemProps;
}
const NOW = new Date();

const PropertyListItemContracts = (props: IPropertyListItemContractsProps) => {
  const {
    property,
    includeFinishedContracts = false,
    onSetParentId,
    selectable,
  } = props;

  const filterConditions: Record<string, any> = [
    { propertyId: { eq: property.id } },
    { contractType: { eq: EContractType.Basic } },
  ];

  if (!includeFinishedContracts) {
    filterConditions.push({ stopDate: { gte: NOW } });
  }
  const { items: contracts, isFetching: isFetchingContracts } =
    contractHooks.useGetAll({
      query: {
        filter: {
          and: filterConditions,
        },
        sort: [
          {
            field: "startDate",
            method: "DESC",
          },
        ],
      },
    });

  if (isFetchingContracts) {
    return <Loading asDots={true} />;
  }

  return (
    <PropertyListItem
      property={property}
      onSetParentId={onSetParentId}
      selectable={selectable}
    >
      <PropertyListItemSpacer />
      <div style={{ width: "100%" }}>
        {/* When no contract is found, we still need to render a row 
        saying there is no contract */}
        {contracts?.length === 0 && (
          <ContractRow
            contract={undefined}
            propertyId={property.id}
            isGroup={property.typeId === EPropertyTypeId.Group}
          />
        )}
        {contracts?.map((contract: IContract) => (
          <ContractRow
            key={contract.id}
            contract={contract}
            propertyId={contract.propertyId}
          />
        ))}
      </div>
    </PropertyListItem>
  );
};

// eslint-disable-next-line import/no-default-export
export default PropertyListItemContracts;
