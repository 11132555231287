import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { ALL_ACTIVITY_TYPES } from "@rentiohq/shared-frontend/dist/types/activity.types";
import { mapActivityTypeToString } from "@rentiohq/shared-frontend/dist/utils/activity.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { capitalize } from "lodash";
import { EField } from "./schema.exportActivities.types";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return {
            required: [EField.Types, EField.From, EField.To],
            properties: (_a = {},
                _a[EField.Types] = {
                    default: getValue(EField.Types, initialValues, defaultValues),
                    // @ts-ignore
                    options: ALL_ACTIVITY_TYPES.map(function (type) { return ({
                        id: type,
                        value: getLocalizedText("activity.".concat(mapActivityTypeToString(type)).toLowerCase()),
                    }); }),
                },
                _a[EField.From] = {
                    default: getValue(EField.From, initialValues, defaultValues),
                },
                _a[EField.To] = {
                    default: getValue(EField.To, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:navigationTitle": getLocalizedText("export_activities.form.title")
        },
        _a[EField.Types] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("history.filter_activities.label"),
            isMultiSelect: true,
            asOptionList: true,
        },
        _a[EField.From] = {
            "ui:field": ERentioFormField.Date,
            title: capitalize(getLocalizedText("system.from")),
        },
        _a[EField.To] = {
            "ui:field": ERentioFormField.Date,
            title: capitalize(getLocalizedText("system.to")),
        },
        _a);
};
