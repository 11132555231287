import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import {
  ERevenuesInvoicedStatus,
  ERevenuesPaymentStatus,
} from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EPaymentRequestStatus,
  IPaymentRequest,
} from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  formatCurrency,
  getAmountWithoutVat,
} from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { canCreateInvoice } from "@rentiohq/shared-frontend/dist/utils/paymentRequest.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  AddressCell,
  Button,
  Card,
  ContactFetchListItem,
  DataTable,
  ESpacings,
  EmptyState,
  Filters,
  Loading,
  Lozenge,
  OptionListShared,
  Pagination,
  Stack,
  Switch,
  TSortDirection,
  TextStyle,
  TruncateTooltip,
} from "@rentiohq/web-shared/dist/components";
import { useMultiselect } from "@rentiohq/web-shared/dist/hooks/useMultiselect";
import { IAction } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import { sum } from "lodash";
import hash from "object-hash";
import isEmpty from "ramda/es/isEmpty";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { StringParam } from "serialize-query-params";
import { ts } from "../../../../services";
import { createTitle, getPaymentOrders } from "../../../../utils/payment";
import {
  ERevenueType,
  FETCH_LIMIT,
  getFilterQuery,
  getSimpleStatus,
} from "../../Revenues.utils";
import * as S from "./../../Revenues.styled";
import { ExternalInvoiceDetailModal } from "./../../components/ExternalInvoiceDetailModal";
const SELECTION_LIMIT = 500;

interface IProps {
  type: ERevenueType;
  onShowPaymentRequest: (params: { id: number }) => void;
}

const orderMap = [
  "requestPayerAccountId",
  undefined,
  undefined,
  undefined,
  "startedAt",
  undefined,
  undefined,
  undefined,
  undefined,
];

export const RevenueTabList = (props: IProps) => {
  const { type, onShowPaymentRequest } = props;

  const [paymentStatus, setPaymentStatus] = React.useState<
    ERevenuesPaymentStatus[]
  >([]);
  const [invoicesStatus, setInvoicesStatus] = React.useState<
    ERevenuesInvoicedStatus[]
  >([]);
  const [order, setOrder] = React.useState<{
    field: string;
    method: TSortDirection;
  }>({
    field: "startedAt",
    method: "DESC",
  });
  const [page, setPage] = React.useState(1);

  const [queryInput, setQueryInput] = React.useState<string>("");
  const [queryDebounced] = useDebounce(queryInput);
  const [queryParams] = useQueryParams({
    invoiceStatus: StringParam,
  });
  const [isCreatingInvoice, setIsCreatingInvoice] = React.useState(false);
  const {
    selectedIds,
    onSelectIds,
    onDeselectIds,
    onSelectId,
    onDeselectId,
    onDeselectAll,
  } = useMultiselect<number>([], SELECTION_LIMIT);
  const [externalInvoiceDetailModalId, setExternalInvoiceDetailModalId] =
    React.useState<string>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (queryParams?.invoiceStatus) {
      filterPaymentsByInvoicedStatus([queryParams.invoiceStatus]);
      setPaymentStatus([]);
    }
  }, [queryParams]);

  const { user, broker, isBroker } = authHooks.useSelf();
  const hasInvoicing = broker?.features.includes(EBrokerFeature.Invoicing);

  const [filter, setFilter] = React.useState<any>(undefined);
  const identifier = `revenues-${hash(filter || {})}`;

  const paymentRequests =
    useSelector((state: IRootStore) =>
      paymentSelectors.getPaymentRequestsForPage(state, identifier, page),
    ) || [];
  const isFetching = useSelector((state: IRootStore) =>
    paymentSelectors.isFetchingPaymentRequestsForPage(state, identifier, page),
  );
  const fetchError = useSelector((state: IRootStore) =>
    paymentSelectors.paymentRequestsForPageFetchError(state, identifier, page),
  );

  const totalCount =
    useSelector((state: IRootStore) =>
      countSelectors.getCount(state, identifier),
    ) || 0;

  // Data
  const fetchData = (params: { filter: any }) => {
    const { filter: newFilter } = params;

    if (!newFilter) {
      return;
    }

    const { page, limit, ...rest } = newFilter;

    dispatch(
      paymentActions.getPaymentRequestsPaged.actions.start({
        identifier,
        page,
        limit,
        filterData: {
          search: queryDebounced.length === 0 ? undefined : queryDebounced,
          selection: ["FUTURE_REVENUES"],
          ...rest,
        },
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: identifier,
        countBase: append("/payment-requests/count", {
          search: queryDebounced.length === 0 ? undefined : queryDebounced,
          selection: ["FUTURE_REVENUES"],
          where: rest.where,
        }),
      }),
    );
  };

  // Refetch filter
  React.useEffect(() => {
    setFilter(
      getFilterQuery({
        type,
        query: queryDebounced,
        page,
        order,
        paymentStatus,
        invoicesStatus,
        user,
      }),
    );
  }, [type, queryDebounced, page, order, paymentStatus, invoicesStatus]);

  React.useEffect(() => {
    fetchData({ filter });
  }, [filter]);

  const handleMarkInvoiced = (id: number, value: boolean) => {
    dispatch(
      paymentActions.updatePaymentRequestInvoicedAt.actions.start({
        paymentRequestId: id,
        invoicedAt: value ? new Date() : null,
      }),
    );
  };

  const handleCreateInvoiceWithConfirm = (params: {
    paymentRequestIds: number[];
  }) => {
    const { paymentRequestIds } = params;

    confirm({
      title: getLocalizedText("revenues.create_invoice.confirm.title"),
      primaryActions: [
        {
          title: getLocalizedText("revenues.create_invoice.cta.without_email"),
          onPress: () => {
            handleCreateInvoice({
              paymentRequestIds,
              automaticallySendToClient: false,
            });
          },
        },
        {
          title: getLocalizedText("revenues.create_invoice.cta.with_email"),
          onPress: () => {
            handleCreateInvoice({
              paymentRequestIds,
              automaticallySendToClient: true,
            });
          },
        },
      ],
    });
  };

  const handleCreateInvoice = async (params: {
    paymentRequestIds: number[];
    automaticallySendToClient: boolean;
  }) => {
    const { paymentRequestIds, automaticallySendToClient } = params;

    try {
      setIsCreatingInvoice(true);

      await api.post("/external-invoice", {
        isV2: true,
        data: {
          paymentRequestIds,
          automaticallySendToClient,
        },
      });

      showAlert({
        type: "success",
        message: getLocalizedText("revenues.create_invoice.success.title"),
        content: getLocalizedText("revenues.create_invoice.success.message"),
      });

      onDeselectAll();
      fetchData({ filter });
      setIsCreatingInvoice(false);
    } catch (error) {
      setIsCreatingInvoice(false);

      showAlert({ error, type: "error" });
    }
  };

  const renderStatus = (paymentRequest: IPaymentRequest) => {
    const [simpleStatus, variation] = getSimpleStatus(paymentRequest.status);

    if (paymentRequest.status === EPaymentRequestStatus.Partial) {
      return (
        <Lozenge appearance={variation}>
          {getLocalizedText("revenues.payment_status.partial", {
            amount: formatCurrency(paymentRequest.amount),
          })}
        </Lozenge>
      );
    }

    return (
      <Lozenge appearance={variation}>
        {ts.revenuesPaymentStatusLabel({
          extra: { isBroker, key: simpleStatus },
        })}
      </Lozenge>
    );
  };

  const handleFiltersQueryChange = (value: string) => {
    setQueryInput(value);
    setPage(1);
  };

  const handleQueryValueRemove = () => setQueryInput("");

  const handleFiltersClearAll = React.useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  const filterPaymentsByPaymentStatus = (values: any) => {
    setPaymentStatus(values);
    setPage(1);
    onDeselectAll();
  };

  const filterPaymentsByInvoicedStatus = (values: any) => {
    setInvoicesStatus(values);
    setPage(1);
    onDeselectAll();
  };

  const handleInvoicedStatusChange = React.useCallback(
    filterPaymentsByInvoicedStatus,
    [filterPaymentsByInvoicedStatus],
  );
  const handleInvoicedStatusRemove = React.useCallback(
    () => filterPaymentsByInvoicedStatus([]),
    [filterPaymentsByInvoicedStatus],
  );

  const handlePaymentStatusChange = React.useCallback(
    filterPaymentsByPaymentStatus,
    [filterPaymentsByPaymentStatus],
  );
  const handlePaymentStatusRemove = React.useCallback(
    () => filterPaymentsByPaymentStatus([]),
    [filterPaymentsByPaymentStatus],
  );

  const handleSort = (headingIndex: number, direction: TSortDirection) => {
    const sortItem = orderMap[headingIndex];
    if (!sortItem) {
      return;
    }

    setOrder({ field: sortItem, method: direction });
  };

  const handlePageClick = ({ selected }: any) => {
    setPage(selected + 1);
  };

  const initialPage = Math.max(0, page - 1);
  const pageCount = totalCount ? Math.ceil(totalCount / FETCH_LIMIT) : 1;

  const renderFilter = () => {
    const appliedFilters = [];

    if (invoicesStatus && !isEmpty(invoicesStatus)) {
      appliedFilters.push({
        key: "processed",
        label: join(
          invoicesStatus.map((item: any) =>
            ts.revenuesInvoicedLabel({ extra: { isBroker, key: item } }),
          ),
        ),
        onRemove: handleInvoicedStatusRemove,
      });
    }

    if (paymentStatus && !isEmpty(paymentStatus)) {
      const key = "paymentStatus";

      appliedFilters.push({
        key,
        label: join(
          paymentStatus.map((item: any) =>
            ts.revenuesPaymentStatusLabel({
              extra: {
                isBroker,
                key: item,
              },
            }),
          ),
        ),
        onRemove: handlePaymentStatusRemove,
      });
    }

    return (
      <Box mb={ESpacings.loose}>
        <Filters
          appliedFilters={appliedFilters}
          queryValue={queryInput}
          queryPlaceholder={ts.revenuesFilterQueryPlaceholder()}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleQueryValueRemove}
          onClearAll={handleFiltersClearAll}
          filters={[
            {
              key: "paymentStatus",
              label: ts.revenuesFilterLabel({
                extra: { isBroker, key: "payment_status" },
              }),
              content: (
                <OptionListShared
                  id="payment-status"
                  value={paymentStatus}
                  variant="simple"
                  multiple={true}
                  options={Object.values(ERevenuesPaymentStatus).map(status => {
                    return {
                      label: ts.revenuesPaymentStatusLabel({
                        extra: { isBroker, key: status },
                      }),
                      value: status,
                      id: status,
                    };
                  })}
                  onChange={handlePaymentStatusChange}
                />
              ),
            },
            {
              key: "invoiced",
              label: ts.revenuesFilterLabel({
                extra: { isBroker, key: "invoiced" },
              }),
              content: (
                <OptionListShared
                  id="invoiced-status"
                  value={invoicesStatus}
                  variant="simple"
                  multiple={true}
                  options={Object.values(ERevenuesInvoicedStatus).map(type => {
                    return {
                      label: ts.revenuesInvoicedLabel({
                        extra: { isBroker, key: type },
                      }),
                      value: type,
                      id: type,
                    };
                  })}
                  onChange={handleInvoicedStatusChange}
                />
              ),
            },
          ]}
        />
      </Box>
    );
  };

  if (fetchError) {
    return (
      <Card>
        {renderFilter()}
        <p>{ts.fetchError()}</p>
      </Card>
    );
  }

  return (
    <>
      <Card>
        {renderFilter()}

        {selectedIds && selectedIds.length > 0 && (
          <>
            <Stack vertical={true} spacing="extraTight">
              <Button
                isSubmitting={isCreatingInvoice}
                onClick={() => {
                  handleCreateInvoiceWithConfirm({
                    paymentRequestIds: selectedIds,
                  });
                }}
                appearance="primary"
                iconAfter={
                  <S.SelectedCount>
                    <div>{selectedIds.length}</div>
                  </S.SelectedCount>
                }
              >
                {getLocalizedText("revenues.create_invoice")}
              </Button>

              <Button
                onClick={() => onDeselectAll()}
                appearance="link"
                isDisabled={isCreatingInvoice}
              >
                {getLocalizedText("system.clear_selection", {
                  value: `${selectedIds.length}`,
                })}
              </Button>
            </Stack>
            <Spacer weight={ESpacerWeight.W16} />
          </>
        )}

        {paymentRequests && paymentRequests.length === 0 && !isFetching && (
          <EmptyState heading={ts.emptyStateSearchHeading()} icon="archive">
            {ts.emptyStateSearchContent()}
          </EmptyState>
        )}

        {paymentRequests && paymentRequests.length > 0 && (
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "numeric",
              "text",
              "text",
              "action",
            ]}
            horizontalSpacing="tight"
            multiSelect={hasInvoicing}
            selectedIds={selectedIds}
            onSelectPage={(ids: (number | string)[]) => {
              onSelectIds(ids as number[]);
            }}
            onDeselectPage={(ids: (number | string)[]) => {
              onDeselectIds(ids as number[]);
            }}
            onSelectRow={(id: number | string) => {
              onSelectId(id as number);
            }}
            onDeselectRow={(id: number | string) => {
              onDeselectId(id as number);
            }}
            headings={[
              getLocalizedText(`revenues.client.${type}.heading`),
              getLocalizedText("revenues.payee.heading"),
              ts.revenuesPropertyHeading(),
              ts.revenuesCostsHeading(),
              ts.revenuesPayDateHeading(),
              ts.revenuesAmountHeading(),
              ts.revenuesPaymentStatusHeading(),
              ts.revenuesProcessedHeading({ extra: { isBroker } }),
              "",
            ]}
            sortable={orderMap.map(x => !!x)}
            sortColumnIndex={orderMap.indexOf(order.field)}
            sortDirection={order.method}
            onSort={handleSort}
            onRowClick={(index: number) => {
              const item = paymentRequests[index];
              if (!item) {
                return;
              }

              onShowPaymentRequest({ id: item.id });
            }}
            rows={paymentRequests.map(paymentRequest => {
              const paymentOrders = getPaymentOrders(paymentRequest);

              const amount = paymentRequest.originalAmount;
              const amountWithoutVat = sum(
                paymentRequest.paymentRequestItems.map(item => {
                  return getAmountWithoutVat(
                    item.amount,
                    item.paymentOrder.vat / 100,
                  );
                }),
              );
              const showAmountWithoutVat = amountWithoutVat < amount;

              const handleInvoiceSwitch = (value: boolean) => {
                handleMarkInvoiced(paymentRequest.id, value);
              };

              const actions: IAction[] = [];

              actions.push({
                content: ts.paymentsFollowUpCardActionsDetailPaymentRequest(),
                onClick: () => {
                  onShowPaymentRequest({ id: paymentRequest.id });
                },
              });

              if (paymentRequest.externalInvoiceId) {
                actions.push({
                  content: getLocalizedText("revenues.view_invoice"),
                  onClick: () => {
                    if (!paymentRequest.externalInvoiceId) {
                      return;
                    }

                    setExternalInvoiceDetailModalId(
                      paymentRequest.externalInvoiceId,
                    );
                  },
                });
              } else if (paymentRequest.invoicedAt) {
                actions.push({
                  content: ts.revenuesActionMarkUnprocessed({
                    extra: { isBroker },
                  }),
                  onClick: () => handleMarkInvoiced(paymentRequest.id, false),
                });
              } else {
                if (canCreateInvoice({ paymentRequest, broker, user })) {
                  actions.push({
                    content: getLocalizedText("revenues.create_invoice"),
                    onClick: () => {
                      handleCreateInvoiceWithConfirm({
                        paymentRequestIds: [paymentRequest.id],
                      });
                    },
                  });
                }

                actions.push({
                  content: ts.revenuesActionMarkProcessed({
                    extra: { isBroker },
                  }),
                  onClick: () => handleMarkInvoiced(paymentRequest.id, true),
                });
              }

              return {
                id: paymentRequest.id,
                multiSelectDisabled: !canCreateInvoice({
                  paymentRequest,
                  broker,
                  user,
                }),
                content: [
                  <TruncateTooltip width={170}>
                    {getName(paymentRequest.payerAccount)}
                  </TruncateTooltip>,

                  <ContactFetchListItem
                    accountId={paymentRequest.requestPayeeAccountId}
                    renderContact={getName}
                    renderAccount={getName}
                  />,

                  <AddressCell property={paymentRequest.property} />,

                  <TruncateTooltip width={220}>
                    {createTitle({ paymentOrders })}
                  </TruncateTooltip>,

                  <TextStyle>
                    <div style={{ whiteSpace: "nowrap" }}>
                      {utils.date.format(paymentRequest.startedAt)}
                      {paymentRequest.startedAt !== paymentRequest.endedAt && (
                        <TextStyle variation="subdued" element="div">
                          {utils.date.format(paymentRequest.endedAt)}
                        </TextStyle>
                      )}
                    </div>
                  </TextStyle>,

                  <TextStyle
                    variation="code"
                    key={`${paymentRequest.id}-amount`}
                  >
                    <div style={{ whiteSpace: "nowrap" }}>
                      {formatCurrency(amount)}
                      {showAmountWithoutVat && (
                        <TextStyle
                          variation="subdued"
                          size="small"
                          element="div"
                        >
                          {"("}
                          {getLocalizedText("system.amount_without_vat", {
                            amount: formatCurrency(amountWithoutVat),
                          })}
                          {")"}
                        </TextStyle>
                      )}
                    </div>
                  </TextStyle>,

                  renderStatus(paymentRequest),

                  // paymentRequest.invoicedAt ? utils.date.format(paymentRequest.invoicedAt) : '-',

                  <Switch
                    key={`${paymentRequest.id}-processed`}
                    isChecked={
                      !!paymentRequest.invoicedAt ||
                      !!paymentRequest.externalInvoiceId
                    }
                    onChange={handleInvoiceSwitch}
                    isDisabled={!!paymentRequest.externalInvoiceId}
                  />,
                ],
                actions,
              };
            })}
            totalPages={pageCount}
          />
        )}

        {paymentRequests && paymentRequests.length === 0 && isFetching && (
          <Loading asDots={true} />
        )}

        {!!totalCount && pageCount > 1 && (
          <Pagination
            initialPage={initialPage}
            pageCount={pageCount}
            onPageChange={handlePageClick}
          />
        )}
      </Card>

      {externalInvoiceDetailModalId && (
        <ExternalInvoiceDetailModal
          externalInvoiceId={externalInvoiceDetailModalId}
          onClose={() => {
            setExternalInvoiceDetailModalId(undefined);
          }}
        />
      )}
    </>
  );
};
