import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { subYears } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMappedNationalities } from "@rentiohq/shared-frontend/dist/utils/location/location.utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.kycVerification.types";

const NOW = new Date();

export const getSchema =
  (initialValues: { [key: string]: any }) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => ({
    required: [
      EField.DateOfBirth,
      EField.Nationality,
      EField.Address,
      EField.IdCardFront,
      EField.IdCardBack,
    ],
    properties: {
      [EField.DateOfBirth]: {
        default: getValue(EField.DateOfBirth, initialValues, defaultValues),
      },
      [EField.Nationality]: {
        default: getValue(EField.Nationality, initialValues, defaultValues),
        // @ts-ignore
        options: getMappedNationalities(),
      },
      [EField.Address]: {
        default: getValue(EField.Address, initialValues, defaultValues),
      },
      [EField.IdCardFront]: {
        default: getValue(EField.IdCardFront, initialValues, defaultValues),
      },
      [EField.IdCardBack]: {
        default: getValue(EField.IdCardBack, initialValues, defaultValues),
      },
    },
  });

export const uiSchema = (extraData: IExtraData): IUiSchema<EField> => ({
  "rentio:navigationTitle": getLocalizedText(
    "form.kyc_receiver_as_broker.title",
    {
      name: getName(extraData.contact),
    },
  ),
  [EField.Address]: {
    "ui:field": ERentioFormField.Address,
    title: getLocalizedText("system.address"),
  },
  [EField.DateOfBirth]: {
    "ui:field": ERentioFormField.Date,
    title: getLocalizedText("system.date_of_birth"),
    minDate: subYears(NOW, CONFIG.CONTACT_AGE_RANGE.MIN),
    maxDate: subYears(NOW, CONFIG.CONTACT_AGE_RANGE.MAX),
    formatSummary: formData => (formData ? formatDate(formData) : undefined),
  },
  [EField.Nationality]: {
    "ui:field": ERentioFormField.DropdownSelect,
    title: getLocalizedText("system.nationality"),
  },
  [EField.IdCardFront]: {
    "ui:field": ERentioFormField.DocumentSelectInline,
    title: getLocalizedText(
      "form.kyc_receiver_as_broker.field.id_card.front.info.title",
    ),
    accept: ["application/pdf", "image/jpeg", "image/png"],
    multiple: false,
    minFileSize: 32_000, // 32KB
    maxFileSize: 10_000_000, // 10MB
    fileName: getLocalizedText(
      "form.kyc_receiver_as_broker.field.id_card.front.info.title",
    ),
  },
  [EField.IdCardBack]: {
    "ui:field": ERentioFormField.DocumentSelectInline,
    title: getLocalizedText(
      "form.kyc_receiver_as_broker.field.id_card.back.info.title",
    ),
    accept: ["application/pdf", "image/jpeg", "image/png"],
    multiple: false,
    minFileSize: 32_000, // 32KB
    maxFileSize: 10_000_000, // 10MB
    fileName: getLocalizedText(
      "form.kyc_receiver_as_broker.field.id_card.back.info.title",
    ),
  },
});
