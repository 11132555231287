var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import { useNavigate } from "react-router-dom";
import { DropdownMenu, Icon } from "../../components";
import { isArrayOfArrays } from "../../utils/array";
import { renderActions } from "../Button";
import { Checkbox } from "../Form";
import { ResourceList } from "./ResourceList";
import * as S from "./ResourceList.styled";
export var ResourceListItem = function (props) {
    var children = props.children, _a = props.actions, actions = _a === void 0 ? [] : _a, _b = props.primaryActions, primaryActions = _b === void 0 ? [] : _b, onClick = props.onClick, item = props.item, link = props.link, media = props.media, _c = props.mediaVerticalAlign, mediaVerticalAlign = _c === void 0 ? "center" : _c, badge = props.badge, _d = props.depth, depth = _d === void 0 ? 0 : _d, _e = props.mediaSize, mediaSize = _e === void 0 ? "large" : _e, variation = props.variation, _f = props.boxProps, boxProps = _f === void 0 ? {
        p: 3,
    } : _f, _g = props.selected, selected = _g === void 0 ? false : _g, selectable = props.selectable;
    var _h = selectable || {}, page = _h.page, _j = _h.pageItemIds, pageItemIds = _j === void 0 ? [] : _j, selectedIds = _h.selectedIds, onSelectItem = _h.onSelectItem, onDeselectItem = _h.onDeselectItem, onSelectItems = _h.onSelectItems, onDeselectItems = _h.onDeselectItems;
    // Router
    var navigate = useNavigate();
    // Event handlers
    var handleClick = function () {
        if (onClick) {
            onClick();
            return;
        }
        if (link) {
            navigate(link);
            return;
        }
    };
    var getIsChecked = function () {
        var _a, _b;
        if (item) {
            var id = item.id;
            return (_a = selectedIds === null || selectedIds === void 0 ? void 0 : selectedIds.includes(id)) !== null && _a !== void 0 ? _a : false;
        }
        if (page) {
            return (_b = pageItemIds === null || pageItemIds === void 0 ? void 0 : pageItemIds.every(function (id) { return selectedIds === null || selectedIds === void 0 ? void 0 : selectedIds.includes(id); })) !== null && _b !== void 0 ? _b : false;
        }
        return false;
    };
    var handleSelectEvents = function (isChecked) {
        if (page) {
            if (isChecked) {
                onDeselectItems === null || onDeselectItems === void 0 ? void 0 : onDeselectItems(pageItemIds);
            }
            else {
                onSelectItems === null || onSelectItems === void 0 ? void 0 : onSelectItems(pageItemIds);
            }
        }
        if (item) {
            var id = item.id;
            if (isChecked) {
                onDeselectItem === null || onDeselectItem === void 0 ? void 0 : onDeselectItem(id);
            }
            else {
                onSelectItem === null || onSelectItem === void 0 ? void 0 : onSelectItem(id);
            }
        }
    };
    // Render
    var renderCheckBox = function () {
        if (!selectable) {
            return null;
        }
        var isChecked = getIsChecked();
        return (_jsx(S.StyledItemCheckBox, { children: _jsx(Checkbox, { onChangeValue: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelectEvents(isChecked);
                }, checked: isChecked, appearance: isChecked ? "success" : "default", error: false }) }));
    };
    var renderMedia = function () {
        if (!media) {
            return null;
        }
        return (_jsxs(S.StyledItemMedia, { mediaSize: mediaSize, children: [_jsx(S.StyledItemMediaIcon, { children: React.isValidElement(media)
                        ? // @ts-ignore
                            React.cloneElement(media, { size: mediaSize })
                        : media }), (item === null || item === void 0 ? void 0 : item.children) && (_jsx(S.StyledItemMediaIconExpand, { children: _jsx(Icon, { source: item.showChildren ? "subtractCircle" : "addCircle", size: "large" }) })), badge && _jsx(S.StyledItemBadge, { children: badge })] }));
    };
    var renderActionsInternal = function () {
        var hasPrimaryActions = !isEmpty(primaryActions);
        var hasActions = !isEmpty(actions);
        if (!hasPrimaryActions && !hasActions) {
            return null;
        }
        return (_jsxs(S.StyledItemActions, { children: [hasPrimaryActions &&
                    renderActions(primaryActions, {
                        overrides: { appearance: "plain" },
                    }), hasActions && (_jsx(DropdownMenu, { passDownClickEvents: false, children: _jsx(S.StyledItemActionsTrigger, { children: _jsx(Icon, { source: "navigationMenuVertical" }) }), extraData: { actions: actions }, renderContent: function (closeDropdownMenu) {
                        return isArrayOfArrays(actions) ? (_jsx(_Fragment, { children: __spreadArray([], actions, true).map(function (actionList, index) { return (_jsx(ResourceList, { items: actionList, renderItem: renderResourceListAction, hasSeparator: false }, index)); }) })) : (_jsx(ResourceList, { items: actions, renderItem: renderResourceListAction, hasSeparator: false }));
                    } }))] }));
    };
    var renderContent = function () { return (_jsxs(S.StyledItem, __assign({ variation: variation, hasClick: !!onClick || !!link, depth: depth, hasChildren: item && !!item.children, selected: selected, mediaVerticalAlign: mediaVerticalAlign }, boxProps, { children: [renderCheckBox(), renderMedia(), _jsx(S.StyledItemContent, { children: children }), renderActionsInternal()] }))); };
    if (onClick) {
        return _jsx(S.Button, { onClick: handleClick, children: renderContent() });
    }
    if (!link) {
        return renderContent();
    }
    return _jsx(S.StyledLink, { to: link !== null && link !== void 0 ? link : "", children: renderContent() });
};
export var renderResourceListAction = function (option) {
    var media = option.media;
    var mediaProps = option.media ? option.media.props : { size: null };
    var _a = mediaProps.size, size = _a === void 0 ? "small" : _a;
    return (_jsx(ResourceListItem, { mediaSize: size, media: media, item: option, onClick: option.onClick, link: option.link, boxProps: { p: 2 }, children: option.content }));
};
