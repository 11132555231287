var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { PROPERTY_TYPES } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
export var getImageForProperty = function (property) {
    if (property.images.length > 0) {
        var result = __spreadArray([], property.images, true).reverse()[0];
        if (result) {
            return result;
        }
    }
    return "https://assets-rentio-prod.s3.eu-west-1.amazonaws.com/rentio/default-property-image.png";
};
export var getIcon = function (typeId) { var _a; return (_a = PROPERTY_TYPES.find(function (x) { return x.id === typeId; })) === null || _a === void 0 ? void 0 : _a.icon; };
