import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex } from "@rebass/grid";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getIcon } from "../../utils/properties";
import { Button, ButtonActions } from "../Button";
import { DropdownMenu } from "../DropdownMenu";
import { ESpacings } from "../Grid";
import { Icon } from "../Icon";
import * as S from "./ActionCard.styled";
var Item = function (_a) {
    var icon = _a.icon, appearance = _a.appearance, children = _a.children;
    return (_jsxs(Flex, { mt: ESpacings.tight, alignItems: "center", children: [_jsx(S.ItemIcon, { appearance: appearance || "default", children: icon }), _jsx(Box, { children: children })] }));
};
export var ActionCard = function (props) {
    var _a = props.appearance, appearance = _a === void 0 ? "default" : _a, isDetailView = props.isDetailView, heading = props.heading, subheading = props.subheading, actions = props.actions, _b = props.primaryActions, primaryActions = _b === void 0 ? [] : _b, reason = props.reason, property = props.property, children = props.children;
    return (_jsxs(S.Wrapper, { appearance: appearance, children: [_jsxs(S.Header, { appearance: appearance, children: [_jsxs(S.Heading, { children: [_jsx(Box, { flex: 1, mr: ESpacings.tight, children: heading }), actions && (_jsx(S.Actions, { children: _jsx(DropdownMenu, { children: _jsx(S.ActionTrigger, { children: _jsx(Icon, { source: "navigationMenuVertical" }) }), actions: actions }) }))] }), subheading && (_jsx(Box, { mt: ESpacings.tight, children: _jsx(S.Subheading, { children: subheading }) }))] }), _jsxs(S.Content, { children: [children, reason && (_jsx(Item, { appearance: appearance, icon: _jsx(Icon, { source: "alertDiamond" }), children: reason })), primaryActions.map(function (primaryAction, index) { return (_jsx(Box, { mt: ESpacings.base, children: _jsx(ButtonActions, { actions: [primaryAction], options: { overrides: { isFullWidth: true } } }) }, "index")); })] }), property && !isDetailView && (_jsxs(S.Property, { as: Flex, children: [!!property.typeId && (_jsx(Box, { mr: ESpacings.tight, children: _jsx(Icon, { source: getIcon(property.typeId), size: "small" }) })), _jsx(S.PropertyContent, { children: _jsxs(Button, { appearance: "reset", url: "/properties/".concat(property.id, "/payments"), children: [formatAddress(property), " (", property.name, ")"] }) })] }))] }));
};
