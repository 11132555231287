var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import { getDefaultStateAll, registerActions, } from "../generic/all.reducer";
import * as actions from "./template.actions";
export var REDUCER_KEY_TEMPLATE = "template";
export var ENTITY_NAME_TEMPLATE = "template";
export var RESOURCE_NAME_TEMPLATE = "template";
var getDefaultState = function () {
    return merge({}, getDefaultStateAll(), {
        dataTemplateDetail: {},
        templateDetail: {},
    });
};
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
registerActions({
    entityName: "template",
    reducer: reducer,
});
reducer.on(actions.getTemplateDetail.types.START, function (state, _a) {
    var _b;
    var id = _a.id;
    return (__assign(__assign({}, state), { templateDetail: __assign(__assign({}, state.templateDetail), (_b = {}, _b[id] = {
            isFetching: true,
            fetchError: undefined,
        }, _b)) }));
});
reducer.on(actions.getTemplateDetail.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var id = _a.id, templateDetail = _a.templateDetail;
    return (__assign(__assign({}, state), { dataTemplateDetail: __assign(__assign({}, state.dataTemplateDetail), (_b = {}, _b[id] = templateDetail, _b)), templateDetail: __assign(__assign({}, state.templateDetail), (_c = {}, _c[id] = {
            isFetching: false,
            fetchError: undefined,
        }, _c)) }));
});
reducer.on(actions.getTemplateDetail.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return (__assign(__assign({}, state), { templateDetail: __assign(__assign({}, state.templateDetail), (_b = {}, _b[id] = {
            isFetching: false,
            fetchError: error,
        }, _b)) }));
});
reducer.on(actions.updateTemplateDetail.types.START, function (state, _a) {
    var _b;
    var id = _a.id;
    return (__assign(__assign({}, state), { templateDetail: __assign(__assign({}, state.templateDetail), (_b = {}, _b[id] = {
            isUpdating: true,
            updateError: undefined,
        }, _b)) }));
});
reducer.on(actions.updateTemplateDetail.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var id = _a.id, data = _a.data;
    return (__assign(__assign({}, state), { dataTemplateDetail: __assign(__assign({}, state.dataTemplateDetail), (_b = {}, _b[id] = data, _b)), templateDetail: __assign(__assign({}, state.templateDetail), (_c = {}, _c[id] = {
            isUpdating: false,
            updateError: undefined,
        }, _c)) }));
});
reducer.on(actions.updateTemplateDetail.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return (__assign(__assign({}, state), { templateDetail: __assign(__assign({}, state.templateDetail), (_b = {}, _b[id] = {
            isUpdating: false,
            updateError: error,
        }, _b)) }));
});
reducer.on(actions.duplicateTemplate.types.START, function (state, _a) {
    var _b;
    var id = _a.id;
    return (__assign(__assign({}, state), { templateDetail: __assign(__assign({}, state.templateDetail), (_b = {}, _b[id] = {
            isDuplicating: true,
            duplicateError: undefined,
        }, _b)) }));
});
reducer.on(actions.duplicateTemplate.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var id = _a.id, duplicatedTemplate = _a.duplicatedTemplate;
    return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), (_b = {}, _b[duplicatedTemplate.id] = duplicatedTemplate, _b)), templateDetail: __assign(__assign({}, state.templateDetail), (_c = {}, _c[id] = {
            isDuplicating: false,
            duplicateError: undefined,
        }, _c)) }));
});
reducer.on(actions.duplicateTemplate.types.FAILURE, function (state, _a) {
    var _b;
    var id = _a.id, error = _a.error;
    return (__assign(__assign({}, state), { templateDetail: __assign(__assign({}, state.templateDetail), (_b = {}, _b[id] = {
            isDuplicating: false,
            duplicateError: error,
        }, _b)) }));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
