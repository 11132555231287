var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isNumber } from "lodash";
import { hasValue } from "../../redux/form/form.utils";
import { contractModule } from "../../reduxV2/contract";
import { isValidStopDateForTacitRenewal } from "../../reduxV2/contract/contract.utils";
import { isResidential } from "../../reduxV2/property/property.utils";
import { EContractCostsPriceType, EContractLengthType, EContractMemberTypes, VAT_OPTIONS, VAT_OPTIONS_BOOKKEEPING, } from "../../types/contract.types";
import { EPropertyTypeId } from "../../types/property.types";
import { addYears, getMonth, getYear, isBefore, isSameDay, startOfDay, subDays, subMonths, } from "../../utils/date-fns.utils";
import { getGeneralUpdateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import { EEnableIbanisation, EField, EPaymentOrderCreateOption, ETemplateDocumentContractCreateOption, } from "./schema.createContract.types";
import { getEndDate, isExistingContract } from "./schema.createContract.utils";
// INFO: If contract id is passed => update existing contract
export var isPerformingSubmitActionSelector = function (contractId) { return function (state) {
    if (contractId) {
        var contractState = state.CONTRACT_V2.detail[contractId];
        if (!contractState) {
            return false;
        }
        var cancelDeletionState = state.CONTRACT_V2.deleteCancellation[contractId];
        var isPerformingDelete = (cancelDeletionState === null || cancelDeletionState === void 0 ? void 0 : cancelDeletionState.isDeletingCanceling) || false;
        return contractState.pending || isPerformingDelete || false;
    }
    return !!state.CONTRACT_V2.create.pending;
}; };
export var performingSubmitActionErrorSelector = function (contractId) { return function (state) {
    if (contractId) {
        var contractState = state.CONTRACT_V2.detail[contractId];
        if (!contractState) {
            return undefined;
        }
        var cancelDeletionState = state.CONTRACT_V2.deleteCancellation[contractId];
        var hasDeletionError = (cancelDeletionState === null || cancelDeletionState === void 0 ? void 0 : cancelDeletionState.deleteCancellationError) || false;
        return contractState.error || hasDeletionError;
    }
    return state.CONTRACT_V2.create.error;
}; };
export var submitActionCreator = function (params) {
    return function (formData, dispatch) {
        var property = params.property, contractType = params.contractType, contractId = params.contractId, onSuccess = params.onSuccess, templateDocumentGeneration = params.templateDocumentGeneration, deleteCancellation = params.deleteCancellation, bookkeepingEnabled = params.bookkeepingEnabled;
        var isExisting = isExistingContract(formData);
        var indexMonthYear = isExisting
            ? formData[EField.IndexInitial]
            : formData[EField.IndexInitial2];
        var hasDiscount = formData[EField.HasDiscount] === "yes";
        var priceVatKey = isExisting
            ? EField.PriceInitialVat
            : EField.PriceCurrentVat;
        var costsCommonVatKey = isExisting
            ? EField.CommonCostsInitialVat
            : EField.CommonCostsCurrentVat;
        var costsNonCommonVatKey = isExisting
            ? EField.NonCommonCostsInitialVat
            : EField.NonCommonCostsCurrentVat;
        var currentPrice = hasValue(formData[EField.PriceCurrent])
            ? formData[EField.PriceCurrent]
            : null;
        var currentCostsCommon = hasValue(formData[EField.CostsCommonCurrent])
            ? formData[EField.CostsCommonCurrent]
            : null;
        var currentCostsNonCommon = hasValue(formData[EField.CostsNonCommonCurrent])
            ? formData[EField.CostsNonCommonCurrent]
            : null;
        var vatOptionsForFeature = bookkeepingEnabled
            ? VAT_OPTIONS_BOOKKEEPING
            : VAT_OPTIONS;
        var priceVat = hasValue(formData[priceVatKey])
            ? formData[priceVatKey]
            : vatOptionsForFeature[0];
        var priceVatAsNumber = isNumber(priceVat) ? priceVat : 0;
        var priceNoVatType = bookkeepingEnabled && priceVatAsNumber === 0
            ? priceVat
            : null;
        var costsCommon = hasValue(formData[costsCommonVatKey])
            ? formData[costsCommonVatKey]
            : vatOptionsForFeature[0];
        var costsCommonVatAsNumber = isNumber(costsCommon) ? costsCommon : 0;
        var costsCommonNoVatType = bookkeepingEnabled && costsCommonVatAsNumber === 0
            ? costsCommon
            : null;
        var costsNonCommonVat = hasValue(formData[costsNonCommonVatKey])
            ? formData[costsNonCommonVatKey]
            : vatOptionsForFeature[0];
        var costsNonCommonVatAsNumber = isNumber(costsNonCommonVat)
            ? costsNonCommonVat
            : 0;
        var costsNonCommonNoVatType = bookkeepingEnabled && costsNonCommonVatAsNumber === 0
            ? costsNonCommonVat
            : null;
        var startPrice = isExisting
            ? formData[EField.PriceInitial]
            : currentPrice;
        var startCostsCommon = isExisting
            ? formData[EField.CostsCommonInitial]
            : currentCostsCommon;
        var startCostsNonCommon = isExisting
            ? formData[EField.CostsNonCommonInitial]
            : currentCostsNonCommon;
        var startDate = formData[EField.StartDate];
        var stopDate = getEndDate(formData);
        var contractLengthType = null;
        if (isResidential(property)) {
            var finalDateShortContract = addYears(startOfDay(startDate), 3);
            if (isBefore(stopDate, finalDateShortContract)) {
                contractLengthType = EContractLengthType.Short;
            }
            else {
                contractLengthType = EContractLengthType.Long;
            }
        }
        var tacitRenewal = false;
        if (isValidStopDateForTacitRenewal({
            startDate: startDate,
            stopDate: stopDate,
        })) {
            tacitRenewal = formData[EField.TacitRenewal] === "yes";
        }
        var parents = [];
        if (property.typeId === EPropertyTypeId.StudentResidence &&
            formData[EField.Parents]) {
            parents = formData[EField.Parents];
        }
        var contract = {
            propertyId: property.id,
            contractType: contractType,
            leaseType: formData[EField.LeaseType],
            startDate: startDate,
            stopDate: stopDate,
            tacitRenewal: tacitRenewal,
            contractLengthType: contractLengthType,
            payDateStart: formData[EField.PayDate],
            repetitionType: formData[EField.PaymentRepetition],
            currentPrice: currentPrice,
            currentCostsCommon: currentCostsCommon,
            currentCostsNonCommon: currentCostsNonCommon,
            priceVat: priceVatAsNumber,
            priceNoVatType: priceNoVatType,
            costsCommonVat: costsCommonVatAsNumber,
            costsCommonNoVatType: costsCommonNoVatType,
            costsNonCommonVat: costsNonCommonVatAsNumber,
            costsNonCommonNoVatType: costsNonCommonNoVatType,
            rentDiscountEnabled: hasDiscount,
            rentDiscountAmount: hasDiscount ? formData[EField.DiscountAmount] : null,
            rentDiscountOffset: hasDiscount
                ? parseInt(formData[EField.DiscountOffset] || 0) || 0
                : null,
            rentDiscountStartedAt: hasDiscount
                ? formData[EField.DiscountStartDate]
                : null,
            rentDiscountBankAccountId: hasDiscount ? formData[EField.Iban] : null,
            startPrice: startPrice,
            startCostsCommon: startCostsCommon,
            startCostsNonCommon: startCostsNonCommon,
            baseYear: formData[EField.BaseYear],
            startIndexMonth: getMonth(indexMonthYear) + 1, // 0-indexed
            startIndexYear: getYear(indexMonthYear),
            lastIndexedAt: isExisting ? formData[EField.LastIndexedOn] : null,
            priceTypeCommonCosts: formData[EField.CostsCommonCurrent] > 0 ||
                formData[EField.CostsCommonInitial] > 0
                ? formData[EField.PriceTypeCommonCostsCurrent]
                : EContractCostsPriceType.Fixed,
            priceTypeNonCommonCosts: formData[EField.CostsNonCommonCurrent] > 0 ||
                formData[EField.CostsNonCommonInitial] > 0
                ? formData[EField.PriceTypeNonCommonCostsCurrent]
                : EContractCostsPriceType.Fixed,
            members: __spreadArray(__spreadArray(__spreadArray([], (formData[EField.Owners] || []).map(function (accountId) { return ({
                roles: [EContractMemberTypes.Owner],
                accountId: accountId,
            }); }), true), (formData[EField.Tenants] || []).map(function (accountId) { return ({
                roles: [EContractMemberTypes.Tenant],
                accountId: accountId,
            }); }), true), parents.map(function (accountId) { return ({
                roles: [EContractMemberTypes.Parent],
                accountId: accountId,
            }); }), true),
            rentDepositAmount: contractId || formData[EField.RentDepositAmountPrefill] === 0
                ? formData[EField.RentDepositAmount]
                : formData[EField.RentDepositAmountPrefillPreview],
            enableIbanisation: formData[EField.EnableIbanisation] === EEnableIbanisation.Enable,
            // TODO: Check properties
            // "baseYear": 0,
            // "period": "M",
        };
        // Is 2 year contract & already renewed
        if (isSameDay(subDays(addYears(startDate, 2), 1), stopDate) &&
            formData[EField.IsPreviouslyRenewed] === "yes") {
            contract.lastRenewalDate = subMonths(stopDate, 3);
        }
        if (contractId) {
            var customSuccessMessage_1 = "".concat(getGeneralUpdateSuccessText("contract"), " ").concat(getLocalizedText("contract_info.update.payment_orders.updated"));
            var _1 = contract.propertyId, updateContract_1 = __rest(contract, ["propertyId"]);
            if (deleteCancellation) {
                return contractModule.actions.deleteCancellationStart.getAction({
                    id: contractId,
                    data: updateContract_1,
                    onSuccess: function () {
                        if (dispatch) {
                            dispatch(contractModule.actions.updateStart.getAction({
                                id: contractId,
                                data: updateContract_1,
                                customSuccessMessage: customSuccessMessage_1,
                                onSuccess: onSuccess,
                            }));
                        }
                    },
                });
            }
            else {
                return contractModule.actions.updateStart.getAction({
                    id: contractId,
                    data: updateContract_1,
                    customSuccessMessage: customSuccessMessage_1,
                    onSuccess: onSuccess,
                });
            }
        }
        var templateDocumentOption = formData[EField.TemplateDocumentContractCreateOption];
        var selectedTemplateId = templateDocumentOption !== ETemplateDocumentContractCreateOption.None
            ? formData[EField.TemplateDocumentSelected]
            : undefined;
        var selectedTemplate = templateDocumentGeneration === null || templateDocumentGeneration === void 0 ? void 0 : templateDocumentGeneration.templates.find(function (x) { return x.id === selectedTemplateId; });
        var paymentOrderGeneration = formData[EField.PaymentOrderCreateOption] ===
            EPaymentOrderCreateOption.Create
            ? {
                payerAccountId: formData[EField.PaymentOrderCreatePayer][0],
                payeeAccountId: formData[EField.PaymentOrderCreatePayee][0],
                payeeBankAccountId: formData[EField.PaymentOrderCreateIban],
            }
            : undefined;
        return contractModule.actions.createStart.getAction({
            data: __assign(__assign({}, contract), { templateDocumentGeneration: templateDocumentGeneration && selectedTemplate
                    ? {
                        template: selectedTemplate,
                        getTemplateDocumentData: templateDocumentGeneration.getTemplateDocumentData,
                        withSignature: templateDocumentOption ===
                            ETemplateDocumentContractCreateOption.CreateAndSign,
                    }
                    : undefined, paymentOrderGeneration: paymentOrderGeneration }),
            config: {
                keysToRemoveFromRequest: [
                    "templateDocumentGeneration",
                    "paymentOrderGeneration",
                ],
            },
            afterCreate: contractModule.actions.afterContractCreate,
            onSuccess: onSuccess,
        });
    };
};
