import { getAddendumStatusesFromContract } from "@rentiohq/shared-frontend/dist/redux/registration/registration.utils";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { ERegistrationContractStatus } from "@rentiohq/shared-frontend/dist/types/registration.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import React from "react";
import { renderTooltipChecklist } from "../../../../utils/tooltip.utils";
import StatusDot, { EStatus } from "../StatusDot";

interface IProps {
  contract: IContract;
}

const StatusDotRegistration: React.FC<IProps> = props => {
  const { contract } = props;

  // Helpers
  const getStatus = () => {
    const {
      manuallyCompletedRegistration,
      rentRegistrationRentContractStatus = ERegistrationContractStatus.None,
      rentRegistrationLocationDescriptionStatus = ERegistrationContractStatus.None,
    } = contract;

    const rentRegistrationAddendumStatuses =
      getAddendumStatusesFromContract(contract);

    if (manuallyCompletedRegistration) {
      return EStatus.Success;
    }

    let emptyContract = false;
    let emptyLocationDescription = false;

    let pendingContract = false;
    let pendingLocationDescription = false;
    let pendingAddendum = false;

    let successContract = false;
    let successLocationDescription = false;
    let successAddendum = false;

    let failedContract = false;
    let failedLocationDescription = false;

    switch (rentRegistrationRentContractStatus) {
      case ERegistrationContractStatus.Registered:
      case ERegistrationContractStatus.RegisteredManually:
      case ERegistrationContractStatus.NotApplicable:
        successContract = true;
        break;

      case ERegistrationContractStatus.Registering:
      case ERegistrationContractStatus.NeedPayment:
        pendingContract = true;
        break;

      case ERegistrationContractStatus.None:
        emptyContract = true;
        break;

      case ERegistrationContractStatus.Rejected:
      case ERegistrationContractStatus.Failed:
        failedContract = true;
        break;

      case ERegistrationContractStatus.Incomplete:
      default:
        break;
    }

    switch (rentRegistrationLocationDescriptionStatus) {
      case ERegistrationContractStatus.Registered:
      case ERegistrationContractStatus.RegisteredManually:
      case ERegistrationContractStatus.NotApplicable:
        successLocationDescription = true;
        break;

      case ERegistrationContractStatus.Registering:
      case ERegistrationContractStatus.NeedPayment:
        pendingLocationDescription = true;
        break;

      case ERegistrationContractStatus.None:
        emptyLocationDescription = true;
        break;

      case ERegistrationContractStatus.Rejected:
      case ERegistrationContractStatus.Failed:
        failedLocationDescription = true;
        break;

      case ERegistrationContractStatus.Incomplete:
      default:
        break;
    }

    rentRegistrationAddendumStatuses.forEach(rentRegistrationAddendumStatus => {
      switch (rentRegistrationAddendumStatus) {
        case ERegistrationContractStatus.Registered:
        case ERegistrationContractStatus.RegisteredManually:
        case ERegistrationContractStatus.NotApplicable:
          if (!pendingAddendum) {
            successAddendum = true;
          }
          break;

        case ERegistrationContractStatus.Registering:
        case ERegistrationContractStatus.NeedPayment:
          successAddendum = false;
          break;

        case ERegistrationContractStatus.None:
        case ERegistrationContractStatus.Incomplete:
        case ERegistrationContractStatus.Rejected:
        case ERegistrationContractStatus.Failed:
        default:
          break;
      }
    });

    const addendumIsRegistered =
      rentRegistrationAddendumStatuses.length === 0 || successAddendum;

    if (successContract && successLocationDescription && addendumIsRegistered) {
      return EStatus.Success;
    }

    const somethingFailed =
      failedContract || failedLocationDescription || !addendumIsRegistered;
    const somethingPending =
      !emptyContract &&
      (pendingLocationDescription ||
        emptyLocationDescription ||
        pendingContract);

    if (somethingFailed || somethingPending) {
      return EStatus.Warning;
    }

    return EStatus.Error;
  };

  // Render
  const renderTooltipContent = () => {
    const {
      manuallyCompletedRegistration,
      rentRegistrationRentContractStatus = ERegistrationContractStatus.None,
      rentRegistrationLocationDescriptionStatus = ERegistrationContractStatus.None,
    } = contract;

    const rentRegistrationAddendumStatuses =
      getAddendumStatusesFromContract(contract);

    if (manuallyCompletedRegistration) {
      return getLocalizedText(manuallyCompletedRegistration);
    }

    const checklist = [];

    checklist.push({
      label: getLocalizedText("registration.status_info.contract", {
        status: getLocalizedText(
          `registration.status.${stringToSnakeCase(
            rentRegistrationRentContractStatus,
          )}`.toLowerCase(),
        ),
      }),
      success: [
        ERegistrationContractStatus.Registered,
        ERegistrationContractStatus.RegisteredManually,
        ERegistrationContractStatus.NotApplicable,
      ].includes(rentRegistrationRentContractStatus),
    });

    checklist.push({
      label: getLocalizedText("registration.status_info.location_description", {
        status: getLocalizedText(
          `registration.status.${stringToSnakeCase(
            rentRegistrationLocationDescriptionStatus,
          )}`.toLowerCase(),
        ),
      }),
      success: [
        ERegistrationContractStatus.Registered,
        ERegistrationContractStatus.RegisteredManually,
        ERegistrationContractStatus.NotApplicable,
      ].includes(rentRegistrationLocationDescriptionStatus),
    });

    rentRegistrationAddendumStatuses.forEach(status => {
      checklist.push({
        label: getLocalizedText("registration.status_info.addendum", {
          status: getLocalizedText(
            `registration.status.${stringToSnakeCase(status)}`.toLowerCase(),
          ),
        }),
        success: [
          ERegistrationContractStatus.Registered,
          ERegistrationContractStatus.RegisteredManually,
          ERegistrationContractStatus.NotApplicable,
        ].includes(rentRegistrationLocationDescriptionStatus),
      });
    });

    return renderTooltipChecklist(checklist);
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={renderTooltipContent()} />
  );
};

// eslint-disable-next-line import/no-default-export
export default StatusDotRegistration;
