import { jsx as _jsx } from "react/jsx-runtime";
import SwitchToggle from "@rentiohq/shared-frontend/dist/components/components/SwitchToggle";
import React from "react";
var SwitchBase = function (props) {
    var _a = props.isChecked, isChecked = _a === void 0 ? false : _a, _b = props.isDisabled, isDisabled = _b === void 0 ? false : _b, onChange = props.onChange;
    var handleClick = function () {
        !isDisabled && onChange(!isChecked);
    };
    return (_jsx(SwitchToggle, { value: isChecked, isDisabled: isDisabled, onValueChange: handleClick }));
};
export var Switch = React.memo(SwitchBase);
