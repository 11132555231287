import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { Grid } from "@rentiohq/web-shared-next/dist/ui-components";
import { PropertyCard } from "../../components/PropertyCard";

interface PropertyProps {
  properties: IProperty[];
  isActive?: boolean;
}

export const Property = ({ properties, isActive }: PropertyProps) => {
  return (
    <Grid container spacing={1}>
      {properties.map(property => (
        <Grid key={property?.id} item xs={12} sm={6} md={4} lg={4} xl={3}>
          <PropertyCard property={property} isActive={isActive} />
        </Grid>
      ))}
    </Grid>
  );
};
