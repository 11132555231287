import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as cardActions from "@rentiohq/shared-frontend/dist/redux/card/card.actions";
import { ICard } from "@rentiohq/shared-frontend/dist/redux/card/card.types";
import {
  Button,
  ESpacings,
  Icon,
  RentioInternalRenderer,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { useDispatch } from "react-redux";
import * as S from "./Card.styles";
import CardButton from "./CardButton";

interface IProps {
  card: ICard;
  onDone: () => void;
}

const Card: React.FC<IProps> = (props: IProps) => {
  const { internalModeEnabled } = useInternalMode();
  const { card, onDone } = props;
  const buttons = [...(card.buttons || []), ...(card.extraButtons || [])];

  // Redux
  const dispatch = useDispatch();

  // Event handlers
  const handleClickCloseButton = (card: ICard) => {
    dispatch(cardActions.closeCard.actions.start({ cardId: card.id }));
  };

  // Render
  return (
    <S.Wrap key={card.id}>
      {/* {card.icon ? <S.Icon src={card.icon} alt="Notification icon" /> : <S.IconSpacing />} */}
      <S.TextWrap>
        {card.title && (
          <TextStyle variation="strong">
            <div dangerouslySetInnerHTML={{ __html: card.title }} />
          </TextStyle>
        )}
        <Spacer weight={ESpacerWeight.W04} />
        {card.message && (
          <TextStyle variation="default">
            <div dangerouslySetInnerHTML={{ __html: card.message }} />
          </TextStyle>
        )}

        {!card.hasCloseButton && internalModeEnabled && (
          <RentioInternalRenderer>
            <Button
              appearance={"link"}
              onClick={() => {
                if (!confirm("Force close card?")) {
                  return;
                }

                handleClickCloseButton(card);
              }}
            >
              Force close
            </Button>
          </RentioInternalRenderer>
        )}

        {buttons && (
          <Box mt={ESpacings.tight}>
            <S.CardButtonsWrap>
              {buttons.map((cardButton, index) => (
                <CardButton
                  key={index}
                  card={card}
                  cardButton={cardButton}
                  actionIndex={index}
                  onDone={onDone}
                />
              ))}
            </S.CardButtonsWrap>
          </Box>
        )}
      </S.TextWrap>
      {card.hasCloseButton && (
        <S.CloseButtonWrap>
          <S.CloseButton
            onClick={() => {
              handleClickCloseButton(card);
            }}
          >
            <Icon source={"closeBold"} size={"small"} />
          </S.CloseButton>
        </S.CloseButtonWrap>
      )}
    </S.Wrap>
  );
};

// eslint-disable-next-line import/no-default-export
export default Card;
