import { useTheme } from "@mui/material/styles";
export var SimpleInfoBoxStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            bgcolor: theme.palette.background.paper,
            padding: "16px",
            border: "1px solid ".concat(theme.palette.grey[200]),
            boxShadow: theme.shadows[0],
            marginTop: "16px",
        },
        content: {
            description: {
                display: "flex",
                flexWrap: "wrap",
                color: theme.palette.text.primary,
            },
        },
    };
};
