import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import signInSchemas from "@rentiohq/shared-frontend/dist/forms/signInToken";
import * as signInActions from "@rentiohq/shared-frontend/dist/forms/signInToken/schema.signInToken.actions";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as authSelectors from "@rentiohq/shared-frontend/dist/redux/auth/auth.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useSelector } from "react-redux";
import { StringParam } from "serialize-query-params";
import { DisplayText } from "../../components";
import { MultiStepForm } from "../../components/SharedForm/MultiStepForm";
import * as t from "../../services/translationService";
import { StyledAuthBody, StyledAuthContent, StyledAuthFrame, StyledAuthLogo, StyledAuthMain, StyledAuthMedia, StyledAuthMediaWrap, } from "./Auth.styled";
import { useNavigateAfterSuccess } from "./Auth.utils";
import { InternalModeButton } from "./components/InternalModeButton";
var SignInToken = function () {
    var queryParams = useQueryParams({
        token: StringParam,
    })[0];
    // Router
    var navigateAfterSuccess = useNavigateAfterSuccess();
    // State
    var _a = React.useState(true), preventRedirect = _a[0], setPreventRedirect = _a[1];
    // Redux
    var hasAccessToken = useSelector(authSelectors.hasAccessToken);
    var user = authHooks.useSelf().user;
    // Lifecycle
    React.useEffect(function () {
        if (preventRedirect) {
            return;
        }
        if (!hasAccessToken || !user) {
            return;
        }
        navigateAfterSuccess();
    }, [hasAccessToken, navigateAfterSuccess, preventRedirect, user]);
    // Render
    return (_jsxs(StyledAuthFrame, { children: [_jsx(StyledAuthContent, { children: _jsxs(StyledAuthMain, { children: [_jsx(InternalModeButton, {}), _jsx(StyledAuthLogo, {}), _jsxs(StyledAuthBody, { children: [_jsx(DisplayText, { children: t.authLoginTitle() }), _jsx(MultiStepForm, { formId: "sign-in", 
                                    // @ts-ignore state match
                                    isPerformingSubmitActionSelector: signInActions.isPerformingSubmitActionSelector(), 
                                    // @ts-ignore state match
                                    performingSubmitActionResultSelector: signInActions.performingSubmitActionResultSelector(), 
                                    // @ts-ignore state match
                                    performingSubmitActionErrorSelector: signInActions.performingSubmitActionErrorSelector(), submitActionCreator: signInActions.submitActionCreator(), schemas: signInSchemas({ token: queryParams.token || undefined }), asModal: false, withAside: false, onSuccess: function () {
                                        setPreventRedirect(false);
                                    }, submitLabel: getLocalizedText("auth.login.submit.label"), fullWidthSubmitButton: true })] })] }) }), _jsx(StyledAuthMediaWrap, { children: _jsx(StyledAuthMedia, {}) })] }));
};
// eslint-disable-next-line import/no-default-export
export default SignInToken;
