export var ETemplateAccessScope;
(function (ETemplateAccessScope) {
    ETemplateAccessScope["Public"] = "PUBLIC";
    ETemplateAccessScope["Private"] = "PRIVATE";
})(ETemplateAccessScope || (ETemplateAccessScope = {}));
export var ETemplateType;
(function (ETemplateType) {
    ETemplateType["ContractResidential"] = "ContractResidential";
    ETemplateType["ContractResidentialShort"] = "ContractResidentialShort";
    ETemplateType["ContractResidentialLong"] = "ContractResidentialLong";
    ETemplateType["ContractStudentResidence"] = "ContractStudentResidence";
    ETemplateType["ContractCommercial"] = "ContractCommercial";
    ETemplateType["ContractParkingLot"] = "ContractParkingLot";
    ETemplateType["ContractGarage"] = "ContractGarage";
    ETemplateType["ContractOther"] = "ContractOther";
    ETemplateType["KeyHandover"] = "KeyHandover";
    ETemplateType["Indexation"] = "Indexation";
    ETemplateType["PropertyInspection"] = "PropertyInspection";
    ETemplateType["Other"] = "Other";
})(ETemplateType || (ETemplateType = {}));
export var ETemplateTag;
(function (ETemplateTag) {
    ETemplateTag["Broker"] = "Broker";
    ETemplateTag["Consumer"] = "Consumer";
    ETemplateTag["Tenant"] = "Tenant";
})(ETemplateTag || (ETemplateTag = {}));
export var ETemplateRegionBelgium;
(function (ETemplateRegionBelgium) {
    ETemplateRegionBelgium["Flanders"] = "FL";
    ETemplateRegionBelgium["Wallonia"] = "WA";
    ETemplateRegionBelgium["Brussels"] = "BR";
})(ETemplateRegionBelgium || (ETemplateRegionBelgium = {}));
