import { uniqBy } from "lodash";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { EPaymentOrderType } from "../../types/payment.types";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createPayment.types";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.BookkeepingGeneralLedgerAccount],
            properties: (_a = {},
                _a[EField.Header + "18"] = {
                    default: undefined,
                },
                _a[EField.BookkeepingPeriodStart] = {
                    default: getValue(EField.BookkeepingPeriodStart, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.TypeOnce] === EPaymentOrderType.OtherOneoff;
                    },
                },
                _a[EField.BookkeepingPeriodEnd] = {
                    default: getValue(EField.BookkeepingPeriodEnd, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.TypeOnce] === EPaymentOrderType.OtherOneoff;
                    },
                },
                _a[EField.BookkeepingGeneralLedgerAccount] = {
                    default: getValue(EField.BookkeepingGeneralLedgerAccount, initialValues, defaultValues),
                    minLength: 6,
                    maxLength: 6,
                    // @ts-ignore
                    getOptions: function (formData) {
                        var _a;
                        var payeeAccountId = formData[EField.Payee][0];
                        var paymentOrderType = formData[EField.TypeRecurring] || formData[EField.TypeOnce];
                        if (!extraData.generalLedgerAccounts) {
                            return [];
                        }
                        var mapGeneralLedgerAccount = function (x) { return ({
                            id: x.code,
                            value: ["".concat(x.code), x.description].join(" - "),
                        }); };
                        var result = (_a = extraData.generalLedgerAccounts) === null || _a === void 0 ? void 0 : _a.filter(function (x) {
                            return (!payeeAccountId || x.accountId === payeeAccountId) &&
                                x.paymentOrderType === paymentOrderType;
                        }).map(mapGeneralLedgerAccount);
                        if (result.length > 0) {
                            return uniqBy(result, "id");
                        }
                        return uniqBy(extraData.generalLedgerAccounts.map(mapGeneralLedgerAccount), "id");
                    },
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("payment.payment_order.bookkeeping.heading.summary"),
            "ui:field": ERentioFormField.Grid,
            grid: {
                row: [
                    {
                        col: {
                            width: 1,
                            children: [EField.Header + "18"],
                        },
                    },
                    {
                        col: {
                            width: [1, 1 / 2],
                            children: [EField.BookkeepingPeriodStart],
                        },
                    },
                    {
                        col: {
                            width: [1, 1 / 2],
                            children: [EField.BookkeepingPeriodEnd],
                        },
                    },
                    {
                        col: {
                            width: [1, 2 / 5],
                            children: [EField.BookkeepingGeneralLedgerAccount],
                        },
                    },
                ],
            }
        },
        _a[EField.Header + "18"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("payment.payment_order.bookkeeping.heading"),
        },
        _a[EField.BookkeepingPeriodStart] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("payment.payment_order.bookkeeping.period_start"),
            optional: true,
            formatSummary: function (formData) { return (formData ? formatDate(formData) : undefined); },
        },
        _a[EField.BookkeepingPeriodEnd] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("payment.payment_order.bookkeeping.period_end"),
            optional: true,
            formatSummary: function (formData) { return (formData ? formatDate(formData) : undefined); },
        },
        _a[EField.BookkeepingGeneralLedgerAccount] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("payment.payment_order.bookkeeping.general_ledger_account"),
            optional: false,
        },
        _a);
};
export var getValidate = function (extraData) { return function (formData, errors) {
    var _a, _b, _c;
    var hasSelected = formData[EField.BookkeepingPeriodStart] ||
        formData[EField.BookkeepingPeriodEnd];
    if (hasSelected) {
        if (!formData[EField.BookkeepingPeriodStart]) {
            (_a = errors[EField.BookkeepingPeriodStart]) === null || _a === void 0 ? void 0 : _a.addError(getLocalizedText("system.form.error.required"));
        }
        else if (!formData[EField.BookkeepingPeriodEnd]) {
            (_b = errors[EField.BookkeepingPeriodEnd]) === null || _b === void 0 ? void 0 : _b.addError(getLocalizedText("system.form.error.required"));
        }
        else if (formData[EField.BookkeepingPeriodStart] >
            formData[EField.BookkeepingPeriodEnd]) {
            (_c = errors[EField.BookkeepingPeriodEnd]) === null || _c === void 0 ? void 0 : _c.addError(getLocalizedText("filter.month_range.error.to_before_from"));
        }
    }
    return errors;
}; };
