import { Box } from "@rebass/grid";
import { RecurrentIcon } from "@rentiohq/shared-frontend/dist/components/components/TaskListItem/TaskListItem.styles";
import {
  ERecurrenceType,
  ITask,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Lozenge } from "@rentiohq/web-shared/dist/components";
import { spacing } from "@rentiohq/web-theme";
import { memo } from "react";
import Truncate from "react-truncate";
import * as TS from "../../Tasks.styled";
import * as S from "./TaskTitle.styled";

interface TaskTitleProps extends ITask {}

const TaskTitle = ({
  title,
  recurrenceType,
  description,
  type,
}: TaskTitleProps) => {
  return (
    <>
      <TS.FlexColumn style={{ maxWidth: S.WIDTH_TITLE_COLUMN }}>
        <S.TitleWithIcon>
          <p>{title}</p>
          {recurrenceType !== ERecurrenceType.Once ? (
            <S.IconWrapper>
              <RecurrentIcon />
            </S.IconWrapper>
          ) : null}
        </S.TitleWithIcon>
        {description && (
          <TS.GrayText>
            <Truncate
              trimWhitespace={true}
              style={{ width: S.WIDTH_TITLE_COLUMN }}
            >
              {description}
            </Truncate>
          </TS.GrayText>
        )}
        {type && (
          <Box style={{ paddingTop: spacing("extraTight") }}>
            <Lozenge>
              {getLocalizedText(`task.type.${type.toLowerCase()}`)}
            </Lozenge>
          </Box>
        )}
      </TS.FlexColumn>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default memo(TaskTitle);
