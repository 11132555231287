import logger from "@rentiohq/shared-frontend/dist/logger";
import { IBroker } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getTenantProfileUrl } from "@rentiohq/shared-frontend/dist/utils/property.utils";
import { toast } from "@rentiohq/web-shared/dist/managers/Toast";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { useContext } from "react";
import {
  IApplicationDropDownHandler,
  IDropArea,
} from "../PropertyDetailApplications";
import { DragAndDropContext } from "../context/DragAndDropContext";
import { IMultiSelectProps } from "./DragItem/DragItem";
import { PropertyApplicationDropArea } from "./PropertyApplicationDropArea";

interface IProps {
  property: IProperty;
  broker?: IBroker;

  dropAreas: IDropArea[];

  handler: IApplicationDropDownHandler;

  showActions?: boolean;
  onClickInviteViaEmail: () => void;
}

const handleCopyUrlToClipboard = async (
  property: IProperty,
  broker?: IBroker,
) => {
  const url = getTenantProfileUrl(property, broker);
  if (!url) {
    return;
  }

  try {
    navigator.clipboard.writeText(url);

    toast({
      heading: getLocalizedText("system.copied_to_clipboard"),
      variation: "success",
    });
  } catch (unknownError) {
    const error = unknownError as any;
    logger.logError({ error });
  }
};

interface IDropAreasListProps extends IProps, IMultiSelectProps {}

export const DropAreasList = (props: IDropAreasListProps) => {
  const {
    property,
    broker,
    dropAreas,
    handler,
    showActions = false,
    onClickInviteViaEmail,
  } = props;

  const { selectedItems } = useContext(DragAndDropContext);

  return (
    <>
      {dropAreas.map((dropArea: any, index: any) => {
        const actions: IAction[] = [];
        const dropItemItemIds = dropArea.items.map((item: any) => item.id);

        if (showActions && index === 0) {
          actions.push({
            content: getLocalizedText(
              "property.application.invite.email_action.label",
            ),
            onClick: onClickInviteViaEmail,
            size: "small",
            appearance: "primary",
          });

          if (getTenantProfileUrl(property, broker)) {
            actions.push({
              content: getLocalizedText(
                "property.application.invite.url_action.label",
              ),
              onClick: () => handleCopyUrlToClipboard(property, broker),
              size: "small",
              appearance: "primary",
            });
          }
        }

        return (
          <PropertyApplicationDropArea
            key={dropArea.id}
            id={dropArea.id}
            items={dropArea.items}
            allowedSteps={dropArea.allowedSteps}
            isDisabled={dropArea.isDisabled}
            selectedItemIds={selectedItems
              .filter(item => dropItemItemIds.includes(item.id))
              .map(item => item.id)}
            handler={handler}
            actionsLabel={
              actions.length > 0
                ? getLocalizedText(
                    "property.application.invite.main_action.label",
                  )
                : undefined
            }
            actions={actions.length > 0 ? actions : undefined}
          />
        );
      })}
    </>
  );
};
