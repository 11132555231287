import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import {
  Box,
  Icon,
  IMenuAction,
  Menu,
  Stack,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { ECaptionVariant } from "../../types";
import { AccountListItemStyles } from "./AccountListItem.styles";

export interface IProps {
  account: IAccount;
  getActions?: (params: { accountIds: number[] }) => IMenuAction[];
  getSubtitle?: (
    accountIds: number[],
    contact?: IContact,
  ) =>
    | {
        subTitle: React.ReactNode | undefined;
        subTitleColor?: ECaptionVariant;
      }
    | undefined;
}

export const AccountListItem = ({
  account,
  getSubtitle,
  getActions,
}: IProps) => {
  const { subTitle = "", subTitleColor = ECaptionVariant.Default } =
    getSubtitle?.([account.id]) || {};

  const sx = AccountListItemStyles(subTitleColor);

  // Will be used later

  // const handleEditContact = () => console.log({ account });

  // const handleViewContact = () => navigate(`/contacts/${account.id}`);

  const actions = [
    ...(getActions?.({ accountIds: [account.id] }) || []),
    // {
    //   label: "contact.edit_contact",
    //   icon: EAssets.PencilIcon,
    //   onClick: handleEditContact,
    // },
    // {
    //   label: "contact.view_contact.action",
    //   icon: EAssets.SingleNeutralIcon,
    //   onClick: handleViewContact,
    // },
  ];

  const iconSource = !!account.company
    ? EAssets.SuitCaseIcon
    : EAssets.UserOutlinedIcon;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={sx.content.accountItem}
      >
        {iconSource && <Icon path={iconSource} width="24px" height="24px" />}
        <Box>
          <Typography variant="subtitle1" lineHeight="16px">
            {getName(account)}
          </Typography>
          {subTitle && (
            <Typography variant="subtitle1" sx={sx.content.subtitle}>
              {subTitle}
            </Typography>
          )}
        </Box>
      </Stack>
      {actions?.length > 0 && <Menu actions={actions} />}
    </Stack>
  );
};
