import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { payInModule } from "@rentiohq/shared-frontend/dist/reduxV2/payin";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { IPayIn } from "@rentiohq/shared-frontend/dist/types/payin.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatIban } from "@rentiohq/shared-frontend/dist/utils/iban.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import {
  Button,
  Card,
  DataTable,
  Lozenge,
  PropertyFetchListItem,
  TColumnContentType,
  TextStyle,
  TruncateMiddle,
  TruncateTooltip,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { compact, without } from "lodash";
import { NumberParam } from "serialize-query-params";
import { ContractInfoCardFooter } from "../ContractInfoCardFooter";

interface IProps {
  showGeneralTitle: boolean;
  payIns: IPayIn[];
  onMatch: (payIn: IPayIn) => void;
  showPropertyInfo: boolean;
  showFooter: boolean;
  customPreFooter?: React.ReactNode;
}

export const PayInTable = (props: IProps) => {
  const {
    showGeneralTitle,
    payIns,
    onMatch,
    showPropertyInfo,
    showFooter,
    customPreFooter,
  } = props;

  const contractId = payIns[0]?.contractId;

  const { internalModeEnabled } = useInternalMode();

  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const [, setQueryParamValue] = useQueryParams({
    payInId: NumberParam,
  });

  // Event handlers
  const generateHandleMatch = (payIn: IPayIn) => () => {
    onMatch(payIn);
  };

  // Render
  return (
    <Card
      heading={
        showGeneralTitle
          ? getLocalizedText("follow_up.unmatched_pay_ins.title")
          : undefined
      }
      footerContent={
        showFooter ? (
          <ContractInfoCardFooter
            contractId={contractId}
            showIbanisationInfo={true}
          />
        ) : undefined
      }
    >
      <Card.Section
        boxProps={{
          py: 1,
        }}
      >
        <DataTable
          verticalAlign="middle"
          columnContentTypes={
            without(
              [
                "text",
                "text",
                "text",
                "text",
                "text",
                showPropertyInfo ? "text" : undefined,
                showPropertyInfo ? undefined : "action",
                "action",
              ],
              undefined,
            ) as TColumnContentType[]
          }
          headings={without(
            [
              getLocalizedText("pay_in.item.payment_date"),
              getLocalizedText("pay_in.item.amount"),
              getLocalizedText("pay_in.item.reference"),
              getLocalizedText("pay_in.item.payer.iban"),
              getLocalizedText("pay_in.item.payer.author"),
              showPropertyInfo
                ? getLocalizedText("pay_in.item.property")
                : undefined,
              showPropertyInfo ? undefined : getLocalizedText("system.action"),
              "",
            ],
            undefined,
          )}
          onRowClick={rowIndex => {
            setQueryParamValue({
              payInId: payIns?.[rowIndex].id,
            });
          }}
          rows={payIns.map(payIn => {
            const contractInfo =
              internalModeEnabled && hasPaymentV2ForBroker
                ? payIn.contractInfo
                : payIn.contract;

            return {
              id: payIn.id,
              content: compact([
                formatDate(payIn.createdAt),
                <>
                  <TextStyle variation={"strong"} element="div">
                    {formatCurrency(
                      payInModule.utils.amountToBeAllocated(payIn),
                    )}
                  </TextStyle>

                  <Spacer weight={ESpacerWeight.W04} />

                  {payInModule.utils.isPristine(payIn) ? (
                    <Lozenge>
                      {getLocalizedText("pay_in.allocation_type.pristine")}
                    </Lozenge>
                  ) : (
                    <Lozenge appearance="default">
                      {getLocalizedText("pay_in.allocation_type.balance")}
                    </Lozenge>
                  )}
                </>,
                payIn.reference ? (
                  <TruncateTooltip width={180}>
                    {payIn.reference}
                  </TruncateTooltip>
                ) : (
                  "-"
                ),
                payIn.authorIban ? (
                  <span style={{ whiteSpace: "nowrap" }}>
                    {formatIban(payIn.authorIban)}
                  </span>
                ) : (
                  "-"
                ),
                payIn.authorName || "-",
                showPropertyInfo ? (
                  <PropertyFetchListItem
                    propertyId={contractInfo?.propertyId}
                    renderProperty={property => (
                      <div style={{ maxWidth: 180 }}>
                        <TextStyle>
                          <TruncateMiddle
                            input={formatAddress(property, false, false)}
                            skipInitialChars={14}
                          />
                        </TextStyle>

                        <TextStyle variation={"subdued"}>
                          {`${property.zip} ${property.city}`}
                        </TextStyle>
                      </div>
                    )}
                  />
                ) : undefined,
                showPropertyInfo ? undefined : (
                  <Button
                    appearance="outline"
                    onClick={generateHandleMatch(payIn)}
                  >
                    {getLocalizedText("pay_in.cta.match")}
                  </Button>
                ),
              ]),
              actions: [
                compact([
                  showPropertyInfo
                    ? undefined
                    : {
                        content: (
                          <TextStyle variation="positive">
                            {getLocalizedText("pay_in.cta.match")}
                          </TextStyle>
                        ),
                        onClick: generateHandleMatch(payIn),
                      },
                ]),
                compact([
                  {
                    content: getLocalizedText("system.view_detail"),
                    onClick: () => {
                      setQueryParamValue({ payInId: payIn.id });
                    },
                  },
                  showPropertyInfo && contractInfo?.propertyId
                    ? {
                        content: getLocalizedText(
                          "property.view_property.action",
                        ),
                        url: `/properties/${contractInfo.propertyId}/payments`,
                      }
                    : undefined,
                ]),
              ],
            };
          })}
        />
      </Card.Section>

      {customPreFooter}
    </Card>
  );
};
