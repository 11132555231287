import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import * as CONFIG from "scenes/Settings/scenes/TenantProfileNotificationPreferences/TenantProfileNotificationPreferences.config";

export interface IPreference {
  key: string;
  value: any;
}

interface IDefaultPreference {
  key: string;
  defaultValue: string;
}

export const preferences: IDefaultPreference[] = [
  {
    key: CONFIG.PREFERENCE_NOT_ELIGIBLE_NL,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.not_eligible.placeholder",
      undefined,
      undefined,
      ELocale.NL,
    ),
  },
  {
    key: CONFIG.PREFERENCE_NOT_ELIGIBLE_FR,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.not_eligible.placeholder",
      undefined,
      undefined,
      ELocale.FR,
    ),
  },
  {
    key: CONFIG.PREFERENCE_NOT_ELIGIBLE_EN,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.not_eligible.placeholder",
      undefined,
      undefined,
      ELocale.EN,
    ),
  },

  {
    key: CONFIG.PREFERENCE_VISITOR_NL,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.visitor.placeholder",
      undefined,
      undefined,
      ELocale.NL,
    ),
  },
  {
    key: CONFIG.PREFERENCE_VISITOR_FR,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.visitor.placeholder",
      undefined,
      undefined,
      ELocale.FR,
    ),
  },
  {
    key: CONFIG.PREFERENCE_VISITOR_EN,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.visitor.placeholder",
      undefined,
      undefined,
      ELocale.EN,
    ),
  },

  {
    key: CONFIG.PREFERENCE_PLAN_FOR_VISIT_NL,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.plan_for_visit.placeholder",
      undefined,
      undefined,
      ELocale.NL,
    ),
  },
  {
    key: CONFIG.PREFERENCE_PLAN_FOR_VISIT_FR,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.plan_for_visit.placeholder",
      undefined,
      undefined,
      ELocale.FR,
    ),
  },
  {
    key: CONFIG.PREFERENCE_PLAN_FOR_VISIT_EN,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.plan_for_visit.placeholder",
      undefined,
      undefined,
      ELocale.EN,
    ),
  },

  {
    key: CONFIG.PREFERENCE_WAITING_LIST_NL,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.waiting_list.placeholder",
      undefined,
      undefined,
      ELocale.NL,
    ),
  },
  {
    key: CONFIG.PREFERENCE_WAITING_LIST_FR,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.waiting_list.placeholder",
      undefined,
      undefined,
      ELocale.FR,
    ),
  },
  {
    key: CONFIG.PREFERENCE_WAITING_LIST_EN,
    defaultValue: getLocalizedText(
      "tenant_profile.notification.waiting_list.placeholder",
      undefined,
      undefined,
      ELocale.EN,
    ),
  },
];
