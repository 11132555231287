var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import ButtonPrimary from "@rentiohq/shared-frontend/dist/components/components/ButtonPrimary";
import Spacer, { ESpacerDirection, ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { DropdownMenu, ESpacings, Icon } from "../../../../components";
import { EExternalViewType } from "../../../TemplateDocumentEditor/scenes/TemplateDocumentExternal/TemplateDocumentExternal.types";
import { EDisplayType, } from "../Editor/Editor.types";
import { getIsVariablesDataValid } from "../Editor/Editor.utils";
import { DisplayTypeContext } from "../Editor/contexts/DisplayType.context";
import { DocumentConfigContext } from "../Editor/contexts/DocumentConfig.context";
import { DocumentIdsContext } from "../Editor/contexts/DocumentIds.context";
import { EditorStateContext } from "../Editor/contexts/EditorState.context";
import { ExitPromptContext } from "../Editor/contexts/ExitPrompt.context";
import { FieldsContext } from "../Editor/contexts/Fields.context";
import { VariablesDataContext } from "../Editor/contexts/VariablesData.context";
import * as S from "./ToolbarFooter.styles";
export var ToolbarFooter = function (props) {
    var onPressUpdate = props.onPressUpdate, onPressSave = props.onPressSave, onPressNext = props.onPressNext, onPressBack = props.onPressBack, canEdit = props.canEdit, externalViewType = props.externalViewType;
    // states
    var _a = React.useState(false), sendForSigning = _a[0], setSendForSigning = _a[1];
    // Context
    var _b = React.useContext(DisplayTypeContext), displayType = _b.displayType, setDisplayType = _b.setDisplayType, isTemplateDocument = _b.isTemplateDocument, isTemplateDocumentEditor = _b.isTemplateDocumentEditor, isTemplateDocumentExternal = _b.isTemplateDocumentExternal;
    var fields = React.useContext(FieldsContext).fields;
    var variablesData = React.useContext(VariablesDataContext).variablesData;
    var documentConfig = React.useContext(DocumentConfigContext).documentConfig;
    var editorState = React.useContext(EditorStateContext).editorState;
    var documentIds = React.useContext(DocumentIdsContext).documentIds;
    var setExitPromptEnabled = React.useContext(ExitPromptContext).setExitPromptEnabled;
    // Event handlers
    var handlePressSendToTenant = function () {
        if (!editorState) {
            return;
        }
        onPressSave === null || onPressSave === void 0 ? void 0 : onPressSave({
            withGeneratePdf: true,
            editorState: editorState,
            fields: fields,
            documentConfig: documentConfig,
            variablesData: variablesData,
            documentIds: documentIds,
            tenantCanEdit: true,
        });
    };
    var handlePressSave = function (withGeneratePdf, skipValidation) {
        if (skipValidation === void 0) { skipValidation = false; }
        if (!editorState) {
            return;
        }
        var editableVariables = fields.variables.filter(function (x) { return x.editableByTenant; });
        var editableFields = isTemplateDocumentExternal
            ? __assign(__assign({}, fields), { variables: editableVariables }) : fields;
        // Only validate data before PDF export
        if (!skipValidation &&
            withGeneratePdf &&
            !getIsVariablesDataValid({ fields: editableFields, variablesData: variablesData })) {
            confirm({
                type: "warning",
                modalProps: {
                    hasDismiss: true,
                    shouldCloseOnOverlayClick: true,
                },
                title: getLocalizedText("editor.template_document.alert.invalid_fields.title"),
                info: getLocalizedText("editor.template_document.alert.invalid_fields.info"),
                secondaryAction: {
                    title: getLocalizedText("system.back"),
                    onPress: function () {
                        if (sendForSigning) {
                            setSendForSigning(false);
                            onPressBack === null || onPressBack === void 0 ? void 0 : onPressBack();
                        }
                    },
                },
                primaryActions: [
                    {
                        title: sendForSigning
                            ? getLocalizedText("editor.template_document.cta.send_for_signing")
                            : getLocalizedText("editor.template_document.cta.generate_pdf"),
                        onPress: function () {
                            handlePressSave(withGeneratePdf, true);
                        },
                    },
                ],
            });
            return;
        }
        if (withGeneratePdf) {
            setExitPromptEnabled(false);
        }
        onPressSave === null || onPressSave === void 0 ? void 0 : onPressSave({
            withGeneratePdf: withGeneratePdf,
            editorState: editorState,
            fields: fields,
            documentConfig: documentConfig,
            variablesData: variablesData,
            documentIds: documentIds,
            sendForSigning: externalViewType === EExternalViewType.Dashboard
                ? true
                : sendForSigning,
        });
    };
    // Render
    var dropdownActions = [];
    if (onPressUpdate) {
        if (displayType === EDisplayType.Template) {
            dropdownActions.push({
                content: getLocalizedText("editor.template.cta.preview"),
                onClick: function () {
                    setDisplayType(EDisplayType.TemplateDocument);
                },
            });
        }
        else {
            dropdownActions.push({
                content: getLocalizedText("editor.template.cta.back_to_editor"),
                onClick: function () {
                    setDisplayType(EDisplayType.Template);
                },
            });
        }
    }
    if (onPressSave && isTemplateDocument) {
        dropdownActions.push({
            content: getLocalizedText("editor.template_document.cta.edit_template"),
            onClick: function () {
                setDisplayType(EDisplayType.TemplateDocumentEditor);
            },
        });
    }
    if (isTemplateDocumentEditor) {
        dropdownActions.push({
            content: getLocalizedText("editor.template_document.cta.finish_editing_template"),
            onClick: function () {
                setDisplayType(EDisplayType.TemplateDocument);
            },
        });
    }
    return (_jsxs(S.Wrap, { children: [sendForSigning && isTemplateDocumentExternal && onPressBack && (_jsxs(_Fragment, { children: [_jsx(ButtonBasic, { title: getLocalizedText("editor.template_document.cta.back"), onPress: function () {
                            setSendForSigning(false);
                            onPressBack();
                        } }), _jsx(Spacer, { weight: ESpacerWeight.W20, direction: ESpacerDirection.Horizontal })] })), onPressSave && (_jsxs(_Fragment, { children: [_jsx(ButtonBasic, { title: getLocalizedText("editor.template_document.cta.temp_save"), onPress: function () {
                            handlePressSave(false);
                        } }), _jsx(Spacer, { weight: ESpacerWeight.W20, direction: ESpacerDirection.Horizontal })] })), !isTemplateDocumentExternal && canEdit && (_jsxs(_Fragment, { children: [_jsx(ButtonBasic, { title: getLocalizedText("editor.template_document.cta.can_edit"), onPress: function () {
                            handlePressSendToTenant();
                        } }), _jsx(Spacer, { weight: ESpacerWeight.W20, direction: ESpacerDirection.Horizontal })] })), !isTemplateDocumentExternal && onPressSave && (_jsx(ButtonPrimary, { title: getLocalizedText("editor.template_document.cta.generate_pdf"), onPress: function () {
                    handlePressSave(true);
                } })), !sendForSigning && isTemplateDocumentExternal && onPressNext && (_jsx(ButtonPrimary, { title: getLocalizedText("editor.template_document.cta.next"), onPress: function () {
                    setSendForSigning(true);
                    onPressNext();
                } })), sendForSigning && isTemplateDocumentExternal && onPressSave && (_jsx(ButtonPrimary, { title: getLocalizedText("editor.template_document.cta.send_for_signing"), onPress: function () {
                    handlePressSave(true);
                } })), onPressUpdate && (_jsx(ButtonPrimary, { title: getLocalizedText("editor.template.cta.update"), onPress: function () {
                    if (!editorState) {
                        return;
                    }
                    onPressUpdate({ editorState: editorState, fields: fields, documentIds: documentIds });
                } })), dropdownActions.length > 0 && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W08, direction: ESpacerDirection.Horizontal }), _jsx(DropdownMenu, { children: _jsx(Box, { ml: ESpacings.tight, children: _jsx(Icon, { source: "navigationMenuVertical" }) }), actions: dropdownActions })] }))] }));
};
// eslint-disable-next-line import/no-default-export
export default ToolbarFooter;
