import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EField, IExtraData } from "./schema.kycVerification.types";

export const getInitialValues = ({ contact }: IExtraData): IValues => {
  let address = undefined;
  if (contact.street) {
    address = {
      street: contact.street,
      number: contact.number,
      box: contact.box,
      zip: contact.zip,
      city: contact.city,
      country: contact.countryId,
    };
  }

  return {
    [EField.DateOfBirth]: contact.dateOfBirth,
    [EField.Nationality]: contact.nationality,
    [EField.Address]: address,
  };
};
