import { ACTIVITY_SUBTYPES, ACTIVITY_SUBTYPES_INSURANCE, ACTIVITY_TYPES, ACTIVITY_TYPES_INSURANCE, } from "../types/activity.types";
export var mapActivityTypeToString = function (activityTypeId, isInsurance) {
    if (isInsurance === void 0) { isInsurance = false; }
    var activityTypes = isInsurance ? ACTIVITY_TYPES_INSURANCE : ACTIVITY_TYPES;
    return Object.keys(activityTypes).find(function (activityTypeString) { return activityTypes[activityTypeString] === activityTypeId; });
};
export var mapActivitySubtypeToString = function (activitySubtypeId, isInsurance) {
    if (isInsurance === void 0) { isInsurance = false; }
    var result;
    var activitySubtypes = isInsurance
        ? ACTIVITY_SUBTYPES_INSURANCE
        : ACTIVITY_SUBTYPES;
    Object.keys(activitySubtypes).forEach(function (activitySubtypeCategoryString) {
        Object.keys(activitySubtypes[activitySubtypeCategoryString]).forEach(function (activitySubtypeString) {
            if (activitySubtypes[activitySubtypeCategoryString][activitySubtypeString] === activitySubtypeId) {
                result = activitySubtypeString;
                return;
            }
        });
    });
    return result;
};
