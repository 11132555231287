import { EContractMemberTypes } from "../types/contract.types";
import { EPropertyMemberTypes, } from "../types/property.types";
export var getPrioritizedPropertyRole = function (member) {
    if (member.roles.includes(EPropertyMemberTypes.Owner)) {
        return EPropertyMemberTypes.Owner;
    }
    if (member.roles.includes(EPropertyMemberTypes.Tenant)) {
        return EPropertyMemberTypes.Tenant;
    }
    if (member.roles.includes(EPropertyMemberTypes.Parent)) {
        return EPropertyMemberTypes.Parent;
    }
    if (member.roles.includes(EPropertyMemberTypes.Broker)) {
        return EPropertyMemberTypes.Broker;
    }
    if (member.roles.includes(EPropertyMemberTypes.FinancialManager)) {
        return EPropertyMemberTypes.FinancialManager;
    }
    if (member.roles.includes(EPropertyMemberTypes.TechnicalManager)) {
        return EPropertyMemberTypes.TechnicalManager;
    }
};
export var getSignerForAccountId = function (params) {
    var accountId = params.accountId, contract = params.contract, property = params.property, allowedSigningTypes = params.allowedSigningTypes;
    if (!!contract) {
        var contractMember = (contract || []).members.find(function (member) { return member.account.id === accountId; });
        if (!contractMember) {
            return {
                accountId: accountId,
                role: "SIGNER",
                allowedSigningTypes: allowedSigningTypes,
            };
        }
        var role = contractMember.roles.find(function (role) {
            return [
                EContractMemberTypes.Tenant,
                EContractMemberTypes.Parent,
                EContractMemberTypes.Owner,
                EContractMemberTypes.Broker,
            ].includes(role);
        });
        if (!role && !!property) {
            var isBroker = (property || []).members.find(function (member) {
                return member.account.id === accountId &&
                    member.roles.includes(EPropertyMemberTypes.Broker);
            });
            if (isBroker) {
                role = EContractMemberTypes.Broker;
            }
        }
        if (role) {
            return {
                accountId: accountId,
                role: role,
                allowedSigningTypes: allowedSigningTypes,
            };
        }
    }
    if (!!property) {
        var propertyMember = (property || []).members.find(function (member) { return member.account.id === accountId; });
        if (!propertyMember) {
            return {
                accountId: accountId,
                role: "SIGNER",
                allowedSigningTypes: allowedSigningTypes,
            };
        }
        return {
            accountId: accountId,
            role: getPrioritizedPropertyRole(propertyMember),
            allowedSigningTypes: allowedSigningTypes,
        };
    }
    return {
        accountId: accountId,
        role: "SIGNER",
        allowedSigningTypes: allowedSigningTypes,
    };
};
