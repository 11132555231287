import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import { hasValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EPayoutType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { EPaymentRequestStatus } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import StatusDot, { EStatus } from "../StatusDot";

interface IProps {
  propertyId: number;
  contractId: string;
}

const StatusDotPropertyPaymentRequests: React.FC<IProps> = props => {
  const { propertyId } = props;

  const filterData = {
    where: {
      // TODO: Jan will add contractId to payment request
      // requestContractId: contractId,
      requestPropertyId: propertyId,
      status: { neq: EPaymentRequestStatus.Paid },
      payoutType: EPayoutType.Charge,
    },
  };
  const countIdentifier = `property-${propertyId}-open-payment-requests`;
  const countBase = append("/payment-requests/count", filterData);

  // Redux
  const dispatch = useDispatch();
  const paymentRequestsCount = useSelector((state: IRootStore) =>
    countSelectors.getCount(state, countIdentifier),
  );
  const isFetching = useSelector((state: IRootStore) =>
    countSelectors.getIsFetchingCount(state, countIdentifier),
  );

  // Lifecycle methods
  React.useEffect(() => {
    if (hasValue(paymentRequestsCount) || isFetching) {
      return;
    }

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier,
        countBase,
      }),
    );
  }, [propertyId]);

  // Render
  const getStatus = () => {
    if (!hasValue(paymentRequestsCount)) {
      return EStatus.None;
    }

    if ((paymentRequestsCount || 0) > 0) {
      return EStatus.Error;
    }

    return EStatus.Success;
  };

  const renderTooltipContent = () => {
    if (!hasValue(paymentRequestsCount)) {
      return undefined;
    }

    return getLocalizedText(
      "properties.overview.open_payment_requests",
      { count: `${paymentRequestsCount || 0}` },
      paymentRequestsCount || 0,
    );
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={renderTooltipContent()} />
  );
};

// eslint-disable-next-line import/no-default-export
export default StatusDotPropertyPaymentRequests;
