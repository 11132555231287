import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { EContractType } from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  EPropertyTypeId,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import { getFontSize } from "@rentiohq/web-shared-next/dist/themes";
import { EFontSizes } from "@rentiohq/web-shared-next/dist/themes/types";
import { Avatar, Icon } from "@rentiohq/web-shared-next/dist/ui-components";
import { useNavigate } from "react-router-dom";
import { QuickActions } from "../../scenes/Property/QuickActions";
import { ProductCardStyles } from "./PropertyCard.styles";

interface PropertyCardProps {
  property: IProperty;
  isActive?: boolean;
}

const PropertyIcon = {
  [EPropertyTypeId.House]: EAssets.HouseIcon,
  [EPropertyTypeId.Apartment]: EAssets.ApartmentIcon,
  [EPropertyTypeId.StudentResidence]: EAssets.StudentRoomIcon,
  [EPropertyTypeId.Commercial]: EAssets.CommercialPropertyIcon,
  [EPropertyTypeId.Office]: EAssets.OfficeIcon,
  [EPropertyTypeId.ParkingLot]: EAssets.ParkingSpotIcon,
  [EPropertyTypeId.Garage]: EAssets.GarageIcon,
  [EPropertyTypeId.Storage]: EAssets.StorageIcon,
  [EPropertyTypeId.Shed]: EAssets.ShedIcon,
  [EPropertyTypeId.Warehouse]: EAssets.WarehouseIcon,
};

const NOW = new Date();

export const PropertyCard = ({ property, isActive }: PropertyCardProps) => {
  const navigate = useNavigate();

  const {
    id,
    name,
    reference,
    typeId = EPropertyTypeId.House,
    propertyAddress: { simple } = {},
    images,
  } = property;

  const filterConditions: Record<string, any> = [
    { propertyId: { eq: property.id } },
    { contractType: { eq: EContractType.Basic } },
    { stopDate: isActive ? { gte: NOW } : { lt: NOW } },
  ];

  const { items: contracts } = contractHooks.useGetAll({
    query: {
      filter: {
        and: filterConditions,
      },
      sort: [
        {
          field: "startDate",
          method: "DESC",
        },
      ],
    },
  });

  const contract = contracts?.[0];
  const { startDate, stopDate } = contract || {};
  const renewalInfo = contract && contractUtils.getRenewalInfo(contract);

  const sx = ProductCardStyles();
  return (
    <Card sx={sx.wrapper} onClick={() => navigate(`/properties/${id}/`)}>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          {images?.length ? (
            <Avatar
              alt="propertyType"
              size="lg"
              sx={sx.content.profileImg}
              src={images[0]}
            />
          ) : (
            <Avatar alt="propertyType" size="lg" sx={sx.content.avatar}>
              <Icon
                // @ts-ignore
                path={PropertyIcon[typeId]}
                width="38.4px"
                height="38.4px"
              />
            </Avatar>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            fontSize={getFontSize(EFontSizes.Medium)}
            sx={sx.content.longText}
            title={simple}
          >
            {simple}
          </Typography>
          <Typography
            variant="caption"
            sx={sx.content.longText}
            title={`${reference} | ${name}`}
          >
            {reference} | {name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="column" spacing={1}>
            <Box>
              <Typography variant="caption">
                {getLocalizedText("system.contract")}
              </Typography>
              <Typography
                variant="subtitle2"
                fontSize={getFontSize(EFontSizes.ExtraSmall)}
              >
                {startDate ? formatDate(startDate) : "N/A"} -{" "}
                {stopDate ? formatDate(stopDate) : "N/A"}
              </Typography>
            </Box>
            {isActive && (
              <Box>
                <Typography variant="caption">
                  {getLocalizedText("contract.cancellation.modal.heading")}
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontSize={getFontSize(EFontSizes.ExtraSmall)}
                  sx={sx.content.longText}
                >
                  {renewalInfo || "-"}
                </Typography>
              </Box>
            )}
          </Stack>
        </Grid>
        {isActive && (
          <Grid item xs={12} onClick={e => e.stopPropagation()}>
            <QuickActions property={property} />
          </Grid>
        )}
      </Grid>
    </Card>
  );
};
