import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as invoiceItemHooks from "@rentiohq/shared-frontend/dist/redux/invoiceItem/invoiceItem.hooks";
import { EInvoiceResourceModelType } from "@rentiohq/shared-frontend/dist/types/invoice.types";
import { formatDateAndTime } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { NumberParam, StringParam } from "serialize-query-params";
import { Grid, Icon, Loading, Page } from "../../";
import { InvoiceItemInfo } from "./components";
export var InvoiceItem = function (props) {
    var invoiceItemId = props.invoiceItemId, onDrawerClose = props.onDrawerClose;
    var _a = useQueryParams({
        paymentRequestId: NumberParam,
        documentPackageId: StringParam,
    }), setQueryParamValue = _a[1];
    // Redux
    var _b = invoiceItemHooks.useDetail({ id: invoiceItemId }), invoiceItem = _b.detail, isFetching = _b.isFetching;
    // Render
    if (!invoiceItem) {
        if (isFetching) {
            return _jsx(Loading, {});
        }
        return null;
    }
    var mainActions = [];
    if (invoiceItem.resourceId) {
        switch (invoiceItem.resourceModelType) {
            case EInvoiceResourceModelType.PaymentRequest:
                mainActions.push({
                    content: getLocalizedText("payment_request.view_payment_request.action"),
                    onClick: function () {
                        if (invoiceItem.resourceId) {
                            setQueryParamValue({ paymentRequestId: +invoiceItem.resourceId });
                        }
                    },
                });
                break;
            case EInvoiceResourceModelType.DocumentPackage:
                mainActions.push({
                    content: getLocalizedText("document_package.view_document_package.action"),
                    onClick: function () {
                        if (invoiceItem.resourceId) {
                            setQueryParamValue({
                                documentPackageId: invoiceItem.resourceId,
                            });
                        }
                    },
                });
                break;
            default:
                break;
        }
    }
    var billedAt = invoiceItem.billedAt, resourceType = invoiceItem.resourceType;
    var renderMeta = function () { return (_jsx(Box, { children: _jsxs(Grid, { spacing: "extraTight", alignItems: "center", children: [_jsx(Grid.Item, { children: _jsx(Icon, { source: "calendar", size: "small" }) }), _jsx(Grid.Item, { children: formatDateAndTime(billedAt) })] }) })); };
    return (_jsx(_Fragment, { children: _jsx(Page, { title: getLocalizedText("invoice.item.".concat(resourceType).toLowerCase()), subtitle: getLocalizedText("entity.invoice_item"), metadata: renderMeta(), setDocumentTitle: false, dropdown: mainActions.length > 0 ? mainActions : undefined, children: _jsx(InvoiceItemInfo, { invoiceItem: invoiceItem, onDrawerClose: onDrawerClose }) }) }));
};
