import { ERentioFormField } from "../../components/components/Form";
import { getIbans } from "../../redux/contact/contact.actions";
import * as contactSelectors from "../../redux/contact/contact.selectors";
import { getName } from "../../redux/contact/contact.utils";
import { getValue, ibanSelectInlineValueSelector, } from "../../redux/form/form.utils";
import { EContractMemberTypes } from "../../types/contract.types";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { formatCurrency } from "../../utils/number.utils";
import { EField } from "./schema.releaseRentDeposit.types";
import { getNavigationTitle } from "./schema.releaseRentDeposit.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.BrokerBankAccountId],
            properties: (_a = {},
                _a[EField.Header + "2"] = {
                    default: undefined,
                },
                _a[EField.Info] = {
                    default: undefined,
                },
                _a[EField.BrokerBankAccountId] = {
                    default: getValue(EField.BrokerBankAccountId, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    var _b;
    var tenants = extraData.relatedContract.members.filter(function (member) {
        return member.roles.includes(EContractMemberTypes.Tenant) ||
            member.roles.includes(EContractMemberTypes.Parent);
    });
    var owners = extraData.relatedContract.members.filter(function (member) {
        return member.roles.includes(EContractMemberTypes.Owner);
    });
    var brokerAccountId = (_b = extraData.contactMeMaster) === null || _b === void 0 ? void 0 : _b.accountId;
    return _a = {
            "rentio:title": getLocalizedText("rent_deposit_release.broker_bankaccount.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "2"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rent_deposit_release.broker_bankaccount.heading"),
        },
        _a[EField.Info] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("rent_deposit_release.contract_info.heading"),
            subtitle: (function () {
                return getLocalizedText("rent_deposit_release.contract_info.content", {
                    startDate: formatDate(extraData.relatedContract.startDate),
                    amount: formatCurrency(extraData.relatedContract.amount || 0),
                    tenants: tenants.map(function (member) { return getName(member.account); }).join(", "),
                    owners: owners.map(function (member) { return getName(member.account); }).join(", "),
                });
            })(),
            // TODO: Icon
            // icon: images.ICO_CHAT_SEND
        },
        _a[EField.BrokerBankAccountId] = {
            "ui:field": ERentioFormField.IbanSelectInline,
            title: getLocalizedText("rent_deposit_release.broker_bankaccount.heading"),
            addIbanCtaTitle: getLocalizedText("iban.add.cta"),
            valueSelector: function (state, input) {
                return ibanSelectInlineValueSelector(state, input);
            },
            accountIdToAddIban: function () { return brokerAccountId; },
            customIsFetchingSelector: function (state) {
                return contactSelectors.isFetchingIbans(state, brokerAccountId);
            },
            customIbansSelector: function (state) {
                return contactSelectors.getIbans(state, undefined, brokerAccountId);
            },
            customGetIbansActions: function () { return [
                function () { return getIbans.actions.start({ accountId: brokerAccountId }); },
            ]; },
        },
        _a;
};
export var getValidate = function () { return function (formData, errors) {
    if (!formData[EField.BrokerBankAccountId]) {
        var noBankAccountError = getLocalizedText("rent_deposit_release.error.no_bank_account");
        errors[EField.BrokerBankAccountId].addError(noBankAccountError);
    }
    return errors;
}; };
