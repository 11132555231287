import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { StyledSearchResultLink, StyledSearchResultMeta, } from "./SearchList.styled";
export var SearchItemApplication = function (_a) {
    var _b = _a.data, data = _b === void 0 ? [] : _b, linkHandler = _a.linkHandler, searchValue = _a.searchValue;
    return (_jsx(_Fragment, { children: data.slice(0, 10).map(function (item, index) {
            var link = "/properties/".concat(item.property.id, "/applications");
            return (_jsxs(StyledSearchResultLink, { to: link, onClick: linkHandler, children: [_jsx("div", { children: item.contacts
                            .map(function (contact) { return "".concat(contact.firstname, " ").concat(contact.lastname); })
                            .join(", ") }), _jsx(StyledSearchResultMeta, { children: formatAddress(item.property) })] }, "property".concat(index, "/applications")));
        }) }));
};
