import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as authSelectors from "@rentiohq/shared-frontend/dist/redux/auth/auth.selectors";
import * as cardActions from "@rentiohq/shared-frontend/dist/redux/card/card.actions";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as invitesActions from "@rentiohq/shared-frontend/dist/redux/invites/invites.actions";
import * as invitesSelectors from "@rentiohq/shared-frontend/dist/redux/invites/invites.selectors";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  MuiDialog,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { EButtonVariants } from "@rentiohq/web-shared-next/dist/utils/types/button";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import useDocumentVisibility from "../../../modals/InvitesModal/hooks/useDocumentVisibility";

export const InvitesModal = () => {
  let location = useLocation();

  // Redux
  const dispatch = useDispatch();
  const invites =
    useSelector((store: IRootStore) => invitesSelectors.invites(store)) || [];
  const previousInvites = usePrevious(invites);

  const isHidden = useDocumentVisibility();
  const wasHidden = usePrevious(isHidden);

  const { user } = authHooks.useSelf();
  const hasAccessToken = useSelector((store: IRootStore) =>
    authSelectors.hasAccessToken(store),
  );
  const previousUser = usePrevious(user);

  const [preventModal, setPreventModal] = React.useState(false);

  // Data
  const fetchData = () => {
    dispatch(invitesActions.getInvites.actions.start({}));
  };

  // Lifecycle
  React.useEffect(() => {
    if (!user?.email) {
      return;
    }

    if (!hasAccessToken) {
      return;
    }

    if (isHidden) {
      return;
    }

    if (
      isHidden === wasHidden &&
      user.id === previousUser?.id &&
      user.email === previousUser?.email
    ) {
      return;
    }

    fetchData();
  }, [user, hasAccessToken, previousUser, isHidden]);

  React.useEffect(() => {
    if ((invites || []).length === 0 && (previousInvites || []).length !== 0) {
      dispatch(cardActions.getCards.actions.start({}));
    }
  }, [invites, previousInvites]);

  // Event handlers
  const handleClose = () => setPreventModal(true);

  const handlePressAcceptAll = () => {
    dispatch(invitesActions.acceptAllInvites.actions.start({}));
  };

  const handlePressAccept = () => {
    if (invites?.length) {
      dispatch(
        invitesActions.acceptInvite.actions.start({ invite: invites[0] }),
      );
    }
  };

  const handlePressDecline = () => {
    if (invites?.length) {
      const invite = invites[0];
      // TODO: New confirm modal to be made
      // confirm({
      //   title: getLocalizedText("contact.button.decline.confirm_alert.title"),
      //   info: getLocalizedText(
      //     "contact.button.decline.confirm_alert.description",
      //     {
      //       value: getName(invite.account),
      //     },
      //   ),
      //   primaryActions: [
      //     {
      //       title: getLocalizedText("contact.button.accept"),
      //       onPress: () => {
      //         dispatch(invitesActions.acceptInvite.actions.start({ invite }));
      //       },
      //     },
      //   ],
      //   secondaryAction: {
      //     title: getLocalizedText("contact.button.decline"),
      //     onPress: () => {
      //       dispatch(invitesActions.declineInvite.actions.start({ invite }));
      //     },
      //   },
      // });

      dispatch(invitesActions.declineInvite.actions.start({ invite }));
    }
  };

  // Render
  if (!invites || invites.length === 0) {
    return null;
  }

  // Never show on rentio internal screen
  if (location.pathname.includes("/rentio-internal")) {
    return null;
  }

  // Never show on authentication screens
  if (location.pathname.startsWith("/auth/")) {
    return null;
  }

  // Don't show if hidden
  if (preventModal) {
    return null;
  }

  const getActions = () => {
    let actions = [
      {
        variant: EButtonVariants.Outlined,
        onClick: handlePressDecline,
        label: getLocalizedText("contact.button.decline"),
      },
      {
        onClick: handlePressAccept,
        label: getLocalizedText("contact.button.accept"),
      },
    ];

    if (invites.length > 1) {
      actions.push({
        onClick: handlePressAcceptAll,
        label: getLocalizedText("contact.button.accept_all"),
      });
    }
    return actions;
  };

  return (
    <MuiDialog
      title={getLocalizedText("invite.connection.heading")}
      open={true}
      toggleOpen={handleClose}
      actions={getActions()}
    >
      <Typography variant="h5">
        {getLocalizedText("open_invites.invite_info_new", {
          name: getName(invites[0]?.account),
        })}
      </Typography>
    </MuiDialog>
  );
};
