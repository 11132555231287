import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import { ERegistrationContractLanguage } from "@rentiohq/shared-frontend/dist/types/registration.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.registration.types";

export const getSchema =
  (
    initialValues: {
      [key: string]: unknown;
    },
    _: IExtraData,
  ) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    const translatedLanguageOpts = Object.entries(
      ERegistrationContractLanguage,
    ).map(lang => {
      return {
        [getLocalizedText(
          `registration.preferred_language.${lang[1]}`.toLowerCase(),
        )]: lang[1],
      };
    });

    return {
      required: [
        EField.RentContractDocumentToUpload,
        EField.RentContractLanguage,
      ],
      properties: {
        title1: {
          default: undefined,
        },
        info1: {
          default: undefined,
        },
        [EField.RentContractDocumentToUpload]: {
          default: getValue(
            EField.RentContractDocumentToUpload,
            initialValues,
            defaultValues,
          ),
        },
        [EField.RentContractLanguage]: {
          default: ERegistrationContractLanguage.Dutch,
          // @ts-ignore
          options: translatedLanguageOpts.map(lang => {
            return {
              id: Object.values(lang)[0],
              value: Object.keys(lang)[0],
            };
          }),
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData): IUiSchema<EField> => {
  return {
    "rentio:title": utils.i18n.t(
      "registration.create.form.step.contract_select",
    ),
    title1: {
      "ui:field": ERentioFormField.SectionHeader,
      title: utils.i18n.t(
        "registration.create.form.field.contract_select_title",
      ),
    },
    info1: {
      "ui:field": ERentioFormField.HelpInfo,
      subtitle: getLocalizedText(
        "registration.create.form.field.contract_select_info",
      ),
    },
    [EField.RentContractDocumentToUpload]: {
      "ui:field": ERentioFormField.DocumentSelectInline,
      valueSelector: (state: IPartialRootState, formData: any[] = []) =>
        (formData || [])
          .map(item => {
            if (typeof item === "string") {
              const document = state.DOCUMENTS_V2.detail[item]?.entity;
              if (document) {
                return document.filename;
              }

              return getLocalizedText("summary.view.document");
            }

            return item.name;
          })
          .join(", "),
      accept: ["application/pdf"],
      mimeTypes: ["application/pdf"],
      multiple: false,
      showOptionsOnFirstFocus: true,
      useExistingDocuments: true,
      getExistingDocumentsWhereFilter: () => ({
        propertyId: { eq: extraData.property.id },
      }),
      title: getLocalizedText(
        "registration.create.form.field.contract_upload_document.title",
      ),
      info: getLocalizedText(
        "registration.create.form.field.contract_upload_document.info",
      ),
      hideOnSummaryIfEmpty: true,
    },
    [EField.RentContractLanguage]: {
      "ui:field": ERentioFormField.DropdownSelect,
      title: getLocalizedText(
        "registration.create.form.field.preferred_language",
      ),
      formatSummary: (formData: ERegistrationContractLanguage) =>
        getLocalizedText(
          `registration.preferred_language.${formData}`.toLowerCase(),
        ),
    },
  };
};
