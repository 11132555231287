import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { memo } from "react";
import * as S from "./ActivityContent/ActivityContent.styled";
export var ContentMeta = memo(function (_a) {
    var meta = _a.meta;
    return (_jsx("table", { children: _jsx("tbody", { children: Array.from(meta.entries())
                .filter(function (_a) {
                var _ = _a[0], value = _a[1];
                return Boolean(value);
            })
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                return (_jsxs("tr", { children: [_jsxs(S.ValueCell, { children: [key, ":"] }), _jsx("td", { style: { textAlign: "start" }, children: value })] }));
            }) }) }));
});
