import { compact } from "lodash";
import { getFetchedContacts } from "../../redux/contact/contact.selectors";
import { contractModule } from "../../reduxV2/contract";
import { EContractFetchType } from "../../reduxV2/contract/contract.types";
import * as propertyActions from "../../reduxV2/property/property.actions";
import * as propertySelectors from "../../reduxV2/property/property.selectors";
import { EContractMemberTypes, EContractType, } from "../../types/contract.types";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
export var customContractSelector = function (payload) {
    var state = payload.state, contractId = payload.contractId;
    var contract = contractModule.selectors.getDetail(state, contractId);
    return contract;
};
export var customPropertySelector = function (payload) {
    var state = payload.state, propertyId = payload.propertyId;
    var property = propertySelectors.getDetail(state, propertyId);
    return property;
};
var getFilter = function (propertyId) {
    return contractModule.utils.getFilterForProperty(propertyId, EContractFetchType.Active);
};
export var customContractsSelector = function (payload) {
    var propertyId = payload.propertyId, state = payload.state;
    var filter = getFilter(propertyId);
    var contracts = contractModule.selectors.getAllWhere(state, { filter: filter });
    if (contracts === null || contracts === void 0 ? void 0 : contracts.items) {
        return contracts.items
            .filter(function (contract) { return contract.contractType === EContractType.Basic; })
            .map(function (contract) {
            var startDate = contract.startDate, stopDate = contract.stopDate;
            var startAndEndDate = stopDate
                ? "".concat(formatDate(startDate), " - ").concat(formatDate(stopDate))
                : "".concat(formatDate(startDate), " - ").concat(getLocalizedText("contract.indefinite"));
            var contractMembers = contract.members
                .filter(function (member) {
                return member.roles.includes(EContractMemberTypes.Tenant) ||
                    member.roles.includes(EContractMemberTypes.Parent);
            })
                .map(function (member) { return "".concat(member.account.firstname, " ").concat(member.account.lastname); })
                .join(", ");
            return {
                id: contract.id,
                value: contract.contractType
                    ? "".concat(getLocalizedText("contract.type.".concat(contract.contractType.toLowerCase())), ": ").concat(contractMembers)
                    : contractMembers,
                subValue: startAndEndDate,
            };
        });
    }
    return [];
};
export var customIsFetchingSelector = function (state, extraData, formData) {
    var filter = getFilter(extraData.property.id);
    return contractModule.selectors.isFetchingAllWhere(state, {
        filter: filter,
    });
};
export var customGetContractsActions = function (extraData) {
    var result = [];
    result.push(function (formData) {
        var filter = contractModule.utils.getFilterForProperty(extraData.property.id, EContractFetchType.Active);
        return contractModule.actions.getAllWhereStart.getAction({
            query: { filter: filter },
        });
    });
    result.push(function (formData) {
        return propertyActions.getDetailStart.getAction({
            id: extraData.property.id,
        });
    });
    return result;
};
export var customGetForeignerContactsForAccountIds = function (state, accountIds) {
    var contacts = getFetchedContacts(state);
    var resultRaw = accountIds.map(function (accountId) {
        var contact = contacts.find(function (contact) {
            return contact.accountIds.includes(accountId);
        });
        if (!contact) {
            return undefined;
        }
        var hasNationalRegisterNumber = !!contact.nationalRegisterNumber;
        if (hasNationalRegisterNumber) {
            return undefined;
        }
        return contact;
    });
    return compact(resultRaw);
};
