var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable no-console */
import { logError } from "@rentiohq/shared-frontend/dist/logger/logger";
import { EErrorType } from "@rentiohq/shared-frontend/dist/logger/logger.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { Link } from "react-router-dom";
import { ts } from "../../services";
import { NoResults } from "../NoResults";
import { RentioInternalRenderer } from "../RentioInternalRenderer";
var ErrorBoundary = /** @class */ (function (_super) {
    __extends(ErrorBoundary, _super);
    function ErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = { error: undefined };
        return _this;
    }
    ErrorBoundary.getDerivedStateFromError = function (error) {
        return { error: error };
    };
    ErrorBoundary.prototype.componentDidCatch = function (error, errorInfo) {
        logError({ type: EErrorType.General, error: error, fatal: true });
        console.error({ error: error, errorInfo: errorInfo });
    };
    ErrorBoundary.prototype.render = function () {
        if (this.state.error) {
            return (_jsxs(NoResults, { iconColor: "red", icon: "houseLock", heading: ts.serverError(), children: [_jsx(Link, { to: "/", children: getLocalizedText("system.cta.back_to_home") }), _jsx(RentioInternalRenderer, { children: "".concat(this.state.error) })] }));
        }
        return this.props.children;
    };
    return ErrorBoundary;
}(React.Component));
export { ErrorBoundary };
