import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button } from "../../Button";
import { DisplayText } from "../../DisplayText";
import { Icon } from "../../Icon";
import * as S from "../Modal.styled";
// @ts-ignore TODO: Typescript upgrade
export var ModalHeader = function (_a) {
    var type = _a.type, heading = _a.heading, component = _a.component, onClose = _a.onClose, hasDismiss = _a.hasDismiss;
    if (!component && !heading) {
        return null;
    }
    if (component && heading) {
        return null;
    }
    if (component) {
        // @ts-ignore
        return React.createElement(component, {
            onClose: onClose,
            hasDismiss: hasDismiss,
        });
    }
    return (_jsxs(S.Header, { type: type, children: [_jsx("div", { children: _jsx(DisplayText, { size: "large", children: heading }) }), hasDismiss && (_jsx(Button, { appearance: "plain", onClick: onClose, children: _jsx(Icon, { source: "close" }) }))] }));
};
