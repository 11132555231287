import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  ContractFetchListItem,
  ESpacings,
  getIconForContractType,
  Icon,
  ResourceList,
  ResourceListItem,
  Stack,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { ts } from "../../services";
import { getContractDates, renderContractNames } from "../../utils/contract";

interface IProps {
  contracts?: IContract[];
  contractIds?: string[];
}

export const ContractRows: React.FC<IProps> = ({ contracts, contractIds }) => {
  const renderContract = (contract: IContract) => {
    return (
      <ResourceListItem
        boxProps={{ p: ESpacings.tight }}
        item={contract}
        media={<Icon source={getIconForContractType(contract.contractType!)} />}
        mediaSize="medium"
        // actions={}
      >
        <Stack alignment="center">
          <div>{utils.contract.getDisplayName(contract)}</div>
        </Stack>
        <TextStyle size="small" variation={["subdued", "strong"]} element="div">
          {renderContractNames({ contract }) ||
            ts.contractsOverviewContractNoTenants()}
        </TextStyle>
        <TextStyle size="small" variation="subdued" element="div">
          {getContractDates(contract as any)}
        </TextStyle>
      </ResourceListItem>
    );
  };

  const renderRow = (contract: IContract | string, index: number) => {
    if (typeof contract === "string") {
      return (
        <ContractFetchListItem
          key={index}
          contractId={contract}
          renderContract={renderContract}
        />
      );
    }

    return (
      <React.Fragment key={index}>{renderContract(contract)}</React.Fragment>
    );
  };

  return (
    <ResourceList
      items={contracts || contractIds || []}
      renderItem={renderRow}
    />
  );
};
