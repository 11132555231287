var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { TextField } from "../../../../components/TextField";
export var BaseInput = function (props) {
    var onKeyChange = props.onKeyChange, onBlur = props.onBlur, onFocus = props.onFocus, onChange = props.onChange, onDropPropertyClick = props.onDropPropertyClick, error = props.error, autoFocus = props.autoFocus, rest = __rest(props, ["onKeyChange", "onBlur", "onFocus", "onChange", "onDropPropertyClick", "error", "autoFocus"]);
    var textFieldRef = React.useRef(null);
    var handleChange = function (_a) {
        var newValue = _a.target.value;
        if (!onChange) {
            return;
        }
        // @ts-ignore
        if (newValue === "" && rest.options) {
            // @ts-ignore
            return onChange(rest.options.emptyValue);
        }
        return onChange(newValue);
    };
    var handleBlur = function (event) {
        return onBlur ? onBlur(rest.id, event.target.value) : undefined;
    };
    var handleFocus = function (event) {
        return onFocus ? onFocus(rest.id, event.target.value) : undefined;
    };
    // Autofocus on error
    React.useEffect(function () {
        var _a, _b;
        if (!!error) {
            (_b = (_a = textFieldRef.current) === null || _a === void 0 ? void 0 : _a.inputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
        }
    }, [error, autoFocus]);
    return (_jsx(TextField, __assign({ name: rest.id, onChange: handleChange, onBlur: handleBlur, onFocus: handleFocus, 
        // @ts-ignore
        ref: textFieldRef, error: error, autoFocus: !!error || autoFocus }, rest)));
};
