import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { isValidStopDateForTacitRenewal } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { isResidential } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import { max } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import {
  EField,
  ERenewContractDuration,
  IExtraData,
} from "./schema.renewContract.types";
import {
  getDurationOptionsForContract,
  getEndDate,
} from "./schema.renewContract.utils";

const NOW = new Date();

export const getSchema =
  (initialValues: { [key: string]: any }, { property, contract }: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    const durationOptions = getDurationOptionsForContract(property, contract);

    return {
      required: [EField.NewEndDate],
      properties: {
        [EField.Info]: {
          default: undefined,
        },

        [EField.StartDate]: {
          default: getValue(EField.StartDate, initialValues, defaultValues),
        },

        [EField.CurrentEndDate]: {
          default: getValue(
            EField.CurrentEndDate,
            initialValues,
            defaultValues,
          ),
        },

        [EField.Duration]: {
          default: getValue(EField.Duration, initialValues, defaultValues),
          // @ts-ignore
          showIf: () => durationOptions.length > 1,
          requiredIf: () => durationOptions.length > 1,
          // @ts-ignore
          options: durationOptions,
          handleChange: (fieldName: string) => {
            if (fieldName === EField.NewEndDate) {
              return {
                shouldSetNewValue: true,
                newValue: ERenewContractDuration.Other,
              };
            }
            return { shouldSetNewValue: false };
          },
        },

        [EField.NewEndDate]: {
          default: getValue(EField.NewEndDate, initialValues, defaultValues),
          // @ts-ignore
          handleChange: (
            fieldName: string,
            fieldValue: any,
            myFieldValue: string,
            formData: any,
          ) => {
            if (fieldName === EField.NewEndDate) {
              return { shouldSetNewValue: false };
            }
            if (formData[EField.Duration] !== ERenewContractDuration.Other) {
              return {
                shouldSetNewValue: true,
                newValue: getEndDate(formData),
              };
            }
            return { shouldSetNewValue: false };
          },
        },

        [EField.NonDefaultRenewalWarning]: {
          default: undefined,
          // @ts-ignore
          showIf: (formData: any) => {
            if (!isResidential(property)) {
              return false;
            }

            const newEndDate = formData[EField.NewEndDate];
            const startDate = formData[EField.StartDate];

            if (!newEndDate) {
              return false;
            }

            return !isValidStopDateForTacitRenewal({
              startDate,
              stopDate: newEndDate,
            });
          },
        },

        [EField.TacitRenewal]: {
          default: getValue(EField.TacitRenewal, initialValues, defaultValues),
          // @ts-ignore
          showIf: (formData: any) => {
            if (!isResidential(property)) {
              return false;
            }

            const newEndDate = formData[EField.NewEndDate];
            const startDate = formData[EField.StartDate];

            if (!newEndDate) {
              return false;
            }

            return isValidStopDateForTacitRenewal({
              startDate,
              stopDate: newEndDate,
            });
          },
          // @ts-ignore
          options: ["yes", "no"].map(id => ({
            id,
            value: getLocalizedText(`system.${id}`.toLowerCase()),
          })),
        },

        [EField.MoreInfo]: {
          default: undefined,
          // @ts-ignore
          showIf: () => isResidential(property),
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData): IUiSchema<EField> => ({
  [EField.Info]: {
    "ui:field": ERentioFormField.PropertyInfo,
    propertyId: extraData.property.id,
    property: extraData.property,
  },

  [EField.StartDate]: {
    "ui:field": ERentioFormField.Date,
    title: getLocalizedText("contract.start_date.label"),
    isDisabled: () => true,
  },

  [EField.CurrentEndDate]: {
    "ui:field": ERentioFormField.Date,
    title: getLocalizedText("contract.current_end_date.label"),
    isDisabled: () => true,
  },

  [EField.Duration]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("contract.renew_duration.option.label"),
  },

  [EField.NewEndDate]: {
    "ui:field": ERentioFormField.Date,
    title: getLocalizedText("contract.renew_end_date.label"),
    minDate: max([NOW, extraData.contract?.stopDate || NOW]),
  },

  [EField.NonDefaultRenewalWarning]: {
    "ui:field": ERentioFormField.HelpInfo,
    showCloseButton: false,
    type: "error",
    title: getLocalizedText(
      "contract.renew_end_date.non_default_renewal_warning.title",
    ),
  },

  [EField.TacitRenewal]: {
    "ui:field": ERentioFormField.SegmentedControl,
    title: getLocalizedText("contract.renew_tacit_renewal.label"),
    info: getLocalizedText("contract.renew_tacit_renewal.info"),
    extraInfoUrl: "contract.tacit_renewal.info.url",
  },

  [EField.MoreInfo]: {
    "ui:field": ERentioFormField.HelpInfo,
    showCloseButton: false,
    // type: "info",
    title: getLocalizedText("contract.renew_end_date.info.title"),
    getSubtitle: (_: any) =>
      getLocalizedText("contract.renew_end_date.info.subtitle"),
    actionTitle: getLocalizedText("contract.renew_end_date.info.cta.title"),
    onPressAction: () => {
      const url = getLocalizedText("contract.renew_end_date.info.cta.url");
      window.open(url, "_blank");
    },
  },
});
