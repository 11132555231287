import { createForm, storePartialFormData, } from "@rentiohq/shared-frontend/dist/redux/form/form.actions";
import { getFormData, makeGetSchemas, mergedPartialFormDataSelector, } from "@rentiohq/shared-frontend/dist/redux/form/form.selectors";
import { connect } from "react-redux";
import { FormSummary } from "./FormSummary";
var mapStateToProps = function (state, _a) {
    var formId = _a.formId, onChange = _a.onChange;
    var getSchemas = makeGetSchemas();
    return {
        onChange: onChange,
        schemas: getSchemas(state, formId),
        formData: getFormData(state, formId),
        mergedPartialFormData: mergedPartialFormDataSelector(state, formId),
    };
};
var mapDispatchToProps = {
    createForm: createForm,
    storePartialFormData: storePartialFormData,
};
export var FormSummaryContainer = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(FormSummary);
