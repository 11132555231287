import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as moveRequestActions from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.actions";
import {
  EMoveRequestDocumentCategory,
  EMoveServiceService,
  IMoveRequest,
} from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.types";
import {
  EElectricityType,
  ENaturalGasAvailable,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import {
  getGeneralRemoveSuccessText,
  getLocalizedText,
} from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Button,
  Card,
  DisplayText,
  ESpacings,
  Icon,
  ResourceList,
  ResourceListItem,
  TAssetSource,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { EStep } from "forms/moveRequestUpdate/schema.moveRequestUpdate.types";
import React from "react";
import { useDispatch } from "react-redux";
import { StringParam } from "serialize-query-params";
import MoveRequestUpdateModal from "../MoveRequestUpdateModal";
import * as S from "./MoveRequestInfo.styles";

interface IProps {
  moveRequest: IMoveRequest;
  property: IProperty;
  onDrawerClose?: () => void;
}

const MoveRequestInfo: React.FC<IProps> = props => {
  const { moveRequest, property } = props;
  const {
    electricityEan,
    electricityMeterNumberBoth,
    naturalGasMeterNumber,
    naturalGasMeterType,
    naturalGasEan,
    electricityType,
    naturalGasMeterReading,
    naturalGasMeterReadingDate,
    electricityMeterReadingDay,
    electricityMeterReadingDayDate,
    electricityMeterReadingNight,
    electricityMeterReadingNightDate,
    electricityMeterReadingBoth,
    waterMeterNumber,
    waterMeterReading,
    waterMeterReadingDate,
    electricityMeterReadingBothDate,
    electricityMeterType,
    moveRequestDocuments = [],
  } = moveRequest;

  const [, setQueryParamValue] = useQueryParams({
    documentId: StringParam,
  });

  // State
  const [updateStep, setUpdateStep] = React.useState<EStep>();

  // Redux
  const dispatch = useDispatch();

  // Render
  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  const renderInfoField = (
    title: string,
    value?: string | number | boolean,
  ) => {
    return (
      <TextStyle variation="subdued" element="div">
        {title}
        {": "}
        {value || (
          <TextStyle variation="negative">
            {getLocalizedText("system.not_filled_in")}
          </TextStyle>
        )}
      </TextStyle>
    );
  };

  const renderDocumentsListItem = (params: {
    entityKey: string;
    documentCategory: EMoveRequestDocumentCategory;
    updateStepAddDocuments: EStep;
  }) => {
    const { entityKey, documentCategory, updateStepAddDocuments } = params;

    const moveRequestDocumentsToRender = moveRequestDocuments.filter(
      x => x.categoryId === documentCategory,
    );

    return (
      <ResourceListItem
        media={<Icon source="fileImage" />}
        mediaSize="medium"
        boxProps={{
          px: ESpacings.base,
          py: ESpacings.tight,
          pl: ESpacings.tight,
        }}
      >
        <TextStyle element="div" size="medium">
          {getLocalizedText(
            `move_request.detail.section.${entityKey}.handover_documents_info`,
          )}
        </TextStyle>

        <Spacer weight={ESpacerWeight.W04} />

        <ResourceList
          items={moveRequestDocumentsToRender}
          renderItem={(moveRequestDocument, index = 0) => {
            return (
              <ResourceListItem
                boxProps={
                  moveRequestDocumentsToRender.length === 1
                    ? {
                        p: ESpacings.none,
                        pb: ESpacings.tight,
                      }
                    : {
                        py: ESpacings.tight,
                        pt: index === 0 ? ESpacings.none : ESpacings.tight,
                      }
                }
                actions={[
                  {
                    content: getLocalizedText("system.view_detail"),
                    onClick: () => {
                      setQueryParamValue({
                        documentId: moveRequestDocument.documentId,
                      });
                    },
                  },
                  // {
                  //   content: getLocalizedText("system.view"),
                  //   onClick: () => {
                  //     utils.document.viewDocument(moveRequestDocument.document);
                  //   },
                  // },
                  {
                    content: (
                      <TextStyle variation={"negative"}>
                        {getLocalizedText("system.delete")}
                      </TextStyle>
                    ),
                    onClick: () => {
                      confirm({
                        title: getLocalizedText("system.remove_confirm.title", {
                          entity: getLocalizedText(
                            "system.entity.document",
                          ).toLowerCase(),
                        }),
                        primaryActions: [
                          {
                            title: getLocalizedText("system.remove"),
                            onPress: async () => {
                              try {
                                await api.delete(
                                  `/move-requests/${moveRequest.id}/documents/${moveRequestDocument.id}`,
                                );

                                dispatch(
                                  moveRequestActions.getMoveRequest.actions.start(
                                    {
                                      id: moveRequest.id,
                                    },
                                  ),
                                );

                                showAlert({
                                  type: "success",
                                  message:
                                    getGeneralRemoveSuccessText("document"),
                                });
                              } catch (unknownError) {
                                const error = unknownError as any;
                                showAlert({
                                  type: "error",
                                  error,
                                });
                              }
                            },
                          },
                        ],
                      });
                    },
                  },
                ]}
              >
                <TextStyle variation={"subdued"}>
                  {moveRequestDocument.document.filename}
                </TextStyle>
              </ResourceListItem>
            );
          }}
        />

        <Button
          appearance="link"
          onClick={() => {
            setUpdateStep(updateStepAddDocuments);
          }}
        >
          {getLocalizedText("document.add")}
        </Button>
      </ResourceListItem>
    );
  };

  const defaultItems = [
    { key: "general_info" },
    { key: "handover_info" },
    { key: "handover_documents_info" },
  ];

  return (
    <>
      {moveRequest.externalUrl && (
        <Card
          heading={renderCardHeader(
            getLocalizedText("move_request.detail.card.external_status.title"),
          )}
          space={ESpacings.base}
        >
          <Button
            appearance="link"
            onClick={() => {
              setTimeout(() => {
                window.open(moveRequest.externalUrl, "_blank");
              }, 100);
            }}
          >
            {getLocalizedText(
              "contract_card.checklist.move_service.info_modal.cta.external_status",
            )}
          </Button>
        </Card>
      )}

      <Card
        heading={renderCardHeader(
          getLocalizedText("move_request.detail.card.customer.title"),
        )}
        space={ESpacings.base}
      >
        <ResourceListItem
          media={<Icon source="contact" />}
          mediaSize="medium"
          boxProps={{
            px: ESpacings.base,
            py: ESpacings.tight,
            pl: ESpacings.tight,
          }}
        >
          <TextStyle element="div" size="medium">
            {[moveRequest.contactFirstname, moveRequest.contactLastname].join(
              " ",
            )}
          </TextStyle>

          <Spacer weight={ESpacerWeight.W04} />

          <TextStyle element="div" variation="subdued">
            {[
              formatPhone(moveRequest.contactPhone),
              moveRequest.contactEmail,
            ].join(" - ")}
          </TextStyle>

          <Spacer weight={ESpacerWeight.W02} />

          <TextStyle element="div">
            {getLocalizedText(
              `move.option.manager.${stringToSnakeCase(
                moveRequest.manager,
              )}.info`.toLowerCase(),
            )}
          </TextStyle>
        </ResourceListItem>
      </Card>

      <Card
        heading={renderCardHeader(
          getLocalizedText("move_request.detail.card.leaving_contact.title"),
        )}
        space={ESpacings.base}
      >
        <ResourceListItem
          media={<Icon source="contact" />}
          mediaSize="medium"
          boxProps={{
            px: ESpacings.base,
            py: ESpacings.tight,
            pl: ESpacings.tight,
          }}
          actions={
            moveRequest.leavingContactFirstname
              ? [
                  {
                    content: getLocalizedText(
                      "move_request.detail.card.leaving_contact.update.title",
                    ),
                    onClick: () => {
                      setUpdateStep(EStep.LeavingContact);
                    },
                  },
                  {
                    content: (
                      <TextStyle variation="negative">
                        {getLocalizedText(
                          "move_request.detail.card.leaving_contact.clear.title",
                        )}
                      </TextStyle>
                    ),
                    onClick: () => {
                      confirm({
                        type: "warning",
                        modalProps: {
                          hasDismiss: true,
                          shouldCloseOnOverlayClick: true,
                        },
                        title: getLocalizedText("form.dismiss.alert.title"),
                        primaryActions: [
                          {
                            title: getLocalizedText("system.yes"),
                            onPress: () => {
                              dispatch(
                                moveRequestActions.updateMoveRequest.actions.start(
                                  {
                                    id: moveRequest.id,
                                    publicId: moveRequest.publicId,
                                    moveRequest: {
                                      // @ts-ignore
                                      leavingContactFirstname: null,
                                      // @ts-ignore
                                      leavingContactLastname: null,
                                      // @ts-ignore
                                      leavingContactEmail: null,
                                    },
                                  },
                                ),
                              );
                            },
                          },
                        ],
                      });
                    },
                  },
                ]
              : [
                  {
                    content: getLocalizedText(
                      "move_request.detail.card.leaving_contact.select.title",
                    ),
                    onClick: () => {
                      setUpdateStep(EStep.LeavingContact);
                    },
                  },
                ]
          }
        >
          {moveRequest.leavingContactFirstname ? (
            <>
              <TextStyle element="div" size="medium">
                {[
                  moveRequest.leavingContactFirstname,
                  moveRequest.leavingContactLastname,
                ].join(" ")}
              </TextStyle>

              <Spacer weight={ESpacerWeight.W04} />

              <TextStyle element="div" variation="subdued">
                {[moveRequest.leavingContactEmail].join(" - ")}
              </TextStyle>
            </>
          ) : (
            <>
              <TextStyle element="div" variation="subdued">
                {getLocalizedText(
                  "move_request.detail.card.leaving_contact.empty",
                )}
              </TextStyle>
            </>
          )}
        </ResourceListItem>
      </Card>

      <Card
        heading={renderCardHeader(
          getLocalizedText("move_request.detail.card.services.title"),
        )}
        space={ESpacings.base}
      >
        <ResourceList
          items={moveRequest.services}
          renderItem={service => {
            let iconSource: TAssetSource = "lightBulb";
            let showNote = false;
            let note = null;

            switch (service) {
              case EMoveServiceService.Energy:
                iconSource = "lightBulb";
                showNote = true;
                note = moveRequest.energyInvoiceNote;
                break;

              case EMoveServiceService.Telco:
                iconSource = "wifi";
                showNote = true;
                note = moveRequest.telcoInvoiceNote;
                break;

              case EMoveServiceService.Water:
                iconSource = "waterTap";
                break;

              default:
                break;
            }

            return (
              <ResourceListItem
                media={<Icon source={iconSource} />}
                mediaSize="medium"
                boxProps={{
                  px: ESpacings.base,
                  py: ESpacings.tight,
                  pl: ESpacings.tight,
                }}
                actions={
                  showNote
                    ? [
                        {
                          content: getLocalizedText(
                            "move_request.detail.card.services.cta.edit_note",
                          ),
                          onClick: () => {
                            switch (service) {
                              case EMoveServiceService.Energy:
                                setUpdateStep(EStep.EnergyNote);
                                break;

                              case EMoveServiceService.Telco:
                                setUpdateStep(EStep.TelcoNote);
                                break;

                              default:
                                break;
                            }
                          },
                        },
                      ]
                    : []
                }
              >
                <TextStyle element="div" size="medium">
                  {getLocalizedText(`move.option.service.${service}`)}
                </TextStyle>

                {showNote && (
                  <TextStyle element="div" variation="subdued">
                    <S.Ellipsis>
                      {getLocalizedText(
                        "move_request.detail.card.services.note",
                        {
                          value:
                            note ||
                            getLocalizedText(
                              "move_request.detail.card.services.note.empty",
                            ),
                        },
                      )}
                    </S.Ellipsis>
                  </TextStyle>
                )}
              </ResourceListItem>
            );
          }}
        />
      </Card>

      <Card
        heading={renderCardHeader(
          getLocalizedText("move_request.detail.card.electricity.title"),
        )}
        space={ESpacings.base}
      >
        <ResourceList
          items={defaultItems}
          renderItem={item => {
            const { key } = item;

            if (key === "general_info") {
              return (
                <ResourceListItem
                  media={<Icon source="lightBulb" />}
                  mediaSize="medium"
                  boxProps={{
                    px: ESpacings.base,
                    py: ESpacings.tight,
                    pl: ESpacings.tight,
                  }}
                  actions={[
                    {
                      content: getLocalizedText("system.edit"),
                      onClick: () => {
                        setUpdateStep(EStep.ElectricityGeneral);
                      },
                    },
                  ]}
                >
                  <TextStyle element="div" size="medium">
                    {getLocalizedText(
                      "move_request.detail.section.electricity.general_info",
                    )}
                  </TextStyle>

                  <Spacer weight={ESpacerWeight.W04} />

                  {renderInfoField(
                    getLocalizedText("move.field.electricity_type.title"),
                    electricityType &&
                      getLocalizedText(
                        `move.option.electricity_type.${electricityType}`,
                      ),
                  )}

                  <Spacer weight={ESpacerWeight.W02} />

                  {renderInfoField(
                    getLocalizedText("move.field.electricity_ean.title"),
                    electricityEan,
                  )}

                  <Spacer weight={ESpacerWeight.W02} />

                  {renderInfoField(
                    getLocalizedText(
                      "move.field.electricity_meter_number_both.title",
                    ),
                    electricityMeterNumberBoth,
                  )}
                </ResourceListItem>
              );
            }

            if (key === "handover_info") {
              return (
                <ResourceListItem
                  media={<Icon source="gaugeDashboard" />}
                  mediaSize="medium"
                  boxProps={{
                    px: ESpacings.base,
                    py: ESpacings.tight,
                    pl: ESpacings.tight,
                  }}
                  actions={[
                    moveRequest.electricityEan &&
                    moveRequest.electricityMeterNumberBoth
                      ? {
                          content: getLocalizedText("system.edit"),
                          onClick: () => {
                            setUpdateStep(EStep.ElectricityHandover);
                          },
                        }
                      : {
                          content: (
                            <TextStyle variation="warn">
                              {getLocalizedText(
                                "move_request.detail.cta.complete_general_info.electricity",
                              )}
                            </TextStyle>
                          ),
                          onClick: () => {
                            setUpdateStep(EStep.ElectricityGeneral);
                          },
                        },
                  ]}
                >
                  <TextStyle element="div" size="medium">
                    {getLocalizedText(
                      "move_request.detail.section.electricity.handover_info",
                    )}
                  </TextStyle>

                  <Spacer weight={ESpacerWeight.W04} />

                  {electricityType === EElectricityType.Double ? (
                    <>
                      {renderInfoField(
                        getLocalizedText(
                          "move.field.electricity_meter_reading_day.title",
                        ),
                        electricityMeterReadingDay &&
                          `${electricityMeterReadingDay} - ${formatDate(
                            electricityMeterReadingDayDate || new Date(),
                          )}`,
                      )}

                      <Spacer weight={ESpacerWeight.W02} />

                      {renderInfoField(
                        getLocalizedText(
                          "move.field.electricity_meter_reading_night.title",
                        ),
                        electricityMeterReadingNight &&
                          `${electricityMeterReadingNight} - ${formatDate(
                            electricityMeterReadingNightDate || new Date(),
                          )}`,
                      )}

                      <Spacer weight={ESpacerWeight.W02} />
                    </>
                  ) : (
                    <>
                      {renderInfoField(
                        getLocalizedText(
                          "move.field.electricity_meter_reading_both.title",
                        ),
                        electricityMeterReadingBoth &&
                          `${electricityMeterReadingBoth} - ${formatDate(
                            electricityMeterReadingBothDate || new Date(),
                          )}`,
                      )}

                      <Spacer weight={ESpacerWeight.W02} />
                    </>
                  )}

                  {renderInfoField(
                    getLocalizedText("move.field.electricity_meter_type.title"),
                    electricityMeterType
                      ? getLocalizedText(
                          `move.option.electricity_meter_type.${electricityMeterType}`,
                        )
                      : undefined,
                  )}
                </ResourceListItem>
              );
            }

            if (key === "handover_documents_info") {
              return renderDocumentsListItem({
                entityKey: "electricity",
                documentCategory:
                  EMoveRequestDocumentCategory.ElectricityGeneral,
                updateStepAddDocuments: EStep.ElectricityHandoverDocuments,
              });
            }

            return null;
          }}
        />
      </Card>

      <Card
        heading={renderCardHeader(
          getLocalizedText("move_request.detail.card.natural_gas.title"),
        )}
        space={ESpacings.base}
      >
        <ResourceList
          items={
            moveRequest.naturalGasAvailable === ENaturalGasAvailable.Unavailable
              ? [{ key: "general_info" }]
              : [
                  { key: "general_info" },
                  { key: "handover_info" },
                  { key: "handover_documents_info" },
                ]
          }
          renderItem={item => {
            const { key } = item;

            if (key === "general_info") {
              return (
                <ResourceListItem
                  media={<Icon source="houseFire" />}
                  mediaSize="medium"
                  boxProps={{
                    px: ESpacings.base,
                    py: ESpacings.tight,
                    pl: ESpacings.tight,
                  }}
                  actions={[
                    {
                      content: getLocalizedText("system.edit"),
                      onClick: () => {
                        setUpdateStep(EStep.NaturalGasGeneral);
                      },
                    },
                  ]}
                >
                  <TextStyle element="div" size="medium">
                    {getLocalizedText(
                      "move_request.detail.section.natural_gas.general_info",
                    )}
                  </TextStyle>

                  <Spacer weight={ESpacerWeight.W04} />

                  {renderInfoField(
                    getLocalizedText("move.field.natural_gas_type.title"),

                    moveRequest.naturalGasAvailable
                      ? getLocalizedText(
                          `move.option.natural_gas_type.${moveRequest.naturalGasAvailable}`.toLowerCase(),
                        )
                      : undefined,
                  )}

                  {moveRequest.naturalGasAvailable !==
                    ENaturalGasAvailable.Unavailable && (
                    <>
                      <Spacer weight={ESpacerWeight.W02} />

                      {renderInfoField(
                        getLocalizedText("move.field.natural_gas_ean.title"),
                        naturalGasEan,
                      )}

                      <Spacer weight={ESpacerWeight.W02} />

                      {renderInfoField(
                        getLocalizedText(
                          "move.field.natural_gas_meter_number.title",
                        ),
                        naturalGasMeterNumber,
                      )}
                    </>
                  )}
                </ResourceListItem>
              );
            }

            if (key === "handover_info") {
              return (
                <ResourceListItem
                  media={<Icon source="gaugeDashboard" />}
                  mediaSize="medium"
                  boxProps={{
                    px: ESpacings.base,
                    py: ESpacings.tight,
                    pl: ESpacings.tight,
                  }}
                  actions={[
                    moveRequest.naturalGasEan &&
                    moveRequest.naturalGasMeterNumber
                      ? {
                          content: getLocalizedText("system.edit"),
                          onClick: () => {
                            setUpdateStep(EStep.NaturalGasHandover);
                          },
                        }
                      : {
                          content: (
                            <TextStyle variation="warn">
                              {getLocalizedText(
                                "move_request.detail.cta.complete_general_info.natural_gas",
                              )}
                            </TextStyle>
                          ),
                          onClick: () => {
                            setUpdateStep(EStep.NaturalGasGeneral);
                          },
                        },
                  ]}
                >
                  <TextStyle element="div" size="medium">
                    {getLocalizedText(
                      "move_request.detail.section.natural_gas.handover_info",
                    )}
                  </TextStyle>

                  <Spacer weight={ESpacerWeight.W04} />

                  {renderInfoField(
                    getLocalizedText(
                      "move.field.natural_gas_meter_reading.title",
                    ),
                    naturalGasMeterReading &&
                      `${naturalGasMeterReading} - ${formatDate(
                        naturalGasMeterReadingDate || new Date(),
                      )}`,
                  )}

                  <Spacer weight={ESpacerWeight.W02} />

                  {renderInfoField(
                    getLocalizedText("move.field.natural_gas_meter_type.title"),
                    naturalGasMeterType
                      ? getLocalizedText(
                          `move.option.natural_gas_meter_type.${naturalGasMeterType}`,
                        )
                      : undefined,
                  )}
                </ResourceListItem>
              );
            }

            if (key === "handover_documents_info") {
              return renderDocumentsListItem({
                entityKey: "natural_gas",
                documentCategory:
                  EMoveRequestDocumentCategory.NaturalGasGeneral,
                updateStepAddDocuments: EStep.NaturalGasHandoverDocuments,
              });
            }

            return null;
          }}
        />
      </Card>

      <Card
        heading={renderCardHeader(
          getLocalizedText("move_request.detail.card.water.title"),
        )}
        space={ESpacings.base}
      >
        <ResourceList
          items={defaultItems}
          renderItem={item => {
            const { key } = item;

            if (key === "general_info") {
              return (
                <ResourceListItem
                  media={<Icon source="waterTap" />}
                  mediaSize="medium"
                  boxProps={{
                    px: ESpacings.base,
                    py: ESpacings.tight,
                    pl: ESpacings.tight,
                  }}
                  actions={[
                    {
                      content: getLocalizedText("system.edit"),
                      onClick: () => {
                        setUpdateStep(EStep.WaterGeneral);
                      },
                    },
                  ]}
                >
                  <TextStyle element="div" size="medium">
                    {getLocalizedText(
                      "move_request.detail.section.water.general_info",
                    )}
                  </TextStyle>

                  <Spacer weight={ESpacerWeight.W04} />

                  {renderInfoField(
                    getLocalizedText("move.field.water_meter_number.title"),
                    waterMeterNumber,
                  )}
                </ResourceListItem>
              );
            }

            if (key === "handover_info") {
              return (
                <ResourceListItem
                  media={<Icon source="gaugeDashboard" />}
                  mediaSize="medium"
                  boxProps={{
                    px: ESpacings.base,
                    py: ESpacings.tight,
                    pl: ESpacings.tight,
                  }}
                  actions={[
                    moveRequest.waterMeterNumber
                      ? {
                          content: getLocalizedText("system.edit"),
                          onClick: () => {
                            setUpdateStep(EStep.WaterHandover);
                          },
                        }
                      : {
                          content: (
                            <TextStyle variation="warn">
                              {getLocalizedText(
                                "move_request.detail.cta.complete_general_info.water",
                              )}
                            </TextStyle>
                          ),
                          onClick: () => {
                            setUpdateStep(EStep.WaterGeneral);
                          },
                        },
                  ]}
                >
                  <TextStyle element="div" size="medium">
                    {getLocalizedText(
                      "move_request.detail.section.water.handover_info",
                    )}
                  </TextStyle>

                  <Spacer weight={ESpacerWeight.W04} />

                  {renderInfoField(
                    getLocalizedText("move.field.water_meter_reading.title"),
                    waterMeterReading &&
                      `${waterMeterReading} - ${formatDate(
                        waterMeterReadingDate || new Date(),
                      )}`,
                  )}
                </ResourceListItem>
              );
            }

            if (key === "handover_documents_info") {
              return renderDocumentsListItem({
                entityKey: "water",
                documentCategory: EMoveRequestDocumentCategory.WaterGeneral,
                updateStepAddDocuments: EStep.WaterHandoverDocuments,
              });
            }

            return null;
          }}
        />
      </Card>

      {updateStep !== undefined && (
        <MoveRequestUpdateModal
          moveRequest={moveRequest}
          property={property}
          step={updateStep}
          onClose={() => {
            setUpdateStep(undefined);
          }}
        />
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default MoveRequestInfo;
