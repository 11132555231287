import { Box } from "@rebass/grid";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { spacing } from "@rentiohq/web-theme";

export interface IRepairTrackingLink {
  taskCaseId: string;
}

export const RepairTrackingLink = ({ taskCaseId }: IRepairTrackingLink) => {
  if (!CONFIG.KEYPOINT_REPAIR_URL && taskCaseId) return null;
  return (
    <Box mt={spacing("base")}>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${CONFIG.KEYPOINT_REPAIR_URL}?caseId=${taskCaseId}`}
      >
        {getLocalizedText("task.repair.keypoint.tracking_link_info")}
      </a>
    </Box>
  );
};
