import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { DisplayTypeContext } from "../../../contexts/DisplayType.context";
import * as S from "./AtomicBlockPageBreak.styles";
var AtomicBlockPageBreak = function (props) {
    var _a = React.useContext(DisplayTypeContext), isPrint = _a.isPrint, isTemplateDocument = _a.isTemplateDocument;
    // Render
    if (isPrint) {
        return _jsx(S.PrintWrap, { children: props.children });
    }
    return (_jsxs(_Fragment, { children: [isTemplateDocument && props.children, _jsxs(S.Wrap, { children: [_jsx(S.Line, {}), _jsx(S.Title, { children: getLocalizedText("editor.block.page_break") }), _jsx(S.Line, {})] })] }));
};
// eslint-disable-next-line import/no-default-export
export default AtomicBlockPageBreak;
