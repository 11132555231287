import { IPaymentOrder } from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  Activities,
  Card,
  DisplayText,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { ts } from "../../../../../../services";
import { getPaymentOrderActivityFilter } from "../../../../../../utils/payment";

interface IProps {
  paymentOrder: IPaymentOrder;
  setTab?: (tab: any) => void;
}

export const PaymentOrderHistory: React.FC<IProps> = ({ paymentOrder }) => {
  return (
    <Card
      space={ESpacings.base}
      heading={
        <DisplayText size="extraSmall" space="tight">
          {ts.system("history")}
        </DisplayText>
      }
    >
      <Activities
        identifier={`payment-Order-${paymentOrder.id}`}
        filter={getPaymentOrderActivityFilter(paymentOrder)}
        hasFetchMore={true}
        isCompact={true}
      />
    </Card>
  );
};
