import { useTheme } from "@mui/material/styles";
export var NoDataCardStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            width: "100%",
            height: "150px",
            borderRadius: "4px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: theme.palette.grey[100],
            border: "1px solid ".concat(theme.palette.grey[200]),
            color: theme.palette.text.primary,
        },
    };
};
