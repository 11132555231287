var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense } from "react";
import { Loader } from "../Loader";
export var Loadable = function (Component) { return function (props) { return (_jsx(Suspense, { fallback: _jsx(Loader, {}), children: _jsx(Component, __assign({}, props)) })); }; };
