import { useTheme } from "@rentiohq/web-shared-next/dist/themes";

export const DataListItemStyles = (clickable: boolean) => {
  const theme = useTheme();
  return {
    wrapper: {
      position: "relative",
      cursor: clickable ? "pointer" : "default",
    },
    content: {
      link: {
        position: "absolute",
        right: "8px",
        top: "8px",
        svg: {
          color: theme.palette.text.secondary,
        },
      },
    },
  };
};
