import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EProduct } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import signInSchemas from "@rentiohq/shared-frontend/dist/forms/signIn";
import * as signInActions from "@rentiohq/shared-frontend/dist/forms/signIn/schema.signIn.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as authSelectors from "@rentiohq/shared-frontend/dist/redux/auth/auth.selectors";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useSelector } from "react-redux";
import { DisplayText, Link } from "../../components";
import { MultiStepForm } from "../../components/SharedForm/MultiStepForm";
import * as t from "../../services/translationService";
import { StyledAuthActions, StyledAuthBody, StyledAuthContent, StyledAuthFrame, StyledAuthLogo, StyledAuthMain, StyledAuthMedia, StyledAuthMediaWrap, } from "./Auth.styled";
import { useNavigateAfterSuccess } from "./Auth.utils";
import { InternalModeButton } from "./components/InternalModeButton";
var SignIn = function () {
    // Router
    var navigateAfterSuccess = useNavigateAfterSuccess();
    // State
    var _a = React.useState(true), preventRedirect = _a[0], setPreventRedirect = _a[1];
    // Redux
    var hasAccessToken = useSelector(authSelectors.hasAccessToken);
    var user = authHooks.useSelf().user;
    // Lifecycle
    React.useEffect(function () {
        if (preventRedirect) {
            return;
        }
        if (!hasAccessToken || !user) {
            return;
        }
        navigateAfterSuccess();
    }, [hasAccessToken, navigateAfterSuccess, preventRedirect, user]);
    // Render
    var defaultFormData = {};
    if (process.env.NODE_ENV === "development") {
        if (CONFIG.PRODUCT === EProduct.Dashboard) {
            defaultFormData = {
                phoneOrEmail: "stijn@rentio.be",
                password: "test1234",
            };
        }
        if (CONFIG.PRODUCT === EProduct.Backoffice) {
            defaultFormData = {
                phoneOrEmail: "admin@rentio.be",
                password: "admin123",
            };
        }
        if (CONFIG.PRODUCT === EProduct.InsuranceBackoffice) {
            defaultFormData = {
                phoneOrEmail: "hello@rentio-insurance.be",
                password: "test1234",
            };
        }
    }
    return (_jsxs(StyledAuthFrame, { children: [_jsx(StyledAuthContent, { children: _jsxs(StyledAuthMain, { children: [_jsx(InternalModeButton, {}), _jsx(StyledAuthLogo, {}), _jsxs(StyledAuthBody, { children: [_jsx(DisplayText, { children: t.authLoginTitle() }), _jsx(MultiStepForm, { formId: "sign-in", 
                                    // @ts-ignore state match
                                    isPerformingSubmitActionSelector: signInActions.isPerformingSubmitActionSelector(), 
                                    // @ts-ignore state match
                                    performingSubmitActionResultSelector: signInActions.performingSubmitActionResultSelector(), 
                                    // @ts-ignore state match
                                    performingSubmitActionErrorSelector: signInActions.performingSubmitActionErrorSelector(), submitActionCreator: signInActions.submitActionCreator(), schemas: signInSchemas(defaultFormData), asModal: false, withAside: false, onSuccess: function () { return setPreventRedirect(false); }, submitLabel: getLocalizedText("auth.login.submit.label"), fullWidthSubmitButton: true }), CONFIG.PRODUCT === EProduct.Dashboard && (_jsxs(_Fragment, { children: [_jsx(StyledAuthActions, { children: _jsx(Link, { to: "/auth/resetPassword", children: t.authCantLogin() }) }), _jsx(StyledAuthActions, { children: _jsx(Link, { to: "/auth/signup", children: t.authSignUp() }) })] }))] })] }) }), _jsx(StyledAuthMediaWrap, { children: _jsx(StyledAuthMedia, {}) })] }));
};
// eslint-disable-next-line import/no-default-export
export default SignIn;
