import * as actions from "./generalLedgerAccount.actions";
import * as api from "./generalLedgerAccount.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY, } from "./generalLedgerAccount.constants";
import * as hooks from "./generalLedgerAccount.hooks";
import * as promises from "./generalLedgerAccount.promises";
import { GeneralLedgerAccountReducer, } from "./generalLedgerAccount.reducer";
import { saga } from "./generalLedgerAccount.saga";
import * as selectors from "./generalLedgerAccount.selectors";
export var REDUCER_KEY_GENERAL_LEDGER_ACCOUNT = REDUCER_KEY;
export var generalLedgerAccountModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: GeneralLedgerAccountReducer,
    saga: saga,
    selectors: selectors,
    utils: {},
};
