import { AsYouType, isValidNumber, parseNumber } from "libphonenumber-js";
export var phoneValidate = function (value) {
    return !!value && isValidNumber(clean(value));
};
var parseNumberSafe = function (value) {
    var phoneMask = value;
    try {
        phoneMask = parseNumber(value);
    }
    catch (unknownError) { }
    if (phoneMask && typeof phoneMask === "string") {
        return phoneMask;
    }
    return value;
};
var clean = function (value) {
    if (!value) {
        return value;
    }
    // Replace all non characters that are not a number, '|' or '+'
    return value.replace(/([^0-9|+|/])/g, "");
};
export var parsePhoneNumber = function (value) {
    var parsedPhoneNumber = clean(value);
    // Replace '00' at start with '+'
    if (parsedPhoneNumber.startsWith("00")) {
        parsedPhoneNumber = "+" + parsedPhoneNumber.substring(2);
    }
    // Replace '04' at start with '+324'
    if (parsedPhoneNumber.startsWith("04")) {
        parsedPhoneNumber = "+324" + parsedPhoneNumber.substring(2);
    }
    // Replace '+3204' at start with '+324'
    if (parsedPhoneNumber.startsWith("+3204")) {
        parsedPhoneNumber = "+324" + parsedPhoneNumber.substring(5);
    }
    // Replace '+3200' at start with '+32'
    if (parsedPhoneNumber.startsWith("+3200")) {
        parsedPhoneNumber = "+32" + parsedPhoneNumber.substring(5);
    }
    if (parsedPhoneNumber && parsedPhoneNumber.length > 1) {
        parsedPhoneNumber = parseNumberSafe(parsedPhoneNumber);
    }
    return new AsYouType().input(parsedPhoneNumber);
};
export { AsYouType, isValidNumber, parseNumber };
