export var TAB_BACKGROUND = "WINDOW_STATE.TAB_BACKGROUND";
export var TAB_FOREGROUND = "WINDOW_STATE.TAB_FOREGROUND";
export var ONLINE = "WINDOW_STATE.ONLINE";
export var OFFLINE = "WINDOW_STATE.OFFLINE";
export var FOCUS = "WINDOW_STATE.FOCUS";
export var BLUR = "WINDOW_STATE.BLUR";
// eslint-disable-next-line import/no-default-export
export default (function () {
    return function (createStore) {
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            var store = createStore.apply(void 0, args);
            var handleFocusChanged = function (event) {
                var type;
                switch (event.type) {
                    case "focus":
                        type = FOCUS;
                        break;
                    case "blur":
                        type = BLUR;
                        break;
                }
                store.dispatch({
                    type: type,
                });
            };
            var handleVisibilityChanged = function () {
                var type;
                if (window.document.hidden) {
                    type = TAB_BACKGROUND;
                }
                else {
                    type = TAB_FOREGROUND;
                }
                store.dispatch({
                    type: type,
                });
            };
            var handleConnectionChanged = function () {
                var type;
                if (navigator.onLine) {
                    type = ONLINE;
                }
                else {
                    type = OFFLINE;
                }
                store.dispatch({
                    type: type,
                });
            };
            window.addEventListener("focus", handleFocusChanged);
            window.addEventListener("blur", handleFocusChanged);
            window.document.addEventListener("visibilitychange", handleVisibilityChanged);
            window.addEventListener("online", handleConnectionChanged);
            // setTimeout to allow redux-saga to catch the initial state
            setTimeout(function () { return handleVisibilityChanged(); });
            // setTimeout(() => handleConnectionChanged())
            return store;
        };
    };
});
