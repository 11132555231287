import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import {
  ETaskMemberType,
  ITask,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMembersWithRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  Button,
  Grid,
  Icon,
  Lozenge,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import * as t from "../../../../services/translationService";

interface IProps {
  task?: ITask;
}

export const TaskMeta: React.FC<IProps> = props => {
  const { task } = props;

  if (!task) {
    return null;
  }

  const {
    dueDate,
    executeDate,
    propertyId,
    propertyAddress,
    status,
    location,
    category,
    type,
  } = task;

  const admin = getMembersWithRole(task.members, ETaskMemberType.Admin)[0]
    ?.account;

  return (
    <Grid alignItems="center" flexDirection="row">
      {admin && (
        <Grid.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="contact" />
            </Grid.Item>
            <Grid.Item>{getName(admin)}</Grid.Item>
          </Grid>
        </Grid.Item>
      )}

      {propertyId && propertyAddress && (
        <>
          <Grid.Item>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source="house" />
              </Grid.Item>
              <Grid.Item>
                <Button appearance="link" url={`/properties/${propertyId}`}>
                  {propertyAddress.simple}
                </Button>
              </Grid.Item>
            </Grid>
          </Grid.Item>
        </>
      )}

      {executeDate && (
        <Grid.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="notesClock" />
            </Grid.Item>
            <Grid.Item>{t.tasksExecuteDateMeta()}</Grid.Item>
            <Grid.Item>{formatDate(executeDate)}</Grid.Item>
          </Grid>
        </Grid.Item>
      )}

      {dueDate && (
        <Grid.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="alarmClock" />
            </Grid.Item>
            <Grid.Item>{t.tasksDueDate()}</Grid.Item>
            <Grid.Item>{formatDate(dueDate)}</Grid.Item>
          </Grid>
        </Grid.Item>
      )}

      {type && (
        <Grid.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="houseNotes" />
            </Grid.Item>
            <Grid.Item>
              {getLocalizedText(`task.type.${type}`.toLowerCase())}
            </Grid.Item>
            {category && (
              <Grid.Item>
                {"("}
                {getLocalizedText(`task.category.${category}`.toLowerCase())}
                {")"}
              </Grid.Item>
            )}
          </Grid>
        </Grid.Item>
      )}

      {location && (
        <Grid.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="locationSearch" />
            </Grid.Item>
            <Grid.Item>
              {getLocalizedText(`task.location.${location}`.toLowerCase())}
            </Grid.Item>
          </Grid>
        </Grid.Item>
      )}

      {status && (
        <Grid.Item>
          <Lozenge customBackgroundColor="#2D9CDB" customTextColor="white">
            {getLocalizedText("task.repair.service.keypoint")}
          </Lozenge>
        </Grid.Item>
      )}

      <Grid.Item>
        <Grid spacing="extraTight" alignItems="center">
          <Grid.Item>
            <Icon color={"transparent"} source="alarmClock" />
          </Grid.Item>
        </Grid>
      </Grid.Item>
    </Grid>
  );
};
