var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import MuiSnackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { HIDE_SNACKBAR } from "../../redux/actions";
import { snackbarSelector } from "../../redux/selectors";
function TransitionSlideLeft(props) {
    return _jsx(Slide, __assign({}, props, { direction: "left" }));
}
function TransitionSlideUp(props) {
    return _jsx(Slide, __assign({}, props, { direction: "up" }));
}
function TransitionSlideRight(props) {
    return _jsx(Slide, __assign({}, props, { direction: "right" }));
}
function TransitionSlideDown(props) {
    return _jsx(Slide, __assign({}, props, { direction: "down" }));
}
function GrowTransition(props) {
    return _jsx(Grow, __assign({}, props));
}
var animation = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade: Fade,
};
export var Snackbar = function () {
    var dispatch = useDispatch();
    var snackbar = useSelector(snackbarSelector);
    var actionButton = snackbar.actionButton, anchorOrigin = snackbar.anchorOrigin, alert = snackbar.alert, close = snackbar.close, message = snackbar.message, open = snackbar.open, transition = snackbar.transition, variant = snackbar.variant;
    var handleClose = function (event, reason) {
        if (reason === "clickaway") {
            return;
        }
        dispatch({ type: HIDE_SNACKBAR });
    };
    return (_jsxs(_Fragment, { children: [variant === "default" && (_jsx(MuiSnackbar, { anchorOrigin: anchorOrigin, open: open, autoHideDuration: 1500, onClose: handleClose, message: message, TransitionComponent: animation[transition], action: _jsxs(_Fragment, { children: [_jsx(Button, { color: "secondary", size: "small", onClick: handleClose, children: "UNDO" }), _jsx(IconButton, { size: "small", "aria-label": "close", color: "inherit", onClick: handleClose, sx: { mt: 0.25 }, children: _jsx(CloseIcon, { fontSize: "small" }) })] }) })), variant === "alert" && (_jsx(MuiSnackbar, { TransitionComponent: animation[transition], anchorOrigin: anchorOrigin, open: open, autoHideDuration: 1500, onClose: handleClose, children: _jsx(Alert, { variant: alert.variant, color: alert.color, action: _jsxs(_Fragment, { children: [actionButton !== false && (_jsx(Button, { size: "small", onClick: handleClose, sx: { color: "background.paper" }, children: "UNDO" })), close !== false && (_jsx(IconButton, { sx: { color: "background.paper" }, size: "small", "aria-label": "close", onClick: handleClose, children: _jsx(CloseIcon, { fontSize: "small" }) }))] }), sx: __assign({}, (alert.variant === "outlined" && {
                        bgcolor: "background.paper",
                    })), children: message }) }))] }));
};
