import { Box } from "@rebass/grid";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import logger from "@rentiohq/shared-frontend/dist/logger";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as countTypes from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import * as documentPackageApi from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.api";
import * as documentPackageSelectors from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.selectors";
import { EContractType } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EDocumentPackageTypes } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatBytes } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Card,
  DataTable,
  DocumentFetchListItem,
  EmptyState,
  ESpacings,
  Filters,
  Loading,
  Lozenge,
  OptionListShared,
  Page,
  Pagination,
  Stages,
  TextStyle,
  TSortDirection,
} from "@rentiohq/web-shared/dist/components";
import { EDocumentPackageStageSimple } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import { compact, isEqual } from "lodash";
import hash from "object-hash";
import isEmpty from "ramda/es/isEmpty";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  withDefault,
} from "serialize-query-params";
import { rs, ts } from "../../../services";
import { documentPackageUtils } from "../../../utils";
import { getContractEditRoute } from "../../../utils/contract";
import {
  checkIfRevokable,
  getSignerInfo,
} from "../../../utils/documentPackage.utils";
import {
  DEFAULT_FILTER,
  getFilterQuery,
  getHasActiveFilter,
  ORDER_MAP,
} from "./DocumentPackages.utils";

const documentPackagesIdentifier = countTypes.ECountIdentifier.DocumentPackages;

export const DocumentPackages: React.FC<{}> = () => {
  const [queryParams, setQueryParamValue] = useQueryParams({
    query: withDefault(StringParam, ""),
    stage: ArrayParam,
    documentPackageType: ArrayParam,
    order: StringParam,
    page: withDefault(NumberParam, 1),
    documentPackageId: StringParam,
  });
  const [queryDebounced] = useDebounce(queryParams.query);
  const [queryInput, setQueryInput] = React.useState(queryParams.query);

  const [filterData, setFilterData] = React.useState<{
    where: Object;
    search?: string;
  }>({
    where: {},
    search: undefined,
  });

  React.useEffect(() => {
    const newFilterData = getFilterQuery(
      { ...queryParams, query: queryDebounced },
      DEFAULT_FILTER,
    ).filter;

    if (isEqual(newFilterData, filterData)) {
      return;
    }

    setFilterData(newFilterData);
  }, [queryParams, queryDebounced]);

  const uniqueIdentifier = React.useMemo(() => {
    if (!filterData) {
      return documentPackagesIdentifier;
    }
    // Create unique identifier for filter
    return `${documentPackagesIdentifier}-${hash(filterData)}`;
  }, [filterData]);

  const countBase = React.useMemo(() => {
    const { where, search } = filterData;
    let countFilter: { [key: string]: any } = { where };

    if (search) {
      countFilter.search = search;
    }
    return append("/document-packages/count", countFilter);
  }, [filterData]);

  const documentPackages =
    useSelector((state: IRootStore) =>
      documentPackageSelectors.getDocumentPackagesForPage(
        state,
        uniqueIdentifier,
        queryParams.page,
      ),
    ) || [];
  const isFetching = useSelector((state: IRootStore) =>
    documentPackageSelectors.isFetchingDocumentPackagesForPage(
      state,
      uniqueIdentifier,
      queryParams.page,
    ),
  );
  const fetchError = useSelector((state: IRootStore) =>
    documentPackageSelectors.documentPackagesForPageFetchError(
      state,
      uniqueIdentifier,
      queryParams.page,
    ),
  );
  const totalCount =
    useSelector((state: IRootStore) =>
      countSelectors.getCount(state, `${uniqueIdentifier}-count`),
    ) || 0;

  const documentPackagesPendingCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.DocumentPackagesPending,
      ) || 0,
  );
  const documentPackagesFinishedCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(
        state,
        countTypes.ECountIdentifier.DocumentPackagesFinished,
      ) || 0,
  );

  const dispatch = useDispatch();
  const isRevokingDocumentPackage = useSelector(
    (state: IRootStore) => state.documentPackage.isRevokingDocumentPackage,
  );
  const prevIsRevokingDocumentPackage = usePrevious(isRevokingDocumentPackage);

  const fetchCounts = () => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: countTypes.ECountIdentifier.DocumentPackagesPending,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier.DocumentPackagesPending
          ],
      }),
    );

    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: countTypes.ECountIdentifier.DocumentPackagesFinished,
        countBase:
          countTypes.COUNT_BASE[
            countTypes.ECountIdentifier.DocumentPackagesFinished
          ],
      }),
    );
  };

  React.useEffect(() => {
    setQueryParamValue({ query: queryInput, page: 1 });
  }, [queryInput]);

  React.useEffect(() => {
    if (!isRevokingDocumentPackage && prevIsRevokingDocumentPackage) {
      fetchCounts();
    }
  }, [isRevokingDocumentPackage, prevIsRevokingDocumentPackage]);

  React.useEffect(() => {
    fetchCounts();
  }, []);

  React.useEffect(() => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: `${uniqueIdentifier}-count`,
        countBase,
      }),
    );

    dispatch(
      documentPackageActions.getDocumentPackagesPaged.actions.start({
        identifier: uniqueIdentifier,
        page: queryParams.page,
        filterData,
      }),
    );
  }, [uniqueIdentifier, queryParams.page, filterData]);

  // Filter functions
  const handleFiltersQueryChange = (value: any) => {
    setQueryInput(value);
  };

  const handleFilterQueryValueRemove = () => {
    setQueryInput("");
  };

  const filterDocumentPackagesByStage = (values: any) => {
    const simpleStages = Object.values(EDocumentPackageStageSimple);
    const onlySimple = values.every((value: any) =>
      simpleStages.includes(value),
    );

    if (!onlySimple && values.length > 1) {
      setQueryParamValue({
        stage: values.filter((value: EDocumentPackageStageSimple) =>
          simpleStages.includes(value),
        ),
        page: 1,
      });
    } else {
      setQueryParamValue({ stage: values, page: 1 });
    }
  };

  const filterDocumentPackagesByContractType = (values: any[]) => {
    setQueryParamValue({ documentPackageType: values, page: 1 });
  };

  const handleFiltersClearAll = () => {
    handleFilterQueryValueRemove();
    filterDocumentPackagesByStage([]);
    filterDocumentPackagesByContractType([]);
  };

  const hasActiveFilter = getHasActiveFilter(queryParams);

  const handleSort = (headingIndex: number, direction: string) => {
    const sortItem = ORDER_MAP[headingIndex];
    setQueryParamValue({ order: `${sortItem} ${direction}` });
  };

  const handlePageClick = ({ selected }: any) => {
    setQueryParamValue({ page: selected + 1 });
  };

  const pageCount = React.useMemo(() => {
    return totalCount
      ? Math.ceil(totalCount / documentPackageApi.FETCH_LIMIT)
      : 1;
  }, [totalCount]);

  const handleStageClick = (stage: string) => {
    filterDocumentPackagesByStage([stage]);
  };

  const handleDocumentPackageRowClick = (rowIndex: number) => {
    setQueryParamValue({
      documentPackageId: documentPackages?.[rowIndex].id,
    });
  };

  const renderFilter = () => {
    const appliedFilters = [];
    if (queryParams.stage && !isEmpty(queryParams.stage)) {
      appliedFilters.push({
        key: "stage",
        label: join(
          queryParams.stage.map((item: any) =>
            Object.values(EDocumentPackageStageSimple).includes(item)
              ? ts.followUpDocumentPackagesFilterStageLabel({
                  extra: { key: item },
                })
              : ts.followUpDocumentPackagesStageLabel({ extra: { key: item } }),
          ),
        ),
        onRemove: () => {
          filterDocumentPackagesByStage([]);
        },
      });
    }
    if (
      queryParams.documentPackageType &&
      !isEmpty(queryParams.documentPackageType)
    ) {
      appliedFilters.push({
        key: "documentPackageType",
        label: join(
          queryParams.documentPackageType.map((item: any) =>
            ts.documentPackageType(item.toLowerCase()),
          ),
        ),
        onRemove: () => {
          filterDocumentPackagesByContractType([]);
        },
      });
    }

    return (
      <Box mb={ESpacings.loose}>
        <Filters
          appliedFilters={appliedFilters}
          queryValue={queryInput || ""}
          queryPlaceholder={ts.followUpDocumentPackagesFilterQueryPlaceholder()}
          onQueryChange={handleFiltersQueryChange}
          onQueryClear={handleFilterQueryValueRemove}
          onClearAll={handleFiltersClearAll}
          filters={[
            {
              key: "documentPackageType",
              label: ts.followUpDocumentPackagesFilterLabel({
                extra: { key: "document_package_type" },
              }),
              content: (
                <OptionListShared
                  id="contract-type"
                  value={queryParams.documentPackageType}
                  variant="simple"
                  multiple={true}
                  options={Object.values(EDocumentPackageTypes).map(
                    (type: string) => {
                      return {
                        label: ts.documentPackageType(type.toLowerCase()),
                        value: type,
                        id: type,
                      };
                    },
                  )}
                  onChange={filterDocumentPackagesByContractType}
                />
              ),
            },
            {
              key: "stage",
              label: ts.followUpDocumentPackagesFilterLabel({
                extra: { key: "stage" },
              }),
              content: (
                <OptionListShared
                  id="stage"
                  value={queryParams.stage}
                  variant="simple"
                  multiple={true}
                  options={Object.values(EDocumentPackageStageSimple)
                    .filter(
                      key =>
                        ![
                          EDocumentPackageStageSimple.Failed,
                          EDocumentPackageStageSimple.Revoked,
                        ].includes(key),
                    )
                    .map((type: string) => {
                      return {
                        label: ts.followUpDocumentPackagesFilterStageLabel({
                          extra: { key: type },
                        }),
                        value: type,
                        id: type,
                      };
                    })}
                  onChange={stages => {
                    filterDocumentPackagesByStage(stages);
                  }}
                />
              ),
            },
          ]}
        />
      </Box>
    );
  };

  const renderContent = () => {
    if (documentPackages.length > 0) {
      return (
        <>
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "action",
            ]}
            headings={[
              ts.followUpDocumentPackagesCreatedAtHeading(),
              ts.followUpDocumentPackagesContractTypeHeading(),
              ts.followUpDocumentPackagesAddressHeading(),
              ts.system("documents"),
              ts.followUpDocumentPackagesSignersHeading(),
              ts.followUpDocumentPackagesStatusHeading(),
              "",
            ]}
            sortable={[true, false, false, false, false, false, false]}
            sortColumnIndex={0}
            sortDirection={
              queryParams.order
                ? (queryParams.order.split(" ")[1] as TSortDirection)
                : "ASC"
            }
            onSort={handleSort}
            onRowClick={handleDocumentPackageRowClick}
            rows={documentPackages.map(documentPackage => {
              const handleDocumentPackageClick = () => {
                setQueryParamValue({ documentPackageId: documentPackage.id });
              };
              const { contract } = documentPackage;
              const canReleaseRentalGuarantee =
                !!contract &&
                contract.contractType! === EContractType.RentDeposit &&
                !!contract.signedAt;
              const addressParts = documentPackage.property
                ? utils.address.addressObjToString(
                    documentPackage.property,
                    true,
                  )
                : "";
              const simpleStage =
                documentPackage.status &&
                documentPackageUtils.getSimpleStages(documentPackage.status);
              const { signedCount } = getSignerInfo(documentPackage);

              const handleRevokeClick = () => {
                try {
                  dispatch(
                    documentPackageActions.revokeDocumentPackage.actions.start({
                      documentPackageId: documentPackage.id,
                    }),
                  );
                } catch (unknownError) {
                  const error = unknownError as any;
                  logger.logError({ error });
                }
              };

              return {
                id: documentPackage.id,
                content: [
                  utils.date.format(documentPackage.createdAt),
                  ts.documentPackageType(documentPackage.type.toLowerCase()),
                  <div key={`${documentPackage.id}-property`}>
                    <div>{addressParts[0]}</div>
                    <TextStyle variation={"subdued"}>
                      {addressParts[1]}
                    </TextStyle>
                  </div>,
                  <DocumentFetchListItem
                    documentId={
                      documentPackage.documentsInPackageIds[0] || "-1"
                    }
                    renderDocument={document => (
                      <div>
                        <div>{document.filename}</div>
                        <TextStyle variation={"subdued"}>
                          {formatBytes(document.filesize)}
                        </TextStyle>
                      </div>
                    )}
                    renderError={() => <p>"-"</p>}
                  />,
                  join(
                    documentPackage.signers.map(signers =>
                      getName(signers as any),
                    ),
                  ),
                  <Lozenge
                    key={`${documentPackage.id}-signature-status`}
                    // appearance={getSignatureStatusVariation(documentPackage.status!)}
                    appearance={documentPackageUtils.getSimpleStageVariation(
                      simpleStage!,
                    )}
                  >
                    {ts.followUpDocumentPackagesFilterStageLabel({
                      extra: { key: simpleStage },
                    })}
                    :{` ${signedCount}/${documentPackage.signers.length}`}
                  </Lozenge>,
                ],
                isSelected:
                  queryParams.documentPackageId === documentPackage.id,
                actions: compact([
                  {
                    content: ts.documentPackageViewAction(),
                    onClick: handleDocumentPackageClick,
                  },

                  !!contract && {
                    content: ts.followUpDocumentPackagesEditContractAction(),
                    url: getContractEditRoute(
                      contract,
                      documentPackage.propertyId!,
                    ),
                  },
                  canReleaseRentalGuarantee && {
                    content: ts.contractReleaseRentDepositAction(),
                    link: rs.createRentDepositReleaseRoute(
                      `${documentPackage.propertyId}`,
                      `${contract!.id}`,
                    ),
                  },
                  checkIfRevokable(documentPackage) && {
                    content: (
                      <TextStyle variation={"negative"}>
                        {ts.documentPackageRevokeAction()}
                      </TextStyle>
                    ),
                    onClick: handleRevokeClick,
                  },
                ]),
              };
            })}
            totalPages={pageCount}
          />
          {!!totalCount && pageCount > 1 && (
            <Pagination
              initialPage={queryParams.page ? queryParams.page - 1 : 0}
              pageCount={pageCount}
              onPageChange={handlePageClick}
            />
          )}
        </>
      );
    }

    if (fetchError) {
      return <p>{getLocalizedText("fetch.error")}</p>;
    }

    if (isFetching) {
      return <Loading />;
    }

    if (totalCount === 0 && !isFetching && hasActiveFilter) {
      return (
        <EmptyState heading={ts.emptyStateSearchHeading()} icon="archive">
          {ts.emptyStateSearchContent()}
        </EmptyState>
      );
    }

    return (
      <EmptyState
        heading={ts.followUpDocumentPackagesEmptyStateHeading()}
        icon="leaseContract"
      >
        {ts.followUpDocumentPackagesEmptyStateContent()}
      </EmptyState>
    );
  };

  return (
    <Page
      fullWidth
      title={ts.followUpDocumentPackagesPageTitle()}
      metadata={ts.followUpDocumentPackagesPageDescription()}
      breadcrumbs={{
        to: "/",
        content: getLocalizedText("system.back"),
      }}
      dropdown={[
        {
          content: ts.documentPackageCreateAction(),
          url: "/forms/document-package",
        },
      ]}
    >
      <Card>
        <Stages
          stages={[
            {
              heading: ts.followUpDocumentPackagesStageLabel({
                extra: { key: "pending" },
              }),
              icon: "contentPenWrite",
              count: documentPackagesPendingCount,
              onClick: () => {
                handleStageClick("pending");
              },
            },
            {
              heading: ts.followUpDocumentPackagesStageLabel({
                extra: { key: "finished" },
              }),
              icon: "archiveDrawer",
              count: documentPackagesFinishedCount,
              onClick: () => {
                handleStageClick("finished");
              },
            },
          ]}
        />
      </Card>
      <Card>
        {renderFilter()}
        {renderContent()}
      </Card>
    </Page>
  );
};
