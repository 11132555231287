import * as paymentRequestActions from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import { IRootStore } from "redux/reducers";
import { EField, IExtraData } from "./schema.manuallyMarkPaid.types";

export const isPerformingSubmitActionSelector =
  (extraData: IExtraData) => (state: IRootStore) => {
    const { paymentRequest } = extraData;

    return state.PAYMENT_REQUESTS_V2.markPaid[paymentRequest.id]?.isMarkingPaid;
  };

export const performingSubmitActionResultSelector =
  (extraData: IExtraData) => (state: IRootStore) => {
    return undefined;
  };

export const performingSubmitActionErrorSelector =
  (extraData: IExtraData) => (state: IRootStore) => {
    const { paymentRequest } = extraData;

    return state.PAYMENT_REQUESTS_V2.markPaid[paymentRequest.id]?.markPaidError;
  };

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { paymentRequest } = extraData;

    return paymentRequestActions.markPaidStart.getAction({
      id: paymentRequest.id,
      manuallyPaidAt: formData[EField.PaidAtDate],
      amount: formData[EField.Amount],
    });
  };
