import { jsx as _jsx } from "react/jsx-runtime";
import { getInitials } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as S from "./ProfilePicture.styled";
export var ProfilePicture = function (_a) {
    var profile = _a.profile, size = _a.size, variationProfile = _a.variationProfile;
    var pictureUrl = profile.pictureUrl;
    var initials = getInitials(profile);
    if (pictureUrl) {
        return _jsx("img", { src: pictureUrl, alt: initials });
    }
    return (_jsx(S.InitialsWrapper, { profile: profile, size: size, variationProfile: variationProfile, children: _jsx(S.InitialsContent, { children: initials }) }));
};
