import { EDocumentCategory } from "../../types/document.types";
import { EDocumentPackageTypes } from "../../types/documentPackage.types";
export var getDocumentCategoryId = function (params) {
    var documentPackageType = params.documentPackageType;
    switch (documentPackageType) {
        case EDocumentPackageTypes.Contract:
            return EDocumentCategory.Contract;
        case EDocumentPackageTypes.RentDeposit:
            return EDocumentCategory.RentDeposit;
        case EDocumentPackageTypes.RentDepositRelease:
            return EDocumentCategory.RentDepositRelease;
        case EDocumentPackageTypes.RentalAssignment:
            return EDocumentCategory.RentalAssignment;
        case EDocumentPackageTypes.Document:
        default:
            return EDocumentCategory.Other;
    }
};
