import { jsx as _jsx } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { DocumentFetchListItem } from "../../../../../../components";
import { DocumentIdsContext } from "../../../Editor/contexts/DocumentIds.context";
import { FieldsContext } from "../../../Editor/contexts/Fields.context";
import { LoopContext } from "../../../Editor/contexts/Loop.context";
import { VariablesDataContext } from "../../../Editor/contexts/VariablesData.context";
import * as S from "./ImageBlock.styles";
var ImageBlock = function (props) {
    var _a, _b, _c;
    var variableId = props.variableId, width = props.width, documentId = props.documentId, alignment = props.alignment;
    // Context
    var fields = React.useContext(FieldsContext).fields;
    var variablesData = React.useContext(VariablesDataContext).variablesData;
    var extraQueryParams = React.useContext(DocumentIdsContext).extraQueryParams;
    var variable = (_a = fields.variables) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === variableId; });
    var groupId = variable === null || variable === void 0 ? void 0 : variable.groupId;
    var group = (_b = fields.groups) === null || _b === void 0 ? void 0 : _b.find(function (x) { return x.id === groupId; });
    var loopConfig = React.useContext(LoopContext).loopConfig;
    // Render
    var Loading = (_jsx(S.FullActivityIndicatorWrap, { children: _jsx(S.FullActivityIndicator, {}) }));
    if (variableId) {
        if (!variable || !group) {
            return null;
        }
        var index = 0;
        if (loopConfig) {
            var isLoopedVariable = loopConfig.variableId === variableId || loopConfig.groupId === groupId;
            if (isLoopedVariable) {
                index = loopConfig.index;
            }
        }
        var variableData = (_c = variablesData[variableId]) === null || _c === void 0 ? void 0 : _c[index];
        if (variableData) {
            return (_jsx(DocumentFetchListItem, { documentId: typeof variableData === "string" ? variableData : undefined, extraQueryParams: extraQueryParams, renderLoading: function () { return Loading; }, renderError: function () { return _jsx("p", { children: "--" }); }, renderDocument: function (document) { return (_jsx(S.InnerWrap, { alignment: alignment, children: _jsx(S.ResizeableWrap, { width: width, asVariable: false, children: _jsx(S.Image, { draggable: false, src: document.url }) }) })); } }));
        }
        return (_jsx(S.InnerWrap, { alignment: alignment, children: _jsx(S.ResizeableWrap, { width: width, style: { height: 120 }, asVariable: true, children: _jsx(S.ImageVariablePlaceholder, { children: "".concat(getLocalizedText(variable.name), " (").concat(getLocalizedText(group.name), ")") }) }) }));
    }
    if (documentId) {
        return (_jsx(DocumentFetchListItem, { documentId: documentId, extraQueryParams: extraQueryParams, renderLoading: function () { return Loading; }, renderDocument: function (document) { return (_jsx(S.InnerWrap, { alignment: alignment, children: _jsx(S.ResizeableWrap, { width: width, asVariable: false, children: _jsx(S.Image, { draggable: false, src: document.url }) }) })); } }));
    }
    return null;
};
// eslint-disable-next-line import/no-default-export
export default ImageBlock;
