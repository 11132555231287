import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { TextStyle } from "@rentiohq/web-shared/dist/components";

interface IProps {
  contract: IContract;
}
export const ContractsAddress = (props: IProps) => {
  const { contract } = props;
  const { propertyAddress } = contract;

  if (!propertyAddress) {
    return null;
  }

  return (
    <div key={`${contract.id}-property`}>
      <div>{propertyAddress.line1}</div>
      <TextStyle variation={"subdued"}>{propertyAddress.line2}</TextStyle>
    </div>
  );
};
