import {
  IGroupActionContentBase,
  TGroupActionsLocale as TLocale,
} from "@rentiohq/shared-frontend/dist/types/groupAction.types";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EGroupActionModalStep,
  EGroupActionType,
} from "./GroupActionsModal.types";

export const getType = (type: string) => {
  switch (type) {
    case EGroupActionType.Sms:
      return "SMS";

    case EGroupActionType.Notification:
      return "notification";

    case EGroupActionType.Email:
      return "email";

    case EGroupActionType.Chat:
      return "chat";

    default:
      return "";
  }
};

export const getLocaleKey = (locale: ELocale) => {
  switch (locale) {
    case ELocale.NL:
      return "nl";
    case ELocale.FR:
      return "fr";
    case ELocale.EN:
      return "en";
    default:
      return "nl";
  }
};

const getContentLabelKey = (type: string, locale: ELocale) => {
  if (
    [EGroupActionType.Chat, EGroupActionType.Sms].includes(
      type as EGroupActionType,
    )
  ) {
    return `group_actions.modal.content.label.${getLocaleKey(locale)}`;
  }

  return "group_actions.modal.content.label";
};

export const getLocalizedGroupActionsContent = (
  type: string,
): Record<TLocale, IGroupActionContentBase> => {
  const lowerCaseType = type.toLowerCase();
  const localizedContent = (locale: TLocale): IGroupActionContentBase => ({
    key: `group_actions_modal_${lowerCaseType}_content_${locale}`,
    label: getLocalizedText(getContentLabelKey(type, locale)),
    value: getLocalizedText(
      `group_actions.modal.${lowerCaseType}.content.placeholder`,
      {},
      0,
      locale,
    ),
  });

  return {
    nl_BE: localizedContent(ELocale.NL),
    fr_FR: localizedContent(ELocale.FR),
    en_UK: localizedContent(ELocale.EN),
  };
};

export const stepsForType: Record<EGroupActionType, EGroupActionModalStep[]> = {
  [EGroupActionType.Notification]: [
    EGroupActionModalStep.SelectAction,
    EGroupActionModalStep.SelectMembers,
    EGroupActionModalStep.AddContent,
  ],
  [EGroupActionType.Sms]: [
    EGroupActionModalStep.SelectAction,
    EGroupActionModalStep.SelectMembers,
    EGroupActionModalStep.AddContent,
  ],
  [EGroupActionType.Email]: [
    EGroupActionModalStep.SelectAction,
    EGroupActionModalStep.SelectMembers,
    EGroupActionModalStep.AddContent,
  ],
  [EGroupActionType.Task]: [EGroupActionModalStep.SelectAction],
  [EGroupActionType.Document]: [
    EGroupActionModalStep.SelectAction,
    EGroupActionModalStep.SelectDocument,
    EGroupActionModalStep.SelectMembers,
  ],
  [EGroupActionType.Chat]: [
    EGroupActionModalStep.SelectAction,
    EGroupActionModalStep.SelectMembers,
    EGroupActionModalStep.AddContent,
  ],
};
