import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import * as featureFlagSelectors from "../../../redux/featureFlag/featureFlag.selectors";
var FeatureFlag = function (props) {
    var enabled = useSelector(function (state) {
        return featureFlagSelectors.getFlag(state, props.flagKey, props.defaultValue);
    });
    return enabled ? _jsx(_Fragment, { children: props.children }) : null;
};
// eslint-disable-next-line import/no-default-export
export default FeatureFlag;
