import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EProduct } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import "draft-js/dist/Draft.css";
import React from "react";
import { TextStyle } from "../../../../../../components/TextStyle";
import { useInternalMode } from "../../../../../../redux/system/system.hooks";
import { FieldsContext } from "../../../Editor/contexts/Fields.context";
import { showContentDate, showContentNumber, showContentText, } from "../../decorators/ConditionRenderer/condition.utils";
import { DefaultVariableLozenge } from "../DefaultVariableLozenge";
var ConditionInfo = function (props) {
    var _a;
    var condition = props.condition, variable = props.variable;
    var internalModeEnabled = useInternalMode().internalModeEnabled;
    // Context
    var fields = React.useContext(FieldsContext).fields;
    var groupId = variable.groupId;
    var group = (_a = fields.groups) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === groupId; });
    // Render
    return (_jsxs(_Fragment, { children: [_jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.variable.info_variable") }), _jsxs("div", { children: [getLocalizedText((group === null || group === void 0 ? void 0 : group.name) || "-"), " -", " ", getLocalizedText(variable.name)] }), _jsx(Spacer, { weight: ESpacerWeight.W12 }), (CONFIG.PRODUCT === EProduct.Backoffice || internalModeEnabled) && (_jsxs(_Fragment, { children: [_jsx(TextStyle, { variation: "subdued", children: "Variable ID (Used for internal mapping to values)" }), _jsxs("div", { children: [variable.id, " ", _jsx(DefaultVariableLozenge, { variable: variable })] }), _jsx(Spacer, { weight: ESpacerWeight.W12 })] })), _jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.insert_custom.insert_condition.field.type") }), _jsx("div", { children: getLocalizedText("editor.condition.type.".concat(stringToSnakeCase(condition.type))) }), showContentText(condition.type) && condition.contentText && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.insert_custom.insert_condition.field.content_text") }), _jsx("div", { children: condition.contentText })] })), showContentNumber(condition.type) && condition.contentNumber && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.insert_custom.insert_condition.field.content_number") }), _jsx("div", { children: condition.contentNumber })] })), showContentDate(condition.type) && condition.contentDate && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.insert_custom.insert_condition.field.content_date") }), _jsx("div", { children: formatDate(condition.contentDate) })] }))] }));
};
// eslint-disable-next-line import/no-default-export
export default ConditionInfo;
