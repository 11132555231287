var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from "react";
import defaultConfig from "../config";
import useLocalStorage from "../hooks/useLocalStorage";
// initial state
var initialState = __assign(__assign({}, defaultConfig), { onChangeMenuOrientation: function () { }, onChangeMiniDrawer: function () { }, onChangeMode: function () { }, onChangePresetColor: function () { }, onChangeLocale: function () { }, onChangeDirection: function () { }, onChangeContainer: function () { }, onChangeFontFamily: function () { }, onChangeBorderRadius: function () { }, onChangeOutlinedField: function () { }, onReset: function () { } });
// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //
var ConfigContext = createContext(initialState);
function ConfigProvider(_a) {
    var children = _a.children;
    var _b = useLocalStorage("berry-config-vite-ts", {
        menuOrientation: initialState.menuOrientation,
        miniDrawer: initialState.miniDrawer,
        fontFamily: initialState.fontFamily,
        borderRadius: initialState.borderRadius,
        outlinedFilled: initialState.outlinedFilled,
        mode: initialState.mode,
        presetColor: initialState.presetColor,
        i18n: initialState.i18n,
        themeDirection: initialState.themeDirection,
        container: initialState.container,
    }), config = _b[0], setConfig = _b[1];
    var onChangeMenuOrientation = function (menuOrientation) {
        setConfig(__assign(__assign({}, config), { menuOrientation: menuOrientation }));
    };
    var onChangeMiniDrawer = function (miniDrawer) {
        setConfig(__assign(__assign({}, config), { miniDrawer: miniDrawer }));
    };
    var onChangeMode = function (mode) {
        setConfig(__assign(__assign({}, config), { mode: mode }));
    };
    var onChangePresetColor = function (presetColor) {
        setConfig(__assign(__assign({}, config), { presetColor: presetColor }));
    };
    var onChangeLocale = function (i18n) {
        setConfig(__assign(__assign({}, config), { i18n: i18n }));
    };
    var onChangeDirection = function (themeDirection) {
        setConfig(__assign(__assign({}, config), { themeDirection: themeDirection }));
    };
    var onChangeContainer = function (container) {
        setConfig(__assign(__assign({}, config), { container: container }));
    };
    var onChangeFontFamily = function (fontFamily) {
        setConfig(__assign(__assign({}, config), { fontFamily: fontFamily }));
    };
    var onChangeBorderRadius = function (event, newValue) {
        setConfig(__assign(__assign({}, config), { borderRadius: newValue }));
    };
    var onChangeOutlinedField = function (outlinedFilled) {
        setConfig(__assign(__assign({}, config), { outlinedFilled: outlinedFilled }));
    };
    var onReset = function () {
        setConfig(__assign({}, defaultConfig));
    };
    return (_jsx(ConfigContext.Provider, { value: __assign(__assign({}, config), { onChangeMenuOrientation: onChangeMenuOrientation, onChangeMiniDrawer: onChangeMiniDrawer, onChangeMode: onChangeMode, onChangePresetColor: onChangePresetColor, onChangeLocale: onChangeLocale, onChangeDirection: onChangeDirection, onChangeContainer: onChangeContainer, onChangeFontFamily: onChangeFontFamily, onChangeBorderRadius: onChangeBorderRadius, onChangeOutlinedField: onChangeOutlinedField, onReset: onReset }), children: children }));
}
export { ConfigContext, ConfigProvider };
