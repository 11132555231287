var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer } from "redux-act";
import { getRequirements } from "./requirements.actions";
export var REDUCER_KEY_REQUIREMENTS = "requirements";
var getDefaultState = function () { return ({
    isFetching: false,
    fetchError: undefined,
    requirements: undefined,
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on(getRequirements.types.START, function (state) { return (__assign(__assign({}, state), { isFetching: true, fetchError: undefined })); });
reducer.on(getRequirements.types.SUCCESS, function (state, _a) {
    var requirements = _a.requirements;
    return (__assign(__assign({}, state), { isFetching: false, fetchError: undefined, requirements: requirements }));
});
reducer.on(getRequirements.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isFetching: false, fetchError: error }));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
