import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { FormValidation } from "react-native-jsonschema-form-test";
import { EField, IExtraData } from "./schema.moveRequestUpdate.types";

const NOW = new Date();

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [],
      properties: {
        [EField.WaterMeterReading]: {
          default: getValue(
            EField.WaterMeterReading,
            initialValues,
            defaultValues,
          ),
        },
        [EField.WaterMeterReadingDate]: {
          default: getValue(
            EField.WaterMeterReadingDate,
            initialValues,
            defaultValues,
          ),
        },
        [EField.HandoverDocuments]: {
          default: getValue(
            EField.HandoverDocuments,
            initialValues,
            defaultValues,
          ),
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData): IUiSchema<EField> => ({
  "ui:field": ERentioFormField.Grid,
  grid: {
    row: [
      {
        col: {
          width: [1, 1 / 2],
          children: [EField.WaterMeterReading],
        },
      },
      {
        col: {
          width: [1, 1 / 2],
          children: [EField.WaterMeterReadingDate],
        },
      },
      {
        col: {
          width: 1,
          children: [EField.HandoverDocuments],
        },
      },
    ],
  },
  [EField.WaterMeterReading]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("move.field.water_meter_reading.title"),
    optional: true,
  },
  [EField.WaterMeterReadingDate]: {
    "ui:field": ERentioFormField.Date,
    title: getLocalizedText("move.field.water_meter_reading.title"),
    maxDate: NOW,
    // optional: true,
  },
  [EField.HandoverDocuments]: {
    "ui:field": ERentioFormField.DocumentSelectInline,
    title: getLocalizedText("move.field.water_handover_documents.title"),
    optional: true,
    multiple: true,
    accept: ["application/*", "image/*"],
  },
});

export const getValidate =
  (_: IExtraData) => (formData: any, errors: FormValidation) => {
    [
      {
        meterReadingKey: EField.WaterMeterReading,
        meterReadingDateKey: EField.WaterMeterReadingDate,
      },
    ].forEach(value => {
      const { meterReadingKey, meterReadingDateKey } = value;

      const meterReading = formData[meterReadingKey] || "";
      const meterReadingDate = formData[meterReadingDateKey] || "";

      if (meterReading === "" && meterReadingDate !== "") {
        errors[meterReadingKey].addError(
          getLocalizedText("move.field.error.meter_reading.required"),
        );
      }

      if (meterReading !== "" && meterReadingDate === "") {
        errors[meterReadingDateKey].addError(
          getLocalizedText("move.field.error.meter_reading_date.required"),
        );
      }
    });

    return errors;
  };
