import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.tenantProfileBroker.types";

const INTAKE_TYPE_OPTIONS = [
  { label: "intake_type_1", id: 1 },
  { label: "intake_type_2", id: 2 },
  { label: "intake_type_3", id: 3 },
];

export const getSchema =
  (initialValues: { [key: string]: any }) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => ({
    required: [EField.IntakeMail, EField.IntakeType],
    properties: {
      [EField.IntakeType]: {
        default: getValue(EField.IntakeType, initialValues, defaultValues),
        //@ts-ignore
        options: INTAKE_TYPE_OPTIONS.map(type => ({
          id: type.id,
          value: getLocalizedText(`broker.intakeType.${type.id}`),
        })),
      },
      [EField.IntakeMail]: {
        default: getValue(EField.IntakeMail, initialValues, defaultValues),
      },
    },
  });

export const uiSchema = (_extraData: IExtraData): IUiSchema<EField> => ({
  [EField.IntakeType]: {
    "ui:field": ERentioFormField.DropdownSelect,
    title: getLocalizedText("broker.tenant_profile.intake_type.label"),
  },
  [EField.IntakeMail]: {
    "ui:field": ERentioFormField.Email,
    title: getLocalizedText("broker.tenant_profile.intake_email.label"),
  },
});
