import { ETemplateDocumentStatus } from "../../types/contract.types";
import { EPaymentOrderOwnerPaymentMethod } from "../../types/payment.types";
import { appendWhere } from "../../utils/api.utils";
export var getCountIdentifierContractsForProperty = function (propertyId) {
    return "contracts-for-property-".concat(propertyId);
};
export var getActionPayloadContractsForProperty = function (propertyId) {
    return {
        countIdentifier: getCountIdentifierContractsForProperty(propertyId),
        countBase: appendWhere("/contracts/count", {
            propertyId: propertyId,
        }),
    };
};
export var getCountIdentifierPaymentOrdersForProperty = function (propertyId) { return "paymentOrders-for-property-active-".concat(propertyId); };
export var getActionPayloadPaymentOrdersForProperty = function (propertyId) {
    return {
        countIdentifier: getCountIdentifierPaymentOrdersForProperty(propertyId),
        countBase: appendWhere("/payment-orders/count", {
            propertyId: propertyId,
            completedAt: null,
        }),
    };
};
export var getCountIdentifierActivePaymentOrdersForProperty = function (propertyId) { return "active-paymentOrders-for-property-".concat(propertyId); };
export var getActionPayloadActivePaymentOrdersForProperty = function (propertyId) {
    return {
        countIdentifier: getCountIdentifierActivePaymentOrdersForProperty(propertyId),
        countBase: appendWhere("/payment-orders/count", {
            propertyId: propertyId,
            completedAt: null,
        }),
    };
};
export var getCountIdentifierActiveFromRentPaymentOrdersForProperty = function (propertyId) { return "active-from-rent-paymentOrders-for-property-".concat(propertyId); };
export var getActionPayloadActiveFromRentPaymentOrdersForProperty = function (propertyId) {
    return {
        countIdentifier: getCountIdentifierActiveFromRentPaymentOrdersForProperty(propertyId),
        countBase: appendWhere("/payment-orders/count", {
            propertyId: propertyId,
            completedAt: null,
            ownerPaymentMethod: EPaymentOrderOwnerPaymentMethod.FromRent,
        }),
    };
};
export var getCountIdentifierInactivePaymentOrdersForProperty = function (propertyId) { return "inactive-paymentOrders-for-property-".concat(propertyId); };
export var getActionPayloadInactivePaymentOrdersForProperty = function (propertyId) {
    return {
        countIdentifier: getCountIdentifierInactivePaymentOrdersForProperty(propertyId),
        countBase: appendWhere("/payment-orders/count", {
            propertyId: propertyId,
            completedAt: { neq: null },
        }),
    };
};
export var getCountIdentifierTemplateDocumentsForProperty = function (propertyId) { return "template-documents-for-property-".concat(propertyId); };
export var getActionPayloadTemplateDocumentsForProperty = function (propertyId) {
    return {
        countIdentifier: getCountIdentifierTemplateDocumentsForProperty(propertyId),
        countBase: appendWhere("/template-documents/count", {
            propertyId: propertyId,
            status: {
                inq: [ETemplateDocumentStatus.New, ETemplateDocumentStatus.Failed],
            },
            archivedAt: { eq: null },
        }),
    };
};
export var getCountIdentifierDocumentsForTask = function (taskId) {
    return "documents-for-task-".concat(taskId);
};
export var getActionPayloadDocumentsForTask = function (taskId) {
    return {
        countIdentifier: getCountIdentifierDocumentsForTask(taskId),
        countBase: appendWhere("/documents/count", {
            taskId: taskId,
        }),
    };
};
export var getCountIdentifierPropertiesInGroup = function (parentId) {
    return "properties-for-group-".concat(parentId);
};
