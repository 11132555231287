import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { EContractType, } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { endOfDay, isAfter, } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Error, Stack } from "../..";
import { Card, DisplayText, ESpacings, Grid, Icon, Loading, TextStyle, } from "../../";
import utils from "../../../utils";
import { Lozenge } from "../../Lozenge";
var statusToAppearanceMap = {
    draft: "info",
    passed: "default",
    active: "success",
};
export var getIconForContractType = function (type) {
    switch (true) {
        case type === EContractType.RentDeposit:
        case type === EContractType.RentDepositRelease:
            return "rentDeposit";
        case type === EContractType.Basic:
        default:
            return "leaseContract";
    }
};
var getContractDates = function (contract) {
    return "".concat(utils.date.format(contract.startDate), " - ").concat(contract.stopDate
        ? utils.date.format(contract.stopDate)
        : getLocalizedText("contract.indefinite"));
};
export var getContractStatus = function (contract) {
    if (contract.stopDate &&
        isAfter(endOfDay(new Date()), endOfDay(contract.stopDate))) {
        return "passed";
    }
    if (contract.signedAt !== null) {
        return "active";
    }
    return "draft";
};
export var ContractCard = function (props) {
    var passedContract = props.contract, contractId = props.contractId;
    var _a = contractHooks.useDetail({ id: contractId }), contractFromStore = _a.detail, isFetching = _a.isFetching, fetchError = _a.fetchError;
    // Render
    var contract = contractFromStore || passedContract;
    return (_jsxs(Card, { heading: _jsx(DisplayText, { size: "extraSmall", space: "tight", children: getLocalizedText("system.model.contract") }), space: ESpacings.loose, actions: contract
            ? [
                {
                    content: getLocalizedText("contract.view_contract.action"),
                    url: "/properties/".concat(contract.propertyId, "/contracts"),
                },
            ]
            : undefined, children: [!contract && isFetching && _jsx(Loading, {}), !contract && fetchError && _jsx(Error, { errors: [fetchError] }), contract && (_jsx(_Fragment, { children: _jsxs(Grid, { alignItems: "center", children: [_jsx(Grid.Item, { children: _jsx(Icon, { source: getIconForContractType(contract.contractType || EContractType.Basic), size: "large" }) }), _jsxs(Grid.Item, { children: [_jsx("div", { children: _jsxs(Stack, { alignment: "center", spacing: "extraTight", children: [_jsx(TextStyle, { children: utils.contract.getDisplayName(contract) }), _jsx(Lozenge, { appearance: statusToAppearanceMap[getContractStatus(contract)], children: getLocalizedText("contract.status.".concat(getContractStatus(contract))) })] }) }), _jsx(TextStyle, { variation: "subdued", element: "div", children: getContractDates(contract) })] })] }) }))] }));
};
