import { alpha } from "@mui/material/styles";
import { getFontSize } from "../themes";
import { ThemeMode } from "../utils/types/config";
import { EFontSizes, EFontWeights } from "./types";
var Typography = function (theme, borderRadius, fontFamily) {
    var isDarkMode = theme.palette.mode === ThemeMode.DARK;
    var primaryText = theme.palette.text.primary; // #364152
    var secondaryText = theme.palette.text.secondary; // #697586
    var lightDarkText = theme.palette.text.lightDark; // #4b5565
    var darkText = theme.palette.text.dark; // #121926
    var primaryText50 = alpha(primaryText, 0.5); // with 50% opacity
    return {
        fontFamily: fontFamily,
        h1: {
            fontSize: getFontSize(EFontSizes.UltraLarge),
            fontWeight: EFontWeights.ExtraBold,
            color: isDarkMode ? lightDarkText : darkText,
        },
        h2: {
            fontSize: getFontSize(EFontSizes.SuperLarge),
            fontWeight: EFontWeights.ExtraBold,
            color: isDarkMode ? lightDarkText : darkText,
        },
        h3: {
            fontSize: getFontSize(EFontSizes.ExtraExtraLarge),
            fontWeight: EFontWeights.ExtraBold,
            color: isDarkMode ? lightDarkText : darkText,
        },
        h4: {
            fontSize: getFontSize(EFontSizes.Medium),
            fontWeight: EFontWeights.Medium,
            color: isDarkMode ? lightDarkText : secondaryText,
        },
        h5: {
            fontSize: getFontSize(EFontSizes.Normal),
            fontWeight: EFontWeights.Medium,
            color: isDarkMode ? lightDarkText : secondaryText,
        },
        h6: {
            fontSize: getFontSize(EFontSizes.Small),
            fontWeight: EFontWeights.Medium,
            color: isDarkMode ? lightDarkText : primaryText50,
        },
        subtitle1: {
            fontSize: getFontSize(EFontSizes.Small),
            fontWeight: EFontWeights.Medium,
            color: lightDarkText,
        },
        subtitle2: {
            fontSize: getFontSize(EFontSizes.Small),
            fontWeight: EFontWeights.Medium,
            color: isDarkMode ? lightDarkText : secondaryText,
        },
        body1: {
            fontSize: getFontSize(EFontSizes.Normal),
            fontWeight: EFontWeights.Regular,
            color: isDarkMode ? lightDarkText : primaryText50,
        },
        body2: {
            fontSize: getFontSize(EFontSizes.Small),
            fontWeight: EFontWeights.Regular,
            color: isDarkMode ? lightDarkText : primaryText50,
        },
        caption: {
            fontSize: getFontSize(EFontSizes.ExtraSmall),
            fontWeight: EFontWeights.Medium,
            color: isDarkMode ? lightDarkText : primaryText50,
        },
        button: {
            textTransform: "capitalize",
        },
        customInput: {
            marginTop: 1,
            marginBottom: 1,
            "& > label": {
                top: 23,
                left: 0,
                color: theme.palette.grey[500],
                '&[data-shrink="false"]': {
                    top: 5,
                },
            },
            "& > div > input": {
                padding: "30.5px 14px 11.5px !important",
            },
            "& legend": {
                display: "none",
            },
            "& fieldset": {
                top: 0,
            },
        },
        mainContent: {
            backgroundColor: theme.palette.mode === ThemeMode.DARK
                ? theme.palette.dark[800]
                : theme.palette.grey[100],
            width: "100%",
            minHeight: "calc(100vh - 78px)",
            flexGrow: 1,
            padding: "20px",
            marginTop: "78px",
            marginRight: "20px",
            borderRadius: "".concat(borderRadius, "px"),
        },
        menuCaption: {
            fontSize: "0.875rem",
            fontWeight: 500,
            color: theme.palette.mode === ThemeMode.DARK
                ? theme.palette.grey[600]
                : theme.palette.grey[900],
            padding: "6px",
            textTransform: "capitalize",
            marginTop: "10px",
        },
        subMenuCaption: {
            fontSize: "0.6875rem",
            fontWeight: 500,
            color: theme.palette.text.secondary,
            textTransform: "capitalize",
        },
        commonAvatar: {
            cursor: "pointer",
            borderRadius: "8px",
        },
        smallAvatar: {
            width: "22px",
            height: "22px",
            fontSize: "1rem",
        },
        mediumAvatar: {
            width: "34px",
            height: "34px",
            fontSize: "1.2rem",
        },
        largeAvatar: {
            width: "44px",
            height: "44px",
            fontSize: "1.5rem",
        },
    };
};
// eslint-disable-next-line import/no-default-export
export default Typography;
// For all 10px use caption variant - apply opacity where needed
