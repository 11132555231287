import { getSchema as getSchema1, uiSchema as uiSchema1, } from "./schema.addIban.1";
import { EField } from "./schema.addIban.types";
// eslint-disable-next-line import/no-default-export
export default (function (extraData) {
    var _a;
    var _b;
    if (extraData === void 0) { extraData = {}; }
    return [
        {
            schema: getSchema1(extraData, (_a = {},
                _a[EField.OwnerName] = extraData.ownerName,
                _a[EField.Iban] = (_b = extraData.iban) !== null && _b !== void 0 ? _b : "BE",
                _a)),
            uiSchema: uiSchema1(extraData),
        },
    ];
});
