import { getAccountContactInfo } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.api";
import {
  IAccount,
  IMember,
} from "@rentiohq/shared-frontend/dist/types/auth.types";
import { EContactInitialType } from "@rentiohq/shared-frontend/dist/types/contact.types";
import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  isAfter,
  isBefore,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  AccountList,
  Card,
  DisplayText,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import uniqBy from "lodash/uniqBy";

const NOW = new Date();

interface IProps {
  property: IProperty;
}

const PropertyTenantsCard = (props: IProps) => {
  const { property } = props;

  const allTenantMembers = property.members.filter(member =>
    member.roles.includes(EPropertyMemberTypes.Tenant),
  );

  const allTenantAccounts = allTenantMembers.map(tenant => tenant.account);

  // Helpers
  const getDurationInfo = (member: IMember<any>) => {
    const { contractInfo } = member;
    if (!contractInfo) {
      return;
    }

    const { from, to } = contractInfo;
    if (!to) {
      return;
    }

    // Is future tenant
    if (isBefore(NOW, from)) {
      return getLocalizedText("property.tenant_info.future", {
        from: formatDate(from),
        to: formatDate(to),
      });
    }

    // Is past tenant
    if (isAfter(NOW, to)) {
      return getLocalizedText("property.tenant_info.past", {
        from: formatDate(from),
        to: formatDate(to),
      });
    }

    // Is current tenant
    return getLocalizedText("property.tenant_info.active", {
      from: formatDate(from),
      to: formatDate(to),
    });
  };

  // Render
  const renderCard = (params: { title: string; accounts: IAccount[] }) => {
    const { title, accounts } = params;

    return (
      <Card
        heading={
          <DisplayText size="extraSmall" space="tight">
            {title}
          </DisplayText>
        }
        space={ESpacings.loose}
        actions={[]}
      >
        <AccountList
          accounts={accounts}
          getContactInfoForAccount={async (account: IAccount) =>
            (
              await getAccountContactInfo({
                propertyId: property.id,
                accountId: account.id,
              })
            ).data.data
          }
          initialContactType={EContactInitialType.Tenant}
          getSubtitle={(accountIds: number[]) => {
            const member = allTenantMembers.find(member =>
              accountIds.includes(member.account.id),
            );
            if (!member) {
              return;
            }

            return getDurationInfo(member);
          }}
          mediaSize="medium"
          boxProps={{ py: 3 }}
        />
      </Card>
    );
  };

  const tenants = uniqBy(allTenantAccounts, "id").filter(account =>
    account.roles ? account.roles.includes(EPropertyMemberTypes.Tenant) : true,
  );
  const parents = uniqBy(allTenantAccounts, "id").filter(
    account => account.roles?.includes(EPropertyMemberTypes.Parent),
  );

  return (
    <>
      {tenants.length > 0 &&
        renderCard({
          title: getLocalizedText("property.dashboard.tenants.heading"),
          accounts: tenants,
        })}

      {parents.length > 0 &&
        renderCard({
          title: getLocalizedText("property.dashboard.parents.heading"),
          accounts: parents,
        })}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default PropertyTenantsCard;
