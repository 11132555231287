import {
  EGroupActionType,
  IGroupActionContent,
  IGroupActionContentBase,
  TGroupActionsLocale,
} from "@rentiohq/shared-frontend/dist/types/groupAction.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { DisplayText } from "@rentiohq/web-shared/dist/components";
import React from "react";
import { getType } from "../GroupActions.util";
import AddGroupActionContentSection from "./AddGroupActionContentSection";

export interface IAddGroupActionContentProps {
  type: EGroupActionType;
  initialContents: IGroupActionContent;
  onChange: (content: Record<TGroupActionsLocale, string>) => void;
  onChangeError: (error?: string) => void;
  onChangeTitle?: (key: TGroupActionsLocale, value?: string) => void;
  onChangeSubject?: (key: TGroupActionsLocale, value?: string) => void;
}

const AddGroupActionContent = (props: IAddGroupActionContentProps) => {
  const {
    type,
    initialContents,
    onChange,
    onChangeError,
    onChangeTitle,
    onChangeSubject,
  } = props;

  // States
  const [contents, setContents] =
    React.useState<IGroupActionContent>(initialContents);

  // Effects
  React.useEffect(() => {
    onChange({
      nl_BE: contents.nl_BE.value,
      fr_FR: contents.fr_FR.value,
      en_UK: contents.en_UK.value,
    });
  }, [contents]);

  // Events
  const handleContentChange = (locale: TGroupActionsLocale, value: string) => {
    setContents(prevContents => ({
      ...prevContents,
      [locale]: {
        ...prevContents[locale],
        value,
      },
    }));
  };

  // Renders
  const renderContent = (
    [key, content]: [string, IGroupActionContentBase],
    index: number,
  ) => (
    <AddGroupActionContentSection
      key={key}
      locale={key as TGroupActionsLocale}
      index={index}
      type={type}
      initialContent={content}
      onChange={(updatedContent: IGroupActionContentBase) => {
        handleContentChange(key as TGroupActionsLocale, updatedContent.value);
      }}
      onChangeTitle={onChangeTitle}
      onChangeSubject={onChangeSubject}
      onChangeError={onChangeError}
    />
  );

  return (
    <>
      <DisplayText size="medium">
        {getLocalizedText("group_actions.modal.content.title", {
          type: getType(type),
        })}
      </DisplayText>

      {Object.entries(contents).map(renderContent)}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default AddGroupActionContent;
