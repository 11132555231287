import { Box } from "@rebass/grid";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { IMember } from "@rentiohq/shared-frontend/dist/types/auth.types";
import {
  EContactInitialType,
  IContact,
} from "@rentiohq/shared-frontend/dist/types/contact.types";
import { EPropertyMemberTypes } from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  Button,
  ContactSearch,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { ts } from "../../../../../../services";

export interface IManagersInputProps {
  showActions?: boolean;
  hasManagers: boolean;
  propertyAdmins: IMember<EPropertyMemberTypes>[];
  toggleInput: () => void;
}

export const ManagersInput = (props: IManagersInputProps) => {
  const { showActions, hasManagers, propertyAdmins, toggleInput } = props;

  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const { updateMembers } = propertyHooks.useUpdate({
    id: propertyId,
  });

  const addManager = async (contact: IContact) => {
    try {
      if (!propertyId) {
        return;
      }

      updateMembers({
        membersToAdd: [
          {
            accountId: contact.accountId,
            roles: [
              EPropertyMemberTypes.FinancialManager,
              EPropertyMemberTypes.TechnicalManager,
            ],
          },
        ],
        membersToRemove: [],
      });

      toggleInput();
    } catch (unknownError) {
      const error = unknownError as any;

      throw new Error(error);
    }
  };

  return (
    <Box
      mt={hasManagers ? ESpacings.base : ESpacings.none}
      mb={!hasManagers ? ESpacings.base : ESpacings.none}
    >
      {showActions ? (
        <>
          <ContactSearch
            onContactSelect={addManager}
            disallowedAccountIds={propertyAdmins.map(
              admin => admin.account.id!,
            )}
            initialType={EContactInitialType.Manager}
          />
          {!isEmpty(propertyAdmins) && (
            <Box mt={1}>
              <Button appearance="link" onClick={toggleInput}>
                {ts.system("cancel")}
              </Button>
            </Box>
          )}
        </>
      ) : (
        <p>{ts.propertyDashboardEmptyContent()}</p>
      )}
    </Box>
  );
};
