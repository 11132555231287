import { IRegistration } from "@rentiohq/shared-frontend/dist/types/registration.types";
import {
  Activities,
  Card,
  DisplayText,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { ts } from "../../../../../../services";
import { ERegistrationTab } from "../../Registration.types";
import { getActivitiesFilter } from "../../Registration.utils";

interface IProps {
  registration: IRegistration;
  setTab: (tab: ERegistrationTab) => void;
}

const RegistrationHistory: React.FC<IProps> = props => {
  const { registration } = props;

  return (
    <Card
      space={ESpacings.base}
      heading={
        <DisplayText size="extraSmall" space="tight">
          {ts.system("history")}
        </DisplayText>
      }
    >
      <Activities
        identifier={`registration-${registration.id}`}
        filter={getActivitiesFilter(registration)}
        hasFetchMore={true}
        isCompact={true}
      />
    </Card>
  );
};

// eslint-disable-next-line import/no-default-export
export default RegistrationHistory;
