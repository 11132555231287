import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { Button, Grid, Icon } from "..";
import * as t from "../../services/translationService";
export var ContactDetails = function (props) {
    var contact = props.contact;
    var handleEmailContact = function () {
        if (contact.email) {
            return;
        }
        window.location.href = "mailto:".concat(contact.email);
    };
    var handleCallContact = function () {
        if (contact.phone) {
            return;
        }
        window.location.href = "tel:".concat(contact.phone);
    };
    return (_jsxs(_Fragment, { children: [contact.phone && (_jsx(Box, { mb: 1, children: _jsxs(Grid, { spacing: "extraTight", alignItems: "center", children: [_jsx(Grid.Item, { alignItems: "center", children: _jsx(Icon, { source: "smartphone", size: "small" }) }), _jsx(Grid.Item, { children: _jsx(Button, { appearance: "reset", onClick: handleCallContact, children: formatPhone(contact.phone) }) })] }) })), contact.email && (_jsx(Box, { mb: 1, children: _jsxs(Grid, { spacing: "extraTight", alignItems: "center", children: [_jsx(Grid.Item, { children: _jsx(Icon, { source: "email", size: "small" }) }), _jsx(Grid.Item, { children: _jsx(Button, { appearance: "reset", onClick: handleEmailContact, children: contact.email }) })] }) })), contact.locale && (_jsxs(Grid, { spacing: "extraTight", alignItems: "center", children: [_jsx(Grid.Item, { alignItems: "center", children: _jsx(Icon, { source: "locale", size: "small" }) }), _jsx(Grid.Item, { children: t.locale(contact.locale) })] }))] }));
};
