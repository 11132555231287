var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as propertyActionsV2 from "@rentiohq/shared-frontend/dist/reduxV2/property/property.actions";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Icon, TextStyle } from "..";
import * as t from "../../services/translationService";
import utils from "../../utils";
import { AutocompleteField } from "../AutocompleteField";
import { Label } from "../Labelled/Labelled.styled";
import { DefaultPropertyListItemRender, PropertyList } from "../PropertyList";
export var PropertySelect = function (_a) {
    var _b = _a.properties, properties = _b === void 0 ? [] : _b, onPropertyAdd = _a.onPropertyAdd, onPropertyRemove = _a.onPropertyRemove, _c = _a.showPropertyList, showPropertyList = _c === void 0 ? true : _c, hideForm = _a.hideForm, customLabel = _a.label, placeholder = _a.placeholder, error = _a.error, disabled = _a.disabled, maxItems = _a.maxItems, optional = _a.optional, _d = _a.customFilter, customFilter = _d === void 0 ? {} : _d;
    var dispatch = useDispatch();
    var _e = useState(false), isFetchingProperties = _e[0], setIsFetchingProperties = _e[1];
    var label = customLabel || t.propertySelectSearchLabel();
    var filterSelectedProperties = function (property) {
        return !properties.find(function (selectedProperty) { return selectedProperty.id === property.id; });
    };
    var fetchProperties = function (searchQuery) {
        setIsFetchingProperties(true);
        return new Promise(function (resolve, reject) {
            dispatch(propertyActionsV2.getAllWhereStart.getAction({
                query: {
                    filter: __assign(__assign({}, customFilter), { archivedAt: { is: null } }),
                    search: searchQuery,
                },
                onSuccess: function (result) {
                    setIsFetchingProperties(false);
                    if (result.data.items.length > 0) {
                        resolve(result.data.items);
                    }
                    else {
                        return [];
                    }
                },
                onFailure: function (error) {
                    setIsFetchingProperties(false);
                    reject([]);
                },
            }));
        });
    };
    var searchProperties = AwesomeDebouncePromise(fetchProperties, 350);
    var handleSearch = function (query) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!query || query.length === 0) {
                        return [2 /*return*/, []];
                    }
                    return [4 /*yield*/, searchProperties(query)];
                case 1:
                    data = _a.sent();
                    try {
                        return [2 /*return*/, data
                                .filter(filterSelectedProperties)
                                .map(function (property) { return ({
                                data: property,
                                content: _jsx(DefaultPropertyListItemRender, { property: property }),
                                media: property.typeId ? (_jsx(Icon, { source: utils.properties.getIcon(property.typeId) })) : undefined,
                            }); })];
                    }
                    catch (err) { }
                    return [2 /*return*/, []];
            }
        });
    }); };
    var handleAutocompleteChange = function (property) {
        onPropertyAdd === null || onPropertyAdd === void 0 ? void 0 : onPropertyAdd(property);
    };
    var propertyActions = [
        {
            media: _jsx(Icon, { source: "bin", color: "red" }),
            content: (_jsx(TextStyle, { variation: "negative", children: t.propertySelectRemoveProperty() })),
            onClick: function (data) {
                onPropertyRemove === null || onPropertyRemove === void 0 ? void 0 : onPropertyRemove(data);
            },
        },
    ];
    var showForm = hideForm
        ? !hideForm
        : !maxItems || (maxItems && properties.length < maxItems);
    return (_jsxs("div", { children: [showPropertyList && properties.length > 0 && (_jsxs(Box, { mb: 3, children: [_jsx(Label, { children: label }), _jsx(Spacer, { weight: ESpacerWeight.W08 }), _jsx(PropertyList, { properties: properties, actions: propertyActions, readOnly: hideForm })] })), showForm && (_jsx(AutocompleteField, { clearAfterChange: true, label: label, name: "property-contact-search", loadOptions: handleSearch, onChange: handleAutocompleteChange, error: error, disabled: disabled, placeholder: placeholder || t.propertySelectSearchPlaceholder(), optional: optional, isLoading: isFetchingProperties }))] }));
};
