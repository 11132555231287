import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { NumberParam, StringParam } from "serialize-query-params";
import { EAssets } from "../../assets";
import { Icon } from "../Icon";
import { DrawerStyles } from "./Drawer.styles";
export var Anchor;
(function (Anchor) {
    Anchor["Top"] = "top";
    Anchor["Right"] = "right";
    Anchor["Left"] = "left";
    Anchor["Bottom"] = "bottom";
})(Anchor || (Anchor = {}));
export var Drawer = function (_a) {
    var _b = _a.anchor, anchor = _b === void 0 ? Anchor.Right : _b, open = _a.open, children = _a.children, formId = _a.formId;
    var _c = useQueryParams({
        documentPackageId: StringParam,
        documentId: StringParam,
        rentDepositId: NumberParam,
        templateDocumentId: StringParam,
    }), _ = _c[0], setQueryParamValue = _c[1];
    var handleDrawerClose = function () {
        var _a;
        setQueryParamValue((_a = {}, _a[formId] = undefined, _a));
    };
    var sx = DrawerStyles();
    return (_jsxs(MuiDrawer, { anchor: anchor, open: open, onClose: handleDrawerClose, sx: sx.wrapper, children: [_jsx(Box, { sx: sx.content.closeIcon, onClick: handleDrawerClose, children: _jsx(Icon, { path: EAssets.CloseIcon, width: "24px", height: "24px" }) }), children] }));
};
