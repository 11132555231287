import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";
import {
  EDocumentPackageStatus,
  IDocumentPackage,
} from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { getMessageForError } from "@rentiohq/shared-frontend/dist/utils/error.utils";
import { formatBytes } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import {
  DocumentFetchListItem,
  ESpacings,
  Icon,
  Loading,
  RentioInternalRenderer,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import { compact } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";
import { ts } from "../../../../../../../services";

type TDocumentPackage = IDocumentPackage & {
  documentsInPackage: IDocument[];
};

interface IDocumentListItemProps {
  documentId: string;
  spacing?: ESpacings;
}

const renderError = (error: Error) => (
  <>
    <RentioInternalRenderer items={{ error }} />
    <p>{getMessageForError(error)}</p>
  </>
);

export const DocumentListItem: React.FC<IDocumentListItemProps> = ({
  documentId,
  spacing = ESpacings.tight,
}) => {
  return (
    <DocumentFetchListItem
      documentId={documentId}
      renderDocument={(document: IDocument) => {
        const { mimeType, filesize } = document;

        const actions = [];

        if (document.url) {
          actions.push({
            content: ts.viewDocument(),
            onClick: () => utils.document.viewDocument(document),
          });
        }

        if (document.downloadUrl) {
          actions.push({
            content: ts.downloadDocument(),
            onClick: () => (window.location.href = document.downloadUrl),
          });
        }

        return (
          <ResourceListItem
            boxProps={{ p: spacing }}
            item={document}
            media={
              <Icon source={utils.document.getTypeData(mimeType || "").icon} />
            }
            mediaSize="medium"
            actions={actions}
          >
            <div>{document.filename}</div>
            <TextStyle variation="subdued" element="div">
              {!!filesize && formatBytes(filesize)}
            </TextStyle>
          </ResourceListItem>
        );
      }}
      renderLoading={() => (
        <>
          Loading...
          <Loading asDots />
        </>
      )}
      renderError={renderError}
    />
  );
};

export const DocumentRow: React.FC<{
  documentPackage: TDocumentPackage;
  hasRevoke?: boolean;
}> = ({ hasRevoke = true, documentPackage }) => {
  const dispatch = useDispatch();
  const handleRevoke = async () => {
    dispatch(
      documentPackageActions.revokeDocumentPackage.actions.start({
        documentPackageId: documentPackage.id,
      }),
    );
  };

  const documentArray = documentPackage.documentsInPackageIds || [];
  const documentId = documentArray[0];

  if (!documentId) {
    return (
      <ResourceListItem
        boxProps={{ p: ESpacings.tight }}
        item={{}}
        media={<Icon source="fileGeneric" />}
        mediaSize="medium"
      >
        <div>{ts.documentProcessing()}</div>
      </ResourceListItem>
    );
  }

  const renderDocument = (document: IDocument) => {
    const typeData = utils.document.getTypeData(document.mimeType || "");
    const isSignable =
      documentPackage.status &&
      [
        EDocumentPackageStatus.New,
        EDocumentPackageStatus.Draft,
        EDocumentPackageStatus.PDFReady,
        EDocumentPackageStatus.Pending,
      ].includes(documentPackage.status);

    return (
      <ResourceListItem
        boxProps={{ p: ESpacings.tight }}
        item={document}
        media={<Icon source={typeData.icon} />}
        mediaSize="medium"
        actions={compact([
          !!document.url && {
            content: ts.viewDocument(),
            onClick: () => utils.document.viewDocument(document),
          },
          !!document.downloadUrl && {
            content: ts.downloadDocument(),
            onClick: () => (window.location.href = document.downloadUrl),
          },
          isSignable &&
            !!hasRevoke && {
              content: (
                <TextStyle variation={"negative"}>
                  {ts.contractRevokeAction()}
                </TextStyle>
              ),
              onClick: handleRevoke,
            },
        ])}
      >
        <div>{document.filename}</div>
        <TextStyle variation="subdued" element="div">
          {!!document.filesize && formatBytes(document.filesize)}
        </TextStyle>
      </ResourceListItem>
    );
  };

  return (
    <DocumentFetchListItem
      documentId={documentId}
      renderDocument={renderDocument}
      renderLoading={() => (
        <>
          Loading...
          <Loading asDots />
        </>
      )}
      renderError={renderError}
    />
  );
};
