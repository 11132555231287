import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from "../../components";
import { Icon } from "../Icon";
import * as S from "./Tags.styled";
export var Tags = function (_a) {
    var items = _a.items, onRemove = _a.onRemove;
    return (_jsx(Grid, { spacing: "extraTight", alignItems: "center", children: items.map(function (item, index) {
            var handleRemove = function () {
                var _a;
                onRemove === null || onRemove === void 0 ? void 0 : onRemove(item.key);
                (_a = item.onRemove) === null || _a === void 0 ? void 0 : _a.call(item, item.key);
            };
            var hasCloseButton = onRemove || item.onRemove;
            return (_jsx(Grid.Item, { children: _jsxs(S.Tag, { hasCloseButton: hasCloseButton, children: [_jsx(S.Text, { children: item.label }), hasCloseButton && (_jsx(S.Close, { onClick: handleRemove, children: _jsx(Icon, { source: "close", size: "extraSmall" }) }))] }) }, index));
        }) }));
};
