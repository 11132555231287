var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { generateReducer, } from "../generators/generator.reducer";
import { createPaymentOrdersFailed, createPaymentOrdersStart, createPaymentOrdersSucceeded, getMissingKYCsFailed, getMissingKYCsStart, getMissingKYCsSucceeded, getPayeeBankAccountFailed, getPayeeBankAccountStart, getPayeeBankAccountSucceeded, getPayerBankAccountFailed, getPayerBankAccountStart, getPayerBankAccountSucceeded, } from "./paymentOrder.actions";
import { GENERATOR_PARAMS } from "./paymentOrder.constants";
var getExtraDefaultState = function () { return ({
    payee: {},
    payer: {},
    missingKycs: {},
    createPaymentOrders: {},
}); };
var _reducer = generateReducer(GENERATOR_PARAMS, getExtraDefaultState);
var reducer = _reducer;
reducer.on(getPayeeBankAccountStart.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { payee: __assign(__assign({}, state.payee), (_a = {}, _a[payload.id] = {
            bankAccount: undefined,
            pending: true,
            error: undefined,
        }, _a)) });
});
reducer.on(getPayeeBankAccountSucceeded.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { payee: __assign(__assign({}, state.payee), (_a = {}, _a[payload.id] = {
            bankAccount: payload.result.data,
            pending: false,
            error: undefined,
        }, _a)) });
});
reducer.on(getPayeeBankAccountFailed.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { payee: __assign(__assign({}, state.payee), (_a = {}, _a[payload.id] = {
            bankAccount: undefined,
            pending: false,
            error: payload.error,
        }, _a)) });
});
reducer.on(getPayerBankAccountStart.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { payer: __assign(__assign({}, state.payer), (_a = {}, _a[payload.id] = {
            bankAccount: undefined,
            pending: true,
            error: undefined,
        }, _a)) });
});
reducer.on(getPayerBankAccountSucceeded.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { payer: __assign(__assign({}, state.payer), (_a = {}, _a[payload.id] = {
            bankAccount: payload.result.data,
            pending: false,
            error: undefined,
        }, _a)) });
});
reducer.on(getPayerBankAccountFailed.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { payer: __assign(__assign({}, state.payer), (_a = {}, _a[payload.id] = {
            bankAccount: undefined,
            pending: false,
            error: payload.error,
        }, _a)) });
});
reducer.on(getMissingKYCsStart.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { missingKycs: __assign(__assign({}, state.missingKycs), (_a = {}, _a[payload.propertyId || "all"] = {
            isExhausted: false,
            missingKycs: undefined,
            pending: true,
            error: undefined,
        }, _a)) });
});
reducer.on(getMissingKYCsSucceeded.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { missingKycs: __assign(__assign({}, state.missingKycs), (_a = {}, _a[payload.propertyId || "all"] = {
            missingKycs: payload.result,
            pending: false,
            error: undefined,
        }, _a)) });
});
reducer.on(getMissingKYCsFailed.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { missingKycs: __assign(__assign({}, state.missingKycs), (_a = {}, _a[payload.propertyId || "all"] = {
            isExhausted: false,
            missingKycs: undefined,
            pending: false,
            error: payload.error,
        }, _a)) });
});
reducer.on(createPaymentOrdersStart.type, function (state, payload) { return (__assign(__assign({}, state), { createPaymentOrders: {
        pending: true,
        error: undefined,
    } })); });
reducer.on(createPaymentOrdersSucceeded.type, function (state, payload) {
    var obj = {};
    payload.result.data.forEach(function (item) {
        obj[item.id] = item;
    });
    return __assign(__assign({}, state), { infiniteLoad: {
        //Clear infiniteLoad when a new entity is added.
        }, detail: __assign(__assign({}, state.detail), obj), createPaymentOrders: {
            ids: payload.result.data.map(function (x) { return x.id; }),
            pending: false,
            error: undefined,
        } });
});
reducer.on(createPaymentOrdersFailed.type, function (state, payload) { return (__assign(__assign({}, state), { createPaymentOrders: {
        pending: false,
        error: payload.error,
    } })); });
// eslint-disable-next-line import/no-default-export
export default reducer;
