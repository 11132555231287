import React from "react";
import useEventListener from "./useEventListener";

const getVisibilityPropertyNames = () => {
  // Opera 12.10 and Firefox 18 and later support
  if (typeof document.hidden !== "undefined") {
    return ["hidden", "visibilitychange"];
  }

  // @ts-ignore
  if (typeof document.msHidden !== "undefined") {
    return ["msHidden", "msvisibilitychange"];
  }

  // @ts-ignore
  if (typeof document.webkitHidden !== "undefined") {
    return ["webkitHidden", "webkitvisibilitychange"];
  }

  return ["hidden", "visibilitychange"];
};

const [hidden, visibilityChange] = getVisibilityPropertyNames();

// @ts-ignore
const isDocumentHidden = () => document[hidden] as boolean;

const useDocumentVisibilityChange = (callback: any) => {
  const onChange = React.useCallback(() => {
    callback(isDocumentHidden());
  }, [callback]);

  // @ts-ignore
  useEventListener(visibilityChange, onChange, document);
};

// eslint-disable-next-line import/no-default-export
export default () => {
  const [isHidden, setHidden] = React.useState(isDocumentHidden());

  const onChange = React.useCallback(
    (state: boolean) => setHidden(state),
    [setHidden],
  );

  useDocumentVisibilityChange(onChange);

  return isHidden;
};
