import {
  EGroupActionAccount,
  IGroupActionMember,
} from "@rentiohq/shared-frontend/dist/types/groupAction.types";
import { getGroupActionsMembers } from "@rentiohq/shared-frontend/dist/utils/groupAction.utils";
import {
  DisplayText,
  IOptionListOption,
  OptionListShared,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { EGroupActionType } from "../GroupActionsModal.types";

const getSelectMembersInitialState = (type?: EGroupActionType) => {
  if (type === EGroupActionType.Document) {
    return [EGroupActionAccount.Myself];
  }

  return [
    EGroupActionAccount.Tenant,
    EGroupActionAccount.Owner,
    EGroupActionAccount.Myself,
  ];
};

export interface ISelectGroupActionsMembersProps {
  title: string;
  type?: EGroupActionType;
  onChange: (selection: number[]) => void;
}

const SelectGroupActionsMembers = (props: ISelectGroupActionsMembersProps) => {
  const { title, type, onChange } = props;
  const isDocumentType = type === EGroupActionType.Document ? true : false;

  const [selectedMembers, setSelectedMembers] = React.useState<number[]>(
    getSelectMembersInitialState(type),
  );

  React.useEffect(() => {
    onChange(selectedMembers);
  }, [selectedMembers]);

  // Helpers
  const getLabel = (member: IGroupActionMember) => member.title;

  const options: IOptionListOption<number>[] = getGroupActionsMembers(
    isDocumentType,
  ).map((member: IGroupActionMember) => {
    return {
      id: member.id,
      value: member.id,
      label: getLabel(member),
      disabled: member.id === EGroupActionAccount.Myself ? true : false,
    };
  });

  // Renders
  if (!type) {
    return null;
  }

  return (
    <>
      <DisplayText size="medium">{title}</DisplayText>
      <OptionListShared
        id="members-select"
        variant="default"
        multiple={true}
        value={selectedMembers}
        options={options}
        onChange={(selection: number[]) => {
          setSelectedMembers(selection);
        }}
      />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default SelectGroupActionsMembers;
