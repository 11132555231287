var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery } from "redux-saga/effects";
import logger from "../../logger";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralUpdateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import { generateSaga } from "../generators/generator.saga";
import { duplicateApplicationStart, getCountStepsFailed, getCountStepsStart, getCountStepsSucceeded, getDetailFailed, updateApplicationStepStart, updateApplicationStepSucceeded, } from "./application.actions";
import * as applicationApi from "./application.api";
import { GENERATOR_PARAMS } from "./application.constants";
function updateApplicationStepStartFlow(action) {
    var _a, id, step, tenantFeedbackText, onSuccess, onFailure, application, unknownError_1, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, step = _a.step, tenantFeedbackText = _a.tenantFeedbackText, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 5]);
                return [4 /*yield*/, call(applicationApi.updateApplicationStep, id, {
                        tenantFeedbackText: tenantFeedbackText,
                        step: step,
                    })];
            case 2:
                application = _b.sent();
                return [4 /*yield*/, put(updateApplicationStepSucceeded.getAction({
                        id: id,
                        result: application.data,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(application.data);
                showAlert({
                    type: "success",
                    message: getGeneralUpdateSuccessText("application"),
                });
                return [3 /*break*/, 5];
            case 4:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function duplicateApplicationStartFlow(action) {
    var _a, id, propertyId, step, onSuccess, onFailure, application, unknownError_2, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, propertyId = _a.propertyId, step = _a.step, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 5]);
                return [4 /*yield*/, call(applicationApi.duplicateApplication, id, {
                        propertyId: propertyId,
                        step: step,
                    })];
            case 2:
                application = _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(application.data);
                showAlert({
                    type: "success",
                    message: getLocalizedText("application.duplicate.toast.success.heading"),
                });
                return [3 /*break*/, 5];
            case 3:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(getDetailFailed.getAction({ id: id, error: error }))];
            case 4:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function getCountStepsStartFlow(action) {
    var _a, query, onSuccess, onFailure, countStepsResponse, unknownError_3, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, query = _a.query, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(applicationApi.getCountSteps, query)];
            case 2:
                countStepsResponse = _b.sent();
                return [4 /*yield*/, put(getCountStepsSucceeded.getAction({
                        query: query,
                        result: countStepsResponse.data,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(countStepsResponse.data);
                return [3 /*break*/, 6];
            case 4:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(getCountStepsFailed.getAction({ query: query, error: error }))];
            case 5:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(updateApplicationStepStart.type, updateApplicationStepStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(duplicateApplicationStart.type, duplicateApplicationStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(getCountStepsStart.type, getCountStepsStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, generateSaga(GENERATOR_PARAMS)];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
