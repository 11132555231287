import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as S from "./SegmentedControl.styled";
export var SegmentedControl = function (props) {
    var name = props.name, selected = props.selected, options = props.options, error = props.error, onChange = props.onChange, disabled = props.disabled, _a = props.size, size = _a === void 0 ? "regular" : _a;
    return (_jsx(S.Wrapper, { hasError: !!error, children: options.map(function (_a) {
            var id = _a.id, value = _a.value, renderLeadingIcon = _a.renderLeadingIcon;
            var isSelected = selected === id;
            return (_jsxs(S.Button, { disabled: disabled, onClick: onChange.bind(null, id), selected: isSelected, size: size, children: [renderLeadingIcon === null || renderLeadingIcon === void 0 ? void 0 : renderLeadingIcon(), value] }, "".concat(name, "-").concat(id)));
        }) }));
};
