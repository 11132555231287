var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { round } from "lodash";
import { ERentioFormField } from "../../components/components/Form";
import { CONFIG } from "../../config/app.config";
import { getValue, getValueOrExtraValue, } from "../../redux/form/form.utils";
import { EPaymentOrderType } from "../../types/payment.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { formatCurrency, formatNumber } from "../../utils/number.utils";
import { getVatFieldsForSchema, getVatFieldsForUiSchema, } from "../createContract/schema.createContract.utils.vat";
import { EField } from "./schema.createPayment.types";
import { getNavigationTitle, getRelatedContract, } from "./schema.createPayment.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a, _b;
        return {
            required: [EField.ManagementFeePrice, EField.ManagementFeeShouldIndex],
            properties: __assign(__assign((_a = {}, _a[EField.Header + "12"] = {
                default: undefined,
            }, _a[EField.ManagementFeePercentage] = {
                default: getValue(EField.ManagementFeePercentage, initialValues, defaultValues),
                minimum: 0,
                maximum: 100,
                // @ts-ignore
                // handleChange: (
                //   fieldName: string,
                //   fieldValue: any,
                //   myFieldValue: string,
                //   formData: any,
                // ) => {
                //   if (fieldName === EField.ManagementFeePrice) {
                //     if (!fieldValue || fieldValue === 0) {
                //       return { shouldSetNewValue: true, newValue: 0 };
                //     }
                //     const newValue = Math.round(
                //       (fieldValue / formData[EField.Price]) * 100,
                //     );
                //     return {
                //       shouldSetNewValue: true,
                //       newValue: Math.min(100, Math.max(0, newValue)),
                //     };
                //   }
                //   return { shouldSetNewValue: false };
                // },
            }, _a[EField.ManagementFeePrice] = {
                default: getValue(EField.ManagementFeePrice, initialValues, defaultValues),
                minimum: 0,
                maximum: defaultValues[EField.Price] || 0,
                // @ts-ignore
                handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                    if (fieldName === EField.ManagementFeePercentage) {
                        if (!fieldValue || fieldValue === 0) {
                            return { shouldSetNewValue: true, newValue: 0 };
                        }
                        var rentPrice = formData[EField.Price] || 0;
                        var newValue = round((rentPrice / 100) * fieldValue, 2);
                        return {
                            shouldSetNewValue: true,
                            newValue: Math.min(rentPrice, Math.max(0, newValue)),
                        };
                    }
                    return { shouldSetNewValue: false };
                },
            }, _a), getVatFieldsForSchema({
                defaultValues: defaultValues,
                initialValues: initialValues,
                fieldKey: EField.ManagementFeeVat,
                infoFieldKey: EField.ManagementFeeVatHelpInfo,
                baseAmountFieldKey: EField.ManagementFeePrice,
                defaultValue: getValueOrExtraValue(EField.ManagementFeeVat, defaultValues, 21),
                shouldSetNewBaseAmount: function (fieldName, fieldValue, myFieldValue, formData) {
                    if (fieldName === EField.ManagementFeePercentage) {
                        if (!fieldValue || fieldValue === 0) {
                            return { shouldSetNewValue: true, newValue: 0 };
                        }
                        var rentPrice = formData[EField.Price] || 0;
                        var newValue = round((rentPrice / 100) * fieldValue, 2);
                        return {
                            shouldSetNewValue: true,
                            newValue: Math.min(rentPrice, Math.max(0, newValue)),
                        };
                    }
                    return { shouldSetNewValue: false };
                },
                bookkeepingEnabled: extraData.bookkeepingEnabled,
            })), (_b = {}, _b[EField.ManagementFeeShouldIndex] = {
                default: getValueOrExtraValue(EField.ManagementFeeShouldIndex, defaultValues, "yes"),
                // @ts-ignore
                options: [
                    {
                        id: "yes",
                        value: getLocalizedText("system.yes"),
                    },
                    {
                        id: "no",
                        value: getLocalizedText("system.no"),
                    },
                ],
            }, _b)),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a, _b;
    return (__assign(__assign((_a = { "rentio:title": getLocalizedText("payment.payment_order.management_fixed.heading.summary"), "rentio:navigationTitle": getNavigationTitle(extraData), "ui:field": ERentioFormField.Grid, grid: {
                row: [
                    {
                        col: {
                            width: 1,
                            children: [EField.Header + "12"],
                        },
                    },
                    {
                        col: {
                            width: 1,
                            children: [EField.ManagementFeePercentage],
                        },
                    },
                    {
                        col: {
                            width: [1, 3 / 5],
                            children: [EField.ManagementFeePrice],
                        },
                    },
                    {
                        col: {
                            width: [1, 2 / 5],
                            children: [EField.ManagementFeeVat],
                        },
                    },
                    {
                        col: {
                            width: 1,
                            children: [EField.ManagementFeeVatHelpInfo],
                        },
                    },
                    {
                        col: {
                            width: [1, 1 / 2],
                            children: [EField.ManagementFeeShouldIndex],
                        },
                    },
                ],
            } }, _a[EField.Header + "12"] = {
        "ui:field": ERentioFormField.SectionHeader,
        getTitle: function () {
            return getLocalizedText("payment.payment_order.management_fixed.heading");
        },
    }, _a[EField.ManagementFeePercentage] = {
        "ui:field": ERentioFormField.Number,
        title: getLocalizedText("payment.payment_order.management.percentage.field"),
        formatSummary: function (formData) {
            return formData && "".concat(formatNumber(formData), "%");
        },
        placeholder: "0",
        suffixText: "%",
        optional: true,
        isFloat: true,
        autoFocus: true,
        extraInfoUrl: "payment.payment_order.management.fixed.url",
        getInfo: function (formData) {
            var contract = getRelatedContract(extraData, formData);
            if (contract === null || contract === void 0 ? void 0 : contract.rentDiscountEnabled) {
                return undefined;
            }
            var type = formData[EField.TypeRecurring] || "-";
            if (![EPaymentOrderType.RentAndCosts, EPaymentOrderType.Rent].includes(type)) {
                return undefined;
            }
            var rentPrice = formData[EField.Price] || 0;
            return getLocalizedText("payment.payment_order.management.percentage.rent_discount_info", {
                amount: formatCurrency(rentPrice),
                rentDiscountAmount: "".concat(formatCurrency((contract === null || contract === void 0 ? void 0 : contract.rentDiscountAmount) || 0)),
            });
        },
    }, _a[EField.ManagementFeePrice] = {
        "ui:field": ERentioFormField.Number,
        title: getLocalizedText("payment.payment_order.management.fixed.field"),
        formatSummary: function (formData) { return formData && formatCurrency(formData); },
        placeholder: "0",
        suffixText: "EUR",
        isFloat: true,
        decimals: CONFIG.DECIMALS_FOR_CURRENCY,
    }, _a), getVatFieldsForUiSchema({
        fieldKey: EField.ManagementFeeVat,
        infoFieldKey: EField.ManagementFeeVatHelpInfo,
        baseAmountFieldKey: EField.ManagementFeePrice,
    })), (_b = {}, _b[EField.ManagementFeeShouldIndex] = {
        "ui:field": ERentioFormField.SegmentedControl,
        title: getLocalizedText("payment.payment_order.management.indexable.field"),
        formatSummary: function (formData) {
            return formData && getLocalizedText("system.".concat(formData.toLowerCase()));
        },
    }, _b)));
};
