import { Box } from "@rebass/grid";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { ACTIVITY_SUBTYPES } from "@rentiohq/shared-frontend/dist/types/activity.types";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { EPaymentRequestFollowUpType } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Activities,
  DisplayText,
  ESpacings,
  Modal,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { ts } from "../../services";
import { createTitle, getPaymentOrders } from "../../utils/payment";
import {
  IPaymentFollowUpModalProps,
  TModalTypes,
} from "./PaymentFollowUpModal.types";
import { AppScreen } from "./components/AppScreen";
import { ContactScreen } from "./components/ContactScreen";
import { MailScreen } from "./components/MailScreen";
import { MethodSelectionScreen } from "./components/MethodSelectionScreen";
import { PhoneScreen } from "./components/PhoneScreen";
import { RegisteredMailScreen } from "./components/RegisteredMailScreen";
import { SmsScreen } from "./components/SmsScreen";

export const PaymentFollowUpModal: React.FC<IPaymentFollowUpModalProps> = ({
  onClose,
  ...props
}) => {
  const { id: accountId } = props.followUpItem.payerAccount;
  const dispatch = useDispatch();
  // Keep current account Id in local state, because this can chance in the process.
  const [currentAccountId, setCurrentAccountId] = React.useState<
    number | undefined
  >(accountId);
  const contact: any = useSelector((state: any) =>
    contactSelectors.getContact(state, undefined, currentAccountId),
  );
  const [step, setStep] = React.useState<TModalTypes>("method");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const phoneFormRef = React.useRef();
  const registeredMailFormRef = React.useRef();
  const addContactFormRef = React.useRef();
  const addSmsFormRef = React.useRef();
  const [mailPostBody, setMailPostBody] = React.useState<any>({
    subject: getLocalizedText(
      "payments.follow_up.mail.subject",
      undefined,
      undefined,
      contact?.locale || ELocale.NL,
    ),
    title: `${getLocalizedText(
      "payments.follow_up.mail.heading",
      undefined,
      undefined,
      contact?.locale || ELocale.NL,
    )}: ${createTitle({
      paymentOrders: getPaymentOrders(props.followUpItem),
      paymentRequest: props.followUpItem,
    })}`,
    actionCopy: getLocalizedText(
      "payments.follow_up.mail.action",
      undefined,
      undefined,
      contact?.locale || ELocale.NL,
    ),
    message: getLocalizedText(
      "payments.follow_up.mail.content",
      { contact: contact?.firstname || "user" },
      undefined,
      contact?.locale || ELocale.NL,
    ),
  });

  const [appPostBody, setAppPostBody] = React.useState<any>({
    message: getLocalizedText("payments.follow_up.app.content", {
      contact: contact?.firstname || "user",
    }),
  });

  const isPendingFollowUpMail =
    useSelector((state: IRootStore) =>
      paymentSelectors.isPendingFollowUp(
        state,
        props.followUpItem.id,
        EPaymentRequestFollowUpType.Mail,
      ),
    ) || false;
  const followUpErrorMail =
    useSelector((state: IRootStore) =>
      paymentSelectors.followUpError(
        state,
        props.followUpItem.id,
        EPaymentRequestFollowUpType.Mail,
      ),
    ) || false;
  const prevIsPendingFollowUpMail = usePrevious(isPendingFollowUpMail);
  const isPendingFollowUpApp =
    useSelector((state: IRootStore) =>
      paymentSelectors.isPendingFollowUp(
        state,
        props.followUpItem.id,
        EPaymentRequestFollowUpType.App,
      ),
    ) || false;
  const followUpErrorApp =
    useSelector((state: IRootStore) =>
      paymentSelectors.followUpError(
        state,
        props.followUpItem.id,
        EPaymentRequestFollowUpType.App,
      ),
    ) || false;
  const prevIsPendingFollowUpApp = usePrevious(isPendingFollowUpApp);

  React.useEffect(() => {
    if (contact) {
      setMailPostBody({
        subject: getLocalizedText(
          "payments.follow_up.mail.subject",
          undefined,
          undefined,
          contact.locale,
        ),
        title: `${getLocalizedText(
          "payments.follow_up.mail.heading",
          undefined,
          undefined,
          contact.locale,
        )}: ${createTitle({
          paymentOrders: getPaymentOrders(props.followUpItem),
          paymentRequest: props.followUpItem,
        })}`,
        actionCopy: getLocalizedText(
          "payments.follow_up.mail.action",
          undefined,
          undefined,
          contact.locale,
        ),
        message: getLocalizedText(
          "payments.follow_up.mail.content",
          { contact: contact.firstname },
          undefined,
          contact.locale,
        ),
      });

      setAppPostBody({
        message: getLocalizedText("payments.follow_up.app.content", {
          contact: contact.firstname || "user",
        }),
      });
    }
  }, [contact, props.followUpItem]);

  React.useEffect(() => {
    if (!contact && accountId) {
      fetchContact(accountId);
    }
  }, [accountId, contact]);

  React.useEffect(() => {
    if (contact) {
      setStep("method");

      setMailPostBody({
        subject: getLocalizedText(
          "payments.follow_up.mail.subject",
          undefined,
          undefined,
          contact.locale,
        ),
        title: `${getLocalizedText(
          "payments.follow_up.mail.heading",
          undefined,
          undefined,
          contact.locale,
        )}: ${createTitle({
          paymentOrders: getPaymentOrders(props.followUpItem),
          paymentRequest: props.followUpItem,
        })}`,
        actionCopy: getLocalizedText(
          "payments.follow_up.mail.action",
          undefined,
          undefined,
          contact.locale,
        ),
        message: getLocalizedText(
          "payments.follow_up.mail.content",
          { contact: contact.firstname },
          undefined,
          contact.locale,
        ),
      });

      setAppPostBody({
        message: getLocalizedText("payments.follow_up.app.content", {
          contact: contact.firstname || "user",
        }),
      });

      return;
    }

    if (accountId) {
      return;
    }

    setStep("contact");
  }, [contact, props.followUpItem]);

  React.useEffect(() => {
    if (
      !!prevIsPendingFollowUpMail &&
      !isPendingFollowUpMail &&
      !followUpErrorMail
    ) {
      onClose?.();
    }
  }, [prevIsPendingFollowUpMail, isPendingFollowUpMail, followUpErrorMail]);

  React.useEffect(() => {
    if (
      !!prevIsPendingFollowUpApp &&
      !isPendingFollowUpApp &&
      !followUpErrorApp
    ) {
      onClose?.();
    }
  }, [prevIsPendingFollowUpApp, isPendingFollowUpApp, followUpErrorApp]);

  const fetchContact = (accountId: number) => {
    dispatch(
      contactActions.getContactsByAccountIds.actions.start({
        accountIds: [accountId!],
      }),
    );
  };

  const handleFollowUpMail = async () => {
    const { followUpItem } = props;
    dispatch(
      paymentActions.paymentRequestFollowUpMail.actions.start({
        paymentRequestId: followUpItem.id,
        body: mailPostBody,
      }),
    );
  };

  const handleFollowUpApp = async () => {
    const { followUpItem } = props;
    dispatch(
      paymentActions.paymentRequestFollowUpApp.actions.start({
        paymentRequestId: followUpItem.id,
        body: appPostBody,
      }),
    );
  };

  const handlePrevious = () => {
    setStep("method");
  };

  const handlePhoneModalSubmit = () => {
    // @ts-ignore
    phoneFormRef.current!.handleExternalSubmit();
  };

  const handleRegisteredMailModalSubmit = () => {
    // @ts-ignore
    registeredMailFormRef.current!.handleExternalSubmit();
  };

  const handleSmsModalSubmit = () => {
    // @ts-ignore
    addSmsFormRef.current!.handleExternalSubmit();
  };

  const handleAddContactModalSubmit = () => {
    // @ts-ignore
    addContactFormRef.current!.handleExternalSubmit();
  };

  const stepActions: { [key: string]: IAction[] } = {
    method: [
      {
        content: getLocalizedText("system.cancel"),
        appearance: "outline",
        onClick: onClose,
      },
    ],
    app: [
      {
        content: getLocalizedText("system.previous"),
        onClick: handlePrevious,
        appearance: "outline",
      },
      {
        content: getLocalizedText("system.submit"),
        onClick: handleFollowUpApp,
        appearance: "primary",
        isSubmitting: isPendingFollowUpApp,
      },
    ],
    sms: [
      {
        content: getLocalizedText("system.previous"),
        onClick: handlePrevious,
        appearance: "outline",
      },
      {
        content: getLocalizedText("system.submit"),
        onClick: handleSmsModalSubmit,
        appearance: "primary",
        isSubmitting: isLoading,
      },
    ],
    phone: [
      {
        content: getLocalizedText("system.previous"),
        onClick: handlePrevious,
        appearance: "outline",
      },
      {
        content: getLocalizedText("system.submit"),
        appearance: "primary",
        onClick: handlePhoneModalSubmit,
        isSubmitting: isLoading,
      },
    ],
    mail: [
      {
        content: getLocalizedText("system.previous"),
        onClick: handlePrevious,
        appearance: "outline",
      },
      {
        content: getLocalizedText("system.submit"),
        onClick: handleFollowUpMail,
        appearance: "primary",
        isSubmitting: isPendingFollowUpMail,
      },
    ],
    registeredMail: [
      {
        content: getLocalizedText("system.previous"),
        onClick: handlePrevious,
        appearance: "outline",
      },
      {
        content: getLocalizedText("system.submit"),
        onClick: handleRegisteredMailModalSubmit,
        appearance: "primary",
        isSubmitting: isLoading,
      },
    ],
    contact: [
      {
        content: getLocalizedText("system.cancel"),
        onClick: onClose,
        appearance: "outline",
      },
      {
        content: getLocalizedText("system.submit"),
        onClick: handleAddContactModalSubmit,
        appearance: "primary",
        isSubmitting: isLoading,
      },
    ],
  };

  const handleMethodChange = (value: TModalTypes[]) => {
    setTimeout(() => {
      setStep(value[0]);
    }, 250);
  };

  const activityFilter = {
    where: {
      subtype: {
        inq: [
          ACTIVITY_SUBTYPES["PAYMENT"]["REQUEST_CREATED"],
          ACTIVITY_SUBTYPES["PAYMENT"]["REQUEST_REMINDER_SENT"],
        ],
      },
      paymentRequestId: props.followUpItem.id,
      // propertyId: 15,
    },
  };

  const renderActivities = () => {
    return (
      <Box mt={ESpacings.loose}>
        <DisplayText size="small">{ts.system("history")}</DisplayText>
        <Activities
          identifier={`payments-follow-up-${props.followUpItem.id}`}
          filter={activityFilter}
          limit={3}
          isCompact
        />
      </Box>
    );
  };

  const handleAddContactModalClose = (contact: any) => {
    setCurrentAccountId(contact.account.id);
    fetchContact(contact.account.id);
    // setStep('method')
  };

  const renderContent = () => {
    if (!step) {
      return null;
    }

    switch (step) {
      case "app":
        return (
          <AppScreen
            {...props}
            setPostBody={setAppPostBody}
            postBody={appPostBody}
            renderActivities={renderActivities()}
            contact={contact}
          />
        );
      case "sms":
        return (
          <SmsScreen
            {...props}
            contact={contact}
            account={props.followUpItem.payerAccount}
            ref={addSmsFormRef}
            onClose={onClose}
            renderActivities={renderActivities()}
            setIsLoading={setIsLoading}
          />
        );
      case "phone":
        return (
          <PhoneScreen
            {...props}
            ref={phoneFormRef}
            onClose={onClose}
            renderActivities={renderActivities()}
            setIsLoading={setIsLoading}
            contact={contact}
          />
        );
      case "mail":
        return (
          <MailScreen
            {...props}
            setPostBody={setMailPostBody}
            postBody={mailPostBody}
            renderActivities={renderActivities()}
            contact={contact}
          />
        );
      case "registeredMail":
        return (
          <RegisteredMailScreen
            {...props}
            contact={contact}
            ref={registeredMailFormRef}
            onClose={onClose}
            renderActivities={renderActivities()}
            setIsLoading={setIsLoading}
          />
        );
      case "contact":
        return (
          <ContactScreen
            {...props}
            account={props.followUpItem.payerAccount}
            ref={addContactFormRef}
            onClose={handleAddContactModalClose}
            renderActivities={renderActivities()}
            setIsLoading={setIsLoading}
          />
        );
      default:
        return (
          <MethodSelectionScreen
            step={step}
            {...props}
            onMethodChange={handleMethodChange}
            renderActivities={renderActivities()}
          />
        );
    }
  };

  const getTitle = () => {
    switch (step) {
      case "app":
        return getLocalizedText("payments.follow_up.modal.method.app.heading");
      case "phone":
        return getLocalizedText(
          "payments.follow_up.modal.method.phone.heading",
        );
      case "mail":
        return getLocalizedText("payments.follow_up.modal.method.mail.heading");
      case "registeredMail":
        return getLocalizedText(
          "payments.follow_up.modal.method.registered_mail.heading",
        );
      case "contact":
        return getLocalizedText("payments.follow_up.modal.add_contact.heading");
      case "sms":
        return getLocalizedText("payments.follow_up.modal.method.sms.heading");
      default:
        return getLocalizedText("payments.follow_up.modal.heading");
    }
  };

  return (
    <Modal
      onClose={onClose}
      heading={getTitle()}
      hasDismiss={true}
      shouldCloseOnOverlayClick={false}
      width="medium"
      actions={step ? stepActions[step] : []}
    >
      {renderContent()}
    </Modal>
  );
};
