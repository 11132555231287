import { IPaymentRequest } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";

export interface IExtraData {
  paymentRequest: IPaymentRequest;
}

export enum EField {
  PaidAtDate = "paidAtDate",
  Amount = "amount",
}
