import { useEffect } from "react";
export var NavigationScroll = function (_a) {
    var children = _a.children;
    useEffect(function () {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    return children || null;
};
