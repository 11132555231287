import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as contractActionsV2 from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.actions";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as contractTypes from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import {
  EContractIndexationStatus,
  EContractMemberTypes,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Button,
  Card,
  DataTable,
  EFilterType,
  EmptyState,
  Filters,
  Loading,
  LoadingWrapper,
  Lozenge,
  Page,
  Pagination,
  Stack,
  Stages,
} from "@rentiohq/web-shared/dist/components";
import { useMultiselect } from "@rentiohq/web-shared/dist/hooks/useMultiselect";
import { ts as tsCommon } from "@rentiohq/web-shared/dist/services";
import utils from "@rentiohq/web-shared/dist/utils";
import { IndexContractBulkAskOwnerModal } from "components/IndexContractBulkAskOwnerModal";
import { IndexContractBulkIndexModal } from "components/IndexContractBulkIndexModal";
import { IndexContractBulkSkipIndexModal } from "components/IndexContractBulkSkipIndexModal";
import { compact } from "lodash";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import {
  NumberParam,
  StringParam,
  createEnumArrayParam,
  createEnumParam,
  withDefault,
} from "serialize-query-params";
import { getContractPrice } from "utils/contract";
import { IndexContractModal } from "../../../components/IndexContractModalV2";
import { ts } from "../../../services";
import { ContractsAddressX } from "../EndingContracts/components/ContractsAddress";
import * as S from "./IndexableContracts.styled";
import {
  EAlreadyIndexed,
  getFilterQuery,
  getIndexationStatusVariation,
} from "./IndexableContracts.utils";

const SELECTION_LIMIT = 100;

const INDEXATION_STATUSES = [
  EContractIndexationStatus.Ready,
  EContractIndexationStatus.OwnerAsked,
  EContractIndexationStatus.OwnerApproved,
  EContractIndexationStatus.OwnerDeclined,
  EContractIndexationStatus.Muted,
  EContractIndexationStatus.NotReady,
];

export const IndexableContracts: FC<{}> = () => {
  const dispatch = useDispatch();

  const { isBroker } = authHooks.useSelf();

  const {
    selectedIds,
    onSelectIds,
    onDeselectIds,
    onSelectId,
    onDeselectId,
    onDeselectAll,
  } = useMultiselect<string>([], SELECTION_LIMIT);

  const [showBulkIndexModal, setShowBulkIndexModal] = React.useState(false);
  const [showBulkAskOwnerModal, setShowBulkAskOwnerModal] =
    React.useState(false);
  const [showBulkSkipIndexModal, setShowBulkSkipIndexModal] =
    React.useState(false);

  const [query, setQuery] = useQueryParams({
    query: StringParam,
    indexationStatuses: withDefault(createEnumArrayParam(INDEXATION_STATUSES), [
      EContractIndexationStatus.Ready,
      EContractIndexationStatus.OwnerApproved,
      EContractIndexationStatus.OwnerDeclined,
    ]),
    alreadyIndexed: createEnumParam(Object.values(EAlreadyIndexed)),
    page: withDefault(NumberParam, 1),
    contractId: StringParam,
    documentId: StringParam,
  });

  const [debouncedSearchQuery] = useDebounce(query.query);

  const filter = getFilterQuery({ ...query, query: debouncedSearchQuery });

  const {
    items: contracts = [],
    isFetching: isFetchingContracts,
    totalPages,
  } = contractHooks.usePaged({
    query: filter,
  });

  const { count: indexableContractsCount = 0 } = contractHooks.useCount({
    query: contractTypes.ContractFilters.contractsIndexable,
    shouldRefetch: true,
  });
  const { count: indexableContractsOwnerAskedCount = 0 } =
    contractHooks.useCount({
      query: contractTypes.ContractFilters.contractsIndexOwnerAsked,
      shouldRefetch: true,
    });
  const { count: indexedContracts = 0 } = contractHooks.useCount({
    query: contractTypes.ContractFilters.contractsIndexed,
    shouldRefetch: true,
  });

  // Filter functions
  const handleFiltersQueryChange = (value: any) => {
    setQuery({ query: value });
  };

  const handleFilterQueryValueRemove = () => setQuery({ query: "" });

  const filterContractsByStage = (values?: EContractIndexationStatus[]) => {
    setQuery({ indexationStatuses: values, page: 1 });
    onDeselectAll();
  };

  const handleStageRemove = () => {
    filterContractsByStage();
  };

  const handleFiltersClearAll = () => {
    handleFilterQueryValueRemove();
    handleStageRemove();
  };

  const handlePageClick = ({ selected }: any) => {
    setQuery({ page: selected + 1 });
  };

  const handleClickRow = (index: number) => {
    const contract = contracts?.[index];
    if (!contract) {
      return;
    }

    if (!contract.indexationStatus) {
      return;
    }

    if (
      ![
        EContractIndexationStatus.Ready,
        EContractIndexationStatus.OwnerAsked,
        EContractIndexationStatus.OwnerApproved,
        EContractIndexationStatus.OwnerDeclined,
        EContractIndexationStatus.Muted,
      ].includes(contract.indexationStatus)
    ) {
      return;
    }

    setQuery({ contractId: contract.id });
  };

  const handleModalClick = (documentId?: string) => {
    setQuery({ contractId: undefined, documentId });
  };

  const handleSkipIndex = (contractIdToIndex: string) => () => {
    confirm({
      title: getLocalizedText("indexation.confirm.skip_index.title"),
      info: getLocalizedText("indexation.confirm.skip_index.info"),
      type: "warning",
      primaryActions: [
        {
          title: tsCommon.contractIndexSkipAction(),
          onPress: () => {
            dispatch(
              contractActionsV2.indexSkipStart.getAction({
                id: contractIdToIndex,
              }),
            );
          },
        },
      ],
    });
  };

  const handleAskOwner = (contractIdToIndex: string) => async () => {
    confirm({
      title: getLocalizedText("indexation.confirm.ask_owner.title"),
      info: getLocalizedText("indexation.confirm.ask_owner.info"),
      type: "warning",
      primaryActions: [
        {
          title: tsCommon.contractIndexInquiryAction(),
          onPress: () => {
            dispatch(
              contractActionsV2.indexAskOwnerStart.getAction({
                id: contractIdToIndex,
                onFailure: error => {
                  showAlert({
                    type: "error",
                    error,
                  });
                },
              }),
            );
          },
        },
      ],
    });
  };

  const renderTable = (
    <>
      <Filters
        queryValue={query.query || ""}
        queryPlaceholder={ts.followUpIndexableContractsFilterQueryPlaceholder()}
        onQueryChange={handleFiltersQueryChange}
        onQueryClear={handleFilterQueryValueRemove}
        onClearAll={handleFiltersClearAll}
        filterConfigs={[
          {
            label: getLocalizedText(
              "follow_up.indexable_contracts.filter.stage.label",
            ),
            groupKey: "stageGroupKey",
            filters: [
              {
                filterKey: "stage",
                options: INDEXATION_STATUSES,
                values: query.indexationStatuses,
                type: EFilterType.MultiSelect,
                translate: value =>
                  getLocalizedText(
                    `follow_up.indexable_contracts.filter.stage.${value}.label`.toLowerCase(),
                  ),
                onChange: (
                  newIndexationStatuses: EContractIndexationStatus[],
                ) => {
                  setQuery({
                    indexationStatuses: newIndexationStatuses,
                    page: 1,
                  });
                  onDeselectAll();
                },
                onRemove: (value: string) => {
                  setQuery({
                    indexationStatuses: query.indexationStatuses?.filter(
                      (v: any) => v !== value,
                    ),
                  });
                },
              },
            ],
          },
          {
            label: getLocalizedText(
              "follow_up.indexable_contracts.filter.indexed.label",
            ),
            groupKey: "indexedGroupKey",
            filters: [
              {
                filterKey: "indexed",
                options: Object.values(EAlreadyIndexed),
                values: query.alreadyIndexed ? [query.alreadyIndexed] : [],
                type: EFilterType.SingleSelect,
                translate: value =>
                  getLocalizedText(
                    `follow_up.indexable_contracts.filter.indexed.${value}.label`.toLowerCase(),
                  ),
                onChange: newValues => {
                  const newValue = newValues[0];
                  setQuery({
                    alreadyIndexed: newValue,
                    page: 1,
                  });
                  onDeselectAll();
                },
                onRemove: (value: string) => {
                  setQuery({
                    alreadyIndexed: undefined,
                    page: 1,
                  });
                  onDeselectAll();
                },
              },
            ],
          },
        ]}
      />

      <Spacer weight={ESpacerWeight.W16} />

      {selectedIds && selectedIds.length > 0 && (
        <>
          <Stack vertical={true} spacing="extraTight">
            <Stack spacing="extraTight">
              {compact([
                {
                  title: getLocalizedText(
                    "follow_up.indexable_contracts.bulk_action.index",
                  ),
                  onClick: () => {
                    setShowBulkIndexModal(true);
                  },
                },
                isBroker
                  ? {
                      title: getLocalizedText(
                        "follow_up.indexable_contracts.bulk_action.ask_owner",
                      ),
                      onClick: () => {
                        confirm({
                          title: getLocalizedText(
                            "indexation.confirm.bulk_ask_owner.title",
                          ),
                          info: getLocalizedText(
                            "indexation.confirm.bulk_ask_owner.info",
                          ),
                          type: "warning",
                          primaryActions: [
                            {
                              title: getLocalizedText(
                                "follow_up.indexable_contracts.bulk_action.ask_owner",
                              ),
                              onPress: () => {
                                setShowBulkAskOwnerModal(true);
                              },
                            },
                          ],
                        });
                      },
                    }
                  : undefined,
                {
                  title: getLocalizedText(
                    "follow_up.indexable_contracts.bulk_action.skip_index",
                  ),
                  onClick: () => {
                    confirm({
                      title: getLocalizedText(
                        "indexation.confirm.bulk_skip_index.title",
                      ),
                      info: getLocalizedText(
                        "indexation.confirm.bulk_skip_index.info",
                      ),
                      type: "warning",
                      primaryActions: [
                        {
                          title: getLocalizedText(
                            "follow_up.indexable_contracts.bulk_action.skip_index",
                          ),
                          onPress: () => {
                            setShowBulkSkipIndexModal(true);
                          },
                        },
                      ],
                    });
                  },
                },
              ]).map(item => (
                <Button
                  onClick={item.onClick}
                  appearance={"primary"}
                  iconAfter={
                    <S.SelectedCount>
                      <div>{selectedIds.length}</div>
                    </S.SelectedCount>
                  }
                >
                  {item.title}
                </Button>
              ))}
            </Stack>

            <Button onClick={onDeselectAll} appearance="link">
              {getLocalizedText("system.clear_selection", {
                value: `${selectedIds.length}`,
              })}
            </Button>
          </Stack>

          <Spacer weight={ESpacerWeight.W16} />
        </>
      )}

      {contracts.length === 0 && !isFetchingContracts && (
        <EmptyState
          heading={ts.followUpIndexableContractsEmptyStateHeading()}
          icon="leaseContract"
        />
      )}

      {contracts.length > 0 && (
        <LoadingWrapper
          isLoading={contracts.length === 0 && (isFetchingContracts || false)}
        >
          <DataTable
            multiSelect={true}
            selectedIds={selectedIds}
            onSelectRow={(id: number | string) => {
              onSelectId(id as string);
            }}
            onDeselectRow={(id: number | string) => {
              onDeselectId(id as string);
            }}
            onSelectPage={(ids: (number | string)[]) => {
              onSelectIds(ids as string[]);
            }}
            onDeselectPage={(ids: (number | string)[]) => {
              onDeselectIds(ids as string[]);
            }}
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "action",
            ]}
            headings={[
              ts.followUpIndexableContractsIndexableAtHeading(),
              ts.followUpIndexableContractsAddressHeading(),
              ts.followUpIndexableContractsOwnersHeading(),
              ts.followUpIndexableContractsTenantsHeading(),
              ts.followUpIndexableContractsCurrentPriceHeading(),
              ts.followUpIndexableContractsStatusHeading(),
              "",
            ]}
            sortable={[false, false, false, false, false, false, false]}
            sortColumnIndex={0}
            onRowClick={handleClickRow}
            rows={contracts.map(contract => {
              const owners = contract.members.filter(member =>
                member.roles.includes(EContractMemberTypes.Owner),
              );
              const tenants = contract.members.filter(member =>
                member.roles.includes(EContractMemberTypes.Tenant),
              );

              const handleContractClick = () => {
                setQuery({ contractId: contract.id });
              };

              const isIndexable =
                contract.indexationStatus &&
                [
                  EContractIndexationStatus.Ready,
                  EContractIndexationStatus.OwnerAsked,
                  EContractIndexationStatus.OwnerApproved,
                  EContractIndexationStatus.OwnerDeclined,
                  EContractIndexationStatus.Muted,
                ].includes(contract.indexationStatus);

              const actions = [];

              if (isIndexable) {
                actions.push({
                  content: tsCommon.contractIndexIndexViewAction(),
                  onClick: handleContractClick,
                });

                actions.push({
                  content: tsCommon.contractIndexSkipAction(),
                  onClick: handleSkipIndex(contract.id),
                });

                if (!contract.roles.includes(EContractMemberTypes.Owner)) {
                  actions.push({
                    content: tsCommon.contractIndexInquiryAction(),
                    onClick: handleAskOwner(contract.id),
                  });
                }
              }

              return {
                id: contract.id,
                multiSelectDisabled: !isIndexable,
                content: [
                  contract.indexableAt
                    ? utils.date.format(contract.indexableAt)
                    : null,
                  <ContractsAddressX contract={contract} />,
                  join(owners.map(member => getName(member.account))),
                  join(tenants.map(member => getName(member.account))),
                  getContractPrice(contract),
                  <Lozenge
                    key={`${contract.id}-stage`}
                    appearance={getIndexationStatusVariation(
                      contract.indexationStatus,
                    )}
                  >
                    {getLocalizedText(
                      `follow_up.indexable_contracts.filter.stage.${contract.indexationStatus}.label`.toLowerCase(),
                    )}
                    {contract.lastIndexedAt &&
                      contract.indexationStatus ===
                        EContractIndexationStatus.NotReady &&
                      ` (${getLocalizedText("contract.last_indexed_at.label", {
                        value: utils.date.format(contract.lastIndexedAt),
                      })})`}
                  </Lozenge>,
                ],
                actions,
              };
            })}
            totalPages={totalPages}
          />
        </LoadingWrapper>
      )}

      {contracts.length === 0 && isFetchingContracts && (
        <Loading asDots={true} />
      )}

      {!!totalPages && totalPages > 1 && (
        <Pagination
          initialPage={query.page - 1}
          pageCount={totalPages}
          onPageChange={handlePageClick}
        />
      )}
    </>
  );

  return (
    <Page
      fullWidth
      title={ts.followUpIndexableContractsPageTitle()}
      metadata={ts.followUpIndexableContractsPageDescription()}
      breadcrumbs={{
        to: "/",
        content: getLocalizedText("system.back"),
      }}
    >
      <Card>
        <Stages
          stages={[
            {
              heading: ts.followUpIndexableContractsStageLabel({
                extra: { key: "indexable" },
              }),
              icon: "currencyEuroIncrease",
              count: indexableContractsCount,
              onClick: () => {
                setQuery({
                  contractId: undefined,
                  indexationStatuses: [
                    EContractIndexationStatus.Ready,
                    EContractIndexationStatus.OwnerApproved,
                    EContractIndexationStatus.OwnerDeclined,
                  ],
                  alreadyIndexed: undefined,
                  page: 1,
                });
                onDeselectAll();
              },
            },
            {
              heading: ts.followUpIndexableContractsStageLabel({
                extra: { key: "requested" },
              }),
              icon: "singleNeutralActionsQuestion",
              count: indexableContractsOwnerAskedCount,
              onClick: () => {
                setQuery({
                  contractId: undefined,
                  indexationStatuses: [EContractIndexationStatus.OwnerAsked],
                  alreadyIndexed: undefined,
                  page: 1,
                });
                onDeselectAll();
              },
            },
            {
              heading: ts.followUpIndexableContractsStageLabel({
                extra: { key: "indexed" },
              }),
              countDescription: getLocalizedText("system.contracts.active"),
              icon: "realEstateActionHouseCheck",
              count: indexedContracts,
              onClick: () => {
                setQuery({
                  contractId: undefined,
                  indexationStatuses: [EContractIndexationStatus.NotReady],
                  alreadyIndexed: EAlreadyIndexed.AlreadyIndexed,
                  page: 1,
                });
                onDeselectAll();
              },
            },
          ]}
        />
      </Card>
      <Card>{renderTable}</Card>
      {!!query.contractId && (
        <IndexContractModal
          contractId={query.contractId}
          onClose={handleModalClick}
        />
      )}
      {showBulkIndexModal && (
        <IndexContractBulkIndexModal
          contractIds={selectedIds as any as string[]}
          onClose={() => {
            setShowBulkIndexModal(false);
          }}
        />
      )}
      {showBulkAskOwnerModal && (
        <IndexContractBulkAskOwnerModal
          contractIds={selectedIds as any as string[]}
          onClose={() => {
            setShowBulkAskOwnerModal(false);
          }}
        />
      )}
      {showBulkSkipIndexModal && (
        <IndexContractBulkSkipIndexModal
          contractIds={selectedIds as any as string[]}
          onClose={() => {
            setShowBulkSkipIndexModal(false);
          }}
        />
      )}
    </Page>
  );
};
