var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Ajv from "ajv";
import { flatten, unflatten } from "flat";
import clone from "lodash/clone";
import isInteger from "lodash/isInteger";
import toPath from "lodash/toPath";
import { path as getFromPath } from "ramda";
import utils from "../../utils";
/**
 * Deeply set a value from in object via its path.
 * @see https://github.com/developit/linkstate
 */
export var setIn = function (obj, path, value) {
    var res = {};
    var resVal = res;
    var i = 0;
    var pathArray = toPath(path);
    for (; i < pathArray.length - 1; i++) {
        var currentPath = pathArray[i];
        var currentObj = getFromPath(pathArray.slice(0, i + 1), obj);
        if (resVal[currentPath]) {
            resVal = resVal[currentPath];
        }
        else if (currentObj) {
            resVal = resVal[currentPath] = clone(currentObj);
        }
        else {
            var nextPath = pathArray[i + 1];
            resVal = resVal[currentPath] =
                isInteger(nextPath) && Number(nextPath) >= 0 ? [] : {};
        }
    }
    // Return original object if new value is the same as current
    if ((i === 0 ? obj : resVal)[pathArray[i]] === value) {
        return obj;
    }
    if (value === undefined) {
        delete resVal[pathArray[i]];
    }
    else {
        resVal[pathArray[i]] = value;
    }
    var result = __assign(__assign({}, obj), res);
    // If the path array has a single element, the loop did not run.
    // Deleting on `resVal` had no effect in this scenario, so we delete on the result instead.
    if (i === 0 && value === undefined) {
        delete result[pathArray[i]];
    }
    return result;
};
/**
 * Transform Yup ValidationError to a more usable object
 */
export var yupToFormErrors = function (yupError) {
    var errors = {};
    yupError.inner.map(function (error) { return (errors[error.path] = error.message); });
    return errors;
};
/**
 * Validate a yup schema.
 */
export var validateYupSchema = function (values, schema) {
    var validateData = {};
    for (var k in values) {
        if (values.hasOwnProperty(k)) {
            var key = String(k);
            validateData[key] = values[key] !== "" ? values[key] : undefined;
        }
    }
    return schema.validateSync(validateData, {
        abortEarly: false,
    });
};
var translateKey = function (key) {
    if (!(key === null || key === void 0 ? void 0 : key.includes("."))) {
        return key;
    }
    var isMarkdown = key.search(/^markdown:(.*)$/g);
    if (isMarkdown > -1) {
        return utils.i18n.tm(key.replace("markdown:", ""));
    }
    else {
        return utils.i18n.t(key);
    }
};
export var selectTextOnFocus = function (_a) {
    var target = _a.target;
    target.select();
};
export var setCaretPosition = function (ctrl, pos) {
    // Modern browsers
    if (ctrl.setSelectionRange) {
        ctrl.setSelectionRange(pos, pos);
    }
    else if (ctrl.createTextRange) {
        // IE8 and below
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd("character", pos);
        range.moveStart("character", pos);
        range.select();
    }
};
export var parseSchema = function (schema) {
    var newSchema = clone(schema);
    var flatUiSchema = {};
    var flatSchema = flatten(schema);
    var schemaKeys = Object.keys(flatSchema);
    // get all Obvious translatable keys
    schemaKeys
        .filter(function (key) {
        return /enumNames.(\d)$/.test(key) ||
            ["title"].some(function (suffix) { return key.endsWith(suffix); });
    })
        .forEach(function (key) {
        newSchema = setIn(newSchema, key, translateKey(flatSchema[key]));
    });
    // Create ui map
    schemaKeys
        .filter(function (key) { return key.indexOf(".ui") >= 0; })
        .forEach(function (key) {
        var value = flatSchema[key];
        if (["ui:description", "ui:help"].some(function (suffix) { return key.endsWith(suffix); })) {
            value = translateKey(value);
        }
        var uiKey = key
            .replace(".ui.", ".")
            .replace(new RegExp(".properties", "g"), "")
            .replace(new RegExp("properties.", "g"), "")
            .replace(/steps.(\d)./g, "");
        flatUiSchema[uiKey] = value;
    });
    // get all condition keys
    // const conditionKeys = schemaKeys.filter((key: string) => key.indexOf('.condition') >= 0)
    // console.log({ conditionKeys })
    return [newSchema, unflatten(flatUiSchema)];
};
function isObject(thing) {
    return typeof thing === "object" && thing !== null && !Array.isArray(thing);
}
export var getUiOptions = function (uiSchema) {
    // get all passed options from ui:widget, ui:options, and ui:<optionName>
    return Object.keys(uiSchema)
        .filter(function (key) { return key.indexOf("ui:") === 0; })
        .reduce(function (options, key) {
        var _a;
        var value = uiSchema[key];
        if (key === "ui:widget" && isObject(value)) {
            // eslint-disable-next-line no-console
            console.warn("Setting options via ui:widget object is deprecated, use ui:options instead");
            return __assign(__assign(__assign({}, options), (value.options || {})), { widget: value.component });
        }
        if (key === "ui:options" && isObject(value)) {
            return __assign(__assign({}, options), value);
        }
        return __assign(__assign({}, options), (_a = {}, _a[key.substring(3)] = value, _a));
    }, {});
};
export var formatJsonSchemaError = function (errors) {
    if (!errors) {
        return;
    }
    var uniq = errors.filter(function (elem, pos, arr) { return arr.indexOf(elem) === pos; });
    var isArrayItemErrors = errors.length > 0 && errors.every(function (error) { return error.includes(":"); });
    if (isArrayItemErrors) {
        var arrayItemErrors_1 = {};
        uniq.forEach(function (item) {
            var splitted = item.split(":");
            arrayItemErrors_1[splitted[0]] = splitted[1];
        });
        return arrayItemErrors_1;
    }
    else {
        return uniq[0];
    }
};
export var validateSchema = function (schema, formData) {
    if (!formData) {
        return true;
    }
    var ajv = new Ajv({ allErrors: true, jsonPointers: true });
    var validation = ajv.compile(schema);
    var isValid = validation(utils.object.removeEmpty(clone(formData)));
    // utils.debug.log({ isValid, validation, formData })
    return { isValid: isValid, validation: validation };
};
export var getIdPath = function (id, idPrefix) {
    var excludes = ["items", "root"];
    // Optionally exclude idPrefix
    idPrefix && excludes.push(idPrefix);
    var path = id.split("_").filter(function (item) { return !excludes.includes(item); });
    return path;
};
export var removeFields = function (schema, fields) {
    var _a;
    if (fields === void 0) { fields = []; }
    var removeFrom = function (step) {
        if (!step.properties)
            return step;
        step.properties = utils.object.removeKeys(step.properties, fields);
        step.required &&
            (step.required = step.required.filter(function (requiredField) { return fields.indexOf(requiredField) < 0; }));
        step.rules &&
            (step.rules = step.rules.map(function (rule) {
                var rulesToRemove = Object.keys(rule.conditions).filter(function (condition) {
                    return fields.includes(condition);
                });
                // Remove rules associated with removed fields
                rulesToRemove.forEach(function (ruleToRemove) {
                    delete rule.conditions[ruleToRemove];
                });
                // Remove rule dependencies
                rule.event = rule.event.filter(function (event) {
                    var _a;
                    return ((_a = event.params) === null || _a === void 0 ? void 0 : _a.field)
                        ? !fields.includes(event.params.field)
                        : true;
                });
                return rule;
            }));
        return step;
    };
    (_a = schema.steps) === null || _a === void 0 ? void 0 : _a.forEach(function (step) {
        removeFrom(step);
    });
    if (schema.properties) {
        removeFrom(schema);
    }
    return schema;
};
