import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { EContractMemberTypes } from "../../types/contract.types";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.addLegalCase.types";
import { printUploadedFiles } from "./schema.addLegalCase.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [],
            properties: (_a = {},
                _a[EField.ContractInformation] = {
                    default: undefined,
                },
                _a[EField.Header + "3"] = {
                    default: undefined,
                },
                _a[EField.DamageInfo] = {
                    default: getValue(EField.DamageInfo, initialValues, defaultValues),
                },
                _a[EField.DamageDocuments] = {
                    default: getValue(EField.DamageDocuments, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    var contract = extraData.contract, contractCost = extraData.contractCost;
    return _a = {
            "rentio:title": getLocalizedText("rentio_legal.form.add.step_3"),
            "rentio:navigationTitle": getLocalizedText("TODO: Damages"),
            "rentio:trackingKey": "rentio-legal-case"
        },
        _a[EField.ContractInformation] = {
            "ui:field": ERentioFormField.HelpInfo,
            subtitle: getLocalizedText("rentio_legal.form.contract_info.details", {
                amount: contractCost,
                start_date: formatDate(contract.startDate),
                end_date: contract.stopDate ? formatDate(contract.stopDate) : "...",
                tenants: contract.members
                    .filter(function (m) { return m.roles.includes(EContractMemberTypes.Tenant); })
                    .map(function (_a) {
                    var account = _a.account;
                    return "".concat(account.firstname, " ").concat(account.lastname);
                })
                    .join(", "),
            }),
            title: getLocalizedText("rentio_legal.form.contract_info.title"),
        },
        _a[EField.Header + "3"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rentio_legal.form.damage_info"),
        },
        _a[EField.DamageInfo] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("rentio_legal.form.damage_info.description"),
            multiline: true,
            optional: true,
            formatSummary: function (value) {
                if (!value) {
                    return undefined;
                }
                return "".concat(value);
            },
        },
        _a[EField.DamageDocuments] = {
            "ui:field": ERentioFormField.DocumentSelectInline,
            title: getLocalizedText("rentio_legal.form.damage_info.documents"),
            multiple: true,
            useExistingDocuments: false,
            optional: true,
            formatSummary: printUploadedFiles,
            hideOnSummaryIfEmpty: true,
        },
        _a;
};
