import { useTheme } from "@rentiohq/web-shared-next/dist/themes";
import { ThemeMode } from "@rentiohq/web-shared-next/dist/utils/types/config";

export const UserCardStyles = () => {
  const theme = useTheme();

  return {
    wrapper: {
      p: 3,
      bgcolor:
        theme.palette.mode === ThemeMode.DARK
          ? "background.default"
          : "grey.50",
      border: "1px solid",
      borderColor: "divider",
    },
    content: {
      avatar: {
        bgcolor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.grey[500]}`,
        svg: {
          width: "38.4px",
          height: "38.4px",
          color: theme.palette.grey[500],
        },
        img: {
          width: "38.4px",
          height: "38.4px",
          objectFit: "contain",
        },
      },
      longText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block",
      },
      resendInvite: {
        color: theme.palette.success.main,
        cursor: "pointer",
        textDecoration: "underline",
        whiteSpace: "nowrap",
      },
      iconButton: {
        mt: -0.75,
        mr: -0.75,
      },
    },
  };
};
