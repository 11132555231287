import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getGroupOptions, getNameOptions, } from "../../InsertVariableModal/schemas/variableForm.utils";
import { EField } from "./schema.insertLoop.types";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return {
            required: [],
            properties: (_a = {},
                _a[EField.Header1] = {
                    default: undefined,
                },
                _a[EField.Name] = {
                    default: getValue(EField.Name, initialValues, defaultValues),
                    // @ts-ignore
                    options: getNameOptions({
                        fields: extraData.fields,
                        withNewOption: false,
                    }),
                    handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                        if (fieldName !== EField.Group) {
                            return { shouldSetNewValue: false };
                        }
                        if (formData[EField.Group] === undefined) {
                            return { shouldSetNewValue: false };
                        }
                        return {
                            shouldSetNewValue: true,
                            newValue: undefined,
                        };
                    },
                },
                _a[EField.Group] = {
                    default: getValue(EField.Group, initialValues, defaultValues),
                    // @ts-ignore
                    options: getGroupOptions({
                        fields: extraData.fields,
                    }),
                    // requiredIf: (formData: any) => formData[EField.Name] === OPTION_NEW,
                    // // @ts-ignore
                    // showIf: (formData: any) => formData[EField.Name] === OPTION_NEW,
                    handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                        if (fieldName !== EField.Name) {
                            return { shouldSetNewValue: false };
                        }
                        if (formData[EField.Name] === undefined) {
                            return { shouldSetNewValue: false };
                        }
                        return {
                            shouldSetNewValue: true,
                            newValue: undefined,
                        };
                    },
                },
                _a[EField.Header2] = {
                    default: undefined,
                },
                _a[EField.Separator] = {
                    default: getValue(EField.Separator, initialValues, defaultValues),
                },
                _a[EField.LastSeparator] = {
                    default: getValue(EField.LastSeparator, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {},
        _a[EField.Header1] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("editor.insert_custom.insert_loop.header.variable"),
        },
        _a[EField.Name] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("editor.insert_custom.insert_loop.field.variable"),
        },
        _a[EField.Group] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("editor.insert_custom.insert_loop.field.group"),
        },
        _a[EField.Header2] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("editor.insert_custom.insert_loop.header.meta"),
        },
        _a[EField.Separator] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("editor.insert_custom.insert_loop.field.separator"),
            placeholder: ", ",
            optional: true,
        },
        _a[EField.LastSeparator] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("editor.insert_custom.insert_loop.field.last_separator"),
            placeholder: " & ",
            optional: true,
        },
        _a);
};
export var getValidate = function (_) { return function (formData, errors) {
    if (!formData[EField.Name] && !formData[EField.Group]) {
        errors[EField.Name].addError(getLocalizedText("editor.insert_custom.insert_loop.field.error.select_variable_or_group"));
        errors[EField.Group].addError(getLocalizedText("editor.insert_custom.insert_loop.field.error.select_variable_or_group"));
    }
    return errors;
}; };
