import { jsx as _jsx } from "react/jsx-runtime";
import Link from "@mui/material/Link";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { EAssets } from "../../assets";
import { sidebarStateSelector } from "../../redux/selectors";
import { Icon } from "../../ui-components/Icon";
export var LogoSection = function () {
    var sidebarOpened = useSelector(sidebarStateSelector);
    return (_jsx(Link, { component: RouterLink, to: "properties", "aria-label": "theme-logo", children: _jsx(Icon, { path: sidebarOpened ? EAssets.FullLogoIcon : EAssets.LogoIcon, width: sidebarOpened ? "100px" : "40px", height: sidebarOpened ? "26px" : "40px" }) }));
};
