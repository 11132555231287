var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery } from "redux-saga/effects";
import logger from "../../logger";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralCreateFailureText, getGeneralCreateSuccessText, } from "../../utils/i18n/i18n.utils";
import { generateSaga } from "../generators/generator.saga";
import { createPaymentOrdersFailed, createPaymentOrdersStart, createPaymentOrdersSucceeded, getMissingKYCsFailed, getMissingKYCsStart, getMissingKYCsSucceeded, getPayeeBankAccountFailed, getPayeeBankAccountStart, getPayeeBankAccountSucceeded, getPayerBankAccountFailed, getPayerBankAccountStart, getPayerBankAccountSucceeded, } from "./paymentOrder.actions";
import * as paymentOrderApi from "./paymentOrder.api";
import { createPaymentOrders } from "./paymentOrder.api";
import { GENERATOR_PARAMS } from "./paymentOrder.constants";
function getPayeeBankAccountStartFlow(action) {
    var _a, id, onSuccess, onFailure, result, unknownError_1, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentOrderApi.getPayeeBankAccount, id)];
            case 2:
                result = _b.sent();
                return [4 /*yield*/, put(getPayeeBankAccountSucceeded.getAction({
                        id: id,
                        result: result,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(result);
                return [3 /*break*/, 6];
            case 4:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(getPayeeBankAccountFailed.getAction({ id: id, error: error }))];
            case 5:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getPayerBankAccountStartFlow(action) {
    var _a, id, onSuccess, onFailure, result, unknownError_2, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, id = _a.id, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentOrderApi.getPayerBankAccount, id)];
            case 2:
                result = _b.sent();
                return [4 /*yield*/, put(getPayerBankAccountSucceeded.getAction({
                        id: id,
                        result: result,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(result);
                return [3 /*break*/, 6];
            case 4:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(getPayerBankAccountFailed.getAction({ id: id, error: error }))];
            case 5:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getMissingKycsStartFlow(action) {
    var _a, propertyId, refetch, query, onSuccess, onFailure, result, unknownError_3, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, propertyId = _a.propertyId, refetch = _a.refetch, query = _a.query, onSuccess = _a.onSuccess, onFailure = _a.onFailure;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(paymentOrderApi.getMissingKYCs, query, propertyId)];
            case 2:
                result = _b.sent();
                return [4 /*yield*/, put(getMissingKYCsSucceeded.getAction({
                        query: query,
                        propertyId: propertyId,
                        refetch: refetch,
                        result: result,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(result);
                return [3 /*break*/, 6];
            case 4:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(getMissingKYCsFailed.getAction({ propertyId: propertyId, error: error }))];
            case 5:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function createPaymentOrdersStartFlow(action) {
    var _a, dataRaw, onSuccess, onFailure, customPath, data, response, unknownError_4, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, dataRaw = _a.data, onSuccess = _a.onSuccess, onFailure = _a.onFailure, customPath = _a.customPath;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                data = __assign({}, dataRaw);
                return [4 /*yield*/, call(createPaymentOrders, {
                        data: data,
                        customPath: customPath,
                    })];
            case 2:
                response = _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText(GENERATOR_PARAMS.entity),
                });
                return [4 /*yield*/, put(createPaymentOrdersSucceeded.getAction({
                        result: response.data,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(response.data);
                return [3 /*break*/, 6];
            case 4:
                unknownError_4 = _b.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                    message: getGeneralCreateFailureText(GENERATOR_PARAMS.entity),
                });
                return [4 /*yield*/, put(createPaymentOrdersFailed.getAction({
                        error: error,
                    }))];
            case 5:
                _b.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(createPaymentOrdersStart.type, createPaymentOrdersStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(getPayeeBankAccountStart.type, getPayeeBankAccountStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(getPayerBankAccountStart.type, getPayerBankAccountStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(getMissingKYCsStart.type, getMissingKycsStartFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, generateSaga(GENERATOR_PARAMS)];
            case 5:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
