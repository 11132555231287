var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/web-theme";
export var SIDEBAR_WIDTH = 72; // In pixels
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n\n  display: flex;\n  flex-direction: column;\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n\n  display: flex;\n  flex-direction: column;\n"])));
export var HeaderWrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var ContentWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  position: relative;\n  display: flex;\n"], ["\n  flex: 1;\n  position: relative;\n  display: flex;\n"])));
export var SidebarWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: absolute;\n  height: 100%;\n"], ["\n  position: absolute;\n  height: 100%;\n"])));
export var SidebarBackground = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  width: ", "px;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  background-color: ", ";\n  color: ", ";\n  box-shadow: inset -1px 0px 2px rgba(0, 0, 0, 0.1);\n"], ["\n  position: absolute;\n  width: ", "px;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  background-color: ", ";\n  color: ", ";\n  box-shadow: inset -1px 0px 2px rgba(0, 0, 0, 0.1);\n"])), SIDEBAR_WIDTH, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral20;
}, function (_a) {
    var theme = _a.theme;
    return theme.colors.neutral80;
});
export var NavigationWrap = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 100%;\n  overflow: scroll;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"], ["\n  height: 100%;\n  overflow: scroll;\n  scrollbar-width: none;\n  -ms-overflow-style: none;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n"])));
export var ChildrenWrap = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-left: ", "px;\n  flex: 1;\n  position: relative;\n"], ["\n  margin-left: ", "px;\n  flex: 1;\n  position: relative;\n"])), SIDEBAR_WIDTH);
export var ChildrenInnerWrap = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  position: absolute;\n  top: ", ";\n  right: 0;\n  bottom: 0;\n  left: 0;\n  overflow-y: scroll;\n"], ["\n  position: absolute;\n  top: ", ";\n  right: 0;\n  bottom: 0;\n  left: 0;\n  overflow-y: scroll;\n"])), function (_a) {
    var shouldShowBanner = _a.shouldShowBanner;
    return (shouldShowBanner ? "48px" : "0");
});
export var BannerWrap = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  width: 100%;\n  background-color: ", ";\n  color: white;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n  padding: 16px;\n  text-align: center;\n  align-self: center;\n  position: fixed;\n  top: 56px;\n  z-index: 100;\n"], ["\n  width: 100%;\n  background-color: ", ";\n  color: white;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n  padding: 16px;\n  text-align: center;\n  align-self: center;\n  position: fixed;\n  top: 56px;\n  z-index: 100;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.colors.warningText;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
