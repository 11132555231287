import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.moveRequestUpdate.types";

export const getSchema =
  (initialValues: { [key: string]: any }, _: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [],
      properties: {
        [EField.WaterMeterNumber]: {
          default: getValue(
            EField.WaterMeterNumber,
            initialValues,
            defaultValues,
          ),
        },
      },
    };
  };

export const uiSchema = (_: IExtraData): IUiSchema<EField> => ({
  [EField.WaterMeterNumber]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("move.field.water_meter_number.title"),
    optional: true,
  },
});
