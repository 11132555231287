var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import { onMultiple } from "../utils/reducer.utils";
import * as actions from "./moveRequest.actions";
export var REDUCER_KEY_MOVE_REQUEST = "moveRequest";
var getDefaultState = function () { return ({
    moveRequests: {},
    moveRequestsById: {},
    moveRequestsByPublicId: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(actions.getMoveRequest.types.START, function (state, _a) {
    var _b, _c;
    var publicId = _a.publicId, id = _a.id;
    var newState = {
        isFetching: true,
        fetchError: undefined,
    };
    return merge({}, state, id
        ? {
            moveRequestsById: (_b = {},
                _b[id] = newState,
                _b),
        }
        : {}, publicId
        ? {
            moveRequestsByPublicId: (_c = {},
                _c[publicId] = newState,
                _c),
        }
        : {});
});
reducer.on(actions.getMoveRequest.types.SUCCESS, function (state, _a) {
    var _b, _c, _d;
    var publicId = _a.publicId, id = _a.id, moveRequest = _a.moveRequest;
    var newState = {
        isFetching: false,
        fetchError: undefined,
    };
    return __assign(__assign({}, state), { moveRequests: __assign(__assign({}, state.moveRequests), (_b = {}, _b[moveRequest.id] = moveRequest, _b)), moveRequestsById: __assign(__assign({}, state.moveRequestsById), (id
            ? (_c = {},
                _c[id] = __assign(__assign({}, state.moveRequestsById[id]), newState),
                _c) : {})), moveRequestsByPublicId: __assign({}, (publicId
            ? (_d = {},
                _d[publicId] = __assign(__assign({}, state.moveRequestsByPublicId[publicId]), newState),
                _d) : {})) });
});
reducer.on(actions.getMoveRequest.types.FAILURE, function (state, _a) {
    var _b, _c;
    var publicId = _a.publicId, id = _a.id, error = _a.error;
    var newState = {
        isFetching: false,
        fetchError: error,
    };
    return merge({}, state, id
        ? {
            moveRequestsById: (_b = {},
                _b[id] = newState,
                _b),
        }
        : {}, publicId
        ? {
            moveRequestsByPublicId: (_c = {},
                _c[publicId] = newState,
                _c),
        }
        : {});
});
onMultiple(reducer, [
    actions.updateMoveRequest.types.START,
    actions.commitMoveRequest.types.START,
], function (state, _a) {
    var _b, _c;
    var id = _a.id, publicId = _a.publicId;
    var newState = {
        isUpdating: true,
        updateError: undefined,
    };
    return merge({}, state, id
        ? {
            moveRequestsById: (_b = {},
                _b[id] = newState,
                _b),
        }
        : {}, {
        moveRequestsByPublicId: (_c = {},
            _c[publicId] = newState,
            _c),
    });
});
onMultiple(reducer, [
    actions.updateMoveRequest.types.SUCCESS,
    actions.commitMoveRequest.types.SUCCESS,
], function (state, _a) {
    var _b, _c, _d;
    var id = _a.id, publicId = _a.publicId, moveRequest = _a.moveRequest;
    var newState = {
        isUpdating: false,
        updateError: undefined,
    };
    return __assign(__assign({}, state), { moveRequests: __assign(__assign({}, state.moveRequests), (_b = {}, _b[moveRequest.id] = moveRequest, _b)), moveRequestsById: __assign(__assign({}, state.moveRequestsById), (id
            ? (_c = {},
                _c[id] = __assign(__assign({}, state.moveRequestsById[id]), newState),
                _c) : {})), moveRequestsByPublicId: __assign({}, (publicId
            ? (_d = {},
                _d[publicId] = __assign(__assign({}, state.moveRequestsByPublicId[publicId]), newState),
                _d) : {})) });
});
onMultiple(reducer, [
    actions.updateMoveRequest.types.FAILURE,
    actions.commitMoveRequest.types.FAILURE,
], function (state, _a) {
    var _b, _c;
    var id = _a.id, publicId = _a.publicId, error = _a.error;
    var newState = {
        isUpdating: false,
        updateError: error,
    };
    return merge({}, state, id
        ? {
            moveRequestsById: (_b = {},
                _b[id] = newState,
                _b),
        }
        : {}, {
        moveRequestsByPublicId: (_c = {},
            _c[publicId] = newState,
            _c),
    });
});
reducer.on(actions.requestCall.types.START, function (state, _a) {
    var _b;
    var publicId = _a.publicId;
    return merge({}, state, {
        moveRequestsById: (_b = {},
            _b[publicId] = {
                isRequestingCall: true,
                requestCallError: undefined,
            },
            _b),
    });
});
reducer.on(actions.requestCall.types.SUCCESS, function (state, _a) {
    var _b;
    var publicId = _a.publicId;
    return merge({}, state, {
        moveRequestsById: (_b = {},
            _b[publicId] = {
                isRequestingCall: false,
                requestCallError: undefined,
            },
            _b),
    });
});
reducer.on(actions.requestCall.types.FAILURE, function (state, _a) {
    var _b;
    var publicId = _a.publicId, error = _a.error;
    return merge({}, state, {
        moveRequestsById: (_b = {},
            _b[publicId] = {
                isRequestingCall: false,
                requestCallError: error,
            },
            _b),
    });
});
// eslint-disable-next-line import/no-default-export
export default reducer;
