var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { logError } from "../../logger/logger";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralUpdateFailureText, getGeneralUpdateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import { getCreateSaga } from "../generic/create.saga";
import { getDeleteSaga } from "../generic/delete.saga";
import { getGetDetailSaga } from "../generic/detail.saga";
import { getGetPagedSaga } from "../generic/paged.saga";
import { getUpdateSaga } from "../generic/update.saga";
import * as templateDocumentActions from "./templateDocument.actions";
import * as templateDocumentApi from "./templateDocument.api";
import CONFIG from "./templateDocument.config";
function getTemplateDocumentDetailStartFlow(_a) {
    var id, onSuccess, templateDocumentDetail, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id, onSuccess = payload.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(templateDocumentApi.getTemplateDocumentDetail, { id: id })];
            case 2:
                templateDocumentDetail = (_b.sent()).data;
                return [4 /*yield*/, put(templateDocumentActions.getTemplateDocumentDetail.actions.success({
                        id: id,
                        templateDocumentDetail: templateDocumentDetail,
                    }))];
            case 3:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(templateDocumentDetail);
                return [3 /*break*/, 6];
            case 4:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logError({ error: error });
                return [4 /*yield*/, put(templateDocumentActions.getTemplateDocumentDetail.actions.failure({
                        error: error,
                        id: id,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function updateTemplateDocumentDetailStartFlow(_a) {
    var id, data, onSuccess, _b, suppressSuccessMessage, updatedData, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                id = payload.id, data = payload.data, onSuccess = payload.onSuccess, _b = payload.suppressSuccessMessage, suppressSuccessMessage = _b === void 0 ? false : _b;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(templateDocumentApi.updateTemplateDocument, { id: id, data: data })];
            case 2:
                _c.sent();
                return [4 /*yield*/, call(templateDocumentApi.getTemplateDocumentDetail, { id: id })];
            case 3:
                updatedData = (_c.sent()).data;
                return [4 /*yield*/, put(templateDocumentActions.updateTemplateDocumentDetail.actions.success({
                        id: id,
                        data: updatedData,
                    }))];
            case 4:
                _c.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(updatedData);
                if (!suppressSuccessMessage) {
                    showAlert({
                        type: "success",
                        message: getGeneralUpdateSuccessText("template_document"),
                    });
                }
                return [3 /*break*/, 7];
            case 5:
                unknownError_2 = _c.sent();
                error = unknownError_2;
                logError({ error: error });
                return [4 /*yield*/, put(templateDocumentActions.updateTemplateDocumentDetail.actions.failure({
                        error: error,
                        id: id,
                    }))];
            case 6:
                _c.sent();
                showAlert({
                    type: "error",
                    error: error,
                    message: getGeneralUpdateFailureText("template_document"),
                });
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function generateTemplateDocumentPdfStartFlow(_a) {
    var id, data, onSuccess, onFailure, customSuccessMessage, _b, suppressSuccessMessage, _c, suppressErrorMessage, templateDocument, unknownError_3, error;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                id = payload.id, data = payload.data, onSuccess = payload.onSuccess, onFailure = payload.onFailure, customSuccessMessage = payload.customSuccessMessage, _b = payload.suppressSuccessMessage, suppressSuccessMessage = _b === void 0 ? false : _b, _c = payload.suppressErrorMessage, suppressErrorMessage = _c === void 0 ? false : _c;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(templateDocumentApi.generateTemplateDocumentPdf, { id: id, data: data })];
            case 2:
                templateDocument = (_d.sent()).data;
                return [4 /*yield*/, put(templateDocumentActions.generateTemplateDocumentPdf.actions.success({
                        id: id,
                    }))];
            case 3:
                _d.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(templateDocument);
                if (!suppressSuccessMessage) {
                    showAlert({
                        type: "success",
                        message: customSuccessMessage ||
                            getLocalizedText("editor.template_document.generate_pdf.alert.success"),
                    });
                }
                return [3 /*break*/, 6];
            case 4:
                unknownError_3 = _d.sent();
                error = unknownError_3;
                logError({ error: error });
                return [4 /*yield*/, put(templateDocumentActions.generateTemplateDocumentPdf.actions.failure({
                        error: error,
                        id: id,
                    }))];
            case 5:
                _d.sent();
                if (!suppressErrorMessage) {
                    showAlert({
                        type: "error",
                        error: error,
                        message: getGeneralUpdateFailureText("template_document"),
                    });
                }
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(templateDocumentActions.create.types.START, getCreateSaga({
                    entityName: CONFIG.ENTITY_NAME,
                    create: templateDocumentApi.createTemplateDocument,
                    getDetail: templateDocumentApi.getTemplateDocument,
                }))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateDocumentActions.update.types.START, getUpdateSaga({
                        entityName: CONFIG.ENTITY_NAME,
                        update: templateDocumentApi.updateTemplateDocument,
                        getDetail: templateDocumentApi.getTemplateDocument,
                    }))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateDocumentActions.deleteObject.types.START, getDeleteSaga({
                        entityName: CONFIG.ENTITY_NAME,
                        deleteObject: templateDocumentApi.deleteTemplateDocument,
                    }))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateDocumentActions.getDetail.types.START, getGetDetailSaga({
                        entityName: CONFIG.ENTITY_NAME,
                        getDetail: templateDocumentApi.getTemplateDocument,
                    }))];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeEvery(templateDocumentActions.getPaged.types.START, getGetPagedSaga({
                        entityName: CONFIG.ENTITY_NAME,
                        getPaged: templateDocumentApi.getTemplateDocuments,
                    }))];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateDocumentActions.getTemplateDocumentDetail.types.START, getTemplateDocumentDetailStartFlow)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateDocumentActions.updateTemplateDocumentDetail.types.START, updateTemplateDocumentDetailStartFlow)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateDocumentActions.generateTemplateDocumentPdf.types.START, generateTemplateDocumentPdfStartFlow)];
            case 8:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line import/no-default-export
export default saga;
