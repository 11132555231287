var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import * as React from "react";
import { Icon } from "../../components";
import { ESpacings } from "../Grid";
import { TextStyle } from "../TextStyle";
import { StyledBanner, StyledBannerContentWrapper, StyledBannerDismiss, StyledBannerIconWrapper, VariationToColor, } from "./Banner.styled";
var Banner = /** @class */ (function (_super) {
    __extends(Banner, _super);
    function Banner() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isVisible: true,
        };
        _this.handleDismiss = function () {
            _this.setState({ isVisible: false });
        };
        return _this;
    }
    Banner.prototype.render = function () {
        var _a = this.props, children = _a.children, _b = _a.hasDismiss, hasDismiss = _b === void 0 ? true : _b, _c = _a.variation, variation = _c === void 0 ? "info" : _c, icon = _a.icon;
        var isVisible = this.state.isVisible;
        return isVisible ? (_jsxs(StyledBanner, { hasDismiss: hasDismiss, variation: variation, children: [hasDismiss && (_jsx(StyledBannerDismiss, { onClick: this.handleDismiss, appearance: "plain", ariaLabel: "Dismiss notification", children: _jsx(Icon, { source: "close" }) })), icon && (_jsx(StyledBannerIconWrapper, { children: _jsx(Icon, { source: icon, color: VariationToColor[variation] }) })), _jsxs(StyledBannerContentWrapper, { children: [this.props.title && (_jsx(Box, { mb: ESpacings.tight, children: _jsx(TextStyle, { variation: "strong", children: this.props.title }) })), children] })] })) : null;
    };
    return Banner;
}(React.PureComponent));
export { Banner };
