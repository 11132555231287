import { Box } from "@rebass/grid";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import * as contractUtils from "@rentiohq/shared-frontend/dist/utils/contract.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Checkbox,
  ESpacings,
  Grid,
  Lozenge,
  ResourceListItem,
  TextStyle,
  TLozengeAppearance,
  Tooltip,
} from "@rentiohq/web-shared/dist/components";
import { TAppearance } from "@rentiohq/web-shared/dist/types";
import React from "react";
import { ActivityIndicator } from "react-native";

export enum EActionStatus {
  Loading = "loading",
  Incomplete = "incomplete",
  PendingRentio = "pending_rentio",
  CompletedInRentio = "completed_rentio",
  CompletedManually = "completed_manually",
  // Rename to action needed in Rentio
  UrgentActionRentio = "urgent_action_rentio",
}

export interface IChecklistItem {
  actionStatus: EActionStatus;
  actionStatusText?: string;
  onChangeActionStatus?: (currentActionStatus: EActionStatus) => void;
  title: string;
  content: React.ReactNode;
  rightContent?: React.ReactNode;
  onClick?: () => void;
  link?: string;
}

interface IProps {
  item: IChecklistItem;
  contract: IContract;
}

export const ChecklistItem = (props: IProps) => {
  const { item, contract } = props;

  const {
    actionStatus,
    onChangeActionStatus,
    actionStatusText,
    onClick,
    link,
  } = item;

  const contractIsEnded = contractUtils.isEndedContract(contract);

  let checked = false;
  let checkboxAppearance: TAppearance = "default";
  let lozengeAppearanceForActionStatus: TLozengeAppearance = "default";
  let tooltipContent = getLocalizedText(
    `contract_action.manual.status.${actionStatus}`,
  );

  switch (actionStatus) {
    case EActionStatus.Incomplete:
      // checked = false;
      checkboxAppearance = "default";
      // lozengeAppearanceForActionStatus = "default";
      break;

    case EActionStatus.PendingRentio:
      // checked = true;
      checkboxAppearance = "warning";
      lozengeAppearanceForActionStatus = "warning";
      break;

    case EActionStatus.CompletedInRentio:
      checked = true;
      checkboxAppearance = "success";
      lozengeAppearanceForActionStatus = "success";
      break;

    case EActionStatus.CompletedManually:
      checked = true;
      checkboxAppearance = "success";
      lozengeAppearanceForActionStatus = "default";
      break;

    case EActionStatus.UrgentActionRentio:
      checkboxAppearance = "default";
      lozengeAppearanceForActionStatus = "warning";
      break;

    default:
  }

  const checkbox = (
    <Checkbox
      onChangeValue={(e: any) => {
        e.preventDefault?.();
        e.stopPropagation?.();
        e.stopImmediatePropagation?.();
        e.nativeEvent?.stopImmediatePropagation?.();

        if (actionStatus === EActionStatus.CompletedInRentio) {
          return;
        }

        if (contractIsEnded) {
          return;
        }

        onChangeActionStatus?.(actionStatus);
      }}
      error={false}
      checked={checked}
      appearance={checkboxAppearance}
    />
  );

  let media = null;
  switch (actionStatus) {
    case EActionStatus.Loading:
      media = (
        <div>
          <ActivityIndicator color={"gray"} size={18} />
        </div>
      );
      break;

    case EActionStatus.PendingRentio:
    case EActionStatus.CompletedInRentio:
    case EActionStatus.CompletedManually:
      media = <Tooltip tooltipContent={tooltipContent}>{checkbox}</Tooltip>;
      break;

    default:
      media = checkbox;
  }

  return (
    <ResourceListItem
      item={item}
      boxProps={{ py: ESpacings.base, px: ESpacings.loose }}
      onClick={onClick}
      link={link}
      media={media}
    >
      <Grid
        alignItems="center"
        justifyContent="space-between"
        flexWrap="nowrap"
      >
        <Grid.Item>
          <Box mb={ESpacings.extraTight}>
            <TextStyle element="div" variation="strong">
              <span style={{ display: "inline-block", paddingBottom: 3 }}>
                {item.title}
              </span>
              {actionStatusText && (
                <>
                  {" "}
                  <Lozenge appearance={lozengeAppearanceForActionStatus}>
                    {actionStatusText}
                  </Lozenge>
                </>
              )}
            </TextStyle>
          </Box>
          {item.content && (
            <TextStyle element="div" variation="subdued">
              {item.content}
            </TextStyle>
          )}
        </Grid.Item>
        {item.rightContent && (
          <Grid.Item flex="0 0 auto">{item.rightContent}</Grid.Item>
        )}
      </Grid>
    </ResourceListItem>
  );
};
