import * as rentDepositActions from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.actions";
import * as rentDepositSelectors from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.selectors";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { ERentDepositStatus } from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { ts } from "../../../../services";
import StatusDot from "../StatusDot";
import { EStatus } from "../StatusDot/StatusDot.types";

interface IProps {
  contract: IContract;
}

const StatusDotContractRentDeposit = (props: IProps) => {
  const { contract } = props;

  // Helpers
  const rentDepositsIdentifier = `contract-${contract.id}`;

  // Redux
  const dispatch = useDispatch();
  const rentDeposits = useSelector((state: IRootStore) =>
    rentDepositSelectors.getRentDeposits(state, rentDepositsIdentifier),
  );
  const isFetching = useSelector((state: IRootStore) =>
    rentDepositSelectors.isFetchingRentDeposits(state, rentDepositsIdentifier),
  );

  // Lifecycle
  React.useEffect(() => {
    if (contract.manuallyCompletedRentDeposit) {
      return;
    }

    if (isFetching) {
      return;
    }

    if (rentDeposits && rentDeposits.length > 0) {
      return;
    }

    dispatch(
      rentDepositActions.getRentDeposits.actions.start({
        identifier: rentDepositsIdentifier,
        refetch: true,
        extraFilterData: {
          where: {
            basicContractId: contract.id,
            status: { neq: ERentDepositStatus.Cancelled },
          },
          order: "createdAt DESC",
        },
        limit: 1,
      }),
    );
  }, [contract]);

  // Helpers
  const getStatus = () => {
    if (contract.manuallyCompletedRentDeposit) {
      return EStatus.Success;
    }

    if (!rentDeposits) {
      return EStatus.None;
    }

    if (rentDeposits.length === 0) {
      return EStatus.Error;
    }

    const rentDeposit = rentDeposits[0];
    switch (rentDeposit.status) {
      case ERentDepositStatus.Established:
      case ERentDepositStatus.Released:
        return EStatus.Success;

      default:
        return EStatus.Warning;
    }
  };

  // Render
  const renderTooltipContent = () => {
    if (contract.manuallyCompletedRentDeposit) {
      return getLocalizedText(contract.manuallyCompletedRentDeposit);
    }

    if (!rentDeposits) {
      return undefined;
    }

    if (rentDeposits.length === 0) {
      return getLocalizedText("status.rent_deposits.empty");
    }

    const rentDeposit = rentDeposits[0];
    return ts.followUpRentDepositsFilterStatusLabel({
      extra: { key: rentDeposit.status },
    });
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={renderTooltipContent()} />
  );
};

// eslint-disable-next-line import/no-default-export
export default StatusDotContractRentDeposit;
