import { Route } from "react-router-dom";
import { ContactCostCenters } from "../ContactCostCenters";
import { ContactDashboard } from "../ContactDashboard";
import { ContactDocuments } from "../ContactDocuments";
import { ContactGeneralLedgerAccounts } from "../ContactGeneralLedgerAccounts";
import { ContactHistory } from "../ContactHistory";
import { DuplicateContacts } from "../DuplicateContacts";
import { ContactDetail } from "./ContactDetail";

export const getContactDetailScene = () => (
  <Route path=":contactId" element={<ContactDetail />}>
    <Route index element={<ContactDashboard />} />

    <Route path="documents" element={<ContactDocuments />} />

    <Route path="history" element={<ContactHistory />} />

    <Route path="duplicate-contacts" element={<DuplicateContacts />} />

    <Route path="cost-centers" element={<ContactCostCenters />} />

    <Route
      path="general-ledger-accounts"
      element={<ContactGeneralLedgerAccounts />}
    />
  </Route>
);
