import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.registrationAddDocument.types";

export const getSchema =
  (
    initialValues: {
      [key: string]: unknown;
    },
    _: IExtraData,
  ) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    return {
      required: [EField.SignedAt],
      properties: {
        title1: {
          default: undefined,
        },
        [EField.SignedAt]: {
          default: getValue(EField.SignedAt, initialValues, defaultValues),
        },
      },
    };
  };

export const uiSchema = (_: IExtraData): IUiSchema<EField> => {
  return {
    "rentio:title": utils.i18n.t(
      "registration_property_inspection_report.create.form.step.report_details",
    ),
    title1: {
      "ui:field": ERentioFormField.SectionHeader,
      title: utils.i18n.t("registration.create.form.field.contract_meta_title"),
    },
    [EField.SignedAt]: {
      "ui:field": ERentioFormField.Date,
      title: getLocalizedText(
        "registration.create.form.field.contract_signed_at",
      ),
      maxDate: new Date(),
      formatSummary: formData => (formData ? formatDate(formData) : undefined),
    },
  };
};
