import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { StyledHelpText } from "../../Form/Form.styled";
import { Labelled } from "../../Labelled";
import { SegmentedControl } from "../../SegmentedControl";
export var SegmentField = function (_a) {
    var name = _a.name, schema = _a.schema, uiSchema = _a.uiSchema, idSchema = _a.idSchema, formData = _a.formData, formContext = _a.formContext, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, required = _a.required, onChange = _a.onChange;
    // @ts-ignore TODO: Typescript upgrade
    var title = getFieldTitle(uiSchema, formContext);
    // @ts-ignore TODO: Typescript upgrade
    var info = getFieldInfo(uiSchema, formContext);
    var isDisabled = uiSchema.isDisabled, _c = uiSchema.optional, optional = _c === void 0 ? false : _c, size = uiSchema.size;
    var error = rawErrors[0];
    var _d = schema.options, options = _d === void 0 ? [] : _d;
    var uiDisabled = isDisabled
        ? isDisabled(formContext.getAllFormData())
        : undefined;
    var handleClick = function (newId) {
        onChange(newId);
        if (formContext.onFieldChange) {
            setTimeout(function () {
                formContext.onFieldChange(name, newId);
            }, 0);
        }
    };
    var fieldMarkup = (_jsxs(_Fragment, { children: [_jsx(SegmentedControl, { name: name, options: options, selected: formData, onChange: handleClick, error: error, disabled: uiDisabled, size: size }), info && _jsx(StyledHelpText, { children: info })] }));
    var handlePressExtraInfo = function () {
        if (formContext.onPressExtraInfo) {
            formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
        }
    };
    return (_jsx(_Fragment, { children: title ? (_jsx(Labelled, { label: title, error: error, optional: optional, onPressExtraInfo: uiSchema.extraInfoUrl && handlePressExtraInfo, children: fieldMarkup })) : (_jsx(_Fragment, { children: fieldMarkup })) }));
};
