import { Divider, Stack } from "@rentiohq/web-shared-next/dist/ui-components";
import { DocumentListItem } from "../DocumentListItem";
interface IProps {
  documentsInPackageIds: string[];
}

export const DocumentList = ({ documentsInPackageIds }: IProps) => {
  return (
    <Stack
      direction="column"
      gap={2}
      divider={<Divider orientation="horizontal" />}
    >
      {documentsInPackageIds.map(id => (
        <DocumentListItem key={id} documentId={id} />
      ))}
    </Stack>
  );
};
