import { useTheme } from "@mui/material/styles";
export var MainLayoutStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            display: "flex",
            ".MuiButton-outlinedPrimary": {
                borderColor: theme.palette.success.main,
            },
            ".MuiButton-outlinedWarning": {
                borderColor: theme.palette.warning.main,
            },
        },
        content: {
            appBar: {
                bgcolor: theme.palette.grey[100],
            },
            toolbar: {
                p: 2,
            },
            container: {
                px: { xs: 0 },
            },
        },
    };
};
