var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { EContactInitialType } from "../../types/contact.types";
import { EField } from "./schema.addContact.types";
export var getContact = function (formDataRaw, contactId, baseAccountId, prefilledAddress, optionalFields) {
    var _a = formDataRaw, _b = EField.PrefillAddress, _c = _a[_b], prefillAddressRaw = _c === void 0 ? "yes" : _c, _d = EField.SendInvite, _ = _a[_d], _e = EField.IdType, __ = _a[_e], _f = EField.Company, company = _a[_f], _g = EField.InitialType, initialType = _a[_g], _h = EField.Address, address = _a[_h], _j = EField.PlaceOfBirth, placeOfBirth = _a[_j], _k = EField.ForeignIdentityNumber, foreignIdentityNumber = _a[_k], _l = EField.NationalRegisterNumber, nationalRegisterNumber = _a[_l], formData = __rest(_a, [typeof _b === "symbol" ? _b : _b + "", typeof _d === "symbol" ? _d : _d + "", typeof _e === "symbol" ? _e : _e + "", typeof _f === "symbol" ? _f : _f + "", typeof _g === "symbol" ? _g : _g + "", typeof _h === "symbol" ? _h : _h + "", typeof _j === "symbol" ? _j : _j + "", typeof _k === "symbol" ? _k : _k + "", typeof _l === "symbol" ? _l : _l + ""]);
    var contact = __assign(__assign({}, formData), { baseAccountId: baseAccountId || null, company: company });
    optionalFields === null || optionalFields === void 0 ? void 0 : optionalFields.forEach(function (field) {
        // Not all fields match with contact property
        // @ts-ignore
        if (contact[field] === undefined) {
            // @ts-ignore
            contact[field] = null;
        }
    });
    // Fields on new contact
    if (!contactId) {
        contact = __assign(__assign({}, contact), { isCompany: !!company });
        if (initialType !== EContactInitialType.Other) {
            contact.initialType = initialType;
        }
        if (prefillAddressRaw === "yes" && prefilledAddress) {
            contact = __assign(__assign({}, contact), prefilledAddress);
        }
    }
    if (address) {
        contact = __assign(__assign(__assign({}, contact), address), { countryId: address.country });
    }
    if (placeOfBirth) {
        contact = __assign(__assign({}, contact), { placeOfBirth: placeOfBirth.city, countryOfBirth: placeOfBirth.country });
    }
    if (nationalRegisterNumber) {
        contact.nationalRegisterNumber = nationalRegisterNumber;
        contact.foreignIdentityNumber = null;
    }
    else if (foreignIdentityNumber) {
        contact.nationalRegisterNumber = null;
        contact.foreignIdentityNumber = foreignIdentityNumber;
    }
    return contact;
};
