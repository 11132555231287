import {
  createTaskForm,
  IExtraData,
} from "@rentiohq/shared-frontend/dist/forms/createTask";
// import createTaskSchemas from "@rentiohq/shared-frontend/dist/forms/createTask";
// import {
//   isPerformingSubmitActionSelector,
//   performingSubmitActionErrorSelector,
//   performingSubmitActionResultSelector,
//   submitCreateActionCreator,
//   submitUpdateActionCreator,
// } from "@rentiohq/shared-frontend/dist/forms/createTask/schema.createTask.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { taskModule } from "@rentiohq/shared-frontend/dist/reduxV2/task";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { ITask } from "@rentiohq/shared-frontend/dist/types/task.types";
import { getGroupActionsMembers } from "@rentiohq/shared-frontend/dist/utils/groupAction.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  MultiStepForm as Form,
  Loading,
} from "@rentiohq/web-shared/dist/components";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { PREFERENCE_KEY_GROUP_ACTIONS_PROPERTY_IDS } from "components/GroupActionsModal/GroupActionsModal";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import usePreference from "scenes/Settings/hooks/usePreference";
import * as ts from "../../../../services/translationService";

const formId = generateFormId();

interface IProps {
  taskId?: number;
  propertyId?: number;
  isKeypoint?: boolean;
  isGroupActions?: boolean;
}

export const TaskForm = (props: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasKeypointRepair = brokerHooks.useBrokerFeature(
    EBrokerFeature.KeypointRepair,
  );
  const { broker, isBroker } = authHooks.useSelf();
  const { employees, isFetching: isFetchingEmployees } =
    brokerHooks.useGetBrokerEmployees();

  const {
    taskId,
    propertyId,
    isKeypoint = false,
    isGroupActions = false,
  } = props;

  const { detail: task, isFetching } = taskModule.hooks.useDetail({
    id: taskId,
  });

  const [groupActionPropertyIds, _, clearGroupActionPropertyIds] =
    usePreference<number[]>({
      preferenceKey: PREFERENCE_KEY_GROUP_ACTIONS_PROPERTY_IDS,
      preferencePersistScope: EPreferencePersistScope.InMemory,
    });

  const groupActions = isGroupActions ? getGroupActionsMembers() : [];

  // Redux
  const contactMeMaster = useSelector((state: IPartialRootState) =>
    contactSelectors.getContactMeMaster(state),
  );
  const property = useSelector((state: IPartialRootState) =>
    propertyId ? propertySelectors.getDetail(state, propertyId) : undefined,
  );

  const payerContact = useSelector((state: IPartialRootState) =>
    task ? contactSelectors.getContact(state, task?.payerContactId) : undefined,
  );

  const repairContact = useSelector((state: IPartialRootState) =>
    task
      ? contactSelectors.getContact(state, task?.repairContactId)
      : undefined,
  );

  const getActiveStep = () => {
    if (taskId) {
      return "summary";
    }
  };

  useEffect(() => {
    if (task) {
      dispatch(
        contactActions.getContacts.actions.start({
          refetch: false,
          filterData: {
            where: {
              id: { inq: [task.payerContactId, task.repairContactId] },
            },
          },
        }),
      );
    }
  }, [task]);

  // Render
  if (isFetching || isFetchingEmployees || (isBroker && !broker)) {
    return <Loading />;
  }

  if (taskId && !task) {
    return null;
  }

  if (!contactMeMaster) {
    return null;
  }

  const executors = [];
  const followers = [];

  if (contactMeMaster) {
    if (isKeypoint) {
      followers.push(contactMeMaster.accountId);
    } else {
      executors.push(contactMeMaster.accountId);
    }
  }

  const extraData: IExtraData = taskId
    ? {
        task,
        taskId,
        keypoint: hasKeypointRepair ? isKeypoint : false,
        payerAccountId: payerContact?.accountId || undefined,
        repairAccountId: repairContact?.accountId || undefined,
        broker,
        employees,
        isGroupActions,
      }
    : {
        executors,
        followers,
        employees,
        propertyId,
        property,
        broker,
        keypoint: hasKeypointRepair ? isKeypoint : false,
        groupActions,
        isGroupActions,

        propertyIds: groupActionPropertyIds,
        clearGroupActionPropertyIds,
        onGroupActionSuccess: () => {
          navigate("/tasks/");
        },
      };

  return (
    <Card>
      <Form
        formId={`task-form-${formId}`}
        schemas={createTaskForm.formList(extraData)}
        withSummary={true}
        activeStep={getActiveStep()}
        withAside={true}
        isPerformingSubmitActionSelector={createTaskForm.isPerformingSubmitActionSelector?.(
          extraData,
        )}
        performingSubmitActionErrorSelector={createTaskForm.performingSubmitActionErrorSelector?.(
          extraData,
        )}
        performingSubmitActionResultSelector={createTaskForm.performingSubmitActionResultSelector?.(
          extraData,
        )}
        submitActionCreator={createTaskForm.submitCreateActionCreator?.(
          extraData,
        )}
        submitLabel={
          isKeypoint
            ? getLocalizedText("task.repair.keypoint.send")
            : ts.system("save")
        }
        onSuccess={(updatedTask?: ITask) => {
          const taskToCheck = task || updatedTask;

          if (!taskToCheck) {
            return;
          }
          navigate(`/tasks/${taskToCheck.id}`);
        }}
      />
    </Card>
  );
};
