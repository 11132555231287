import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getConnectionLabel } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { Button, ContactConnectionIndicator, Grid, Icon } from "..";
import * as t from "../../services/translationService";
export var ContactMeta = function (_a) {
    var contact = _a.contact;
    if (!contact)
        return null;
    var email = contact.email, phone = contact.phone, VATNumber = contact.VATNumber;
    var mail = function () {
        window.location.href = "mailto:".concat(email);
    };
    var call = function () {
        window.location.href = "tel:".concat(phone);
    };
    return (_jsxs(Grid, { alignItems: "center", children: [_jsxs(Grid.Item, { children: [_jsx(ContactConnectionIndicator, { contact: contact, showTooltip: false }), getConnectionLabel({
                        contact: contact,
                        showConnectionDate: true,
                    })] }), contact.isCompany && VATNumber && (_jsxs(Grid.Item, { children: [t.system("vat_number"), ": ", VATNumber] })), phone && (_jsx(Grid.Item, { children: _jsxs(Grid, { spacing: "extraTight", alignItems: "center", children: [_jsx(Grid.Item, { children: _jsx(Icon, { source: "smartphone" }) }), _jsx(Grid.Item, { children: _jsx(Button, { appearance: "reset", onClick: call, children: formatPhone(phone) }) })] }) })), email && (_jsx(Grid.Item, { children: _jsxs(Grid, { spacing: "extraTight", alignItems: "center", children: [_jsx(Grid.Item, { children: _jsx(Icon, { source: "email" }) }), _jsx(Grid.Item, { children: _jsx(Button, { appearance: "reset", onClick: mail, children: email }) })] }) }))] }));
};
