import { jsx as _jsx } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import "draft-js/dist/Draft.css";
var IndexCountRenderer = function (props) {
    // const { entityKey, contentState } = props;
    // const entity = contentState.getEntity(entityKey);
    // const data = entity.getData() as IIndexData;
    // const indexId = data.id;
    // Render
    return (_jsx("span", { contentEditable: false, 
        // @ts-ignore
        readOnly: true, children: _jsx("button", { 
            // TODO: Modal
            // onClick={handleShowModal}
            // onMouseEnter={togglePopover}
            // onMouseLeave={togglePopover}
            style: {
                paddingLeft: 2,
                paddingRight: 2,
                backgroundColor: "powderblue",
            }, children: getLocalizedText("editor.insert_custom.insert_index_count") }) }));
};
// eslint-disable-next-line import/no-default-export
export default IndexCountRenderer;
