var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CLOSE_SIDEBAR, HIDE_SNACKBAR, OPEN_SIDEBAR, SHOW_APP_MODAL, SHOW_CONFIRM_MODAL, SHOW_LOADER, SHOW_SNACKBAR, } from "../actions";
var initialState = {
    isSidebarOpen: true,
    snackbar: {
        action: false,
        open: false,
        message: "Note archived",
        anchorOrigin: {
            vertical: "top",
            horizontal: "left",
        },
        variant: "default",
        alert: {
            color: "primary",
            variant: "filled",
        },
        transition: "Fade",
        close: true,
        maxStack: 3,
        dense: false,
        iconVariant: "hide",
        actionButton: false,
    },
    isLoading: false,
    confirmModal: {
        showConfirmModal: false,
        title: "",
        description: "",
        actions: [],
    },
    appModal: {
        showAppModal: false,
        title: "",
        link: "",
        description: "",
        btnText: "",
        icon: undefined,
    },
};
export var uiReducer = function (state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case OPEN_SIDEBAR:
            return __assign(__assign({}, state), { isSidebarOpen: true });
        case CLOSE_SIDEBAR:
            return __assign(__assign({}, state), { isSidebarOpen: false });
        case SHOW_SNACKBAR:
            var _a = action.payload, open_1 = _a.open, message = _a.message, anchorOrigin = _a.anchorOrigin, variant = _a.variant, alert_1 = _a.alert, transition = _a.transition, close_1 = _a.close, actionButton = _a.actionButton;
            return __assign(__assign({}, state), { snackbar: {
                    open: open_1 || state.snackbar.open,
                    message: message || state.snackbar.message,
                    anchorOrigin: anchorOrigin || state.snackbar.anchorOrigin,
                    variant: variant || state.snackbar.variant,
                    alert: alert_1 || state.snackbar.alert,
                    transition: transition || state.snackbar.transition,
                    close: close_1 || state.snackbar.close,
                    actionButton: actionButton || state.snackbar.actionButton,
                } });
        case SHOW_CONFIRM_MODAL:
            var _b = action.payload, showConfirmModal = _b.showConfirmModal, title = _b.title, description = _b.description, actions = _b.actions;
            return __assign(__assign({}, state), { confirmModal: {
                    showConfirmModal: showConfirmModal,
                    title: title,
                    description: description || "",
                    actions: actions,
                } });
        case SHOW_APP_MODAL:
            return __assign(__assign({}, state), { appModal: {
                    showAppModal: action.payload.showAppModal,
                    title: action.payload.title,
                    description: action.payload.description,
                    link: action.payload.link,
                    btnText: action.payload.btnText,
                    icon: action.payload.icon,
                } });
        case HIDE_SNACKBAR:
            return __assign(__assign({}, state), { snackbar: __assign(__assign({}, state.snackbar), { open: false }) });
        case SHOW_LOADER:
            return __assign(__assign({}, state), { isLoading: action.payload });
        default:
            return state;
    }
};
export var REDUCER_KEY_UI = "uiReducer";
