export var EFontWeights;
(function (EFontWeights) {
    EFontWeights[EFontWeights["Regular"] = 400] = "Regular";
    EFontWeights[EFontWeights["Medium"] = 500] = "Medium";
    EFontWeights[EFontWeights["SemiBold"] = 600] = "SemiBold";
    EFontWeights[EFontWeights["Bold"] = 700] = "Bold";
    EFontWeights[EFontWeights["ExtraBold"] = 900] = "ExtraBold";
})(EFontWeights || (EFontWeights = {}));
export var EFontSizes;
(function (EFontSizes) {
    EFontSizes[EFontSizes["ExtraSmall"] = 0.75] = "ExtraSmall";
    EFontSizes[EFontSizes["Small"] = 0.875] = "Small";
    EFontSizes[EFontSizes["Normal"] = 1] = "Normal";
    EFontSizes[EFontSizes["Medium"] = 1.125] = "Medium";
    EFontSizes[EFontSizes["Large"] = 1.25] = "Large";
    EFontSizes[EFontSizes["ExtraLarge"] = 1.375] = "ExtraLarge";
    EFontSizes[EFontSizes["ExtraExtraLarge"] = 1.5] = "ExtraExtraLarge";
    EFontSizes[EFontSizes["SuperLarge"] = 2.125] = "SuperLarge";
    EFontSizes[EFontSizes["HyperLarge"] = 3] = "HyperLarge";
    EFontSizes[EFontSizes["UltraLarge"] = 4] = "UltraLarge";
})(EFontSizes || (EFontSizes = {}));
