var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Box } from "@rebass/grid";
import styled, { iconSize, spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
import { Link } from "react-router-dom";
export var VariationToColor = {
    info: "blue",
    warning: "yellow",
    error: "red",
    success: "green",
};
export var StyledListWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  ", ";\n"], ["\n  position: relative;\n  width: 100%;\n  ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    ".concat(StyledItemWrapper, "  {\n      border-bottom: 1px solid ").concat(theme.colors.neutral30, ";\n    }\n    &:last-child ").concat(StyledItemWrapper, ":last-child {\n      border-bottom: none;\n    }\n    ");
});
export var StyledItemWrapper = styled.li(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme, hasSeparator = _a.hasSeparator;
    return !hasSeparator ? "border-bottom: none !important;" : "";
});
export var StyledList = styled.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  z-index: 0; /* does this break anything? */\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  ", ";\n"], ["\n  position: relative;\n  z-index: 0; /* does this break anything? */\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  ", ";\n"])), function (_a) {
    var variation = _a.variation, theme = _a.theme;
    return variation &&
        variation === "striped" &&
        "\n      ".concat(StyledItemWrapper, " {\n        &:nth-child(even) {\n          background: ").concat(theme.getColor("gray", 0), ";\n        }\n      }\n    ");
});
export var StyledItem = styled(Box)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  position: relative;\n  outline: none;\n  display: flex;\n  flex-direction: row;\n  ", "\n  max-width: 100%;\n  ", "\n  ", "\n"], ["\n  position: relative;\n  outline: none;\n  display: flex;\n  flex-direction: row;\n  ", "\n  max-width: 100%;\n  ", "\n  ", "\n"])), function (_a) {
    var mediaVerticalAlign = _a.mediaVerticalAlign;
    return "align-items: ".concat(mediaVerticalAlign, ";");
}, function (_a) {
    var theme = _a.theme, hasClick = _a.hasClick;
    return hasClick
        ? "&:hover {\n          background-color: ".concat(theme.colors.neutral20, "\n        };")
        : "";
}, function (_a) {
    var hasClick = _a.hasClick, depth = _a.depth, theme = _a.theme, hasChildren = _a.hasChildren, variation = _a.variation, selected = _a.selected;
    return "\n    ".concat(depth ? "padding-left: calc(".concat(spacing("extraLoose"), " * ").concat(depth, ");") : "", "\n    ").concat(
    // eslint-disable-next-line no-nested-ternary
    variation
        ? variation === "subdued"
            ? "opacity: .5"
            : "background-color: ".concat(theme.getColor(VariationToColor[variation], 0))
        : "", " \n    ").concat(hasClick
        ? "\n            cursor: pointer;\n            button {\n              cursor: pointer;\n            }\n        "
        : "", "\n    ").concat(hasChildren
        ? "\n        &:hover {\n          ".concat(StyledItemMediaIcon, " {\n            display: none;\n          }\n          ").concat(StyledItemMediaIconExpand, " {\n            display: block;\n          }\n        }\n    ")
        : "", " \n    ").concat(selected ? "background-color: ".concat(theme.colors.neutral30) : "", " \n    ");
});
export var StyledLink = styled(Link)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  text-decoration: none;\n  color: rgb(73, 73, 73);\n"], ["\n  text-decoration: none;\n  color: rgb(73, 73, 73);\n"])));
export var StyledItemContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex-grow: 1;\n  overflow: hidden;\n"], ["\n  flex-grow: 1;\n  overflow: hidden;\n"])));
export var Button = styled.button(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border: none;\n  background: none;\n  display: inherit;\n  width: 100%;\n  color: inherit;\n  font: inherit;\n  text-align: inherit;\n  font-weight: inherit;\n  padding: 0;\n  margin: 0;\n"], ["\n  border: none;\n  background: none;\n  display: inherit;\n  width: 100%;\n  color: inherit;\n  font: inherit;\n  text-align: inherit;\n  font-weight: inherit;\n  padding: 0;\n  margin: 0;\n"])));
export var StyledItemActionButton = styled.button(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border: none;\n  background: none;\n"], ["\n  border: none;\n  background: none;\n"])));
var tightMediaSizes = ["small", "medium"];
export var StyledItemMedia = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  position: relative;\n  ", "\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  ", "\n"], ["\n  position: relative;\n  ", "\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-shrink: 0;\n  ", "\n"])), function (_a) {
    var mediaSize = _a.mediaSize;
    return "\n    height: ".concat(iconSize(mediaSize), ";\n    width: ").concat(iconSize(mediaSize), ";\n    margin-right: ").concat(spacing(tightMediaSizes.includes(mediaSize) ? "tight" : "base"), ";\n  ");
}, function (_a) {
    var theme = _a.theme;
    return "color: ".concat(theme.getColor("gray", 600), ";");
});
export var StyledItemMediaIcon = styled.span(templateObject_10 || (templateObject_10 = __makeTemplateObject([""], [""])));
export var StyledItemMediaIconExpand = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: none;\n"], ["\n  display: none;\n"])));
export var StyledItemActionsTrigger = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  cursor: pointer;\n  &:hover,\n  &:active {\n    ", ";\n  }\n"], ["\n  cursor: pointer;\n  &:hover,\n  &:active {\n    ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return "color: ".concat(theme.getColor("primary"), ";");
});
export var StyledItemActions = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  position: relative;\n  z-index: 2;\n  margin-left: ", ";\n  &:first-child {\n    margin-left: 0;\n  }\n"], ["\n  position: relative;\n  z-index: 2;\n  margin-left: ", ";\n  &:first-child {\n    margin-left: 0;\n  }\n"])), spacing());
export var StyledItemBadge = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  position: absolute;\n  border-radius: 50%;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 16px;\n  height: 16px;\n  top: 0;\n  right: 0;\n  ", ";\n"], ["\n  position: absolute;\n  border-radius: 50%;\n  color: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 16px;\n  height: 16px;\n  top: 0;\n  right: 0;\n  ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return "\n    background: ".concat(theme.getColor("primary"), ";\n    font-size: ").concat(rem("10px", theme.baseFontSize), ";}\n  ");
});
export var StyledFilter = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  margin-bottom: ", ";\n"], ["\n  margin-bottom: ", ";\n"])), spacing());
export var StyledIcon = styled.span(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  /* .HasClick {\n    cursor: pointer;\n  }\n  .HasClick:hover,\n  .HasClick:global(.active) {\n    background-color: var(--primary-lightest);\n  } */\n\n  /* .Item,\n  .Item + .Item,\n  .ItemWrapper + .ItemWrapper {\n    border-top: 1px solid #dee2e6;\n  } */\n"], ["\n  /* .HasClick {\n    cursor: pointer;\n  }\n  .HasClick:hover,\n  .HasClick:global(.active) {\n    background-color: var(--primary-lightest);\n  } */\n\n  /* .Item,\n  .Item + .Item,\n  .ItemWrapper + .ItemWrapper {\n    border-top: 1px solid #dee2e6;\n  } */\n"])));
export var StyledItemCheckBox = styled.div(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  margin-right: 8px;\n"], ["\n  margin-right: 8px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
