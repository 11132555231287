import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { MultipleChoice } from "@rentiohq/web-shared-next/dist/ui-components";
import { EMultipleChoiceVariants } from "@rentiohq/web-shared-next/dist/utils/types/radio";
import { EContactMethod } from "../types";

const choices = [
  {
    icon: "chats",
    label: getLocalizedText("activity.content.chat.message"),
    value: EContactMethod.Chat,
  },
  {
    icon: "ringingPhone",
    label: getLocalizedText("activity.content.phone"),
    value: EContactMethod.Call,
  },
];

interface IProps {
  method?: EContactMethod;
  handleSelect: (choice: EContactMethod) => void;
}

export const ContactMethods = ({ method, handleSelect }: IProps) => {
  return (
    <MultipleChoice<EContactMethod>
      title={getLocalizedText("property.report_problem.choose.contact_method")}
      // @ts-ignore
      choices={choices}
      variant={EMultipleChoiceVariants.Card}
      selectedChoice={method}
      handleSelect={handleSelect}
    />
  );
};
