import { useTheme } from "@mui/material/styles";
import { EFontWeights } from "@rentiohq/web-shared-next/dist/themes/types";

export const ActivityGroupStyles = () => {
  const theme = useTheme();

  return {
    content: {
      activityItem: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        paddingBottom: "2px",
        marginBottom: "8px",
        "&:last-child": {
          borderBottom: "none",
        },
      },
      dateTitle: {
        backgroundColor: theme.palette.grey[200],
        color: "rgba(54, 65, 82, 0.90)",
        padding: "8px",
        marginBottom: "4px",
        fontSize: "14px",
      },
      activityType: {
        fontWeight: EFontWeights.Regular,
      },
    },
  };
};
