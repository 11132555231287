import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getMessageForError } from "@rentiohq/shared-frontend/dist/utils/error.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { useNavigate } from "react-router-dom";
import * as t from "../../services/translationService";
import { Button } from "../Button";
import { NoResults } from "../NoResults";
import { RentioInternalRenderer } from "../RentioInternalRenderer";
export var Error = function (props) {
    var children = props.children, _a = props.errors, errors = _a === void 0 ? [] : _a, onRetry = props.onRetry, backLink = props.backLink, errorHeading = props.errorHeading;
    var navigate = useNavigate();
    // Event handlers
    var handleGoBack = function () {
        if (backLink) {
            navigate(backLink, { replace: true });
            return;
        }
        navigate(-1);
    };
    // Render
    if (errors.length === 0) {
        return null;
    }
    var axiosError = errors[0];
    if (axiosError.isAxiosError) {
        return (_jsxs(NoResults, { iconColor: "red", 
            // TODO: Get icon per response code
            icon: "questionMark", heading: getMessageForError(axiosError), children: [_jsx(RentioInternalRenderer, { items: { error: errors[0] } }), !onRetry && (_jsx(Button, { appearance: "plain", onClick: handleGoBack, children: t.system("back_to_previous") })), onRetry && (_jsx(Button, { appearance: "plain", onClick: onRetry, children: t.system("retry") })), children] }));
    }
    return (_jsxs(NoResults, { iconColor: "red", icon: "questionMark", heading: errorHeading ? errorHeading : getLocalizedText("general.error"), children: [_jsx(RentioInternalRenderer, { items: { error: errors[0] } }), !onRetry && (_jsx(Button, { appearance: "plain", onClick: handleGoBack, children: t.system("back_to_previous") })), onRetry && (_jsx(Button, { appearance: "plain", onClick: onRetry, children: t.system("retry") })), children] }));
};
