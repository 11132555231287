var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import logger from "@rentiohq/shared-frontend/dist/logger";
import React from "react";
import ReactDOM from "react-dom";
var ModalRoot = /** @class */ (function (_super) {
    __extends(ModalRoot, _super);
    // Initialize
    function ModalRoot(props) {
        var _this = _super.call(this, props) || this;
        _this.modalRoot = document.getElementById("modal-root");
        _this.element = document.createElement("div");
        return _this;
    }
    // Lifecycle methods
    ModalRoot.prototype.componentDidMount = function () {
        var _a;
        (_a = this.modalRoot) === null || _a === void 0 ? void 0 : _a.appendChild(this.element);
    };
    ModalRoot.prototype.componentWillUnmount = function () {
        var _a;
        if (!this.modalRoot) {
            logger.logError({
                type: "error",
                fatal: false,
                error: new Error("Could not remove element from modalRoot. Reason: modalRoot is undefined"),
            });
            return;
        }
        if (!this.modalRoot.contains(this.element)) {
            logger.logError({
                type: "error",
                fatal: false,
                error: new Error("Could not remove element from modalRoot. Reason: element is not a child of modalRoot. Attempting to remove element directly."),
            });
            this.element.remove();
            return;
        }
        (_a = this.modalRoot) === null || _a === void 0 ? void 0 : _a.removeChild(this.element);
    };
    // Render
    ModalRoot.prototype.render = function () {
        return ReactDOM.createPortal(this.props.children, this.element);
    };
    return ModalRoot;
}(React.Component));
export { ModalRoot };
