import * as registrationActions from "@rentiohq/shared-frontend/dist/redux/registration/registration.actions";
import * as registrationSelectors from "@rentiohq/shared-frontend/dist/redux/registration/registration.selectors";
import {
  ERegistrationContractStatus,
  ERegistrationDocumentType,
  ERegistrationMemberType,
  ICreateRegistrationDocument,
} from "@rentiohq/shared-frontend/dist/types/registration.types";
import { IRootStore } from "redux/reducers";
import { EField, IExtraData } from "./schema.registrationAddDocument.types";

export const isPerformingSubmitActionSelector =
  (extraData: IExtraData) => (state: IRootStore) =>
    registrationSelectors.isUpdating(state, extraData.registrationId);

export const performingSubmitActionResultSelector =
  (extraData: IExtraData) => (state: IRootStore) =>
    registrationSelectors.registration(state, extraData.registrationId);

export const performingSubmitActionErrorSelector =
  (extraData: IExtraData) => (state: IRootStore) =>
    registrationSelectors.updateError(state, extraData.registrationId);

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { documentType, registrationId, existingDocumentId } = extraData;

    const signers = [
      ...(formData[EField.Owners] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Owner,
        accountId,
      })),
      ...(formData[EField.SignAsBroker] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Broker,
        accountId,
      })),
      ...(formData[EField.Tenants] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Tenant,
        accountId,
      })),
    ];

    const document: ICreateRegistrationDocument = {
      documentId: formData[EField.DocumentToUpload]?.[0],
      documentType,
      type: formData[EField.Type],
      signedAt:
        extraData.documentType === ERegistrationDocumentType.Addendum
          ? formData[EField.SignedAtAddendum]
          : formData[EField.SignedAt],
      signers,
    };

    if (existingDocumentId) {
      return registrationActions.registrationUpdateDocument.actions.start({
        registrationId: registrationId || -1,
        documentId: existingDocumentId,
        document: {
          ...document,
          status: ERegistrationContractStatus.Registering,
        },
      });
    }

    return registrationActions.registrationAddDocuments.actions.start({
      registrationId: registrationId || -1,
      documents: [document],
    });
  };
