var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { all, call, put, takeLatest } from "redux-saga/effects";
import { logError } from "../../logger/logger";
import * as documentApi from "../../reduxV2/documents/document.api";
import * as propertyActionsV2 from "../../reduxV2/property/property.actions";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralUpdateSuccessText } from "../../utils/i18n/i18n.utils";
import { takeLatestWithUniquePayload } from "../utils/saga.utils";
import * as moveRequestActions from "./moveRequest.actions";
import * as moveRequestApi from "./moveRequest.api";
function getMoveRequestStartFlow(_a) {
    var id, publicId, publicToken, suppressError, moveRequest, data, data, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id, publicId = payload.publicId, publicToken = payload.publicToken, suppressError = payload.suppressError;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 7, , 9]);
                moveRequest = undefined;
                if (!publicId) return [3 /*break*/, 3];
                return [4 /*yield*/, call(moveRequestApi.getMoveRequestByPublicId, publicId, publicToken)];
            case 2:
                data = (_b.sent()).data;
                moveRequest = data;
                return [3 /*break*/, 5];
            case 3:
                if (!id) return [3 /*break*/, 5];
                return [4 /*yield*/, call(moveRequestApi.getMoveRequestById, id)];
            case 4:
                data = (_b.sent()).data;
                moveRequest = data;
                _b.label = 5;
            case 5:
                if (!moveRequest) {
                    throw new Error("No move request id or publicId");
                }
                return [4 /*yield*/, put(moveRequestActions.getMoveRequest.actions.success({
                        id: id,
                        publicId: moveRequest.publicId,
                        moveRequest: moveRequest,
                    }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 9];
            case 7:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logError({ error: error });
                if (!suppressError) {
                    showAlert({
                        type: "error",
                        error: error,
                    });
                }
                return [4 /*yield*/, put(moveRequestActions.getMoveRequest.actions.failure({
                        id: id,
                        publicId: publicId,
                        error: error,
                    }))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function updateMoveRequestStartFlow(_a) {
    var id, publicId, publicToken, moveRequest, propertyId, property, _b, documents, _c, suppressSuccessMessage, _d, suppressErrorMessage, presignedUrls, updatedMoveRequest, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                id = payload.id, publicId = payload.publicId, publicToken = payload.publicToken, moveRequest = payload.moveRequest, propertyId = payload.propertyId, property = payload.property, _b = payload.documents, documents = _b === void 0 ? [] : _b, _c = payload.suppressSuccessMessage, suppressSuccessMessage = _c === void 0 ? false : _c, _d = payload.suppressErrorMessage, suppressErrorMessage = _d === void 0 ? false : _d;
                _e.label = 1;
            case 1:
                _e.trys.push([1, 10, , 12]);
                // Update move request
                return [4 /*yield*/, call(moveRequestApi.updateMoveRequest, publicId, moveRequest, publicToken)];
            case 2:
                // Update move request
                _e.sent();
                if (!(id && propertyId && documents.length > 0)) return [3 /*break*/, 5];
                return [4 /*yield*/, all((documents || []).map(function (_a) {
                        var document = _a.document;
                        return call(documentApi.uploadDocumentToS3, document);
                    }))];
            case 3:
                presignedUrls = _e.sent();
                return [4 /*yield*/, all(presignedUrls.map(function (presignedUrl, index) {
                        var metaData = {
                            categoryId: documents[index].categoryId,
                            propertyId: propertyId,
                            filename: documents[index].document.name,
                        };
                        return call(moveRequestApi.uploadMoveRequestDocument, {
                            id: id,
                            presignedUrl: presignedUrl,
                            metaData: metaData,
                        });
                    }))];
            case 4:
                _e.sent();
                _e.label = 5;
            case 5: return [4 /*yield*/, call(moveRequestApi.getMoveRequestByPublicId, publicId, publicToken)];
            case 6:
                updatedMoveRequest = (_e.sent()).data;
                if (!(propertyId && property)) return [3 /*break*/, 8];
                return [4 /*yield*/, put(propertyActionsV2.updateStart.getAction({
                        id: propertyId,
                        data: property,
                        suppressSuccessAlert: true,
                        suppressFailureAlert: true,
                    }))];
            case 7:
                _e.sent();
                _e.label = 8;
            case 8: return [4 /*yield*/, put(moveRequestActions.updateMoveRequest.actions.success({
                    id: id,
                    publicId: publicId,
                    moveRequest: updatedMoveRequest,
                }))];
            case 9:
                _e.sent();
                if (!suppressSuccessMessage) {
                    showAlert({
                        type: "success",
                        message: getGeneralUpdateSuccessText("move_request"),
                    });
                }
                return [3 /*break*/, 12];
            case 10:
                unknownError_2 = _e.sent();
                error = unknownError_2;
                logError({ error: error });
                if (!suppressErrorMessage) {
                    showAlert({
                        type: "error",
                        error: error,
                    });
                }
                return [4 /*yield*/, put(moveRequestActions.updateMoveRequest.actions.failure({
                        id: id,
                        publicId: publicId,
                        error: error,
                    }))];
            case 11:
                _e.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
function commitMoveRequestStartFlow(_a) {
    var id, publicId, publicToken, moveRequest, service, updatedMoveRequest, unknownError_3, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id, publicId = payload.publicId, publicToken = payload.publicToken, moveRequest = payload.moveRequest, service = payload.service;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(moveRequestApi.updateMoveRequest, publicId, moveRequest, publicToken)];
            case 2:
                updatedMoveRequest = (_b.sent()).data;
                return [4 /*yield*/, call(moveRequestApi.commitMoveRequest, publicId, service, publicToken)];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(moveRequestActions.commitMoveRequest.actions.success({
                        id: id,
                        publicId: publicId,
                        moveRequest: updatedMoveRequest,
                    }))];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                });
                return [4 /*yield*/, put(moveRequestActions.commitMoveRequest.actions.failure({
                        id: id,
                        publicId: publicId,
                        error: error,
                    }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function requestCallStartFlow(_a) {
    var publicId, publicToken, moveRequest, unknownError_4, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                publicId = payload.publicId, publicToken = payload.publicToken, moveRequest = payload.moveRequest;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(updateMoveRequestStartFlow, moveRequestActions.updateMoveRequest.actions.start({
                        publicId: publicId,
                        moveRequest: moveRequest,
                        publicToken: publicToken,
                    }))];
            case 2:
                _b.sent();
                return [4 /*yield*/, call(moveRequestApi.requestCall, publicId, publicToken)];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(moveRequestActions.requestCall.actions.success({
                        publicId: publicId,
                    }))];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_4 = _b.sent();
                error = unknownError_4;
                logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                });
                return [4 /*yield*/, put(moveRequestActions.requestCall.actions.failure({
                        publicId: publicId,
                        error: error,
                    }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatestWithUniquePayload(function (_a) {
                    var payload = _a.payload;
                    return payload.id || payload.publicId || -1;
                }, moveRequestActions.getMoveRequest.types.START, getMoveRequestStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(moveRequestActions.updateMoveRequest.types.START, updateMoveRequestStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(moveRequestActions.commitMoveRequest.types.START, commitMoveRequestStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(moveRequestActions.requestCall.types.START, requestCallStartFlow)];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line import/no-default-export
export default saga;
