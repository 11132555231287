import { jsx as _jsx } from "react/jsx-runtime";
import { getMonth, getYear, } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { BaseDatePicker } from "../../BaseDatePicker";
export var MonthYearPicker = function (_a) {
    var minDate = _a.minDate, maxDate = _a.maxDate, id = _a.id, placeholder = _a.placeholder, selected = _a.selected, error = _a.error, onChange = _a.onChange, handleClear = _a.handleClear, handleIconClick = _a.handleIconClick;
    var handleChange = function (input) {
        if (minDate && input < minDate) {
            onChange === null || onChange === void 0 ? void 0 : onChange(minDate);
            return;
        }
        if (maxDate && input > maxDate) {
            onChange === null || onChange === void 0 ? void 0 : onChange(maxDate);
            return;
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(input);
    };
    var handleMonthChange = function (month) {
        // TODO: Dirty fix if month is after max date for current year
        var newDate = selected || new Date();
        newDate.setMonth(month.value);
        handleChange(newDate);
    };
    var handleYearChange = function (year) {
        var newDate = selected || new Date();
        newDate.setFullYear(year.value);
        handleChange(newDate);
    };
    return (_jsx(BaseDatePicker, { id: id, hideDayPicker: true, hasError: !!error, isDisabled: false, placeholderText: placeholder, selected: selected, minDate: minDate, maxDate: maxDate, inverseYears: true, required: true, dateFormat: "MM/yyyy", showTimeSelect: false, onChange: handleChange, onMonthChange: function (date) {
            var month = getMonth(date);
            if (month !== undefined) {
                handleMonthChange({ value: month });
            }
        }, onYearChange: function (date) {
            var year = getYear(date);
            if (year !== undefined) {
                handleYearChange({ value: year });
            }
        }, handleClear: handleClear, handleIconClick: handleIconClick }));
};
