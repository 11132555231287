export const TaskCardStyles = () => {
  return {
    content: {
      longText: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        display: "block",
      },
    },
  };
};
