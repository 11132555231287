import { useTheme } from "@mui/material/styles";
export var DrawerStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            position: "relative",
            ".MuiDrawer-paper": {
                width: { xs: "340px", sm: "380px", md: "600px" },
                padding: "24px 16px",
                paddingLeft: "24px",
                background: theme.palette.grey[50],
            },
        },
        content: {
            closeIcon: {
                cursor: "pointer",
                position: "absolute",
                top: 16,
                right: 16,
            },
        },
    };
};
