import { createDocumentPackage } from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import {
  IDocumentPackageCreate,
  IDocumentPackageCreateSigner,
} from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.types";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import * as templateDocumentSelectors from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.selectors";
import { getSignerForAccountId } from "@rentiohq/shared-frontend/dist/utils/documentPackage.utils";
import { IRootStore } from "redux/reducers";
import { EField, IExtraData } from "./schema.createDocumentPackage.types";
import {
  getRelatedContract,
  getRelatedProperty,
  getSigningTypes,
} from "./schema.createDocumentPackage.utils";

export const isPerformingSubmitActionSelector =
  (extraData: IExtraData) => (state: IRootStore) => {
    const { sendToTenant, templateDocument } = extraData;

    if (sendToTenant && templateDocument) {
      return templateDocumentSelectors.isUpdatingTemplateDocumentDetail(
        state,
        templateDocument.id,
      );
    }
    return !!state.documentPackage.isCreatingDocumentPackage;
  };

export const performingSubmitActionResultSelector =
  (extraData: IExtraData) => (state: IRootStore) => {
    const { sendToTenant, templateDocument } = extraData;

    if (sendToTenant && templateDocument) {
      return templateDocumentSelectors.templateDocumentDetail(
        state,
        templateDocument.id,
      );
    }
    const documentPackageId: any =
      state.documentPackage.createdDocumentPackageId;
    return state.documentPackage.documentPackages[documentPackageId];
  };

export const performingSubmitActionErrorSelector =
  (extraData: IExtraData) => (state: IRootStore) => {
    const { sendToTenant, templateDocument } = extraData;

    if (sendToTenant && templateDocument) {
      return templateDocumentSelectors.templateDocumentDetailUpdateError(
        state,
        templateDocument.id,
      );
    }
    return state.documentPackage.createDocumentPackageError;
  };

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const relatedContract = getRelatedContract(extraData, formData);
    const relatedProperty = getRelatedProperty(extraData, formData);

    const allowedSigningTypes = getSigningTypes(
      formData[EField.SigningTypes] || [],
    );

    const { sendToTenant, templateDocument } = extraData;

    if (sendToTenant && templateDocument) {
      return templateDocumentActions.updateTemplateDocumentDetail.actions.start(
        {
          id: templateDocument.id,
          data: {
            allowedSigningTypes,
          },
        },
      );
    } else {
      const signers = [...(formData[EField.Signers] || [])];

      const signAsBroker = formData[EField.SignAsBroker] || [];
      if (signAsBroker.length > 0) {
        signers.push(+signAsBroker[0]);
      }

      const signerObjects = signers.map(
        (accountId: number): IDocumentPackageCreateSigner => {
          return getSignerForAccountId({
            accountId,
            allowedSigningTypes,
            contract: relatedContract,
            property: relatedProperty,
          });
        },
      );

      const documentPackage: IDocumentPackageCreate = {
        type: formData[EField.DocumentPackageType],

        propertyId: relatedProperty?.id,
        contractId: relatedContract?.id,

        documents: formData[EField.Documents] || [],

        name: formData[EField.Name],
        signers: signerObjects,
      };

      return createDocumentPackage.actions.start(documentPackage);
    }
  };
