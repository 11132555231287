var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as templateActions from "@rentiohq/shared-frontend/dist/redux/template/template.actions";
import * as templateSelectors from "@rentiohq/shared-frontend/dist/redux/template/template.selectors";
import { convertToRaw } from "draft-js";
import uniq from "lodash/uniq";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Error } from "../../components";
import Editor from "./components/Editor";
import { EDisplayType } from "./components/Editor/Editor.types";
import { parseData, parseFields } from "./components/Editor/Editor.utils";
var TemplateEditor = function () {
    // Router
    var navigate = useNavigate();
    var templateId = useParams().templateId;
    // Redux
    var dispatch = useDispatch();
    var template = useSelector(function (state) {
        return templateSelectors.detail.detail(state, templateId);
    });
    var isFetching = useSelector(function (state) {
        return templateSelectors.detail.isFetching(state, templateId);
    });
    var fetchError = useSelector(function (state) {
        return templateSelectors.detail.fetchError(state, templateId);
    });
    var detail = useSelector(function (state) {
        return templateSelectors.templateDetail(state, templateId);
    });
    var isFetchingDetail = useSelector(function (state) {
        return templateSelectors.isFetchingTemplateDetail(state, templateId);
    });
    var fetchDetailError = useSelector(function (state) {
        return templateSelectors.templateDetailFetchError(state, templateId);
    });
    var isUpdatingDetail = useSelector(function (state) {
        return templateSelectors.isUpdatingTemplateDetail(state, templateId);
    });
    var rawState = React.useMemo(function () {
        if (!detail) {
            return undefined;
        }
        return parseData(detail.data);
    }, [detail]);
    var fields = React.useMemo(function () { return parseFields(detail === null || detail === void 0 ? void 0 : detail.fields); }, [detail]);
    // Lifecycle
    React.useEffect(function () {
        if (!templateId || isFetching) {
            return;
        }
        dispatch(templateActions.getDetail.actions.start({
            id: templateId,
        }));
        dispatch(templateActions.getTemplateDetail.actions.start({
            id: templateId,
        }));
    }, [templateId]);
    // Event handlers
    var handlePressUpdate = function (params) {
        var editorState = params.editorState, fields = params.fields, documentIds = params.documentIds, goBackOnSuccess = params.goBackOnSuccess;
        if (!templateId) {
            return;
        }
        dispatch(templateActions.updateTemplateDetail.actions.start({
            id: templateId,
            data: {
                data: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
                fields: JSON.stringify(fields),
                documentIds: uniq(__spreadArray(__spreadArray([], ((detail === null || detail === void 0 ? void 0 : detail.documentIds) || []), true), documentIds, true)),
            },
            onSuccess: function () {
                if (goBackOnSuccess) {
                    navigate(-1);
                }
            },
        }));
        // downloadToFile({
        //   content: json,
        //   filename: `document-template-${new Date().getTime()}.json`,
        //   contentType: "application/json",
        // });
    };
    // Render
    var error = fetchError || fetchDetailError;
    if (error) {
        return _jsx(Error, { errors: [error] });
    }
    return (_jsx(Editor, { displayType: EDisplayType.Template, templateId: templateId, title: template === null || template === void 0 ? void 0 : template.name, onPressUpdate: handlePressUpdate, isFetching: isFetching || isFetchingDetail, isUpdating: isUpdatingDetail, rawState: rawState, fields: fields, documentConfig: {}, 
        // TODO: Remove fallback
        documentIds: (detail === null || detail === void 0 ? void 0 : detail.documentIds) || [] }));
};
// eslint-disable-next-line import/no-default-export
export default TemplateEditor;
