import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { Banner } from "../../Banner";
import { Modal } from "../../Modal";
import { TextStyle } from "../../TextStyle";
import * as S from "./CameraModal.styles";
export var CameraModal = function (props) {
    var fileName = props.fileName, showModal = props.showModal, onClose = props.onClose, getImage = props.getImage;
    var videoRef = React.useRef(null);
    var canvasRef = React.useRef(null);
    var streamRef = React.useRef(null);
    var contextRef = React.useRef(null);
    var _a = React.useState(false), imageCaptured = _a[0], setImageCaptured = _a[1];
    var _b = React.useState(null), imageSrc = _b[0], setImageSrc = _b[1];
    React.useEffect(function () {
        if (showModal) {
            startCamera();
        }
        return function () {
            stopCamera();
        };
    }, [showModal]);
    var startCamera = function () {
        var _a;
        if ((_a = navigator.mediaDevices) === null || _a === void 0 ? void 0 : _a.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true }).then(function (mediaStream) {
                streamRef.current = mediaStream;
                if (videoRef.current) {
                    videoRef.current.srcObject = mediaStream;
                    videoRef.current.play();
                }
                if (canvasRef.current) {
                    contextRef.current = canvasRef.current.getContext("2d");
                }
            });
        }
    };
    var stopCamera = function () {
        if (streamRef.current) {
            var tracks = streamRef.current.getTracks();
            tracks.forEach(function (track) { return track.stop(); });
            streamRef.current = null;
        }
        contextRef.current = null;
    };
    var takeSnapshot = function () {
        var _a, _b;
        if (contextRef.current && videoRef.current && canvasRef.current) {
            var videoWidth = videoRef.current.videoWidth;
            var videoHeight = videoRef.current.videoHeight;
            var guardRailWidth = videoWidth * 0.8;
            var guardRailHeight = videoHeight * 0.7;
            var guardRailX = (videoWidth - guardRailWidth) / 2;
            var guardRailY = (videoHeight - guardRailHeight) / 2;
            var outputWidth = 600;
            var outputHeight = 400;
            canvasRef.current.width = outputWidth;
            canvasRef.current.height = outputHeight;
            contextRef.current.drawImage(videoRef.current, guardRailX, guardRailY, guardRailWidth, guardRailHeight, 0, 0, (_a = canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current) === null || _a === void 0 ? void 0 : _a.width, (_b = canvasRef === null || canvasRef === void 0 ? void 0 : canvasRef.current) === null || _b === void 0 ? void 0 : _b.height);
            var dataURL = canvasRef.current.toDataURL("image/png");
            setImageSrc(dataURL);
        }
    };
    var generateImage = function () {
        if (canvasRef.current) {
            var dataURL = canvasRef.current.toDataURL("image/png");
            var byteString = atob(dataURL.split(",")[1]);
            var mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var ia = new Uint8Array(arrayBuffer);
            for (var i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            var blob = new Blob([ia], { type: mimeString });
            var file = new File([blob], fileName + ".png", { type: mimeString });
            return file;
        }
        return null;
    };
    var captureImage = function () {
        takeSnapshot();
        var file = generateImage();
        if (file) {
            setImageCaptured(true);
            getImage([file]);
        }
    };
    var resetSettings = function () {
        setImageCaptured(false);
        setImageSrc(null);
        getImage([]);
        startCamera();
    };
    return (_jsx(Modal, { id: "camera-modal", heading: getLocalizedText("system.take.picture"), onClose: onClose, shouldCloseOnOverlayClick: true, width: "large", actions: [
            {
                content: getLocalizedText("system.camera.change.image"),
                appearance: "primary",
                isDisabled: !imageCaptured,
                onClick: resetSettings,
            },
            {
                content: getLocalizedText(imageCaptured ? "system.confirm" : "system.camera.capture.image"),
                appearance: "primary",
                onClick: imageCaptured ? onClose : captureImage,
            },
        ], children: _jsxs(S.CameraContainer, { children: [_jsxs(Banner, { title: getLocalizedText("form.kyc_receiver_as_broker.field.id_card.title"), icon: "alertDiamond", variation: "warning", hasDismiss: false, children: [_jsxs(TextStyle, { children: ["1.", " ", getLocalizedText("form.kyc_receiver_as_broker.field.id_card.image.guidline_1")] }), _jsx(Spacer, { weight: ESpacerWeight.W08 }), _jsxs(TextStyle, { children: ["2.", " ", getLocalizedText("form.kyc_receiver_as_broker.field.id_card.image.guidline_2")] }), _jsx(Spacer, { weight: ESpacerWeight.W08 }), _jsxs(TextStyle, { children: ["3.", " ", getLocalizedText("form.kyc_receiver_as_broker.field.id_card.image.guidline_3")] })] }), _jsx(S.Label, { children: imageSrc
                        ? getLocalizedText("system.camera.captured.image")
                        : getLocalizedText("system.use.camera") }), _jsxs(S.VideoWrapper, { "$hidden": Boolean(imageSrc), children: [_jsx(S.Video, { ref: videoRef, autoPlay: true }), _jsx(S.Overlay, { children: _jsx(S.OverlayBorderWrap, { children: _jsx(S.OverlayBorder, {}) }) })] }), _jsx(S.Canvas, { ref: canvasRef }), imageSrc && (_jsx(S.Image, { src: imageSrc, alt: "Captured", id: "captured-image" }))] }) }));
};
