import { alpha, useTheme } from "@mui/material/styles";
export var EIconAvatarVariant;
(function (EIconAvatarVariant) {
    EIconAvatarVariant["Success"] = "success";
    EIconAvatarVariant["Warning"] = "warning";
    EIconAvatarVariant["Info"] = "info";
    EIconAvatarVariant["Error"] = "error";
})(EIconAvatarVariant || (EIconAvatarVariant = {}));
var getBgColor = function (theme, variant) {
    switch (variant) {
        case EIconAvatarVariant.Success:
            return theme.palette.success[200];
        case EIconAvatarVariant.Warning:
            return theme.palette.warning.light;
        case EIconAvatarVariant.Info:
            return theme.palette.grey[100];
        case EIconAvatarVariant.Error:
            return alpha(theme.palette.error.light, 0.4);
        default:
            return theme.palette.success[200];
    }
};
export var IconAvatarStyles = function (variant) {
    var theme = useTheme();
    return {
        wrapper: {
            width: "24px",
            height: "24px",
            color: theme.palette.grey[900],
            borderRadius: "8px",
            background: getBgColor(theme, variant),
        },
    };
};
