import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import * as paymentRequestSelectors from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import {
  EPaymentOrderType,
  EPayoutType,
  ETransferType,
  IPaymentTransaction,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { IPaymentRequest } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { IBeneficiaryReportPayout } from "@rentiohq/shared-frontend/dist/types/report.types";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { ELoadingSize, Loading } from "@rentiohq/web-shared/dist/components";
import { round } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const getCost = (
  linkedPayouts: IPaymentRequest[],
  type: EPaymentOrderType,
): number => {
  const allLinkedCostsWithType = linkedPayouts.filter(
    linkedPayout => linkedPayout.paymentRequestItems?.[0].type === type,
  );
  return allLinkedCostsWithType.reduce((sum: number, currentLinkedPayout) => {
    const firstPayoutTransaction =
      currentLinkedPayout.paymentTransactions?.find(
        (currentTransaction: IPaymentTransaction) =>
          currentTransaction.transferType === ETransferType.TransferPayout,
      );

    if (firstPayoutTransaction) {
      sum += Number(firstPayoutTransaction.amount);
    }

    return sum;
  }, 0);
};

const getOtherCosts = (linkedPayouts: IPaymentRequest[]): number => {
  const allLinkedCostsWithOtherType = linkedPayouts.filter(linkedPayout =>
    [EPaymentOrderType.OtherOneoff, EPaymentOrderType.OtherRecurring].includes(
      linkedPayout.paymentRequestItems?.[0].type,
    ),
  );

  return allLinkedCostsWithOtherType.reduce(
    (total, current) =>
      (total += Number(current.originalAmount) - Number(current.amount)),
    0,
  );
};

const calculateNumbers = (
  payout: IBeneficiaryReportPayout,
  linkedPayouts: IPaymentRequest[],
  transactionAmount: number | null,
) => {
  const isRent =
    payout?.paymentRequestItems?.[0].type === EPaymentOrderType.Rent;

  const revenue = payout.originalAmount;
  const commission = isRent
    ? getCost(linkedPayouts, EPaymentOrderType.BrokerFee)
    : 0;
  const managementCost = isRent
    ? getCost(linkedPayouts, EPaymentOrderType.ManagementCosts)
    : 0;

  let otherCost = isRent ? getOtherCosts(linkedPayouts) : 0;

  const calculatedPayout = revenue - (commission + managementCost + otherCost);

  if (transactionAmount !== null && calculatedPayout !== transactionAmount) {
    const diff = calculatedPayout - (transactionAmount ?? 0);
    otherCost += round(diff, 2);
  }

  return {
    managementCost,
    commission,
    otherCost,
    revenue,
    payout: transactionAmount ?? calculatedPayout,
  };
};

interface ICost {
  payout: IBeneficiaryReportPayout;
  costType: "revenue" | "payout" | "management" | "commission" | "other";
  isNegative?: boolean;
  isBold?: boolean;
  showZero?: boolean;
  showLoader?: boolean;
}

// eslint-disable-next-line import/no-default-export
export default function Cost({
  costType,
  payout,
  isNegative = false,
  isBold = false,
  showZero = false,
  showLoader = false,
}: ICost) {
  const linkedPayouts = useSelector((state: IPartialRootState) =>
    paymentRequestSelectors.getInfiniteLoad(state, {
      filter: {
        payoutType: { eq: EPayoutType.Payout },
        parent: { eq: payout.parent },
      },
      limit: 20,
    }),
  )?.items;
  const isFetching = useSelector((state: IPartialRootState) =>
    paymentRequestSelectors.getInfiniteLoad(state, {
      filter: {
        payoutType: { eq: EPayoutType.Payout },
        parent: { eq: payout.parent },
      },
      limit: 20,
    }),
  )?.isFetching;

  const transactionAmount = useMemo(() => {
    if (payout.paymentTransactions.length === 0) return null;
    const sum = payout.paymentTransactions.reduce(
      (all: number, current: IPaymentTransaction) => {
        if (current.transferType === ETransferType.TransferPayout)
          all += Number(current.amount);
        return all;
      },
      0,
    );

    return round(sum, 2);
  }, [payout]);

  let cost = useMemo(() => {
    const numbers = calculateNumbers(
      payout,
      linkedPayouts ?? [],
      transactionAmount,
    );
    // We can get "revenue" and "payout" from this payout, for the other types
    // we need the linkedPayouts
    if (costType === "revenue") return numbers.revenue;
    if (costType === "payout") return numbers.payout;
    if (linkedPayouts) {
      switch (costType) {
        case "commission":
          return numbers.commission;
        case "management":
          return numbers.managementCost;
        case "other":
          return numbers.otherCost;
      }
    }

    return 0;
  }, [linkedPayouts]);

  if (isFetching && costType !== "payout" && costType !== "revenue")
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {showLoader && <Loading asDots={true} size={ELoadingSize.Medium} />}
      </div>
    );

  const comparison = showZero ? cost >= 0 : cost > 0;
  const formatted = comparison ? formatCurrency(cost) : "-";
  return (
    <div>
      {isNegative && cost ? "- " : null}
      {isBold ? <b>{formatted} </b> : formatted}
    </div>
  );
}
