import { uiSchema as distributionOfAmountsUiSchema, getSchema as getDistributionOfAmountsSchema, getValidate as getDistributionOfAmountsValidate, } from "./schema.releaseRentDeposit.1";
import { getSchema as getOwnerBankAccountSchema, getValidate as getValidateOwnerBankAccount, uiSchema as ownerBankAccountUiSchema, } from "./schema.releaseRentDeposit.2";
import { getSchema as getSignersSchema, getValidate as getValidateSigners, uiSchema as signersUiSchema, } from "./schema.releaseRentDeposit.3";
import { getSchema as getTenantFundsReceiverSchema, uiSchema as tenantFundsReceiverUiSchema, } from "./schema.releaseRentDeposit.4";
import { getSchema as getTenantAccountNumberSchema, uiSchema as getTenantAccountsNumberUiSchema, getValidate as getValidateTenantBankAccount, } from "./schema.releaseRentDeposit.5";
import { uiSchema as brokerBankAccountUiSchema, getSchema as getBrokerBankAccountSchema, getValidate as getValidateBrokerBankAccount, } from "./schema.releaseRentDeposit.6";
import { getSchema as getOwnerFundsReceiverSchema, uiSchema as ownerFundsReceiverUiSchema, } from "./schema.releaseRentDeposit.7";
import { EField } from "./schema.releaseRentDeposit.types";
import { getInitialValues, getOwners, getTenants, } from "./schema.releaseRentDeposit.utils";
// eslint-disable-next-line import/no-default-export
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    var tenants = getTenants(extraData.relatedContract);
    var owners = getOwners(extraData.relatedContract);
    return [
        {
            schema: getDistributionOfAmountsSchema(initialValues, extraData),
            uiSchema: distributionOfAmountsUiSchema(extraData),
            validate: getDistributionOfAmountsValidate(extraData),
        },
        {
            schema: getSignersSchema(initialValues, extraData),
            uiSchema: signersUiSchema(extraData),
            validate: getValidateSigners(extraData),
            showIf: function (formData) {
                var shouldHide = extraData.internalModeEnabled &&
                    formData[EField.JudicialRelease] === true;
                return !shouldHide;
            },
        },
        {
            schema: getOwnerFundsReceiverSchema(initialValues, extraData),
            uiSchema: ownerFundsReceiverUiSchema(extraData),
            showIf: function (formData) {
                if (Number(formData[EField.OwnerAmount]) === 0)
                    return false;
                return owners.length > 1;
            },
        },
        {
            schema: getOwnerBankAccountSchema(initialValues, extraData),
            uiSchema: ownerBankAccountUiSchema(extraData),
            validate: getValidateOwnerBankAccount(),
            showIf: function (formData) { return Number(formData[EField.OwnerAmount]) > 0; },
        },
        {
            schema: getTenantFundsReceiverSchema(initialValues, extraData),
            uiSchema: tenantFundsReceiverUiSchema(extraData),
            showIf: function (formData) {
                if (Number(formData[EField.TenantAmount]) === 0)
                    return false;
                return tenants.length > 1;
            },
        },
        {
            schema: getTenantAccountNumberSchema(initialValues, extraData),
            uiSchema: getTenantAccountsNumberUiSchema(extraData),
            validate: getValidateTenantBankAccount(),
            showIf: function (formData) { return Number(formData[EField.TenantAmount]) > 0; },
        },
        {
            schema: getBrokerBankAccountSchema(initialValues, extraData),
            uiSchema: brokerBankAccountUiSchema(extraData),
            validate: getValidateBrokerBankAccount(),
            showIf: function (formData) { return Number(formData[EField.BrokerAmount]) > 0; },
        },
    ];
});
