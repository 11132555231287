import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { ERecurrenceType } from "../../types/task.types";
import { addYears } from "../../utils/date-fns.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createTask.types";
import { FREQUENCY_OPTIONS } from "./schema.createTask.utils";
var NOW = new Date();
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        return {
            required: [EField.RecurrenceType],
            properties: (_a = {},
                _a[EField.RecurrenceType] = {
                    default: getValue(EField.RecurrenceType, initialValues, defaultValues),
                    //@ts-ignore
                    options: FREQUENCY_OPTIONS.map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("task.frequency.".concat(id.toLowerCase())),
                    }); }).filter(function (option) {
                        if (!defaultValues[EField.ExecuteDate] &&
                            !!defaultValues[EField.DueDate]) {
                            return option.id !== ERecurrenceType.Daily;
                        }
                        return FREQUENCY_OPTIONS;
                    }),
                },
                _a[EField.RecurrenceEndDate] = {
                    default: getValue(EField.RecurrenceEndDate, initialValues, defaultValues),
                },
                _a),
        };
    };
};
export var uiSchema = function () {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("task.frequency.section_header")
        },
        _a[EField.RecurrenceType] = {
            "ui:field": ERentioFormField.InlineSelect,
            title: getLocalizedText("task.frequency.select.title"),
            customIsFetchingSelector: function () { return false; },
            formatSummary: function (formData) {
                if (formData) {
                    return getLocalizedText("task.frequency.".concat(formData.toLowerCase()));
                }
                return;
            },
        },
        _a[EField.RecurrenceEndDate] = {
            "ui:field": ERentioFormField.Date,
            formatSummary: function (input) {
                return input ? formatDate(input) : getLocalizedText("task.date.no_selection");
            },
            title: getLocalizedText("task.frequency.end_date"),
            getMinDate: function (initialValues) {
                return initialValues[EField.ExecuteDate] || initialValues[EField.DueDate];
            },
            maxDate: addYears(NOW, 40),
            optional: true,
        },
        _a;
};
