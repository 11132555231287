// NOTE: This is just a quick implementation to complete the file upload flow
// This will be replaced with the actual forms later

import { NON_USER_SELECTABLE_DOCUMENT_CATEGORIES } from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.constants";
import {
  getCategoriesSortedByTranslatedLabel,
  getDocumentCategoryName,
} from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Box,
  FormHelperText,
  InputLabel,
  MenuItem,
  MuiDialog,
  Select,
  Stack,
  TextField,
} from "@rentiohq/web-shared-next/dist/ui-components";
import React from "react";

interface IProps {
  openDialog: boolean;
  handleClose: () => void;
  handleAdd: ({
    filename,
    categoryId,
  }: {
    filename: string;
    categoryId: string;
  }) => void;
  file: File;
}

export const CreateDocumentModal = ({
  openDialog,
  handleClose,
  handleAdd,
  file,
}: IProps) => {
  const categoryIds = getCategoriesSortedByTranslatedLabel().filter(
    x => !NON_USER_SELECTABLE_DOCUMENT_CATEGORIES.includes(x),
  );

  const [fileName, setFileName] = React.useState<string>("");
  const [category, setCategory] = React.useState<string>();
  const [errors, setErrors] = React.useState({
    fileName: false,
    category: false,
  });

  React.useEffect(() => {
    if (file) {
      setFileName(file.name);
    }
  }, [file]);

  const handleSave = () => {
    if (fileName && category) {
      handleAdd({ filename: fileName, categoryId: category });
      handleClose();
    } else {
      setErrors({ fileName: !fileName, category: !category });
    }
  };

  return (
    <MuiDialog
      title={getLocalizedText("document.add.modal.heading")}
      open={openDialog}
      maxWidth="700px"
      width="700px"
      toggleOpen={handleClose}
      actions={[
        {
          onClick: handleSave,
          label: getLocalizedText("system.save"),
        },
      ]}
    >
      <Stack direction="column" gap={2}>
        <Box>
          <InputLabel>Name</InputLabel>
          <TextField
            value={fileName}
            fullWidth
            onChange={e => {
              setFileName(e.target.value);
              setErrors({ ...errors, fileName: false });
            }}
            error={errors?.fileName}
          />
          {errors?.fileName && (
            <FormHelperText error={true} sx={{ mt: 0.5 }}>
              File name is required
            </FormHelperText>
          )}
        </Box>
        <Box>
          <InputLabel>Category</InputLabel>
          <Select
            value={category}
            onChange={e => {
              setCategory(e.target.value);
              setErrors({ ...errors, category: false });
            }}
            fullWidth
            error={errors?.category}
          >
            {Array.from(new Set(categoryIds)).map(categoryId => (
              <MenuItem value={categoryId} key={categoryId}>
                {getDocumentCategoryName(categoryId)}
              </MenuItem>
            ))}
          </Select>
          {errors?.category && (
            <FormHelperText error={true} sx={{ mt: 1 }}>
              Category is required
            </FormHelperText>
          )}
        </Box>
      </Stack>
    </MuiDialog>
  );
};
