var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { darken } from "polished";
import React from "react";
import { Doughnut } from "react-chartjs-2";
import { useOutletContext } from "react-router-dom";
import { Loading, TextStyle } from "..";
import { DisplayText } from "../DisplayText";
import { Lozenge } from "../Lozenge";
import * as S from "./BrokerUsage.styles";
export var BrokerUsage = function () {
    var brokerId = useOutletContext().brokerId;
    // State
    var _a = React.useState(false), isFetching = _a[0], setIsFetching = _a[1];
    var _b = React.useState(), usage = _b[0], setUsage = _b[1];
    // Data
    var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, unknownError_1, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (usage) {
                        return [2 /*return*/];
                    }
                    if (isFetching) {
                        return [2 /*return*/];
                    }
                    if (!brokerId) {
                        return [2 /*return*/];
                    }
                    setIsFetching(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, api.get("/brokers/".concat(brokerId, "/stats"))];
                case 2:
                    data = (_a.sent()).data;
                    setUsage(data);
                    return [3 /*break*/, 4];
                case 3:
                    unknownError_1 = _a.sent();
                    error = unknownError_1;
                    showAlert({
                        type: "error",
                        error: error,
                    });
                    return [3 /*break*/, 4];
                case 4:
                    setIsFetching(false);
                    return [2 /*return*/];
            }
        });
    }); };
    // Lifecycle
    React.useEffect(function () {
        fetchData();
    }, [brokerId]);
    // Render
    if (isFetching) {
        return _jsx(Loading, {});
    }
    if (!usage) {
        return null;
    }
    var renderChart = function (baseKey, usageItem) {
        var isPostpaid = usageItem.percentage === -1;
        var colorUnused = "#7F8281";
        var colorPostpaid = darken(0.2, "rgb(26, 179, 148)");
        var colorPrepaid = "rgb(26, 179, 148)";
        var labelUnused = getLocalizedText("broker_usage.chart_item.unused.".concat(baseKey));
        var labelPostpaid = getLocalizedText("broker_usage.chart_item.postpaid.".concat(baseKey));
        var labelPrepaid = getLocalizedText("broker_usage.chart_item.prepaid.".concat(baseKey));
        var data = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [],
                },
            ],
        };
        if (isPostpaid) {
            data = {
                labels: [labelPostpaid],
                datasets: [
                    {
                        data: [usageItem.total],
                        backgroundColor: [colorPostpaid],
                    },
                ],
            };
        }
        // Used more than allowed (extra billed as postpaid)
        else if (usageItem.total > usageItem.totalAllowed) {
            data = {
                labels: [labelPrepaid, labelPostpaid],
                datasets: [
                    {
                        data: [
                            usageItem.totalAllowed,
                            usageItem.total - usageItem.totalAllowed,
                        ],
                        backgroundColor: [colorPrepaid, colorPostpaid],
                    },
                ],
            };
        }
        // Within subscription
        else {
            data = {
                labels: [labelPrepaid, labelUnused],
                datasets: [
                    {
                        data: [usageItem.total, usageItem.totalAllowed - usageItem.total],
                        backgroundColor: [colorPrepaid, colorUnused],
                    },
                ],
            };
        }
        return (_jsxs(S.UsageItemWrap, { children: [_jsx(DisplayText, { size: "extraSmall", space: "extraTight", children: getLocalizedText("broker_usage.item.title.".concat(baseKey)) }), _jsx(TextStyle, { variation: "subdued", children: getLocalizedText("broker_usage.item.info.".concat(baseKey)) }), _jsx(Spacer, { weight: ESpacerWeight.W08 }), isPostpaid && usageItem.total === 0 ? (_jsx(Lozenge, { children: getLocalizedText("system.no_data") })) : (_jsx(S.ChartWrap, { children: _jsx(Doughnut, { type: "-", 
                        // @ts-ignore
                        data: data, options: {
                            responsive: true,
                            animation: {
                                animateRotate: false,
                                animateScale: false,
                            },
                            radius: 170,
                            plugins: {
                                legend: {
                                    position: "top",
                                },
                                title: {
                                    display: true,
                                },
                            },
                        } }) }))] }));
    };
    return (_jsxs(S.Wrap, { children: [_jsx(DisplayText, { space: "extraTight", children: getLocalizedText("broker_usage.title") }), usage.period.start && usage.period.end ? (_jsx(TextStyle, { variation: "subdued", children: getLocalizedText("invoice.license_info", {
                    startDate: formatDate(usage.period.start),
                    endDate: formatDate(usage.period.end),
                }) })) : (_jsx(TextStyle, { variation: "subdued", children: getLocalizedText("invoice.license_info.postpaid") })), _jsx(Spacer, { weight: ESpacerWeight.W24 }), _jsxs(S.UsageItemsWrap, { children: [!usage.currentManagementLicense &&
                        renderChart("management_license", usage.managementLicence), !!usage.currentManagementLicense &&
                        renderChart("management_license_current", usage.currentManagementLicense), !!usage.lastInvoiceManagementLicense &&
                        renderChart("management_license_last_invoice", usage.lastInvoiceManagementLicense), renderChart("rental_fee", usage.lastInvoiceRentalFee || usage.rentalFee)] })] }));
};
