import { CONFIG } from "../../config/app.config";
import { EEnvironment } from "../../config/app.config.types";
import { appendQueryParams } from "../../utils/url.utils";
// TODO: Move to generic utils
export var getExternalUrl = function (moveRequest, isBroker) {
    var publicId = moveRequest.publicId, _a = moveRequest.publicToken, publicToken = _a === void 0 ? "demo" : _a;
    var environmentSubdomain = "";
    switch (CONFIG.ENVIRONMENT) {
        case EEnvironment.Dev:
            environmentSubdomain = ".dev";
            break;
        case EEnvironment.Stag:
            environmentSubdomain = ".stag";
            break;
        case EEnvironment.Demo:
            environmentSubdomain = ".demo";
            break;
        default:
            break;
    }
    return appendQueryParams({
        path: "https://move".concat(environmentSubdomain, ".rentio.be/move/self-serve"),
        queryParams: {
            moveRequestId: publicId,
            publicToken: publicToken,
            isBroker: isBroker ? "1" : "0",
        },
    });
};
export var getDashboardUrl = function (moveRequest) {
    var propertyId = moveRequest.propertyId, id = moveRequest.id;
    var environmentSubdomain = "";
    switch (CONFIG.ENVIRONMENT) {
        case EEnvironment.Dev:
            environmentSubdomain = ".dev";
            break;
        case EEnvironment.Stag:
            environmentSubdomain = ".staging";
            break;
        case EEnvironment.Demo:
            environmentSubdomain = ".demo";
            break;
        default:
            break;
    }
    return appendQueryParams({
        path: "https://dashboard".concat(environmentSubdomain, ".rentio.be/properties/").concat(propertyId),
        queryParams: {
            moveRequestId: "".concat(id),
        },
    });
};
export var getBackofficeUrl = function (params) {
    var _a = params.path, path = _a === void 0 ? "" : _a, queryParams = params.queryParams;
    var environmentSubdomain = "";
    switch (CONFIG.ENVIRONMENT) {
        case EEnvironment.Dev:
            environmentSubdomain = ".dev";
            break;
        case EEnvironment.Stag:
            environmentSubdomain = ".staging";
            break;
        case EEnvironment.Demo:
            environmentSubdomain = ".demo";
            break;
        default:
            break;
    }
    return appendQueryParams({
        path: "https://backoffice".concat(environmentSubdomain, ".rentio.be/").concat(path),
        queryParams: queryParams || {},
    });
};
