import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { IMember } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { ETaskMemberType } from "@rentiohq/shared-frontend/dist/types/task.types";
import { getMembersWithRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { TruncateTooltip } from "@rentiohq/web-shared/dist/components";
import { flatten } from "lodash";

interface IMembersWithRoleProps {
  members: IMember<ETaskMemberType>[];
  role: ETaskMemberType;
}

export const TaskMembersWithRole = ({
  members,
  role,
}: IMembersWithRoleProps) => {
  const { broker } = authHooks.useSelf();

  const membersWithRole = getMembersWithRole(members, role);

  let accountsToShow = membersWithRole.map(member => member.account);
  if (broker) {
    accountsToShow = flatten(
      membersWithRole.map(member => {
        const isSelf = member.account.id === broker.accountId;

        if (!isSelf) {
          return member.account;
        }

        const employeeAccounts = flatten(
          Object.values(member.employeeRoles || {}),
        );
        if (employeeAccounts.length === 0) {
          return member.account;
        }

        return employeeAccounts;
      }),
    );
  }

  if (accountsToShow.length === 0) {
    return <p>-</p>;
  }

  return (
    <TruncateTooltip width={120}>
      {join(accountsToShow.map(account => getName(account)))}
    </TruncateTooltip>
  );
};
