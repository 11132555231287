import { Box } from "@rebass/grid";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as contractActions from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.actions";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { isValidStopDateForTacitRenewal } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { isResidential } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EContractEndingTenantStatus,
  EContractLengthType,
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  differenceInDays,
  startOfDay,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatMediumDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  getMembersWithOneOfRoles,
  getMembersWithRole,
} from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  join,
  stringToSnakeCase,
} from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Card,
  DataTable,
  ESpacings,
  EmptyState,
  Error,
  Filters,
  Grid,
  IAppliedFilterInterface,
  IFilterInterface,
  Icon,
  Loading,
  Lozenge,
  OptionListShared,
  Page,
  Pagination,
  TSortDirection,
  TTextStyleVariation,
  TextStyle,
  Tooltip,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FinalNoticeDate } from "scenes/Properties/components/PropertyListItemContracts/ContractRow/components";
import { StringParam, withDefault } from "serialize-query-params";
import { ts } from "../../../services";
import RenewContractModalV2 from "../EndingContractsV2/components/RenewContractModal";
import {
  EEndDateWithinMonths,
  ERenewalDateWithinMonths,
} from "./EndingContracts.types";
import {
  getEndDateFilterQuery,
  getEndingTenantStatusVariation,
  getRenewalDateFilterQuery,
} from "./EndingContracts.utils";
import { ContractsAddressX } from "./components/ContractsAddress";
import EndingContractsStageEndDate from "./components/EndingContractsStageEndDate";
import EndingContractsStageRenewalDate from "./components/EndingContractsStageRenewalDate";
import RenewContractModal from "./components/RenewContractModal";

const NOW = new Date();
const START_OF_DAY = startOfDay(NOW);
const FETCH_LIMIT = 20;
const DEFAULT_SORT = "ASC";

enum EEndingContractsScreenType {
  RenewalDate = "renewal_date",
  EndDate = "end_date",
}

const EndingContracts: FC<{}> = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    screenType: withDefault(
      StringParam,
      EEndingContractsScreenType.RenewalDate,
    ),
    renewalDateWithinMonths: withDefault(
      StringParam,
      ERenewalDateWithinMonths.Within1Month,
    ),
    endDateWithinMonths: withDefault(
      StringParam,
      EEndDateWithinMonths.Within1Month,
    ),
  });

  const { screenType, renewalDateWithinMonths, endDateWithinMonths } =
    queryParams as {
      screenType: EEndingContractsScreenType;
      renewalDateWithinMonths: ERenewalDateWithinMonths;
      endDateWithinMonths: EEndDateWithinMonths;
    };

  const { internalModeEnabled } = useInternalMode();

  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  // State
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<"ASC" | "DESC">(DEFAULT_SORT);
  const [query, setQuery] = useState<string | undefined>();
  const [queryDebounced] = useDebounce(query);
  const [lengthType, setLengthType] = useState<
    EContractLengthType | undefined
  >();

  const [tenantStatus, setTenantStatus] =
    useState<EContractEndingTenantStatus>();

  const [filterQuery, setFilterQuery] = useState(
    (() => {
      switch (screenType) {
        case EEndingContractsScreenType.RenewalDate:
          return getRenewalDateFilterQuery({
            order,
            query,
            renewalDateWithinMonths,
            lengthType,
            tacitRenewal: true,
            tenantStatus,
          });

        case EEndingContractsScreenType.EndDate:
          return getEndDateFilterQuery({
            order,
            query,
            endDateWithinMonths,
            lengthType,
            tacitRenewal: false,
            tenantStatus,
          });

        default:
          return undefined;
      }
    })(),
  );
  const [renewalContractId, setRenewalContractId] = useState<
    string | undefined
  >(undefined);

  // Redux
  const dispatch = useDispatch();

  const {
    items: contracts,
    isFetching,
    fetchError,
    refetch: refetchContracts,
    totalPages,
  } = contractHooks.usePaged({
    query: {
      page,
      limit: FETCH_LIMIT,
      ...filterQuery,
    },
  });

  const contractsIdFilter =
    contracts && contracts.length > 0
      ? { in: contracts?.map(c => c.propertyId) }
      : undefined;

  const { items: contractProperties, isFetching: isFetchingProperties } =
    propertyHooks.usePaged({
      query: contractsIdFilter
        ? {
            page: 1,
            limit: FETCH_LIMIT,
            filter: {
              id: contractsIdFilter,
            },
          }
        : undefined,
    });

  const { update } = contractHooks.useUpdate({});

  // Hooks
  useEffect(() => {
    switch (screenType) {
      case EEndingContractsScreenType.RenewalDate:
        setFilterQuery(
          getRenewalDateFilterQuery({
            order,
            query: queryDebounced,
            renewalDateWithinMonths,
            lengthType,
            tacitRenewal: true,
            tenantStatus,
          }),
        );

        break;

      case EEndingContractsScreenType.EndDate:
        setFilterQuery(
          getEndDateFilterQuery({
            order,
            query: queryDebounced,
            endDateWithinMonths,
            lengthType,
            tacitRenewal: false,
            tenantStatus,
          }),
        );

        break;

      default:
        break;
    }
  }, [
    order,
    queryDebounced,
    renewalDateWithinMonths,
    endDateWithinMonths,
    lengthType,
    tenantStatus,
  ]);

  // Event handlers
  const handleQueryChange = (value: string) => {
    if (value.length === 0) {
      setQuery(undefined);
      return;
    }

    setQuery(value);
  };

  const handleSortChange = (index: number, sort: TSortDirection) => {
    if (index !== 1) {
      return;
    }

    if (sort === "DESC") {
      setOrder("DESC");
    } else {
      setOrder("ASC");
    }
  };

  const handleQueryClear = () => {
    setQuery(undefined);
  };

  const handlePageClick = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  // const handleClickRow = (index: number) => {
  //   const contract = contracts?.[index];
  //   if (!contract) {
  //     return;
  //   }

  //   setRenewalContractId(contract.id);
  // };

  // Render
  const appliedFilters: IAppliedFilterInterface[] = [];

  if (lengthType !== undefined) {
    appliedFilters.push({
      key: "length_type",
      label: getLocalizedText(
        `contract.length_type.option.${lengthType}`.toLowerCase(),
      ),
      onRemove: () => {
        setLengthType(undefined);
      },
    });
  }

  // if (tacitRenewal !== undefined) {
  //   appliedFilters.push({
  //     key: "tacit_renewal",
  //     label: getLocalizedText(
  //       `contract.tacit_renewal.label.${tacitRenewal ? "yes" : "no"}`,
  //     ),
  //     onRemove: () => {
  //       setTacitRenewal(undefined);
  //     },
  //   });
  // }

  const filters: IFilterInterface[] = [];

  if (screenType === EEndingContractsScreenType.RenewalDate) {
    filters.push({
      key: "ending_contracts.option.renewal_date_within_months",
      label: getLocalizedText(
        "ending_contracts.option.renewal_date_within_months",
      ),
      renderContent: (closeDropdownMenu: () => void) => (
        <OptionListShared
          id="renewalDateWithinMonths"
          value={[renewalDateWithinMonths]}
          variant="simple"
          multiple={false}
          options={Object.values(ERenewalDateWithinMonths).map(id => ({
            label: getLocalizedText(
              `ending_contracts.option.renewal_date_within_months.${stringToSnakeCase(
                id,
              )}`,
            ),
            value: id,
            id,
          }))}
          onChange={(newValue: ERenewalDateWithinMonths[]) => {
            setQueryParams({ renewalDateWithinMonths: newValue[0] });

            closeDropdownMenu();
          }}
        />
      ),
    });
  }

  if (screenType === EEndingContractsScreenType.EndDate) {
    filters.push({
      key: "ending_contracts.option.end_date_within_months",
      label: getLocalizedText("ending_contracts.option.end_date_within_months"),
      renderContent: (closeDropdownMenu: () => void) => (
        <OptionListShared
          id="endDateWithinMonths"
          value={[endDateWithinMonths]}
          variant="simple"
          multiple={false}
          options={[
            EEndDateWithinMonths.Within1Month,
            EEndDateWithinMonths.Within4Months,
            EEndDateWithinMonths.Within7Months,
          ].map(id => ({
            label: getLocalizedText(
              `ending_contracts.option.end_date_within_months.${stringToSnakeCase(
                id,
              )}`,
            ),
            value: id,
            id,
          }))}
          onChange={(newValue: EEndDateWithinMonths[]) => {
            setQueryParams({ endDateWithinMonths: newValue[0] });

            closeDropdownMenu();
          }}
        />
      ),
    });
  }

  filters.push({
    key: "contract.ending.tenant.status.label",
    label: getLocalizedText("contract.ending.tenant.status.label"),
    renderContent: (closeDropdownMenu: () => void) => (
      <OptionListShared
        id="tenantStatus"
        value={tenantStatus === undefined ? ["all"] : [tenantStatus]}
        variant="simple"
        multiple={false}
        options={[
          "all",
          ...(Object.values(EContractEndingTenantStatus) as string[]),
        ].map(id => ({
          label:
            id === "all"
              ? getLocalizedText("system.all")
              : getLocalizedText(
                  `contract.ending.tenant.status.${stringToSnakeCase(id)}`,
                ),
          value: id,
          id,
        }))}
        onChange={(newValues: string[]) => {
          const newValue = newValues[0];

          if (newValue === "all") {
            setTenantStatus(undefined);

            closeDropdownMenu();
            return;
          }

          const contractStatus = newValue as EContractEndingTenantStatus;

          setTenantStatus(contractStatus);

          closeDropdownMenu();
        }}
      />
    ),
  });

  filters.push({
    key: "contract.length_type.option.label.short",
    label: getLocalizedText("contract.length_type.option.label.short"),
    renderContent: (closeDropdownMenu: () => void) => (
      <OptionListShared
        id="lengthType"
        value={lengthType === undefined ? ["all"] : [lengthType]}
        variant="simple"
        multiple={false}
        // @ts-ignore
        options={["all", ...Object.values(EContractLengthType)].map(id => {
          let label = `contract.length_type.option.${id}`.toLowerCase();
          if (id === "all") {
            label = "system.all";
          }

          return {
            label: getLocalizedText(label),
            value: id,
            id,
          };
        })}
        onChange={(newValues: (EContractLengthType | "all")[]) => {
          const newValue = newValues[0];

          if (newValue === "all") {
            setLengthType(undefined);

            closeDropdownMenu();
            return;
          }

          setLengthType(newValue);

          closeDropdownMenu();
        }}
      />
    ),
  });

  // filters.push({
  //   key: "contract.tacit_renewal.label.short",
  //   label: getLocalizedText("contract.tacit_renewal.label.short"),
  //   renderContent: (closeDropdownMenu: () => void) => (
  //     <OptionListShared
  //       id="tacitRenewal"
  //       value={tacitRenewal === undefined ? "all" : `${tacitRenewal}`}
  //       variant="simple"
  //       multiple={false}
  //       options={["all", false, true].map(id => {
  //         let labelPostfix = "all";
  //         if (id !== "all") {
  //           labelPostfix = id ? "yes" : "no";
  //         }

  //         return {
  //           label: getLocalizedText(`system.${labelPostfix}`),
  //           value: `${id}`,
  //           id: `${id}`,
  //         };
  //       })}
  //       // @ts-ignore
  //       onChange={(newValue: string) => {
  //         let newValueParsed = undefined;
  //         if (newValue === "true") {
  //           newValueParsed = true;
  //         } else if (newValue === "false") {
  //           newValueParsed = false;
  //         }
  //         setTacitRenewal(newValueParsed);

  //         closeDropdownMenu();
  //       }}
  //     />
  //   ),
  // });

  const renderFilters = () => (
    <Box mb={ESpacings.loose}>
      <Filters
        queryValue={query || ""}
        queryPlaceholder={getLocalizedText(
          "ending_contracts.filter.query.placeholder",
        )}
        onQueryChange={handleQueryChange}
        onQueryClear={handleQueryClear}
        appliedFilters={appliedFilters}
        filters={filters}
      />
    </Box>
  );

  const setTacitRenewal = (contractId: string, tacitRenewal: boolean) => {
    update(
      {
        data: {
          tacitRenewal,
        },
      },
      contractId,
    );
  };

  const getRowActions = (contract: IContract) => {
    let result: IAction[][] = [];
    const property = contractProperties
      ? contractProperties.find(cp => cp.id === contract.propertyId)
      : null;

    const renewAction: IAction = {
      id: "view",
      content: (
        <TextStyle variation="positive">
          {getLocalizedText("ending_contracts.cta.renew_contract")}
        </TextStyle>
      ),
      onClick: () => {
        setRenewalContractId(contract.id);
      },
    };

    const disableTacitRenewalAction: IAction = {
      id: "view",
      content: (
        <TextStyle variation="warn">
          {getLocalizedText("ending_contracts.cta.disable_tacit_renewal")}
        </TextStyle>
      ),
      onClick: () => setTacitRenewal(contract.id, false),
    };

    const enableTacitRenewalAction: IAction = {
      id: "view",
      content: getLocalizedText("ending_contracts.cta.enable_tacit_renewal"),
      onClick: () => setTacitRenewal(contract.id, true),
    };

    if (contract.tacitRenewal) {
      result.push([renewAction, disableTacitRenewalAction]);
    } else if (
      property &&
      isResidential(property) &&
      isValidStopDateForTacitRenewal({
        startDate: contract.startDate,
        stopDate: contract.stopDate,
      })
    ) {
      result.push([renewAction, enableTacitRenewalAction]);
    } else {
      result.push([renewAction]);
    }

    result.push([
      {
        id: "view",
        content: getLocalizedText("property.view_property.action"),
        url: `/properties/${contract.propertyId}/contracts`,
      },
    ]);

    return result;
  };

  const renderTable = () => {
    if (!contracts) {
      if (isFetching || isFetchingProperties) {
        return <Loading asDots={true} />;
      }

      if (fetchError) {
        return <Error errors={[fetchError]} />;
      }

      return;
    }

    if (contracts.length === 0) {
      return (
        <EmptyState heading={ts.emptyStateSearchHeading()} icon="archive">
          {ts.emptyStateSearchContent()}
        </EmptyState>
      );
    }

    return (
      <DataTable
        // onRowClick={handleClickRow}
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "text",
          "action",
        ]}
        headings={[
          getLocalizedText("contract.renewal_deadline.short"),
          getLocalizedText("contract.enddate"),
          getLocalizedText("contract.length_type.option.label.short"),
          getLocalizedText("contract.tacit_renewal.label.short"),
          getLocalizedText("system.address"),
          getLocalizedText("system.owners"),
          getLocalizedText("system.tenants"),
          getLocalizedText("contract.ending.tenant.status.label"),
          "",
        ]}
        sortable={[
          false,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ]}
        sortDirection={order}
        onSort={handleSortChange}
        rows={contracts.map(contract => {
          const renewalDeadline = contractUtils.getRenewalDeadline(contract);

          let stopDateVariation: TTextStyleVariation = "default";
          if (!renewalDeadline && contract.stopDate) {
            const daysTilDeadline = differenceInDays(
              contract.stopDate,
              START_OF_DAY,
            );
            if (daysTilDeadline < 0) {
              stopDateVariation = "negative";
            } else if (daysTilDeadline < 30) {
              stopDateVariation = "warn";
            }
          }

          const renewalInfo = contractUtils.getRenewalInfo(contract);

          return {
            id: contract.id,
            content: [
              <FinalNoticeDate contract={contract} />,
              <TextStyle variation={stopDateVariation}>
                {contract.stopDate ? formatMediumDate(contract.stopDate) : "-"}
              </TextStyle>,
              contract.contractLengthType ? (
                getLocalizedText(
                  `contract.length_type.option.${contract.contractLengthType}`.toLowerCase(),
                )
              ) : (
                <TextStyle variation={"subdued"}>-</TextStyle>
              ),
              renewalInfo ? (
                <Grid spacing="extraTight">
                  <TextStyle>
                    {getLocalizedText(
                      `system.${
                        contract.tacitRenewal === true ? "yes" : "no"
                      }`.toLowerCase(),
                    )}
                  </TextStyle>
                  <Tooltip tooltipContent={renewalInfo}>
                    <Icon source="informationCircle" size="small" />
                  </Tooltip>
                </Grid>
              ) : (
                getLocalizedText(
                  `system.${
                    contract.tacitRenewal === true ? "yes" : "no"
                  }`.toLowerCase(),
                )
              ),
              <ContractsAddressX contract={contract} />,
              join(
                getMembersWithRole(
                  contract.members,
                  EContractMemberTypes.Owner,
                ).map(member => getName(member.account)),
              ),
              join(
                getMembersWithOneOfRoles(contract.members, [
                  EContractMemberTypes.Tenant,
                  EContractMemberTypes.Parent,
                ]).map(member => getName(member.account)),
              ),
              contract.contractEndingTenantStatus ? (
                <Lozenge
                  key={`${contract.id}-stage`}
                  appearance={getEndingTenantStatusVariation(
                    contract.contractEndingTenantStatus,
                  )}
                >
                  {getLocalizedText(
                    `contract.ending.tenant.status.${contract.contractEndingTenantStatus}`.toLowerCase(),
                  )}
                </Lozenge>
              ) : (
                <TextStyle variation={"subdued"}>-</TextStyle>
              ),
            ],
            actions: getRowActions(contract),
          };
        })}
        // totalPages={totalPages}
        // @ts-ignore
        extraData={contracts}
      />
    );
  };

  const renderPaging = () => {
    if (!totalPages || totalPages <= 1) {
      return;
    }

    return (
      <Pagination
        initialPage={page - 1}
        pageCount={totalPages}
        onPageChange={handlePageClick}
      />
    );
  };

  const renderRenewContractModal = () => {
    if (renewalContractId) {
      // TODO: To be removed internalModeEnabled
      if (internalModeEnabled && hasPaymentV2ForBroker) {
        return (
          <RenewContractModalV2
            contractId={renewalContractId}
            onClose={() => {
              setRenewalContractId(undefined);
            }}
            onSuccess={() => {
              if (page > 1) {
                setPage(1);
              } else {
                refetchContracts();
              }

              if (screenType === EEndingContractsScreenType.RenewalDate) {
                dispatch(
                  contractActions.getCountStart.getAction({
                    query: getRenewalDateFilterQuery({
                      tacitRenewal: true,
                      renewalDateWithinMonths,
                    }),
                  }),
                );
              } else if (screenType === EEndingContractsScreenType.EndDate) {
                dispatch(
                  contractActions.getCountStart.getAction({
                    query: getEndDateFilterQuery({
                      tacitRenewal: false,
                      endDateWithinMonths,
                    }),
                  }),
                );
              }

              setRenewalContractId(undefined);
            }}
          />
        );
      } else {
        return (
          <RenewContractModal
            contractId={renewalContractId}
            onClose={() => {
              setRenewalContractId(undefined);
            }}
            onSuccess={() => {
              if (page > 1) {
                setPage(1);
              } else {
                refetchContracts();
              }

              if (screenType === EEndingContractsScreenType.RenewalDate) {
                dispatch(
                  contractActions.getCountStart.getAction({
                    query: getRenewalDateFilterQuery({
                      tacitRenewal: true,
                      renewalDateWithinMonths,
                    }),
                  }),
                );
              } else if (screenType === EEndingContractsScreenType.EndDate) {
                dispatch(
                  contractActions.getCountStart.getAction({
                    query: getEndDateFilterQuery({
                      tacitRenewal: false,
                      endDateWithinMonths,
                    }),
                  }),
                );
              }

              setRenewalContractId(undefined);
            }}
          />
        );
      }
    }
  };

  return (
    <Page
      fullWidth
      title={getLocalizedText(
        `ending_contracts.page.title.${stringToSnakeCase(screenType)}`,
      )}
      metadata={getLocalizedText(
        `ending_contracts.page.description.${stringToSnakeCase(screenType)}`,
      )}
      breadcrumbs={{
        to: "/",
        content: getLocalizedText("system.back"),
      }}
    >
      <Card>
        {screenType === EEndingContractsScreenType.RenewalDate && (
          <EndingContractsStageRenewalDate
            activeRenewalDateWithinMonths={renewalDateWithinMonths}
            onClickActiveRenewalDate={(newValue: ERenewalDateWithinMonths) => {
              setQueryParams({ renewalDateWithinMonths: newValue });
            }}
          />
        )}

        {screenType === EEndingContractsScreenType.EndDate && (
          <EndingContractsStageEndDate
            activeEndDateWithinMonths={endDateWithinMonths}
            onClickActiveEndDate={(newValue: EEndDateWithinMonths) => {
              setQueryParams({ endDateWithinMonths: newValue });
            }}
          />
        )}
      </Card>

      <Card>
        {renderFilters()}
        {renderTable()}
        {renderPaging()}
      </Card>

      {renewalContractId && renderRenewContractModal()}
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default EndingContracts;
