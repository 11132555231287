import {
  getMappedName,
  getName,
} from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { formatAddress as formatAddressLead } from "@rentiohq/shared-frontend/dist/reduxInsurance/lead/lead.utils";
import { IActivityItem } from "@rentiohq/shared-frontend/dist/types/activity.types";
import {
  mapActivitySubtypeToString,
  mapActivityTypeToString,
} from "@rentiohq/shared-frontend/dist/utils/activity.utils";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import * as paymentOrderUtils from "@rentiohq/shared-frontend/dist/utils/paymentOrder.utils";
import * as paymentRequestUtils from "@rentiohq/shared-frontend/dist/utils/paymentRequest.utils";
import { capitalize } from "@rentiohq/shared-frontend/dist/utils/text/text.utils";
import { Typography } from "@rentiohq/web-shared-next/dist/ui-components";
import { ActivityTextStyles } from "./ActivityText.styles";

interface IProps {
  activityItem: IActivityItem;
  onParamPress?: (param: string) => void;
}

export const ActivityText = ({ activityItem, onParamPress }: IProps) => {
  const sx = ActivityTextStyles();

  const renderFallbackInfo = () => getLocalizedText("activity.not_supported");

  const renderTouchableActivityText = (
    activityItem: IActivityItem,
    nonTouchableValues: {},
    touchableValues: { [key: string]: string | undefined },
  ) => {
    const { type, subtype, lokaliseKey } = activityItem;

    let key = `activity.${mapActivityTypeToString(
      type,
      false,
    )}.${mapActivitySubtypeToString(subtype, false)}`.toLowerCase();

    if (lokaliseKey && getLocalizedText(lokaliseKey) !== lokaliseKey) {
      key = lokaliseKey;
    }

    const baseString = getLocalizedText(key, nonTouchableValues);
    const stringComponents = baseString.split(/{{|}}/);

    return (
      <>
        {stringComponents.map((stringComponent, index) => {
          const touchableValue = touchableValues[stringComponent];

          if (touchableValue) {
            // Will be used later
            // const handlePress = () => {
            //   if (onParamPress) {
            //     onParamPress(stringComponent);
            //   }
            // };

            // @ts-ignore
            return activityItem[stringComponent]
              ? touchableValue
              : touchableValue;
          }

          if (index === 0) {
            return capitalize(stringComponent);
          }

          return stringComponent;
        })}
      </>
    );
  };

  const renderContent = () => {
    const {
      actor,
      amount,
      application,
      asset,
      broker,
      contact,
      contract,
      contractNewPrice,
      contractOldPrice,
      contractRentDiscountPrice,
      documentFilename,
      documentPackage,
      email,
      employee,
      invitingAccount,
      invoiceAmount,
      lead,
      locationDescriptionRejectionComment,
      newStopDate,
      notificationType,
      oldStopDate,
      stopDate,
      participant,
      payee,
      payeeBankAccount,
      payer,
      payerBankAccount,
      paymentOrder,
      paymentRequest,
      paymentTransaction,
      property,
      propertyInfo,
      quote,
      reference,
      rentAmount,
      rentContractRejectionComment,
      roles,
      source,
      step,
      subtype,
      tacVersion,
      taskTitle,
      type,
      visitUrl,
      usedSigningType,
      datetime,
      paymentPayIn,
      paymentPayInRemainingBalance,
      epcLabel,
      epcValue,
      epcExpirationDate,
      reasonForCancellation,
      dateOfCancellation,
    } = activityItem;

    if (
      mapActivityTypeToString(type, false) === undefined ||
      mapActivitySubtypeToString(subtype, false) === undefined
    ) {
      return renderFallbackInfo();
    }

    let paymentType = "invalid payment order";
    if (paymentOrder) {
      paymentType = paymentOrderUtils.getTitle(paymentOrder);
    }

    let paymentRequestPeriod = "invalid payment request";
    if (paymentRequest) {
      if (paymentRequest.startedAt === paymentRequest.endedAt) {
        paymentRequestPeriod = formatDate(paymentRequest.startedAt);
      } else {
        paymentRequestPeriod = `${formatDate(
          paymentRequest.startedAt,
        )} - ${formatDate(paymentRequest.endedAt)}`;
      }
    }

    let paymentRequestEstimatedCollectionDate = "invalid payment request";
    if (paymentRequest?.estimatedCollectionDate) {
      paymentRequestEstimatedCollectionDate = formatDate(
        paymentRequest.estimatedCollectionDate,
      );
    }

    let paymentRequestTitle = "invalid payment request";
    if (paymentRequest) {
      paymentRequestTitle = `${paymentRequestUtils.getTitle(
        paymentRequest,
      )} (${paymentRequestPeriod})`;
    }

    let actorFormatted = actor
      ? getMappedName(actor, false)
      : getLocalizedText("system.rentio");
    if (employee?.firstname || employee?.lastname) {
      actorFormatted = `${actorFormatted} (${[
        employee.firstname,
        employee.lastname,
      ]
        .filter(Boolean)
        .join(" ")})`;
    }

    const validStopDate = (date: Date) => {
      if (typeof date === "string") {
        return new Date(date) || contract?.stopDate;
      } else {
        return date;
      }
    };

    const values = {
      createdAtDateAndTime: datetime
        ? formatDate(datetime, "dd/MM/yyyy HH:mm")
        : "invalid datetime",
      createdAtDate: datetime ? formatDate(datetime) : "invalid datetime",
      createdAtTime: datetime
        ? formatDate(datetime, "HH:mm")
        : "invalid datetime",
      actor: actorFormatted,
      amount: amount ? formatCurrency(amount) : "invalid amount",
      application: application
        ? getName(application.contacts[0])
        : getLocalizedText("activity.param_not_available.application"),
      applicationEmail: application?.applicationEmail || "No email",
      asset: asset ? asset.name : "invalid asset",
      broker: broker ? broker.name : "invalid broker",
      contact: contact ? getName(contact) : "invalid contact",
      contract: contract
        ? getLocalizedText(
            `contract.type.${contract.contractType}`,
          ).toLowerCase()
        : "invalid contract",
      contractNewPrice: contractNewPrice
        ? formatCurrency(contractNewPrice)
        : "invalid amount",
      contractOldPrice: contractOldPrice
        ? formatCurrency(contractOldPrice)
        : "invalid amount",
      contractRentDiscountPrice: contractRentDiscountPrice
        ? formatCurrency(contractRentDiscountPrice)
        : "invalid amount",
      documentFilename: documentFilename
        ? documentFilename
        : "invalid document filename",
      documentPackage: documentPackage
        ? documentPackage.name
        : "invalid document package",
      iban: payeeBankAccount ? payeeBankAccount.iban : "invalid iban",
      payeeBankAccount: payeeBankAccount
        ? payeeBankAccount.iban
        : "invalid iban",
      payerBankAccount: payerBankAccount
        ? payerBankAccount.iban
        : "invalid iban",
      invitingAccount: invitingAccount
        ? getMappedName(invitingAccount, false)
        : getLocalizedText("system.rentio"),
      invoiceAmount: invoiceAmount
        ? formatCurrency(invoiceAmount)
        : "invalid amount",
      newStopDate: newStopDate
        ? formatDate(newStopDate)
        : "invalid new stop date",
      notificationType: notificationType
        ? getLocalizedText(
            `activity.notification_type.${notificationType}`,
          ).toLowerCase()
        : `unknown type: ${notificationType}`,
      oldStopDate: oldStopDate
        ? formatDate(oldStopDate)
        : "invalid old stop date",
      participant: participant
        ? getMappedName(participant, false)
        : "invalid participant",
      payee: payee ? getMappedName(payee, false) : "invalid payee",
      payer: payer ? getMappedName(payer, false) : "invalid payer",
      paymentRequestEstimatedCollectionDate,
      paymentRequestAmount: paymentRequest
        ? formatCurrency(paymentRequest.originalAmount)
        : "invalid amount",
      paymentRequestPeriod,
      paymentRequestInterface: paymentRequest?.paymentInterface
        ? getLocalizedText(
            `payment_request.interface.${paymentRequest.paymentInterface}`,
          )
        : "invalid payment interface",
      paymentRequestMethod: paymentRequest?.paymentMethod
        ? getLocalizedText(
            `payment_request.method.${paymentRequest.paymentMethod}`,
          )
        : "invalid payment method",
      paymentTransactionAmount: paymentTransaction
        ? formatCurrency(paymentTransaction.amount)
        : "invalid amount",
      paymentRequest: paymentRequestTitle,
      paymentType,
      paymentPayInPayerName: paymentPayIn?.authorName || "invalid author",
      paymentPayInPayerIban: paymentPayIn?.authorIban || "invalid author",
      paymentPayInRemainingBalance: paymentPayInRemainingBalance
        ? formatCurrency(paymentPayInRemainingBalance)
        : "invalid balance",
      property: property ? formatAddress(property) : "invalid property",
      propertyInfo: propertyInfo
        ? propertyInfo.reference
        : "invalid property info",
      roles: roles
        ? roles
            .map(role => getLocalizedText(`role.${role.toLowerCase()}`))
            .join(", ")
        : "invalid roles",
      reference: reference ? reference : "no reference",
      rentAmount: rentAmount ? formatCurrency(rentAmount) : "invalid amount",
      source: source
        ? getLocalizedText(`source.${source.toLowerCase()}`)
        : "Rentio",
      step: step
        ? getLocalizedText(`property.dashboard.application.${step}.title`)
        : "invalid step",
      tacVersion: tacVersion || "invalid version",
      taskTitle: taskTitle || "invalid task",
      usedSigningType: usedSigningType
        ? getLocalizedText(`used_signing_type.${usedSigningType.toLowerCase()}`)
        : "invalid used signing type",
      leadId: lead ? lead.id : "invalid lead",
      leadAddress: lead ? formatAddressLead(lead) : "invalid lead",
      leadName: lead
        ? `${lead.contactFirstname} ${lead.contactLastname}`
        : "invalid lead",
      leadReferrer: lead ? lead.referrer : "invalid lead",
      leadEmail: lead ? lead.contactEmail : "invalid email",
      quoteValue: quote
        ? formatCurrency(quote.calculationValue)
        : "invalid quote",
      rentContractRejectionComment:
        rentContractRejectionComment || "invalid reason",
      locationDescriptionRejectionComment:
        locationDescriptionRejectionComment || "invalid reason",
      epcLabel,
      epcValue,
      epcExpirationDate,
      stopDate: stopDate
        ? formatDate(validStopDate(stopDate))
        : "invalid stop date",
      reasonForCancellation: reasonForCancellation
        ? getLocalizedText(`contract.cancellation.${reasonForCancellation}`)
        : "invalid reason",
      dateOfCancellation: dateOfCancellation
        ? formatDate(dateOfCancellation)
        : "invalid cancelled date",
    };

    return renderTouchableActivityText(
      activityItem,
      {
        createdAtDateAndTime: values.createdAtDateAndTime,
        createdAtDate: values.createdAtDate,
        createdAtTime: values.createdAtTime,
        amount: values.amount,
        application: values.application,
        applicationEmail: values.applicationEmail,
        asset: values.asset,
        broker: values.broker,
        contract: values.contract,
        contractNewPrice: values.contractNewPrice,
        contractOldPrice: values.contractOldPrice,
        contractRentDiscountPrice: values.contractRentDiscountPrice,
        documentPackage: values.documentPackage,
        email,
        iban: values.iban,
        payerBankAccount: values.payerBankAccount,
        payeeBankAccount: values.payeeBankAccount,
        invitingAccount: values.invitingAccount,
        invoiceAmount: values.invoiceAmount,
        leadAddress: values.leadAddress,
        leadId: values.leadId,
        leadName: values.leadName,
        leadReferrer: values.leadReferrer,
        leadEmail: values.leadEmail,
        newStopDate: values.newStopDate,
        notificationType: values.notificationType,
        oldStopDate: values.oldStopDate,
        paymentRequestAmount: values.paymentRequestAmount,
        paymentRequestEstimatedCollectionDate:
          values.paymentRequestEstimatedCollectionDate,
        paymentRequestInterface: values.paymentRequestInterface,
        paymentRequestMethod: values.paymentRequestMethod,
        paymentRequestPeriod: values.paymentRequestPeriod,
        paymentTransactionAmount: values.paymentTransactionAmount,
        paymentPayInPayerName: values.paymentPayInPayerName,
        paymentPayInPayerIban: values.paymentPayInPayerIban,
        paymentPayInRemainingBalance: values.paymentPayInRemainingBalance,
        propertyInfo: values.propertyInfo,
        quoteValue: values.quoteValue,
        rentAmount: values.rentAmount,
        roles: values.roles,
        source: values.source,
        reference: values.reference,
        step: values.step,
        tacVersion: values.tacVersion,
        usedSigningType: values.usedSigningType,
        rentContractRejectionComment: values.rentContractRejectionComment,
        locationDescriptionRejectionComment:
          values.locationDescriptionRejectionComment,
        epcLabel: values.epcLabel,
        epcValue: values.epcValue,
        epcExpirationDate: values.epcExpirationDate,
        stopDate: values.stopDate,
        reasonForCancellation: values.reasonForCancellation,
        dateOfCancellation: values.dateOfCancellation,
      },
      {
        actor: values.actor,
        contact: values.contact,
        documentFilename,
        participant: values.participant,
        payee: values.payee,
        payer: values.payer,
        paymentRequest: values.paymentRequest,
        paymentType: values.paymentType,
        property: values.property,
        taskTitle,
        visitUrl,
      },
    );
  };

  return (
    <Typography variant="subtitle2" sx={sx.wrapper}>
      {renderContent()}
    </Typography>
  );
};
