import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EDocumentPackageTypes } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import {
  EDocumentSigningTypes,
  EField,
  IExtraData,
} from "./schema.createDocumentPackage.types";
import { getNavigationTitle } from "./schema.createDocumentPackage.utils";

export const getSchema =
  (initialValues: { [key: string]: any }) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => ({
    required: [EField.SigningTypes],
    properties: {
      [EField.Header + "4"]: {
        default: undefined,
      },
      [EField.SigningTypes]: {
        default: getValue(EField.SigningTypes, initialValues, defaultValues),
        // @ts-ignore
        options: Object.values(EDocumentSigningTypes).map(id => ({
          id,
          value: utils.i18n.t(
            `document_package.form.signing_type.option.${id.toLowerCase()}.label`,
          ),
          subValue: utils.i18n.t(
            `document_package.form.signing_type.option.${id.toLowerCase()}.description`,
          ),
        })),
      },
      [EField.SigningTypesWarning]: {
        default: getValue(
          EField.SigningTypesWarning,
          initialValues,
          defaultValues,
        ),
        // @ts-ignore
        showIf: (formData: any) => {
          // Only show warning for rent contracts
          if (
            formData[EField.DocumentPackageType] !==
            EDocumentPackageTypes.Contract
          ) {
            return false;
          }

          // Only show warning when OTP (email/sms) is selected
          if (
            !(formData[EField.SigningTypes] || []).includes(
              EDocumentSigningTypes.OTP,
            )
          ) {
            return false;
          }

          return true;
        },
      },
    },
  });

export const uiSchema = (_: IExtraData): IUiSchema<EField> => ({
  "rentio:title": utils.i18n.t(
    "document_package.form.signing_type.step_heading",
  ),
  "rentio:navigationTitle": getNavigationTitle(),
  [EField.Header + "4"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: utils.i18n.t("document_package.form.signing_type.heading"),
  },
  [EField.SigningTypes]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: utils.i18n.t("document_package.form.signing_type.label"),
    formatSummary: (values: any) =>
      values
        .map((id: string) =>
          utils.i18n.t(
            `document_package.form.signing_type.option.${id.toLowerCase()}.label`,
          ),
        )
        .join(", "),
    hideOnSummaryIfEmpty: true,
    isMultiSelect: true,
  },
  [EField.SigningTypesWarning]: {
    "ui:field": ERentioFormField.HelpInfo,
    type: "warning",
    title: getLocalizedText(
      "document_package.form.signing_type_warning_contract.heading",
    ),
    subtitle: getLocalizedText(
      "document_package.form.signing_type_warning_contract.content",
    ),
  },
});
