export var PRIMARY_COUNTRIES = ["BE", "NL", "FR", "DE"];
// https://support.mangopay.com/s/article/which-are-the-authorized-countries-where-you-can-process-payments?language=en_US
// Unsupported mangopay countries are commented out
export var ALL_COUNTRIES = [
    "AD",
    "AE", // TODO: Check country in mangopay
    "AF", // TODO: Check country in mangopay
    "AG",
    "AI",
    "AL", // TODO: Check country in mangopay
    "AM",
    "AO",
    "AQ", // TODO: Check country in mangopay
    "AR",
    "AS",
    "AT",
    "AU",
    "AW",
    "AX",
    "AZ", // TODO: Check country in mangopay
    "BA",
    "BB", // TODO: Check country in mangopay
    "BD",
    "BF", // TODO: Check country in mangopay
    "BG",
    "BH",
    "BI",
    "BJ",
    "BL",
    "BM",
    "BN",
    "BO",
    "BQ",
    "BR",
    "BS",
    "BT",
    "BV", // TODO: Check country in mangopay
    "BW",
    "BY", // TODO: Check country in mangopay
    "BZ",
    "CA",
    "CC",
    "CD", // TODO: Check country in mangopay
    "CF", // TODO: Check country in mangopay
    "CG",
    "CH",
    "CI",
    "CK",
    "CL",
    "CM",
    "CN",
    "CO",
    "CR",
    "CU", // TODO: Check country in mangopay
    "CV",
    "CW",
    "CX",
    "CY",
    "CZ",
    "DJ",
    "DK",
    "DM",
    "DO",
    "DZ",
    "EC",
    "EE",
    "EG",
    "EH",
    "ER", // TODO: Check country in mangopay
    "ES",
    "ET", // TODO: Check country in mangopay
    "FI",
    "FJ",
    "FK",
    "FM",
    "FO",
    "GA",
    "GB",
    "GD",
    "GE",
    "GF",
    "GG",
    "GH",
    "GI",
    "GL",
    "GM",
    "GN", // TODO: Check country in mangopay
    "GP",
    "GQ",
    "GR",
    "GS", // TODO: Check country in mangopay
    "GT",
    "GU",
    "GW",
    "GY",
    "HK",
    "HM", // TODO: Check country in mangopay
    "HN",
    "HR",
    "HT", // TODO: Check country in mangopay
    "HU",
    "ID",
    "IE",
    "IL",
    "IM",
    "IN",
    "IO",
    "IQ", // TODO: Check country in mangopay
    "IR", // TODO: Check country in mangopay
    "IS",
    "IT",
    "JE",
    "JM", // TODO: Check country in mangopay
    "JO", // TODO: Check country in mangopay
    "JP",
    "KE",
    "KG",
    "KH", // TODO: Check country in mangopay
    "KI",
    "KM",
    "KN",
    "KP", // TODO: Check country in mangopay
    "KR",
    "KW",
    "KY", // TODO: Check country in mangopay
    "KZ", // TODO: Check country in mangopay
    "LA",
    "LB", // TODO: Check country in mangopay
    "LC",
    "LI",
    "LK",
    "LR", // TODO: Check country in mangopay
    "LS",
    "LT",
    "LU",
    "LV",
    "LY", // TODO: Check country in mangopay
    "MA", // TODO: Check country in mangopay
    "MC",
    "MD",
    "ME",
    "MF",
    "MG",
    "MH",
    "MK",
    "ML", // TODO: Check country in mangopay
    "MM", // TODO: Check country in mangopay
    "MN",
    "MO",
    "MP",
    "MQ",
    "MR",
    "MS",
    "MT",
    "MU",
    "MV",
    "MW",
    "MX",
    "MY",
    "MZ",
    "NA",
    "NC",
    "NE",
    "NF",
    "NG",
    "NI", // TODO: Check country in mangopay
    "NO",
    "NP",
    "NR",
    "NU",
    "NZ",
    "OM",
    "PA", // TODO: Check country in mangopay
    "PE",
    "PF",
    "PG",
    "PH", // TODO: Check country in mangopay
    "PK", // TODO: Check country in mangopay
    "PL",
    "PM",
    "PN",
    "PR",
    "PS", // TODO: Check country in mangopay
    "PT",
    "PW",
    "PY",
    "QA",
    "RE",
    "RO",
    "RS",
    "RU", // TODO: Check country in mangopay
    "RW",
    "SA",
    "SB",
    "SC",
    "SD", // TODO: Check country in mangopay
    "SE",
    "SG",
    "SH",
    "SI",
    "SJ",
    "SK",
    "SL",
    "SM",
    "SN", // TODO: Check country in mangopay
    "SO", // TODO: Check country in mangopay
    "SR",
    "SS", // TODO: Check country in mangopay
    "ST",
    "SV",
    "SX",
    "SY", // TODO: Check country in mangopay
    "SZ",
    "TC",
    "TD",
    "TF", // TODO: Check country in mangopay
    "TG",
    "TH",
    "TJ", // TODO: Check country in mangopay
    "TK",
    "TL",
    "TM", // TODO: Check country in mangopay
    "TN",
    "TO",
    "TR", // TODO: Check country in mangopay
    "TT", // TODO: Check country in mangopay
    "TV",
    "TW",
    "TZ",
    "UA", // TODO: Check country in mangopay
    "UG", // TODO: Check country in mangopay
    "UM",
    "US",
    "UY",
    "UZ", // TODO: Check country in mangopay
    "VA",
    "VC",
    "VE", // TODO: Check country in mangopay
    "VG",
    "VI",
    "VN",
    "VU", // TODO: Check country in mangopay
    "WF",
    "WS",
    "XK",
    "YE", // TODO: Check country in mangopay
    "YT",
    "ZA",
    "ZM",
    "ZW", // TODO: Check country in mangopay
];
