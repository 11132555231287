import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EPaymentOrderRole,
  EPaymentOrderType,
  EPaymentRepetitionType,
  IPaymentOrder,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { getTitle } from "@rentiohq/shared-frontend/dist/utils/paymentOrder.utils";
import {
  Button,
  Grid,
  Icon,
  Page,
  Stack,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAmounts } from "../../../../scenes/Properties/scenes/Payments/scenes/PaymentsOverview";
import { EPaymentOrderTabs, IPaymentOrderProps } from "./PaymentOrder.types";
import { PaymentOrderHistory } from "./components/PaymentOrderHistory";
import { PaymentOrderInfo } from "./components/PaymentOrderInfo";

export const PaymentOrder: React.FC<IPaymentOrderProps> = ({
  paymentOrder,
}) => {
  const { contract } = paymentOrder;
  const amounts = getAmounts(paymentOrder);
  const isOnce = paymentOrder.repetitionType === EPaymentRepetitionType.Once;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = React.useState<EPaymentOrderTabs>(
    EPaymentOrderTabs.Info,
  );

  const hasBookkeeping = brokerHooks.useBrokerFeature(
    EBrokerFeature.Bookkeeping,
  );

  const startDate = paymentOrder.startedAt
    ? utils.date.format(paymentOrder.startedAt, "d MMMM yyyy")
    : "";
  const endDate = paymentOrder.endedAt
    ? utils.date.format(paymentOrder.endedAt, "d MMMM yyyy")
    : getLocalizedText("contract.indefinite").toLowerCase();

  const contractStartDate = contract?.startDate
    ? utils.date.format(contract.startDate, "d MMMM yyyy")
    : "";
  const contractEndDate = contract?.stopDate
    ? utils.date.format(contract.stopDate, "d MMMM yyyy")
    : getLocalizedText("contract.indefinite").toLowerCase();

  const metadata = (
    <div>
      <Spacer weight={ESpacerWeight.W08} />

      {paymentOrder.property && (
        <Stack.Item>
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon
                source={utils.properties.getIcon(
                  paymentOrder.property.typeId || 0,
                )}
                size="small"
              />
            </Grid.Item>
            <Grid.Item>
              <Button
                appearance="link"
                url={`/properties/${paymentOrder.property.id}`}
              >
                {formatAddress(paymentOrder.property)}
              </Button>
            </Grid.Item>
          </Grid>
        </Stack.Item>
      )}

      {amounts.vat > 0 && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Stack.Item>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source="billStack" size="small" />
              </Grid.Item>
              <Grid.Item>
                {getLocalizedText("system.amount_with_vat", {
                  amount: formatCurrency(amounts.amount),
                  vat: formatCurrency(amounts.amountVat),
                })}
              </Grid.Item>
            </Grid>
          </Stack.Item>
        </>
      )}

      <Spacer weight={ESpacerWeight.W08} />
      <Stack.Item>
        <Grid spacing="extraTight" alignItems="center">
          <Grid.Item>
            <Icon source="leaseContract" size="small" />
          </Grid.Item>
          <Grid.Item>
            {contract
              ? getLocalizedText("payment_order.contract.contract_duration", {
                  startDate: contractStartDate,
                  endDate: contractEndDate,
                })
              : getLocalizedText("payment_order.contract.no_contract")}
          </Grid.Item>
        </Grid>
      </Stack.Item>

      {paymentOrder.type === EPaymentOrderType.RentDeposit &&
        paymentOrder.wireReference && (
          <>
            <Spacer weight={ESpacerWeight.W08} />
            <Stack.Item>
              <Grid spacing="extraTight" alignItems="center">
                <Grid.Item>
                  <Icon source="billStack" size="small" />
                </Grid.Item>
                <Grid.Item>
                  {getLocalizedText("payment_order.wire_reference.info", {
                    value: paymentOrder.wireReference,
                  })}
                </Grid.Item>
              </Grid>
            </Stack.Item>
          </>
        )}

      {(paymentOrder.payoutReference || paymentOrder.payoutReferenceCustom) && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Stack.Item>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source="billStack" size="small" />
              </Grid.Item>
              <Grid.Item>
                {getLocalizedText("payment_order.reference.info", {
                  value:
                    paymentOrder.payoutReference ||
                    paymentOrder.payoutReferenceCustom ||
                    "",
                })}
              </Grid.Item>
            </Grid>
          </Stack.Item>
        </>
      )}

      {paymentOrder.repetitionType !== EPaymentRepetitionType.Once &&
        !paymentOrder.isIndexable && (
          <>
            <Spacer weight={ESpacerWeight.W08} />
            <Stack.Item>
              <Grid spacing="extraTight" alignItems="center">
                <Grid.Item>
                  <Icon source={"billStack"} size="small" />
                </Grid.Item>
                <Grid.Item>
                  {getLocalizedText("payment_order.not_indexable.info")}
                </Grid.Item>
              </Grid>
            </Stack.Item>
          </>
        )}

      {paymentOrder.contract?.rentDiscountEnabled && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Stack.Item>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source="billStack" size="small" />
              </Grid.Item>
              <Grid.Item>
                {getLocalizedText(
                  "payment.overview.payment_order.rent_discount_info_amount",
                  {
                    amount: `${formatCurrency(
                      paymentOrder.contract?.rentDiscountAmount || 0,
                    )}`,
                  },
                )}
              </Grid.Item>
            </Grid>
          </Stack.Item>

          <Spacer weight={ESpacerWeight.W08} />
          <Stack.Item>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source="billStack" size="small" />
              </Grid.Item>
              <Grid.Item>
                {getLocalizedText(
                  "payment.overview.payment_order.rent_discount_info_offset",
                  {
                    offset: `${paymentOrder.contract?.rentDiscountOffset || 0}`,
                  },
                  paymentOrder.contract?.rentDiscountOffset || 0,
                )}
              </Grid.Item>
            </Grid>
          </Stack.Item>
        </>
      )}

      <Spacer weight={ESpacerWeight.W08} />
      <Stack.Item>
        <Grid spacing="extraTight" alignItems="center">
          <Grid.Item>
            <Icon source="calendar" size="small" />
          </Grid.Item>
          <Grid.Item>
            {startDate}
            {endDate && !isOnce && ` - ${endDate}`}
          </Grid.Item>
        </Grid>
      </Stack.Item>

      {paymentOrder.repetitionType !== EPaymentRepetitionType.Once &&
        paymentOrder.nextInvitationAt &&
        paymentOrder.nextContractualPayDate && (
          <>
            <Spacer weight={ESpacerWeight.W08} />
            <Stack.Item>
              <Grid spacing="extraTight" alignItems="center">
                <Grid.Item>
                  <Icon source="calendar" size="small" />
                </Grid.Item>
                <Grid.Item>
                  {getLocalizedText(
                    "payment_order.next_date_info.contractual_date",
                    {
                      nextContractualPayDate: formatDate(
                        paymentOrder.nextContractualPayDate,
                        "d MMMM yyyy",
                      ),
                    },
                  )}
                </Grid.Item>
              </Grid>
            </Stack.Item>

            <Spacer weight={ESpacerWeight.W08} />
            <Stack.Item>
              <Grid spacing="extraTight" alignItems="center">
                <Grid.Item>
                  <Icon source="calendar" size="small" />
                </Grid.Item>
                <Grid.Item>
                  {getLocalizedText(
                    "payment_order.next_date_info.invitation_date",
                    {
                      nextInvitationAt: formatDate(
                        paymentOrder.nextInvitationAt,
                        "d MMMM yyyy",
                      ),
                    },
                  )}
                </Grid.Item>
              </Grid>
            </Stack.Item>
          </>
        )}

      {hasBookkeeping && paymentOrder.bookkeepingGeneralLedgerAccount && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Stack.Item>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source="layout" size="small" />
              </Grid.Item>
              <Grid.Item>
                {getLocalizedText(
                  "payment.payment_order.bookkeeping.general_ledger_account",
                )}
                {": "}
                {paymentOrder.bookkeepingGeneralLedgerAccount}
              </Grid.Item>
            </Grid>
          </Stack.Item>
        </>
      )}
    </div>
  );

  const handleInfoTabClick = () => {
    setTab(EPaymentOrderTabs.Info);
  };

  const handleHistoryTabClick = () => {
    setTab(EPaymentOrderTabs.History);
  };

  const handleEdit = (paymentOrder: IPaymentOrder) => () => {
    navigate(
      `/properties/${paymentOrder.propertyId}/payments/${paymentOrder.id}/edit`,
    );
  };

  const handleRemoveWithConfirm = (paymentOrderId: number) => () => {
    const handleRemove = (paymentOrderId: number) => () => {
      dispatch(
        paymentActions.deletePaymentOrder.actions.start({ paymentOrderId }),
      );
    };

    confirm({
      title: getLocalizedText("system.delete.confirm", {
        typePrefix: getLocalizedText("system.model.payment_order.prefix"),
        type: getLocalizedText("system.model.payment_order").toLowerCase(),
      }),
      primaryActions: [
        {
          title: getLocalizedText("system.remove"),
          onPress: handleRemove(paymentOrderId),
        },
      ],
    });
  };

  let tabs = [
    {
      name: "dashboard",
      permission: "propertyDashboard:visit",
      content: getLocalizedText("payment_order.detail.tab.info"),
      onClick: handleInfoTabClick,
      isActive: tab === EPaymentOrderTabs.Info,
    },
    {
      name: "payments",
      permission: "propertyPayments:visit",
      content: getLocalizedText("payment_order.detail.tab.history"),
      onClick: handleHistoryTabClick,
      isActive: tab === EPaymentOrderTabs.History,
    },
  ];

  const dropdown = [];

  if (
    !paymentOrder.completedAt &&
    paymentOrder.isEditable &&
    paymentOrder.roles.includes(EPaymentOrderRole.Write) &&
    !paymentOrder.deletedAt
  ) {
    dropdown.push({
      content: getLocalizedText("payments.overview.payment_order.edit.action"),
      onClick: handleEdit(paymentOrder),
    });

    dropdown.push({
      content: (
        <TextStyle variation={"negative"}>
          {getLocalizedText("system.remove")}
        </TextStyle>
      ),
      onClick: handleRemoveWithConfirm(paymentOrder.id),
    });
  }

  const renderInfo = () => (
    <PaymentOrderInfo paymentOrder={paymentOrder} setTab={setTab} />
  );
  const renderHistory = () => (
    <PaymentOrderHistory paymentOrder={paymentOrder} setTab={setTab} />
  );

  return (
    <Page
      title={`${getTitle(paymentOrder)} ${formatCurrency(amounts.amount)}`}
      metadata={metadata}
      tabs={tabs}
      dropdown={dropdown.length > 0 ? (dropdown as IAction[]) : undefined}
      setDocumentTitle={false}
    >
      {tab === EPaymentOrderTabs.Info && renderInfo()}
      {tab === EPaymentOrderTabs.History && renderHistory()}
    </Page>
  );
};
