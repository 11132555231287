import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as tasksHooks from "@rentiohq/shared-frontend/dist/reduxV2/task/task.hooks";
import { ETaskCustomFilter } from "@rentiohq/shared-frontend/dist/reduxV2/task/task.utils";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  ETaskCategory,
  ETaskMemberType,
  ETaskStatus,
  ETaskType,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EFilterType,
  ESpacings,
  Filters,
  IDateRange,
  Stages,
} from "@rentiohq/web-shared/dist/components";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { compact } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import usePreference from "scenes/Settings/hooks/usePreference";
import { createEnumParam } from "serialize-query-params";
import {
  EInitialType,
  ETaskStatusFilter,
  EThirdPartyFilter,
  ETimeFilter,
  IResultingFilter,
  getTimeFilter,
} from "./TasksFilter.utils";
export interface IProps {
  searchQuery?: string;
  showCounts?: boolean;
  onSearchChange: (searchTerm: string | undefined) => void;
  onFilterChange: (
    filter: IResultingFilter | undefined,
    isFirstTime?: boolean,
  ) => void;
  employeeAccounts?: IAccount[];
  companyName?: string;
}

export const TasksFilter = (props: IProps) => {
  const {
    searchQuery,
    onSearchChange,
    onFilterChange,
    showCounts = true,
    employeeAccounts,
    companyName,
  } = props;

  const isFirstFilterChange = useRef(true);
  // State
  const [statusFilter = ETaskStatusFilter.Open, setStatusFilter] =
    usePreference<ETaskStatusFilter | undefined>({
      preferenceKey: "task_filter_status",
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });
  const [customFilter = ETaskCustomFilter.NotArchived, setCustomFilter] =
    usePreference<ETaskCustomFilter | undefined>({
      preferenceKey: "task_custom_filter",
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });
  const [thirdPartyFilter = EThirdPartyFilter.Both, setThirdPartyFilter] =
    usePreference<EThirdPartyFilter | undefined>({
      preferenceKey: "task_filter_party",
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });
  const [keypointStatusFilter, setKeypointStatusFilter] = usePreference<
    ETaskStatus[]
  >({
    preferenceKey: "task_filter_keypoint_status",
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [
    rolesFilter = [ETaskMemberType.Executor, ETaskMemberType.Follower],
    setRolesFilter,
  ] = usePreference<ETaskMemberType[]>({
    preferenceKey: "task_filter_roles",
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [timeFilter = ETimeFilter.All, setTimeFilter] = usePreference<
    ETimeFilter | undefined
  >({
    preferenceKey: "task_filter_time",
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [typeFilter, setTypeFilter] = usePreference<ETaskType[]>({
    preferenceKey: "task_filter_type",
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });
  const [categoryFilter, setCategoryFilter] = usePreference<ETaskCategory[]>({
    preferenceKey: "task_filter_category",
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [employeesFilter = [], setEmployeesFilter] = usePreference<number[]>({
    preferenceKey: "task_filter_employees",
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  // Calculated filters
  const finalStatusFilter = useMemo(() => {
    if (statusFilter === ETaskStatusFilter.Open) {
      return {
        finishedAt: { is: null },
      };
    }

    if (statusFilter === ETaskStatusFilter.Finished) {
      return {
        finishedAt: { isNot: null },
      };
    }
  }, [statusFilter]);

  const finalCustomFilter = useMemo(() => {
    if (customFilter === ETaskCustomFilter.NotArchived) {
      return ETaskCustomFilter.NotArchived;
    }
    if (customFilter === ETaskCustomFilter.Archived) {
      return ETaskCustomFilter.Archived;
    }
  }, [customFilter]);

  const finalThirdPartyFilter = useMemo(() => {
    if (thirdPartyFilter === EThirdPartyFilter.Keypoint) {
      return { status: { isNot: null } };
    }

    if (thirdPartyFilter === EThirdPartyFilter.Rentio) {
      return { status: { is: null } };
    }
  }, [thirdPartyFilter]);

  const finalKeypointStatusFilter = useMemo(() => {
    if (
      thirdPartyFilter !== EThirdPartyFilter.Rentio &&
      keypointStatusFilter &&
      keypointStatusFilter.length > 0
    ) {
      return { status: { in: keypointStatusFilter } };
    }
  }, [thirdPartyFilter, keypointStatusFilter]);

  const finalRolesFilter = useMemo(() => {
    if (rolesFilter.length === 1) {
      return {
        role: rolesFilter,
      };
    }
  }, [rolesFilter]);

  const finalEmployeesFilter = useMemo(() => {
    if (employeesFilter && employeesFilter.length > 0) {
      return {
        employeeAccountIds: employeesFilter,
        unassignedToEmployee: employeesFilter.includes(-1),
      };
    }
  }, [employeesFilter]);

  const finalTypeFilter = useMemo(() => {
    if (typeFilter && typeFilter.length > 0) {
      return {
        type: { in: typeFilter },
      };
    }
  }, [typeFilter]);

  const finalCategoryFilter = useMemo(() => {
    if (categoryFilter && categoryFilter.length > 0) {
      return {
        category: { in: categoryFilter },
      };
    }
  }, [categoryFilter]);

  // Hooks
  const hasKeypointRepair = brokerHooks.useBrokerFeature(
    EBrokerFeature.KeypointRepair,
  );

  const [queryParams, setQueryParams] = useQueryParams({
    initialType: createEnumParam(Object.values(EInitialType)),
  });

  useEffect(() => {
    if (queryParams?.initialType) {
      // Reset all filters
      setStatusFilter(undefined);
      setCustomFilter(undefined);
      setThirdPartyFilter(undefined);
      setKeypointStatusFilter([]);
      setRolesFilter([]);
      setTimeFilter(undefined);
      setTypeFilter([]);
      setCategoryFilter([]);
      setEmployeesFilter([]);

      // Set correct filter for initial type
      switch (queryParams.initialType) {
        case EInitialType.ToExecute:
          setRolesFilter([ETaskMemberType.Executor]);
          break;

        case EInitialType.Keypoint:
          setThirdPartyFilter(EThirdPartyFilter.Keypoint);
          break;

        case EInitialType.FromDashboard:
          setStatusFilter(ETaskStatusFilter.Open);
          setCustomFilter(ETaskCustomFilter.NotArchived);
          setRolesFilter([ETaskMemberType.Executor]);
          setTypeFilter([ETaskType.Miscellaneous]);
          setCategoryFilter([ETaskCategory.Other]);
          break;

        default:
          break;
      }

      setQueryParams(
        {
          initialType: undefined,
        },
        { replace: true },
      );
    }
  }, [queryParams.initialType]);

  useEffect(() => {
    const finalFilter: {
      filter: Record<string, any>;
      rolesFilter?: Array<any>;
      employeeAccountIds?: Array<number>;
      unassignedToEmployee?: boolean;
      customFilters?: Array<string>;
    } = {
      filter: {
        and: [],
      },
    };

    if (finalStatusFilter) {
      finalFilter.filter.and.push(finalStatusFilter);
    }
    if (finalCustomFilter) {
      finalFilter.customFilters = [finalCustomFilter];
    }
    if (finalThirdPartyFilter) {
      finalFilter.filter.and.push(finalThirdPartyFilter);
    }
    if (finalKeypointStatusFilter) {
      finalFilter.filter.and.push(finalKeypointStatusFilter);
    }
    if (finalRolesFilter) {
      finalFilter.rolesFilter = finalRolesFilter?.role;
    }
    if (timeFilter !== ETimeFilter.All) {
      finalFilter.filter.and.push(getTimeFilter(timeFilter));
    }
    if (finalTypeFilter) {
      finalFilter.filter.and.push(finalTypeFilter);
    }
    if (finalCategoryFilter) {
      finalFilter.filter.and.push(finalCategoryFilter);
    }
    if (finalEmployeesFilter && finalEmployeesFilter) {
      finalFilter.employeeAccountIds = finalEmployeesFilter.employeeAccountIds;
      finalFilter.unassignedToEmployee =
        finalEmployeesFilter.unassignedToEmployee;
    }

    onFilterChange(finalFilter, isFirstFilterChange.current);
    isFirstFilterChange.current = false;
  }, [
    finalRolesFilter,
    timeFilter,
    keypointStatusFilter,
    thirdPartyFilter,
    finalStatusFilter,
    finalCustomFilter,
    finalCategoryFilter,
    finalTypeFilter,
    finalEmployeesFilter,
  ]);

  const getCount = (params: {
    filter?: Record<string, any>;
    customFilter?: ETaskCustomFilter[];
  }) => {
    if (!showCounts) {
      return { count: 0 };
    }

    const { filter, customFilter = [ETaskCustomFilter.NotArchived] } = params;

    const customFilters = customFilter || undefined;

    return tasksHooks.useCount({
      query: {
        filter,
        customFilters,
      },
    });
  };

  const { count: beforeTodayCount } = getCount({
    filter: {
      and: [
        getTimeFilter(ETimeFilter.BeforeToday),
        { finishedAt: { is: null } },
      ],
    },
  });

  const { count: todayCount } = getCount({
    filter: {
      and: [
        getTimeFilter(ETimeFilter.Today),
        {
          finishedAt: { is: null },
        },
      ],
    },
  });

  const { count: thisWeekCount } = getCount({
    filter: {
      and: [
        getTimeFilter(ETimeFilter.ThisWeek),
        {
          finishedAt: { is: null },
        },
      ],
    },
  });

  const { count: nextWeekCount } = getCount({
    filter: {
      and: [
        getTimeFilter(ETimeFilter.NextWeek),
        {
          finishedAt: { is: null },
        },
      ],
    },
  });

  const { count: all } = getCount({
    filter: {
      finishedAt: { is: null },
    },
  });

  return (
    <>
      {showCounts && (
        <>
          <Stages
            stages={[
              {
                heading: getLocalizedText("tasks.stage.before_today"),
                icon: "checklist",
                count: beforeTodayCount ?? 0,
                onClick: () => {
                  setStatusFilter(ETaskStatusFilter.Open);
                  setTimeFilter(ETimeFilter.BeforeToday);
                },
              },
              {
                heading: getLocalizedText("tasks.stage.today"),
                icon: "checklist",
                count: todayCount ?? 0,
                onClick: () => {
                  setStatusFilter(ETaskStatusFilter.Open);
                  setTimeFilter(ETimeFilter.Today);
                },
              },
              {
                heading: getLocalizedText("tasks.stage.this_week"),
                icon: "checklist",
                count: thisWeekCount ?? 0,
                onClick: () => {
                  setStatusFilter(ETaskStatusFilter.Open);
                  setTimeFilter(ETimeFilter.ThisWeek);
                },
              },
              {
                heading: getLocalizedText("tasks.stage.next_week"),
                icon: "checklist",
                count: nextWeekCount ?? 0,
                onClick: () => {
                  setStatusFilter(ETaskStatusFilter.Open);
                  setTimeFilter(ETimeFilter.NextWeek);
                },
              },
              {
                heading: getLocalizedText("tasks.stage.all"),
                icon: "checklist",
                count: all ?? 0,
                onClick: () => {
                  setStatusFilter(ETaskStatusFilter.Open);
                  setTimeFilter(ETimeFilter.All);
                },
              },
            ]}
          />
          <Spacer weight={ESpacerWeight.W16} />
        </>
      )}

      <Box mb={ESpacings.loose}>
        <Filters
          queryValue={searchQuery || ""}
          queryPlaceholder={getLocalizedText(
            "follow_up.registrations.filter.query.placeholder",
          )}
          onQueryChange={onSearchChange}
          onQueryClear={() => onSearchChange(undefined)}
          onClearAll={() => {
            onSearchChange(undefined);
          }}
          filterConfigs={compact([
            {
              label: "employee-filter",
              groupKey: "employees",
              asDropdown: false,
              filters: [
                {
                  type: EFilterType.EmployeeSelect,
                  options: employeeAccounts,
                  values: employeesFilter,
                  extra: {
                    companyName,
                  },
                  filterKey: "employees",
                  onChange: (selectedEmployeeId: number) => {
                    if (employeesFilter.includes(selectedEmployeeId)) {
                      const copy = [...employeesFilter];
                      copy.splice(copy.indexOf(selectedEmployeeId), 1);
                      setEmployeesFilter(copy);
                    } else {
                      setEmployeesFilter([
                        ...employeesFilter,
                        selectedEmployeeId,
                      ]);
                    }
                  },
                  onRemove: (removedEmployeeId: string) => {
                    const id = Number(removedEmployeeId);
                    const copy = [...employeesFilter];
                    copy.splice(copy.indexOf(id), 1);
                    setEmployeesFilter(copy);
                  },
                  translate: (id: string | IDateRange) => {
                    const _id = Number(id);
                    if (isNaN(_id)) return "";
                    if (_id === -1)
                      return getLocalizedText("system.unassigned");
                    const employee = (employeeAccounts || []).find(
                      m => m.id === _id,
                    );
                    if (employee) return getName(employee);
                    return "";
                  },
                },
              ],
            },
            {
              label: getLocalizedText("system.status"),
              groupKey: "finished_third_party_repair_status",
              filters: compact([
                {
                  type: EFilterType.SingleSelect,
                  label: getLocalizedText("task.filter.finished"),
                  options: Object.values(ETaskStatusFilter),
                  values: [statusFilter],
                  filterKey: "finished",
                  onChange: (values: ETaskStatusFilter[]) => {
                    setStatusFilter(values[0]);
                  },
                  onRemove: () => {
                    setStatusFilter(undefined);
                  },
                  translate: (value: string | IDateRange) =>
                    getLocalizedText(`task.filter.finished.${value as string}`),
                },
                {
                  type: EFilterType.SingleSelect,
                  label: getLocalizedText("task.filter.archived"),
                  options: Object.values(ETaskCustomFilter),
                  values: [customFilter],
                  filterKey: "archived",
                  onChange: (values: ETaskCustomFilter[]) => {
                    setCustomFilter(values[0]);
                  },
                  onRemove: () => {
                    setCustomFilter(undefined);
                  },
                  translate: (value: string | IDateRange) =>
                    getLocalizedText(
                      `task.filter.archived.${(value as string).toLowerCase()}`,
                    ),
                },
                hasKeypointRepair
                  ? {
                      type: EFilterType.SingleSelect,
                      label: getLocalizedText("task.filter.third_party"),
                      options: Object.values(EThirdPartyFilter),
                      values: [thirdPartyFilter],
                      filterKey: "third_party",
                      onChange: (values: EThirdPartyFilter[]) => {
                        setThirdPartyFilter(values[0]);
                      },
                      onRemove: () => {
                        setThirdPartyFilter(undefined);
                      },
                      translate: (value: string | IDateRange) =>
                        getLocalizedText(
                          `task.filter.third_party.${value as string}`,
                        ),
                      showInAppliedFilters: values =>
                        values?.[0] !== EThirdPartyFilter.Both,
                    }
                  : undefined,
                hasKeypointRepair &&
                thirdPartyFilter !== EThirdPartyFilter.Rentio
                  ? {
                      type: EFilterType.MultiSelect,
                      values: keypointStatusFilter ?? [],
                      options: Object.values(ETaskStatus),
                      label: getLocalizedText("task.filter.repair_status"),
                      filterKey: "repair_status",
                      onChange: (values: ETaskStatus[]) => {
                        setKeypointStatusFilter(values);
                      },
                      onRemove: (key: string) => {
                        if (keypointStatusFilter) {
                          const found = keypointStatusFilter.find(f => {
                            return f ? f === key : undefined;
                          });
                          if (found) {
                            keypointStatusFilter.splice(
                              keypointStatusFilter.indexOf(found),
                              1,
                            );
                            setKeypointStatusFilter([...keypointStatusFilter]);
                          }
                        }
                      },
                      translate: (values: string | IDateRange) =>
                        getLocalizedText(
                          `task.repair.keypoint.status.${values as string}`,
                        ),
                      appliedFiltersPrefix: `${getLocalizedText(
                        "task.filter.repair_status",
                      )}: `,
                      showAsOneAppliedFilterBlock: true,
                    }
                  : undefined,
              ]),
            },

            {
              label: getLocalizedText("task.filter.role"),
              groupKey: "roles",
              filters: [
                {
                  type: EFilterType.MultiSelect,
                  options: [ETaskMemberType.Executor, ETaskMemberType.Follower],
                  values: rolesFilter,
                  filterKey: "roles",
                  onChange: keys => {
                    setRolesFilter(keys);
                  },
                  onRemove: (key: string) => {
                    setRolesFilter(rolesFilter.filter(f => f !== key));
                  },
                  translate: value =>
                    getLocalizedText(
                      `role.${value as string}`.toLowerCase(),
                      undefined,
                      1,
                    ),
                },
              ],
            },
            {
              label: getLocalizedText("task.execute_date.meta"),
              groupKey: "time",
              filters: [
                {
                  type: EFilterType.SingleSelect,
                  options: Object.values(ETimeFilter),
                  values: [timeFilter],
                  filterKey: "time",
                  onChange: (values: ETimeFilter[]) => {
                    setTimeFilter(values[0]);
                  },
                  onRemove: () => {
                    setTimeFilter(undefined);
                  },
                  translate: value =>
                    getLocalizedText(`tasks.stage.${value as string}`),
                  showInAppliedFilters: values =>
                    values?.[0] !== ETimeFilter.All,
                },
              ],
            },
            {
              label: `${getLocalizedText(
                "task.filter.types",
              )} / ${getLocalizedText("task.filter.categories")}`,
              groupKey: "type_category",
              filters: [
                {
                  type: EFilterType.MultiSelect,
                  label: getLocalizedText("task.filter.types"),
                  options: Object.values(ETaskType),
                  values: typeFilter ?? [],
                  filterKey: "type",
                  onChange: (values: any[]) => {
                    setTypeFilter(values);
                  },
                  onRemove: (key: string) => {
                    if (typeFilter) {
                      const found = typeFilter.find(f => {
                        return f ? f === key : undefined;
                      });
                      if (found) {
                        typeFilter.splice(typeFilter.indexOf(found), 1);
                        setTypeFilter([...typeFilter]);
                      }
                    }
                  },
                  translate: (value: string | IDateRange) =>
                    getLocalizedText(
                      `task.type.${(value as string).toLowerCase()}`,
                    ),
                },
                {
                  type: EFilterType.MultiSelect,
                  label: getLocalizedText("task.filter.categories"),
                  options: Object.values(ETaskCategory),
                  values: categoryFilter ?? [],
                  filterKey: "category",
                  onChange: (values: any[]) => {
                    setCategoryFilter(values);
                  },
                  onRemove: (key: string) => {
                    if (categoryFilter) {
                      const found = categoryFilter.find(f => {
                        return f ? f === key : undefined;
                      });
                      if (found) {
                        categoryFilter.splice(categoryFilter.indexOf(found), 1);
                        setCategoryFilter([...categoryFilter]);
                      }
                    }
                  },
                  translate: (value: string | IDateRange) =>
                    getLocalizedText(
                      `task.category.${(value as string).toLowerCase()}`,
                    ),
                },
              ],
            },
          ])}
        />
      </Box>
    </>
  );
};
