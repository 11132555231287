import * as contractActions from "@rentiohq/shared-frontend/dist/redux/contract/contract.actions";
import * as moveRequestSelectors from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.selectors";
import {
  EMoveServiceManager,
  EMoveServiceService,
} from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.types";
import * as contractSelectorsV2 from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.selectors";
import {
  EElectricityType,
  ENaturalGasAvailable,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { IRootStore } from "redux/reducers";
import { EAN_PREFIX } from "./schema.requestMoveService.config";
import { EField, IExtraData } from "./schema.requestMoveService.types";

export const performingSubmitActionResultSelector =
  (contractId: string) => (state: IRootStore) => {
    const contract = contractSelectorsV2.getDetail(state, contractId);

    if (!contract?.moveRequestId) {
      return;
    }

    return moveRequestSelectors.moveRequestById(state, contract.moveRequestId);
  };

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { property, contract } = extraData;

    const updatedProperty: Partial<IProperty> = {};

    const manager = formData[EField.Manager] as EMoveServiceManager;
    const services = formData[EField.Services] as EMoveServiceService[];
    const isCorporateAddress = formData[EField.IsCorporateAddress] === "yes";

    // Energy (Electricity & natural gas)
    if (
      services.includes(EMoveServiceService.Energy) &&
      manager !== EMoveServiceManager.TenantAndBroker
    ) {
      // Electricity
      const electricityType = formData[
        EField.ElectricityType
      ] as EElectricityType;

      updatedProperty.electricityType = electricityType;

      let parsedElectricityEan = null;
      if (formData[EField.ElectricityEan]) {
        parsedElectricityEan = `${EAN_PREFIX}${
          formData[EField.ElectricityEan]
        }`;
      }

      switch (electricityType) {
        case EElectricityType.Single:
          // @ts-ignore
          updatedProperty.electricityEan = parsedElectricityEan;

          updatedProperty.electricityMeterNumberBoth =
            formData[EField.ElectricityMeterNumberBoth];

          updatedProperty.electricityMeterType =
            formData[EField.ElectricityMeterType];

          break;

        case EElectricityType.Double:
          // @ts-ignore
          updatedProperty.electricityEan = parsedElectricityEan;

          updatedProperty.electricityMeterNumberBoth =
            formData[EField.ElectricityMeterNumberBoth];

          updatedProperty.electricityMeterType =
            formData[EField.ElectricityMeterType];

          break;

        case EElectricityType.Unknown:
        default:
          break;
      }

      // Natural gas
      const naturalGasAvailable = formData[
        EField.NaturalGasAvailable
      ] as ENaturalGasAvailable;

      updatedProperty.naturalGasAvailable = naturalGasAvailable;

      switch (formData[EField.NaturalGasAvailable]) {
        case ENaturalGasAvailable.Available:
          let parsedNaturalGasEan = null;
          if (formData[EField.NaturalGasEan]) {
            parsedNaturalGasEan = `${EAN_PREFIX}${
              formData[EField.NaturalGasEan]
            }`;
          }
          // @ts-ignore
          updatedProperty.naturalGasEan = parsedNaturalGasEan;
          updatedProperty.naturalGasMeterNumber =
            formData[EField.NaturalGasMeterNumber];

          updatedProperty.naturalGasMeterType =
            formData[EField.NaturalGasMeterType];

          break;

        case ENaturalGasAvailable.Unavailable:
        case ENaturalGasAvailable.Unknown:
        default:
          break;
      }
    }

    return contractActions.requestMoveService.actions.start({
      contractId: contract.id,
      propertyId: property.id,
      updatedProperty,
      requestMoveService: {
        contractId: contract.id,
        manager,
        services,
        isCorporateAddress,
      },
    });
  };
