import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { subMonths } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { EField, IExtraData } from "./schema.exportRevenues.types";

const NOW = new Date();

export const getInitialValues = (extraData: IExtraData): IValues => {
  return {
    [EField.From]: subMonths(NOW, 1),
    [EField.To]: NOW,
  };
};
