import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer/Spacer";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as legalCaseHooks from "@rentiohq/shared-frontend/dist/reduxV2/legalCase/legalCase.hooks";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { ELegalCaseStatus } from "@rentiohq/shared-frontend/dist/types/legalCase.types";
import { EPropertyMemberTypes } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  DataTable,
  EmptyState,
  mapItemsToDataTableProps,
  Page,
  Pagination,
} from "@rentiohq/web-shared/dist/components";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NumberParam, StringParam } from "serialize-query-params";
import { RentioLegalFilter } from "./RentioLegal.filter";
import { getDataTableItems } from "./RentioLegal.util";
import { ConfirmLegalActivationModal } from "./components/ConfirmLegalActivationModal";

const FETCH_LIMIT = 15;
const NOW = new Date();

export const RentioLegal = () => {
  const [queryParams, setQueryParams] = useQueryParams({
    page: NumberParam,
    legalCaseId: StringParam,
  });
  const [finalFilter, setFinalFilter] = useState<any>({});
  const [contractToEnableRentioLegalFor, setContractToEnableRentioLegalFor] =
    useState<IContract>();

  const [searchQuery, setSearchQuery] = useState<string>();
  const [queryDebounced] = useDebounce(searchQuery);

  const navigate = useNavigate();

  const { page } = queryParams;

  const {
    items: contracts,
    isFetching: isFetchingContracts,
    totalPages = 0,
    refetch: refetchContracts,
  } = contractHooks.usePaged({
    query: {
      limit: FETCH_LIMIT,
      page: queryParams.page ?? 1,
      filter: {
        stopDate: { gte: NOW },
        ...finalFilter,
      },
      sort: [
        {
          field: "startDate",
          method: "DESC",
          // If ever nested sorting is enabled, use this
          /*  field: {
            propertyAddress: { field: "simple", method: "DESC" },
          },
          method: "DESC", */
        },
      ],
      propertyRolesFilter: [EPropertyMemberTypes.FinancialManager],
      search: queryDebounced,
      customFilters: ["PROPERTY_TYPE_RESIDENTIAL"],
    },
  });

  const { items: openCases, refetch: refetchOpenCases } =
    legalCaseHooks.useGetAll({
      shouldRefetch: true,
      query: {
        filter: {
          status: { eq: ELegalCaseStatus.Open },
        },
      },
    });
  const { update } = legalCaseHooks.useUpdate({});

  const dataTableItems = getDataTableItems({
    openCases,
  });
  const dataTableProps = mapItemsToDataTableProps(dataTableItems, {
    hasActions: true,
  });

  const getRows = () => {
    if (!contracts) {
      return [];
    }

    return contracts.map(contract => {
      const openCase = openCases?.find(x => x.contractId === contract.id);

      let actions = [];

      if (openCase) {
        actions.push({
          content: getLocalizedText(
            "rentio_legal.overview.actions.go_to_detail",
          ),
          onClick: () => {
            setQueryParams({
              legalCaseId: openCase.id,
            });
          },
        });

        actions.push({
          content: getLocalizedText("rentio_legal.overview.actions.close_case"),
          onClick: () => {
            update(
              {
                data: { status: ELegalCaseStatus.Closed },
                onSuccess: () => {
                  refetchOpenCases();
                  refetchContracts();
                },
              },
              openCase.id,
            );
          },
        });
      } else if (contract.legalLicenseActivatedAt) {
        actions.push({
          content: getLocalizedText(
            "rentio_legal.overview.actions.start_new_case",
          ),
          onClick: () => {
            navigate(`/rentio-legal/add?contractId=${contract.id}`);
          },
        });
      } else {
        actions.push({
          content: getLocalizedText("rentio_legal.overview.actions.activate"),
          onClick: () => {
            setContractToEnableRentioLegalFor(contract);
          },
        });
      }

      actions.push({
        content: getLocalizedText(
          "rentio_legal.overview.actions.go_to_property",
        ),
        onClick: () => {
          navigate(`/properties/${contract.propertyId}`);
        },
      });

      return {
        id: contract.id,
        content: dataTableItems.map(item => item.renderContent(contract)),
        actions,
      };
    });
  };

  const handleRowClick = (rowIndex: number) => {
    const row = getRows()[rowIndex];
    if (!row) {
      return;
    }

    const contractId = row.id;
    const openCase = openCases?.find(x => x.contractId === contractId);

    setQueryParams({
      legalCaseId: openCase?.id,
    });
  };

  const renderPagination = () => {
    if (totalPages <= 1) {
      return null;
    }

    return (
      <Pagination
        forcePage={page ? page - 1 : 0}
        pageCount={totalPages}
        onPageChange={({ selected }) => setQueryParams({ page: selected + 1 })}
      />
    );
  };

  return (
    <Page
      title={getLocalizedText("rentio_legal.overview.heading")}
      actions={[]}
      fullWidth={true}
    >
      <Card>
        <RentioLegalFilter
          searchQuery={searchQuery}
          onSearchChange={searchQuery => {
            setSearchQuery(searchQuery);
          }}
          onFilterChange={filter => {
            setFinalFilter(filter);
          }}
        />

        <Spacer weight={ESpacerWeight.W16} />

        <DataTable
          isLoadingData={isFetchingContracts}
          emptyState={
            <EmptyState
              backgroundColor="gray"
              heading={getLocalizedText("rentio_legal.overview.empty_state")}
            />
          }
          verticalAlign="middle"
          {...dataTableProps}
          rows={getRows()}
          onRowClick={handleRowClick}
        />
      </Card>

      {renderPagination()}

      {contractToEnableRentioLegalFor && (
        <ConfirmLegalActivationModal
          contract={contractToEnableRentioLegalFor}
          onSuccess={() => setContractToEnableRentioLegalFor(undefined)}
          onClose={() => setContractToEnableRentioLegalFor(undefined)}
        />
      )}
    </Page>
  );
};
