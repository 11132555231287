import { getAmountWithVat } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import groupBy from "lodash/groupBy";
export var getGroupId = function (invoiceItem) {
    return "".concat(invoiceItem.resourceType, "-").concat(invoiceItem.price, "-").concat(invoiceItem.isPrepaid);
};
export var getInvoiceItemsDataSource = function (params) {
    var unsortedInvoiceItems = params.invoiceItems, selectedKey = params.selectedKey, expandAllGroups = params.expandAllGroups;
    var invoiceItems = unsortedInvoiceItems
        ? unsortedInvoiceItems.sort(function (_a, _b) {
            var a = _a.propertyId;
            var b = _b.propertyId;
            return !a || !b ? 0 : a - b;
        })
        : undefined;
    var result = [];
    var groupedItems = groupBy(invoiceItems, getGroupId);
    Object.values(groupedItems).forEach(function (groupedInvoiceItems) {
        var isGrouped = groupedInvoiceItems.length > 1;
        var firstItem = groupedInvoiceItems[0];
        var isActiveGroup = expandAllGroups || getGroupId(firstItem) === selectedKey;
        // Add header for active group
        if (isGrouped) {
            var totalPrice = groupedInvoiceItems.reduce(function (temp, x) { return temp + (x.price || 0); }, 0);
            var header = {
                id: "".concat(firstItem.resourceType, "-header"),
                resourceType: firstItem.resourceType,
                isPrepaid: firstItem.isPrepaid,
                totalAmount: groupedInvoiceItems.reduce(function (temp, x) { return temp + (x.amount || 1); }, 0),
                price: firstItem.price,
                totalPrice: totalPrice,
                totalPriceIncludingVat: getAmountWithVat(totalPrice, firstItem.VATPercentage),
                VATPercentage: firstItem.VATPercentage,
            };
            result.push({ header: header });
        }
        if (isGrouped && isActiveGroup) {
            result.push.apply(result, groupedInvoiceItems.map(function (x) { return ({ item: x }); }));
        }
        if (!isGrouped) {
            result.push.apply(result, groupedInvoiceItems.map(function (x) { return ({ item: x }); }));
        }
    });
    return result;
};
