import * as actions from "./paymentGroup.actions";
import * as api from "./paymentGroup.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY, } from "./paymentGroup.constants";
import * as hooks from "./paymentGroup.hooks";
import * as promises from "./paymentGroup.promises";
import reducer from "./paymentGroup.reducer";
import { saga } from "./paymentGroup.saga";
import * as selectors from "./paymentGroup.selectors";
export var REDUCER_KEY_PAYMENT_GROUPS = REDUCER_KEY;
export var paymentGroupModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: reducer,
    saga: saga,
    selectors: selectors,
    utils: {},
};
