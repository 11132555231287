import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
// Context
export var ExitPromptContext = React.createContext({
    exitPromptEnabled: false,
    setExitPromptEnabled: function (newValue) { },
});
// Provider
var ExitPrompt = function (props) {
    var children = props.children;
    // State
    var _a = React.useState(true), exitPromptEnabled = _a[0], setExitPromptEnabled = _a[1];
    // Render
    return (_jsx(ExitPromptContext.Provider, { value: {
            exitPromptEnabled: exitPromptEnabled,
            setExitPromptEnabled: setExitPromptEnabled,
        }, children: children }));
};
// eslint-disable-next-line import/no-default-export
export default ExitPrompt;
