import { Platform } from "react-native";
import { ERentioFormField } from "../../components/components/Form";
import { EField, } from "../../forms/createTask/schema.createTask.types";
import { REMINDER_OPTIONS } from "../../forms/createTask/schema.createTask.utils";
import { getValue } from "../../redux/form/form.utils";
import { ERecurrenceType, ETaskReminder } from "../../types/task.types";
import { addYears, differenceInDays, isBefore, isEqual, setHours, startOfDay, subDays, subMonths, subWeeks, } from "../../utils/date-fns.utils";
import { formatDate } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
var NOW = new Date();
var prefillDateOnChange = function (formData, fieldName, fieldValue, field) {
    var chosenDate = formData.executeDate || formData.dueDate;
    var executeDate = formData.executeDate;
    var dueDate = formData.dueDate;
    var newDate = new Date();
    switch (fieldValue) {
        case !chosenDate && formData.NextReminderDate:
            return { shouldSetNewValue: true, newValue: formData.NextReminderDate };
        case ETaskReminder.DayBeforeExecuteDate:
            newDate = subDays(new Date(executeDate), 1);
            break;
        case ETaskReminder.DayBeforeDueDate:
            newDate = subDays(new Date(dueDate), 1);
            break;
        case ETaskReminder.WeekBeforeExecuteDate:
            newDate = subWeeks(new Date(executeDate), 1);
            break;
        case ETaskReminder.WeekBeforeDueDate:
            newDate = subWeeks(new Date(dueDate), 1);
            break;
        case ETaskReminder.MonthBeforeExecuteDate:
            newDate = subMonths(new Date(executeDate), 1);
            break;
        case ETaskReminder.MonthBeforeDueDate:
            newDate = subMonths(new Date(dueDate), 1);
            break;
        case ETaskReminder.Date:
            if (Platform.OS === "web") {
                return { shouldSetNewValue: true, newValue: "open-picker" };
            }
            return { shouldSetNewValue: true, newValue: undefined };
        default:
            return { shouldSetNewValue: false };
    }
    return {
        shouldSetNewValue: true,
        newValue: isBefore(newDate, NOW) ? NOW : setHours(newDate, 12),
    };
};
var isReminderOptionDisabled = function (id, executeDate, dueDate) {
    var chosenDate = executeDate || dueDate;
    if (!chosenDate && id !== ETaskReminder.Date) {
        return true;
    }
    if (id === ETaskReminder.DayBeforeExecuteDate) {
        if (!executeDate || isBefore(subDays(new Date(executeDate), 1), NOW)) {
            return true;
        }
    }
    if (id === ETaskReminder.DayBeforeDueDate) {
        if (!dueDate || isBefore(subDays(new Date(dueDate), 1), NOW)) {
            return true;
        }
    }
    if (id === ETaskReminder.WeekBeforeExecuteDate) {
        if (!executeDate || isBefore(subWeeks(new Date(executeDate), 1), NOW)) {
            return true;
        }
    }
    if (id === ETaskReminder.WeekBeforeDueDate) {
        if (!dueDate || isBefore(subWeeks(new Date(dueDate), 1), NOW)) {
            return true;
        }
    }
    if (id === ETaskReminder.MonthBeforeExecuteDate) {
        if (!executeDate || isBefore(subMonths(new Date(executeDate), 1), NOW)) {
            return true;
        }
    }
    if (id === ETaskReminder.MonthBeforeDueDate) {
        if (!dueDate || isBefore(subMonths(new Date(dueDate), 1), NOW)) {
            return true;
        }
    }
    return false;
};
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        return {
            required: [],
            properties: (_a = {},
                _a[EField.ExecuteDate] = {
                    default: getValue(EField.ExecuteDate, initialValues, defaultValues),
                },
                _a[EField.DueDate] = {
                    default: getValue(EField.DueDate, initialValues, defaultValues),
                },
                _a[EField.ReminderType] = {
                    default: getValue(EField.ReminderType, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        {
                            id: "NONE",
                            value: getLocalizedText("task.reminder_type.no"),
                        },
                        {
                            id: "HAS_DATE",
                            value: getLocalizedText("intake.profile.history.info.date.header"),
                        },
                    ],
                    handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                        if (fieldName === EField.ExecuteDate ||
                            fieldName === EField.DueDate) {
                            return { shouldSetNewValue: true, newValue: ETaskReminder.None };
                        }
                        return { shouldSetNewValue: false };
                    },
                },
                _a[EField.ReminderOption] = {
                    default: (function () {
                        return getValue(EField.ReminderOption, initialValues, defaultValues);
                    })(),
                    // @ts-ignore
                    options: REMINDER_OPTIONS.map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("reminder.type.".concat(id.toLowerCase())),
                        disabled: isReminderOptionDisabled(id, getValue(EField.ExecuteDate, initialValues, defaultValues), getValue(EField.DueDate, initialValues, defaultValues)),
                    }); }),
                    showIf: function (formData) {
                        return formData.reminderType === ETaskReminder.HasDate;
                    },
                    handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                        if (fieldName === EField.NextReminderDate) {
                            var amountOfDaysToExecuteDate = differenceInDays(startOfDay(formData.executeDate), startOfDay(new Date(fieldValue)));
                            var amountOfDaysToDueDate = differenceInDays(startOfDay(formData.dueDate), startOfDay(new Date(fieldValue)));
                            if (amountOfDaysToExecuteDate === 31 ||
                                amountOfDaysToExecuteDate === 30) {
                                return {
                                    shouldSetNewValue: true,
                                    newValue: ETaskReminder.MonthBeforeExecuteDate,
                                };
                            }
                            if (amountOfDaysToDueDate === 31 ||
                                amountOfDaysToDueDate === 30) {
                                return {
                                    shouldSetNewValue: true,
                                    newValue: ETaskReminder.MonthBeforeDueDate,
                                };
                            }
                            if (amountOfDaysToExecuteDate === 7) {
                                return {
                                    shouldSetNewValue: true,
                                    newValue: ETaskReminder.WeekBeforeExecuteDate,
                                };
                            }
                            if (amountOfDaysToDueDate === 7) {
                                return {
                                    shouldSetNewValue: true,
                                    newValue: ETaskReminder.WeekBeforeDueDate,
                                };
                            }
                            if (amountOfDaysToExecuteDate === 1) {
                                return {
                                    shouldSetNewValue: true,
                                    newValue: ETaskReminder.DayBeforeExecuteDate,
                                };
                            }
                            if (amountOfDaysToDueDate === 1) {
                                return {
                                    shouldSetNewValue: true,
                                    newValue: ETaskReminder.DayBeforeDueDate,
                                };
                            }
                            return { shouldSetNewValue: true, newValue: ETaskReminder.Date };
                        }
                        return { shouldSetNewValue: false };
                    },
                },
                _a[EField.NextReminderDate] = {
                    default: getValue(EField.NextReminderDate, initialValues, defaultValues),
                    //@ts-ignore
                    handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                        return prefillDateOnChange(formData, fieldName, fieldValue, EField.ReminderOption);
                    },
                    showIf: function (formData) {
                        // if (formData.recurrenceSelection !== ERecurrenceType.Once) {
                        //   return false;
                        // }
                        return (getValue(EField.ReminderType, initialValues, defaultValues) !==
                            ETaskReminder.None &&
                            !!formData.reminderOption &&
                            formData.reminderOption !== ETaskReminder.None);
                    },
                },
                _a),
        };
    };
};
export var uiSchema = function () {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("task.date.section_header")
        },
        _a[EField.ExecuteDate] = {
            "ui:field": ERentioFormField.Date,
            formatSummary: function (input) {
                return input ? formatDate(input) : getLocalizedText("task.date.no_selection");
            },
            getTitle: function (formData) {
                return formData.recurrenceSelection === ERecurrenceType.Once
                    ? getLocalizedText("task.execute_date")
                    : getLocalizedText("task.recurrent.execute_date");
            },
            minDate: NOW,
            maxDate: addYears(NOW, 40),
            optional: true,
        },
        _a[EField.DueDate] = {
            "ui:field": ERentioFormField.Date,
            formatSummary: function (input) {
                return input ? formatDate(input) : getLocalizedText("task.date.no_selection");
            },
            getTitle: function (formData) {
                return formData.recurrenceSelection === ERecurrenceType.Once
                    ? getLocalizedText("task.due_date")
                    : getLocalizedText("task.recurrent.due_date");
            },
            minDate: NOW,
            maxDate: addYears(NOW, 40),
            optional: true,
        },
        _a[EField.ReminderType] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("task.reminder.title"),
            formatSummary: function (formData) {
                if (formData === ETaskReminder.None) {
                    return getLocalizedText("task.reminder_type.no");
                }
                return getLocalizedText("task.reminder_type.date");
            },
        },
        _a[EField.ReminderOption] = {
            "ui:field": ERentioFormField.InlineSelect,
            getTitle: function (formData) {
                return formData.recurrenceSelection === ERecurrenceType.Once
                    ? getLocalizedText("task.reminder.select.title")
                    : getLocalizedText("task.reminder.recurrent.select.title");
            },
            shouldShowOnSummary: function () { return false; },
            customIsFetchingSelector: function () { return false; },
        },
        _a[EField.NextReminderDate] = {
            "ui:field": ERentioFormField.Date,
            formatSummary: function (input) {
                return input
                    ? formatDate(input, "dd/MM/yyyy HH:mm")
                    : getLocalizedText("task.date.no_selection");
            },
            title: getLocalizedText("task.reminder.date"),
            minDate: NOW,
            maxDate: addYears(NOW, 40),
            dateFormat: "dd/MM/yyyy HH:mm",
            showTimePicker: true,
        },
        _a;
};
export var getValidate = function () { return function (formData, errors) {
    var chosenDate = formData[EField.ExecuteDate] || formData[EField.DueDate];
    if (!chosenDate) {
        return errors;
    }
    if (!isEqual(formData[EField.DueDate], formData[EField.ExecuteDate]) &&
        isBefore(formData[EField.DueDate], formData[EField.ExecuteDate])) {
        errors[EField.DueDate].addError(getLocalizedText("task.execute_date.after.due_date.error"));
    }
    return errors;
}; };
