var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { FlatList, Platform, } from "react-native";
import ChatListItem from "../ChatListItem";
import ListItemSeparator from "../ListItemSeparator";
import * as S from "./Chats.styles";
var Chats = /** @class */ (function (_super) {
    __extends(Chats, _super);
    function Chats() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderFlatListChatListItem = function (_a) {
            var chat = _a.item;
            return _this.renderChatListItem(chat);
        };
        _this.renderSeparator = function () { return _jsx(ListItemSeparator, {}); };
        _this.renderFooter = function () { return (_jsxs(_Fragment, { children: [_jsx(ListItemSeparator, {}), _jsx(S.ListFooter, {})] })); };
        _this.renderChatListItem = function (chat) { return (_jsx(ChatListItem, { chat: chat, onPress: _this.handlePressChat, isActiveChat: _this.props.activeChatId === chat.id })); };
        // Event handlers
        _this.handlePressChat = function (chat) {
            _this.props.onPressChat(chat);
        };
        // Helpers
        _this.keyExtractor = function (chat) { return "".concat(chat.id); };
        return _this;
    }
    // Render
    Chats.prototype.render = function () {
        var _this = this;
        var _a = this.props, _b = _a.chats, chats = _b === void 0 ? [] : _b, _c = _a.flatListProps, flatListProps = _c === void 0 ? {} : _c;
        if (Platform.OS === "web") {
            return (_jsx(S.Wrap, { children: chats.map(function (chat, index) {
                    return (_jsxs(React.Fragment, { children: [index !== 0 && _jsx(ListItemSeparator, {}), _this.renderChatListItem(chat)] }, chat.id));
                }) }));
        }
        return (_jsx(FlatList, __assign({ keyExtractor: this.keyExtractor, data: chats, renderItem: this.renderFlatListChatListItem, ItemSeparatorComponent: this.renderSeparator, ListFooterComponent: this.renderFooter }, flatListProps)));
    };
    return Chats;
}(React.PureComponent));
// eslint-disable-next-line import/no-default-export
export default Chats;
