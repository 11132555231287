import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Button } from "../Button";
import { Stack } from "../Stack";
import { TextStyle } from "../TextStyle";
import { Tooltip } from "../Tooltip";
import * as S from "./ActionButton.styled";
export var ActionButton = function (props) {
    var value = props.value, title = props.title, _a = props.clearTitle, clearTitle = _a === void 0 ? getLocalizedText("system.clear_selection") : _a, onAction = props.onAction, onClear = props.onClear, limit = props.limit;
    var isLimitExceeded = limit ? limit === value : false;
    var renderActionButton = function () {
        return (_jsx(Button, { onClick: function () { return onAction === null || onAction === void 0 ? void 0 : onAction(); }, appearance: "primary", iconAfter: _jsx(S.SelectedCount, { children: _jsx("div", { children: "".concat(value) }) }), children: title }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { vertical: true, spacing: "extraTight", children: [isLimitExceeded && (_jsx(TextStyle, { size: "small", variation: "negative", children: getLocalizedText("item.selection.limit.exceeded") })), limit && limit > 0 ? (_jsx(Tooltip, { tooltipContent: _jsx(_Fragment, { children: _jsx(TextStyle, { children: getLocalizedText("action.button.tooltip.title", {
                                    limit: "".concat(limit),
                                }) }) }), children: renderActionButton() })) : (renderActionButton()), _jsx(Button, { onClick: function () { return onClear === null || onClear === void 0 ? void 0 : onClear(); }, appearance: "link", children: clearTitle })] }), _jsx(Spacer, { weight: ESpacerWeight.W16 })] }));
};
