import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
// Context
export var VariablesDataContext = React.createContext({
    variablesData: {},
    setVariablesData: function (newValue) { },
});
// Provider
var Provider = function (props) {
    var children = props.children;
    // State
    var _a = React.useState(props.variablesData || {}), variablesData = _a[0], setVariablesData = _a[1];
    // Render
    return (_jsx(VariablesDataContext.Provider, { value: {
            variablesData: variablesData,
            setVariablesData: setVariablesData,
        }, children: children }));
};
// eslint-disable-next-line import/no-default-export
export default Provider;
