import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EElectricityType,
  ENaturalGasAvailable,
  EPropertyEPCLabel,
  EPropertyMemberTypes,
  EPropertyTypeId,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { iHaveOneOfRoles } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  Card,
  Grid,
  MultiStepForm,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  submitActionCreator,
} from "forms/editPropertyExtraInfo/schema.editPropertyExtraInfo.actions";
import { EExtraInfoGroup } from "forms/editPropertyExtraInfo/schema.editPropertyExtraInfo.types";
import {
  propertyTypeCanHaveAdjoinedType,
  propertyTypeCanHaveFloors,
} from "forms/editPropertyExtraInfo/schema.editPropertyExtraInfo.utils";
import { compact } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import createEditPropertyExtraInfoForm from "../../../../forms/editPropertyExtraInfo";

interface IExtraCardItem {
  title: string;
  extraInfoGroup: EExtraInfoGroup;
  items: { title: string; value?: string | number | boolean }[];
  editable: boolean;
  clearable: boolean;
}

const formId = generateFormId();

export const Extra = () => {
  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const property = useSelector((state: IRootStore) =>
    propertyId ? propertySelectors.getDetail(state, propertyId) : undefined,
  );
  const isGroup = property?.typeId === EPropertyTypeId.Group;

  const hasBookkeepingFeature = brokerHooks.useBrokerFeature(
    EBrokerFeature.Bookkeeping,
  );

  const [extraInfoGroup, setExtraInfoGroup] = React.useState<
    EExtraInfoGroup | undefined
  >();

  // Helpers
  const getItems = () => {
    if (!property) {
      return [];
    }

    const result: IExtraCardItem[] = [];

    if (!isGroup) {
      const hasElectricity =
        property.electricityType &&
        property.electricityType !== EElectricityType.Unknown;
      result.push({
        title: getLocalizedText("property.extra.electricity.title"),
        extraInfoGroup: EExtraInfoGroup.Electricity,
        items: compact([
          {
            title: getLocalizedText("move.field.electricity_type.title"),
            value: property.electricityType
              ? getLocalizedText(
                  `move.option.electricity_type.${property.electricityType}`.toLowerCase(),
                )
              : undefined,
          },
          hasElectricity && {
            title: getLocalizedText("move.field.electricity_ean.title"),
            value: property.electricityEan,
          },
          hasElectricity && {
            title: getLocalizedText(
              "move.field.electricity_meter_number_both.title",
            ),
            value: property.electricityMeterNumberBoth,
          },
          hasElectricity && {
            title: getLocalizedText("move.field.electricity_meter_type.title"),
            value: property.electricityMeterType
              ? getLocalizedText(
                  `move.option.electricity_meter_type.${property.electricityMeterType}`.toLowerCase(),
                )
              : undefined,
          },
        ]),
        editable: iHaveOneOfRoles(property, [
          EPropertyMemberTypes.Admin,
          EPropertyMemberTypes.FinancialManager,
          EPropertyMemberTypes.TechnicalManager,
          EPropertyMemberTypes.Owner,
        ]),
        clearable: true,
      });

      const hasNaturalGas =
        property.naturalGasAvailable &&
        property.naturalGasAvailable === ENaturalGasAvailable.Available;

      result.push({
        title: getLocalizedText("property.extra.natural_gas.title"),
        extraInfoGroup: EExtraInfoGroup.NaturalGas,
        items: compact([
          {
            title: getLocalizedText("move.field.natural_gas_type.title"),
            value: property.naturalGasAvailable
              ? getLocalizedText(
                  `move.option.natural_gas_type.${property.naturalGasAvailable}`.toLowerCase(),
                )
              : undefined,
          },
          hasNaturalGas && {
            title: getLocalizedText("move.field.natural_gas_ean.title"),
            value: property.naturalGasEan,
          },
          hasNaturalGas && {
            title: getLocalizedText(
              "move.field.natural_gas_meter_number.title",
            ),
            value: property.naturalGasMeterNumber,
          },
          hasNaturalGas && {
            title: getLocalizedText("move.field.natural_gas_meter_type.title"),
            value: property.naturalGasMeterType
              ? getLocalizedText(
                  `move.option.natural_gas_meter_type.${property.naturalGasMeterType}`.toLowerCase(),
                )
              : undefined,
          },
        ]),
        editable: iHaveOneOfRoles(property, [
          EPropertyMemberTypes.Admin,
          EPropertyMemberTypes.FinancialManager,
          EPropertyMemberTypes.TechnicalManager,
          EPropertyMemberTypes.Owner,
        ]),
        clearable: true,
      });
    }

    if (!isGroup) {
      result.push({
        title: getLocalizedText("property.extra.water.title"),
        extraInfoGroup: EExtraInfoGroup.Water,
        items: [
          {
            title: getLocalizedText("move.field.water_meter_number.title"),
            value: property.waterMeterNumber,
          },
        ],
        editable: iHaveOneOfRoles(property, [
          EPropertyMemberTypes.Admin,
          EPropertyMemberTypes.FinancialManager,
          EPropertyMemberTypes.TechnicalManager,
          EPropertyMemberTypes.Owner,
        ]),
        clearable: true,
      });
    }

    if (!isGroup) {
      const hasEpcLabel =
        property.epcLabel &&
        property.epcLabel !== EPropertyEPCLabel.Unknown &&
        property.epcLabel !== EPropertyEPCLabel.NoEPCAvailable;

      result.push({
        title: getLocalizedText("property.extra.epc.title"),
        extraInfoGroup: EExtraInfoGroup.Epc,
        items: compact([
          {
            title: getLocalizedText("editor.variable.property_epc_label"),
            value: property.epcLabel
              ? getLocalizedText(`epc.label.${property.epcLabel}`.toLowerCase())
              : undefined,
          },
          hasEpcLabel && {
            title: getLocalizedText("editor.variable.property_epc_score"),
            value: property.epcValue
              ? `${property.epcValue} ${getLocalizedText(
                  "epc.energy_usage.per_year",
                )}`
              : undefined,
          },
          hasEpcLabel && {
            title: getLocalizedText("editor.variable.property_epc_end_date"),
            value: property.epcExpirationDate
              ? formatDate(property.epcExpirationDate)
              : undefined,
          },
        ]),
        editable: iHaveOneOfRoles(property, [
          EPropertyMemberTypes.Admin,
          EPropertyMemberTypes.FinancialManager,
          EPropertyMemberTypes.TechnicalManager,
          EPropertyMemberTypes.Owner,
        ]),
        clearable: true,
      });
    }

    if (!isGroup) {
      const canHaveFloors = propertyTypeCanHaveFloors({ property });
      const canHaveAdjoinedType = propertyTypeCanHaveAdjoinedType({ property });

      result.push({
        title: getLocalizedText("property.extra.other_properties.title"),
        extraInfoGroup: EExtraInfoGroup.OtherProperties,
        items: compact([
          {
            title: getLocalizedText(
              "property.extra.other_properties.field.building_surface_area",
            ),
            value: property.buildingSurfaceArea,
          },
          canHaveFloors && {
            title: getLocalizedText(
              "property.extra.other_properties.field.building_floor",
            ),
            value: property.buildingFloor,
          },
          canHaveFloors && {
            title: getLocalizedText(
              "property.extra.other_properties.field.building_layers",
            ),
            value: property.buildingFloorCount,
          },
          canHaveAdjoinedType && {
            title: getLocalizedText(
              "property.extra.other_properties.field.building_adjoined",
            ),
            value: property.buildingAdjoined
              ? getLocalizedText(
                  `property.adjoined.option.${property.buildingAdjoined}.label`.toLowerCase(),
                )
              : undefined,
          },
          {
            title: getLocalizedText(
              "property.extra.other_properties.field.room_count_bedroom",
            ),
            value: property.roomCountBedroom,
          },
          {
            title: getLocalizedText(
              "property.extra.other_properties.field.room_count_bathroom",
            ),
            value: property.roomCountBathroom,
          },
          {
            title: getLocalizedText(
              "property.extra.other_properties.field.is_furnished",
            ),
            value: property.isFurnished || false,
          },
        ]),
        editable: iHaveOneOfRoles(property, [
          EPropertyMemberTypes.Admin,
          EPropertyMemberTypes.FinancialManager,
          EPropertyMemberTypes.TechnicalManager,
          EPropertyMemberTypes.Owner,
        ]),
        clearable: true,
      });
    }

    if (hasBookkeepingFeature) {
      if (isGroup) {
        result.push({
          title: getLocalizedText("property.extra.bookkeeping.title"),
          extraInfoGroup: EExtraInfoGroup.BookkeepingGroup,
          items: [
            {
              title: getLocalizedText(
                "property.extra.bookkeeping.item.cost_center.title",
              ),
              value: property.costCenter,
            },
          ],
          editable: iHaveOneOfRoles(property, [
            EPropertyMemberTypes.Admin,
            EPropertyMemberTypes.FinancialManager,
          ]),
          clearable: true,
        });
      } else {
        result.push({
          title: getLocalizedText("property.extra.bookkeeping.title"),
          extraInfoGroup: EExtraInfoGroup.Bookkeeping,
          items: [
            {
              title: getLocalizedText(
                "property.extra.bookkeeping.item.cost_unit.title",
              ),
              value: property.costUnit,
            },
            {
              title: getLocalizedText(
                "property.extra.bookkeeping.item.bookkeeping_paid_out_start_date.title",
              ),
              value: property.bookkeepingPaidOutStartDate
                ? formatDate(property.bookkeepingPaidOutStartDate)
                : undefined,
            },
          ],
          editable: iHaveOneOfRoles(property, [
            EPropertyMemberTypes.Admin,
            EPropertyMemberTypes.FinancialManager,
          ]),
          clearable: true,
        });
      }
    }

    return result;
  };

  // Event handlers
  const handleEditSuccess = () => {
    setExtraInfoGroup(undefined);
  };

  // Render
  if (!property) {
    return null;
  }

  const renderCard = (item: IExtraCardItem) => {
    return (
      <Card
        heading={item.title}
        actions={
          item.editable
            ? [
                {
                  content: getLocalizedText("system.edit"),
                  onClick: () => {
                    setExtraInfoGroup(item.extraInfoGroup);
                  },
                },
              ]
            : undefined
        }
      >
        <ResourceList
          items={item.items}
          renderItem={item => {
            let mappedItemValue = item.value;
            if (typeof item.value === "number") {
              mappedItemValue = `${item.value}`;
            } else if (typeof item.value === "boolean") {
              if (item.value) {
                mappedItemValue = getLocalizedText("system.yes");
              } else {
                mappedItemValue = getLocalizedText("system.no");
              }
            }

            return (
              <ResourceListItem item={item}>
                <TextStyle variation="subdued" element="div">
                  {item.title}
                </TextStyle>
                <div style={{ whiteSpace: "pre-line" }}>
                  {mappedItemValue || "-"}
                </div>
              </ResourceListItem>
            );
          }}
        />
      </Card>
    );
  };

  return (
    <>
      <Grid spacing="loose">
        {getItems().map(item => (
          <Grid.Item key={item.title} width={[1, 1, 1 / 3]}>
            {renderCard(item)}
          </Grid.Item>
        ))}
      </Grid>

      {property && extraInfoGroup ? (
        <MultiStepForm
          formId={`property-info-${formId}`}
          schemas={createEditPropertyExtraInfoForm({
            property,
            extraInfoGroup,
          })}
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            property.id,
          )}
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            property.id,
          )}
          submitActionCreator={submitActionCreator({
            property,
            extraInfoGroup,
          })}
          withAside={false}
          asModal={true}
          onSuccess={handleEditSuccess}
          modalProps={{
            shouldCloseOnOverlayClick: true,
            onClose: () => {
              setExtraInfoGroup(undefined);
            },
          }}
          submitLabel={getLocalizedText("system.update")}
          isLoading={false}
        />
      ) : null}
    </>
  );
};
