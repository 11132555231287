import {
  Button,
  Card,
  DisplayText,
  Icon,
} from "@rentiohq/web-shared/dist/components";

interface IDetailArchivedCardProps {
  title: string;
  description: string;
  ctaTitle: string;
  onClick: () => void;
}

export const DetailArchivedCard = (props: IDetailArchivedCardProps) => {
  const { title, description, ctaTitle, onClick } = props;

  return (
    <Card appearance="subdued" align="center">
      <Icon source="archive" size="extraLarge" color="red"></Icon>

      <DisplayText element="h3" size="medium">
        {title}
      </DisplayText>

      <p>{description}</p>

      <Button onClick={onClick} appearance="link">
        {ctaTitle}
      </Button>
    </Card>
  );
};

// eslint-disable-next-line import/no-default-export
export default DetailArchivedCard;
