var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { compact } from "lodash";
import { getContact, } from "../../redux/contact/contact.selectors";
import { EContractCostsPriceType, EContractIbanisationStatus, EContractMemberTypes, EContractPaydate, EContractPaymentRepetitionType, ELeaseType, VAT_OPTIONS, VAT_OPTIONS_BOOKKEEPING, } from "../../types/contract.types";
import { EPropertyMemberTypes, EPropertyTypeId, } from "../../types/property.types";
import { addYears, isBefore, subDays, subYears, } from "../../utils/date-fns.utils";
import { getAllRequiredCompanyFields, getAllRequiredContactFields, } from "../../utils/forms.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField as EContactField } from "../addContact/schema.addContact.types";
import { EContractDuration, EContractStartState, EEnableIbanisation, EField, EPaymentOrderCreateOption, ETemplateDocumentContractCreateOption, } from "./schema.createContract.types";
var NOW = new Date();
var getLeaseTypeOnPropertyTypeId = function (typeId) {
    if ([2, 3].includes(typeId))
        return ELeaseType.MainResidence;
    if (typeId === 4)
        return ELeaseType.StudentHousing;
    if (typeId === 5)
        return ELeaseType.CommercialLease;
    return ELeaseType.Other;
};
export var getNavigationTitle = function (_a) {
    var contract = _a.contract;
    if (contract) {
        return getLocalizedText("update.rental.contract");
    }
    return getLocalizedText("new.rental.contract");
};
export var getStartStateLabel = function (id) {
    var key = id === EContractStartState.New ? "yes" : "no";
    return getLocalizedText("contract.is_new.".concat(key, ".option.label"));
};
export var isExistingContract = function (formData) {
    if (!formData[EField.StartDate]) {
        return false;
    }
    return isBefore(formData[EField.StartDate], subYears(new Date(), 1));
};
export var isNewContract = function (formData) { return !isExistingContract(formData); };
export var getPayDateLabel = function (id) {
    var key = id === EContractPaydate.Calendar
        ? EContractPaydate.Calendar
        : EContractPaydate.Contract;
    return getLocalizedText("contract.paydate.option.".concat(key, ".label"));
};
export var getInitialValues = function (_a) {
    var _b, _c;
    var property = _a.property, contract = _a.contract, bookkeepingEnabled = _a.bookkeepingEnabled;
    if (!contract) {
        var rentDepositAmountPrefill = void 0;
        switch (property === null || property === void 0 ? void 0 : property.typeId) {
            case EPropertyTypeId.StudentResidence:
                rentDepositAmountPrefill = 2;
                break;
            default:
                rentDepositAmountPrefill = 3;
                break;
        }
        return _b = {},
            _b[EField.LeaseType] = property.typeId
                ? getLeaseTypeOnPropertyTypeId(property.typeId)
                : ELeaseType.MainResidence,
            _b[EField.StartDate] = NOW,
            _b[EField.Owners] = property.members
                .filter(function (member) { return member.roles.includes(EPropertyMemberTypes.Owner); })
                .map(function (member) { return member.account.id; }),
            _b[EField.TacitRenewal] = "yes",
            _b[EField.IsPreviouslyRenewed] = "no",
            _b[EField.PayDate] = EContractPaydate.Calendar,
            _b[EField.PriceInitialVat] = bookkeepingEnabled
                ? VAT_OPTIONS_BOOKKEEPING[0]
                : VAT_OPTIONS[0],
            _b[EField.PriceCurrentVat] = bookkeepingEnabled
                ? VAT_OPTIONS_BOOKKEEPING[0]
                : VAT_OPTIONS[0],
            _b[EField.CommonCostsInitialVat] = bookkeepingEnabled
                ? VAT_OPTIONS_BOOKKEEPING[0]
                : VAT_OPTIONS[0],
            _b[EField.CommonCostsCurrentVat] = bookkeepingEnabled
                ? VAT_OPTIONS_BOOKKEEPING[0]
                : VAT_OPTIONS[0],
            _b[EField.NonCommonCostsInitialVat] = bookkeepingEnabled
                ? VAT_OPTIONS_BOOKKEEPING[0]
                : VAT_OPTIONS[0],
            _b[EField.NonCommonCostsCurrentVat] = bookkeepingEnabled
                ? VAT_OPTIONS_BOOKKEEPING[0]
                : VAT_OPTIONS[0],
            _b[EField.PaymentRepetition] = EContractPaymentRepetitionType.Monthly,
            _b[EField.PriceTypeCommonCostsInitial] = EContractCostsPriceType.Fixed,
            _b[EField.PriceTypeCommonCostsCurrent] = EContractCostsPriceType.Fixed,
            _b[EField.PriceTypeNonCommonCostsInitial] = EContractCostsPriceType.Fixed,
            _b[EField.PriceTypeNonCommonCostsCurrent] = EContractCostsPriceType.Fixed,
            _b[EField.HasDiscount] = "no",
            _b[EField.DiscountOffset] = "5",
            _b[EField.RentDepositAmountPrefill] = rentDepositAmountPrefill,
            _b[EField.RentDepositAmountPrefillPreview] = undefined,
            _b[EField.RentDepositAmount] = undefined,
            _b[EField.EnableIbanisation] = EEnableIbanisation.Disable,
            _b[EField.TemplateDocumentContractCreateOption] = ETemplateDocumentContractCreateOption.None,
            _b[EField.PaymentOrderCreateOption] = EPaymentOrderCreateOption.None,
            _b;
    }
    var isNewContract = !contract.startPrice;
    var indexDate = contract.startIndexYear && contract.startIndexMonth
        ? new Date(contract.startIndexYear, contract.startIndexMonth - 1, // Zero-based
        1)
        : undefined;
    var priceVat = contract.priceVat || 0;
    var costCommonVat = contract.costsCommonVat || 0;
    var costNonCommonVat = contract.costsNonCommonVat || 0;
    var priceVatMapped;
    var costsCommonVatMapped;
    var costsNonCommonVatMapped;
    if (bookkeepingEnabled) {
        priceVatMapped =
            priceVat === 0
                ? contract.priceNoVatType || VAT_OPTIONS_BOOKKEEPING[0]
                : priceVat;
        costsCommonVatMapped =
            costCommonVat === 0
                ? contract.costsCommonNoVatType || VAT_OPTIONS_BOOKKEEPING[0]
                : costCommonVat;
        costsNonCommonVatMapped =
            costNonCommonVat === 0
                ? contract.costsNonCommonNoVatType || VAT_OPTIONS_BOOKKEEPING[0]
                : costNonCommonVat;
    }
    else {
        priceVatMapped = priceVat || VAT_OPTIONS[0];
        costsCommonVatMapped = costCommonVat || VAT_OPTIONS[0];
        costsNonCommonVatMapped = costNonCommonVat || VAT_OPTIONS[0];
    }
    var initialValues = (_c = {},
        _c[EField.LeaseType] = contract.leaseType,
        _c[EField.StartState] = isNewContract
            ? EContractStartState.New
            : EContractStartState.Existing,
        _c[EField.StartDate] = contract.startDate,
        _c[EField.Duration] = EContractDuration.Other,
        _c[EField.EndDate] = contract.stopDate,
        _c[EField.TacitRenewal] = contract.tacitRenewal ? "yes" : "no",
        _c[EField.IsPreviouslyRenewed] = contract.lastRenewalDate ? "yes" : "no",
        // [EField.LengthType]: contract.contractLengthType,
        _c[EField.PayDate] = contract.payDateStart || EContractPaydate.Calendar,
        _c[EField.PriceCurrent] = contract.currentPrice,
        _c[EField.PriceInitialVat] = priceVatMapped,
        _c[EField.PriceCurrentVat] = priceVatMapped,
        _c[EField.CommonCostsInitialVat] = costsCommonVatMapped,
        _c[EField.CommonCostsCurrentVat] = costsCommonVatMapped,
        _c[EField.NonCommonCostsInitialVat] = costsNonCommonVatMapped,
        _c[EField.NonCommonCostsCurrentVat] = costsNonCommonVatMapped,
        _c[EField.CostsCommonCurrent] = contract.currentCostsCommon,
        _c[EField.CostsNonCommonCurrent] = contract.currentCostsNonCommon,
        _c[EField.PriceInitial] = contract.startPrice,
        _c[EField.CostsCommonInitial] = contract.startCostsCommon,
        _c[EField.CostsNonCommonInitial] = contract.startCostsNonCommon,
        _c[EField.BaseYear] = contract.baseYear,
        _c[EField.HasDiscount] = contract.rentDiscountEnabled ? "yes" : "no",
        _c[EField.DiscountAmount] = contract.rentDiscountAmount,
        _c[EField.DiscountOffset] = contract.rentDiscountOffset
            ? "".concat(contract.rentDiscountOffset)
            : undefined,
        _c[EField.DiscountStartDate] = contract.rentDiscountStartedAt,
        _c[EField.PaymentRepetition] = contract.repetitionType || EContractPaymentRepetitionType.Monthly,
        _c[EField.PriceTypeCommonCostsInitial] = contract.priceTypeCommonCosts || EContractCostsPriceType.Fixed,
        _c[EField.PriceTypeCommonCostsCurrent] = contract.priceTypeCommonCosts || EContractCostsPriceType.Fixed,
        _c[EField.PriceTypeNonCommonCostsInitial] = contract.priceTypeNonCommonCosts || EContractCostsPriceType.Fixed,
        _c[EField.PriceTypeNonCommonCostsCurrent] = contract.priceTypeNonCommonCosts || EContractCostsPriceType.Fixed,
        _c[EField.IndexInitial] = indexDate,
        _c[EField.IndexInitial2] = indexDate,
        _c[EField.LastIndexedOn] = contract.lastIndexedAt,
        _c[EField.Owners] = contract.members
            .filter(function (member) { return member.roles.includes(EContractMemberTypes.Owner); })
            .map(function (member) { return member.account.id; }),
        _c[EField.Tenants] = contract.members
            .filter(function (member) { return member.roles.includes(EContractMemberTypes.Tenant); })
            .map(function (member) { return member.account.id; }),
        _c[EField.Parents] = contract.members
            .filter(function (member) { return member.roles.includes(EContractMemberTypes.Parent); })
            .map(function (member) { return member.account.id; }),
        _c[EField.RentDepositAmount] = contract.rentDepositAmount,
        _c[EField.Iban] = contract.rentDiscountBankAccountId,
        _c[EField.EnableIbanisation] = [
            EContractIbanisationStatus.None,
            EContractIbanisationStatus.Disabled,
            EContractIbanisationStatus.EnablingFailed,
        ].includes(contract.ibanisationStatus)
            ? EEnableIbanisation.Disable
            : EEnableIbanisation.Enable,
        _c);
    return initialValues;
};
export var getEndDate = function (formData) {
    var startDate = formData[EField.StartDate];
    var result = formData[EField.EndDate];
    switch (formData[EField.Duration]) {
        case EContractDuration.OneYear:
            result = subDays(addYears(startDate, 1), 1);
            break;
        case EContractDuration.TwoYears:
            result = subDays(addYears(startDate, 2), 1);
            break;
        case EContractDuration.ThreeYears:
            result = subDays(addYears(startDate, 3), 1);
            break;
        case EContractDuration.NineYears:
            result = subDays(addYears(startDate, 9), 1);
            break;
        default:
            break;
    }
    return result;
};
export var getCurrentPriceTypeValue = function (currentKey, initialKey, initialValues, defaultValues) {
    var isExisting = isExistingContract(defaultValues);
    var priceTypeKey = isExisting ? initialKey : currentKey;
    return priceTypeKey in defaultValues
        ? defaultValues[priceTypeKey]
        : initialValues[priceTypeKey];
};
export var getSubtitlePayments = function (payload) {
    var item = payload.item, _a = payload.customOwners, customOwners = _a === void 0 ? [] : _a, _b = payload.customTenants, customTenants = _b === void 0 ? [] : _b, _c = payload.customParents, customParents = _c === void 0 ? [] : _c;
    var accountId = item.accountId;
    if (!accountId) {
        return;
    }
    var roles = [];
    if (customOwners.includes(accountId)) {
        roles.push(EContractMemberTypes.Owner);
    }
    if (customTenants.includes(accountId)) {
        roles.push(EContractMemberTypes.Tenant);
    }
    if (customParents.includes(accountId)) {
        roles.push(EContractMemberTypes.Parent);
    }
    if (roles.length === 0) {
        return;
    }
    return roles
        .map(function (role) { return getLocalizedText("role.".concat(role.toLowerCase())); })
        .join(", ");
};
export var customContactsSelectorPayments = function (payload) {
    var payerType = payload.payerType, state = payload.state, _a = payload.customOwners, customOwners = _a === void 0 ? [] : _a, _b = payload.customTenants, customTenants = _b === void 0 ? [] : _b, _c = payload.customParents, customParents = _c === void 0 ? [] : _c;
    var accountIds = __spreadArray([], customOwners, true);
    if (payerType === "payer") {
        accountIds = __spreadArray(__spreadArray([], customTenants, true), customParents, true);
    }
    var accounts = compact(accountIds.map(function (accountId) { return getContact(state, undefined, accountId); })).map(function (x) { return x.account; });
    return accounts;
};
export var getCreateContractCompanyFieldsSchema = function (contact) {
    return getAllRequiredCompanyFields({
        contact: contact,
        requiredFields: [
            EContactField.Address,
            EContactField.DateOfBirth,
            EContactField.Nationality,
            EContactField.PlaceOfBirth,
            EContactField.VATNumber,
        ],
        appendIdentityNumberToRequiredFields: false,
    });
};
export var getCreateContractContactFieldsSchema = function (contact) {
    return getAllRequiredContactFields({
        contact: contact,
        requiredFields: [
            EContactField.Address,
            EContactField.DateOfBirth,
            EContactField.Nationality,
            EContactField.PlaceOfBirth,
        ],
        appendIdentityNumberToRequiredFields: false,
    });
};
