import { ThemeProvider as ThemeProviderNative } from "@rentiohq/shared-frontend/dist/components/theme/styled-components.native";
import rentioTheme, {
  rentioThemeNative,
} from "@rentiohq/shared-frontend/dist/components/theme/theme.rentio";
import { GlobalStyle, ThemeProvider, defaultTheme } from "@rentiohq/web-theme";
import merge from "lodash/merge";

export const RentioThemeProvider = ({ children }: any) => {
  return (
    <ThemeProvider theme={merge({}, rentioTheme, defaultTheme)}>
      <ThemeProviderNative theme={rentioThemeNative}>
        <GlobalStyle />
        {children}
      </ThemeProviderNative>
    </ThemeProvider>
  );
};
