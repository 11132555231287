import * as commentActions from "@rentiohq/shared-frontend/dist/redux/comment/comment.actions";
import * as commentSelectors from "@rentiohq/shared-frontend/dist/redux/comment/comment.selectors";
import { IComment } from "@rentiohq/shared-frontend/dist/types/comment.types";
import {
  Button,
  EmptyState,
  ResourceList,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import * as t from "../../services/translationService";
import * as S from "./InlineComments.styled";
import { CommentListItem } from "./components/CommentListItem";
import CommentSendBar from "./components/CommentSendBar";

const INLINE_FETCH_LIMIT = 7;

interface IProps {
  baseUrl: string;
  commentIdentifier: string;
}

const InlineComments: React.FC<IProps> = props => {
  const { commentIdentifier, baseUrl } = props;

  // Redux
  const dispatch = useDispatch();

  const isFetchingComments = useSelector((state: IRootStore) =>
    commentSelectors.getIsFetchingComments(state, commentIdentifier),
  );

  const comments = useSelector(
    (state: IRootStore) =>
      commentSelectors.getComments(state, commentIdentifier) || [],
  );

  const showLimit = useSelector((state: IRootStore) => state.comment.showLimit);

  // Lifecycle
  React.useEffect(() => {
    fetchData(true);
  }, [commentIdentifier]);

  // Data
  const fetchData = (refetch: boolean) => {
    dispatch(
      commentActions.getComments.actions.start({
        commentIdentifier,
        baseUrl,
        refetch,
        limit: INLINE_FETCH_LIMIT,
      }),
    );
  };

  // Event handlers
  const handleClickDelete = (comment: IComment) => () => {
    dispatch(
      commentActions.setShowLimit({
        showLimit: showLimit > 5 ? showLimit - 1 : showLimit,
      }),
    );
    dispatch(
      commentActions.deleteComment.actions.start({
        commentId: comment.id,
        commentIdentifier,
      }),
    );
  };

  const handlePressShowMore = () => {
    dispatch(commentActions.setShowLimit({ showLimit: showLimit + 3 }));
    fetchData(false);
  };

  // Render
  const renderContent = () => {
    if (comments && comments.length === 0) {
      return (
        <>
          <EmptyState
            backgroundColor="transparent"
            heading={t.commentEmptyTitle()}
            isLoading={isFetchingComments}
          />
        </>
      );
    }
    if (comments) {
      const commentsToRender = comments.slice(0, showLimit);
      return (
        <>
          {
            <ResourceList
              items={commentsToRender}
              renderItem={renderComments}
              hasSeparator={true}
            />
          }
          {renderShowAllButton()}
        </>
      );
    }
    return null;
  };

  const renderShowAllButton = () => {
    if (comments.length <= showLimit) {
      return;
    }

    return (
      <S.ShowAllButtonWrap>
        <Button appearance="link" onClick={handlePressShowMore}>
          {t.tasksInlineShowMore()}
        </Button>
      </S.ShowAllButtonWrap>
    );
  };

  const renderComments = (comment: IComment) => {
    return (
      <React.Fragment key={comment.id}>
        <CommentListItem
          comment={comment}
          commentIdentifier={commentIdentifier}
          handleClickDelete={handleClickDelete(comment)}
        />
      </React.Fragment>
    );
  };

  return (
    <>
      <CommentSendBar baseUrl={baseUrl} commentIdentifier={commentIdentifier} />
      {renderContent()}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default InlineComments;
