var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { append } from "../../utils/api.utils";
import api from "../../utils/api/api.utils";
import { generateApi } from "../generators/generator.api";
import { GENERATOR_PARAMS } from "./property.constants";
export var FETCH_LIMIT = 20;
export var getPaged = (_a = generateApi(GENERATOR_PARAMS), _a.getPaged), getDetail = _a.getDetail, getCount = _a.getCount, getInfiniteLoad = _a.getInfiniteLoad, update = _a.update, create = _a.create, remove = _a.remove, removeMembers = _a.removeMembers, addMembers = _a.addMembers;
export var sendGroupAction = function (data) {
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/group-communication"), {
        isV2: true,
        data: data,
    });
};
export var sendApplicationInvitation = function (propertyId, data) {
    return api.post("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(propertyId, "/invite-applicant"), {
        data: data,
        isV2: true,
    });
};
export var getAccountContactInfo = function (params) {
    var propertyId = params.propertyId, accountId = params.accountId;
    return api.get("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(propertyId, "/members/").concat(accountId, "/light-contact-info"), { isV2: true });
};
export var getPropertyMissingKYCs = function (propertyId, query) {
    return api.get(append("/".concat(GENERATOR_PARAMS.basePathEntity, "/").concat(propertyId, "/missing-kycs"), __assign(__assign({}, query), { sort: query.sort })), { isV2: true });
};
