var _a, _b, _c, _d;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./paymentRequest.constants";
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["MarkPaid"] = "MARK_PAID";
    EActionCustom["PaymentLink"] = "PAYMENT_LINK";
    EActionCustom["GrantAccessPaymentRequest"] = "GRANT_ACCESS_PAYMENT_REQUEST";
})(EActionCustom || (EActionCustom = {}));
var generateMarkPaidActions = function () { return ({
    markPaidStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkPaid,
        state: EActionState.Start,
    }),
    markPaidSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkPaid,
        state: EActionState.Success,
    }),
    markPaidFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkPaid,
        state: EActionState.Fail,
    }),
}); };
var generateGetPaymentLinkActions = function () { return ({
    getPaymentLinkStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PaymentLink,
        state: EActionState.Start,
    }),
    getPaymentLinkSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PaymentLink,
        state: EActionState.Success,
    }),
    getPaymentLinkFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PaymentLink,
        state: EActionState.Fail,
    }),
}); };
var generateGrantAccessPaymentRequestActions = function () { return ({
    grantAccessPaymentRequestStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GrantAccessPaymentRequest,
        state: EActionState.Start,
    }),
    grantAccessPaymentRequestSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GrantAccessPaymentRequest,
        state: EActionState.Success,
    }),
    grantAccessPaymentRequestFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GrantAccessPaymentRequest,
        state: EActionState.Fail,
    }),
}); };
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, getInfiniteLoadStart = _a.getInfiniteLoadStart, getInfiniteLoadFailed = _a.getInfiniteLoadFailed, getInfiniteLoadSucceeded = _a.getInfiniteLoadSucceeded, createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed, updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed;
export var markPaidStart = (_b = generateMarkPaidActions(), _b.markPaidStart), markPaidSucceeded = _b.markPaidSucceeded, markPaidFailed = _b.markPaidFailed;
export var getPaymentLinkStart = (_c = generateGetPaymentLinkActions(), _c.getPaymentLinkStart), getPaymentLinkSucceeded = _c.getPaymentLinkSucceeded, getPaymentLinkFailed = _c.getPaymentLinkFailed;
export var grantAccessPaymentRequestStart = (_d = generateGrantAccessPaymentRequestActions(), _d.grantAccessPaymentRequestStart), grantAccessPaymentRequestSucceeded = _d.grantAccessPaymentRequestSucceeded, grantAccessPaymentRequestFailed = _d.grantAccessPaymentRequestFailed;
