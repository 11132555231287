import { ERecurrenceType, ETaskReminder, ETaskType, TypeCategoriesMap, } from "../../types/task.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.createTask.types";
export var getNavigationTitle = function (_a) {
    var taskId = _a.taskId;
    if (taskId) {
        return getLocalizedText("tasks.edit");
    }
    return getLocalizedText("task.new");
};
export var REMINDER_OPTIONS = [
    ETaskReminder.DayBeforeExecuteDate,
    ETaskReminder.DayBeforeDueDate,
    ETaskReminder.WeekBeforeExecuteDate,
    ETaskReminder.WeekBeforeDueDate,
    ETaskReminder.MonthBeforeExecuteDate,
    ETaskReminder.MonthBeforeDueDate,
    ETaskReminder.Date,
];
export var FREQUENCY_OPTIONS = [
    ERecurrenceType.Daily,
    ERecurrenceType.Weekly,
    ERecurrenceType.Monthly,
    ERecurrenceType.Quarterly,
    ERecurrenceType.Yearly,
    ERecurrenceType.HalfYearly,
    ERecurrenceType.TwoYearly,
    ERecurrenceType.ThreeYearly,
    ERecurrenceType.FiveYearly,
];
export var getMappedCategories = function (type) {
    var categories = TypeCategoriesMap.get(type);
    if (!categories)
        return [];
    return categories;
};
export var getInitialValues = function (extraData) {
    var _a, _b;
    var task = extraData.task, executors = extraData.executors, followers = extraData.followers, payerAccountId = extraData.payerAccountId, repairAccountId = extraData.repairAccountId, propertyId = extraData.propertyId, keypoint = extraData.keypoint;
    if (task) {
        var isRecurrent = task.recurrenceType !== ERecurrenceType.Once;
        return _a = {},
            _a[EField.Title] = task.title,
            _a[EField.PropertySelect] = task.propertyId ? task.propertyId : undefined,
            _a[EField.ExecuteDate] = task.executeDate || undefined,
            _a[EField.RecurrenceSelection] = isRecurrent
                ? ERecurrenceType.Recurring
                : ERecurrenceType.Once,
            _a[EField.ReminderType] = task.nextReminderDate
                ? ETaskReminder.HasDate
                : ETaskReminder.None,
            _a[EField.ReminderOption] = task.reminderOption || undefined,
            _a[EField.DueDate] = task.dueDate || undefined,
            _a[EField.NextReminderDate] = task.nextReminderDate || undefined,
            _a[EField.RecurrenceType] = (isRecurrent && task.recurrenceType) || undefined,
            _a[EField.RecurrenceEndDate] = (isRecurrent &&
                task.recurrenceInfo &&
                task.recurrenceInfo.recurrenceEndDate) ||
                undefined,
            _a[EField.Description] = task.description,
            _a[EField.Type] = keypoint ? ETaskType.Repair : task.type,
            _a[EField.Category] = task.category,
            _a[EField.DamageType] = task.damageType,
            _a[EField.Location] = task.location,
            _a[EField.IncidentDate] = task.incidentDate,
            _a[EField.Payer] = payerAccountId ? [payerAccountId] : undefined,
            _a[EField.ContactPerson] = repairAccountId ? [repairAccountId] : undefined,
            _a;
    }
    return _b = {},
        _b[EField.GroupActionExecutors] = [],
        _b[EField.Executors] = executors,
        _b[EField.Followers] = followers,
        _b[EField.PropertySelect] = propertyId,
        _b[EField.RecurrenceSelection] = ERecurrenceType.Once,
        _b[EField.ReminderType] = ETaskReminder.None,
        _b[EField.Type] = keypoint ? ETaskType.Repair : undefined,
        _b;
};
