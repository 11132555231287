import { jsx as _jsx } from "react/jsx-runtime";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { SHOW_CONFIRM_MODAL } from "../../redux/actions";
import { confirmModalSelector } from "../../redux/selectors";
import { MuiDialog } from "../Dialog";
export var ConfirmModal = function () {
    var dispatch = useDispatch();
    var confirmModal = useSelector(confirmModalSelector);
    var handleClose = function () {
        dispatch({
            type: SHOW_CONFIRM_MODAL,
            payload: { showConfirmModal: false },
        });
    };
    var showConfirmModal = confirmModal.showConfirmModal, title = confirmModal.title, description = confirmModal.description, actions = confirmModal.actions;
    return (_jsx(MuiDialog, { title: title, open: showConfirmModal, actions: actions, toggleOpen: handleClose, children: _jsx(Typography, { variant: "body1", children: description }) }));
};
