import { EAssets } from "@rentiohq/web-shared-next/dist/assets";

export enum EProblemCategory {
  Property = "property",
  Payment = "payment",
  RentioApp = "rentioApp",
}

export enum EContactMethod {
  Chat = "chat",
  Call = "call",
}

export type Choice<T> = {
  icon?: EAssets;
  label: string;
  value: T;
};
