import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
// Context
export var LoopContext = React.createContext({
    loopConfig: undefined,
    setLoopConfig: function (newValue) { },
});
// Provider
var Provider = function (props) {
    var children = props.children;
    // State
    var _a = React.useState(props.loopConfig), loopConfig = _a[0], setLoopConfig = _a[1];
    // Render
    return (_jsx(LoopContext.Provider, { value: {
            loopConfig: loopConfig,
            setLoopConfig: setLoopConfig,
        }, children: children }));
};
// eslint-disable-next-line import/no-default-export
export default Provider;
