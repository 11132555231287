var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";
import Slide from "@mui/material/Slide";
import Zoom from "@mui/material/Zoom";
import React from "react";
import { TransitionStyles } from "./Transitions.styles";
export var Transitions = React.forwardRef(function (_a, ref) {
    var children = _a.children, position = _a.position, sx = _a.sx, type = _a.type, direction = _a.direction, others = __rest(_a, ["children", "position", "sx", "type", "direction"]);
    var defaultStyles = TransitionStyles(position);
    return (_jsxs(Box, { ref: ref, children: [type === "grow" && (_jsx(Grow, __assign({}, others, { children: _jsx(Box, { sx: defaultStyles, children: children }) }))), type === "collapse" && (_jsx(Collapse, __assign({}, others, { sx: defaultStyles, children: children }))), type === "fade" && (_jsx(Fade, __assign({}, others, { timeout: {
                    appear: 500,
                    enter: 600,
                    exit: 400,
                }, children: _jsx(Box, { sx: defaultStyles, children: children }) }))), type === "slide" && (_jsx(Slide, __assign({}, others, { timeout: {
                    appear: 0,
                    enter: 400,
                    exit: 200,
                }, direction: direction, children: _jsx(Box, { sx: defaultStyles, children: children }) }))), type === "zoom" && (_jsx(Zoom, __assign({}, others, { children: _jsx(Box, { sx: defaultStyles, children: children }) })))] }));
});
Transitions.defaultProps = {
    type: "grow",
    position: "top-left",
    direction: "up",
};
