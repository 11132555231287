import { IApplication } from "@rentiohq/shared-frontend/dist/types/application.types";
import { useState } from "react";
import { IDropArea } from "../PropertyDetailApplications";
import { DragAndDropContext } from "./DragAndDropContext";

interface IProps {
  children: React.ReactNode;
}

export const DragAndDropContextProvider = ({ children }: IProps) => {
  const [selectedItems, setSelectedItems] = useState<IApplication[]>([]);
  const [allDropAreas, setAllDropAreas] = useState<IDropArea[]>([]);

  const onSelectItem = (item: IApplication) => {
    setSelectedItems([...selectedItems, item]);
  };

  const onUnselectItem = (item: IApplication) => {
    setSelectedItems(selectedItems.filter(i => i.id !== item.id));
  };

  const replaceSelectedItems = (items: IApplication[]) => {
    setSelectedItems(items);
  };

  return (
    <DragAndDropContext.Provider
      value={{
        selectedItems,
        allDropAreas,
        onSelectItem,
        onUnselectItem,
        replaceSelectedItems,
        setAllDropAreas,
      }}
    >
      {children}
    </DragAndDropContext.Provider>
  );
};
