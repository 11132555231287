var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Banner } from "../../Banner";
import { StyledHelpText } from "../../Form/Form.styled";
import { Loading } from "../../Loading";
import { OptionListShared } from "../../OptionList";
export var OptionsField = function (_a) {
    var name = _a.name, schema = _a.schema, idSchema = _a.idSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, formData = _a.formData, formContext = _a.formContext, required = _a.required, uiSchema = _a.uiSchema, disabled = _a.disabled, readonly = _a.readonly;
    var dispatch = useDispatch();
    var id = idSchema.$id;
    // @ts-ignore TODO: Typescript upgrade
    var title = getFieldTitle(uiSchema, formContext);
    // @ts-ignore TODO: Typescript upgrade
    var info = getFieldInfo(uiSchema, formContext);
    var _c = schema.options, options = _c === void 0 ? [] : _c;
    var error = rawErrors[0];
    var uiOptions = uiSchema["ui:options"];
    var variant = (uiOptions === null || uiOptions === void 0 ? void 0 : uiOptions.hasOwnProperty("variant"))
        ? uiOptions.variant
        : undefined;
    var size = (uiOptions === null || uiOptions === void 0 ? void 0 : uiOptions.hasOwnProperty("size")) ? uiOptions.size : undefined;
    var iconSize = uiOptions ? uiOptions.iconSize : undefined;
    var showLabel = (uiOptions === null || uiOptions === void 0 ? void 0 : uiOptions.hasOwnProperty("showLabel"))
        ? uiOptions.showLabel
        : true;
    var _d = uiSchema.isMultiSelect, isMultiSelect = _d === void 0 ? false : _d, autoSubmit = uiSchema.autoSubmit, customExtraOptionsSelector = uiSchema.customExtraOptionsSelector, customIsFetchingSelector = uiSchema.customIsFetchingSelector, _e = uiSchema.customGetOptionsActions, customGetOptionsActions = _e === void 0 ? [] : _e, emptyMessage = uiSchema.emptyMessage, isDisabled = uiSchema.isDisabled, _f = uiSchema.optional, optional = _f === void 0 ? false : _f;
    var allFormData = formContext.getAllFormData();
    var extraOptions = useSelector(function (state) { return (customExtraOptionsSelector === null || customExtraOptionsSelector === void 0 ? void 0 : customExtraOptionsSelector(state, allFormData)) || []; });
    var mergedOptions = __spreadArray(__spreadArray([], options, true), extraOptions, true);
    var isFetching = useSelector(function (state) { return (customIsFetchingSelector === null || customIsFetchingSelector === void 0 ? void 0 : customIsFetchingSelector(state, allFormData)) || false; });
    var uiDisabled = isDisabled
        ? isDisabled(formContext.getAllFormData())
        : undefined;
    React.useEffect(function () {
        customGetOptionsActions.forEach(function (action) {
            dispatch(action(allFormData));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var getIdFromValue = function (value) {
        var selectionOption = mergedOptions.find(function (option) { return "".concat(option.id) === "".concat(value); });
        return selectionOption ? selectionOption.id : undefined;
    };
    var handleChange = function (newValues) {
        var formattedValue = newValues.map(function (value) {
            return getIdFromValue(value);
        });
        if (!isMultiSelect) {
            formattedValue = newValues[0];
            // Value can't be deselected if it's a radio button list
            if (formattedValue === undefined || formattedValue === null) {
                return;
            }
        }
        onChange(formattedValue);
        if (formContext.onFieldChange) {
            setTimeout(function () {
                formContext.onFieldChange(name, formattedValue);
            }, 0);
        }
        if (autoSubmit && formContext.hasOwnProperty("onSubmitAttempt")) {
            setTimeout(function () {
                formContext.onSubmitAttempt();
            }, 300);
        }
    };
    var handlePressExtraInfo = function () {
        if (formContext.onPressExtraInfo) {
            formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
        }
    };
    if (isFetching && mergedOptions.length === 0) {
        return _jsx(Loading, { asDots: true });
    }
    if (mergedOptions.length === 0 && !!emptyMessage) {
        return (_jsx(Banner, { variation: "warning", hasDismiss: false, children: typeof emptyMessage === "function"
                ? emptyMessage(allFormData)
                : emptyMessage }));
    }
    var mappedOptions = mergedOptions.map(function (option) {
        var id = option.id, getValue = option.getValue, value = option.value, getSubValue = option.getSubValue, subValue = option.subValue, rest = __rest(option, ["id", "getValue", "value", "getSubValue", "subValue"]);
        var label = value;
        var description = subValue;
        if (getValue) {
            label = getValue(formContext.getFormData());
        }
        if (getSubValue) {
            description = getSubValue(formContext.getFormData());
        }
        return __assign({ label: label, value: id, id: id, description: description }, rest);
    });
    return (_jsxs(_Fragment, { children: [_jsx(OptionListShared, { id: id, value: isMultiSelect ? formData : [formData], options: mappedOptions, title: title, multiple: isMultiSelect, variant: variant, size: size, iconSize: iconSize, showLabel: showLabel, error: error, onChange: handleChange, onPressExtraInfo: uiSchema.extraInfoUrl && handlePressExtraInfo, optional: optional, disabled: uiDisabled || disabled, readOnly: readonly }), info && _jsx(StyledHelpText, { children: info })] }));
};
