var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as paymentGroupHooks from "@rentiohq/shared-frontend/dist/reduxV2/paymentGroup/paymentGroup.hooks";
import { useInternalMode } from "../../redux/system/system.hooks";
import { RentioInternalRenderer } from "./RentioInternalRenderer";
var RentioInternalRendererPaymentGroupBase = function (props) {
    var paymentGroupId = props.paymentGroupId;
    var paymentGroup = paymentGroupHooks.useDetail({
        id: paymentGroupId,
        shouldRefetch: true,
    });
    var _a = paymentGroup.detail || {}, status = _a.status, repetitionType = _a.repetitionType;
    return (_jsx(RentioInternalRenderer, { items: __assign(__assign({}, props), { status: status, repetitionType: repetitionType }) }));
};
export var RentioInternalRendererPaymentGroup = function (props) {
    var internalModeEnabled = useInternalMode().internalModeEnabled;
    if (!internalModeEnabled) {
        return null;
    }
    return _jsx(RentioInternalRendererPaymentGroupBase, __assign({}, props));
};
