import {
  getSchema as getSchema1,
  uiSchema as uiSchema1,
} from "./schema.manuallyMarkPaid.1";
import { IExtraData } from "./schema.manuallyMarkPaid.types";
import { getInitialValues } from "./schema.manuallyMarkPaid.utils";

export const manuallyMarkPaidSchemas = (extraData: IExtraData) => {
  const initialValues = getInitialValues(extraData);

  return [
    {
      schema: getSchema1(initialValues, extraData),
      uiSchema: uiSchema1(extraData),
    },
  ];
};
