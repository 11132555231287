import { round } from "lodash";
import { EField } from "../forms/createContract/schema.createContract.types";
import { getName } from "../redux/contact/contact.utils";
import { EContractMemberTypes, ELeaseType, } from "../types/contract.types";
import { isAfter, isBefore } from "./date-fns.utils";
import { getLocalizedText } from "./i18n/i18n.utils";
import { formatCurrency } from "./number.utils";
import { isFlemishZip } from "./property.utils";
import { getMembersWithRole } from "./roles.utils";
import { join } from "./string.utils";
var FIRST_OF_JANUARY_2019 = "2019-01-01T00:00:00.000Z";
export var getContractAmountAndTenants = function (contract) {
    var total = (contract.currentCostsCommon || 0) +
        (contract.currentCostsNonCommon || 0) +
        (contract.currentPrice || 0);
    var amount = getLocalizedText("system.amount.".concat(contract.repetitionType).toLowerCase(), {
        amount: formatCurrency(round(total, 2)),
    });
    var tenants = join(getMembersWithRole(contract.members, EContractMemberTypes.Tenant).map(function (member) { return getName(member.account); }), " - ");
    return "".concat(amount, " - ").concat(tenants);
};
export var isResidentialLease = function (leaseType) {
    return [ELeaseType.StudentHousing, ELeaseType.MainResidence].includes(leaseType);
};
export var getShouldPrefillRefIndex = function (formData, property) {
    if (formData === void 0) { formData = {}; }
    var leaseType = formData[EField.LeaseType];
    var startDate = formData[EField.StartDate];
    var propertyInFlanders = (property === null || property === void 0 ? void 0 : property.zip) && isFlemishZip(property.zip);
    var residentialLease = isResidentialLease(leaseType);
    var contractStartedAfter2018 = isAfter(startDate, FIRST_OF_JANUARY_2019);
    return propertyInFlanders && residentialLease && contractStartedAfter2018;
};
export var isActiveContract = function (contract) {
    var startDate = contract.startDate, stopDate = contract.stopDate;
    if (!stopDate) {
        return false;
    }
    return isAfter(new Date(), startDate) && isBefore(new Date(), stopDate);
};
export var isEndedContract = function (contract) {
    var stopDate = contract.stopDate;
    if (!stopDate) {
        return false;
    }
    return isAfter(new Date(), stopDate);
};
export var isFutureContract = function (contract) {
    var startDate = contract.startDate;
    return isBefore(new Date(), startDate);
};
