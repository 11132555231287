import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CssBaseline from "@mui/material/CssBaseline";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { ThemeProvider, createTheme, } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useMemo } from "react";
import useConfig from "../hooks/useConfig";
import componentStyleOverrides from "./compStyleOverride";
import Palette from "./palette";
import customShadows from "./shadows";
import Typography from "./typography";
export { alpha, useTheme } from "@mui/material/styles";
export { useMediaQuery };
// eslint-disable-next-line import/no-default-export
export default function ThemeCustomization(_a) {
    var children = _a.children;
    var _b = useConfig(), borderRadius = _b.borderRadius, fontFamily = _b.fontFamily, mode = _b.mode, outlinedFilled = _b.outlinedFilled, presetColor = _b.presetColor, themeDirection = _b.themeDirection;
    var theme = useMemo(function () { return Palette(mode, presetColor); }, [mode, presetColor]);
    var themeTypography = useMemo(function () { return Typography(theme, borderRadius, fontFamily); }, [theme, borderRadius, fontFamily]);
    var themeCustomShadows = useMemo(function () { return customShadows(mode, theme); }, [mode, theme]);
    var themeOptions = useMemo(function () { return ({
        direction: themeDirection,
        palette: theme.palette,
        mixins: {
            toolbar: {
                minHeight: "48px",
                padding: "16px",
                "@media (min-width: 600px)": {
                    minHeight: "48px",
                },
            },
        },
        typography: themeTypography,
        customShadows: themeCustomShadows,
    }); }, [themeDirection, theme, themeCustomShadows, themeTypography]);
    var themes = createTheme(themeOptions);
    themes.components = useMemo(function () { return componentStyleOverrides(themes, borderRadius, outlinedFilled); }, [themes, borderRadius, outlinedFilled]);
    return (_jsx(StyledEngineProvider, { injectFirst: true, children: _jsxs(ThemeProvider, { theme: themes, children: [_jsx(CssBaseline, { enableColorScheme: true }), children] }) }));
}
export var getFontSize = function (size) { return "".concat(size, "rem"); };
