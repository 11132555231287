import { useTheme } from "@mui/material/styles";
import { EFontWeights } from "../../themes/types";
export var MuiDialogStyles = function (maxWidth, width) {
    var theme = useTheme();
    return {
        wrapper: {
            ".MuiDialog-paper": {
                maxWidth: maxWidth || "700px",
                width: width || "fit-content",
                pt: 0,
            },
        },
        content: {
            dialogTitle: {
                m: 0,
                p: 2,
                pr: 10,
                color: theme.palette.grey[900],
                fontWeight: EFontWeights.ExtraBold,
                fontSize: "20px",
                wordBreak: "break-word",
            },
            iconButton: {
                position: "absolute",
                right: 10,
                top: 10,
                color: theme.palette.grey[500],
                fontWeight: EFontWeights.Bold,
                fontSize: "20px",
            },
            dialogActions: { pr: 2.5, pt: 2.5 },
        },
    };
};
