import { IMoveRequest } from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";

export enum EStep {
  LeavingContact = "leavingContact",
  EnergyNote = "energyNote",
  TelcoNote = "telcoNote",
  ElectricityGeneral = "electricityGeneral",
  ElectricityHandover = "electricityHandover",
  ElectricityHandoverDocuments = "electricityHandoverDocuments",
  NaturalGasGeneral = "naturalGasGeneral",
  NaturalGasHandover = "naturalGasHandover",
  NaturalGasHandoverDocuments = "naturalGasHandoverDocuments",
  WaterGeneral = "waterGeneral",
  WaterHandover = "waterHandover",
  WaterHandoverDocuments = "waterHandoverDocuments",
}

export interface IExtraData {
  step: EStep;
  moveRequest: IMoveRequest;
  property: IProperty;
}

export enum EField {
  HandoverDocuments = "handoverDocuments",

  LeavingContactFirstname = "leavingContactFirstname",
  LeavingContactLastname = "leavingContactLastname",
  LeavingContactEmail = "leavingContactEmail",

  NoteEnergy = "noteEnergy",
  NoteTelco = "noteTelco",

  ElectricityType = "electricityType",
  ElectricityEan = "electricityEan",
  ElectricityMeterNumberBoth = "electricityMeterNumberBoth",

  ElectricityMeterType = "electricityMeterType",
  ElectricityMeterReadingBoth = "electricityMeterReadingBoth",
  ElectricityMeterReadingBothDate = "electricityMeterReadingBothDate",
  ElectricityMeterReadingDay = "electricityMeterReadingDay",
  ElectricityMeterReadingDayDate = "electricityMeterReadingDayDate",
  ElectricityMeterReadingNight = "electricityMeterReadingNight",
  ElectricityMeterReadingNightDate = "electricityMeterReadingNightDate",

  NaturalGasAvailable = "naturalGasAvailable",
  NaturalGasEan = "naturalGasEan",
  NaturalGasMeterNumber = "naturalGasMeterNumber",
  NaturalGasMeterType = "naturalGasMeterType",
  NaturalGasMeterReading = "naturalGasMeterReading",
  NaturalGasMeterReadingDate = "naturalGasMeterReadingDate",

  WaterMeterNumber = "waterMeterNumber",
  WaterMeterReading = "waterMeterReading",
  WaterMeterReadingDate = "waterMeterReadingDate",
}
