import {
  getSchema as getSchema1,
  uiSchema as uiSchema1,
} from "./schema.propertyApplicationNotEligible.1";
import { IExtraData } from "./schema.propertyApplicationNotEligible.types";
import { getInitialValues } from "./schema.propertyApplicationNotEligible.utils";

// eslint-disable-next-line import/no-default-export
export default (extraData: IExtraData) => {
  const initialValues = getInitialValues(extraData);
  return [
    {
      schema: getSchema1(initialValues, extraData),
      uiSchema: uiSchema1(extraData),
    },
  ];
};
