import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import "draft-js/dist/Draft.css";
import { TextStyle } from "../../../../../../components";
var GroupInfo = function (props) {
    var group = props.group;
    // Render
    return (_jsxs(_Fragment, { children: [_jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.group.name") }), _jsx("div", { children: getLocalizedText(group.name) }), _jsx(Spacer, { weight: ESpacerWeight.W12 }), group.sortWeight !== undefined && (_jsxs(_Fragment, { children: [_jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.group.sort_weight") }), _jsx("div", { children: group.sortWeight })] }))] }));
};
// eslint-disable-next-line import/no-default-export
export default GroupInfo;
