import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import FormSegmentedControl from "@rentiohq/shared-frontend/dist/components/components/FormSegmentedControl";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { CompositeDecorator, convertFromRaw, EditorState } from "draft-js";
import React from "react";
import { Banner } from "../../../../components/Banner";
import { Loading } from "../../../../components/Loading";
import { EExternalViewType } from "../../../TemplateDocumentEditor/scenes/TemplateDocumentExternal/TemplateDocumentExternal.types";
import ToolbarFooter from "../../components/ToolbarFooter";
import EditorPreview from "../EditorPreview";
import EditorData from "./components/EditorData";
import EditorSidebar from "./components/EditorSidebar";
import { DisplayTypeContext } from "./contexts/DisplayType.context";
import { DocumentConfigContext } from "./contexts/DocumentConfig.context";
import EditorStateContextProvider from "./contexts/EditorState.context";
import { FieldsContext } from "./contexts/Fields.context";
import { VariablesDataContext } from "./contexts/VariablesData.context";
import decoratorConditionRenderer from "./decorators/ConditionRenderer";
import decoratorIndexRenderer from "./decorators/IndexCountRenderer";
import decoratorLoopRenderer from "./decorators/LoopRenderer";
import decoratorVariableRenderer from "./decorators/VariableRenderer";
import * as S from "./Editor.styles";
import { EOptionsType } from "./Editor.types";
var Editor = function (props) {
    var title = props.title, subtitle = props.subtitle, onPressUpdate = props.onPressUpdate, onPressSave = props.onPressSave, isFetching = props.isFetching, rawState = props.rawState, canEdit = props.canEdit, _a = props.externalViewType, externalViewType = _a === void 0 ? EExternalViewType.Dashboard : _a;
    // Context
    var _b = React.useContext(DisplayTypeContext), setDisplayType = _b.setDisplayType, isTemplateDocument = _b.isTemplateDocument, isPrint = _b.isPrint, isTemplateDocumentExternal = _b.isTemplateDocumentExternal;
    var setDocumentConfig = React.useContext(DocumentConfigContext).setDocumentConfig;
    var resetFields = React.useContext(FieldsContext).resetFields;
    var setVariablesData = React.useContext(VariablesDataContext).setVariablesData;
    // State
    var _c = React.useState(), editorState = _c[0], setEditorState = _c[1];
    var _d = React.useState(EOptionsType.FillIn), selected = _d[0], setSelected = _d[1];
    var _e = React.useState(false), signingPhase = _e[0], setSigningPhase = _e[1];
    var _f = React.useState(false), isPreview = _f[0], setPreview = _f[1];
    // Lifecycle
    React.useEffect(function () {
        if (props.fields) {
            resetFields(props.fields);
        }
    }, [props.fields]);
    React.useEffect(function () {
        if (props.displayType) {
            setDisplayType(props.displayType);
        }
    }, [props.displayType]);
    React.useEffect(function () {
        if (props.documentConfig) {
            setDocumentConfig(props.documentConfig);
        }
    }, [props.documentConfig]);
    React.useEffect(function () {
        setVariablesData(props.variablesData || {});
    }, [props.variablesData]);
    React.useEffect(function () {
        if (!editorState && rawState) {
            // setEditorState(EditorState.createEmpty(decorator));
            var decorator = new CompositeDecorator([
                decoratorConditionRenderer,
                decoratorIndexRenderer,
                decoratorLoopRenderer,
                decoratorVariableRenderer,
            ]);
            var newEditorState = EditorState.createWithContent(convertFromRaw(rawState), decorator);
            setEditorState(newEditorState);
        }
    }, [rawState]);
    React.useEffect(function () {
        var preview = selected === EOptionsType.FillIn ? false : true;
        setPreview(preview);
    }, [selected]);
    // Render
    var renderEditor = function () {
        if (isFetching || !editorState) {
            return _jsx(Loading, {});
        }
        if (isTemplateDocument || isPrint || isTemplateDocumentExternal) {
            return (_jsx(S.ContentFontWrap, { children: _jsx(EditorPreview, { editorState: editorState, onPressUpdate: onPressUpdate, onPressSave: onPressSave, canEdit: canEdit }) }));
        }
        return (_jsx(EditorData, { editorState: editorState, setEditorState: setEditorState, onPressUpdate: onPressUpdate, onPressSave: onPressSave }));
    };
    var onPressNext = function () {
        setSigningPhase(true);
    };
    var onPressBack = function () {
        setSigningPhase(false);
    };
    var handleSelect = function (id) {
        setSelected(id);
    };
    var options = [
        { id: EOptionsType.FillIn, value: "Fill in" },
        { id: EOptionsType.Preview, value: "Preview" },
    ];
    var renderEditorLayout = function () {
        return (_jsxs(EditorStateContextProvider, { editorState: editorState, setEditorState: setEditorState, children: [signingPhase && externalViewType === EExternalViewType.Dashboard ? (_jsx(S.OuterWrap, { isBanner: true, children: showBanner() })) : (_jsxs(S.Wrap, { children: [!isPrint && !isPreview && !signingPhase && (_jsx(S.SidebarWrap, { children: isFetching || !editorState ? (_jsx(Loading, {})) : (_jsx(_Fragment, { children: _jsx(EditorSidebar, { title: title, subtitle: subtitle, onPressUpdate: onPressUpdate, onPressSave: onPressSave }) })) })), (!isTemplateDocumentExternal ||
                            isPreview ||
                            externalViewType === EExternalViewType.Dashboard) &&
                            !signingPhase && _jsx(S.EditorWrap, { children: renderEditor() })] })), isTemplateDocumentExternal && (_jsx(ToolbarFooter, { onPressSave: onPressSave, onPressNext: onPressNext, onPressBack: onPressBack, externalViewType: externalViewType }))] }));
    };
    var showBanner = function () {
        return (_jsx(Banner, { icon: "lightBulb", title: getLocalizedText("template_document.alert_message_box.title"), variation: "warning", hasDismiss: false, children: getLocalizedText("template_document.alert_message_box.description") }));
    };
    if (isTemplateDocumentExternal &&
        externalViewType === EExternalViewType.MobileApp) {
        return (_jsxs(S.OuterWrap, { isBanner: signingPhase, children: [!signingPhase && (_jsx(FormSegmentedControl, { title: "", selected: selected, options: options, onPressExtraInfo: function () { }, onSelect: handleSelect })), signingPhase && showBanner(), renderEditorLayout()] }));
    }
    return renderEditorLayout();
};
// eslint-disable-next-line import/no-default-export
export default Editor;
