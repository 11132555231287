var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import { getDefaultStateAll, registerActions, } from "../generic/all.reducer";
import * as invoiceActions from "./invoice.actions";
import CONFIG from "./invoice.config";
export var REDUCER_KEY_INVOICE = CONFIG.REDUCER_KEY;
var getDefaultState = function () {
    return merge({}, getDefaultStateAll(), {
        custom: {},
    });
};
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
registerActions({
    entityName: CONFIG.ENTITY_NAME,
    reducer: reducer,
});
reducer.on(invoiceActions.markInvoicePaid.types.START, function (state, _a) {
    var _b;
    var invoiceId = _a.invoiceId;
    return (__assign(__assign({}, state), { custom: __assign(__assign({}, state.custom), (_b = {}, _b[invoiceId] = __assign(__assign({}, state.custom[invoiceId]), { isMarkingPaid: true, markPaidError: undefined }), _b)) }));
});
reducer.on(invoiceActions.markInvoicePaid.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var invoiceId = _a.invoiceId, invoice = _a.invoice;
    return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), (_b = {}, _b[invoiceId] = invoice, _b)), custom: __assign(__assign({}, state.custom), (_c = {}, _c[invoiceId] = __assign(__assign({}, state.custom[invoiceId]), { isMarkingPaid: false, markPaidError: undefined }), _c)) }));
});
reducer.on(invoiceActions.markInvoicePaid.types.FAILURE, function (state, _a) {
    var _b;
    var invoiceId = _a.invoiceId, error = _a.error;
    return (__assign(__assign({}, state), { custom: __assign(__assign({}, state.custom), (_b = {}, _b[invoiceId] = __assign(__assign({}, state.custom[invoiceId]), { isMarkingPaid: false, markPaidError: error }), _b)) }));
});
reducer.on(invoiceActions.markInvoiceUnpaid.types.START, function (state, _a) {
    var _b;
    var invoiceId = _a.invoiceId;
    return (__assign(__assign({}, state), { custom: __assign(__assign({}, state.custom), (_b = {}, _b[invoiceId] = __assign(__assign({}, state.custom[invoiceId]), { isMarkingUnpaid: true, markUnpaidError: undefined }), _b)) }));
});
reducer.on(invoiceActions.markInvoiceUnpaid.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var invoiceId = _a.invoiceId, invoice = _a.invoice;
    return (__assign(__assign({}, state), { data: __assign(__assign({}, state.data), (_b = {}, _b[invoiceId] = invoice, _b)), custom: __assign(__assign({}, state.custom), (_c = {}, _c[invoiceId] = __assign(__assign({}, state.custom[invoiceId]), { isMarkingUnpaid: false, markUnpaidError: undefined }), _c)) }));
});
reducer.on(invoiceActions.markInvoiceUnpaid.types.FAILURE, function (state, _a) {
    var _b;
    var invoiceId = _a.invoiceId, error = _a.error;
    return (__assign(__assign({}, state), { custom: __assign(__assign({}, state.custom), (_b = {}, _b[invoiceId] = __assign(__assign({}, state.custom[invoiceId]), { isMarkingUnpaid: false, markUnpaidError: error }), _b)) }));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
