import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Grid } from "../Grid";
import * as S from "./ButtonGroup.styled";
export var ButtonGroup = function (props) {
    var children = props.children, _a = props.spacing, spacing = _a === void 0 ? "base" : _a, _b = props.align, align = _b === void 0 ? "center" : _b;
    return (_jsx(S.ButtonGroup, { spacing: spacing, justifyContent: align, children: React.Children.map(children, function (child, idx) {
            if (!child) {
                return null;
            }
            return _jsx(Grid.Item, { children: child }, idx);
        }) }));
};
