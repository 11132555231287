var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { compact } from "lodash";
import React from "react";
import { TextStyle } from "../../components";
import { ts } from "../../services";
import { AccountContactInfoModal } from "../AccountContactInfoModal";
import { Icon } from "../Icon";
import { ResourceListItem } from "../ResourceList";
export var AccountInfoListItem = function (props) {
    var account = props.account, employeeAccount = props.employeeAccount, _a = props.actions, actions = _a === void 0 ? [] : _a, _b = props.getActions, getActions = _b === void 0 ? function () { return []; } : _b, getContactInfoForAccount = props.getContactInfoForAccount, onAddAccount = props.onAddAccount, getSubtitle = props.getSubtitle, getExtraSubtitle = props.getExtraSubtitle, resourceListItemProps = props.resourceListItemProps;
    var iconSource = !!account.company ? "briefcase" : "profile";
    var subtitle = getSubtitle === null || getSubtitle === void 0 ? void 0 : getSubtitle([account.id]);
    var extraSubtitle = getExtraSubtitle === null || getExtraSubtitle === void 0 ? void 0 : getExtraSubtitle([account.id]);
    var _c = React.useState(false), showContactInfo = _c[0], setShowContactInfo = _c[1];
    var hasContactInfo = !!getContactInfoForAccount;
    var mergedActions = compact(__spreadArray(__spreadArray(__spreadArray([], getActions(account.id).map(function (action) { return (__assign(__assign({}, action), { onClick: function () {
            var _a;
            return (_a = action.onClick) === null || _a === void 0 ? void 0 : _a.call(action, account.id);
        } })); }), true), actions
        .filter(function (action) { return !action.isContactAction; })
        .map(function (action) { return (__assign(__assign({}, action), { onClick: function () {
            var _a;
            return (_a = action.onClick) === null || _a === void 0 ? void 0 : _a.call(action, account.id);
        } })); }), true), [
        hasContactInfo
            ? {
                content: getLocalizedText("account.view_basic_info"),
                media: _jsx(Icon, { source: "contact" }),
                onClick: function () {
                    setShowContactInfo(true);
                },
            }
            : undefined,
        {
            content: ts.contactAddAsContact(),
            media: _jsx(Icon, { source: "addCircle" }),
            onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                var accountContactInfo, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 3, , 4]);
                            accountContactInfo = void 0;
                            if (!getContactInfoForAccount) return [3 /*break*/, 2];
                            return [4 /*yield*/, getContactInfoForAccount(account)];
                        case 1:
                            accountContactInfo = _a.sent();
                            _a.label = 2;
                        case 2:
                            onAddAccount(account, accountContactInfo);
                            return [3 /*break*/, 4];
                        case 3:
                            error_1 = _a.sent();
                            showAlert({
                                type: "error",
                                error: error_1,
                            });
                            onAddAccount(account);
                            return [3 /*break*/, 4];
                        case 4: return [2 /*return*/];
                    }
                });
            }); },
        },
    ], false));
    var renderName = function () {
        if (employeeAccount) {
            return (_jsxs("div", { children: [getName(employeeAccount), " (", getName(account), ")"] }));
        }
        return _jsx("div", { children: getName(account) });
    };
    var renderSubtitle = function () {
        if (!subtitle) {
            return null;
        }
        if (subtitle === null || subtitle === void 0 ? void 0 : subtitle.hasOwnProperty("type")) {
            return subtitle;
        }
        return (_jsx(TextStyle, { variation: "subdued", size: "small", children: subtitle }));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(ResourceListItem, __assign({ media: _jsx(Icon, { source: iconSource }), item: account, actions: mergedActions }, resourceListItemProps, { children: [renderName(), renderSubtitle(), !!extraSubtitle && _jsx("div", { children: extraSubtitle })] })), showContactInfo && (_jsx(AccountContactInfoModal, { account: account, getContactInfoForAccount: getContactInfoForAccount, onClose: function () {
                    setShowContactInfo(false);
                }, onAddAsContact: function (accountContactInfo) {
                    setShowContactInfo(false);
                    onAddAccount(account, accountContactInfo);
                } }))] }));
};
