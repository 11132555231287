import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { ELinkTarget } from "../../utils/types";
import { LinkStyles } from "./Link.styles";
export var Link = function (_a) {
    var _b = _a.link, link = _b === void 0 ? "" : _b, label = _a.label, _c = _a.fontSize, fontSize = _c === void 0 ? 14 : _c, onClick = _a.onClick, _d = _a.target, target = _d === void 0 ? ELinkTarget.self : _d, _e = _a.underlined, underlined = _e === void 0 ? true : _e;
    var navigate = useNavigate();
    var sx = LinkStyles(fontSize, underlined);
    var OpenLink = function () {
        if (target === ELinkTarget.blank) {
            window.open(link, target);
        }
        if (target === ELinkTarget.self) {
            navigate(link);
        }
    };
    return (_jsx(Box, { onClick: function () { return (onClick ? onClick() : link && OpenLink()); }, sx: sx.wrapper, children: label }));
};
