import { ERecurrenceType, ETaskMemberType } from "../types/task.types";
import { iHaveOneOfRoles } from "../utils/roles.utils";
export var getCanEditTask = function (task) {
    if (!task) {
        return false;
    }
    var hasCorrectRole = iHaveOneOfRoles(task, [
        ETaskMemberType.Admin,
        ETaskMemberType.Executor,
    ]);
    return hasCorrectRole && !task.finishedAt;
};
export var getIsTaskAdmin = function (task) {
    if (!task) {
        return false;
    }
    return task.roles.includes(ETaskMemberType.Admin);
};
export var getIsLastTask = function (task) {
    if (!task) {
        return false;
    }
    if (task.recurrenceType === ERecurrenceType.Once) {
        return true;
    }
    return task.recurrenceInfo && task.recurrenceInfo.lastTaskId === task.id;
};
