import { IPaymentRequest } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";

export interface IContainerProps {
  paymentRequestId: number;
  onDrawerClose: () => void;
}

export interface IPaymentRequestProps extends IContainerProps {
  paymentRequest: IPaymentRequest;
}

export enum EPaymentRequestTabs {
  Info,
  History,
}
