var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import merge from "lodash/merge";
import { animationEasing, animationSpeed, baseUnit, borderRadius, iconSize, spacing, } from "./utils";
var rentioColors = {
    transparent: "transparent",
    neutral0: "#FFFFFF",
    neutral10: "#F7F7F7",
    neutral20: "#EEEEEE",
    neutral30: "#E2E2E2",
    neutral40: "#D0D0D0",
    neutral50: "#B9B9B9",
    neutral60: "#989898",
    neutral70: "#7D7D7D",
    neutral80: "#5F5F5F",
    neutral90: "#494949",
    neutral100: "#2C2C2C",
    green10: "#F1F9F6",
    green30: "#C2E6DC",
    green40: "#7CCAB5",
    green50: "#4AB89A",
    green60: "#17A680",
    green70: "#0F8F6E",
    green80: "#0F6C53",
    green90: "#0D5441",
    green100: "#0B3228",
    primary: {
        // https://hihayk.github.io/scale/#1/4/20/85/0/0/-10/10/18AF87/24/175/135
        1000: "#0D8B69",
        800: "#17A680",
        600: "#4AB89A",
        400: "#7CCAB5",
        200: "#ADDDD0",
        0: "#F1F9F6",
    },
    gray: {
        1000: "#303332",
        800: "#585A59",
        600: "#7F8281",
        400: "#A7A9A8",
        200: "#CFD0D0",
        0: "#F7F7F7",
    },
    white: {
        800: "#fff",
    },
    green: {
        // https://hihayk.github.io/scale/#1/4/20/85/0/0/-10/10/18AF87/24/175/135
        1000: "#0D8B69",
        800: "#17A680",
        600: "#4AB89A",
        400: "#7CCAB5",
        200: "#ADDDD0",
        0: "#F1F9F6",
    },
    red: {
        // https://hihayk.github.io/scale/#1/4/25/93/5/-10/0/50/ef3d59/239/61/89
        1000: "#BF0C3D",
        800: "#EF3D59",
        600: "#F36A7A",
        400: "#F6979E",
        200: "#FAC4C6",
        0: "#FEF2F1",
    },
    yellow: {
        // https://hihayk.github.io/scale/#1/4/10/90/5/-24/-15/50/FFAB00/45/156/219
        1000: "#E69100",
        800: "#FFAB00",
        600: "#FBD03D",
        400: "#FAE978",
        200: "#FAF8B1",
        0: "#FCFDE7",
    },
    orange: {
        // https://hihayk.github.io/scale/#2/6/25/80/8/-33/-7/47/F16305/241/99/23/white
        1000: "#FF8C00",
        800: "#FFAF3B",
        600: "#FFA500",
        400: "#FFC23B",
        200: "#FFCD77",
        0: "#FFE6B2",
    },
    blue: {
        // https://hihayk.github.io/scale/#1/4/50/93/0/10/-15/30/2D9CDB/45/156/219
        1000: "#09527B",
        800: "#2D9CDB",
        600: "#60B8E1",
        400: "#92D0E8",
        200: "#C2E6F1",
        0: "#F1FAFC",
    },
    purple: {
        1000: "#4A2173",
        800: "#4B1243",
        600: "#541758",
        400: "#4A2173",
        200: "#553D87",
        0: "#777BAE",
    },
};
var getColor = 
// @ts-ignore
function (colors) {
    return function (hue, value) {
        if (value === void 0) { value = "800"; }
        return colors[hue][value];
    };
};
var inputStyles = function (getColor) { return ({
    borderRadius: borderRadius(),
    backgroundColor: {
        base: getColor("white"),
        focus: getColor("white"),
        error: getColor("red", "0"),
    },
    borderColor: {
        base: getColor("gray", "400"),
        focus: getColor("gray", "800"),
        hover: getColor("gray", "800"),
        error: getColor("red"),
    },
    boxShadowColor: {
        focus: getColor("primary", "400"),
        error: getColor("red", "400"),
    },
    spacing: {
        vertical: "".concat(baseUnit * 3, "px"),
        horizontal: spacing(),
    },
    maxWidth: "".concat(baseUnit * 160, "px"),
}); };
export var defaultTheme = {
    // @ts-ignore
    colors: rentioColors,
    getColor: getColor(rentioColors),
    baseFontSize: 10,
    bodyFontSize: 14,
    baseUnit: baseUnit,
    spacing: spacing,
    iconSize: iconSize,
    animationEasing: animationEasing,
    animationSpeed: animationSpeed,
    borderRadius: borderRadius,
    inputStyles: inputStyles(getColor(rentioColors)),
    space: [0, 4, 8, 16, 24, 32, 40, 64],
    fontSizes: [12, 14, 16, 18, 22, 28, 34, 48, 64],
    header: {
        height: "56px",
    },
};
var insuranceColors = __assign(__assign({}, rentioColors), { primary: {
        // https://hihayk.github.io/scale/#1/4/50/93/0/10/-15/30/2D9CDB/45/156/219
        1000: "#09527B",
        800: "#2D9CDB",
        600: "#60B8E1",
        400: "#92D0E8",
        200: "#C2E6F1",
        0: "#F1FAFC",
    } });
export var insuranceTheme = merge({}, defaultTheme, {
    colors: insuranceColors,
    getColor: getColor(insuranceColors),
    inputStyles: inputStyles(getColor(insuranceColors)),
});
