import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { compareAsc } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import en from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";
import nl from "date-fns/locale/nl";
import React from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { StyledBackdrop, StyledFloatIcon, StyledTextField, } from "../../../../components/TextField/TextField.styled";
import { ts } from "../../../../services";
import { format } from "../../../../utils/date";
import { Button } from "../../../Button";
import { StyledHelpText } from "../../../Form/Form.styled";
import { ESpacings } from "../../../Grid";
import { Icon } from "../../../Icon";
import { Labelled } from "../../../Labelled";
import { Select } from "../../../Select";
import { Stack } from "../../../Stack";
import * as S from "./DateField.styled";
import { defineStartYear, defineYearAmount } from "./DateField.util";
registerLocale(ELocale.NL, nl);
registerLocale(ELocale.FR, fr);
registerLocale(ELocale.EN, en);
export var DateFieldInternal = function (props) {
    var MONTH_NAMES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (month) {
        return format(new Date("1/1/2000").setMonth(month), "MMMM");
    });
    var value = props.value, _a = props.showTimePicker, showTimePicker = _a === void 0 ? false : _a, _b = props.optional, optional = _b === void 0 ? false : _b, placeholder = props.placeholder, minDate = props.minDate, maxDate = props.maxDate, _c = props.dateFormat, dateFormat = _c === void 0 ? props.showTimePicker ? "dd/MM/yyyy HH:mm" : "dd/MM/yyyy" : _c, error = props.error, _d = props.errorMessageHidden, errorMessageHidden = _d === void 0 ? false : _d, title = props.title, info = props.info, isDisabled = props.isDisabled, onChange = props.onChange, onFocus = props.onFocus, _e = props.inverseYears, inverseYears = _e === void 0 ? false : _e, _f = props.showMonthYearPicker, showMonthYearPicker = _f === void 0 ? false : _f, onPressExtraInfo = props.onPressExtraInfo, _g = props.hideIcon, hideIcon = _g === void 0 ? false : _g;
    var pickerRef = React.useRef();
    var handleChange = function (input) {
        var compareMax = maxDate ? compareAsc(maxDate, input) : null;
        var compareMin = minDate ? compareAsc(minDate, input) : null;
        var isBeforeMin = compareMin ? compareMin > 0 : null;
        var isAfterMax = compareMax ? compareMax < 0 : null;
        // eslint-disable-next-line no-nested-ternary
        var validatedInput = isBeforeMin ? minDate : isAfterMax ? maxDate : input;
        if (!validatedInput) {
            return;
        }
        onChange(validatedInput);
    };
    var yearAmount = defineYearAmount({ minDate: minDate, maxDate: maxDate });
    var yearStart = defineStartYear({ minDate: minDate, maxDate: maxDate });
    var yearsArray = Array(yearAmount)
        .fill(0)
        .map(function (_, idx) { return yearStart + idx; });
    if (inverseYears) {
        yearsArray.reverse();
    }
    var renderHeader = function (_a) {
        var date = _a.date, changeYear = _a.changeYear, changeMonth = _a.changeMonth, decreaseMonth = _a.decreaseMonth, increaseMonth = _a.increaseMonth, decreaseYear = _a.decreaseYear, increaseYear = _a.increaseYear, prevMonthButtonDisabled = _a.prevMonthButtonDisabled, nextMonthButtonDisabled = _a.nextMonthButtonDisabled, prevYearButtonDisabled = _a.prevYearButtonDisabled, nextYearButtonDisabled = _a.nextYearButtonDisabled;
        var handleMonthChange = function (_a) {
            var value = _a.target.value;
            changeMonth(value);
        };
        var handleYearChange = function (_a) {
            var value = _a.target.value;
            changeYear(value);
        };
        return (_jsxs("div", { children: [!prevMonthButtonDisabled && !showMonthYearPicker && (_jsx(S.PreviousMonth, { appearance: "plain", onClick: decreaseMonth, children: _jsx(Icon, { source: "chevronLeft" }) })), !nextMonthButtonDisabled && !showMonthYearPicker && (_jsx(S.NextMonth, { appearance: "plain", onClick: increaseMonth, children: _jsx(Icon, { source: "chevronRight" }) })), !prevMonthButtonDisabled && showMonthYearPicker && (_jsx(S.PreviousMonth, { appearance: "plain", onClick: decreaseYear, children: _jsx(Icon, { source: "chevronLeft" }) })), !nextMonthButtonDisabled && showMonthYearPicker && (_jsx(S.NextMonth, { appearance: "plain", onClick: increaseYear, children: _jsx(Icon, { source: "chevronRight" }) })), _jsx(S.CurrentMonth, { children: showMonthYearPicker
                        ? format(date, "yyyy")
                        : format(date, "MMMM yyyy") }), !showMonthYearPicker && (_jsxs(S.SelectMonthYear, { children: [_jsx("select", { value: date.getMonth(), onChange: handleMonthChange, children: MONTH_NAMES.map(function (label, value) { return (_jsx("option", { value: value, children: label }, value)); }) }), _jsx("select", { value: date.getFullYear(), onChange: handleYearChange, children: yearsArray.map(function (year, index) { return (_jsx("option", { value: year, children: year }, index)); }) })] }))] }));
    };
    var handleClear = function () {
        onChange(undefined);
    };
    var handleIconClick = function () {
        if (pickerRef.current) {
            // @ts-ignore
            pickerRef.current.setOpen(true);
        }
    };
    var handleMonthChange = function (month) {
        var newDate = value || new Date();
        newDate.setMonth(month.value);
        handleChange(newDate);
    };
    var handleYearChange = function (year) {
        var newDate = value || new Date();
        newDate.setFullYear(year.value);
        handleChange(newDate);
    };
    var fieldMarkup = showMonthYearPicker ? (_jsxs(Stack, { children: [_jsx(Box, { width: 200, children: _jsx(Select, { label: ts.system("month"), labelHidden: true, id: "month-picker", onChange: handleMonthChange, value: value ? value.getMonth() : undefined, error: error, options: MONTH_NAMES.map(function (label, value) { return ({
                        value: value,
                        label: label,
                    }); }), disabled: isDisabled }) }), _jsx(Box, { width: 100, children: _jsx(Select, { label: ts.system("year"), labelHidden: true, id: "month-picker", onChange: handleYearChange, value: value ? value.getFullYear() : undefined, options: yearsArray.map(function (year) { return ({
                        value: year,
                        label: String(year),
                    }); }), disabled: isDisabled }) })] })) : (_jsxs(_Fragment, { children: [_jsx(S.DatePickerContainer, { children: _jsxs(StyledTextField, { hasError: !!error, children: [_jsx(StyledBackdrop, {}), _jsx(DatePicker, { ref: pickerRef, placeholderText: placeholder || ts.datePlaceholder(dateFormat), id: typeof title === "string" ? title : "", locale: getLocale(), onFocus: onFocus, selected: value, minDate: minDate, maxDate: maxDate, onChange: handleChange, showMonthDropdown: true, showYearDropdown: true, dateFormat: dateFormat, dropdownMode: "select", showTimeSelect: !!showTimePicker, renderCustomHeader: renderHeader, showMonthYearDropdown: true, autoComplete: "off", disabled: isDisabled, popperPlacement: "top-end", popperModifiers: [
                                {
                                    name: "offset",
                                    options: {
                                        offset: [5, 10],
                                    },
                                },
                                {
                                    name: "preventOverflow",
                                    options: {
                                        rootBoundary: "viewport",
                                        tether: false,
                                        altAxis: true,
                                    },
                                },
                            ] }), !isDisabled && value && optional && (_jsx(Box, { pt: ESpacings.extraTight, pr: ESpacings.tight, children: _jsx(Button, { onClick: handleClear, appearance: "plain", children: _jsx(Icon, { source: "removeCircle" }) }) })), !hideIcon && (_jsx(StyledFloatIcon, { onClick: handleIconClick, children: _jsx(Icon, { source: "calendar" }) }))] }) }), info && _jsx(StyledHelpText, { children: info })] }));
    return (_jsx(_Fragment, { children: title ? (_jsx(Labelled, { label: title, error: errorMessageHidden ? undefined : error, optional: optional, onPressExtraInfo: onPressExtraInfo, children: fieldMarkup })) : (_jsx(_Fragment, { children: fieldMarkup })) }));
};
