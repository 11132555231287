import { Box } from "@rebass/grid";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { EContractFetchType } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  EPropertyMemberTypes,
  TPropertyMember,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  Can,
  DisplayText,
  ESpacings,
  Error,
  Grid,
  Loading,
} from "@rentiohq/web-shared/dist/components";
import isEmpty from "ramda/es/isEmpty";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { rs } from "../../../../services";
import * as t from "../../../../services/translationService";
import NoContractCard from "../Detail/components/NoContractCard";
import { PropertyOwnersCard } from "../Detail/components/PropertyOwnersCard";
import { PropertyRolesModal } from "../Detail/components/PropertyRolesModal";
import { ContractCard } from "./components/ContractCard/ContractCard";

export const ContractsOverview = () => {
  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const [showEndedContracts, setShowEndedContracts] = useState(false);

  const property = useSelector((state: IRootStore) =>
    propertyId ? propertySelectors.getDetail(state, propertyId) : undefined,
  );

  const activeContractsForPropertyFilter = propertyId
    ? contractUtils.getFilterForProperty(propertyId, EContractFetchType.Active)
    : undefined;
  const endedContractsForPropertyFilter = propertyId
    ? contractUtils.getFilterForProperty(propertyId, EContractFetchType.Ended)
    : undefined;

  const {
    items: activeContracts = [],
    isFetching: isFetchingActiveContracts,
    fetchError: activeContractsFetchError,
    refetch: refetchActive,
  } = contractHooks.useGetAll({
    query: {
      filter: activeContractsForPropertyFilter,
      sort: [{ field: "startDate", method: "DESC" }],
    },
  });

  const {
    count: endedContractsCount,
    isFetching: isFetchingEndedContractsCount,
  } = contractHooks.useCount({
    query: {
      filter: endedContractsForPropertyFilter,
    },
    shouldRefetch: true,
  });

  const {
    items: endedContracts,
    isFetching: isFetchingEndedContracts,
    fetchError: endedContractsFetchError,
    refetch: refetchEnding,
  } = contractHooks.useGetAll({
    query: showEndedContracts
      ? {
          filter: endedContractsForPropertyFilter,
          sort: [{ field: "startDate", method: "DESC" }],
        }
      : undefined,
  });

  const refetchAllContractForProperty = () => {
    refetchEnding();
    refetchActive();
  };

  const navigate = useNavigate();
  const [showInitialPropertyRolesModal, setShowInitialPropertyRolesModal] =
    useState(false);

  if (!property) {
    return null;
  }

  const { members = [] } = property;

  const propertyOwners = members.filter(
    (member: TPropertyMember) =>
      member.roles?.includes(EPropertyMemberTypes.Owner),
  );
  const hasPropertyOwners = propertyOwners.length > 0;

  if (!hasPropertyOwners) {
    return (
      <PropertyOwnersCard
        heading={t.propertyAddOwnerTitle()}
        property={property}
        owners={propertyOwners}
        onSelectOwner={() => {
          setShowInitialPropertyRolesModal(true);
        }}
      >
        {t.contractAddOwnerBody()}
      </PropertyOwnersCard>
    );
  }

  if (propertyId && showInitialPropertyRolesModal) {
    return (
      <PropertyRolesModal
        propertyId={+propertyId}
        onClose={() => {
          setShowInitialPropertyRolesModal(false);
        }}
      />
    );
  }

  // let contractCardActions: IAction[] = [];

  /*   if (propertyId) {
    contractCardActions.push({
      content: t.contractsOverviewCreateBasicContractInfoLabel(),
      permission: "propertyContractBasicInfo:create",
      url: rs.createBasicContractInfoRoute(propertyId),
    });
  } */

  // {
  //   content: t.contractsOverviewCreateRentalAgreementLabel(),
  //   permission: 'propertyContractRentalAgreement:create',
  //   url: `/properties/${propertyId}/rental-agreement`,
  // },

  const renderActiveContracts = () => {
    if (isFetchingActiveContracts && isEmpty(activeContracts)) {
      return (
        <Box mb={ESpacings.base}>
          <Loading />
        </Box>
      );
    }

    if (!isFetchingActiveContracts && activeContractsFetchError) {
      return (
        <Box mb={ESpacings.base}>
          <Error errors={[activeContractsFetchError]} onRetry={refetchActive} />
        </Box>
      );
    }

    if (isEmpty(activeContracts)) {
      return <NoContractCard property={property} propertyId={property.id} />;
    }

    return (
      <>
        <Grid
          alignItems="center"
          justifyContent="space-between"
          mb={ESpacings.loose}
        >
          <Grid.Item>
            <DisplayText>{t.contractsOverviewCurrentContracts()}</DisplayText>
          </Grid.Item>
          <Grid.Item>
            <Button
              appearance="primary"
              onClick={() =>
                navigate(rs.createBasicContractInfoRoute(propertyId!))
              }
            >
              {t.contractsOverviewCreateRentalAgreementLabel()}
            </Button>
          </Grid.Item>
        </Grid>

        {activeContracts.map((contract, index) => (
          <ContractCard
            key={contract.id}
            contract={contract}
            property={property}
            showChecklistInitialState={index === 0}
            onCancelContract={refetchAllContractForProperty}
          />
        ))}
      </>
    );
  };

  const renderEndedContracts = () => {
    // Only show one spinner, don't show this spinner when active contracts are being fetched
    if (
      !endedContractsCount &&
      isFetchingEndedContractsCount &&
      !isFetchingActiveContracts
    ) {
      return (
        <Box my={ESpacings.extraLoose}>
          <Loading />
        </Box>
      );
    }

    if (!endedContractsCount || endedContractsCount === 0) {
      return null;
    }

    if (isFetchingEndedContracts && !endedContracts) {
      return (
        <Box my={ESpacings.extraLoose}>
          <DisplayText>{t.contractsOverviewFinishedContracts()}</DisplayText>
          <Loading />
        </Box>
      );
    }

    if (!isFetchingEndedContracts && endedContractsFetchError) {
      return (
        <Box my={ESpacings.extraLoose}>
          <DisplayText>{t.contractsOverviewFinishedContracts()}</DisplayText>
          <Error errors={[endedContractsFetchError]} onRetry={refetchEnding} />
        </Box>
      );
    }

    return (
      <Box my={ESpacings.extraLoose}>
        <DisplayText>{t.contractsOverviewFinishedContracts()}</DisplayText>

        {showEndedContracts &&
          endedContracts?.map((contract: IContract) => (
            <ContractCard
              key={contract.id}
              contract={contract}
              property={property}
              showChecklistInitialState={false}
              onCancelContract={refetchAllContractForProperty}
            />
          ))}

        <Box mt={ESpacings.loose}>
          <Button
            appearance="link"
            onClick={() => {
              setShowEndedContracts(!showEndedContracts);
            }}
          >
            {getLocalizedText(
              showEndedContracts
                ? "contracts.overview.hide_finished.action"
                : "contracts.overview.show_finished.action",
              {
                value: `${endedContractsCount}`,
              },
            )}
          </Button>
        </Box>
      </Box>
    );
  };

  const renderContent = () => (
    <>
      {renderActiveContracts()}
      {renderEndedContracts()}
    </>
  );

  return (
    <Can
      role={property.roles}
      perform="propertyContracts:visit"
      yes={renderContent}
      no={() => <Navigate to={`/properties/${propertyId}`} />}
    />
  );
};
