import { detailGenerator } from "../generic/detail.hooks";
import { pagedGenerator } from "../generic/paged.hooks";
import { ENTITY_NAME_TEMPLATE, REDUCER_KEY_TEMPLATE, RESOURCE_NAME_TEMPLATE, } from "./template.reducer";
export var useDetail = detailGenerator({
    entityName: ENTITY_NAME_TEMPLATE,
    getBaseState: function (state) { return state[REDUCER_KEY_TEMPLATE]; },
});
export var usePaged = pagedGenerator({
    entityName: ENTITY_NAME_TEMPLATE,
    resource: RESOURCE_NAME_TEMPLATE,
    getBaseState: function (state) { return state[REDUCER_KEY_TEMPLATE]; },
});
