import { EPaymentOrderType } from "../../types/payment.types";
import { formatDate } from "../../utils/date.utils";
// TODO: Remove of move to string utils
var escapeLetter = function (input, letter) {
    return input
        .split("")
        .map(function (input) {
        if ([letter.toLowerCase(), letter.toUpperCase()].includes(letter)) {
            return "\\".concat(input);
        }
        return input;
    })
        .join("");
};
// const escapeLetters = (input: string, letters: string[]) =>
//   letters.map(letter => escapeLetter(input, letter));
export var getReference = function (params) {
    var asMask = params.asMask, reference = params.reference, property = params.property, paymentOrderType = params.paymentOrderType, _a = params.startDate, startDate = _a === void 0 ? new Date() : _a, otherDescription = params.otherDescription;
    var period = formatDate(startDate, "MMMyy").toUpperCase().replace(".", "");
    var address = (function () {
        var _a = property.number, propertyNumber = _a === void 0 ? "" : _a, box = property.box, _b = property.street, street = _b === void 0 ? "" : _b, _c = property.city, city = _c === void 0 ? "" : _c;
        return "".concat(propertyNumber).concat(box ? "/".concat(box) : "", " ").concat(street, " ").concat(city);
    })();
    var refMaskStart = [];
    var refMaskEnd = [];
    switch (paymentOrderType) {
        case EPaymentOrderType.RentAndCosts:
        case EPaymentOrderType.Rent:
            refMaskStart.push("HR");
            refMaskStart.push(period);
            break;
        case EPaymentOrderType.CommonCosts:
            refMaskStart.push("GK");
            refMaskStart.push(period);
            break;
        case EPaymentOrderType.NonCommonCosts:
            refMaskStart.push("AK");
            refMaskStart.push(period);
            break;
        case EPaymentOrderType.ManagementCosts:
            refMaskStart.push("BK");
            refMaskStart.push(period);
            break;
        case EPaymentOrderType.LocationDescription:
            refMaskStart.push("PB");
            break;
        case EPaymentOrderType.BrokerFee:
            refMaskStart.push("COM");
            break;
        case EPaymentOrderType.OtherRecurring:
        case EPaymentOrderType.OtherOneoff:
            if (otherDescription) {
                refMaskEnd.push(otherDescription);
                break;
            }
            refMaskEnd.push("OT");
            break;
        case EPaymentOrderType.Rentio:
        default:
            break;
    }
    // Always add address
    refMaskEnd.push(address);
    // Add spaces
    if (refMaskStart.length > 0) {
        refMaskStart.push(" ");
    }
    if (refMaskEnd.length > 0) {
        refMaskEnd.unshift("");
    }
    var refMaskStartString = refMaskStart.join("").toUpperCase();
    var refMaskEndString = refMaskEnd.join(" ").toUpperCase();
    // Compose reference as string
    if (!asMask) {
        if (reference) {
            return "".concat(refMaskStartString).concat(reference).concat(refMaskEndString);
        }
        return "".concat(refMaskStartString, " ").concat(refMaskEndString);
    }
    // Compose reference as mask
    var referenceMask = Array(10).fill("*").join("");
    return "".concat(escapeLetter(refMaskStartString, "0")).concat(referenceMask).concat(escapeLetter(refMaskEndString, "0"));
};
