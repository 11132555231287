import createPaymentSchemas from "@rentiohq/shared-frontend/dist/forms/createPayment";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/createPayment/schema.createPayment.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IGeneralLedgerAccount } from "@rentiohq/shared-frontend/dist/reduxV2/generalLedgerAccount/generalLedgerAccount.types";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IPaymentOrder } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  Card,
  MultiStepForm as Form,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { useNavigate } from "react-router-dom";

const formId = generateFormId();

interface IProps {
  property: IProperty;
  activeContracts?: IContract[];
  endedContracts?: IContract[];
  generalLedgerAccounts?: IGeneralLedgerAccount[];
  paymentOrder?: IPaymentOrder;
  paymentOrders?: IPaymentOrder[];
  hasOpenPaymentRequests?: boolean;
  shouldUpdateOpenPaymentRequests?: boolean;
}

export const PaymentForm: React.FC<IProps> = ({
  property,
  paymentOrder,
  hasOpenPaymentRequests,
  shouldUpdateOpenPaymentRequests,
  activeContracts,
  endedContracts,
  generalLedgerAccounts,
  paymentOrders,
}) => {
  const navigate = useNavigate();

  const { isBroker } = authHooks.useSelf();

  const hasIbanisationForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Ibanisation,
  );
  const hasBookkeepingForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Bookkeeping,
  );
  const hasPaymentV2ForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.PaymentV2,
  );

  const { internalModeEnabled } = useInternalMode();

  const handleSubmit = () => {
    navigate(
      internalModeEnabled && hasPaymentV2ForBroker
        ? `/properties/${property.id}/payments-v2`
        : `/properties/${property.id}/payments`,
    );
  };

  const extraData = {
    activeContracts,
    endedContracts,
    generalLedgerAccounts,
    paymentOrders,
    paymentOrder,
    isBroker,
    property,
    hasOpenPaymentRequests,
    shouldUpdateOpenPaymentRequests,
    ibanisationEnabled: hasIbanisationForBroker,
    bookkeepingEnabled: hasBookkeepingForBroker,
    internalModeEnabled,
  };

  return (
    <Card>
      <Form
        formId={`payment-form-${formId}`}
        schemas={createPaymentSchemas(extraData)}
        withSummary={true}
        activeStep={paymentOrder ? "summary" : undefined}
        isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
          paymentOrder,
          hasPaymentV2ForBroker && internalModeEnabled,
        )}
        performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
          paymentOrder,
          hasPaymentV2ForBroker && internalModeEnabled,
        )}
        submitActionCreator={(formData: any) => {
          const { id: propertyId } = property;
          const { id: paymentOrderId = undefined } = paymentOrder || {};

          return submitActionCreator(
            extraData,
            propertyId,
            paymentOrderId,
            paymentOrder,
            hasPaymentV2ForBroker && internalModeEnabled,
          )(formData);
        }}
        onSubmit={handleSubmit}
      />
    </Card>
  );
};
