import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.partnerImportWhise.types";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [EField.WhiseId],
      properties: {
        // [EField.Info]: {
        //   default: undefined,
        // },

        [EField.WhiseId]: {
          default: getValue(EField.WhiseId, initialValues, defaultValues),
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData): IUiSchema<EField> => ({
  // [EField.Info]: {
  //   "ui:field": ERentioFormField.HelpInfo,
  //   title: getLocalizedText("partner_import.whise.form.help.title"),
  //   subtitle: getLocalizedText("partner_import.whise.form.help.content"),
  // },

  [EField.WhiseId]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("partner_import.whise.form.whise_id.title"),
    // extraInfoUrl: "partner_import.whise.form.whise_id.url",
  },
});
