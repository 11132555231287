import { jsx as _jsx } from "react/jsx-runtime";
import Truncate from "react-truncate";
import { Tooltip } from "../Tooltip";
export var TruncateTooltip = function (props) {
    var width = props.width, fontSize = props.fontSize, children = props.children;
    if (!children) {
        return null;
    }
    return (_jsx(Tooltip, { tooltipContent: children, children: _jsx(Truncate, { width: width, trimWhitespace: true, style: { width: width, fontSize: fontSize }, children: children }) }));
};
