var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import React from "react";
import { FormPropertySelect } from "../FormPropertySelect";
var FormPropertySelectJsonFormSchema = /** @class */ (function (_super) {
    __extends(FormPropertySelectJsonFormSchema, _super);
    function FormPropertySelectJsonFormSchema() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        // Event handlers
        _this.handlePressExtraInfo = function () {
            var _a = _this.props, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, uiSchema = _a.uiSchema;
            if (formContext.onPressExtraInfo) {
                formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
            }
        };
        _this.handleDeselectProperty = function (selectedPropertyId) {
            if (selectedPropertyId) {
                _this.updateFormData(selectedPropertyId);
            }
            else {
                _this.updateFormData();
            }
        };
        _this.handleSelectMoreProperties = function (_) {
            var _a = _this.props, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, name = _a.name, formData = _a.formData;
            if (formContext.onSelectMore) {
                formContext.onSelectMore(name, formData);
            }
        };
        _this.handlePressAddNewContact = function () {
            var _a = _this.props, _b = _a.formContext, formContext = _b === void 0 ? {} : _b, name = _a.name, formData = _a.formData;
            if (formContext.onSelectMore) {
                formContext.onSelectMore(name, formData, { type: "new" });
            }
        };
        // Update
        _this.updateFormData = function (selectedPropertyId) {
            var _a = _this.props, onChange = _a.onChange, _b = _a.formContext, formContext = _b === void 0 ? {} : _b;
            onChange(selectedPropertyId ? selectedPropertyId : null);
            // TODO: Timeout?
            setTimeout(function () {
                if (formContext.onFieldChange) {
                    formContext.onFieldChange(name, undefined);
                }
            }, 0);
        };
        return _this;
    }
    // Render
    FormPropertySelectJsonFormSchema.prototype.render = function () {
        var _a = this.props, uiSchema = _a.uiSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, formData = _a.formData, formContext = _a.formContext;
        var customUiSchema = uiSchema;
        var isDisabled = customUiSchema.isDisabled;
        var disabled = isDisabled
            ? isDisabled(formContext.getAllFormData())
            : undefined;
        return (_jsx(FormPropertySelect, __assign({}, customUiSchema, { 
            // customIsFetchingSelector={customUiSchema.customIsFetchingSelector}
            // customGetPropertyActions={customUiSchema.customGetPropertyActions}
            // customPropertySelector={customUiSchema.customPropertySelector}
            selectedPropertyId: formData, onPressExtraInfo: this.handlePressExtraInfo, 
            // @ts-ignore TODO: Typescript upgrade
            title: getFieldTitle(customUiSchema, formContext), 
            // @ts-ignore TODO: Typescript upgrade
            info: getFieldInfo(customUiSchema, formContext), onSelectProperty: this.handleDeselectProperty, onSelectMoreProperties: this.handleSelectMoreProperties, onPressAddNewContact: this.handlePressAddNewContact, error: rawErrors[0], disabled: disabled })));
    };
    return FormPropertySelectJsonFormSchema;
}(React.PureComponent));
export { FormPropertySelectJsonFormSchema };
