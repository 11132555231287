import { IUser } from "@rentiohq/shared-frontend/dist/types/auth.types";
import {
  EDocumentPackageSignerStatus,
  IDocumentPackage,
  IDocumentPackageSigner,
} from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { matchPath } from "react-router-dom";
import { EPropertyDetailsTabs } from "./propertyDetails.types";

export const getActiveTab = (pathname: string) => {
  const tabsIndexes = {
    [EPropertyDetailsTabs.Info]: matchPath(
      "/properties/:propertyId/",
      pathname,
    ),
    [EPropertyDetailsTabs.Contract]: matchPath(
      "/properties/:propertyId/contracts",
      pathname,
    ),
    [EPropertyDetailsTabs.Documents]: matchPath(
      "/properties/:propertyId/documents",
      pathname,
    ),
    [EPropertyDetailsTabs.History]: matchPath(
      "properties/:propertyId/history",
      pathname,
    ),
  };

  return Object.keys(tabsIndexes).find(tabIndex =>
    // @ts-ignore
    tabsIndexes[tabIndex] ? tabIndex : null,
  );
};

export const getSignableDocumentPackages = (
  documentPackages: IDocumentPackage[] = [],
  user?: IUser,
) => {
  const { accountIds = [], activeAccountId } = user || {};

  return (documentPackages || []).filter(documentPackage =>
    documentPackage.signers
      .filter(
        signer =>
          signer?.connectiveUrl &&
          signer.status !== EDocumentPackageSignerStatus.Finished,
      )
      .map(signer => signer.accountId)
      .some(
        accountId =>
          accountId === activeAccountId || accountIds.includes(accountId),
      ),
  );
};

export const getSignedInSigner = (
  signers: IDocumentPackageSigner[],
  user?: IUser,
) => {
  const { accountIds = [], activeAccountId } = user || {};

  return signers.find(
    signer =>
      signer.accountId === activeAccountId ||
      accountIds.includes(signer.accountId),
  );
};
