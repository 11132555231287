import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { EDisplayType } from "../Editor.types";
// Context
export var DisplayTypeContext = React.createContext({
    displayType: EDisplayType.Template,
    setDisplayType: function (newValue) { },
    isTemplate: true,
    isTemplateDocument: false,
    isTemplateDocumentEditor: false,
    isTemplateDocumentExternal: false,
    isPrint: false,
});
// Provider
export var DisplayTypeProvider = function (props) {
    var children = props.children;
    // State
    var _a = React.useState(props.displayType), displayType = _a[0], setDisplayType = _a[1];
    var isTemplate = displayType === EDisplayType.Template;
    var isTemplateDocument = displayType === EDisplayType.TemplateDocument;
    var isTemplateDocumentEditor = displayType === EDisplayType.TemplateDocumentEditor;
    var isTemplateDocumentExternal = displayType === EDisplayType.TemplateDocumentExternal;
    var isPrint = displayType === EDisplayType.Print;
    // Render
    return (_jsx(DisplayTypeContext.Provider, { value: {
            displayType: displayType,
            setDisplayType: setDisplayType,
            isTemplate: isTemplate,
            isTemplateDocument: isTemplateDocument,
            isTemplateDocumentEditor: isTemplateDocumentEditor,
            isTemplateDocumentExternal: isTemplateDocumentExternal,
            isPrint: isPrint,
        }, children: children }));
};
