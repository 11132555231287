import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";

export interface IExtraData {
  files?: File[];
  document?: IDocument;
}

export enum EField {
  Name = "filename",
  Category = "categoryId",
}
