import { jsx as _jsx } from "react/jsx-runtime";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authActions from "@rentiohq/shared-frontend/dist/redux/auth/auth.actions";
import * as authSelectors from "@rentiohq/shared-frontend/dist/redux/auth/auth.selectors";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StringParam } from "serialize-query-params";
import { Loading } from "../../../../components";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";
import TemplateDocumentEditor from "../../TemplateDocumentEditor";
import { EExternalViewType } from "./TemplateDocumentExternal.types";
var DASHBOARD_MIN_WIDTH = 1100;
export var TemplateDocumentExternal = function () {
    // Router
    var query = useQueryParams({
        token: StringParam,
        templateDocumentId: StringParam,
    })[0];
    var token = query.token, templateDocumentId = query.templateDocumentId;
    var width = useWindowDimension()[0];
    // State
    var _a = React.useState(width > DASHBOARD_MIN_WIDTH
        ? EExternalViewType.Dashboard
        : EExternalViewType.MobileApp), externalViewType = _a[0], setExternalViewType = _a[1];
    // Redux
    var dispatch = useDispatch();
    var hasAccessToken = useSelector(authSelectors.hasAccessToken);
    // Lifecycle
    React.useEffect(function () {
        if (width > DASHBOARD_MIN_WIDTH) {
            setExternalViewType(EExternalViewType.Dashboard);
        }
        else {
            setExternalViewType(EExternalViewType.MobileApp);
        }
    }, [width]);
    React.useEffect(function () {
        if (!token) {
            return;
        }
        dispatch(authActions.setAccessTokensWithoutSideEffects({
            accessToken: token,
        }));
    }, []);
    React.useEffect(function () {
        if (!hasAccessToken) {
            return;
        }
    }, [hasAccessToken]);
    // Render
    if (!templateDocumentId) {
        return null;
    }
    if (!hasAccessToken) {
        return _jsx(Loading, {});
    }
    return (_jsx(TemplateDocumentEditor, { templateDocumentId: templateDocumentId, sendToTenant: true, externalViewType: externalViewType }));
};
