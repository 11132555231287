var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled, { spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
import { Button } from "../Button";
export var VariationToColor = {
    default: "blue",
    info: "blue",
    warning: "yellow",
    error: "red",
    success: "green",
};
export var StyledBanner = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  margin-top: ", ";\n  margin-bottom: ", ";\n  padding: ", ";\n  color: ", ";\n  ", "\n  ", "\n  &:first-child {\n    margin-top: 0;\n  }\n  &:last-child {\n    margin-bottom: 0;\n  }\n"], ["\n  position: relative;\n  display: flex;\n  margin-top: ", ";\n  margin-bottom: ", ";\n  padding: ", ";\n  color: ", ";\n  ", "\n  ", "\n  &:first-child {\n    margin-top: 0;\n  }\n  &:last-child {\n    margin-bottom: 0;\n  }\n"])), spacing(), spacing(), spacing(), function (props) { return props.theme.colors.neutral90; }, function (_a) {
    var hasDismiss = _a.hasDismiss, theme = _a.theme;
    return hasDismiss && "padding-right: ".concat(rem("40px", theme.baseFontSize), ";");
}, function (_a) {
    var variation = _a.variation, theme = _a.theme;
    return "\n    background-color: ".concat(theme.getColor(VariationToColor[variation], 0), ";\n    border-left: 3px solid ").concat(theme.getColor(VariationToColor[variation]), ";\n  ");
});
export var StyledBannerDismiss = styled(function (props) { return (_jsx(Button, __assign({}, props))); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  right: ", ";\n  top: ", ";\n"], ["\n  position: absolute;\n  right: ", ";\n  top: ", ";\n"])), spacing(), spacing());
export var StyledBannerContentWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject([""], [""])));
export var StyledBannerIconWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 0 0 ", ";\n  ~ ", " {\n    padding-top: ", ";\n  }\n"], ["\n  flex: 0 0 ", ";\n  ~ ", " {\n    padding-top: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return rem("40px", theme.baseFontSize);
}, StyledBannerContentWrapper, function (_a) {
    var theme = _a.theme;
    return rem(2, theme.baseFontSize);
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
