import { MenuOrientation, ThemeDirection, ThemeMode, Themes, } from "./utils/types/config";
export var WELCOME_PATH = "/";
export var HORIZONTAL_MAX_ITEM = 7;
var config = {
    menuOrientation: MenuOrientation.VERTICAL,
    miniDrawer: false,
    fontFamily: "'Roboto', sans-serif",
    borderRadius: 8,
    outlinedFilled: true,
    mode: ThemeMode.LIGHT,
    presetColor: Themes.Theme1,
    i18n: "en",
    themeDirection: ThemeDirection.LTR,
    container: false,
};
// eslint-disable-next-line import/no-default-export
export default config;
