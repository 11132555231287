import { isEqual, isFunction, omitBy } from "lodash";
import clone from "lodash/clone";
import React from "react";
var isValidElement = React.isValidElement, Children = React.Children;
var isComponent = function (AComponent, AnotherComponent) { return AComponent === AnotherComponent; };
export var isElementOfType = function (element, Component) {
    if (element == null ||
        !isValidElement(element) ||
        typeof element.type === "string") {
        return false;
    }
    var type = element.type;
    var Components = Array.isArray(Component) ? Component : [Component];
    return Components.some(function (AComponent) { return typeof type !== "string" && isComponent(AComponent, type); });
};
export var wrapWithComponent = function (element, Component, props) {
    return isElementOfType(element, Component)
        ? element
        : React.createElement(Component, clone(props), element);
};
export var elementChildren = function (children) {
    return Children.toArray(children).filter(function (child) { return isValidElement(child); });
};
export var arePropsEqual = function (prevProps, nextProps) {
    var oldProps = omitBy(prevProps, isFunction);
    var newProps = omitBy(nextProps, isFunction);
    var checkProps = isEqual(oldProps, newProps);
    // utils.debug.log({ oldProps, newProps, checkProps })
    return checkProps;
};
