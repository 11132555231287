import { ITemplateDocument } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";
import { EDocumentPackageTypes } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";

export interface IExtraData {
  document?: IDocument;
  property?: IProperty;
  contract?: IContract;
  templateDocument?: ITemplateDocument;
  documentPackageType?: EDocumentPackageTypes;

  contactMeMaster?: IContact;
  legalContact?: IContact;
  isBroker?: boolean;
  signerFields?: { [accountId: number]: string };
  sendToTenant?: boolean;
}

export enum EDocumentSigningTypes {
  ID = "id",
  OTP = "otp",
  // TMP Disable on screen signature
  // Signature = 'signature',
}

export enum EField {
  Header = "_header",
  Subtitle = "_subtitle",
  Name = "name",
  Signers = "signers",
  Documents = "documents",
  Contract = "contract",
  Property = "property",
  DocumentPackageType = "documentPackageType",
  SigningTypes = "signingTypes",
  SigningTypesWarning = "signingTypesWarning",
  LegalContactInfo = "legalContactInfo",
  SignAsBroker = "signAsBroker",
}
