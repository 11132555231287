var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as templateHooks from "@rentiohq/shared-frontend/dist/redux/template/template.hooks";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import * as templateDocumentHooks from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.hooks";
import * as templateDocumentSelectors from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.selectors";
import { ESendType, } from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import { EDocumentPackageTypes } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { appendQueryParams } from "@rentiohq/shared-frontend/dist/utils/url.utils";
import { convertToRaw } from "draft-js";
import uniq from "lodash/uniq";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Error } from "../../components";
import * as systemActions from "../../redux/system/system.actions";
import { EPreferencePersistScope } from "../../redux/system/system.types";
import { redirectPage } from "../../utils/redirect";
import Editor from "../TemplateEditor/components/Editor";
import { EDisplayType, } from "../TemplateEditor/components/Editor/Editor.types";
import { useParsedTemplateDocument } from "./TemplateDocumentEditor.hooks";
import { getSignerFields } from "./TemplateDocumentEditor.utils";
import { EExternalViewType } from "./scenes/TemplateDocumentExternal/TemplateDocumentExternal.types";
var TemplateDocumentEditor = function (props) {
    var sendToTenant = props.sendToTenant, _a = props.externalViewType, externalViewType = _a === void 0 ? EExternalViewType.Dashboard : _a;
    // Router
    var navigate = useNavigate();
    var paramTemplateDocumentId = useParams().templateDocumentId;
    var templateDocumentId = paramTemplateDocumentId || props.templateDocumentId;
    // Redux
    var dispatch = useDispatch();
    var _b = templateDocumentHooks.useDetail({ id: templateDocumentId }), templateDocument = _b.detail, isFetching = _b.isFetching, fetchError = _b.fetchError;
    var template = templateHooks.useDetail({
        id: templateDocument === null || templateDocument === void 0 ? void 0 : templateDocument.templateId,
    }).detail;
    var detail = useSelector(function (state) {
        return templateDocumentSelectors.templateDocumentDetail(state, templateDocumentId);
    });
    var isFetchingDetail = useSelector(function (state) {
        return templateDocumentSelectors.isFetchingTemplateDocumentDetail(state, templateDocumentId);
    });
    var fetchDetailError = useSelector(function (state) {
        return templateDocumentSelectors.templateDocumentDetailFetchError(state, templateDocumentId);
    });
    var isUpdatingDetail = useSelector(function (state) {
        return templateDocumentSelectors.isUpdatingTemplateDocumentDetail(state, templateDocumentId);
    });
    var _c = useParsedTemplateDocument(detail), rawState = _c.rawState, fields = _c.fields, variablesData = _c.variablesData, documentConfig = _c.documentConfig;
    var canEdit = useState(!!(template === null || template === void 0 ? void 0 : template.tenantCanEdit))[0];
    // Lifecycle
    React.useEffect(function () {
        if (!templateDocumentId || isFetchingDetail) {
            return;
        }
        dispatch(templateDocumentActions.getTemplateDocumentDetail.actions.start({
            id: templateDocumentId,
        }));
    }, [templateDocumentId]);
    // Event handlers
    var handlePressSave = function (params) {
        var withGeneratePdf = params.withGeneratePdf, newEditorState = params.editorState, newFields = params.fields, newVariablesData = params.variablesData, newDocumentConfig = params.documentConfig, documentIds = params.documentIds, goBackOnSuccess = params.goBackOnSuccess, _a = params.tenantCanEdit, tenantCanEdit = _a === void 0 ? false : _a, sendForSigning = params.sendForSigning;
        if (!templateDocumentId || !newVariablesData) {
            return;
        }
        // Store values in preferences
        var preferences = {};
        (newFields.variables || []).forEach(function (variable) {
            if (variable.persisted) {
                preferences["template_document:field:".concat(variable.id)] =
                    newVariablesData[variable.id];
            }
        });
        if (Object.keys(preferences).length > 0) {
            dispatch(systemActions.setPreferences({
                persistScope: EPreferencePersistScope.RemoteBroker,
                preferences: preferences,
            }));
        }
        var generateTemplateData = function () {
            if (tenantCanEdit) {
                return {
                    sendType: ESendType.Tenant,
                };
            }
            if (sendForSigning) {
                return {
                    sendType: ESendType.Signing,
                };
            }
            return {};
        };
        // Update, generate PDF & navigate
        dispatch(templateDocumentActions.updateTemplateDocumentDetail.actions.start({
            id: templateDocumentId,
            data: {
                data: JSON.stringify(convertToRaw(newEditorState.getCurrentContent())),
                fields: JSON.stringify(newFields),
                variablesData: JSON.stringify(newVariablesData),
                documentConfig: JSON.stringify(newDocumentConfig),
                documentIds: uniq(__spreadArray(__spreadArray([], ((detail === null || detail === void 0 ? void 0 : detail.documentIds) || []), true), documentIds, true)),
                signerFields: getSignerFields({
                    fields: newFields,
                    variablesData: newVariablesData,
                }),
            },
            suppressSuccessMessage: withGeneratePdf,
            onSuccess: function () {
                if (withGeneratePdf) {
                    dispatch(templateDocumentActions.generateTemplateDocumentPdf.actions.start({
                        id: templateDocumentId,
                        data: generateTemplateData(),
                        suppressSuccessMessage: tenantCanEdit,
                        customSuccessMessage: sendForSigning
                            ? getLocalizedText("editor.template_document.send_for_signing.alert.success")
                            : undefined,
                        onSuccess: function (updatedTemplateDocument) {
                            if (tenantCanEdit) {
                                var documentId = updatedTemplateDocument === null || updatedTemplateDocument === void 0 ? void 0 : updatedTemplateDocument.documentId;
                                dispatch(documentActions.getDetailStart.getAction({
                                    id: documentId,
                                    onSuccess: function () {
                                        navigate(appendQueryParams({
                                            path: "/forms/document-package",
                                            queryParams: {
                                                propertyId: updatedTemplateDocument === null || updatedTemplateDocument === void 0 ? void 0 : updatedTemplateDocument.propertyId,
                                                contractId: (updatedTemplateDocument === null || updatedTemplateDocument === void 0 ? void 0 : updatedTemplateDocument.contractId) ||
                                                    undefined,
                                                documentIdToSign: updatedTemplateDocument === null || updatedTemplateDocument === void 0 ? void 0 : updatedTemplateDocument.documentId,
                                                sendToTenant: 1,
                                                documentPackageType: EDocumentPackageTypes.Contract,
                                            },
                                        }));
                                    },
                                }));
                            }
                            else if (sendForSigning) {
                                dispatch({ type: "LOG_OUT" });
                                setTimeout(function () {
                                    redirectPage(getLocalizedText("template.document.send_for_signing.redirect_url"));
                                }, 500);
                            }
                            else {
                                navigate(-1);
                            }
                        },
                    }));
                }
                if (goBackOnSuccess) {
                    navigate(-1);
                }
            },
        }));
    };
    // Render
    var error = fetchError || fetchDetailError;
    if (error) {
        return _jsx(Error, { errors: [error] });
    }
    return (_jsx(Editor, { displayType: sendToTenant
            ? EDisplayType.TemplateDocumentExternal
            : EDisplayType.TemplateDocument, templateDocumentId: templateDocumentId, title: templateDocument === null || templateDocument === void 0 ? void 0 : templateDocument.name, onPressSave: handlePressSave, isFetching: isFetching && isFetchingDetail, isUpdating: isUpdatingDetail, canEdit: canEdit, rawState: rawState, fields: fields, variablesData: variablesData, documentConfig: documentConfig, documentIds: (detail === null || detail === void 0 ? void 0 : detail.documentIds) || [], externalViewType: externalViewType }));
};
// eslint-disable-next-line import/no-default-export
export default TemplateDocumentEditor;
