import { jsx as _jsx } from "react/jsx-runtime";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { useDispatch } from "react-redux";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { SHOW_APP_MODAL } from "../../redux/actions";
import { Icon } from "../../ui-components/Icon";
import menuItems from "../../utils/menu-items";
import { BottomMenuStyles } from "./BottomMenu.styles";
export var BottomMenu = function () {
    var navigate = useNavigate();
    var dispatch = useDispatch();
    var pathname = useLocation().pathname;
    var sx = BottomMenuStyles();
    var handleShowConfirmModal = function (item) {
        dispatch({
            type: SHOW_APP_MODAL,
            payload: {
                link: item === null || item === void 0 ? void 0 : item.url,
                title: item === null || item === void 0 ? void 0 : item.title,
                showAppModal: true,
                icon: item === null || item === void 0 ? void 0 : item.icon,
                description: item === null || item === void 0 ? void 0 : item.message,
                btnText: getLocalizedText("system.open.website"),
            },
        });
    };
    var navItems = menuItems
        .filter(function (item) { return !item.disabled; })
        .map(function (item) {
        var id = item.id, title = item.title, icon = item.icon;
        var isSelected = item.matchPaths.some(function (path) {
            return matchPath(path, pathname);
        });
        return (_jsx(BottomNavigationAction, { label: title, onClick: function () {
                return (item === null || item === void 0 ? void 0 : item.openModal)
                    ? handleShowConfirmModal(item)
                    : navigate((item === null || item === void 0 ? void 0 : item.url) || "");
            }, icon: _jsx(Icon, { path: icon, width: "24px", height: "24px" }), sx: isSelected ? sx.content.navButtonSelected : {} }, id));
    });
    return (_jsx(BottomNavigation, { showLabels: true, sx: sx.wrapper, children: navItems }));
};
