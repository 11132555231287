var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isNumber } from "lodash";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { VAT_OPTIONS, VAT_OPTIONS_BOOKKEEPING, } from "../../types/contract.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { formatCurrency, getAmountWithVat } from "../../utils/number.utils";
export var getVatFieldsForSchema = function (params) {
    var _a;
    var _b;
    var initialValues = params.initialValues, defaultValues = params.defaultValues, fieldKey = params.fieldKey, infoFieldKey = params.infoFieldKey, baseAmountFieldKey = params.baseAmountFieldKey, defaultValue = params.defaultValue, getForcedValue = params.getForcedValue, showIf = params.showIf, shouldSetNewBaseAmount = params.shouldSetNewBaseAmount, bookkeepingEnabled = params.bookkeepingEnabled;
    var forcedValue = getForcedValue === null || getForcedValue === void 0 ? void 0 : getForcedValue({ initialValues: initialValues, defaultValues: defaultValues });
    var valueParsed = (_b = forcedValue !== null && forcedValue !== void 0 ? forcedValue : defaultValue) !== null && _b !== void 0 ? _b : getValue(fieldKey, initialValues, defaultValues);
    return _a = {},
        _a[fieldKey] = {
            default: valueParsed,
            // @ts-ignore
            options: (bookkeepingEnabled ? VAT_OPTIONS_BOOKKEEPING : VAT_OPTIONS).map(function (id) {
                if (isNumber(id)) {
                    return {
                        id: id,
                        value: getLocalizedText("contract.item.vat", { value: "".concat(id) }, id),
                    };
                }
                return {
                    id: id,
                    value: getLocalizedText("contract.item.no_vat_type.".concat(id).toLowerCase()),
                };
            }),
            showIf: showIf,
            requiredIf: showIf,
        },
        _a[infoFieldKey] = {
            default: {},
            // @ts-ignore
            showIf: function (formData) {
                return (showIf ? showIf(formData) : true) && formData[fieldKey] > 0;
            },
            // @ts-ignore
            handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                var _a, _b;
                var setNewBaseAmount = shouldSetNewBaseAmount === null || shouldSetNewBaseAmount === void 0 ? void 0 : shouldSetNewBaseAmount(fieldName, fieldValue, myFieldValue, formData);
                if (setNewBaseAmount === null || setNewBaseAmount === void 0 ? void 0 : setNewBaseAmount.shouldSetNewValue) {
                    return {
                        shouldSetNewValue: true,
                        newValue: __assign(__assign({}, myFieldValue), (_a = {}, _a[baseAmountFieldKey] = setNewBaseAmount.newValue, _a)),
                    };
                }
                if (fieldName === fieldKey || fieldName === baseAmountFieldKey) {
                    return {
                        shouldSetNewValue: true,
                        newValue: __assign(__assign({}, myFieldValue), (_b = {}, _b[fieldName] = fieldValue, _b)),
                    };
                }
                return { shouldSetNewValue: false };
            },
        },
        _a;
};
export var getVatFieldsForUiSchema = function (params) {
    var _a;
    var fieldKey = params.fieldKey, infoFieldKey = params.infoFieldKey, baseAmountFieldKey = params.baseAmountFieldKey, isDisabled = params.isDisabled;
    return _a = {},
        _a[fieldKey] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("contract_info.vat_amount.title"),
            shouldShowOnSummary: function (formData) {
                return formData[fieldKey] !== undefined && formData[fieldKey] !== null;
            },
            formatSummary: function (formData) {
                if (formData === null || formData === undefined) {
                    return;
                }
                if (isNumber(formData)) {
                    return getLocalizedText("contract.item.vat", { value: "".concat(formData) }, formData);
                }
                return getLocalizedText("contract.item.no_vat_type.".concat(formData).toLowerCase());
            },
            isDisabled: isDisabled,
        },
        _a[infoFieldKey] = {
            "ui:field": ERentioFormField.HelpInfo,
            // title: getLocalizedText("contract.price.vat"),
            getSubtitle: function (state, formData, fieldValue) {
                var baseAmount = fieldValue[baseAmountFieldKey] || formData[baseAmountFieldKey] || 0;
                var vat = fieldValue[fieldKey] || formData[fieldKey] || 0;
                return getLocalizedText("system.vat_total_info", {
                    amount: formatCurrency(baseAmount),
                    amountWithVat: formatCurrency(getAmountWithVat(baseAmount, vat / 100)),
                    vat: vat,
                });
            },
        },
        _a;
};
