import component from "./ConditionRenderer";
import strategy from "./ConditionRenderer.strategy";
export { CONDITION_RENDERER_TYPE } from "./ConditionRenderer.strategy";
export * from "./ConditionRenderer.types";
var decorator = {
    strategy: strategy,
    component: component,
};
// eslint-disable-next-line import/no-default-export
export default decorator;
