import { getYear } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
export var MAX_YEARS = 120;
export var defineYearAmount = function (params) {
    var minDate = params.minDate, maxDate = params.maxDate;
    if (minDate && maxDate) {
        var difference = getYear(maxDate) - getYear(minDate);
        return Math.max(1, Math.min(MAX_YEARS, difference + 1));
    }
    return MAX_YEARS;
};
export var defineStartYear = function (params) {
    var minDate = params.minDate, maxDate = params.maxDate, yearAmountBase = params.yearAmount;
    var yearAmount = Math.max(1, Math.min(MAX_YEARS, yearAmountBase || 0));
    if (minDate) {
        return getYear(minDate);
    }
    if (maxDate) {
        return getYear(maxDate) - yearAmount + 1;
    }
    return getYear(new Date()) - Math.round(yearAmount / 2);
};
