import clone from "ramda/es/clone";
import equals from "ramda/es/equals";
import { execute } from "./actions";
function doRunRules(engine, formData, schema, extraActions) {
    if (extraActions === void 0) { extraActions = {}; }
    var schemaCopy = clone(schema);
    var formDataCopy = clone(formData);
    var res = engine.run(formData).then(function (events) {
        events.forEach(function (event) {
            return execute(event, schemaCopy, formDataCopy, extraActions);
        });
    });
    return res.then(function () {
        return {
            schema: schemaCopy,
            formData: formDataCopy,
        };
    });
}
export var normRules = function (rules) {
    return rules.sort(function (a, b) {
        if (a.order === undefined) {
            return b.order === undefined ? 0 : 1;
        }
        return b.order === undefined ? -1 : a.order - b.order;
    });
};
export var rulesRunner = function (schema, rules, engine, extraActions) {
    if (extraActions === void 0) { extraActions = {}; }
    engine = typeof engine === "function" ? new engine([], schema) : engine;
    normRules(rules).forEach(function (rule) { return engine.addRule(rule); });
    return function (formData) {
        if (formData === undefined || formData === null) {
            return Promise.resolve({ schema: schema, formData: formData });
        }
        return doRunRules(engine, formData, schema, extraActions).then(function (conf) {
            if (equals(conf.formData, formData)) {
                return conf;
            }
            else {
                return doRunRules(engine, conf.formData, schema, extraActions);
            }
        });
    };
};
