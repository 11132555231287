import { Box } from "@rebass/grid";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Banner,
  ESpacings,
  Label,
  TextField,
} from "@rentiohq/web-shared/dist/components";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import usePreference from "scenes/Settings/hooks/usePreference";
import SettingsImageUpload from "../../components/SettingsImageUpload";
import CommunicationPreview from "./CommunicationPreview";

const PREFERENCE_KEY_COMMUNICATION_FOOTER = "COMMUNICATION_FOOTER";
// const PREFERENCE_KEY_COMMUNICATION_COLOR = "COMMUNICATION_COLOR";
// const PREFERENCE_KEY_COMMUNICATION_BACKGROUND_COLOR = "COMMUNICATION_BG_COLOR";
const PREFERENCE_KEY_COMMUNICATION_LOGO = "COMMUNICATION_LOGO";

// eslint-disable-next-line import/no-default-export
export default function Communication() {
  const [footerText, setFooterText] = useState<string>();

  const [preferredFooterText = "", _saveFooterText] = usePreference<string>({
    preferenceKey: PREFERENCE_KEY_COMMUNICATION_FOOTER,
    preferencePersistScope: EPreferencePersistScope.RemoteBroker,
  });

  const saveFooterText = useCallback(debounce(_saveFooterText, 500), []);

  return (
    <Box mb={ESpacings.loose}>
      <Banner
        hasDismiss={false}
        icon="leaseContract"
        title={getLocalizedText(
          "broker.communication_preferences.info.heading",
        )}
      />

      {/* <CommunicationColorPicker
        preferenceKey={PREFERENCE_KEY_COMMUNICATION_COLOR}
        preferencePersistScope={EPreferencePersistScope.RemoteBroker}
        modalHeading={getLocalizedText(
          "editor.template_document.document_config.communication_color.title",
        )}
        title={getLocalizedText(
          "editor.template_document.document_config.communication_color.info",
        )}
      />

      <Spacer weight={ESpacerWeight.W32} />

      <CommunicationColorPicker
        preferenceKey={PREFERENCE_KEY_COMMUNICATION_BACKGROUND_COLOR}
        preferencePersistScope={EPreferencePersistScope.RemoteBroker}
        modalHeading={getLocalizedText(
          "editor.template_document.document_config.communication_background_color",
        )}
        title={getLocalizedText(
          "editor.template_document.document_config.communication_background_color",
        )}
      />

      <Spacer weight={ESpacerWeight.W32} /> */}

      <SettingsImageUpload
        preferenceKey={PREFERENCE_KEY_COMMUNICATION_LOGO}
        preferencePersistScope={EPreferencePersistScope.RemoteBroker}
        title={getLocalizedText(
          "editor.template_document.document_config.communication_logo",
        )}
        fileNamePrefix="Communication logo"
        tags={["communication_logo"]}
      />

      <Spacer weight={ESpacerWeight.W16} />

      <Label>
        {getLocalizedText(
          "editor.template_document.document_config.communication_footer",
        )}
      </Label>

      <Spacer weight={ESpacerWeight.W16} />

      <TextField
        name="footer"
        type="textarea"
        value={footerText ?? preferredFooterText}
        multiline
        onChange={({ target: { value } }: { target: { value: string } }) => {
          setFooterText(value);
          saveFooterText(value);
        }}
      />

      <Spacer weight={ESpacerWeight.W32} />

      <CommunicationPreview
        title={getLocalizedText(
          "editor.template_document.document_config.communication_preview.title",
        )}
        logoPreferenceKey={PREFERENCE_KEY_COMMUNICATION_LOGO}
        // textColorPreferenceKey={PREFERENCE_KEY_COMMUNICATION_COLOR}
        // bgColorPreferenceKey={PREFERENCE_KEY_COMMUNICATION_BACKGROUND_COLOR}
      />
    </Box>
  );
}
