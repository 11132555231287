import { IPaymentRequest } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { differenceInCalendarDays } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import {
  ETagVariant,
  Grid,
  Tag,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { getReadableType } from "@rentiohq/web-shared/dist/utils/paymentRequest";
import { ts } from "../../../services";
import { PaymentRequestCardStyles } from "./PaymentRequestCard.styles";

interface IProps {
  paymentRequest: IPaymentRequest;
}

export const PaymentRequestCard = ({ paymentRequest }: IProps) => {
  const sx = PaymentRequestCardStyles();
  const getTitle = () => {
    const costType = getReadableType(paymentRequest, key =>
      getLocalizedText(`payment.payment_order.type.${key.toLowerCase()}`),
    );
    return `${costType} (${formatDate(paymentRequest.startedAt)} - ${formatDate(
      paymentRequest.endedAt,
    )})`;
  };

  const { amount, propertyAddress, dueDateAt, endedAt } = paymentRequest;
  const paymentDetails = `${formatDate(dueDateAt)} - ${
    endedAt ? formatDate(endedAt) : ""
  } (${propertyAddress?.simple})`;
  const dso = differenceInCalendarDays(new Date(), dueDateAt);

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12} md={12} lg={8}>
        <Typography variant="h5">
          {`${formatCurrency(amount)} - ${getTitle()}`}
        </Typography>
        <Typography
          sx={sx.content.longText}
          mb={1}
          variant="caption"
          title={paymentDetails}
        >
          {paymentDetails}
        </Typography>
        <Tag variant={ETagVariant.Warning}>
          {ts.paymentsFollowUpCardDaysMessage({ days: dso })}
        </Tag>
      </Grid>
      {/* cspell:disable-next-line */}
      <Grid item xs={6} md={6} lg={2} sx={sx.content.hideable}>
        <Typography variant="caption">
          {getLocalizedText("payment.payment_order.due_date.once.label.basic")}
        </Typography>
        <Typography variant="subtitle2">{formatDate(dueDateAt)}</Typography>
      </Grid>
    </Grid>
  );
};
