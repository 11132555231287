import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import createBasicContractInfo from "@rentiohq/shared-frontend/dist/forms/createContract";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/createContract/schema.createContract.actions";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { ITemplate } from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EContractType,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  Card,
  MultiStepForm as Form,
} from "@rentiohq/web-shared/dist/components";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { getSignerFields } from "@rentiohq/web-shared/dist/scenes/TemplateDocumentEditor/TemplateDocumentEditor.utils";
import { parseFields } from "@rentiohq/web-shared/dist/scenes/TemplateEditor/components/Editor/Editor.utils";
import {
  getDefaultDocumentConfig,
  getDefaultFilename,
  getDefaultVariablesData,
} from "components/TemplateDocumentContractCreateModal/TemplateDocumentContractCreateModal.utils";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import { IRootStore } from "redux/reducers";
import { IBasicContractFormProps } from "./BasicContractForm.types";

const PREFERENCE_KEY_BRAND_COLOR = "BRAND_COLOR";
const PREFERENCE_KEY_BRAND_LOGO = "BRAND_LOGO";

const formId = generateFormId();

export const BasicContractForm: React.FC<IBasicContractFormProps> = ({
  property,
  contract,
  availableTemplatesForContract,
  ibanisationBankAccount,
  deleteCancellation,
}) => {
  // Router
  const navigate = useNavigate();

  // Redux
  const { broker } = authHooks.useSelf();
  const hasBookkeepingForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Bookkeeping,
  );
  const hasRentDiscountForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.RentDiscount,
  );
  const hasIbanisationForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Ibanisation,
  );
  const hasCompleteContractFlowForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.CompleteContractFlow,
  );

  const preferences = useSelector(
    (state: IRootStore) => state.systemLocal.preferences,
  );

  const contacts = useSelector((state: IRootStore) =>
    contactSelectors.getAllContacts(state),
  );
  const legalContact = useSelector((state: IRootStore) =>
    contactSelectors.getContactByCustomId(state, EContactCustomId.Legal),
  );
  const meMaster = useSelector((state: IRootStore) =>
    contactSelectors.getContactByCustomId(state, EContactCustomId.MeMaster),
  );

  const brandColor = useSelector((state: IRootStore) =>
    systemSelectors.getPreference<string>(state, PREFERENCE_KEY_BRAND_COLOR),
  );
  const brokerLogoDocumentId = useSelector((state: IRootStore) =>
    systemSelectors.getPreference<string>(state, PREFERENCE_KEY_BRAND_LOGO),
  );

  // Event handlers
  const handleSuccess = () => {
    navigate(`/properties/${property.id}/contracts`);
  };

  const getActiveStep = () => {
    if (deleteCancellation) {
      return 4;
    }
    return contract ? "summary" : undefined;
  };

  // Render
  return (
    <Card>
      <Form
        formId={`basic-contract-form-${formId}`}
        schemas={createBasicContractInfo({
          baseYears: CONFIG.CONTRACT_BASE_YEARS,
          property,
          contract,
          ibanisationBankAccount,
          discountEnabled: hasRentDiscountForBroker,
          ibanisationEnabled: hasIbanisationForBroker,
          bookkeepingEnabled: hasBookkeepingForBroker,
          completeFlowEnabled: hasCompleteContractFlowForBroker,
          templates: availableTemplatesForContract,
        })}
        withSummary={true}
        activeStep={getActiveStep()}
        isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
          contract ? contract.id : undefined,
        )}
        performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
          contract ? contract.id : undefined,
        )}
        submitActionCreator={(formData: any, dispatch?: Dispatch) => {
          return submitActionCreator({
            property,
            contractType: EContractType.Basic,
            contractId: contract ? contract.id : undefined,
            onSuccess: handleSuccess,
            templateDocumentGeneration: availableTemplatesForContract
              ? {
                  templates: availableTemplatesForContract,
                  getTemplateDocumentData: (params: {
                    contract: IContract;
                    template: ITemplate;
                  }) => {
                    const { template, contract: createdContract } = params;

                    const fields = parseFields(template.fields);

                    const variablesData = getDefaultVariablesData({
                      legalContact,
                      meMaster,
                      broker,
                      brokerLogoDocumentId,
                      property,
                      contract: createdContract,
                      contacts,
                      fields,
                      preferences,
                    });

                    return {
                      templateId: template.id,
                      propertyId: property.id,
                      contractId: createdContract.id,
                      name: getDefaultFilename({
                        type: "contract",
                        template,
                        property,
                      }),
                      type: template.type,
                      documentConfig: getDefaultDocumentConfig({
                        brandColor,
                      }),
                      variablesData,
                      documentIds: [],
                      signerFields: getSignerFields({
                        fields,
                        variablesData: JSON.parse(variablesData),
                      }),
                    };
                  },
                }
              : undefined,
            deleteCancellation,
            bookkeepingEnabled: hasBookkeepingForBroker,
          })(formData, dispatch);
        }}
        onSuccess={handleSuccess}
      />
    </Card>
  );
};
