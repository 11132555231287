var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "@rentiohq/shared-frontend/dist/components/theme/styled-components";
export var OuterWrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: ", ";\n  padding-right: 16px;\n  padding-bottom: 16px;\n  padding-left: 16px;\n  height: 100%;\n  overflow: hidden;\n"], ["\n  padding-top: ", ";\n  padding-right: 16px;\n  padding-bottom: 16px;\n  padding-left: 16px;\n  height: 100%;\n  overflow: hidden;\n"])), function (props) { return (props.isBanner ? "40px" : "0px"); });
export var Wrap = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  height: 100%;\n"], ["\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  flex: 1;\n  height: 100%;\n"])));
export var SidebarWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  width: 100%;\n  max-width: 520px;\n  background-color: rgba(243, 246, 249);\n  border-right: 1px solid ", ";\n"], ["\n  position: relative;\n  width: 100%;\n  max-width: 520px;\n  background-color: rgba(243, 246, 249);\n  border-right: 1px solid ", ";\n"])), function (props) { return props.theme.colors.global4; });
export var EditorWrap = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 999;\n  position: relative;\n"], ["\n  flex: 999;\n  position: relative;\n"])));
// font-family: Lato, sans-serif;
export var ContentFontWrap = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
