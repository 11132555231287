import { Box } from "@rebass/grid";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import * as chatActions from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.actions";
import * as chatSelectors from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import * as chatUtils from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { EContractFetchType } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import {
  EPropertyMemberTypes,
  EPropertyTypeId,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  getMembersWithRole,
  memberHasOneOfRoles,
  memberHasRole,
} from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  Can,
  Card,
  DisplayText,
  Error,
  ESpacings,
  Grid,
  Loading,
  RentioInternalRenderer,
  Washout,
} from "@rentiohq/web-shared/dist/components";
import { orderBy } from "lodash";
import isEmpty from "ramda/es/isEmpty";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { ChatOverview } from "../../../../components/ChatOverview";
import { InlineNotes } from "../../../../components/InlineNotes";
import * as t from "../../../../services/translationService";
import { RentioLegalCards } from "../../../RentioLegal/components/RentioLegalCards";
import { ContractCard } from "../Contracts/components/ContractCard";
import LinkedPropertiesCard from "./components/LinkedPropertiesCard";
import NoContractCard from "./components/NoContractCard";
import OfficeManagersCard from "./components/OfficeManagersCard";
import { PropertyManagersCard } from "./components/PropertyManagersCard";
import { PropertyOwnersCard } from "./components/PropertyOwnersCard";
import { PropertyRolesCard } from "./components/PropertyRolesCard";
import { PropertyRolesModal } from "./components/PropertyRolesModal";
import PropertyTenantsCard from "./components/PropertyTenantsCard";

const INLINE_FETCH_LIMIT = 5;

export const PropertyDetailDashboard = () => {
  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Helpers
  const chatsExtraFilterData = propertyId
    ? chatUtils.getFilterForProperty(propertyId)
    : undefined;

  const chatQuery = chatUtils.getChatsQuery({
    limit: INLINE_FETCH_LIMIT,
    filter: {
      ...chatsExtraFilterData,
    },
  });

  // State
  const [showInitialPropertyRolesModal, setShowInitialPropertyRolesModal] =
    useState(false);

  const contractsExtraFilterData = propertyId
    ? contractUtils.getFilterForProperty(propertyId, EContractFetchType.Active)
    : undefined;

  const {
    items: currentContracts = [],
    isFetching: isFetchingCurrentContracts,
    fetchError: currentContractsFetchError,
    refetch: refetchCurrentContracts,
  } = contractHooks.useGetAll({
    query: {
      filter: contractsExtraFilterData,
      sort: [{ field: "startDate", method: "DESC" }],
    },
  });

  const chats = useSelector(
    (state: IPartialRootState) =>
      chatSelectors.getInfiniteLoad(state, chatQuery)?.items,
  );

  const isRevokingDocumentPackage = useSelector(
    (state: IRootStore) => state.documentPackage.isRevokingDocumentPackage,
  );
  const prevIsRevokingDocumentPackage = usePrevious(isRevokingDocumentPackage);

  const isCancellingRentDeposit = useSelector(
    (state: IRootStore) => state.rentDeposit.isCancellingRentDeposit,
  );
  const cancelRentDepositErrors = useSelector(
    (state: IRootStore) => state.rentDeposit.cancelRentDepositError,
  );
  const prevIsCancellingRentDeposit = usePrevious(isCancellingRentDeposit);
  const hasCancelledRentDeposit =
    !isCancellingRentDeposit &&
    !!prevIsCancellingRentDeposit &&
    !cancelRentDepositErrors;

  const { detail: property, refetch: refetchProperty } =
    propertyHooks.useDetail({
      id: propertyId,
    });

  const isGroup = property?.typeId === EPropertyTypeId.Group;

  useEffect(() => {
    dispatch(
      chatActions.getInfiniteLoadStart.getAction({
        refresh: true,
        query: chatQuery,
      }),
    );
  }, []);

  useEffect(() => {
    if (!isRevokingDocumentPackage && prevIsRevokingDocumentPackage) {
      refetchProperty();
    }
  }, [isRevokingDocumentPackage, prevIsRevokingDocumentPackage]);

  useEffect(() => {
    if (hasCancelledRentDeposit) {
      refetchProperty();
    }
  }, [hasCancelledRentDeposit]);

  if (!property) {
    return null;
  }

  const { archivedAt, members } = property;

  const propertyOwners = getMembersWithRole(
    members,
    EPropertyMemberTypes.Owner,
  );

  const hasPropertyOwners = propertyOwners && propertyOwners.length > 0;
  const isDisabled = !hasPropertyOwners && !archivedAt;

  const handleAddChatClick = () => {
    if (!propertyId) {
      return;
    }

    navigate("/chats/add", {
      state: {
        propertyId,
      },
    });
  };

  const renderChatsOverview = () => {
    if (!chats || isEmpty(chats)) {
      return (
        <Card
          heading={t.chatsTitle()}
          footerActions={[
            {
              content: t.propertyDetailChatsEmptyAction(),
              onClick: handleAddChatClick,
              appearance: "outline",
            },
          ]}
        >
          <p>{t.propertyDetailChatsEmptyMessage()}</p>
        </Card>
      );
    }

    return (
      <Card heading={t.chatsTitle()}>
        <ChatOverview chats={chats} />
      </Card>
    );
  };

  const renderContracts = () => {
    if (isFetchingCurrentContracts && isEmpty(currentContracts)) {
      return (
        <Box mb={ESpacings.base}>
          <Loading />
        </Box>
      );
    }

    if (currentContractsFetchError) {
      return (
        <Box mb={ESpacings.base}>
          <Error
            errors={[currentContractsFetchError]}
            onRetry={refetchCurrentContracts}
          />
        </Box>
      );
    }

    if (isEmpty(currentContracts)) {
      return <NoContractCard property={property} propertyId={property.id} />;
    }

    return currentContracts.map((contract, index) => (
      <ContractCard
        key={contract.id}
        contract={contract}
        property={property}
        showContractType={false}
        showChecklistInitialState={index === 0}
        onCancelContract={() => refetchCurrentContracts()}
      />
    ));
  };

  const renderContent = () => (
    <>
      {isDisabled && !isGroup && (
        <Box mb={[3, 3, 4]}>
          <PropertyOwnersCard
            heading={t.propertyAddOwnerTitle()}
            property={property}
            owners={orderBy(propertyOwners, member => getName(member.account))}
            onSelectOwner={() => {
              setShowInitialPropertyRolesModal(true);
            }}
            onRemoveOwner={refetchProperty}
            onAddOwner={refetchProperty}
          >
            {t.propertyAddOwnerBody()}
          </PropertyOwnersCard>
        </Box>
      )}

      {propertyId && showInitialPropertyRolesModal && (
        <PropertyRolesModal
          propertyId={propertyId}
          onClose={() => {
            setShowInitialPropertyRolesModal(false);
          }}
        />
      )}

      <Washout
        isDisabled={isDisabled && !isGroup}
        variation={isDisabled && !isGroup ? "light" : "off"}
      >
        <Grid spacing="loose">
          <Grid.Item width={[1, 1, 11 / 16]}>
            {renderContracts()}

            {renderChatsOverview()}
          </Grid.Item>

          <Grid.Item width={[1, 1, 5 / 16]}>
            <LinkedPropertiesCard property={property} />
            <InlineNotes
              customPath={`/properties/${property.id}/notes`}
              cardProps={{
                heading: (
                  <DisplayText size="extraSmall" space="none">
                    {t.noteTitle()}
                  </DisplayText>
                ),
              }}
            />

            <RentioLegalCards
              property={property}
              contracts={currentContracts}
            />

            <PropertyTenantsCard property={property} />

            {!isGroup && (
              <>
                <PropertyOwnersCard
                  heading={
                    <DisplayText size="extraSmall">
                      {t.propertyDashboardOwnersHeading()}
                    </DisplayText>
                  }
                  space={ESpacings.loose}
                  property={property}
                  owners={propertyOwners}
                  onRemoveOwner={refetchProperty}
                  onAddOwner={refetchProperty}
                  showActions={!archivedAt}
                />

                <PropertyManagersCard
                  members={orderBy(
                    members,
                    [
                      member =>
                        memberHasRole(member, EPropertyMemberTypes.Broker),
                      member =>
                        memberHasOneOfRoles(member, [
                          EPropertyMemberTypes.IndexationManager,
                          EPropertyMemberTypes.FinancialManager,
                          EPropertyMemberTypes.TechnicalManager,
                        ]),
                      member => getName(member.account),
                    ],
                    ["desc", "desc", "asc"],
                  )}
                  property={property}
                  showActions={!archivedAt}
                />
                <OfficeManagersCard propertyId={property.id} />
              </>
            )}

            <RentioInternalRenderer>
              <PropertyRolesCard
                property={property}
                roles={[EPropertyMemberTypes.Broker]}
                showRoles={false}
                heading={
                  <DisplayText size="extraSmall">
                    {getLocalizedText("property.dashboard.brokers.heading")}
                  </DisplayText>
                }
              />

              <PropertyRolesCard
                property={property}
                roles={Object.values(EPropertyMemberTypes)}
                showRoles={true}
                heading={
                  <DisplayText size="extraSmall">{"All members"}</DisplayText>
                }
              />
            </RentioInternalRenderer>
          </Grid.Item>
        </Grid>
      </Washout>
    </>
  );

  return (
    <Can
      role={property.roles}
      perform="propertyDashboard:visit"
      yes={renderContent}
      no={() => <Navigate to="/" />}
    />
  );
};
