var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import * as pricingActions from "./pricing.actions";
export var REDUCER_KEY_PRICING = "pricing";
var getDefaultState = function () { return ({
    pricings: {},
    pricingsById: {},
    pricingsPaged: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on(pricingActions.getPricing.types.START, function (state, _a) {
    var _b;
    var pricingId = _a.pricingId;
    return merge({}, state, {
        pricingsById: (_b = {},
            _b[pricingId] = {
                isFetching: true,
                fetchError: undefined,
            },
            _b),
    });
});
reducer.on(pricingActions.getPricing.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var pricingId = _a.pricingId, pricing = _a.pricing;
    return merge({}, state, {
        pricings: (_b = {},
            _b[pricingId] = pricing,
            _b),
        pricingsById: (_c = {},
            _c[pricingId] = {
                isFetching: false,
                fetchError: undefined,
            },
            _c),
    });
});
reducer.on(pricingActions.getPricing.types.FAILURE, function (state, _a) {
    var _b;
    var pricingId = _a.pricingId, error = _a.error;
    return merge({}, state, {
        pricingsById: (_b = {},
            _b[pricingId] = {
                isFetching: false,
                fetchError: error,
            },
            _b),
    });
});
reducer.on(pricingActions.updatePricing.types.START, function (state, _a) {
    var _b;
    var pricingId = _a.pricingId;
    return __assign(__assign({}, state), { pricingsById: __assign(__assign({}, state.pricingsById), (_b = {}, _b[pricingId] = __assign(__assign({}, state.pricingsById[pricingId]), { isUpdating: true, updateError: undefined }), _b)) });
});
reducer.on(pricingActions.updatePricing.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var pricingId = _a.pricingId, pricing = _a.pricing;
    return __assign(__assign({}, state), { pricings: __assign(__assign({}, state.pricings), (_b = {}, _b[pricingId] = pricing, _b)), pricingsById: __assign(__assign({}, state.pricingsById), (_c = {}, _c[pricingId] = __assign(__assign({}, state.pricingsById[pricingId]), { isUpdating: false, updateError: undefined }), _c)) });
});
reducer.on(pricingActions.updatePricing.types.FAILURE, function (state, _a) {
    var _b;
    var pricingId = _a.pricingId, error = _a.error;
    return __assign(__assign({}, state), { pricingsById: __assign(__assign({}, state.pricingsById), (_b = {}, _b[pricingId] = __assign(__assign({}, state.pricingsById[pricingId]), { isUpdating: false, updateError: error }), _b)) });
});
reducer.on(pricingActions.createPricing.types.START, function (state) {
    return __assign(__assign({}, state), { isCreating: true, createError: undefined, createdPricingId: undefined });
});
reducer.on(pricingActions.createPricing.types.SUCCESS, function (state, pricing) {
    var _a;
    return __assign(__assign({}, state), { pricings: __assign(__assign({}, state.pricings), (_a = {}, _a[pricing.id] = pricing, _a)), isCreating: false, createError: undefined, createdPricingId: pricing.id });
});
reducer.on(pricingActions.createPricing.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return __assign(__assign({}, state), { isCreating: false, createError: error, createdPricingId: undefined });
});
reducer.on(pricingActions.deletePricing.types.START, function (state, _a) {
    var _b;
    var pricingId = _a.pricingId;
    return merge({}, state, {
        pricingsById: (_b = {},
            _b[pricingId] = {
                isDeleting: true,
                deleteError: undefined,
            },
            _b),
    });
});
reducer.on(pricingActions.deletePricing.types.SUCCESS, function (state, _a) {
    var _b;
    var pricingId = _a.pricingId;
    var pricings = __assign({}, state.pricings);
    delete pricings[pricingId];
    return __assign(__assign({}, state), { pricings: pricings, pricingsById: __assign(__assign({}, state.pricingsById), (_b = {}, _b[pricingId] = __assign(__assign({}, state.pricingsById[pricingId]), { isDeleting: false, deleteError: undefined }), _b)) });
});
reducer.on(pricingActions.deletePricing.types.FAILURE, function (state, _a) {
    var _b;
    var pricingId = _a.pricingId, error = _a.error;
    return merge({}, state, {
        pricingsById: (_b = {},
            _b[pricingId] = {
                isDeleting: false,
                deleteError: error,
            },
            _b),
    });
});
reducer.on(pricingActions.getPricingsPaged.types.START, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, filterData = _a.filterData;
    return merge({}, state, {
        pricingsPaged: (_b = {},
            _b[identifier] = {
                filterData: filterData,
                pages: (_c = {},
                    _c[page] = {
                        isFetching: true,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(pricingActions.getPricingsPaged.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, pricings = _a.pricings;
    return merge({}, state, {
        pricings: __assign({}, keyBy(pricings, "id")),
        pricingsPaged: (_b = {},
            _b[identifier] = {
                pages: (_c = {},
                    _c[page] = {
                        pricingIds: pricings.map(function (pricing) { return pricing.id; }),
                        isFetching: false,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(pricingActions.getPricingsPaged.types.FAILURE, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, error = _a.error;
    return merge({}, state, {
        pricingsPaged: (_b = {},
            _b[identifier] = {
                pages: (_c = {},
                    _c[page] = {
                        isFetching: false,
                        fetchError: error,
                    },
                    _c),
            },
            _b),
    });
});
// eslint-disable-next-line import/no-default-export
export default reducer;
