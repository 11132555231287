import { Box } from "@rebass/grid";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  DisplayText,
  ESpacings,
  IOptionListOption,
  OptionListShared,
  TAssetSource,
} from "@rentiohq/web-shared/dist/components";
import { EGroupActionType } from "../GroupActionsModal.types";

export interface ISelectGroupActionProps {
  type?: EGroupActionType;
  onChange: (value: EGroupActionType) => void;
}

const SelectGroupAction = (props: ISelectGroupActionProps) => {
  const { type, onChange } = props;

  // Helpers
  const getIcon = (type: EGroupActionType): TAssetSource => {
    switch (type) {
      case EGroupActionType.Sms:
        return "smartphone";

      case EGroupActionType.Email:
        return "email";
      case EGroupActionType.Chat:
        return "chat";
      case EGroupActionType.Task:
        return "checklist";
      case EGroupActionType.Document:
        return "fileDoc";

      default:
        return "messagesBubbleSquareText";
    }
  };

  const getLabel = (type: EGroupActionType) =>
    getLocalizedText(
      `group_actions.modal.select.action.${type.toLowerCase()}.label`,
    );

  const options: IOptionListOption<EGroupActionType>[] = Object.values(
    EGroupActionType,
  ).map((type: EGroupActionType) => {
    return {
      id: type,
      value: type,
      label: getLabel(type),
      icon: getIcon(type),
    };
  });

  // Event
  const onOptionChange = (
    value: EGroupActionType[],
    option?: IOptionListOption<EGroupActionType>,
  ) => {
    onChange(value[0]);
  };

  return (
    <>
      <Box mb={ESpacings.loose}>
        <DisplayText size="medium">
          {getLocalizedText("group_actions.modal.select.action.label")}
        </DisplayText>

        <OptionListShared
          id="GroupActionsAction"
          variant="card"
          onChange={onOptionChange}
          value={type ? [type] : undefined}
          options={options}
        />
      </Box>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default SelectGroupAction;
