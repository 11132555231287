import { useTheme } from "@mui/material/styles";
import { EFontWeights } from "../../themes/types";
export var TableStyles = function () {
    var theme = useTheme();
    return {
        content: {
            tableHeaderCell: {
                fontWeight: EFontWeights.Bold,
                h6: {
                    color: theme.palette.grey[900],
                },
                "&:first:child": {
                    pl: 3,
                },
            },
            tableBodyCell: {
                h6: {
                    fontWeight: EFontWeights.Regular,
                },
                "&:first:child": {
                    pl: 3,
                },
            },
            cardActions: {
                flexDirection: "column",
                alignItems: "flex-start",
            },
            paginationContainer: {
                width: "100%",
                display: "flex",
                justifyContent: "center",
            },
        },
    };
};
