import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { IContactListDataSourceItem } from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import {
  contactSelectInlineV2ValueSelector,
  getValue,
} from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import * as documentSelectors from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.selectors";
import { EContractMemberTypes } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";
import { EDocumentPackageTypes } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { EPropertyMemberTypes } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { ts } from "../../services";
import { EField, IExtraData } from "./schema.createDocumentPackage.types";
import {
  getNavigationTitle,
  getRelatedContract,
  getRelatedProperty,
} from "./schema.createDocumentPackage.utils";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: any) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    let documentFromStore: IDocument | undefined = undefined;

    // Filename
    let defaultFilename = getValue(EField.Name, initialValues, defaultValues);
    const document = defaultValues[EField.Documents]?.[0];
    if (document && typeof document === "string") {
      const state = getStore()?.getState();
      if (state) {
        documentFromStore = documentSelectors.getDetail(state, document);
      }

      if (documentFromStore) {
        defaultFilename = utils.file.removeExtension(
          documentFromStore.filename,
        );
      }
    } else if (document?.name) {
      defaultFilename = utils.file.removeExtension(document.name);
    }

    // Default signers
    let defaultSigners = getValue(EField.Signers, initialValues, defaultValues);
    const signerFields =
      documentFromStore?.signerFields || extraData.signerFields;
    if (signerFields) {
      defaultSigners = Object.keys(signerFields)
        .map(id => +id)
        .filter(
          extraData.isBroker
            ? (id: number) =>
                !extraData.contactMeMaster?.accountIds.includes(id)
            : () => true,
        );
    }

    // Default signers as broker
    let defaultSignersAsBroker = getValue(
      EField.SignAsBroker,
      initialValues,
      defaultValues,
    );
    if (documentFromStore?.signerFields) {
      defaultSignersAsBroker = Object.keys(documentFromStore.signerFields)
        .map(id => +id)
        .filter(
          extraData.isBroker
            ? id => extraData.contactMeMaster?.accountIds.includes(id)
            : () => false,
        );
    }

    return {
      required: [EField.Name],
      properties: {
        [EField.Header + "6"]: {
          default: undefined,
        },
        [EField.Name]: {
          default: defaultFilename,
        },
        [EField.LegalContactInfo]: {
          default: undefined,
          // @ts-ignore
          showIf: (_: any) =>
            extraData.isBroker &&
            !extraData.legalContact &&
            !extraData.sendToTenant,
        },
        [EField.Signers]: {
          default: defaultSigners,
        },
        [EField.SignAsBroker]: {
          default: defaultSignersAsBroker?.map((id: number) => `${id}`),
          // @ts-ignore
          showIf: (_: any) =>
            extraData.isBroker &&
            extraData.legalContact &&
            extraData.contactMeMaster,
          options: (() => {
            if (!extraData.contactMeMaster) {
              return [];
            }
            return [
              {
                id: `${extraData.contactMeMaster.account.id}`,
                value:
                  extraData.contactMeMaster.account.company ||
                  `${extraData.contactMeMaster.account.firstname} ${extraData.contactMeMaster.account.lastname}`,
                subValue: utils.i18n.t("role.broker"),
              },
            ];
          })(),
        },
      },
    };
  };

const signersDisabled = (formData: any, extraData: any) => {
  const documents = formData[EField.Documents] || [];
  const documentId = documents[0];
  if (!documentId) {
    return false;
  }
  if (typeof documentId !== "string") {
    return false;
  }

  const store = getStore();
  if (!store) {
    return false;
  }

  const state = store.getState();
  const documentFromStore = documentSelectors.getDetail(state, documentId);
  if (!documentFromStore) {
    return false;
  }

  // Disabled if document has signer fields from document generator
  const signerFields =
    documentFromStore?.signerFields || extraData.signerFields;
  if (!signerFields) {
    return false;
  }

  return Object.keys(signerFields).length > 0;
};

export const uiSchema = (extraData: IExtraData): IUiSchema<EField> => ({
  "rentio:title": utils.i18n.t("document_package.form.info.heading"),
  "rentio:navigationTitle": getNavigationTitle(),
  [EField.Header + "6"]: {
    "ui:field": ERentioFormField.SectionHeader,
    title: utils.i18n.t("document_package.form.info.heading"),
  },
  [EField.Name]: {
    "ui:field": ERentioFormField.Text,
    title: utils.i18n.t("document_package.form.name.label"),
  },
  [EField.LegalContactInfo]: {
    "ui:field": ERentioFormField.HelpInfo,
    type: "warning",
    title: getLocalizedText(
      "document_package.form.no_legal_contact_info.heading",
    ),
    subtitle: getLocalizedText(
      "document_package.form.no_legal_contact_info.content",
    ),
  },
  [EField.Signers]: {
    "ui:field": ERentioFormField.ContactSelectInlineV2,
    title: utils.i18n.t("document_package.form.signers.label"),
    isMultiSelect: true,
    asOptionList: true,
    isDisabled: (formData: any) => signersDisabled(formData, extraData),
    requiredContactFields: [
      "dateOfBirth",
      "placeOfBirth",
      "nationalRegisterNumber",
    ],
    selectContactFromListCtaTitle: utils.i18n.t(
      "document_package.form.signers.select_contact.action",
    ),
    customAddCtaLabel: (formData: any) => {
      if (
        [
          EDocumentPackageTypes.Contract,
          EDocumentPackageTypes.RentalAssignment,
        ].includes(formData[EField.DocumentPackageType])
      ) {
        return;
      }

      return utils.i18n.t("document_package.form.signers.add_contact.action");
    },
    valueSelector: (
      state: IPartialRootState,
      selectedAccountIds: number[] | string = [],
      formData: any,
    ) => {
      const relatedContract = getRelatedContract(extraData, formData);

      return contactSelectInlineV2ValueSelector(
        state,
        selectedAccountIds,
        relatedContract
          ? relatedContract.members.map(member => member.account)
          : [],
      );
    },
    customGetSubtitleSelector: (
      _: IPartialRootState,
      formData: any,
      item: IContactListDataSourceItem,
    ) => {
      const { accountId, contact } = item;
      const relatedContract = getRelatedContract(extraData, formData);
      const relatedProperty = getRelatedProperty(extraData, formData);

      if (relatedContract) {
        const contractMember = relatedContract.members.find(member => {
          if (accountId && accountId === member.account.id) {
            return true;
          }

          if (contact?.accountIds.includes(member.account.id)) {
            return true;
          }

          return false;
        });
        if (contractMember) {
          return contractMember.roles
            .filter(role => role !== EContractMemberTypes.Signer)
            .map(role => ts.role(role.toLowerCase()))
            .join(", ");
        }
      }

      if (relatedProperty) {
        const propertyMember = relatedProperty.members.find(member => {
          if (accountId && accountId === member.account.id) {
            return true;
          }

          if (contact?.accountIds.includes(member.account.id)) {
            return true;
          }

          return false;
        });
        if (propertyMember) {
          return (
            propertyMember.roles
              // @ts-ignore (signer is returned as property role)
              .filter(role => role !== EContractMemberTypes.Signer)
              .map(role => ts.role(role.toLowerCase()))
              .join(", ")
          );
        }
      }

      return;
    },
    customExtraAccountsSelector: (state: IPartialRootState, formData: any) => {
      const relatedContract = getRelatedContract(extraData, formData);
      const relatedProperty = getRelatedProperty(extraData, formData);
      if (!!formData[EField.Contract]) {
        if (!relatedContract) {
          return extraData.contactMeMaster
            ? [extraData.contactMeMaster.accountId]
            : undefined;
        }

        const contractMembers = relatedContract.members
          .filter(member =>
            member.roles.some(role =>
              [
                EContractMemberTypes.Owner,
                EContractMemberTypes.Tenant,
                EContractMemberTypes.Parent,
              ].includes(role),
            ),
          )
          .map((member: any) => member.account);

        // if (extraData.isBroker && extraData.legalContact && extraData.contactMeMaster) {
        //   return [...contractMembers, extraData.contactMeMaster.account]
        // }

        return contractMembers;
      }
      if (!!formData[EField.Property]) {
        if (!relatedProperty) {
          return extraData.contactMeMaster
            ? [extraData.contactMeMaster.accountId]
            : undefined;
        }

        return relatedProperty.members
          .filter(member =>
            extraData.isBroker && extraData.legalContact
              ? true
              : member.roles.some(role =>
                  [
                    EPropertyMemberTypes.Owner,
                    EPropertyMemberTypes.Tenant,
                    EPropertyMemberTypes.Parent,
                  ].includes(role),
                ),
          )
          .map((member: any) => member.account);
      }

      return extraData.contactMeMaster && !extraData.isBroker
        ? [extraData.contactMeMaster.account]
        : undefined;
    },
    customIsFetchingSelector: () => false,
    customGetContactsActions: [],
    getDisallowedAccountIds: (_: any) =>
      extraData.contactMeMaster && extraData.isBroker
        ? [extraData.contactMeMaster.account.id]
        : [],
  },
  [EField.SignAsBroker]: {
    "ui:field": ERentioFormField.InlineSelect,
    title: getLocalizedText("document_package.form.sign_as_broker.label"),
    isMultiSelect: true,
    asOptionList: true,
    hideOnSummaryIfEmpty: true,
    isDisabled: (formData: any) => signersDisabled(formData, extraData),
    formatSummary: (formData: any) => {
      if (!formData || !extraData.contactMeMaster) {
        return;
      }
      return (
        extraData.contactMeMaster.account.company ||
        `${extraData.contactMeMaster.account.firstname} ${extraData.contactMeMaster.account.lastname}`
      );
    },
  },
});

export const getValidate =
  (_: IExtraData) => (formData: any, errors: any, _mergedFormData: any) => {
    if (!formData[EField.Signers] && !formData[EField.SignAsBroker]) {
      errors[EField.Signers].addError(
        utils.i18n.t("document_package.form.signers.error.required"),
      );
    }

    return errors;
  };
