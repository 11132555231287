import { EPaymentRepetitionType, } from "@rentiohq/shared-frontend/dist/types/payment.types";
export var isEditable = function (paymentRequest) {
    return paymentRequest.paymentRequestItems.every(function (paymentRequestItem) { return paymentRequestItem.paymentOrder.isEditable; });
};
export var hasRecurring = function (paymentRequest) {
    return paymentRequest.paymentRequestItems.some(function (paymentRequestItem) {
        return paymentRequestItem.paymentOrder.repetitionType !==
            EPaymentRepetitionType.Once;
    });
};
export var getReadableType = function (paymentRequest, translateFunction) {
    var allTypes = new Set(paymentRequest.paymentRequestItems.map(function (paymentRequestItem) {
        return paymentRequestItem.type;
    }));
    var asArray = Array.from(allTypes);
    if (asArray.length === 1)
        return translateFunction(asArray[0]);
    else {
        return asArray.map(function (type) { return translateFunction(type); }).join(", ");
    }
};
