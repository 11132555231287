import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getMembersWithOneOfRoles } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  AccountList,
  Card,
  ESpacings,
} from "@rentiohq/web-shared/dist/components";
import { ts } from "@rentiohq/web-shared/dist/services";
import React from "react";

interface IProps {
  property: IProperty;
  roles: EPropertyMemberTypes[];
  showRoles: boolean;
  heading?: React.ReactNode;
  space?: ESpacings;
  children?: React.ReactNode;
}

export const PropertyRolesCard = (props: IProps) => {
  const {
    property,
    roles,
    showRoles,
    heading,
    space = ESpacings.extraLoose,
  } = props;

  const members = getMembersWithOneOfRoles(property.members, roles);

  const getSubtitle = showRoles
    ? (accountIds: number[]) => {
        const member = members.find(member =>
          accountIds.includes(member.account.id!),
        );

        if (!member) {
          return "";
        }

        return join(
          member
            .roles!.filter((role: any) => roles.includes(role))
            .map(role => ts.role(role)),
        );
      }
    : undefined;

  return (
    <Card heading={heading} space={space}>
      <AccountList
        accounts={members.map(member => member.account)}
        boxProps={{ py: ESpacings.base }}
        getSubtitle={getSubtitle}
      />
    </Card>
  );
};
