import { IApplication } from "@rentiohq/shared-frontend/dist/types/application.types";
import { IUser } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";

export interface IExtraData {
  property: IProperty;
  application: IApplication;
  user: IUser;
  localeMessages: { [key: string]: string | undefined };
}

export enum EField {
  TenantFeedbackText = "tenantFeedbackText",
}
