import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { useSelf } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as rentioLegalHooks from "@rentiohq/shared-frontend/dist/reduxV2/legalCase/legalCase.hooks";
import { activateRentioLegalFeature } from "@rentiohq/shared-frontend/dist/reduxV2/legalCase/legalCase.utils";
import {
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { ELegalCaseStatus } from "@rentiohq/shared-frontend/dist/types/legalCase.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMembersWithRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  Button,
  Card,
  DisplayText,
  ESpacings,
  Icon,
  Loading,
  RentioInternalRenderer,
  Stack,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { Colors } from "@rentiohq/web-theme";
import { compact } from "lodash";
import { useNavigate } from "react-router-dom";
import StatusDot, { EStatus } from "scenes/Properties/components/StatusDot";
import { useIsRentioLegalActivated } from "scenes/RentioLegal/hooks/useIsRentioLegalActivated";
import { StringParam } from "serialize-query-params";
import * as S from "./RentioLegalCard.styles";

interface IProps {
  contract?: IContract;
  onActivateRentioLegal?: (contract: IContract) => void;
}

export const RentioLegalCard = (props: IProps) => {
  const { contract, onActivateRentioLegal } = props;

  const { broker } = useSelf();
  const hasRentioLegal = useIsRentioLegalActivated();

  const navigate = useNavigate();

  const [_, setQueryParams] = useQueryParams({
    legalCaseId: StringParam,
  });

  // Redux
  const { items: legalCases, isFetching: isFetchingLegalCases } =
    rentioLegalHooks.useGetAll({
      query: contract
        ? {
            filter: {
              contractId: { eq: contract.id },
            },
          }
        : undefined,
    });

  const openCases = legalCases?.filter(
    legalCase => legalCase.status === ELegalCaseStatus.Open,
  );
  const closedCases = legalCases?.filter(
    legalCase => legalCase.status === ELegalCaseStatus.Closed,
  );

  // Event handlers
  const handleStartNewRentioLegalCase = () => {
    if (!hasRentioLegal) {
      return;
    }

    if (!contract) {
      return;
    }

    navigate(`/rentio-legal/add?contractId=${contract.id}`);
  };

  // Render
  const renderContractStatusInfo = () => {
    if (!contract) {
      return null;
    }

    const tenants = getMembersWithRole(
      contract.members,
      EContractMemberTypes.Tenant,
    );

    const { startDate, stopDate } = contract;

    const contractPeriod = compact([startDate, stopDate])
      .map(date => formatDate(date))
      .join(" - ");

    const strTenantNames = `${tenants
      .map(x => getName(x.account))
      .join(" / ")}`;

    const message = contract.legalLicenseActivatedAt
      ? `${getLocalizedText("rentio_legal.actions.activated_since", {
          value: formatDate(contract.legalLicenseActivatedAt),
        })}`
      : getLocalizedText("rentio_legal.actions.activate.description");

    return (
      <TextStyle>
        {strTenantNames} {contractPeriod}
        <br />
        {message}
      </TextStyle>
    );
  };

  const renderCtaButton = (params: {
    color: Colors;
    title: string;
    onClick: () => void;
  }) => {
    const { color, onClick, title } = params;

    return (
      <Button
        appearance="ghost"
        color={color}
        onClick={onClick}
        iconAfter={<Icon source="chevronRight" size="medium" />}
        style={{
          width: "100%",
        }}
      >
        {title}
      </Button>
    );
  };

  if (!broker) {
    return null;
  }

  if (!legalCases && isFetchingLegalCases) {
    return (
      <Card>
        <Loading />
      </Card>
    );
  }

  return (
    <Card
      heading={
        <Stack spacing="tight">
          <Icon source="legalHammer" size="medium" />

          <DisplayText size="extraSmall" space="tight">
            {getLocalizedText("rentio_legal.card.header")}
          </DisplayText>
        </Stack>
      }
      space={ESpacings.loose}
    >
      {!hasRentioLegal && (
        <Stack vertical={true}>
          <TextStyle>
            {getLocalizedText(
              "rentio_legal.actions.activate_feature.description",
            )}{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={getLocalizedText(
                "rentio_legal.actions.activate_feature.link",
              )}
            >
              {getLocalizedText("system.more_info")}
            </a>
          </TextStyle>

          {renderCtaButton({
            color: "blue",
            onClick: () => {
              activateRentioLegalFeature(broker);
            },
            title: getLocalizedText("rentio_legal.actions.activate_feature"),
          })}
        </Stack>
      )}

      {hasRentioLegal && !contract?.legalLicenseActivatedAt && (
        <Stack vertical={true}>
          <Stack alignment="center" noWrap={true}>
            <StatusDot status={EStatus.Warning} />
            {renderContractStatusInfo()}
          </Stack>

          {renderCtaButton({
            color: "yellow",
            onClick: () => {
              if (contract) {
                onActivateRentioLegal?.(contract);
              }
            },
            title: getLocalizedText("rentio_legal.actions.activate"),
          })}
        </Stack>
      )}

      {hasRentioLegal &&
        contract?.legalLicenseActivatedAt &&
        openCases &&
        openCases.length === 0 && (
          <Stack vertical={true}>
            <Stack vertical={false} alignment="center">
              <StatusDot status={EStatus.Success} />
              {renderContractStatusInfo()}
            </Stack>

            {renderCtaButton({
              color: "green",
              onClick: () => {
                handleStartNewRentioLegalCase();
              },
              title: getLocalizedText("rentio_legal.actions.start_new_case"),
            })}
          </Stack>
        )}

      {openCases?.map(openCase => {
        return (
          <S.RentioLegalOngoingCaseWrapper
            onClick={() => {
              setQueryParams({ legalCaseId: openCase.id });
            }}
          >
            {getLocalizedText("rentio_legal.status.start_date.text", {
              start_date: formatDate(openCase.startDate),
            })}
            <Icon source="chevronRight" size="mediumLarge" />
          </S.RentioLegalOngoingCaseWrapper>
        );
      })}

      {closedCases && closedCases.length > 0 && (
        <S.RentioLegalClosedCasesWrapper>
          {closedCases.map(closedCase => (
            <S.RentioLegalClosedCase
              onClick={() => {
                setQueryParams({ legalCaseId: closedCase.id });
              }}
            >
              {getLocalizedText("rentio_legal.status.end_date.text", {
                value: formatDate(closedCase.closedAt),
              })}
              <Icon source="chevronRight" size="mediumLarge" />
            </S.RentioLegalClosedCase>
          ))}
        </S.RentioLegalClosedCasesWrapper>
      )}

      <RentioInternalRenderer
        items={{
          "Activated date": contract?.legalLicenseActivatedAt
            ? formatDate(contract.legalLicenseActivatedAt)
            : undefined,
          "Renewal date": contract?.legalLicenseRenewalDate
            ? formatDate(contract.legalLicenseRenewalDate)
            : undefined,
        }}
      />
    </Card>
  );
};
