import { getSchema as getSchema1, uiSchema as uiSchema1, } from "./schema.createChat.1";
import { getInitialValues } from "./schema.createChat.utils";
// eslint-disable-next-line import/no-default-export
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema1(initialValues, extraData),
            uiSchema: uiSchema1(extraData),
        },
    ];
});
