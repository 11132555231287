var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DisplayText } from "../../../DisplayText";
import * as S from "../../Form.styled";
export var ObjectFieldTemplate = function (_a) {
    var title = _a.title, description = _a.description, properties = _a.properties, className = _a.className, rest = __rest(_a, ["title", "description", "properties", "className"]);
    return (_jsxs(S.Fieldset, { className: className, children: [title && _jsx(DisplayText, { size: "medium", children: title }), description && _jsx("p", { children: description }), properties.map(function (element) { return element.content; })] }));
};
