import { Route } from "react-router-dom";
import Contacts from "./";

export const getContactsScene = () => {
  return (
    <Route path="contacts">
      <Route index element={<Contacts />} />
    </Route>
  );
};
