import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { Stages } from "@rentiohq/web-shared/dist/components";
import React from "react";
import { ERenewalDateWithinMonths } from "../../EndingContracts.types";
import { getRenewalDateFilterQuery } from "../../EndingContracts.utils";

interface IProps {
  activeRenewalDateWithinMonths?: ERenewalDateWithinMonths;
  onClickActiveRenewalDate: (deadline: ERenewalDateWithinMonths) => void;
  getUrl?: (renewal: ERenewalDateWithinMonths) => string;
}

const EndingContractsStageRenewalDate: React.FC<IProps> = props => {
  const { activeRenewalDateWithinMonths, onClickActiveRenewalDate, getUrl } =
    props;

  const { count: deadlineWithin1MonthCount = 0 } = contractHooks.useCount({
    query: getRenewalDateFilterQuery({
      tacitRenewal: true,
      renewalDateWithinMonths: ERenewalDateWithinMonths.Within1Month,
    }),
    shouldRefetch: true,
  });
  const { count: deadlineWithin3MonthsCount = 0 } = contractHooks.useCount({
    query: getRenewalDateFilterQuery({
      tacitRenewal: true,
      renewalDateWithinMonths: ERenewalDateWithinMonths.Within3Months,
    }),
    shouldRefetch: true,
  });

  return (
    <Stages
      stages={Object.values(ERenewalDateWithinMonths).map(id => {
        let count = 0;
        switch (id) {
          case ERenewalDateWithinMonths.Within3Months:
            count = deadlineWithin3MonthsCount;
            break;

          case ERenewalDateWithinMonths.Within1Month:
            count = deadlineWithin1MonthCount;
            break;

          default:
            break;
        }

        return {
          heading: getLocalizedText(
            `ending_contracts.option.renewal_date_within_months.${stringToSnakeCase(
              id,
            )}`,
          ),
          icon: "businessContractBreak",
          count,
          url: getUrl?.(id),
          onClick: () => {
            onClickActiveRenewalDate(id);
          },
          isActive: id === activeRenewalDateWithinMonths,
        };
      })}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default EndingContractsStageRenewalDate;
