import { useTheme } from "@rentiohq/web-shared-next/dist/themes";
import { EFontWeights } from "@rentiohq/web-shared-next/dist/themes/types";
import { ECaptionVariant } from "../../types";

export const DocumentListItemStyles = (
  captionColor: ECaptionVariant = ECaptionVariant.Default,
) => {
  const theme = useTheme();

  const getCaptionColor = () => {
    switch (captionColor) {
      case ECaptionVariant.Success:
        return theme.palette.success.main;
      case ECaptionVariant.Warning:
        return theme.palette.warning.main;
      case ECaptionVariant.Error:
        return theme.palette.error.main;
      default:
        return theme.palette.text.secondary;
    }
  };
  return {
    content: {
      caption: {
        color: getCaptionColor(),
        fontWeight: EFontWeights.Regular,
      },
    },
  };
};
