import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { MultipleChoice } from "@rentiohq/web-shared-next/dist/ui-components";
import { Choice, EProblemCategory } from "../types";

interface IProps {
  title: string;
  choices: Choice<EProblemCategory>[];
  category?: EProblemCategory;
  handleSelect: (choice: EProblemCategory) => void;
}

export const ProblemCategories = ({
  title,
  choices,
  category,
  handleSelect,
}: IProps) => {
  return (
    <MultipleChoice<EProblemCategory>
      title={getLocalizedText(title)}
      choices={choices}
      selectedChoice={category}
      handleSelect={handleSelect}
    />
  );
};
