import * as activityActions from "@rentiohq/shared-frontend/dist/redux/activity/activity.actions";
import * as activitySelectors from "@rentiohq/shared-frontend/dist/redux/activity/activity.selectors";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as countTypes from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import { IRentDeposit } from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { startOfDay } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Box,
  Button,
  SimpleCard,
  SpinningLoader,
} from "@rentiohq/web-shared-next/dist/ui-components";
import {
  EButtonColors,
  EButtonVariants,
} from "@rentiohq/web-shared-next/dist/utils/types/button";
import { IPartialState } from "@rentiohq/web-shared/dist/components/Activities/Activities.types";
import groupBy from "lodash/groupBy";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActivityGroup } from "../../../../../ActivityGroup";
import { ERentDepositTabs } from "../../RentDeposit";
import { getRentDepositActivityFilter } from "./RentDeposit.utils";

interface IProps {
  limit?: number;
  rentDeposit: IRentDeposit;
  loadMore?: (tab: ERentDepositTabs) => void;
}

export const RentDepositHistory = ({
  rentDeposit,
  loadMore,
  limit = 20,
}: IProps) => {
  const identifier = `rent-deposit-${rentDeposit?.id}`;
  const dispatch = useDispatch();

  const [skip, setSkip] = React.useState(0);

  const activities =
    useSelector((state: IPartialState) =>
      activitySelectors.getActivities(state, identifier),
    ) || [];

  const activitiesCount =
    useSelector((state: IPartialRootState) =>
      countSelectors.getCount(state, `activities-${identifier}`),
    ) || 0;

  const activitiesFetchError = useSelector((state: IPartialState) =>
    activitySelectors.getFetchActivitiesError(state, identifier),
  );

  const isFetchingActivities = useSelector((state: IPartialState) =>
    activitySelectors.getIsFetchingActivities(state, identifier),
  );

  React.useEffect(() => {
    fetchCount();
  }, []);

  React.useEffect(() => {
    if (isFetchingActivities) {
      return;
    }

    fetchActivities(!skip);
  }, [skip]);

  const fetchActivities = (refetch: boolean) => {
    dispatch(
      activityActions.getActivities.actions.start({
        activityIdentifier: identifier,
        refetch,
        limit,
        extraFilterData: getRentDepositActivityFilter(rentDeposit),
      }),
    );
  };

  const fetchCount = () => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: `activities-${identifier}`,
        countBase: append(
          countTypes.COUNT_BASE[countTypes.ECountIdentifier.Activities],
          { where: getRentDepositActivityFilter(rentDeposit) },
        ),
      }),
    );
  };

  if (activitiesFetchError) {
    return <p>{getLocalizedText("fetch.error")}</p>;
  }

  const fetchMore = () => setSkip(skip + 20);

  const renderActivities = () => {
    const groupedActivities = groupBy(activities, (activity: any) =>
      startOfDay(new Date(activity.datetime)),
    );

    const sortedActivitiesKeys = Object.keys(groupedActivities).sort(
      (a, b) => new Date(b).getTime() - new Date(a).getTime(),
    );

    return (
      <Box>
        {sortedActivitiesKeys.map((groupedDate: any) => {
          return (
            <ActivityGroup
              groupedDate={groupedDate}
              groupedActivities={groupedActivities[groupedDate]}
            />
          );
        })}
      </Box>
    );
  };

  const renderLoadMore = () => {
    if (activitiesCount <= activities.length) {
      return null;
    }

    if (isFetchingActivities) {
      return null;
    }

    return (
      <Button
        onClick={() =>
          loadMore ? loadMore(ERentDepositTabs.History) : fetchMore()
        }
        variant={EButtonVariants.Text}
        color={EButtonColors.Success}
      >
        {getLocalizedText("system.load_more")}
      </Button>
    );
  };

  return (
    <SimpleCard title={getLocalizedText("system.history")}>
      {isFetchingActivities ? (
        <SpinningLoader />
      ) : (
        <>
          {renderActivities()}
          {renderLoadMore()}
        </>
      )}
    </SimpleCard>
  );
};
