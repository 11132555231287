var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import keyBy from "lodash/keyBy";
import merge from "lodash/merge";
import { createReducer } from "redux-act";
import * as activityActions from "./activity.actions";
export var REDUCER_KEY_ACTIVITY = "activity";
var getDefaultState = function () { return ({
    activitiesByIdentifier: {},
    pagedActivities: {},
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on(activityActions.getActivities.types.START, function (state, _a) {
    var _b;
    var activityIdentifier = _a.activityIdentifier, activityTypes = _a.activityTypes, extraFilterData = _a.extraFilterData;
    return merge({}, state, {
        activitiesByIdentifier: (_b = {},
            _b[activityIdentifier] = {
                activityTypes: activityTypes,
                extraFilterData: extraFilterData,
                isFetching: true,
                fetchError: undefined,
            },
            _b),
    });
});
reducer.on(activityActions.getActivities.types.SUCCESS, function (state, _a) {
    var _b;
    var activityIdentifier = _a.activityIdentifier, refetch = _a.refetch, activities = _a.activities, isExhausted = _a.isExhausted;
    return __assign(__assign({}, state), { activitiesByIdentifier: __assign(__assign({}, state.activitiesByIdentifier), (_b = {}, _b[activityIdentifier] = __assign(__assign({}, state.activitiesByIdentifier[activityIdentifier]), { isFetching: false, fetchError: undefined, isExhausted: isExhausted, activities: refetch
                ? activities
                : __spreadArray(__spreadArray([], (state.activitiesByIdentifier[activityIdentifier]
                    .activities || []), true), activities, true) }), _b)) });
});
reducer.on(activityActions.getActivities.types.FAILURE, function (state, _a) {
    var _b;
    var activityIdentifier = _a.activityIdentifier, error = _a.error;
    return merge({}, state, {
        activitiesByIdentifier: (_b = {},
            _b[activityIdentifier] = {
                isFetching: false,
                fetchError: error,
            },
            _b),
    });
});
reducer.on(activityActions.getActivitiesPaged.types.START, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, filterData = _a.filterData;
    return merge({}, state, {
        pagedActivities: (_b = {},
            _b[identifier] = {
                filterData: filterData,
                pages: (_c = {},
                    _c[page] = {
                        isFetching: true,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(activityActions.getActivitiesPaged.types.SUCCESS, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, activities = _a.activities;
    var activityIds = activities.map(function (_a) {
        var id = _a.id;
        return id;
    });
    return merge({}, state, {
        // @ts-ignore
        activitiesById: __assign({}, keyBy(activities, "id")),
        pagedActivities: (_b = {},
            _b[identifier] = {
                pages: (_c = {},
                    _c[page] = {
                        activityIds: activityIds,
                        isFetching: false,
                        fetchError: undefined,
                    },
                    _c),
            },
            _b),
    });
});
reducer.on(activityActions.getActivitiesPaged.types.FAILURE, function (state, _a) {
    var _b, _c;
    var identifier = _a.identifier, page = _a.page, error = _a.error;
    return merge({}, state, {
        pagedActivities: (_b = {},
            _b[identifier] = {
                pages: (_c = {},
                    _c[page] = {
                        isFetching: false,
                        fetchError: error,
                    },
                    _c),
            },
            _b),
    });
});
// eslint-disable-next-line import/no-default-export
export default reducer;
