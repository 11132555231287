import { ERentioFormField } from "../../components/components/Form";
import { contactSelectInlineV2ValueSelector, getValue, } from "../../redux/form/form.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField } from "./schema.releaseRentDeposit.types";
import { getNavigationTitle, getSelectedTenants, } from "./schema.releaseRentDeposit.utils";
export var getSchema = function (initialValues, _) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.TenantReceiver],
            properties: (_a = {},
                _a[EField.Header + "6"] = {
                    default: undefined,
                },
                _a[EField.TenantReceiver] = {
                    default: getValue(EField.TenantReceiver, initialValues, defaultValues),
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("rent_deposit.receiver.tenant.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "6"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("rent_deposit.receiver.heading"),
        },
        _a[EField.TenantReceiver] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            title: getLocalizedText("rent_deposit.receiver.tenant.label"),
            isMultiSelect: false,
            asOptionList: true,
            customExtraAccountsSelector: function (_, formData) {
                return getSelectedTenants(extraData.relatedContract, formData);
            },
            valueSelector: function (state, selectedAccountIds, formData) {
                if (selectedAccountIds === void 0) { selectedAccountIds = []; }
                var relatedContract = extraData.relatedContract;
                return contactSelectInlineV2ValueSelector(state, selectedAccountIds, relatedContract
                    ? relatedContract.members.map(function (member) { return member.account; })
                    : []);
            },
            customGetSubtitleSelector: function (_, formData, item) {
                var accountId = item.accountId, contact = item.contact;
                var relatedContract = extraData.relatedContract;
                var member = relatedContract
                    ? relatedContract.members.find(function (member) {
                        if (accountId && accountId === member.account.id) {
                            return true;
                        }
                        if (contact === null || contact === void 0 ? void 0 : contact.accountIds.includes(member.account.id)) {
                            return true;
                        }
                        return false;
                    })
                    : undefined;
                if (!member) {
                    return;
                }
                return member.roles
                    .map(function (role) { return getLocalizedText("role.".concat(role.toLowerCase())); })
                    .join(", ");
            },
            customIsFetchingSelector: function () { return false; },
            customGetContactsActions: [],
        },
        _a);
};
