import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.manuallyMarkPaid.types";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    return {
      required: [EField.PaidAtDate, EField.Amount],
      properties: {
        [EField.PaidAtDate]: {
          default: getValue(EField.PaidAtDate, initialValues, defaultValues),
        },
        [EField.Amount]: {
          default: getValue(EField.Amount, initialValues, defaultValues),
          minimum: 0,
          maximum: extraData.paymentRequest.amount,
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData): IUiSchema<EField> => ({
  [EField.PaidAtDate]: {
    "ui:field": ERentioFormField.Date,
    title: getLocalizedText("payment_request.alert.mark_paid_confirm.info"),
    maxDate: new Date(),
  },
  [EField.Amount]: {
    "ui:field": ERentioFormField.Number,
    title: getLocalizedText("payment_request.alert.mark_paid_confirm.amount"),
    isFloat: true,
  },
});
