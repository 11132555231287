import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as requirementsSelectors from "@rentiohq/shared-frontend/dist/redux/requirements/requirements.selectors";
import { makeGetUnreadChatsCount } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import {
  ETacPlatform,
  EUserFeature,
} from "@rentiohq/shared-frontend/dist/types/auth.types";
import { getLocale } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { UpdateTac } from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";

interface IBackgroundChecksProps {}

// TODO: Split into multiple files
// 1. Show TAC modal or not
// 2. Update favicon for unread chats
export const BackgroundChecks: React.FC<IBackgroundChecksProps> = () => {
  const faviconElement = React.useRef<HTMLLinkElement>(
    document.querySelector("link[rel*='icon']"),
  );

  const unreadChatsCount: number = useSelector(makeGetUnreadChatsCount());

  const { user } = authHooks.useSelf();

  const requirements = useSelector((state: IRootStore) =>
    requirementsSelectors.getRequirements(state),
  );
  const { internalModeEnabled } = useInternalMode();

  const showUpdateTac = (() => {
    if (!user || !requirements) {
      return false;
    }

    if (internalModeEnabled && !user.features?.includes(EUserFeature.Monitor)) {
      return false;
    }

    // No TAC (nothing accepted so far)
    const tacDashboard = user.tac?.dashboard;
    if (!tacDashboard) {
      // eslint-disable-next-line no-console
      console.log("Show tac update", {
        userTac: user.tac,
        tacDashboard,
        requirements,
        reason: "No TAC (nothing accepted so far)",
      });

      return true;
    }

    // Accepted TAC version is outdated
    if (tacDashboard.version < requirements.dashboardRequiredTac) {
      // eslint-disable-next-line no-console
      console.log("Show tac update", {
        userTac: user.tac,
        tacDashboard,
        requirements,
        reason: "Accepted TAC version is outdated",
      });

      return true;
    }

    return false;
  })();

  React.useEffect(() => {
    if (!faviconElement.current) {
      return;
    }
    if (unreadChatsCount > 0) {
      faviconElement.current.href = faviconElement.current.dataset
        .notificationIcon as string;
    } else {
      faviconElement.current.href = faviconElement.current.dataset
        .originalIcon as string;
    }
  }, [unreadChatsCount]);

  if (!showUpdateTac) {
    return null;
  }

  return (
    <UpdateTac
      brokerId={CONFIG.RENTIO_BROKER_ID}
      platform={ETacPlatform.Dashboard}
      locale={getLocale()}
    />
  );
};
