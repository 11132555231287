import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { Loading } from "../Loading";
export var ContractFetchListItem = function (_a) {
    var contract = _a.contract, contractId = _a.contractId, renderContract = _a.renderContract;
    var _b = contractHooks.useDetail({
        id: contractId,
    }), fetchedContract = _b.detail, isFetching = _b.isFetching;
    if (contract) {
        return _jsx(_Fragment, { children: renderContract(contract) });
    }
    if (isFetching) {
        return _jsx(Loading, { asDots: true });
    }
    if (fetchedContract) {
        return _jsx(_Fragment, { children: renderContract(fetchedContract) });
    }
    return null;
};
