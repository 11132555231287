import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./auth.reducer";
var PERSIST_CONFIG = {
    key: "auth",
    version: 2,
    storage: storage,
    whitelist: ["hasAccessToken", "user", "selectedDashboardType"],
};
// eslint-disable-next-line import/no-default-export
export default persistReducer(PERSIST_CONFIG, reducer);
