import {
  BrokerUsage,
  InvoiceDetail,
  InvoiceTable,
} from "@rentiohq/web-shared/dist/components";
import { Navigate, Route } from "react-router-dom";
import { ContactCostCenters } from "scenes/Contacts/scenes/ContactCostCenters";
import { ContactGeneralLedgerAccounts } from "scenes/Contacts/scenes/ContactGeneralLedgerAccounts";
import { ExportFiles } from "scenes/Profile/scenes/ExportFiles";
import { ContactBankAccounts } from "../../components/ContactBankAccounts/ContactBankAccounts";
import { Company } from "./Company";
import { AccountHolder } from "./scenes/AccountHolder";
import { Bookkeeping } from "./scenes/Bookkeeping";
import { BrokerContact } from "./scenes/BrokerContact";
import Employees from "./scenes/Employees";
import { LegalContact } from "./scenes/LegalContact";

export const getCompanyScene = () => (
  <Route path="company" element={<Company />}>
    <Route path="contact" element={<BrokerContact />} />

    <Route path="financial" element={<ContactBankAccounts />} />

    <Route path="legal" element={<LegalContact />} />

    <Route path="account-holder" element={<AccountHolder />} />

    <Route path="employees" element={<Employees />} />

    <Route path="usage" element={<BrokerUsage />} />

    <Route path="export-files" element={<ExportFiles />} />

    <Route path="bookkeeping" element={<Bookkeeping />} />

    <Route
      path="cost-centers"
      element={<ContactCostCenters asCard={false} />}
    />

    <Route
      path="general-ledger-accounts"
      element={<ContactGeneralLedgerAccounts asCard={false} />}
    />

    <Route path="invoices" element={<InvoiceTable />} />

    <Route path="invoices/:invoiceId" element={<InvoiceDetail />} />

    <Route index element={<Navigate to="contact" replace={true} />} />
  </Route>
);
