var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createReducer } from "redux-act";
import { cardActionCallback, cardActionDeeplink, closeCard, generateExtraCards, getCards, } from "./card.actions";
export var REDUCER_KEY_CARD = "card";
var getDefaultState = function () { return ({
    cards: undefined,
    isFetchingCards: false,
    fetchCardsError: undefined,
    extraCards: undefined,
    isGeneratingExtraCards: false,
    generateExtraCardsError: undefined,
    cardActions: {},
    closedCardIds: [],
}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(getCards.types.START, function (state) { return (__assign(__assign({}, state), { isFetchingCards: true, fetchCardsError: undefined })); });
reducer.on(getCards.types.SUCCESS, function (state, cards) { return (__assign(__assign({}, state), { cards: cards, isFetchingCards: false, fetchCardsError: undefined })); });
reducer.on(getCards.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isFetchingCards: false, fetchCardsError: error }));
});
reducer.on(generateExtraCards.types.START, function (state) { return (__assign(__assign({}, state), { isGeneratingExtraCards: true, generateExtraCardsError: undefined })); });
reducer.on(generateExtraCards.types.SUCCESS, function (state, _a) {
    var extraCards = _a.extraCards;
    return (__assign(__assign({}, state), { extraCards: extraCards, isGeneratingExtraCards: false, generateExtraCardsError: undefined }));
});
reducer.on(generateExtraCards.types.FAILURE, function (state, _a) {
    var error = _a.error;
    return (__assign(__assign({}, state), { isGeneratingExtraCards: false, generateExtraCardsError: error }));
});
var getCardAction = function (id, state) {
    return state.cardActions[id] || {
        isPerformingAction: false,
        performActionError: undefined,
    };
};
var cardActionGenericStart = function (state, _a) {
    var _b;
    var cardId = _a.cardId, actionIndex = _a.actionIndex;
    var id = "".concat(cardId, "-").concat(actionIndex);
    var cardAction = getCardAction(id, state);
    return __assign(__assign({}, state), { cardActions: __assign(__assign({}, state.cardActions), (_b = {}, _b[id] = __assign(__assign({}, cardAction), { isPerformingAction: true, performActionError: undefined }), _b)) });
};
var cardActionGenericSuccess = function (state, _a) {
    var _b;
    var cardId = _a.cardId, actionIndex = _a.actionIndex, rest = __rest(_a, ["cardId", "actionIndex"]);
    var id = "".concat(cardId, "-").concat(actionIndex);
    var cardAction = getCardAction(id, state);
    return __assign(__assign({}, state), { cardActions: __assign(__assign({}, state.cardActions), (_b = {}, _b[id] = __assign(__assign(__assign({}, cardAction), rest), { isPerformingAction: false, performActionError: undefined }), _b)) });
};
var cardActionGenericFailure = function (state, _a) {
    var _b;
    var cardId = _a.cardId, actionIndex = _a.actionIndex, error = _a.error;
    var id = "".concat(cardId, "-").concat(actionIndex);
    var cardAction = getCardAction(id, state);
    return __assign(__assign({}, state), { cardActions: __assign(__assign({}, state.cardActions), (_b = {}, _b[id] = __assign(__assign({}, cardAction), { isPerformingAction: false, performActionError: error }), _b)) });
};
reducer.on(cardActionCallback.types.START, cardActionGenericStart);
reducer.on(cardActionCallback.types.SUCCESS, 
// @ts-ignore
cardActionGenericSuccess);
reducer.on(cardActionCallback.types.FAILURE, cardActionGenericFailure);
reducer.on(cardActionDeeplink.types.START, cardActionGenericStart);
reducer.on(cardActionDeeplink.types.SUCCESS, cardActionGenericSuccess);
reducer.on(cardActionDeeplink.types.FAILURE, cardActionGenericFailure);
reducer.on(closeCard.types.START, function (state, _a) {
    var cardId = _a.cardId;
    return (__assign(__assign({}, state), { closedCardIds: __spreadArray(__spreadArray([], state.closedCardIds, true), [cardId], false) }));
});
reducer.on(closeCard.types.FAILURE, function (state, _a) {
    var cardId = _a.cardId;
    return (__assign(__assign({}, state), { closedCardIds: __spreadArray([], state.closedCardIds.filter(function (randomCardId) { return cardId !== randomCardId; }), true) }));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
