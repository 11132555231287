import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import * as templateDocumentHooks from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.hooks";
import {
  ERecipientType,
  ITemplateDocument,
} from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import * as templateDocumentsActionsV2 from "@rentiohq/shared-frontend/dist/reduxV2/templateDocuments/templateDocument.actions";
import { EDocumentPackageTypes } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import {
  Card,
  Loading,
  MultiStepForm,
  Page,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import {
  BooleanParam,
  NumberParam,
  StringParam,
  createEnumParam,
} from "serialize-query-params";
import createDocumentPackageForm from "../../forms/createDocumentPackage";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "../../forms/createDocumentPackage/schema.createDocumentPackage.actions";
import { ts } from "../../services";
import { getBreadcrumb } from "./DocumentPackageForm.utils";

const generatedFormId = generateFormId();

export const DocumentPackageForm: React.FC<{}> = React.memo(() => {
  const navigate = useNavigate();

  // Redux
  const dispatch = useDispatch();

  const [params] = useQueryParams({
    propertyId: NumberParam,
    contractId: StringParam,
    documentIdToSign: StringParam,
    documentPackageType: createEnumParam(Object.values(EDocumentPackageTypes)),
    sendToTenant: BooleanParam,
    templateDocumentId: StringParam,
  });
  const propertyId = params.propertyId;
  const contractId = params.contractId;
  const documentId = params?.documentIdToSign;
  const documentPackageType = params?.documentPackageType;
  const sendToTenant = params?.sendToTenant;

  const contactMeMaster = useSelector((state: IRootStore) =>
    contactSelectors.getContactMeMaster(state),
  );

  const { detail: contract } = contractHooks.useDetail({ id: contractId });

  const { detail: property } = propertyHooks.useDetail({
    id: propertyId,
  });

  const { detail: document } = documentHooks.useDetail({
    id: documentId,
  });

  const { detail: templateDocument } = templateDocumentHooks.useDetail({
    id: document?.templateDocumentId,
  });

  const { isBroker } = authHooks.useSelf();

  const legalContact = useSelector((state: IRootStore) =>
    isBroker
      ? contactSelectors.getContactByCustomId(state, EContactCustomId.Legal)
      : undefined,
  );
  const isFetchingLegalContact = useSelector((state: IRootStore) =>
    isBroker
      ? state.contact.contactsById[EContactCustomId.Legal].isFetching
      : false,
  );

  const handleSuccess = (documentPackage: any) => {
    handleFinish(documentPackage);
  };

  const handleFinish = (documentPackage: any) => {
    if (templateDocument && sendToTenant) {
      dispatch(
        templateDocumentsActionsV2.templateDocumentExternalCompletionStart.getAction(
          {
            id: templateDocument.id,
            data: {
              propertyId: templateDocument?.propertyId || 0,
              contractId: templateDocument?.contractId,
              documentId,
              recipients: [ERecipientType.Tenant],
            },
            onSuccess: () => {
              dispatch(
                templateDocumentActions.getDetail.actions.start({
                  id: templateDocument.id,
                  onSuccess: (updatedTemplateDocument: ITemplateDocument) => {
                    navigate(
                      `/properties/${updatedTemplateDocument?.propertyId}/documents?documentId=${updatedTemplateDocument.documentId}`,
                    );
                  },
                }),
              );
            },
          },
        ),
      );
    } else {
      if (propertyId && contractId) {
        navigate(
          `/properties/${documentPackage.propertyId}/contracts?documentPackageId=${documentPackage.id}`,
        );

        return;
      }

      if (propertyId) {
        navigate(
          `/properties/${documentPackage.propertyId}/documents?documentPackageId=${documentPackage.id}`,
        );

        return;
      }

      navigate(
        `/follow-up/signatures?page=1&documentPackageId=${documentPackage.id}`,
      );
    }
  };

  if (isBroker && isFetchingLegalContact) {
    return <Loading />;
  }

  if (propertyId && !property) {
    return <Loading />;
  }

  if (contractId && !contract) {
    return <Loading />;
  }

  if (documentId && !documentId) {
    return <Loading />;
  }

  if (document?.templateDocumentId && !templateDocument) {
    return <Loading />;
  }

  const signerFields = sendToTenant ? templateDocument?.signerFields : {};

  const extraData = {
    document,
    contract,
    property,
    templateDocument,
    documentPackageType,

    contactMeMaster,
    legalContact,
    isBroker,
    signerFields,
    sendToTenant,
  };

  return (
    <Page
      title={
        sendToTenant
          ? ts.documentPackageFromSendToTenantHeading()
          : ts.documentPackageFormHeading()
      }
      subtitle={property ? formatAddress(property) : undefined}
      breadcrumbs={getBreadcrumb({ propertyId, documentId })}
    >
      <Card>
        <MultiStepForm
          formId={`document-package-${generatedFormId}`}
          // schemas={schemas}
          schemas={createDocumentPackageForm(extraData)}
          withSummary={true}
          submitLabel={ts.system("save_send")}
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            extraData,
          )}
          performingSubmitActionResultSelector={performingSubmitActionResultSelector(
            extraData,
          )}
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            extraData,
          )}
          submitActionCreator={(formData: any) =>
            submitActionCreator(extraData)(formData)
          }
          onSuccess={handleSuccess}
        />
      </Card>
    </Page>
  );
}, utils.components.arePropsEqual);
