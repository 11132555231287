import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import "draft-js/dist/Draft.css";
import React from "react";
import { useToggle } from "../../../../../../hooks/useToggle";
import LoopModal from "../../components/LoopModal";
import { FieldsContext } from "../../contexts/Fields.context";
import { ReadOnlyContext } from "../../contexts/ReadOnly.context";
import * as S from "../decorator.styles";
var FieldRenderer = function (props) {
    var entityKey = props.entityKey, contentState = props.contentState;
    var fields = React.useContext(FieldsContext).fields;
    var setIsShowingModal = React.useContext(ReadOnlyContext).setIsShowingModal;
    var entity = contentState.getEntity(entityKey);
    var data = entity.getData();
    var id = data.id, instancePosition = data.instancePosition, renderType = data.renderType;
    var loop = fields.loops.find(function (x) { return x.id === id; });
    var variableId = loop === null || loop === void 0 ? void 0 : loop.variableId;
    var variable = fields.variables.find(function (x) { return x.id === variableId; });
    var groupId = (variable === null || variable === void 0 ? void 0 : variable.groupId) || (loop === null || loop === void 0 ? void 0 : loop.groupId);
    var group = fields.groups.find(function (x) { return x.id === groupId; });
    var isLeading = instancePosition === "LEADING";
    // State
    var _a = useToggle(), showModal = _a[0], toggleModalOn = _a[2], toggleModalOff = _a[3];
    // Event handlers
    var handleShowModal = function () {
        setIsShowingModal(true);
        toggleModalOn();
    };
    var handleCloseModal = function () {
        setIsShowingModal(false);
        toggleModalOff();
    };
    // Render
    return (_jsxs(S.Wrap, { contentEditable: false, 
        // @ts-ignore
        readOnly: true, renderType: renderType, children: [_jsxs(S.Button, { onClick: handleShowModal, 
                // onMouseEnter={togglePopover}
                // onMouseLeave={togglePopover}
                contentEditable: false, 
                // @ts-ignore
                readOnly: true, renderType: renderType, isStart: isLeading, tintColor: "pink", children: [variable &&
                        group &&
                        "".concat(getLocalizedText(group.name), " - ").concat(getLocalizedText(variable.name)), !variable && group && "".concat(getLocalizedText(group.name)), !variable && !group && "Error loading loop"] }), showModal && _jsx(LoopModal, { id: id, onClose: handleCloseModal })] }));
};
// eslint-disable-next-line import/no-default-export
export default FieldRenderer;
