import { getSteps } from "scenes/Properties/scenes/Applications/PropertyDetailApplications.util";
import {
  getSchema as getSchema1,
  uiSchema as uiSchema1,
} from "./schema.duplicateApplication.1";
import { EField, IExtraData } from "./schema.duplicateApplication.types";

// eslint-disable-next-line import/no-default-export
export default (extraData: IExtraData) => {
  const { broker } = extraData;

  const intakeType = broker?.intakeType || 2;
  const steps = getSteps(intakeType);

  const defaultValues = {
    [EField.Step]: steps[0],
  };

  return [
    {
      schema: getSchema1(defaultValues, extraData, steps),
      uiSchema: uiSchema1(extraData),
    },
  ];
};
