import { connect } from "react-redux";
import { IbanSelect } from "./IbanSelect";
var mapStateToProps = function (state, props) {
    return {
        isFetching: props.customIsFetchingSelector(state),
        ibans: props.customIbansSelector(state),
    };
};
var mapDispatchToProps = function (dispatch, _a) {
    var _b = _a.customGetIbansActions, customGetIbansActions = _b === void 0 ? [] : _b;
    return ({
        getIbans: function () {
            customGetIbansActions.forEach(function (action) {
                dispatch(action());
            });
        },
    });
};
export var IbanSelectContainer = connect(mapStateToProps, mapDispatchToProps)(IbanSelect);
