import { jsx as _jsx } from "react/jsx-runtime";
import { getFieldTitle } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { DisplayText } from "../../DisplayText";
export var SubtitleField = function (_a) {
    var rawUiSchema = _a.uiSchema, _b = _a.formContext, formContext = _b === void 0 ? {} : _b;
    var uiSchema = rawUiSchema;
    // @ts-ignore TODO: Typescript upgrade
    var title = getFieldTitle(uiSchema, formContext);
    return (_jsx(DisplayText, { element: "h5", size: "small", children: title }));
};
