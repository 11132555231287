import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
// Context
export var EditorStateContext = React.createContext({
    editorState: undefined,
    setEditorState: function (newValue) { },
});
// Provider
var Provider = function (props) {
    var children = props.children, editorState = props.editorState, setEditorState = props.setEditorState;
    // Render
    return (_jsx(EditorStateContext.Provider, { value: {
            editorState: editorState,
            setEditorState: setEditorState,
        }, children: children }));
};
// eslint-disable-next-line import/no-default-export
export default Provider;
