import { jsx as _jsx } from "react/jsx-runtime";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EProduct } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import { Lozenge } from "../../../../../../components";
import { useInternalMode } from "../../../../../../redux/system/system.hooks";
import { isDefaultVariable } from "../../../InsertVariableModal/schemas/variableForm.utils";
export var DefaultVariableLozenge = function (props) {
    var variable = props.variable, renderWrapper = props.renderWrapper;
    // Hooks
    var internalModeEnabled = useInternalMode().internalModeEnabled;
    var shouldShow = CONFIG.PRODUCT === EProduct.Backoffice || internalModeEnabled;
    // Render
    if (!shouldShow) {
        return null;
    }
    var renderContent = function () {
        if (isDefaultVariable({ variable: variable })) {
            return _jsx(Lozenge, { appearance: "success", children: "Default variable" });
        }
        return _jsx(Lozenge, { appearance: "warning", children: "Non-default variable" });
    };
    return (renderWrapper === null || renderWrapper === void 0 ? void 0 : renderWrapper(renderContent())) || renderContent();
};
