import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import {
  ERevenuesInvoicedStatus,
  ERevenuesPaymentStatus,
} from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import {
  EClientType,
  IBroker,
} from "@rentiohq/shared-frontend/dist/types/broker.types";
import { EDocumentPackageStatus } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import {
  EPaymentOrderOwnerPaymentMethod,
  EPaymentOrderType,
  EPaymentRepetitionType,
  IPaymentOrder,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { ITranslateProps } from "@rentiohq/web-shared/dist/utils/i18n";

const { i18n } = utils;

export const modelTypePrefix = (type: string) =>
  i18n.t(`system.model.${type.toLowerCase()}.prefix`);

export const modelType = (type: string) =>
  i18n.t(`system.model.${type.toLowerCase()}`);

// System
export const system = (key: string) => i18n.t(`system.${key}`);
export const firstname = () => i18n.t("system.firstname");
export const lastname = () => i18n.t("system.lastname");
export const fullName = () => i18n.t("full_name");
export const mobileNumber = () => i18n.t("system.mobile_number");
export const email = () => i18n.t("system.email");
export const VATNumber = () => i18n.t("system.vat_number");
export const box = () => i18n.t("system.box");
export const NationalRegisterNumber = () =>
  i18n.t("system.national_register_number");
export const PlaceOfBirth = () => i18n.t("system.place_of_birth");
export const locale = (locale: string) => i18n.t(`system.locale.${locale}`);

export const save = () => i18n.t("system.save");
export const logout = () => i18n.t("system.logout");
export const error = (statusCode: number) =>
  i18n.t(`system.error.${statusCode}`);

export const viewItem = (item: string) => i18n.t(`view.${item}`);
export const downloadItem = (item: string) => i18n.t(`download.${item}`);

export const filename = () => i18n.t("system.filename");
export const filesize = () => i18n.t("system.filesize");
export const filetype = () => i18n.t("system.filetype");
export const category = () => i18n.t("system.category");
export const uploadDate = () => i18n.t("system.date.upload");

export const month = () => i18n.t("system.month");

export const callImperative = () => i18n.t("system.call.imperative");
export const mailImperative = () => i18n.t("system.mail.imperative");

export const from = () => i18n.t("system.from");
export const to = () => i18n.t("system.to");

export const loadMore = () => i18n.t("system.load_more");

export const archiveConfirm = (type: string) =>
  i18n.t("system.archive.confirm", {
    values: {
      typePrefix: modelTypePrefix(type).toLowerCase(),
      type: modelType(type).toLowerCase(),
    },
  });

export const unarchiveConfirm = (type: string) =>
  i18n.t("system.unarchive.confirm", {
    values: {
      typePrefix: modelTypePrefix(type).toLowerCase(),
      type: modelType(type).toLowerCase(),
    },
  });
export const remove = () => i18n.t("system.delete");
export const removeConfirm = (type: string) =>
  i18n.t("system.delete.confirm", {
    values: {
      typePrefix: modelTypePrefix(type).toLowerCase(),
      type: modelType(type).toLowerCase(),
    },
  });
export const removeConfirmed = (type: string) =>
  i18n.t("system.delete.confirmed", { values: { type: modelType(type) } });
export const add = () => i18n.t("system.add");
export const all = () => i18n.t("system.all");
export const addConfirmed = (type: string) =>
  i18n.t("system.add.confirmed", { values: { type: modelType(type) } });
export const edit = () => i18n.t("system.edit");
export const editConfirm = (type: string) =>
  i18n.t("system.edit.confirm", { values: { type: modelType(type) } });
export const editConfirmed = (type: string) =>
  i18n.t("system.edit.confirmed", { values: { type: modelType(type) } });
export const failed = () => i18n.t("system.fail");
export const archive = () => i18n.t("system.archive");
export const unarchive = () => i18n.t("system.unarchive");
export const filterType = (type: string) =>
  i18n.t("system.filter.type", { values: { type: modelType(type) } });
export const filterBy = (type: string) =>
  i18n.t("system.filter_by", { values: { type } });
export const filterAll = () => i18n.t("system.filter_all");

export const saveConfirmed = (type: string) =>
  i18n.t("system.save.confirmed", { values: { type: modelType(type) } });
export const publishConfirmed = (type: string) =>
  i18n.t("system.publish.confirmed", { values: { type: modelType(type) } });

export const generalError = () => i18n.t("general.error");

export const fetchLoading = () => i18n.t("fetch.loading");
export const fetchError = () => i18n.t("fetch.error");
export const serverError = () => i18n.t("system.500.server.error");

export const serverErrorAccessDenied = () =>
  i18n.t("system.error.access_denied");

export const role = (roleName: string) =>
  i18n.t(`role.${roleName.toLowerCase()}`);
export const systemDatePlaceholder = () => i18n.t("system.date.placeholder");

export const toastErrorGeneralHeading = (type: string = "") =>
  i18n.t(`toast.error.general.${type.toLowerCase()}.heading`);

export const systemOutdatedVersionNoticeHeading = () =>
  i18n.t("system.outdated_version.notice.heading");
export const systemOutdatedVersionNoticeContent = () =>
  i18n.t("system.outdated_version.notice.content");
export const systemOutdatedVersionNoticeAction = () =>
  i18n.t("system.outdated_version.notice.action");

// Usermenu
export const usermenuLogout = logout;
export const usermenuProfile = () => i18n.t("usermenu.profile");
export const usermenuHelp = () => i18n.t("usermenu.help");

// Navigation
export const navigationDashboard = () => i18n.t("navigation.dashboard");
export const navigationProperties = () => i18n.t("navigation.properties");
// export const navigationApplications = () => i18n.t('navigation.applications')
export const navigationContacts = () => i18n.t("navigation.contacts");
export const navigationChats = () => i18n.t("navigation.chats");
export const navigationTasks = () => i18n.t("navigation.tasks");
export const navigationPaymentsFollowUp = () =>
  i18n.t("navigation.payments_follow_up");
export const navigationReports = () => i18n.t("navigation.reports");
export const navigationPaymentsOverview = () =>
  i18n.t("navigation.payments_overview");

// Search
export const searchPlaceholder = () => i18n.t("search.placeholder");

// Address
export const addressFullAddressLabel = () =>
  i18n.t("address.full_address.label");
export const addressFullAddressHelpText = () =>
  i18n.t("address.full_address.help_text");
export const addressStreetLabel = () => i18n.t("address.street.label");
export const addressStateLabel = () => i18n.t("address.state.label");
export const addressNumberLabel = () => i18n.t("address.number.label");
export const addressBoxLabel = () => i18n.t("address.box.label");
export const addressZipLabel = () => i18n.t("address.zip.label");
export const addressCityLabel = () => i18n.t("address.city.label");
export const addressCountryLabel = () => i18n.t("address.country.label");

// Document
export const addDocument = () => i18n.t("document.add");
export const addFileModalHeading = () => i18n.t("document.add.modal.heading");
export const addFileModalNameLabel = () => i18n.t("document.form.name.label");
export const addFileModalCategoryLabel = () =>
  i18n.t("document.form.category.label");
export const viewDocument = () => i18n.t("document.view");
export const downloadDocument = () => i18n.t("document.download");
export const signDocument = () => i18n.t("document.sign");
export const editDocument = () => i18n.t("document.edit");
export const archiveDocument = () => i18n.t("document.archive");
export const unarchiveDocument = () => i18n.t("document.unarchive");
export const deleteDocument = () => i18n.t("document.delete");
export const viewDocumentInfo = () => i18n.t("document.view_info");
export const documentMetaEmptyHeading = () =>
  i18n.t("document.meta.empty.heading");
export const documentMetaEmptyContent = () =>
  i18n.t("document.meta.empty.content");
export const documentTypeLabel = (type: string) =>
  i18n.t(`document.type.${type}`);
export const documentViewerAdded = (args: ITranslateProps) =>
  i18n.t("document.viewer.added", args);
export const documentViewerRemoved = (args: ITranslateProps) =>
  i18n.t("document.viewer.removed", args);
export const documentProcessing = () => i18n.t("document.processing");
export const documentHasSignatures = () => i18n.t("document.has_signatures");
export const maxUploadSizeWarning = (size: string) =>
  i18n.t("files.error.size_limit_exceeded", { values: { size } });

// Form
export const formError = (code: string) =>
  i18n.t(`system.form.error.${code.toLowerCase()}`);
export const formErrorRequired = (field?: string) => {
  if (field) {
    return i18n.t("system.form.error.required.field", { values: { field } });
  } else {
    return i18n.t("system.form.error.required");
  }
};
export const formErrorNumber = () => i18n.t("system.form.error.number");
export const formErrorCurrency = () => i18n.t("system.form.error.currency");
export const formErrorNotNegative = () =>
  i18n.t("system.form.error.not_negative");
export const formErrorInvalidIBAN = () =>
  i18n.t("system.form.error.invalid_IBAN");
export const formErrorInvalidEmail = () =>
  i18n.t("system.form.error.invalid_email");
export const formErrorInvalidPassword = () =>
  i18n.t("system.form.error.invalid_password");
export const formErrorInvalidPhone = () =>
  i18n.t("system.form.error.invalid_phone");
export const formErrorInvalidToken = () =>
  i18n.t("system.form.error.invalid_token");

export const fileDropZonePlaceholder = () =>
  i18n.t("file.dropzone.placeholder");
export const fileDropZoneDrop = () => i18n.t("file.dropzone.drop");

// Page not found
export const pageNotFoundTitle = () => i18n.t("page_not_found.title");

// Page not found
export const pageForbidden = () => i18n.t("forbidden.title");
export const pageForbiddenHomeLink = () => i18n.t("forbidden.title.home_link");

// Auth
export const authPhoneOrEmailLabel = () => i18n.t("auth.phone_or_email.label");
export const authEmailLabel = () => i18n.t("auth.email.label");
export const authPhoneLabel = () => i18n.t("auth.phone.label");
export const authPasswordLabel = () => i18n.t("auth.password.label");
export const authNewPasswordLabel = () => i18n.t("auth.new_password.label");
export const authCantLogin = () => i18n.t("auth.cant_login");
export const authSignUp = () => i18n.t("auth.sign_up");
export const authToLogin = () => i18n.t("auth.to_login");
export const authError = (code: string) =>
  i18n.t(`auth.error.${code.toLowerCase()}`);
export const authPasswordHelpText = () => i18n.t("auth.password.help_text");

export const authLoginTitle = () => i18n.t("auth.login.title");
export const authLoginSubmitLabel = () => i18n.t("auth.login.submit.label");

export const authRequestPasswordResetTitle = () =>
  i18n.t("auth.request_password_reset.title");
export const authRequestPasswordResetSubmitLabel = () =>
  i18n.t("auth.request_password_reset.submit.label");
export const authRequestPasswordResetSentMessage = () =>
  i18n.t("auth.request_password_reset.sent.message");

export const authVerificationTitle = () => i18n.t("auth.verification.title");
export const authVerificationTokenLabel = () =>
  i18n.t("auth.verification.token.label");
export const authVerificationSubmitLabel = () =>
  i18n.t("auth.verification.submit.label");

export const authSignUpTitle = () => i18n.t("auth.sign_up.title");
export const authSignUpSubmitLabel = () => i18n.t("auth.sign_up.submit.label");
export const authSignUpTACMessage = () => i18n.t("auth.sign_up.tac.message");
export const authSignUpTACLink = () => i18n.t("auth.sign_up.tac.link");

export const authChangePasswordResetSubmitLabel = () =>
  i18n.t("auth.change_password.submit.label");
export const authChangePasswordTitle = () =>
  i18n.t("auth.change_password.title");
export const authChangePasswordProblems = () =>
  i18n.t("auth.change_password.problems");
export const authChangePasswordChangesMessage = () =>
  i18n.t("auth.change_password.changed.message");

// Search
export const searchNoResultsDefault = () => i18n.t("search.no_results");
export const searchNoResults = (type: string) =>
  i18n.t(`search.no_results.${type}`);
export const searchCreateCTADefault = () => i18n.t("search.create_CTA");
export const searchCreateCTA = (type: string) =>
  i18n.t(`search.create_CTA.${type}`);
export const searchIncentive = () => i18n.t("search.incentive");

// Property
export const propertyTypeLabel = () => i18n.t("property.type.label");
export const propertyNameLabel = () => i18n.t("property.name.label");
export const propertyDescriptionLabel = () =>
  i18n.t("property.description.label");

// Property Overview
export const propertyOverviewTitle = () => i18n.t("property.overview.title");
export const propertyOverviewPrimaryCTA = () =>
  i18n.t("property.overview.primary_CTA");
export const propertyOverviewRowAddChild = () =>
  i18n.t("property.overview.row.addChild");
export const propertyOverviewRowRemove = () => i18n.t("system.delete");
export const propertyOverviewEmptyStateTitle = () =>
  i18n.t("property.overview.empty_state.title");
export const propertyOverviewEmptyStatePrimaryCTA = () =>
  i18n.t("property.overview.empty_state.primary_CTA");
export const propertyOverviewEmptyStateContent = () =>
  i18n.t("property.overview.empty_state.content");
export const propertyOverviewSearchLabel = () =>
  i18n.t("property.overview.search.label");
export const propertyOverviewSearchPlaceholder = () =>
  i18n.t("property.overview.search.placeholder");
export const propertyOverviewNoResultsTitle = () =>
  i18n.t("property.overview.no_results.title");
export const propertyOverviewNoResultsContent = () =>
  i18n.t("property.overview.no_results.content");
export const propertyAddGroup = () => i18n.t("property.overview.add_group");
// Property Create
export const propertyCreateTitle = () => i18n.t("property.create.title");
export const propertyEditTitle = () => i18n.t("properties.edit");
export const propertyCreateSubTitle = () => i18n.t("property.create.sub_title");
export const propertyCreateTypeLegend = () =>
  i18n.t("property.create.type.legend");
export const propertyCreateFullAddressLegend = () =>
  i18n.t("property.create.full_address.legend");
export const propertyCreateCheckAddressLegend = () =>
  i18n.t("property.create.check_address.legend");
export const propertyCreateNameLegend = () =>
  i18n.t("property.create.name.legend");
export const propertyCreateSubmitLabel = () =>
  i18n.t("property.create.submit.label");
export const propertyCreateAdded = (name: string) =>
  i18n.t("property.create.added", { values: { name } });

// Property detail
export const propertyDetailBreadcrumb = () =>
  i18n.t("property.detail.breadcrumb");
export const propertyDetailTabsInformation = () =>
  i18n.t("property.detail.tabs.information");
export const propertyDetailDashboardBreadcrumb = () =>
  i18n.t("property.detail.dashboard.breadcrumb");

export const propertyDetailTabsPayments = () =>
  i18n.t("property.detail.tabs.payments");
export const propertyDetailTabsContracts = () =>
  i18n.t("property.detail.tabs.contracts");
export const propertyDetailTabsApplications = () =>
  i18n.t("property.detail.tabs.applications");
export const propertyDetailTabsDocuments = () =>
  i18n.t("property.detail.tabs.documents");
export const propertyDetailTabsHistory = () =>
  i18n.t("property.detail.tabs.history");
export const propertyDetailTabsTasks = () =>
  i18n.t("property.detail.tabs.tasks");

export const propertyDetailTasksMessage = () =>
  i18n.t("property.detail.tasks.message");
export const propertyDetailTasksEmptyMessage = () =>
  i18n.t("property.detail.tasks.empty.message");
export const propertyDetailTasksEmptyAction = () =>
  i18n.t("property.detail.tasks.empty.action");

export const propertyDetailChatsEmptyMessage = () =>
  i18n.t("property.detail.chats.empty.message");
export const propertyDetailChatsEmptyAction = () =>
  i18n.t("property.detail.chats.empty.action");

export const propertyDetailAddBasicContractInfoAction = () =>
  i18n.t("property.detail.add_basic_contract_info.action");
export const propertyDetailAddPaymentOrderAction = () =>
  i18n.t("property.detail.add_payment_order.action");
export const propertyDetailAddTaskAction = () =>
  i18n.t("property.detail.add_task.action");

// Property  Edit
export const propertyEditDropdownLabel = () =>
  i18n.t("property.edit.dropdown.label");

// Property Info Edit
export const propertyInfoEditDropdownLabel = () =>
  i18n.t("property_info.edit.dropdown.label");
export const propertyInfoFormModalTitle = () =>
  i18n.t("property_info.form.modal.title");
export const propertyInfoFormPriceLabel = () =>
  i18n.t("property_info.form.price.label");
export const propertyInfoFormCostsLabel = () =>
  i18n.t("property_info.form.costs.label");
export const propertyInfoEditToastFailedHeading = () =>
  i18n.t("property_info.edit.toast.failed.heading");
export const propertyInfoEditToastFailedContent = () =>
  i18n.t("property_info.edit.toast.failed.content");

// Add owner
export const propertyAddOwnerTitle = () => i18n.t("property.add_owner.title");
export const propertyAddOwnerBody = () => i18n.t("property.add_owner.body");
export const propertyAddOwnerSubTitle = propertyCreateSubTitle;
export const propertyAddOwnerBannerAddLater = () =>
  i18n.t("property.add_owner.banner.add_later");
export const propertyAddOwnerToggleCompanyLabel = () =>
  i18n.t("property.add_owner.toggle_company.label");
export const propertyAddOwnerIsCompanyLegend = () =>
  i18n.t("property.add_owner.is_company.legend");
export const propertyAddOwnerPersonalDataLegend = () =>
  i18n.t("property.add_owner.personal_data.legend");
export const propertyAddOwnerErrorNoOwners = () =>
  i18n.t("property.add_owner.error.no_owners");
export const propertyAddOwnerRequired = () =>
  i18n.t("property.add_owner.required");
export const propertyAddRoleTitle = () => i18n.t("property.add_role.title");

export const contactAddContactWhoLabelMe = (type: string) =>
  i18n.t("contact.add_contact.who.label.me", {
    values: { type: type.toLowerCase() },
  });
export const contactAddAsContact = () => i18n.t("add.to.contacts");

// Add roles
export const propertyAddRolesTitle = () => i18n.t("property.add_roles.title");
export const propertyAddRolesBannerAddLater = () =>
  i18n.t("property.add_roles.banner.add_later");
export const propertyAddRolesRequired = () =>
  i18n.t("property.add_roles.required");
export const propertyAddRolesRolesLabel = () =>
  i18n.t("property.add_roles.roles.label");

// Payments
export const paymentOrderAmountHeading = () =>
  i18n.t("payment.payment_order.amount.heading", {});
export const paymentOrderContractHeading = () =>
  i18n.t("payment.payment_order.contract.heading");
export const paymentOrderPaymentsHeading = () =>
  i18n.t("payment.payment_order.payments.heading");
export const paymentOrderFirstPaymentHeading = () =>
  i18n.t("payment.payment_order.first_payment.heading");
export const paymentOrderPaymentTypeLabel = () =>
  i18n.t("payment.payment_order.payment_type.label");
export const paymentOrderPaymentTypeValue = (type: any) =>
  i18n.tm(`payment.payment_order.payment_type.value.${type.toLowerCase()}`);
export const paymentOrderAmount = (type: string) =>
  i18n.t("payment.payment_order.amount", {
    values: { type: paymentOrderTypeLabel(type).toLowerCase() },
  });
export const paymentOrderFirstAmount = () =>
  i18n.t("payment.payment_order.first_amount");
export const paymentOrderInfo = () => i18n.t("payment.payment_order.info");
export const paymentOrderType = () => i18n.t("payment.payment_order.type");
export const paymentOrderTypeLabel = (
  type: string,
  args: ITranslateProps = {},
) => i18n.t(`payment.payment_order.type.${type.toLowerCase()}`, args);
export const paymentOrderMandateIconTooltip = () =>
  i18n.t("payment.payment_order.mandate_icon.tooltip");
export const paymentOrderNoConnectionWithPayerLabel = () =>
  i18n.t("payment.payment_order.no_connection.label");
export const paymentOrderProcessingLabel = () =>
  i18n.t("payment.payment_order.processing.label");
export const paymentOrderNoConnectionWithPayerDescription = () =>
  i18n.t("payment.payment_order.no_connection.description");
export const paymentRepetitionType = (type: string) =>
  i18n.t(`payment.repetition_type.${type.toLowerCase()}`);
export const paymentOrderPayer = () => i18n.t("payment.payment_order.payer");
export const paymentOrderPayerPlaceholder = () =>
  i18n.t("payment.payment_order.payer.placeholder");
export const paymentOrderPayerOtherLabel = () =>
  i18n.t("payment.payment_order.payer.other.label");
export const paymentOrderPayee = () => i18n.t("payment.payment_order.payee");
export const paymentOrderPayeePlaceholder = () =>
  i18n.t("payment.payment_order.payee.placeholder");
export const paymentOrderPayeeOtherLabel = () =>
  i18n.t("payment.payment_order.payee.other.label");
export const paymentOrderBankaccount = () =>
  i18n.t("payment.payment_order.bankaccount.label");
export const paymentOrderBankaccountNewLabel = () =>
  i18n.t("payment.payment_order.bankaccount.new.label");
export const paymentOrderStartDateLabel = () =>
  i18n.t("payment.payment_order.start_date.label");
export const paymentOrderEndDateLabel = () =>
  i18n.t("payment.payment_order.end_date.label");
export const paymentOrderRepetitionDayLabel = () =>
  i18n.t("payment.payment_order.repetition_day.label");
export const paymentOrderOwnerPaymentMethodLabel = () =>
  i18n.t("payment.payment_order.owner_payment_method.label");
export const paymentOrderOwnerPaymentMethodRent = () =>
  i18n.t("payment.payment_order.owner_payment_method.rent");
export const paymentOrderOwnerPaymentMethodDirect = () =>
  i18n.t("payment.payment_order.owner_payment_method.direct");
export const paymentOrderFirstPaymentPeriodHeading = () =>
  i18n.t("payment.payment_order.first_payment.period.heading");
export const paymentOrderFirstPaymentPeriodLabel = () =>
  i18n.t("payment.payment_order.first_payment.period.label");
export const paymentOrderFirstPaymentPeriodValue = (values: any) =>
  i18n.tm("payment.payment_order.first_payment.period.value", {
    values,
    n: values.days,
  });
export const paymentOrderFirstPaymentPeriodValueWithMonth = (values: any) =>
  i18n.tm("payment.payment_order.first_payment.period.value_incl_month", {
    values,
    n: values.days,
  });
export const paymentOrderFirstPaymentAmountHeading = () =>
  i18n.t("payment.payment_order.first_payment.amount.heading");
export const paymentOrderFirstPaymentAmountLabel = (type: string) =>
  i18n.t("payment.payment_order.first_payment.amount.label", {
    values: { type: paymentOrderTypeLabel(type).toLowerCase() },
  });
export const paymentOrderFirstPaymentAmountHelpText = (values: any) =>
  i18n.tm("payment.payment_order.first_payment.amount.help_text", { values });
export const paymentOrderDueDateHeading = () =>
  i18n.t("payment.payment_order.due_date.heading");
export const paymentOrderDueDateLabel = (type: string, n = 1) =>
  i18n.t("payment.payment_order.due_date.label", {
    values: { type: paymentOrderTypeLabel(type).toLowerCase() },
    n,
  });
export const paymentOrderDueDateHelpText = () =>
  i18n.t("payment.payment_order.due_date.help_text");

export const paymentOrderRecurringLabel = () =>
  i18n.t("payment.payment_order.recurring.label");

export const paymentOrderContractInfoHeading = () =>
  i18n.t("payment.payment_order.contract_info.heading");

export const paymentOrderProratedInfoHeading = () =>
  i18n.t("payment.payment_order.prorated_info.heading");
export const paymentOrderProratedInfoCalculation = (
  days: string,
  amount: string,
) =>
  i18n.tm("payment.payment_order.prorated_info.calculation", {
    values: { days, amount },
    n: +days,
  });

export const paymentOrderViewPaymentOrderAction = () =>
  i18n.t("payment_order.view_payment_order.action");

// Add payments (Cost)
export const paymentsDetailBreadcrumb = () =>
  i18n.t("payments.detail.breadcrumb");

export const paymentsCreateTitle = () => i18n.t("payments.create.title");
export const paymentsEditTitle = () => i18n.t("payments.edit.title");
export const paymentsCreateBreadcrumb = () =>
  i18n.t("payments.create.breadcrumb");

export const paymentOrderTypeStep = () =>
  i18n.t("payment.payment_order.type.step");
export const paymentOrderInfoStep = () =>
  i18n.t("payment.payment_order.info.step");
export const paymentOrderFirstPaymentStep = () =>
  i18n.t("payment.payment_order.first_payment.step");
export const paymentOrderPayerStep = () =>
  i18n.t("payment.payment_order.payer.step");
export const paymentOrderPayeeStep = () =>
  i18n.t("payment.payment_order.payee.step");
export const paymentOrderBankaccountStep = () =>
  i18n.t("payment.payment_order.bankaccount.step");
export const paymentOrderPayerHeading = (type: string) =>
  i18n.t("payment.payment_order.payer.heading", {
    values: { type: paymentOrderTypeLabel(type).toLowerCase() },
  });
export const paymentOrderPayeeHeading = (type: string) =>
  i18n.t("payment.payment_order.payee.heading", {
    values: { type: paymentOrderTypeLabel(type).toLowerCase() },
  });
export const paymentOrderBankaccountHeading = (type: string) =>
  i18n.t("payment.payment_order.bankaccount.heading", {
    values: { type: paymentOrderTypeLabel(type).toLowerCase() },
  });

export const paymentsOverviewEmptyStateTitle = () =>
  i18n.t("payments.overview.empty_state.title");
export const paymentsOverviewEmptyStatePrimaryCTA = () =>
  i18n.t("payments.overview.empty_state.primary_CTA");
export const paymentsOverviewEmptyStateContent = () =>
  i18n.t("payments.overview.empty_state.content");
export const paymentsOverviewEmptyStateNoResultContent = () =>
  i18n.t("payments.overview.empty_state.no_result.content");
export const paymentsOverviewHeading = () =>
  i18n.t("payments.overview.heading");
export const paymentsOverviewInactiveHeading = () =>
  i18n.t("payments.overview_inactive.heading");
export const paymentsOverviewShowInactiveAction = () =>
  i18n.t("payments.overview.show_inactive.action");
export const paymentsOverviewHideInactiveAction = () =>
  i18n.t("payments.overview.hide_inactive.action");
export const paymentsOverviewPaymentOrderEdit = () =>
  i18n.t("payments.overview.payment_order.edit.action");
export const paymentsOverviewCreatePrimaryCTA = () =>
  i18n.t("payments.overview.create.primary_CTA");
export const paymentOverviewFirstPay = (contractInfo: {
  startDate: string;
  stopDate: string;
  amount: string;
}) =>
  i18n.tm("payment.overview.first_payment_info", {
    values: contractInfo,
  });

export const paymentOverviewPaymentRequestMeta = (params: {
  paymentOrder: IPaymentOrder;
}) => {
  const { paymentOrder } = params;

  const isOnce = paymentOrder.repetitionType === EPaymentRepetitionType.Once;

  const payer = paymentOrder.payer ? getName(paymentOrder.payer) : "Payer";
  const payee = paymentOrder.payee ? getName(paymentOrder.payee) : "Payee";
  const payees = paymentOrder.payee ? getName(paymentOrder.payee) : "Payees";
  const startDate = paymentOrder.startedAt
    ? utils.date.format(paymentOrder.startedAt, "d MMMM yyyy")
    : "";
  const endDate = paymentOrder.endedAt
    ? utils.date.format(paymentOrder.endedAt, "d MMMM yyyy")
    : i18n.t("contract.indefinite").toLowerCase();
  const dueDate = paymentOrder.nextContractualPayDate
    ? utils.date.format(paymentOrder.nextContractualPayDate, "d MMMM yyyy")
    : "";

  const values = { payer, payee, payees, startDate, endDate, dueDate };

  if (paymentOrder.type === EPaymentOrderType.RentDeposit) {
    return i18n.tm("payment.overview.payment_request_meta.rent_deposit", {
      values,
    });
  }

  if (paymentOrder.type === EPaymentOrderType.RentDepositPayout) {
    return i18n.tm(
      "payment.overview.payment_request_meta.rent_deposit_payout",
      {
        values,
      },
    );
  }

  if (
    paymentOrder.ownerPaymentMethod === EPaymentOrderOwnerPaymentMethod.FromRent
  ) {
    return i18n.tm("payment.overview.payment_order_meta.from_rent", {
      values,
      n: isOnce ? 1 : 2,
    });
  }

  if (isOnce) {
    return i18n.tm("payment.overview.payment_order_meta", {
      values,
    });
  }

  return i18n.tm("payment.overview.payment_order_meta.recurring", {
    values,
  });
};

export const paymentOverviewFrequencyMeta = (params: {
  paymentOrder: IPaymentOrder;
}) => {
  const { paymentOrder } = params;

  const isOnce = paymentOrder.repetitionType === EPaymentRepetitionType.Once;

  const startDate = paymentOrder.startedAt
    ? utils.date.format(paymentOrder.startedAt, "d MMMM yyyy")
    : "";
  const endDate = paymentOrder.endedAt
    ? utils.date.format(paymentOrder.endedAt, "d MMMM yyyy")
    : i18n.t("contract.indefinite").toLowerCase();
  const dueDate = paymentOrder.nextContractualPayDate
    ? utils.date.format(paymentOrder.nextContractualPayDate, "d MMMM yyyy")
    : "";

  if (isOnce) {
    return getLocalizedText("payment.overview.payment_order_frequency_meta", {
      dueDate,
    });
  }

  return `${getLocalizedText(
    "task.recurring_type.recurring",
  )}: ${getLocalizedText(
    `payment.payment_order.frequency.${paymentOrder.repetitionType.toLowerCase()}`,
  )} - ${startDate} ${getLocalizedText(
    "system.till",
  )} ${endDate} - ${getLocalizedText(
    `payment.payment_order.paydate_offset.value${
      paymentOrder.paydateOffset < 0 ? "_negative" : ""
    }`,
    {
      days: `${Math.abs(paymentOrder.paydateOffset)}`,
    },
    Math.abs(paymentOrder.paydateOffset),
  )}`;
};

// Property dashboard
export const propertyDashboardArchivedPropertiesHeading = () =>
  i18n.t("property.dashboard.archived_properties.heading");
export const propertyDashboardOwnersHeading = () =>
  i18n.t("property.dashboard.owners.heading");
export const propertyDashboardOwnersEmptyContent = () =>
  i18n.t("property.dashboard.owners.empty.body");
export const propertyDashboardManagersHeading = () =>
  i18n.t("property.dashboard.managers.heading");
export const propertyDashboardManagersPrimaryCTA = () =>
  i18n.t("property.dashboard.managers.primary_CTA");
export const propertyDashboardManagersAddContactAction = () =>
  i18n.t("property.dashboard.managers.add_contact.action");
export const propertyDashboardEmptyContent = () =>
  i18n.t("property.dashboard.managers.empty.body");
export const propertyDashboardUpcomingContractHeading = () =>
  i18n.t("property.dashboard.upcoming_contract.heading");
export const propertyDashboardActiveContractHeading = () =>
  i18n.t("property.dashboard.active_contract.heading");
export const propertyDashboardEndedContractHeading = () =>
  i18n.t("property.dashboard.ended_contract.heading");
export const propertyDashboardTenantsPrimaryCTA = () =>
  i18n.t("property.dashboard.tenants.primary_CTA");
export const propertyDashboardTenantsAddCTA = () =>
  i18n.t("property.dashboard.tenants.add_CTA");

export const propertyDashboardCardCreatePaymentOrderTitle = () =>
  i18n.t("property.dashboard.card.create_order.title");
export const propertyDashboardCardCreatePaymentOrderPrimaryCTA = () =>
  i18n.t("property.dashboard.card.create_order.primary_CTA");
export const propertyDashboardCardCreatePaymentBody = () =>
  i18n.t("property.dashboard.card.create_order.body");

export const propertySearchManagerLabel = () =>
  i18n.t("property.search.manager.label");

export const propertySearchOwnerLabel = () =>
  i18n.t("property.search.owner.label");
export const propertyAddOwnerLabel = () => i18n.t("property.owner.add.label");

export const propertyDashboardCardArchivedTitle = () =>
  i18n.t("property.dashboard.card.archived.title");
export const propertyDashboardCardArchivedContent = () =>
  i18n.t("property.dashboard.card.archived.content");
export const propertyDashboardCardArchivedCta = () =>
  i18n.t("property.dashboard.card.archived.cta");

export const propertyDashboardChecklistRentDepositHeading = () =>
  i18n.t("property.dashboard.checklist.rent_deposit.heading");
export const propertyDashboardChecklistRentDepositEmptyContent = () =>
  i18n.t("property.dashboard.checklist.rent_deposit.empty_content");
export const propertyDashboardChecklistRentDepositEmptyAction = () =>
  i18n.t("property.dashboard.checklist.rent_deposit.empty_action");
export const propertyDashboardChecklistRentDepositContent = () =>
  i18n.t("property.dashboard.checklist.rent_deposit.content");
export const propertyDashboardChecklistRentDepositAction = () =>
  i18n.t("property.dashboard.checklist.rent_deposit.action");
export const propertyDashboardChecklistPaymentOrderHeading = () =>
  i18n.t("property.dashboard.checklist.payment_order.heading");
export const propertyDashboardChecklistPaymentOrderEmptyContent = () =>
  i18n.t("property.dashboard.checklist.payment_order.empty_content");
export const propertyDashboardChecklistPaymentOrderEmptyAction = () =>
  i18n.t("property.dashboard.checklist.payment_order.empty_action");
export const propertyDashboardChecklistSignContractHeading = () =>
  i18n.t("property.dashboard.checklist.sign_contract.heading");
export const propertyDashboardChecklistSignContractContent = () =>
  i18n.t("property.dashboard.checklist.sign_contract.content");
export const propertyDashboardChecklistViewDocumentPackageAction = () =>
  i18n.t("property.dashboard.checklist.view_document_package.action");
export const propertyDashboardChecklistSignContractEmptyContent = () =>
  i18n.t("property.dashboard.checklist.sign_contract.empty_content");
export const propertyDashboardChecklistSignContractAction = () =>
  i18n.t("property.dashboard.checklist.sign_contract.action");
export const propertyDashboardChecklistIndexContractHeading = () =>
  i18n.t("property.dashboard.checklist.index_contract.heading");
export const propertyDashboardChecklistIndexContractContent = () =>
  i18n.t("property.dashboard.checklist.index_contract.content");
export const propertyDashboardChecklistIndexContractAction = () =>
  i18n.t("property.dashboard.checklist.index_contract.action");

export const propertyViewPropertyAction = () =>
  i18n.t("property.view_property.action");

// Property contacts
export const addNewContact = () => i18n.t("contacts.add.new");
export const addNewCompany = () => i18n.t("contacts.company.add.new");

// Property applications
export const applicationsOverviewEmptyStateTitle = () =>
  i18n.t("applications.overview.empty_state.title");
export const applicationsOverviewEmptyStatePrimaryCTA = () =>
  i18n.t("applications.overview.empty_state.primary_CTA");
export const applicationsOverviewEmptyStateContent = () =>
  i18n.t("applications.overview.empty_state.content");
export const applicationsOverviewEmptyStateBlockTitle = (index: number) =>
  i18n.t(`applications.overview.empty_state.block.${index}.title`);
export const applicationsOverviewEmptyStateBlockBody = (index: number) =>
  i18n.t(`applications.overview.empty_state.block.${index}.body`);
export const applicationStepCompletedAt = (step: number) =>
  i18n.t(`property.dashboard.application.${step}.stepCompletedAt`);
export const applicationStepArchivedAt = () =>
  i18n.t("property.dashboard.application.archivedAt");
export const applicationStepProvidedAt = (step: number) =>
  i18n.t(`property.dashboard.application.${step}.stepStartedAt`);
export const applicationStateTitle = (step: number) =>
  i18n.t(`property.dashboard.application.${step}.title`);
export const applicationStatePlaceholder = (step: number) =>
  i18n.t(`property.dashboard.application.${step}.placeholder`);
export const applicationAssignStatus = (step: number) =>
  i18n.t(`property.dashboard.application.${step}.assign`);
export const applicationAssignCandidateConfirmBody = () =>
  i18n.t("application.assign.candidate.confirm.body");
export const applicationAssignCandidateConfirmHeading = () =>
  i18n.t("application.assign.candidate.confirm.heading");
export const applicationAssignTenantConfirmBody = (phone: string) =>
  i18n.t("application.assign.tenant.confirm.body", { values: { phone } });
export const applicationAssignTenantConfirmHeading = () =>
  i18n.t("application.assign.tenant.confirm.heading");
export const applicationDuplicateApplicationAction = () =>
  i18n.t("application.duplicate_application.action");
export const applicationDuplicateToastSuccessHeading = () =>
  i18n.t("application.duplicate.toast.success.heading");
export const applicationDuplicateToastFailedHeading = () =>
  i18n.t("application.duplicate.toast.failed.heading");
export const applicationDuplicateToastFailedContent = () =>
  i18n.t("application.duplicate.toast.failed.content");
export const applicationDuplicateModalHeading = () =>
  i18n.t("application.duplicate.modal.heading");

export const propertyInviteApplicationAction = () =>
  i18n.t("property.invite_application.action");
export const propertyInviteApplicationModalHeading = () =>
  i18n.t("property.invite_application.modal.heading");
export const propertyInviteApplicationEmailLabel = () =>
  i18n.t("property.invite_application.email.label");
export const propertyInviteApplicationToastFailedHeading = () =>
  i18n.t("property.invite_application.toast.failed.heading");
export const propertyInviteApplicationToastFailedContent = () =>
  i18n.t("property.invite_application.toast.failed.content");
export const propertyInviteApplicationToastSucceededHeading = () =>
  i18n.t("property.invite_application.toast.success.heading");
// Property documents
export const propertyDocumentsSearchLabel = () =>
  i18n.t("property.documents.search.label");
export const propertyDocumentsSearchPlaceholder = () =>
  i18n.t("property.documents.search.placeholder");
export const propertyDocumentsSharedWithTitle = () =>
  i18n.t("property.documents.shared_with.title");
export const propertyDocumentsShareLabel = () =>
  i18n.t("property.documents.share.label");
export const propertyDocumentsShowArchiveAction = () =>
  i18n.t("property.documents.show_archive.action");
export const propertyDocumentsHideArchiveAction = () =>
  i18n.t("property.documents.hide_archive.action");
export const documentsEmpty = () => i18n.t("documents.empty");
export const documentsArchiveEmpty = () => i18n.t("documents.archive.empty");

// Dashboard
export const dashboardTitle = () => i18n.t("dashboard.title");
export const dashboardCardAddPropertyTitle = () =>
  i18n.t("dashboard.card.add_property.title");
export const dashboardCardAddPropertyBody = () =>
  i18n.t("dashboard.card.add_property.body");
export const dashboardCardAddPropertyAction = () =>
  i18n.t("dashboard.card.add_property.action");
export const dashboardCardEmptyNewChatsTitle = () =>
  i18n.t("dashboard.card.empty_new_chats.title");
export const dashboardCardEmptyNewChatsBody = () =>
  i18n.t("dashboard.card.empty_new_chats.body");
export const dashboardCardEmptyNewChatsAction = () =>
  i18n.t("dashboard.card.empty_new_chats.action");
export const dashboardCardEmptyPaymentsTitle = () =>
  i18n.t("dashboard.card.empty_payments.title");
export const dashboardCardEmptyPaymentsBody = () =>
  i18n.t("dashboard.card.empty_payments.body");
export const dashboardCardEmptyPaymentsAction = () =>
  i18n.t("dashboard.card.empty_payments.action");
export const dashboardCardPropertiesTitle = () =>
  i18n.t("dashboard.card.properties.title");
export const dashboardCardPropertiesAction = () =>
  i18n.t("dashboard.card.properties.action");
export const dashboardCardNewChatsTitle = () =>
  i18n.t("dashboard.card.new_chats.title");
export const dashboardCardNewChatsAction = () =>
  i18n.t("dashboard.card.new_chats.action");
export const dashboardCardPaymentsTitle = () =>
  i18n.t("dashboard.card.payments.title");
export const dashboardCardPaymentsAction = () =>
  i18n.t("dashboard.card.payments.action");

const getBrokerTypePostFix = (params: { broker?: IBroker }) => {
  if (params.broker?.type === EClientType.Broker) {
    return "_broker";
  }
  return "";
};
export const dashboardCardRevenuesTitle = (params: { broker?: IBroker }) =>
  i18n.t(`dashboard.card.revenues${getBrokerTypePostFix(params)}.title`);
export const dashboardCardRevenuesAction = (params: { broker?: IBroker }) =>
  i18n.t(`dashboard.card.revenues${getBrokerTypePostFix(params)}.action`);

export const dashboardCardRentDepositsTitle = () =>
  i18n.t("dashboard.card.rent_deposits.title");

export const dashboardCardDocumentPackagesTitle = () =>
  i18n.t("dashboard.card.document_packages.title");

export const dashboardCardIndexableContractsTitle = () =>
  i18n.t("dashboard.card.indexable_contracts.title");
export const dashboardCardIndexableContractsAction = () =>
  i18n.t("dashboard.card.indexable_contracts.action");

// TEMP (?)
export const propertyDashboardCardCreatePropertyTitle = () =>
  i18n.t("property.dashboard.card.create_property.title");
export const propertyDashboardCardCreatePropertyPrimaryCTA = () =>
  i18n.t("property.dashboard.card.create_property.primary_CTA");
export const propertyDashboardCardCreatePropertyBody = () =>
  i18n.t("property.dashboard.card.create_property.body");
export const propertyDashboardCardCreateContractInfoTitle = () =>
  i18n.t("property.dashboard.card.create_contract_info.title");
export const propertyDashboardCardCreateContractInfoPrimaryCTA = () =>
  i18n.t("property.dashboard.card.create_contract_info.primary_CTA");
export const propertyDashboardCardCreateContractInfoBody = () =>
  i18n.t("property.dashboard.card.create_contract_info.body");
export const cardCreateContractHeading = () =>
  i18n.t("card.create_contract.heading");
export const cardCreateContractAction = () =>
  i18n.t("card.create_contract.action");
export const cardCreateContractContent = () =>
  i18n.t("card.create_contract.content");

export const cardCreateContractFeatureIndexation = () =>
  i18n.tm("card.create_contract.feature.indexation");
export const cardCreateContractFeatureDigitalSigning = () =>
  i18n.tm("card.create_contract.feature.digital_signing");
export const cardCreateContractFeatureDigitalRegistration = () =>
  i18n.tm("card.create_contract.feature.digital_registration");
export const cardCreateContractFeatureRentDeposit = () =>
  i18n.tm("card.create_contract.feature.rent_deposit");
export const cardCreateContractFeatureDigitalPayments = () =>
  i18n.tm("card.create_contract.feature.digital_payments");
export const cardCreateContractFeatureMoveService = () =>
  i18n.tm("card.create_contract.feature.move_service");

// Contract
export const contractEdit = () => i18n.t("contract.edit");
export const contractAddOwnerBody = () => i18n.t("contract.add_owner.body");
export const contractRenew = () => i18n.t("contract.renew");
export const contractCancel = () => i18n.t("contract.cancel");
export const contractShowCancellation = () =>
  i18n.t("contract.show_cancellation");
export const contractTurnOffTacitRenewal = () =>
  i18n.t("contract.turn_off_tacit_renewal");

export const contractTerm = () => i18n.t("contract.term");
export const contractStartDate = () => i18n.t("contract.startdate");
export const contractEndDate = () => i18n.t("contract.enddate");
export const contractTenants = () => i18n.t("contract.tenants");
export const contractStartDateLabel = () => i18n.t("contract.start_date.label");
export const contractEndDateLabel = () => i18n.t("contract.end_date.label");
export const contractPeriodLabel = () => i18n.t("contract.period.label");
export const contractEntryDateLabel = () => i18n.t("contract.entry_date.label");
export const contractInitialIndexLabel = () =>
  i18n.t("contract.initial_index.label");
export const contractInitialPriceLabel = () =>
  i18n.t("contract.initial_price.label");
export const contractInitialCostsLabel = () =>
  i18n.t("contract.initial_costs.label");
export const contractCurrentIndexLabel = () =>
  i18n.t("contract.current_index.label");

export const contractCurrentPriceLabel = () =>
  i18n.t("contract.current_price.label");
export const contractCurrentCostsLabel = () =>
  i18n.t("contract.current_costs.label");
export const contractMonthlyPayDateLabel = () =>
  i18n.t("contract.paydate.label");
export const contractMonthlyPayDateBeforeMonth = () =>
  i18n.t("contract.paydate.before_month");
export const contractMonthlyPayDateBeforePeriod = () =>
  i18n.t("contract.paydate.before_period");
export const contractIndefinite = () => i18n.t("contract.indefinite");

export const contractsOverviewCurrentContracts = () =>
  i18n.t("contracts.overview.current_contracts");
export const contractsOverviewFinishedContracts = () =>
  i18n.t("contracts.overview.finished_contracts");
export const contractsOverviewHeading = () =>
  i18n.t("contracts.overview.heading");
export const contractsOverviewCreatePrimaryCTA = () =>
  i18n.t("contracts.overview.create.primary_CTA");
export const contractsOverviewContractDetailsAction = () =>
  i18n.t("contracts.overview.contract.details.action");
export const contractsOverviewContractNoTenants = () =>
  i18n.t("contracts.overview.contract.no_tenants");

export const contractDetailBreadcrumb = () =>
  i18n.t("contract.detail.breadcrumb");

export const contractInfoCreateHeading = () =>
  i18n.t("contract_info.create.heading");
export const contractInfoCreateTenantsHeading = () =>
  i18n.t("contract_info.create.tenants.heading");
export const contractInfoCreateDurationHeading = () =>
  i18n.t("contract_info.create.duration.heading");
export const contractInfoCreateCurrentDataHeading = () =>
  i18n.t("contract_info.create.current_data.heading");
export const contractInfoCreateInitialDataHeading = () =>
  i18n.t("contract_info.create.initial_data.heading");
export const contractInfoCreateHasInitialValuesLabel = () =>
  i18n.t("contract_info.create.has_initial_values.label");

export const contractsOverviewCreateMandateLabel = () =>
  i18n.t("contracts.overview.create.mandate.label");
export const contractsOverviewCreateRentalAgreementLabel = () =>
  i18n.t("contracts.overview.create.rental_agreement.label");
export const contractsOverviewCreateBasicContractInfoLabel = () =>
  i18n.t("contracts.overview.create.basic_contract_info.label");

export const contractOpenRentDepositAction = () =>
  i18n.t("contract.open_rent_deposit.action");
export const contractReleaseRentDepositAction = () =>
  i18n.t("contract.release_rent_deposit.action");

export const contractOpenRentDepositAddedModalHeading = () =>
  i18n.t("contract.open_rent_deposit.added_modal.heading");
export const contractOpenRentDepositAddedModalIntro = () =>
  i18n.t("contract.open_rent_deposit.added_modal.intro");
export const contractOpenRentDepositAddedModalOutro = () =>
  i18n.t("contract.open_rent_deposit.added_modal.outro");
export const contractOpenRentDepositAddedModalNextStepsHeading = () =>
  i18n.t("contract.open_rent_deposit.added_modal.next_steps.heading");
export const contractOpenRentDepositAddedModalNextStepsStep = (index: number) =>
  i18n.t(`contract.open_rent_deposit.added_modal.next_steps.step${index}`);

export const contractPrepareToSignAction = () =>
  i18n.t("contract.prepare_to_sign.action");
export const contractRevokeAction = () => i18n.t("contract.revoke.action");

export const contractType = (type: string) => i18n.t(`contract.type.${type}`);
export const contractStatus = (status: string) =>
  i18n.t(`contract.status.${status}`);

export const contractCreateStep1 = () => i18n.t("contract.create.step_1");
export const contractCreateStep2 = () => i18n.t("contract.create.step_2");
export const contractMembersLabel = () => i18n.t("contract.members.label");

// Index Contract
export const contractIndexBannerHeading = () =>
  i18n.t("contract.index.banner.heading");
export const contractIndexBannerContent = () =>
  i18n.t("contract.index.banner.content");

// Location Description
export const contractCardChecklistLocationDescriptionHeading = () =>
  i18n.t("contract_card.checklist.location_description.heading");
export const contractCardChecklistLocationDescriptionEmptyAction = () =>
  i18n.t("contract_card.checklist.location_description.empty_action");
export const contractCardChecklistLocationDescriptionListAction = () =>
  i18n.t("contract_card.checklist.location_description.list_action");
export const contractCardChecklistLocationDescriptionInContent = (
  args: ITranslateProps,
) => i18n.t("contract_card.checklist.location_description.in.content", args);
export const contractCardChecklistLocationDescriptionOutContent = (
  args: ITranslateProps,
) => i18n.t("contract_card.checklist.location_description.out.content", args);
export const contractCardChecklistLocationDescriptionBothContent = (
  args: ITranslateProps,
) => i18n.t("contract_card.checklist.location_description.both.content", args);

export const locationDescriptionModalHeading = () =>
  i18n.t("location_description.modal.heading");
export const locationDescriptionIntroModalContent = () =>
  i18n.tm("location_description.intro_modal.content", {
    markdownProps: { listAsChecklist: true },
  });
export const locationDescriptionIntroModalAction = () =>
  i18n.t("location_description.intro_modal.action");
export const locationDescriptionFormModalAction = () =>
  i18n.t("location_description.form_modal.action");
export const locationDescriptionOutroModalContent = (params: {
  partner: string;
}) =>
  i18n.tm("location_description.outro_modal.content", {
    values: params,
    markdownProps: { listAsChecklist: true },
  });
export const locationDescriptionPricesLink = () =>
  i18n.t("location_description.prices_url");
export const locationDescriptionModalViewTaskAction = () =>
  i18n.t("location_description.view_task.action");

// Mandate
export const mandateCreateHeading = () => i18n.t("mandate.create.heading");

// Rent Deposit
export const rentDepositOpenAction = () => i18n.t("rent_deposit.open.action");
export const rentDepositReleaseAction = () =>
  i18n.t("rent_deposit.release.action");
export const rentDepositJudicialReleaseAction = () =>
  i18n.t("rent_deposit.release.via_judgement.action");
export const rentDepositCancelAction = () =>
  i18n.t("rent_deposit.cancel.action");
export const rentDepositCreateHeading = () =>
  i18n.t("rent_deposit.create.heading");
export const rentDepositReleaseHeading = () =>
  i18n.t("rent_deposit.release.heading");
export const rentDepositCancelReleaseAction = () =>
  i18n.t("rent_deposit.cancel.release.action");

// Profile
export const profileTitle = () => i18n.t("profile.title");
export const profileDetailTabsPersonal = () =>
  i18n.t("profile.detail.tabs.personal");
export const profileDetailTabsFinancial = () =>
  i18n.t("profile.detail.tabs.financial");
export const profileDetailTabsExtra = () => i18n.t("profile.detail.tabs.extra");
export const profileDetailTabsTac = () => i18n.t("profile.detail.tabs.tac");

export const propertyDashboardCardPrepareMoveTitle = () =>
  i18n.t("property.dashboard.card.prepare_move.title");
export const propertyDashboardCardPrepareMovePrimaryCTA = () =>
  i18n.t("property.dashboard.card.prepare_move.primary_CTA");
export const propertyDashboardCardPrepareMoveBody = () =>
  i18n.t("property.dashboard.card.prepare_move.body");

// Contact
export const addContact = () => i18n.t("contact.add");
export const addCompany = () => i18n.t("company.company.add");
export const contactCompanyNameLabel = () =>
  i18n.t("contact.company.name.label");
export const contactCompanyVATNumberLabel = () =>
  i18n.t("contact.company.vat_number.label");
export const contactIbanAccountLabel = () =>
  i18n.t("contact.company.iban_account.label");
export const contactIbanAccountHelpText = () =>
  i18n.t("contact.company.iban_account.help_text");
export const contactAddContact = (type: string) =>
  i18n.t(`contact.add_contact.${type.toLowerCase()}`);
export const contactRemoveContact = (type: string) =>
  i18n.t(`contact.remove_contact.${type.toLowerCase()}`);
export const contactEditContact = (type?: string) =>
  i18n.t(
    type
      ? `contact.edit_contact.${type.toLowerCase()}`
      : "contact.edit_contact",
  );
export const contactAssignContact = (type: string) =>
  i18n.t(`contact.assign_contact.${type.toLowerCase()}`);
export const contactAssignPermissions = () =>
  i18n.t("contact.assign_permissions");
export const contactBankaccountLabel = () =>
  i18n.t("contact.bankaccount.iban.label");
export const contactBankaccountsLabel = () =>
  i18n.t("contact.bankaccounts.iban.label");
export const contactBankaccountOwnerNameLabel = () =>
  i18n.t("contact.bankaccount.owner_name.label");
export const contactViewContactAction = () =>
  i18n.t("contact.view_contact.action");
export const contactResendKYCRegistrationAction = () =>
  i18n.t("contact.resend_kyc_registration.action");
export const contactDetails = () => i18n.t("contact.details");
export const contactDetailsAddress = () => i18n.t("contact.details.address");
export const contactDetailsPersonal = () => i18n.t("contact.details.personal");
export const contactDetailsFinancial = () =>
  i18n.t("contact.details.financial");
export const contactHistoryHeading = (args: ITranslateProps) =>
  i18n.t("contact.history.heading", args);
export const contactCompanyDetailsContact = () =>
  i18n.t("contact.company.details.contact");
export const contactDetailsExtra = () => i18n.t("contact.details.extra");
export const contactDetailsContact = () =>
  i18n.t("contact.company.details.contact");
export const contactUserSince = () => i18n.t("contact.user_since");
export const contactConnectedSince = (args: ITranslateProps) =>
  i18n.t("contact.connected_since", args);
export const contactInvitedOn = () => i18n.t("contact.invited_on");
export const contactResendInvitation = () =>
  i18n.t("contact.resend_invitation");
export const contactResendInvitationSuccess = () =>
  i18n.t("contact.resend_invitation.success");
export const contactResendInvitationError = () =>
  i18n.t("contact.resend_invitation.error");
export const contactDocumentsOverviewHeading = () =>
  i18n.t("contact.document_overview.heading");

export const contactResendKYCRegistrationSentSuccess = ({
  name,
}: {
  name: string;
}) =>
  i18n.t("contact.resend_kyc_registration.sent.success", { values: { name } });
export const contactResendKYCRegistrationSentFail = ({
  name,
}: {
  name: string;
}) => i18n.t("contact.resend_kyc_registration.sent.fail", { values: { name } });

export const editContact = () => i18n.t("contact.edit");
export const editContactCompany = () => i18n.t("contact.company.edit");
export const removeContact = () => i18n.t("contact.delete");
export const removeContactConfirmBody = (name: string) =>
  i18n.t("contact.delete.confirm.body", { values: { name } });

// Contact overview
export const contactOverviewTitle = () => i18n.t("contact.overview.title");
export const contactsFilterQueryPlaceholder = () =>
  i18n.t("contacts.filter.query.placeholder");

// Contact detail
export const contactDetailBreadcrumb = () =>
  i18n.t("contact.detail.breadcrumb");
export const contactDetailTabsInformation = () =>
  i18n.t("contact.detail.tabs.information");
export const contactDetailTabsHistory = () =>
  i18n.t("contact.detail.tabs.history");
export const contactDetailTabsDocuments = () =>
  i18n.t("contact.detail.tabs.documents");
export const contactPropertyEmptyHeading = () =>
  i18n.t("contact.property.empty.heading");
export const contactHistoryEmptyHeading = () =>
  i18n.t("contact.history.empty.heading");
export const contactHistoryEmptyContent = () =>
  i18n.t("contact.history.empty.content");

// Contact dashboard
export const contactDashboardContactDataHeading = () =>
  i18n.t("contact.dashboard.contact_data.heading");
export const contactDashboardHistoryHeading = () =>
  i18n.t("contact.dashboard.history.heading");
export const contactEditModalHeading = () =>
  i18n.t("contact.edit.modal.heading");

export const contactDashboardChatsHeading = (args?: ITranslateProps) =>
  i18n.t("contact.dashboard.chats.heading", args);
export const contactDashboardNoChatsHeading = (args?: ITranslateProps) =>
  i18n.t("contact.dashboard.no_chats.heading", args);
export const contactDashboardNoChatsContent = () =>
  i18n.t("contact.dashboard.no_chats.content");
export const contactDashboardNoChatsAction = (args?: ITranslateProps) =>
  i18n.t("contact.dashboard.no_chats.action", args);

// Contact select
export const contactSelectSearchLabel = () =>
  i18n.t("contact_select.search.label");

// Chats
export const chatsTitle = () => i18n.t("chats.title");
export const chatsAddActionTitle = () => i18n.t("chats.add_action.title");
export const chatsInfoActionTitle = () => i18n.t("chats.info_action.title");
export const chatsArchiveActionTitle = () =>
  i18n.t("chats.archive_action.title");
export const chatsUnarchiveActionTitle = () =>
  i18n.t("chats.unarchive_action.title");
export const chatsSearchPlaceholder = () => i18n.t("chats.search.placeholder");
export const chatsListEmptyText = () => i18n.t("chats.list.empty.text");
export const chatsListEmptyAction = () => i18n.t("chats.list.empty.action");
export const chatsListShowMore = () => i18n.t("chats.list.cta.show_more");
export const chatsViewArchived = () => i18n.t("chats.view.archived");

export const chatsCreateTitle = () => i18n.t("chats.create.title");
export const chatsEditTitle = () => i18n.t("chats.edit.title");
export const chatsInfoTitle = (name: string) =>
  i18n.t("chats.info.title", { values: { name } });
export const chatParticipants = () => i18n.t("chat.participants");
export const chatParticipantAdded = () => i18n.t("chat.participant.added");
export const chatParticipantRemoved = () => i18n.t("chat.participant.removed");
export const chatSubjectHeading = () => i18n.t("chats.subject.heading");
export const chatsSubjectLabel = () => i18n.t("chats.subject.label");
export const chatsDetailBreadcrumbAction = () =>
  i18n.t("chats.detail.breadcrumb.action");
export const chatPropertyHeading = () => i18n.t("chats.property.heading");
export const chatRemoveParticipant = () => i18n.t("chats.remove_participant");
export const chatRemovedParticipant = () => i18n.t("chats.removed_participant");
export const chatAddedParticipant = () => i18n.t("chats.added_participant");
export const chatArchivedCardTitle = () => i18n.t("chat.archived.card.title");
export const chatArchivedCardContent = () =>
  i18n.t("chat.archived.card.content");
export const chatArchivedCardCta = () => i18n.t("chat.archived.card.cta");

export const chatsToastGoToChatAction = () =>
  i18n.t("chats.toast.go_to_chat.action");

// Tasks
export const taskDetailTabsInformation = () =>
  i18n.t("task.detail.tabs.information");
export const taskDetailTabsHistory = () => i18n.t("task.detail.tabs.history");
export const taskDetailTabsDocuments = () =>
  i18n.t("task.detail.tabs.documents");
export const tasksTitle = () => i18n.t("tasks.title");
export const tasksAddActionTitle = () => i18n.t("tasks.new");
export const tasksSearchPlaceholder = () => i18n.t("tasks.search.placeholder");
export const tasksListEmptyText = () =>
  i18n.t("tasks.overview.empty_state.title");
export const tasksListEmptyAction = () => i18n.t("quick_action.new_task.title");
export const tasksEditTitle = () => i18n.t("tasks.edit");
export const tasksToDo = () => i18n.t("todo");
export const tasksToFollow = () => i18n.t("todo_as_follower");
export const tasksRecentlyFinished = () => i18n.t("recently.finished");
export const tasksFinished = () => i18n.t("task.completed");
export const taskCreatedAt = (date: string) =>
  i18n.t("task.created.at", { values: { date } });
export const taskCompletedAt = (date: string) =>
  i18n.t("task.completed.at", { values: { date } });
export const taskDescription = () => i18n.t("system.description");
export const taskCompletedBtn = () => i18n.t("task.complete.btn");
export const taskActivate = () => i18n.t("task.activate");
export const taskActivated = () => i18n.t("task.activated");
export const taskExecutors = () => i18n.t("task.executor.label");
export const taskFollowers = () => i18n.t("task.follower.label");
export const taskAddFollower = () => i18n.t("task.add.follower");
export const taskAddExecutor = () => i18n.t("tasks.add.executors");
export const taskCompleted = () => i18n.t("task.completed");
export const taskFollowerAdded = () => i18n.t("task.follower.added");
export const taskExecutorAdded = () => i18n.t("task.executor.added");
export const taskFollowerDeleted = () => i18n.t("task.follower.deleted");
export const taskExecutorDeleted = () => i18n.t("task.executor.deleted");
export const taskRemoveExecutor = () => i18n.t("tasks.remove.executor");
export const taskRemoveFollower = () => i18n.t("tasks.remove.follower");
export const taskEmptySelect = () => i18n.t("task.empty.select");
export const taskNew = () => i18n.t("quick_action.new_task.title");
export const repairNew = () => i18n.t("quick_action.new_repair.title");
export const repairEdit = () => i18n.t("task.repair.edit");
export const taskCreated = () => i18n.t("history.new.task");
export const taskEdited = () => i18n.t("task.edited");
export const tasksEdit = () => i18n.t("tasks.edit");
export const taskEmpty = () => i18n.t("tasks.empty.subtitle");
export const taskEmptyState = () => i18n.t("tasks.empty");
export const taskEmptyStateTodo = () => i18n.t("tasks.empty_todo");
export const taskEmptyStateFollowUp = () => i18n.t("tasks.empty_follow_up");
export const taskEmptyStateDone = () => i18n.t("tasks.empty_done");
export const taskEmptyStateTime = (time: string) =>
  i18n.t(`tasks.empty_section.${time}`);
export const tasksOverview = () => i18n.t("tasks.overview.breadcrumb");
export const tasksInlineShowMore = () => i18n.t("task.inline.cta.show_more");
export const tasksExecuteDate = () => i18n.t("task.execute_date");
export const tasksExecuteDateMeta = () => i18n.t("task.execute_date.meta");
export const tasksDueDate = () => i18n.t("task.due_date");
export const taskTimeSpan = (value: string) =>
  i18n.t(`tasks.time_span.${value}`);
export const tasksStageToday = () => i18n.t("tasks.stage.today");
export const tasksStageBeforeToday = () => i18n.t("tasks.stage.before_today");
export const tasksStageThisWeek = () => i18n.t("tasks.stage.this_week");
export const tasksStageNextWeek = () => i18n.t("tasks.stage.next_week");
export const tasksStageAll = () => i18n.t("tasks.stage.all");

export const taskDashboardCardArchivedTitle = () =>
  i18n.t("task.dashboard.card.archived.title");
export const taskDashboardCardArchivedContent = () =>
  i18n.t("task.dashboard.card.archived.content");
export const taskDashboardCardArchivedCta = () =>
  i18n.t("task.dashboard.card.archived.cta");

export const noteTitle = () => i18n.t("system.model.note");
export const emptyNote = () => i18n.t("task.note_empty");
export const noteAdd = () => i18n.t("task.note_add");
export const noteLastUpdated = () => i18n.t("task.note.last_updated");
export const noteEdit = () => i18n.t("task.note_edit");
export const noteDelete = () => i18n.t("note.delete");

export const commentTitle = () => i18n.t("system.comments");
export const commentEmptyTitle = () => i18n.t("comment.empty.info");
export const commentDelete = () => i18n.t("comment.delete");
export const addCommentPlaceholder = () => i18n.t("comment_add.placeholder");
export const systemSubmit = () => i18n.t("system.submit");

// Payments follow up
export const paymentsFollowUpHeading = () =>
  i18n.t("payments.follow_up.heading");
export const followUpPaymentsPageDescription = () =>
  i18n.t("payments.follow_up.page.description");

export const paymentsFollowUpOrdersHeading = () =>
  i18n.t("payments.follow_up.orders.heading");
export const paymentsFollowUpCardActionsDetailPaymentRequest = () =>
  i18n.t("payments.follow_up.card_actions.detail_payment_request");
export const paymentsFollowUpCardActionsMarkPayed = () =>
  i18n.t("payments.follow_up.card_actions.mark_payed");

export const paymentsFollowUpCardActionsContactPayer = () =>
  i18n.t("payments.follow_up.card_actions.contact_payer");

export const paymentsFollowUpMissingKYCHeading = () =>
  i18n.t("payments.follow_up.missing_kyc.heading");

export const paymentsFollowUpCardDaysMessage = ({ days }: { days: number }) => {
  if (days <= 0) {
    return i18n.t("payments.follow_up.days.in_advance", {
      values: { days: `${0 - days}` },
      n: days,
    });
  }

  return i18n.t("payments.follow_up.days.overdue", {
    values: { days: `${days}` },
    n: days,
  });
};

export const paymentsFollowUpIncomingHeading = () =>
  i18n.t("payments.follow_up.incoming.heading");
export const paymentsFollowUpIncomingEmptyHeading = () =>
  i18n.t("payments.follow_up.incoming.empty.heading");
export const paymentsFollowUpIncomingEmptyContent = () =>
  i18n.t("payments.follow_up.incoming.empty.content");

export const paymentsFollowUpReminderRentioHeading = () =>
  i18n.t("payments.follow_up.reminder_rentio.heading");
export const paymentsFollowUpReminderRentioEmptyHeading = () =>
  i18n.t("payments.follow_up.reminder_rentio.empty.heading");
export const paymentsFollowUpReminderRentioEmptyContent = () =>
  i18n.t("payments.follow_up.reminder_rentio.empty.content");

export const paymentsFollowUpActionRequiredHeading = () =>
  i18n.t("payments.follow_up.action_required.heading");
export const paymentsFollowUpActionRequiredEmptyHeading = () =>
  i18n.t("payments.follow_up.action_required.empty.heading");
export const paymentsFollowUpActionRequiredEmptyContent = () =>
  i18n.t("payments.follow_up.action_required.empty.content");
export const paymentsFollowUpActionRequiredAction = () =>
  i18n.t("payments.follow_up.action_required.action");
export const paymentsFollowUpReason = ({
  reason,
  mandate,
  date,
}: {
  reason: string;
  mandate: string;
  date?: string;
}) =>
  i18n.t(`payments.follow_up.reason.${reason}.${mandate}`, {
    values: date ? { date } : {},
  });
export const paymentsFollowUpReasonWithEstimatedCollectionDate = ({
  reason,
  date,
}: {
  reason: string;
  date: string;
}) =>
  i18n.t(`payments.follow_up.reason.${reason}.with_collection_date`, {
    values: { date },
  });
export const paymentsFollowUpLastReminder = ({ date }: { date: string }) =>
  i18n.t("payments.follow_up.last_reminder", { values: { date } });
export const paymentsFollowUpNextReminder = ({
  date,
  count,
}: {
  date: string;
  count: string;
}) => i18n.t("payments.follow_up.next_reminder", { values: { date, count } });

// Payments follow-up modal
export const paymentsFollowUpModalHeading = () =>
  i18n.t("payments.follow_up.modal.heading");
export const paymentsFollowUpModalMethodLabel = () =>
  i18n.t("payments.follow_up.modal.method.label");
export const paymentsFollowUpModalMethodOptionAppLabel = () =>
  i18n.t("payments.follow_up.modal.method.option.app.label");
export const paymentsFollowUpModalMethodOptionPhoneLabel = () =>
  i18n.t("payments.follow_up.modal.method.option.phone.label");
export const paymentsFollowUpModalMethodOptionMailLabel = () =>
  i18n.t("payments.follow_up.modal.method.option.mail.label");
export const paymentsFollowUpModalMethodOptionRegisteredMailLabel = () =>
  i18n.t("payments.follow_up.modal.method.option.registered_mail.label");

export const paymentsFollowUpModalMethodAppHeading = () =>
  i18n.t("payments.follow_up.modal.method.app.heading");
export const paymentsFollowUpModalMethodPhoneHeading = () =>
  i18n.t("payments.follow_up.modal.method.phone.heading");
export const paymentsFollowUpModalMethodMailHeading = () =>
  i18n.t("payments.follow_up.modal.method.mail.heading");
export const paymentsFollowUpModalMethodRegisteredMailHeading = () =>
  i18n.t("payments.follow_up.modal.method.registered_mail.heading");
export const paymentsFollowUpModalAddContactHeading = () =>
  i18n.t("payments.follow_up.modal.add_contact.heading");

export const paymentsFollowUpModalPhoneStatusNotAnsweredLabel = () =>
  i18n.t("payments.follow_up.modal.phone_status.option.not_answered.label");
export const paymentsFollowUpModalPhoneStatusAnsweredLabel = () =>
  i18n.t("payments.follow_up.modal.phone_status.option.answered.label");
export const paymentsFollowUpModalPhoneSummaryLabel = () =>
  i18n.t("payments.follow_up.modal.summary.label");
export const paymentsFollowUpModalPhonePromisedDataLabel = () =>
  i18n.t("payments.follow_up.modal.promised_date.label");
export const paymentsFollowUpModalPhoneSendToPayerLabel = () =>
  i18n.t("payments.follow_up.modal.send_to_payer.label");

export const paymentsFollowUpAppContent = (args: ITranslateProps = {}) =>
  i18n.t("payments.follow_up.app.content", args);
export const paymentsFollowUpAppContentLabel = () =>
  i18n.t("payments.follow_up.app.content.label");
export const paymentsFollowUpMailContent = (args: ITranslateProps = {}) =>
  i18n.t("payments.follow_up.mail.content", args);
export const paymentsFollowUpMailContentLabel = (args: ITranslateProps = {}) =>
  i18n.t("payments.follow_up.mail.content.label", args);
export const paymentsFollowUpMailSubject = (args: ITranslateProps = {}) =>
  i18n.t("payments.follow_up.mail.subject", args);
export const paymentsFollowUpMailHeading = (args: ITranslateProps = {}) =>
  i18n.t("payments.follow_up.mail.heading", args);
export const paymentsFollowUpMailAction = (args: ITranslateProps = {}) =>
  i18n.t("payments.follow_up.mail.action", args);
export const paymentsFollowUpMailFootnote = () =>
  i18n.t("payments.follow_up.mail.footnote");
export const paymentsFollowUpRegisteredMailContent = (
  args: ITranslateProps = {},
) => i18n.t("payments.follow_up.registered_mail.content", args);
export const paymentsFollowUpRegisteredMailContentLabel = (
  args: ITranslateProps = {},
) => i18n.t("payments.follow_up.registered_mail.content.label", args);
export const paymentsFollowUpRegisteredMailSubject = (
  args: ITranslateProps = {},
) => i18n.t("payments.follow_up.registered_mail.subject", args);
export const paymentsFollowUpRegisteredMailHeading = (
  args: ITranslateProps = {},
) => i18n.t("payments.follow_up.registered_mail.heading", args);
export const paymentsFollowUpRegisteredMailAction = (
  args: ITranslateProps = {},
) => i18n.t("payments.follow_up.registered_mail.action", args);
export const paymentsFollowUpRegisteredMailSubjectLabel = () =>
  i18n.t("payments.follow_up.registered_mail.subject.label");
export const paymentsFollowUpRegisteredMailCellPhoneLabel = () =>
  i18n.t("payments.follow_up.registered_mail.cell_phone.label");
export const paymentsFollowUpModalPhoneEmailLabel = () =>
  i18n.t("payments.follow_up.registered_mail.email.label");
export const paymentsFollowUpModalPhoneCutOffDateDataLabel = () =>
  i18n.t("payments.follow_up.registered_mail.cut_off_date.label");
export const paymentsFollowUpModalPhoneAddressLabel = () =>
  i18n.t("payments.follow_up.registered_mail.address.label");

export const paymentsFollowUpActivityInvitationSent = ({
  actor,
}: {
  actor: string;
}) =>
  i18n.t("payments.follow_up.activity.invitation_sent", { values: { actor } });
export const paymentsFollowUpActivityReminderSent = ({
  actor,
  count,
}: {
  actor: string;
  count: number;
}) =>
  i18n.t("payments.follow_up.activity.reminder_sent", {
    values: { actor, count: String(count) },
    n: count,
  });
export const paymentsFollowUpActivityReminderSend = ({
  actor,
  count,
}: {
  actor: string;
  count: number;
}) =>
  i18n.t("payments.follow_up.activity.reminder_send", {
    values: { actor, count: String(count) },
    n: count,
  });

export const paymentRequestFollowUpMailSuccess = () =>
  i18n.t("payment_request.follow_up.mail.success");
export const paymentRequestFollowUpMailFailed = () =>
  i18n.t("payment_request.follow_up.mail.failed");
export const paymentRequestFollowUpAppSuccess = () =>
  i18n.t("payment_request.follow_up.app.success");
export const paymentRequestFollowUpAppFailed = () =>
  i18n.t("payment_request.follow_up.app.failed");
export const paymentRequestFollowUpPhoneSuccess = () =>
  i18n.t("payment_request.follow_up.phone.success");
export const paymentRequestFollowUpPhoneFailed = () =>
  i18n.t("payment_request.follow_up.phone.failed");
export const paymentRequestFollowUpRegisteredMailSuccess = () =>
  i18n.t("payment_request.follow_up.registered_mail.success");
export const paymentRequestFollowUpRegisteredMailFailedHeading = () =>
  i18n.t("payment_request.follow_up.registered_mail.failed.heading");
export const paymentRequestFollowUpRegisteredMailFailedContent = () =>
  i18n.t("payment_request.follow_up.registered_mail.failed.content");

export const paymentRequestFollowUpAddContactNoticeHeading = (
  args: ITranslateProps,
) => i18n.t("payment_request.follow_up.add_contact.notice.heading", args);
export const paymentRequestFollowUpAddContactNoticeContent = (
  args: ITranslateProps,
) => i18n.t("payment_request.follow_up.add_contact.notice.content", args);

export const paymentRequestFollowUpFilterFeaturesLabel = () =>
  i18n.t("payment_request.follow_up.filter.features.label");
export const paymentRequestFollowUpFilterFeaturesAll = () =>
  i18n.t("payment_request.follow_up.filter.features.all");
export const paymentRequestFollowUpFilterFeaturesType = (type: string) =>
  i18n.t(
    `payment_request.follow_up.filter.features.feature.${type.toLowerCase()}.label`,
  );

// Payout follow-up
export const paymentsCollectionFollowUpHeading = () =>
  i18n.t("payments.collection.follow_up.heading");
export const paymentsCollectionFollowUpReason = ({
  reason,
}: {
  reason: string;
}) => i18n.t(`payments.collection.follow_up.reason.${reason}`);
export const paymentsCollectionFollowUpRentioNoFollowUp = ({
  name,
}: {
  name: string;
}) =>
  i18n.t("payments.collection.follow_up.rentio.no_follow_up", {
    values: { name },
  });
export const paymentsCollectionFollowUpRentioFollowUp = ({
  name,
  date,
}: {
  name: string;
  date: string;
}) =>
  i18n.t("payments.collection.follow_up.rentio.follow_up", {
    values: { name, date },
  });

// Follow Up Rent Deposits
export const followUpRentDepositsPageTitle = () =>
  i18n.t("follow_up.rent_deposits.page.title");
export const followUpRentDepositsPageDescription = () =>
  i18n.t("follow_up.rent_deposits.page.description");
export const followUpRentDepositsBreadcrumb = () =>
  i18n.t("follow_up.rent_deposits.page.breadcrumb");

export const followUpRentDepositsStageLabel = ({
  extra: { key } = {},
}: ITranslateProps) => i18n.t(`follow_up.rent_deposits.stage.${key}.label`);

export const followUpRentDepositsFilterQueryPlaceholder = () =>
  i18n.t("follow_up.rent_deposits.filter.query.placeholder");
export const followUpRentDepositsFilterLabel = ({
  extra: { key } = {},
}: ITranslateProps) => i18n.t(`follow_up.rent_deposits.filter.${key}.label`);
export const followUpRentDepositsFilterStageLabel = ({
  extra: { key } = {},
}: ITranslateProps) =>
  i18n.t(`follow_up.rent_deposits.filter.stage.${key}.label`);
export const followUpRentDepositsFilterStatusLabel = ({
  extra: { key } = {},
}: ITranslateProps) =>
  i18n.t(`follow_up.rent_deposits.filter.status.${key}.label`.toLowerCase());
export const followUpRentDepositsFilterPaymentStatusLabel = ({
  extra: { key } = {},
}: ITranslateProps) =>
  i18n.t(`follow_up.rent_deposits.filter.payment_status.${key}.label`);
export const followUpRentDepositsFilterSignatureStatusLabel = ({
  extra: { key } = {},
}: ITranslateProps) =>
  i18n.t(`follow_up.rent_deposits.filter.signature_status.${key}.label`);
export const followUpRentDepositsWaitingForIbanStatusLabel = () =>
  i18n.t("follow_up.rent_deposits.status.waiting_for_iban.label");
export const followUpRentDepositsWaitingStatusLabel = ({
  extra: { status = "" } = {},
}: ITranslateProps) =>
  i18n.t(
    `follow_up.rent_deposits.status.waiting.${status.toLowerCase()}.label`,
  );
export const followUpRentDepositsManualVerificationStatusNoticeHeading = () =>
  i18n.t("follow_up.rent_deposits.manual_verification.notice.heading");
export const followUpRentDepositsManualVerificationStatusNoticeContent = () =>
  i18n.t("follow_up.rent_deposits.manual_verification.notice.content");
export const followUpRentDepositsWaitingForIbanStatusNoticeHeading = () =>
  i18n.t("follow_up.rent_deposits.waiting_for_iban.notice.heading");
export const followUpRentDepositsWaitingForIbanStatusNoticeContent = () =>
  i18n.t("follow_up.rent_deposits.waiting_for_iban.notice.content");
export const followUpRentDepositsWaitingForValidationsStatusNoticeHeading =
  () =>
    i18n.t("follow_up.rent_deposits.waiting_for_validations.notice.heading");
export const followUpRentDepositsWaitingForValidationsStatusNoticeContent =
  () =>
    i18n.t("follow_up.rent_deposits.waiting_for_validations.notice.content");
export const followUpRentDepositsWaitingForVerificationStatusNoticeHeading =
  () =>
    i18n.t("follow_up.rent_deposits.waiting_for_verification.notice.heading");
export const followUpRentDepositsWaitingForVerificationStatusNoticeContent =
  () =>
    i18n.t("follow_up.rent_deposits.waiting_for_verification.notice.content");

export const followUpRentDepositsTableContractStartDateHeading = () =>
  i18n.t("follow_up.rent_deposits.table.contract_startdate.heading");
export const followUpRentDepositsTableAddressHeading = () =>
  i18n.t("follow_up.rent_deposits.table.address.heading");
export const followUpRentDepositsTableTenantsHeading = () =>
  i18n.t("follow_up.rent_deposits.table.tenants.heading");
export const followUpRentDepositsTableOwnersHeading = () =>
  i18n.t("follow_up.rent_deposits.table.owners.heading");
export const followUpRentDepositsTableTotalAmountHeading = () =>
  i18n.t("follow_up.rent_deposits.table.total_amount.heading");
export const followUpRentDepositsTableTenantAmountHeading = () =>
  i18n.t("follow_up.rent_deposits.table.tenant_amount.heading");
export const followUpRentDepositsTableOwnerAmountHeading = () =>
  i18n.t("follow_up.rent_deposits.table.owner_amount.heading");
export const followUpRentDepositsTableBrokerAmountHeading = () =>
  i18n.t("follow_up.rent_deposits.table.broker_amount.heading");
export const followUpRentDepositsTableSignatureStatusHeading = () =>
  i18n.t("follow_up.rent_deposits.table.signature_status.heading");
export const followUpRentDepositsTablePaymentStatusHeading = () =>
  i18n.t("follow_up.rent_deposits.table.payment_status.heading");

export const followUpRentDepositsEditContractAction = () =>
  i18n.t("follow_up.rent_deposits.edit_contract.action");
export const followUpRentDepositsDocumentViewAction = () =>
  i18n.t("follow_up.rent_deposits.document_view.action");
export const followUpRentDepositsDocumentDownloadAction = () =>
  i18n.t("follow_up.rent_deposits.document_download.action");
export const followUpRentDepositsDetailPaymentRequestAction = () =>
  i18n.t("follow_up.rent_deposits.detail_payment_request.action");
export const followUpRentDepositsDetailPaymentRequestTenantAction = () =>
  i18n.t("follow_up.rent_deposits.detail_payment_request_tenant.action");
export const followUpRentDepositsDetailPaymentRequestRenterAction = () =>
  i18n.t("follow_up.rent_deposits.detail_payment_request_renter.action");
export const followUpRentDepositsDetailRentDepositAction = () =>
  i18n.t("follow_up.rent_deposits.detail_rent_deposit.action");
export const followUpRentDepositsDetailCancelAction = () =>
  i18n.t("follow_up.rent_deposits.cancel.action");

export const followUpRentDepositsEmptyStateHeading = () =>
  i18n.t("follow_up.rent_deposits.empty_state.heading");
export const followUpRentDepositsEmptyStateContent = () =>
  i18n.tm("follow_up.rent_deposits.empty_state.content");

// Payment Request
export const paymentRequestDetailPaidAt = (args: ITranslateProps) =>
  i18n.t("payment_request.info.paidAt", args);
export const paymentRequestDetailDueDateMessage = () =>
  // cspell:ignore duedate
  i18n.t("payment_request.detail.duedate.message");
export const paymentRequestDetailTabsInfo = () =>
  i18n.t("payment_request.detail.tabs.info");
export const paymentRequestDetailTabsHistory = () =>
  i18n.t("payment_request.detail.tabs.history");

export const paymentRequestResendInvitationAction = () =>
  i18n.t("payment_request.resend_invitation.action");
export const paymentRequestResendInvitationSentSuccess = ({
  name,
}: {
  name: string;
}) =>
  i18n.t("payment_request.resend_invitation.sent.success", {
    values: { name },
  });
export const paymentRequestResendInvitationSentFail = ({
  name,
}: {
  name: string;
}) =>
  i18n.t("payment_request.resend_invitation.sent.fail", { values: { name } });

export const paymentRequestNotYetPaidLabel = () =>
  i18n.t("payment_request.not_yet_paid.label");
export const paymentRequestPaidOnTimeLabel = () =>
  i18n.t("payment_request.paid_on_time.label");
export const paymentRequestPaidManuallyLabel = () =>
  i18n.t("payment_request.paid_manually.label");
export const paymentRequestPaidManuallyAtLabel = (values: {
  manuallyPaidAt: string;
  payer: string;
  payee: string;
}) =>
  i18n.tm("payment_request.paid_manually_at.label", {
    values,
  });
export const paymentRequestNotPaidOnTimeLabel = ({ days }: { days: number }) =>
  i18n.t("payment_request.not_paid_on_time.label", {
    values: { days: String(days) },
    n: days,
  });

export const paymentRequestPayerMandateLabel = () =>
  i18n.t("payment_request.payer.mandate.label");
export const paymentRequestPayerNoMandateLabel = () =>
  i18n.t("payment_request.payer.no_mandate.label");

export const paymentRequestMarkPaidAction = () =>
  i18n.t("payment_request.mark_paid.action");
export const paymentRequestMarkedPaidToastHeading = () =>
  i18n.t("payment_request.marked_paid.toast.heading");
export const paymentRequestMarkedPaidFailedHeading = ({
  extra: { errorCode } = {},
}: ITranslateProps) =>
  i18n.t(
    errorCode
      ? `payment_request.marked_paid.failed_${errorCode.toLowerCase()}.heading`
      : "payment_request.marked_paid.failed.heading",
  );
export const paymentRequestMarkedPaidFailedContent = ({
  extra: { errorCode } = {},
}: ITranslateProps) =>
  i18n.t(
    errorCode
      ? `payment_request.marked_paid.failed_${errorCode.toLowerCase()}.content`
      : "payment_request.marked_paid.failed.content",
  );

export const paymentRequestRemovedToastHeading = () =>
  i18n.t("payment_request.removed.toast.heading");

export const paymentsFollowUpCardActionsThirdPartyAccountAction = () =>
  i18n.t("payment_request.third_party_account.action");
export const paymentRequestFeatureThirdPartyAccountLabel = () =>
  i18n.t("payment_request.feature.third_party_account.label");

export const paymentRequestPaidBy = (args: ITranslateProps) =>
  i18n.tm("payment_request.paid_by", args);
export const paymentRequestReceivedBy = (args: ITranslateProps) =>
  i18n.tm(
    `payment_request.received_by${args.extra?.fromRent ? ".form_rent" : ""}`,
    args,
  );

export const paymentRequestThirdPartyAccountModalHeading = () =>
  i18n.t("payment_request.third_party_account.modal.heading");
export const paymentRequestThirdPartyAccountModalContent = (
  args: ITranslateProps,
) => i18n.t("payment_request.third_party_account.modal.content", args);
export const paymentRequestThirdPartyAccountModalOnlyThisAction = () =>
  i18n.t("payment_request.third_party_account.modal.only_this.action");
export const paymentRequestThirdPartyAccountModalAction = () =>
  i18n.t("payment_request.third_party_account.modal.action");
export const paymentRequestThirdPartyAccountModalOptionRecurringLabel = (
  args: ITranslateProps,
) =>
  i18n.t(
    "payment_request.third_party_account.modal.option.recurring.label",
    args,
  );

// Payment Request History
export const paymentsPaymentRequestsHistoryHeading = () =>
  i18n.t("payments.payment_requests.history.heading");
export const paymentsPaymentRequestsHistoryEmptyMessage = () =>
  i18n.t("payments.payment_requests.history.empty_message");

// History
export const historyViewHistoryAction = () =>
  i18n.t("history.view_history.action");
export const historyEmptyMessage = () => i18n.t("history.empty_message");

// Tac
export const tacNothingAccepted = () => i18n.t("tac.nothing_accepted");

// Document Package
export const documentPackagePrepareStep1 = () =>
  i18n.t("document_package.prepare.step_1");
export const documentPackagePrepareStep2 = () =>
  i18n.t("document_package.prepare.step_2");
export const documentPackageNameLabel = () =>
  i18n.t("document_package.name.label");
export const documentPackageSignersLabel = () =>
  i18n.t("document_package.signers.label");
export const documentPackageCreateFail = () =>
  i18n.t("document_package.create.fail");
export const documentPackageCreateSuccess = () =>
  i18n.t("document_package.create.success");
export const documentPackageRevokeAction = () =>
  i18n.t("document_package.revoke.action");
export const documentPackageRevokeFail = () =>
  i18n.t("document_package.revoke.fail");
export const documentPackageRevokeSuccess = () =>
  i18n.t("document_package.revoke.success");
export const documentPackagesStatus = (status?: EDocumentPackageStatus) =>
  i18n.t(`document_package.status.${status}`.toLowerCase());
export const documentPackageFormBreadcrumbProperty = () =>
  i18n.t("document_package.form.breadcrumb.property");
export const documentPackageFormBreadcrumbDocument = () =>
  i18n.t("document_package.form.breadcrumb.document");
export const documentPackageFormBreadcrumbFollowUp = () =>
  i18n.t("document_package.form.breadcrumb.follow_up");
export const documentPackageCreateAction = () =>
  i18n.t("document_package.create.action");
export const documentPackageSigningType = (type: string) =>
  i18n.t(`used_signing_type.${type.toLowerCase()}`);
export const documentPackageSignerToSignWith = (args: ITranslateProps) =>
  i18n.t("document_package.signer.to_sign_with", args);
export const documentPackageSignerUsedSigningType = (args: ITranslateProps) =>
  i18n.t("document_package.signer.used_signing_type", args);
export const documentPackageViewAction = () =>
  i18n.t("document_package.view.action");
export const documentPackageDetailTabsInfo = () =>
  i18n.t("document_package.detail.tabs.info");
export const documentPackageDetailTabsHistory = () =>
  i18n.t("document_package.detail.tabs.history");
export const documentPackageFormHeading = () =>
  i18n.t("document_package.form.heading");
export const documentPackageFromSendToTenantHeading = () =>
  i18n.t("document_package.form.send_to_tenant.heading");
export const documentPackageFormDescription = () =>
  i18n.t("document_package.form.description");
export const documentPackageExcerptHeading = () =>
  i18n.t("document_package.excerpt.heading");
export const documentPackageExcerptAction = () =>
  i18n.t("document_package.excerpt.action");
export const documentPackageType = (type: string) =>
  i18n.t(`document_package.type.${type}`);
export const documentPackageContractWaitingForTenantNoticeHeading = () =>
  i18n.t("document_package.contract.waiting_for_tenant.notice.heading");
export const documentPackageContractWaitingForTenantNoticeContent = () =>
  i18n.t("document_package.contract.waiting_for_tenant.notice.content");

// Follow Up Document Packages
export const followUpDocumentPackagesPageTitle = () =>
  i18n.t("follow_up.document_packages.page.title");
export const followUpDocumentPackagesPageDescription = () =>
  i18n.t("follow_up.document_packages.page.description");

export const followUpDocumentPackagesStageLabel = ({
  extra: { key } = {},
}: ITranslateProps) => i18n.t(`follow_up.document_packages.stage.${key}.label`);

export const followUpDocumentPackagesFilterQueryPlaceholder = () =>
  i18n.t("follow_up.document_packages.filter.query.placeholder");
export const followUpDocumentPackagesFilterLabel = ({
  extra: { key } = {},
}: ITranslateProps) =>
  i18n.t(`follow_up.document_packages.filter.${key}.label`);
export const followUpDocumentPackagesFilterStageLabel = ({
  extra: { key } = {},
}: ITranslateProps) =>
  i18n.t(`follow_up.document_packages.filter.stage.${key.toLowerCase()}.label`);
export const followUpDocumentPackagesFilterContractTypeLabel = ({
  extra: { key } = {},
}: ITranslateProps) =>
  i18n.t(
    `follow_up.document_packages.filter.contract_type.${key.toLowerCase()}.label`,
  );

export const followUpDocumentPackagesCreatedAtHeading = () =>
  i18n.t("follow_up.document_packages.table.created_at.heading");
export const followUpDocumentPackagesContractTypeHeading = () =>
  i18n.t("follow_up.document_packages.table.contract_type.heading");
export const followUpDocumentPackagesAddressHeading = () =>
  i18n.t("follow_up.document_packages.table.address.heading");
export const followUpDocumentPackagesSignersHeading = () =>
  i18n.t("follow_up.document_packages.table.signers.heading");
export const followUpDocumentPackagesStatusHeading = () =>
  i18n.t("follow_up.document_packages.table.status.heading");

export const followUpDocumentPackagesEmptyStateHeading = () =>
  i18n.t("follow_up.document_packages.empty_state.heading");
export const followUpDocumentPackagesEmptyStateContent = () =>
  i18n.tm("follow_up.document_packages.empty_state.content");

export const followUpDocumentPackagesEditContractAction = () =>
  i18n.t("follow_up.document_packages.edit_contract.action");

// Follow up Indexable Contracts
export const followUpIndexableContractsPageTitle = () =>
  i18n.t("follow_up.indexable_contracts.page.title");
export const followUpIndexableContractsPageDescription = () =>
  i18n.t("follow_up.indexable_contracts.page.description");

export const followUpIndexableContractsStageLabel = ({
  extra: { key } = {},
}: ITranslateProps) =>
  i18n.t(`follow_up.indexable_contracts.stage.${key}.label`);

export const followUpIndexableContractsIndexableAtHeading = () =>
  i18n.t("follow_up.indexable_contracts.indexable_at.heading");
export const followUpIndexableContractsAddressHeading = () =>
  i18n.t("follow_up.indexable_contracts.address.heading");
export const followUpIndexableContractsOwnersHeading = () =>
  i18n.t("follow_up.indexable_contracts.owners.heading");
export const followUpIndexableContractsTenantsHeading = () =>
  i18n.t("follow_up.indexable_contracts.tenants.heading");
export const followUpIndexableContractsCurrentPriceHeading = () =>
  i18n.t("follow_up.indexable_contracts.current_price.heading");
export const followUpIndexableContractsStatusHeading = () =>
  i18n.t("follow_up.indexable_contracts.status.heading");

export const followUpIndexableContractsEmptyStateHeading = () =>
  i18n.t("follow_up.indexable_contracts.empty_state.heading");
export const followUpIndexableContractsFilterQueryPlaceholder = () =>
  i18n.t("follow_up.indexable_contracts.filter.query.placeholder");
export const followUpIndexableContractsFilterLabel = ({
  extra: { key } = {},
}: ITranslateProps) =>
  i18n.t(`follow_up.indexable_contracts.filter.${key}.label`);

// TAC
export const TACUpdateHeading = () => i18n.t("tac.update.title");
export const TACUpdateDescription = () => i18n.t("tac.update.description");
export const TACUpdateAcceptAction = () => i18n.t("tac.update.cta.accept");

// Bank Accounts
export const bankAccountAddAction = () => i18n.t("bank_account.add.action");

// Brokerage Fees
export const revenuesPageHeading = (params: { broker?: IBroker }) =>
  i18n.t(`revenues${getBrokerTypePostFix(params)}.page.heading`);
export const revenuesPageSubHeading = (params: { broker?: IBroker }) =>
  i18n.t(`revenues${getBrokerTypePostFix(params)}.page.sub_heading`);
export const revenuesPageBreadcrumb = () => i18n.t("revenues.page.breadcrumb");
export const revenuesFilterQueryPlaceholder = () =>
  i18n.t("revenues.client.filter.query_placeholder");
export const revenuesClientHeading = () => i18n.t("revenues.client.heading");
export const revenuesPropertyHeading = () =>
  i18n.t("revenues.property.heading");
export const revenuesCostsHeading = () => i18n.t("revenues.costs.heading");
export const revenuesPayDateHeading = () => i18n.t("revenues.pay_date.heading");
export const revenuesAmountHeading = () => i18n.t("revenues.amount.heading");
export const revenuesPaymentStatusHeading = () =>
  i18n.t("revenues.payment_status.heading");

export const revenuesProcessedHeading = ({
  extra: { isBroker = false } = {},
}: ITranslateProps) =>
  i18n.t(`revenues${isBroker ? "_broker" : ""}.processed.heading`);

export const revenuesActionMarkProcessed = ({
  extra: { isBroker = false } = {},
}: ITranslateProps) =>
  i18n.t(`revenues${isBroker ? "_broker" : ""}.action.mark_processed`);

export const revenuesActionMarkUnprocessed = ({
  extra: { isBroker = false } = {},
}: ITranslateProps) =>
  i18n.t(`revenues${isBroker ? "_broker" : ""}.action.mark_unprocessed`);

export const revenuesFilterLabel = ({
  extra,
}: ITranslateProps<{
  isBroker: boolean;
  key: string;
}>) =>
  i18n.t(
    `revenues${
      extra?.isBroker ? "_broker" : ""
    }.${extra?.key}.label`.toLowerCase(),
  );

export const revenuesPaymentStatusLabel = ({
  extra,
}: ITranslateProps<{
  isBroker: boolean;
  key: ERevenuesPaymentStatus;
}>) =>
  i18n.t(
    `revenues${
      extra?.isBroker ? "_broker" : ""
    }.payment_status.${extra?.key}`.toLowerCase(),
  );

export const revenuesInvoicedLabel = ({
  extra,
}: ITranslateProps<{
  isBroker: boolean;
  key: ERevenuesInvoicedStatus;
}>) =>
  i18n.t(
    `revenues${
      extra?.isBroker ? "_broker" : ""
    }.invoiced.${extra?.key}.label`.toLowerCase(),
  );

export const rentioLegal = () => i18n.t("navigation.rentio_legal");

// Empty Stats
export const emptyStateSearchHeading = () =>
  i18n.t("empty_state.search.heading");
export const emptyStateSearchContent = () =>
  i18n.tm("empty_state.search.content");
