import {
  getSchema as getSchema1,
  uiSchema as uiSchema1,
} from "./schema.editPropertyInfo.1";
import { IExtraData } from "./schema.editPropertyInfo.types";
import { getInitialValues } from "./schema.editPropertyInfo.utils";

// eslint-disable-next-line import/no-default-export
export default (extraData: IExtraData) => {
  const initialValues = getInitialValues(extraData);
  return [
    {
      schema: getSchema1(initialValues),
      uiSchema: uiSchema1(extraData),
    },
  ];
};
