import { connect } from "react-redux";
import * as paymentActions from "../../../redux/payment/payment.actions";
import * as paymentSelectors from "../../../redux/payment/payment.selectors";
import PaymentOrderFetch from "./PaymentOrderFetch";
var mapStateToProps = function (state, _a) {
    var id = _a.id;
    return ({
        paymentOrder: paymentSelectors.getPaymentOrder(state, id),
        isFetchingPaymentOrder: paymentSelectors.isFetchingPaymentOrder(state, id),
        fetchErrorPaymentOrder: paymentSelectors.fetchPaymentOrderError(state, id),
    });
};
var mapDispatchToProps = {
    getPaymentOrder: paymentActions.getPaymentOrder.actions.start,
};
// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(PaymentOrderFetch);
