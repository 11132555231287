import { jsx as _jsx } from "react/jsx-runtime";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as templateHooks from "@rentiohq/shared-frontend/dist/redux/template/template.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Loading, MultiStepForm, Page } from "../../../../components";
import createSchemas from "../../forms/template";
import { isPerformingSubmitActionSelector, performingSubmitActionErrorSelector, performingSubmitActionResultSelector, submitActionCreator, } from "../../forms/template/schema.template.actions";
export var TemplateForm = function () {
    // Router
    var navigate = useNavigate();
    var templateId = useParams().templateId;
    // Form
    var formId = React.useRef("template-form-".concat(generateFormId()));
    // Redux
    var template = templateHooks.useDetail({ id: templateId }).detail;
    // Event handlers
    var handleSuccess = function (_) {
        // TODO: Show detail
        navigate("/templates");
    };
    // Render
    var renderContent = function () {
        if (templateId && !template) {
            return _jsx(Loading, {});
        }
        return (_jsx(MultiStepForm, { formId: formId.current, schemas: createSchemas({
                template: template,
            }), withSummary: false, withAside: false, submitLabel: getLocalizedText("system.save"), isPerformingSubmitActionSelector: isPerformingSubmitActionSelector(templateId), performingSubmitActionResultSelector: performingSubmitActionResultSelector(templateId), performingSubmitActionErrorSelector: performingSubmitActionErrorSelector(templateId), submitActionCreator: submitActionCreator(templateId), onSuccess: handleSuccess }));
    };
    return (_jsx(Page, { title: templateId
            ? getLocalizedText("template.edit.title")
            : getLocalizedText("template.add.title"), breadcrumbs: {
            content: getLocalizedText("template.cta.view_all"),
            to: "/templates",
        }, children: _jsx(Card, { children: renderContent() }) }));
};
// eslint-disable-next-line import/no-default-export
export default TemplateForm;
