export var TransitionStyles = function (position) {
    var positionSX = {
        transformOrigin: "0 0 0",
    };
    switch (position) {
        case "top-right":
            positionSX = {
                transformOrigin: "top right",
            };
            break;
        case "top":
            positionSX = {
                transformOrigin: "top",
            };
            break;
        case "bottom-left":
            positionSX = {
                transformOrigin: "bottom left",
            };
            break;
        case "bottom-right":
            positionSX = {
                transformOrigin: "bottom right",
            };
            break;
        case "bottom":
            positionSX = {
                transformOrigin: "bottom",
            };
            break;
        case "top-left":
        default:
            positionSX = {
                transformOrigin: "0 0 0",
            };
            break;
    }
    return positionSX;
};
