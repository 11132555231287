import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DropdownMenu, Icon } from "..";
import * as S from "./QuickActionCard.styles";
export var QuickActionCard = function (_a) {
    var _b;
    var heading = _a.heading, subheading = _a.subheading, icon = _a.icon, iconColor = _a.iconColor, count = _a.count, countDescription = _a.countDescription, url = _a.url, actions = _a.actions, emptyText = _a.emptyText, onDropdownOpen = _a.onDropdownOpen, onClick = _a.onClick;
    var navigate = useNavigate();
    var description = countDescription !== null && countDescription !== void 0 ? countDescription : getLocalizedText("system.action_required");
    var _onClick = useCallback(function () {
        if (onClick)
            onClick();
        else if (url)
            navigate(url);
    }, [navigate, onClick]);
    return (_jsxs(S.StylesQuickActionCard, { onClick: _onClick, children: [_jsxs(S.QuickActionCardHeaderContainer, { children: [_jsxs("div", { children: [_jsx(S.QuickActionCardHeader, { children: _jsx("h1", { children: heading }) }), subheading && (_jsx(S.QuickActionCardSubheader, { children: _jsx("p", { children: subheading }) }))] }), _jsx("div", { style: { display: "flex", alignItems: "center" }, children: _jsx(Icon, { source: icon, size: "large", color: (_b = iconColor) !== null && _b !== void 0 ? _b : "primary" }) })] }), _jsxs("div", { style: {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "end",
                }, children: [count !== undefined && (_jsxs("div", { children: [_jsx(S.QuickActionCount, { children: count }), _jsx(S.QuickActionCountDescription, { children: description.toUpperCase() })] })), emptyText && (_jsx(S.QuickActionCardEmptyText, { children: emptyText })), actions && (_jsx(DropdownMenu, { onOpen: onDropdownOpen, passDownClickEvents: false, children: _jsx("span", { children: _jsx(Icon, { source: "navigationMenuVertical" }) }), actions: actions }))] })] }));
};
