import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EField, IExtraData } from "./schema.manuallyMarkPaid.types";

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { paymentRequest } = extraData;

  return {
    [EField.PaidAtDate]: new Date(),
    [EField.Amount]: paymentRequest.amount,
  };
};
