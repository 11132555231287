import {
  getSchema as getSchemaElectricityGeneral,
  getValidate as getValidateElectricityGeneral,
  uiSchema as uiSchemaElectricityGeneral,
} from "./schema.moveRequestUpdate.step.electricity_general";
import {
  getSchema as getSchemaElectricityHandover,
  getValidate as getValidateElectricityHandover,
  uiSchema as uiSchemaElectricityHandover,
} from "./schema.moveRequestUpdate.step.electricity_handover";
import {
  getSchema as getSchemaElectricityHandoverDocuments,
  uiSchema as uiSchemaElectricityHandoverDocuments,
} from "./schema.moveRequestUpdate.step.electricity_handover_documents";
import {
  getSchema as getSchemaLeavingContact,
  getValidate as getValidateLeavingContact,
  uiSchema as uiSchemaLeavingContact,
} from "./schema.moveRequestUpdate.step.leaving_contact";
import {
  getSchema as getSchemaNaturalGasGeneral,
  getValidate as getValidateNaturalGasGeneral,
  uiSchema as uiSchemaNaturalGasGeneral,
} from "./schema.moveRequestUpdate.step.natural_gas_general";
import {
  getSchema as getSchemaNaturalGasHandover,
  getValidate as getValidateNaturalGasHandover,
  uiSchema as uiSchemaNaturalGasHandover,
} from "./schema.moveRequestUpdate.step.natural_gas_handover";
import {
  getSchema as getSchemaNaturalGasHandoverDocuments,
  uiSchema as uiSchemaNaturalGasHandoverDocuments,
} from "./schema.moveRequestUpdate.step.natural_gas_handover_documents";
import {
  getSchema as getSchemaNoteEnergy,
  getValidate as getValidateNoteEnergy,
  uiSchema as uiSchemaNoteEnergy,
} from "./schema.moveRequestUpdate.step.note_energy";
import {
  getSchema as getSchemaNoteTelco,
  getValidate as getValidateNoteTelco,
  uiSchema as uiSchemaNoteTelco,
} from "./schema.moveRequestUpdate.step.note_telco";
import {
  getSchema as getSchemaWaterGeneral,
  uiSchema as uiSchemaWaterGeneral,
} from "./schema.moveRequestUpdate.step.water_general";
import {
  getSchema as getSchemaWaterHandover,
  getValidate as getValidateWaterHandover,
  uiSchema as uiSchemaWaterHandover,
} from "./schema.moveRequestUpdate.step.water_handover";
import {
  getSchema as getSchemaWaterHandoverDocuments,
  uiSchema as uiSchemaWaterHandoverDocuments,
} from "./schema.moveRequestUpdate.step.water_handover_documents";
import { EStep, IExtraData } from "./schema.moveRequestUpdate.types";
import { getInitialValues } from "./schema.moveRequestUpdate.utils";

// eslint-disable-next-line import/no-default-export
export default (extraData: IExtraData) => {
  const initialValues = getInitialValues(extraData);

  switch (extraData.step) {
    case EStep.LeavingContact:
      return [
        {
          schema: getSchemaLeavingContact(initialValues, extraData),
          uiSchema: uiSchemaLeavingContact(extraData),
          validate: getValidateLeavingContact(extraData),
        },
      ];

    case EStep.EnergyNote:
      return [
        {
          schema: getSchemaNoteEnergy(initialValues, extraData),
          uiSchema: uiSchemaNoteEnergy(extraData),
          validate: getValidateNoteEnergy(extraData),
        },
      ];

    case EStep.TelcoNote:
      return [
        {
          schema: getSchemaNoteTelco(initialValues, extraData),
          uiSchema: uiSchemaNoteTelco(extraData),
          validate: getValidateNoteTelco(extraData),
        },
      ];

    case EStep.ElectricityGeneral:
      return [
        {
          schema: getSchemaElectricityGeneral(initialValues, extraData),
          uiSchema: uiSchemaElectricityGeneral(extraData),
          validate: getValidateElectricityGeneral(extraData),
        },
      ];

    case EStep.ElectricityHandover:
      return [
        {
          schema: getSchemaElectricityHandover(initialValues, extraData),
          uiSchema: uiSchemaElectricityHandover(extraData),
          validate: getValidateElectricityHandover(extraData),
        },
      ];

    case EStep.ElectricityHandoverDocuments:
      return [
        {
          schema: getSchemaElectricityHandoverDocuments(
            initialValues,
            extraData,
          ),
          uiSchema: uiSchemaElectricityHandoverDocuments(extraData),
        },
      ];

    case EStep.NaturalGasGeneral:
      return [
        {
          schema: getSchemaNaturalGasGeneral(initialValues, extraData),
          uiSchema: uiSchemaNaturalGasGeneral(extraData),
          validate: getValidateNaturalGasGeneral(extraData),
        },
      ];

    case EStep.NaturalGasHandover:
      return [
        {
          schema: getSchemaNaturalGasHandover(initialValues, extraData),
          uiSchema: uiSchemaNaturalGasHandover(extraData),
          validate: getValidateNaturalGasHandover(extraData),
        },
      ];

    case EStep.NaturalGasHandoverDocuments:
      return [
        {
          schema: getSchemaNaturalGasHandoverDocuments(
            initialValues,
            extraData,
          ),
          uiSchema: uiSchemaNaturalGasHandoverDocuments(extraData),
        },
      ];

    case EStep.WaterGeneral:
      return [
        {
          schema: getSchemaWaterGeneral(initialValues, extraData),
          uiSchema: uiSchemaWaterGeneral(extraData),
        },
      ];

    case EStep.WaterHandover:
      return [
        {
          schema: getSchemaWaterHandover(initialValues, extraData),
          uiSchema: uiSchemaWaterHandover(extraData),
          validate: getValidateWaterHandover(extraData),
        },
      ];

    case EStep.WaterHandoverDocuments:
      return [
        {
          schema: getSchemaWaterHandoverDocuments(initialValues, extraData),
          uiSchema: uiSchemaWaterHandoverDocuments(extraData),
        },
      ];

    default:
      return [];
  }
};
