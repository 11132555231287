import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
// Context
export var ReadOnlyContext = React.createContext({
    isShowingModal: false,
    setIsShowingModal: function (newValue) { },
});
// Provider
var Provider = function (props) {
    var children = props.children;
    // State
    var _a = React.useState(false), isShowingModal = _a[0], setIsShowingModal = _a[1];
    // Render
    return (_jsx(ReadOnlyContext.Provider, { value: {
            isShowingModal: isShowingModal,
            setIsShowingModal: setIsShowingModal,
        }, children: children }));
};
// eslint-disable-next-line import/no-default-export
export default Provider;
