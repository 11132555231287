var _a;
import { compact } from "lodash";
import { generateSelectors } from "../generators/generator.selectors";
import { GENERATOR_PARAMS, REDUCER_KEY } from "./paymentRequest.constants";
export var getDetail = (_a = generateSelectors(GENERATOR_PARAMS), _a.getDetail), getPaged = _a.getPaged, getCreatedDetail = _a.getCreatedDetail, getCount = _a.getCount, getInfiniteLoad = _a.getInfiniteLoad, isFetchingDetail = _a.isFetchingDetail, fetchDetailError = _a.fetchDetailError, isCreating = _a.isCreating, createError = _a.createError, isUpdating = _a.isUpdating, updateError = _a.updateError, isRemoving = _a.isRemoving, removeError = _a.removeError;
var extractPaymentRequests = function (state, paymentRequestIds) {
    return compact(paymentRequestIds
        ? paymentRequestIds.map(function (id) { var _a; return (_a = state.PAYMENT_REQUESTS_V2.detail[id]) === null || _a === void 0 ? void 0 : _a.entity; })
        : []);
};
var buildPaymentRequestMap = function (state, identifiers) {
    var map = {};
    identifiers.forEach(function (identifier) {
        var infiniteLoadData = state.PAYMENT_REQUESTS_V2.infiniteLoad[identifier];
        if (!infiniteLoadData)
            return;
        var paymentRequestIds = infiniteLoadData.ids;
        var paymentRequests = extractPaymentRequests(state, paymentRequestIds);
        map[identifier] = {
            isFetching: infiniteLoadData.pending || false,
            fetchError: infiniteLoadData.error,
            isExhausted: infiniteLoadData.isExhausted,
            paymentRequestIds: paymentRequestIds,
            paymentRequests: paymentRequests,
        };
    });
    return map;
};
export var getPaymentRequestsByIdentifiers = function (state, paymentRequestsIdentifiers) {
    if (!state.PAYMENT_REQUESTS_V2.infiniteLoad) {
        return {};
    }
    var identifiers = Object.keys(state.PAYMENT_REQUESTS_V2.infiniteLoad).filter(function (identifier) { return paymentRequestsIdentifiers.includes(identifier); });
    return buildPaymentRequestMap(state, identifiers);
};
export var getIsFetchingOrHasErrorPaymentRequestsByIdentifiers = function (state, paymentRequestsIdentifiers) {
    if (!state.PAYMENT_REQUESTS_V2.infiniteLoad) {
        return {};
    }
    var infiniteLoad = state.PAYMENT_REQUESTS_V2.infiniteLoad;
    var identifiers = paymentRequestsIdentifiers.filter(function (identifier) {
        return infiniteLoad.hasOwnProperty(identifier);
    });
    var map = {};
    identifiers.forEach(function (identifier) {
        var pr = infiniteLoad[identifier];
        map[identifier] = (pr === null || pr === void 0 ? void 0 : pr.pending) || !!(pr === null || pr === void 0 ? void 0 : pr.error) || false;
    });
    return map;
};
export var isMarkingPaid = function (state, paymentRequestId) {
    return state.PAYMENT_REQUESTS_V2.markPaid[paymentRequestId]
        ? state.PAYMENT_REQUESTS_V2.markPaid[paymentRequestId].isMarkingPaid
        : false;
};
export var markPaidError = function (state, paymentRequestId) {
    return state.PAYMENT_REQUESTS_V2.markPaid[paymentRequestId]
        ? state.PAYMENT_REQUESTS_V2.markPaid[paymentRequestId].markPaidError
        : undefined;
};
export var isFetchingPaymentLink = function (state, paymentRequestId) { var _a; return ((_a = state.PAYMENT_REQUESTS_V2.paymentLink[paymentRequestId]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var getPaymentLink = function (state, paymentRequestId) { var _a; return (_a = state.PAYMENT_REQUESTS_V2.paymentLink[paymentRequestId]) === null || _a === void 0 ? void 0 : _a.url; };
export var getPaymentLinkError = function (state, paymentRequestId) { var _a; return (_a = state.PAYMENT_REQUESTS_V2.paymentLink[paymentRequestId]) === null || _a === void 0 ? void 0 : _a.fetchError; };
export var isFetchingGrantAccessPaymentRequest = function (state, paymentRequestId) { var _a; return ((_a = state.PAYMENT_REQUESTS_V2.grantAccess[paymentRequestId]) === null || _a === void 0 ? void 0 : _a.isFetching) || false; };
export var getGrantAccessPaymentRequest = function (state, paymentRequestId) { var _a; return (_a = state.PAYMENT_REQUESTS_V2.detail[paymentRequestId]) === null || _a === void 0 ? void 0 : _a.entity; };
export var getGrantAccessPaymentRequestError = function (state, paymentRequestId) { var _a; return (_a = state.PAYMENT_REQUESTS_V2.grantAccess[paymentRequestId]) === null || _a === void 0 ? void 0 : _a.fetchError; };
