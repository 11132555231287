import {
  EContractIndexationStatus,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { iHaveRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import React from "react";
import StatusDot, { EStatus } from "../StatusDot";

interface IProps {
  property?: IProperty;
  contract?: IContract;
}

const StatusDotContractIndexStatus: React.FC<IProps> = props => {
  const { property, contract } = props;

  // Helpers
  const getStatus = () => {
    if (!property) {
      return EStatus.None;
    }

    if (!iHaveRole(property, EPropertyMemberTypes.IndexationManager)) {
      return EStatus.None;
    }

    if (!contract) {
      return EStatus.None;
    }

    switch (contract.indexationStatus) {
      case EContractIndexationStatus.Ready:
      case EContractIndexationStatus.OwnerApproved:
      case EContractIndexationStatus.OwnerDeclined:
        return EStatus.Error;

      case EContractIndexationStatus.Muted:
      case EContractIndexationStatus.OwnerAsked:
        return EStatus.Warning;

      case EContractIndexationStatus.NotReady:
      default:
        return EStatus.Success;
    }
  };

  // Render
  const renderTooltipContent = () => {
    if (!property) {
      return;
    }

    if (!iHaveRole(property, EPropertyMemberTypes.IndexationManager)) {
      return;
    }

    if (!contract?.indexationStatus) {
      return;
    }

    return getLocalizedText(
      `properties.overview.indexation_status.${contract.indexationStatus}`.toLowerCase(),
    );
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={renderTooltipContent()} />
  );
};

// eslint-disable-next-line import/no-default-export
export default StatusDotContractIndexStatus;
