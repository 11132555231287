import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import "draft-js/dist/Draft.css";
import React from "react";
import { useToggle } from "../../../../../../hooks/useToggle";
import ConditionModal from "../../components/ConditionModal";
import { FieldsContext } from "../../contexts/Fields.context";
import { ReadOnlyContext } from "../../contexts/ReadOnly.context";
import * as S from "../decorator.styles";
var Renderer = function (props) {
    var _a, _b;
    var entityKey = props.entityKey, contentState = props.contentState;
    // Context
    var fields = React.useContext(FieldsContext).fields;
    var setIsShowingModal = React.useContext(ReadOnlyContext).setIsShowingModal;
    // Helpers
    var entity = contentState.getEntity(entityKey);
    var data = entity.getData();
    var id = data.id, instancePosition = data.instancePosition, renderType = data.renderType;
    var condition = fields.conditions.find(function (x) { return x.id === id; });
    var variableId = condition === null || condition === void 0 ? void 0 : condition.variableId;
    var variable = (_a = fields.variables) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === variableId; });
    var groupId = variable === null || variable === void 0 ? void 0 : variable.groupId;
    var group = (_b = fields.groups) === null || _b === void 0 ? void 0 : _b.find(function (x) { return x.id === groupId; });
    var isLeading = instancePosition === "LEADING";
    // State
    var _c = useToggle(), showModal = _c[0], toggleModalOn = _c[2], toggleModalOff = _c[3];
    // Event handlers
    var handleShowModal = function () {
        setIsShowingModal(true);
        toggleModalOn();
    };
    var handleCloseModal = function () {
        setIsShowingModal(false);
        toggleModalOff();
    };
    var contentItems = [];
    if (group) {
        contentItems.push(getLocalizedText(group.name));
    }
    if (variable) {
        contentItems.push(getLocalizedText(variable.name));
    }
    if (condition) {
        contentItems.push(getLocalizedText("editor.condition.type.".concat(stringToSnakeCase(condition.type))));
    }
    // Render
    return (_jsxs(S.Wrap, { contentEditable: false, 
        // @ts-ignore
        readOnly: true, renderType: renderType, children: [_jsx(S.Button, { onClick: handleShowModal, 
                // onMouseEnter={togglePopover}
                // onMouseLeave={togglePopover}
                contentEditable: false, 
                // @ts-ignore
                readOnly: true, renderType: renderType, isStart: isLeading, tintColor: "lightgreen", children: contentItems.join(" - ") }), showModal && _jsx(ConditionModal, { id: id, onClose: handleCloseModal })] }));
};
// eslint-disable-next-line import/no-default-export
export default Renderer;
