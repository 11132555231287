import { useTheme } from "@mui/material/styles";
export var UploadFieldStyles = function (_a) {
    var hasError = _a.hasError, isDraggingOver = _a.isDraggingOver;
    var theme = useTheme();
    return {
        wrapper: {
            p: 2,
            border: "1px dashed ".concat(hasError ? theme.palette.error.dark : theme.palette.grey[200]),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "92px",
            cursor: "pointer",
            position: "relative",
            background: theme.palette.background.paper,
            borderRadius: "4px",
            "&:hover": {
                borderColor: theme.palette.grey[900],
            },
            "&:focus": {
                outline: "none",
            },
        },
        content: {
            placeHolderTitle: {
                color: theme.palette.grey[900],
            },
        },
    };
};
