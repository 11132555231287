import { IDocumentPackage } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import {
  SimpleCard,
  Stack,
} from "@rentiohq/web-shared-next/dist/ui-components";
import React from "react";
import { SignerList } from "tenant-dashboard/components/SignerList";
import { ts } from "../../../../../../../services";
import { DocumentList } from "../../../../../DocumentList";
import { EDocumentPackageTabs } from "../../DocumentPackage";
import { DocumentPackageHistory } from "../DocumentPackageHistory";

interface IProps {
  documentPackage: IDocumentPackage;
  setActiveTab: (tab: EDocumentPackageTabs) => void;
}

export const DocumentPackageInfo: React.FC<IProps> = ({
  documentPackage,
  setActiveTab,
}) => {
  return (
    <Stack direction="column" gap={1}>
      {documentPackage?.documentsInPackageIds.length > 0 && (
        <SimpleCard title={ts.system("documents")}>
          <DocumentList
            documentsInPackageIds={documentPackage.documentsInPackageIds}
          />
        </SimpleCard>
      )}
      {documentPackage && (
        <SimpleCard title={ts.system("signers")}>
          <SignerList documentPackage={documentPackage} />
        </SimpleCard>
      )}
      {documentPackage && (
        <DocumentPackageHistory
          documentPackageId={documentPackage?.id}
          loadMore={setActiveTab}
          limit={5}
        />
      )}
    </Stack>
  );
};
