import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { IContactListDataSourceItem } from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import {
  contactSelectInlineV2ValueSelector,
  getValue,
} from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import { EContractMemberTypes } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMembersWithOneOfRoles } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.registration.types";
import {
  getRegistrationCompanyFieldsSchema,
  getRegistrationContactFieldsSchema,
} from "./schema.registration.utils";

export const getSchema =
  (
    initialValues: {
      [key: string]: unknown;
    },
    _: IExtraData,
  ) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    return {
      required: [EField.Tenants],
      properties: {
        title1: {
          default: undefined,
        },
        info1: {
          default: undefined,
        },
        [EField.Tenants]: {
          default: getValue(EField.Tenants, initialValues, defaultValues),
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData): IUiSchema<EField> => {
  const { contract, contactMeMaster, isBroker } = extraData;

  return {
    "rentio:title": utils.i18n.t("registration.create.form.step.tenants"),
    title1: {
      "ui:field": ERentioFormField.SectionHeader,
      title: utils.i18n.t("registration.create.form.field.tenants_title"),
    },
    info1: {
      "ui:field": ERentioFormField.HelpInfo,
      subtitle: getLocalizedText("registration.create.form.field.tenants_info"),
    },
    [EField.Tenants]: {
      "ui:field": ERentioFormField.ContactSelectInlineV2,
      title: getLocalizedText("registration.create.form.field.tenants"),
      isMultiSelect: true,
      asOptionList: true,
      getRequiredContactFieldsSchema: getRegistrationContactFieldsSchema,
      getRequiredCompanyFieldsSchema: getRegistrationCompanyFieldsSchema,
      valueSelector: (
        state: IPartialRootState,
        selectedAccountIds: number[] | string = [],
      ) =>
        contactSelectInlineV2ValueSelector(
          state,
          selectedAccountIds,
          contract.members.map(member => member.account),
        ),
      customGetSubtitleSelector: (
        _: IPartialRootState,
        formData: any,
        item: IContactListDataSourceItem,
      ) => {
        const { accountId, contact } = item;

        const member = contract.members.find(member => {
          if (accountId && accountId === member.account.id) {
            return true;
          }

          if (contact?.accountIds.includes(member.account.id)) {
            return true;
          }

          return false;
        });

        if (!member) {
          return;
        }

        return member.roles
          .map(role => getLocalizedText(`role.${role.toLowerCase()}`))
          .join(", ");
      },
      customExtraAccountsSelector: () =>
        getMembersWithOneOfRoles(contract.members, [
          EContractMemberTypes.Tenant,
          EContractMemberTypes.Parent,
        ]).map(member => member.account),
      customIsFetchingSelector: () => false,
      customGetContactsActions: [],
      getDisallowedAccountIds: (_: any) =>
        contactMeMaster && isBroker ? [contactMeMaster.account.id] : [],
    },
  };
};
