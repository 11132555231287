var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeLatest } from "redux-saga/effects";
import { logError } from "../../logger/logger";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralUpdateFailureText, getGeneralUpdateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import { getCreateSaga } from "../generic/create.saga";
import { getDeleteSaga } from "../generic/delete.saga";
import { getGetDetailSaga } from "../generic/detail.saga";
import { getGetPagedSaga } from "../generic/paged.saga";
import { getUpdateSaga } from "../generic/update.saga";
import { takeLatestWithUniquePayload } from "../utils/saga.utils";
import * as templateActions from "./template.actions";
import * as templateApi from "./template.api";
import { getPagedId } from "./template.utils";
function getTemplateDetailStartFlow(_a) {
    var id, templateDetail, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(templateApi.getTemplateDetail, { id: id })];
            case 2:
                templateDetail = (_b.sent()).data;
                return [4 /*yield*/, put(templateActions.getTemplateDetail.actions.success({
                        id: id,
                        templateDetail: templateDetail,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logError({ error: error });
                return [4 /*yield*/, put(templateActions.getTemplateDetail.actions.failure({
                        error: error,
                        id: id,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function updateTemplateDetailStartFlow(_a) {
    var id, data, onSuccess, templateDetail, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id, data = payload.data, onSuccess = payload.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(templateApi.updateTemplate, { id: id, data: data })];
            case 2:
                _b.sent();
                return [4 /*yield*/, call(templateApi.getTemplateDetail, { id: id })];
            case 3:
                templateDetail = (_b.sent()).data;
                return [4 /*yield*/, put(templateActions.updateTemplateDetail.actions.success({
                        id: id,
                        data: templateDetail,
                    }))];
            case 4:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(templateDetail);
                showAlert({
                    type: "success",
                    message: getGeneralUpdateSuccessText("template"),
                });
                return [3 /*break*/, 7];
            case 5:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logError({ error: error });
                return [4 /*yield*/, put(templateActions.updateTemplateDetail.actions.failure({
                        error: error,
                        id: id,
                    }))];
            case 6:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                    message: getGeneralUpdateFailureText("template"),
                });
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function duplicateTemplateStartFlow(_a) {
    var id, onSuccess, duplicatedTemplate, duplicatedTemplateRefetched, unknownError_3, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id, onSuccess = payload.onSuccess;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call(templateApi.duplicateTemplate, { id: id })];
            case 2:
                duplicatedTemplate = (_b.sent()).data;
                return [4 /*yield*/, call(templateApi.getTemplate, { id: duplicatedTemplate.id })];
            case 3:
                duplicatedTemplateRefetched = (_b.sent()).data;
                return [4 /*yield*/, put(templateActions.duplicateTemplate.actions.success({
                        id: id,
                        duplicatedTemplate: duplicatedTemplateRefetched,
                    }))];
            case 4:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(id, duplicatedTemplate.id);
                showAlert({
                    type: "success",
                    message: getLocalizedText("system.duplicate.success", {
                        type: "template",
                    }),
                });
                return [3 /*break*/, 7];
            case 5:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logError({ error: error });
                return [4 /*yield*/, put(templateActions.duplicateTemplate.actions.failure({
                        error: error,
                        id: id,
                    }))];
            case 6:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                    message: getLocalizedText("system.duplicate.failure", {
                        type: "template",
                    }),
                });
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(templateActions.create.types.START, getCreateSaga({
                    entityName: "template",
                    create: templateApi.createTemplate,
                    getDetail: templateApi.getTemplate,
                }))];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateActions.update.types.START, getUpdateSaga({
                        entityName: "template",
                        update: templateApi.updateTemplate,
                        getDetail: templateApi.getTemplate,
                    }))];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateActions.deleteObject.types.START, getDeleteSaga({
                        entityName: "template",
                        deleteObject: templateApi.deleteTemplate,
                    }))];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateActions.getDetail.types.START, getGetDetailSaga({
                        entityName: "template",
                        getDetail: templateApi.getTemplate,
                    }))];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatestWithUniquePayload(function (_a) {
                        var payload = _a.payload;
                        return getPagedId(__assign({}, payload));
                    }, templateActions.getPaged.types.START, getGetPagedSaga({
                        entityName: "template",
                        getPaged: templateApi.getTemplates,
                    }))];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateActions.getTemplateDetail.types.START, getTemplateDetailStartFlow)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateActions.updateTemplateDetail.types.START, updateTemplateDetailStartFlow)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLatest(templateActions.duplicateTemplate.types.START, duplicateTemplateStartFlow)];
            case 8:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line import/no-default-export
export default saga;
