var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { alpha, useTheme } from "@mui/material/styles";
import useConfig from "../../../hooks/useConfig";
import { ThemeMode } from "../../../utils/types/config";
export var NavItemStyles = function () {
    var theme = useTheme();
    var _a = useConfig(), mode = _a.mode, borderRadius = _a.borderRadius;
    return {
        wrapper: {
            open: __assign({ zIndex: 1201, borderRadius: "".concat(borderRadius, "px"), mb: 0.5, pl: "16px" }, (mode !== ThemeMode.DARK && {
                "&:hover": {
                    bgcolor: "secondary.light",
                },
                "&.Mui-selected": {
                    bgcolor: "secondary.light",
                    color: "secondary.main",
                    "&:hover": {
                        color: "secondary.main",
                        bgcolor: "secondary.light",
                    },
                },
            })),
            close: {
                zIndex: 1201,
                borderRadius: "".concat(borderRadius, "px"),
                mb: 0.5,
                pl: "14px",
                py: 0,
                "&:hover": {
                    bgcolor: "transparent",
                },
                "&.Mui-selected": {
                    "&:hover": {
                        bgcolor: "transparent",
                    },
                    bgcolor: "transparent",
                },
            },
        },
        content: {
            buttonBase: {
                borderRadius: "".concat(borderRadius, "px"),
            },
            listItemIcon: {
                open: {
                    minWidth: 36,
                    color: "text.primary",
                },
                close: {
                    minWidth: 36,
                    color: "text.primary",
                    borderRadius: "".concat(borderRadius, "px"),
                    width: 46,
                    height: 46,
                    alignItems: "center",
                    justifyContent: "center",
                    "&:hover": {
                        bgcolor: mode === ThemeMode.DARK
                            ? alpha(theme.palette.secondary.main, 0.25)
                            : "secondary.light",
                    },
                },
                openSelected: {
                    color: "text.primary",
                },
                closeSelected: {
                    color: "secondary.main",
                    bgcolor: mode === ThemeMode.DARK
                        ? alpha(theme.palette.secondary.main, 0.25)
                        : "secondary.light",
                    "&:hover": {
                        bgcolor: mode === ThemeMode.DARK
                            ? alpha(theme.palette.secondary.main, 0.3)
                            : "secondary.light",
                    },
                },
            },
            typography: __assign({}, theme.typography.subMenuCaption),
        },
    };
};
