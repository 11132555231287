var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import React from "react";
import { EFontWeights } from "../../../themes/types";
import { SimpleCardStyles } from "./SimpleCard.styles";
export var SimpleCard = React.forwardRef(function (_a, ref) {
    var boxShadow = _a.boxShadow, children = _a.children, _b = _a.content, content = _b === void 0 ? true : _b, _c = _a.contentClass, contentClass = _c === void 0 ? "" : _c, darkTitle = _a.darkTitle, secondary = _a.secondary, shadow = _a.shadow, contentSX = _a.contentSX, _d = _a.sx, sx = _d === void 0 ? {} : _d, title = _a.title, others = __rest(_a, ["boxShadow", "children", "content", "contentClass", "darkTitle", "secondary", "shadow", "contentSX", "sx", "title"]);
    var defaultStyles = SimpleCardStyles(boxShadow, shadow);
    var cardSX = defaultStyles.cardSX, headerSX = defaultStyles.headerSX;
    return (_jsxs(Card, __assign({ ref: ref }, others, { sx: __assign(__assign({}, cardSX), sx), children: [title && (_jsx(CardHeader, { sx: headerSX, title: _jsx(Typography, { variant: "body2", fontWeight: EFontWeights.Medium, children: title }), action: secondary })), content && (_jsx(CardContent, { sx: contentSX, className: contentClass, children: children })), !content && children] })));
});
