import { Box } from "@rebass/grid";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  AccountList,
  DisplayText,
  ESpacings,
  TextField,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { IPaymentFollowUpScreenProps } from "../PaymentFollowUpModal.types";

export const AppScreen: React.FC<IPaymentFollowUpScreenProps> = ({
  followUpItem,
  postBody,
  renderActivities,
  setPostBody,
  contact,
}) => {
  const handleMessageChange = ({ target: { value } }: any) => {
    setPostBody({ ...postBody, message: value });
  };
  return (
    <>
      <DisplayText size="small">
        {getLocalizedText("contact.dashboard.contact_data.heading")}
      </DisplayText>
      <AccountList accounts={[followUpItem.payerAccount] as IAccount[]} />
      <Box mt={ESpacings.base}>
        <TextField
          value={
            postBody.message ||
            getLocalizedText("payments.follow_up.app.content", {
              contact: contact?.firstname || "user",
            })
          }
          name="message"
          label={getLocalizedText("payments.follow_up.app.content.label")}
          multiline={true}
          onChange={handleMessageChange}
        />
      </Box>
      {renderActivities}
    </>
  );
};
