import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
export var BottomMenuStyles = function () {
    var downSM = useMediaQuery(function (theme) { return theme.breakpoints.down("sm"); });
    var theme = useTheme();
    return {
        wrapper: {
            height: "72px",
            position: "fixed",
            bottom: 0,
            width: "100%",
            borderTop: "1px solid #E3E8EF",
            display: downSM ? "flex" : "none",
            ".MuiBottomNavigationAction-root": {
                rowGap: "4px",
            },
        },
        content: {
            navButtonSelected: {
                color: theme.palette.success.main,
            },
        },
    };
};
