var _a;
import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import FormInputCaptchaJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputCaptcha/components/FormInputCaptchaJsonFormSchema";
import FormInputPhoneOrEmailJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormInputText/components/FormInputPhoneOrEmailJsonFormSchema";
import FormSwitch from "@rentiohq/shared-frontend/dist/components/components/FormSwitch/components/FormSwitchJsonFromSchema";
import FormSwitchContactSelectJsonFormSchema from "@rentiohq/shared-frontend/dist/components/components/FormSwitchContactSelect/components/FormSwitchContactSelectJsonFormSchema";
import { AddressField } from "../components/SharedForm/fields/AddressField";
import { ContactSelectField as FormContactSelectInlineV2JsonFormSchema } from "../components/SharedForm/fields/ContactSelectFieldV2";
import { DateField as FormInputDateJsonFormSchema } from "../components/SharedForm/fields/DateField";
import { EmailField } from "../components/SharedForm/fields/EmailField";
import { FileField } from "../components/SharedForm/fields/FileField";
import { FormPropertySelectJsonFormSchema } from "../components/SharedForm/fields/FormPropertySelect/components/FormPropertySelectJsonFormSchema";
import { GridField } from "../components/SharedForm/fields/GridField";
import { HeaderField as FormSectionHeaderJsonFormSchema } from "../components/SharedForm/fields/HeaderField";
import { IbanField as FormInputIbanJsonFormSchema } from "../components/SharedForm/fields/IbanField";
import { IbanSelectField as FormIbanSelectInlineJsonFormSchema } from "../components/SharedForm/fields/IbanSelectField/IbanSelectField";
import { InfoField as FormHelpInfoJsonFormSchema } from "../components/SharedForm/fields/InfoField";
import { LatePayments as FormInputManualLatePaymentsSelectJsonFormSchema } from "../components/SharedForm/fields/LatePayments";
import { NumberField as FormInputNumberJsonFormSchema } from "../components/SharedForm/fields/NumberField";
import { OptionsField as FormInlineSelectJsonFromSchema } from "../components/SharedForm/fields/OptionsField";
import { PhoneField as FormInputPhoneJsonFormSchema } from "../components/SharedForm/fields/PhoneField";
import { PropertyInfoFieldJsonFormSchema } from "../components/SharedForm/fields/PropertyInfoField/components/PropertyInfoFieldJsonSchema";
import { SegmentField as FormSegmentedControlJsonFormSchema } from "../components/SharedForm/fields/SegmentField";
import { SelectField } from "../components/SharedForm/fields/SelectField";
import { SubtitleField as FormSectionSubtitleJsonFormSchema } from "../components/SharedForm/fields/SubtitleField";
import { TextField as FormInputTextJsonFormSchema } from "../components/SharedForm/fields/TextField";
export var fields = (_a = {},
    _a[ERentioFormField.Address] = AddressField,
    _a[ERentioFormField.Captcha] = FormInputCaptchaJsonFormSchema,
    _a[ERentioFormField.ContactSelectInlineV2] = FormContactSelectInlineV2JsonFormSchema,
    _a[ERentioFormField.Date] = FormInputDateJsonFormSchema,
    _a[ERentioFormField.DocumentSelectInline] = FileField,
    _a[ERentioFormField.DropdownSelect] = SelectField,
    _a[ERentioFormField.Email] = EmailField,
    _a[ERentioFormField.Grid] = GridField,
    _a[ERentioFormField.HelpInfo] = FormHelpInfoJsonFormSchema,
    _a[ERentioFormField.Iban] = FormInputIbanJsonFormSchema,
    _a[ERentioFormField.IbanSelectInline] = FormIbanSelectInlineJsonFormSchema,
    _a[ERentioFormField.InlineSelect] = FormInlineSelectJsonFromSchema,
    _a[ERentioFormField.Number] = FormInputNumberJsonFormSchema,
    _a[ERentioFormField.Phone] = FormInputPhoneJsonFormSchema,
    _a[ERentioFormField.PhoneOrEmail] = FormInputPhoneOrEmailJsonFormSchema,
    _a[ERentioFormField.PropertyInfo] = PropertyInfoFieldJsonFormSchema,
    _a[ERentioFormField.PropertySelectInline] = FormPropertySelectJsonFormSchema,
    _a[ERentioFormField.SectionHeader] = FormSectionHeaderJsonFormSchema,
    _a[ERentioFormField.SectionSubtitle] = FormSectionSubtitleJsonFormSchema,
    _a[ERentioFormField.SegmentedControl] = FormSegmentedControlJsonFormSchema,
    _a[ERentioFormField.Switch] = FormSwitch,
    _a[ERentioFormField.SwitchContactSelect] = FormSwitchContactSelectJsonFormSchema,
    _a[ERentioFormField.Text] = FormInputTextJsonFormSchema,
    _a.file = FileField,
    _a[ERentioFormField.ManualLatePaymentsSelect] = FormInputManualLatePaymentsSelectJsonFormSchema,
    _a);
