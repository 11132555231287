import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Icon } from "../../../Icon";
import * as S from "../../styled/OptionWidget.styled";
// Check to see if a schema specifies that a value must be true
var schemaRequiresTrueValue = function (schema) {
    // Check if const is a truthy value
    if (schema.const) {
        return true;
    }
    // Check if an enum has a single value of true
    if (schema.enum && schema.enum.length === 1 && schema.enum[0] === true) {
        return true;
    }
    // If anyOf has a single value, evaluate the sub-schema
    if (schema.anyOf && schema.anyOf.length === 1) {
        return schemaRequiresTrueValue(schema.anyOf[0]);
    }
    // If oneOf has a single value, evaluate the sub-schema
    if (schema.oneOf && schema.oneOf.length === 1) {
        return schemaRequiresTrueValue(schema.oneOf[0]);
    }
    // Evaluate each sub-schema in allOf, to see if one of them requires a true
    // value
    if (schema.allOf) {
        return schema.allOf.some(schemaRequiresTrueValue);
    }
};
export var CheckboxWidget = function (props) {
    var schema = props.schema, id = props.id, value = props.value, disabled = props.disabled, readonly = props.readonly, label = props.label, autofocus = props.autofocus, onBlur = props.onBlur, onFocus = props.onFocus, onChange = props.onChange;
    // Because an unchecked checkbox will cause html5 validation to fail, only add
    // the "required" attribute if the field value must be "true", due to the
    // "const" or "enum" keywords
    var required = schemaRequiresTrueValue(schema);
    var checked = typeof value === "undefined" ? false : value;
    var hasError = props.rawErrors && props.rawErrors.length > 0;
    var handleChange = function (event) {
        onChange(event.target.checked);
    };
    return (_jsxs(S.Option, { isChecked: checked, hasError: hasError, isDisabled: disabled || readonly ? true : false, children: [_jsxs(S.Checkbox, { isChecked: checked, hasError: hasError, children: [_jsx(Icon, { source: "checkboxOutline" }), checked && _jsx(Icon, { source: "checkboxMark" }), _jsx("input", { type: "checkbox", id: id, checked: checked, required: required, disabled: disabled || readonly, autoFocus: autofocus, onChange: handleChange, onBlur: onBlur && (function (event) { return onBlur(id, event.target.checked); }), onFocus: onFocus && (function (event) { return onFocus(id, event.target.checked); }) })] }), _jsx(S.OptionContent, { children: label })] }));
};
