import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ASSETS } from "@rentiohq/web-shared-next/dist/assets";
import { Button, Stack } from "@rentiohq/web-shared-next/dist/ui-components";
import React from "react";
import { ContactManager } from "./ContactManager";
import { ReportProblem } from "./ReportProblem";

interface IProps {
  property: IProperty;
  fullWidth?: boolean;
}

export const QuickActions = ({ property, fullWidth = false }: IProps) => {
  const [reportOpenDialog, setOpenReportDialog] = React.useState(false);
  const [contactOpenDialog, setOpenContactDialog] = React.useState(false);

  const toggleOpenReportDialog = () => {
    setOpenReportDialog(prevState => !prevState);
  };

  const toggleOpenContractDialog = () => {
    setOpenContactDialog(prevState => !prevState);
  };

  return (
    <>
      <Stack direction="row" gap={1}>
        <Button
          variant="outlined"
          color="warning"
          fullWidth={fullWidth}
          size="small"
          startIcon={<img src={ASSETS.alert} alt="alert" />}
          onClick={toggleOpenReportDialog}
        >
          {getLocalizedText("nav.problems")}
        </Button>
        <Button
          variant="outlined"
          fullWidth={fullWidth}
          size="small"
          startIcon={<img src={ASSETS.greenUser} alt="greenUser" />}
          onClick={toggleOpenContractDialog}
        >
          {getLocalizedText("system.contact")}
        </Button>
      </Stack>
      <ReportProblem
        property={property}
        openDialog={reportOpenDialog}
        toggleOpenDialog={toggleOpenReportDialog}
      />
      <ContactManager
        property={property}
        openDialog={contactOpenDialog}
        toggleOpenDialog={toggleOpenContractDialog}
      />
    </>
  );
};
