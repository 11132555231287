import { alpha, useTheme } from "@mui/material/styles";
import { EFontWeights } from "../../themes/types";
export var MultipleChoiceStyles = function (_a) {
    var isSelected = _a.isSelected, fullWidth = _a.fullWidth;
    var theme = useTheme();
    var borderColor = isSelected ? theme.palette.success.main : "#D9D9D9"; // to be added to colors list
    var background = isSelected
        ? alpha(theme.palette.success[200], 0.2)
        : theme.palette.background.default;
    return {
        content: {
            formControlLabel: {
                margin: 0,
                width: fullWidth ? "100%" : "fit-content",
                ".MuiFormControlLabel-label": {
                    width: fullWidth ? "100%" : "fit-content",
                },
                ".MuiList-padding": {
                    padding: 0,
                },
            },
            radio: {
                display: "none",
            },
            choicesLabel: {
                fontWeight: EFontWeights.Medium,
                color: theme.palette.text.primary,
            },
            listItem: {
                borderRadius: "8px",
                border: "1px solid ".concat(borderColor),
                padding: "16px",
                paddingRight: "60px",
                background: background,
            },
            listItemAvatar: {
                maxWidth: "36px",
                minWidth: "36px",
            },
            listItemTextPrimary: {
                width: "100%",
            },
            card: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                rowGap: "12px",
                border: "1px solid ".concat(borderColor),
                background: background,
                minHeight: "112px",
                position: "relative",
                width: "262px",
                margin: 0,
                svg: {
                    color: alpha(theme.palette.text.primary, 0.5),
                },
            },
            cardIcon: {
                img: {
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                },
            },
        },
    };
};
