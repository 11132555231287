var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { logError } from "../../logger/logger";
import * as contractActionsV2 from "../../reduxV2/contract/contract.actions";
import * as documentApi from "../../reduxV2/documents/document.api";
import { EDocumentMemberTypes } from "../../types/document.types";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGetPagedSaga } from "../generic/paged.saga";
import * as registrationActions from "./registration.actions";
import * as registrationApi from "./registration.api";
import * as registrationSelectors from "./registration.selectors";
import { getDocumentCategoryId } from "./registration.utils";
function getRegistrationStartFlow(_a) {
    var id, registration, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(registrationApi.getRegistration, id)];
            case 2:
                registration = (_b.sent()).data;
                return [4 /*yield*/, put(registrationActions.getRegistration.actions.success({
                        id: id,
                        registration: registration,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                });
                return [4 /*yield*/, put(registrationActions.getRegistration.actions.failure({
                        id: id,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function uploadNewDocument(params) {
    var createRegistration, registration, documentType, file, registrationData, presignedUrl, metaData, document, accountIds, membersToAdd;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                createRegistration = params.createRegistration, registration = params.registration, documentType = params.documentType, file = params.file;
                registrationData = createRegistration || registration;
                if (!registrationData) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, call(documentApi.uploadDocumentToS3, file)];
            case 1:
                presignedUrl = _a.sent();
                metaData = {
                    categoryId: getDocumentCategoryId({ documentType: documentType }),
                    filename: file.name,
                    propertyId: registrationData.propertyId,
                    contractId: registrationData.contractId,
                };
                return [4 /*yield*/, call(documentApi.uploadDocument, presignedUrl, metaData)];
            case 2:
                document = _a.sent();
                accountIds = [];
                if (createRegistration) {
                    accountIds = createRegistration.members.map(function (m) { return m.accountId; });
                }
                else if (registration) {
                    accountIds = registration.members.map(function (m) { return m.account.id; });
                }
                membersToAdd = accountIds.map(function (accountId) { return ({
                    roles: [EDocumentMemberTypes.Viewer],
                    accountId: accountId,
                }); });
                return [4 /*yield*/, call(documentApi.addMembers, {
                        id: document.id,
                        members: membersToAdd,
                    })];
            case 3:
                _a.sent();
                return [2 /*return*/, document.id];
        }
    });
}
// Upload new document if needed
function mapDocument(params) {
    var registrationDocument, createRegistration, registration, result, _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                registrationDocument = params.registrationDocument, createRegistration = params.createRegistration, registration = params.registration;
                result = __assign({}, registrationDocument);
                if (!(registrationDocument.documentId &&
                    typeof registrationDocument.documentId !== "string")) return [3 /*break*/, 2];
                _a = result;
                return [4 /*yield*/, call(uploadNewDocument, {
                        createRegistration: createRegistration,
                        registration: registration,
                        documentType: registrationDocument.documentType,
                        file: registrationDocument.documentId,
                    })];
            case 1:
                _a.documentId = _b.sent();
                _b.label = 2;
            case 2: return [2 /*return*/, result];
        }
    });
}
function createRegistrationStartFlow(_a) {
    var registration, mappedDocuments, registrationToCreate, createdRegistration, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                registration = payload.registration;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                return [4 /*yield*/, all(registration.documents.map(function (document) {
                        return mapDocument({
                            registrationDocument: document,
                            createRegistration: registration,
                        });
                    }))];
            case 2:
                mappedDocuments = _b.sent();
                registrationToCreate = __assign(__assign({}, registration), { documents: mappedDocuments });
                return [4 /*yield*/, call(registrationApi.createRegistration, registrationToCreate)];
            case 3:
                createdRegistration = (_b.sent()).data;
                return [4 /*yield*/, put(contractActionsV2.getDetailStart.getAction({
                        id: createdRegistration.contractId,
                    }))];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(registrationActions.createRegistration.actions.success({
                        registration: createdRegistration,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 8];
            case 6:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                });
                return [4 /*yield*/, put(registrationActions.createRegistration.actions.failure({
                        error: error,
                    }))];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function registrationAddDocumentsStartFlow(_a) {
    var registrationId, documents, registration_1, mappedDocuments, fetchedRegistration, unknownError_3, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                registrationId = payload.registrationId, documents = payload.documents;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 8, , 10]);
                return [4 /*yield*/, call(registrationApi.getRegistration, registrationId)];
            case 2:
                registration_1 = (_b.sent()).data;
                return [4 /*yield*/, all(documents.map(function (document) {
                        return mapDocument({ registrationDocument: document, registration: registration_1 });
                    }))];
            case 3:
                mappedDocuments = _b.sent();
                return [4 /*yield*/, call(registrationApi.registrationAddDocuments, registration_1.id, mappedDocuments)];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(registrationApi.getRegistration, registration_1.id)];
            case 5:
                fetchedRegistration = (_b.sent()).data;
                return [4 /*yield*/, put(contractActionsV2.getDetailStart.getAction({
                        id: fetchedRegistration.contractId,
                    }))];
            case 6:
                _b.sent();
                return [4 /*yield*/, put(registrationActions.registrationAddDocuments.actions.success({
                        registrationId: registrationId,
                        registration: fetchedRegistration,
                    }))];
            case 7:
                _b.sent();
                return [3 /*break*/, 10];
            case 8:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                });
                return [4 /*yield*/, put(registrationActions.registrationAddDocuments.actions.failure({
                        registrationId: registrationId,
                        error: error,
                    }))];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function registrationUpdateDocumentStartFlow(_a) {
    var registrationId, documentId, document, registration, mappedDocument, fetchedRegistration, unknownError_4, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                registrationId = payload.registrationId, documentId = payload.documentId, document = payload.document;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 8, , 10]);
                return [4 /*yield*/, call(registrationApi.getRegistration, registrationId)];
            case 2:
                registration = (_b.sent()).data;
                return [4 /*yield*/, call(mapDocument, {
                        registrationDocument: document,
                        registration: registration,
                    })];
            case 3:
                mappedDocument = _b.sent();
                return [4 /*yield*/, call(registrationApi.registrationUpdateDocuments, registration.id, documentId, mappedDocument)];
            case 4:
                _b.sent();
                return [4 /*yield*/, call(registrationApi.getRegistration, registration.id)];
            case 5:
                fetchedRegistration = (_b.sent()).data;
                return [4 /*yield*/, put(contractActionsV2.getDetailStart.getAction({
                        id: fetchedRegistration.contractId,
                    }))];
            case 6:
                _b.sent();
                return [4 /*yield*/, put(registrationActions.updateRegistration.actions.success({
                        id: registrationId,
                        registration: fetchedRegistration,
                    }))];
            case 7:
                _b.sent();
                return [3 /*break*/, 10];
            case 8:
                unknownError_4 = _b.sent();
                error = unknownError_4;
                logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                });
                return [4 /*yield*/, put(registrationActions.registrationUpdateDocument.actions.failure({
                        registrationId: registrationId,
                        error: error,
                    }))];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function updateRegistrationStartFlow(_a) {
    var id, registration, registrationToUpdate, updatedRegistration, unknownError_5, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                id = payload.id, registration = payload.registration;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                registrationToUpdate = __assign({}, registration);
                return [4 /*yield*/, call(registrationApi.updateRegistration, id, registrationToUpdate)];
            case 2:
                updatedRegistration = (_b.sent()).data;
                return [4 /*yield*/, put(contractActionsV2.getDetailStart.getAction({
                        id: updatedRegistration.contractId,
                    }))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(registrationActions.updateRegistration.actions.success({
                        id: id,
                        registration: updatedRegistration,
                    }))];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_5 = _b.sent();
                error = unknownError_5;
                logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                });
                return [4 /*yield*/, put(registrationActions.updateRegistration.actions.failure({
                        id: id,
                        error: error,
                    }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function deleteRegistrationStartFlow(_a) {
    var id, onSuccess, cachedRegistration, _b, _c, unknownError_6, error;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                id = payload.id, onSuccess = payload.onSuccess;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 7, , 9]);
                _c = (_b = registrationSelectors).registration;
                return [4 /*yield*/, select()];
            case 2:
                cachedRegistration = _c.apply(_b, [_d.sent(), id]);
                return [4 /*yield*/, call(registrationApi.deleteRegistration, id)];
            case 3:
                _d.sent();
                if (!cachedRegistration) return [3 /*break*/, 5];
                return [4 /*yield*/, put(contractActionsV2.getDetailStart.getAction({
                        id: cachedRegistration.contractId,
                    }))];
            case 4:
                _d.sent();
                _d.label = 5;
            case 5: return [4 /*yield*/, put(registrationActions.deleteRegistration.actions.success({
                    id: id,
                }))];
            case 6:
                _d.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(id);
                return [3 /*break*/, 9];
            case 7:
                unknownError_6 = _d.sent();
                error = unknownError_6;
                logError({ error: error });
                showAlert({
                    type: "error",
                    error: error,
                });
                return [4 /*yield*/, put(registrationActions.deleteRegistration.actions.failure({
                        id: id,
                        error: error,
                    }))];
            case 8:
                _d.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(registrationActions.getRegistration.types.START, getRegistrationStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(registrationActions.createRegistration.types.START, createRegistrationStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(registrationActions.registrationAddDocuments.types.START, registrationAddDocumentsStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(registrationActions.registrationUpdateDocument.types.START, registrationUpdateDocumentStartFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(registrationActions.updateRegistration.types.START, updateRegistrationStartFlow)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(registrationActions.deleteRegistration.types.START, deleteRegistrationStartFlow)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(registrationActions.getPaged.types.START, getGetPagedSaga({
                        entityName: "registration",
                        getPaged: registrationApi.getRegistrations,
                    }))];
            case 7:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line import/no-default-export
export default saga;
