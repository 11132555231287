var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import MaskedInput from "react-text-mask";
import { setCaretPosition } from "../../utils";
import { StyledBackdrop, StyledBaseInput, StyledFloatIcon, StyledInput, StyledPrefix, StyledSuffix, } from "./BaseInput.styled";
var BaseInputBase = function (_a, outerRef) {
    var prefix = _a.prefix, suffix = _a.suffix, floatIcon = _a.floatIcon, floatIconClick = _a.floatIconClick, innerRef = _a.innerRef, _b = _a.type, type = _b === void 0 ? "text" : _b, _c = _a.elementType, elementType = _c === void 0 ? "input" : _c, props = __rest(_a, ["prefix", "suffix", "floatIcon", "floatIconClick", "innerRef", "type", "elementType"]);
    var id = props.id, value = props.value, readonly = props.readonly, disabled = props.disabled, autofocus = props.autofocus, onBlur = props.onBlur, onFocus = props.onFocus, options = props.options, schema = props.schema, formContext = props.formContext, registry = props.registry, rawErrors = props.rawErrors, restProps = __rest(props, ["id", "value", "readonly", "disabled", "autofocus", "onBlur", "onFocus", "options", "schema", "formContext", "registry", "rawErrors"]);
    var mask = restProps.mask, guide = restProps.guide, placeholderChar = restProps.placeholderChar, keepCharPositions = restProps.keepCharPositions, pipe = restProps.pipe, showMask = restProps.showMask, inputProps = __rest(restProps, ["mask", "guide", "placeholderChar", "keepCharPositions", "pipe", "showMask"]);
    var maskedProps = {
        mask: mask,
        guide: guide,
        placeholderChar: placeholderChar,
        keepCharPositions: keepCharPositions,
        pipe: pipe,
        showMask: showMask,
    };
    var isMaskedInput = Object.values(maskedProps).filter(Boolean).length > 0;
    var prefixMarkup = prefix ? (_jsx(StyledPrefix, { id: "".concat(id, "Prefix"), children: prefix })) : null;
    var suffixMarkup = suffix ? (_jsx(StyledSuffix, { id: "".concat(id, "Suffix"), children: suffix })) : null;
    var onFloatClick = function () {
        // if (this.inputRef.current) {
        //   this.inputRef.current.focus()
        // }
        if (floatIconClick) {
            floatIconClick();
        }
    };
    var floatMarkup = floatIcon ? (_jsx(StyledFloatIcon, { onClick: onFloatClick, id: "".concat(id, "Float"), children: floatIcon })) : null;
    var maskedInput = function (ref, maskProps) {
        // const handleChange = (e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
        //   props.onChange(e)
        //   if (onChange) {
        //     onChange(e)
        //   }
        // }
        var setCaretPositionToEndOfString = function (_a) {
            var target = _a.target;
            setTimeout(function () {
                setCaretPosition(target, target.value.indexOf("_"));
            }, 1);
        };
        return (_jsx(StyledInput, __assign({ ref: function (input) { return ref(input); }, as: "input", type: type }, maskProps, { onFocus: setCaretPositionToEndOfString, id: id, name: inputProps.id })));
    };
    var handleChange = function (_a) {
        var changeValue = _a.target.value;
        // @ts-ignore
        return props.onChange(changeValue === "" && options ? options.emptyValue : changeValue);
    };
    var handleBlur = function (event) {
        return onBlur(id, event.target.value);
    };
    var handleFocus = function (event) {
        return onFocus(id, event.target.value);
    };
    var inputMarkup = isMaskedInput && !readonly && !disabled ? (_jsx(MaskedInput, __assign({ value: value, onChange: handleChange }, maskedProps, { render: maskedInput }))) : (_jsx(StyledInput, __assign({ as: elementType, type: type, id: id, readOnly: readonly, disabled: disabled, autoFocus: autofocus, value: value === null || value === undefined ? "" : value }, inputProps, { onChange: handleChange, onBlur: handleBlur, onFocus: handleFocus, isTextarea: elementType === "textarea", ref: outerRef })));
    return (_jsxs(StyledBaseInput, { hasError: rawErrors && rawErrors.length > 0, children: [prefixMarkup, inputMarkup, suffixMarkup, floatMarkup, _jsx(StyledBackdrop, {})] }));
};
export var BaseInput = React.forwardRef(BaseInputBase);
