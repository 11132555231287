import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { IPaymentOrder } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";

export const usePayeeBankAccount = (paymentOrder: IPaymentOrder) => {
  const dispatch = useDispatch();

  // Selectors
  const bankAccount = useSelector((state: IRootStore) =>
    paymentSelectors.getPayeeIban(
      state,
      paymentOrder.id,
      paymentOrder.payee?.id,
    ),
  );

  // Lifecycle
  useEffect(() => {
    if (bankAccount) {
      return;
    }

    if (!paymentOrder?.payee) {
      return;
    }

    dispatch(
      paymentActions.getIban.actions.start({
        paymentOrderId: paymentOrder.id,
        payeeId: paymentOrder.payee?.id,
      }),
    );
  }, [paymentOrder.id, paymentOrder.payee?.id]);

  return bankAccount;
};
