var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import SharedTaskListItem from "@rentiohq/shared-frontend/dist/components/components/TaskListItem";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Lozenge } from "../Lozenge";
export var TaskListItem = function (taskListItemProps) {
    return (_jsx(SharedTaskListItem, __assign({}, taskListItemProps, { renderRepairLozenge: function () {
            var _a;
            if ((_a = taskListItemProps === null || taskListItemProps === void 0 ? void 0 : taskListItemProps.task) === null || _a === void 0 ? void 0 : _a.status) {
                return (_jsx("div", { style: {
                        display: "flex",
                        justifyContent: "start",
                        flexDirection: "row",
                    }, children: _jsx(Lozenge, { customBackgroundColor: "#2D9CDB", customTextColor: "white", children: getLocalizedText("task.repair.service.keypoint") }) }));
            }
            return _jsx(_Fragment, {});
        } })));
};
