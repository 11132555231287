import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { compact } from "lodash";
import { Grid } from "../";
export var QuickActionCardCollection = function (props) {
    var children = props.children;
    if (!children || !Array.isArray(children)) {
        return _jsx(_Fragment, { children: children });
    }
    var filteredChildren = compact(children);
    var width = Array.isArray(filteredChildren)
        ? "".concat(100 / (filteredChildren || []).length - 0.5, "%")
        : "100%";
    return (_jsx(Grid, { justifyContent: "space-between", spacing: "tight", children: filteredChildren.map(function (child, i) { return (_jsx(Grid.Item, { width: width, children: child }, i)); }) }));
};
