import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  ERegistrationDocumentType,
  IRegistration,
} from "@rentiohq/shared-frontend/dist/types/registration.types";

export interface IExtraData {
  documentType: ERegistrationDocumentType;
  registrationId: number;
  registration: IRegistration;
  existingDocumentId?: number;
  property: IProperty;
  contract: IContract;

  contactMeMaster?: IContact;
  legalContact?: IContact;
  isBroker?: boolean;
}

export enum EField {
  Owners = "owners",
  SignAsBroker = "signAsBroker",
  Tenants = "tenants",

  DocumentToUpload = "documentToUpload",
  Type = "type",
  SignedAt = "signedAt",
  SignedAtAddendum = "signedAtAddendum",

  Title1 = "title1",
  Info1 = "info1",
}
