import { EPaymentRequestFetchType, EPaymentRequestStatus, } from "../../types/paymentRequest.types";
export var getPaymentRequestTypes = function (fetchType) {
    switch (fetchType) {
        case EPaymentRequestFetchType.Open:
            return [EPaymentRequestStatus.New, EPaymentRequestStatus.FailedPaidIn];
        case EPaymentRequestFetchType.Pending:
            return [
                EPaymentRequestStatus.Approved,
                EPaymentRequestStatus.Pending,
                EPaymentRequestStatus.PaidIn,
                EPaymentRequestStatus.Partial,
                EPaymentRequestStatus.Transferring,
                EPaymentRequestStatus.Transferred,
                EPaymentRequestStatus.PayingOut,
                // TODO: What to do with failed statuses?
                EPaymentRequestStatus.FailedTransfer,
                EPaymentRequestStatus.FailedPayout,
                EPaymentRequestStatus.FailedKyc,
                EPaymentRequestStatus.Failed,
            ];
        case EPaymentRequestFetchType.Done:
            return [EPaymentRequestStatus.Paid];
        default:
            return [];
    }
};
export var getPaymentOrdersIdentifierForProperty = function (propertyId) {
    return propertyId ? "property-".concat(propertyId) : "";
};
export var getIbanId = function (paymentOrderId, payeeId, payerId) {
    if (payeeId) {
        return "payee-".concat(payeeId, "-").concat(paymentOrderId);
    }
    if (payerId) {
        return "payer-".concat(payerId, "-").concat(paymentOrderId);
    }
};
