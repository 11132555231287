import chatForm from "@rentiohq/shared-frontend/dist/forms/createChat";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/createChat/schema.createChat.actions";
import { useLocationState } from "@rentiohq/shared-frontend/dist/hooks/useLocationState";
import { getContactMeMaster } from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { closeSocket } from "@rentiohq/shared-frontend/dist/redux/socket/socket.actions";
import * as chatSelectors from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import { IChat } from "@rentiohq/shared-frontend/dist/types/chat.types";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  Loading,
  MultiStepForm,
  Page,
} from "@rentiohq/web-shared/dist/components";
import { toast } from "@rentiohq/web-shared/dist/managers/Toast";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import * as t from "../../../../services/translationService";

interface IProps {
  chat?: IChat;
  isCreatingChat: boolean;
  createChatError?: Error;
}

const formId = generateFormId();

const CreateNewChatBase: React.FC<IProps> = ({
  isCreatingChat,
  createChatError,
  chat,
}) => {
  const state = useLocationState<{ propertyId?: number }>();
  const propertyId = state?.propertyId;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const prevIsCreatingChat = usePrevious(isCreatingChat);

  const contactMeMaster = useSelector((state: IRootStore) =>
    getContactMeMaster(state),
  );

  // Check for chat creation..
  React.useEffect(() => {
    // Created or failed creating chat
    if (prevIsCreatingChat && !isCreatingChat) {
      if (createChatError) {
        toast({
          heading: getLocalizedText("system.error"),
          variation: "error",
          autoDismiss: false,
        });
        return;
      }
      if (chat) {
        dispatch(
          closeSocket.actions.start({
            autoReopenAfterClose: false,
            sendPendingMessagesOnNextOpen: true,
          }),
        );
        navigate(`/chats/${chat.id}`, { state: { newChat: true } });
      }
    }
  }, [chat, createChatError, history, isCreatingChat, prevIsCreatingChat]);

  if (!contactMeMaster) {
    return <Loading />;
  }

  return (
    <Page title={t.chatsCreateTitle()}>
      <Card>
        <MultiStepForm
          formId={`create-chat-${formId}`}
          schemas={chatForm({
            contactMeMaster,
            propertyId,
          })}
          withSummary={false}
          withAside={false}
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector()}
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector()}
          submitActionCreator={submitActionCreator()}
        />
      </Card>
    </Page>
  );
};

const mapStateToProps = (state: IRootStore) => ({
  chat: chatSelectors.getCreatedDetail(state),
  isCreatingChat: chatSelectors.isCreating(state),
  createChatError: chatSelectors.createError(state),
});

export const CreateNewChat = connect(mapStateToProps)(CreateNewChatBase);
