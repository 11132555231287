import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { NavItemButtonStyles } from "./NavItemButton.styles";
export var NavItemButton = function (props) {
    var _a = props.selected, selected = _a === void 0 ? false : _a, label = props.label, icon = props.icon, onClick = props.onClick, disabled = props.disabled;
    var sx = NavItemButtonStyles();
    return (_jsxs(ListItemButton, { sx: sx.wrapper, selected: selected, onClick: onClick, disabled: disabled, children: [_jsx(ListItemIcon, { children: icon }), _jsx(ListItemText, { primary: _jsx(Typography, { variant: "body1", sx: sx.content.label, children: label }) })] }));
};
