var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { spacing as getSpacing } from "@rentiohq/web-theme";
export var Wrap = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  > * + * {\n    margin-top: ", ";\n  }\n"], ["\n  > * + * {\n    margin-top: ", ";\n  }\n"])), function (_a) {
    var spacing = _a.spacing;
    return (spacing ? getSpacing(spacing) : 0);
});
var templateObject_1;
