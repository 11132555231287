import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import * as t from "../../services/translationService";
import { Button } from "../Button";
import { StyledDescriptionText, StyledError, StyledHelpText, } from "../Form/Form.styled";
import { ESpacings } from "../Grid";
import { Icon } from "../Icon";
import { TextStyle } from "../TextStyle";
import * as S from "./Labelled.styled";
export var Labelled = function (_a) {
    var children = _a.children, label = _a.label, _b = _a.labelHidden, labelHidden = _b === void 0 ? false : _b, error = _a.error, optional = _a.optional, helpText = _a.helpText, descriptionText = _a.descriptionText, onPressExtraInfo = _a.onPressExtraInfo;
    return (_jsxs("div", { children: [!labelHidden && (_jsxs(S.StyledLabelWrapper, { children: [_jsx(S.Label, { children: label }), optional && (_jsx(S.StyledLabelOptional, { ml: ESpacings.extraTight, children: _jsxs(TextStyle, { size: "small", variation: "subdued", children: [" ", "(", t.system("optional"), ")"] }) })), onPressExtraInfo && (_jsx(Button, { appearance: "plain", onClick: onPressExtraInfo, tabIndex: "-1", children: _jsx(Box, { ml: ESpacings.tight, children: _jsx(Icon, { source: "informationCircle", size: "small" }) }) }))] })), descriptionText ? (_jsx(StyledDescriptionText, { children: descriptionText })) : null, children, error && _jsx(StyledError, { children: error }), helpText && _jsx(StyledHelpText, { children: helpText })] }));
};
