import logger from "@rentiohq/shared-frontend/dist/logger";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentApi from "@rentiohq/shared-frontend/dist/redux/payment/payment.api";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import * as paymentUtils from "@rentiohq/shared-frontend/dist/redux/payment/payment.utils";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { EContractFetchType } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { generalLedgerAccountModule } from "@rentiohq/shared-frontend/dist/reduxV2/generalLedgerAccount";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { IPaymentOrder } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { EPaymentRequestFetchType } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import {
  Can,
  Error,
  Loading,
  Page,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import * as t from "../../../../../../services/translationService";
import { PaymentForm } from "./PaymentForm";

const PaymentFormContainer: FC<{}> = () => {
  // Router
  const params = useParams<{
    paymentOrderId: string;
    propertyId: string;
  }>();
  const paymentOrderId = params.paymentOrderId
    ? +params.paymentOrderId
    : undefined;
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  // State
  const [isLoadingPaymentRequests, setIsLoadingPaymentRequests] =
    useState<boolean>(true);
  const [hasOpenPaymentRequests, setHasOpenPaymentRequests] =
    useState<boolean>(false);
  const [isLoadingPaymentOrder, setIsLoadingPaymentOrder] =
    useState<boolean>(true);
  const [paymentOrder, setPaymentOrder] = useState<IPaymentOrder>();

  // Redux
  const dispatch = useDispatch();

  const { contactMeMaster } = authHooks.useSelf();

  const hasBookkeepingForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Bookkeeping,
  );

  const { internalModeEnabled } = useInternalMode();

  const { detail: property } = propertyHooks.useDetail({
    id: propertyId,
  });

  const activeContractsForPropertyFilter = propertyId
    ? contractUtils.getFilterForProperty(propertyId, EContractFetchType.Active)
    : undefined;
  const endedContractsForPropertyFilter = propertyId
    ? contractUtils.getFilterForProperty(propertyId, EContractFetchType.Ended)
    : undefined;

  const { items: activeContracts, isFetching: isLoadingContracts } =
    contractHooks.useGetAll({
      query: { filter: activeContractsForPropertyFilter },
    });
  const { items: endedContracts } = contractHooks.useGetAll({
    query: { filter: endedContractsForPropertyFilter },
  });

  const {
    items: generalLedgerAccounts,
    isFetching: isLoadingGeneralLedgerAccounts,
  } = generalLedgerAccountModule.hooks.usePaged({
    query: hasBookkeepingForBroker
      ? {
          page: 1,
          limit: 100,
          filter: {
            isDefault: { is: false },
          },
          sort: [{ field: "code", method: "ASC" }],
        }
      : undefined,
  });

  const paymentOrdersIdentifier =
    paymentUtils.getPaymentOrdersIdentifierForProperty(property?.id);
  const isLoadingPaymentOrders = useSelector((state: IRootStore) =>
    paymentSelectors.isFetchingPaymentOrdersByIdentifier(
      state,
      paymentOrdersIdentifier,
    ),
  );
  const paymentOrders = useSelector((state: IRootStore) =>
    paymentSelectors.getPaymentOrdersByIdentifier(
      state,
      paymentOrdersIdentifier,
    ),
  );

  activeContracts?.sort(
    (a, b) =>
      new Date(a.startDate as Date).getTime() -
      new Date(b.startDate as Date).getTime(),
  );
  endedContracts?.sort(
    (a, b) =>
      new Date(a.startDate as Date).getTime() -
      new Date(b.startDate as Date).getTime(),
  );

  useEffect(() => {
    if (!propertyId) {
      return;
    }
    dispatch(
      paymentActions.getPaymentOrdersByIdentifier.actions.start({
        paymentOrdersIdentifier,
        refetch: true,
        filterData: {
          where: { completedAt: null, propertyId: property?.id },
          order: "startedAt DESC, id DESC",
        },
      }),
    );
  }, [propertyId, paymentOrdersIdentifier]);

  useEffect(() => {
    const fetchPaymentRequests = async () => {
      if (!paymentOrderId) {
        setIsLoadingPaymentRequests(false);
        return;
      }

      try {
        // Get open PR's for property
        const { data: openPaymentRequests } =
          await paymentApi.getPaymentRequests({
            skip: 0,
            filterData: {
              where: {
                propertyId,
                status: {
                  inq: paymentUtils.getPaymentRequestTypes(
                    EPaymentRequestFetchType.Open,
                  ),
                },
              },
            },
          });

        // Find PR with relevant PO
        const hasOpenPaymentRequests = !!openPaymentRequests.find(
          paymentRequest =>
            paymentRequest.paymentRequestItems.find(
              paymentRequestItem =>
                paymentRequestItem.paymentOrder.id === paymentOrderId,
            ),
        );

        setHasOpenPaymentRequests(hasOpenPaymentRequests);
      } catch (unknownError) {
        const error = unknownError as any;
        logger.logError({ error });
      }

      setIsLoadingPaymentRequests(false);
    };

    fetchPaymentRequests();
  }, [paymentOrderId, propertyId]);

  useEffect(() => {
    const fetchPaymentOrder = async () => {
      if (!paymentOrderId) {
        setIsLoadingPaymentOrder(false);
        return;
      }

      try {
        // Get open PR's for property
        const { data } = await paymentApi.getPaymentOrder(paymentOrderId);

        setPaymentOrder(data);
      } catch (unknownError) {
        const error = unknownError as any;
        logger.logError({ error });
      }

      setIsLoadingPaymentOrder(false);
    };

    fetchPaymentOrder();
  }, [paymentOrderId]);

  // Render
  if (!contactMeMaster) {
    return null;
  }

  if (
    isLoadingPaymentOrder ||
    isLoadingPaymentRequests ||
    isLoadingContracts ||
    isLoadingGeneralLedgerAccounts ||
    isLoadingPaymentOrders
  ) {
    return <Loading />;
  }

  if (!property || !activeContracts || !endedContracts) {
    return <Error errors={[]} />;
  }

  const renderContent = () => (
    <PaymentForm
      property={property}
      paymentOrder={paymentOrder}
      hasOpenPaymentRequests={hasOpenPaymentRequests}
      shouldUpdateOpenPaymentRequests={true}
      activeContracts={activeContracts}
      internalModeEnabled={internalModeEnabled}
      endedContracts={endedContracts}
      generalLedgerAccounts={generalLedgerAccounts}
      paymentOrders={paymentOrders}
    />
  );

  return (
    <Page
      title={paymentOrderId ? t.paymentsEditTitle() : t.paymentsCreateTitle()}
      subtitle={property && formatAddress(property, false)}
      breadcrumbs={{
        to: `/properties/${propertyId}/payments`,
        content: t.paymentsDetailBreadcrumb(),
      }}
    >
      <Can
        role={property.roles}
        perform="propertyPayments:create"
        yes={renderContent}
        no={() => <Navigate to={`/properties/${propertyId}`} />}
      />
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default PaymentFormContainer;
