import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import { ECountIdentifier } from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import {
  Grid,
  Page,
  Pagination,
  Stack,
} from "@rentiohq/web-shared-next/dist/ui-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { NumberParam, StringParam, withDefault } from "serialize-query-params";
import useDeepCompareEffect from "use-deep-compare-effect";
import { UserCard } from "../../components/UserCard";
import {
  DEFAULT_FILTER,
  contactsOverviewWhereFilter,
  getFilterQuery,
} from "./Contacts.utils";

export const ContactsContainer = () => {
  const dispatch = useDispatch();

  const [queryParams, setQueryParamValue] = useQueryParams({
    query: withDefault(StringParam, ""),
    page: withDefault(NumberParam, 1),
  });

  const contactsIdentifier = React.useMemo(() => {
    if (queryParams.query.length > 0) {
      return `all-contacts-query-${queryParams.query}`;
    }
    return "all-contacts";
  }, [queryParams.query]);

  const countIdentifier = React.useMemo(() => {
    return `${ECountIdentifier.ContactsOverviewPaged}-${contactsIdentifier}`;
  }, [contactsIdentifier]);

  const countBase = React.useMemo(() => {
    let countFilter: { [key: string]: any } = {
      where: contactsOverviewWhereFilter,
    };

    if (queryParams.query) {
      countFilter.search = queryParams.query;
    }

    return append("/contacts/count", countFilter);
  }, [queryParams.query]);

  const contactsPagedByPage = useSelector((state: IRootStore) =>
    contactSelectors.getContactsPaged(
      state,
      contactsIdentifier,
      queryParams.page || 1,
    ),
  );
  const contactsPagedTotalCount = useSelector((state: IRootStore) =>
    countSelectors.getCount(state, countIdentifier),
  );
  const isFetching = useSelector((state: IRootStore) =>
    contactSelectors.isFetchingContactsForPage(
      state,
      contactsIdentifier,
      queryParams.page || 1,
    ),
  );

  const fetchData = () => {
    const filter = getFilterQuery(queryParams, DEFAULT_FILTER);
    dispatch(
      contactActions.getContactsPaged.actions.start({
        id: contactsIdentifier,
        page: queryParams.page || 1,
        filter,
      }),
    );
  };

  useDeepCompareEffect(() => {
    fetchData();
  }, [queryParams]);

  React.useEffect(() => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier,
        countBase,
      }),
    );
  }, [queryParams.query]);

  const handlePageClick = (page: number) => {
    setQueryParamValue({ page });
  };

  const initialPage = queryParams.page || 1;
  const pageCount = contactsPagedTotalCount
    ? Math.ceil(contactsPagedTotalCount / 20)
    : 0;

  const renderContent = () => {
    return (
      <Grid container spacing={1}>
        {contactsPagedByPage?.map(
          contact =>
            contact && (
              <Grid item key={contact?.id} xs={12} sm={6} md={4} lg={4} xl={3}>
                <UserCard contact={contact} />
              </Grid>
            ),
        )}
      </Grid>
    );
  };

  const isEmpty = !contactsPagedTotalCount && pageCount === 0 && !isFetching;

  return (
    <Page
      title={"Your Contacts"}
      isLoading={isFetching}
      isEmpty={isEmpty}
      emptyHeading="tenant.contact.empty.heading"
      emptyMessage="tenant.contact.empty.details"
    >
      {renderContent()}
      {pageCount > 1 && (
        <Stack direction="row" justifyContent="center" mt={3}>
          <Pagination
            color="primary"
            page={initialPage}
            count={pageCount}
            onChange={(_, page) => handlePageClick(page)}
          />
        </Stack>
      )}
    </Page>
  );
};
