import { EPaymentRequestFetchType, EPaymentRequestStatus, } from "../../types/paymentRequest.types";
import { generateUtils } from "../generators/generator.utils";
import { GENERATOR_PARAMS } from "./paymentRequest.constants";
export var getInfiniteLoadKey = generateUtils(GENERATOR_PARAMS).getInfiniteLoadKey;
export var getPaymentRequestTypes = function (fetchType) {
    switch (fetchType) {
        case EPaymentRequestFetchType.Open:
            return [EPaymentRequestStatus.New, EPaymentRequestStatus.FailedPaidIn];
        case EPaymentRequestFetchType.Pending:
            return [
                EPaymentRequestStatus.Approved,
                EPaymentRequestStatus.Pending,
                EPaymentRequestStatus.PaidIn,
                EPaymentRequestStatus.Partial,
                EPaymentRequestStatus.Transferring,
                EPaymentRequestStatus.Transferred,
                EPaymentRequestStatus.PayingOut,
                // TODO: What to do with failed statuses?
                EPaymentRequestStatus.FailedTransfer,
                EPaymentRequestStatus.FailedPayout,
                EPaymentRequestStatus.FailedKyc,
                EPaymentRequestStatus.Failed,
            ];
        case EPaymentRequestFetchType.Done:
            return [EPaymentRequestStatus.Paid];
        default:
            return [];
    }
};
