import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import { SHOW_APP_MODAL } from "@rentiohq/web-shared-next/dist/redux/actions";
import { appModalSelector } from "@rentiohq/web-shared-next/dist/redux/selectors";
import {
  Avatar,
  Button,
  Card,
  Icon,
  Stack,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import {
  EButtonColors,
  EButtonVariants,
} from "@rentiohq/web-shared-next/dist/utils/types/button";
import { isAndroid, isIOS } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { OpenAppCardStyles } from "./OpenAppCard.styles";

export const OpenAppCard = () => {
  const sx = OpenAppCardStyles();
  const dispatch = useDispatch();

  const appModal = useSelector(appModalSelector);
  const { link, description, btnText, icon } = appModal;

  const getOpenAppButtonText = () => {
    if (isIOS || isAndroid) {
      return getLocalizedText("app_banner.cta.open_app.mobile");
    }
    return getLocalizedText("app_banner.cta.open_app.desktop");
  };

  return (
    <Card sx={sx.wrapper}>
      <Stack direction="column" gap={2} alignItems="flex-start">
        <Avatar alt="logo" sx={sx.content.avatar}>
          <Icon path={EAssets.LogoIcon} width="72px" height="72px" />
        </Avatar>
        <Typography variant="h5" sx={sx.content.description}>
          {description ||
            getLocalizedText("property.report_problem.install_app")}
        </Typography>
        <Button
          variant={EButtonVariants.Outlined}
          color={EButtonColors.Success}
          size="small"
          startIcon={
            <Icon
              path={icon || EAssets.ApplicationIcon}
              width="16px"
              height="16px"
            />
          }
          onClick={() => {
            window.open(
              link || "https://rentio.page.link/install-the-app",
              "_blank",
            );
            dispatch({
              type: SHOW_APP_MODAL,
              payload: { showAppModal: false },
            });
          }}
        >
          {btnText || getOpenAppButtonText()}
        </Button>
      </Stack>
    </Card>
  );
};
