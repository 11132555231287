import { getSchema as getSchema1, uiSchema as uiSchema1, } from "./schema.exportActivities.1";
import { getInitialValues } from "./schema.exportActivities.utils";
export var getExportActivitiesSchemas = function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema1(initialValues, extraData),
            uiSchema: uiSchema1(extraData),
        },
    ];
};
