var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { appendFilter } from "../../utils/api.utils";
import api from "../../utils/api/api.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "../../utils/date.utils";
export var FETCH_LIMIT = 20;
export var getContacts = function (skip, limit, filterData) {
    if (limit === void 0) { limit = FETCH_LIMIT; }
    if (filterData === void 0) { filterData = {}; }
    var filter = __assign({ order: ["lastname ASC", "firstname ASC", "createdAt DESC"], limit: limit, skip: skip }, filterData);
    return api.get(appendFilter("/contacts", filter));
};
export var getContact = function (id) {
    return api.get("/contacts/".concat(id));
};
export var getContactsByAccountIds = function (ids) {
    return api.get(appendFilter("/contacts", {
        where: { accountIds: { inq: ids } },
    }));
};
export var getContactSupport = function () {
    return api.get(appendFilter("/contacts", { where: { type: "S" } }));
};
export var searchContacts = function (query, extraFilterData) {
    if (extraFilterData === void 0) { extraFilterData = {}; }
    var filter = __assign(__assign({ order: ["lastname ASC", "firstname ASC", "createdAt DESC"], limit: 30 }, extraFilterData), { search: query });
    return api.get(appendFilter("/contacts", filter));
};
export var createContact = function (data, sendInvite) {
    if (sendInvite === void 0) { sendInvite = false; }
    return api.post("/contacts?sendInvite=".concat(sendInvite), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data, false),
    });
};
export var createContactBroker = function (_a) {
    var brokerId = _a.brokerId, customId = _a.customId, contact = _a.contact;
    return api.post("/brokers/".concat(brokerId, "/").concat(customId, "-contact"), {
        data: contact,
    });
};
export var updateContact = function (id, data) {
    return api.patch("/contacts/".concat(id), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data, false),
    });
};
export var breakUserConnection = function (id) {
    return api.delete("/contacts/".concat(id, "/unlink-connection"), { isV2: true });
};
export var kycContact = function (id, presignedUrlIdCardFront, presignedUrlIdCardBack, asBroker) {
    if (asBroker === void 0) { asBroker = false; }
    var path = asBroker ? "/broker-kyc/".concat(id) : "/contacts/".concat(id, "/kyc");
    return api.post(path, {
        isServerless: true,
        data: {
            uploadMethod: "presigned",
            IDCardFrontside: { preSignedUrl: presignedUrlIdCardFront },
            IDCardBackside: { preSignedUrl: presignedUrlIdCardBack },
        },
    });
};
export var kycFinancialContact = function (id, presignedUrlIdCardFront, presignedUrlIdCardBack) {
    return api.post("/financial-kyc/".concat(id), {
        isServerless: true,
        data: {
            uploadMethod: "presigned",
            IDCardFrontside: { preSignedUrl: presignedUrlIdCardFront },
            IDCardBackside: { preSignedUrl: presignedUrlIdCardBack },
        },
    });
};
export var deleteContact = function (id) { return api.delete("/contacts/".concat(id)); };
export var inviteContact = function (id) { return api.post("/contacts/".concat(id, "/invite")); };
export var getIbans = function (contactId) {
    return api.get("/contacts/".concat(contactId, "/bankAccounts"));
};
export var addIban = function (data, contactId) {
    return api.post("/contacts/".concat(contactId || "me", "/bankAccounts"), {
        data: data,
    });
};
export var deleteIban = function (contactId, ibanId) {
    return api.delete("/contacts/".concat(contactId, "/bankAccounts/").concat(ibanId));
};
export var payoutRentDeposit = function (contractId, ibanId) {
    return api.post("/contracts/rent-deposits/payout", {
        isServerless: true,
        data: {
            contractId: contractId,
            tenantBankAccountId: ibanId,
        },
    });
};
export var getContactBroker = function (brokerId, contactId) {
    return api.get("/brokers/".concat(brokerId, "/").concat(contactId, "-contact"));
};
