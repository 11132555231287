import React from "react";
import { Outlet } from "react-router-dom";

import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { Page, Tabs } from "@rentiohq/web-shared-next/dist/ui-components";
import { useLocation, useParams } from "react-router-dom";
import { EPropertyDetailsTabs } from "./propertyDetails.types";
import { getActiveTab } from "./propertyDetails.utils";

export const PropertyDetails = () => {
  const { pathname } = useLocation();
  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const [activeTab, setActiveTab] = React.useState(EPropertyDetailsTabs.Info);

  React.useEffect(() => {
    if (pathname) {
      // @ts-ignore
      setActiveTab(Number(getActiveTab(pathname)));
    }
  }, [pathname]);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) =>
    setActiveTab(newValue);

  const { detail: property = {}, isFetching } = propertyHooks.useDetail({
    id: propertyId,
  });

  const tabs = [
    {
      label: "system.info",
      tabIndex: EPropertyDetailsTabs.Info,
      link: `/properties/${propertyId}/`,
    },
    {
      label: "system.contracts",
      tabIndex: EPropertyDetailsTabs.Contract,
      link: `/properties/${propertyId}/contracts`,
    },
    {
      label: "system.documents",
      tabIndex: EPropertyDetailsTabs.Documents,
      link: `/properties/${propertyId}/documents`,
    },
    {
      label: "system.history",
      tabIndex: EPropertyDetailsTabs.History,
      link: `/properties/${propertyId}/history`,
    },
  ];

  return (
    <Page
      title={formatAddress(property, true, true, false)}
      isLoading={isFetching}
      breadcrumbLabel="property.detail.breadcrumb"
      breadcrumbLink="/properties"
    >
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
      />
      <Outlet />
    </Page>
  );
};
