import { jsx as _jsx } from "react/jsx-runtime";
import { ETaskStatus } from "@rentiohq/shared-frontend/dist/types/task.types";
import { Lozenge } from "../Lozenge/Lozenge";
export var RepairLozenge = function (_a) {
    var text = _a.text, status = _a.status;
    var getStatus = function () {
        switch (status) {
            case ETaskStatus.Finished:
                return "success";
            case ETaskStatus.Cancelled:
                return "warning";
            case ETaskStatus.Failed:
                return "error";
            case ETaskStatus.Open:
            case ETaskStatus.Pending:
            default:
                return "default";
        }
    };
    return (_jsx(Lozenge, { isUpperCase: true, appearance: getStatus(), children: text }));
};
