var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import ActivityText from "@rentiohq/shared-frontend/dist/components/components/ActivityText";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getActivityMetaInfo } from "@rentiohq/shared-frontend/dist/redux/activity/activity.utils";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { ACTIVITY_TYPES, } from "@rentiohq/shared-frontend/dist/types/activity.types";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { mapActivityTypeToString } from "@rentiohq/shared-frontend/dist/utils/activity.utils";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { startOfDay } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { join } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { compact, uniqBy } from "lodash";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import isEmpty from "ramda/es/isEmpty";
import { isValidElement, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NumberParam } from "serialize-query-params";
import { DisplayText, DropdownMenu, ESpacings, Filters, Icon, Loading, OptionListShared, TextStyle, } from "../../components";
import { useInternalMode } from "../../redux/system/system.hooks";
import * as t from "../../services/translationService";
import { date, i18n } from "../../utils";
import { Button } from "../Button";
import { RentioInternalRendererActivity } from "../RentioInternalRenderer";
import * as S from "./Activities.styled";
import { getActivityIcon, getHasActiveFilter } from "./Activities.utils";
import { ReadMoreToggle } from "./Detail/ReadMoreToggle";
import { ExportActivities } from "./components/ExportActivities";
export var Activities = function (props) {
    var activities = props.activities, heading = props.heading, hasFetchMore = props.hasFetchMore, fetchMore = props.fetchMore, limit = props.limit, count = props.count, loading = props.loading, _a = props.isCompact, isCompact = _a === void 0 ? false : _a, exportPropertyId = props.exportPropertyId, moreLink = props.moreLink, onMoreClick = props.onMoreClick, currentFilter = props.currentFilter, setFilter = props.setFilter;
    var internalModeEnabled = useInternalMode().internalModeEnabled;
    var navigate = useNavigate();
    var hasMoreButton = !!moreLink || !!onMoreClick;
    var hasActiveFilter = getHasActiveFilter(currentFilter);
    var _b = useState(activities.reduce(function (map, activity) {
        var _a;
        return __assign(__assign({}, map), (_a = {}, _a[activity.id] = false, _a));
    }, {})), readMoreOpenMap = _b[0], setReadMoreOpen = _b[1];
    var _c = useState(false), showExportModal = _c[0], setShowExportModal = _c[1];
    var canExportHistoryForBroker = brokerHooks.useBrokerFeature(EBrokerFeature.ExportHistory);
    var toggleOpenStateForActivity = function (activityId) {
        var _a;
        setReadMoreOpen(__assign(__assign({}, readMoreOpenMap), (_a = {}, _a[activityId] = !readMoreOpenMap[activityId], _a)));
    };
    var _d = useQueryParams({
        propertyId: NumberParam,
        payInId: NumberParam,
        paymentOrderId: NumberParam,
        paymentRequestId: NumberParam,
    }), setQueryParamValue = _d[1];
    var filterByActivityType = function (values) {
        setFilter({ activityTypes: values });
    };
    var handleTypeChange = useCallback(filterByActivityType, [
        filterByActivityType,
    ]);
    var handleTypeRemove = useCallback(function () { return filterByActivityType([]); }, []);
    var handleFiltersClearAll = useCallback(function () {
        handleTypeRemove();
    }, [handleTypeRemove]);
    var createHandleParamPress = function (activity) { return function (param) {
        var _a, _b, _c, _d, _e;
        switch (param) {
            case "actor":
                navigate("/contacts/by-account/".concat((_a = activity.actor) === null || _a === void 0 ? void 0 : _a.id));
                return;
            case "property":
                navigate("/properties/".concat((_b = activity.property) === null || _b === void 0 ? void 0 : _b.id));
                return;
            case "payee":
                navigate("/contacts/by-account/".concat((_c = activity.payee) === null || _c === void 0 ? void 0 : _c.id));
                return;
            case "payer":
                navigate("/contacts/by-account/".concat((_d = activity.payer) === null || _d === void 0 ? void 0 : _d.id));
                return;
            case "paymentRequest":
                setQueryParamValue({ paymentRequestId: activity.paymentRequestId });
                return;
            case "participant":
                navigate("/contacts/by-account/".concat((_e = activity.participant) === null || _e === void 0 ? void 0 : _e.id));
                return;
            case "contact":
                navigate("/contacts/".concat(activity.contact.id));
                return;
            case "taskTitle":
                navigate("/tasks/".concat(activity.taskId));
                return;
            case "visitUrl":
                open(activity.visitUrl, "_blank");
                return;
        }
    }; };
    var getDropdownActions = function (activity) {
        var property = activity.property, propertyId = activity.propertyId, contractId = activity.contractId, paymentPayInId = activity.paymentPayInId, paymentOrderId = activity.paymentOrderId, paymentRequestId = activity.paymentRequestId, actor = activity.actor, payee = activity.payee, payer = activity.payer, participant = activity.participant, 
        // TODO:
        // contact,
        taskId = activity.taskId;
        var result = [];
        // Payments
        var paymentResult = [];
        if (paymentPayInId) {
            paymentResult.push({
                content: getLocalizedText("system.pay_in"),
                onClick: function () {
                    setQueryParamValue({
                        payInId: paymentPayInId,
                    });
                },
            });
        }
        if (paymentRequestId) {
            paymentResult.push({
                content: getLocalizedText("system.payment_request"),
                onClick: function () {
                    setQueryParamValue({
                        paymentRequestId: paymentRequestId,
                    });
                },
            });
        }
        if (paymentOrderId) {
            paymentResult.push({
                content: getLocalizedText("system.payment_order"),
                onClick: function () {
                    setQueryParamValue({
                        paymentOrderId: paymentOrderId,
                    });
                },
            });
        }
        if (paymentResult.length > 0) {
            result.push(paymentResult);
        }
        // Accounts
        var accounts = uniqBy(compact([actor, payee, payer, participant]), "id");
        var accountResult = accounts.map(function (account) {
            return {
                content: getName(account),
                onClick: function () {
                    navigate("/contacts/by-account/".concat(account.id));
                },
            };
        });
        if (accountResult.length > 0) {
            result.push(accountResult);
        }
        // Tasks
        if (taskId) {
            result.push([
                {
                    content: getLocalizedText("system.task"),
                    onClick: function () {
                        navigate("/tasks/".concat(taskId));
                    },
                },
            ]);
        }
        // General
        var generalResult = [];
        if (contractId) {
            generalResult.push({
                content: getLocalizedText("system.contract"),
                onClick: function () {
                    navigate("/properties/".concat(propertyId, "/contracts"));
                },
            });
        }
        if (property && propertyId) {
            generalResult.push({
                content: formatAddress(property),
                onClick: function () {
                    navigate("/properties/".concat(propertyId));
                },
            });
        }
        if (generalResult.length > 0) {
            result.push(generalResult);
        }
        return result;
    };
    var renderCompactActivities = function () {
        var dataSource = hasMoreButton ? activities.slice(0, limit) : activities;
        var data = orderBy(dataSource, ["datetime", "id"], ["desc"]);
        if (data.length === 0 && !loading) {
            return (_jsx(TextStyle, { variation: "subdued", children: getLocalizedText("activity.empty") }));
        }
        return (_jsx(S.Wrapper, { isCompact: true, children: data.map(function (activity) {
                var meta = getActivityMetaInfo(activity);
                var handleParamPress = createHandleParamPress(activity);
                return (_jsxs(S.Item, { children: [_jsx(S.Dot, {}), _jsx(S.ContentWrap, { children: _jsxs(S.ActivityContentWrap, { children: [_jsx("div", { children: _jsx(ActivityText, { onParamPress: handleParamPress, activityItem: activity }) }), _jsx(ReadMoreToggle, { activity: activity, isOpen: readMoreOpenMap[activity.id], toggleOpen: toggleOpenStateForActivity }), _jsx(RentioInternalRendererActivity, { activity: activity }), _jsx(TextStyle, { variation: "subdued", children: "".concat(date.diffForHumans(new Date(activity.datetime)), " - ").concat(meta) })] }) })] }, activity.id));
            }) }));
    };
    var renderActivities = function () {
        var groupedActivities = groupBy(hasMoreButton ? activities.slice(0, limit) : activities, function (activity) { return startOfDay(new Date(activity.datetime)); });
        var sortedActivitiesKeys = Object.keys(groupedActivities).sort(function (a, b) { return new Date(b).getTime() - new Date(a).getTime(); });
        var appliedFilters = [];
        if (currentFilter.activityTypes && !isEmpty(currentFilter.activityTypes)) {
            appliedFilters.push({
                key: "activityType",
                label: join(currentFilter.activityTypes.map(function (item) {
                    return t.activityType(item.toLowerCase());
                })),
                onRemove: handleTypeRemove,
            });
        }
        return (_jsxs(_Fragment, { children: [_jsxs(Box, { mb: ESpacings.loose, children: [_jsx(Filters, { appliedFilters: appliedFilters, onClearAll: handleFiltersClearAll, filters: [
                                {
                                    key: "activityType",
                                    label: t.historyFilterActivitiesLabel(),
                                    content: (_jsx(OptionListShared, { id: "payment-status", value: currentFilter.activityTypes, variant: "simple", multiple: true, options: Object.keys(ACTIVITY_TYPES)
                                            .filter(function (type) { return type !== "BILLING"; })
                                            .map(function (type) { return ({
                                            label: t.activityType(type.toLowerCase()),
                                            value: type,
                                            id: type,
                                        }); }), onChange: handleTypeChange })),
                                },
                            ] }), exportPropertyId &&
                            (canExportHistoryForBroker || internalModeEnabled) && (_jsx(Box, { mt: ESpacings.tight, children: _jsx(Button, { appearance: "link", onClick: function () {
                                    setShowExportModal(true);
                                }, children: getLocalizedText("history.filter_activities.export.title") }) }))] }), _jsx(S.Wrapper, { isCompact: false, children: sortedActivitiesKeys.map(function (groupedDate) {
                        return (_jsxs(S.Section, { children: [_jsx(S.SectionHeading, { size: "extraSmall", children: date.diffForHumans(new Date(groupedDate)) }), orderBy(groupedActivities[groupedDate], ["datetime", "id"], ["desc"]).map(function (activity) {
                                    var handleParamPress = createHandleParamPress(activity);
                                    var dropdownActions = getDropdownActions(activity);
                                    return (_jsxs(S.Item, { children: [_jsx(S.Time, { children: _jsx(TextStyle, { style: { textAlign: "right" }, variation: ["code", "subdued"], element: "div", children: date.format(new Date(activity.datetime), "HH:mm") }) }), _jsx(S.Dot, {}), _jsx(S.Icon, { children: _jsx(Icon, { source: getActivityIcon(activity), color: "primary" }) }), _jsxs(S.ActivityContentWrap, { children: [_jsx("div", { children: _jsx(ActivityText, { onParamPress: handleParamPress, activityItem: activity }) }), _jsx(ReadMoreToggle, { activity: activity, isOpen: readMoreOpenMap[activity.id], toggleOpen: toggleOpenStateForActivity }), _jsx(RentioInternalRendererActivity, { activity: activity }), _jsx(TextStyle, { variation: "subdued", children: "".concat(date.diffForHumans(new Date(activity.datetime)), " - ").concat(i18n.t("activity.".concat((mapActivityTypeToString(activity.type) || "")
                                                            .toString()
                                                            .toLowerCase()))) })] }), dropdownActions.length > 0 && (_jsx(DropdownMenu, { passDownClickEvents: false, children: _jsx("span", { children: _jsx(Icon, { source: "navigationMenuVertical" }) }), actions: dropdownActions }))] }, activity.id));
                                })] }, groupedDate));
                    }) })] }));
    };
    var handleFetchMore = function () {
        fetchMore();
    };
    var renderLoadMore = function () {
        if (count <= activities.length) {
            return null;
        }
        if (loading) {
            return null;
        }
        return (_jsx(Box, { mt: ESpacings.base, children: _jsx(Button, { appearance: "link", onClick: handleFetchMore, children: t.loadMore() }) }));
    };
    // eslint-disable-next-line no-nested-ternary
    var headingMarkup = heading ? (isValidElement(heading) ? (heading) : (_jsx(DisplayText, { size: "medium", children: heading }))) : null;
    var renderContent = isCompact
        ? renderCompactActivities()
        : renderActivities();
    return (_jsxs("div", { children: [headingMarkup, activities.length > 0 || hasActiveFilter
                ? renderContent
                : !loading && _jsx("div", { children: t.historyEmptyMessage() }), loading && _jsx(Loading, { asDots: true }), hasFetchMore && renderLoadMore(), hasMoreButton && activities.length > limit && (_jsx(Box, { mt: ESpacings.base, children: _jsx(Button, { appearance: "link", url: moreLink, onClick: onMoreClick, children: t.historyViewHistoryAction() }) })), exportPropertyId && showExportModal && (_jsx(ExportActivities, { onClose: function () { return setShowExportModal(false); }, propertyId: exportPropertyId, selectedTypes: currentFilter.activityTypes, lastLoadedActivity: activities[activities.length - 1] }))] }));
};
