import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import * as t from "../../services/translationService";
import { TextStyle } from "../TextStyle";
import { SearchItemApplication } from "./SearchItemApplication";
import { SearchItemContact } from "./SearchItemContact";
import { SearchItemContract } from "./SearchItemContract";
import { SearchItemProperty } from "./SearchItemProperty";
import { SearchItemTask } from "./SearchItemTask";
import { StyledSearchList, StyledSearchListItem, StyledSearchListTitle, } from "./SearchList.styled";
import { hasResults } from "./SearchList.utils";
export var SearchList = function (_a) {
    var _b = _a.results, results = _b === void 0 ? [] : _b, linkHandler = _a.linkHandler, searchValue = _a.searchValue;
    var renderResults = function (group) {
        var Item;
        switch (group.model) {
            case "property":
                Item = SearchItemProperty;
                break;
            case "contact":
                Item = SearchItemContact;
                break;
            case "contract":
                Item = SearchItemContract;
                break;
            case "task":
                Item = SearchItemTask;
                break;
            case "application":
                Item = SearchItemApplication;
                break;
            default:
                break;
        }
        return (Item && (_jsx(Item, { data: group.data, linkHandler: linkHandler, searchValue: searchValue })));
    };
    if (!searchValue) {
        return (_jsx(Box, { p: 2, children: _jsx(TextStyle, { variation: "subdued", children: t.searchFirstUse() }) }));
    }
    if (!hasResults(results)) {
        return (_jsx(Box, { p: 2, children: _jsx(TextStyle, { variation: "subdued", children: t.searchNoResultsDefault() }) }));
    }
    if (!results) {
        return null;
    }
    return (_jsx(StyledSearchList, { children: results.map(function (group, groupIndex) {
            var _a;
            if (((_a = group.data) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                return null;
            }
            return (_jsxs(StyledSearchListItem, { children: [_jsx(StyledSearchListTitle, { children: t.modelType(group.model) }), renderResults(group)] }, "".concat(groupIndex, "Item")));
        }) }));
};
