import { EPropertyForRentStatus } from "@rentiohq/shared-frontend/dist/types/property.types";
import styled, { spacing } from "@rentiohq/web-theme";

interface IForRentSwitchStyleProps {
  forRentStatus: EPropertyForRentStatus;
}

export const ForRentSwitch = styled.div<IForRentSwitchStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 100px;
  padding: 3px ${spacing("tight")};
  color: white;
  background-color: ${({ forRentStatus }) =>
    [EPropertyForRentStatus.ForRent, EPropertyForRentStatus.InOption].includes(
      forRentStatus,
    )
      ? "purple"
      : "gray"};
`;
