import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";
import { snackbarSelector } from "../../redux/selectors";
// assets
import { IconAlertCircle, IconCircleCheck, IconInfoCircle, IconSquareRoundedX, } from "@tabler/icons-react";
// custom styles
var StyledSnackbarProvider = styled(SnackbarProvider)(function (_a) {
    var theme = _a.theme;
    return ({
        "&.notistack-MuiContent-default": {
            backgroundColor: theme.palette.primary.main,
        },
        "&.notistack-MuiContent-error": {
            backgroundColor: theme.palette.error.main,
        },
        "&.notistack-MuiContent-success": {
            backgroundColor: theme.palette.success.main,
        },
        "&.notistack-MuiContent-info": {
            backgroundColor: theme.palette.info.main,
        },
        "&.notistack-MuiContent-warning": {
            backgroundColor: theme.palette.warning.main,
        },
    });
});
export var Notistack = function (_a) {
    var children = _a.children;
    var snackbar = useSelector(snackbarSelector);
    var iconSX = { marginRight: 8, fontSize: "1.15rem" };
    return (_jsx(StyledSnackbarProvider, { maxSnack: snackbar.maxStack, dense: snackbar.dense, iconVariant: snackbar.iconVariant === "useemojis"
            ? {
                success: _jsx(IconCircleCheck, { style: iconSX }),
                error: _jsx(IconSquareRoundedX, { style: iconSX }),
                warning: _jsx(IconInfoCircle, { style: iconSX }),
                info: _jsx(IconAlertCircle, { style: iconSX }),
            }
            : undefined, hideIconVariant: snackbar.iconVariant === "hide" ? true : false, children: children }));
};
