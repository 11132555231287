import { Route } from "react-router-dom";
import RegistrationForm from "scenes/RegistrationForm";
import { RentDepositForm } from "scenes/RentDepositForm";
import { PaymentForm as PaymentFormV2 } from "../../scenes/Payments/scenes-v2/PaymentForm";
import { PaymentForm } from "../../scenes/Payments/scenes/PaymentForm";
import { PropertyForm } from "../../scenes/PropertyForm";
import { PropertyDetailApplicationsContainer } from "../Applications/PropertyDetailApplicationsContainer";
import { getContractsScene } from "../Contracts";
import { getContractsSceneAtPropertyDetailRoot } from "../Contracts/Contracts.scene";
import { DocumentsOverviewContainer } from "../Documents/DocumentsOverviewContainer";
import { Extra } from "../Extra";
import { History } from "../History";
import { PaymentsOverviewContainer } from "../Payments";
import { PaymentsOverviewContainer as PaymentsOverviewContainerV2 } from "../Payments/scenes-v2/PaymentsOverviewContainer";
import { TasksForProperty } from "../Tasks";
import PropertiesInGroup from "./PropertiesInGroup";
import { PropertyDetail } from "./PropertyDetail";
import { PropertyDetailDashboard } from "./PropertyDetailDashboard";

export const getPropertyDetailScene = () => (
  <Route path=":propertyId">
    {getContractsSceneAtPropertyDetailRoot()}

    <Route path="edit" element={<PropertyForm />} />

    <Route path="duplicate" element={<PropertyForm isDuplicate={true} />} />

    <Route path="payments/add" element={<PaymentForm />} />

    <Route path="payments-v2/add" element={<PaymentFormV2 />} />

    <Route path="payments/:paymentOrderId/edit" element={<PaymentForm />} />

    <Route
      path="contracts/:basicContractId/rent-deposit"
      element={<RentDepositForm />}
    />

    <Route
      path="contracts/:contractId/registration"
      element={<RegistrationForm />}
    />

    <Route element={<PropertyDetail />}>
      <Route index element={<PropertyDetailDashboard />} />

      <Route path="group-info" element={<PropertiesInGroup />} />

      <Route path="payments" element={<PaymentsOverviewContainer />} />
      <Route path="payments-v2" element={<PaymentsOverviewContainerV2 />} />

      <Route
        path="applications"
        element={<PropertyDetailApplicationsContainer />}
      />

      <Route path="documents" element={<DocumentsOverviewContainer />} />

      <Route path="history" element={<History />} />

      <Route path="extra" element={<Extra />} />

      <Route path="tasks" element={<TasksForProperty />} />

      {getContractsScene()}
    </Route>
  </Route>
);
