var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { stateToHTML } from "draft-js-export-html";
import "draft-js/dist/Draft.css";
import React from "react";
import JsxParser from "react-jsx-parser";
import ToolbarFooter from "../../components/ToolbarFooter";
import { getTextStylesBackgroundColor, getTextStylesTextColor, } from "../Editor/Editor.config";
import { ETextAlignment, } from "../Editor/Editor.types";
import AtomicBlockPageBreak from "../Editor/components/EditorData/AtomicBlockPageBreak";
import { HEADER_SIZES, HEADER_SPACING_BOTTOM_FACTOR, 
// HEADER_WEIGHTS,
HEADER_SPACING_TOP_FACTOR, } from "../Editor/components/EditorData/EditorData.styles";
import { DisplayTypeContext } from "../Editor/contexts/DisplayType.context";
import { DocumentConfigContext } from "../Editor/contexts/DocumentConfig.context";
import { CONDITION_RENDERER_TYPE, } from "../Editor/decorators/ConditionRenderer";
import { INDEX_COUNT_RENDERER_TYPE, } from "../Editor/decorators/IndexCountRenderer";
import { LOOP_RENDERER_TYPE, } from "../Editor/decorators/LoopRenderer";
import { VARIABLE_RENDERER_TYPE, } from "../Editor/decorators/VariableRenderer";
import * as S from "./EditorPreview.styles";
import Condition from "./components/Condition";
import ImageBlock from "./components/ImageBlock";
import IndexCount from "./components/IndexCount";
import Loop from "./components/Loop";
import SignatureBlock from "./components/SignatureBlock";
import Variable from "./components/Variable";
var CustomJsxParser = function (props) { return (
// @ts-ignore
_jsx(JsxParser, { bindings: {}, components: {
        // TODO: Custom page component => ambiguity in name
        Page: AtomicBlockPageBreak,
        Condition: Condition,
        ImageBlock: ImageBlock,
        SignatureBlock: SignatureBlock,
        IndexCount: IndexCount,
        Loop: Loop,
        Variable: Variable,
    }, jsx: props.jsx })); };
export var CustomJsxParserMemo = React.memo(CustomJsxParser, function (prev, next) {
    return prev.jsx === next.jsx;
});
var EditorPreview = function (props) {
    var editorState = props.editorState, onPressUpdate = props.onPressUpdate, onPressSave = props.onPressSave, _a = props.canEdit, canEdit = _a === void 0 ? false : _a;
    // Context
    var _b = React.useContext(DisplayTypeContext), isPrint = _b.isPrint, isTemplateDocumentExternal = _b.isTemplateDocumentExternal;
    var documentConfig = React.useContext(DocumentConfigContext).documentConfig;
    // Helpers
    var jsx = React.useMemo(function () {
        var inlineStyles = {};
        var textStylesTextColor = getTextStylesTextColor(documentConfig);
        Object.keys(textStylesTextColor).forEach(function (key) {
            inlineStyles[key] = { style: textStylesTextColor[key].styles };
        });
        var textStylesBackgroundColor = getTextStylesBackgroundColor(documentConfig);
        Object.keys(textStylesBackgroundColor).forEach(function (key) {
            inlineStyles[key] = { style: textStylesBackgroundColor[key].styles };
        });
        var result = stateToHTML(editorState.getCurrentContent(), {
            inlineStyles: __assign({}, inlineStyles),
            // defaultBlockTag: "div",
            blockStyleFn: function (contentBlock) {
                var type = contentBlock.getType();
                var data = contentBlock.getData();
                var style = {};
                var textAlignmentClass = data.get("textAlignmentClass");
                if (textAlignmentClass) {
                    var textAlignment = "left";
                    switch (textAlignmentClass) {
                        case "text-alignment-".concat(ETextAlignment.Center):
                            textAlignment = "center";
                            break;
                        case "text-alignment-".concat(ETextAlignment.Right):
                            textAlignment = "right";
                            break;
                        case "text-alignment-".concat(ETextAlignment.Justify):
                            textAlignment = "justify";
                            break;
                        case "text-alignment-".concat(ETextAlignment.Left):
                        default:
                            break;
                    }
                    style.textAlign = textAlignment;
                }
                switch (type) {
                    case "header-one":
                        style.fontSize = "".concat(HEADER_SIZES[0], "px");
                        // style.fontWeight = HEADER_WEIGHTS[0];
                        style.marginTop = "".concat(HEADER_SIZES[0] * HEADER_SPACING_TOP_FACTOR, "px");
                        style.marginBottom = "".concat(HEADER_SIZES[0] * HEADER_SPACING_BOTTOM_FACTOR, "px");
                        break;
                    case "header-two":
                        style.fontSize = "".concat(HEADER_SIZES[1], "px");
                        // style.fontWeight = HEADER_WEIGHTS[1];
                        style.marginTop = "".concat(HEADER_SIZES[1] * HEADER_SPACING_TOP_FACTOR, "px");
                        style.marginBottom = "".concat(HEADER_SIZES[1] * HEADER_SPACING_BOTTOM_FACTOR, "px");
                        break;
                    case "header-three":
                        style.fontSize = "".concat(HEADER_SIZES[2], "px");
                        // style.fontWeight = HEADER_WEIGHTS[2];
                        style.marginTop = "".concat(HEADER_SIZES[2] * HEADER_SPACING_TOP_FACTOR, "px");
                        style.marginBottom = "".concat(HEADER_SIZES[2] * HEADER_SPACING_BOTTOM_FACTOR, "px");
                        break;
                    case "header-four":
                        style.fontSize = "".concat(HEADER_SIZES[3], "px");
                        // style.fontWeight = HEADER_WEIGHTS[3];
                        style.marginTop = "".concat(HEADER_SIZES[3] * HEADER_SPACING_TOP_FACTOR, "px");
                        style.marginBottom = "".concat(HEADER_SIZES[3] * HEADER_SPACING_BOTTOM_FACTOR, "px");
                        break;
                    case "header-five":
                        style.fontSize = "".concat(HEADER_SIZES[4], "px");
                        // style.fontWeight = HEADER_WEIGHTS[4];
                        style.marginTop = "".concat(HEADER_SIZES[4] * HEADER_SPACING_TOP_FACTOR, "px");
                        style.marginBottom = "".concat(HEADER_SIZES[4] * HEADER_SPACING_BOTTOM_FACTOR, "px");
                        break;
                    case "header-six":
                        style.fontSize = "".concat(HEADER_SIZES[5], "px");
                        // style.fontWeight = HEADER_WEIGHTS[5];
                        style.marginTop = "".concat(HEADER_SIZES[5] * HEADER_SPACING_TOP_FACTOR, "px");
                        style.marginBottom = "".concat(HEADER_SIZES[5] * HEADER_SPACING_BOTTOM_FACTOR, "px");
                        break;
                    default:
                        break;
                }
                return {
                    style: style,
                };
            },
            entityStyleFn: function (entity) {
                switch (entity.getType()) {
                    case VARIABLE_RENDERER_TYPE:
                        return {
                            element: "Variable",
                            attributes: entity.getData(),
                        };
                    case "PAGE_BREAK":
                        return {
                            element: "PageBreak",
                        };
                    case "SIGNATURE": {
                        var data = entity.getData();
                        var attributes = __rest(data, []);
                        return {
                            element: "SignatureBlock",
                            attributes: attributes,
                        };
                    }
                    case "IMAGE": {
                        var data = entity.getData();
                        var attributes = __rest(data, []);
                        return {
                            element: "ImageBlock",
                            attributes: attributes,
                        };
                    }
                    case CONDITION_RENDERER_TYPE: {
                        var data = entity.getData();
                        var instancePosition = data.instancePosition, attributes = __rest(data, ["instancePosition"]);
                        if (instancePosition === "LEADING") {
                            return {
                                element: "ConditionLeading",
                                attributes: attributes,
                            };
                        }
                        return {
                            element: "ConditionTrailing",
                        };
                    }
                    case INDEX_COUNT_RENDERER_TYPE: {
                        var data = entity.getData();
                        var attributes = __rest(data, []);
                        return {
                            element: "IndexCount",
                            attributes: attributes,
                        };
                    }
                    case LOOP_RENDERER_TYPE: {
                        var data = entity.getData();
                        var instancePosition = data.instancePosition, attributes = __rest(data, ["instancePosition"]);
                        if (instancePosition === "LEADING") {
                            return {
                                element: "LoopLeading",
                                attributes: attributes,
                            };
                        }
                        return {
                            element: "LoopTrailing",
                        };
                    }
                    default:
                        break;
                }
                return undefined;
            },
        });
        // Line breaks
        result = result.replaceAll("<br>", "<br/>");
        // Variable
        result = result.replaceAll(/<Variable(.*?)>.+?<\/Variable>/g, "<Variable $1/>");
        // Atomic block fixes
        result = result.replaceAll(/<figure.*?>/g, "");
        result = result.replaceAll("</figure>", "");
        // Page breaks
        if (result.includes("PageBreak")) {
            result = result.replaceAll(/<PageBreak(.*?)>.+?<\/PageBreak>/g, "</Page><Page>");
            result = "<Page>".concat(result, "</Page>");
        }
        // Loop
        result = result.replaceAll(/<LoopLeading(.*?)>.+?<\/LoopLeading>/g, "<Loop $1>");
        result = result.replaceAll(/<LoopTrailing>.+?<\/LoopTrailing>/g, "</Loop>");
        // Condition
        result = result.replaceAll(/<ConditionLeading(.*?)>.+?<\/ConditionLeading>/g, "<Condition $1>");
        result = result.replaceAll(/<ConditionTrailing>.+?<\/ConditionTrailing>/g, "</Condition>");
        // Index count
        var count = 0;
        result = result.replaceAll(/<IndexCount id="default">(.+?)<\/IndexCount>/g, function () {
            count += 1;
            return "<IndexCount key=\"".concat(count, "\" id=\"default\" indexCount=\"").concat(count, "\" />");
        });
        return result;
    }, [editorState, documentConfig]);
    // Render
    return (_jsxs(S.Wrap, { children: [_jsx(S.InnerWrap, { isPrint: isPrint, children: _jsx(S.JsxWrap, { isPrint: isPrint, children: _jsx(CustomJsxParserMemo, { jsx: jsx }) }) }), (!isPrint || !isTemplateDocumentExternal) && (_jsx(ToolbarFooter, { onPressUpdate: onPressUpdate, onPressSave: onPressSave, canEdit: canEdit }))] }));
};
// eslint-disable-next-line import/no-default-export
export default EditorPreview;
