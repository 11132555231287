import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
// Context
export var DocumentConfigContext = React.createContext({
    documentConfig: {},
    setDocumentConfig: function (newValue) { },
});
// Provider
export var DocumentConfigProvider = function (props) {
    var children = props.children;
    // State
    var _a = React.useState(props.documentConfig), documentConfig = _a[0], setDocumentConfig = _a[1];
    // Render
    return (_jsx(DocumentConfigContext.Provider, { value: {
            documentConfig: documentConfig,
            setDocumentConfig: setDocumentConfig,
        }, children: children }));
};
