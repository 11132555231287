var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useSelf } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as contactApi from "@rentiohq/shared-frontend/dist/redux/contact/contact.api";
import { REDUCER_KEY_CONTACT, } from "@rentiohq/shared-frontend/dist/redux/contact/contact.reducer";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { EContactType, isContact, } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { createImperativePromise } from "awesome-imperative-promise";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import { useSelector } from "react-redux";
import { Icon, TextStyle } from "../../components";
import { AutocompleteField } from "../../components/AutocompleteField";
import { ts } from "../../services";
import { AddContactModal } from "../AddContactModal";
import { ContactConnectionIndicator } from "../ContactConnectionIndicator";
import * as S from "./ContactSearch.styled";
export var ContactSearch = function (_a) {
    var error = _a.error, errorMessageHidden = _a.errorMessageHidden, optional = _a.optional, _b = _a.autoFocus, autoFocus = _b === void 0 ? true : _b, _c = _a.allowCreate, allowCreate = _c === void 0 ? true : _c, _d = _a.disallowedContactIds, disallowedContactIds = _d === void 0 ? [] : _d, _e = _a.disallowedAccountIds, disallowedAccountIds = _e === void 0 ? [] : _e, onContactSelect = _a.onContactSelect, onAccountSelect = _a.onAccountSelect, _f = _a.includeSupport, includeSupport = _f === void 0 ? false : _f, prefilledAddress = _a.prefilledAddress, initialType = _a.initialType, _g = _a.includeEmployees, includeEmployees = _g === void 0 ? false : _g, props = __rest(_a, ["error", "errorMessageHidden", "optional", "autoFocus", "allowCreate", "disallowedContactIds", "disallowedAccountIds", "onContactSelect", "onAccountSelect", "includeSupport", "prefilledAddress", "initialType", "includeEmployees"]);
    var employees = brokerHooks.useGetBrokerEmployees().employees;
    var broker = useSelf().broker;
    var label = props.label ||
        (allowCreate
            ? ts.contactSelectSearchOrAddLabel()
            : ts.contactSelectSearchLabel());
    var _h = React.useState(0), addedContacts = _h[0], setAddedContacts = _h[1];
    var _j = React.useState(), createContactType = _j[0], setCreateContactType = _j[1];
    var isSearchingContacts = useSelector(function (state) { return state.contact.isSearchingContacts; });
    var masterContact = useSelector(function (state) {
        return contactSelectors.getContactMeMaster(state);
    });
    var filterSelectedAccounts = React.useCallback(function (account) { return !disallowedAccountIds.includes(account.id); }, [disallowedAccountIds]);
    var filterSelectedContacts = React.useCallback(function (contact) {
        var isDisallowed = contact.accountIds.some(function (accountId) {
            return disallowedAccountIds.includes(accountId);
        });
        if (disallowedContactIds.includes(contact.id) ||
            disallowedAccountIds.includes(contact.accountId) ||
            isDisallowed) {
            return false;
        }
        return true;
    }, [disallowedContactIds, disallowedAccountIds]);
    if (isSearchingContacts) {
        return null;
    }
    var getExtraFilterData = function () {
        var filterData = { limit: 30 };
        var contactTypes = [EContactType.Normal];
        if (includeSupport) {
            contactTypes.push(EContactType.Support);
        }
        filterData.where = {
            or: [{ type: { eq: null } }, { type: { inq: contactTypes } }],
        };
        return filterData;
    };
    // Debounced search functions
    var globalSearch = function (query) {
        if (query === void 0) { query = ""; }
        return createImperativePromise(
        // TODO: Change to REDUX
        query === ""
            ? undefined
            : contactApi.searchContacts(query, getExtraFilterData()));
    };
    var debouncedSearch = AwesomeDebouncePromise(globalSearch, 200);
    // Event handlers
    var handleSearch = function (query) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, promise, cancel, contactsSearch, matchesCurrentUser, contactList, contactsListItems, employeeListItems, err_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, debouncedSearch(query)];
                case 1:
                    _a = _b.sent(), promise = _a.promise, cancel = _a.cancel;
                    _b.label = 2;
                case 2:
                    _b.trys.push([2, 4, , 5]);
                    if (!query && query === "") {
                        cancel();
                        return [2 /*return*/, []];
                    }
                    return [4 /*yield*/, promise];
                case 3:
                    contactsSearch = (_b.sent()).data;
                    matchesCurrentUser = (masterContact &&
                        Object.values(masterContact)
                            .filter(function (value) { return typeof value === "string"; })
                            .find(function (value) {
                            var hasResult = value && value.toLowerCase().indexOf(query.toLowerCase()) >= 0;
                            return hasResult;
                        })) ||
                        getName(masterContact).toLowerCase().indexOf(query.toLowerCase()) >= 0;
                    contactList = [];
                    if (matchesCurrentUser) {
                        contactList = __spreadArray(__spreadArray([], contactList, true), [masterContact], false);
                    }
                    if (contactsSearch) {
                        contactList = __spreadArray(__spreadArray([], contactList, true), contactsSearch, true);
                    }
                    contactsListItems = contactList === null || contactList === void 0 ? void 0 : contactList.filter(filterSelectedContacts).map(function (contact) {
                        var contactMetaData = [];
                        if (contact.phone) {
                            var formattedPhone = formatPhone(contact.phone);
                            if (formattedPhone) {
                                contactMetaData.push(formattedPhone);
                            }
                        }
                        if (contact.email) {
                            contactMetaData.push(contact.email);
                        }
                        return {
                            data: contact,
                            content: (_jsxs(_Fragment, { children: [_jsx("div", { children: getName(contact) }), _jsxs(TextStyle, { variation: "subdued", size: "small", children: [_jsx(S.TooltipWrapper, { children: _jsx(ContactConnectionIndicator, { contact: contact }) }), !isEmpty(contactMetaData) && contactMetaData.join(" | ")] })] })),
                            media: contact.isCompany ? (_jsx(Icon, { source: "briefcase" })) : (_jsx(Icon, { source: "profile" })),
                        };
                    });
                    if (!includeEmployees)
                        return [2 /*return*/, contactsListItems];
                    employeeListItems = employees
                        .filter(filterSelectedAccounts)
                        .map(function (employee) { return ({
                        data: employee,
                        content: (_jsxs(_Fragment, { children: [_jsx("div", { children: getName(employee) }), _jsx(TextStyle, { variation: "subdued", size: "small", children: broker === null || broker === void 0 ? void 0 : broker.name })] })),
                        media: _jsx(Icon, { source: "profile" }),
                    }); });
                    return [2 /*return*/, __spreadArray(__spreadArray([], contactsListItems, true), employeeListItems.filter(function (_a) {
                            var _b, _c;
                            var data = _a.data;
                            var lowerCaseQuery = query.toLowerCase();
                            return (((_b = data.lastname) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(lowerCaseQuery)) ||
                                ((_c = data.firstname) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes(lowerCaseQuery)));
                        }), true)];
                case 4:
                    err_1 = _b.sent();
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleSelectContact = function (contactOrEmployee) {
        setAddedContacts(function (prev) { return prev + 1; });
        if (!onAccountSelect) {
            onContactSelect(contactOrEmployee);
            return;
        }
        if (isContact(contactOrEmployee)) {
            onContactSelect(contactOrEmployee);
        }
        else {
            onAccountSelect(contactOrEmployee);
        }
    };
    var handleCreateContact = function (contactType) { return function () {
        setCreateContactType(contactType);
    }; };
    var handleModalClose = function () {
        setCreateContactType(undefined);
    };
    var handleAddedNewContact = function (contact) {
        setAddedContacts(function (prev) { return prev + 1; });
        onContactSelect(contact);
    };
    return (_jsxs("div", { children: [_jsx(AutocompleteField, { clearAfterChange: true, 
                // label={label}
                placeholder: label, name: "property-contact-search", loadOptions: handleSearch, onChange: handleSelectContact, error: error, errorMessageHidden: errorMessageHidden, optional: optional, autoFocus: autoFocus, renderId: addedContacts, createOptions: allowCreate
                    ? [
                        {
                            content: ts.contactSelectAddNewContact(),
                            onClick: handleCreateContact("contact"),
                            media: _jsx(Icon, { source: "add", size: "medium" }),
                        },
                        {
                            content: ts.contactSelectAddNewCompany(),
                            onClick: handleCreateContact("company"),
                            media: _jsx(Icon, { source: "add", size: "medium" }),
                        },
                    ]
                    : undefined }), !!createContactType && (_jsx(AddContactModal, { type: createContactType, initialType: initialType, prefilledAddress: prefilledAddress, onClose: handleModalClose, onSubmit: handleAddedNewContact }))] }));
};
