import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as costCenterHooks from "@rentiohq/shared-frontend/dist/reduxV2/costCenter/costCenter.hooks";
import { ICostCenter } from "@rentiohq/shared-frontend/dist/reduxV2/costCenter/costCenter.types";
import { getPropertyTypeString } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Banner,
  Card,
  DataTable,
  EmptyState,
  Error,
  IDataTableItem,
  Loading,
  mapItemsToDataTableProps,
} from "@rentiohq/web-shared/dist/components";
import { useParams } from "react-router-dom";

interface IProps {
  asCard?: boolean;
}

export const ContactCostCenters = (props: IProps) => {
  const { asCard = true } = props;

  const params = useParams<{ contactId: string }>();
  const contactId = params.contactId ? +params.contactId : undefined;

  const { contactMeMaster } = authHooks.useSelf();

  // If no contact assume me master
  const { items, isFetching, fetchError } = costCenterHooks.usePaged({
    // TODO: Replace actual me-master ID with just me-master (doesn't work yet in new API)
    customPath: `/contacts/${contactId || contactMeMaster?.id}/cost-centers`,
    query: {
      page: 1,
      limit: 100,
      sort: [{ field: "propertyTypeId", method: "ASC" }],
    },
  });

  const dataTableItems: IDataTableItem<ICostCenter>[] = [
    {
      heading: getLocalizedText("system.entity.cost_center"),
      columnContentType: "text",
      sortable: false,
      order: undefined,
      renderContent: (item: ICostCenter) => item.name,
    },
    {
      heading: getLocalizedText("properties.type"),
      columnContentType: "text",
      sortable: false,
      order: undefined,
      renderContent: (item: ICostCenter) =>
        getPropertyTypeString({ typeId: item.propertyTypeId }),
    },
  ];
  const dataTableItemsMapped = mapItemsToDataTableProps(dataTableItems);

  const content = (
    <>
      {isFetching && !items && <Loading />}

      {!isFetching && !items && fetchError && <Error errors={[fetchError]} />}

      {items && (
        <>
          <Banner
            variation="info"
            title={getLocalizedText("cost_center.info.title")}
            hasDismiss={false}
          >
            {getLocalizedText("cost_center.info.content")}
          </Banner>

          <DataTable
            rows={items.map(item => ({
              id: item.id,
              content: dataTableItems.map(dataTableItem =>
                dataTableItem.renderContent(item),
              ),
            }))}
            {...dataTableItemsMapped}
            emptyState={
              <EmptyState
                heading={getLocalizedText("cost_centers.empty.title")}
              >
                {getLocalizedText("cost_center.empty.content")}
              </EmptyState>
            }
          />
        </>
      )}

      {/* {items?.map(item => (
        <div key={item.id}>
          {item.name}

          {getPropertyTypeString({ typeId: item.propertyTypeId })}
        </div>
      ))} */}
    </>
  );

  if (asCard) {
    return <Card>{content}</Card>;
  }

  return content;
};
