import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  EPropertyTypeId,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { EField, IExtraData } from "./schema.editPropertyExtraInfo.types";

export const getInitialValues = ({ property }: IExtraData): IValues => {
  return {
    // EPC Fields
    [EEpcField.Label]: property.epcLabel,
    [EEpcField.Value]: property.epcValue,
    [EEpcField.ExpirationDate]: property.epcExpirationDate,

    // Bookkeeping
    [EField.CostUnit]: property.costUnit,
    [EField.BookkeepingPaidOutStartDate]: property.bookkeepingPaidOutStartDate,

    // Bookkeeping Group
    [EField.CostCenter]: property.costCenter,

    // Electricity
    [EField.ElectricityType]: property.electricityType,
    [EField.ElectricityEan]: property.electricityEan,
    [EField.ElectricityMeterNumberBoth]: property.electricityMeterNumberBoth,
    [EField.ElectricityMeterType]: property.electricityMeterType,

    // Natural Gas
    [EField.NaturalGasAvailable]: property.naturalGasAvailable,
    [EField.NaturalGasEan]: property.naturalGasEan,
    [EField.NaturalGasMeterNumber]: property.naturalGasMeterNumber,
    [EField.NaturalGasMeterType]: property.naturalGasMeterType,

    // Water
    [EField.WaterMeterNumber]: property.waterMeterNumber,

    // Physical Info
    [EField.BuildingSurfaceArea]: property.buildingSurfaceArea,
    [EField.BuildingFloor]: property.buildingFloor,
    [EField.BuildingFloorCount]: property.buildingFloorCount,
    [EField.BuildingAdjoined]: property.buildingAdjoined,
    [EField.RoomCountBedroom]: property.roomCountBedroom,
    [EField.RoomCountBathroom]: property.roomCountBathroom,
    [EField.IsFurnished]: property.isFurnished ? "yes" : "no",
  };
};

export const propertyTypeCanHaveFloors = (params: { property: IProperty }) => {
  const { property } = params;
  const { typeId } = property;

  switch (typeId) {
    case EPropertyTypeId.Apartment:
    case EPropertyTypeId.StudentResidence:
    case EPropertyTypeId.Commercial:
    case EPropertyTypeId.ParkingLot:
    case EPropertyTypeId.Garage:
    case EPropertyTypeId.Office:
      return true;

    case EPropertyTypeId.Group:
    case EPropertyTypeId.House:
    case EPropertyTypeId.Warehouse:
    case EPropertyTypeId.Shed:
    case EPropertyTypeId.Storage:
      return false;
  }
};

export const propertyTypeCanHaveAdjoinedType = (params: {
  property: IProperty;
}) => {
  const { property } = params;
  const { typeId } = property;

  switch (typeId) {
    case EPropertyTypeId.House:
    case EPropertyTypeId.Commercial:
    case EPropertyTypeId.Garage:
    case EPropertyTypeId.Office:
    case EPropertyTypeId.Warehouse:
    case EPropertyTypeId.Shed:
    case EPropertyTypeId.Storage:
      return true;

    case EPropertyTypeId.Group:
    case EPropertyTypeId.Apartment:
    case EPropertyTypeId.StudentResidence:
    case EPropertyTypeId.ParkingLot:
      return false;
  }
};
