import * as paymentGroupHooks from "@rentiohq/shared-frontend/dist/reduxV2/paymentGroup/paymentGroup.hooks";
import { EPaymentGroupStatus } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Banner } from "@rentiohq/web-shared/dist/components";

interface IProps {
  paymentGroupId: number;
}

export const PaymentGroupErrorBanner = (props: IProps) => {
  const { paymentGroupId } = props;

  const paymentGroup = paymentGroupHooks.useDetail({
    id: paymentGroupId,
    shouldRefetch: true,
  });

  // const { status, repetitionType } = paymentGroup.detail || {};
  if (!paymentGroup.detail) {
    return null;
  }

  if (
    ![
      EPaymentGroupStatus.ValidationError,
      EPaymentGroupStatus.ChargeRequestError,
      EPaymentGroupStatus.Empty,
      EPaymentGroupStatus.Stopped,
      EPaymentGroupStatus.Expired,
      EPaymentGroupStatus.Error,
    ].includes(paymentGroup.detail.status)
  ) {
    return null;
  }

  return (
    <Banner
      title={getLocalizedText("payment_group.error_banner.title")}
      variation="warning"
      hasDismiss={false}
    >
      {getLocalizedText("payment_group.error_banner.info")}
    </Banner>
  );
};
