// Rent deposits
export var ERentDepositStage;
(function (ERentDepositStage) {
    ERentDepositStage["New"] = "new";
    ERentDepositStage["PayingIn"] = "paying_in";
    ERentDepositStage["Established"] = "established";
    ERentDepositStage["SigningOut"] = "signing_out";
    ERentDepositStage["PayingOut"] = "paying_out";
})(ERentDepositStage || (ERentDepositStage = {}));
export var ERentDepositStageSimple;
(function (ERentDepositStageSimple) {
    ERentDepositStageSimple["In"] = "in";
    ERentDepositStageSimple["Established"] = "established";
    ERentDepositStageSimple["Out"] = "out";
})(ERentDepositStageSimple || (ERentDepositStageSimple = {}));
export var ERentDepositPaymentStatus;
(function (ERentDepositPaymentStatus) {
    ERentDepositPaymentStatus["NotPaid"] = "not_paid";
    ERentDepositPaymentStatus["Paid"] = "paid";
})(ERentDepositPaymentStatus || (ERentDepositPaymentStatus = {}));
export var ERentDepositSignatureStatus;
(function (ERentDepositSignatureStatus) {
    ERentDepositSignatureStatus["NotSigned"] = "not_signed";
    ERentDepositSignatureStatus["Signed"] = "signed";
    ERentDepositSignatureStatus["JudicialRelease"] = "judicial_release";
})(ERentDepositSignatureStatus || (ERentDepositSignatureStatus = {}));
// Document packages
export var EDocumentPackageStageSimple;
(function (EDocumentPackageStageSimple) {
    EDocumentPackageStageSimple["Pending"] = "pending";
    EDocumentPackageStageSimple["Finished"] = "finished";
    EDocumentPackageStageSimple["Failed"] = "failed";
    EDocumentPackageStageSimple["Revoked"] = "revoked";
})(EDocumentPackageStageSimple || (EDocumentPackageStageSimple = {}));
