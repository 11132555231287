var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { merge } from "lodash";
import { all, put, takeEvery } from "redux-saga/effects";
import logger from "../../logger";
import * as documentApi from "../../reduxV2/documents/document.api";
import { EDocumentCategory } from "../../types/document.types";
import { generateSaga } from "../generators/generator.saga";
import { createWithDocumentsFailed, createWithDocumentsStart, createWithoutDocumentsStart, updateWithDocumentsStart, updateWithoutDocumentsFailed, updateWithoutDocumentsStart, } from "./legalCase.actions";
import { GENERATOR_PARAMS } from "./legalCase.constants";
var uploadNewDocument = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var file, contract, categoryId, presignedUrl, metaData, document;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                file = params.file, contract = params.contract, categoryId = params.categoryId;
                return [4 /*yield*/, documentApi.uploadDocumentToS3(file)];
            case 1:
                presignedUrl = _a.sent();
                metaData = {
                    categoryId: categoryId,
                    filename: file.name,
                    propertyId: contract.propertyId,
                    contractId: contract.id,
                };
                return [4 /*yield*/, documentApi.uploadDocument(presignedUrl, metaData)];
            case 2:
                document = _a.sent();
                return [2 /*return*/, document];
        }
    });
}); };
var filterAndGetUploadObject = function (params) {
    var _a = params.collection, collection = _a === void 0 ? [] : _a, contract = params.contract, categoryId = params.categoryId;
    return (collection
        .filter(function (doc) { return typeof doc !== "string"; })
        .map(function (doc) {
        return uploadNewDocument({
            contract: contract,
            categoryId: categoryId,
            file: doc,
        });
    }) || []);
};
function createLegalCaseWithDocumentsStartFlow(action) {
    var _a, contract, manualContract, manualProofOfRegistration, manualRentalDeposit, manualPropertyInspections, damageDocuments, extraDocuments, registeredLetters, damageInfo, extraInfo, contractSigningPackageId, manualPayments, rentRegistrationId, payments, rentDepositId, onFailure, onSuccess, _b, uploadedContractDocuments, uploadedProofOfRegistrationDocuments, uploadedRentalDepositDocuments, uploadedPropertyInspections, damageDocs, extraDocs, uploadedRegisteredLetters, newManualPayments, manualPaymentsWithDocumentIds, result, unknownError_1, error;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = action.payload, contract = _a.contract, manualContract = _a.manualContract, manualProofOfRegistration = _a.manualProofOfRegistration, manualRentalDeposit = _a.manualRentalDeposit, manualPropertyInspections = _a.manualPropertyInspections, damageDocuments = _a.damageDocuments, extraDocuments = _a.extraDocuments, registeredLetters = _a.registeredLetters, damageInfo = _a.damageInfo, extraInfo = _a.extraInfo, contractSigningPackageId = _a.contractSigningPackageId, manualPayments = _a.manualPayments, rentRegistrationId = _a.rentRegistrationId, payments = _a.payments, rentDepositId = _a.rentDepositId, onFailure = _a.onFailure, onSuccess = _a.onSuccess;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 6]);
                return [4 /*yield*/, all({
                        uploadedContractDocuments: all(filterAndGetUploadObject({
                            collection: manualContract,
                            contract: contract,
                            categoryId: EDocumentCategory.Contract,
                        })),
                        uploadedProofOfRegistrationDocuments: all(filterAndGetUploadObject({
                            collection: manualProofOfRegistration,
                            contract: contract,
                            categoryId: EDocumentCategory.RegistrationProofContract,
                        })),
                        uploadedRentalDepositDocuments: all(filterAndGetUploadObject({
                            collection: manualRentalDeposit,
                            contract: contract,
                            categoryId: EDocumentCategory.RentDeposit,
                        })),
                        uploadedPropertyInspections: all(filterAndGetUploadObject({
                            collection: manualPropertyInspections,
                            contract: contract,
                            categoryId: EDocumentCategory.LocationDescription,
                        })),
                        damageDocs: all(filterAndGetUploadObject({
                            collection: damageDocuments,
                            contract: contract,
                            categoryId: EDocumentCategory.Attachment,
                        })),
                        extraDocs: all(filterAndGetUploadObject({
                            collection: extraDocuments,
                            contract: contract,
                            categoryId: EDocumentCategory.Attachment,
                        })),
                        uploadedRegisteredLetters: all(filterAndGetUploadObject({
                            collection: registeredLetters,
                            contract: contract,
                            categoryId: EDocumentCategory.RegisteredLetter,
                        })),
                        newManualPayments: manualPayments
                            ? all(Object.entries(manualPayments).reduce(function (finalObject, _a) {
                                var _b;
                                var key = _a[0], manualPayment = _a[1];
                                return (__assign(__assign({}, finalObject), (_b = {}, _b[key] = all(filterAndGetUploadObject({
                                    collection: manualPayment.documents,
                                    contract: contract,
                                    categoryId: EDocumentCategory.Attachment,
                                })), _b)));
                            }, {}))
                            : undefined,
                    })];
            case 2:
                _b = _c.sent(), uploadedContractDocuments = _b.uploadedContractDocuments, uploadedProofOfRegistrationDocuments = _b.uploadedProofOfRegistrationDocuments, uploadedRentalDepositDocuments = _b.uploadedRentalDepositDocuments, uploadedPropertyInspections = _b.uploadedPropertyInspections, damageDocs = _b.damageDocs, extraDocs = _b.extraDocs, uploadedRegisteredLetters = _b.uploadedRegisteredLetters, newManualPayments = _b.newManualPayments;
                manualPaymentsWithDocumentIds = Object.entries(newManualPayments || {}).reduce(function (final, _a) {
                    var _b;
                    var key = _a[0], payments = _a[1];
                    return __assign(__assign({}, final), (_b = {}, _b[key] = { documentIds: payments.map(function (doc) { return doc.id; }) }, _b));
                }, {});
                result = merge(manualPayments, manualPaymentsWithDocumentIds);
                Object.values(result).forEach(function (payment) {
                    delete payment.documents;
                });
                return [4 /*yield*/, put(createWithoutDocumentsStart.getAction({
                        data: {
                            contractId: contract.id,
                            propertyId: contract.propertyId,
                            contractSigningPackageId: contractSigningPackageId,
                            rentDepositId: rentDepositId,
                            rentRegistrationId: rentRegistrationId,
                            damageInfo: damageInfo,
                            extraInfo: extraInfo,
                            payments: payments || [],
                            manualPayments: Object.values(result),
                            manualContracts: __spreadArray(__spreadArray([], uploadedContractDocuments.map(function (d) { return d.id; }), true), (manualContract || []).filter(function (doc) {
                                return typeof doc === "string" && doc !== contract.id;
                            }), true),
                            manualPropertyInspectionReports: __spreadArray(__spreadArray([], uploadedPropertyInspections.map(function (d) { return d.id; }), true), (manualPropertyInspections || []).filter(function (doc) { return typeof doc === "string"; }), true),
                            manualRentDeposits: __spreadArray(__spreadArray([], uploadedRentalDepositDocuments.map(function (d) { return d.id; }), true), (manualRentalDeposit || []).filter(function (doc) { return typeof doc === "string"; }), true),
                            manualRentRegistrations: __spreadArray(__spreadArray([], uploadedProofOfRegistrationDocuments.map(function (d) { return d.id; }), true), (manualProofOfRegistration || []).filter(function (doc) { return typeof doc === "string"; }), true),
                            damageDocumentIds: damageDocs.map(function (d) { return d.id; }),
                            extraDocumentIds: __spreadArray(__spreadArray([], (extraDocs || []), true), (uploadedRegisteredLetters || []), true).map(function (d) { return d.id; }),
                        },
                        onSuccess: onSuccess,
                        onFailure: onFailure,
                    }))];
            case 3:
                _c.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_1 = _c.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(createWithDocumentsFailed.getAction({ error: error }))];
            case 5:
                _c.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function updateLegalCaseWithDocumentsStartFlow(action) {
    var _a, legalCase, manualContract, manualProofOfRegistration, manualRentalDeposit, manualPropertyInspections, manualProofOfPropertyInspections, damageDocuments, extraDocuments, registeredLetters, damageInfo, extraInfo, previousManualPayments, manualPayments, payments, contract, onFailure, onSuccess, _b, uploadedContractDocuments, uploadedProofOfRegistrationDocuments, uploadedRentalDepositDocuments, uploadedPropertyInspections, 
    // uploadedProofOfPropertyInspections,
    damageDocs, extraDocs, uploadedRegisteredLetters, newManualPaymentDocs, updatedPreviousPaymentDocs, manualPaymentsWithDocumentIds, updatedPreviousManualPaymentsWithDocumentIds, allPayments, manualContracts, manualRentDeposits, manualRentRegistrations, manualPropertyInspectionReports, unknownError_2, error;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = action.payload, legalCase = _a.legalCase, manualContract = _a.manualContract, manualProofOfRegistration = _a.manualProofOfRegistration, manualRentalDeposit = _a.manualRentalDeposit, manualPropertyInspections = _a.manualPropertyInspections, manualProofOfPropertyInspections = _a.manualProofOfPropertyInspections, damageDocuments = _a.damageDocuments, extraDocuments = _a.extraDocuments, registeredLetters = _a.registeredLetters, damageInfo = _a.damageInfo, extraInfo = _a.extraInfo, previousManualPayments = _a.previousManualPayments, manualPayments = _a.manualPayments, payments = _a.payments, contract = _a.contract, onFailure = _a.onFailure, onSuccess = _a.onSuccess;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 6]);
                return [4 /*yield*/, all({
                        uploadedContractDocuments: all(filterAndGetUploadObject({
                            collection: manualContract,
                            contract: contract,
                            categoryId: EDocumentCategory.Contract,
                        })),
                        uploadedProofOfRegistrationDocuments: all(filterAndGetUploadObject({
                            collection: manualProofOfRegistration,
                            contract: contract,
                            categoryId: EDocumentCategory.RegistrationProofContract,
                        })),
                        uploadedRentalDepositDocuments: all(filterAndGetUploadObject({
                            collection: manualRentalDeposit,
                            contract: contract,
                            categoryId: EDocumentCategory.RentDeposit,
                        })),
                        uploadedPropertyInspections: all(filterAndGetUploadObject({
                            collection: manualPropertyInspections,
                            contract: contract,
                            categoryId: EDocumentCategory.LocationDescription,
                        })),
                        uploadedProofOfPropertyInspections: all(filterAndGetUploadObject({
                            collection: manualProofOfPropertyInspections,
                            contract: contract,
                            categoryId: EDocumentCategory.RegistrationProofLocationDescription,
                        })),
                        damageDocs: all(filterAndGetUploadObject({
                            collection: damageDocuments,
                            contract: contract,
                            categoryId: EDocumentCategory.Attachment,
                        })),
                        extraDocs: all(filterAndGetUploadObject({
                            collection: extraDocuments,
                            contract: contract,
                            categoryId: EDocumentCategory.Attachment,
                        })),
                        uploadedRegisteredLetters: all(filterAndGetUploadObject({
                            collection: registeredLetters,
                            contract: contract,
                            categoryId: EDocumentCategory.RegisteredLetter,
                        })),
                        newManualPaymentDocs: manualPayments
                            ? all(Object.entries(manualPayments).reduce(function (finalObject, _a) {
                                var _b;
                                var key = _a[0], manualPayment = _a[1];
                                return (__assign(__assign({}, finalObject), (_b = {}, _b[key] = all(filterAndGetUploadObject({
                                    collection: manualPayment.documents,
                                    contract: contract,
                                    categoryId: EDocumentCategory.Attachment,
                                })), _b)));
                            }, {}))
                            : undefined,
                        updatedPreviousPaymentDocs: previousManualPayments
                            ? all(
                            // Upload all the documents for each manual payment
                            Object.entries(previousManualPayments).reduce(function (finalObject, _a) {
                                var _b;
                                var key = _a[0], manualPayment = _a[1];
                                return __assign(__assign({}, finalObject), (_b = {}, _b[key] = all(filterAndGetUploadObject({
                                    collection: manualPayment.documents,
                                    contract: contract,
                                    categoryId: EDocumentCategory.Attachment,
                                })), _b));
                            }, {}))
                            : undefined,
                    })];
            case 2:
                _b = _c.sent(), uploadedContractDocuments = _b.uploadedContractDocuments, uploadedProofOfRegistrationDocuments = _b.uploadedProofOfRegistrationDocuments, uploadedRentalDepositDocuments = _b.uploadedRentalDepositDocuments, uploadedPropertyInspections = _b.uploadedPropertyInspections, damageDocs = _b.damageDocs, extraDocs = _b.extraDocs, uploadedRegisteredLetters = _b.uploadedRegisteredLetters, newManualPaymentDocs = _b.newManualPaymentDocs, updatedPreviousPaymentDocs = _b.updatedPreviousPaymentDocs;
                manualPaymentsWithDocumentIds = Object.entries(newManualPaymentDocs || {}).reduce(function (final, _a) {
                    var _b;
                    var key = _a[0], payments = _a[1];
                    var correspondingManualPayment;
                    if (manualPayments) {
                        correspondingManualPayment = manualPayments[key];
                    }
                    return __assign(__assign({}, final), (_b = {}, _b[key] = __assign(__assign({}, correspondingManualPayment), { documentIds: payments.map(function (doc) { return doc.id; }) }), _b));
                }, {});
                updatedPreviousManualPaymentsWithDocumentIds = Object.entries(updatedPreviousPaymentDocs || {}).reduce(function (final, _a) {
                    var _b;
                    var key = _a[0], payments = _a[1];
                    var correspondingPreviousManualPayment;
                    if (previousManualPayments) {
                        correspondingPreviousManualPayment = previousManualPayments[key];
                    }
                    return __assign(__assign({}, final), (_b = {}, _b[key] = __assign(__assign({}, correspondingPreviousManualPayment), { documentIds: __spreadArray(__spreadArray([], payments.map(function (doc) { return doc.id; }), true), (correspondingPreviousManualPayment
                            ? correspondingPreviousManualPayment === null || correspondingPreviousManualPayment === void 0 ? void 0 : correspondingPreviousManualPayment.documents.filter(function (d) { return typeof d === "string"; })
                            : []), true) }), _b));
                }, {});
                allPayments = merge(manualPaymentsWithDocumentIds, updatedPreviousManualPaymentsWithDocumentIds);
                // Remove all documents as they can't be in the request
                if (allPayments) {
                    Object.values(allPayments).forEach(function (payment) {
                        delete payment.documents;
                    });
                }
                manualContracts = uploadedContractDocuments
                    ? __spreadArray(__spreadArray([], uploadedContractDocuments.map(function (d) { return d.id; }), true), legalCase.manualContracts, true).filter(function (doc) { return typeof doc === "string" && doc !== contract.id; })
                    : undefined;
                manualRentDeposits = uploadedRentalDepositDocuments
                    ? __spreadArray(__spreadArray([], uploadedRentalDepositDocuments.map(function (d) { return d.id; }), true), legalCase.manualRentDeposits, true) : undefined;
                manualRentRegistrations = uploadedProofOfRegistrationDocuments
                    ? __spreadArray(__spreadArray([], uploadedProofOfRegistrationDocuments.map(function (d) { return d.id; }), true), legalCase.manualRentRegistrations, true) : undefined;
                manualPropertyInspectionReports = uploadedPropertyInspections
                    ? __spreadArray(__spreadArray([], uploadedPropertyInspections.map(function (d) { return d.id; }), true), legalCase.manualPropertyInspectionReports, true) : undefined;
                // TODO: We still need to add the manualProofOfPropertyInspections to the API
                // const manualProofOfPropertyInspectionReports =
                //   uploadedProofOfPropertyInspections
                //     ? [
                //         ...uploadedProofOfPropertyInspections.map(d => d.id),
                //         // TODO: add the previous ones
                //         // TODO: do the same stuff but for creation
                //       ]
                //     : undefined;
                return [4 /*yield*/, put(updateWithoutDocumentsStart.getAction({
                        id: legalCase.id,
                        data: {
                            damageInfo: damageInfo,
                            extraInfo: extraInfo,
                            manualContracts: manualContracts,
                            payments: payments,
                            manualPayments: Object.values(allPayments),
                            manualPropertyInspectionReports: manualPropertyInspectionReports,
                            manualRentDeposits: manualRentDeposits,
                            manualRentRegistrations: manualRentRegistrations,
                            //Proof of property inspection reports
                            damageDocumentIds: damageDocs
                                ? __spreadArray(__spreadArray([], damageDocs.map(function (d) { return d.id; }), true), legalCase.damageDocumentIds, true) : undefined,
                            extraDocumentIds: extraDocs || uploadedRegisteredLetters
                                ? __spreadArray(__spreadArray(__spreadArray([], (extraDocs || []).map(function (d) { return d.id; }), true), (uploadedRegisteredLetters || []).map(function (d) { return d.id; }), true), legalCase.extraDocumentIds, true) : undefined,
                        },
                        onSuccess: onSuccess,
                        onFailure: onFailure,
                    }))];
            case 3:
                // TODO: We still need to add the manualProofOfPropertyInspections to the API
                // const manualProofOfPropertyInspectionReports =
                //   uploadedProofOfPropertyInspections
                //     ? [
                //         ...uploadedProofOfPropertyInspections.map(d => d.id),
                //         // TODO: add the previous ones
                //         // TODO: do the same stuff but for creation
                //       ]
                //     : undefined;
                _c.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_2 = _c.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(updateWithoutDocumentsFailed.getAction({ id: legalCase.id, error: error }))];
            case 5:
                _c.sent();
                onFailure === null || onFailure === void 0 ? void 0 : onFailure(error);
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeEvery(createWithDocumentsStart.type, createLegalCaseWithDocumentsStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeEvery(updateWithDocumentsStart.type, updateLegalCaseWithDocumentsStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, generateSaga(GENERATOR_PARAMS)];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
