var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { generateReducer, } from "../generators/generator.reducer";
import { getPaymentLinkFailed, getPaymentLinkStart, getPaymentLinkSucceeded, grantAccessPaymentRequestFailed, grantAccessPaymentRequestStart, grantAccessPaymentRequestSucceeded, markPaidFailed, markPaidStart, markPaidSucceeded, } from "./paymentRequest.actions";
import { GENERATOR_PARAMS } from "./paymentRequest.constants";
var getExtraDefaultState = function () { return ({
    markPaid: {},
    paymentLink: {},
    grantAccess: {},
}); };
var _reducer = generateReducer(GENERATOR_PARAMS, getExtraDefaultState);
var reducer = _reducer;
reducer.on(markPaidStart.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { markPaid: __assign(__assign({}, state.markPaid), (_a = {}, _a[payload.id] = {
            isMarkingPaid: true,
            markPaidError: undefined,
        }, _a)) });
});
reducer.on(markPaidSucceeded.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { markPaid: __assign(__assign({}, state.markPaid), (_a = {}, _a[payload.id] = {
            isMarkingPaid: false,
            markPaidError: undefined,
        }, _a)) });
});
reducer.on(markPaidFailed.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { markPaid: __assign(__assign({}, state.markPaid), (_a = {}, _a[payload.id] = {
            isMarkingPaid: false,
            markPaidError: payload.error,
        }, _a)) });
});
reducer.on(getPaymentLinkStart.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { paymentLink: __assign(__assign({}, state.paymentLink), (_a = {}, _a[payload.id] = {
            isFetching: true,
        }, _a)) });
});
reducer.on(getPaymentLinkSucceeded.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { paymentLink: __assign(__assign({}, state.paymentLink), (_a = {}, _a[payload.id] = {
            isFetching: true,
            url: payload === null || payload === void 0 ? void 0 : payload.result,
        }, _a)) });
});
reducer.on(getPaymentLinkFailed.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { paymentLink: __assign(__assign({}, state.paymentLink), (_a = {}, _a[payload.id] = {
            isFetching: false,
            fetchError: payload.error,
        }, _a)) });
});
reducer.on(grantAccessPaymentRequestStart.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { grantAccess: __assign(__assign({}, state.grantAccess), (_a = {}, _a[payload.id] = {
            isFetching: true,
            fetchError: undefined,
        }, _a)) });
});
reducer.on(grantAccessPaymentRequestSucceeded.type, function (state, payload) {
    var _a, _b;
    return __assign(__assign({}, state), { detail: __assign(__assign({}, state.detail), (_a = {}, _a[payload.id] = {
            pending: false,
            entity: payload === null || payload === void 0 ? void 0 : payload.result,
            error: undefined,
        }, _a)), grantAccess: __assign(__assign({}, state.grantAccess), (_b = {}, _b[payload.id] = {
            isFetching: false,
        }, _b)) });
});
reducer.on(grantAccessPaymentRequestFailed.type, function (state, payload) {
    var _a;
    return __assign(__assign({}, state), { grantAccess: __assign(__assign({}, state.grantAccess), (_a = {}, _a[payload.id] = {
            isFetching: false,
            fetchError: payload.error,
        }, _a)) });
});
// eslint-disable-next-line import/no-default-export
export default reducer;
