import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import { JsonForm } from "@rentiohq/web-shared/dist/components";
import { contact as contactSchemas } from "@rentiohq/web-shared/dist/jsonSchemas";
import { IJsonSchemaProps } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import { useDispatch } from "react-redux";
import { trimAllValuesInObject } from "../profile.util";

const transformSchema = (schema: IJsonSchemaProps) => {
  const nationality = {
    title: "system.nationality",
    type: ["string", "null"],
    action: "GET_ALL_NATIONALITY_ISO",
    ui: { "ui:field": "asyncSelect" },
  };

  schema.properties.nationality = nationality;
};

export const ProfileExtra = () => {
  const { user } = authHooks.useSelf();

  const dispatch = useDispatch();

  if (!user) {
    return null;
  }

  const schema = contactSchemas.defaultContactExtraSchema;
  const profileData = utils.object.removeEmpty(user);
  transformSchema(schema);

  const {
    personalContact: contact = {
      dateOfBirth: "",
      placeOfBirth: "",
      countryOfBirth: "",
    },
  } = profileData;
  const { dateOfBirth, placeOfBirth, countryOfBirth, nationality } = contact;
  const initialData = {
    nationality,
    dateOfBirth,
    dateAndPlaceOfBirth: {
      city: placeOfBirth,
      countryId: countryOfBirth,
    },
  };

  const handleSubmit = async (data: any) => {
    const {
      dateAndPlaceOfBirth: { city: placeOfBirth, countryId: countryOfBirth },
      ...rest
    } = data;

    const input = {
      placeOfBirth,
      countryOfBirth,
      ...rest,
    };

    dispatch(
      contactActions.updateContact.actions.start({
        contactId: user.personalContactId!,
        contact: trimAllValuesInObject(input),
      }),
    );
  };

  return (
    <JsonForm
      id={"profile-extra"}
      schema={schema}
      onSubmit={handleSubmit}
      initialData={initialData}
      autoSave={false}
    />
  );
};
