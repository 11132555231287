import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { ISuccessResponse } from "@rentiohq/shared-frontend/dist/reduxV2/utils/api.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { formatDateAndTime } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  Loading,
  Lozenge,
  Modal,
  Stack,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import React from "react";
import {
  ECreditNoteStatus,
  EInvoiceStatus,
  IExternalInvoice,
} from "./externalInvoice.types";

interface IProps {
  externalInvoiceId: string;
  onClose: () => void;
}

export const ExternalInvoiceDetailModal = (props: IProps) => {
  const { externalInvoiceId, onClose } = props;

  const [externalInvoice, setExternalInvoice] =
    React.useState<IExternalInvoice>();
  const [isCreatingInvoice, setIsCreatingInvoice] = React.useState(false);
  const [isCreatingCreditNote, setIsCreatingCreditNote] = React.useState(false);

  // Data
  const fetchData = async () => {
    try {
      const response = await api.get<ISuccessResponse<IExternalInvoice>>(
        `/external-invoice/${externalInvoiceId}`,
        {
          isV2: true,
        },
      );

      setExternalInvoice(response.data.data);
    } catch (error) {
      showAlert({
        error,
        type: "error",
      });

      onClose();
    }
  };

  // Lifecycle
  React.useEffect(() => {
    fetchData();
  }, [externalInvoiceId]);

  // Event handlers
  const handleCreateInvoiceWithConfirm = () => {
    confirm({
      title: getLocalizedText("revenues.create_invoice.confirm.title"),
      primaryActions: [
        {
          title: getLocalizedText("revenues.create_invoice.cta.without_email"),
          onPress: () => {
            handleCreateInvoice({
              automaticallySendToClient: false,
            });
          },
        },
        {
          title: getLocalizedText("revenues.create_invoice.cta.with_email"),
          onPress: () => {
            handleCreateInvoice({
              automaticallySendToClient: true,
            });
          },
        },
      ],
    });
  };

  const handleCreateInvoice = async (params: {
    automaticallySendToClient: boolean;
  }) => {
    const { automaticallySendToClient } = params;

    try {
      setIsCreatingInvoice(true);

      await api.post(
        `/external-invoice/${externalInvoiceId}/retry-create-invoice`,
        {
          isV2: true,
          data: {
            automaticallySendToClient,
          },
        },
      );

      await fetchData();

      showAlert({
        type: "success",
        message: getLocalizedText("revenues.create_invoice.success.title"),
        content: getLocalizedText("revenues.create_invoice.success.message"),
      });

      setIsCreatingInvoice(false);
    } catch (error) {
      setIsCreatingInvoice(false);

      showAlert({ error, type: "error" });
    }
  };

  const handleCreateCreditNoteWithConfirm = () => {
    confirm({
      title: getLocalizedText("revenues.create_credit_note.confirm.title"),
      primaryActions: [
        {
          title: getLocalizedText(
            "revenues.create_credit_note.cta.without_email",
          ),
          onPress: () => {
            handleCreateCreditNote({
              automaticallySendToClient: false,
            });
          },
        },
        {
          title: getLocalizedText("revenues.create_credit_note.cta.with_email"),
          onPress: () => {
            handleCreateCreditNote({
              automaticallySendToClient: true,
            });
          },
        },
      ],
    });
  };

  const handleCreateCreditNote = async (params: {
    automaticallySendToClient: boolean;
  }) => {
    const { automaticallySendToClient } = params;
    try {
      setIsCreatingCreditNote(true);

      await api.post(`/external-invoice/${externalInvoiceId}/credit-invoice`, {
        isV2: true,
        data: {
          automaticallySendToClient,
        },
      });

      await fetchData();

      showAlert({
        type: "success",
        message: getLocalizedText("revenues.create_credit_note.success.title"),
        content: getLocalizedText(
          "revenues.create_credit_note.success.message",
        ),
      });

      setIsCreatingCreditNote(false);
    } catch (error) {
      setIsCreatingCreditNote(false);

      showAlert({ error, type: "error" });
    }
  };

  const getActions = () => {
    const result: IAction[] = [];
    if (!externalInvoice) {
      return result;
    }

    if (externalInvoice.externalInvoiceUrl) {
      result.push({
        content: getLocalizedText(
          "external_invoice.modal.actions.view_invoice",
        ),
        onClick: () => {
          window.open(externalInvoice.externalInvoiceUrl, "_blank");
        },
        appearance: "primary",
      });
    }

    if (externalInvoice.externalCreditNoteUrl) {
      result.push({
        content: getLocalizedText(
          "external_invoice.modal.actions.view_credit_note",
        ),
        onClick: () => {
          window.open(externalInvoice.externalCreditNoteUrl, "_blank");
        },
        appearance: "outline",
      });
    }

    if (externalInvoice.statusInvoice === EInvoiceStatus.Failed) {
      result.push({
        content: getLocalizedText(
          "external_invoice.modal.actions.retry_invoice_create",
        ),
        onClick: handleCreateInvoiceWithConfirm,
        appearance: "outline",
      });
    }

    if (externalInvoice.statusCreditNote === ECreditNoteStatus.Failed) {
      result.push({
        content: getLocalizedText(
          "external_invoice.modal.actions.retry_credit_node_create",
        ),
        onClick: handleCreateCreditNoteWithConfirm,
        appearance: "outline",
      });
    }

    return result;
  };

  // Render
  const renderContent = () => {
    if (!externalInvoice || isCreatingCreditNote || isCreatingInvoice) {
      return <Loading asDots={true} />;
    }

    const items = [];

    // Errors
    const errors: string[] = [];
    if (externalInvoice?.statusInvoice === EInvoiceStatus.Failed) {
      errors.push(getLocalizedText("external_invoice.invoice.status.failed"));
    }

    if (externalInvoice?.statusCreditNote === ECreditNoteStatus.Failed) {
      errors.push(
        getLocalizedText("external_invoice.credit_note.status.failed"),
      );
    }

    if (errors.length > 0) {
      items.push(
        <Stack spacing="tight">
          {errors.map(error => (
            <Lozenge appearance="error" key={error}>
              {error}
            </Lozenge>
          ))}
        </Stack>,
      );
    }

    // Invoice
    if (externalInvoice.externalInvoiceId) {
      items.push(
        <TextStyle>
          {getLocalizedText("external_invoice.modal.info.message.invoice", {
            createdAt: formatDateAndTime(externalInvoice.createdAt),
            externalId: `${externalInvoice.externalInvoiceId}`,
          })}
        </TextStyle>,
      );
    } else if (externalInvoice.statusInvoice === EInvoiceStatus.New) {
      items.push(
        <TextStyle>
          {getLocalizedText(
            "external_invoice.modal.info.message.invoice_pending",
          )}
        </TextStyle>,
      );

      items.push(
        <Button onClick={fetchData} appearance="link">
          {getLocalizedText("system.reload")}
        </Button>,
      );
    }

    // Credit note
    if (externalInvoice?.externalCreditNoteId) {
      items.push(
        <TextStyle variation="subdued">
          {getLocalizedText("external_invoice.modal.info.message.credited", {
            externalId: `${externalInvoice.externalCreditNoteId}`,
          })}
        </TextStyle>,
      );
    } else if (externalInvoice.statusCreditNote === ECreditNoteStatus.New) {
      items.push(
        <TextStyle>
          {getLocalizedText(
            "external_invoice.modal.info.message.credit_note_pending",
          )}
        </TextStyle>,
      );

      items.push(
        <Button onClick={fetchData} appearance="link">
          {getLocalizedText("system.reload")}
        </Button>,
      );
    } else if (
      externalInvoice.statusInvoice === EInvoiceStatus.Generated &&
      externalInvoice.statusCreditNote === ECreditNoteStatus.None
    ) {
      items.push(
        <Button
          onClick={handleCreateCreditNoteWithConfirm}
          isDisabled={isCreatingCreditNote}
          appearance="link"
          color="yellow"
        >
          {getLocalizedText(
            "external_invoice.modal.actions.create_credit_note",
          )}
        </Button>,
      );
    }

    return items.map((item, index) => (
      <div>
        {index > 0 && <Spacer weight={ESpacerWeight.W12} />}
        {item}
      </div>
    ));
  };

  return (
    <>
      <Modal
        width="medium"
        heading={getLocalizedText("external_invoice.modal.info.title")}
        onClose={onClose}
        hasDismiss={true}
        shouldCloseOnOverlayClick={true}
        actions={getActions()}
      >
        {renderContent()}
      </Modal>
    </>
  );
};
