import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { Stack } from "../Stack";
import { Stage } from "./Stage";
import * as S from "./Stages.styled";
export var Stages = function (_a) {
    var stages = _a.stages, stageStyle = _a.stageStyle;
    return (_jsx(S.Wrapper, { children: _jsx(Stack, { distribution: "fillEvenly", children: stages.map(function (stage, index) {
                var stageComponent = (_jsx(Stage, { stage: stage, index: index, isLast: stages.length - 1 === index, stageStyle: stageStyle }));
                if (stage.url) {
                    return (_jsx(S.StyledLink, { to: stage.url, children: stageComponent }, "stage-".concat(stage.heading, "-").concat(index)));
                }
                return (_jsx(React.Fragment, { children: stageComponent }, "stage-".concat(stage.heading, "-").concat(index)));
            }) }) }));
};
