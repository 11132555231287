export const ActivityTextStyles = () => {
  return {
    wrapper: {
      display: "flex",
      columnGap: "4px",
      fontSize: "14px",
      flexWrap: "wrap",
    },
    content: {
      clickAbleContent: {
        fontSize: "14px",
      },
    },
  };
};
