import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { Page } from "@rentiohq/web-shared/dist/components";
import { useParams } from "react-router-dom";
import { BooleanParam, NumberParam } from "serialize-query-params";
import * as t from "../../../../services/translationService";
import { TaskForm } from "./TaskForm";

export const TaskFormContainer = () => {
  const { user } = authHooks.useSelf();

  const params = useParams<{ taskId: string }>();

  const [queryParams] = useQueryParams({
    propertyId: NumberParam,
    keypoint: BooleanParam,
    groupActions: BooleanParam,
  });

  const taskId = params.taskId ? +params.taskId : undefined;
  const isKeypoint = queryParams.keypoint ?? false;
  const isGroupActions = queryParams.groupActions ?? false;

  const getTitle = () => {
    if (isKeypoint) {
      return taskId ? t.repairEdit() : t.repairNew();
    }

    return taskId ? t.tasksEdit() : t.taskNew();
  };

  if (!user) {
    return null;
  }

  return (
    <Page
      title={getTitle()}
      breadcrumbs={
        taskId
          ? { to: `/tasks/${taskId}`, content: t.tasksOverview() }
          : { to: "/tasks", content: t.tasksOverview() }
      }
    >
      <TaskForm
        taskId={taskId}
        propertyId={queryParams.propertyId}
        isKeypoint={isKeypoint}
        isGroupActions={isGroupActions}
      />
    </Page>
  );
};
