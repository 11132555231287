export var EPaymentOrderRole;
(function (EPaymentOrderRole) {
    EPaymentOrderRole["Read"] = "READ";
    EPaymentOrderRole["Write"] = "WRITE";
})(EPaymentOrderRole || (EPaymentOrderRole = {}));
export var EPaymentOrderType;
(function (EPaymentOrderType) {
    // Recurring
    EPaymentOrderType["RentAndCosts"] = "RENT_AND_COSTS";
    EPaymentOrderType["Rent"] = "RENT";
    EPaymentOrderType["CommonCosts"] = "COMMON_COSTS";
    EPaymentOrderType["NonCommonCosts"] = "NON_COMMON_COSTS";
    EPaymentOrderType["ManagementCosts"] = "MANAGEMENT_COSTS";
    EPaymentOrderType["Rentio"] = "RENTIO";
    EPaymentOrderType["OtherRecurring"] = "OTHER_RECURRING";
    // Non recurring
    EPaymentOrderType["RentDeposit"] = "RENT_DEPOSIT";
    EPaymentOrderType["LocationDescription"] = "LOCATION_DESCRIPTION";
    EPaymentOrderType["BrokerFee"] = "BROKER_FEE";
    EPaymentOrderType["OtherOneoff"] = "OTHER_ONEOFF";
    // Non creatable
    EPaymentOrderType["RentDepositPayout"] = "RENT_DEPOSIT_PAYOUT";
    EPaymentOrderType["RentDiscount"] = "RENT_DISCOUNT";
})(EPaymentOrderType || (EPaymentOrderType = {}));
export var EPaymentOrderOwnerPaymentMethod;
(function (EPaymentOrderOwnerPaymentMethod) {
    EPaymentOrderOwnerPaymentMethod["FromRent"] = "FROM_RENT";
    EPaymentOrderOwnerPaymentMethod["Direct"] = "DIRECT";
})(EPaymentOrderOwnerPaymentMethod || (EPaymentOrderOwnerPaymentMethod = {}));
export var EPaymentRepetitionType;
(function (EPaymentRepetitionType) {
    EPaymentRepetitionType["Once"] = "ONCE";
    EPaymentRepetitionType["Monthly"] = "MONTHLY";
    EPaymentRepetitionType["Yearly"] = "YEARLY";
    EPaymentRepetitionType["Quarterly"] = "QUARTERLY";
})(EPaymentRepetitionType || (EPaymentRepetitionType = {}));
var EPaymentFeatures;
(function (EPaymentFeatures) {
    EPaymentFeatures["BancontactBankWireOnly"] = "BANCONTACT_BANKWIRE_ONLY";
    EPaymentFeatures["BancontactIbanisationOnly"] = "BANCONTACT_IBANISATION_ONLY";
    EPaymentFeatures["BancontactOnly"] = "BANCONTACT_ONLY";
    EPaymentFeatures["BlockedPayment"] = "BLOCKED_PAYMENT";
    EPaymentFeatures["BlockedPayout"] = "BLOCKED_PAYOUT";
    EPaymentFeatures["NoNotifications"] = "NO_NOTIFICATIONS";
    EPaymentFeatures["RentDeposit"] = "RENT_DEPOSIT";
})(EPaymentFeatures || (EPaymentFeatures = {}));
export var EPaymentGroupStatus;
(function (EPaymentGroupStatus) {
    EPaymentGroupStatus["New"] = "NEW";
    EPaymentGroupStatus["Active"] = "ACTIVE";
    EPaymentGroupStatus["Paused"] = "PAUSED";
    EPaymentGroupStatus["Stopped"] = "STOPPED";
    EPaymentGroupStatus["Ended"] = "ENDED";
    EPaymentGroupStatus["Expired"] = "EXPIRED";
    EPaymentGroupStatus["Deleted"] = "DELETED";
    EPaymentGroupStatus["Archived"] = "ARCHIVED";
    EPaymentGroupStatus["Error"] = "ERROR";
    EPaymentGroupStatus["ValidationError"] = "VALIDATION_ERROR";
    EPaymentGroupStatus["Empty"] = "EMPTY";
    // cspell:ignore chargerequest
    EPaymentGroupStatus["ChargeRequestError"] = "CHARGEREQUEST_ERROR";
})(EPaymentGroupStatus || (EPaymentGroupStatus = {}));
export var EPaymentMethod;
(function (EPaymentMethod) {
    EPaymentMethod["Sepa"] = "sepa";
    EPaymentMethod["Ibanisation"] = "ibanisation";
    EPaymentMethod["BankWire"] = "bankwire";
    EPaymentMethod["Bancontact"] = "bancontact";
    EPaymentMethod["Payconiq"] = "payconiq";
    EPaymentMethod["Ideal"] = "ideal";
})(EPaymentMethod || (EPaymentMethod = {}));
export var EPayoutType;
(function (EPayoutType) {
    EPayoutType["Charge"] = "CHARGE";
    EPayoutType["Payout"] = "PAYOUT";
})(EPayoutType || (EPayoutType = {}));
export var EExternalBookkeepingType;
(function (EExternalBookkeepingType) {
    EExternalBookkeepingType["EasyInvoicing"] = "EF";
})(EExternalBookkeepingType || (EExternalBookkeepingType = {}));
export var ETransferType;
(function (ETransferType) {
    ETransferType["TransferPayout"] = "TRANSFER_PAYOUT";
    ETransferType["TransferAdministrative"] = "TRANSFER_ADMINISTRATIVE";
    ETransferType["TransferReverseAdministrative"] = "TRANSFER_REVERSE_ADMINISTRATIVE";
    ETransferType["Payout"] = "PAYOUT";
})(ETransferType || (ETransferType = {}));
export var ETransactionStatus;
(function (ETransactionStatus) {
    ETransactionStatus["Success"] = "success";
    // cSpell:ignore transfered
    ETransactionStatus["Transferred"] = "transfered";
    ETransactionStatus["Skipped"] = "skipped";
    ETransactionStatus["New"] = "new";
    ETransactionStatus["Paid"] = "paid";
})(ETransactionStatus || (ETransactionStatus = {}));
export var EMissingKYCStatus;
(function (EMissingKYCStatus) {
    EMissingKYCStatus["None"] = "none";
    EMissingKYCStatus["New"] = "new";
    EMissingKYCStatus["Requested"] = "requested";
    EMissingKYCStatus["Failed"] = "failed";
    EMissingKYCStatus["Approved"] = "approved";
})(EMissingKYCStatus || (EMissingKYCStatus = {}));
export var EMangoPayKycFailedStatus;
(function (EMangoPayKycFailedStatus) {
    EMangoPayKycFailedStatus["DocumentUnreadable"] = "DOCUMENT_UNREADABLE";
    EMangoPayKycFailedStatus["DocumentNotAccepted"] = "DOCUMENT_NOT_ACCEPTED";
    EMangoPayKycFailedStatus["DocumentHasExpired"] = "DOCUMENT_HAS_EXPIRED";
    EMangoPayKycFailedStatus["DocumentIncomplete"] = "DOCUMENT_INCOMPLETE";
    EMangoPayKycFailedStatus["DocumentDoNotMatchUserData"] = "DOCUMENT_DO_NOT_MATCH_USER_DATA";
    EMangoPayKycFailedStatus["DocumentDoNotMatchAccountData"] = "DOCUMENT_DO_NOT_MATCH_ACCOUNT_DATA";
    EMangoPayKycFailedStatus["DocumentFalsified"] = "DOCUMENT_FALSIFIED";
    EMangoPayKycFailedStatus["UnderagePerson"] = "UNDERAGE_PERSON";
    EMangoPayKycFailedStatus["SpecificCase"] = "SPECIFIC_CASE";
})(EMangoPayKycFailedStatus || (EMangoPayKycFailedStatus = {}));
