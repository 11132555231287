import { ACTIVITY_SUBTYPES } from "@rentiohq/shared-frontend/dist/types/activity.types";
import { IRentDeposit } from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";

export const getRentDepositActivityFilter = (rentDeposit: IRentDeposit) => {
  const {
    id: rentDepositId,
    paymentOrder,
    rentDepositContractIn,
    rentDepositContractOut,
  } = rentDeposit;
  return {
    or: [
      { rentDepositId },
      paymentOrder && {
        paymentOrderId: paymentOrder.id,
        subtype: {
          inq: [
            ACTIVITY_SUBTYPES.PAYMENT.ORDER_CREATED,
            ACTIVITY_SUBTYPES.PAYMENT.ORDER_REMOVED,
            ACTIVITY_SUBTYPES.PAYMENT.ORDER_RECURRING_MANDATE_REMOVED,
          ],
        },
      },
      paymentOrder?.paymentRequests && {
        paymentRequestId: {
          inq: paymentOrder.paymentRequests.map(
            paymentRequest => paymentRequest.id,
          ),
        },
      },
      !!rentDepositContractIn && {
        contractId: rentDepositContractIn.id,
        subtype: {
          inq: [
            ACTIVITY_SUBTYPES.CONTRACT.CREATED,
            ACTIVITY_SUBTYPES.CONTRACT.SIGNED,
          ],
        },
      },
      !!rentDepositContractIn?.documentPackages && {
        documentPackageId: {
          inq: rentDepositContractIn.documentPackages.map(
            documentPackage => documentPackage.id,
          ),
        },
      },
      !!rentDepositContractOut && {
        contractId: rentDepositContractOut.id,
        subtype: {
          inq: [
            ACTIVITY_SUBTYPES.CONTRACT.CREATED,
            ACTIVITY_SUBTYPES.CONTRACT.SIGNED,
          ],
        },
      },
      !!rentDepositContractOut?.documentPackages && {
        documentPackageId: {
          inq: rentDepositContractOut.documentPackages.map(
            documentPackage => documentPackage.id,
          ),
        },
      },
    ].filter(Boolean),
  };
};
