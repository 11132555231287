var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { appendFilter } from "../../utils/api.utils";
import api from "../../utils/api/api.utils";
var FIELDS_LIST = {
    data: false,
    fields: false,
    variablesData: false,
    documentConfig: false,
    documentIds: false,
    signerFields: false,
};
var FIELDS_DETAIL = {
    data: true,
    fields: true,
    variablesData: true,
    documentConfig: true,
    documentIds: true,
    signerFields: true,
};
export var FETCH_LIMIT = 20;
export var createTemplateDocument = function (params) {
    return api.post("/template-documents", {
        data: params.data,
    });
};
export var updateTemplateDocument = function (params) {
    return api.put(appendFilter("/template-documents/".concat(params.id), {
        fields: FIELDS_LIST,
    }), {
        data: params.data,
    });
};
export var deleteTemplateDocument = function (params) {
    return api.delete("/template-documents/".concat(params.id));
};
export var getTemplateDocument = function (params) {
    return api.get(appendFilter("/template-documents/".concat(params.id), {
        fields: FIELDS_LIST,
    }));
};
export var getTemplateDocumentDetail = function (params) {
    return api.get(appendFilter("/template-documents/".concat(params.id), { fields: FIELDS_DETAIL }));
};
export var getTemplateDocuments = function (params) {
    var skip = params.skip, _a = params.limit, limit = _a === void 0 ? FETCH_LIMIT : _a, filter = params.filter;
    var filterFinal = __assign({ order: ["createdAt DESC"], skip: skip, limit: limit, fields: FIELDS_LIST }, filter);
    return api.get(appendFilter("/template-documents", filterFinal));
};
export var generateTemplateDocumentPdf = function (params) {
    return api.post("/template-documents/".concat(params.id, "/generate-document"), { data: params.data });
};
