import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import * as documentApi from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.api";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import { EDocumentCustomFilter } from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.types";
import * as documentUtils from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.utils";
import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  SHOW_CONFIRM_MODAL,
  SHOW_LOADER,
} from "@rentiohq/web-shared-next/dist/redux/actions";
import { useMediaQuery, useTheme } from "@rentiohq/web-shared-next/dist/themes";
import {
  Box,
  Divider,
  ETagVariant,
  MainCard,
  NoDataCard,
  Pagination,
  Stack,
  TabPanel,
  Table,
  Tag,
  Typography,
  UploadField,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { enqueueSnackbar } from "@rentiohq/web-shared-next/dist/utils/snackbar";
import { EButtonVariants } from "@rentiohq/web-shared-next/dist/utils/types/button";
import { check } from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import uniqBy from "lodash/uniqBy";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { StringParam } from "serialize-query-params";
import { DocumentRow } from "../../../../../components/DocumentRow";
import { CreateDocumentModal } from "../../../../../components/Modals/CreateDocumentModal";
import { EPropertyDetailsTabs } from "../../propertyDetails.types";

const columns = [
  {
    columnTitle: "system.name",
  },
  {
    columnTitle: "system.type",
  },
  {
    columnTitle: "system.date.upload",
  },
  {
    columnTitle: "documents.members.header.title",
  },
];

const multiple = false;
const asDropzone = true;

export const Documents = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const downLG = useMediaQuery(theme.breakpoints.down("lg"));

  const { user } = authHooks.useSelf();

  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const [, setQueryParamValue] = useQueryParams({
    documentPackageId: StringParam,
  });

  const [files, setFiles] = React.useState<File[]>([]);
  const [activePage, setActivePage] = React.useState<number>(1);
  const [showCreateDocModal, setShowCreateDocModal] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState<boolean>();

  const {
    items: documents = [],
    totalPages,
    fetchError: docsAPIError,
    refetch,
    isFetching,
  } = documentHooks.usePaged(
    documentUtils.getDocumentPagedQuery({
      page: activePage,
      extraFilterData: {
        propertyId: { eq: propertyId },
      },
      customFilters: [
        EDocumentCustomFilter.Active,
        EDocumentCustomFilter.ExcludeInternalDocuments,
      ],
    }),
  );

  React.useEffect(() => {
    if (docsAPIError?.message) {
      enqueueSnackbar(docsAPIError.message, { variant: "error" });
    }
  }, [docsAPIError]);

  React.useEffect(() => {
    dispatch({ type: SHOW_LOADER, payload: isFetching || isUploading });
  }, [isFetching, isUploading]);

  const handlePageClick = (page: number) => setActivePage(page);

  const handleDelete = (document: IDocument) => {
    dispatch(documentActions.removeStart.getAction({ id: document.id }));
    handleCloseConfirmModal();
  };

  const handleCloseConfirmModal = () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: { showConfirmModal: false },
    });
  };

  const handleShowConfirmModal = (document: IDocument) => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        title: getLocalizedText("document.alert.delete_confirm.message"),
        description: document?.filename,
        showConfirmModal: true,
        actions: [
          {
            label: getLocalizedText("system.cancel"),
            onClick: handleCloseConfirmModal,
            variant: EButtonVariants.Outlined,
          },
          {
            label: getLocalizedText("system.remove"),
            onClick: () => handleDelete(document),
          },
        ],
      },
    });
  };

  const getActions = (document: IDocument) => {
    const { url, downloadUrl, documentPackageId, roles } = document;
    const canDeleteDocument = check(roles, "document:delete");

    const actions = [];

    if (url) {
      actions.push({
        label: "document.view",
        onClick: () => utils.document.viewDocument(document),
      });
    }

    if (downloadUrl) {
      actions.push({
        label: "document.download",
        onClick: () => (window.location.href = downloadUrl),
      });
    }

    if (Boolean(documentPackageId)) {
      actions.push({
        label: "document_package.view.action",
        onClick: () =>
          setQueryParamValue({
            documentPackageId,
          }),
      });
    }

    if (canDeleteDocument) {
      actions.push({
        label: "document.delete",
        onClick: () => handleShowConfirmModal(document),
      });
    }

    return actions;
  };

  const getRows = (documents: IDocument[]) => {
    if (documents?.length === 0) {
      return [];
    }

    return documents.map(document => {
      const { id, filename, categoryId, members, createdAt } = document;

      return {
        id,
        actions: getActions(document),
        content: [
          filename,
          <Tag variant={ETagVariant.Default}>
            {documentUtils.getDocumentCategoryName(categoryId)}
          </Tag>,
          utils.date.format(createdAt),
          members.length > 1 ? (
            <Stack
              direction="row"
              divider={<div>,</div>}
              gap={0.2}
              flexWrap="wrap"
            >
              {members.map((member: any) => {
                return (
                  member.account.id !== user?.activeAccountId && (
                    <Typography
                      variant="subtitle2"
                      key={member.account.id}
                      whiteSpace="nowrap"
                    >
                      {getName(member.account)}
                    </Typography>
                  )
                );
              })}
            </Stack>
          ) : (
            "-"
          ),
        ],
      };
    });
  };

  const handleDrop = (acceptedFiles: any[]) => {
    if (acceptedFiles.length === 0) {
      return;
    }

    setFiles(acceptedFiles);
    toggleCreateDocModal();
  };

  const toggleCreateDocModal = () =>
    setShowCreateDocModal(prevState => !prevState);

  const handleClose = () => {
    toggleCreateDocModal();
    setFiles([]);
  };

  const handleAdd = async (values: any) => {
    try {
      setIsUploading(true);

      const uploads = (files || []).map(async (file: any) => {
        const meta = { propertyId, ...values };

        const preSignedUrl = await documentApi.uploadDocumentToS3(file);

        return documentApi.uploadDocument(preSignedUrl, meta);
      });

      const uploadedDocuments = await Promise.all(uploads);

      setFiles([]);

      if (uploadedDocuments) {
        enqueueSnackbar("Document uploaded successfully", {
          variant: "success",
        });
      }
      // setQueryParamValue({ documentId: uploadedDocuments[0].id });

      refetch();
    } catch (unknownError) {
      const error = unknownError as any;
      // @ts-ignore
      enqueueSnackbar(new Error(error as any), { variant: "error" });
    }

    setIsUploading(false);
  };

  const renderTable = () => {
    return (
      <Table
        columns={columns}
        dataRows={getRows(documents)}
        totalPages={totalPages}
        activePage={activePage}
        handlePageClick={handlePageClick}
      />
    );
  };

  const renderDocumentRows = () => {
    return (
      <Stack flexDirection="column" divider={<Divider />}>
        {documents.map(document => (
          <DocumentRow
            key={document.id}
            document={document}
            actions={getActions(document)}
          />
        ))}
        {(totalPages || 0) > 1 && (
          <Stack direction="row" justifyContent="center" mt={3}>
            <Pagination
              color="primary"
              page={activePage}
              count={totalPages}
              onChange={(_, page) => handlePageClick(page)}
            />
          </Stack>
        )}
      </Stack>
    );
  };

  return (
    <TabPanel
      value={EPropertyDetailsTabs.Documents}
      index={EPropertyDetailsTabs.Documents}
    >
      <MainCard
        title={getLocalizedText("property.detail.tabs.documents")}
        content={false}
      >
        {documents?.length > 0 && (
          <>{downLG ? renderDocumentRows() : renderTable()}</>
        )}
        {!isFetching && documents?.length === 0 && (
          <Box p={2}>
            <NoDataCard label={getLocalizedText("document.empty.info")} />
          </Box>
        )}
        <Box p={2}>
          <UploadField
            id="doc-files"
            value={files || []}
            asDropzone={asDropzone}
            multiple={multiple}
            onChange={(newFiles: any[]) => {
              if (multiple) {
                handleDrop(
                  uniqBy([...(files || []), ...newFiles], x => {
                    if (typeof x === "object") {
                      return x.name;
                    }
                    return x;
                  }),
                );
                return;
              }

              handleDrop(newFiles);
            }}
          />
        </Box>
      </MainCard>
      {files?.length > 0 && (
        <CreateDocumentModal
          openDialog={showCreateDocModal}
          handleClose={handleClose}
          handleAdd={handleAdd}
          file={files[0]}
        />
      )}
    </TabPanel>
  );
};
