var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var CameraContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n"], ["\n  position: relative;\n"])));
export var Label = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: 600;\n  font-size: 1.4rem;\n  margin-bottom: 16px;\n  color: ", ";\n"], ["\n  font-weight: 600;\n  font-size: 1.4rem;\n  margin-bottom: 16px;\n  color: ", ";\n"])), function (props) { return props.theme.colors.global2; });
export var VideoWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: ", ";\n  position: relative;\n  width: 100%;\n  min-height: 100px;\n  margin-bottom: 24px;\n  background-color: lightgray;\n"], ["\n  display: ", ";\n  position: relative;\n  width: 100%;\n  min-height: 100px;\n  margin-bottom: 24px;\n  background-color: lightgray;\n"])), function (props) { return (props.$hidden ? "none" : "block"); });
export var Video = styled.video(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  height: 100%;\n"], ["\n  width: 100%;\n  height: 100%;\n"])));
export var Canvas = styled.canvas(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: none;\n"], ["\n  display: none;\n"])));
export var Overlay = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  pointer-events: none;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  pointer-events: none;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var OverlayBorderWrap = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 100%;\n  max-width: 95%;\n  max-height: 95%;\n  aspect-ratio: 85.6/53.98;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  height: 100%;\n  max-width: 95%;\n  max-height: 95%;\n  aspect-ratio: 85.6/53.98;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
export var OverlayBorder = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  max-height: 100%;\n  aspect-ratio: 85.6/53.98;\n  border: 8px solid #17a680;\n  border-radius: 12px;\n"], ["\n  width: 100%;\n  max-height: 100%;\n  aspect-ratio: 85.6/53.98;\n  border: 8px solid #17a680;\n  border-radius: 12px;\n"])));
export var Image = styled.img(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  max-width: 100%;\n  max-height: 100%;\n  object-fit: contain;\n"], ["\n  max-width: 100%;\n  max-height: 100%;\n  object-fit: contain;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
