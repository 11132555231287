import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import { memo } from "react";
import { TruncateMiddle } from "../TruncateMiddle/TruncateMiddle";
import * as TS from "./AddressListItem.styled";
var AddressListItemBase = function (_a) {
    var propertyId = _a.propertyId, propertyAddress = _a.propertyAddress;
    return (_jsx(TS.FlexColumn, { children: propertyAddress && (_jsxs(Box, { style: { maxWidth: "190px" }, children: [propertyAddress.line1 && (_jsx(TruncateMiddle, { input: propertyAddress.line1, skipInitialChars: 8 })), propertyAddress.line2 && (_jsx(TS.GrayText, { children: _jsx(TruncateMiddle, { input: propertyAddress === null || propertyAddress === void 0 ? void 0 : propertyAddress.line2, skipInitialChars: 8 }) }))] })) }, propertyId !== null && propertyId !== void 0 ? propertyId : "".concat(propertyAddress === null || propertyAddress === void 0 ? void 0 : propertyAddress.line1, "+").concat(propertyAddress === null || propertyAddress === void 0 ? void 0 : propertyAddress.line2)));
};
export var AddressListItem = memo(AddressListItemBase);
