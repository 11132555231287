import { EField as EContactField } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.types";
import {
  IValues,
  getValue,
} from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { EDocumentPackageStatus } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { EPropertyTypeId } from "@rentiohq/shared-frontend/dist/types/property.types";
import { ERegistrationContractDestination } from "@rentiohq/shared-frontend/dist/types/registration.types";
import {
  getAllRequiredCompanyFields,
  getAllRequiredContactFields,
} from "@rentiohq/shared-frontend/dist/utils/forms.utils";
import { getStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import { orderBy } from "lodash";
import { EField, IExtraData } from "./schema.registration.types";

const contractLeaseType: { [key: string]: string } = {
  student_housing: ERegistrationContractDestination.Housing,
  commercial_lease: ERegistrationContractDestination.Commercial,
};

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { property } = extraData;
  let contractDestination =
    contractLeaseType[extraData.contract.leaseType] ??
    ERegistrationContractDestination.Housing;
  // if (property.typeId === EPropertyTypeId.StudentResidence)
  //   contractDestination = ERegistrationContractDestination.University;
  if (property.typeId === EPropertyTypeId.Commercial) {
    contractDestination = ERegistrationContractDestination.Commercial;
  }

  const initialValues = {
    [EField.RentContractDestination]: contractDestination,
    [EField.RentContractSignedAt]: new Date(),
    [EField.RentContractPropertyTaxPaidByTenant]: "yes",

    [EField.LocationDescriptionShouldUpload]: "now",

    [EField.LocationDescriptionSignedAt]: new Date(),
  };

  return initialValues;
};

export const getRegistrationCompanyFieldsSchema = (contact: IContact) =>
  getAllRequiredCompanyFields({
    contact,
    requiredFields: [],
  });

export const getRegistrationContactFieldsSchema = (contact: IContact) =>
  getAllRequiredContactFields({
    contact,
    requiredFields: [
      EContactField.Address,
      EContactField.DateOfBirth,
      EContactField.Nationality,
      EContactField.PlaceOfBirth,
    ],
    appendIdentityNumberToRequiredFields: true,
  });

export const getDocumentSignedAt = (params: {
  documentField: EField;
  signedAtField: EField;
  initialValues: { [key: string]: unknown };
  defaultValues: { [key: string]: unknown };
}) => {
  const { documentField, signedAtField, initialValues, defaultValues } = params;

  const documentIds = getValue(documentField, initialValues, defaultValues);
  const documentId = documentIds?.[0];

  if (typeof documentId === "string") {
    const store = getStore();
    if (store) {
      const state = store.getState();

      const documentPackage = Object.values(
        state.documentPackage.documentPackages,
      ).find(x => x.documentsInPackageIds.includes(documentId));

      if (documentPackage?.status === EDocumentPackageStatus.Finished) {
        // Find signer that last signed
        const lastSigner = orderBy(
          documentPackage.signers,
          ["completedAt"],
          "desc",
        )[0];
        return lastSigner.completedAt;
      }
    }
  }

  return getValue(signedAtField, initialValues, defaultValues);
};
