import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { MultiStepForm } from "@rentiohq/web-shared/dist/components";
import exportRevenuesSchemas from "forms/exportRevenues";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "forms/exportRevenues/schema.exportRevenues.actions";
import React from "react";
import { useNavigate } from "react-router-dom";

interface IProps {
  onClose: () => void;
}

export const ExportRevenues: React.FC<IProps> = props => {
  const { onClose } = props;

  const navigate = useNavigate();

  const { user, broker } = authHooks.useSelf();
  const activeAccountId = user?.activeAccountId;

  // Render
  const extraData = {
    broker,
    activeAccountId,
  };

  return (
    <MultiStepForm
      formId={"export-revenues"}
      schemas={exportRevenuesSchemas(extraData)}
      asModal={true}
      withAside={false}
      modalProps={{ onClose, shouldCloseOnOverlayClick: true, width: "medium" }}
      onSuccess={() => {
        navigate("/profile/export-files");
      }}
      isPerformingSubmitActionSelector={isPerformingSubmitActionSelector}
      performingSubmitActionResultSelector={
        performingSubmitActionResultSelector
      }
      performingSubmitActionErrorSelector={performingSubmitActionErrorSelector}
      submitActionCreator={submitActionCreator(extraData)}
      submitLabel={getLocalizedText("system.export")}
      getPrecommitConfirm={(params: {
        formData: any;
        onProceed: () => void;
      }) => {}}
    />
  );
};
