import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as contractTypes from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.types";
import * as contractUtils from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { Loading } from "@rentiohq/web-shared/dist/components";
import { ReactElement } from "react";

interface IProps {
  propertyId: number;
  renderContracts: (contracts: IContract[]) => ReactElement;
  renderLoading?: () => ReactElement;
}

const ContractsFetchListItem = (props: IProps) => {
  const { propertyId, renderContracts, renderLoading } = props;

  // Redux
  const filter = contractUtils.getFilterForProperty(
    propertyId,
    contractTypes.EContractFetchType.Active,
  );

  const { items: contracts, isFetching } = contractHooks.useGetAll({
    query: {
      filter,
    },
  });

  // Render
  if (contracts) {
    return renderContracts(contracts);
  }

  if (isFetching) {
    return renderLoading?.() || <Loading asDots />;
  }

  return null;
};

// eslint-disable-next-line import/no-default-export
export default ContractsFetchListItem;
