import { ERentioFormField } from "../../components/components/Form";
import { EPaymentOrderType } from "../../types/payment.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EField as EFieldCreatePayment } from "../createPayment/schema.createPayment.types";
import { customContactsValueSelector, getDefaultPayerPayeeValue, } from "../createPayment/schema.createPayment.utils";
import { EField } from "./schema.createContract.types";
import { customContactsSelectorPayments, getNavigationTitle, getSubtitlePayments, } from "./schema.createContract.utils";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return {
            required: [EField.PaymentOrderCreatePayee],
            properties: (_a = {},
                _a[EField.Header + "11"] = {
                    default: undefined,
                },
                _a[EField.PaymentOrderCreatePayee] = {
                    default: getDefaultPayerPayeeValue({
                        key: EFieldCreatePayment.Payee,
                        initialValues: initialValues,
                        defaultValues: defaultValues,
                        type: EPaymentOrderType.Rent,
                    }),
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {
            "rentio:title": getLocalizedText("contract_info.add.payment_orders.payee.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "11"] = {
            "ui:field": ERentioFormField.SectionHeader,
            getTitle: function () {
                return getLocalizedText("payments.create.payee.heading", {
                    type: getLocalizedText("payment.payment_order.type.".concat(EPaymentOrderType.Rent).toLowerCase()),
                });
            },
        },
        _a[EField.PaymentOrderCreatePayee] = {
            "ui:field": ERentioFormField.ContactSelectInlineV2,
            getTitle: function () {
                return getLocalizedText("payment.payment_order.payee.heading", {
                    type: getLocalizedText("payment.payment_order.type.".concat(EPaymentOrderType.Rent).toLowerCase()),
                });
            },
            selectContactFromListCtaTitle: getLocalizedText("payment.payment_order.payee.select_other.cta"),
            addCtaLabel: getLocalizedText("payment.payment_order.payee.other.label"),
            isMultiSelect: false,
            asOptionList: true,
            // Custom selectors & actions
            valueSelector: function (state, selectedAccountIds) {
                return customContactsValueSelector({
                    state: state,
                    propertyId: extraData.property.id,
                    selectedAccountIds: selectedAccountIds,
                });
            },
            customGetSubtitleSelector: function (state, formData, item) {
                if (formData === void 0) { formData = {}; }
                return getSubtitlePayments({
                    state: state,
                    property: extraData.property,
                    item: item,
                    customOwners: formData[EField.Owners],
                    customTenants: formData[EField.Tenants],
                    customParents: formData[EField.Parents],
                });
            },
            customExtraAccountsSelector: function (state, formData) {
                if (formData === void 0) { formData = {}; }
                return customContactsSelectorPayments({
                    payerType: "payee",
                    state: state,
                    property: extraData.property,
                    customOwners: formData[EField.Owners],
                    customTenants: formData[EField.Tenants],
                    customParents: formData[EField.Parents],
                });
            },
            customIsFetchingSelector: function (state) { return false; },
            customGetContactsActions: [],
            // customIsFetchingSelector: (state: IPartialRootState) =>
            //   // @ts-ignore
            //   customIsFetchingSelector(state, extraData),
            // customGetContactsActions:
            //   // @ts-ignore
            //   customGetContactsActions(extraData),
        },
        _a);
};
