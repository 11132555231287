import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { payInModule } from "@rentiohq/shared-frontend/dist/reduxV2/payin";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EPayInStatus,
  EPayInType,
} from "@rentiohq/shared-frontend/dist/types/payin.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { TextStyle } from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { memo, useMemo } from "react";

interface IProps {
  propertyId?: number;
  contractId?: string;
}

const getFilter = (v2: boolean) => {
  if (v2) {
    return {
      and: [
        { type: { eq: EPayInType.Ibanisation } },
        {
          status: {
            in: [EPayInStatus.MatchedPartially, EPayInStatus.Unmatched],
          },
        },
      ],
    };
  }

  return {
    and: [
      { type: EPayInType.Ibanisation },
      {
        status: {
          inq: [EPayInStatus.MatchedPartially, EPayInStatus.Unmatched],
        },
      },
    ],
  };
};

const IbanisationStatusInfoBase: React.FC<IProps> = ({
  propertyId,
  contractId,
}) => {
  const { internalModeEnabled } = useInternalMode();

  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  // Redux
  const { items } = payInModule.hooks.usePaged({
    query: {
      limit: 20,
      page: 1,
      filter: getFilter(internalModeEnabled && hasPaymentV2ForBroker),
    },
    customPath: propertyId ? `/properties/${propertyId}/pay-ins` : undefined,
    skipLegacy: internalModeEnabled && hasPaymentV2ForBroker,
  });

  const payInsForProperty = useMemo(() => {
    if (!items) return null;
    return items.filter(item => item.contractId === contractId);
  }, [items]);

  // Render
  if (!payInsForProperty) {
    return null;
  }

  if (payInsForProperty.length === 0) {
    return null;
  }

  return (
    <TextStyle element="div" variation="subdued">
      {getLocalizedText("payments.follow_up.has_unmatched_pay_ins")}
    </TextStyle>
  );
};

export const IbanisationStatusInfo = memo(IbanisationStatusInfoBase);
