var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Platform } from "react-native";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import logger from "../../logger";
import * as contractApiV2 from "../../reduxV2/contract/contract.api";
import * as documentApi from "../../reduxV2/documents/document.api";
import * as propertyActionsV2 from "../../reduxV2/property/property.actions";
import * as propertyApiV2 from "../../reduxV2/property/property.api";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralCreateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import * as contractActions from "../contract/contract.actions";
import * as contractApi from "../contract/contract.api";
import { takeLatestWithUniquePayload } from "../utils/saga.utils";
import * as rentDepositActions from "./rentDeposit.actions";
import * as rentDepositApi from "./rentDeposit.api";
import * as rentDepositSelectors from "./rentDeposit.selectors";
var DOCUMENT_RENT_DEPOSIT_PASSPORT_CATEGORY = 12;
function getRentDepositStartFlow(_a) {
    var rentDepositId, data, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                rentDepositId = payload.rentDepositId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(rentDepositApi.getRentDeposit, rentDepositId)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(rentDepositActions.getRentDeposit.actions.success({
                        rentDepositId: rentDepositId,
                        rentDeposit: data,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(rentDepositActions.getRentDeposit.actions.failure({
                        rentDepositId: rentDepositId,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getRentDepositsStartFlow(_a) {
    var identifier, refetch, extraFilterData, limit, rentDeposits, _b, _c, data, isExhausted, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                identifier = payload.identifier, refetch = payload.refetch, extraFilterData = payload.extraFilterData, limit = payload.limit;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 5, , 7]);
                _c = (_b = rentDepositSelectors).getRentDeposits;
                return [4 /*yield*/, select()];
            case 2:
                rentDeposits = _c.apply(_b, [_d.sent(), identifier]);
                return [4 /*yield*/, call(rentDepositApi.getRentDeposits, refetch ? 0 : (rentDeposits || []).length, extraFilterData, limit)];
            case 3:
                data = (_d.sent()).data;
                isExhausted = data.length < (limit || rentDepositApi.FETCH_LIMIT);
                return [4 /*yield*/, put(rentDepositActions.getRentDeposits.actions.success({
                        identifier: identifier,
                        rentDeposits: data,
                        refetch: refetch,
                        isExhausted: isExhausted,
                    }))];
            case 4:
                _d.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_2 = _d.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(rentDepositActions.getRentDeposits.actions.failure({
                        error: error,
                        identifier: identifier,
                    }))];
            case 6:
                _d.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getRentDepositsPagedStartFlow(_a) {
    var identifier, page, _b, limit, extraFilterData, skip, data, unknownError_3, error;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                identifier = payload.identifier, page = payload.page, _b = payload.limit, limit = _b === void 0 ? rentDepositApi.FETCH_LIMIT : _b, extraFilterData = payload.extraFilterData;
                skip = (page - 1) * limit;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(rentDepositApi.getRentDeposits, skip, extraFilterData, limit)];
            case 2:
                data = (_c.sent()).data;
                return [4 /*yield*/, put(rentDepositActions.getRentDepositsPaged.actions.success({
                        identifier: identifier,
                        rentDeposits: data,
                        page: page,
                    }))];
            case 3:
                _c.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_3 = _c.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(rentDepositActions.getRentDepositsPaged.actions.failure({
                        identifier: identifier,
                        page: page,
                        error: error,
                    }))];
            case 5:
                _c.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function uploadDocument(attachment) {
    var file, preSignedUrl, metaData;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                file = {
                    // @ts-ignore
                    name: attachment.name,
                    // @ts-ignore
                    path: (_a = attachment.file) === null || _a === void 0 ? void 0 : _a.path,
                    // @ts-ignore
                    type: (_b = attachment.file) === null || _b === void 0 ? void 0 : _b.mime,
                };
                return [4 /*yield*/, call(documentApi.uploadDocumentToS3, Platform.OS === "web" ? attachment : file)];
            case 1:
                preSignedUrl = _c.sent();
                metaData = {
                    categoryId: DOCUMENT_RENT_DEPOSIT_PASSPORT_CATEGORY,
                    filename: "".concat(attachment.name),
                    isInvisible: true,
                };
                return [4 /*yield*/, call(documentApi.uploadDocument, preSignedUrl, metaData)];
            case 2: 
            // @ts-ignore
            return [2 /*return*/, _c.sent()];
        }
    });
}
function getRentDepositValidationItem(_a) {
    var frontDocument, backDocument;
    var identityFrontDocument = _a.identityFrontDocument, identityBackDocument = _a.identityBackDocument, accountId = _a.accountId;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, uploadDocument(identityFrontDocument[0])];
            case 1:
                frontDocument = _b.sent();
                return [4 /*yield*/, uploadDocument(identityBackDocument[0])];
            case 2:
                backDocument = _b.sent();
                return [2 /*return*/, {
                        accountId: accountId,
                        identityFrontDocumentId: typeof identityFrontDocument === "string"
                            ? identityFrontDocument
                            : frontDocument.id,
                        identityBackDocumentId: typeof identityBackDocument === "string"
                            ? identityBackDocument
                            : backDocument.id,
                    }];
        }
    });
}
function createRentDepositStartFlow(_a) {
    var data, updatedProperty, requestInsurance, _b, rawRentDepositValidations, property, rentDepositValidations, response, contractId, contract, unknownError_4, error;
    var payload = _a.payload;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 11, , 13]);
                data = payload.data, updatedProperty = payload.updatedProperty, requestInsurance = payload.requestInsurance;
                _b = data.rentDepositValidations, rawRentDepositValidations = _b === void 0 ? [] : _b;
                if (!updatedProperty) return [3 /*break*/, 3];
                return [4 /*yield*/, call(propertyApiV2.update, {
                        id: data.propertyId,
                        data: updatedProperty,
                    })];
            case 1:
                property = (_c.sent()).data;
                return [4 /*yield*/, put(propertyActionsV2.updateSucceeded.getAction({
                        id: data.propertyId,
                        result: property,
                    }))];
            case 2:
                _c.sent();
                _c.label = 3;
            case 3: return [4 /*yield*/, all(rawRentDepositValidations.map(function (rentDepositValidationItem) {
                    return getRentDepositValidationItem(rentDepositValidationItem);
                }))];
            case 4:
                rentDepositValidations = _c.sent();
                return [4 /*yield*/, call(rentDepositApi.createRentDeposit, __assign(__assign({}, data), { rentDepositValidations: rentDepositValidations }))];
            case 5:
                response = _c.sent();
                if (!requestInsurance) return [3 /*break*/, 9];
                contractId = requestInsurance.contract.id;
                // Request insurance
                return [4 /*yield*/, call(contractApi.requestInsurance, requestInsurance.contract.insuranceExternalAccessCode)];
            case 6:
                // Request insurance
                _c.sent();
                return [4 /*yield*/, call(contractApiV2.getDetail, {
                        id: contractId,
                    })];
            case 7:
                contract = (_c.sent()).data;
                return [4 /*yield*/, put(contractActions.requestInsurance.actions.success({
                        contractId: contractId,
                        contract: contract.data,
                    }))];
            case 8:
                _c.sent();
                _c.label = 9;
            case 9: 
            // Dispatch rent deposit success
            return [4 /*yield*/, put(rentDepositActions.createRentDeposit.actions.success(response.data))];
            case 10:
                // Dispatch rent deposit success
                _c.sent();
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("rent_deposit"),
                });
                return [3 /*break*/, 13];
            case 11:
                unknownError_4 = _c.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [4 /*yield*/, put(rentDepositActions.createRentDeposit.actions.failure({
                        error: error,
                    }))];
            case 12:
                _c.sent();
                return [3 /*break*/, 13];
            case 13: return [2 /*return*/];
        }
    });
}
function releaseRentDepositStartFlow(_a) {
    var newRentDeposit, data, unknownError_5, error;
    var rentDeposit = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4 /*yield*/, call(rentDepositApi.releaseRentDeposit, rentDeposit)];
            case 1:
                newRentDeposit = (_b.sent()).data;
                return [4 /*yield*/, call(rentDepositApi.getRentDeposit, newRentDeposit.id)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(rentDepositActions.releaseRentDeposit.actions.success(data))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("rent_deposit_release"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_5 = _b.sent();
                error = unknownError_5;
                logger.logError({ error: error });
                return [4 /*yield*/, put(rentDepositActions.releaseRentDeposit.actions.failure({
                        error: error,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function cancelRentDepositStartStartFlow(_a) {
    var rentDepositId, unknownError_6, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                rentDepositId = payload.rentDepositId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(rentDepositApi.cancelRentDeposit, rentDepositId)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(rentDepositActions.cancelRentDeposit.actions.success({ rentDepositId: rentDepositId }))];
            case 3:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("rent_deposit.cancel.success"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_6 = _b.sent();
                error = unknownError_6;
                logger.logError({ error: error });
                return [4 /*yield*/, put(rentDepositActions.cancelRentDeposit.actions.failure({
                        rentDepositId: rentDepositId,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(rentDepositActions.getRentDeposit.types.START, getRentDepositStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatestWithUniquePayload(function (_a) {
                        var payload = _a.payload;
                        return payload.identifier;
                    }, rentDepositActions.getRentDeposits.types.START, getRentDepositsStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(rentDepositActions.getRentDepositsPaged.types.START, getRentDepositsPagedStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(rentDepositActions.createRentDeposit.types.START, createRentDepositStartFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(rentDepositActions.releaseRentDeposit.types.START, releaseRentDepositStartFlow)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(rentDepositActions.cancelRentDeposit.types.START, cancelRentDepositStartStartFlow)];
            case 6:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line import/no-default-export
export default saga;
