import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { EExportFileType, EExportType, exportFileModule, } from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import { EField } from "./schema.exportActivities.types";
export var isPerformingSubmitActionSelector = function (state) {
    return exportFileModule.selectors.isCreating(state);
};
export var performingSubmitActionErrorSelector = function (state) {
    return exportFileModule.selectors.createError(state);
};
export var performingSubmitActionResultSelector = function (state) { return paymentSelectors.getPaymentRequestsForPage(state, "export-revenues", 1); };
export var submitActionCreator = function (extraData) { return function (formData) {
    var propertyId = extraData.propertyId;
    var startDate = new Date(formData[EField.From]);
    var endDate = new Date(formData[EField.To]);
    var types = formData[EField.Types];
    var exportActivityParameters = {
        startDate: startDate,
        endDate: endDate,
        propertyId: propertyId,
        types: types,
    };
    return exportFileModule.actions.createStart.getAction({
        data: {
            exportFileType: EExportFileType.Csv,
            exportType: EExportType.Activity,
            exportParameters: { exportActivityParameters: exportActivityParameters },
        },
    });
}; };
