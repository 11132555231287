import { IDocumentPackage } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { formatMediumDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Grid, Typography } from "@rentiohq/web-shared-next/dist/ui-components";
import { ts } from "../../../services";

interface IProps {
  documentPackage: IDocumentPackage;
}

export const DocumentCard = ({ documentPackage }: IProps) => {
  const { name, createdAt, type } = documentPackage || {};

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={8}>
        <Typography variant="h4" fontSize="16px">
          {name}
        </Typography>
        <Typography variant="caption">
          {ts.documentPackageType(type.toLowerCase())}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6} lg={2}>
        <Typography variant="caption">
          {getLocalizedText("system.received_on")}
        </Typography>
        <Typography variant="subtitle2">
          {formatMediumDate(createdAt || "")}
        </Typography>
      </Grid>
    </Grid>
  );
};
