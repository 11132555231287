import { Avatar } from "@rentiohq/web-shared-next/dist/ui-components";

import { UserCardStyles } from "./UserCard.styles";

import * as contactApi from "@rentiohq/shared-frontend/dist/redux/contact/contact.api";
import { getConnectionLabel } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import {
  EContactType,
  IContact,
} from "@rentiohq/shared-frontend/dist/types/contact.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import { SHOW_APP_MODAL } from "@rentiohq/web-shared-next/dist/redux/actions";
import { getFontSize } from "@rentiohq/web-shared-next/dist/themes";
import { EFontSizes } from "@rentiohq/web-shared-next/dist/themes/types";
import {
  Box,
  Button,
  Card,
  Grid,
  Icon,
  Stack,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { enqueueSnackbar } from "@rentiohq/web-shared-next/dist/utils/snackbar";
import utils from "@rentiohq/web-shared/dist/utils";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import { useDispatch } from "react-redux";

interface IProps {
  contact: IContact;
}

const addressData = [
  "street",
  "number",
  "city",
  "state",
  "zip",
  "box",
  "countryId",
];

export const UserCard = ({ contact }: IProps) => {
  const dispatch = useDispatch();

  const sx = UserCardStyles();

  const {
    id,
    firstname,
    lastname,
    email,
    isCompany,
    company,
    pictureUrl,
    // accountId,
    phone,
    inviteAcceptedAt,
    type,
  } = contact;

  const [invitedAt, setInvitedAt] = React.useState<Date | undefined>(
    contact?.invitedAt,
  );

  const resendInvitation = async () => {
    try {
      await contactApi.inviteContact(id);

      setInvitedAt(new Date());

      enqueueSnackbar({
        message: getLocalizedText("contact.resend_invitation.success"),
        variant: "success",
      });
    } catch (err) {
      enqueueSnackbar({
        message: getLocalizedText("contact.resend_invitation.error"),
        variant: "error",
      });
    }
  };

  const handleContact = () => {
    if (type === EContactType.Support) {
      window.location.href = `mailto:${email}`;
    } else {
      dispatch({
        type: SHOW_APP_MODAL,
        payload: {
          title: getLocalizedText("system.model.chat"),
          showAppModal: true,
        },
      });
    }
  };

  // Will be used later
  // const actions = [
  //   {
  //     label: "contact.edit_contact",
  //     onClick: handleEditContact,
  //   },
  //   {
  //     label: "contact.delete",
  //     onClick: handleDeleteContact,
  //   },
  // ];

  const address: any = utils.object.filterKeys(contact, addressData);
  const name = isCompany ? company : `${firstname} ${lastname}`;

  const hasAddress = !isEmpty(utils.object.removeEmpty(address));
  const hasAccepted = Boolean(inviteAcceptedAt);
  const isSupportContact = type === EContactType.Support;

  return (
    <Card sx={sx.wrapper}>
      <Grid container spacing={2.5}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs zeroMinWidth>
              {pictureUrl ? (
                <Avatar
                  alt="pictureUrl"
                  size="lg"
                  src={pictureUrl}
                  sx={sx.content.avatar}
                />
              ) : (
                <Avatar size="lg" sx={sx.content.avatar}>
                  <Icon
                    path={isCompany ? EAssets.SuitCaseIcon : EAssets.UserIcon}
                    width="38.4px"
                    height="38.4px"
                  />
                </Avatar>
              )}
            </Grid>
            {/* Will be used later */}
            {/* <Grid item>
              <Menu actions={actions} />
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h3"
            sx={sx.content.longText}
            fontSize={getFontSize(EFontSizes.Medium)}
            title={name}
          >
            {name}
          </Typography>
          {isSupportContact && (
            <Typography
              variant="caption"
              sx={sx.content.resendInvite}
              onClick={() => (window.location.href = `mailto:${email}`)}
            >
              {getLocalizedText("contact.send_email")}
            </Typography>
          )}
          {type !== EContactType.Support && (
            <>
              {hasAccepted ? (
                <Typography variant="caption" whiteSpace="nowrap">
                  {getLocalizedText("contact.connection.since", {
                    date: utils.date.format(contact.inviteAcceptedAt),
                  })}
                </Typography>
              ) : (
                <Stack
                  direction="row"
                  divider={<div>-</div>}
                  gap={1}
                  alignItems="center"
                  flexWrap="wrap"
                >
                  <Typography variant="caption" whiteSpace="nowrap">
                    {getConnectionLabel({
                      contact: { ...contact, inviteAcceptedAt: invitedAt },
                      showConnectionDate: true,
                    })}
                    {/* {invitedAt
                      ? `${getLocalizedText(
                          "contact.invited_on",
                        )} ${utils.date.format(invitedAt)}`
                      : getLocalizedText("contact.connection.pending")} */}
                  </Typography>

                  <Typography
                    variant="caption"
                    sx={sx.content.resendInvite}
                    onClick={resendInvitation}
                  >
                    {getLocalizedText(
                      invitedAt
                        ? "contact.resend_invitation"
                        : "contact.send_invitation",
                    )}
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <Stack direction="column" spacing={1}>
            <Box>
              <Typography variant="caption">
                {getLocalizedText("system.email")}
              </Typography>
              <Typography variant="subtitle2">{email}</Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                {getLocalizedText("system.phone")}
              </Typography>
              <Typography variant="subtitle2">
                {formatPhone(contact.phone)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                {getLocalizedText("system.address")}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={sx.content.longText}
                title={formatAddress(address)}
              >
                {hasAddress ? formatAddress(address) : "-"}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" gap={1}>
            <Button
              variant="outlined"
              startIcon={
                <Icon
                  path={isSupportContact ? EAssets.MailIcon : EAssets.ChatsIcon}
                  width="16px"
                  height="16px"
                />
              }
              onClick={handleContact}
            >
              {getLocalizedText(
                isSupportContact ? "system.email" : "system.model.chat",
              )}
            </Button>
            <Button
              variant="outlined"
              startIcon={
                <Icon
                  path={EAssets.RingingPhoneIcon}
                  width="18px"
                  height="18px"
                />
              }
              onClick={() => (window.location.href = `tel:${phone}`)}
            >
              {getLocalizedText("system.call.imperative")}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};
