import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { matchPath, useLocation } from "react-router-dom";
import { Icon } from "../../../Icon";
import { TextStyle } from "../../../TextStyle";
import * as S from "../../Navigation.styled";
export var Item = function (props) {
    var url = props.url, icon = props.icon, label = props.label, _a = props.showActivityIndicator, showActivityIndicator = _a === void 0 ? false : _a, disabled = props.disabled, accessibilityLabel = props.accessibilityLabel;
    var location = useLocation();
    var active = !!matchPath(props.matchPath ? props.matchPath : url, location.pathname);
    return (_jsx(S.StyledNavigationListItem, { children: _jsx(S.StyledNavigationItem, { to: url, "aria-disabled": disabled, "aria-label": accessibilityLabel, isActive: active, children: _jsx(S.Tooltip, { noCursor: true, tooltipContent: _jsx(TextStyle, { variation: "positive", children: label }), placement: "right", children: _jsxs(S.StyledNavigationItemIcon, { isActive: active, children: [_jsx(Icon, { source: icon }), showActivityIndicator && (_jsx(S.StyledNavigationItemActivityIndicator, {}))] }) }) }) }));
};
