import { useSelf } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { canHaveRentioLegalForProperty } from "@rentiohq/shared-frontend/dist/reduxV2/legalCase/legalCase.utils";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { orderBy } from "lodash";
import { useState } from "react";
import { RentioLegalCard } from "scenes/RentioLegal/components/RentioLegalCard/RentioLegalCard";
import { useIsRentioLegalActivated } from "scenes/RentioLegal/hooks/useIsRentioLegalActivated";
import { ConfirmLegalActivationModal } from "./ConfirmLegalActivationModal/ConfirmLegalActivationModal";

interface IRentioLegalCards {
  property: IProperty;
  contracts: IContract[];
}

export const RentioLegalCards = (props: IRentioLegalCards) => {
  const { property, contracts } = props;

  const { broker } = useSelf();
  const hasRentioLegal = useIsRentioLegalActivated();

  // State
  const [contractToEnableRentioLegalFor, setContractToEnableRentioLegalFor] =
    useState<IContract>();

  // Event handlers
  const handleActivateRentioLegal = async (contract: IContract) => {
    if (!hasRentioLegal) {
      return;
    }

    setContractToEnableRentioLegalFor(contract);
  };

  // Render
  if (!canHaveRentioLegalForProperty({ property })) {
    return null;
  }

  if (!broker) {
    return null;
  }

  if (!hasRentioLegal) {
    return <RentioLegalCard />;
  }

  return (
    <>
      {orderBy(contracts, "legalLicenseActivatedAt").map(contract => (
        <RentioLegalCard
          contract={contract}
          onActivateRentioLegal={handleActivateRentioLegal}
        />
      ))}

      {contractToEnableRentioLegalFor && (
        <ConfirmLegalActivationModal
          contract={contractToEnableRentioLegalFor}
          onSuccess={() => setContractToEnableRentioLegalFor(undefined)}
          onClose={() => setContractToEnableRentioLegalFor(undefined)}
        />
      )}
    </>
  );
};
