export var EField;
(function (EField) {
    EField["Header1"] = "_header1";
    EField["Header3"] = "_header3";
    EField["Name"] = "name";
    EField["Type"] = "type";
    EField["ContentText"] = "contentText";
    EField["ContentDate"] = "contentDate";
    EField["ContentNumber"] = "contentNumber";
})(EField || (EField = {}));
