var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { compact } from "lodash";
import React from "react";
import { ts } from "../../services";
import { Loading } from "../Loading";
import { Modal } from "../Modal";
import { ResourceList, ResourceListItem } from "../ResourceList";
import { TextStyle } from "../TextStyle";
export var AccountContactInfoModal = function (props) {
    var account = props.account, getContactInfoForAccount = props.getContactInfoForAccount, onAddAsContact = props.onAddAsContact, onClose = props.onClose;
    // State
    var _a = React.useState(false), isLoading = _a[0], setIsLoading = _a[1];
    var _b = React.useState(), accountContactInfo = _b[0], setAccountContactInfo = _b[1];
    // Data
    var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!getContactInfoForAccount) {
                        return [2 /*return*/];
                    }
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getContactInfoForAccount(account)];
                case 2:
                    result = _a.sent();
                    if (result) {
                        setAccountContactInfo(result);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    showAlert({
                        type: "error",
                        error: error_1,
                    });
                    return [3 /*break*/, 4];
                case 4:
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    // Lifecycle
    React.useEffect(function () {
        fetchData();
    }, [account.id]);
    return (_jsxs(Modal, { heading: getName(account), onClose: onClose, shouldCloseOnOverlayClick: true, actions: [
            {
                content: ts.contactAddAsContact(),
                appearance: "outline",
                isDisabled: isLoading,
                onClick: function () {
                    onAddAsContact(accountContactInfo);
                },
            },
        ], children: [isLoading && !accountContactInfo && _jsx(Loading, {}), _jsx(ResourceList, { items: compact([
                    {
                        key: getLocalizedText("system.phone"),
                        value: accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.phone,
                    },
                    {
                        key: getLocalizedText("system.email"),
                        value: accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.email,
                    },
                    {
                        key: getLocalizedText("system.address"),
                        value: (accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.street)
                            ? formatAddress(accountContactInfo)
                            : undefined,
                    },
                    {
                        key: getLocalizedText("system.nationality"),
                        value: (accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.nationality)
                            ? getLocalizedText("system.country.".concat(accountContactInfo.nationality).toLowerCase())
                            : undefined,
                    },
                    {
                        key: getLocalizedText("system.locale"),
                        value: (accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.locale)
                            ? getLocalizedText("system.locale.".concat(accountContactInfo.locale))
                            : undefined,
                    },
                    {
                        key: getLocalizedText("system.date_of_birth"),
                        value: (accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.dateOfBirth)
                            ? formatDate(accountContactInfo.dateOfBirth)
                            : undefined,
                    },
                    {
                        key: getLocalizedText("system.place_of_birth"),
                        value: (accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.placeOfBirth) &&
                            (accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.countryOfBirth)
                            ? "".concat(accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.placeOfBirth, " - ").concat(getLocalizedText("system.country.".concat(accountContactInfo.countryOfBirth).toLowerCase()))
                            : undefined,
                    },
                    (accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.isCompany)
                        ? {
                            key: getLocalizedText("contact.company.name.label"),
                            value: accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.company,
                        }
                        : undefined,
                    (accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.isCompany)
                        ? {
                            key: getLocalizedText("system.vat_number"),
                            value: accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.VATNumber,
                        }
                        : undefined,
                    (accountContactInfo === null || accountContactInfo === void 0 ? void 0 : accountContactInfo.bivNumber)
                        ? {
                            key: getLocalizedText("system.biv_number"),
                            value: accountContactInfo.bivNumber,
                        }
                        : undefined,
                ]), renderItem: function (item) { return (_jsxs(ResourceListItem, { item: item, children: [_jsx(TextStyle, { variation: "subdued", children: item.key }), _jsx(Spacer, { weight: ESpacerWeight.W04 }), _jsx(TextStyle, { element: "div", children: item.value || "-" })] })); } })] }));
};
