import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Button } from "@rentiohq/web-shared/dist/components";

export const Bookkeeping = () => {
  const { broker } = authHooks.useSelf();

  // Event handlers
  const handleClickManualSync = async () => {
    if (!broker) {
      return;
    }

    try {
      await api.post(`/brokers/${broker?.id}/sync-bookkeeping`, {
        isServerless: true,
      });

      showAlert({
        message: getLocalizedText("broker.settings.manual_sync.success.title"),
        content: getLocalizedText(
          "broker.settings.manual_sync.success.content",
        ),
        type: "success",
      });
    } catch (error) {
      showAlert({
        message: getLocalizedText("broker.settings.manual_sync.error.title"),
        content: getLocalizedText("broker.settings.manual_sync.error.content"),
        error,
        type: "error",
      });
    }
  };

  // Render
  if (!broker) {
    return null;
  }

  return (
    <>
      <Button appearance="primary" onClick={handleClickManualSync}>
        {getLocalizedText("broker.settings.cta.manual_sync")}
      </Button>
    </>
  );
};
