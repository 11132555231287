import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// cspell:disable
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as templateActions from "@rentiohq/shared-frontend/dist/redux/template/template.actions";
import * as templateSelectors from "@rentiohq/shared-frontend/dist/redux/template/template.selectors";
import { ETemplateAccessScope, } from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import * as templateUtils from "@rentiohq/shared-frontend/dist/redux/template/template.utils";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { appendFilter } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { formatDate, formatTime, } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocale, getLocalizedText, } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, DataTable, EmptyState, Error, Loading, Page, Pagination, SegmentedControl, TextStyle, } from "../../../../components";
import { Banner } from "../../../../components/Banner";
var FETCH_LIMIT = 20;
var TemplatesOverview = function (_a) {
    var internal = _a.internal;
    // Router
    var navigate = useNavigate();
    // State
    var _b = React.useState(0), page = _b[0], setPage = _b[1];
    // Redux
    var dispatch = useDispatch();
    var _c = React.useState(internal ? ETemplateAccessScope.Public : ETemplateAccessScope.Private), accessScope = _c[0], setAccessScope = _c[1];
    var countIdentifier = "templates-".concat(accessScope);
    var count = useSelector(function (state) {
        return countSelectors.getCount(state, countIdentifier);
    });
    var order = ["modifiedAt DESC", "createdAt DESC"];
    var filter = {
        where: { accessScope: accessScope },
    };
    var templates = useSelector(function (state) {
        return templateSelectors.paged.dataForPage(state, {
            id: templateUtils.getPagedId({ order: order, filter: filter }),
            page: page,
        });
    });
    var isFetching = useSelector(function (state) {
        return templateSelectors.paged.isFetchingPage(state, {
            id: templateUtils.getPagedId({ order: order, filter: filter }),
            page: page,
        });
    });
    var fetchError = useSelector(function (state) {
        return templateSelectors.paged.fetchPageError(state, {
            id: templateUtils.getPagedId({ order: order, filter: filter }),
            page: page,
        });
    });
    // Data
    var fetchData = function () {
        dispatch(countActions.getCount.actions.start({
            countIdentifier: countIdentifier,
            countBase: appendFilter("/templates/count", filter),
        }));
        dispatch(templateActions.getPaged.actions.start({
            page: page,
            limit: FETCH_LIMIT,
            order: order,
            filter: filter,
        }));
    };
    // Lifecycle
    React.useEffect(function () {
        fetchData();
    }, [page, accessScope]);
    // Event handlers
    var handleClickRow = function (index) {
        var template = templates === null || templates === void 0 ? void 0 : templates[index];
        if (!template) {
            return;
        }
        confirm({
            title: getLocalizedText("system.model.template"),
            modalProps: {
                width: "medium",
                hasDismiss: true,
                shouldCloseOnOverlayClick: true,
            },
            primaryActions: [
                {
                    title: getLocalizedText("template.cta.edit_meta"),
                    onPress: function () {
                        navigate("/templates/form/".concat(template.id));
                    },
                },
            ],
            secondaryAction: {
                title: getLocalizedText("template.cta.edit_content"),
                onPress: function () {
                    navigate("/templates/edit-data/".concat(template.id));
                },
            },
        });
    };
    var handlePageClick = function (_a) {
        var selected = _a.selected;
        setPage(selected);
    };
    // Helpers
    var getRowActions = function (template) {
        var result = [];
        result.push({
            id: "edit",
            content: getLocalizedText("template.cta.edit_meta"),
            onClick: function () { return navigate("/templates/form/".concat(template.id)); },
        });
        result.push({
            id: "editData",
            content: getLocalizedText("template.cta.edit_content"),
            onClick: function () { return navigate("/templates/edit-data/".concat(template.id)); },
        });
        result.push({
            id: "duplicate",
            content: getLocalizedText("template.cta.duplicate"),
            onClick: function () {
                dispatch(templateActions.duplicateTemplate.actions.start({
                    id: template.id,
                    onSuccess: function (_, duplicatedId) {
                        navigate("/templates/form/".concat(duplicatedId));
                    },
                }));
            },
        });
        result.push({
            id: "delete",
            content: (_jsx(TextStyle, { variation: "negative", children: getLocalizedText("system.delete") })),
            onClick: function () {
                confirm({
                    title: "Sjabloon verwijderen",
                    modalProps: {
                        width: "medium",
                        hasDismiss: true,
                        shouldCloseOnOverlayClick: true,
                    },
                    primaryActions: [
                        {
                            title: getLocalizedText("system.delete"),
                            onPress: function () {
                                return dispatch(templateActions.deleteObject.actions.start({
                                    id: template.id,
                                }));
                            },
                        },
                    ],
                });
            },
        });
        return result;
    };
    // Render
    var renderTable = function () {
        if (!templates) {
            if (isFetching) {
                return _jsx(Loading, { asDots: true });
            }
            if (fetchError) {
                return _jsx(Error, { errors: [fetchError] });
            }
            return;
        }
        if (templates.length === 0) {
            return (_jsx(EmptyState, { heading: getLocalizedText("empty_state.search.heading"), icon: "archive", children: getLocalizedText("empty_state.search.content") }));
        }
        return (_jsx(DataTable, { onRowClick: handleClickRow, columnContentTypes: [
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
                "text",
                "action",
            ], headings: [
                getLocalizedText("system.modified_at.title"),
                getLocalizedText("template.field.name"),
                getLocalizedText("template.field.access_scope"),
                getLocalizedText("template.field.type"),
                getLocalizedText("template.field.country"),
                getLocalizedText("template.field.region"),
                getLocalizedText("template.field.available_from"),
                getLocalizedText("template.field.available_to"),
                "",
            ], rows: templates.map(function (template) {
                var id = template.id, createdAt = template.createdAt, modifiedAt = template.modifiedAt, name = template.name, accessScope = template.accessScope, type = template.type, country = template.country, region = template.region, availableFrom = template.availableFrom, availableTo = template.availableTo;
                return {
                    id: id,
                    content: [
                        "".concat(formatDate(modifiedAt || createdAt), " ").concat(formatTime(modifiedAt || createdAt)),
                        name || "-",
                        getLocalizedText("template.access_scope.".concat(accessScope).toLowerCase()),
                        getLocalizedText("template.type.".concat(stringToSnakeCase(type)).toLowerCase()),
                        country
                            ? getLocalizedText("system.country.".concat(country).toLowerCase())
                            : "-",
                        region
                            ? getLocalizedText("template.region.".concat(region).toLowerCase())
                            : "-",
                        availableFrom ? formatDate(availableFrom) : "-",
                        availableTo ? formatDate(availableTo) : "-",
                    ],
                    actions: getRowActions(template),
                };
            }), totalPages: Math.ceil((count || 0) / FETCH_LIMIT), 
            // @ts-ignore
            extraData: templates }));
    };
    var renderPaging = function () {
        if (!count || count === 0) {
            return;
        }
        var numberOfPages = Math.ceil(count / FETCH_LIMIT);
        if (numberOfPages <= 1) {
            return;
        }
        return (_jsx(Pagination, { initialPage: page, pageCount: numberOfPages, onPageChange: handlePageClick }));
    };
    return (_jsx(Page, { title: getLocalizedText("template.overview.title"), fullWidth: true, actions: [
            {
                content: getLocalizedText("template.cta.add"),
                url: "/templates/form",
                appearance: "primary",
            },
        ], children: _jsxs(Card, { children: [getLocale() !== ELocale.NL && (_jsx(Banner, { title: "IMPORTANT: Dashboard should be in Dutch to edit/check variable values", icon: "alertDiamond", variation: "warning", hasDismiss: false })), _jsx(SegmentedControl, { name: "Access scope", options: Object.values(ETemplateAccessScope).map(function (x) { return ({
                        id: x,
                        value: getLocalizedText("template.access_scope.".concat(x).toLowerCase()),
                    }); }), selected: accessScope, onChange: function (newValue) {
                        setPage(0);
                        setAccessScope(newValue);
                    } }), renderTable(), renderPaging()] }) }));
};
// eslint-disable-next-line import/no-default-export
export default TemplatesOverview;
