import { useTheme } from "@mui/material/styles";
import { EFontWeights } from "../../themes/types";
export var LinkStyles = function (fontSize, underlined) {
    var theme = useTheme();
    return {
        wrapper: {
            width: "fit-content",
            color: theme.palette.success.main,
            fontSize: "".concat(fontSize, "px"),
            cursor: "pointer",
            fontWeight: EFontWeights.Medium,
            textDecoration: underlined ? "underline" : "none",
        },
    };
};
