import { Box } from "@rebass/grid";
import { useSelf } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { taskModule } from "@rentiohq/shared-frontend/dist/reduxV2/task";
import { getAccountContactInfo } from "@rentiohq/shared-frontend/dist/reduxV2/task/task.api";
import {
  IAccount,
  IMember,
} from "@rentiohq/shared-frontend/dist/types/auth.types";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { ETaskMemberType } from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMembersWithRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  Button,
  Card,
  ContactSearch,
  ESpacings,
  Icon,
  MemberList,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { flatten } from "lodash";
import isEmpty from "ramda/es/isEmpty";
import { useState } from "react";
import * as t from "../../../../../../services/translationService";

interface IProps {
  members: IMember<ETaskMemberType>[];
  onUpdate?: () => void;
  onFocus?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  heading?: React.ReactNode;
  body?: React.ReactNode;
  showActions?: boolean;
  taskId: number;
  role: ETaskMemberType;
  canEditTask: boolean;
}

export const TaskMembers = (props: IProps) => {
  const { members, heading, taskId, role, canEditTask } = props;

  const { broker } = useSelf();
  const { updateMembers, updateEmployees } = taskModule.hooks.useUpdate({
    id: taskId,
  });

  const membersForRole = getMembersWithRole(members, role);

  const [showInput, setShowInput] = useState(false);

  const disallowedAccountIds = flatten([
    ...membersForRole.map(member => member.account.id),
    ...membersForRole.map(
      member =>
        member.employeeRoles?.[role]?.map(employee => employee.id) || [],
    ),
  ]);

  const handleAddMember = (contact: IContact) => {
    const accountId = contact.account ? contact.account.id : contact.accountId;

    if (!accountId) {
      return;
    }

    updateMembers({
      membersToAdd: [
        {
          roles: [role],
          accountId,
        },
      ],
      membersToRemove: [],
    });

    setShowInput(false);
  };

  const handleRemoveMember = (accountId: number) => {
    if (!accountId) {
      return;
    }

    updateMembers({
      membersToAdd: [],
      membersToRemove: [
        {
          roles: [role],
          accountId,
        },
      ],
    });
  };

  const handleSwitchEmployee = (
    role: ETaskMemberType,
    newEmployeeAccountId: number,
    oldEmployeeAccountId?: number,
  ) => {
    if (!broker) {
      return;
    }

    const employeesToRemove = [];
    if (oldEmployeeAccountId) {
      employeesToRemove.push({
        accountId: broker.accountId,
        employeeAccountId: oldEmployeeAccountId,
        roles: [role],
      });
    }

    const employeesToAdd = [
      {
        accountId: broker.accountId,
        employeeAccountId: newEmployeeAccountId,
        roles: [role],
      },
    ];

    updateEmployees({
      employeesToRemove,
      employeesToAdd,
    });
  };

  const handleRemoveEmployee = (
    role: ETaskMemberType,
    employeeAccountId: number,
  ) => {
    if (!broker) {
      return;
    }

    updateEmployees({
      employeesToAdd: [],
      employeesToRemove: [
        {
          accountId: broker.accountId,
          employeeAccountId,
          roles: [role],
        },
      ],
    });
  };

  const getActions = (params: {
    role?: ETaskMemberType;
    accountIds: number[];
    contact?: IContact;
    employeeAccount?: IAccount;
  }) => {
    const { role, accountIds } = params;

    if (!canEditTask) {
      return [];
    }

    const member = members.find(member =>
      accountIds.includes(member.account.id),
    );
    if (!member) {
      return [];
    }

    const result: IAction[] = [];

    const deleteContactAction = {
      content: (
        <TextStyle variation={"negative"}>
          {getLocalizedText(`tasks.remove.${role}`.toLowerCase())}
        </TextStyle>
      ),
      media: <Icon source="bin" color="red" />,
      onClick: () => {
        handleRemoveMember(member.account.id);
      },
      isContactAction: false,
    };

    result.push(deleteContactAction);

    return result;
  };

  return (
    <Card
      heading={heading}
      space={ESpacings.loose}
      actions={
        canEditTask
          ? [
              {
                content: role.includes(ETaskMemberType.Executor)
                  ? t.taskAddExecutor()
                  : t.taskAddFollower(),
                onClick: () => setShowInput(true),
                appearance: "link",
              },
            ]
          : undefined
      }
    >
      <MemberList
        role={role}
        members={membersForRole}
        getActions={getActions}
        getContactInfoForAccount={async (account: IAccount) =>
          (
            await getAccountContactInfo({
              taskId,
              accountId: account.id,
            })
          ).data.data
        }
        onSwitchEmployee={(
          role,
          newEmployeeAccountId,
          oldEmployeeAccountId,
        ) => {
          handleSwitchEmployee(
            role,
            newEmployeeAccountId,
            oldEmployeeAccountId,
          );
        }}
        onRemoveEmployee={(role, employeeAccountId) => {
          handleRemoveEmployee(role, employeeAccountId);
        }}
        mediaSize="medium"
        boxProps={{ py: 3 }}
      />

      {showInput && (
        <Box mt={ESpacings.base}>
          <ContactSearch
            includeEmployees={true}
            onContactSelect={handleAddMember}
            onAccountSelect={employee => {
              handleSwitchEmployee(role, employee.id);
              setShowInput(false);
            }}
            disallowedAccountIds={disallowedAccountIds}
            autoFocus={false}
          />

          {!isEmpty(membersForRole) && (
            <Box mt={1}>
              <Button
                appearance="link"
                onClick={() => {
                  setShowInput(false);
                }}
              >
                {t.system("cancel")}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Card>
  );
};
