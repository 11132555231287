import { Box } from "@rebass/grid";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import * as paymentRequestActionsV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import * as paymentRequestSelectorsV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import { IInfiniteLoadQuery } from "@rentiohq/shared-frontend/dist/reduxV2/utils/api.types";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  DisplayText,
  ESpacings,
  Grid,
  Loading,
  Lozenge,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import hash from "object-hash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import {
  getPaymentRequestsFilter,
  getPaymentRequestsFilterV2,
} from "scenes/FollowUp/Payments/Payments.utils";
import { IPaymentRequestsGridColumnProps } from "../OpenPaymentRequests.types";
import { PaymentRequestsGridItem } from "./PaymentRequestsGridItem";

const FETCH_LIMIT = 5;

export const PaymentRequestsGridColumn: React.FC<
  IPaymentRequestsGridColumnProps
> = props => {
  const {
    propertyId,
    search,
    phase,
    status,
    from,
    heading,
    emptyState,
    getPaymentRequestActions,
    getPrimaryPaymentRequestActions,
  } = props;

  const { internalModeEnabled } = useInternalMode();

  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const filter = getPaymentRequestsFilter({
    propertyId,
    search,
    phase,
    status,
    from,
  });
  const identifier = hash(filter);

  // Redux
  const dispatch = useDispatch();
  const paymentRequests = useSelector((state: IRootStore) =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentRequestSelectorsV2.getInfiniteLoad(
          state,
          getPaymentRequestsFilterV2({
            propertyId,
            search,
            status,
            phase,
            from,
          }),
        )?.items
      : paymentSelectors.getPaymentRequestsByIdentifier(state, identifier),
  );
  const isFetching = useSelector((state: IRootStore) =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentRequestSelectorsV2.getInfiniteLoad(
          state,
          getPaymentRequestsFilterV2({
            propertyId,
            search,
            status,
            phase,
            from,
          }),
        )?.isFetching
      : paymentSelectors.isFetchingPaymentRequestsByIdentifier(
          state,
          identifier,
        ),
  );
  const isExhausted = useSelector((state: IRootStore) =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentRequestSelectorsV2.getInfiniteLoad(
          state,
          getPaymentRequestsFilterV2({
            propertyId,
            search,
            status,
            phase,
            from,
          }),
        )?.isExhausted
      : paymentSelectors.isExhaustedPaymentRequestsByIdentifier(
          state,
          identifier,
        ),
  );
  const fetchError = useSelector((state: IRootStore) =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentRequestSelectorsV2.getInfiniteLoad(
          state,
          getPaymentRequestsFilterV2({
            propertyId,
            search,
            status,
            phase,
            from,
          }),
        )?.fetchError
      : paymentSelectors.paymentRequestsByIdentifierFetchError(
          state,
          identifier,
        ),
  );

  // Hooks
  const { count } = useCount({
    countBase: append("/payment-requests/count", {
      where: mapObjectWithTimeZonedDatesToUtcIsoString(
        getPaymentRequestsFilter({ phase, propertyId }).where,
      ),
    }),
  });

  // Helpers
  const fetchPaymentRequests = (refetch: boolean) => {
    if (isFetching) {
      return;
    }

    if (!refetch && isExhausted) {
      return;
    }

    const filterData =
      internalModeEnabled && hasPaymentV2ForBroker
        ? getPaymentRequestsFilterV2({
            propertyId,
            search,
            status,
            phase,
            from,
          })
        : getPaymentRequestsFilter({
            propertyId,
            search,
            status,
            phase,
            from,
          });

    if (internalModeEnabled && hasPaymentV2ForBroker) {
      dispatch(
        paymentRequestActionsV2.getInfiniteLoadStart.getAction({
          query: filterData as IInfiniteLoadQuery,
          refresh: true,
        }),
      );
    } else {
      dispatch(
        paymentActions.getPaymentRequestsByIdentifier.actions.start({
          paymentRequestsIdentifier: identifier,
          refetch,
          filterData,
          limit: FETCH_LIMIT,
        }),
      );
    }
  };

  // Events
  const handleLoadMoreClick = () => {
    fetchPaymentRequests(false);
  };

  React.useEffect(() => {
    fetchPaymentRequests(true);
  }, [propertyId, search, status, phase, from]);

  const renderContent = () => {
    if (paymentRequests && paymentRequests.length > 0) {
      return (
        <>
          {paymentRequests.map(paymentRequest => (
            <PaymentRequestsGridItem
              key={paymentRequest.id}
              item={paymentRequest}
              actions={getPaymentRequestActions(paymentRequest)}
              primaryActions={getPrimaryPaymentRequestActions(
                paymentRequest,
                phase,
              )}
              phase={phase}
            />
          ))}

          {!isExhausted && (count || 0) > paymentRequests.length && (
            <Box mt={ESpacings.base}>
              <Button
                onClick={handleLoadMoreClick}
                isSubmitting={isFetching}
                size="small"
                appearance="outline"
                isFullWidth
              >
                {getLocalizedText("system.load_more")}
              </Button>
            </Box>
          )}
        </>
      );
    }
    if (fetchError) {
      return <p>{getLocalizedText("fetch.error")}</p>;
    }

    if (isFetching) {
      return <Loading />;
    }

    return emptyState;
  };

  return (
    <div>
      <Grid alignItems="center" spacing="tight" mb={ESpacings.base}>
        <DisplayText subdued={true} space="none" size="extraSmall">
          {heading}
        </DisplayText>
        <Lozenge>{count}</Lozenge>
      </Grid>
      {renderContent()}
    </div>
  );
};
