import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  Card,
  ESpacings,
  RentioInternalRenderer,
} from "@rentiohq/web-shared/dist/components";
import { useState } from "react";
import { ChecklistItems } from "./components/ChecklistItems";
import { ContractMetaData } from "./components/ContractMetaData";

interface IProps {
  contract: IContract;
  property: IProperty;
  showContractType?: boolean;
  showChecklistInitialState?: boolean;
  onCancelContract?: () => void;
}

export const ContractCard = (props: IProps) => {
  const {
    contract,
    property,
    showContractType = true,
    showChecklistInitialState = true,
    onCancelContract,
  } = props;

  const [showChecklistItems, setShowChecklistItems] = useState(
    showChecklistInitialState,
  );

  // Render
  return (
    <Card key={contract.id} hasSections={true}>
      <Card.Section>
        <ContractMetaData
          contract={contract}
          property={property}
          showContractType={showContractType}
          showChecklistItems={showChecklistItems}
          onCancelContract={onCancelContract}
        />
      </Card.Section>

      <RentioInternalRenderer
        items={{
          ibanisationStatus: contract.ibanisationStatus,
        }}
      />

      {showChecklistItems && (
        <Card.Section boxProps={{ p: ESpacings.none }}>
          <ChecklistItems property={property} contract={contract} />
        </Card.Section>
      )}

      {!showChecklistInitialState && (
        <Card.Section boxProps={{ px: ESpacings.loose, py: ESpacings.base }}>
          <Button
            appearance="link"
            onClick={() => {
              setShowChecklistItems(!showChecklistItems);
            }}
          >
            {getLocalizedText(
              showChecklistItems
                ? "contracts.detail.hide_checklist.action"
                : "contracts.detail.show_checklist.action",
            )}
          </Button>
        </Card.Section>
      )}
    </Card>
  );
};
