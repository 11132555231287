import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import * as chatSelectors from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { InfoChat } from "./InfoChat";

// eslint-disable-next-line import/no-default-export
export default () => {
  const params = useParams<{ chatId: string }>();
  const chatId = params.chatId ? +params.chatId : undefined;

  const chat = useSelector((state: IPartialRootState) =>
    chatId ? chatSelectors.getDetail(state, chatId) : undefined,
  );

  if (!chat) {
    return null;
  }

  return <InfoChat chat={chat} />;
};
