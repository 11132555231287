import { Box, Flex } from "@rebass/grid";
import {
  getSubtitle,
  getTitle,
} from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import {
  EChatMemberTypes,
  IChat,
} from "@rentiohq/shared-frontend/dist/types/chat.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { iHaveRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  Button,
  DisplayText,
  DropdownMenu,
  ESpacings,
  Icon,
  Stack,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import * as t from "../../../../../../services/translationService";
import * as S from "./ConversationHeader.styled";

interface IProps {
  chat: IChat;
  actions?: IAction[];
}

export const ConversationHeader: React.FC<IProps> = ({ chat, actions }) => {
  const navigate = useNavigate();

  const { detail: property } = propertyHooks.useDetail({
    id: chat.propertyId,
  });

  const title = chat && getTitle({ chat });
  const subtitle = chat && getSubtitle(chat);

  const actionsMarkup =
    actions && actions.length > 0 ? (
      <DropdownMenu
        children={
          <Box ml={ESpacings.tight}>
            <Icon source="navigationMenuVertical" />
          </Box>
        }
        actions={actions}
      />
    ) : null;

  const renderTitle = title ? (
    <DisplayText size="extraLarge" element="h1" space="tight">
      {title}
    </DisplayText>
  ) : null;

  const renderSubtitle = subtitle ? (
    <TextStyle variation="subdued">{subtitle}</TextStyle>
  ) : null;

  const detailUrl = useMemo(
    () =>
      iHaveRole(chat, EChatMemberTypes.Admin)
        ? `/chats/${chat.id}/edit`
        : `/chats/${chat.id}/info`,
    [chat],
  );

  const navigateToDetail = () => {
    if (!property) {
      return;
    }

    navigate(`/properties/${property.id}`);
  };

  return (
    <S.Wrapper>
      <Stack alignment="center">
        <Stack.Item hasFill={true}>
          <Flex alignItems="center">
            <S.Icon>
              <Icon
                size="large"
                source={
                  chat && chat.members.length > 2
                    ? "multipleUsersCircle"
                    : "profile"
                }
              />
            </S.Icon>
            <S.Content>
              <div>{renderTitle}</div>
              {renderSubtitle}
              {property ? (
                <S.Address onClick={navigateToDetail}>
                  {property && formatAddress(property)}
                </S.Address>
              ) : null}
            </S.Content>
          </Flex>
        </Stack.Item>

        {!chat.archivedAt && (
          <Stack.Item>
            <Button
              appearance="outline"
              url={{
                pathname: detailUrl,
                state: { chat },
              }}
            >
              {t.chatsInfoActionTitle()}
            </Button>
          </Stack.Item>
        )}

        {!chat.archivedAt && <Stack.Item>{actionsMarkup}</Stack.Item>}
      </Stack>
    </S.Wrapper>
  );
};
