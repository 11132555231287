import * as contractActions from "@rentiohq/shared-frontend/dist/redux/contract/contract.actions";
import * as contractSelectors from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.selectors";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { IRootStore } from "redux/reducers";
import { EField, IExtraData } from "./schema.requestInsurance.types";

export const isPerformingSubmitActionSelector =
  (contractId: string) => (state: IRootStore) =>
    contractSelectors.isRequestingInsurance(state, contractId);

export const performingSubmitActionErrorSelector =
  (contractId: string) => (state: IRootStore) =>
    contractSelectors.requestInsuranceError(state, contractId);

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { property, contract } = extraData;

    const updatedProperty: Partial<IProperty> = {
      buildingSurfaceArea: formData[EField.BuildingSurfaceArea],
      buildingHasHardMaterials: formData[EField.BuildingHasHardMaterials],
    };

    // const buildingFloor = formData[EField.BuildingFloor];
    // if (buildingFloor) {
    //   updatedProperty.buildingFloor = buildingFloor;
    // }

    const buildingFloorCount = formData[EField.BuildingLayers];
    if (buildingFloorCount) {
      updatedProperty.buildingFloorCount = buildingFloorCount;
    }

    const buildingAdjoined = formData[EField.BuildingAdjoined];
    if (buildingAdjoined) {
      updatedProperty.buildingAdjoined = buildingAdjoined;
    }

    return contractActions.requestInsurance.actions.start({
      contractId: contract.id,
      insuranceExternalAccessCode: contract.insuranceExternalAccessCode,
      propertyId: property.id,
      updatedProperty,
    });
  };
