import addContactSchemas from "@rentiohq/shared-frontend/dist/forms/addContact";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.actions";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  Banner,
  MultiStepForm as Form,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import * as t from "../../../services/translationService";

const formId = generateFormId();

export const ContactScreen: React.FC<any> = React.forwardRef(
  ({ account, onClose }: any, formRef: any) => {
    const { firstname, lastname, company } = account;
    const contact = { firstname, lastname, company };
    const contactSchemas = addContactSchemas({
      isCompany: !!account.company,
      contact: contact as any,
      showSendInvite: false,
    });

    const handleSuccess = (values: any) => {
      onClose?.(values);
    };

    const translationValues = {
      values: { name: getName(account) },
    };
    return (
      <>
        <Banner
          variation="warning"
          title={t.paymentRequestFollowUpAddContactNoticeHeading(
            translationValues,
          )}
          hasDismiss={false}
        >
          {t.paymentRequestFollowUpAddContactNoticeContent(translationValues)}
        </Banner>
        <Form
          ref={formRef}
          formId={`follow-up-phone-${formId}`}
          preventSubmitThroughEnter={true}
          schemas={contactSchemas}
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            undefined,
          )}
          performingSubmitActionResultSelector={performingSubmitActionResultSelector(
            undefined,
          )}
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            undefined,
          )}
          submitActionCreator={submitActionCreator(undefined, account.id)}
          showControls={false}
          withAside={false}
          onSuccess={handleSuccess}
        />
      </>
    );
  },
);
