import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EProduct } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import "draft-js/dist/Draft.css";
import { TextStyle } from "../../../../../../components";
import { useInternalMode } from "../../../../../../redux/system/system.hooks";
import { DefaultVariableLozenge } from "../DefaultVariableLozenge";
var LoopInfo = function (props) {
    var loop = props.loop, group = props.group, variable = props.variable;
    var internalModeEnabled = useInternalMode().internalModeEnabled;
    // Render
    return (_jsxs(_Fragment, { children: [group && (_jsxs(_Fragment, { children: [_jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.variable.info_group") }), _jsx("div", { children: getLocalizedText(group.id) })] })), variable && (_jsxs(_Fragment, { children: [_jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.variable.info_variable") }), _jsx("div", { children: getLocalizedText(variable.name) }), (CONFIG.PRODUCT === EProduct.Backoffice || internalModeEnabled) && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, { variation: "subdued", children: "Variable ID (Used for internal mapping to values)" }), _jsxs("div", { children: [variable.id, " ", _jsx(DefaultVariableLozenge, { variable: variable })] })] }))] })), _jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.insert_custom.insert_loop.field.separator") }), _jsx("div", { children: loop.separator || "-" }), _jsx(Spacer, { weight: ESpacerWeight.W12 }), _jsx(TextStyle, { variation: "subdued", children: getLocalizedText("editor.insert_custom.insert_loop.field.last_separator") }), _jsx("div", { children: loop.lastSeparator || "-" })] }));
};
// eslint-disable-next-line import/no-default-export
export default LoopInfo;
