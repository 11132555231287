var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getSchema as getSchema1, getValidate as getValidate1, uiSchema as uiSchema1, } from "./schema.createProperty.1";
import { getSchema as getSchema2, uiSchema as uiSchema2, } from "./schema.createProperty.2";
import { getSchema as getSchema3, getValidate as getValidate3, uiSchema as uiSchema3, } from "./schema.createProperty.3";
import { EField } from "./schema.createProperty.types";
import { getInitialValues } from "./schema.createProperty.utils";
// eslint-disable-next-line import/no-default-export
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    return __spreadArray(__spreadArray([
        {
            schema: getSchema1(extraData, initialValues),
            uiSchema: uiSchema1(extraData, initialValues),
            validate: getValidate1(extraData),
        }
    ], (extraData.propertyId
        ? []
        : [
            {
                schema: getSchema2(extraData, initialValues),
                uiSchema: uiSchema2(extraData, initialValues),
                showIf: function (formData) { return formData[EField.PropertyType] !== 1; },
            },
        ]), true), (extraData.propertyId
        ? []
        : [
            {
                schema: getSchema3(extraData, initialValues),
                uiSchema: uiSchema3(extraData, initialValues),
                validate: getValidate3(extraData),
                showIf: function (formData) {
                    return ((formData.owners && formData.owners.length > 1) ||
                        !!extraData.brokerContact) &&
                        formData[EField.PropertyType] !== 1;
                },
            },
        ]), true);
});
