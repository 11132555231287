var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { styled } from "@mui/material/styles";
import { drawerWidth } from "../../utils/constant";
import { ThemeMode } from "../../utils/types/config";
export var MainContentStyled = styled("main", {
    shouldForwardProp: function (prop) {
        return prop !== "open" && prop !== "borderRadius" && prop !== "menuOrientation";
    },
})(function (_a) {
    var _b, _c, _d;
    var theme = _a.theme, open = _a.open, borderRadius = _a.borderRadius;
    return (__assign(__assign(__assign({ backgroundColor: theme.palette.mode === ThemeMode.DARK
            ? theme.palette.dark[800]
            : theme.palette.grey[100], minWidth: "1%", width: "100%", minHeight: "calc(100vh - 78px)", flexGrow: 1, padding: 20, paddingBottom: 40, marginTop: 78, borderRadius: "".concat(borderRadius, "px"), borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }, (!open && (_b = {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.shorter + 200,
            })
        },
        _b[theme.breakpoints.up("md")] = {
            marginLeft: 20,
            width: "calc(100% - ".concat(drawerWidth, "px)"),
        },
        _b))), (open && (_c = {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.shorter + 200,
            }),
            marginLeft: 0,
            width: "calc(100% - ".concat(drawerWidth, "px)")
        },
        _c[theme.breakpoints.up("md")] = {},
        _c))), (_d = {}, _d[theme.breakpoints.down("md")] = __assign({ marginLeft: 0, padding: 16, paddingBottom: 40 }, (!open && {
        width: "calc(100% - ".concat(drawerWidth, "px)"),
    })), _d[theme.breakpoints.down("sm")] = {
        marginLeft: 0,
        paddingBottom: 100,
    }, _d)));
});
