import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import PropertyListItemHeader, {
  IPropertyListAddressSortProps,
  PropertyListItemItem,
  PropertyListItemSpacer,
} from "../PropertyListItem/PropertyListItem.header";
import { ESortOrder, OrderChangeFunction } from "../SortableResourceListHeader";
import {
  WIDTH_CONTRACT_DATE,
  WIDTH_OWNERS,
  WIDTH_TENANTS,
} from "./PropertyListItemRent.constants";
import * as S from "./PropertyListItemRent.styles";

const statuses: string[] = [
  "system.template_document_contract",
  "system.contract_signing",
  "system.rent_deposit",
  "system.payment_orders",
  "system.insurance",
  "system.move_service",
  "system.location_description",
  "system.registration",
];

interface IPropertyListItemRentHeaderProps
  extends IPropertyListAddressSortProps {
  contractStartDateOrder: ESortOrder | undefined;
  contractStartDateOnOrderChange: OrderChangeFunction;
}

const PropertyListItemRentHeader = ({
  addressOrder,
  addressOnChangeOrder,
  contractStartDateOrder,
  contractStartDateOnOrderChange,
  selectable,
}: IPropertyListItemRentHeaderProps) => {
  // Render
  return (
    <PropertyListItemHeader
      addressOrder={addressOrder}
      addressOnChangeOrder={addressOnChangeOrder}
      selectable={selectable}
    >
      <PropertyListItemItem
        text={getLocalizedText("properties.owners")}
        style={{ width: WIDTH_OWNERS }}
        contentStyle={{ justifyContent: "center" }}
      />

      <PropertyListItemSpacer />

      <PropertyListItemItem
        text={getLocalizedText("properties.tenants")}
        style={{ width: WIDTH_TENANTS }}
      />

      <S.StartDateWrap>
        <PropertyListItemItem
          style={{
            width: WIDTH_CONTRACT_DATE,
          }}
          text={getLocalizedText("contract.startdate")}
          sortable={{
            order: contractStartDateOrder,
            onOrderChange: contractStartDateOnOrderChange,
          }}
        />
      </S.StartDateWrap>

      {/* <S.EndDateWrap>
        <PropertyListItemItem
          text={getLocalizedText("contract.enddate")}
          style={{ width: WIDTH_CONTRACT_DATE }}
        />
      </S.EndDateWrap> */}

      <PropertyListItemSpacer />

      {statuses.map(key => (
        <PropertyListItemItem
          key={key}
          text={getLocalizedText(`${key}.short`)}
          tooltipContent={getLocalizedText(key)}
          style={{ flex: 1 }}
          contentStyle={{ justifyContent: "center" }}
        />
      ))}
    </PropertyListItemHeader>
  );
};

// eslint-disable-next-line import/no-default-export
export default PropertyListItemRentHeader;
