import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EPayInStatus,
  EPayInType,
} from "@rentiohq/shared-frontend/dist/types/payin.types";
import {
  append,
  appendWhere,
} from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Banner,
  Link,
  Loading,
  Page,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { TabAction } from "@rentiohq/web-shared/dist/types";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { PayInsFollowUp } from "scenes/Properties/scenes/Payments/components/PayInsFollowUp";
import { OpenPaymentRequests } from "../../../components/OpenPaymentRequests";
import { ts } from "../../../services";
import CollectionFollowUp from "../../Properties/scenes/Payments/components/CollectionFollowUp";
import CollectionFollowUpV2 from "../../Properties/scenes/Payments/components/CollectionFollowUpV2";
import { getPaymentRequestsFilter } from "./Payments.utils";

const FOLLOW_UP_SUB_ROUTE = "follow-up";
const PAYMENTS_SUB_ROUTE = "payments";

const PATH_PAYMENTS = `/${FOLLOW_UP_SUB_ROUTE}/${PAYMENTS_SUB_ROUTE}`;

const PATH_OPEN_PAYMENTS = `${PATH_PAYMENTS}/open-payments`;
const PATH_UNMATCHED_PAY_INS = `${PATH_PAYMENTS}/unmatched-pay-ins`;
const PATH_MISSING_KYCS = `${PATH_PAYMENTS}/missing-kycs`;

export const Payments = () => {
  // Hooks
  const location = useLocation();

  const { internalModeEnabled } = useInternalMode();

  const hasPaymentV2ForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.PaymentV2,
  );

  const hasIbanisationForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Ibanisation,
  );

  const { count: paymentRequestsCount } = useCount({
    countBase: append("/payment-requests/count", {
      where: getPaymentRequestsFilter({}).where,
    }),
  });

  const { count: payInsCount } = useCount({
    countBase: hasIbanisationForBroker
      ? appendWhere("payment-pay-ins/count", {
          type: EPayInType.Ibanisation,
          status: {
            inq: [EPayInStatus.MatchedPartially, EPayInStatus.Unmatched],
          },
        })
      : undefined,
  });

  const { count: missingKycsCount, isFetching: isFetchingMissingKycsCount } =
    useCount({
      countBase: "/payment-orders/missing-kycs/count",
    });

  const getTabs = () => {
    const tabs: TabAction[] = [];

    tabs.push({
      content: getLocalizedText("payments.follow_up.payment_requests.heading"),
      url: PATH_PAYMENTS,
      isActive: !!matchPath(PATH_PAYMENTS, location.pathname),
      count: paymentRequestsCount,
    });

    if (hasIbanisationForBroker) {
      tabs.push({
        content: getLocalizedText("follow_up.unmatched_pay_ins.title"),
        url: PATH_UNMATCHED_PAY_INS,
        isActive: !!matchPath(PATH_UNMATCHED_PAY_INS, location.pathname),
        count: payInsCount,
      });
    }

    tabs.push({
      content: getLocalizedText("payments.collection.follow_up.heading"),
      url: PATH_MISSING_KYCS,
      isActive: !!matchPath(PATH_MISSING_KYCS, location.pathname),
      count: missingKycsCount,
    });

    return tabs;
  };

  const renderContent = () => {
    if (!!matchPath(PATH_OPEN_PAYMENTS, location.pathname)) {
      return <OpenPaymentRequests />;
    }

    if (!!matchPath(PATH_UNMATCHED_PAY_INS, location.pathname)) {
      return <PayInsFollowUp />;
    }

    if (!!matchPath(PATH_MISSING_KYCS, location.pathname)) {
      if (internalModeEnabled && hasPaymentV2ForBroker) {
        return <CollectionFollowUpV2 />;
      }
      return <CollectionFollowUp />;
    }

    return <OpenPaymentRequests />;
  };

  return (
    <Page
      title={ts.paymentsFollowUpHeading()}
      metadata={
        <>
          {ts.followUpPaymentsPageDescription()}

          {isFetchingMissingKycsCount && (missingKycsCount || 0) === 0 && (
            <Loading />
          )}

          {!matchPath(PATH_MISSING_KYCS, location.pathname) &&
            (missingKycsCount || 0) > 0 && (
              <>
                <Spacer weight={ESpacerWeight.W16} />
                <Banner
                  title={getLocalizedText(
                    "payments.follow_up.kyc.banner.title",
                    {
                      value: `${missingKycsCount || 0}`,
                    },
                  )}
                  icon="alertDiamond"
                  variation="warning"
                  hasDismiss={false}
                >
                  <TextStyle>
                    {getLocalizedText("payments.follow_up.kyc.banner.content", {
                      value: `${missingKycsCount}`,
                    })}
                  </TextStyle>

                  <Spacer weight={ESpacerWeight.W08} />

                  <Link to="/follow-up/payments/missing-kycs">
                    {getLocalizedText("payments.follow_up.kyc.banner.link")}
                  </Link>
                </Banner>
              </>
            )}
        </>
      }
      fullWidth
      tabs={getTabs()}
    >
      {renderContent()}
    </Page>
  );
};
