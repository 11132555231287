import { SHOW_APP_MODAL } from "@rentiohq/web-shared-next/dist/redux/actions";
import { appModalSelector } from "@rentiohq/web-shared-next/dist/redux/selectors";
import { MuiDialog } from "@rentiohq/web-shared-next/dist/ui-components";
import { useDispatch, useSelector } from "react-redux";
import { OpenAppCard } from "../../../components/OpenAppCard";

export const MobileAppModal = () => {
  const dispatch = useDispatch();

  const appModal = useSelector(appModalSelector);
  const { showAppModal, title } = appModal;

  const handleClose = () => {
    dispatch({
      type: SHOW_APP_MODAL,
      payload: { showAppModal: false },
    });
  };

  return (
    <MuiDialog
      title={title}
      open={showAppModal}
      maxWidth="700px"
      width="700px"
      toggleOpen={handleClose}
    >
      <OpenAppCard />
    </MuiDialog>
  );
};
