export var sidebarStateSelector = function (state) {
    return state.uiReducer.isSidebarOpen;
};
export var snackbarSelector = function (state) {
    return state.uiReducer.snackbar;
};
export var loadingStateSelector = function (state) {
    return state.uiReducer.isLoading;
};
export var confirmModalSelector = function (state) {
    return state.uiReducer.confirmModal;
};
export var appModalSelector = function (state) {
    return state.uiReducer.appModal;
};
