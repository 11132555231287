import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EField as EEpcField } from "@rentiohq/shared-frontend/dist/forms/epc/schema.epcInfo.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { EEpcBasedIndexationType, ELeaseType, } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EPropertyEPCLabel } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { isFlemishZip } from "@rentiohq/shared-frontend/dist/utils/property.utils";
import { ts } from "../../services";
import utils from "../../utils";
import { EField } from "./schema.indexContract.types";
import { checkIfEpcExpired, getMaximumPrice as getMaximumPriceBase, } from "./schema.indexContract.utils";
var canIndex = function (formData, extraData) {
    var indexInfo = extraData.indexInfo, property = extraData.property, isCommercialLease = extraData.isCommercialLease;
    var epcExpired = checkIfEpcExpired(formData[EField.EpcExpirationDate]);
    var epcLabel = (formData[EEpcField.Label] ||
        EPropertyEPCLabel.Unknown);
    var noEPC = [
        EPropertyEPCLabel.Unknown,
        EPropertyEPCLabel.NoEPCAvailable,
    ].includes(epcLabel);
    var forceFullIndexation = formData[EField.ForceFullIndexation];
    if (forceFullIndexation) {
        return indexInfo.increase !== 0;
    }
    if (!isCommercialLease && (epcExpired || noEPC)) {
        var propertyInFlanders = (property === null || property === void 0 ? void 0 : property.zip) && isFlemishZip(property.zip);
        if (!propertyInFlanders) {
            return false;
        }
    }
    switch (epcLabel) {
        case EPropertyEPCLabel.APlus:
        case EPropertyEPCLabel.A:
        case EPropertyEPCLabel.B:
        case EPropertyEPCLabel.C:
            return indexInfo.increase !== 0;
        case EPropertyEPCLabel.D:
            return indexInfo.increaseEpcD !== 0;
        case EPropertyEPCLabel.E:
            return indexInfo.increaseEpcE !== 0;
        case EPropertyEPCLabel.F:
            return indexInfo.increaseEpcF !== 0;
        case EPropertyEPCLabel.G:
            return indexInfo.increaseEpcG !== 0;
        case EPropertyEPCLabel.Unknown:
        case EPropertyEPCLabel.NoEPCAvailable:
        default:
            return indexInfo.increaseNoEpc !== 0;
    }
};
var showEpcBasedLimitationInfoSubtitle = function (formData, extraData) {
    var indexInfo = extraData.indexInfo;
    var epcLabel = (formData[EEpcField.Label] ||
        EPropertyEPCLabel.Unknown);
    var epcExpired = checkIfEpcExpired(formData[EField.EpcExpirationDate]);
    var noEPC = [
        EPropertyEPCLabel.Unknown,
        EPropertyEPCLabel.NoEPCAvailable,
    ].includes(epcLabel);
    if (epcExpired || noEPC) {
        return true;
    }
    if (indexInfo.epcBasedIndexationType === EEpcBasedIndexationType.Regular) {
        return false;
    }
    var forceFullIndexation = formData[EField.ForceFullIndexation];
    if (forceFullIndexation) {
        return true;
    }
    var newPrice = indexInfo.newPrice;
    switch (epcLabel) {
        case EPropertyEPCLabel.APlus:
        case EPropertyEPCLabel.A:
        case EPropertyEPCLabel.B:
        case EPropertyEPCLabel.C:
            break;
        case EPropertyEPCLabel.D:
            newPrice = indexInfo.newPriceEpcD;
            break;
        case EPropertyEPCLabel.E:
            newPrice = indexInfo.newPriceEpcE;
            break;
        case EPropertyEPCLabel.F:
            newPrice = indexInfo.newPriceEpcF;
            break;
        case EPropertyEPCLabel.G:
            newPrice = indexInfo.newPriceEpcG;
            break;
        case EPropertyEPCLabel.Unknown:
        case EPropertyEPCLabel.NoEPCAvailable:
        default:
            newPrice = indexInfo.newPriceNoEpc;
            break;
    }
    return newPrice !== indexInfo.newPrice;
};
var getMaximumPrice = function (params) {
    var formData = params.formData, extraData = params.extraData;
    var forceFullIndexation = formData[EField.ForceFullIndexation] || false;
    var isCommercialLease = [
        ELeaseType.CommercialLease,
        ELeaseType.Other,
    ].includes(extraData.contract.leaseType);
    var epcLabel = formData[EEpcField.Label];
    var epcExpired = checkIfEpcExpired(formData[EField.EpcExpirationDate]);
    return getMaximumPriceBase({
        indexInfo: extraData.indexInfo,
        epcLabel: epcLabel,
        epcExpired: epcExpired,
        isCommercialLease: isCommercialLease,
        forceFullIndexation: forceFullIndexation,
    });
};
var getEpcBasedLimitationInfoSubtitle = function (extraData) { return function (state, formData) {
    var indexInfo = extraData.indexInfo, property = extraData.property, contract = extraData.contract;
    var forceFullIndexation = formData[EField.ForceFullIndexation];
    if (forceFullIndexation) {
        return getLocalizedText("contract.index.epc_info.force_full");
    }
    var epcKey;
    var correctionFactorForEpc;
    var newPriceForEpc;
    var increaseForEpc;
    var epcLabel = formData[EEpcField.Label];
    var epcExpired = checkIfEpcExpired(formData[EField.EpcExpirationDate]);
    var noEPC = [
        EPropertyEPCLabel.Unknown,
        EPropertyEPCLabel.NoEPCAvailable,
    ].includes(epcLabel);
    switch (epcLabel) {
        case EPropertyEPCLabel.APlus:
        case EPropertyEPCLabel.A:
        case EPropertyEPCLabel.B:
        case EPropertyEPCLabel.C:
            // Info banner not shown since default indexation is allowed
            break;
        case EPropertyEPCLabel.D:
            newPriceForEpc = indexInfo.newPriceEpcD;
            increaseForEpc = indexInfo.increaseEpcD;
            correctionFactorForEpc = indexInfo.correctionFactorEpcD;
            if (indexInfo.epcBasedIndexationType === EEpcBasedIndexationType.Corrected) {
                epcKey = "contract.index.epc_info.epc.corrected";
                break;
            }
            break;
        case EPropertyEPCLabel.E:
            newPriceForEpc = indexInfo.newPriceEpcE;
            increaseForEpc = indexInfo.increaseEpcE;
            correctionFactorForEpc = indexInfo.correctionFactorEpcE;
            if (indexInfo.epcBasedIndexationType === EEpcBasedIndexationType.Corrected) {
                epcKey = "contract.index.epc_info.epc.corrected";
                break;
            }
            break;
        case EPropertyEPCLabel.F:
            newPriceForEpc = indexInfo.newPriceEpcF;
            increaseForEpc = indexInfo.increaseEpcF;
            correctionFactorForEpc = indexInfo.correctionFactorEpcF;
            if (indexInfo.epcBasedIndexationType === EEpcBasedIndexationType.Corrected) {
                epcKey = "contract.index.epc_info.epc.corrected";
                break;
            }
            break;
        case EPropertyEPCLabel.G:
            newPriceForEpc = indexInfo.newPriceEpcG;
            increaseForEpc = indexInfo.increaseEpcG;
            correctionFactorForEpc = indexInfo.correctionFactorEpcG;
            if (indexInfo.epcBasedIndexationType === EEpcBasedIndexationType.Corrected) {
                epcKey = "contract.index.epc_info.epc.corrected";
                break;
            }
            break;
        case EPropertyEPCLabel.Unknown:
        case EPropertyEPCLabel.NoEPCAvailable:
        default:
            newPriceForEpc = indexInfo.newPriceNoEpc;
            increaseForEpc = indexInfo.increaseNoEpc;
            correctionFactorForEpc = indexInfo.correctionFactorNoEpc;
            if ([ELeaseType.CommercialLease, ELeaseType.Other].includes(contract.leaseType)) {
                epcKey = "contract.index.epc_info.contract.lease_type.commercial";
                break;
            }
            if (indexInfo.epcBasedIndexationType === EEpcBasedIndexationType.Corrected) {
                epcKey = "contract.index.epc_info.epc_unknown.corrected";
                break;
            }
            break;
    }
    if (epcExpired) {
        newPriceForEpc = indexInfo.newPriceNoEpc;
        increaseForEpc = indexInfo.increaseNoEpc;
        correctionFactorForEpc = indexInfo.correctionFactorNoEpc;
        if (indexInfo.epcBasedIndexationType === EEpcBasedIndexationType.Corrected) {
            epcKey = "contract.index.epc_info.epc_expired.corrected";
        }
    }
    if (epcExpired || noEPC) {
        var propertyInFlanders = (property === null || property === void 0 ? void 0 : property.zip) && isFlemishZip(property.zip);
        if (!propertyInFlanders) {
            return getLocalizedText("contract.index.not_allowed");
        }
    }
    if (epcKey) {
        return getLocalizedText(epcKey, {
            epcLabel: getLocalizedText("epc.label.".concat(epcLabel).toLowerCase()),
            correctionFactor: correctionFactorForEpc
                ? "".concat(correctionFactorForEpc.toFixed(3))
                : "",
            newPriceBase: formatCurrency(indexInfo.newPrice),
            increaseBase: formatCurrency(indexInfo.increase),
            newPriceForEpc: newPriceForEpc ? formatCurrency(newPriceForEpc) : "",
            increaseForEpc: increaseForEpc ? formatCurrency(increaseForEpc) : "",
        });
    }
}; };
var getAdjustedRetroactiveAmountLabel = function (extraData) {
    var indexInfo = extraData.indexInfo;
    var retroactivityPrice = indexInfo.retroactivityPrice, retroactivityMonths = indexInfo.retroactivityMonths, retroactivityPeriodEnd = indexInfo.retroactivityPeriodEnd, retroactivityPeriodStart = indexInfo.retroactivityPeriodStart;
    return ts.contractIndexRetroactivity({
        values: {
            retroactiveMonths: retroactivityMonths || 0,
            retroactivePrice: formatCurrency(retroactivityPrice || 0),
            retroactivityPeriodEnd: retroactivityPeriodEnd
                ? utils.date.format(retroactivityPeriodEnd)
                : "",
            retroactivityPeriodStart: retroactivityPeriodStart
                ? utils.date.format(retroactivityPeriodStart)
                : "",
        },
    });
};
export var getSchema = function (extraData, initialValues) {
    return function (defaultValues) {
        var _a;
        var _b;
        return {
            required: [EField.NewPrice],
            properties: (_a = {},
                _a[EField.EpcBasedLimitationInfo] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function (formData) {
                        return showEpcBasedLimitationInfoSubtitle(formData, extraData);
                    },
                },
                _a[EField.NewPrice] = {
                    default: defaultValues[EEpcField.Label] || (extraData === null || extraData === void 0 ? void 0 : extraData.isCommercialLease)
                        ? defaultValues[EField.NewPrice] ||
                            getMaximumPrice({
                                formData: defaultValues,
                                extraData: extraData,
                            })
                        : undefined,
                    // @ts-ignore
                    showIf: function (formData) { return canIndex(formData, extraData); },
                    minimum: 0,
                    getMaximum: function (formData) {
                        return getMaximumPrice({
                            formData: formData,
                            extraData: extraData,
                        });
                    },
                },
                _a[EField.Retroactivity] = {
                    type: "boolean",
                    default: getValue(EField.Retroactivity, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return (canIndex(formData, extraData) &&
                            (extraData.indexInfo.retroactivityMonths || 0) > 0 &&
                            (extraData.indexInfo.increase || 0) > 0);
                    },
                },
                _a[EField.GenerateLetter] = {
                    type: "boolean",
                    default: getValue(EField.GenerateLetter, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return extraData.showGenerateLetter && canIndex(formData, extraData);
                    },
                },
                _a[EField.LetterType] = {
                    default: getValue(EField.LetterType, initialValues, defaultValues),
                    // @ts-ignore
                    options: (_b = extraData.letterTemplates) === null || _b === void 0 ? void 0 : _b.map(function (template) { return ({
                        id: template.id,
                        value: template.name,
                    }); }),
                    showIf: function (formData) {
                        return formData[EField.GenerateLetter] === true &&
                            canIndex(formData, extraData);
                    },
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData, initialValues) {
    var _a;
    return _a = {},
        _a[EField.NewPrice] = {
            "ui:field": ERentioFormField.Number,
            title: ts.contractIndexPriceNew(),
            suffixText: "EUR",
            isFloat: true,
            decimals: CONFIG.DECIMALS_FOR_CURRENCY,
            getInfo: function (formData) {
                var maxPrice = getMaximumPrice({
                    formData: formData,
                    extraData: extraData,
                });
                return ts.contractIndexPriceNewDescription({
                    values: {
                        maximumPrice: formatCurrency(maxPrice),
                    },
                });
            },
        },
        _a[EField.EpcBasedLimitationInfo] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("contract.index.epc_info.title"),
            getSubtitle: getEpcBasedLimitationInfoSubtitle(extraData),
            actionTitle: getLocalizedText("contract.index.epc_info.more_info.text"),
            onPressAction: function () {
                var url = getLocalizedText("contract.index.epc_info.more_info.link");
                window.open(url, "_blank");
            },
        },
        _a[EField.Retroactivity] = {
            "ui:field": ERentioFormField.Switch,
            getLabel: function (formData) { return getAdjustedRetroactiveAmountLabel(extraData); },
        },
        _a[EField.GenerateLetter] = {
            "ui:field": ERentioFormField.Switch,
            label: getLocalizedText("contract.index.generate_letter"),
        },
        _a[EField.LetterType] = {
            "ui:field": ERentioFormField.DropdownSelect,
            label: getLocalizedText("contract.index.letter_type"),
        },
        _a;
};
