import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import {
  EMoveServiceManager,
  IMoveRequest,
} from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.types";
import { getExternalUrl } from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.utils";
import {
  isRequestingMoveService,
  requestMoveServiceError,
} from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.selectors";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { ASSETS } from "@rentiohq/web-assets";
import {
  Button,
  IModalHeaderProps,
  Icon,
  Modal,
  MultiStepForm,
  OptionListItem,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import requestMoveServiceSchemas from "forms/requestMoveService";
import {
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "forms/requestMoveService/schema.requestMoveService.actions";
import React from "react";
import { NumberParam } from "serialize-query-params";
import { ts } from "../../services";
import * as S from "../LocationDescriptionModal/LocationDescriptionModal.styled";

const { i18n } = utils;

enum EStep {
  Intro,
  Form,
  Outro,
}

interface IProps {
  property: IProperty;
  contract: IContract;
  onClose: () => void;
}

const RequestMoveServiceModal: React.FC<IProps> = props => {
  const { property, contract, onClose } = props;

  const [, setQueryParamValue] = useQueryParams({
    moveRequestId: NumberParam,
  });

  // Redux
  const { isBroker } = authHooks.useSelf();

  // State
  const [didConfirmDataSharing, setDidConfirmDataSharing] =
    React.useState(false);
  const [showDidConfirmDataSharingError, setShowDidConfirmDataSharingError] =
    React.useState(false);
  const [formId] = React.useState(generateFormId());
  const [step, setStep] = React.useState<EStep>(EStep.Intro);
  const [moveRequest, setMoveRequest] = React.useState<
    IMoveRequest | undefined
  >(undefined);

  // Render
  const renderIntro = () => (
    <>
      <Modal
        width="medium"
        onClose={onClose}
        heading={getLocalizedText("request_move_service.modal.heading")}
        actions={[
          {
            content: ts.system("cancel"),
            onClick: onClose,
            appearance: "outline",
          },
          {
            content: ts.system("next"),
            onClick: () => {
              if (!didConfirmDataSharing) {
                setShowDidConfirmDataSharingError(true);
                return;
              }

              setShowDidConfirmDataSharingError(false);
              setStep(EStep.Form);
            },
            appearance: "primary",
          },
        ]}
      >
        {i18n.tm("request_move_service.modal.intro.content", {
          markdownProps: { listAsChecklist: true },
        })}

        {/* <Button onClick={handleClickTerms} appearance="link">
            {getLocalizedText("request_move_service.modal.intro.cta.terms.title")}
          </Button> */}

        <Spacer weight={ESpacerWeight.W16} />

        <OptionListItem
          type={"checkbox"}
          title={getLocalizedText(
            "request_move_service.modal.intro.checkbox.data_sharing.title",
          )}
          error={showDidConfirmDataSharingError ? "_" : undefined}
          selected={didConfirmDataSharing}
          onChange={() => {
            setDidConfirmDataSharing(!didConfirmDataSharing);
          }}
        />
      </Modal>
    </>
  );

  const renderForm = () => {
    return (
      <MultiStepForm
        formId={`request-move_service-services-${formId}`}
        schemas={requestMoveServiceSchemas({
          property,
          contract,
        })}
        asModal={true}
        withAside={false}
        onPressPreviousFirstStep={() => {
          setStep(EStep.Intro);
        }}
        modalProps={{
          onClose,
          heading: getLocalizedText("request_move_service.modal.heading"),
          width: "medium",
        }}
        performingSubmitActionResultSelector={performingSubmitActionResultSelector(
          contract.id,
        )}
        isPerformingSubmitActionSelector={state =>
          isRequestingMoveService(state, contract.id)
        }
        performingSubmitActionErrorSelector={state =>
          requestMoveServiceError(state, contract.id)
        }
        submitActionCreator={submitActionCreator({
          property,
          contract,
        })}
        onSuccess={(moveRequest?: IMoveRequest) => {
          setMoveRequest(moveRequest);
          setStep(EStep.Outro);
        }}
        submitLabel={getLocalizedText("system.submit")}
      />
    );
  };

  const renderOutro = () => {
    if (!moveRequest) {
      return null;
    }

    if (moveRequest.manager === EMoveServiceManager.TenantAndBroker) {
      return (
        <Modal
          width="medium"
          onClose={onClose}
          heading={getLocalizedText("request_move_service.modal.heading")}
          actions={[
            {
              content: ts.system("next"),
              onClick: () => {
                setTimeout(() => {
                  window.open(getExternalUrl(moveRequest, isBroker), "_blank");
                }, 10);

                onClose();
              },
              appearance: "primary",
            },
          ]}
        >
          {i18n.tm(
            `request_move_service.modal.outro.content.${stringToSnakeCase(
              moveRequest?.manager || EMoveServiceManager.Tenant,
            )}`,
            {
              markdownProps: { listAsChecklist: true },
            },
          )}
        </Modal>
      );
    }

    return (
      <Modal
        width="medium"
        onClose={onClose}
        actions={[
          {
            content: ts.system("close"),
            onClick: () => {
              setQueryParamValue({ moveRequestId: moveRequest.id });

              onClose();
            },
            appearance: "primary",
          },
        ]}
        components={{
          Header: ({ onClose }: IModalHeaderProps) => (
            <S.ModalHeader>
              <S.CloseModal>
                <Button appearance="plain" onClick={onClose}>
                  <Icon source="close" />
                </Button>
              </S.CloseModal>
              <img src={ASSETS.successStar} alt="success" />
            </S.ModalHeader>
          ),
        }}
      >
        {i18n.tm(
          `request_move_service.modal.outro.content.${stringToSnakeCase(
            moveRequest?.manager || EMoveServiceManager.Tenant,
          )}`,
          {
            markdownProps: { listAsChecklist: true },
          },
        )}
      </Modal>
    );
  };

  switch (step) {
    case EStep.Form:
      return renderForm();

    case EStep.Outro:
      return renderOutro();

    case EStep.Intro:
    default:
      return renderIntro();
  }
};

// eslint-disable-next-line import/no-default-export
export default RequestMoveServiceModal;
