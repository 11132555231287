import * as actions from "./costCenter.actions";
import * as api from "./costCenter.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY } from "./costCenter.constants";
import * as hooks from "./costCenter.hooks";
import * as promises from "./costCenter.promises";
import { CostCenterReducer } from "./costCenter.reducer";
import { saga } from "./costCenter.saga";
import * as selectors from "./costCenter.selectors";
export var REDUCER_KEY_COST_CENTER = REDUCER_KEY;
export var costCenterModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: CostCenterReducer,
    saga: saga,
    selectors: selectors,
    utils: {},
};
