import { EPropertyMemberTypes, EPropertyTypeId, EPropertyTypes, } from "../../types/property.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { EPropertyFetchType } from "./property.types";
export var isResidential /* House or apartment */ = function (property) {
    return (property === null || property === void 0 ? void 0 : property.typeId) &&
        [EPropertyTypeId.House, EPropertyTypeId.Apartment].includes(property.typeId);
};
export var PROPERTY_TYPES = [
    {
        maxImmoType: null,
        label: EPropertyTypes.Group,
        id: EPropertyTypeId.Group,
        icon: "buildings",
    },
    {
        maxImmoType: "house",
        label: EPropertyTypes.House,
        id: EPropertyTypeId.House,
        icon: "house",
    },
    {
        maxImmoType: "apartment",
        label: EPropertyTypes.Apartment,
        id: EPropertyTypeId.Apartment,
        icon: "apartment",
    },
    {
        maxImmoType: "room",
        label: EPropertyTypes.StudentResidence,
        id: EPropertyTypeId.StudentResidence,
        icon: "studentHousing",
    },
    {
        maxImmoType: "commercial",
        label: EPropertyTypes.Commercial,
        id: EPropertyTypeId.Commercial,
        icon: "properties",
    },
    {
        maxImmoType: "office",
        label: EPropertyTypes.Office,
        id: EPropertyTypeId.Office,
        icon: "officeOutdoors",
    },
    {
        maxImmoType: "plot",
        label: EPropertyTypes.ParkingLot,
        id: EPropertyTypeId.ParkingLot,
        icon: "parkingSpot",
    },
    {
        maxImmoType: "garage",
        label: EPropertyTypes.Garage,
        id: EPropertyTypeId.Garage,
        icon: "garage",
    },
    {
        maxImmoType: "storage",
        label: EPropertyTypes.Storage,
        id: EPropertyTypeId.Storage,
        icon: "storage",
    },
    {
        maxImmoType: "shed",
        label: EPropertyTypes.Shed,
        id: EPropertyTypeId.Shed,
        icon: "houseWrench",
    },
    {
        maxImmoType: "warehouse",
        label: EPropertyTypes.Warehouse,
        id: EPropertyTypeId.Warehouse,
        icon: "warehouse",
    },
];
export var getPropertyTypeString = function (_a) {
    var typeId = _a.typeId;
    var propertyType = PROPERTY_TYPES.find(function (type) { return type.id === typeId; });
    if (!propertyType) {
        return;
    }
    return getLocalizedText("property.type.".concat(propertyType.label));
};
var mapRolesToObject = function (roles) {
    return roles.map(function (el) {
        var _a = el.split("-"), id = _a[0], role = _a[1];
        return {
            id: parseInt(id),
            role: role,
        };
    });
};
export var getRolesFromOwnerId = function (ownerId, roles) {
    var newMemberRoles = [];
    mapRolesToObject(roles || []).forEach(function (el) {
        if (el.id === ownerId) {
            newMemberRoles.push(el.role);
        }
    });
    return newMemberRoles;
};
export var checkValidMemberRoles = function (roles) {
    var newMemberRoles = [];
    mapRolesToObject(roles).forEach(function (el) {
        newMemberRoles.push(el.role);
    });
    return (newMemberRoles.includes(EPropertyMemberTypes.FinancialManager) &&
        newMemberRoles.includes(EPropertyMemberTypes.TechnicalManager));
};
export var getFilter = function (type, parentIdInGroup) {
    switch (type) {
        case EPropertyFetchType.Owned:
            return {
                filter: { archivedAt: { is: null } },
                rolesFilter: [
                    EPropertyMemberTypes.Owner,
                    EPropertyMemberTypes.TechnicalManager,
                    EPropertyMemberTypes.FinancialManager,
                ],
            };
        case EPropertyFetchType.Rented:
            return {
                filter: { archivedAt: { is: null } },
                rolesFilter: [EPropertyMemberTypes.Tenant, EPropertyMemberTypes.Parent],
            };
        case EPropertyFetchType.Groups:
            return { filter: { archivedAt: { is: null }, typeId: { eq: 1 } } };
        case EPropertyFetchType.InGroup:
            return {
                filter: parentIdInGroup
                    ? {
                        archivedAt: { is: null },
                        parentId: { eq: parentIdInGroup },
                    }
                    : {
                        archivedAt: { is: null },
                        parentId: { isNot: null },
                    },
            };
        default:
            return {};
    }
};
export var getIdentifierForPropertiesByContactId = function (contactId) {
    return "contact-".concat(contactId);
};
