import { Box } from "@rebass/grid";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  DisplayText,
  ESpacings,
  IOptionListOption,
  OptionListShared,
} from "@rentiohq/web-shared/dist/components";
import { useFeatureFlag } from "@rentiohq/web-shared/dist/hooks/useFeatureFlag";
import React from "react";
import { EFeatureFlags } from "types/featureFlags";
import {
  IPaymentFollowUpMethodProps,
  TModalTypes,
} from "../PaymentFollowUpModal.types";

export const MethodSelectionScreen: React.FC<IPaymentFollowUpMethodProps> = ({
  onMethodChange,
  step,
  renderActivities,
}) => {
  const hasSmsFollowUp = useFeatureFlag(
    EFeatureFlags.Payments.FollowUpSMS,
    false,
  );

  const options: IOptionListOption<TModalTypes>[] = [];

  options.push({
    id: "app",
    value: "app",
    label: getLocalizedText("payments.follow_up.modal.method.option.app.label"),
    icon: "messagesBubbleSquareText",
  });

  if (hasSmsFollowUp) {
    options.push({
      id: "sms",
      value: "sms",
      label: getLocalizedText(
        "payments.follow_up.modal.method.option.sms.label",
      ),
      icon: "smartphone",
    });
  }

  options.push({
    id: "phone",
    value: "phone",
    label: getLocalizedText(
      "payments.follow_up.modal.method.option.phone.label",
    ),
    icon: "phoneIncomingCall",
  });

  options.push({
    id: "mail",
    value: "mail",
    label: getLocalizedText(
      "payments.follow_up.modal.method.option.mail.label",
    ),
    icon: "email",
  });

  options.push({
    id: "registeredMail",
    value: "registeredMail",
    label: getLocalizedText(
      "payments.follow_up.modal.method.option.registered_mail.label",
    ),
    icon: "email",
  });

  return (
    <div>
      <Box mb={ESpacings.loose}>
        <DisplayText size="medium">
          {getLocalizedText("payments.follow_up.modal.method.label")}
        </DisplayText>

        <OptionListShared
          id="followUpMethod"
          variant="card"
          onChange={onMethodChange}
          value={[step]}
          options={options}
        />
      </Box>

      {renderActivities}
    </div>
  );
};
