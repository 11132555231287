var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeLatest } from "redux-saga/effects";
import logger from "../../logger";
import * as contractApiV2 from "../../reduxV2/contract/contract.api";
import * as propertyActionsV2 from "../../reduxV2/property/property.actions";
import * as propertyApiV2 from "../../reduxV2/property/property.api";
import { showAlert } from "../../utils/alert/alert.utils";
import * as moveRequestActions from "../moveRequest/moveRequest.actions";
import * as moveRequestApi from "../moveRequest/moveRequest.api";
import * as contractActions from "./contract.actions";
import * as contractApi from "./contract.api";
function requestInsuranceStartFlow(_a) {
    var contractId, insuranceExternalAccessCode, propertyId, updatedProperty, property, contract, unknownError_1, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                contractId = payload.contractId, insuranceExternalAccessCode = payload.insuranceExternalAccessCode, propertyId = payload.propertyId, updatedProperty = payload.updatedProperty;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 7, , 9]);
                return [4 /*yield*/, call(propertyApiV2.update, {
                        id: propertyId,
                        data: updatedProperty,
                    })];
            case 2:
                property = (_b.sent()).data;
                return [4 /*yield*/, put(propertyActionsV2.updateSucceeded.getAction({
                        id: propertyId,
                        result: property,
                    }))];
            case 3:
                _b.sent();
                // Request insurance
                return [4 /*yield*/, call(contractApi.requestInsurance, insuranceExternalAccessCode)];
            case 4:
                // Request insurance
                _b.sent();
                return [4 /*yield*/, call(contractApiV2.getDetail, {
                        id: contractId,
                    })];
            case 5:
                contract = (_b.sent()).data;
                return [4 /*yield*/, put(contractActions.requestInsurance.actions.success({
                        contractId: contractId,
                        contract: contract.data,
                    }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 9];
            case 7:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                showAlert({ type: "error", error: error });
                logger.logError({ error: error });
                return [4 /*yield*/, put(contractActions.requestInsurance.actions.failure({
                        error: error,
                        contractId: contractId,
                    }))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function requestMoveServiceStartFlow(_a) {
    var contractId, propertyId, updatedProperty, requestMoveService, property, moveRequestCreated, moveRequest, contract, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                contractId = payload.contractId, propertyId = payload.propertyId, updatedProperty = payload.updatedProperty, requestMoveService = payload.requestMoveService;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 9, , 11]);
                return [4 /*yield*/, call(propertyApiV2.update, {
                        id: propertyId,
                        data: updatedProperty,
                    })];
            case 2:
                property = (_b.sent()).data;
                return [4 /*yield*/, put(propertyActionsV2.updateSucceeded.getAction({
                        id: propertyId,
                        result: property,
                    }))];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(contractApi.requestMoveService, requestMoveService)];
            case 4:
                moveRequestCreated = (_b.sent()).data;
                return [4 /*yield*/, call(moveRequestApi.getMoveRequestById, moveRequestCreated.id)];
            case 5:
                moveRequest = (_b.sent()).data;
                return [4 /*yield*/, put(moveRequestActions.getMoveRequest.actions.success({
                        id: moveRequest.id,
                        moveRequest: moveRequest,
                    }))];
            case 6:
                _b.sent();
                return [4 /*yield*/, call(contractApiV2.getDetail, {
                        id: contractId,
                    })];
            case 7:
                contract = (_b.sent()).data;
                return [4 /*yield*/, put(contractActions.requestMoveService.actions.success({
                        contractId: contractId,
                        contract: contract.data,
                    }))];
            case 8:
                _b.sent();
                return [3 /*break*/, 11];
            case 9:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                showAlert({ type: "error", error: error });
                logger.logError({ error: error });
                return [4 /*yield*/, put(contractActions.requestMoveService.actions.failure({
                        error: error,
                        contractId: contractId,
                    }))];
            case 10:
                _b.sent();
                return [3 /*break*/, 11];
            case 11: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(contractActions.requestInsurance.types.START, requestInsuranceStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(contractActions.requestMoveService.types.START, requestMoveServiceStartFlow)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line import/no-default-export
export default saga;
