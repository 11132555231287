export var EField;
(function (EField) {
    EField["Header"] = "_header";
    EField["Subtitle"] = "_subtitle";
    EField["StartState"] = "startState";
    EField["LeaseType"] = "leaseType";
    EField["LeaseTypeInfo"] = "leaseTypeInfo";
    EField["Owners"] = "owners";
    EField["TenantsInfo"] = "tenantsInfo";
    EField["Tenants"] = "tenants";
    EField["ParentsInfo"] = "parentsInfo";
    EField["Parents"] = "parents";
    EField["StartDate"] = "startDate";
    EField["Duration"] = "duration";
    EField["EndDate"] = "endDate";
    EField["TacitRenewalUnavailableInfo"] = "tacitRenewalUnavailableInfo";
    EField["TacitRenewalUnavailableInvalidInfo"] = "tacitRenewalUnavailableInvalidInfo";
    EField["TacitRenewal"] = "tacitRenewal";
    EField["TacitRenewalInfo"] = "tacitRenewalInfo";
    EField["IsPreviouslyRenewed"] = "isPreviouslyRenewed";
    // LengthType = "lengthType",
    EField["PayDate"] = "payDate";
    EField["PaymentRepetition"] = "paymentRepetition";
    EField["PriceInitial"] = "priceInitial";
    EField["CostsCommonInitial"] = "costsCommonInitial";
    EField["CostsNonCommonInitial"] = "costsNonCommonInitial";
    EField["RentDepositAmountPrefill"] = "rentDepositAmountPrefill";
    EField["RentDepositAmountPrefillPreview"] = "rentDepositAmountPrefillPreview";
    EField["RentDepositAmount"] = "rentDepositAmount";
    EField["BaseYear"] = "baseYear";
    EField["IndexInitial"] = "indexInitial";
    EField["IndexInitial2"] = "indexInitial2";
    EField["PriceCurrent"] = "priceCurrent";
    EField["CostsCommonCurrent"] = "costsCommonCurrent";
    EField["CostsNonCommonCurrent"] = "costsNonCommonCurrent";
    EField["PriceInitialVat"] = "priceInitialVat";
    EField["PriceInitialVatHelpInfo"] = "priceInitialVatHelpInfo";
    EField["PriceCurrentVat"] = "priceCurrentVat";
    EField["PriceCurrentVatHelpInfo"] = "priceCurrentVatHelpInfo";
    EField["CommonCostsCurrentVat"] = "commonCostsCurrentVat";
    EField["CommonCostsCurrentVatHelpInfo"] = "commonCostsCurrentVatHelpInfo";
    EField["CommonCostsInitialVat"] = "commonCostsInitialVat";
    EField["CommonCostsInitialVatHelpInfo"] = "commonCostsInitialVatHelpInfo";
    EField["NonCommonCostsCurrentVat"] = "nonCommonCostsCurrentVat";
    EField["NonCommonCostsCurrentVatHelpInfo"] = "nonCommonCostsCurrentVatHelpInfo";
    EField["NonCommonCostsInitialVat"] = "nonCommonCostsInitialVat";
    EField["NonCommonCostsInitialVatHelpInfo"] = "nonCommonCostsInitialVatHelpInfo";
    EField["LastIndexedOn"] = "lastIndexedOn";
    EField["HasDiscount"] = "hasDiscount";
    EField["DiscountAmount"] = "discountAmount";
    EField["DiscountOffset"] = "discountOffset";
    EField["DiscountStartDate"] = "discountStartDate";
    EField["PriceTypeCommonCostsInitial"] = "priceTypeCommonCostsInitial";
    EField["PriceTypeCommonCostsCurrent"] = "priceTypeCommonCostsCurrent";
    EField["PriceTypeNonCommonCostsInitial"] = "priceTypeNonCommonCostsInitial";
    EField["PriceTypeNonCommonCostsCurrent"] = "priceTypeNonCommonCostsCurrent";
    EField["EnableIbanisation"] = "enableIbanisation";
    EField["EnableIbanisationInfo"] = "enableIbanisationInfo";
    EField["TemplateDocumentContractCreateOption"] = "templateDocumentContractCreateOption";
    EField["TemplateDocumentContractCreateOptionInfo"] = "templateDocumentContractCreateOptionInfo";
    EField["TemplateDocumentSelected"] = "templateDocumentSelected";
    EField["PaymentOrderCreateOption"] = "paymentOrderCreateOption";
    EField["PaymentOrderCreateOptionInfo"] = "paymentOrderCreateOptionInfo";
    EField["PaymentOrderCreatePayer"] = "paymentOrderCreatePayer";
    EField["PaymentOrderCreatePayee"] = "paymentOrderCreatePayee";
    EField["PaymentOrderCreateIban"] = "paymentOrderCreateIban";
    EField["Iban"] = "rentDiscountBankAccountId";
})(EField || (EField = {}));
export var EContractStartState;
(function (EContractStartState) {
    EContractStartState["New"] = "new";
    EContractStartState["Existing"] = "existing";
})(EContractStartState || (EContractStartState = {}));
export var EContractDuration;
(function (EContractDuration) {
    EContractDuration["OneYear"] = "one_year";
    EContractDuration["TwoYears"] = "two_years";
    EContractDuration["ThreeYears"] = "three_years";
    EContractDuration["NineYears"] = "nine_years";
    EContractDuration["Other"] = "other";
})(EContractDuration || (EContractDuration = {}));
export var ETemplateDocumentContractCreateOption;
(function (ETemplateDocumentContractCreateOption) {
    ETemplateDocumentContractCreateOption["None"] = "none";
    ETemplateDocumentContractCreateOption["Create"] = "create";
    ETemplateDocumentContractCreateOption["CreateAndSign"] = "create_and_sign";
})(ETemplateDocumentContractCreateOption || (ETemplateDocumentContractCreateOption = {}));
export var EPaymentOrderCreateOption;
(function (EPaymentOrderCreateOption) {
    EPaymentOrderCreateOption["None"] = "none";
    EPaymentOrderCreateOption["Create"] = "create";
})(EPaymentOrderCreateOption || (EPaymentOrderCreateOption = {}));
export var EEnableIbanisation;
(function (EEnableIbanisation) {
    EEnableIbanisation["Disable"] = "disable";
    EEnableIbanisation["Enable"] = "enable";
})(EEnableIbanisation || (EEnableIbanisation = {}));
