import * as actions from "./paymentOrder.actions";
import * as api from "./paymentOrder.api";
import { BASE_PATH_ENTITY, ENTITY, REDUCER_KEY, } from "./paymentOrder.constants";
import * as hooks from "./paymentOrder.hooks";
import * as promises from "./paymentOrder.promises";
import reducer from "./paymentOrder.reducer";
import { saga } from "./paymentOrder.saga";
import * as selectors from "./paymentOrder.selectors";
export var REDUCER_KEY_PAYMENT_ORDERS = REDUCER_KEY;
export var paymentOrderModule = {
    entity: ENTITY,
    reducerKey: REDUCER_KEY,
    basePathEntity: BASE_PATH_ENTITY,
    reducerState: {},
    actions: actions,
    api: api,
    hooks: hooks,
    promises: promises,
    reducer: reducer,
    saga: saga,
    selectors: selectors,
    utils: {},
};
