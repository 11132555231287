import component from "./IndexCountRenderer";
import strategy from "./IndexCountRenderer.strategy";
export { INDEX_COUNT_RENDERER_TYPE } from "./IndexCountRenderer.strategy";
export * from "./IndexCountRenderer.types";
var decorator = {
    strategy: strategy,
    component: component,
};
// eslint-disable-next-line import/no-default-export
export default decorator;
