import { Box } from "@rebass/grid";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Banner,
  Button,
  ESpacings,
  Loading,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { BrokerContactForm } from "../components/BrokerContactForm";

export const AccountHolder: React.FC<{}> = () => {
  const [showForm, setShowForm] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const { user } = authHooks.useSelf();
  const financialContact = useSelector((state: IRootStore) =>
    user?.brokerId
      ? contactSelectors.getContactByCustomId(state, EContactCustomId.Financial)
      : undefined,
  );
  const isFetchingContact = useSelector((state: IRootStore) =>
    user?.brokerId
      ? state.contact.contactsById[EContactCustomId.Financial].isFetching
      : false,
  );

  React.useEffect(() => {
    if (!user) {
      return;
    }
    if (!financialContact && user.brokerId) {
      dispatch(
        contactActions.getContact.actions.start({
          contactId: EContactCustomId.Legal,
          brokerId: user.brokerId,
        }),
      );
    }
  }, [financialContact, user]);

  const handleShowFormClick = () => {
    setShowForm(true);
  };

  const renderContent = () => {
    if (!user?.brokerId) {
      return null;
    }

    if (isFetchingContact) {
      return <Loading />;
    }

    if (!financialContact && !showForm) {
      return (
        <Button appearance="primary" onClick={handleShowFormClick}>
          {getLocalizedText("company.account_holder.action.label")}
        </Button>
      );
    }

    return (
      <BrokerContactForm
        contact={financialContact}
        type={EContactCustomId.Financial}
        brokerId={user.brokerId}
      />
    );
  };

  return (
    <>
      <Box mb={ESpacings.loose}>
        <Banner
          hasDismiss={false}
          icon="billStack"
          title={getLocalizedText("company.account_holder.info.heading")}
        >
          {getLocalizedText("company.account_holder.info.content")}
        </Banner>
      </Box>
      {renderContent()}
    </>
  );
};
