var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import { useTheme } from "@mui/material/styles";
import { useDashboardType } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { EDashboardType } from "@rentiohq/shared-frontend/dist/redux/types";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { getLocale, getLocalizedText, } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { InternalModeButton } from "@rentiohq/web-shared/dist/scenes/Auth/components/InternalModeButton";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ASSETS, EAssets } from "../../../assets";
import { SHOW_APP_MODAL } from "../../../redux/actions";
import { MainCard } from "../../../ui-components/cards/MainCard";
import { Icon } from "../../../ui-components/Icon";
import { NavItemButton } from "../../../ui-components/NavItemButton";
import { Transitions } from "../../../ui-components/Transitions";
import { ProfileSectionStyles } from "./ProfileSection.styles";
var ProfileSection = function () {
    var dispatch = useDispatch();
    var setSelectedDashboardType = useDashboardType().setSelectedDashboardType;
    var theme = useTheme();
    var navigate = useNavigate();
    var _a = useState(false), open = _a[0], setOpen = _a[1];
    var anchorRef = useRef(null);
    var sx = ProfileSectionStyles();
    var handleLogout = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch({ type: "LOG_OUT" });
            return [2 /*return*/];
        });
    }); };
    var handleProfileClick = function (event) {
        handleClose(event);
        dispatch({
            type: SHOW_APP_MODAL,
            payload: {
                title: getLocalizedText("tenant.profile_settings"),
                showAppModal: true,
            },
        });
    };
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var handleClose = function (event) {
        var _a;
        if ((anchorRef === null || anchorRef === void 0 ? void 0 : anchorRef.current) && ((_a = anchorRef === null || anchorRef === void 0 ? void 0 : anchorRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
            return;
        }
        setOpen(false);
    };
    var getHelpUrl = function () {
        switch (getLocale()) {
            case ELocale.NL:
                return "https://mijnhuurprofiel.zendesk.com/hc/nl";
            case ELocale.FR:
                return "https://mijnhuurprofiel.zendesk.com/hc/fr-be";
            default:
                return "https://mijnhuurprofiel.zendesk.com/hc/en-be";
        }
    };
    var handleClickHelp = function () {
        window.open(getHelpUrl(), "_blank");
    };
    var prevOpen = useRef(open);
    useEffect(function () {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);
    var navItems = [
        {
            label: "usermenu.switch_pro_mode",
            icon: _jsx(Icon, { path: EAssets.ModeSwitchIcon, width: "24px", height: "24px" }),
            onClick: function (_) {
                setSelectedDashboardType(EDashboardType.ProDashboard);
                navigate("/");
            },
        },
        {
            label: "usermenu.help_center",
            icon: _jsx(Icon, { path: EAssets.HelpIcon, width: "24px", height: "24px" }),
            onClick: handleClickHelp,
        },
        {
            label: "system.logout",
            icon: _jsx(Icon, { path: EAssets.SignoutIcon, width: "24px", height: "24px" }),
            onClick: handleLogout,
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(InternalModeButton, {}), _jsx(Chip, { sx: sx.wrapper.chip, icon: _jsx(Box, { sx: sx.content.avatar, ref: anchorRef, "aria-controls": open ? "menu-list-grow" : undefined, "aria-haspopup": "true", children: _jsx("img", { src: ASSETS.profile, width: "36px", height: "36px", alt: "profile" }) }), label: _jsx(Icon, { path: EAssets.MenuIcon, width: "24px", height: "24px" }), variant: "outlined", ref: anchorRef, "aria-controls": open ? "menu-list-grow" : undefined, "aria-haspopup": "true", onClick: handleToggle, color: "primary", "aria-label": "user-account" }), _jsx(Popper, { placement: "bottom", open: open, anchorEl: anchorRef.current, role: undefined, transition: true, disablePortal: true, modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 14],
                        },
                    },
                ], children: function (_a) {
                    var TransitionProps = _a.TransitionProps;
                    return (_jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsx(Transitions, __assign({ in: open }, TransitionProps, { children: _jsx(Paper, { children: open && (_jsxs(MainCard, { border: false, elevation: 16, content: false, boxShadow: true, shadow: theme.shadows[16], children: [_jsx(Box, { sx: sx.content.profileBtn, children: _jsx(NavItemButton, { label: getLocalizedText("usermenu.profile"), onClick: handleProfileClick, icon: _jsx("img", { src: ASSETS.profile, alt: "profile", width: 24, height: 24 }) }) }), _jsxs(Box, { sx: sx.content.listContainer, children: [_jsx(Divider, {}), _jsx(List, { component: "nav", sx: sx.content.list, children: navItems.map(function (item) { return (_jsx(NavItemButton, { label: getLocalizedText(item.label), onClick: item.onClick, icon: item.icon })); }) })] })] })) }) })) }));
                } })] }));
};
// eslint-disable-next-line import/no-default-export
export default ProfileSection;
