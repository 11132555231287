import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { contractModule } from "@rentiohq/shared-frontend/dist/reduxV2/contract";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { isValidStopDateForTacitRenewal } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import * as paymentOrderHooks from "@rentiohq/shared-frontend/dist/reduxV2/paymentOrder/paymentOrder.hooks";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { isResidential } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import { EContractLengthType } from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  EPaymentRepetitionType,
  IPaymentOrder,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import {
  addYears,
  isBefore,
  startOfDay,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { getTitle } from "@rentiohq/shared-frontend/dist/utils/paymentOrder.utils";
import {
  Banner,
  Loading,
  Modal,
  MultiStepForm,
  OptionListItem,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import renewContractSchemas from "forms/renewContract";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  submitActionCreator,
} from "forms/renewContract/schema.renewContract.actions";
import { EField } from "forms/renewContract/schema.renewContract.types";
import { FC, useState } from "react";

const { i18n } = utils;

const FETCH_LIMIT = 20;

const NOW = new Date();

enum EStep {
  Contract,
  Payments,
}

interface IProps {
  contractId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const RenewContractModal: FC<IProps> = props => {
  const { contractId, onClose, onSuccess } = props;

  // State
  const [formId] = useState(generateFormId());
  const [endDates, setEndDates] = useState<
    | { initialEndDate: Date; newEndDate: Date; tacitRenewal: boolean }
    | undefined
  >(undefined);
  const [step, setStep] = useState<EStep>(EStep.Contract);

  // Redux

  const { items: paymentOrders, isFetching: isFetchingPaymentOrders } =
    paymentOrderHooks.useInfiniteLoad({
      query: {
        filter: {
          contractId: { eq: contractId },
          repetitionType: { neq: EPaymentRepetitionType.Once },
        },
        limit: FETCH_LIMIT,
      },
    });

  const { detail: contract } = contractHooks.useDetail({ id: contractId });
  const { detail: property } = propertyHooks.useDetail({
    id: contract?.propertyId,
  });

  const { renew } = contractModule.hooks.useRenew({
    contractId: contract?.id,
  });

  // Lifecycle

  // Event handlers
  const handleSuccess = () => {
    showAlert({
      type: "success",
      message: getLocalizedText("renew_contract.alert.success.title"),
    });

    onSuccess();
  };

  // Render
  if (!property || !contract || isFetchingPaymentOrders || !paymentOrders) {
    return (
      <Modal
        width="medium"
        heading={getLocalizedText("renew_contract.modal.heading")}
        onClose={onClose}
        hasDismiss={true}
        shouldCloseOnOverlayClick={true}
      >
        {isFetchingPaymentOrders && <Loading asDots={true} />}
      </Modal>
    );
  }

  const hasPaymentOrders = paymentOrders.length > 0;

  const renderContract = () => (
    <MultiStepForm
      formId={`renew-contract-${formId}`}
      schemas={renewContractSchemas({
        property,
        contract,
      })}
      asModal={true}
      withAside={false}
      modalProps={{
        onClose,
        heading: getLocalizedText("renew_contract.modal.heading"),
        width: "medium",
      }}
      modalActions={[
        {
          content: getLocalizedText("system.cancel"),
          onClick: onClose,
          appearance: "outline",
        },
      ]}
      isPerformingSubmitActionSelector={
        hasPaymentOrders
          ? undefined
          : isPerformingSubmitActionSelector(contract.id)
      }
      performingSubmitActionErrorSelector={
        hasPaymentOrders
          ? undefined
          : performingSubmitActionErrorSelector(contract.id)
      }
      submitActionCreator={
        hasPaymentOrders
          ? undefined
          : submitActionCreator({
              property,
              contract,
            })
      }
      onSuccess={(data: any) => {
        if (hasPaymentOrders) {
          setEndDates({
            initialEndDate: contract.stopDate || NOW,
            newEndDate: data[EField.NewEndDate],
            tacitRenewal: data[EField.TacitRenewal] === "yes",
          });

          setStep(EStep.Payments);

          return;
        }

        handleSuccess();
      }}
      submitLabel={getLocalizedText(
        hasPaymentOrders ? "system.next" : "renew_contract.modal.cta.confirm",
      )}
    />
  );

  const paymentOrderAsString = (paymentOrder: IPaymentOrder) =>
    `${getTitle(paymentOrder)} (${formatCurrency(paymentOrder.amount)})`;

  const renderPayments = () => (
    <Modal
      width="medium"
      heading={getLocalizedText("renew_contract.modal.heading")}
      onClose={onClose}
      hasDismiss={true}
      shouldCloseOnOverlayClick={true}
      actions={[
        {
          content: getLocalizedText("system.cancel"),
          onClick: onClose,
          appearance: "outline",
        },
        {
          content: getLocalizedText("renew_contract.modal.cta.confirm"),
          onClick: () => {
            if (!endDates) {
              return;
            }

            const canRenewTacitly =
              isResidential(property) &&
              isValidStopDateForTacitRenewal({
                startDate: contract.startDate,
                stopDate: endDates.newEndDate,
              });

            let contractLengthType = null;
            if (isResidential(property)) {
              const finalDateShortContract = addYears(
                startOfDay(contract.startDate),
                3,
              );
              if (isBefore(endDates.newEndDate, finalDateShortContract)) {
                contractLengthType = EContractLengthType.Short;
              } else {
                contractLengthType = EContractLengthType.Long;
              }
            }

            renew({
              stopDate: endDates.newEndDate || NOW,
              tacitRenewal: canRenewTacitly ? endDates.tacitRenewal : false,
              contractLengthType,
              onSuccess: handleSuccess,
            });
          },
          appearance: "primary",
        },
      ]}
    >
      <Banner
        title={property ? formatAddress(property) : ""}
        hasDismiss={false}
      >
        {property?.name || ""}
      </Banner>

      {i18n.tm("renew_contract.modal.payments.content", {
        values: {
          initialEndDate: formatDate(endDates?.initialEndDate || NOW),
          newEndDate: formatDate(endDates?.newEndDate || NOW),
        },
        markdownProps: { listAsChecklist: true },
      })}

      {paymentOrders.length > 0 && (
        <>
          <Spacer weight={ESpacerWeight.W20} />
          {paymentOrders.map(paymentOrder => (
            <OptionListItem
              type={"checkbox"}
              title={paymentOrderAsString(paymentOrder)}
              selected={true}
              disabled={true}
              onChange={() => {}}
            />
          ))}
        </>
      )}
    </Modal>
  );

  switch (step) {
    case EStep.Payments:
      return renderPayments();

    case EStep.Contract:
    default:
      return renderContract();
  }
};

// eslint-disable-next-line import/no-default-export
export default RenewContractModal;
