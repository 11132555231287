import { Box } from "@rebass/grid";
import addContactSchemas from "@rentiohq/shared-frontend/dist/forms/addContact";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.actions";
import { EField } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.types";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Banner,
  ESpacings,
  Loading,
  MultiStepForm,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";

const formId = generateFormId();

export const BrokerContact: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { user } = authHooks.useSelf();

  const brokerContact = useSelector((state: IRootStore) =>
    user?.brokerId
      ? contactSelectors.getContactByCustomId(state, EContactCustomId.MeMaster)
      : undefined,
  );
  const isFetchingContact = useSelector((state: IRootStore) =>
    user?.brokerId
      ? state.contact.contactsById[EContactCustomId.MeMaster].isFetching
      : false,
  );

  React.useEffect(() => {
    if (!user) {
      return;
    }
    if (!brokerContact && user.brokerId) {
      dispatch(
        contactActions.getContact.actions.start({
          contactId: EContactCustomId.MeMaster,
        }),
      );
    }
  }, [brokerContact, user]);

  const renderContent = () => {
    if (!user?.brokerId) {
      return null;
    }

    if (isFetchingContact) {
      return <Loading />;
    }

    return (
      <MultiStepForm
        formId={`contact-broker-${formId}`}
        schemas={addContactSchemas({
          isCompany: true,
          contact: brokerContact,
          optionalFields: [EField.Firstname, EField.Lastname],
          showSendInvite: false,
        })}
        withSummary={false}
        withAside={false}
        isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
          brokerContact ? brokerContact.id : undefined,
        )}
        performingSubmitActionResultSelector={performingSubmitActionResultSelector(
          brokerContact ? brokerContact.id : undefined,
        )}
        performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
          brokerContact ? brokerContact.id : undefined,
        )}
        submitActionCreator={submitActionCreator(
          brokerContact ? brokerContact.id : undefined,
          undefined,
          undefined,
          [EField.Firstname, EField.Lastname],
        )}
      />
    );
  };

  return (
    <>
      <Box mb={ESpacings.loose}>
        <Banner
          hasDismiss={false}
          icon="broker"
          title={getLocalizedText("company.broker_contact.info.heading")}
        />
      </Box>
      {renderContent()}
    </>
  );
};
