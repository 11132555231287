var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import FormMultiStepShared from "@rentiohq/shared-frontend/dist/components/components/FormMultistep";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { fields } from "../../../config/form.config";
import * as t from "../../../services/translationService";
import utils from "../../../utils";
import { Button } from "../../Button";
import { ButtonGroup } from "../../ButtonGroup";
import { Grid, Modal } from "../../index";
import { FormAside } from "../FormAside";
import { FormSummary } from "../FormSummary";
import * as S from "../SharedForm.styled";
import { ArrayFieldTemplate } from "../templates/ArrayFieldTemplate";
import { FieldTemplate } from "../templates/FieldTemplate";
import { ObjectFieldTemplate } from "../templates/ObjectFieldTemplate";
var MultiStepForm = /** @class */ (function (_super) {
    __extends(MultiStepForm, _super);
    function MultiStepForm(props) {
        var _this = _super.call(this, props) || this;
        _this.getFormId = function () {
            var formId = _this.props.formId;
            if (formId) {
                return formId;
            }
            return 0;
        };
        _this.UNSAFE_componentWillReceiveProps = function (_a) {
            var isPerformingSubmitAction = _a.isPerformingSubmitAction, performingSubmitActionResult = _a.performingSubmitActionResult, performingSubmitActionError = _a.performingSubmitActionError;
            var _b = _this.props, wasPerformingSubmitAction = _b.isPerformingSubmitAction, onSubmit = _b.onSubmit, onSuccess = _b.onSuccess, onError = _b.onError, partialData = _b.partialData;
            var isSucceeded = wasPerformingSubmitAction &&
                !isPerformingSubmitAction &&
                !performingSubmitActionError;
            var isFailed = wasPerformingSubmitAction &&
                !isPerformingSubmitAction &&
                performingSubmitActionError;
            if (isSucceeded) {
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(performingSubmitActionResult, partialData);
                onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit();
            }
            if (isFailed) {
                onError === null || onError === void 0 ? void 0 : onError(performingSubmitActionError, partialData);
            }
        };
        // Render
        _this.renderControls = function (onSubmitAttempt) {
            var _a;
            // private renderControls = (onSubmitAttempt: (formData: any) => void) => {
            var _b = _this.state, didAccessFromSummary = _b.didAccessFromSummary, stepIndex = _b.stepIndex;
            var _c = _this.props, _d = _c.partialData, partialData = _d === void 0 ? {} : _d, _e = _c.withSummary, withSummary = _e === void 0 ? false : _e, _f = _c.submitLabel, submitLabel = _f === void 0 ? t.system("save") : _f, _g = _c.showControls, showControls = _g === void 0 ? true : _g, _h = _c.isLoading, isLoading = _h === void 0 ? false : _h, _j = _c.isPerformingSubmitAction, isPerformingSubmitAction = _j === void 0 ? false : _j, _k = _c.fullWidthSubmitButton, fullWidthSubmitButton = _k === void 0 ? false : _k, _l = _c.showSubmitButton, showSubmitButton = _l === void 0 ? true : _l;
            var isFirstStep = !_this.hasPreviousSchemas();
            var schemas = ((_a = _this.props.schemas) !== null && _a !== void 0 ? _a : []).filter(function (schema) {
                var showIf = schema.showIf;
                return showIf ? showIf(partialData) : true;
            });
            var isLastStep = stepIndex === schemas.length - 1;
            var label = submitLabel;
            if (didAccessFromSummary) {
                label = t.system("update");
            }
            else if (!isLastStep || withSummary) {
                label = t.system("next");
            }
            if (!showControls)
                return _jsx(_Fragment, {});
            if (fullWidthSubmitButton) {
                return (_jsx(S.Actions, { style: { width: "100%" }, children: _jsx(Button, { appearance: "primary", type: "submit", onClick: onSubmitAttempt, isSubmitting: isLoading || isPerformingSubmitAction, isFullWidth: true, children: label }) }));
            }
            var hasActions = showSubmitButton || didAccessFromSummary || !isFirstStep;
            return (hasActions && (_jsx(S.Actions, { style: { width: "100%" }, children: _jsxs(ButtonGroup, { align: "left", children: [!didAccessFromSummary &&
                            (!isFirstStep || _this.props.onPressPreviousFirstStep) && (_jsx(Button, { onClick: _this.handleStepPrev, children: t.system("previous") })), showSubmitButton && (_jsx(Button, { appearance: "primary", type: "submit", onClick: onSubmitAttempt, isSubmitting: isLoading || isPerformingSubmitAction, children: label })), didAccessFromSummary && (_jsx(Button, { onClick: _this.handlePressBackToSummary, children: getLocalizedText("forms.cta.back_to_summary") }))] }) })));
        };
        _this.getModalActions = function () {
            var _a = _this.state, didAccessFromSummary = _a.didAccessFromSummary, stepIndex = _a.stepIndex, maxStepIndex = _a.maxStepIndex;
            var _b = _this.props, _c = _b.schemas, schemas = _c === void 0 ? [] : _c, _d = _b.withSummary, withSummary = _d === void 0 ? false : _d, _e = _b.submitLabel, submitLabel = _e === void 0 ? t.system("save") : _e, _f = _b.isLoading, isLoading = _f === void 0 ? false : _f, _g = _b.isPerformingSubmitAction, isPerformingSubmitAction = _g === void 0 ? false : _g, activeStep = _b.activeStep, _h = _b.showSubmitButton, showSubmitButton = _h === void 0 ? true : _h, _j = _b.isSubmitButtonDisabled, isSubmitButtonDisabled = _j === void 0 ? false : _j;
            var isFirstStep = stepIndex === 0;
            var isLastStep = stepIndex === schemas.length - 1;
            var isSummary = stepIndex === maxStepIndex;
            var isEdit = activeStep && activeStep === "summary";
            var label = t.system("next");
            if (withSummary && isSummary) {
                label = submitLabel;
            }
            if (!withSummary && isLastStep) {
                label = submitLabel;
            }
            if (didAccessFromSummary) {
                label = t.system("update");
            }
            return [
                !didAccessFromSummary &&
                    (!isFirstStep || _this.props.onPressPreviousFirstStep) &&
                    !isEdit && {
                    content: t.system("previous"),
                    appearance: "outline",
                    onClick: _this.handleStepPrev,
                    key: "previous",
                },
                showSubmitButton && {
                    content: label,
                    appearance: "primary",
                    isDisabled: isSubmitButtonDisabled,
                    onClick: isSummary && withSummary
                        ? _this.handlePressSubmit
                        : _this.handleExternalSubmit,
                    key: "next",
                    isSubmitting: isLoading || isPerformingSubmitAction,
                },
            ].filter(Boolean);
        };
        _this.getNavigationTitle = function () {
            var _a = _this.state, stepIndex = _a.stepIndex, maxStepIndex = _a.maxStepIndex;
            var isSummary = stepIndex === maxStepIndex;
            var _b = _this.props.schemas, schemas = _b === void 0 ? [] : _b;
            if (isSummary) {
                return t.system("summary");
            }
            return schemas[stepIndex].uiSchema["rentio:navigationTitle"];
        };
        _this.getPreviousSchemas = function () {
            var stepIndex = _this.state.stepIndex;
            var _a = _this.props, _b = _a.schemas, schemas = _b === void 0 ? [] : _b, partialData = _a.partialData;
            return schemas
                .slice(0, stepIndex)
                .reverse()
                .filter(function (schema) {
                var showIf = schema.showIf;
                return showIf ? showIf(partialData) : true;
            });
        };
        _this.hasPreviousSchemas = function () {
            return _this.getPreviousSchemas().length > 0;
        };
        // private handleBeforeUnload = (e: any) => {
        //   if (!this.state.shouldShowPrompt) {
        //     return true;
        //   }
        //   const confirmationMessage = getLocalizedText(
        //     "form.dismiss.alert.description",
        //   );
        //   e.returnValue = confirmationMessage;
        //   return confirmationMessage;
        // };
        _this.handlePressSubmitWithoutConfirm = function () {
            var _a = _this.props, submitAction = _a.submitAction, partialData = _a.partialData, submitActionCreator = _a.submitActionCreator, onSuccess = _a.onSuccess;
            _this.setState({ shouldShowPrompt: false }, function () {
                if (!submitActionCreator) {
                    onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(partialData);
                    return;
                }
                submitAction(partialData);
            });
        };
        _this.handlePressSubmit = function () {
            var _a = _this.props, partialData = _a.partialData, getPrecommitConfirm = _a.getPrecommitConfirm;
            var precommitConfirm = getPrecommitConfirm === null || getPrecommitConfirm === void 0 ? void 0 : getPrecommitConfirm({
                formData: partialData,
                onProceed: function () {
                    _this.handlePressSubmitWithoutConfirm();
                },
            });
            if (precommitConfirm) {
                confirm(precommitConfirm);
                return;
            }
            _this.handlePressSubmitWithoutConfirm();
        };
        _this.handlePressUpdate = function () {
            _this.setState(__assign(__assign({}, _this.state), { stepIndex: _this.state.maxStepIndex }));
        };
        _this.handlePressNext = function (stepIndex) {
            _this.setState(__assign(__assign({}, _this.state), { stepIndex: stepIndex }));
        };
        _this.handlePressViewSummary = function () {
            _this.setState(__assign(__assign({}, _this.state), { stepIndex: _this.state.maxStepIndex }));
        };
        _this.handlePressEditSchema = function (stepIndex) {
            _this.setState(__assign(__assign({}, _this.state), { stepIndex: stepIndex, didAccessFromSummary: true }));
        };
        _this.handleSummaryPrev = function () {
            var stepIndex = _this.state.stepIndex;
            var _a = _this.props, _b = _a.schemas, schemas = _b === void 0 ? [] : _b, partialData = _a.partialData;
            var allPrevSchemas = schemas.slice(0, stepIndex).reverse();
            // Find the first schema that can be shown
            var prevSchema = allPrevSchemas.findIndex(function (schema) {
                var showIf = schema.showIf;
                return showIf ? showIf(partialData) : true;
            });
            var prevStep = stepIndex === 0 ? 0 : stepIndex - (prevSchema + 1);
            _this.setState(__assign(__assign({}, _this.state), { stepIndex: prevStep, didAccessFromSummary: true }));
        };
        _this.handleStepPrev = function () {
            var stepIndex = _this.state.stepIndex;
            var _a = _this.props, _b = _a.schemas, schemas = _b === void 0 ? [] : _b, partialData = _a.partialData, onPressPreviousFirstStep = _a.onPressPreviousFirstStep;
            if (stepIndex === 0) {
                onPressPreviousFirstStep === null || onPressPreviousFirstStep === void 0 ? void 0 : onPressPreviousFirstStep();
            }
            // Get all previous schema's and reverse order
            var allPrevSchemas = schemas.slice(0, stepIndex).reverse();
            // Find the first schema that can be shown
            var prevSchema = allPrevSchemas.findIndex(function (schema) {
                var showIf = schema.showIf;
                return showIf ? showIf(partialData) : true;
            });
            var prevStep = stepIndex === 0 ? 0 : stepIndex - (prevSchema + 1);
            _this.setState(__assign(__assign({}, _this.state), { stepIndex: prevStep }));
        };
        _this.handlePressBackToSummary = function () {
            _this.setState({
                stepIndex: _this.state.maxStepIndex,
            });
        };
        _this.handlePressAttemptSubmitSummary = function () {
            if (!_this.formSummary) {
                return;
            }
            _this.setState({ shouldShowPrompt: false }, function () {
                _this.formSummary.attemptSubmit();
            });
        };
        _this.handleSelectMore = function (formId, stepIndex, fieldName, fieldValue, uiSchema, formData, extraData) {
            var updateFormData = _this.props.updateFormData;
            var field = uiSchema["ui:field"];
            switch (field) {
                case ERentioFormField.IbanSelectInline:
                    updateFormData({ fieldName: fieldName, fieldData: fieldValue, stepIndex: stepIndex });
                    break;
                default:
                    break;
            }
        };
        _this.handlePressExtraInfo = function (extraInfoUrl) {
            if (extraInfoUrl) {
                utils.general.openWindow(utils.i18n.t("".concat(extraInfoUrl, ".dashboard")));
                // this.setState({ extraInfoUrl: utils.i18n.t(`${extraInfoUrl}.dashboard`) })
            }
        };
        _this.handleExternalSubmit = function () {
            var _a, _b;
            (_b = (_a = _this.ref) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.handleSubmitAttempt();
        };
        _this.renderErrorList = function (formErrors) {
            var stepIndex = _this.state.stepIndex;
            var showErrors = _this.props.showErrors;
            var shouldShowErrors = showErrors.includes(stepIndex);
            if (!shouldShowErrors || !formErrors) {
                return null;
            }
            // TODO: Not called on after showErrors prop updates
            return null;
            // return (
            //   <Banner
            //     title={t.formErrorGeneral()}
            //     variation="info"
            //     hasDismiss={false}
            //     icon="alertDiamond"
            //   />
            // );
        };
        _this.handleKeydown = function (event) {
            if (event.which === 13) {
                _this.handleExternalSubmit();
            }
        };
        _this.handleStepClick = function (index) {
            _this.setState(__assign(__assign({}, _this.state), { stepIndex: index }));
        };
        _this.renderForm = function () {
            var _a = _this.state, stepIndex = _a.stepIndex, maxStepIndex = _a.maxStepIndex;
            var _b = _this.props, summaryTitle = _b.summaryTitle, _c = _b.submitLabel, submitLabel = _c === void 0 ? t.system("save") : _c, _d = _b.asModal, asModal = _d === void 0 ? false : _d, _e = _b.withAside, withAside = _e === void 0 ? true : _e, withSummary = _b.withSummary, _f = _b.isPerformingSubmitAction, isPerformingSubmitAction = _f === void 0 ? false : _f, intro = _b.intro, _g = _b.showErrorList, showErrorList = _g === void 0 ? true : _g, activeStep = _b.activeStep, onChange = _b.onChange, onPressNext = _b.onPressNext;
            var isSummary = stepIndex === maxStepIndex;
            var handleChange = function (fromData) {
                onChange === null || onChange === void 0 ? void 0 : onChange(fromData, stepIndex);
            };
            return (_jsx(_Fragment, { children: _jsxs(Grid, { spacing: "extraLoose", justifyContent: "space-between", children: [withAside && (_jsx(Grid.Item, { width: [1, 1, 3 / 12], children: _jsx(FormAside, { formId: _this.getFormId(), currentStep: stepIndex, withSummary: withSummary, onStepClick: _this.handleStepClick }) })), _jsxs(Grid.Item, { width: withAside ? [1, 1, 9 / 12] : 1, children: [intro, !isSummary ? (_jsxs(_Fragment, { children: [_jsx(S.FormContent, { children: _jsx(FormMultiStepShared, { formId: _this.getFormId(), formProps: {
                                                    // @ts-ignore
                                                    ref: _this.ref,
                                                    // @ts-ignore TODO: Typescript upgrade
                                                    fields: fields,
                                                    // @ts-ignore TODO: Typescript upgrade
                                                    FieldTemplate: FieldTemplate,
                                                    // @ts-ignore TODO: Typescript upgrade
                                                    ArrayFieldTemplate: ArrayFieldTemplate,
                                                    // @ts-ignore TODO: Typescript upgrade
                                                    ObjectFieldTemplate: ObjectFieldTemplate,
                                                    renderControls: function (_) { return _jsx(_Fragment, {}); },
                                                    showErrorList: showErrorList,
                                                    errorList: _this.renderErrorList,
                                                }, stepIndex: _this.state.stepIndex, onPressUpdate: _this.handlePressUpdate, onPressNext: function (next) {
                                                    _this.handlePressNext(next);
                                                    if (onPressNext)
                                                        onPressNext(next);
                                                }, onPressViewSummary: _this.handlePressViewSummary, onPressSubmit: _this.handlePressSubmit, onPressExtraInfo: _this.handlePressExtraInfo, onChange: handleChange, 
                                                // @ts-ignore TODO: Typescript upgrade
                                                onSelectMore: _this.handleSelectMore, didAccessFromSummary: _this.state.didAccessFromSummary }) }), !asModal && _this.renderControls(_this.handleExternalSubmit)] })) : (_jsxs(_Fragment, { children: [_jsx(FormSummary
                                        // @ts-ignore
                                        , { 
                                            // @ts-ignore
                                            ref: function (ref) { return (_this.formSummary = ref); }, title: summaryTitle, formId: _this.getFormId(), onPressEditSchema: _this.handlePressEditSchema, onPressSubmit: _this.handlePressSubmit, onChange: handleChange }), !asModal && (_jsx(S.Actions, { children: _jsxs(ButtonGroup, { align: "left", children: [activeStep !== "summary" && (_jsx(Button, { onClick: _this.handleSummaryPrev, children: t.system("previous") })), _jsx(Button, { appearance: "primary", type: "submit", onClick: _this.handlePressAttemptSubmitSummary, isSubmitting: isPerformingSubmitAction, children: submitLabel })] }) }))] }))] })] }) }));
        };
        _this.renderAsModal = function () {
            var _a, _b;
            var _c = _this.props, _d = _c.modalProps, modalProps = _d === void 0 ? {} : _d, _e = _c.modalActions, modalActions = _e === void 0 ? [] : _e;
            var showDefaultHeader = !modalProps.heading &&
                (!modalProps.components ||
                    (!!modalProps.components && !modalProps.components.Header));
            var newModalProps = __assign({ heading: showDefaultHeader && _this.getNavigationTitle() }, modalProps);
            return (_jsx(Modal, __assign({ shouldCloseOnOverlayClick: false }, newModalProps, { actions: __spreadArray(__spreadArray([], modalActions, true), _this.getModalActions(), true), children: _jsxs("div", { style: { minHeight: 260 }, children: [newModalProps.children, _this.renderForm(), (_b = (_a = _this.props).renderModalFooter) === null || _b === void 0 ? void 0 : _b.call(_a)] }) })));
        };
        _this.render = function () {
            var _a = _this.props.asModal, asModal = _a === void 0 ? false : _a;
            if (asModal) {
                return _this.renderAsModal();
            }
            return _this.renderForm();
        };
        var _a = _this.props, formId = _a.formId, _b = _a.schemas, schemas = _b === void 0 ? [] : _b, _c = _a.withSummary, withSummary = _c === void 0 ? false : _c;
        _this.props.createForm({
            formId: formId,
            schemas: schemas,
            withSummary: withSummary,
        });
        _this.ref = _this.props.ref ? _this.props.ref : React.createRef();
        var maxStepIndex = schemas.length;
        var stepIndex = _this.getActiveStepIndex();
        _this.state = {
            stepIndex: stepIndex,
            maxStepIndex: maxStepIndex,
            didAccessFromSummary: false,
            extraInfoUrl: undefined,
            fieldErrors: {},
            shouldShowPrompt: true,
        };
        return _this;
    }
    // Lifecycle methods
    MultiStepForm.prototype.componentDidMount = function () {
        if (this.props.preventSubmitThroughEnter === true) {
            return;
        }
        document.addEventListener("keydown", this.handleKeydown);
        // window.addEventListener("beforeunload", this.handleBeforeUnload);
    };
    MultiStepForm.prototype.componentWillUnmount = function () {
        if (this.props.preventSubmitThroughEnter === true) {
            return;
        }
        document.removeEventListener("keydown", this.handleKeydown);
        // window.removeEventListener("beforeunload", this.handleBeforeUnload);
    };
    MultiStepForm.prototype.componentDidUpdate = function (prevProps) {
        // const { performingSubmitActionError } = this.props
        if (prevProps.activeStep !== this.props.activeStep) {
            var stepIndex = this.getActiveStepIndex();
            this.setState(__assign(__assign({}, this.state), { stepIndex: stepIndex }));
        }
    };
    MultiStepForm.prototype.getActiveStepIndex = function () {
        var _a = this.props, _b = _a.schemas, schemas = _b === void 0 ? [] : _b, activeStep = _a.activeStep;
        var maxStepIndex = schemas.length;
        // eslint-disable-next-line no-nested-ternary
        var stepIndex = activeStep
            ? activeStep === "summary"
                ? maxStepIndex
                : activeStep
            : 0;
        return stepIndex;
    };
    return MultiStepForm;
}(React.Component));
export { MultiStepForm };
