var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { formatIban, formatIbanWithoutSpaces, isValidIban, } from "@rentiohq/shared-frontend/dist/utils/iban.utils";
import React from "react";
import * as utils from "../../../utils/";
import { StyledHelpText } from "../../Form/Form.styled";
import { Labelled } from "../../Labelled";
import { BaseInput } from "../components/BaseInput";
var IbanField = /** @class */ (function (_super) {
    __extends(IbanField, _super);
    function IbanField() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IbanField.prototype.render = function () {
        var _a = this.props, name = _a.name, uiSchema = _a.uiSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, formData = _a.formData, _c = _a.formContext, formContext = _c === void 0 ? {} : _c, idSchema = _a.idSchema;
        var customUiSchema = uiSchema;
        var placeholder = customUiSchema.placeholder, suffixText = customUiSchema.suffixText, _d = customUiSchema.optional, optional = _d === void 0 ? false : _d;
        var $id = idSchema.$id;
        // @ts-ignore TODO: Typescript upgrade
        var title = getFieldTitle(uiSchema, formContext);
        // @ts-ignore TODO: Typescript upgrade
        var info = getFieldInfo(uiSchema, formContext);
        var error = rawErrors[0];
        var id = "".concat($id).concat(uiSchema["ui:field"]);
        var printValue = 
        // eslint-disable-next-line no-nested-ternary
        !formData || formData === ""
            ? formData
            : formData.includes("*")
                ? formData
                : formatIban(formData);
        var handleChange = function (input) {
            var formattedInput = input.length === 0 ? undefined : formatIbanWithoutSpaces(input);
            onChange(formattedInput);
            setTimeout(function () {
                if (formContext.onFieldChange) {
                    formContext.onFieldChange(name, formattedInput);
                }
            }, 0);
        };
        var inputMarkup = (_jsxs(_Fragment, { children: [_jsx(BaseInput, { id: id, value: printValue, placeholder: placeholder, suffix: suffixText, onChange: handleChange, error: error, mask: utils.iban.ibanMask, pipe: utils.iban.ibanPipe, autoFocus: true }), info && _jsx(StyledHelpText, { children: info })] }));
        var handlePressExtraInfo = function () {
            if (formContext.onPressExtraInfo) {
                formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
            }
        };
        return (_jsx(_Fragment, { children: title ? (_jsx(Labelled, { label: title, error: error, optional: optional, onPressExtraInfo: uiSchema.extraInfoUrl && handlePressExtraInfo, children: inputMarkup })) : (_jsx(_Fragment, { children: inputMarkup })) }));
    };
    IbanField.validate = function (formData, errors, schema, uiSchema) {
        Object.keys(formData).forEach(function (fieldName) {
            var fieldUiSchema = uiSchema[fieldName];
            if (!fieldUiSchema) {
                return;
            }
            if (fieldUiSchema["ui:field"] !== "rentio:field:iban") {
                return;
            }
            var fieldError = errors[fieldName];
            if (!fieldError) {
                return;
            }
            var data = formData[fieldName];
            if (!data) {
                return;
            }
            if (isValidIban(data)) {
                return;
            }
            fieldError.addError(utils.i18n.t("system.form.error.invalid_IBAN"));
        });
    };
    return IbanField;
}(React.PureComponent));
export { IbanField };
