import { Route } from "react-router-dom";
import Properties from "../Property";
import { getPropertyDetailScene } from "./PropertyDetails/PropertyDetails.scene";

export const getPropertyScene = () => {
  return (
    <Route path="properties">
      <Route index element={<Properties />} />

      {getPropertyDetailScene()}
    </Route>
  );
};
