import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { DropdownMenu } from "../DropdownMenu";
import { Icon } from "../Icon";
import * as S from "./Tabs.styled";
export var Tabs = function (_a) {
    var tabs = _a.tabs, dropdown = _a.dropdown, className = _a.className, _b = _a.forceRenderTabPanel, forceRenderTabPanel = _b === void 0 ? false : _b, style = _a.style, tabStyle = _a.tabStyle, tabCountStyle = _a.tabCountStyle, paddingRight = _a.paddingRight, onChangeTab = _a.onChangeTab;
    var _c = React.useState(0), activeIndex = _c[0], setActiveIndex = _c[1];
    var onClick = function (index) {
        setActiveIndex(index);
        onChangeTab === null || onChangeTab === void 0 ? void 0 : onChangeTab(index);
    };
    return (_jsx("div", { className: className, children: _jsxs(_Fragment, { children: [_jsxs(S.Tabs, { style: style, children: [tabs.map(function (tab, index) {
                            var isActive = tab.isActive !== undefined ? tab.isActive : index === activeIndex;
                            var handleClick = function () {
                                var _a;
                                onClick(index);
                                (_a = tab.onClick) === null || _a === void 0 ? void 0 : _a.call(tab);
                            };
                            return (_jsxs(S.Tab, { url: tab.url, isActive: isActive, onClick: handleClick, style: tabStyle, children: [tab.content, tab.count !== undefined && (_jsx(S.TabCount, { isActive: isActive, style: tabCountStyle, children: tab.count }))] }, index));
                        }), paddingRight && (_jsx("div", { style: {
                                paddingLeft: paddingRight,
                            } })), dropdown && (_jsx(S.TabActionTrigger, { children: _jsx(DropdownMenu, { children: _jsx(S.TabActionTrigger, { children: _jsx(Icon, { source: "navigationMenuVertical" }) }), actions: dropdown }) }))] }), tabs.map(function (tab, index) {
                    return (index === activeIndex || forceRenderTabPanel) && (_jsx(S.TabContent, { isHidden: index !== activeIndex, children: tab.component }, index));
                })] }) }));
};
