var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// import { Select, TId, IProps } from './components/Select'
import { getFieldInfo, getFieldTitle, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { Select } from "../../../../components/Select";
import { StyledHelpText } from "../../../Form/Form.styled";
export var SelectField = function (_a) {
    var name = _a.name, schema = _a.schema, uiSchema = _a.uiSchema, idSchema = _a.idSchema, _b = _a.rawErrors, rawErrors = _b === void 0 ? [] : _b, onChange = _a.onChange, formData = _a.formData, _c = _a.formContext, formContext = _c === void 0 ? {} : _c, required = _a.required, props = __rest(_a, ["name", "schema", "uiSchema", "idSchema", "rawErrors", "onChange", "formData", "formContext", "required"]);
    // @ts-ignore TODO: Typescript upgrade
    var title = getFieldTitle(uiSchema, formContext);
    // @ts-ignore TODO: Typescript upgrade
    var info = getFieldInfo(uiSchema, formContext);
    var _d = uiSchema, isDisabled = _d.isDisabled, customUiSchema = __rest(_d, ["isDisabled"]);
    var disabled = isDisabled
        ? isDisabled(formContext.getAllFormData())
        : false;
    // @ts-ignore
    var _e = schema.options, options = _e === void 0 ? [] : _e, refreshOptionsOnChange = schema.refreshOptionsOnChange, getOptions = schema.getOptions;
    var error = rawErrors[0];
    var mappedOptions = ((getOptions === null || getOptions === void 0 ? void 0 : getOptions(formContext.getFormData())) || options).map(function (option) {
        var result = __assign(__assign({}, option), { value: option.id, label: option.value });
        if (option.getValue) {
            result.label = option.getValue(formContext.getFormData());
        }
        if (option.getSubValue) {
            result.description = option.getSubValue(formContext.getFormData());
        }
        return result;
    });
    var handleChange = function (_a) {
        var value = _a.value;
        var newValue = value === undefined || value === null || value === -1 ? undefined : value;
        onChange(newValue);
        setTimeout(function () {
            if (formContext.onFieldChange) {
                formContext.onFieldChange(name, newValue);
            }
        }, 0);
    };
    // Event handlers
    var handlePressExtraInfo = function () {
        if (formContext.onPressExtraInfo) {
            formContext.onPressExtraInfo(uiSchema.extraInfoUrl);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(Select, __assign({}, customUiSchema, { id: "".concat(idSchema.$id, "-").concat(name), error: error, label: title, name: name, value: formData, options: mappedOptions, onChange: handleChange, 
                // @ts-ignore
                isDisabled: disabled, disabled: disabled, onPressExtraInfo: uiSchema.extraInfoUrl ? handlePressExtraInfo : undefined })), info && _jsx(StyledHelpText, { children: info })] }));
};
