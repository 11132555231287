import { Box } from "@rebass/grid";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import {
  ACTIVITY_SUBTYPES,
  IActivityItem,
} from "@rentiohq/shared-frontend/dist/types/activity.types";
import { EPayoutType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { EPaymentRequestFeature } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { differenceInCalendarDays } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import {
  Button,
  Card,
  DisplayText,
  ESpacings,
  Grid,
  Icon,
  Loading,
  Lozenge,
  RentioInternalRendererActivity,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { i18n } from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { NumberParam } from "serialize-query-params";
import * as t from "../../../../../../services/translationService";
import {
  createTitle,
  getPaymentOrders,
  getPeriod,
} from "../../../../../../utils/payment";
import { getPaymentOrderTypeById } from "../../helpers/paymentOrder";
import { IPaymentRequestsProps } from "./PaymentRequests.types";

export const PaymentRequests: React.FC<IPaymentRequestsProps> = ({
  items,
  fetchMore,
  loading,
  count,
}) => {
  const [, setQueryParamValue] = useQueryParams({
    paymentRequestId: NumberParam,
  });

  const renderPaymentRequestRow: React.FC<IActivityItem> = item => {
    const {
      datetime,
      subtype,
      paymentRequest,
      paymentTransaction,
      payee,
      payer,
      contractRentDiscountPrice = 0,
    } = item;
    if (!paymentRequest) {
      return null;
    }

    const paymentOrders = getPaymentOrders(paymentRequest);
    const daysBetweenDueDateAndPaidDate =
      0 -
      differenceInCalendarDays(
        new Date(paymentRequest.dueDateAt),
        new Date(paymentRequest.paidAt),
      );
    const paymentOrder = paymentRequest.paymentRequestItems[0]?.paymentOrder;
    if (!paymentOrder) {
      // console.log("No payment order found in payment request items", {
      //   paymentRequest,
      // });
      return null;
    }

    const paymentOrderType = getPaymentOrderTypeById(
      paymentOrder ? paymentOrder.type! : "OTHER",
    );
    const viaThirdParty = (paymentRequest.features || []).includes(
      EPaymentRequestFeature.ThirdPartyPayInBrokerDirect,
    );

    const handlePaymentRequestClick = () => {
      setQueryParamValue({ paymentRequestId: paymentRequest.id });
    };

    const isCharge = paymentRequest.payoutType === EPayoutType.Charge;

    const renderTitle = () => {
      switch (subtype) {
        case ACTIVITY_SUBTYPES.PAYMENT.RENT_DISCOUNT_SKIPPED:
        case ACTIVITY_SUBTYPES.PAYMENT.RENT_DISCOUNT_LATE:
          return `${getLocalizedText(
            "payment.payment_order.type.rent_discount",
          )} (${getPeriod(paymentRequest)})`;

        default:
          if (payer) {
            return createTitle({ paymentOrders, paymentRequest });
          }
          return createTitle({ paymentOrders: [paymentOrder], paymentRequest });
      }
    };

    const renderContentText = () => {
      switch (subtype) {
        case ACTIVITY_SUBTYPES.PAYMENT.PAYIN_DISPUTED:
          return (
            <TextStyle variation={["subdued", "negative"]} element="div">
              {i18n.tm("payment_request.payin_disputed.label", {
                values: {
                  payer: payer
                    ? getName(payer)
                    : getLocalizedText("payment.payment_order.payer"),
                  payee: payee
                    ? getName(payee)
                    : getLocalizedText("payment.payment_order.payee"),
                  date: formatDate(datetime),
                },
              })}
            </TextStyle>
          );

        case ACTIVITY_SUBTYPES.PAYMENT.REQUEST_MANUALLY_PAID:
          if (!!paymentRequest.paidManuallyAt) {
            return (
              <TextStyle variation="subdued" element="div">
                {t.paymentRequestPaidManuallyAtLabel({
                  manuallyPaidAt: formatDate(paymentRequest.paidManuallyAt),
                  payer: payer
                    ? getName(payer)
                    : getLocalizedText("payment.payment_order.payer"),
                  payee: payee
                    ? getName(payee)
                    : getLocalizedText("payment.payment_order.payee"),
                })}
              </TextStyle>
            );
          }

          return null;

        case ACTIVITY_SUBTYPES.PAYMENT.RENT_DISCOUNT_SKIPPED:
          return (
            <TextStyle variation="subdued" element="div">
              {i18n.tm("payment_request.rent_discount_skipped.label", {
                values: {
                  amount: `${contractRentDiscountPrice}`,
                  payer: payer
                    ? getName(payer)
                    : getLocalizedText("payment.payment_order.payer"),
                  payee: payee
                    ? getName(payee)
                    : getLocalizedText("payment.payment_order.payee"),
                },
              })}
            </TextStyle>
          );

        case ACTIVITY_SUBTYPES.PAYMENT.RENT_DISCOUNT_LATE:
          return (
            <TextStyle variation="subdued" element="div">
              {i18n.tm("payment_request.rent_discount_late.label", {
                values: {
                  amount: `${contractRentDiscountPrice}`,
                  payer: payer
                    ? getName(payer)
                    : getLocalizedText("payment.payment_order.payer"),
                  payee: payee
                    ? getName(payee)
                    : getLocalizedText("payment.payment_order.payee"),
                },
              })}
            </TextStyle>
          );

        default:
          return (
            <TextStyle variation="subdued" element="div">
              {payer &&
                t.paymentRequestPaidBy({
                  values: {
                    payer: getName(payer, false),
                    date: formatDate(item.datetime),
                  },
                })}

              {payee &&
                t.paymentRequestReceivedBy({
                  values: {
                    payee: getName(payee, false),
                    date: formatDate(item.datetime),
                  },
                  extra: {
                    fromRent: paymentOrder.ownerPaymentMethod === "FROM_RENT",
                  },
                })}
            </TextStyle>
          );
      }
    };

    const renderPrice = () => {
      switch (subtype) {
        case ACTIVITY_SUBTYPES.PAYMENT.RENT_DISCOUNT_SKIPPED:
        case ACTIVITY_SUBTYPES.PAYMENT.RENT_DISCOUNT_LATE:
          return null;

        case ACTIVITY_SUBTYPES.PAYMENT.PAYIN_DISPUTED:
          return (
            <TextStyle variation="negative" element="div">
              {formatCurrency(-paymentRequest.originalAmount)}
            </TextStyle>
          );

        case ACTIVITY_SUBTYPES.PAYMENT.REQUEST_MANUALLY_PAID:
          if (item.amount) {
            return formatCurrency(item.amount);
          }

          return null;

        default:
          if (paymentTransaction) {
            return formatCurrency(paymentTransaction.amount);
          }

          return formatCurrency(paymentRequest.originalAmount);
      }
    };

    return (
      <>
        <ResourceListItem
          key={item.id}
          item={item}
          media={<Icon source={paymentOrderType?.icon || "billStack"} />}
          onClick={handlePaymentRequestClick}
        >
          <Grid alignItems="center" justifyContent="space-between">
            <Grid.Item>
              <Grid spacing="tight" alignItems="center">
                <Grid.Item>
                  <DisplayText size="small" space="extraTight">
                    {renderTitle()}
                  </DisplayText>
                </Grid.Item>
                {!!paymentRequest.paidManuallyAt &&
                  subtype ===
                    ACTIVITY_SUBTYPES.PAYMENT.REQUEST_MANUALLY_PAID && (
                    <Grid.Item>
                      <Lozenge>{t.paymentRequestPaidManuallyLabel()}</Lozenge>
                    </Grid.Item>
                  )}
              </Grid>
              <Box mb={ESpacings.extraTight}>{renderContentText()}</Box>
              {isCharge &&
                ACTIVITY_SUBTYPES.PAYMENT.PAYIN_DISPUTED !== subtype &&
                daysBetweenDueDateAndPaidDate > 0 && (
                  <TextStyle variation={"warn"} element="div">
                    {t.paymentRequestNotPaidOnTimeLabel({
                      days: daysBetweenDueDateAndPaidDate,
                    })}{" "}
                    {viaThirdParty &&
                      t.paymentRequestFeatureThirdPartyAccountLabel()}
                  </TextStyle>
                )}
              {isCharge &&
                ACTIVITY_SUBTYPES.PAYMENT.PAYIN_DISPUTED !== subtype &&
                daysBetweenDueDateAndPaidDate <= 0 && (
                  <TextStyle variation={"positive"} element="div">
                    {t.paymentRequestPaidOnTimeLabel()}
                    {viaThirdParty &&
                      ` (${t
                        .paymentRequestFeatureThirdPartyAccountLabel()
                        .toLowerCase()})`}
                  </TextStyle>
                )}
            </Grid.Item>
            <Grid.Item>
              <DisplayText size="medium" element="div">
                <TextStyle element="div" variation="code">
                  {renderPrice()}
                </TextStyle>
              </DisplayText>
            </Grid.Item>
          </Grid>
        </ResourceListItem>
        <RentioInternalRendererActivity activity={item} />
      </>
    );
  };

  const handleFetchMore = () => {
    fetchMore({
      skip: items.length,
      limit: 10,
    });
  };

  const renderLoadMore = () => {
    if (count <= items.length) {
      return null;
    }
    if (loading) {
      return <Loading />;
    }
    return (
      <Box mt={ESpacings.base}>
        <Button appearance="link" onClick={handleFetchMore}>
          {t.loadMore()}
        </Button>
      </Box>
    );
  };

  return (
    <Card>
      <Box mb={ESpacings.base}>
        <DisplayText size="medium">
          {t.paymentsPaymentRequestsHistoryHeading()}
        </DisplayText>
      </Box>
      {items.length > 0 ? (
        <>
          <ResourceList items={items} renderItem={renderPaymentRequestRow} />
          {renderLoadMore()}
        </>
      ) : (
        <p>{t.paymentsPaymentRequestsHistoryEmptyMessage()}</p>
      )}
    </Card>
  );
};
