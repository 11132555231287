import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import {
  EField,
  IExtraData,
} from "./schema.propertyApplicationNotEligible.types";

const getDefaultTenantFeedbackText = (extraData: IExtraData) => {
  const { application, localeMessages, user, property } = extraData;

  const userLocal = application.contacts[0].language || ELocale.NL;
  const localeMessage = localeMessages[userLocal];

  if (!localeMessage) {
    return undefined;
  }

  const params: { [key: string]: string | null | undefined } = {
    address: formatAddress(extraData.property),
    applicant: application.contacts?.[0]
      ? getName(application.contacts[0])
      : "Applicant",
    actor: application.broker?.name || getName(user),

    propertyId: `${property.id}`,
    propertyInfoId: `${property.propertyInfoId}`,

    propertyReference: property.reference,
    propertyDescription: property.description,
    propertyName: property.name,

    refWhise: property.refWhise,
    refMaxImmo: property.refMaxImmo,
    refOmnicasa: property.refOmnicasa,
    refSkarabee: property.refSkarabee,
    refSweepbright: property.refSweepbright,
    refZabun: property.refZabun,
    refDewaele: property.refDewaele,
  };

  let result = localeMessage;
  Object.keys(params).forEach(key => {
    result = result.replaceAll(`{{${key}}}`, params[key] || "");
  });

  return result;
};

export const getInitialValues = (extraData: IExtraData) => {
  return {
    [EField.TenantFeedbackText]: getDefaultTenantFeedbackText(extraData),
  };
};
