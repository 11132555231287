import { groupBy, orderBy } from "lodash";
import { EPaymentOrderType } from "../types/payment.types";
import { EPropertyMemberTypes } from "../types/property.types";
import { getLocalizedText } from "./i18n/i18n.utils";
export var getTitle = function (_a) {
    var otherDescription = _a.otherDescription, type = _a.type;
    return otherDescription ||
        getLocalizedText("payment.payment_order.type.".concat(type).toLowerCase());
};
var sortPaymentOrdersByType = function (input) {
    return orderBy(input, [
        function (x) {
            var typeWeight = [
                EPaymentOrderType.Rent,
                EPaymentOrderType.CommonCosts,
                EPaymentOrderType.NonCommonCosts,
                EPaymentOrderType.ManagementCosts,
                EPaymentOrderType.Rentio,
                EPaymentOrderType.OtherRecurring,
                EPaymentOrderType.LocationDescription,
                EPaymentOrderType.BrokerFee,
                EPaymentOrderType.OtherOneoff,
                EPaymentOrderType.RentDepositPayout,
                EPaymentOrderType.RentDiscount,
                EPaymentOrderType.RentDeposit,
            ];
            return typeWeight.indexOf(x.type);
        },
        "createdAt",
        "id",
    ]);
};
export var groupPaymentOrdersByContractV2 = function (paymentOrders) {
    var paymentOrdersGroupedByContractId = Object.values(groupBy(paymentOrders, function (x) { var _a; return ((_a = x.contractInfo) === null || _a === void 0 ? void 0 : _a.relatedContractId) || x.contractId; }));
    var paymentOrdersGroupedByPaymentGroupId = paymentOrdersGroupedByContractId.map(function (paymentOrders) {
        return Object.values(groupBy(paymentOrders, function (x) { return x.paymentGroupId; }));
    });
    var result = paymentOrdersGroupedByPaymentGroupId.map(function (x) {
        return {
            contractInfo: x[0][0].contractInfo,
            paymentOrders: x.map(function (y) { return ({
                paymentGroupId: y[0].paymentGroupId,
                paymentOrders: sortPaymentOrdersByType(y),
            }); }),
        };
    });
    // Sort groups by contract start date
    return orderBy(result, function (group) { var _a; return (_a = group.contractInfo) === null || _a === void 0 ? void 0 : _a.startDate; });
};
export var groupPaymentOrdersByContract = function (paymentOrders) {
    var paymentOrdersGroupedByContract = Object.values(groupBy(paymentOrders, function (paymentOrder) { var _a; return ((_a = paymentOrder.contract) === null || _a === void 0 ? void 0 : _a.relatedContractId) || paymentOrder.contractId; }));
    var result = paymentOrdersGroupedByContract.map(function (paymentOrders) {
        var sortedPaymentOrders = orderBy(paymentOrders, [
            function (paymentOrder) {
                var typeWeight = [
                    EPaymentOrderType.Rent,
                    EPaymentOrderType.CommonCosts,
                    EPaymentOrderType.NonCommonCosts,
                    EPaymentOrderType.ManagementCosts,
                    EPaymentOrderType.Rentio,
                    EPaymentOrderType.OtherRecurring,
                    EPaymentOrderType.LocationDescription,
                    EPaymentOrderType.BrokerFee,
                    EPaymentOrderType.OtherOneoff,
                    EPaymentOrderType.RentDepositPayout,
                    EPaymentOrderType.RentDiscount,
                    EPaymentOrderType.RentDeposit,
                ];
                return typeWeight.indexOf(paymentOrder.type);
            },
            "createdAt",
            "id",
        ]);
        return {
            contract: paymentOrders[0].contract,
            paymentOrders: sortedPaymentOrders,
        };
    });
    // Sort groups by contract start date
    var orderedResult = orderBy(result, function (group) { var _a; return (_a = group.contract) === null || _a === void 0 ? void 0 : _a.startDate; });
    return orderedResult;
};
export var canAddPaymentOrders = function (params) {
    var isBroker = params.isBroker, property = params.property;
    return (isBroker || property.roles.includes(EPropertyMemberTypes.FinancialManager));
};
