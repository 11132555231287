export var EButtonVariants;
(function (EButtonVariants) {
    EButtonVariants["Contained"] = "contained";
    EButtonVariants["Outlined"] = "outlined";
    EButtonVariants["Text"] = "text";
})(EButtonVariants || (EButtonVariants = {}));
export var EButtonColors;
(function (EButtonColors) {
    EButtonColors["Error"] = "error";
    EButtonColors["Info"] = "info";
    EButtonColors["Primary"] = "primary";
    EButtonColors["Secondary"] = "secondary";
    EButtonColors["Success"] = "success";
    EButtonColors["Warning"] = "warning";
})(EButtonColors || (EButtonColors = {}));
