import { EPaymentRepetitionType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { EPropertyMemberTypes } from "@rentiohq/shared-frontend/dist/types/property.types";
import { TAssetSource } from "@rentiohq/web-shared/dist/components";

interface IPaymentOrderType {
  id: string;
  repetitionType: EPaymentRepetitionType;
  repetitionDay: number;
  allowedPayerRoles: EPropertyMemberTypes[];
  defaultPayerRole?: EPropertyMemberTypes;
  defaultPayeeRole?: EPropertyMemberTypes;
  allowedPayeeRole: EPropertyMemberTypes[];
  icon: TAssetSource;
}

export const getPaymentOrderTypeById = (id: string) => {
  return paymentOrderTypes.find(type => type.id === id);
};

const paymentOrderTypes: IPaymentOrderType[] = [
  {
    id: "RENT",
    repetitionType: EPaymentRepetitionType.Monthly,
    repetitionDay: 1,
    allowedPayerRoles: [
      EPropertyMemberTypes.Tenant,
      EPropertyMemberTypes.Parent,
    ],
    defaultPayerRole: EPropertyMemberTypes.Tenant,
    allowedPayeeRole: [EPropertyMemberTypes.Owner],
    defaultPayeeRole: EPropertyMemberTypes.Owner,
    icon: "forRentSign",
  },
  {
    id: "COMMON_COSTS",
    repetitionType: EPaymentRepetitionType.Monthly,
    repetitionDay: 1,
    allowedPayerRoles: [
      EPropertyMemberTypes.Tenant,
      EPropertyMemberTypes.Parent,
    ],
    defaultPayerRole: EPropertyMemberTypes.Tenant,
    allowedPayeeRole: [EPropertyMemberTypes.Owner],
    defaultPayeeRole: EPropertyMemberTypes.Owner,
    icon: "houseWrench",
  },
  {
    id: "NON_COMMON_COSTS",
    repetitionType: EPaymentRepetitionType.Monthly,
    repetitionDay: 1,
    allowedPayerRoles: [
      EPropertyMemberTypes.Tenant,
      EPropertyMemberTypes.Parent,
    ],
    defaultPayerRole: EPropertyMemberTypes.Tenant,
    allowedPayeeRole: [EPropertyMemberTypes.Owner],
    defaultPayeeRole: EPropertyMemberTypes.Owner,
    icon: "billSplit",
  },
  {
    id: "MANAGEMENT_COSTS",
    repetitionType: EPaymentRepetitionType.Monthly,
    repetitionDay: 1,
    allowedPayerRoles: [EPropertyMemberTypes.Owner],
    defaultPayerRole: EPropertyMemberTypes.Owner,
    allowedPayeeRole: [EPropertyMemberTypes.Broker],
    defaultPayeeRole: EPropertyMemberTypes.Broker,
    icon: "keys",
  },
  {
    id: "RENTIO",
    repetitionType: EPaymentRepetitionType.Monthly,
    repetitionDay: 1,
    allowedPayerRoles: [
      EPropertyMemberTypes.Tenant,
      EPropertyMemberTypes.Parent,
      EPropertyMemberTypes.Owner,
    ],
    defaultPayerRole: EPropertyMemberTypes.Tenant,
    allowedPayeeRole: [EPropertyMemberTypes.Broker],
    defaultPayeeRole: EPropertyMemberTypes.Broker,
    icon: "smartphone",
  },
  {
    id: "RENT_DEPOSIT",
    repetitionType: EPaymentRepetitionType.Once,
    repetitionDay: 1,
    allowedPayerRoles: [
      EPropertyMemberTypes.Tenant,
      EPropertyMemberTypes.Parent,
    ],
    defaultPayerRole: EPropertyMemberTypes.Tenant,
    allowedPayeeRole: [EPropertyMemberTypes.Broker, EPropertyMemberTypes.Owner],
    icon: "safe",
  },
  {
    id: "LOCATION_DESCRIPTION",
    repetitionType: EPaymentRepetitionType.Once,
    repetitionDay: 1,
    allowedPayerRoles: [
      EPropertyMemberTypes.Tenant,
      EPropertyMemberTypes.Parent,
      EPropertyMemberTypes.Owner,
    ],
    allowedPayeeRole: [EPropertyMemberTypes.Broker, EPropertyMemberTypes.Owner],
    icon: "houseNotes",
  },
  {
    id: "BROKER_FEE",
    repetitionType: EPaymentRepetitionType.Once,
    repetitionDay: 1,
    allowedPayerRoles: [EPropertyMemberTypes.Owner],
    defaultPayerRole: EPropertyMemberTypes.Owner,
    defaultPayeeRole: EPropertyMemberTypes.Broker,
    allowedPayeeRole: [EPropertyMemberTypes.Broker],
    icon: "broker",
  },
  {
    id: "OTHER_ONEOFF",
    repetitionType: EPaymentRepetitionType.Once,
    repetitionDay: 1,
    allowedPayerRoles: [
      EPropertyMemberTypes.Tenant,
      EPropertyMemberTypes.Parent,
      EPropertyMemberTypes.Owner,
    ],
    allowedPayeeRole: [EPropertyMemberTypes.Broker, EPropertyMemberTypes.Owner],
    icon: "billStack",
  },
];
