export var ETacPlatform;
(function (ETacPlatform) {
    ETacPlatform["App"] = "app";
    ETacPlatform["Dashboard"] = "dashboard";
    ETacPlatform["Intake"] = "intake";
})(ETacPlatform || (ETacPlatform = {}));
export var EUserFeature;
(function (EUserFeature) {
    EUserFeature["Monitor"] = "MONITOR";
})(EUserFeature || (EUserFeature = {}));
export var EUserType;
(function (EUserType) {
    EUserType["Broker"] = "BROKER";
    EUserType["Owner"] = "OWNER";
    EUserType["Tenant"] = "TENANT";
    EUserType["Applicant"] = "APPLICANT";
})(EUserType || (EUserType = {}));
export var ECaptchaVersion;
(function (ECaptchaVersion) {
    ECaptchaVersion["Legacy"] = "legacy";
    ECaptchaVersion["Enterprise"] = "enterprise";
})(ECaptchaVersion || (ECaptchaVersion = {}));
export var ECaptchaEnterprisePlatform;
(function (ECaptchaEnterprisePlatform) {
    ECaptchaEnterprisePlatform["Ios"] = "ios";
    ECaptchaEnterprisePlatform["Android"] = "android";
})(ECaptchaEnterprisePlatform || (ECaptchaEnterprisePlatform = {}));
