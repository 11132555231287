import { IBeneficiaryReportPayout } from "@rentiohq/shared-frontend/dist/types/report.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { TruncateTooltip } from "@rentiohq/web-shared/dist/components";
import { compact } from "lodash";
import * as S from "../../BeneficiaryReport.styled";

interface IPayoutInfo {
  payout: IBeneficiaryReportPayout;
}

export const PayoutInfo = ({ payout }: IPayoutInfo) => {
  const dateItems = compact([payout.startedAt, payout.endedAt]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <TruncateTooltip width={100}>
        {getLocalizedText(
          `payment.payment_order.type.${payout?.paymentRequestItems?.[0].type.toLowerCase()}`,
        )}
      </TruncateTooltip>

      <S.GrayText>
        {dateItems.map(item => formatDate(item)).join(" - ")}
      </S.GrayText>
    </div>
  );
};
