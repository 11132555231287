import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import {
  EPropertyMemberTypes,
  EPropertyTypeId,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMembersWithOneOfRoles } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import { TextStyle } from "@rentiohq/web-shared/dist/components";
import { Fragment } from "react";
import { TSelectableItemProps } from "scenes/Properties/Properties.types";
import PropertyListItem from "../PropertyListItem";
import {
  PropertyListItemItem,
  PropertyListItemSpacer,
} from "../PropertyListItem/PropertyListItem.header";
import PropertyMembersWithRole from "./PropertyMemberWithRole/PropertyMemberWithRole";
interface IProps {
  property: IProperty;
  selectedParentId?: number;
  onSetParentId: (parentId?: number) => void;
  selectable?: TSelectableItemProps;
}

// Render
export const renderMembers = (
  roles: EPropertyMemberTypes[],
  property: IProperty,
) => {
  const members = getMembersWithOneOfRoles(property.members, roles);

  if (members.length === 0) {
    return (
      <PropertyListItemItem style={{ flex: 1 }}>
        <TextStyle variation={"subdued"}>
          {getLocalizedText(
            `properties.overview.property.no_${roles[0].toLowerCase()}s`,
          )}
        </TextStyle>
      </PropertyListItemItem>
    );
  }

  const tenants = (
    <TextStyle>
      {members.map(owner => (
        <Fragment key={`account-${owner.account.id}`}>
          {getName(owner.account)}
          <br />
        </Fragment>
      ))}
    </TextStyle>
  );

  return (
    <PropertyListItemItem style={{ flex: 1 }} tooltipContent={tenants}>
      {tenants}
    </PropertyListItemItem>
  );
};

const PropertyListItemGeneral = (props: IProps) => {
  const { property, selectedParentId, onSetParentId, selectable } = props;

  if (property.typeId === EPropertyTypeId.Group) {
    return (
      <PropertyListItem
        property={property}
        selectedParentId={selectedParentId}
        onSetParentId={onSetParentId}
        selectable={selectable}
      />
    );
  }

  return (
    <PropertyListItem
      property={property}
      selectedParentId={selectedParentId}
      onSetParentId={onSetParentId}
      selectable={selectable}
    >
      <div style={{ width: 40 }} />
      <PropertyMembersWithRole
        members={property.members}
        role={EPropertyMemberTypes.Owner}
      />
      <PropertyListItemSpacer />

      <PropertyMembersWithRole
        members={property.members}
        role={EPropertyMemberTypes.FinancialManager}
      />
      <PropertyListItemSpacer />

      <PropertyMembersWithRole
        members={property.members}
        role={EPropertyMemberTypes.TechnicalManager}
      />
      <PropertyListItemSpacer />

      <PropertyMembersWithRole
        members={property.members}
        role={EPropertyMemberTypes.IndexationManager}
      />
      <PropertyListItemSpacer />

      <PropertyMembersWithRole
        members={property.members}
        role={EPropertyMemberTypes.AdministrativeManager}
      />
    </PropertyListItem>
  );
};

// eslint-disable-next-line import/no-default-export
export default PropertyListItemGeneral;
