import { useTheme } from "@mui/material/styles";
import useConfig from "../../hooks/useConfig";
export var NavItemButtonStyles = function () {
    var borderRadius = useConfig().borderRadius;
    var theme = useTheme();
    return {
        wrapper: {
            borderRadius: "".concat(borderRadius, "px"),
        },
        content: {
            label: {
                color: theme.palette.text.primary,
            },
        },
    };
};
