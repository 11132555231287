export var isDateRange = function (toBeDetermined) {
    if (toBeDetermined && (toBeDetermined.end || toBeDetermined.start))
        return true;
    return false;
};
export var EFilterType;
(function (EFilterType) {
    EFilterType[EFilterType["MultiSelect"] = 0] = "MultiSelect";
    EFilterType[EFilterType["SingleSelect"] = 1] = "SingleSelect";
    EFilterType[EFilterType["MonthRange"] = 2] = "MonthRange";
    EFilterType[EFilterType["EmployeeSelect"] = 3] = "EmployeeSelect";
})(EFilterType || (EFilterType = {}));
export var EDateQuickFilters;
(function (EDateQuickFilters) {
    EDateQuickFilters["Today"] = "today";
    EDateQuickFilters["ThisWeek"] = "this_week";
    EDateQuickFilters["ThisMonth"] = "this_month";
    EDateQuickFilters["PreviousMonth"] = "previous_month";
    EDateQuickFilters["LastYear"] = "last_year";
    EDateQuickFilters["DateRange"] = "date_range";
})(EDateQuickFilters || (EDateQuickFilters = {}));
export var EPaymentQuickFilters;
(function (EPaymentQuickFilters) {
    EPaymentQuickFilters["ThisMonth"] = "this_month";
    EPaymentQuickFilters["PreviousMonth"] = "previous_month";
    EPaymentQuickFilters["LastThreeMonths"] = "last_three_months";
    EPaymentQuickFilters["LastSixMonths"] = "last_six_months";
    EPaymentQuickFilters["LastYear"] = "last_year";
    EPaymentQuickFilters["DateRange"] = "date_range";
})(EPaymentQuickFilters || (EPaymentQuickFilters = {}));
export var ECombinedQuickFilters;
(function (ECombinedQuickFilters) {
    ECombinedQuickFilters["Today"] = "today";
    ECombinedQuickFilters["ThisWeek"] = "this_week";
    ECombinedQuickFilters["ThisMonth"] = "this_month";
    ECombinedQuickFilters["PreviousMonth"] = "previous_month";
    ECombinedQuickFilters["LastThreeMonths"] = "last_three_months";
    ECombinedQuickFilters["LastSixMonths"] = "last_six_months";
    ECombinedQuickFilters["LastYear"] = "last_year";
    ECombinedQuickFilters["DateRange"] = "date_range";
})(ECombinedQuickFilters || (ECombinedQuickFilters = {}));
