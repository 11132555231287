import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as SInputBase from "../FormInputBase/FormInputBase.styles";
import SwitchToggle from "../SwitchToggle";
import * as S from "./FormSwitch.styles";
var FormSwitch = function (_a) {
    var title = _a.title, label = _a.label, info = _a.info, _b = _a.checked, checked = _b === void 0 ? false : _b, _c = _a.disabled, disabled = _c === void 0 ? false : _c, error = _a.error, onChange = _a.onChange, extraInfoUrl = _a.extraInfoUrl, onPressExtraInfo = _a.onPressExtraInfo;
    var handleChange = function () {
        onChange(!checked);
    };
    return (_jsxs(SInputBase.Wrap, { disabled: false, children: [title && (_jsxs(SInputBase.HeaderWrap, { children: [_jsx(SInputBase.HeaderText, { hasError: !!error, children: title }), !!extraInfoUrl && (_jsx(SInputBase.InfoButton, { onPress: onPressExtraInfo, children: _jsx(SInputBase.InfoIcon, {}) }))] })), _jsxs(S.Content, { hasTitle: !!title, children: [_jsxs(S.Label, { children: [!title ? (_jsx(SInputBase.HeaderText, { hasError: !!error, children: label })) : (label), !!extraInfoUrl && !title && (_jsx(SInputBase.InfoButton, { onPress: onPressExtraInfo, children: _jsx(SInputBase.InfoIcon, {}) }))] }), _jsx(SwitchToggle, { onValueChange: handleChange, value: checked, isDisabled: disabled })] }), error && _jsx(SInputBase.ErrorText, { children: error }), info && _jsx(SInputBase.InfoText, { children: info })] }));
};
// eslint-disable-next-line import/no-default-export
export default FormSwitch;
