var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createReducer } from "redux-act";
import { getCount } from "./count.actions";
export var REDUCER_KEY_COUNT = "count";
var getDefaultState = function () { return ({}); };
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
reducer.on(getCount.types.START, function (state, _a) {
    var _b;
    var countIdentifier = _a.countIdentifier;
    return __assign(__assign({}, state), (_b = {}, _b[countIdentifier] = __assign(__assign({ count: undefined }, (state[countIdentifier] || {})), { isFetching: true, fetchError: undefined }), _b));
});
reducer.on(getCount.types.SUCCESS, function (state, _a) {
    var _b;
    var countIdentifier = _a.countIdentifier, count = _a.count;
    return (__assign(__assign({}, state), (_b = {}, _b[countIdentifier] = __assign(__assign({}, (state[countIdentifier] || {})), { count: count, isFetching: false, fetchError: undefined }), _b)));
});
reducer.on(getCount.types.FAILURE, function (state, _a) {
    var _b;
    var countIdentifier = _a.countIdentifier, error = _a.error;
    return (__assign(__assign({}, state), (_b = {}, _b[countIdentifier] = __assign(__assign({}, (state[countIdentifier] || {})), { isFetching: false, fetchError: error }), _b)));
});
// eslint-disable-next-line import/no-default-export
export default reducer;
