import { EGroupActionAccount, } from "../types/groupAction.types";
import { getLocalizedText } from "./i18n/i18n.utils";
export var getGroupActionsMembers = function (includeMySelf) {
    if (includeMySelf === void 0) { includeMySelf = false; }
    var members = [
        {
            id: EGroupActionAccount.Tenant,
            title: getLocalizedText("group_actions.members.tenants.title"),
            description: getLocalizedText("group_actions.members.tenants.description"),
        },
        {
            id: EGroupActionAccount.Owner,
            title: getLocalizedText("group_actions.members.owners.title"),
            description: getLocalizedText("group_actions.members.owners.description"),
        },
    ];
    if (includeMySelf) {
        members.push({
            id: EGroupActionAccount.Myself,
            title: getLocalizedText("group_actions.members.myself.title"),
            description: getLocalizedText("group_actions.members.myself.description"),
        });
    }
    return members;
};
