import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex } from "@rebass/grid";
import { ESpacings } from "../../../Grid";
import { Icon } from "../../../Icon";
import * as S from "../../OptionList.styled";
export var OptionListItem = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, selected = _a.selected, value = _a.value, error = _a.error, disabled = _a.disabled, readOnly = _a.readOnly, appearance = _a.appearance, type = _a.type, onChange = _a.onChange;
    var iconPrefix = type === "checkbox" ? "checkbox" : "radioButton";
    return (_jsx(S.OptionWrapper, { children: _jsxs(S.Option, { isChecked: selected, hasError: !!error, disabled: disabled, readOnly: readOnly, as: readOnly ? "div" : "label", appearance: appearance, onClick: function (e) {
                var _a, _b;
                (_a = e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
                (_b = e.stopPropagation) === null || _b === void 0 ? void 0 : _b.call(e);
                if (readOnly || disabled) {
                    return;
                }
                // @ts-ignore
                onChange(value);
            }, children: [_jsxs(S.OptionInput, { isChecked: selected, hasError: !!error, readOnly: readOnly, children: [_jsx(Icon, { source: "".concat(iconPrefix, "Outline") }), selected && _jsx(Icon, { source: "".concat(iconPrefix, "Mark") })] }), _jsxs(Flex, { width: 1, alignItems: "center", children: [_jsxs(S.OptionContent, { children: [title, subtitle && _jsx(S.OptionDescription, { children: subtitle })] }), appearance === "warning" && (_jsx(Box, { ml: ESpacings.base, children: _jsx(Icon, { source: "alertDiamond" }) }))] })] }) }));
};
