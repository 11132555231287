import * as activityActions from "@rentiohq/shared-frontend/dist/redux/activity/activity.actions";
import * as activitySelectors from "@rentiohq/shared-frontend/dist/redux/activity/activity.selectors";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as countTypes from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import { ACTIVITY_SUBTYPES } from "@rentiohq/shared-frontend/dist/types/activity.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { PaymentRequests } from "./PaymentRequests";
import { IPaymentRequestsContainerProps } from "./PaymentRequests.types";

const PaymentRequestsContainer: React.FC<
  IPaymentRequestsContainerProps
> = props => {
  const isMarkingPaid = useSelector((state: IRootStore) =>
    Object.values(state.payment.markPaid).some(
      (item: any) => item.isMarkingPaid,
    ),
  );
  const prevIsMarkingPaid = usePrevious(isMarkingPaid);
  const identifier = `payment-requests-property-${props.property.id}`;
  const dispatch = useDispatch();

  const activitiesCount =
    useSelector((state: IRootStore) =>
      countSelectors.getCount(state, `activities-${identifier}`),
    ) || 0;

  const activities = useSelector((state: IRootStore) =>
    activitySelectors.getActivities(state, identifier),
  );
  const activitiesFetchError = useSelector((state: IRootStore) =>
    activitySelectors.getFetchActivitiesError(state, identifier),
  );
  const isFetchingActivities = useSelector((state: IRootStore) =>
    activitySelectors.getIsFetchingActivities(state, identifier),
  );

  const initialFilter = {
    and: [
      { propertyId: props.property.id },
      {
        subtype: {
          inq: [
            ACTIVITY_SUBTYPES.PAYMENT.PAYIN_SUCCEEDED,
            ACTIVITY_SUBTYPES.PAYMENT.PAYOUT_SUCCEEDED,
            ACTIVITY_SUBTYPES.PAYMENT.REQUEST_MANUALLY_PAID,
            ACTIVITY_SUBTYPES.PAYMENT.RENT_DISCOUNT_SKIPPED,
            ACTIVITY_SUBTYPES.PAYMENT.RENT_DISCOUNT_LATE,
            ACTIVITY_SUBTYPES.PAYMENT.PAYIN_DISPUTED,
          ],
        },
      },
    ],
  };

  React.useEffect(() => {
    fetchActivities(true);
    fetchCount();
  }, []);
  React.useEffect(() => {
    if (!isMarkingPaid && !!prevIsMarkingPaid) {
      fetchActivities(true);
    }
  }, [isMarkingPaid, prevIsMarkingPaid]);

  const fetchCount = () => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier: `activities-${identifier}`,
        countBase: append(
          countTypes.COUNT_BASE[countTypes.ECountIdentifier.Activities],
          { where: initialFilter },
        ),
      }),
    );
  };

  const fetchActivities = (refetch: boolean) => {
    dispatch(
      activityActions.getActivities.actions.start({
        activityIdentifier: identifier,
        refetch,
        limit: 3,
        extraFilterData: initialFilter,
      }),
    );
  };

  if (activitiesFetchError) {
    return <p>{getLocalizedText("fetch.error")}</p>;
  }

  const handleFetchMore = () => {
    fetchActivities(false);
  };

  if (!activities) {
    return null;
  }

  return (
    <PaymentRequests
      {...props}
      loading={isFetchingActivities}
      fetchMore={handleFetchMore}
      items={activities}
      count={activitiesCount}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default PaymentRequestsContainer;
