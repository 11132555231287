var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { EButtonColors, EButtonVariants } from "../../utils/types/button";
import { Icon } from "../Icon";
import { MuiDialogStyles } from "./MuiDialog.styles";
var ScrollType;
(function (ScrollType) {
    ScrollType["Paper"] = "paper";
    ScrollType["body"] = "body";
})(ScrollType || (ScrollType = {}));
var BootstrapDialogTitle = function (_a) {
    var children = _a.children, onClose = _a.onClose, other = __rest(_a, ["children", "onClose"]);
    var sx = MuiDialogStyles();
    return (_jsxs(DialogTitle, __assign({ sx: sx.content.dialogTitle }, other, { children: [children, onClose ? (_jsx(IconButton, { "aria-label": "close", onClick: onClose, sx: sx.content.iconButton, children: _jsx(Icon, { path: "close", width: "20px", height: "20px" }) })) : null] })));
};
export var MuiDialog = function (_a) {
    var open = _a.open, title = _a.title, children = _a.children, _b = _a.actions, actions = _b === void 0 ? [] : _b, maxWidth = _a.maxWidth, width = _a.width, toggleOpen = _a.toggleOpen;
    var descriptionElementRef = React.useRef(null);
    React.useEffect(function () {
        if (open) {
            var descriptionElement = descriptionElementRef.current;
            if (descriptionElement !== null) {
                descriptionElement === null || descriptionElement === void 0 ? void 0 : descriptionElement.focus();
            }
        }
    }, [open]);
    var sx = MuiDialogStyles(maxWidth, width);
    return (_jsx(Dialog, { sx: sx.wrapper, open: open, onClose: toggleOpen, scroll: ScrollType.Paper, "aria-labelledby": "rentio-dialog-title", "aria-describedby": "rentio-dialog-description", children: open && (_jsxs(_Fragment, { children: [_jsx(BootstrapDialogTitle, { id: "rentio-dialog-title", onClose: toggleOpen, children: title }), children && (_jsx(DialogContent, { dividers: (actions === null || actions === void 0 ? void 0 : actions.length) > 0, children: children })), (actions === null || actions === void 0 ? void 0 : actions.length) > 0 && (_jsx(DialogActions, { sx: sx.content.dialogActions, children: actions.map(function (action) {
                        var _a = action.variant, variant = _a === void 0 ? EButtonVariants.Contained : _a, onClick = action.onClick, label = action.label, _b = action.startIcon, startIcon = _b === void 0 ? null : _b, _c = action.color, color = _c === void 0 ? EButtonColors.Success : _c;
                        return (_jsx(Button, { variant: variant, size: "small", onClick: onClick, startIcon: startIcon ? (_jsx(Icon, { path: startIcon, width: "12px", height: "12px" })) : null, disableElevation: true, color: color, children: label }));
                    }) }))] })) }));
};
