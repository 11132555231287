import { Box } from "@rebass/grid";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import {
  DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT,
  DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT,
  DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT,
} from "@rentiohq/shared-frontend/dist/redux/count/count.config";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import * as countTypes from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import { ECountIdentifier } from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import {
  ERegistrationContractStatus,
  ERegistrationDocumentType,
} from "@rentiohq/shared-frontend/dist/types/registration.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Card,
  ESpacings,
  Filters,
  OptionListShared,
  Page,
  RegistrationDocuments as RegistrationDocumentsBase,
  Stages,
} from "@rentiohq/web-shared/dist/components";
import { useState } from "react";
import { NumberParam, createEnumParam } from "serialize-query-params";

const getStatusesFilter = (
  stage: string | undefined,
): ERegistrationContractStatus[] | undefined => {
  let filter: ERegistrationContractStatus[] | undefined;
  switch (stage) {
    case "in_progress":
      filter = DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT;
      break;
    case "action_required":
      filter = DEFAULT_STATUSES_REGISTRATION_ACTION_REQUIRED_CONTRACT;
      break;
    case "registered":
      filter = DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT;
      break;
    default:
      filter = undefined;
      break;
  }
  return filter;
};

export const RegistrationDocuments = () => {
  const [queryParams, setQueryParamValue] = useQueryParams({
    stage: createEnumParam(["in_progress", "action_required", "registered"]),
    registrationId: NumberParam,
  });

  const { count: registrationAddendaActionRequiredCount = 0 } = useCount({
    shouldRefetch: true,
    countBase:
      countTypes.COUNT_BASE[
        ECountIdentifier.RegistrationsAddendaActionRequired
      ],
  });

  const { count: registrationAddendaInProgressCount = 0 } = useCount({
    shouldRefetch: true,
    countBase:
      countTypes.COUNT_BASE[ECountIdentifier.RegistrationsAddendaInProgress],
  });

  const { count: registrationAddendaRegisteredCount = 0 } = useCount({
    shouldRefetch: true,
    countBase:
      countTypes.COUNT_BASE[ECountIdentifier.RegistrationsAddendaRegistered],
  });

  // State
  const [query, setQuery] = useState<string | undefined>();
  const [statuses, setStatuses] = useState<
    ERegistrationContractStatus[] | undefined
  >(getStatusesFilter(queryParams.stage));

  const filter = {
    where: {
      documentType: ERegistrationDocumentType.Addendum,
      ...(statuses && statuses.length > 0
        ? {
            status: {
              inq: statuses,
            },
          }
        : {}),
    },
  };

  // Event handlers
  const handleApplyInProgressFilter = () => {
    setStatuses(DEFAULT_STATUSES_REGISTRATION_IN_PROGRESS_CONTRACT);
  };

  const handleApplyRegisteredFilter = () => {
    setStatuses(DEFAULT_STATUSES_REGISTRATION_REGISTERED_CONTRACT);
  };

  const handleQueryChange = (value: string) => {
    if (value.length === 0) {
      setQuery(undefined);
      return;
    }

    setQuery(value);
  };

  const handleQueryClear = () => {
    setQuery(undefined);
  };

  const handleClearFilter = () => {
    setQuery(undefined);
    setStatuses(undefined);
  };

  const handleStatusChange = (
    value: ERegistrationContractStatus[] | ERegistrationContractStatus,
  ) => {
    if (!Array.isArray(value)) {
      return;
    }
    setStatuses(value.length > 0 ? value : undefined);
  };

  // Render
  const renderFilters = () => {
    const appliedFilters = (statuses || []).map(status => ({
      key: "status",
      label: getLocalizedText(
        `registration.status.${stringToSnakeCase(status)}`.toLowerCase(),
      ),
      onRemove: () => {
        if (statuses) {
          setStatuses([
            ...statuses.filter(randomStatus => randomStatus !== status),
          ]);
        }
      },
    }));

    return (
      <Box mb={ESpacings.loose}>
        <Filters
          appliedFilters={appliedFilters}
          queryValue={query || ""}
          queryPlaceholder={getLocalizedText(
            "follow_up.registrations.filter.query.placeholder",
          )}
          onQueryChange={handleQueryChange}
          onQueryClear={handleQueryClear}
          onClearAll={handleClearFilter}
          filters={[
            {
              key: "status",
              label: getLocalizedText("registration.filter.status", {
                type: getLocalizedText(
                  "system.addendum".toLowerCase(),
                ).toLowerCase(),
              }),
              content: (
                <OptionListShared
                  id="stage"
                  value={statuses}
                  variant="simple"
                  multiple={true}
                  options={Object.values(ERegistrationContractStatus)
                    .filter(x => x !== ERegistrationContractStatus.None)
                    .map(status => {
                      return {
                        label: getLocalizedText(
                          `registration.status.${stringToSnakeCase(
                            status,
                          )}`.toLowerCase(),
                        ),
                        value: status,
                        id: status,
                      };
                    })}
                  // @ts-ignore
                  onChange={handleStatusChange}
                />
              ),
            },
          ]}
        />
      </Box>
    );
  };

  return (
    <Page
      fullWidth
      title={getLocalizedText("follow_up.registration_documents.page.title")}
      metadata={getLocalizedText(
        "follow_up.registration_documents.page.description",
      )}
      breadcrumbs={{
        to: "/",
        content: getLocalizedText("system.back"),
      }}
    >
      <Card>
        <Stages
          stages={[
            {
              heading: getLocalizedText(
                "follow_up.registration_documents.stage.in_progress",
              ),
              icon: "contentPenWrite",
              count: registrationAddendaInProgressCount,
              onClick: handleApplyInProgressFilter,
            },
            {
              heading: getLocalizedText(
                "follow_up.registration_documents.stage.registered",
              ),
              appearance: "warning",
              icon: "contentPenWrite",
              count: registrationAddendaActionRequiredCount,
              onClick: handleApplyRegisteredFilter,
            },
            {
              heading: getLocalizedText(
                "follow_up.registration_documents.stage.registered",
              ),
              icon: "billStack",
              count: registrationAddendaRegisteredCount,
              onClick: handleApplyRegisteredFilter,
            },
          ]}
        />
      </Card>

      <RegistrationDocumentsBase
        filter={filter}
        renderFilters={renderFilters}
        onClick={registrationDocument => {
          setQueryParamValue({
            registrationId: registrationDocument.rentRegistrationId,
          });
        }}
      />
    </Page>
  );
};
