var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import MuiAvatar from "@mui/material/Avatar";
import styled from "styled-components";
var getColorStyles = function (color, outline) {
    if (color && !outline) {
        return {
            color: "background.paper",
            bgcolor: "grey.200",
        };
    }
    if (outline) {
        return {
            color: color ? "".concat(color, ".main") : "primary.main",
            bgcolor: "background.paper",
            border: "2px solid",
            borderColor: color ? "".concat(color, ".main") : "primary.main",
        };
    }
    return {};
};
var getSizeStyles = function (size) {
    switch (size) {
        case "badge":
            return { width: 28, height: 28 };
        case "xs":
            return { width: 34, height: 34 };
        case "sm":
            return { width: 40, height: 40 };
        case "lg":
            return { width: 72, height: 72 };
        case "xl":
            return { width: 82, height: 82 };
        case "md":
            return { width: 60, height: 60 };
        default:
            return {};
    }
};
export var StyledAvatar = styled(MuiAvatar)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n  ", "\n"], ["\n  ", "\n  ", "\n"])), function (_a) {
    var color = _a.color, outline = _a.outline;
    return getColorStyles(color, outline);
}, function (_a) {
    var size = _a.size;
    return getSizeStyles(size);
});
export var AvatarStyles = function (color, outline, size) {
    return __assign(__assign(__assign({}, getColorStyles(color, outline)), { background: "#E3E8EF" }), getSizeStyles(size));
};
var templateObject_1;
