import { ACTIVITY_TYPES } from "@rentiohq/shared-frontend/dist/types/activity.types";
import { addDays, startOfDay, subMonths, } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { EField } from "./schema.exportActivities.types";
var TOMORROW = addDays(new Date(), 1);
var START_OF_DAY = startOfDay(new Date());
export var getInitialValues = function (extraData) {
    var _a;
    var selectedTypes = extraData.selectedTypes, lastLoadedActivity = extraData.lastLoadedActivity;
    var types = (selectedTypes === null || selectedTypes === void 0 ? void 0 : selectedTypes.map(function (type) { return ACTIVITY_TYPES[type]; })) || [];
    if (types.length === 0) {
        types = Object.values(ACTIVITY_TYPES);
    }
    return _a = {},
        _a[EField.Types] = types,
        _a[EField.From] = (lastLoadedActivity === null || lastLoadedActivity === void 0 ? void 0 : lastLoadedActivity.datetime) || subMonths(START_OF_DAY, 1),
        _a[EField.To] = TOMORROW,
        _a;
};
