import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { EField } from "./schema.duplicateTemplatePropertySelect.types";

export const getSchema =
  (initialValues: { [key: string]: unknown }) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    return {
      required: [EField.Property],
      properties: {
        [EField.Property]: {
          default: getValue(EField.Property, initialValues, defaultValues),
        },
      },
    };
  };

export const uiSchema = (): IUiSchema<EField> => {
  return {
    [EField.Property]: {
      "ui:field": ERentioFormField.PropertySelectInline,
      title: utils.i18n.t(
        "create_template_document_contract.modal.duplicate_property_select",
      ),
      selectPropertyFromListCtaTitle: undefined,
      hideOnSummaryIfEmpty: true,
      valueSelector: (state: IPartialRootState, propertyId: number) => {
        if (propertyId) {
          const property = state.PROPERTY_V2.detail[propertyId]?.entity;
          if (property) {
            return property.name;
          }

          return getLocalizedText("summary.view.property");
        }
        return getLocalizedText("property.no.summary");
      },
    },
  };
};
