var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { capitalizeString, stringToSnakeCase, } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { EditorState, Modifier, RichUtils, SelectionState } from "draft-js";
import { getBlockBeforeSelectedBlock } from "draftjs-utils";
import { Map } from "immutable";
import merge from "lodash/merge";
import React from "react";
import { Icon } from "../../../../components";
import { useToggle } from "../../../../hooks/useToggle";
import { performActions, removeSelectedText } from "../../utils/draftjs.utils";
import { getTextStylesBackgroundColor, getTextStylesTextColor, } from "../Editor/Editor.config";
import { ETextAlignment } from "../Editor/Editor.types";
import { DisplayTypeContext } from "../Editor/contexts/DisplayType.context";
import { DocumentConfigContext } from "../Editor/contexts/DocumentConfig.context";
import { insertIndexCountAtStartOfSelection } from "../Editor/decorators/IndexCountRenderer/IndexCountRenderer.utils";
import InsertConditionModal from "../InsertConditionModal";
import InsertLoopModal from "../InsertLoopModal";
import InsertVariableModal from "../InsertVariableModal";
import ToolbarButton from "../ToolbarButton";
import * as S from "./Toolbar.styles";
import { ECustomAction, STYLE_ACTIONS, getBlockTypeActions, getInsertCustomActions, } from "./Toolbar.utils";
export var Toolbar = function (props) {
    var editorState = props.editorState, setEditorState = props.setEditorState;
    // Context
    var isTemplate = React.useContext(DisplayTypeContext).isTemplate;
    var documentConfig = React.useContext(DocumentConfigContext).documentConfig;
    // Helpers
    var textStylesTextColor = getTextStylesTextColor(documentConfig);
    var textStylesBackgroundColor = getTextStylesBackgroundColor(documentConfig);
    // State
    var _a = React.useState(false), showInsertVariableModal = _a[0], setShowInsertVariableModal = _a[1];
    var _b = useToggle(false), showInsertLoopModal = _b[0], toggleInsertLoopModal = _b[1];
    var _c = useToggle(false), showInsertConditionModal = _c[0], toggleInsertConditionModal = _c[1];
    // Event handlers
    var onClickCustomAction = function (customAction) {
        switch (customAction) {
            case ECustomAction.InsertVariable:
                setTimeout(function () {
                    setShowInsertVariableModal(true);
                }, 0);
                break;
            case ECustomAction.InsertCondition:
                setTimeout(function () {
                    toggleInsertConditionModal();
                }, 0);
                break;
            case ECustomAction.InsertLoop:
                setTimeout(function () {
                    toggleInsertLoopModal();
                }, 0);
                break;
            case ECustomAction.InsertIndexCount:
                setTimeout(function () {
                    var newEditorState = performActions(editorState, [
                        removeSelectedText,
                        function (x) {
                            // TODO: Make id variable
                            return insertIndexCountAtStartOfSelection(x, { id: "default" });
                        },
                    ]);
                    setEditorState(newEditorState);
                }, 0);
                break;
            case ECustomAction.InsertPageBreak:
            case ECustomAction.InsertImage:
            default:
                break;
        }
    };
    var toggleInlineStyle = function (style) {
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };
    var toggleCustomInlineStyle = function (key, styles) {
        var selection = editorState.getSelection();
        // Allow one color at a time. Turn off all active colors
        var nextContentState = Object.keys(styles).reduce(function (contentState, color) {
            return Modifier.removeInlineStyle(contentState, selection, color);
        }, editorState.getCurrentContent());
        var nextEditorState = EditorState.push(editorState, nextContentState, "change-inline-style");
        var currentStyle = editorState.getCurrentInlineStyle();
        // Unset style override for current color
        if (selection.isCollapsed()) {
            nextEditorState = currentStyle.reduce(function (state, color) {
                return RichUtils.toggleInlineStyle(state, color);
            }, nextEditorState);
        }
        // If the color is being toggled on, apply it
        if (!currentStyle.has(key)) {
            nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, key);
        }
        setEditorState(nextEditorState);
    };
    var handleTextAlignmentChange = function (textAlignment) {
        var newContentState = Modifier.mergeBlockData(editorState.getCurrentContent(), editorState.getSelection(), Map({
            textAlignmentClass: "text-alignment-".concat(textAlignment),
        }));
        setEditorState(EditorState.push(editorState, newContentState, "change-block-data"));
    };
    var handleRemoveBlock = function () {
        var selectionState = editorState.getSelection();
        var anchorKey = selectionState.getAnchorKey();
        var currentContent = editorState.getCurrentContent();
        var currentContentBlock = currentContent.getBlockForKey(anchorKey);
        if (!currentContentBlock) {
            return;
        }
        var previousContentBlock = getBlockBeforeSelectedBlock(editorState);
        if (!previousContentBlock) {
            showAlert({
                type: "error",
                message: "Removing first block is not possible.",
            });
            return;
        }
        var removeSelection = new SelectionState({
            anchorKey: previousContentBlock.getKey(),
            anchorOffset: previousContentBlock.getText().length,
            focusKey: currentContentBlock.getKey(),
            focusOffset: currentContentBlock.getText().length,
        });
        var newContentState = Modifier.removeRange(currentContent, removeSelection, "forward");
        setEditorState(EditorState.push(editorState, newContentState, "remove-range"));
    };
    // Helpers
    var currentInlineStyle = editorState.getCurrentInlineStyle();
    var selectionState = editorState.getSelection();
    var isCollapsed = selectionState.isCollapsed();
    var anchorKey = selectionState.getAnchorKey();
    var currentContent = editorState.getCurrentContent();
    var currentContentBlock = currentContent.getBlockForKey(anchorKey);
    var contentBlockType = currentContentBlock.getType();
    var currentStyle = props.editorState.getCurrentInlineStyle();
    var styles = currentStyle.map(function (style) { return style; }).toArray();
    var selectedStyle = {};
    styles.forEach(function (key) {
        if (!key) {
            return;
        }
        var styleTextColor = textStylesTextColor[key];
        if (styleTextColor) {
            selectedStyle = merge({}, selectedStyle, styleTextColor.styles);
        }
        var styleBackgroundColor = textStylesBackgroundColor[key];
        if (styleBackgroundColor) {
            selectedStyle = merge({}, selectedStyle, styleBackgroundColor.styles);
        }
    });
    // Render
    return (_jsxs(_Fragment, { children: [_jsxs(S.Wrap, { children: [_jsx(ToolbarButton, { iconSource: "add", showDropdownIcon: false, dropdownActions: getInsertCustomActions({
                            editorState: editorState,
                            setEditorState: setEditorState,
                            onClickCustomAction: onClickCustomAction,
                            isTemplate: isTemplate,
                        }) }), _jsx(S.ButtonSeparator, {}), _jsx(ToolbarButton
                    // minWidth={260}
                    , { 
                        // minWidth={260}
                        title: getLocalizedText("editor.block_type.".concat(stringToSnakeCase(contentBlockType))), dropdownActions: getBlockTypeActions({
                            setEditorState: setEditorState,
                            editorState: editorState,
                        }) }), _jsx(S.ButtonSeparator, {}), STYLE_ACTIONS.map(function (buttonConfig) { return (_jsx(ToolbarButton, { 
                        // title={buttonConfig.title}
                        iconSource: buttonConfig.icon, onClick: function () { return toggleInlineStyle(buttonConfig.id); }, isActive: currentInlineStyle.has(buttonConfig.id), isDisabled: isCollapsed }, buttonConfig.id)); }), _jsx(S.ButtonSeparator, {}), Object.values(ETextAlignment).map(function (alignment) { return (_jsx(ToolbarButton, { iconSource: "icoEditorAlign".concat(capitalizeString(alignment)), onClick: function () {
                            handleTextAlignmentChange(alignment);
                        } }, alignment)); }), _jsx(S.ButtonSeparator, {}), _jsx(ToolbarButton, { icon: _jsx("div", { style: __assign({ display: "inline-block", borderRadius: 5, border: "1px solid lightgray", padding: 1 }, selectedStyle), children: _jsx(Icon, { source: "icoEditorColor", realColor: selectedStyle.color || "black", size: "small" }) }), title: getLocalizedText("editor.text_styles.colors"), isDisabled: isCollapsed, dropdownActions: isCollapsed
                            ? []
                            : [
                                Object.keys(textStylesTextColor).map(function (key) {
                                    var textStyle = textStylesTextColor[key];
                                    return {
                                        iconBefore: (_jsx("div", { style: __assign({ display: "inline-block", borderRadius: 5, border: "1px solid lightgray", padding: 1, marginRight: 6 }, textStyle.styles), children: _jsx(Icon, { source: "icoEditorColor", realColor: textStyle.styles.color || "black", size: "small" }) })),
                                        content: textStyle.label,
                                        onClick: function () {
                                            toggleCustomInlineStyle(key, textStylesTextColor);
                                        },
                                    };
                                }),
                                Object.keys(textStylesBackgroundColor).map(function (key) {
                                    var textStyle = textStylesBackgroundColor[key];
                                    return {
                                        iconBefore: (_jsx("div", { style: __assign({ display: "inline-block", borderRadius: 5, border: "1px solid lightgray", padding: 1, marginRight: 6 }, textStyle.styles), children: _jsx(Icon, { source: "icoEditorColor", 
                                                // realColor={textStyle.styles.color || "black"}
                                                // realColor={color.darkColor ? "white" : "black"}
                                                size: "small" }) })),
                                        content: textStyle.label,
                                        onClick: function () {
                                            toggleCustomInlineStyle(key, textStylesBackgroundColor);
                                        },
                                    };
                                }),
                            ] }), _jsx(S.ButtonSeparator, {}), isTemplate && (_jsxs(_Fragment, { children: [_jsx(ToolbarButton, { iconSource: "bin", onClick: handleRemoveBlock }), _jsx(S.ButtonSeparator, {})] })), _jsx(ToolbarButton, { iconSource: "chevronLeft", showDropdownIcon: false, onClick: function () {
                            setEditorState(EditorState.undo(editorState));
                        }, isDisabled: editorState.getUndoStack().count() === 0 }), _jsx(ToolbarButton, { iconSource: "chevronRight", showDropdownIcon: false, onClick: function () {
                            setEditorState(EditorState.redo(editorState));
                        }, isDisabled: editorState.getRedoStack().count() === 0 }), _jsx(S.ButtonSeparator, {})] }), showInsertLoopModal && (_jsx(InsertLoopModal, { editorState: editorState, setEditorState: setEditorState, onClose: toggleInsertLoopModal })), showInsertConditionModal && (_jsx(InsertConditionModal, { editorState: editorState, setEditorState: setEditorState, onClose: toggleInsertConditionModal })), showInsertVariableModal && (_jsx(InsertVariableModal, { editorState: editorState, setEditorState: setEditorState, onClose: function () { return setShowInsertVariableModal(false); } }))] }));
};
// eslint-disable-next-line import/no-default-export
export default Toolbar;
