import { EFontWeights } from "../../themes/types";
export var TabsStyles = function () {
    return {
        wrapper: {
            mb: 3,
            ".MuiTab-root": {
                minHeight: "auto",
                minWidth: 10,
                px: 0,
                mx: 1.5,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                textTransform: "uppercase",
                fontWeight: EFontWeights.Medium,
                "&:first-child": {
                    pl: 0,
                    ml: 0,
                },
            },
            "& a > svg": {
                mb: "0px !important",
                mr: 1.1,
            },
        },
        content: {
            tabChip: {
                color: "secondary.main",
                bgcolor: "secondary.light",
                ml: 1.3,
            },
        },
    };
};
