import { Box } from "@rebass/grid";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  ESpacings,
  IOptionListOption,
  Select,
} from "@rentiohq/web-shared/dist/components";

interface ISelectEmployeeDropdownProps {
  accountId?: number;
  employeeAccounts: IAccount[];
  hideDropdown: (accountId: number) => void;
  onSelectEmployee: (selected: IOptionListOption) => void;
}

export const SelectEmployeeDropdown = (props: ISelectEmployeeDropdownProps) => {
  const { accountId, employeeAccounts, hideDropdown, onSelectEmployee } = props;

  return (
    <Box mt={ESpacings.base}>
      <Select
        id={`case-manager-${accountId ?? "empty"}`}
        label="_"
        labelHidden={true}
        options={employeeAccounts.map(employeeAccount => {
          return {
            label: getName(employeeAccount),
            value: employeeAccount.id,
          };
        })}
        onChange={onSelectEmployee}
      />

      <Box mt={1}>
        <Button appearance="link" onClick={hideDropdown}>
          {getLocalizedText("system.cancel")}
        </Button>
      </Box>
    </Box>
  );
};
