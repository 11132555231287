import { Stack, TabPanel } from "@rentiohq/web-shared-next/dist/ui-components";
import { ContractList } from "../../components/ContractList";

import {
  getActiveContracts,
  getEndedContracts,
} from "@rentiohq/shared-frontend/dist/forms/createPayment/schema.createPayment.utils";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { EContractType } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { SHOW_LOADER } from "@rentiohq/web-shared-next/dist/redux/actions";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { EPropertyDetailsTabs } from "../../propertyDetails.types";

export const Contracts = () => {
  const dispatch = useDispatch();

  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const { items: contracts = [], isFetching } = contractHooks.useGetAll({
    query: {
      filter: {
        and: [
          { propertyId: { eq: propertyId } },
          {
            contractType: { eq: EContractType.Basic },
          },
        ],
      },
      sort: [
        {
          field: "startDate",
          method: "DESC",
        },
      ],
    },
  });

  React.useEffect(() => {
    dispatch({ type: SHOW_LOADER, payload: isFetching });
  }, [isFetching]);

  const activeContracts = getActiveContracts(contracts);
  const endedContracts = getEndedContracts(contracts);

  return (
    <TabPanel
      value={EPropertyDetailsTabs.Contract}
      index={EPropertyDetailsTabs.Contract}
    >
      <Stack direction="column" gap={2}>
        {activeContracts?.length > 0 && (
          <ContractList
            contracts={activeContracts}
            title={getLocalizedText("contract.title.running")}
          />
        )}

        {endedContracts?.length > 0 && (
          <ContractList
            contracts={endedContracts}
            title={getLocalizedText("contract.title.ended")}
          />
        )}
      </Stack>
    </TabPanel>
  );
};
