import { jsx as _jsx } from "react/jsx-runtime";
import { mapActivitySubtypeToString, mapActivityTypeToString, } from "@rentiohq/shared-frontend/dist/utils/activity.utils";
import { formatDateAndTime } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { RentioInternalRenderer } from "./RentioInternalRenderer";
export var RentioInternalRendererActivity = function (props) {
    var activity = props.activity;
    return (_jsx(RentioInternalRenderer, { items: {
            id: activity.id,
            type: "".concat(mapActivityTypeToString(activity.type, false), " (").concat(activity.type, ")"),
            subtype: "".concat(mapActivitySubtypeToString(activity.subtype, false), " (").concat(activity.subtype, ")"),
            createdAt: activity.datetime
                ? formatDateAndTime(activity.datetime)
                : undefined,
        } }));
};
