import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { useSelector } from "react-redux";
import { sidebarStateSelector } from "../../redux/selectors";
import menuItems from "../../utils/menu-items";
import NavItem from "./NavItem";
export var MenuList = function () {
    var sidebarOpened = useSelector(sidebarStateSelector);
    var navItems = menuItems
        .filter(function (item) { return !item.disabled; })
        .map(function (item) {
        return (_jsx(List, { sx: { py: 0 }, children: _jsx(NavItem, { item: item, sidebarOpened: sidebarOpened }) }, item.id));
    });
    return _jsx(Box, { sx: { mt: 1.5 }, children: navItems });
};
