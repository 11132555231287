import hash from "object-hash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { append } from "../../utils/api.utils";
import * as countActions from "../count/count.actions";
import * as countSelectors from "../count/count.selectors";
import { getGetPagedAction } from "./paged.actions";
import { getPagedSelectors } from "./paged.selectors";
import { getPagedId } from "./paged.utils";
export var pagedGenerator = function (paramsGenerator) {
    var getBaseState = paramsGenerator.getBaseState, entityName = paramsGenerator.entityName, resource = paramsGenerator.resource;
    var getPaged = getGetPagedAction({
        entityName: entityName,
    });
    return function (params) {
        var page = params.page, limit = params.limit, order = params.order, filter = params.filter, customResource = params.customResource, _a = params.withPaged, withPaged = _a === void 0 ? true : _a, _b = params.withCount, withCount = _b === void 0 ? false : _b, _c = params.preventDefaultFetching, preventDefaultFetching = _c === void 0 ? false : _c, _d = params.disabled, disabled = _d === void 0 ? false : _d;
        var id = params.id ||
            getPagedId({
                entityName: entityName,
                order: order,
                filter: filter,
            });
        // Redux
        var dispatch = useDispatch();
        var selectors = getPagedSelectors(getBaseState);
        var data = useSelector(function (state) {
            return selectors.dataForPage(state, {
                id: id,
                page: page,
            });
        });
        var isFetching = useSelector(function (state) {
            return selectors.isFetchingPage(state, { id: id, page: page });
        });
        var fetchError = useSelector(function (state) {
            return selectors.fetchPageError(state, { id: id, page: page });
        });
        var countIdentifier = filter
            ? "".concat(entityName, "-").concat(hash(filter))
            : entityName;
        var totalCount = useSelector(function (state) {
            return countSelectors.getCount(state, countIdentifier);
        }) || 0;
        var pageCount = totalCount ? Math.ceil(totalCount / (limit || 20)) : 1;
        var isFetchingCount = false;
        var countFetchError = undefined;
        // Data
        var fetchPage = function () {
            if (disabled) {
                return;
            }
            if (!withPaged) {
                return;
            }
            if (isFetching) {
                return;
            }
            dispatch(getPaged.actions.start({
                id: id,
                page: page,
                limit: limit,
                order: order,
                filter: filter,
                customResource: customResource,
            }));
        };
        var fetchCount = function () {
            if (disabled) {
                return;
            }
            if (!withCount) {
                return;
            }
            if (isFetchingCount) {
                return;
            }
            dispatch(countActions.getCount.actions.start({
                countIdentifier: countIdentifier,
                countBase: append("/".concat(customResource || resource, "/count"), filter || {}),
            }));
        };
        // Lifecycle
        React.useEffect(function () {
            if (preventDefaultFetching) {
                return;
            }
            fetchPage();
        }, [id, page]);
        React.useEffect(function () {
            if (preventDefaultFetching) {
                return;
            }
            fetchCount();
        }, [id, withCount]);
        return {
            // Data
            data: data,
            isFetching: isFetching,
            fetchError: fetchError,
            refetchPage: fetchPage,
            // Count
            totalCount: totalCount,
            pageCount: pageCount,
            isFetchingCount: isFetchingCount,
            countFetchError: countFetchError,
        };
    };
};
