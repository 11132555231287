import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EProduct } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { TextStyle } from "../TextStyle";
import * as S from "./Frame.styled";
import { Header } from "./components";
var FrameBase = function (props) {
    var children = props.children, user = props.user, navigation = props.navigation, _a = props.userMenuActions, userMenuActions = _a === void 0 ? [] : _a, _b = props.showHelp, showHelp = _b === void 0 ? false : _b, _c = props.showSearch, showSearch = _c === void 0 ? false : _c, headerItems = props.headerItems;
    var shouldShowBanner = CONFIG.PRODUCT === EProduct.Dashboard &&
        user &&
        !user.broker &&
        !user.hasCreateAccess;
    return (_jsxs(S.Wrap, { children: [_jsx(S.HeaderWrap, { children: _jsx(Header, { showHelp: showHelp, showSearch: showSearch, extraItems: headerItems, userMenuActions: userMenuActions }) }), _jsxs(S.ContentWrap, { children: [_jsxs(S.SidebarWrap, { children: [_jsx(S.SidebarBackground, {}), _jsx(S.NavigationWrap, { children: navigation })] }), _jsxs(S.ChildrenWrap, { children: [shouldShowBanner && (_jsx(S.BannerWrap, { children: _jsx(TextStyle, { variation: "strong", element: "div", children: getLocalizedText("user.no_create_access.warning.title") }) })), _jsx(S.ChildrenInnerWrap, { shouldShowBanner: shouldShowBanner, children: children })] })] })] }));
};
export var Frame = React.memo(FrameBase);
