import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";

export const getIndexationLetterTitle = (params: { property: IProperty }) => {
  const { property } = params;

  return getLocalizedText(
    "activity.template_document.title.indexation_letter",
    { address: formatAddress(property), date: formatDate(new Date()) },
  );
};
