import { getTitle } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import { IMember } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { IChat } from "@rentiohq/shared-frontend/dist/types/chat.types";
import {
  AccountList,
  Card,
  DisplayText,
  Page,
  PropertyList,
} from "@rentiohq/web-shared/dist/components";
import * as t from "../../../../services/translationService";

interface IProps {
  chat: IChat;
}

export const InfoChat = (props: IProps) => {
  const { chat } = props;

  const title = getTitle({ chat });

  return (
    <Page
      title={t.chatsInfoTitle(title)}
      breadcrumbs={{
        content: t.chatsDetailBreadcrumbAction(),
        to: `/chats/${chat.id}`,
      }}
      hasBorder={true}
    >
      <Card>
        <DisplayText>{t.chatParticipants()}</DisplayText>
        <AccountList
          accounts={chat.members.map((member: IMember<any>) => member.account)}
          mediaSize="large"
        />
      </Card>
      {chat.propertyId && (
        <Card>
          <DisplayText>{t.system("property")}</DisplayText>
          <PropertyList propertyIds={[chat.propertyId]} />
        </Card>
      )}
    </Page>
  );
};
