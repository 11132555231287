import { jsxs as _jsxs, Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import "draft-js/dist/Draft.css";
import React from "react";
import { getVariablesForGroup } from "../../../Editor/Editor.utils";
import { FieldsContext } from "../../../Editor/contexts/Fields.context";
import LoopContextProvider from "../../../Editor/contexts/Loop.context";
import { VariablesDataContext } from "../../../Editor/contexts/VariablesData.context";
var Loop = function (props) {
    var _a;
    var loopId = props.id;
    // Context
    var fields = React.useContext(FieldsContext).fields;
    var variablesData = React.useContext(VariablesDataContext).variablesData;
    var loop = (_a = fields.loops) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === loopId; });
    var variableId = loop === null || loop === void 0 ? void 0 : loop.variableId;
    var groupId = loop === null || loop === void 0 ? void 0 : loop.groupId;
    // Find loop amounts for variable or group
    var loopCount = 1;
    if (variableId) {
        loopCount = (variablesData[variableId] || []).length;
    }
    if (groupId) {
        var variablesForGroup = getVariablesForGroup({
            groupId: groupId,
            fields: fields,
            variablesData: variablesData,
            skipCondition: false,
        });
        var leadingVariable = variablesForGroup.find(function (_a) {
            var _b = _a.isRequired, isRequired = _b === void 0 ? true : _b;
            return isRequired;
        }) ||
            variablesForGroup[0];
        loopCount = (variablesData[leadingVariable === null || leadingVariable === void 0 ? void 0 : leadingVariable.id] || [""]).length;
    }
    loopCount = Math.max(1, loopCount);
    // Render
    return (_jsx(_Fragment, { children: Array(loopCount)
            .fill(undefined)
            .map(function (_, index) {
            var renderLastSeparator = loopCount - 1 === index && index > 0;
            var renderSeparator = !renderLastSeparator && index > 0;
            return (_jsxs(LoopContextProvider, { loopConfig: {
                    index: index,
                    loopId: loopId,
                    groupId: groupId,
                    variableId: variableId,
                }, children: [renderSeparator && (loop === null || loop === void 0 ? void 0 : loop.separator), renderLastSeparator && (loop === null || loop === void 0 ? void 0 : loop.lastSeparator), props.children] }, index));
        }) }));
};
// eslint-disable-next-line import/no-default-export
export default Loop;
