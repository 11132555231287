import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import {
  IGroupActionContentBase,
  TGroupActionsLocale,
} from "@rentiohq/shared-frontend/dist/types/groupAction.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { TextField } from "@rentiohq/web-shared/dist/components";
import { isEmpty } from "lodash";
import React from "react";
import { getLocaleKey } from "../GroupActions.util";
import { EGroupActionType } from "../GroupActionsModal.types";

export interface IAddGroupActionContentSectionProps {
  type: EGroupActionType;
  index: number;
  locale: TGroupActionsLocale;
  initialContent: IGroupActionContentBase;
  children?: React.ReactNode;
  onChange: (content: IGroupActionContentBase) => void;
  onChangeTitle?: (key: TGroupActionsLocale, value?: string) => void;
  onChangeSubject?: (key: TGroupActionsLocale, value?: string) => void;
  onChangeError?: (error?: string) => void;
}

const AddGroupActionContentSection = (
  props: IAddGroupActionContentSectionProps,
) => {
  const {
    type,
    index,
    locale,
    initialContent,
    onChange,
    onChangeTitle,
    onChangeSubject,
    onChangeError,
  } = props;

  // State
  const [content, setContent] =
    React.useState<IGroupActionContentBase>(initialContent);
  const [title, setTitle] = React.useState<string>("");
  const [titleError, setTitleError] = React.useState<string | undefined>("");

  const [subject, setSubject] = React.useState<string>("");
  const [subjectError, setSubjectError] = React.useState<string | undefined>(
    "",
  );

  const [contentError, setContentError] = React.useState<string | undefined>(
    "",
  );

  // Effects
  React.useEffect(() => {
    onChange(content);
  }, [content]);

  React.useEffect(() => {
    onChangeTitle?.(locale, title);
  }, [title]);

  React.useEffect(() => {
    onChangeSubject?.(locale, subject);
  }, [subject]);

  React.useEffect(() => {
    onChangeError?.(titleError || subjectError || contentError);
  }, [titleError, subjectError, contentError]);

  // Helpers
  const getMaxTextLength = () => {
    if (type === EGroupActionType.Sms) {
      return 160;
    }

    if (type === EGroupActionType.Notification) {
      return 178;
    }
  };

  // Events
  const handleContentChange = (value: string) => {
    setContent({ ...content, value });
  };

  // Renders
  const renderChildren = () => {
    const lowerCaseType = type.toLowerCase();

    switch (type) {
      case EGroupActionType.Notification:
        return (
          <>
            <TextField
              name={`group_actions_modal_${lowerCaseType}_title_${locale}`}
              label={getLocalizedText(
                `group_actions.modal.title.label.${getLocaleKey(locale)}`,
              )}
              value={title}
              error={titleError}
              type="input"
              onChange={(
                e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
              ) => {
                const { value } = e.target;

                if (isEmpty(value)) {
                  setTitleError(
                    `The ${getLocalizedText(
                      `group_actions.modal.title.label.${getLocaleKey(locale)}`,
                    )} is required`,
                  );
                } else {
                  setTitleError(undefined);
                }

                setTitle(value);
              }}
            />
            <Spacer weight={ESpacerWeight.W08} />
          </>
        );

      case EGroupActionType.Email:
        return (
          <>
            <TextField
              name={`group_actions_modal_${lowerCaseType}_subject_${locale}`}
              label={getLocalizedText(
                `group_actions.modal.subject.label.${getLocaleKey(locale)}`,
              )}
              value={subject}
              error={subjectError}
              type="input"
              onChange={(
                e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
              ) => {
                const { value } = e.target;

                if (isEmpty(value)) {
                  setSubjectError(
                    `The ${getLocalizedText(
                      `group_actions.modal.subject.label.${getLocaleKey(
                        locale,
                      )}`,
                    )} is required`,
                  );
                } else {
                  setSubjectError(undefined);
                }

                setSubject(value);
              }}
            />
            <Spacer weight={ESpacerWeight.W08} />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {index > 0 && <Spacer weight={ESpacerWeight.W32} />}
      {renderChildren()}
      <TextField
        name={content.key}
        label={content.label}
        value={content.value || ""}
        type="textarea"
        maxLength={getMaxTextLength()}
        multiline
        error={contentError}
        onChange={(
          e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
        ) => {
          const { value } = e.target;
          if (isEmpty(value)) {
            setContentError(`The ${content.label} is required`);
          } else {
            setContentError(undefined);
          }

          handleContentChange(value);
        }}
      />
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default AddGroupActionContentSection;
