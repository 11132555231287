import merge from "lodash/merge";
import { createReducer } from "redux-act";
import { getDefaultStateAll, registerActions, } from "../generic/all.reducer";
import CONFIG from "./employeeAccount.config";
export var REDUCER_KEY_EMPLOYEE_ACCOUNT = CONFIG.REDUCER_KEY;
var getDefaultState = function () {
    return merge({}, getDefaultStateAll(), {});
};
var reducer = createReducer({}, getDefaultState());
reducer.on("LOG_OUT", function () { return getDefaultState(); });
registerActions({
    entityName: CONFIG.ENTITY_NAME,
    reducer: reducer,
});
// eslint-disable-next-line import/no-default-export
export default reducer;
