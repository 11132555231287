var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Flex } from "@rebass/grid";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import { DisplayText, DropdownMenu, Icon } from "../../../components";
import { ESpacings } from "../../../components/Grid";
import { renderActions } from "../../Button";
import { TextStyle } from "../../TextStyle";
export var Header = function (_a) {
    var heading = _a.heading, subHeading = _a.subHeading, primaryActions = _a.primaryActions, actions = _a.actions, space = _a.space, icon = _a.icon, _b = _a.iconPosition, iconPosition = _b === void 0 ? "left" : _b, _c = _a.iconSize, iconSize = _c === void 0 ? "medium" : _c;
    var actionMarkup = (_jsxs(_Fragment, { children: [primaryActions &&
                renderActions(primaryActions.map(function (action) { return (__assign({ appearance: "primary", size: "small" }, action)); })), actions && !isEmpty(actions) ? (_jsx(DropdownMenu, { children: _jsx("div", { children: _jsx(Icon, { source: "navigationMenuVertical" }) }), actions: actions })) : null, " "] }));
    // eslint-disable-next-line no-nested-ternary
    var headingMarkup = heading ? (React.isValidElement(heading) ? (heading) : (_jsx(DisplayText, { size: "medium", space: "none", children: heading }))) : null;
    // eslint-disable-next-line no-nested-ternary
    var subHeadingMarkup = subHeading ? (React.isValidElement(subHeading) ? (subHeading) : (_jsx(TextStyle, { variation: "subdued", element: "div", children: subHeading }))) : null;
    var iconMarkup = icon ? (_jsx(Box, { mr: ESpacings.tight, children: _jsx(Icon, { source: icon, color: "primary", size: iconSize }) })) : null;
    return (_jsxs(Flex, { px: space, pt: space, alignItems: "center", children: [iconPosition === "left" && iconMarkup, _jsxs(Box, { flex: 1, mr: ESpacings.tight, children: [headingMarkup, subHeadingMarkup] }), iconPosition === "right" && iconMarkup, actionMarkup] }));
};
