import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { isValidStopDateForTacitRenewal } from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.utils";
import { isResidential } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import {
  addDays,
  addYears,
  differenceInDays,
  isAfter,
  max,
  subDays,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EField,
  ERenewContractDuration,
  IExtraData,
} from "./schema.renewContract.types";

const NOW = new Date();

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { property, contract } = extraData;

  const defaultDuration = getDurationOptionsForContract(property, contract)[0]
    ?.id;

  return {
    [EField.StartDate]: contract.startDate,
    [EField.CurrentEndDate]: contract.stopDate,
    [EField.Duration]: defaultDuration,
    [EField.NewEndDate]: getDefaultEndDateForDuration(
      contract,
      defaultDuration,
    ),
    [EField.TacitRenewal]: contract.tacitRenewal ? "yes" : "no",
  };
};

const differenceInYears = (date1: string | Date, date2: string | Date) =>
  Math.floor(differenceInDays(date1, date2) / 365);

export const getDurationOptionsForContract = (
  property: IProperty,
  contract: IContract,
) => {
  if (!isResidential(property)) {
    return [];
  }

  const { startDate, stopDate } = contract;

  if (!startDate || !stopDate) {
    return [];
  }

  if (
    !isValidStopDateForTacitRenewal({
      startDate,
      stopDate,
    })
  ) {
    return [];
  }

  const result: ERenewContractDuration[] = [];

  const passedContractYears = differenceInYears(
    addDays(stopDate, 1),
    startDate,
  );

  if (passedContractYears >= 9) {
    result.push(ERenewContractDuration.AddThreeYears);
  } else if (passedContractYears <= 3) {
    // New end date can't be longer than 3 years
    if (isAfter(addYears(startDate, 3), addYears(stopDate, 1))) {
      result.push(ERenewContractDuration.AddOneYear);
    }

    // New end date can't be longer than 3 years
    if (isAfter(addYears(startDate, 3), addYears(stopDate, 2))) {
      result.push(ERenewContractDuration.AddTwoYears);
    }

    result.push(ERenewContractDuration.ConvertToNineYears);
  }

  result.push(ERenewContractDuration.Other);

  return result.map(id => ({
    id,
    value: getLocalizedText(`contract.renew.option.${id}`),
  }));
};

export const getEndDate = (formData: any) => {
  const startDate = formData[EField.StartDate];
  const currentEndDate = formData[EField.CurrentEndDate];

  let result = formData[EField.NewEndDate];

  switch (formData[EField.Duration]) {
    case ERenewContractDuration.AddOneYear:
      result = addYears(currentEndDate, 1);
      break;

    case ERenewContractDuration.AddTwoYears:
      result = addYears(currentEndDate, 2);
      break;

    case ERenewContractDuration.AddThreeYears:
      result = addYears(currentEndDate, 3);
      break;

    case ERenewContractDuration.ConvertToNineYears:
      result = subDays(addYears(startDate, 9), 1);
      break;

    default:
      break;
  }

  return max([result, NOW]);
};

const getDefaultEndDateForDuration = (
  contract: IContract,
  duration?: ERenewContractDuration,
) => {
  const { startDate, stopDate = new Date() } = contract;

  switch (duration) {
    case ERenewContractDuration.AddOneYear:
      return addYears(stopDate, 1);

    case ERenewContractDuration.AddTwoYears:
      return addYears(stopDate, 2);

    case ERenewContractDuration.AddThreeYears:
      return addYears(stopDate, 3);

    case ERenewContractDuration.ConvertToNineYears:
      return subDays(addYears(startDate, 9), 1);

    default:
      return addYears(stopDate, 1);
  }
};
