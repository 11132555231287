import { useTheme } from "@rentiohq/web-shared-next/dist/themes";

export const OpenAppCardStyles = () => {
  const theme = useTheme();

  return {
    wrapper: {
      p: 3,
      borderRadius: "8px",
      border: `1px solid ${theme.palette.grey[200]}`,
      width: "100%",
    },
    content: {
      avatar: {
        width: "72px",
        height: "72px",
        background: theme.palette.grey[50],

        svg: {
          color: theme.palette.success.main,
        },
      },
      description: {
        wordBreak: "break-word",
      },
    },
  };
};
