import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as paymentRequestActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentRequestSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import * as paymentRequestActionsV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import * as paymentRequestSelectorsV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { ILegalCase } from "@rentiohq/shared-frontend/dist/types/legalCase.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  ESpacings,
  Icon,
  Loading,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { spacing } from "@rentiohq/web-theme";
import { ContractRows } from "components/ContractRows";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DocumentListItem } from "../../../RentDeposit/components/RentDepositInfo/components/DocumentRow";
import ManuallyAddedPayment from "../ManuallyAddedPayment/ManuallyAddedPayment";
import { PaymentRequestRow } from "../PaymentRequestRow";
import * as S from "./LegalCaseInfo.styles";

interface ILegalCaseDrawerProps {
  legalCase: ILegalCase;
}

const renderInfoAndDocuments = (
  heading: string,
  navigate: any,
  contractId: string,
  damageInfo?: string,
  damageDocumentIds?: string[],
  startDate?: Date,
) => {
  const hasDocuments =
    (damageDocumentIds && damageDocumentIds.length > 0) || false;
  const shouldRender = hasDocuments || damageInfo;
  if (!shouldRender) return null;
  return (
    <Card heading={heading} space={ESpacings.base}>
      {damageInfo && startDate && (
        <S.InfoBox hasDocuments={hasDocuments}>
          {formatDate(startDate)} - {damageInfo}
        </S.InfoBox>
      )}
      {hasDocuments &&
        damageDocumentIds &&
        damageDocumentIds.map(docId => (
          <DocumentListItem key={docId} documentId={docId} />
        ))}
      <div
        style={{ color: "#17A680", cursor: "pointer", paddingTop: "10px" }}
        onClick={() => navigate(`/rentio-legal/edit?contractId=${contractId}`)}
      >
        {getLocalizedText("rentio_legal.case.detail.context.text")}
      </div>
    </Card>
  );
};

const LegalCaseInfo = ({ legalCase }: ILegalCaseDrawerProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { internalModeEnabled } = useInternalMode();

  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const {
    id,
    startDate,
    contractId,
    damageDocumentIds,
    damageInfo,
    extraDocumentIds,
    extraInfo,
    payments,
    manualPayments,
    manualContracts,
    manualPropertyInspectionReports,
    manualRentDeposits,
    manualRentRegistrations,
    documents,
  } = legalCase;

  const PAYMENT_REQUESTS_ID = `payment-requests-legal-case-${id}}`;

  useEffect(() => {
    // TODO: To be removed internalModeEnabled
    if (internalModeEnabled && hasPaymentV2ForBroker) {
      dispatch(
        paymentRequestActionsV2.getInfiniteLoadStart.getAction({
          query: {
            limit: 20,
            filter: {
              id: { in: payments },
            },
          },
          refresh: true,
        }),
      );
    } else {
      dispatch(
        paymentRequestActions.getPaymentRequestsByIdentifier.actions.start({
          paymentRequestsIdentifier: PAYMENT_REQUESTS_ID,
          refetch: true,
          filterData: {
            where: {
              id: { inq: payments },
            },
          },
        }),
      );
    }

    dispatch(
      documentActions.getInfiniteLoadStart.getAction({
        query: {
          limit: 100,
          sort: [{ field: "createdAt", method: "DESC" }],
          filter: {
            legalCaseId: { eq: id },
          },
        },
        refresh: false,
      }),
    );
  }, []);

  const paymentRequests = useSelector((state: IPartialRootState) =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentRequestSelectorsV2.getInfiniteLoad(state, {
          limit: 20,
          filter: { id: { in: payments } },
        })?.items
      : paymentRequestSelectors.getPaymentRequestsByIdentifier(
          state,
          PAYMENT_REQUESTS_ID,
        ),
  );
  const isFetchingPaymentRequests = useSelector((state: IPartialRootState) =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentRequestSelectorsV2.getInfiniteLoad(state, {
          limit: 20,
          filter: { id: { in: payments } },
        })?.isFetching
      : paymentRequestSelectors.isFetchingPaymentRequestsByIdentifier(
          state,
          PAYMENT_REQUESTS_ID,
        ),
  );

  if (!legalCase) {
    return null;
  }

  return (
    <>
      <Card
        heading={getLocalizedText("system.contract")}
        space={ESpacings.base}
      >
        <ContractRows contractIds={[legalCase.contractId]} />
      </Card>

      <Card
        heading={getLocalizedText("rentio_legal.case.detail.payments.header")}
        space={ESpacings.base}
      >
        {isFetchingPaymentRequests && !paymentRequests && <Loading />}

        {paymentRequests?.map(paymentRequestItem => (
          <PaymentRequestRow
            key={paymentRequestItem.id}
            paymentRequest={paymentRequestItem}
          />
        ))}

        {manualPayments?.map((payment, index) => (
          <ManuallyAddedPayment
            manualPayment={payment}
            index={index}
            key={index}
          />
        ))}
        <div
          style={{ color: "#17A680", cursor: "pointer", paddingTop: "10px" }}
          onClick={() =>
            navigate(`/rentio-legal/edit?contractId=${contractId}`)
          }
        >
          {getLocalizedText("rentio_legal.case.detail.payments.text")}
        </div>
      </Card>

      {renderInfoAndDocuments(
        getLocalizedText("rentio_legal.case.detail.damage.header"),
        navigate,
        contractId,
        damageInfo,
        damageDocumentIds,
        startDate,
      )}

      <Card
        heading={getLocalizedText("rentio_legal.case.detail.documents.header")}
        space={ESpacings.base}
      >
        {[
          ...((documents || []).map(d => d.id) || []),
          ...(manualContracts || []),
          ...(manualPropertyInspectionReports || []),
          ...(manualRentDeposits || []),
          ...(manualRentRegistrations || []),
        ].map(documentId => (
          <DocumentListItem key={documentId} documentId={documentId} />
        ))}

        <div
          style={{ color: "#17A680", cursor: "pointer", paddingTop: "10px" }}
          onClick={() =>
            navigate(`/rentio-legal/edit?contractId=${contractId}`)
          }
        >
          {getLocalizedText("rentio_legal.case.detail.documents.add")}
        </div>
      </Card>

      {renderInfoAndDocuments(
        getLocalizedText("rentio_legal.case.detail.context.header"),
        navigate,
        contractId,
        extraInfo,
        extraDocumentIds,
        startDate,
      )}

      <Card
        heading={getLocalizedText(
          "rentio_legal.case.detail.legal_contact.header",
        )}
        space={ESpacings.base}
        appearance="highlighted"
        hasBorder={true}
      >
        <div style={{ display: "flex" }}>
          <div
            style={{
              paddingRight: spacing("tight"),
              paddingLeft: spacing("tight"),
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Icon size="large" source="legalHammer" />
          </div>
          <div>
            <div style={{ marginBottom: spacing("tight") }}>
              <TextStyle style={{ fontSize: 14 }}>
                {getLocalizedText(
                  "rentio_legal.case.detail.legal_contact.name",
                )}
              </TextStyle>
            </div>
            <TextStyle variation="subdued">
              <a
                href={`mailto:${getLocalizedText(
                  "rentio_legal.case.detail.legal_contact.email",
                )}`}
              >
                {getLocalizedText(
                  "rentio_legal.case.detail.legal_contact.email",
                )}
              </a>{" "}
              -{" "}
              {getLocalizedText("rentio_legal.case.detail.legal_contact.phone")}
            </TextStyle>
          </div>
        </div>
      </Card>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default LegalCaseInfo;
