import * as countApi from "@rentiohq/shared-frontend/dist/redux/count/count.api";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import {
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  EPaymentOrderType,
  EPayoutType,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { EPaymentRequestStatus } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { ERentDepositStatus } from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { appendWhere } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { startOfDay } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMembersWithRole } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import {
  Banner,
  Loading,
  ModalConfirmation,
} from "@rentiohq/web-shared/dist/components";
import React from "react";

export interface IConfirmActivationModalProps {
  contract: IContract;
  onSuccess: () => void;
  onClose: () => void;
}

const getLabelForNewRentioLegalContract = (contract: IContract) => {
  const tenants = getMembersWithRole(
    contract.members,
    EContractMemberTypes.Tenant,
  );
  const startDate = formatDate(contract.startDate);
  const endDate = contract.stopDate ? formatDate(contract.stopDate) : undefined;

  return `${tenants
    .map(({ account }) => `${account.firstname} ${account.lastname}`)
    .join(" / ")} - ${startDate} - ${endDate}`;
};

interface ICanActivateLegalForContract {
  isValid: boolean;
  latePaymentsOk: boolean;
  rentDepositsOk: boolean;
  rentPaymentsOk: boolean;
}

const getCanActivateLegalForContract = async (contract: IContract) => {
  const [
    latePaymentsCountResponse,
    rentDepositsCountResponse,
    rentPaymentsCountResponse,
  ] = await Promise.all([
    countApi.getCount(
      appendWhere("/payment-requests/count", {
        status: { neq: EPaymentRequestStatus.Paid },
        requestPropertyId: contract.propertyId,
        payoutType: EPayoutType.Charge,
        dueDateAt: {
          lt: startOfDay(new Date()).getTime(),
        },
        requestPayerAccountId: {
          inq: getMembersWithRole(
            contract.members,
            EContractMemberTypes.Tenant,
          ).map(t => t.account.id),
        },
      }),
    ),
    countApi.getCount(
      appendWhere("/rent-deposits/count", {
        basicContractId: contract.id,
        status: { eq: ERentDepositStatus.Established },
      }),
    ),
    countApi.getCount(
      appendWhere("/payment-orders/count", {
        and: [{ contractId: contract.id }, { type: EPaymentOrderType.Rent }],
      }),
    ),
  ]);

  const latePayments = latePaymentsCountResponse.data.count;
  const rentDeposits = rentDepositsCountResponse.data.count;
  const rentPayments = rentPaymentsCountResponse.data.count;

  const latePaymentsOk = latePayments === 0;
  const rentDepositsOk =
    rentDeposits > 0 || contract.manuallyCompletedRentDeposit !== null;
  const rentPaymentsOk =
    rentPayments > 0 || contract.manuallyCompletedPaymentRent !== null;

  const result: ICanActivateLegalForContract = {
    isValid: latePayments === 0 && rentDepositsOk && rentPaymentsOk,
    latePaymentsOk,
    rentDepositsOk,
    rentPaymentsOk,
  };

  return result;
};

export const ConfirmLegalActivationModal = (
  props: IConfirmActivationModalProps,
) => {
  const { contract, onSuccess, onClose } = props;

  // Redux
  const { activateLegal, isActivating } = contractHooks.useActivateLegal();

  // State
  const [canActivateLegalForContract, setCanActivateLegalForContract] =
    React.useState<ICanActivateLegalForContract>();

  // Data
  const fetchData = async () => {
    setCanActivateLegalForContract(undefined);

    try {
      setCanActivateLegalForContract(
        await getCanActivateLegalForContract(contract),
      );
    } catch (error) {
      showAlert({
        type: "error",
        error,
      });
    }
  };

  // Lifecycle
  React.useEffect(() => {
    fetchData();
  }, [contract.id]);

  // Event handlers
  const onActivate = async () => {
    if (isActivating) {
      return;
    }

    activateLegal({
      id: contract.id,
      onSuccess: () => {
        onSuccess();
      },
    });
  };

  // Render
  return (
    <ModalConfirmation
      heading={`${getLocalizedText(
        "rentio_legal.actions.activate.modal.heading",
      )}: ${getLabelForNewRentioLegalContract(contract)}`}
      isDisabled={!canActivateLegalForContract?.isValid}
      onCancel={onClose}
      onConfirm={onActivate}
      modalProps={{
        width: "medium",
      }}
    >
      {!canActivateLegalForContract && <Loading />}
      {isActivating && <Loading />}

      {canActivateLegalForContract?.isValid && (
        <Banner
          variation="info"
          icon="questionMark"
          title={getLocalizedText(
            "rentio_legal.actions.activate.modal.activate_legal",
          )}
          hasDismiss={false}
        />
      )}

      {!canActivateLegalForContract?.latePaymentsOk && (
        <Banner
          variation="error"
          icon="alertDiamond"
          title={getLocalizedText(
            "rentio_legal.actions.activate.modal.warning.late_payments",
          )}
          hasDismiss={false}
        />
      )}

      {!canActivateLegalForContract?.rentDepositsOk && (
        <Banner
          variation="error"
          icon="alertDiamond"
          title={getLocalizedText(
            "rentio_legal.actions.activate.modal.warning.rent_deposit",
          )}
          hasDismiss={false}
        />
      )}

      {!canActivateLegalForContract?.rentPaymentsOk && (
        <Banner
          variation="error"
          icon="alertDiamond"
          title={getLocalizedText(
            "rentio_legal.actions.activate.modal.warning.rent_payment",
          )}
          hasDismiss={false}
        />
      )}
    </ModalConfirmation>
  );
};
