import {
  InvoiceDetail,
  InvoiceTable,
} from "@rentiohq/web-shared/dist/components";
import { ContactBankAccounts } from "components/ContactBankAccounts/ContactBankAccounts";
import { Navigate, Route } from "react-router";
import { Profile } from "./Profile";
import { ExportFiles } from "./scenes/ExportFiles";
import { ProfileExtra } from "./scenes/ProfileExtra";
import { ProfilePersonal } from "./scenes/ProfilePersonal";
import { ProfileTac } from "./scenes/ProfileTac";

export const getProfileScene = () => (
  <Route path="profile" element={<Profile />}>
    <Route path="personal" element={<ProfilePersonal />} />

    <Route path="financial" element={<ContactBankAccounts />} />

    <Route path="extra" element={<ProfileExtra />} />

    <Route path="tac" element={<ProfileTac />} />

    <Route path="export-files" element={<ExportFiles />} />

    <Route path="invoices/:invoiceId" element={<InvoiceDetail />} />

    <Route path="invoices" element={<InvoiceTable />} />

    <Route index element={<Navigate to="personal" replace={true} />} />
  </Route>
);
