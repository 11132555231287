import { IBroker } from "@rentiohq/shared-frontend/dist/types/broker.types";

export interface IExtraData {
  broker?: IBroker;
  propertyId: number;
}

export enum EField {
  Header = "_header",
  Property = "propertyId",
  Step = "step",
}
