import * as moveRequestActions from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.actions";
import * as moveRequestSelectors from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.selectors";
import {
  EMoveRequestDocumentCategory,
  IMoveRequest,
} from "@rentiohq/shared-frontend/dist/redux/moveRequest/moveRequest.types";
import {
  EElectricityType,
  ENaturalGasAvailable,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { EAN_PREFIX } from "forms/requestMoveService/schema.requestMoveService.config";
import { IRootStore } from "redux/reducers";
import { EField, EStep, IExtraData } from "./schema.moveRequestUpdate.types";

export const isPerformingSubmitActionSelector =
  (moveRequestId: number) => (state: IRootStore) =>
    moveRequestSelectors.isUpdating(state, moveRequestId);

export const performingSubmitActionErrorSelector =
  (moveRequestId: number) => (state: IRootStore) =>
    moveRequestSelectors.updateError(state, moveRequestId);

export const performingSubmitActionResultSelector =
  (moveRequestId: number) => (state: IRootStore) =>
    moveRequestSelectors.moveRequestById(state, moveRequestId);

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { step, moveRequest } = extraData;

    const updatedMoveRequest: Partial<IMoveRequest> = {};
    const moveRequestDocuments: { document: any; categoryId: number }[] = [];
    const updatedProperty: Partial<IProperty> = {};

    switch (step) {
      case EStep.LeavingContact: {
        updatedMoveRequest.leavingContactFirstname =
          formData[EField.LeavingContactFirstname] || null;
        updatedMoveRequest.leavingContactLastname =
          formData[EField.LeavingContactLastname] || null;
        updatedMoveRequest.leavingContactEmail =
          formData[EField.LeavingContactEmail] || null;

        break;
      }

      case EStep.EnergyNote: {
        updatedMoveRequest.energyInvoiceNote =
          formData[EField.NoteEnergy] || null;
        break;
      }

      case EStep.TelcoNote: {
        updatedMoveRequest.telcoInvoiceNote =
          formData[EField.NoteTelco] || null;
        break;
      }

      case EStep.ElectricityGeneral: {
        const electricityType = formData[
          EField.ElectricityType
        ] as EElectricityType;
        const electricityEan = formData[EField.ElectricityEan];
        const electricityMeterNumberBoth =
          formData[EField.ElectricityMeterNumberBoth];

        let parsedElectricityEan = null;
        if (electricityEan) {
          parsedElectricityEan = `${EAN_PREFIX}${electricityEan}`;
        }

        // Update move request
        updatedMoveRequest.electricityType = electricityType;
        // @ts-ignore
        updatedMoveRequest.electricityEan = null;
        // @ts-ignore
        updatedMoveRequest.electricityMeterNumberBoth = null;
        if (electricityType !== EElectricityType.Unknown) {
          // @ts-ignore
          updatedMoveRequest.electricityEan = parsedElectricityEan;
          updatedMoveRequest.electricityMeterNumberBoth =
            electricityMeterNumberBoth;
        }

        // Update property
        updatedProperty.electricityType = electricityType;
        // @ts-ignore
        updatedProperty.electricityEan = null;
        // @ts-ignore
        updatedProperty.electricityMeterNumberBoth = null;
        if (electricityType !== EElectricityType.Unknown) {
          // @ts-ignore
          updatedProperty.electricityEan = parsedElectricityEan;
          updatedProperty.electricityMeterNumberBoth =
            electricityMeterNumberBoth;
        }

        break;
      }

      case EStep.ElectricityHandover: {
        const electricityMeterReadingBoth =
          formData[EField.ElectricityMeterReadingBoth];
        const electricityMeterReadingBothDate =
          formData[EField.ElectricityMeterReadingBothDate];
        const electricityMeterReadingDay =
          formData[EField.ElectricityMeterReadingDay];
        const electricityMeterReadingDayDate =
          formData[EField.ElectricityMeterReadingDayDate];
        const electricityMeterReadingNight =
          formData[EField.ElectricityMeterReadingNight];
        const electricityMeterReadingNightDate =
          formData[EField.ElectricityMeterReadingNightDate];
        const handoverDocuments = formData[EField.HandoverDocuments] || [];
        const electricityMeterType = formData[EField.ElectricityMeterType];

        // Update move request
        if (moveRequest.electricityType === EElectricityType.Single) {
          updatedMoveRequest.electricityMeterReadingBoth =
            electricityMeterReadingBoth;
          updatedMoveRequest.electricityMeterReadingBothDate =
            electricityMeterReadingBothDate;
        } else if (moveRequest.electricityType === EElectricityType.Double) {
          updatedMoveRequest.electricityMeterReadingDay =
            electricityMeterReadingDay;
          updatedMoveRequest.electricityMeterReadingDayDate =
            electricityMeterReadingDayDate;
          updatedMoveRequest.electricityMeterReadingNight =
            electricityMeterReadingNight;
          updatedMoveRequest.electricityMeterReadingNightDate =
            electricityMeterReadingNightDate;
        }

        moveRequestDocuments.push(
          ...handoverDocuments.map((x: any) => ({
            document: x,
            categoryId: EMoveRequestDocumentCategory.ElectricityGeneral,
          })),
        );

        updatedMoveRequest.electricityMeterType = electricityMeterType;

        // Update property
        updatedProperty.electricityMeterType = electricityMeterType;

        break;
      }

      case EStep.ElectricityHandoverDocuments: {
        const handoverDocuments = formData[EField.HandoverDocuments] || [];

        moveRequestDocuments.push(
          ...handoverDocuments.map((x: any) => ({
            document: x,
            categoryId: EMoveRequestDocumentCategory.ElectricityGeneral,
          })),
        );

        break;
      }

      case EStep.NaturalGasGeneral: {
        const naturalGasAvailable = formData[
          EField.NaturalGasAvailable
        ] as ENaturalGasAvailable;
        const naturalGasEan = formData[EField.NaturalGasEan];
        const naturalGasMeterNumber = formData[EField.NaturalGasMeterNumber];

        let parsedNaturalGasEan = null;
        if (naturalGasEan) {
          parsedNaturalGasEan = `${EAN_PREFIX}${
            formData[EField.NaturalGasEan]
          }`;
        }

        // Update move request
        updatedMoveRequest.naturalGasAvailable = naturalGasAvailable;
        // @ts-ignore
        updatedMoveRequest.naturalGasEan = null;
        // @ts-ignore
        updatedMoveRequest.naturalGasMeterNumber = null;
        if (naturalGasAvailable === ENaturalGasAvailable.Available) {
          // @ts-ignore
          updatedMoveRequest.naturalGasEan = parsedNaturalGasEan;
          updatedMoveRequest.naturalGasMeterNumber = naturalGasMeterNumber;
        }

        // Update property
        updatedProperty.naturalGasAvailable = naturalGasAvailable;
        // @ts-ignore
        updatedProperty.naturalGasEan = null;
        // @ts-ignore
        updatedProperty.naturalGasMeterNumber = null;
        if (naturalGasAvailable === ENaturalGasAvailable.Available) {
          // @ts-ignore
          updatedProperty.naturalGasEan = parsedNaturalGasEan;
          updatedProperty.naturalGasMeterNumber = naturalGasMeterNumber;
        }

        break;
      }

      case EStep.NaturalGasHandover: {
        const naturalGasMeterReading = formData[EField.NaturalGasMeterReading];
        const naturalGasMeterReadingDate =
          formData[EField.NaturalGasMeterReadingDate];
        const handoverDocuments = formData[EField.HandoverDocuments] || [];
        const naturalGasMeterType = formData[EField.NaturalGasMeterType];

        // Update move request
        updatedMoveRequest.naturalGasMeterReading = naturalGasMeterReading;
        updatedMoveRequest.naturalGasMeterReadingDate =
          naturalGasMeterReadingDate;

        moveRequestDocuments.push(
          ...handoverDocuments.map((x: any) => ({
            document: x,
            categoryId: EMoveRequestDocumentCategory.NaturalGasGeneral,
          })),
        );

        updatedMoveRequest.naturalGasMeterType = naturalGasMeterType;

        // Update property
        updatedProperty.naturalGasMeterType = naturalGasMeterType;

        break;
      }

      case EStep.NaturalGasHandoverDocuments: {
        const handoverDocuments = formData[EField.HandoverDocuments] || [];

        moveRequestDocuments.push(
          ...handoverDocuments.map((x: any) => ({
            document: x,
            categoryId: EMoveRequestDocumentCategory.NaturalGasGeneral,
          })),
        );

        break;
      }

      case EStep.WaterGeneral: {
        const waterMeterNumber = formData[EField.WaterMeterNumber];

        // Update move request
        updatedMoveRequest.waterMeterNumber = waterMeterNumber;

        // Update property
        updatedProperty.waterMeterNumber = waterMeterNumber;

        break;
      }

      case EStep.WaterHandover: {
        const waterMeterReading = formData[EField.WaterMeterReading];
        const waterMeterReadingDate = formData[EField.WaterMeterReadingDate];
        const handoverDocuments = formData[EField.HandoverDocuments] || [];

        // Update move request
        updatedMoveRequest.waterMeterReading = waterMeterReading;
        updatedMoveRequest.waterMeterReadingDate = waterMeterReadingDate;

        moveRequestDocuments.push(
          ...handoverDocuments.map((x: any) => ({
            document: x,
            categoryId: EMoveRequestDocumentCategory.WaterGeneral,
          })),
        );

        break;
      }

      case EStep.WaterHandoverDocuments: {
        const handoverDocuments = formData[EField.HandoverDocuments] || [];

        moveRequestDocuments.push(
          ...handoverDocuments.map((x: any) => ({
            document: x,
            categoryId: EMoveRequestDocumentCategory.WaterGeneral,
          })),
        );

        break;
      }

      default:
        break;
    }

    return moveRequestActions.updateMoveRequest.actions.start({
      id: moveRequest.id,
      publicId: moveRequest.publicId,
      moveRequest: updatedMoveRequest,
      propertyId: moveRequest.propertyId,
      property: updatedProperty,
      documents: moveRequestDocuments,
    });
  };
