import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import { EContractMemberTypes } from "@rentiohq/shared-frontend/dist/types/contract.types";
import utils from "@rentiohq/web-shared/dist/utils";
import { JSONSchema6 } from "json-schema";
import { EField, IExtraData } from "./schema.createDocumentPackage.types";
import {
  getNavigationTitle,
  getRelatedContract,
} from "./schema.createDocumentPackage.utils";
import {
  customContractsSelector,
  customGetContractsActions,
  customIsFetchingSelector,
} from "./schema.createDocumentPackage.utils.fetchContracts";

export const getSchema =
  (initialValues: { [key: string]: unknown }, extraData: any) =>
  (defaultValues: { [key: string]: unknown }): JSONSchema6 => {
    return {
      required: [EField.Contract],
      properties: {
        [EField.Header + "5"]: {
          default: undefined,
        },
        [EField.Contract]: {
          default: getValue(EField.Contract, initialValues, defaultValues),
          // @ts-ignore
          showIf: (_: any) => !extraData.sendToTenant,
        },
      },
    };
  };

export const uiSchema = (extraData: IExtraData): IUiSchema<EField> => {
  return {
    "rentio:title": utils.i18n.t("document_package.form.contract.step_heading"),
    "rentio:navigationTitle": getNavigationTitle(),
    [EField.Header + "5"]: {
      "ui:field": ERentioFormField.SectionHeader,
      title: utils.i18n.t("document_package.form.contract.heading"),
    },
    [EField.Contract]: {
      "ui:field": ERentioFormField.InlineSelect,
      valueSelector: (_: IPartialRootState, value: string, formData: any) => {
        const relatedContract = getRelatedContract(extraData, formData);

        if (!relatedContract) {
          return "";
        }

        const contractMembers = relatedContract
          ? relatedContract.members
              .filter(
                member =>
                  member.roles.includes(EContractMemberTypes.Tenant) ||
                  member.roles.includes(EContractMemberTypes.Parent),
              )
              .map(
                member =>
                  `${member.account.firstname} ${member.account.lastname}`,
              )
              .join(", ")
          : "";
        return [
          utils.i18n.t(`contract.type.${relatedContract.contractType}`),
          contractMembers,
        ].join(" | ");
      },
      customExtraOptionsSelector: (
        state: IPartialRootState,
        formData: any,
      ): any =>
        customContractsSelector({
          state,
          propertyId: formData[EField.Property],
        }),
      customIsFetchingSelector: (state: IPartialRootState, formData: any) =>
        customIsFetchingSelector(state, extraData, formData),
      customGetOptionsActions: customGetContractsActions(extraData),
      emptyMessage: (_formData: any) =>
        utils.i18n.t("document_package.form.contract.select.empty"),
      title: utils.i18n.t("document_package.form.contract.select.heading"),
    },
  };
};
