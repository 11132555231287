import { ETemplateAccessScope, ETemplateTag, ETemplateType, } from "@rentiohq/shared-frontend/dist/redux/template/template.types";
import { EField } from "./schema.template.types";
export var getInitialValues = function (extraData) {
    var _a, _b;
    var template = extraData.template;
    if (template) {
        return _a = {},
            _a[EField.AccessScope] = template.accessScope,
            _a[EField.Name] = template.name,
            _a[EField.Type] = template.type,
            _a[EField.TenantCanEdit] = template.tenantCanEdit ? "yes" : "no",
            _a[EField.Tags] = template.tags,
            _a[EField.Country] = template.country,
            _a[EField.Region] = template.region,
            _a[EField.AvailableFrom] = template.availableFrom,
            _a[EField.AvailableTo] = template.availableTo,
            _a;
    }
    return _b = {},
        _b[EField.AccessScope] = ETemplateAccessScope.Private,
        _b[EField.Name] = undefined,
        _b[EField.Type] = Object.values(ETemplateType)[0],
        _b[EField.TenantCanEdit] = "no",
        _b[EField.Tags] = [ETemplateTag.Broker],
        _b[EField.Country] = "BE",
        _b[EField.Region] = undefined,
        _b[EField.AvailableFrom] = undefined,
        _b[EField.AvailableTo] = undefined,
        _b;
};
