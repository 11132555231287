import { getSchema as getSchema1, uiSchema as uiSchema1, } from "./schema.createRentDeposit.1";
import { getSchema as getSchema2, getValidate as getValidate4, uiSchema as uiSchema2, } from "./schema.createRentDeposit.2";
import { getSchema as getSchema5, uiSchema as uiSchema5, } from "./schema.createRentDeposit.5";
import { getSchema as getSchema6, uiSchema as uiSchema6, } from "./schema.createRentDeposit.6";
import { getSchema as getSchema7, getValidate as getValidate7, uiSchema as uiSchema7, } from "./schema.createRentDeposit.7";
import { EField } from "./schema.createRentDeposit.types";
import { getForeigners, getInitialValues, getTenants, showInsurance, } from "./schema.createRentDeposit.utils";
// eslint-disable-next-line import/no-default-export
export default (function (extraData) {
    var initialValues = getInitialValues(extraData);
    return [
        {
            schema: getSchema1(initialValues, extraData),
            uiSchema: uiSchema1(extraData),
        },
        {
            schema: getSchema2(initialValues, extraData),
            uiSchema: uiSchema2(extraData),
            validate: getValidate4(extraData),
        },
        {
            schema: getSchema5(initialValues, extraData),
            uiSchema: uiSchema5(extraData),
            showIf: function (formData) {
                return (getForeigners(formData[EField.Signers]) || []).length > 0;
            },
        },
        {
            schema: getSchema6(initialValues, extraData),
            uiSchema: uiSchema6(extraData),
            showIf: function (formData) { return getTenants(formData, extraData).length > 1; },
        },
        {
            schema: getSchema7(initialValues, extraData),
            uiSchema: uiSchema7(extraData),
            validate: getValidate7(extraData),
            showIf: function () { return showInsurance(extraData); },
        },
    ];
});
