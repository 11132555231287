import { IRentDeposit } from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";
import {
  SimpleCard,
  Stack,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { SignerList } from "tenant-dashboard/components/SignerList";
import { ts } from "../../../../../../../services";
import { getValidDocumentPackage } from "../../../../../../../utils/contract";
import { DocumentList } from "../../../../../DocumentList";
import { ERentDepositTabs } from "../../RentDeposit";
import { RentDepositHistory } from "../RentDepositHistory";

interface IProps {
  rentDeposit: IRentDeposit;
  setActiveTab: (tab: ERentDepositTabs) => void;
}

export const RentDepositInfo = ({ rentDeposit, setActiveTab }: IProps) => {
  const { rentDepositContractOut, rentDepositContractIn } = rentDeposit;

  const documentPackages = [
    ...(rentDepositContractIn?.documentPackages || []),
    ...(rentDepositContractOut?.documentPackages || []),
  ];
  const currentDocumentPackage = getValidDocumentPackage(
    rentDepositContractOut || rentDepositContractIn,
  );

  const getDocumentsInPackageIds = () => {
    let documentsInPackageIds: string[] = [];
    documentPackages.forEach(documentPackage => {
      documentsInPackageIds.push(...documentPackage?.documentsInPackageIds);
    });

    return documentsInPackageIds;
  };

  return (
    <Stack direction="column" gap={1}>
      {documentPackages.length > 0 && (
        <SimpleCard title={ts.system("documents")}>
          <DocumentList documentsInPackageIds={getDocumentsInPackageIds()} />
        </SimpleCard>
      )}
      {currentDocumentPackage && (
        <SimpleCard title={ts.system("signers")}>
          <SignerList documentPackage={currentDocumentPackage} />
        </SimpleCard>
      )}
      {rentDeposit && (
        <RentDepositHistory
          rentDeposit={rentDeposit}
          loadMore={setActiveTab}
          limit={5}
        />
      )}
    </Stack>
  );
};
