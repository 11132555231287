import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import ListItemSeparator from "@rentiohq/shared-frontend/dist/components/components/ListItemSeparator";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import orderBy from "lodash/orderBy";
import React from "react";
import { ChromePicker } from "react-color";
import { DisplayText, Icon, Modal, TextStyle, } from "../../../../../../components";
import { Label } from "../../../../../../components/Labelled/Labelled.styled";
import { getCompletionInfo, getVariablesForGroup } from "../../Editor.utils";
import { DisplayTypeContext } from "../../contexts/DisplayType.context";
import { DocumentConfigContext } from "../../contexts/DocumentConfig.context";
import { FieldsContext } from "../../contexts/Fields.context";
import { GroupSidebarContext } from "../../contexts/GroupSidebar.context";
import { VariablesDataContext } from "../../contexts/VariablesData.context";
import VariablesFormGroup from "../VariablesFormGroup";
import VariablesFormVariable from "../VariablesFormVariable";
import * as S from "./VariablesForm.styles";
export var VariablesForm = function (props) {
    // Context
    var _a = React.useContext(DisplayTypeContext), isTemplate = _a.isTemplate, isTemplateDocument = _a.isTemplateDocument, isTemplateDocumentEditor = _a.isTemplateDocumentEditor, isTemplateDocumentExternal = _a.isTemplateDocumentExternal;
    var _b = React.useContext(DocumentConfigContext), documentConfig = _b.documentConfig, setDocumentConfig = _b.setDocumentConfig;
    var variablesData = React.useContext(VariablesDataContext).variablesData;
    var fields = React.useContext(FieldsContext).fields;
    var _c = React.useContext(GroupSidebarContext), openGroups = _c.openGroups, setOpenGroups = _c.setOpenGroups, toggleOpenGroup = _c.toggleOpenGroup;
    var groups = orderBy(fields.groups, [function (x) { return x.sortWeight || 0; }, "name"], ["desc", "asc"]).filter(function (x) {
        if (isTemplateDocument) {
            return (getVariablesForGroup({
                groupId: x.id,
                fields: fields,
                variablesData: variablesData,
                skipCondition: false,
                instanceIndex: 0,
            }).length > 0);
        }
        if (isTemplateDocumentExternal) {
            return (fields.variables.filter(function (variable) { return variable.editableByTenant && variable.groupId === x.id; }).length > 0);
        }
        return true;
    });
    // State
    var _d = React.useState(false), showDocumentConfig = _d[0], setShowDocumentConfig = _d[1];
    var _e = React.useState(), brandColorModalValue = _e[0], setBrandColorModalValue = _e[1];
    // Lifecycle
    React.useEffect(function () {
        var defaultOpenGroups = groups[0] ? [groups[0].id] : [];
        if (isTemplate) {
            defaultOpenGroups = groups.map(function (x) { return x.id; });
        }
        setOpenGroups(defaultOpenGroups);
    }, []);
    React.useEffect(function () {
        if (!isTemplate) {
            return;
        }
        setOpenGroups(groups.map(function (x) { return x.id; }));
    }, [isTemplate]);
    // Helpers
    var completionInfo = React.useMemo(function () { return getCompletionInfo({ fields: fields, variablesData: variablesData }); }, [fields, variablesData]);
    var getPreviousGroup = function (group) {
        var indexInList = groups.findIndex(function (x) { return x.id === group.id; });
        if (indexInList === -1) {
            return;
        }
        var previousIndexInList = indexInList - 1;
        var result = groups[previousIndexInList];
        if (!result) {
            return;
        }
        return result;
    };
    var getNextGroup = function (group) {
        var indexInList = groups.findIndex(function (x) { return x.id === group.id; });
        if (indexInList === -1) {
            return;
        }
        var previousIndexInList = indexInList + 1;
        var result = groups[previousIndexInList];
        if (!result) {
            return;
        }
        return result;
    };
    // Render
    var renderDocumentConfig = function () {
        if (!(isTemplateDocument || isTemplateDocumentEditor)) {
            return null;
        }
        return (_jsxs(_Fragment, { children: [_jsx(ListItemSeparator, {}), _jsxs(S.DocumentConfigWrap, { children: [_jsx(S.DocumentConfigTitleWrap, { onClick: function () {
                                setShowDocumentConfig(!showDocumentConfig);
                            }, children: _jsxs(S.DocumentConfigIconWrap, { children: [_jsx(Icon, { source: showDocumentConfig ? "chevronDown" : "chevronRight", size: "mediumLarge", color: "gray" }), _jsx(DisplayText, { space: "none", size: "small", children: getLocalizedText("editor.template_document.document_config.title") })] }) }), showDocumentConfig && (_jsxs(S.BrandColorWrap, { children: [_jsx(S.ColorCircle, { brandColor: documentConfig.brandColor }), _jsxs("div", { children: [_jsx(Label, { children: getLocalizedText("editor.template_document.document_config.brand_color.title") }), _jsx(Spacer, { weight: ESpacerWeight.W16 }), _jsx(ButtonBasic, { title: getLocalizedText("editor.template_document.document_config.brand_color.cta.change"), onPress: function () {
                                                setBrandColorModalValue(documentConfig.brandColor || "darkgray");
                                            } })] }), brandColorModalValue && (_jsx(Modal, { onClose: function () {
                                        setBrandColorModalValue(undefined);
                                    }, heading: getLocalizedText("editor.template_document.document_config.brand_color.title"), shouldCloseOnOverlayClick: true, hasDismiss: true, width: "medium", actions: [
                                        {
                                            content: getLocalizedText("system.ok"),
                                            onClick: function () {
                                                setDocumentConfig({
                                                    brandColor: brandColorModalValue,
                                                });
                                                setBrandColorModalValue(undefined);
                                            },
                                            appearance: "primary",
                                        },
                                    ], children: _jsx(S.ChromePickerWrap, { children: _jsx(ChromePicker, { color: brandColorModalValue, onChangeComplete: function (color) {
                                                setBrandColorModalValue(color.hex);
                                            } }) }) }))] }))] })] }));
    };
    var variablesWithoutGroup = [];
    if (isTemplate && fields.variables) {
        variablesWithoutGroup = fields.variables.filter(function (x) {
            return !fields.groups.find(function (y) {
                return y.id === x.groupId;
            });
        });
        if (isTemplateDocumentExternal) {
            variablesWithoutGroup = variablesWithoutGroup.filter(function (x) { return !x.editableByTenant; });
        }
    }
    return (_jsxs(S.Wrap, { children: [(isTemplateDocument || isTemplateDocumentEditor) && (_jsx(S.ProgressBarWrap, { children: _jsx(S.ProgressBarCompleted, { amount: completionInfo.completed / completionInfo.total }) })), renderDocumentConfig(), isTemplate && variablesWithoutGroup.length > 0 && (_jsxs(_Fragment, { children: [_jsxs("div", { style: {
                            padding: "24px 24px 12px 40px",
                        }, children: [_jsx(DisplayText, { space: "none", size: "small", children: "Variabelen zonder groep" }), _jsx(TextStyle, { variation: ["subdued", "negative"], children: "Te verwijderen" })] }), variablesWithoutGroup.map(function (variable) {
                        return (_jsx(VariablesFormVariable, { variable: variable, instanceIndexGroup: 0, groupInstancesToRender: 0 }, variable.id));
                    })] })), groups.map(function (group) {
                var previousGroup = getPreviousGroup(group);
                var nextGroup = getNextGroup(group);
                return (_jsxs(_Fragment, { children: [_jsx(ListItemSeparator, {}), _jsx(VariablesFormGroup, { group: group, isOpen: openGroups.includes(group.id), onToggleOpenGroup: function (group) {
                                toggleOpenGroup(group.id);
                            }, onPressPreviousGroup: previousGroup
                                ? function () { return toggleOpenGroup(previousGroup.id); }
                                : undefined, onPressNextGroup: nextGroup
                                ? function () {
                                    toggleOpenGroup(nextGroup.id, true);
                                }
                                : undefined }, group.id)] }));
            })] }));
};
// eslint-disable-next-line import/no-default-export
export default VariablesForm;
