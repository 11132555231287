var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ELocale } from "../../types/i18n.types";
import { getLocalizedText } from "../i18n/i18n.utils";
import { ALL_COUNTRIES, PRIMARY_COUNTRIES } from "./countries";
var mapCountry = function (id) { return ({
    value: getLocalizedText("system.country.".concat(id.toLowerCase())),
    id: id,
}); };
var mapNationality = function (id) { return ({
    value: getLocalizedText("system.nationality.".concat(id.toLowerCase())),
    id: id,
}); };
var mapLanguage = function (id) { return ({
    value: getLocalizedText("system.locale.".concat(id)),
    id: id,
}); };
var sortDataSource = function (a, b) {
    var labelA = a.value.toLowerCase();
    var labelB = b.value.toLowerCase();
    if (labelA < labelB)
        return -1;
    if (labelA > labelB)
        return 1;
    return 0;
};
export var getMappedCountries = function () {
    var primaryCountries = PRIMARY_COUNTRIES.map(mapCountry);
    var allCountries = ALL_COUNTRIES.map(mapCountry).sort(sortDataSource);
    return __spreadArray(__spreadArray([], primaryCountries, true), allCountries, true);
};
export var getMappedNationalities = function () {
    var primaryCountries = PRIMARY_COUNTRIES.map(mapNationality);
    var allCountries = ALL_COUNTRIES.map(mapNationality).sort(sortDataSource);
    return __spreadArray(__spreadArray([], primaryCountries, true), allCountries, true);
};
export var getMappedLanguages = function () {
    return [ELocale.NL, ELocale.FR, ELocale.EN].map(mapLanguage);
};
