import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { FieldsContext } from "../../../contexts/Fields.context";
import * as S from "./AtomicBlockSignature.styles";
var AtomicBlockSignature = function (props) {
    var contentState = props.contentState, blockProps = props.blockProps;
    var entityKey = blockProps.entityKey;
    var entity = contentState.getEntity(entityKey);
    var entityData = entity.getData();
    // Context
    var fields = React.useContext(FieldsContext).fields;
    var variable = fields.variables.find(function (x) { return x.id === entityData.variableId; });
    if (!variable) {
        return null;
    }
    var group = fields.groups.find(function (x) { return x.id === variable.groupId; });
    if (!group) {
        return null;
    }
    // Render
    if (!variable || !group) {
        return _jsx(_Fragment, { children: "Error loading variable" });
    }
    return (_jsx(S.Wrap, { children: _jsx(S.InnerWrap, { children: _jsx(S.Placeholder, { children: "".concat(getLocalizedText(variable.name), " (").concat(getLocalizedText(group.name), ")") }) }) }));
};
// eslint-disable-next-line import/no-default-export
export default AtomicBlockSignature;
