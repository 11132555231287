import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import {
  EExportActionType,
  EExportFileType,
  EExportType,
} from "@rentiohq/shared-frontend/dist/reduxV2/exportFile";
import * as exportFilesActions from "@rentiohq/shared-frontend/dist/reduxV2/exportFile/exportFile.actions";
import * as paymentRequestActions from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import * as paymentRequestSelectors from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import * as paymentRequestUtils from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.utils";
import * as beneficiaryReportHooks from "@rentiohq/shared-frontend/dist/reduxV2/reportBeneficiary/report.beneficiary.hooks";
import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";
import {
  EPaymentOrderOwnerPaymentMethod,
  EPaymentOrderType,
  EPayoutType,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { IBeneficiaryReportPayout } from "@rentiohq/shared-frontend/dist/types/report.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  ActionButton,
  Card,
  DataTable,
  ELoadingSize,
  EmptyState,
  Loading,
  mapItemsToDataTableProps,
  Pagination,
} from "@rentiohq/web-shared/dist/components";
import { useMultiselect } from "@rentiohq/web-shared/dist/hooks/useMultiselect";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import usePreference from "scenes/Settings/hooks/usePreference";
import { NumberParam, StringParam } from "serialize-query-params";
import BeneficiaryReportFilter from "./BeneficiaryReport.filter";
import { getColumns } from "./BeneficiaryReport.util";

const getPayoutIdentifier = (payout: IBeneficiaryReportPayout) => {
  return paymentRequestUtils.getInfiniteLoadKey({
    query: {
      filter: {
        payoutType: { eq: EPayoutType.Payout },
        parent: { eq: payout.parent },
      },
      limit: 20,
    },
  });
};

const FETCH_LIMIT = 20;
const SELECTION_LIMIT = 500;

export const BeneficiaryReport = () => {
  const [, setQueryParamValue] = useQueryParams({
    paymentRequestId: NumberParam,
    documentId: StringParam,
  });
  const dispatch = useDispatch();

  const { user } = authHooks.useSelf();

  const [page, setPage] = useState<number>(1);
  const {
    selectedIds,
    onSelectIds,
    onDeselectIds,
    onSelectId,
    onDeselectId,
    onDeselectAll,
  } = useMultiselect<number>([], SELECTION_LIMIT);
  const [searchQuery = "", setSearchQuery] = usePreference({
    preferenceKey: "search_query",
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });
  const [queryDebounced] = useDebounce(searchQuery);

  const [payoutToGetDocsFor, setPayoutToGetDocsFor] = useState<number>();
  const [didInitializeFilter, setDidInitializeFilter] = useState(false);
  // TODO: might be better to store this in redux -> this is only a temp solution
  const allPayoutsRef = useRef<IBeneficiaryReportPayout[]>([]);
  const [filter, setFilter] = useState<any>();

  useEffect(() => {
    onDeselectAll();
    setPage(1);
  }, [queryDebounced, filter]);

  const {
    items: payouts,
    isFetching,
    totalPages = 0,
    refetch,
  } = beneficiaryReportHooks.usePaged(
    didInitializeFilter
      ? {
          query: {
            page,
            limit: FETCH_LIMIT,
            sort: [
              { field: "requestPayeeAccountId", method: "ASC" },
              { field: "payoutDate", method: "DESC" },
            ],
            search: queryDebounced,
            ...filter,
          },
        }
      : {},
  );

  // Keep all payouts in memory to find linked payouts for all later
  useEffect(() => {
    if (payouts) {
      let newPayouts = payouts;
      newPayouts = payouts.filter(
        payout => !allPayoutsRef.current!.find(p => p.id === payout.id),
      );
      allPayoutsRef.current = [...newPayouts, ...(allPayoutsRef.current || [])];
    }
  }, [payouts]);

  const { items: documentsForPaymentRequest, isFetching: isFetchingDocuments } =
    documentHooks.useGetAll({
      customPath: payoutToGetDocsFor
        ? `/payment-requests/${payoutToGetDocsFor}/documents`
        : undefined,
      query: payoutToGetDocsFor ? {} : undefined,
      shouldRefetch: true,
    });

  const items = useMemo(getColumns, [user]);
  const tableSetup = useMemo(
    () => mapItemsToDataTableProps(items, { hasActions: true }),
    [items],
  );

  const linkedPayouts = useSelector((state: IRootStore) => {
    return paymentRequestSelectors.getPaymentRequestsByIdentifiers(
      state,
      Array.from(new Set(allPayoutsRef.current.map(getPayoutIdentifier))),
    );
  });

  const parentIdsAndIdentifiers = useMemo(
    () =>
      payouts
        ? payouts
            .filter(
              p =>
                p.parent !== null &&
                p.paymentRequestItems?.[0].type === EPaymentOrderType.Rent,
            )
            .map(p => ({
              parent: p.parent,
              identifier: getPayoutIdentifier(p),
            }))
        : [],
    [payouts],
  );

  const paymentRequestsFetching = useSelector((state: IRootStore) =>
    paymentRequestSelectors.getIsFetchingOrHasErrorPaymentRequestsByIdentifiers(
      state,
      parentIdsAndIdentifiers.map(p => p.identifier),
    ),
  );

  useEffect(() => {
    if (!payouts) {
      return;
    }

    parentIdsAndIdentifiers.forEach(
      ({ parent: payoutParentId, identifier }) => {
        if (payoutParentId) {
          const linkedPayoutState = linkedPayouts[identifier];
          if (
            !linkedPayoutState?.paymentRequestIds &&
            !linkedPayoutState?.isFetching
          ) {
            dispatch(
              paymentRequestActions.getInfiniteLoadStart.getAction({
                query: {
                  filter: {
                    payoutType: { eq: EPayoutType.Payout },
                    parent: { eq: payoutParentId },
                  },
                  limit: 20,
                },
                refresh: false,
              }),
            );
          }
        }
      },
    );
  }, [payouts, linkedPayouts, parentIdsAndIdentifiers]);

  const createReport = useCallback(
    (selectedPayoutIds: number[], action: EExportActionType) => {
      if (selectedPayoutIds?.length === 0) return;
      if (!allPayoutsRef.current) return;

      const linkedIds = selectedPayoutIds.reduce((allPayoutIds, selectedId) => {
        const payouts = allPayoutsRef.current.filter(p => p.id === selectedId);
        if (!payouts || payouts.length === 0) {
          return allPayoutIds;
        }
        const allIds = payouts
          .map(payout => {
            if (payout.parent) {
              if (!linkedPayouts) {
                return [];
              }
              return linkedPayouts[getPayoutIdentifier(payout)]?.paymentRequests
                ?.filter(
                  lp =>
                    lp.paymentRequestItems?.[0]?.paymentOrder
                      .ownerPaymentMethod ===
                    EPaymentOrderOwnerPaymentMethod.FromRent,
                )
                .map(lp => lp.id);
            }
            return [];
          })
          .flat();

        return [...allPayoutIds, ...(allIds ?? [])];
      }, [] as number[]);

      dispatch(
        exportFilesActions.createStart.getAction({
          data: {
            exportFileType: EExportFileType.Pdf,
            exportType: EExportType.BeneficiaryReport,
            exportParameters: {
              exportBeneficiaryReportParameters: {
                paymentRequestIds: Array.from(
                  new Set([...linkedIds, ...selectedPayoutIds]),
                ),
                action,
              },
            },
          },
          onSuccess: () => {
            onDeselectAll();
            refetch();
          },
        }),
      );
    },
    [user, dispatch, refetch, linkedPayouts],
  );

  const rows = useMemo(() => {
    if (!payouts) {
      return [];
    }

    return payouts.map((payout: IBeneficiaryReportPayout) => {
      const isMultiSelectDisabled = payout.requestPayeeAccount
        ? !payout.requestPayeeAccount.reportingEnabled
        : false;

      let actions = [];
      if (!isMultiSelectDisabled) {
        actions.push({
          content: getLocalizedText("reports.beneficiary.create_report"),
          onClick: () => createReport([payout.id], EExportActionType.Create),
        });
        actions.push({
          content: getLocalizedText("reports.beneficiary.create_and_send"),
          onClick: () =>
            createReport([payout.id], EExportActionType.CreateAndEmail),
        });
      }

      const docs = documentsForPaymentRequest ?? [];

      actions = [
        ...actions,
        ...(isFetchingDocuments
          ? [
              {
                content: <Loading asDots={true} size={ELoadingSize.Medium} />,
              },
            ]
          : (docs || [])
              // TODO: @Arno check types of docs
              // @ts-ignore
              .sort((a: IDocument, b: IDocument) => {
                if (a.createdAt === b.createdAt) return 0;
                return a.createdAt > b.createdAt ? 1 : -1;
              })
              .map((doc: IDocument) => ({
                content: <p>{doc.filename}</p>,
                onClick: () => {
                  setQueryParamValue({
                    documentId: doc.id,
                  });
                },
              }))),
      ];

      return {
        id: payout.id,
        multiSelectTooltip: getLocalizedText(
          "reports.beneficiary.reporting_disabled",
          {
            beneficiaryName: payout.requestPayeeAccount
              ? String(payout.requestPayeeAccount.fullName)
              : "",
          },
        ),
        multiSelectDisabled:
          isMultiSelectDisabled ||
          Object.values(paymentRequestsFetching).some(v => v),
        content: items
          .map(item => item.renderContent(payout))
          .filter(c => c !== null),
        actions,
        onDropdownOpen: () => {
          //Forces the call to happen every time the dropdown is opened.
          setPayoutToGetDocsFor(undefined);
          setPayoutToGetDocsFor(payout.id);
        },
        disabled: payout.parent
          ? paymentRequestsFetching[payout.parent]
          : false,
      };
    });
  }, [
    payouts,
    documentsForPaymentRequest,
    isFetchingDocuments,
    paymentRequestsFetching,
  ]);

  const renderPaging = () => {
    if (totalPages <= 1) {
      return null;
    }

    return (
      <Pagination
        initialPage={page ? page - 1 : 0}
        pageCount={totalPages}
        onPageChange={({ selected }) => setPage(selected + 1)}
      />
    );
  };

  const handleShowConfirmModal = () => {
    confirm({
      title: getLocalizedText("reports.beneficiary_report.confirm.title"),
      primaryActions: [
        {
          title: getLocalizedText(
            "reports.beneficiary_report.cta.without_email",
          ),
          onPress: () => {
            createReport(selectedIds, EExportActionType.Create);
            refetch();
          },
        },
        {
          title: getLocalizedText("reports.beneficiary_report.cta.with_email"),
          onPress: () => {
            createReport(selectedIds, EExportActionType.CreateAndEmail);
            refetch();
          },
        },
      ],
    });
  };

  return (
    <Card heading={getLocalizedText("reports.beneficiary.title")}>
      <BeneficiaryReportFilter
        onFilterChange={resultingFilter => {
          setDidInitializeFilter(true);
          setFilter(resultingFilter);
        }}
        onSearchChange={setSearchQuery}
        searchQuery={searchQuery}
      />

      <Spacer weight={ESpacerWeight.W16} />

      {selectedIds && selectedIds.length > 0 && (
        <ActionButton
          title={getLocalizedText("reports.beneficiary.create_report")}
          value={selectedIds.length}
          onAction={handleShowConfirmModal}
          onClear={() => onDeselectAll()}
        />
      )}

      <DataTable
        isLoadingData={isFetching && !payouts}
        horizontalSpacing="extraTight"
        emptyState={
          <EmptyState
            backgroundColor="gray"
            heading={getLocalizedText("reports.beneficiary.payouts.empty")}
          />
        }
        {...tableSetup}
        rows={rows}
        multiSelect={true}
        selectedIds={selectedIds}
        onSelectPage={(ids: (number | string)[]) => {
          onSelectIds(ids as number[]);
        }}
        onDeselectPage={(ids: (number | string)[]) => {
          onDeselectIds(ids as number[]);
        }}
        onSelectRow={(id: number | string) => {
          onSelectId(id as number);
        }}
        onDeselectRow={(id: number | string) => {
          onDeselectId(id as number);
        }}
        onRowClick={rowIndex => {
          setQueryParamValue({
            paymentRequestId: payouts?.[rowIndex].id,
          });
        }}
      />

      {renderPaging()}
    </Card>
  );
};
