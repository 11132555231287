var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Fuse from "fuse.js";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import { mapActivityTypeToString } from "../../utils/activity.utils";
import { startOfDay } from "../../utils/date-fns.utils";
import { formatDate, formatDateWithCustomClosebyFormat, formatTime, } from "../../utils/date.utils";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
var CONTACT_FUZZY_SEARCH_POSTFIX = ["firstname", "lastname", "company"];
var ACTIVITY_FUZZY_SEARCH_PROPERTIES = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], CONTACT_FUZZY_SEARCH_POSTFIX.map(function (postfix) { return "contact.".concat(postfix); }), true), CONTACT_FUZZY_SEARCH_POSTFIX.map(function (postfix) { return "payee.".concat(postfix); }), true), [
    // Payee bank account
    "payeeBankAccount.iban",
    "payeeBankAccount.ownerName"
], false), CONTACT_FUZZY_SEARCH_POSTFIX.map(function (postfix) { return "payer.".concat(postfix); }), true), [
    // Payment order
    "paymentOrder.amount"
], false), CONTACT_FUZZY_SEARCH_POSTFIX.map(function (postfix) { return "property.members.".concat(postfix); }), true), [
    "property.box",
    "property.city",
    "property.description",
    "property.name",
    "property.number",
    "property.state",
    "property.street",
    "property.zip",
], false);
export var getActivitiesSectionListDataSource = function (activities, searchQuery) {
    if (!activities) {
        return;
    }
    var filteredActivities = activities;
    if (searchQuery) {
        var options = {
            threshold: 0.2,
            minMatchCharLength: 2,
            keys: ACTIVITY_FUZZY_SEARCH_PROPERTIES,
        };
        var fuse = new Fuse(activities, options);
        filteredActivities = fuse.search(searchQuery);
    }
    var groupedActivities = groupBy(filteredActivities, function (activity) {
        return startOfDay(activity.datetime);
    });
    var sortedActivitiesKeys = Object.keys(groupedActivities).sort(function (a, b) { return new Date(b).getTime() - new Date(a).getTime(); });
    var dataSource = sortedActivitiesKeys.map(function (date) { return ({
        title: formatDateWithCustomClosebyFormat(new Date(date)),
        data: orderBy(groupedActivities[date], ["datetime", "id"], ["desc"]),
    }); });
    return dataSource;
};
export var getActivityMetaInfo = function (_a, fullDate) {
    var type = _a.type, datetime = _a.datetime;
    if (fullDate === void 0) { fullDate = false; }
    var time = fullDate
        ? "".concat(formatDate(datetime), " ").concat(formatTime(datetime))
        : formatTime(datetime);
    var activityType = getLocalizedText("activity.".concat(mapActivityTypeToString(type) || "").toLowerCase());
    return "".concat(time, " - ").concat(activityType);
};
