var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { getDay } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import styled, { spacing } from "@rentiohq/web-theme";
import { rem } from "polished";
import media from "styled-media-query";
import { Logo } from "../../components";
export var StyledAuthFrame = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 100vh;\n  display: flex;\n"], ["\n  min-height: 100vh;\n  display: flex;\n"])));
export var StyledAuthContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  justify-content: center;\n"], ["\n  flex: 1;\n  display: flex;\n  justify-content: center;\n"])));
export var StyledAuthMain = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  max-width: 480px;\n  padding: ", ";\n"], ["\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  max-width: 480px;\n  padding: ", ";\n"])), spacing("extraLoose"));
export var StyledAuthMediaWrap = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: relative;\n  ", "\n  ", "\n"], ["\n  position: relative;\n  ", "\n  ", "\n"])), media.greaterThan("medium")(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    flex: 1;\n  "], ["\n    flex: 1;\n  "]))), media.greaterThan("large")(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    flex: 2;\n  "], ["\n    flex: 2;\n  "]))));
var NOW = new Date();
var imageIndex = Math.min(Math.floor(getDay(NOW) / 5), 5);
export var StyledAuthMedia = styled.img.attrs(function () { return ({
    src: "https://assets-rentio-prod.s3.eu-west-1.amazonaws.com/auth-visuals/login-visual-".concat(imageIndex, ".jpg"),
}); })(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n"], ["\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n"])));
export var StyledAuthActions = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  margin-top: ", ";\n  text-align: ", ";\n"], ["\n  margin-top: ", ";\n  text-align: ", ";\n"])), spacing("loose"), function (props) {
    return props.align ? props.align : "center";
});
export var StyledAuthBody = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  padding: ", " 0;\n"], ["\n  padding: ", " 0;\n"])), spacing("extraLoose"));
export var StyledAuthLogo = styled(function (props) { return _jsx(Logo, __assign({}, props)); })(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: block;\n  width: ", ";\n  margin: ", " auto;\n  margin-bottom: ", ";\n  svg {\n    fill: ", ";\n  }\n"], ["\n  display: block;\n  width: ", ";\n  margin: ", " auto;\n  margin-bottom: ", ";\n  svg {\n    fill: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return rem("200px", theme.baseFontSize);
}, spacing("extraLoose"), spacing("loose"), function (_a) {
    var theme = _a.theme;
    return theme.getColor("primary");
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
