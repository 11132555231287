import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CustomTooltip, TooltipContent } from "./CustomTooltip";
import * as S from "./Tooltip.styled";
export var Tooltip = function (props) {
    var children = props.children, style = props.style, noCursor = props.noCursor, tooltipContent = props.tooltipContent, tooltipContentStyle = props.tooltipContentStyle, placement = props.placement;
    if (!tooltipContent) {
        return _jsx(_Fragment, { children: children });
    }
    return (_jsxs(CustomTooltip, { placement: placement || "top", children: [_jsx(S.TooltipTrigger, { style: style, noCursor: noCursor, children: children }), _jsx(TooltipContent, { children: _jsx(S.TooltipContentWrap, { style: tooltipContentStyle, placement: placement, children: tooltipContent }) })] }));
};
