import { EPropertyForRentStatus } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Icon,
  TAssetSource,
  Tooltip,
} from "@rentiohq/web-shared/dist/components";
import { Colors, iconSize } from "@rentiohq/web-theme";
import * as S from "./ForRentCell.styles";

interface IProps {
  forRentStatus?: EPropertyForRentStatus;
}

export const ForRentCell = (props: IProps) => {
  const forRentStatus = props.forRentStatus || EPropertyForRentStatus.None;

  const iconSource = ((): TAssetSource | undefined => {
    switch (forRentStatus) {
      case EPropertyForRentStatus.ForRent:
        return "forRentSign";

      case EPropertyForRentStatus.InOption:
        return "inOption";

      case EPropertyForRentStatus.None:
      default:
        return;
    }
  })();

  return (
    <S.ForRentSignWrapper>
      {iconSource ? (
        <Tooltip
          tooltipContent={getLocalizedText(
            `property.for_rent_status.${stringToSnakeCase(forRentStatus)}`,
          )}
        >
          <Icon
            source={iconSource}
            size={"mediumLarge"}
            color={"purple" as Colors}
          />
        </Tooltip>
      ) : (
        <div style={{ width: iconSize("mediumLarge") }}></div>
      )}
    </S.ForRentSignWrapper>
  );
};
