import RequirementsProvider from "@rentiohq/shared-frontend/dist/providers/RequirementsProvider";
import { useDashboardType } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { EDashboardType } from "@rentiohq/shared-frontend/dist/redux/types";
import {
  IAlert,
  registerAlertTrigger,
} from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { getMessageForError } from "@rentiohq/shared-frontend/dist/utils/error.utils";
import {
  ErrorBoundary,
  ModalProvider,
  PrivateRoute,
} from "@rentiohq/web-shared/dist/components";
import {
  ToastContainer,
  toast,
} from "@rentiohq/web-shared/dist/managers/Toast";
import { getAuthScene } from "@rentiohq/web-shared/dist/scenes/Auth/Auth.scene";
import { getTemplateDocumentEditorScene } from "@rentiohq/web-shared/dist/scenes/TemplateDocumentEditor";
import { TemplateDocumentExternal } from "@rentiohq/web-shared/dist/scenes/TemplateDocumentEditor/scenes/TemplateDocumentExternal";
import { getTemplatesScene } from "@rentiohq/web-shared/dist/scenes/Templates";
import { App } from "App";
import InvitesModal from "modals/InvitesModal";
import { Outlet, Route, Routes } from "react-router-dom";
import { getChatsScene } from "scenes/Chats";
import { getCompanyScene } from "scenes/Company";
import { getContactsScene } from "scenes/Contacts";
import { getDashboardScene } from "scenes/Dashboard";
import { getFollowUpScene } from "scenes/FollowUp";
import { getFormsScene } from "scenes/Forms";
import { NotFound } from "scenes/NotFound";
import { getPaymentsOverviewScene } from "scenes/PaymentsOverview";
import { getProfileScene } from "scenes/Profile";
import { getPropertiesScene } from "scenes/Properties";
import { getRentioLegalScene } from "scenes/RentioLegal/RentioLegal.scene";
import { getReportsScene } from "scenes/Reports";
import { getRevenuesScene } from "scenes/Revenues";
import { getSettingsScene } from "scenes/Settings";
import { getTasksScene } from "scenes/Tasks";
import { TenantDashboardApp } from "tenant-dashboard/App";
import { PrivateRoutes } from "tenant-dashboard/routes/PrivateRoutes";
import { RentioThemeProvider } from "utils/theme.provider";
import { ConfirmProvider } from "../providers/ConfirmProvider";
import { RentioInternal } from "../scenes/RentioInternal";
import { getContactsScene as getTenantContactsScene } from "../tenant-dashboard/scenes/Contacts/Contacts.scene";
import { getPropertyScene } from "../tenant-dashboard/scenes/Property/Property.scene";

const ProDashboardWrapper = () => {
  return (
    <RentioThemeProvider>
      <ErrorBoundary>
        <ToastContainer />
        <ModalProvider>
          <RequirementsProvider>
            <ConfirmProvider>
              <Outlet />
              <InvitesModal />
            </ConfirmProvider>
          </RequirementsProvider>
        </ModalProvider>
      </ErrorBoundary>
    </RentioThemeProvider>
  );
};

const alertTrigger = ({ message = "", error, type, content }: IAlert) => {
  toast({
    variation: type,
    heading: error ? getMessageForError(error) : message,
    content,
  });
};
registerAlertTrigger(alertTrigger);

export const AppRouter = () => {
  const { selectedDashboardType } = useDashboardType();

  const selectedDashboardTypeMapped =
    selectedDashboardType === EDashboardType.TenantDashboard
      ? EDashboardType.TenantDashboard
      : EDashboardType.ProDashboard;

  return (
    <Routes>
      <Route path="*">
        <Route path="*" element={<ProDashboardWrapper />}>
          {getAuthScene()}

          <Route path="rentio-internal" element={<RentioInternal />} />

          <Route
            path="template-document-external"
            element={<TemplateDocumentExternal />}
          />

          {selectedDashboardTypeMapped === EDashboardType.ProDashboard && (
            <Route element={<PrivateRoute />}>
              <Route path="*" element={<App />}>
                {getDashboardScene()}

                {getTasksScene()}

                {getFormsScene()}

                {getRevenuesScene()}

                {getChatsScene()}

                {getTemplateDocumentEditorScene()}

                {getFollowUpScene()}

                {getPaymentsOverviewScene()}

                {getContactsScene()}

                {getProfileScene()}

                {getCompanyScene()}

                {getSettingsScene()}

                {getPropertiesScene()}

                {getReportsScene()}

                {getRentioLegalScene()}

                {getTemplatesScene({ internal: false })}

                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
          )}

          <Route path="*" element={<NotFound />} />
        </Route>

        {selectedDashboardTypeMapped === EDashboardType.TenantDashboard && (
          <Route path="*">
            <Route element={<PrivateRoute />}>
              <Route path="*" element={<TenantDashboardApp />}>
                {getPropertyScene()}

                {getTenantContactsScene()}

                {PrivateRoutes.map(route => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                ))}

                {/* 
                // TODO: This should be a new not found component */}
                <Route path="*" element={<>Page not found</>} />
              </Route>
            </Route>
          </Route>
        )}
      </Route>
    </Routes>
  );
};
