import { IBroker } from "@rentiohq/shared-frontend/dist/types/broker.types";

export interface IExtraData {
  broker?: IBroker;
  activeAccountId?: number;
}

export enum EField {
  PaymentStatus = "paymentStatus",
  Invoiced = "invoiced",
  From = "from",
  To = "to",
}
