import usePreference from "scenes/Settings/hooks/usePreference";
import CustomColorPicker from "../CustomColorPicker";
import { SharedSettingsProps } from "../Settings.types";

export interface SettingsColorPickerProps extends SharedSettingsProps {
  title: string;
  modalHeading: string;
  buttonText?: string;
  successMessage?: string;
}

// eslint-disable-next-line import/no-default-export
export default function SettingsColorPicker({
  preferenceKey,
  preferencePersistScope,
  title,
  buttonText,
  modalHeading,
  successMessage,
}: SettingsColorPickerProps) {
  const [preferredColor = "darkgray", saveColor] = usePreference<string>({
    preferenceKey,
    preferencePersistScope,
  });

  return (
    <CustomColorPicker
      color={preferredColor ?? "white"}
      modalHeading={modalHeading}
      title={title}
      buttonText={buttonText}
      successMessage={successMessage}
      onChange={saveColor}
    />
  );
}
