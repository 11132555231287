export var EField;
(function (EField) {
    EField["CurrentPrice"] = "currentPrice";
    EField["NewPrice"] = "newPrice";
    EField["Retroactivity"] = "retroactivity";
    EField["GenerateLetter"] = "generateLetter";
    EField["LetterType"] = "letterType";
    EField["ForceFullIndexation"] = "forceFullIndexation";
    EField["EpcBasedLimitationInfo"] = "epcBasedLimitationInfo";
    EField["EpcExpirationDate"] = "epcExpirationDate";
})(EField || (EField = {}));
