import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_SIDEBAR, OPEN_SIDEBAR } from "../../redux/actions";
import { sidebarStateSelector } from "../../redux/selectors";
import { LogoSection } from "../LogoSection";
import { MenuList } from "../MenuList";
import { SidebarStyles } from "./Sidebar.styles";
export var Sidebar = function () {
    var sidebarOpened = useSelector(sidebarStateSelector);
    var dispatch = useDispatch();
    var downLG = useMediaQuery(function (theme) { return theme.breakpoints.down("lg"); });
    var sx = SidebarStyles(sidebarOpened);
    var logo = useMemo(function () { return (_jsx(Box, { sx: sx.content.logo, children: _jsx(LogoSection, {}) })); }, []);
    var menu = function () {
        var drawerSX = sidebarOpened
            ? sx.content.menu.open
            : sx.content.menu.close;
        return (_jsx(Box, { sx: drawerSX, children: _jsx(MenuList, {}) }));
    };
    useEffect(function () {
        dispatch({ type: downLG ? CLOSE_SIDEBAR : OPEN_SIDEBAR });
    }, [downLG]);
    return (_jsx(Box, { component: "nav", sx: sx.wrapper, children: _jsxs(Drawer, { variant: "persistent", anchor: "left", open: true, sx: sx.content.drawer, ModalProps: { keepMounted: true }, color: "inherit", children: [logo, menu()] }) }));
};
