import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { getLocale, getLocalizedText, } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "../assets";
var getApplicationsRoute = function () {
    switch (getLocale()) {
        case ELocale.NL:
            return "https://mijnhuurprofiel.be";
        case ELocale.FR:
            return "https://monprofillocataire.be";
        default:
            return "https://mytenantprofile.be";
    }
};
var menuItems = [
    {
        id: "property",
        title: getLocalizedText("system.properties"),
        icon: EAssets.HouseIcon,
        url: "/properties",
        matchPaths: [
            "/properties",
            "/properties/:propertyId/",
            "/properties/:propertyId/contracts",
            "/properties/:propertyId/documents",
            "properties/:propertyId/history",
        ],
        breadcrumbs: true,
    },
    {
        id: "contacts",
        title: getLocalizedText("system.contacts"),
        icon: EAssets.UserOutlinedIcon,
        url: "/contacts",
        matchPaths: ["/contacts"],
        breadcrumbs: true,
    },
    {
        id: "welcome",
        title: getLocalizedText("dashboard.welcome"),
        icon: EAssets.CalendarIcon,
        url: "",
        matchPaths: ["/"],
        breadcrumbs: true,
        disabled: true,
    },
    {
        id: "applications",
        title: getLocalizedText("system.entity.applications"),
        icon: EAssets.ApplicationIcon,
        url: getApplicationsRoute(),
        matchPaths: ["applications"],
        breadcrumbs: true,
        target: "_blank",
        openModal: true,
        message: getLocalizedText("tenant_dashboard.see_applications.modal.message"),
    },
    {
        id: "payments",
        title: getLocalizedText("payments_overview.heading"),
        icon: EAssets.EuroIcon,
        url: "https://pay.rentio.be",
        matchPaths: ["payments"],
        breadcrumbs: true,
        target: "_blank",
        openModal: true,
        message: getLocalizedText("tenant_dashboard.see_payments.modal.message"),
    },
    {
        id: "tasks",
        title: getLocalizedText("system.entity.tasks"),
        icon: EAssets.TasksIcon,
        url: "/tasks",
        matchPaths: ["tasks"],
        breadcrumbs: true,
        disabled: true,
    },
    {
        id: "chats",
        title: getLocalizedText("system.entity.chats"),
        icon: EAssets.ChatsIcon,
        url: "/chats",
        matchPaths: ["chats"],
        breadcrumbs: true,
        disabled: true,
    },
];
// eslint-disable-next-line import/no-default-export
export default menuItems;
