export var EDocumentMemberTypes;
(function (EDocumentMemberTypes) {
    EDocumentMemberTypes["Admin"] = "ADMIN";
    EDocumentMemberTypes["Viewer"] = "VIEWER";
})(EDocumentMemberTypes || (EDocumentMemberTypes = {}));
// New categories should be updated in the following places:
// - Loopback: document-category.utils.js
// - Nest: document.types.ts
// - Serverless: documentCategoryType.ts
// - Shared: Document.types.ts & document.constants.ts
// - Lokalise: Add new label
export var EDocumentCategory;
(function (EDocumentCategory) {
    EDocumentCategory[EDocumentCategory["Other"] = 1] = "Other";
    EDocumentCategory[EDocumentCategory["Contract"] = 2] = "Contract";
    EDocumentCategory[EDocumentCategory["Certificate"] = 3] = "Certificate";
    EDocumentCategory[EDocumentCategory["Invoice"] = 4] = "Invoice";
    EDocumentCategory[EDocumentCategory["Application"] = 5] = "Application";
    EDocumentCategory[EDocumentCategory["LocationDescription"] = 6] = "LocationDescription";
    EDocumentCategory[EDocumentCategory["Image"] = 7] = "Image";
    EDocumentCategory[EDocumentCategory["Attachment"] = 8] = "Attachment";
    EDocumentCategory[EDocumentCategory["RentDeposit"] = 9] = "RentDeposit";
    EDocumentCategory[EDocumentCategory["RentalAssignment"] = 10] = "RentalAssignment";
    EDocumentCategory[EDocumentCategory["RegisteredMail"] = 11] = "RegisteredMail";
    EDocumentCategory[EDocumentCategory["RentDepositRelease"] = 12] = "RentDepositRelease";
    EDocumentCategory[EDocumentCategory["IdCardBack"] = 13] = "IdCardBack";
    EDocumentCategory[EDocumentCategory["IdCardFront"] = 14] = "IdCardFront";
    EDocumentCategory[EDocumentCategory["Epc"] = 15] = "Epc";
    EDocumentCategory[EDocumentCategory["Electricity"] = 16] = "Electricity";
    EDocumentCategory[EDocumentCategory["Examination"] = 17] = "Examination";
    EDocumentCategory[EDocumentCategory["HeaterMaintenance"] = 18] = "HeaterMaintenance";
    EDocumentCategory[EDocumentCategory["RegisteredLetter"] = 19] = "RegisteredLetter";
    EDocumentCategory[EDocumentCategory["Quotation"] = 20] = "Quotation";
    EDocumentCategory[EDocumentCategory["CancelledContract"] = 21] = "CancelledContract";
    EDocumentCategory[EDocumentCategory["InsuranceProof"] = 22] = "InsuranceProof";
    EDocumentCategory[EDocumentCategory["KeyHandover"] = 23] = "KeyHandover";
    EDocumentCategory[EDocumentCategory["ContractAddendum"] = 24] = "ContractAddendum";
    EDocumentCategory[EDocumentCategory["RegistrationProofContract"] = 25] = "RegistrationProofContract";
    EDocumentCategory[EDocumentCategory["RegistrationProofLocationDescription"] = 26] = "RegistrationProofLocationDescription";
    EDocumentCategory[EDocumentCategory["RegistrationProofAddendum"] = 27] = "RegistrationProofAddendum";
    EDocumentCategory[EDocumentCategory["BeneficiaryReport"] = 28] = "BeneficiaryReport";
    EDocumentCategory[EDocumentCategory["LegalCase"] = 29] = "LegalCase";
})(EDocumentCategory || (EDocumentCategory = {}));
