import * as registrationActions from "@rentiohq/shared-frontend/dist/redux/registration/registration.actions";
import * as registrationSelectors from "@rentiohq/shared-frontend/dist/redux/registration/registration.selectors";
import {
  ERegistrationContractDestination,
  ERegistrationContractType,
  ERegistrationDocumentType,
  ERegistrationMemberType,
  ICreateRegistration,
  ICreateRegistrationDocument,
} from "@rentiohq/shared-frontend/dist/types/registration.types";
import { IRootStore } from "redux/reducers";
import { EField, IExtraData } from "./schema.registration.types";

export const isPerformingSubmitActionSelector =
  (_: IExtraData) => (state: IRootStore) =>
    registrationSelectors.isCreating(state);

export const performingSubmitActionResultSelector =
  (_: IExtraData) => (state: IRootStore) =>
    registrationSelectors.createdRegistration(state);

export const performingSubmitActionErrorSelector =
  (_: IExtraData) => (state: IRootStore) =>
    registrationSelectors.createError(state);

export const submitActionCreator =
  (extraData: IExtraData) => (formData: any) => {
    const { property, contract } = extraData;

    const members = [
      ...(formData[EField.Owners] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Owner,
        accountId,
      })),
      ...(formData[EField.SignAsBroker] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Broker,
        accountId,
      })),
      ...(formData[EField.Tenants] || []).map((accountId: number) => ({
        role: ERegistrationMemberType.Tenant,
        accountId,
      })),
    ];

    const registration: ICreateRegistration = {
      contractId: contract.id,
      propertyId: property.id,
      members,
      documents: [],
      rentContractDestination: formData[EField.RentContractDestination],
      preferredContractLanguage: formData[EField.RentContractLanguage],
      rentContractPropertyTaxPaidByTenant:
        formData[EField.RentContractPropertyTaxPaidByTenant] === "yes",
    };

    if (
      formData[EField.RentContractDestination] ===
      ERegistrationContractDestination.Housing
    ) {
      registration.rentContractPropertyTaxPaidByTenant = false;
    }

    const registrationContractDocument: ICreateRegistrationDocument = {
      documentId: formData[EField.RentContractDocumentToUpload]?.[0],
      documentType: ERegistrationDocumentType.Contract,
      type: ERegistrationContractType.Lease,
      signedAt: formData[EField.RentContractSignedAt],
      signers: members,
    };

    registration.documents.push(registrationContractDocument);

    if (formData[EField.LocationDescriptionShouldUpload] === "now") {
      const locationDescriptionDocument: ICreateRegistrationDocument = {
        documentId: formData[EField.LocationDescriptionDocumentToUpload]?.[0],
        documentType: ERegistrationDocumentType.PropertyInspectionReport,
        type: ERegistrationContractType.Lease,
        signedAt: formData[EField.LocationDescriptionSignedAt],
        signers: members,
      };

      registration.documents.push(locationDescriptionDocument);
    }

    return registrationActions.createRegistration.actions.start({
      registration,
    });
  };
